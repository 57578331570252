import { useQuery } from 'react-query';

import { PlanPrice } from '@/interfaces/plan_price';
import api from '@/services/swarm';

interface Invoice {
  amount?: number;
  cadence?: PlanPrice['interval'];
  timestamp?: string;
}

interface BillingDetails {
  brand?: string;
  last4?: string;
  exp_month?: number;
  exp_year?: number;
  name?: string;
  email?: string;
  phone?: string;
  tax_ids?: string[];
  address?: {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string;
    postal_code?: string;
    state?: string;
  };
}

export interface BillingSummaryApiResponse {
  last_invoice: Invoice;
  next_invoice: Invoice;
  billing_details: BillingDetails;
  past_due: boolean;
}

interface Props {
  organizationId: string | undefined;
  publicationId: string;
  includeLaunch?: boolean;
}

const useBillingSummary = ({ organizationId, publicationId, includeLaunch = false }: Props) => {
  return useQuery<BillingSummaryApiResponse>(
    ['organization', organizationId, 'billing_summary', `include_launch_${includeLaunch}`],
    () =>
      api
        .get(`/organizations/${organizationId}/billing_summary`, {
          params: {
            publication_id: publicationId,
            include_launch: includeLaunch,
          },
        })
        .then((res) => res.data),
    {
      retry: false,
      staleTime: 60_000, // 1 minute
      enabled: !!organizationId,
    }
  );
};

export default useBillingSummary;
