import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { VIEWPORTS } from '../../page/constants';
import { Viewport } from '../../page/types';
import { Tabs, TabsList, TabsTrigger } from '../UI/Tabs';
import { Text } from '../UI/Text';

interface Props {
  selectedTab: 'desktop' | 'mobile';
  setSelectedTab: (tab: 'desktop' | 'mobile') => void;
}

const ViewportContext = createContext<{ viewport: Viewport; setViewport: (tab: 'desktop' | 'mobile') => void }>({
  viewport: VIEWPORTS[0],
  setViewport: () => {},
});

const useDesktopMobileTabs = () => {
  const [searchParams] = useSearchParams();
  const defaultTab = (searchParams.get('viewport') as 'desktop' | 'mobile') || 'desktop';
  const [selectedTab, setSelectedTab] = useState<'desktop' | 'mobile'>(defaultTab);
  const viewport = VIEWPORTS.find((item) => item.type === selectedTab) || VIEWPORTS[0];

  return { viewport, selectedTab, setSelectedTab };
};

const ViewportProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchParams] = useSearchParams();
  const defaultTab = (searchParams.get('viewport') as 'desktop' | 'mobile') || 'desktop';
  const [viewport, setInternalViewport] = useState<Viewport>(
    VIEWPORTS.find((item) => item.type === defaultTab) || VIEWPORTS[0]
  );
  const setViewport = useCallback((tab: 'desktop' | 'mobile') => {
    setInternalViewport(VIEWPORTS.find((item) => item.type === tab) || VIEWPORTS[0]);
  }, []);

  const value = useMemo(() => ({ viewport, setViewport }), [viewport, setViewport]);

  return <ViewportContext.Provider value={value}>{children}</ViewportContext.Provider>;
};

const useViewport = () => {
  const context = useContext(ViewportContext);
  if (!context) {
    throw new Error('useViewport must be used within a ViewportProvider');
  }
  return context;
};

const DesktopMobileTabs = ({ selectedTab, setSelectedTab }: Props) => {
  const [, setSearchParams] = useSearchParams();
  const { setViewport } = useViewport();

  const handleSetSelectedTab = (tab: 'desktop' | 'mobile') => {
    setSelectedTab(tab);
    setSearchParams({ viewport: tab });
    setViewport(tab);
  };

  return (
    <div className="flex flex-col mx-auto max-w-[200px]">
      <Tabs
        defaultValue="desktop"
        className="flex flex-col"
        value={selectedTab}
        onValueChange={(value) => handleSetSelectedTab(value as 'desktop' | 'mobile')}
      >
        <TabsList className="p-2" defaultValue="desktop">
          <TabsTrigger value="desktop">
            <Text weight="medium" size="2xs" className="leading-0">
              Desktop
            </Text>
          </TabsTrigger>
          <TabsTrigger value="mobile">
            <Text weight="medium" size="2xs" className="leading-0">
              Mobile
            </Text>
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </div>
  );
};

export { DesktopMobileTabs, useDesktopMobileTabs, useViewport, ViewportProvider };
