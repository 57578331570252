import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Price, PriceInterval, Tier, TierStatus } from '@shared/dream-components';
import { Editor } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

import { useSettings } from '@/context/settings-context';
import usePricingTiers from '@/hooks/useDreamBuilder/usePricingTiers';
import useUpdatePricingTiers, { TiersUpdateProps } from '@/hooks/useDreamBuilder/useUpdatePricingTiers';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Tier as SwarmTier } from '@/interfaces/tier';

import { Button } from '../../../../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Text } from '../../../../UI/Text';
import { tiers as dummyTiers } from '../../../dummyData/pricing';

import SelectedTierView from './SelectedTierView';
import SortableTiersList from './SortableTiersList';

const title = 'Select tiers to display';

const TiersSelectionModal = ({
  editor,
  node,
  isOpen,
  setIsModalOpen,
  onClose,
  pos,
}: {
  node: ProseMirrorNode;
  editor: Editor;
  isOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onClose: () => void;
  pos: number;
}) => {
  const [tiersInState, setTiersInState] = useState<Tier[]>([]);
  const [selectedTier, setSelectedTier] = useState<Tier | null>(null);

  const publicationId = useCurrentPublicationId();
  const FREE_TIER: Tier = useMemo(
    () => ({
      id: 'free',
      name: 'Free Tier',
      enabled: false,
      publication_id: publicationId,
      status: TierStatus.ACTIVE,
      is_default: false,
      prices: [
        {
          id: 'free',
          amount_cents: 0,
          original_amount_cents: 0,
          currency: 'usd',
          interval: PriceInterval.MONTH,
          interval_display: 'monthly ',
          denominator: '',
          cta: 'free cta',
          enabled: true,
          features: [],
        },
      ],
    }),
    [publicationId]
  );

  const reset = () => {
    setTiersInState([]);
    setSelectedTier(null);
  };

  const { settings } = useSettings();
  const { data: tiersData, isLoading } = usePricingTiers({ enabled: true });
  const hasPaidTiers = settings?.premium_subscriptions;
  const showEmptyState = !hasPaidTiers || tiersData?.tiers.length === 0;

  const { mutateAsync: updateTiers } = useUpdatePricingTiers({});

  const handleSubmit = useCallback(async () => {
    try {
      const updatedTierVariables: TiersUpdateProps = tiersInState
        .filter((tier) => tier.id !== FREE_TIER.id)
        .map((tier) => {
          return {
            id: tier.id,
            name: tier.name,
            description: tier.description,
            prices_attributes: tier.prices.map((price) => {
              return {
                id: price.id,
                features:
                  (Array.isArray(price.features) && price.features.length === 0) || price.features === null
                    ? null
                    : price.features,
              };
            }),
          };
        });

      await updateTiers(updatedTierVariables);

      const visibleTiers = tiersInState.filter((tier: Tier) => tier.enabled);
      const visibleTierIds = visibleTiers.map((tier: Tier) => tier.id);
      const newRecommendedTierId = visibleTierIds.includes(node.attrs.recommendedTierId)
        ? node.attrs.recommendedTierId
        : null;

      const newRecommendedPriceId =
        // eslint-disable-next-line no-nested-ternary
        visibleTiers.length === 1
          ? visibleTiers[0].prices.map((price) => price.id).includes(node.attrs.recommendedPriceId)
            ? node.attrs.recommendedPriceId
            : null
          : null;

      editor?.commands.command(({ tr }) => {
        tr.setNodeAttribute(pos, 'insertedFromSidebar', false);
        if (visibleTierIds.length > 0) {
          tr.setNodeAttribute(pos, 'hasFakeData', false);
          tr.setNodeAttribute(pos, 'data', tiersInState);
          tr.setNodeAttribute(pos, 'recommendedTierId', newRecommendedTierId);
          tr.setNodeAttribute(pos, 'recommendedPriceId', newRecommendedPriceId);
        } else {
          tr.setNodeAttribute(pos, 'hasFakeData', true);
          tr.setNodeAttribute(pos, 'data', dummyTiers);
          tr.setNodeAttribute(pos, 'recommendedTierId', null);
          tr.setNodeAttribute(pos, 'recommendedPriceId', null);
        }
        return true;
      });

      setIsModalOpen(false);
    } catch (error: any) {
      toast.error('Something went wrong updating Tiers, please try again!');
    }
  }, [tiersInState, updateTiers, editor?.commands, setIsModalOpen, FREE_TIER.id, pos]);

  const handleChangeTier = (changedTier: Tier) => {
    const newTiers = [...tiersInState];
    const indexOfChangedTier = tiersInState.findIndex((tier) => tier.id === changedTier.id);
    newTiers[indexOfChangedTier] = {
      ...changedTier,
    };

    setSelectedTier(changedTier);
    setTiersInState(newTiers);
  };

  const handleClickTier = (tier: Tier) => {
    setSelectedTier(tier);
  };

  useEffect(() => {
    reset();
  }, [isOpen]);

  useEffect(() => {
    if (!tiersData || tiersData.tiers.length === 0) {
      // No tiers exist anymore
      // due to users deleting or disabling them
      return;
    }

    if (!node.attrs.data || node.attrs.data.length === 0 || node.attrs.hasFakeData) {
      const transformedTiers: Tier[] = tiersData.tiers.map((tier) => {
        return {
          ...tier,
          enabled: false,
          prices: tier.prices.map((price) => {
            return {
              ...price,
            } as Price;
          }),
        };
      });
      // No tiers have been saved before
      setSelectedTier(transformedTiers[0]);
      setTiersInState([...transformedTiers, FREE_TIER]);
      return;
    }

    const validTiersById: Record<string, SwarmTier> = tiersData.tiers.reduce((pv, cv) => {
      return {
        ...pv,
        [cv.id]: cv,
      };
    }, {});

    const previousTiers: Tier[] = node.attrs.data
      .filter((tier: Tier) => tier.id === FREE_TIER.id || !!validTiersById[tier.id])
      .map((tier: Tier) => {
        if (tier.id === FREE_TIER.id) {
          return tier;
        }

        return {
          enabled: tier.enabled,
          ...validTiersById[tier.id],
        };
      });

    const previousTierIds = previousTiers.map((tier: Tier) => tier.id);

    const newTiers: Tier[] = tiersData.tiers
      .filter((tier) => !previousTierIds.includes(tier.id))
      .map((tier: SwarmTier) => {
        return {
          ...tier,
          enabled: false,
          prices: tier.prices.map((price) => {
            return {
              ...price,
            } as Price;
          }),
        } as Tier;
      });

    const newTiersInState = [...previousTiers, ...newTiers];

    setSelectedTier(newTiersInState[0]);
    setTiersInState(newTiersInState);
  }, [FREE_TIER, node.attrs, node.attrs.data, node.attrs.hasFakeData, tiersData]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) {
          onClose();
        }
      }}
    >
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col justify-between overflow-hidden p-0 gap-0">
        <DialogHeader className="grow-0">
          <DialogTitle>
            <div className="flex items-center gap-2 p-4">
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                {title}
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        {!showEmptyState && !isLoading && (
          <div className="flex flex-row p-4 grow h-auto overflow-hidden">
            <div className="h-full w-1/3 border-r border-surface-200 pr-2">
              <ul className="flex flex-col gap-y-1">
                <SortableTiersList
                  onClick={handleClickTier}
                  tiersInState={tiersInState}
                  setTiersInState={setTiersInState}
                  selectedTier={selectedTier}
                />
              </ul>
            </div>
            <div className="w-2/3 px-4 h-full overflow-y-scroll">
              {selectedTier && (
                <SelectedTierView key={selectedTier.id} tier={selectedTier} onChange={handleChangeTier} />
              )}
            </div>
          </div>
        )}
        {showEmptyState && !isLoading && (
          <div className="flex flex-col p-4 grow h-auto overflow-hidden justify-center items-center">
            <Text variant="primary">Configure Paid Subscription Tiers</Text>
            <Text variant="primary" size="sm">
              Click{' '}
              <a href={`${window.location.origin}/monetize/subscriptions/paid_tiers`} target="_blank" rel="noreferrer">
                <Text variant="accent" size="sm" className="underline">
                  here
                </Text>
              </a>{' '}
              to customize plans and pricing for your paid subscriptions.
            </Text>
          </div>
        )}

        <DialogFooter className="flex justify-between items-center p-4 bg-white border-t border-wb-primary grow-0">
          <div className="flex items-center gap-2 justify-end w-full">
            <div className="flex gap-2 w-full justify-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit} isDisabled={showEmptyState}>
                Save
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TiersSelectionModal;
