import { FC } from 'react';
import cx from 'classnames';

import { AdPortalAdvertiser } from '@/ad_portal/interfaces/advertiser';

import AdvertiserOption from './AdvertiserOption';

interface Props {
  isOpen: boolean;
  currentAdvertiserId: string;
  advertisers: AdPortalAdvertiser[];
  onSelectOption: (advertiser: AdPortalAdvertiser) => void;
}

const AdvertiserOptions: FC<Props> = (props) => {
  const { isOpen, currentAdvertiserId, advertisers, onSelectOption } = props;

  const className = isOpen
    ? 'min-w-[200px] absolute z-50 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm opacity-100 scale-100'
    : 'absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm opacity-0 scale-95 pointer-events-none';

  return (
    <ul
      aria-labelledby="location"
      aria-orientation="vertical"
      className={cx(className, 'mt-1')}
      role="menu"
      tabIndex={-1}
    >
      {advertisers.map((advertiser) => (
        <AdvertiserOption
          key={advertiser.id}
          currentAdvertiserId={currentAdvertiserId}
          advertiser={advertiser}
          onSelectOption={onSelectOption}
        />
      ))}
    </ul>
  );
};

export default AdvertiserOptions;
