import { Dispatch, SetStateAction, useEffect } from 'react';

import { useDreamEditorContext } from '@/context/dream-editor-context';

import {
  INSERTED_FROM_INSERT_PANEL_IDENTIFIER,
  OPEN_INSERT_PANEL_IDENTIFIER,
} from '../../DreamEditor/extensions/constants';

interface Props {
  setIsInsertPanelOpen: Dispatch<SetStateAction<boolean>>;
}

const useEditorHelpers = ({ setIsInsertPanelOpen }: Props) => {
  const { editor } = useDreamEditorContext();

  useEffect(() => {
    if (!editor) return;

    editor.on('transaction', ({ transaction }) => {
      if (transaction.getMeta(INSERTED_FROM_INSERT_PANEL_IDENTIFIER)) {
        setIsInsertPanelOpen(false);
      }

      if (transaction.getMeta(OPEN_INSERT_PANEL_IDENTIFIER)) {
        setIsInsertPanelOpen(true);
      }
    });
  }, [editor, setIsInsertPanelOpen]);

  useEffect(() => {
    editor?.on('focus', () => {
      setIsInsertPanelOpen(false);
    });
  }, [editor, setIsInsertPanelOpen]);
};

export default useEditorHelpers;
