import { Fragment, useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import Tooltip from '../../ui/Tooltip';
import { Checkbox } from '../Form';

export enum SelectingState {
  NONE = 'none',
  SOME = 'some',
  ALL = 'all',
}

type SelectCheckboxProps = {
  alignMenu: 'left' | 'right';
  checked: boolean;
  className?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  handleCheck?: (checked: boolean) => void;
  indeterminate?: boolean;
  options: JSX.Element[];
};

const SelectCheckbox = ({
  alignMenu,
  checked = false,
  className,
  disabled = false,
  disabledTooltip,
  handleCheck = () => {},
  indeterminate = false,
  options,
}: SelectCheckboxProps) => {
  const classNames = cx(
    'relative inline-block border border-gray-300 text-left p-2 space-x-2',
    'justify-center duration-200 border font-medium items-center whitespace-nowrap',
    'focus:outline-none focus:ring-2 disabled:cursor-not-allowed hover:cursor-pointer',
    disabled ? 'disabled' : '',
    className
  );

  const buttonRef = useRef<HTMLButtonElement>(null);
  const checkingMainBox = (event: React.MouseEvent<HTMLDivElement>) => event.target instanceof HTMLInputElement;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (checkingMainBox(event)) {
      return;
    }

    // Open menu
    buttonRef?.current?.click();
  };

  const menuItemsClassNames = cx(
    'z-10 absolute w-56 mt-4 bg-white divide-y divide-gray-100',
    'rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
    alignMenu === 'left' ? '-left-2' : '-left-44',
    disabled ? 'disabled' : ''
  );
  const disabledTooltipId = `disabled-tooltip-${Math.random().toString(36).substring(7)}`;

  if (disabled) {
    return (
      <Tooltip
        tooltipClass="text-center"
        id={disabledTooltipId}
        text={disabledTooltip || ''}
        showIcon={false}
        autoWidth={false}
        isEnabled={!!disabledTooltip && disabledTooltip.length > 0}
      >
        <Menu as="div" className={classNames} onClick={() => {}}>
          {() => (
            <div className="flex flex-row space-x-2">
              <Checkbox name="select-items" checked={false} onChange={() => {}} disabled />

              <Menu.Button ref={buttonRef} className="cursor-not-allowed">
                <ChevronDownIcon className="w-4 h-4" />
              </Menu.Button>
            </div>
          )}
        </Menu>
      </Tooltip>
    );
  }

  return (
    <Menu as="div" className={classNames} onClick={handleClick}>
      {({ open }) => (
        <>
          <div className="flex flex-row space-x-2">
            <Checkbox
              name="select-items"
              checked={checked}
              onChange={handleCheck}
              indeterminate={indeterminate}
              disabled={disabled}
            />

            <Menu.Button ref={buttonRef} className={cx(disabled ? 'cursor-not-allowed' : '')}>
              {open && <ChevronUpIcon className="w-4 h-4" />}
              {!open && <ChevronDownIcon className="w-4 h-4" />}
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items static className={menuItemsClassNames}>
              {options.map((option) => {
                return (
                  <Menu.Item key={option.key}>
                    {({ active }) => {
                      let bgColor = option.props.type === 'label' ? 'bg-tertiary-50 text-gray-900' : 'text-gray-800';

                      if (active && option.props.type !== 'label') {
                        bgColor = 'bg-secondary-50 text-secondary-900';
                      }

                      const optionClass = cx(
                        bgColor,
                        'group flex flex-row rounded-md items-center w-full text-sm disabled:opacity-50 disabled:cursor-not-allowed'
                      );

                      return <div className={optionClass}>{option}</div>;
                    }}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default SelectCheckbox;
