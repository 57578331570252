import { FC, ReactNode } from 'react';

import Header from '@/components/Header';
import HelpingHand from '@/components/HelpingHand';
import Tooltip from '@/ui/Tooltip';

const formContainerClass = 'text-left';
const sectionClass = 'py-3';
const sectionTitleClass = 'pb-3';
const formInputContainerClass = 'py-3';

export const subTitleClass = 'text-sm pb-2 font-bold';

export const Divider = () => {
  return (
    <div className={formInputContainerClass}>
      <hr />
    </div>
  );
};

export const FormContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className={formContainerClass}>{children}</div>;
};

export const FormSection: FC<{ children: ReactNode; title: string; protip?: string | React.ReactNode }> = ({
  children,
  title,
  protip,
}) => {
  return (
    <section className={sectionClass}>
      <Header className={sectionTitleClass} as="h3">
        {title}
      </Header>
      {protip && <HelpingHand>{protip}</HelpingHand>}
      {children}
    </section>
  );
};

export const FormInput: FC<{
  children: ReactNode;
  title?: string;
  helperText?: string | React.ReactNode;
  tooltipText?: string;
}> = ({ children, title, helperText, tooltipText }) => {
  return (
    <div className={formInputContainerClass}>
      {title && (
        <div className="flex space-x-1">
          <Header as="h5" className={subTitleClass}>
            {title}
          </Header>
          {tooltipText && (
            <div className="pt-0.5">
              <Tooltip id={`${title?.split(' ').join('-')}-tooltip`} text={tooltipText} />
            </div>
          )}
        </div>
      )}
      {children}
      {helperText && <div className="mt-2 text-xs text-gray-500">{helperText}</div>}
    </div>
  );
};
