import { PropsWithChildren } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import useAdBlockDetect from '@/hooks/useAdBlockDetect';
import { Button } from '@/ui/Button';

const AdBlockerWarning: React.FC<PropsWithChildren> = ({ children }) => {
  const { adBlockDetected, ignoreAdBlocker } = useAdBlockDetect();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!adBlockDetected) return <>{children}</>;

  return (
    <div className="p-4">
      <div className="flex flex-wrap max-w-2xl p-8 mx-auto bg-white border rounded">
        <div className="flex items-center justify-center w-10 h-10 mb-4 mr-4 bg-gray-200 rounded-full">
          <ExclamationTriangleIcon className="w-6 h-6 text-gray-500" />
        </div>
        <div className="sm:flex-1">
          <h1 className="text-lg font-bold text-gray-800 sm:text-base">Ad blocker detected</h1>
          <p className="text-lg text-gray-800 sm:text-base">
            It appears that you are using an ad blocker — some elements of the site may not work properly while an ad
            blocker is enabled. Please consider disabling your ad blocker for this site.
          </p>
          <Button onClick={ignoreAdBlocker} className="mt-4" variant="primary-inverse">
            I understand, continue anyway
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdBlockerWarning;
