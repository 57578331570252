import { useNavigate } from 'react-router-dom';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import { Button } from '@/ui/Button';
import { FormStep } from '@/ui/FormStep';

import TwoColumnPageContainer from '../../../../_components/TwoColumnPageContainer';
import EmailForm from '../../components/form/EmailForm';
import ReviewForm from '../../components/form/ReviewForm';
import Summary from '../../components/form/Summary';
import { StepType } from '../../components/form/types';
import getRedirectDomainValue from '../../getRedirectDomainValue';
import useForm from '../../hooks/useForm';

interface Props {
  publicationId: string;
}

const NewEmailDomainForm = ({ publicationId }: Props) => {
  const navigate = useNavigate();
  const { data: publication, isSuccess, isError } = usePublication(publicationId);

  const {
    currentStep,
    errors,
    handleClickNextStep,
    handleClickPreviousStep,
    webDomain,
    redirectToDomain,
    setShouldUseDefaultEmailDomain,
    setEmailDomain,
    setShouldUseCustomEmailDomain,
    setShouldUseWebDomainAsEmailDomain,
    isCreating,
    shouldUseDefaultWebDomain,
    redirectToWWW,
    shouldRedirectToDomain,
    shouldUseCustomEmailDomain,
    emailDomain,
    shouldUseWebDomainAsEmailDomain,
    shouldUseDefaultEmailDomain,
    setupMethod,
    setSetupMethod,
    isEntriAvailable,
    isSetupMethodModalOpen,
    setIsSetupMethodModalOpen,
    customDomainIds,
    startManualVerification,
  } = useForm({ publicationId, isEmailOnly: true, initialStep: StepType.EMAIL });

  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <TwoColumnPageContainer
          rhsColumn={
            <Summary
              publicationSubdomain={publication.subdomain}
              shouldUseDefaultWebDomain={shouldUseDefaultWebDomain}
              shouldUseCustomEmailDomain={shouldUseCustomEmailDomain}
              emailDomain={emailDomain}
              shouldUseWebDomainAsEmailDomain={shouldUseWebDomainAsEmailDomain}
              shouldUseDefaultEmailDomain={shouldUseDefaultEmailDomain}
              hideWebSection
            />
          }
        >
          <FormStep
            isOpen={currentStep === 'email'}
            stepTitle="Configure Email Domain"
            footer={
              <>
                <Button
                  type="button"
                  variant="primary-inverse"
                  onClick={() => navigate('/settings/publication/domain')}
                  size="sm"
                >
                  Back
                </Button>
                <Button type="button" variant="primary" onClick={handleClickNextStep} size="sm">
                  Next
                </Button>
              </>
            }
          >
            <EmailForm
              errors={errors}
              webDomain={webDomain || ''}
              shouldUseCustomEmailDomain={shouldUseCustomEmailDomain}
              setShouldUseCustomEmailDomain={setShouldUseCustomEmailDomain}
              emailDomain={emailDomain}
              setEmailDomain={setEmailDomain}
              shouldUseWebDomainAsEmailDomain={shouldUseWebDomainAsEmailDomain}
              setShouldUseWebDomainAsEmailDomain={setShouldUseWebDomainAsEmailDomain}
              shouldUseDefaultEmailDomain={shouldUseDefaultEmailDomain}
              setShouldUseDefaultEmailDomain={setShouldUseDefaultEmailDomain}
              shouldUseDefaultWebDomain={shouldUseDefaultWebDomain}
            />
          </FormStep>
          <FormStep
            isOpen={currentStep === 'review'}
            stepTitle="Review and Set Up"
            footer={
              <>
                <Button type="button" variant="primary-inverse" onClick={handleClickPreviousStep} size="sm">
                  Back
                </Button>

                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleClickNextStep}
                  Icon={ArrowUpRightIcon}
                  iconRight
                  disabled={isCreating}
                >
                  Finish Set Up
                </Button>
              </>
            }
          >
            <ReviewForm
              publicationSubdomain={publication.subdomain}
              shouldUseDefaultWebDomain={shouldUseDefaultWebDomain}
              webDomain={webDomain || ''}
              redirectDomainValue={redirectToWWW ? getRedirectDomainValue(webDomain as string) : undefined}
              redirectToWWW={redirectToWWW}
              redirectToDomain={redirectToDomain}
              shouldRedirectToDomain={shouldRedirectToDomain}
              shouldUseCustomEmailDomain={shouldUseCustomEmailDomain}
              emailDomain={emailDomain}
              shouldUseWebDomainAsEmailDomain={shouldUseWebDomainAsEmailDomain}
              shouldUseDefaultEmailDomain={shouldUseDefaultEmailDomain}
              setupMethod={setupMethod}
              setSetupMethod={setSetupMethod}
              isEntriAvailable={isEntriAvailable}
              isSetupMethodModalOpen={isSetupMethodModalOpen}
              setIsSetupMethodModalOpen={setIsSetupMethodModalOpen}
              customDomainIds={customDomainIds}
              isCreating={isCreating}
              startManualVerification={startManualVerification}
              hideWebSection
            />
          </FormStep>
        </TwoColumnPageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default NewEmailDomainForm;
