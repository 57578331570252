import { Link } from 'react-router-dom';
import moment from 'moment-mini';

import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import useReports from '@/hooks/useAdNetwork/publisher/useReports';
import { AdNetworkDisbursementStatusLabelHash } from '@/interfaces/ad_network/internal/disbursement/types';
import { AdNetworkReport } from '@/interfaces/ad_network/publisher/report';
import { Button } from '@/ui/Button';

import AdNetworkTabNavigation from '../_components/AdNetworkTabNavigation';

interface Props {
  reports: AdNetworkReport[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

const MonetizeAdsReports = ({ reports, hasNextPage, fetchNextPage }: Props) => {
  if (!reports.length) {
    return (
      <div className="py-4">
        <p className="text-gray-600 text-sm">No reports available yet, check back later.</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <PageHeading title="Advertisements" description="Seamlessly monetize your newsletter with premium sponsors." />
      <AdNetworkTabNavigation />
      <div className="bg-white rounded border border-gray-200 overflow-auto">
        <table className="min-w-full table-auto">
          <thead className="border-b border-gray-200">
            <tr className="border-b border-gray-200">
              <th scope="col" className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
                Post
              </th>
              <th scope="col" className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
                Advertiser
              </th>
              <th scope="col" className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
                Date
              </th>
              <th scope="col" className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
                Clicks
              </th>
              <th scope="col" className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
                Opens
              </th>
              <th scope="col" className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
                Earnings
              </th>
              <th scope="col" className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
                Status
              </th>
              <th scope="col" className="px-4 py-2 text-left text-sm font-semibold text-gray-900">
                Payment Date
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">View report</span>
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Invoice</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {reports.map((report) => (
              <tr key={report.id}>
                <td className="p-4 text-sm font-medium text-gray-900">
                  <Link to={`/posts/${report.post_id}`} className="text-feedback-info-500 hover:underline max-w-xs">
                    {report.post_web_title}
                  </Link>
                </td>
                <td className="p-4 text-sm font-medium text-gray-900">
                  <Link to={`/posts/${report.post_id}`} className="text-feedback-info-500 hover:underline max-w-xs">
                    {report.advertiser_name}
                  </Link>
                </td>
                <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                  {moment(report.window_opened_at).format('MMM DD, YYYY')}
                </td>
                <td className="whitespace-nowrap p-4 text-sm text-gray-500">{report.disbursement_approved_clicks}</td>
                <td className="whitespace-nowrap p-4 text-sm text-gray-500">{report.disbursement_approved_opens}</td>
                {report.disbursement_approved_amount !== report.disbursement_amount ? (
                  <td className="whitespace-nowrap p-4 text-sm text-gray-500">{report.disbursement_approved_amount}</td>
                ) : (
                  <td className="whitespace-nowrap p-4 text-sm text-gray-500">{report.disbursement_amount}</td>
                )}
                <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                  {AdNetworkDisbursementStatusLabelHash[report.disbursement_status]}
                </td>
                <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                  {moment(report.expected_payment_date).format('MMM DD, YYYY')}
                </td>
                <td className="relative whitespace-nowrap p-4 text-right text-sm font-medium">
                  <Button to={`/monetize/ads/reports/${report.id}`} variant="primary-inverse" size="xs">
                    View report
                  </Button>
                </td>
                <td className="relative whitespace-nowrap p-4 text-right text-sm font-medium">
                  {report.disbursement_status === 'paid' && (
                    <Button to={`/monetize/ads/reports/${report.id}/remittance`} variant="primary-inverse" size="xs">
                      View Remittance
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full">
        <span className="text-xs float-right">
          Need other documents? Reach out to{' '}
          <a href="mailto:brian.schneider@beehiiv.com">brian.schneider@beehiiv.com</a>
        </span>
      </div>
      {hasNextPage && (
        <Button variant="primary-inverse" onClick={() => fetchNextPage()}>
          Load More
        </Button>
      )}
    </div>
  );
};

export default function Loader() {
  const { data, isSuccess, hasNextPage, fetchNextPage } = useReports();

  if (!isSuccess) return null;

  const reports = data?.pages.flatMap((page) => page.reports);

  return <MonetizeAdsReports reports={reports} hasNextPage={!!hasNextPage} fetchNextPage={fetchNextPage} />;
}
