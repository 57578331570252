import { LoadingSpinner } from '@/components/LoadingSpinner';

const EditorLoader = () => {
  return (
    <div className="flex items-center justify-center h-full w-full bg-white shadow-md">
      <LoadingSpinner />
    </div>
  );
};

export default EditorLoader;
