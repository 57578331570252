import moment from 'moment-mini';

import { AdPortalCampaign } from '@/ad_portal/interfaces/campaign';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { currencyFormatter, valueFormatter } from '@/utils/formatters';

import StatusBadge from './StatusBadge';

interface CampaignListItemProps {
  campaign: AdPortalCampaign;
}

const CampaignListItem = ({ campaign }: CampaignListItemProps) => {
  const formattedTotalSpendCents = currencyFormatter(campaign.total_spend_cents / 100, 'usd', 2, 2);
  const formattedCostPerClickCents = currencyFormatter(campaign.cost_per_click_cents / 100, 'usd', 2, 2);
  const formattedStartDate = moment(campaign.start_date).format('LL');
  const formattedEndDate = campaign.end_date ? moment(campaign.end_date).format('LL') : 'N/A';

  return (
    <ItemRow className="bg-white">
      <ItemColumn align={AlignType.LEFT}>
        <Typography weight="medium" size="sm" colorWeight="700">
          {campaign.name}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <StatusBadge status={campaign.status} />
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm">{formattedStartDate}</Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm">{formattedEndDate}</Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm">{formattedTotalSpendCents}</Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm">{valueFormatter(campaign.clicks_goal)}</Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm">{formattedCostPerClickCents}</Typography>
      </ItemColumn>
    </ItemRow>
  );
};

export default CampaignListItem;
