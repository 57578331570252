import { useCallback, useMemo } from 'react';
import { useEditorState } from '@tiptap/react';

import { useCurrentPublication } from '@/hooks';

import { cn } from '../../../../_utils/cn';
import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';
import { isInternalLink } from '../utils/isInternalLink';

import { BooleanSettings } from './BooleanSettings';

export const NewTabSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { data: currentPublication } = useCurrentPublication();

  const { href, target } = useEditorState<{ href: string; target: string }>({
    editor,
    selector: ({ editor: e }) => ({
      href: e.getAttributes('link').href,
      target: e.getAttributes('link').target || '_self',
    }),
  });

  const isExternalLink = useMemo(() => {
    if (!href) return false;
    return !isInternalLink(currentPublication?.url || '', href);
  }, [currentPublication?.url, href]);

  const updateTarget = useCallback(
    (isNewTab: boolean) => {
      editor
        .chain()
        .extendMarkRange('link')
        .setLink({ href, target: isNewTab ? '_blank' : '_self' })
        .focus()
        .run();
    },
    [editor, href]
  );

  return (
    <Tooltip center="Custom external links must open in a new tab" delay={300}>
      <div className={cn(isExternalLink ? 'opacity-50 pointer-events-none' : '')}>
        <BooleanSettings
          isExternalLink={isExternalLink}
          editor={editor}
          activeNodeResult={activeNodeResult}
          defaultValue={isExternalLink ? true : target === '_blank'}
          title="New Tab"
          onChange={updateTarget}
        />
      </div>
    </Tooltip>
  );
};
