import { AlignCenterHorizontal, AlignLeft, AlignRight } from '@phosphor-icons/react';

import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AdvancedWidthSettings } from '../AdvancedWidthSettings';
import { AlignmentSettings } from '../AlignmentSettings';
import BorderSettings from '../BorderSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SimpleNumberSettings } from '../SimpleNumberSettings';

import GroupedSettings from './GroupedSettings';

export const SocialsSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['socials']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Layout">
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <AlignmentSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Alignment"
          property="alignment"
          icons={{
            left: AlignLeft,
            center: AlignCenterHorizontal,
            right: AlignRight,
          }}
        />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <AdvancedWidthSettings editor={editor} activeNodeResult={activeNodeResult} property="width" />
      </SectionRenderer>

      <SectionRenderer title="Icons">
        <SimpleNumberSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="iconSize"
          title="Size"
          unit="px"
        />
        <SimpleNumberSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="iconPadding"
          title="Padding"
          unit="px"
        />
        <GroupedSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
