/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */

import { FC, Fragment, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import { EllipsisDropdownOption } from '../../interfaces/general';
import Tooltip from '../../ui/Tooltip';

interface Props {
  options?: EllipsisDropdownOption[];
  portalMountedId?: string;
  children?: React.ReactNode;
  disabled?: boolean;
}

const EllipsisDropdown: FC<Props> = (props: Props) => {
  const popperElRef = useRef(null);
  const targetElement = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(targetElement?.current, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [14, 8],
        },
      },
    ],
  });

  const { options = [], portalMountedId, children, disabled } = props;

  const filteredOptions = options.filter((option) => option.isVisible !== false);

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div className="flex items-center" ref={targetElement}>
            <Menu.Button disabled={disabled}>
              {children ?? (
                <span
                  className={cx(
                    'flex items-center justify-center w-4 h-4 p-1 box-content bg-transparent border border-gray-200 rounded-md text-gray-700',
                    'hover:bg-gray-100',
                    disabled && 'cursor-not-allowed text-surface-400'
                  )}
                >
                  <EllipsisVerticalIcon className="w-3 h-3" aria-hidden="true" />
                </span>
              )}
            </Menu.Button>
          </div>

          <Portal node={portalMountedId && document ? document.getElementById(portalMountedId) : null}>
            <div ref={popperElRef} className="z-50" style={styles.popper} {...attributes.popper}>
              <Transition
                as={Fragment}
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
              >
                <Menu.Items className="origin-top-right z-10 absolute right-0 w-56 rounded-md overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {filteredOptions?.map((option) => (
                    <Menu.Item key={option.label}>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={() => option.onClick()}
                          disabled={option.disabled || false}
                          className={cx(
                            // eslint-disable-next-line no-nested-ternary
                            option.isDestructive
                              ? 'text-feedback-danger-600 border-t border-surface-100'
                              : active
                                ? 'text-gray-900'
                                : 'text-gray-700',
                            active && 'bg-gray-100',
                            'block gap-y-1 px-4 py-2 text-sm text-left w-full',
                            'disabled:opacity-50 disabled:cursor-not-allowed',
                            option.labelClass
                          )}
                        >
                          <Tooltip
                            tooltipClass="text-center"
                            id={uuid()}
                            key={option.label}
                            text={option.tooltip ? option.tooltip : ''}
                            showIcon={false}
                            autoWidth
                            isEnabled={option.disabled}
                            childrenContainerClass="flex w-full"
                          >
                            <div className="flex-col space-y-1">
                              <div className="flex justify-start items-center space-x-3">
                                <span>{option.label}</span>

                                {option.pinging && (
                                  <span className="relative flex h-2 w-2">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75" />
                                    <span className="relative inline-flex rounded-full h-2 w-2 bg-amber-300" />
                                  </span>
                                )}
                              </div>
                              {option.description ? (
                                <span className={cx('font-normal text-xs text-gray-400')}>{option.description}</span>
                              ) : null}
                            </div>
                          </Tooltip>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Menu>
  );
};

export default EllipsisDropdown;
