import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CloseButton } from '../../UI/Button';

import DynamicAuthorPage from './KnowledgeTypes/DynamicAuthorPage';
import DynamicContentSurveys from './KnowledgeTypes/DynamicContentSurveys';
import DynamicContentTagPage from './KnowledgeTypes/DynamicContentTagPage';
import PlaceholderData from './KnowledgeTypes/PlaceholderData';
import KnowledgeTag from './KnowledgeTag';

const preventPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

const KNOWLEDGE_PANEL_TYPES = {
  placeholder_data: PlaceholderData,
  dynamic_content_surveys: DynamicContentSurveys,
  dynamic_content_tags: DynamicContentTagPage,
  dynamic_author_page: DynamicAuthorPage,
};

const TITLES = {
  placeholder_data: 'Placeholder Data',
  dynamic_content_surveys: 'Surveys Page',
  dynamic_content_tags: 'Tag Page',
  dynamic_author_page: 'Author Page',
};

export const KnowledgePanel = memo(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const LearnMoreComponent =
    KNOWLEDGE_PANEL_TYPES[searchParams.get('learn_more') as keyof typeof KNOWLEDGE_PANEL_TYPES];
  const title = TITLES[searchParams.get('learn_more') as keyof typeof TITLES];

  const handleClose = () => {
    searchParams.delete('learn_more');
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col p-3" onClick={preventPropagation} role="none">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <KnowledgeTag title={title} />
          <CloseButton onClose={handleClose} className="absolute top-6 right-6 bg-white hover:bg-wb-secondary" />
        </div>

        {LearnMoreComponent && <LearnMoreComponent />}
      </div>
    </div>
  );
});
