import { Extension } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';

import { getStringAttribute } from '../../../utils/attributesUtils';
import { WHITE_COLOR } from '../../constants';

interface BackgroundColorOptions {
  nodesToAddBackgroundColorAttributeTo: PMNode['type']['name'][];
}

interface BackgroundColorStorage {}

export const BackgroundColorAttribute = Extension.create<BackgroundColorOptions, BackgroundColorStorage>({
  name: 'backgroundColorAttribute',

  addOptions() {
    return {
      nodesToAddBackgroundColorAttributeTo: [
        'login',
        'loginModal',
        'recommendations',
        'recommendationsModal',
        'resetPassword',
        'signupModal',
        'tags',
        'testimonials',
        'updatePassword',
      ],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.nodesToAddBackgroundColorAttributeTo,
        attributes: {
          backgroundColor: getStringAttribute('backgroundColor', 'data-background-color', WHITE_COLOR, WHITE_COLOR),
        },
      },
    ];
  },
});
