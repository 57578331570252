import { Extension } from '@tiptap/core';

import { getStringAttribute } from '../../../utils/attributesUtils';
import { MARKS_NAMES, NODES_NAMES } from '../../allExtensions';

export const AppliedThemeId = Extension.create({
  name: 'appliedThemeId',

  addOptions() {
    return {
      themeRules: {},
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: [...NODES_NAMES, ...MARKS_NAMES],
        attributes: {
          appliedThemeId: getStringAttribute('appliedThemeId', 'data-applied-theme-id', null),
        },
      },
    ];
  },
});
