import { CustomDomain, CustomDomainTypes, DnsRecord, EntriConfiguration } from '@/interfaces/custom_domain';

import { BRANDED_LINK_STATUS_TEXT, DnsRecordsModalStep } from './components/constants';

export const getDomainForType = (type: CustomDomainTypes, steps: DnsRecordsModalStep[]): CustomDomain | null => {
  switch (type) {
    case CustomDomainTypes.EMAIL:
      return steps.find((step) => step.type === CustomDomainTypes.EMAIL)?.customDomain || null;
    case CustomDomainTypes.BRANDED_LINK:
      return steps.find((step) => step.type === CustomDomainTypes.BRANDED_LINK)?.customDomain || null;
    case CustomDomainTypes.WEB:
      return steps.find((step) => step.type === CustomDomainTypes.WEB)?.customDomain || null;
    case CustomDomainTypes.REDIRECT:
      return steps.find((step) => step.type === CustomDomainTypes.REDIRECT)?.customDomain || null;
    default:
      return null;
  }
};

export const isDomainVerified = (customDomain: CustomDomain | undefined | null, type: CustomDomainTypes): boolean => {
  if (!customDomain) {
    return false;
  }

  switch (type) {
    case 'email':
      return customDomain.email_enabled && !customDomain.email_verification_pending;
    case 'branded_link':
      return customDomain.branded_link_verified && customDomain.branded_link_cloudflare_verified;
    case 'web':
      return customDomain.web_enabled && !customDomain.web_verification_pending;
    case 'redirect':
      return customDomain.web_redirect_enabled && !customDomain.web_redirect_verification_pending;
    default:
      return false;
  }
};

export const isSetupUsingEntri = (customDomain: CustomDomain | null, type: CustomDomainTypes): boolean => {
  const entriConfiguration = customDomain?.entri_configuration;

  if (!entriConfiguration) {
    return false;
  }

  switch (type) {
    case 'email':
      return entriConfiguration.email_enabled;
    case 'branded_link':
      return entriConfiguration.branded_link_enabled;
    case 'web':
      return entriConfiguration.web_enabled;
    case 'redirect':
      return entriConfiguration.web_redirect_enabled;
    default:
      return false;
  }
};

export const isEntriPending = (customDomain: CustomDomain | null, type: CustomDomainTypes): boolean => {
  const entriConfiguration = customDomain?.entri_configuration;

  if (!entriConfiguration) {
    return false;
  }

  switch (type) {
    case 'email':
      return entriConfiguration.email_pending;
    case 'branded_link':
      return entriConfiguration.branded_link_cloudflare_pending || entriConfiguration.branded_link_pending;
    case 'web':
      return entriConfiguration.web_pending;
    case 'redirect':
      return entriConfiguration.web_redirect_pending;
    default:
      return false;
  }
};

export const customDomainRecords = (customDomain: CustomDomain | null, type: CustomDomainTypes): DnsRecord[] => {
  if (!customDomain) {
    return [];
  }

  switch (type) {
    case 'email':
      return customDomain.email_authenticated_domains;
    case 'branded_link':
      if (!customDomain.branded_link_cloudflare_verified) {
        return customDomain.authenticated_branded_links;
      }

      return [customDomain.cloudflare_verified_authenticated_branded_link];
    case 'web':
      return customDomain.web_authenticated_domains;
    case 'redirect':
      return customDomain.web_authenticated_domains;
    default:
      return [];
  }
};

export const isEntriBrandedLinkPending = (entriConfiguration?: EntriConfiguration): boolean => {
  if (!entriConfiguration) return false;
  return entriConfiguration.branded_link_pending || entriConfiguration.branded_link_cloudflare_pending;
};

export const isCloudflareListItemCreating = (customDomain: CustomDomain): boolean => {
  return customDomain.branded_link_cloudflare_verified && !customDomain.branded_link_cloudflare_list_verified;
};

export const brandedLinkVerificationBadgeText = (customDomain: CustomDomain): string | null => {
  const {
    branded_link_added: added,
    branded_link_pending: pending,
    branded_link_cloudflare_enabled: cloudflareEnabled,
    entri_configuration: entriConfiguration,
  } = customDomain;

  if (!added) {
    return BRANDED_LINK_STATUS_TEXT.SETUP_AVAILABLE;
  }

  const entriPending = isEntriBrandedLinkPending(entriConfiguration);
  const isCreatingCloudflareListItem = isCloudflareListItemCreating(customDomain);

  if (pending || entriPending || isCreatingCloudflareListItem) {
    return entriPending ? BRANDED_LINK_STATUS_TEXT.ENTRI_VERIFYING : BRANDED_LINK_STATUS_TEXT.VERIFYING;
  }

  if (!pending && !cloudflareEnabled && !isCreatingCloudflareListItem) {
    return BRANDED_LINK_STATUS_TEXT.ACTION_REQUIRED;
  }

  return null;
};

export const shouldShowBrandedLinkPing = (customDomain: CustomDomain): boolean => {
  const {
    branded_link_added: added,
    branded_link_pending: pending,
    branded_link_cloudflare_enabled: cloudflareEnabled,
    branded_link_cloudflare_verified: cloudflareVerified,
    branded_link_cloudflare_list_verified: cloudflareListVerified,
    email_enabled: emailEnabled,
    email_verification_pending: emailVerificationPending,
    entri_configuration: entriConfiguration,
  } = customDomain;

  // Don't show ping if email setup isn't complete
  if (!emailEnabled || emailVerificationPending) return false;

  // Show ping if branded link setup is available and not started
  if (!added) return true;

  const entriPending = isEntriBrandedLinkPending(entriConfiguration);
  const isCreatingCloudflareListItem = isCloudflareListItemCreating(customDomain);

  // Don't show ping while any verification is in progress
  if (pending || entriPending || isCreatingCloudflareListItem) return false;

  // Show ping when action is required (not fully verified and not in progress)
  return !cloudflareEnabled || !cloudflareVerified || !cloudflareListVerified;
};
