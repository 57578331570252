import React from 'react';
import {
  ArrowsOutLineVertical,
  Bell,
  // ClipboardText,
  CurrencyCircleDollar,
  HardDrive,
  HardDrives,
  Image as ImageIcon,
  // ListBullets,
  ListChecks,
  // ListNumbers,
  Quotes,
  RectangleDashed,
  Slideshow,
  Square,
  SquareSplitHorizontal,
  Tag,
  Textbox,
  TextHFive,
  TextHFour,
  TextHOne,
  TextHSix,
  TextHThree,
  TextHTwo,
  TextT,
  Users,
  XLogo,
} from '@phosphor-icons/react';

import { selectThreeRandomAuthors } from '../dummyData/authors';
import { selectRandomPosts } from '../dummyData/posts';
import { tiers } from '../dummyData/pricing';
import {
  RECOMMENDATION_1,
  RECOMMENDATION_2,
  RECOMMENDATION_3,
  RECOMMENDATION_4,
  RECOMMENDATION_5,
  RECOMMENDATION_6,
} from '../dummyData/recommendations';
import { SOCIALS } from '../dummyData/socials';
// import { survey } from '../dummyData/survey';
import { DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG } from '../dummyData/tags';
import { TESTIMONIALS } from '../dummyData/testimonials';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Authors,
  // BulletList,
  Button,
  Columns,
  Container,
  Divider,
  Heading,
  Icon,
  Image,
  // ListItem,
  // OrderedList,
  Paragraph,
  Post,
  Pricing,
  Recommendations,
  Section,
  Signup,
  Socials,
  // Survey,
  Tags,
  Testimonials,
} from '../extensions';

export const LABELS_BY_CATEGORY: Record<string, string> = {
  templates: 'Templates',
  layout: 'Layout',
  widget: 'Widgets',
  text: 'Text',
};

export const components = {
  paragraph: {
    label: 'Text',
    category: 'text',
    Icon: TextT,
    block: () => ({
      type: Paragraph.name,
      attr: {
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'paragraph',
          marks: [
            {
              type: 'textStyle',
              attrs: {
                lineHeight: '120%',
                fontWeight: '400',
                fontSize: '16px',
              },
            },
          ],
        },
      ],
    }),
  },
  heading1: {
    label: 'Head 1',
    category: 'text',
    Icon: TextHOne,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 1,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 1',
          marks: [
            {
              type: 'textStyle',
              attrs: {
                lineHeight: '120%',
                fontSize: '32px',
                fontWeight: '700',
              },
            },
          ],
        },
      ],
    }),
  },
  heading2: {
    label: 'Head 2',
    category: 'text',
    Icon: TextHTwo,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 2,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 2',
          marks: [
            {
              type: 'textStyle',
              attrs: {
                lineHeight: '120%',
                fontSize: '30px',
                fontWeight: '700',
              },
            },
          ],
        },
      ],
    }),
  },
  heading3: {
    label: 'Head 3',
    category: 'text',
    Icon: TextHThree,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 3,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 3',
          marks: [
            {
              type: 'textStyle',
              attrs: {
                fontSize: '24px',
                lineHeight: '120%',
                fontWeight: '700',
              },
            },
          ],
        },
      ],
    }),
  },
  heading4: {
    label: 'Head 4',
    category: 'text',
    Icon: TextHFour,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 4,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 4',
          marks: [
            {
              type: 'textStyle',
              attrs: {
                fontSize: '22px',
                lineHeight: '120%',
                fontWeight: '600',
                color: '#98A2B3',
              },
            },
          ],
        },
      ],
    }),
  },
  heading5: {
    label: 'Head 5',
    category: 'text',
    Icon: TextHFive,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 5,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 5',
          marks: [
            {
              type: 'textStyle',
              attrs: {
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '120%',
                color: '#98A2B3',
              },
            },
          ],
        },
      ],
    }),
  },
  heading6: {
    label: 'Head 6',
    category: 'text',
    Icon: TextHSix,
    block: () => ({
      type: Heading.name,
      attrs: {
        level: 6,
        textAlign: 'center',
      },
      content: [
        {
          type: 'text',
          text: 'Heading 6',
          marks: [
            {
              type: 'textStyle',
              attrs: {
                fontSize: '16px',
                fontWeight: '400',
                color: '#98A2B3',
                lineHeight: '120%',
              },
            },
          ],
        },
      ],
    }),
  },

  section: {
    label: 'Section',
    category: 'layout',
    Icon: RectangleDashed,
    block: () => ({
      type: Section.name,
      attrs: {
        innerWidthMax: '1280px',
      },
    }),
  },
  container: {
    label: 'Container',
    category: 'layout',
    Icon: Square,
    block: () => ({
      type: Container.name,
      attrs: {},
    }),
  },
  columns: {
    label: 'Columns',
    category: 'layout',
    Icon: SquareSplitHorizontal,
    block: () => ({
      type: Columns.name,
      attrs: {
        width: '100%',
        padding: '20px',
      },
      content: [
        {
          type: Container.name,
          attrs: {
            flexBasis: '50%',
          },
          content: [],
        },
        {
          type: Container.name,
          attrs: {
            flexBasis: '50%',
          },
          content: [],
        },
      ],
    }),
  },
  divider: {
    label: 'Divider',
    category: 'layout',
    Icon: ArrowsOutLineVertical,
    block: () => ({
      type: Divider.name,
      attrs: {},
      content: [],
    }),
  },
  // Commenting Out in favor of just having bullets and numbers work within headins + paragraphs.
  // numberedList: {
  //   label: 'Numbered List',
  //   category: 'lists',
  //   Icon: ListNumbers,
  //   block: () => ({
  //     type: OrderedList.name,
  //     attrs: {},
  //     content: [
  //       {
  //         type: ListItem.name,
  //         attrs: {},
  //         content: [
  //           {
  //             type: Paragraph.name,
  //             attrs: {},
  //             content: [
  //               {
  //                 type: 'text',
  //                 text: 'List Item 1',
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   }),
  // },
  // bulletedList: {
  //   label: 'Bullet List',
  //   category: 'lists',
  //   Icon: ListBullets,
  //   block: () => ({
  //     type: BulletList.name,
  //     attrs: {},
  //     content: [
  //       {
  //         type: ListItem.name,
  //         attrs: {},
  //         content: [
  //           {
  //             type: Paragraph.name,
  //             attrs: {},
  //             content: [
  //               {
  //                 type: 'text',
  //                 text: 'List Item 1',
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   }),
  // },
  signup: {
    label: 'Signup',
    category: 'widget',
    Icon: Textbox,
    block: () => ({
      type: Signup.name,
      attrs: {
        width: '400px',
      },
      content: [],
    }),
  },
  post: {
    label: 'Post',
    category: 'widget',
    Icon: Slideshow,
    block: () => ({
      type: Post.name,
      attrs: {
        width: 'full',
        cardCount: 3,
        gap: 8,
        orientation: 'horizontal',

        data: {
          posts: selectRandomPosts({ count: 3 }),
        },
      },
      content: [],
    }),
  },
  media: {
    label: 'Image',
    Icon: ImageIcon,
    category: 'widget',
    block: () => ({
      type: Image.name,
      attrs: {},
      content: [],
    }),
  },
  button: {
    label: 'Button',
    category: 'widget',
    Icon: HardDrive,
    block: () => ({
      type: Button.name,
      content: [
        {
          type: 'text',
          text: 'Subscribe',
          marks: [
            {
              type: 'textStyle',
              attrs: {
                fontSize: '16px',
              },
            },
          ],
        },
      ],
    }),
  },
  icon: {
    label: 'Icon',
    category: 'widget',
    Icon: Bell,
    block: () => ({
      type: Icon.name,
      attrs: {
        icon: {
          iconName: 'Bell',
          iconType: 'phosphor',
        },
      },
    }),
  },
  authors: {
    label: 'Authors',
    category: 'widget',
    Icon: Users,
    block: () => ({
      type: Authors.name,
      attrs: {
        data: {
          authors: selectThreeRandomAuthors({ count: 3 }),
        },
      },
      content: [],
    }),
  },
  tags: {
    label: 'Tags',
    Icon: Tag,
    category: 'widget',
    block: () => ({
      type: Tags.name,
      attrs: {
        src: '',
        data: [DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG],
      },
      content: [],
    }),
  },
  socials_complete: {
    label: 'Socials',
    category: 'widget',
    Icon: XLogo,
    block: () => ({
      type: Socials.name,
      attrs: {
        data: SOCIALS,
        padding: '10px',
      },
      content: [],
    }),
  },

  recommendations: {
    label: 'Recommendations',
    category: 'widget',
    Icon: ListChecks,
    block: () => ({
      type: Recommendations.name,
      attrs: {
        data: [
          RECOMMENDATION_1,
          RECOMMENDATION_2,
          RECOMMENDATION_3,
          RECOMMENDATION_4,
          RECOMMENDATION_5,
          RECOMMENDATION_6,
        ],
      },
      content: [],
    }),
  },
  testimonials: {
    label: 'Testimonials',
    category: 'widget',
    Icon: Quotes,
    block: () => ({
      type: Testimonials.name,
      attrs: {
        data: TESTIMONIALS,
      },
    }),
  },
  pricing: {
    label: 'Pricing Cards',
    category: 'widget',
    Icon: CurrencyCircleDollar,
    block: () => ({
      type: Pricing.name,
      attrs: {
        data: tiers,
        insertedFromSidebar: true,
        hasFakeData: true,
      },
    }),
  },
  accordion: {
    label: 'Accordion',
    category: 'widget',
    Icon: HardDrives,
    block: () =>
      new Array(2).fill(0).map(() => ({
        type: Accordion.name,
        attrs: {},
        content: [
          {
            type: AccordionTitle.name,
            attrs: {},
            content: [
              {
                type: Paragraph.name,
                content: [
                  {
                    type: 'text',
                    text: 'Title',
                  },
                ],
              },
            ],
          },
          {
            type: AccordionContent.name,
            attrs: {},
            content: [
              {
                type: Paragraph.name,
                content: [
                  {
                    type: 'text',
                    text: 'Content',
                  },
                ],
              },
            ],
          },
        ],
      })),
  },
  html: {
    label: 'HTML',
    category: 'widget',
    tooltip: 'Coming soon',
    Icon: () => {
      return React.createElement(
        'div',
        {
          className: 'pointer-events-none opacity-50',
          title: 'Coming soon',
          style: { userSelect: 'none' },
        },
        '</>'
      );
    },
    block: () => ({
      type: 'html',
      attrs: {},
      content: [],
    }),
    draggable: false,
  },
};
