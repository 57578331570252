import { useState } from 'react';
import toast from 'react-hot-toast';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import useUpdateBoostAgreement from '@/hooks/boosts/grow/useUpdateBoostAgreement';
import useBoostAgreementCanReactivate from '@/hooks/boosts/useBoostAgreementCanReactivate';
import { BoostAgreementCreateWithReferralCount, BoostAgreementStatus } from '@/interfaces/boosts/grow/boost_agreement';
import { EllipsisDropdownOption } from '@/interfaces/general';
import Tooltip from '@/ui/Tooltip';

import ReportPublicationModal from './ReportPublicationModal';

interface Props {
  boostAgreement: BoostAgreementCreateWithReferralCount;
  onSuccess: () => void;
  canReport?: boolean;
}

const BoostAgreementActionsDropdown = ({ boostAgreement, onSuccess, canReport = true }: Props) => {
  const [showReportModal, setShowReportModal] = useState(false);

  const { status, id, boost_offer: boostOffer } = boostAgreement;
  const canReactivateQuery = useBoostAgreementCanReactivate(id);
  const canReactivate = canReactivateQuery.data?.can_reactivate ?? false;

  const { mutateAsync: updateBoostAgreement } = useUpdateBoostAgreement({
    boostOfferId: boostOffer.id,
    onSuccess: () => {
      onSuccess?.();
      toast.success('Boost agreement updated.');
    },
  });

  const handleTransition = (boostAgreementId: string, transition_to: BoostAgreementStatus) => {
    updateBoostAgreement({ boostAgreementId, transition_to });
  };

  const areActionsDisabled = boostAgreement.is_reported === true;

  const actions: EllipsisDropdownOption[] = [];

  if (status === BoostAgreementStatus.ACTIVE) {
    actions.push({
      label: 'Pause',
      onClick: () => handleTransition(id, BoostAgreementStatus.PAUSED),
    });
  } else if (status === BoostAgreementStatus.INACTIVE) {
    actions.push({
      label: 'Activate',
      isVisible: canReactivate,
      onClick: () => handleTransition(id, BoostAgreementStatus.ACTIVE),
    });
    actions.push({
      label: 'Archive',
      onClick: () => handleTransition(id, BoostAgreementStatus.ARCHIVED),
    });
  } else if (status === BoostAgreementStatus.PAUSED) {
    actions.push({
      label: 'Resume',
      onClick: () => handleTransition(id, BoostAgreementStatus.ACTIVE),
    });
    actions.push({
      label: 'Deactivate',
      onClick: () => handleTransition(id, BoostAgreementStatus.INACTIVE),
    });
  }

  if (canReport) {
    actions.push({
      label: 'Report',
      onClick: () => setShowReportModal(true),
    });
  }

  const handleClickConfirm = async () => {
    setShowReportModal(false);
    onSuccess();
  };

  if (actions.length === 0) {
    return null;
  }

  // Pending, Rejected, and Archived states have no actions from this page.
  return (
    <>
      <ReportPublicationModal
        isVisible={showReportModal}
        boostAgreementId={boostAgreement.id}
        recommendingPublication={boostAgreement.recommending_publication}
        onConfirm={handleClickConfirm}
        onClose={() => setShowReportModal(false)}
      />
      <Tooltip
        tooltipClass="text-center"
        id={`disabled-boost-actions-tooltip-${boostAgreement.id}`}
        text="You have reported the Publication"
        showIcon={false}
        autoWidth={false}
        isEnabled={areActionsDisabled}
      >
        <EllipsisDropdown disabled={areActionsDisabled} options={actions} />
      </Tooltip>
    </>
  );
};

export default BoostAgreementActionsDropdown;
