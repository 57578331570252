import { useEffect, useRef, useState } from 'react';

import { cn } from '../../../../_utils/cn';
import { AllBorderIcon, BorderIcon, PaddingIcon } from '../../../Icons/BorderIcons';
import { Slider } from '../../../UI/Slider';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';

const BorderRadiusInput = ({
  defaultValue,
  onChange,
  rightElement = '',
  rightElementClassName,
}: {
  defaultValue: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rightElement?: React.ReactNode;
  rightElementClassName?: string;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [defaultVal, setDefaultVal] = useState(defaultValue);

  useEffect(() => {
    setDefaultVal(defaultValue);
  }, [defaultValue]);

  return (
    <div className="relative h-[32px] flex items-center bg-wb-secondary rounded-lg border-none p-2 flex-1 min-w-0">
      <input
        ref={inputRef}
        className="bg-transparent p-0 outline-none focus:ring-0 border-none text-xs flex-1 w-full max-w-[40px]"
        type="number"
        min={0}
        value={defaultVal}
        onChange={onChange}
        onFocus={() => {
          inputRef.current?.select();
        }}
      />
      {rightElement && <div className={cn(rightElementClassName)}>{rightElement}</div>}
    </div>
  );
};

// We may want to add a slider for the border radius in the future
// const BorderRadiusSlider = ({
//   initialValue,
//   onChange,
// }: {
//   initialValue: number;
//   onChange: (value: number) => void;
// }) => {
//   const [value, setValue] = useState(initialValue);
//   const [isMounted, setIsMounted] = useState(false);

//   useEffect(() => {
//     if (initialValue && !isMounted) {
//       setValue(initialValue);
//       setIsMounted(true);
//     }
//   }, [initialValue, isMounted]);

//   return (
//     <div className="flex flex-col gap-2">
//       <BorderRadiusInput
//         value={value}
//         onChange={(e) => {
//           setValue(Number(e.target.value));
//         }}
//         rightElement={
//           <Text variant="secondary" size="2xs" weight="medium">
//             px
//           </Text>
//         }
//         rightElementClassName="absolute top-1 right-3"
//       />
//       <Slider
//         defaultValue={[value]}
//         max={100}
//         step={1}
//         onValueChange={(newValue) => setValue(newValue[0])}
//         className="w-full"
//         onValueCommit={() => {
//           onChange(value);
//         }}
//       />
//     </div>
//   );
// };

interface BoxModelToggleProps {
  title: string;
  defaultValues: {
    top: number;
    right: number;
    bottom: number;
    left: number;
    maxTopBottom?: number;
    maxLeftRight?: number;
  };
  suffixes: {
    top: string | React.ReactNode;
    right: string | React.ReactNode;
    bottom: string | React.ReactNode;
    left: string | React.ReactNode;
    topBottom?: string | React.ReactNode;
    leftRight?: string | React.ReactNode;
  };
  type?: 'corners' | 'sides';
  showCombinedValues?: boolean;
  onUpdate: (
    position: 'top' | 'right' | 'bottom' | 'left' | 'all' | 'top-bottom' | 'left-right' | any,
    value: number
  ) => void;
}

const BoxModelToggle = ({
  title,
  defaultValues,
  suffixes,
  onUpdate,
  showCombinedValues = true,
  type = 'corners',
}: BoxModelToggleProps) => {
  const [toggleValue, setToggleValue] = useState<'all' | 'sides'>(
    defaultValues.top === defaultValues.right &&
      defaultValues.right === defaultValues.bottom &&
      defaultValues.bottom === defaultValues.left
      ? 'all'
      : 'sides'
  );
  const isAll = toggleValue === 'all';

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-stretch gap-2">
        <Text className="min-w-[80px] w-[80px] cursor-pointer" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <div className="flex items-center gap-2 w-full max-w-[153px]">
          {isAll && (
            <BorderRadiusInput
              defaultValue={defaultValues.top}
              onChange={(e) => onUpdate('all', Number(e.target.value))}
            />
          )}
          <div className="flex-1 bg-wb-secondary rounded-lg shadow-sm min-w-[80px]">
            <ToggleGroup
              className="p-[2px]"
              type="single"
              defaultValue="all"
              value={toggleValue}
              onValueChange={() => {}}
            >
              <ToggleGroupItem value="all" className="grow" onClick={() => setToggleValue('all')}>
                <AllBorderIcon />
              </ToggleGroupItem>
              <ToggleGroupItem value="sides" className="grow" onClick={() => setToggleValue('sides')}>
                {type === 'corners' ? <BorderIcon /> : <PaddingIcon />}
              </ToggleGroupItem>
            </ToggleGroup>
          </div>
        </div>
      </div>

      {!isAll && (
        <div>
          <div className="flex items-center justify-between gap-2">
            <div className="flex-1" />
            <div className="w-[153px] gap-2 flex flex-col">
              <div className="grid grid-cols-2 px-0.5 gap-2">
                <BorderRadiusInput
                  defaultValue={defaultValues.top}
                  onChange={(e) => onUpdate('top', Number(e.target.value))}
                  rightElement={
                    <Text variant="secondary" size="3xs" weight="medium">
                      {suffixes.top}
                    </Text>
                  }
                />
                <BorderRadiusInput
                  defaultValue={defaultValues.right}
                  onChange={(e) => onUpdate('right', Number(e.target.value))}
                  rightElement={
                    <Text variant="secondary" size="3xs" weight="medium">
                      {suffixes.right}
                    </Text>
                  }
                />
                <BorderRadiusInput
                  defaultValue={defaultValues.left}
                  onChange={(e) => onUpdate('left', Number(e.target.value))}
                  rightElement={
                    <Text variant="secondary" size="3xs" weight="medium">
                      {suffixes.left}
                    </Text>
                  }
                />
                <BorderRadiusInput
                  defaultValue={defaultValues.bottom}
                  onChange={(e) => onUpdate('bottom', Number(e.target.value))}
                  rightElement={
                    <Text variant="secondary" size="3xs" weight="medium">
                      {suffixes.bottom}
                    </Text>
                  }
                />
              </div>
              {showCombinedValues && (
                <>
                  <div className="flex items-center gap-2">
                    <Text variant="secondary" size="3xs" weight="medium" className="whitespace-nowrap w-[30px]">
                      {suffixes.topBottom || `${suffixes.top} + ${suffixes.bottom}`}
                    </Text>
                    <Slider
                      defaultValue={[defaultValues.top]}
                      value={[defaultValues.top]}
                      onValueChange={(value) => {
                        onUpdate('top-bottom', value[0]);
                      }}
                      min={0}
                      max={defaultValues?.maxTopBottom || 100}
                      step={1}
                    />
                  </div>

                  <div className="flex items-center gap-2">
                    <Text variant="secondary" size="3xs" weight="medium" className="whitespace-nowrap w-[30px]">
                      {suffixes.leftRight || `${suffixes.left} + ${suffixes.right}`}
                    </Text>
                    <Slider
                      defaultValue={[defaultValues.left]}
                      value={[defaultValues.left]}
                      onValueChange={(value) => {
                        onUpdate('left-right', value[0]);
                      }}
                      min={0}
                      max={defaultValues?.maxLeftRight || 100}
                      step={1}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoxModelToggle;
