import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getUsedFonts,
  getUsedFontsFromNode,
  getUsedFontsURLs,
  NavbarSerializableNode,
  SerializableNode,
} from '@shared/dream-components';
import { JSONContent, useEditorState } from '@tiptap/react';

import { useDreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext } from '@/context/website-context';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';
import useSiteTemplate from '@/hooks/useSiteTemplates/useSiteTemplate';

import { useFonts } from './useFontHrefs';

type Props = {
  inPageFontFamilies?: Set<string> | null;
  navbarContent?: NavbarSerializableNode;
  footerContent?: SerializableNode;
  previewContent?: JSONContent;
};

const useEditorFonts = ({ inPageFontFamilies, navbarContent, footerContent, previewContent }: Props) => {
  const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());
  const { data: allFonts } = useFonts({ enabled: !!previewContent });

  useEffect(() => {
    if (!allFonts || !inPageFontFamilies) return;

    const inPageFontURLs = getUsedFontsURLs(inPageFontFamilies, allFonts);
    if (!inPageFontURLs) return;

    setFontHrefs((fonts) => new Set(Array.from(fonts).concat(inPageFontURLs)));
  }, [inPageFontFamilies, allFonts]);

  const getNavbarFontLinks = useCallback(async () => {
    if (!navbarContent) return;
    const renderableFonts = await getUsedFonts(navbarContent, allFonts);

    setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
  }, [navbarContent, allFonts]);

  const getFooterFontLinks = useCallback(async () => {
    if (!footerContent) return;
    const renderableFonts = await getUsedFonts(footerContent);
    setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
  }, [footerContent]);

  const getContentFontLinks = useCallback(async () => {
    if (!previewContent) return;
    const renderableFonts = await getUsedFonts(previewContent);
    setFontHrefs((fonts) => new Set(Array.from(fonts).concat(renderableFonts)));
  }, [previewContent]);

  useEffect(() => {
    getNavbarFontLinks();
  }, [navbarContent, getNavbarFontLinks]);

  useEffect(() => {
    getFooterFontLinks();
  }, [footerContent, getFooterFontLinks]);

  useEffect(() => {
    if (!previewContent) return;
    getContentFontLinks();
  }, [previewContent, getContentFontLinks]);

  return fontHrefs;
};

export const useEditorFontHrefs = () => {
  const { site, previewSiteVersion } = useWebsiteContext();
  const { editor, previewContent } = useDreamEditorContext();

  const inPageFontFamilies = useEditorState<Set<string> | undefined>({
    editor,
    selector: (state) => {
      const content = state.editor?.getJSON();

      if (!content) return undefined;

      return getUsedFontsFromNode(content);
    },
  });

  const navbarContent = previewSiteVersion ? previewSiteVersion?.navbar : site?.draft_site_version?.navbar;
  const footerContent = previewSiteVersion ? previewSiteVersion?.footer : site?.draft_site_version?.footer;

  // if templateId exists, use the template editor font hrefs
  const { templateId, sitePackageId } = useParams();
  const { data: template } = useSiteTemplate({ siteTemplateId: templateId as string });
  const { data: sitePackage } = useSitePackage({
    sitePackageId: sitePackageId || template?.site_package_id,
  });

  const props = useMemo(
    () => ({
      inPageFontFamilies,
      navbarContent: templateId || sitePackageId ? sitePackage?.navbar : navbarContent,
      footerContent: templateId || sitePackageId ? sitePackage?.footer : footerContent,
      previewContent,
    }),
    [inPageFontFamilies, navbarContent, footerContent, previewContent, templateId, sitePackageId, sitePackage]
  );

  return useEditorFonts(props);
};
