import { PropsWithChildren } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Order } from '@/interfaces/general';

import Tooltip from '../../ui/Tooltip';
import { Typography } from '../Typography';

import { ALIGN_CLASSNAME_MAP } from './constants';
import { AlignType } from './types';

type Props = PropsWithChildren<{
  isSticky?: boolean;
  isSorting?: boolean;
  orderBy?: string;
  onClick?: (orderBy: any, newDirection: Order) => void;
  currentDirection?: Order;
  tooltip?: string;
  align?: AlignType;
  sortDirectionIconDown?: JSX.Element;
  sortDirectionIconUp?: JSX.Element;
}>;

export const ItemHeader = ({
  children,
  onClick,
  currentDirection,
  orderBy,
  tooltip,
  align = AlignType.CENTER,
  isSticky = false,
  isSorting = false,
  sortDirectionIconDown,
  sortDirectionIconUp,
}: Props) => {
  const text = (
    <Typography token="font-medium/text/xs" colorWeight="500">
      {children}
    </Typography>
  );

  const handleClick = () => {
    if (orderBy && onClick) {
      onClick?.(orderBy, currentDirection === Order.ASC ? Order.DESC : Order.ASC);
    }
  };
  return (
    <th
      className={cx(isSticky && 'sticky right-0', ALIGN_CLASSNAME_MAP[align], 'first:rounded-tl-lg last:rounded-tr-lg')}
    >
      <div className="py-3 px-4 leading-3">
        <Tooltip text={tooltip || ''} isEnabled={!!tooltip} id={String(Math.random())} showIcon={false}>
          {orderBy ? (
            <button
              type="button"
              onClick={handleClick}
              className={cx(
                'w-full flex gap-x-1 items-center',
                ALIGN_CLASSNAME_MAP[align],
                (sortDirectionIconUp || sortDirectionIconDown) && align === 'right' && 'justify-end'
              )}
            >
              {text}
              {isSorting &&
                currentDirection === Order.ASC &&
                (sortDirectionIconUp || <ArrowUpIcon className="w-4 h-4" />)}
              {isSorting &&
                currentDirection === Order.DESC &&
                (sortDirectionIconDown || <ArrowDownIcon className="w-4 h-4" />)}
            </button>
          ) : (
            text
          )}
        </Tooltip>
      </div>
    </th>
  );
};
