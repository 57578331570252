import { useNavigate } from 'react-router-dom';

import { useCurrentUser } from '@/context/current-user-context';

const useGoToBilling = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;

  const handleClick = () => {
    navigate(hasSettingsV2 ? '/settings/workspace/billing_and_plan' : '/settings/billing');
  };

  return handleClick;
};

export default useGoToBilling;
