import { TextAa } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { FontWeightSettings } from '../FontWeightSettings';
import { LineClampSettings } from '../LineClampSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';
import { TransformSettings } from '../TransformSettings';

type Props = AttributeSettingProps & {};

export const SubtitlePostsSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <PopoverHelper title="Subtitle" text="Subtitle" popoverTitle="Subtitle" Icon={TextAa}>
      <>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="subtitleShowing"
          title="Subtitle"
        />
        {activeNodeAttributes?.subtitleShowing && (
          <>
            <ColorSettings
              title="Color"
              property="subtitleTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
            />
            <LineClampSettings
              title="Lines"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="subtitleLineClamp"
            />
            <SimpleFontSizeSettings
              title="Size"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="subtitleFontSize"
            />
            <FontWeightSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="subtitleFontWeight"
              isMarkStyle={false}
            />
            <FontSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              isMarkStyle={false}
              title="Subtitle Font"
              property="subtitleFontFamily"
            />
            <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="subtitleTransform" />
          </>
        )}
      </>
    </PopoverHelper>
  );
};

export default SubtitlePostsSettings;
