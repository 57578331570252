import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import { Typography } from '@/components/Typography';
import useWallet from '@/hooks/boosts/useWallet';

import AvailableFundsAndEarnings from './AvailableFundsAndEarnings';
import EarningsAndExpenses from './EarningsAndExpenses';

const Wallet = () => {
  const walletQuery = useWallet();
  const { data: walletData } = walletQuery;

  return (
    <>
      <PageHeading
        title="Wallet"
        description={
          <Typography token="font-normal/text/sm" colorWeight="500">
            Manage funds and track earnings from Boosts and Ad Network.
          </Typography>
        }
        marginBottom={false}
        titleClassNames="text-2xl font-semibold"
      />
      <AvailableFundsAndEarnings walletData={walletData} walletQuery={walletQuery} />
      <EarningsAndExpenses walletData={walletData} walletQuery={walletQuery} />
    </>
  );
};

export default Wallet;
