import { useQuery } from 'react-query';

import { ApplicationDemographics } from '@/interfaces/boosts/grow/application_demographics';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

const useApplicationDemographics = (boostAgreementId?: string, isEnabled: boolean = true) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchApplicationDemographics = (): Promise<ApplicationDemographics> =>
    api
      .get(`/boosts/grow/boost_agreements/${boostAgreementId}/application_demographics`, {
        params: { publication_id: currentPublicationId },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, 'boosts', 'grow', 'boost_agreement', boostAgreementId, 'application_demographics'],
    fetchApplicationDemographics,
    {
      enabled: isEnabled,
      staleTime: 600,
      keepPreviousData: true,
    }
  );
};

export default useApplicationDemographics;
