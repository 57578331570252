import { FC } from 'react';
import cx from 'classnames';

import { AdPortalAdvertiser } from '@/ad_portal/interfaces/advertiser';

interface Props {
  currentAdvertiserId: string;
  advertiser: AdPortalAdvertiser;
  onSelectOption: (advertiser: AdPortalAdvertiser) => void;
}

const AdvertiserOption: FC<Props> = (props) => {
  const { currentAdvertiserId, advertiser, onSelectOption } = props;
  const isCurrent = currentAdvertiserId === advertiser.id;

  return (
    <li
      className={cx(
        'hover:bg-primary-50 hover:text-primary-600 select-none relative py-2 pl-3 pr-2 cursor-pointer',
        isCurrent ? 'bg-primary-50 text-primary-600' : ''
      )}
      role="option"
      aria-selected="true"
      onClick={() => onSelectOption(advertiser)}
      onKeyDown={() => onSelectOption(advertiser)}
      key={advertiser.id}
    >
      <div className="flex items-center">
        <div className="w-4 h-4 min-w-4 min-h-4 rounded overflow-hidden">
          {advertiser.logo.url ? (
            <img className="h-4 w-4" src={advertiser.logo.url} alt="" />
          ) : (
            <div className="bg-gray-200 h-4 w-4 rounded" />
          )}
        </div>
        <div className="mr-2 flex-1">
          <span className="ml-2 block">{advertiser.name}</span>
        </div>
      </div>
    </li>
  );
};

export default AdvertiserOption;
