import { createPortal } from 'react-dom';

import { useAppLayout } from '@/context/app-layout-context';

interface AdditionalProps {
  nested?: boolean;
  publicationSwitchPath?: string;
}

const SideNavPortal: React.FC<React.PropsWithChildren & AdditionalProps> = ({
  children,
  publicationSwitchPath = '/',
  nested = true,
}) => {
  const { mainNavRef, nestedNavRef, useSideNavPortal, usePublicationSwitchPath } = useAppLayout();
  useSideNavPortal({ nested });
  usePublicationSwitchPath(publicationSwitchPath);

  const refTarget = nested ? nestedNavRef.current : mainNavRef.current;
  if (!refTarget) return null;

  return createPortal(children, refTarget);
};

export default SideNavPortal;
