import { useCallback, useMemo } from 'react';
import { AlignBottomSimple, AlignLeftSimple, AlignRightSimple, AlignTopSimple } from '@phosphor-icons/react';
import { COLUMN_DEFAULTS, CONTAINER_DEFAULTS, HEADING_DEFAULTS, PARAGRAPH_DEFAULTS } from '@shared/dream-components';

import { PaddingLeftRightIcon, PaddingTopBottomIcon } from '../../../Icons/BorderIcons';
import BoxModelToggle from '../helpers/BoxModelToggle';
import { AttributeSettingProps } from '../types';
import { parseCssValue } from '../utils/parseCssValue';

type PaddingValue = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

const getDefaultPadding = (type: string) => {
  const isRoot = true;

  if (type === 'column') return isRoot ? COLUMN_DEFAULTS.paddingRoot : COLUMN_DEFAULTS.padding;
  if (['h1', 'h2', 'h3'].includes(type || '')) return isRoot ? HEADING_DEFAULTS.paddingRoot : HEADING_DEFAULTS.padding;
  if (type === 'paragraph') return isRoot ? PARAGRAPH_DEFAULTS.paddingRoot : PARAGRAPH_DEFAULTS.padding;
  return CONTAINER_DEFAULTS.padding;
};

type PaddingSettingsProps = AttributeSettingProps & {
  property: string;
  title?: string;
};

const getPaddingFromNodeAttributes = (
  nodeAttributes: Record<string, any>,
  property: string,
  unit: string,
  defaultPadding: string
) => {
  let nodePadding = nodeAttributes[property];

  if (!nodePadding) {
    nodePadding = defaultPadding;
  }

  // padding is always 4 numbers top, right, bottom, left
  let paddingArray = nodePadding.split(' ');
  if (paddingArray.length === 2) {
    paddingArray = [paddingArray[0], paddingArray[1], paddingArray[0], paddingArray[1]];
  } else if (paddingArray.length === 3) {
    paddingArray = [paddingArray[0], paddingArray[1], paddingArray[2], paddingArray[1]];
  } else if (paddingArray.length === 1) {
    paddingArray = [paddingArray[0], paddingArray[0], paddingArray[0], paddingArray[0]];
  }

  const top = paddingArray[0];
  const right = paddingArray[1];
  const bottom = paddingArray[2];
  const left = paddingArray[3];

  return {
    top: parseCssValue(top, unit).value,
    right: parseCssValue(right, unit).value,
    bottom: parseCssValue(bottom, unit).value,
    left: parseCssValue(left, unit).value,
  };
};

export const PaddingSettings = ({ editor, property, activeNodeResult, title }: PaddingSettingsProps) => {
  const unit = 'px';
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const padding = useMemo(
    () => getPaddingFromNodeAttributes(activeNodeAttributes, property, unit, getDefaultPadding(activeNodeType)),
    [activeNodeAttributes, property, unit, activeNodeType]
  );

  const updatePadding = useCallback(
    (side: 'top' | 'right' | 'bottom' | 'left' | 'all' | 'top-bottom' | 'left-right', value: number) => {
      if (activeNodePos === undefined || !activeNodeType) return;
      let p: PaddingValue;

      if (side === 'top-bottom') {
        p = { ...padding, top: value, bottom: value };
      } else if (side === 'left-right') {
        p = { ...padding, left: value, right: value };
      } else {
        p = { ...padding, [side]: value } as PaddingValue;
      }

      if (side === 'all') {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(
            activeNodePos,
            property,
            `${value}${unit} ${value}${unit} ${value}${unit} ${value}${unit}`
          );
          return true;
        });
      } else {
        editor.commands.command(({ tr }) => {
          // this one seems more promising because it does not
          // override the other attributes.
          tr.setNodeAttribute(
            activeNodePos,
            property,
            `${p.top}${unit} ${p.right}${unit} ${p.bottom}${unit} ${p.left}${unit}`
          );
          return true;
        });
      }
    },
    [activeNodePos, activeNodeType, editor, padding, unit, property]
  );

  return (
    <BoxModelToggle
      title={title || 'Padding'}
      defaultValues={{
        top: padding?.top || 0,
        right: padding?.right || 0,
        bottom: padding?.bottom || 0,
        left: padding?.left || 0,
        maxTopBottom: 500,
        maxLeftRight: 500,
      }}
      suffixes={{
        top: <AlignTopSimple weight="bold" />,
        right: <AlignRightSimple weight="bold" />,
        bottom: <AlignBottomSimple weight="bold" />,
        left: <AlignLeftSimple weight="bold" />,
        topBottom: <PaddingTopBottomIcon />,
        leftRight: <PaddingLeftRightIcon />,
      }}
      type="sides"
      onUpdate={updatePadding}
    />
  );
};
