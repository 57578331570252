import { Extension } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';

import { getBooleanAttribute } from '../../../utils/attributesUtils';

interface StackOnMobileOptions {
  nodesToAddStackOnMobileAttributeTo: PMNode['type']['name'][];
}

interface StackOnMobileStorage {}

export const StackOnMobileAttribute = Extension.create<StackOnMobileOptions, StackOnMobileStorage>({
  name: 'stackOnMobileAttribute',

  addOptions() {
    return {
      nodesToAddStackOnMobileAttributeTo: ['columns', 'container', 'section'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.nodesToAddStackOnMobileAttributeTo,
        attributes: {
          stackOnMobile: getBooleanAttribute('stackOnMobile', 'data-stack-on-mobile', true),
        },
      },
    ];
  },
});
