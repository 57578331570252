export const NODES_NAMES = [
  'accordion',
  'accordionContent',
  'accordionTitle',
  'authors',
  'boost',
  'bulletList',
  'button',
  'codeBlock',
  'columns',
  'container',
  'divider',
  'doc',
  'embedInput',
  'figcaption',
  'footnoteItem',
  'footnoteMarker',
  'footnotesNode',
  'genericEmbed',
  'genericEmbedDescription',
  'genericEmbedHost',
  'genericEmbedTitle',
  'hardBreak',
  'heading',
  'horizontalRule',
  'htmlSnippet',
  'image',
  'listItem',
  'login',
  'loginModal',
  'orderedList',
  'paragraph',
  'paywallBreak',
  'post',
  'pricing',
  'recommendations',
  'recommendationsModal',
  'recommendedPosts',
  'referralProgram',
  'resetPassword',
  'section',
  'serviceEmbed',
  'signup',
  'signupModal',
  'socials',
  'spacer',
  'subscriberBreak',
  'survey',
  'table',
  'tableCell',
  'tableHeader',
  'tableOfContents',
  'tableRow',
  'tags',
  'testimonials',
  'updatePassword',
];

export const MARKS_NAMES = ['textStyle'];
