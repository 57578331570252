import { OrderBy, SpendOrderBy } from '@/interfaces/boosts/order';

export const orderByOptions = [
  { label: 'Pending subscribers', value: OrderBy.PENDING_SUBSCRIBER_COUNT },
  { label: 'Verified subscribers', value: OrderBy.ACCEPTED_SUBSCRIBER_COUNT },
  { label: 'Total subscribers', value: OrderBy.TOTAL_SUBSCRIBER_COUNT },
];

export const spendOrderByOptions = [
  { label: 'Pending spend', value: SpendOrderBy.PENDING_SPEND },
  { label: 'Total spend', value: SpendOrderBy.TOTAL_SPEND },
];

export const SCORE_SIZE_LABELS = {
  1: 'New <5K',
  2: 'Small 5-25K',
  3: 'Medium 25-100K',
  4: 'Large 100-250K',
  5: 'Very Large >250K',
};

export const SCORE_GROWTH_LABELS = {
  1: 'Very Slow',
  2: 'Slow',
  3: 'Average',
  4: 'Fast',
  5: 'Very Fast',
};

export const SCORE_ENGAGEMENT_LABELS = {
  1: 'Poor <5%',
  2: 'Low 5-10%',
  3: 'Average 10-25%',
  4: 'Good 25-40%',
  5: 'Excellent >40%',
};

export const SCORE_QUALITY_LABELS = {
  1: 'Poor',
  2: 'Low',
  3: 'Average',
  4: 'Good',
  5: 'Excellent',
};

export const AUTO_ACCEPT_MODAL_TEXTS = {
  enable: {
    header: 'Enable auto-accept',
    description:
      'All applications that match your selected criteria will be automatically accepted. This includes current and new applications which fit this criteria. Are you sure you want to proceed?',
    action: 'Yes, enable',
  },
  update: {
    header: 'Update auto-accept criteria',
    description:
      'All applications that match your selected criteria will be automatically accepted. This includes current and new applications which fit this criteria. Are you sure you want to proceed?',
    action: 'Yes, update',
  },
  disable: {
    header: 'Disable auto-accept',
    description:
      'By disabling auto-accept, applications that match your selected criteria will no longer be automatically accepted. Are you sure you want to proceed?',
    action: 'Yes, disable',
  },
} as const;

export type AutoAcceptModalType = keyof typeof AUTO_ACCEPT_MODAL_TEXTS;

export const AUTO_PAUSE_MODAL_TEXTS = {
  enable: {
    header: 'Enable auto-pause',
    description:
      'By enabling auto-pause, Grow Boost agreements will automatically pause if the open rate falls below the specified threshold after reaching the defined subscriber count. This policy applies to all current and future agreements that meet these criteria. Are you sure you want to proceed?',
    action: 'Yes, enable',
  },
  update: {
    header: 'Update auto-pause criteria',
    description:
      'All agreements that match your updated criteria will be automatically paused. This applies to both current and future agreements that match the new criteria. Are you sure you want to proceed?',
    action: 'Yes, update',
  },
  disable: {
    header: 'Disable auto-pause',
    description:
      'By disabling auto-pause, agreements will no longer be paused automatically based on the set thresholds. Are you sure you want to proceed?',
    action: 'Yes, disable',
  },
} as const;

export type AutoPauseModalType = keyof typeof AUTO_PAUSE_MODAL_TEXTS;
