import { useState } from 'react';

import { useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';

import { ContentTree } from '../ContentTree';
import { ContentTreeProvider } from '../ContentTree/context';
import { Checkbox } from '../UI/Checkbox';
import { Search } from '../UI/Search';
import { Text } from '../UI/Text';

import { Section, SectionTitle } from './Section';

export const SelectPagesStep = ({
  onBack,
  selectedPages,
  onSelectPage,
  onSelectAllPages,
  goToSearchPagesStep,
}: {
  onBack?: () => void;
  onSelectPage?: (id: string, checked: boolean) => void;
  selectedPages: Record<string, boolean>;
  onSelectAllPages?: () => void;
  goToSearchPagesStep?: () => void;
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const { pages } = useWebsiteContext();
  const pageRouteGetter = useWebsitePageRouteGetter();
  const allRoutes = pageRouteGetter?.getAllRoutes();

  const isAllPagesSelected = pages?.every((page) => selectedPages[page.id] === true);
  const isSomePagesSelected = pages?.some((page) => selectedPages[page.id] === true);
  const selectedPagesCount = Object.values(selectedPages).filter(Boolean).length;

  const renderSelectPageCheckbox = (id: string) => (
    <Checkbox
      checked={selectedPages[id] || false}
      onCheckedChange={(val: boolean) => onSelectPage && onSelectPage(id, val)}
    />
  );

  return (
    <>
      <Section>
        <SectionTitle title="Select Pages" onBack={onBack} className="px-0" />
        <Search
          placeholder="Search pages..."
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          className="flex-1"
          onFocus={goToSearchPagesStep}
        />

        <div className="flex items-center gap-2 p-1.5 w-full justify-end">
          <Text size="2xs" weight="medium" variant="secondary">
            {selectedPagesCount} Selected
          </Text>
          <Text size="2xs" weight="medium">
            Select All
          </Text>
          <Checkbox
            checked={isSomePagesSelected && !isAllPagesSelected ? 'indeterminate' : isAllPagesSelected}
            onCheckedChange={(checked) => {
              if (checked) {
                onSelectAllPages?.();
              } else {
                pages?.forEach((page) => {
                  onSelectPage?.(page.id, false);
                });
              }
            }}
          />
        </div>
      </Section>
      <hr className="w-full h-px bg-wb-hr" />
      <Section>
        <div className="flex flex-col max-h-[300px] overflow-y-auto no-scrollbar">
          {allRoutes && (
            <ContentTreeProvider
              isAllowDnD={false}
              isShowOptions={false}
              isShowAddPage={false}
              rightComponent={renderSelectPageCheckbox}
              isLinkToPage={false}
              searchQuery={searchQuery}
              onClick={(id) => onSelectPage && onSelectPage(id, !selectedPages[id])}
              pageLinkPrefix="/website_builder_v2/settings/page"
            >
              <ContentTree route={allRoutes} parentPath={[]} slug="" />
            </ContentTreeProvider>
          )}
        </div>
      </Section>
    </>
  );
};
