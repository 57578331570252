import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useIsSettingPage from '@/hooks/useIsSettingPage';
import { usePublication } from '@/hooks/usePublications';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import useIsMobile from '@/ui/hooks/useIsMobile';

import { useCurrentPublicationState } from './current-publication-context';

interface IAppLayoutContext {
  isSideNavExpandable: boolean;
  hasFullHeight: boolean;
  disableView: boolean;
  largeNavOpen: boolean;
  setLargeNavOpen: (value: boolean) => void;
  mainNavRef: React.RefObject<HTMLDivElement>;
  nestedNavRef: React.RefObject<HTMLDivElement>;
  topBarRef: React.RefObject<HTMLDivElement>;
  topBarPortalUsed: boolean;
  useTopBarPortal: () => void;
  sideNavPortalUsed: boolean;
  useSideNavPortal: ({ nested }: { nested: boolean }) => void;
  hideMainNav: boolean;
  hidePublicationDropdown: boolean;
  useHidePublicationDropdown: () => void;
  publicationSwitchPath: string;
  usePublicationSwitchPath: (path: string) => void;
  useCollapsedSideNav: () => void;
}

const AppLayoutContext = createContext<IAppLayoutContext | undefined>(undefined);

const AppLayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const isSettingsPages = useIsSettingPage();
  const [publicationId] = useCurrentPublicationState();
  const currentPublication = usePublication(publicationId)?.data;
  const { data: settings } = usePublicationSettings(publicationId);

  // SideNav state
  const mainNavRef = useRef<HTMLDivElement>(null);
  const nestedNavRef = useRef<HTMLDivElement>(null);
  const [largeNavOpen, setLargeNavOpen] = useState<boolean>(localStorage.getItem('largeNavOpen') === 'true');
  const [sideNavPortalUsed, setSideNavPortalUsed] = useState<string>();
  const [hidePublicationDropdown, setHidePublicationDropdown] = useState(false);
  const [sideNavExpandable, setSideNavExpandable] = useState(true);
  const [publicationSwitchPath, setPublicationSwitchPath] = useState('/');

  // TopBar state
  const topBarRef = useRef<HTMLDivElement>(null);
  const [topBarPortalUsed, setTopBarPortalUsed] = useState(false);

  const isPageWithSubLayout = location.pathname.includes('analytics/click_map');
  const isSysAdmin = settings?.system_admin;
  const disableView = (currentPublication?.is_sending_disabled && !isSettingsPages && !isSysAdmin) || false;

  useEffect(() => {
    localStorage.setItem('largeNavOpen', largeNavOpen.toString());
  }, [largeNavOpen]);

  const useSideNavPortal = ({ nested }: { nested: boolean }) => {
    useEffect(() => {
      setSideNavPortalUsed(nested ? 'nested' : 'unnested');
      setSideNavExpandable(false);

      return () => {
        setSideNavPortalUsed(undefined);
        setSideNavExpandable(true);
      };
    }, [nested]);
  };

  const useHidePublicationDropdown = () => {
    useEffect(() => {
      setHidePublicationDropdown(true);

      return () => setHidePublicationDropdown(false);
    });
  };

  const usePublicationSwitchPath = (path: string) => {
    useEffect(() => {
      setPublicationSwitchPath(path);

      return () => setPublicationSwitchPath('/');
    });
  };

  const useTopBarPortal = () => {
    useEffect(() => {
      setTopBarPortalUsed(true);

      return () => setTopBarPortalUsed(false);
    });
  };

  const useCollapsedSideNav = () => {
    useEffect(() => {
      setLargeNavOpen(false);
      setSideNavExpandable(false);

      return () => {
        setLargeNavOpen(true);
        setSideNavExpandable(true);
      };
    });
  };

  const contextProviderProps = useMemo(
    () => ({
      isSideNavExpandable: sideNavExpandable,
      hasFullHeight: isPageWithSubLayout || disableView,
      disableView,
      largeNavOpen: isMobile
        ? sideNavPortalUsed !== 'nested'
        : (sideNavPortalUsed === undefined && largeNavOpen) || sideNavPortalUsed === 'unnested',
      setLargeNavOpen,
      mainNavRef,
      nestedNavRef,
      topBarRef,
      topBarPortalUsed,
      useTopBarPortal,
      sideNavPortalUsed: !!sideNavPortalUsed,
      useSideNavPortal,
      hideMainNav: sideNavPortalUsed === 'unnested',
      hidePublicationDropdown,
      useHidePublicationDropdown,
      publicationSwitchPath,
      usePublicationSwitchPath,
      useCollapsedSideNav,
    }),
    [
      sideNavExpandable,
      isPageWithSubLayout,
      disableView,
      isMobile,
      sideNavPortalUsed,
      largeNavOpen,
      topBarPortalUsed,
      hidePublicationDropdown,
      publicationSwitchPath,
    ]
  );

  return <AppLayoutContext.Provider value={contextProviderProps}>{children}</AppLayoutContext.Provider>;
};

function useAppLayout() {
  const context = React.useContext(AppLayoutContext);
  if (context === undefined) {
    throw new Error(`useAppLayout must be used within a AppLayoutProvider`);
  }
  return context;
}

export { AppLayoutContext, AppLayoutProvider, useAppLayout };
