import { CSSProperties, useEffect, useState } from 'react';
import { autoPlacement, offset, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { CaretRight, X } from '@phosphor-icons/react';

import { Text } from './Text';

const weightKeys = ['900', '800', '700', '600', '500', '400', '300', '200', '100'];
export type WeightType = CSSProperties['fontWeight'];

type Props = {
  defaultValue?: WeightType;
  value?: WeightType;
  onChange: (value: WeightType) => void;
};

const FontWeightSelection = ({ defaultValue, value, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedWeight, setSelectedWeight] = useState<WeightType | undefined>(defaultValue);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'left-start',
    middleware: [autoPlacement(), offset(20)],
  });


  useEffect(() => {
    if (value) {
      setSelectedWeight(value);
    }
  }, [value]);

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <>
      <div ref={refs.setReference} className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Weight
        </Text>
        <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer" {...getReferenceProps()}>
          <div className="w-full justify-between flex items-center gap-2 p-2">
            <div className="flex items-center gap-1">
              <Text size="2xs" weight="medium">
                {selectedWeight || 'Custom'}
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          className="bg-wb-primary w-[255px] max-h-[500px] overflow-y-auto p-3 rounded-lg shadow-xl flex flex-col gap-2"
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Text Weight
            </Text>

            <div
              className="p-1 text-wb-secondary bg-wb-secondary rounded-full"
              onClick={() => setIsOpen(false)}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setIsOpen(false);
                }
              }}
            >
              <X className="cursor-pointer" />
            </div>
          </div>
          {weightKeys.map((weight) => (
            <div
              key={weight}
              className={`flex items-center px-3 py-2 rounded-lg cursor-pointer border ${selectedWeight === weight
                ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                : 'bg-wb-secondary border-transparent'
                }`}
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                setSelectedWeight(weight);
                onChange(weight);
              }}
              onClick={() => {
                setSelectedWeight(weight);
                onChange(weight);
              }}
            >
              <div style={{ fontWeight: weight }}>{weight}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default FontWeightSelection;
