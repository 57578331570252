import { Extension } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';

import { getStringAttribute } from '../../../utils/attributesUtils';
import { DEFAULT_BORDER_RADIUS } from '../../constants';

interface BorderRadiusOptions {
  nodesToAddBorderRadiusAttributeTo: PMNode['type']['name'][];
}

interface BorderRadiusStorage {}

export const BorderRadiusAttribute = Extension.create<BorderRadiusOptions, BorderRadiusStorage>({
  name: 'borderRadiusAttribute',

  addOptions() {
    return {
      nodesToAddBorderRadiusAttributeTo: [
        'container',
        'login',
        'loginModal',
        'recommendations',
        'recommendationsModal',
        'resetPassword',
        'signup',
        'signupModal',
        'socials',
        'tags',
        'testimonials',
        'updatePassword',
      ],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.nodesToAddBorderRadiusAttributeTo,
        attributes: {
          borderRadius: getStringAttribute('borderRadius', 'data-border-radius', DEFAULT_BORDER_RADIUS, DEFAULT_BORDER_RADIUS),
        },
      },
    ];
  },
});
