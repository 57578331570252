import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks/usePublications';
import { SettingsPageLayout } from '@/pages/Settings/Components';

const PublicationSettingsLayout: React.FC = () => {
  const { data: currentPublication } = useCurrentPublication();
  const { settings } = useSettings();

  const publicationName = currentPublication?.name || '';
  const navigate = useNavigate();
  const location = useLocation();

  const sideTabs = [
    {
      name: 'general',
      label: 'General',
      selected: location.pathname === '/settings/publication',
      onSelect: () => navigate('/settings/publication'),
    },
    {
      name: 'domains',
      label: 'Domains',
      selected: location.pathname === '/settings/publication/domains',
      onSelect: () => navigate('/settings/publication/domains'),
    },
    {
      name: 'premium',
      label: 'Paid Subscriptions',
      selected: location.pathname === '/settings/publication/premium',
      onSelect: () => navigate('/settings/publication/premium'),
    },
    {
      name: 'rss_feed',
      label: 'RSS Feed',
      selected: location.pathname === '/settings/publication/rss_feed',
      onSelect: () => navigate('/settings/publication/rss_feed'),
    },
    {
      name: 'import_subscribers',
      label: 'Import Subscribers',
      selected: location.pathname === '/settings/publication/import_subscribers',
      onSelect: () => navigate('/settings/publication/import_subscribers'),
    },
    {
      name: 'import_content',
      label: 'Import Content',
      selected: location.pathname === '/settings/publication/import_content',
      onSelect: () => navigate('/settings/publication/import_content'),
    },
    {
      name: 'export_data',
      label: 'Export Data',
      selected: location.pathname === '/settings/publication/export_data',
      onSelect: () => navigate('/settings/publication/export_data'),
    },
    {
      name: 'subscribe_forms',
      label: 'Subscribe Forms',
      selected: location.pathname === '/settings/publication/subscribe_forms',
      onSelect: () => navigate('/settings/publication/subscribe_forms'),
    },
  ];

  if (settings?.recommendations_widget === true) {
    sideTabs.push({
      name: 'widgets',
      label: 'Widgets',
      selected: location.pathname === '/settings/publication/widgets',
      onSelect: () => navigate('/settings/publication/widgets'),
    });
  }

  return (
    <SettingsPageLayout title={`Publication - ${publicationName}`} sideTabs={sideTabs}>
      <Outlet />
    </SettingsPageLayout>
  );
};

export default PublicationSettingsLayout;
