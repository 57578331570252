import BulletListExtension from '@tiptap/extension-bullet-list';
// import { ReactNodeViewRenderer } from '@tiptap/react';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { BulletListView } from './views/BulletListView';

export const BulletList = BulletListExtension.extend({
  addNodeView() {
    return ReactNodeViewRenderer(BulletListView, {
      stopEvent: () => false,
    });
  },
  addInputRules() {
    return [];
  },
  addCommands() {
    return {};
  },
});

export default BulletList;
