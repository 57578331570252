import { useQuery } from 'react-query';

import { Tier } from '@/interfaces/tier';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  enabled?: boolean;
  offerId?: string;
  appendFreeTier?: boolean;
}

const usePricingTiers = ({ enabled = false, offerId, appendFreeTier = false }: Props) => {
  const [publicationId] = useCurrentPublicationState();

  return useQuery<{ tiers: Tier[] }>(
    ['tiers', 'dream_editor', publicationId, offerId, appendFreeTier],
    () =>
      api
        .get<any>(`/dream_editor/tiers`, {
          params: {
            publication_id: publicationId,
            premium_offer_id: offerId,
          },
        })
        .then((res) => {
          if (appendFreeTier) {
            return {
              tiers: res.data.tiers,
            };
          }

          return res.data;
        }),
    {
      enabled: !!publicationId && enabled,
    }
  );
};

export default usePricingTiers;
