import { CaretRight, ClockCountdown, Cloud, CloudSlash, Info, Link as LinkIcon } from '@phosphor-icons/react';

import { useWebsiteContext } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';

import { dateToAgo } from '../../_utils/dateToAgo';
import { dateToDateString } from '../../_utils/dateToDateString';
import { Button } from '../UI/Button';
import { Checkbox } from '../UI/Checkbox';
import { Label } from '../UI/Label';
import { Tag } from '../UI/Tag';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

import { Section, SectionTitle } from './Section';

const Options = ({
  text,
  right,
  checked,
  onChange,
  onClick,
}: {
  text: string;
  right?: string;
  checked?: boolean;
  onChange?: () => void;
  onClick?: () => void;
}) => {
  return (
    <div className="px-2 py-1.5 gap-2 flex items-center">
      <Checkbox id={text} checked={checked} onCheckedChange={onChange} />
      <Label htmlFor={text} className="flex-1">
        <Text size="sm" weight="medium">
          {text}
        </Text>
      </Label>
      {right && (
        <div
          className="flex items-center gap-1 cursor-pointer"
          onClick={onClick}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick?.();
            }
          }}
        >
          <Text size="xs" weight="medium" variant="secondary">
            {right}
          </Text>
          <CaretRight size={16} weight="bold" className="text-wb-secondary" />
        </div>
      )}
    </div>
  );
};

export const PublishSettingsStep = ({
  goToSelectPagesStep,
  isSettingsSelected,
  setIsSettingsSelected,
  selectedPages,
  onRevertToV1,
  isPublishSelectedPages,
  setIsPublishSelectedPages,
}: {
  goToSelectPagesStep?: () => void;
  isSettingsSelected: boolean;
  setIsSettingsSelected: (isSettingsSelected: boolean) => void;
  selectedPages: Record<string, boolean>;
  onRevertToV1?: () => void;
  isPublishSelectedPages: boolean;
  setIsPublishSelectedPages: (isPublishSelectedPages: boolean) => void;
}) => {
  const { site, pages } = useWebsiteContext();
  const { data: currentPublication } = useCurrentPublication();

  const isAllPagesSelected = pages && selectedPages && pages?.every((page) => selectedPages[page.id]);
  const selectedPagesCount = Object.values(selectedPages)?.filter(Boolean).length;

  const selectPagesText = isAllPagesSelected ? 'All Pages' : `${selectedPagesCount} Pages`;

  const publishedAtAgo = dateToAgo(site?.published_site_version?.published_at || '');
  const publishedAtDate = dateToDateString(site?.published_site_version?.published_at || '');

  return (
    <>
      <Section>
        <SectionTitle title="Publishing" />
        <div className="flex flex-col gap-0">
          <Options
            text="Site Settings"
            checked={isSettingsSelected}
            onChange={() => setIsSettingsSelected(!isSettingsSelected)}
          />
          <Options
            text={selectPagesText}
            right="Select Pages"
            checked={isPublishSelectedPages && (selectedPagesCount > 0)}
            onChange={() => setIsPublishSelectedPages(!isPublishSelectedPages)}
            onClick={goToSelectPagesStep}
          />
        </div>
      </Section>
      <hr className="w-full h-px bg-wb-hr" />
      <Section>
        <SectionTitle title="Publish V2 website to" />
        <div className="flex flex-col gap-0">
          <a
            href={currentPublication?.url || ''}
            target="_blank"
            className="px-2 py-1.5 gap-2 flex items-center cursor-pointer group"
            rel="noreferrer"
          >
            <LinkIcon size={16} weight="bold" className="text-wb-secondary" />
            <Text size="xs" weight="medium" className="group-hover:text-wb-accent">
              {currentPublication?.hostname}
            </Text>
          </a>
          <div className="px-2 py-1.5 gap-2 flex items-center">
            {site?.published_site_version?.published_at ? (
              <>
                <ClockCountdown size={16} weight="bold" className="text-wb-secondary" />
                <Tooltip center={`Published at ${publishedAtDate}`}>
                  <Text size="xs" weight="medium" variant="secondary" className="cursor-pointer">
                    Last published {publishedAtAgo}
                  </Text>
                </Tooltip>
              </>
            ) : (
              <>
                <CloudSlash size={16} weight="bold" className="text-wb-secondary" />
                <Text size="xs" weight="medium" variant="secondary">
                  Not Published
                </Text>
              </>
            )}
          </div>

          <div className="px-2 py-1.5 gap-2 flex items-center">
            <Cloud size={16} weight="bold" className="text-wb-secondary" />

            <div className="gap-1 flex items-center flex-1">
              <Text size="xs" weight="medium" variant="secondary">
                Current Version
              </Text>
              <Tooltip
                center={
                  currentPublication?.uses_dream_builder_site
                    ? 'Your website is currently showing your Website Beta site'
                    : 'To replace your current site with the beta site, hit "Publish to Live Site" below. You can always revert this at any time'
                }
              >
                <Info size={16} weight="bold" className="text-wb-secondary" />
              </Tooltip>
            </div>
            {currentPublication?.uses_dream_builder_site && (
              <Button variant="ghost" size="sm" onClick={onRevertToV1} className="p-1 underline">
                revert to V1
              </Button>
            )}
            <Tag text={currentPublication?.uses_dream_builder_site ? 'V2' : 'V1'} />
          </div>
        </div>
      </Section>
    </>
  );
};
