import { useCallback } from 'react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

import { cn } from '@/utils/cn';

import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { AttributeSettingProps } from '../types';

type IconSideSettingsProps = AttributeSettingProps & {
  property: string;
  disabled?: boolean;
};

export const IconSideSettings = ({ editor, activeNodeResult, property, disabled }: IconSideSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const updateOrientation = useCallback(
    (value: string) => {
      if (!activeNodeType) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, editor]
  );

  return (
    <div
      className={cn('flex items-center justify-stretch gap-2', disabled ? 'opacity-50 pointer-events-none' : '')}
    >
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Icon Side
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="right"
          value={activeNodeAttributes?.[property] || 'right'}
          onValueChange={updateOrientation}
        >
          <ToggleGroupItem value="left" className="grow">
            <CaretLeft weight="bold" format="outline" className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem value="right" className="grow">
            <CaretRight weight="bold" format="outline" className="h-4 w-4" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
