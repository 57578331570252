import { Text } from '../../../UI/Text';

const DynamicContentSurveys = () => {
  return (
    <div className="flex flex-col gap-2">
      <Text weight="medium" variant="secondary" size="xs" as="p">
        This is a dynamic page that applies this styling to all of your surveys. So any survey you create will have this
        styling applied to the actual live survey page.
      </Text>

      <Text weight="medium" variant="secondary" size="xs" as="p">
        We provide this placeholder data to demonstrate how this styling will look on each type of survey question on a
        survey page.
      </Text>

      <Text weight="medium" variant="secondary" size="xs" as="p">
        You cannot have different styles across different surveys at this time since it is a universally applied style.
        However, we are committed to maximizing flexibility in customization here over time.
      </Text>
    </div>
  );
};

export default DynamicContentSurveys;
