import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { cn } from '@/utils/cn';

import { KNOWLEDGE_PANEL_ID } from '../DreamEditor/constants';

const KnowledgePanelPortal = memo(() => {
  const [searchParams] = useSearchParams();
  const isKnowledgePanelOpen = searchParams.has('learn_more');

  return (
    <div
      className={cn(
        'absolute top-0 right-0 bottom-0 w-[266px] min-w-[266px] transition-transform duration-250 bg-wb-primary border-l border-solid overflow-y-auto',
        isKnowledgePanelOpen ? 'translate-x-0' : 'translate-x-[500px]'
      )}
      style={{
        scrollbarGutter: 'stable',
      }}
      id={KNOWLEDGE_PANEL_ID}
    />
  );
});

export default KnowledgePanelPortal;
