import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AdvancedWidthSettings } from '../AdvancedWidthSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { FontSizeSettings } from '../FontSizeSettings';
import { FontWeightSettings } from '../FontWeightSettings';
import { IconPlacementSettings } from '../IconPlacementSettings';
import { IconSettings } from '../IconSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

import { ButtonHoverEffectSettings } from './ButtonHoverEffectSettings';
import { ButtonLinkSettings } from './ButtonLinkSettings';

const ButtonSettings = ({
  children,
  editor,
  activeNodeResult,
  title = 'Style',
  propertyPrefix = '',
}: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['button']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <ButtonLinkSettings editor={editor} activeNodeResult={activeNodeResult} />
      <SectionRenderer title={title}>
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={
            propertyPrefix
              ? {
                  color: `${propertyPrefix}BorderColor`,
                  style: `${propertyPrefix}BorderStyle`,
                  width: `${propertyPrefix}BorderWidth`,
                  radius: `${propertyPrefix}BorderRadius`,
                }
              : undefined
          }
        />
        <PaddingSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property={propertyPrefix ? `${propertyPrefix}Padding` : 'padding'}
        />
        <AdvancedWidthSettings editor={editor} activeNodeResult={activeNodeResult} property="width" />
        <ShadowSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Shadow"
          property={propertyPrefix ? `${propertyPrefix}Shadow` : 'shadow'}
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Text"
          property={propertyPrefix ? `${propertyPrefix}CustomTextColor` : 'customTextColor'}
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Background"
          property={propertyPrefix ? `${propertyPrefix}CustomBackgroundColor` : 'customBackgroundColor'}
        />
        <FontSettings editor={editor} activeNodeResult={activeNodeResult} />
        <FontSizeSettings editor={editor} activeNodeResult={activeNodeResult} />
        <FontWeightSettings editor={editor} activeNodeResult={activeNodeResult} />
        <IconSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property={propertyPrefix ? `${propertyPrefix}Icon` : undefined}
        />
        <IconPlacementSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Icon Placement"
          property={propertyPrefix ? `${propertyPrefix}IconPlacement` : 'iconPlacement'}
          disabled={!activeNodeResult?.activeNodeAttributes?.icon}
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Icon Fill"
          disabled={!activeNodeResult?.activeNodeAttributes?.icon}
          property={propertyPrefix ? `${propertyPrefix}IconColor` : 'iconColor'}
        />
        {children}
      </SectionRenderer>
      <ButtonHoverEffectSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        backgroundColorProperty={propertyPrefix ? `${propertyPrefix}CustomBackgroundColor` : 'customBackgroundColor'}
      />
    </RenderCondition>
  );
};

export { ButtonSettings };
