import { useNavbarContext } from '../../../NavbarContext';

import { BooleanSettings } from './BooleanSettings';

export const NewTabSettings = () => {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (!['navbar_item', 'navbar_dropdown_item'].includes(selectedContent.type)) return null;

  return <BooleanSettings title="New Tab" property="new_tab" />;
};
