import { computeFont } from '@shared/dream-components';
import TiptapFontFamily from '@tiptap/extension-font-family';

type FontFamilyStorage = {
  usedFonts: Record<string, any>;
};

export const FontFamily = TiptapFontFamily.extend<{}, FontFamilyStorage>({
  addStorage() {
    return {
      usedFonts: [],
    };
  },

  onCreate() {
    const { editor } = this;

    const setUsedFonts = () => {
      const allFontFamilies: Record<string, number> = {};

      editor.state.doc.descendants((node) => {
        if (node.type.name === 'text') {
          const textStyleMark = node.marks.find((m) => m.type.name === 'textStyle');

          const { fontFamily } = textStyleMark?.attrs || {};

          if (textStyleMark?.attrs.fontFamily) {
            if (fontFamily in allFontFamilies) {
              allFontFamilies[fontFamily] += 1;
            } else {
              allFontFamilies[fontFamily] = 1;
            }
          }
        } else if (node.attrs) {
          Object.keys(node.attrs).forEach((attr) => {
            if (attr.toLowerCase().includes('fontfamily')) {
              const fontFamily = node.attrs[attr];

              if (fontFamily in allFontFamilies) {
                allFontFamilies[fontFamily] += 1;
              } else {
                allFontFamilies[fontFamily] = 1;
              }
            }
          });
        }
      });

      this.storage.usedFonts = allFontFamilies;
    };

    setUsedFonts();

    editor.on('update', setUsedFonts);
  },

  addGlobalAttributes() {
    return [
      {
        types: ['textStyle'],
        attributes: {
          fontFamily: {
            parseHTML: (element) => element.getAttribute('data-font-family')?.replace(/['"]+/g, '') || '',
            renderHTML: (attributes) => {
              if (!attributes.fontFamily) {
                return {};
              }

              const computedFontFamily = computeFont(attributes.fontFamily);

              return {
                style: `font-family: ${computedFontFamily}`,
                'data-font-family': attributes.fontFamily,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontFamily:
        (fontFamily) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontFamily }).run();
        },
      unsetFontFamily:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { fontFamily: null }).removeEmptyTextStyle().run();
        },
    };
  },
});
