import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UpsellPage } from '@/components/UpsellPage';
import { useSettings } from '@/context/settings-context';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useUpgradeIntent from '@/hooks/useUpgradeIntent';
import { IntentAction } from '@/interfaces/upgrades';
import { PLAN } from '@/utils/plans';

const TeaserPage = () => {
  const publicationId = useCurrentPublicationId();
  const { pushDataLayerEvent } = useUpgradeIntent({
    publicationId,
    intentAction: IntentAction.USE_WEB_BUILDER_V2_BETA,
  });
  const { settings } = useSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (settings?.hiiv_dream) {
      navigate('/website_builder_v2');
    }
  }, [settings, navigate]);

  return (
    <UpsellPage
      plan={PLAN.MAX}
      title="Website Builder [Beta]"
      description="Introducing a new era of powerful, flexible, and beautifully crafted websites."
      youtubeUrl="https://www.youtube.com/embed/-5WzkyKvwJE?si=0rEtuMWijo_-3S_t"
      cards={[
        {
          heading: 'No code (ever).',
          description: 'If you can dream it, you can build it on beehiiv.',
          imageUrl:
            'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,width=1080,quality=100/www/website-builder-v2/overview_1.png',
        },
        {
          heading: 'Customize every last pixel.',
          description: 'Drag and drop sections, headers, containers, widgets, animations, and more.',
          imageUrl:
            'https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,onerror=redirect,format=auto,width=1080,quality=100/www/website-builder-v2/overview_2.png',
        },
      ]}
      learnMoreUrl="https://www.beehiiv.com/features/website"
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default TeaserPage;
