export enum AdNetworkRoleName {
  PRIMARY = 'advertiser_primary_contact',
  BILLING = 'advertiser_billing_contact',
  AD_MANAGER = 'advertiser_ad_manager_contact',
}

export const AdNetworkRoleLabels: Record<AdNetworkRoleName, string> = {
  advertiser_primary_contact: 'Primary',
  advertiser_billing_contact: 'Billing',
  advertiser_ad_manager_contact: 'Ad Manager',
};

export interface AdNetworkRole {
  id: string;
  name: AdNetworkRoleName;
}
