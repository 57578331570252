import { useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/20/solid';
import {
  ArrowTrendingUpIcon,
  BanknotesIcon,
  ChartBarIcon,
  CogIcon,
  DocumentTextIcon,
  Squares2X2Icon,
  SwatchIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import {
  ArrowTrendingUpIcon as ArrowTrendingUpIconSolid,
  BackwardIcon as BackwardIconSolid,
  BanknotesIcon as BanknotesIconSolid,
  ChartBarIcon as ChartBarIconSolid,
  DocumentTextIcon as DocumentTextIconSolid,
  Squares2X2Icon as Squares2X2IconSolid,
  SwatchIcon as SwatchIconSolid,
  UserGroupIcon as UserGroupIconSolid,
} from '@heroicons/react/24/solid';
import cx from 'classnames';
import styled from 'styled-components';

import { PlanCard } from '@/components/Plan';
import { Typography } from '@/components/Typography';
import { REWIND_2024_MODAL_KEY } from '@/constants';
import { useAppLayout } from '@/context/app-layout-context';
import { useCurrentUser } from '@/context/current-user-context';
import { usePermissions } from '@/context/permissions-context';
import useIsSettingPage from '@/hooks/useIsSettingPage';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { useClickOutside } from '@/hooks/useUsers/useClickOutside';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import { useRewindContext } from '@/pages/Rewind2024/context';
import BetaBadge from '@/ui/BetaBadge';
import { Button } from '@/ui/Button';
import useIsMobile from '@/ui/hooks/useIsMobile';
import Tooltip from '@/ui/Tooltip';

import { BODY_HEIGHT_WITH_TOP_BAR, TOP_BAR_HEIGHT_CLASS_NAME } from '../../constants';
import SizingConfiguration from '../../TopBar/SizingConfiguration';
import PublicationDropdown from '../PublicationDropdown';

import NavMenu from './NavMenu';
import { NavParentItem } from './NavParentItem';
import { NavSection } from './NavSection';
import NavStartWriting from './NavStartWriting';

interface Props {
  className?: string;
  largeNavOpen: boolean;
  isMobileNavOpen: boolean;
  setIsMobileNavOpen?: (val: boolean) => void;
}

const SideNavStyled: any = styled.nav`
  width: ${({ isWide, isMobile }: any) => {
    if (isMobile && isWide) return '100%';

    return isWide ? '230px' : '60px';
  }};

  @media (max-width: 640px) {
    z-index: 30;
  }
`;

const Navigation = ({ className, largeNavOpen, isMobileNavOpen, setIsMobileNavOpen }: Props) => {
  const [navMenuOpen, setNavMenuOpen] = useState<string>('');
  const containerRef = useRef(null);

  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const { currentUser } = useCurrentUser();
  const publicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(publicationId);
  const hasPublicationSettingsV2 = currentUser?.has_settings_v2;
  const hiivDreamOptionVisible = settings?.hiiv_dream_visible;
  const hasHiivDream = settings?.hiiv_dream;
  const { checkPermissions, hasAnyReadPermissions, permissions } = usePermissions();
  const settingsMenuAccess = checkPermissions('views/nav/settings', 'read');
  const analyzeReportAccess = checkPermissions('views/nav/analyze/reports', 'read');
  const isSettingPage = useIsSettingPage();
  const { setRewindModalOpen, showRewind2024Button } = useRewindContext();

  const {
    mainNavRef,
    nestedNavRef,
    setLargeNavOpen,
    isSideNavExpandable,
    sideNavPortalUsed,
    hideMainNav,
    hidePublicationDropdown,
    publicationSwitchPath,
  } = useAppLayout();

  useClickOutside(() => setIsMobileNavOpen?.(false), containerRef);

  // WalkthroughPortal
  const openForWalkthrough = (step: string) => {
    return searchParams.get('walkthrough_step') === step;
  };

  const openRewindModal = async () => {
    await window.localStorage.removeItem(REWIND_2024_MODAL_KEY);
    setRewindModalOpen(true);
  };

  return (
    <div className={cx('flex flex-col', isMobileNavOpen && isMobile ? 'w-full h-full bg-[#111827]/20 fixed z-50' : '')}>
      {/* Mobile */}
      {setIsMobileNavOpen && isMobileNavOpen && (
        <div
          className={cx(
            'block sm:hidden xs:w-[80%] bg-white border-b border-r border-surface-200 py-4',
            TOP_BAR_HEIGHT_CLASS_NAME
          )}
        >
          <div className="w-full h-full flex flex-row items-between">
            <div className="flex flex-col justify-center px-2 bg-white">
              <Button variant="flush" size="xs" Icon={Bars3Icon} onClick={() => setIsMobileNavOpen(!isMobileNavOpen)} />
            </div>
          </div>
        </div>
      )}

      <div
        className={cx(isMobile && isMobileNavOpen ? 'flex xs:w-[80%]' : 'hidden', 'flex-row sm:flex')}
        ref={containerRef}
      >
        <SideNavStyled
          className={cx(
            BODY_HEIGHT_WITH_TOP_BAR,
            'sm:h-screen flex-col transition-all duration-200 ease-in-out relative border-r border-surface-200',
            className
          )}
          isWide={largeNavOpen}
          isMobile={isMobile}
          ref={mainNavRef}
        >
          <div className="relative">
            {hidePublicationDropdown ? (
              <div className={TOP_BAR_HEIGHT_CLASS_NAME}>&nbsp;</div>
            ) : (
              <PublicationDropdown largeNavOpen={largeNavOpen} onSwitch={() => navigate(publicationSwitchPath)} />
            )}
            {isSideNavExpandable && !isMobile && (
              <SizingConfiguration largeNavOpen={largeNavOpen} setLargeNavOpen={setLargeNavOpen} />
            )}
            <hr className="w-full absolute bottom-0" />
          </div>
          {!hideMainNav && (
            <div className="h-full flex flex-col justify-between overflow-y-auto overflow-x-hidden">
              <NavSection largeNavOpen={largeNavOpen}>
                <div className="flex h-auto flex-col space-y-2 bg-white">
                  <NavParentItem
                    largeNavOpen={largeNavOpen}
                    title="Dashboard"
                    BoldIcon={Squares2X2IconSolid}
                    Icon={Squares2X2Icon}
                    to="/"
                  />
                  <div className="py-2">
                    <NavStartWriting largeNavOpen={largeNavOpen} />
                  </div>
                  <NavParentItem
                    largeNavOpen={largeNavOpen}
                    title="Posts"
                    Icon={DocumentTextIcon}
                    BoldIcon={DocumentTextIconSolid}
                    to="/posts"
                  />
                  <NavMenu
                    navMenuOpen={navMenuOpen}
                    forceOpen={openForWalkthrough('3') || openForWalkthrough('5')}
                    setNavMenuOpen={setNavMenuOpen}
                    id="audience"
                    links={[
                      {
                        title: 'Subscribers',
                        to: '/subscribers',
                        disabled: !checkPermissions('views/nav/audience/subscribers', 'read'),
                      },
                      {
                        title: 'Segments',
                        to: '/segments',
                        disabled: !checkPermissions('views/nav/audience/segments', 'read'),
                      },
                      {
                        title: 'Automations',
                        to: '/automations',
                        disabled: !checkPermissions('views/nav/audience/automations', 'read'),
                      },
                      {
                        title: 'Polls',
                        to: '/polls',
                        disabled: !checkPermissions('views/nav/audience/polls', 'read'),
                      },
                      {
                        title: 'Surveys',
                        to: '/forms',
                        disabled: !checkPermissions('views/nav/audience/surveys', 'read'),
                      },
                      {
                        title: 'Subscribe Forms',
                        to: '/subscribe_forms',
                        disabled: !checkPermissions('views/nav/audience/subscribe_forms', 'read'),
                      },
                    ]}
                    largeNavOpen={largeNavOpen}
                    title="Audience"
                    Icon={UserGroupIcon}
                    BoldIcon={UserGroupIconSolid}
                    disabled={!hasAnyReadPermissions(permissions?.views?.nav?.audience || {})}
                  />
                  <NavMenu
                    id="grow"
                    navMenuOpen={navMenuOpen}
                    setNavMenuOpen={setNavMenuOpen}
                    links={[
                      {
                        title: 'Referral Program',
                        to: '/referral_program/overview',
                        disabled: !checkPermissions('views/nav/grow/referral_program', 'read'),
                      },
                      {
                        title: 'Recommendations',
                        to: '/recommendations',
                        disabled: !checkPermissions('views/nav/grow/recommendations', 'read'),
                      },
                      {
                        title: 'Magic Links',
                        to: '/magic_links',
                        disabled: !checkPermissions('views/nav/grow/magic_links', 'read'),
                      },
                      {
                        title: 'Boosts',
                        to: '/grow/boosts',
                        disabled: !checkPermissions('views/nav/grow/boosts', 'read'),
                      },
                    ]}
                    largeNavOpen={largeNavOpen}
                    title="Grow"
                    Icon={ArrowTrendingUpIcon}
                    BoldIcon={ArrowTrendingUpIconSolid}
                    disabled={!hasAnyReadPermissions(permissions?.views?.nav?.grow || {})}
                  />
                  <NavMenu
                    id="monetize"
                    navMenuOpen={navMenuOpen}
                    setNavMenuOpen={setNavMenuOpen}
                    links={[
                      {
                        title: 'Ads',
                        to: '/monetize/ads',
                        disabled: !checkPermissions('views/nav/monetize/ads', 'read'),
                      },
                      {
                        title: 'Boosts',
                        to: '/monetize/boosts',
                        disabled: !checkPermissions('views/nav/monetize/boosts', 'read'),
                      },
                      {
                        title: 'Paid Subscriptions',
                        to: '/monetize/subscriptions',
                        disabled: !checkPermissions('views/nav/monetize/subscriptions', 'read'),
                      },
                    ]}
                    largeNavOpen={largeNavOpen}
                    title="Monetize"
                    Icon={BanknotesIcon}
                    BoldIcon={BanknotesIconSolid}
                    disabled={!hasAnyReadPermissions(permissions?.views?.nav?.monetize || {})}
                  />
                  <NavMenu
                    id="design"
                    navMenuOpen={navMenuOpen}
                    forceOpen={openForWalkthrough('7')}
                    setNavMenuOpen={setNavMenuOpen}
                    links={[
                      ...(hiivDreamOptionVisible
                        ? [
                            {
                              title: (
                                <div className="flex flex-row items-center gap-2">
                                  Website <BetaBadge />
                                </div>
                              ),
                              to: hasHiivDream ? '/website_builder_v2' : '/website_builder_v2_overview',
                              disabled: !checkPermissions('views/nav/design/website_builder', 'read'),
                            },
                          ]
                        : []),
                      {
                        title: 'Website Builder',
                        to: '/website_builder',
                        disabled: !checkPermissions('views/nav/design/website_builder', 'read'),
                      },
                      {
                        title: 'Newsletter Builder',
                        to: '/post_themes/edit',
                        disabled: !checkPermissions('views/nav/design/newsletter_builder', 'read'),
                      },
                    ]}
                    largeNavOpen={largeNavOpen}
                    title="Design"
                    Icon={SwatchIcon}
                    BoldIcon={SwatchIconSolid}
                    disabled={!hasAnyReadPermissions(permissions?.views?.nav?.design || {})}
                  />
                  <NavMenu
                    id="analyze"
                    navMenuOpen={navMenuOpen}
                    setNavMenuOpen={setNavMenuOpen}
                    links={[
                      { title: 'Subscribers Report', to: '/reports/subscriber', disabled: !analyzeReportAccess },
                      { title: 'Posts Report', to: '/reports/post', disabled: !analyzeReportAccess },
                      { title: 'Clicks Report', to: '/reports/click', disabled: !analyzeReportAccess },
                    ]}
                    largeNavOpen={largeNavOpen}
                    title="Analyze"
                    Icon={ChartBarIcon}
                    BoldIcon={ChartBarIconSolid}
                    disabled={!hasAnyReadPermissions(permissions?.views?.nav?.analyze || {})}
                  />
                  {!sideNavPortalUsed && (
                    <div className="block sm:hidden space-y-2">
                      <hr className="-mx-4" />
                      <NavParentItem largeNavOpen={largeNavOpen} title="Share & Earn" to="/partner_program" />
                      <NavParentItem largeNavOpen={largeNavOpen} title="Wallet" to="/wallet" />
                      <NavParentItem largeNavOpen={largeNavOpen} title="Help" to="/help" />
                    </div>
                  )}
                </div>
              </NavSection>

              <div className={cx('bg-white transition-all w-full space-y-4')}>
                {largeNavOpen && !navMenuOpen && (
                  <div className="px-4">
                    <PlanCard />
                  </div>
                )}
                {showRewind2024Button && (
                  <div className={cx('w-full', largeNavOpen ? 'px-4' : 'px-2')}>
                    <Button
                      type="button"
                      variant="rewind"
                      className="w-full"
                      Icon={BackwardIconSolid}
                      onClick={openRewindModal}
                    >
                      {largeNavOpen ? 'Rewind 2024' : ''}
                    </Button>
                  </div>
                )}
                <div className={cx('p-2 w-full border-t border-surface-200', largeNavOpen ? 'pl-4' : 'pl-2')}>
                  {settingsMenuAccess ? (
                    <Link
                      to={hasPublicationSettingsV2 ? '/settings/publication/general' : '/settings'}
                      className={cx(
                        'flex flex-row space-x-2 p-2 rounded-lg hover:bg-surface-100',
                        isSettingPage && 'bg-surface-100',
                        !largeNavOpen && 'aspect-1 flex flex-col justify-center items-center',
                        largeNavOpen ? 'w-full' : 'w-fit mx-auto'
                      )}
                    >
                      <CogIcon className="h-4 w-4 my-auto text-surface-900" />
                      {largeNavOpen && (
                        <Typography token="font-medium/text/sm" className="my-auto" colorWeight="900">
                          Settings
                        </Typography>
                      )}
                    </Link>
                  ) : (
                    <Tooltip
                      tooltipClass="text-center"
                      id="nav-settings-tooltip"
                      text={NO_PERMISSION_MESSAGE}
                      showIcon={false}
                      autoWidth={false}
                      placement="right"
                    >
                      <div
                        className={cx(
                          'flex flex-row space-x-2 p-2 rounded-lg hover:bg-surface-100 cursor-not-allowed',
                          isSettingPage && 'bg-surface-100',
                          !largeNavOpen && 'aspect-1 flex flex-col justify-center items-center',
                          largeNavOpen ? 'w-full' : 'w-fit mx-auto'
                        )}
                      >
                        <CogIcon className="h-4 w-4 my-auto text-surface-900" />
                        {largeNavOpen && (
                          <Typography token="font-medium/text/sm" className="my-auto" colorWeight="900">
                            Settings
                          </Typography>
                        )}
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          )}
        </SideNavStyled>
        <div
          className={cx(
            'relative w-full sm:w-[230px] h-screen bg-white',
            !(sideNavPortalUsed && !hideMainNav) && 'hidden'
          )}
        >
          {!isMobile && <div className={TOP_BAR_HEIGHT_CLASS_NAME} />}
          {!isMobile && <hr className="w-full absolute top-[53px]" />}
          <div className={cx(BODY_HEIGHT_WITH_TOP_BAR, 'border-r border-surface-200')} ref={nestedNavRef} />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
