import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { CurrentUserOrganization } from '@/hooks/useCurrentUserOrganizations';
import useIsWorkspaceSettingPage from '@/hooks/useIsWorkspaceSettingPage';
import { Button } from '@/ui/Button';

import WorkspaceNav from './WorkspaceNav';

interface Props {
  organizations: CurrentUserOrganization[];
  onLinkClick: () => void;
  isLoading: boolean;
}

const MultiplePublicationSettings = ({ organizations, onLinkClick, isLoading }: Props) => {
  const navigate = useNavigate();
  const [currentPublicationId] = useCurrentPublicationState();

  const isWorkspaceSettingPage = useIsWorkspaceSettingPage();

  const listContainer = useRef<HTMLDivElement>(null);

  const organizationIdsByPublicationId: Record<string, string> =
    useMemo(
      () =>
        organizations?.reduce((po, co) => {
          return co.publications.reduce((pv, cp) => {
            return {
              ...po,
              ...pv,
              [cp.id]: co.id,
            };
          }, {});
        }, {}),
      [organizations]
    ) || {};

  const handleClickLink = ({ shouldScrollToTop }: { shouldScrollToTop: boolean }) => {
    if (shouldScrollToTop) {
      listContainer.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    onLinkClick();
  };

  if (isLoading) {
    return (
      <div className={classNames('flex flex-col border-x border-surface-100 bg-white')}>
        <SkeletonLoader
          isLoading={isLoading}
          skeletons={
            <div className="p-4 mt-12 space-y-2">
              <Skeleton className="h-9" />
              <Skeleton className="h-9" />
              <Skeleton className="h-9" />
            </div>
          }
        >
          &nbsp;
        </SkeletonLoader>
      </div>
    );
  }

  return (
    <div className={classNames('flex flex-col h-full bg-white')}>
      <div className="px-4 pt-3.5 w-full flex flex-row justify-between items-center">
        <Button align="left" size="xs" variant="flush" Icon={ArrowLeftIcon} onClick={() => navigate('/')}>
          Settings
        </Button>
      </div>
      <div className={classNames('flex flex-col gap-y-2 px-4 pb-4 overflow-y-auto mt-2')} ref={listContainer}>
        {organizations?.map((organization) => {
          if (currentPublicationId && organization.id !== organizationIdsByPublicationId[currentPublicationId]) {
            return null;
          }

          return (
            <WorkspaceNav
              key={`${organization.id}-${currentPublicationId}-${isWorkspaceSettingPage}`}
              organization={organization}
              currentPublicationId={currentPublicationId}
              onLinkClick={handleClickLink}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MultiplePublicationSettings;
