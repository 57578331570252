import { Sparkle } from '@phosphor-icons/react';
import classNames from 'classnames';

import { Text } from '../../../UI/Text';
import { Tooltip } from '../../../UI/Tooltip';

type Props = {
  title?: string;
  text?: string;
  onClick: () => void;
  className?: string;
};

const DynamicContent = ({ title = 'Dynamic content', text, onClick, className }: Props) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        className ||
          'absolute -top-7 left-1/2 transform -translate-x-1/2 w-fit bg-white shadow-md rounded-lg p-1 group',
        'gap-2 flex items-center px-2 py-1 border group'
      )}
    >
      <div className="flex items-center gap-2">
        <Tooltip center={text || 'Not connected to real data'} placement="bottom">
          <Sparkle size={16} className="text-[#6e46cb] text-opacity-80 group-hover:text-opacity-100" />
        </Tooltip>
        <Text size="2xs" variant="secondary" className="text-[#6e46cb] text-opacity-80 group-hover:text-opacity-100">
          {title}
        </Text>
      </div>
    </button>
  );
};

export default DynamicContent;
