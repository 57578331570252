import { Breadcrumbs } from '@/components/Breadcrumbs';
import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';

interface Props {
  endpoint?: WebhookEndpoint;
  showEdit?: boolean;
}

const Header = ({ endpoint, showEdit }: Props) => {
  return (
    <Breadcrumbs>
      <Breadcrumbs.Home />
      <Breadcrumbs.Item to="/settings/publication/webhooks">All Endpoints</Breadcrumbs.Item>
      {endpoint && (
        <>
          <Breadcrumbs.Item to={`/settings/publication/webhooks/${endpoint.id}/show`}>
            {endpoint.description}
          </Breadcrumbs.Item>
          {showEdit && (
            <Breadcrumbs.Item to={`/settings/publication/webhooks/${endpoint.id}/edit`}>Edit</Breadcrumbs.Item>
          )}
        </>
      )}
    </Breadcrumbs>
  );
};

export default Header;
