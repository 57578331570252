/* eslint-disable no-nested-ternary */

import { useCallback, useMemo, useState } from 'react';
import { ArrowLeft } from '@phosphor-icons/react';
import { Recommendation } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';

import { useSettings } from '@/context/settings-context';
import { useRecommendations } from '@/hooks';
import useBoostedRecommendations from '@/hooks/useBoostedRecommendations';
import { useCurrentPublication, useOrganizationPublications } from '@/hooks/usePublications';
import { Publication } from '@/interfaces/publication';
import { Switch } from '@/routes/website/_components/UI/Switch';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

import { Button } from '../../../../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Text } from '../../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../../UI/ToggleGroup';
import { dummyRecommendations } from '../../../dummyData/recommendations';

import EmptyState from './EmptyState';
import RecommendationItem from './RecommendationItem';
import SelectedRecommendationButton from './SelectedRecommendationButton';

type TabType = 'recommendations' | 'boosts' | 'myPublications';

const getRecommendations = (isOwner: boolean, nodeData: Recommendation[] | undefined) => {
  if (!nodeData) {
    return [];
  }

  return nodeData.filter((node) => node.isOwner === isOwner);
};

const RecommendationsSelectionModal = ({
  editor,
  node,
  isOpen,
  onClose,
  setIsModalOpen,
  getPos,
}: NodeViewProps & {
  isOpen: boolean;
  onClose: () => void;
  setIsModalOpen: (isOpen: boolean) => void;
}) => {
  const { settings } = useSettings();
  const { data: currentPublication } = useCurrentPublication();
  const hasAccessToBoosts = settings?.boosts;

  const [selectedRecommendations, setSelectedRecommendations] = useState<Recommendation[]>(
    getRecommendations(false, node.attrs.data)
  );
  const [selectedRecommendationIds, setSelectedRecommendationIds] = useState<string[]>(
    getRecommendations(false, node.attrs.data).map((d: Recommendation) => d.id) || []
  );
  const [selectedPublications, setSelectedPublications] = useState<Recommendation[]>(
    getRecommendations(true, node.attrs.data)
  );
  const [selectedPublicationIds, setSelectedPublicationIds] = useState<string[]>(
    getRecommendations(true, node.attrs.data).map((d: Recommendation) => d.recommended_publication_id) || []
  );

  const [isLayoutOpen, setIsLayoutOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<TabType>('recommendations');
  const [showFakeData, setShowFakeData] = useState(node.attrs.hasFakeData);

  const publicationsQuery = useOrganizationPublications({
    organizationId: currentPublication?.organization_id,
    enabled: selectedType === 'myPublications',
  });

  const recommendationsQuery = useRecommendations({ enabled: selectedType === 'recommendations' });
  const boostedRecommendationsQuery = useBoostedRecommendations({
    enabled: selectedType === 'boosts' && hasAccessToBoosts,
  });

  const renderableRecommendations: Recommendation[] = useMemo(() => {
    if (showFakeData) {
      return dummyRecommendations;
    }

    switch (selectedType) {
      case 'recommendations':
        return (recommendationsQuery.data?.pages.flatMap((page) => page.recommendations) || []).map(
          (recommendation) => {
            return {
              id: recommendation.id,
              name: recommendation.recommended_publication.name,
              description: recommendation.recommended_publication.description || '',
              logo_url: recommendation.recommended_publication.logo_url,
              site_url: recommendation.recommended_publication.hostname,
              recommended_publication_id: recommendation.recommended_publication.id,
              sponsored: false,
              isOwner: false,
            };
          }
        );
      case 'boosts':
        return (boostedRecommendationsQuery.data?.pages.flatMap((page) => page.boosted_recommendations) || []).map(
          (recommendation) => {
            return {
              id: recommendation.id,
              name: recommendation.recommended_publication.name,
              description: recommendation.recommended_publication.description || '',
              logo_url: recommendation.recommended_publication.logo_url,
              site_url: recommendation.recommended_publication.hostname,
              recommended_publication_id: recommendation.recommended_publication.id,
              sponsored: true,
              isOwner: false,
            };
          }
        );
      default:
        return [];
    }
  }, [showFakeData, boostedRecommendationsQuery.data?.pages, recommendationsQuery.data?.pages, selectedType]);
  const renderablePublications = useMemo(() => {
    if (!publicationsQuery.data) {
      return [];
    }

    return (
      publicationsQuery.data
        .filter((publication: Publication) => publication.id !== currentPublication?.id)
        .map((publication: Publication) => publication) || []
    );
  }, [publicationsQuery.data, currentPublication?.id]);

  const showingCount: number = useMemo(() => {
    switch (selectedType) {
      case 'recommendations':
      case 'boosts':
        return renderableRecommendations?.length || 0;
      case 'myPublications':
        return publicationsQuery?.data?.length || 0;
      default:
        return 0;
    }
  }, [publicationsQuery, renderableRecommendations, selectedType]);
  const totalCount: number = useMemo(() => {
    if (showFakeData) {
      return dummyRecommendations.length;
    }

    switch (selectedType) {
      case 'recommendations':
        return recommendationsQuery.data?.pages[0]?.pagination?.total || 0;
      case 'boosts':
        return boostedRecommendationsQuery.data?.pages[0]?.pagination?.total || 0;
      case 'myPublications':
        return publicationsQuery?.data?.length || 0;
      default:
        return 0;
    }
  }, [boostedRecommendationsQuery, publicationsQuery, recommendationsQuery, selectedType, showFakeData]);
  const isFetching = useMemo(() => {
    switch (selectedType) {
      case 'recommendations':
        return recommendationsQuery.isFetching;
      case 'boosts':
        return boostedRecommendationsQuery.isFetching;
      case 'myPublications':
        return publicationsQuery.isFetching;
      default:
        return 0;
    }
  }, [
    boostedRecommendationsQuery.isFetching,
    publicationsQuery.isFetching,
    recommendationsQuery.isFetching,
    selectedType,
  ]);
  const hasNextPage =
    selectedType === 'recommendations'
      ? recommendationsQuery.hasNextPage
      : selectedType === 'boosts'
      ? boostedRecommendationsQuery.hasNextPage
      : false;
  const fetchNextPage =
    selectedType === 'recommendations'
      ? recommendationsQuery.fetchNextPage
      : selectedType === 'boosts'
      ? boostedRecommendationsQuery.fetchNextPage
      : undefined;

  const updateNode = useCallback(() => {
    editor?.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', false);
      if (showFakeData) {
        tr.setNodeAttribute(getPos(), 'hasFakeData', true);
        tr.setNodeAttribute(getPos(), 'data', selectedRecommendations);
      } else {
        tr.setNodeAttribute(getPos(), 'hasFakeData', false);
        tr.setNodeAttribute(getPos(), 'data', [...selectedRecommendations, ...selectedPublications]);
      }
      return true;
    });
  }, [editor?.commands, getPos, showFakeData, selectedRecommendations, selectedPublications]);

  const handleSubmit = useCallback(async () => {
    updateNode();
    setIsModalOpen(false);
  }, [setIsModalOpen, updateNode]);

  const handleCheckedChangeInRecommendationItem = (item: Recommendation, isSelected: boolean) => () => {
    if (isSelected) {
      const filteredItems = selectedRecommendations.filter((p) => p.id !== item.id);
      setSelectedRecommendations(filteredItems);
      setSelectedRecommendationIds(filteredItems.map((i: Recommendation) => i.id));
    } else {
      setSelectedRecommendationIds([...selectedRecommendationIds, item.id]);
      setSelectedRecommendations([...selectedRecommendations, item]);
    }
  };

  const handleCheckedChangeInPublicationItem = (item: Publication, isSelected: boolean) => () => {
    if (isSelected) {
      const filteredItems = selectedPublications.filter((p) => p.recommended_publication_id !== item.id);
      setSelectedPublications(filteredItems);
      setSelectedPublicationIds(filteredItems.map((i: Recommendation) => i.id));
    } else {
      setSelectedPublicationIds([...selectedPublicationIds, item.id]);
      setSelectedPublications([
        ...selectedPublications,
        {
          id: `publication-${item.id}`,
          name: item.name,
          description: item.description || '',
          logo_url: item.logo.thumb.url,
          site_url: item.url,
          sponsored: false,
          recommended_publication_id: item.id,
          isOwner: true,
        },
      ]);
    }
  };

  const handleDeleteSelectedRecommendation = (id: string) => {
    setSelectedRecommendationIds(selectedRecommendationIds.filter((itemId) => itemId !== id));
    setSelectedRecommendations(selectedRecommendations.filter((r) => r.id !== id));
  };

  const handleDeleteSelectedPublication = (id: string) => {
    setSelectedPublicationIds(selectedPublicationIds.filter((itemId) => itemId !== id));
    setSelectedPublications(selectedPublications.filter((r) => r.recommended_publication_id !== id));
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                {isLayoutOpen && <Button variant="ghost" onClick={() => setIsLayoutOpen(false)} LeftIcon={ArrowLeft} />}
                <Text size="xl" weight="semibold" variant="primary" as="h4">
                  Select Recommendations
                </Text>
              </div>

              {(selectedRecommendations.length > 0 || selectedPublications.length > 0) && (
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex gap-2">
                    {selectedRecommendations.map((recommendation) => (
                      <SelectedRecommendationButton
                        key={recommendation.id}
                        id={recommendation.id}
                        name={recommendation.name}
                        onClick={handleDeleteSelectedRecommendation}
                      />
                    ))}
                    {selectedPublications.map((publication) => (
                      <SelectedRecommendationButton
                        key={publication.recommended_publication_id}
                        id={publication.recommended_publication_id}
                        name={publication.name}
                        onClick={handleDeleteSelectedPublication}
                      />
                    ))}
                  </div>
                </div>
              )}

              {!showFakeData && (
                <div className="grow bg-wb-secondary rounded-lg shadow-sm">
                  <ToggleGroup
                    className="p-[2px]"
                    type="single"
                    defaultValue="Recommendations"
                    value={selectedType}
                    onValueChange={(value: TabType) => setSelectedType(value)}
                  >
                    <ToggleGroupItem value="recommendations" className="grow">
                      Recommendations
                    </ToggleGroupItem>
                    {hasAccessToBoosts && (
                      <ToggleGroupItem value="boosts" className="grow">
                        Boosts
                      </ToggleGroupItem>
                    )}
                    <ToggleGroupItem value="myPublications" className="grow">
                      My Publications
                    </ToggleGroupItem>
                  </ToggleGroup>
                </div>
              )}
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-4 w-full overflow-y-auto no-scrollbar">
          <div className="flex flex-col gap-4">
            {selectedType === 'myPublications' && (!renderablePublications || renderablePublications.length === 0) && (
              <EmptyState message="No publications found" />
            )}
            {selectedType !== 'myPublications' && renderableRecommendations.length === 0 && (
              <EmptyState message="No recommendations found" />
            )}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-32">
              {selectedType !== 'myPublications' &&
                renderableRecommendations?.map((item: Recommendation) => {
                  const isSelected = selectedRecommendationIds.includes(item.id);
                  return (
                    <RecommendationItem
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      description={item.description}
                      logoUrl={item.logo_url}
                      isSelected={isSelected}
                      onCheckedChange={handleCheckedChangeInRecommendationItem(item, isSelected)}
                    />
                  );
                })}
              {selectedType === 'myPublications' &&
                renderablePublications?.map((item: Publication) => {
                  const isSelected = selectedPublicationIds.includes(item.id);
                  return (
                    <RecommendationItem
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      description={item.description}
                      logoUrl={item.logo?.thumb?.url}
                      isSelected={isSelected}
                      onCheckedChange={handleCheckedChangeInPublicationItem(item, isSelected)}
                    />
                  );
                })}
            </div>
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
          <div className="flex justify-between items-center w-full">
            <div className="flex gap-2 items-center">
              <Tooltip center="Toggle off to switch between template placeholder data and your actual content. ">
                <Switch
                  id="show-fake-data"
                  checked={showFakeData}
                  onCheckedChange={() => {
                    setShowFakeData(!showFakeData);
                    setSelectedRecommendationIds([]);
                    setSelectedRecommendations([]);
                    setSelectedPublicationIds([]);
                    setSelectedPublications([]);
                  }}
                  labelText="Placeholder data"
                  labelClassName="whitespace-nowrap"
                />
              </Tooltip>
              <Text weight="regular" variant="secondary" size="2xs" as="span" className="whitespace-nowrap">
                Showing {showingCount} of {totalCount} results
              </Text>
              {hasNextPage && (
                <Button variant="outlined" onClick={() => fetchNextPage?.()} className="whitespace-nowrap" size="sm">
                  {isFetching ? 'Loading...' : 'Load more'}
                </Button>
              )}
            </div>
            <div className="flex gap-2 w-full justify-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RecommendationsSelectionModal;
