import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { ColorSettings } from '../general/ColorSettings';
import { Disabled } from '../general/Disabled';
import { WidthHeightSettings } from '../general/WidthHeightSettings';

export function NavMenuBackgroundSettings() {
  const { selectedContent, isMobile } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const widthSettings = selectedContent.type === 'navbar_menu_list' && (
    <WidthHeightSettings selectedContent={selectedContent} attribute="width" defaultFixedValue={1280} />
  );

  const heightSettings = selectedContent.type === 'navbar_menu_list' && (
    <WidthHeightSettings
      selectedContent={selectedContent}
      attribute="height"
      defaultFixedValue={68}
      options={{
        fit: 'Fit',
        fixed: 'Fixed',
      }}
      defaultEmptyValue="fit-content"
    />
  );

  return (
    <SectionRenderer title="Background">
      <ColorSettings title="Color" property="background" defaultValue="#FFFFFF" selectedContent={selectedContent} />
      {isMobile ? <Disabled>{widthSettings}</Disabled> : widthSettings}
      {isMobile ? <Disabled>{heightSettings}</Disabled> : heightSettings}
    </SectionRenderer>
  );
}
