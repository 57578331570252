import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { NavbarNodeWithContent, NavbarSerializableNode } from '@shared/dream-components';
import type { Delta } from 'jsondiffpatch';
import { clone, diff, patch, reverse } from 'jsondiffpatch';

import { useCurrentPublication } from '@/hooks';
import { useSite } from '@/hooks/useSite';
import { useSiteThemes } from '@/hooks/useSiteThemes';

import { navbarContent } from './defaultContent';
import { useNavbarDataContext } from './NavbarDataContext';
import { plugins } from './plugins';
import { applyThemeToNode, getNodeByID, getParent, getParentOrientation } from './utils';

interface Size {
  width: number;
  height: number;
  orientation: 'horizontal' | 'vertical';
}

export type NavbarContextType = {
  content: NavbarSerializableNode | undefined;
  setContent: (content: NavbarSerializableNode) => void;
  selectedContent: NavbarSerializableNode | null | undefined;
  selectedNodeEl: HTMLElement | undefined;
  hoveredContent: NavbarSerializableNode | null | undefined;
  hoveredContentID: string | undefined;
  hoverNodeEl: HTMLElement | undefined;
  onSelectNode: (el: HTMLElement, nodeID: string, isDraggable?: boolean) => void;
  onHoverNode: (el: HTMLElement, nodeID: string) => void;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  editorContainerRef: React.RefObject<HTMLDivElement>;
  onDragStart: () => void;
  onDrag: (event: React.DragEvent) => void;
  isDragging: boolean;
  dragPreviewSize: Size | undefined;
  setDragPreviewSize: (size: Size | undefined) => void;
  onUpdateNodeAttributes: (nodeID: string, attrs: Record<string, any>) => void;
  onUpdateNodeContent: (nodeID: string, nodeContent: NavbarSerializableNode[]) => void;
  onDeleteNode: (nodeID: string) => void;
  onCreateNavbar: () => void;
  isSelectedNodeDraggable: boolean;
  contentWidth: number;
  isMobile: boolean;

  // Navbar History
  withMerging: (fn: () => any | void, merge?: boolean) => void;
  undo: () => void;
  redo: () => void;
};

const updateNodeContent = (
  content: NavbarSerializableNode,
  parentNode: NavbarSerializableNode,
  newParentContent: NavbarSerializableNode[]
): NavbarSerializableNode => {
  // If the node is the root content, update its content
  if (content.attrs?.id === parentNode.attrs?.id) {
    return {
      ...content,
      content: newParentContent,
    } as NavbarSerializableNode;
  }

  // Helper function to recursively update the content
  const updateContent = (currentNode: NavbarSerializableNode): NavbarSerializableNode => {
    if (currentNode.attrs?.id === parentNode.attrs?.id) {
      return {
        ...currentNode,
        content: newParentContent,
      } as NavbarSerializableNode;
    }

    if ('content' in currentNode && Array.isArray(currentNode.content)) {
      return {
        ...currentNode,
        content: currentNode.content.map((child) => updateContent(child)),
      } as NavbarSerializableNode;
    }

    return currentNode;
  };

  // Start the recursive update from the root content
  return plugins.reduce((acc, plugin) => plugin.apply(acc), updateContent(content));
};

/**
 * Move an array item to a different position. Returns a new array with the item moved to the new position.
 */
export function arrayMove(array: any[], from: number, to: number): any[] {
  const newArray = array.slice();
  newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);

  return newArray;
}

const updateNodeAttributes = (
  content: NavbarSerializableNode,
  nodeID: string,
  attrs: Record<string, string>
): NavbarSerializableNode => {
  if (content.attrs?.id === nodeID) {
    return {
      ...content,
      attrs: {
        ...content.attrs,
        ...attrs,
      },
    } as NavbarSerializableNode;
  }

  if ('content' in content && Array.isArray(content.content)) {
    return {
      ...content,
      content: content.content.map((child) => updateNodeAttributes(child, nodeID, attrs)),
    } as NavbarSerializableNode;
  }

  return plugins.reduce((acc, plugin) => plugin.apply(acc), content);
};

const deleteNode = (content: NavbarSerializableNode, nodeID: string): NavbarSerializableNode | undefined => {
  if (content.attrs?.id === nodeID) {
    return undefined;
  }

  if ('content' in content && Array.isArray(content.content)) {
    return {
      ...content,
      content: content.content.map((child) => deleteNode(child, nodeID)).filter(Boolean) as NavbarSerializableNode[],
    } as NavbarSerializableNode;
  }

  return content;
};

export const NavbarContext = createContext<NavbarContextType | undefined>(undefined);

function flattenObject(obj: Record<string, any>, parentKey = '', separator = '.'): Record<string, any> {
  if (typeof obj !== 'object' || obj === null) {
    // Return immediately if the current value is not an object
    return parentKey ? { [parentKey]: obj } : {};
  }

  return Object.entries(obj)
    .flatMap(([key, value]) => {
      const newKey = parentKey ? `${parentKey}${separator}${key}` : key;
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        return Object.entries(flattenObject(value, newKey, separator));
      }
      return [[newKey, value]];
    })
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, any>);
}

const shouldMerge = (delta: Delta, prevDelta: Delta): boolean => {
  // check if the delta key of the delta is the same as prevDelta key
  if (!prevDelta || !delta) return false;

  // compare 2 delta
  const flatDelta = flattenObject(delta);
  const flatPrevDelta = flattenObject(prevDelta);

  // compare keys
  const deltaKeys = Object.keys(flatDelta);
  const prevDeltaKeys = Object.keys(flatPrevDelta);

  return deltaKeys.every((key) => prevDeltaKeys.includes(key));
};

const MAX_UNDO_REDO_STACK_SIZE = 100;

export const NavbarProvider = ({
  children,
  iframeRef,
  editorContainerRef,
  contentWidth,
}: {
  children: React.ReactNode;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  editorContainerRef: React.RefObject<HTMLDivElement>;
  contentWidth: number;
}) => {
  const [undoStack, setUndoStack] = useState<Delta[]>([]);
  const [redoStack, setRedoStack] = useState<Delta[]>([]);
  const isMerging = useRef<boolean | null>(null);

  const { content: draftContent, onUpdateContent } = useNavbarDataContext();
  const [content, setContent] = useState<NavbarSerializableNode | undefined>(undefined);
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [selectedContentID, setSelectedContentID] = useState<string | undefined>(undefined);
  const [hoveredContentID, setHoveredContentID] = useState<string | undefined>(undefined);
  const [hoverNodeEl, setHoverNodeEl] = useState<HTMLElement | undefined>(undefined);
  const [selectedNodeEl, setSelectedNodeEl] = useState<HTMLElement | undefined>(undefined);
  const [isSelectedNodeDraggable, setIsSelectedNodeDraggable] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingInsideIframe, setIsDraggingInsideIframe] = useState(true);
  const [dragPreviewSize, setDragPreviewSize] = useState<Size | undefined>(undefined);
  const { data: currentPublication } = useCurrentPublication();
  const { data: siteThemesData } = useSiteThemes();
  const { data: site } = useSite();

  const originalSelectedNodeStyles = useRef<CSSStyleDeclaration | undefined>(undefined);

  const siteThemes = siteThemesData?.pages.flatMap((page) => page.site_themes) || [];
  const defaultTheme = siteThemes.find((theme) => theme.is_primary);

  const undo = useCallback(() => {
    if (!content) return;

    const delta = undoStack[undoStack.length - 1];
    if (!delta) return;

    setRedoStack((prev) => [...prev, reverse(delta)]);
    setUndoStack((prev) => prev.slice(0, -1));

    const newContent = clone(content);
    patch(newContent, delta);
    setContent(newContent as NavbarSerializableNode);
  }, [content, undoStack]);

  const redo = useCallback(() => {
    if (!content) return;

    const delta = redoStack[redoStack.length - 1];
    if (!delta) return;

    setUndoStack((prev) => [...prev, reverse(delta)]);
    setRedoStack((prev) => prev.slice(0, -1));

    const newContent = clone(content);
    patch(newContent, delta);
    setContent(newContent as NavbarSerializableNode);
  }, [content, redoStack]);

  const addChangesToStack = useCallback((prev?: NavbarSerializableNode, newContent?: NavbarSerializableNode) => {
    if (!prev && !newContent) return;

    const delta = diff(prev, newContent);

    if (!delta) return;

    setUndoStack((prevStack) => {
      const clonedStack = structuredClone(prevStack);

      if (
        isMerging.current === true ||
        (isMerging.current === null && shouldMerge(delta, prevStack[prevStack.length - 1]))
      ) {
        const lastUndoDelta = clonedStack.pop();
        if (!lastUndoDelta) return [...clonedStack, reverse(delta)];
        const prevContentWithDelta = patch(clone(prev), lastUndoDelta);

        const mergedDelta = diff(prevContentWithDelta, newContent);
        if (!mergedDelta) return [...clonedStack, lastUndoDelta, reverse(delta)];

        // make sure that the total length of the undo stack is not more than MAX_UNDO_REDO_STACK_SIZE
        if (clonedStack.length >= MAX_UNDO_REDO_STACK_SIZE) {
          clonedStack.shift();
        }

        return [...clonedStack, reverse(mergedDelta)];
      }

      return [...prevStack, reverse(delta)];
    });
    setRedoStack([]);
  }, []);

  const [dragOverData, setDragOverData] = useState<{ parentID: string; index: number } | undefined>(undefined);

  useEffect(() => {
    if (draftContent && !isContentLoaded) {
      setContent(plugins.reduce((acc, plugin) => plugin.apply(acc), draftContent));
      setIsContentLoaded(true);
    }
  }, [draftContent, isContentLoaded]);

  useEffect(() => {
    if (content) {
      onUpdateContent(content);
    }
  }, [content, onUpdateContent]);

  const onCreateNavbar = useCallback(() => {
    if (content && 'content' in content && content.content?.length && content.content?.length > 0) return;
    let navContent = navbarContent(currentPublication?.logo?.url, currentPublication?.url);

    if (defaultTheme && site?.theme_rules) {
      navContent = applyThemeToNode(navContent, defaultTheme.data, site?.theme_rules);
    }

    setContent(navContent as NavbarSerializableNode);
  }, [content, currentPublication?.logo?.url, currentPublication?.url, defaultTheme, site?.theme_rules]);

  const onSelectNode = useCallback(
    (el: HTMLElement | undefined, nodeID: string | undefined, isDraggable = true) => {
      setIsSelectedNodeDraggable(!!isDraggable);

      setSelectedNodeEl(() => {
        const newEl = el;

        // Remove 'data-node-selected' and 'data-child-node-selected' attributes from all dream- elements
        const dreamElements = iframeRef.current?.contentDocument?.querySelectorAll('[class*="dream-"]');
        dreamElements?.forEach((element) => {
          element.removeAttribute('data-node-selected');
          element.removeAttribute('data-child-node-selected');
        });

        if (newEl && nodeID) {
          newEl.dataset.nodeSelected = 'true';

          // Traverse through parents and set data-child-node-selected attribute
          let currentNodeID = nodeID;
          while (currentNodeID && content) {
            const node = getNodeByID(content, currentNodeID);
            if (!node) break;
            const parentNode = getParent(content, node);
            if (parentNode && 'attrs' in parentNode && parentNode.attrs?.id) {
              const parentElements = iframeRef.current?.contentDocument?.querySelectorAll(
                `[data-element-id="${parentNode.attrs.id}"]`
              ) as NodeListOf<HTMLElement>;
              parentElements.forEach((parentElement) => {
                Object.assign(parentElement.dataset, { childNodeSelected: 'true' });
              });
              currentNodeID = parentNode.attrs.id;
            } else {
              break;
            }
          }
        }
        return newEl;
      });
      setSelectedContentID(nodeID);
    },
    [iframeRef, content]
  );

  const onHoverNode = useCallback((el: HTMLElement, nodeID: string) => {
    setHoverNodeEl(el);
    setHoveredContentID(nodeID);
  }, []);

  const selectedContent = content && selectedContentID ? getNodeByID(content, selectedContentID) : undefined;

  const hoveredContent = useMemo(() => {
    if (!content || !hoveredContentID) return undefined;
    return getNodeByID(content, hoveredContentID);
  }, [content, hoveredContentID]);

  const onReset = useCallback(
    (resetSelectedNode: boolean) => {
      setIsDragging(false);
      setDragPreviewSize(undefined);

      // 1. Remove any 'data-hover-parent' attribute from elements
      const elementsWithHoverParent = iframeRef.current?.contentDocument?.querySelectorAll('[data-hover-parent]');
      elementsWithHoverParent?.forEach((element) => {
        element.removeAttribute('data-hover-parent');
        element.classList.remove('outline-dashed', 'outline-1', 'outline-violet-400');
      });

      if (resetSelectedNode) {
        onSelectNode(undefined, undefined);
        setSelectedContentID(undefined);
        setHoverNodeEl(undefined);
        setHoveredContentID(undefined);
      }
    },
    [iframeRef, onSelectNode]
  );
  const onResetDragAndSelectionBox = useCallback(() => {
    setIsDragging(false);
    setDragPreviewSize(undefined);

    // 1. Remove any 'data-hover-parent' attribute from elements
    const elementsWithHoverParent = iframeRef.current?.contentDocument?.querySelectorAll('[data-hover-parent]');
    elementsWithHoverParent?.forEach((element) => {
      element.removeAttribute('data-hover-parent');
      element.classList.remove('outline-dashed', 'outline-1', 'outline-violet-400');
    });

    setSelectedNodeEl(undefined);
    setHoverNodeEl(undefined);
    setHoveredContentID(undefined);
  }, [iframeRef]);

  const onDragStart = useCallback(() => {
    if (selectedNodeEl) {
      setIsDragging(true);

      if (!['navbar_dropdown_item', 'navbar_dropdown_column'].includes(selectedContent?.type || '')) {
        // Remove 'data-node-selected' and 'data-child-node-selected' attributes from all dream- elements
        const dreamElements = iframeRef.current?.contentDocument?.querySelectorAll('[class*="dream-"]');
        dreamElements?.forEach((element) => {
          element.removeAttribute('data-node-selected');
          element.removeAttribute('data-child-node-selected');
        });
      }

      // Create a placeholder node
      const placeholderNode = document.createElement('div');
      placeholderNode.id = 'drag-placeholder-node';
      // Set placeholder dimensions to match the selected node using its bounding rectangle
      const selectedRect = selectedNodeEl.getBoundingClientRect();
      placeholderNode.style.width = `${selectedRect.width}px`;
      placeholderNode.style.height = `${selectedRect.height}px`;
      placeholderNode.style.pointerEvents = 'none';
      placeholderNode.style.backgroundColor = '#ede9fe';

      const placeholderContainer = document.createElement('div');
      placeholderContainer.id = 'drag-placeholder';
      placeholderContainer.appendChild(placeholderNode);

      const originalPlacementContainer = document.createElement('div');
      originalPlacementContainer.id = 'original-placement-container';
      originalPlacementContainer.style.display = 'none';

      let selectedNodeParent = selectedNodeEl.parentNode as HTMLElement;
      let completeSelectedNode = selectedNodeEl;
      originalSelectedNodeStyles.current = { ...completeSelectedNode.style };
      if (
        selectedNodeParent &&
        !Array.from(selectedNodeParent.classList).some((className) => className.startsWith('dream-'))
      ) {
        completeSelectedNode = selectedNodeParent;
        selectedNodeParent = selectedNodeParent.parentNode as HTMLElement;
      }
      if (completeSelectedNode && selectedNodeParent) {
        // Insert the placeholder where the original node was
        selectedNodeParent.insertBefore(originalPlacementContainer, completeSelectedNode);
        selectedNodeParent.insertBefore(placeholderContainer, completeSelectedNode);
      }

      // Hide the original selected node
      completeSelectedNode.style.display = 'none';
      placeholderContainer.appendChild(completeSelectedNode);
    }
  }, [iframeRef, selectedContent?.type, selectedNodeEl]);

  const onDrop = useCallback(() => {
    onReset(false);
    const placeholderContainer = iframeRef?.current?.contentDocument?.getElementById('drag-placeholder');
    const originalPlacementContainer =
      iframeRef?.current?.contentDocument?.getElementById('original-placement-container');
    if (placeholderContainer) {
      // Get the child of placeholderContainer that is not the placeholder node
      const completeSelectedNode = Array.from(placeholderContainer.children).find(
        (child) => child.id !== 'drag-placeholder-node'
      ) as HTMLElement;
      if (completeSelectedNode) {
        // Return selected node to its original position (we'll let the setState handle updating the content)
        originalPlacementContainer?.parentNode?.insertBefore(completeSelectedNode, originalPlacementContainer);

        // Reset the display style to its original value
        if (originalSelectedNodeStyles.current?.display) {
          completeSelectedNode.style.display = originalSelectedNodeStyles.current.display;
        } else {
          completeSelectedNode.style.removeProperty('display');
        }
      }
      placeholderContainer.remove();
      originalPlacementContainer?.remove();
    }

    if (selectedNodeEl && content && selectedContent) {
      setContent((prev) => {
        if (!prev) return prev;

        const originalParent = getParent(prev, selectedContent);
        const hoverParent = dragOverData ? getNodeByID(prev, dragOverData.parentID) : null;

        if (!originalParent || !('content' in originalParent) || !originalParent.content) return prev;
        const originalIndex =
          originalParent?.content?.findIndex((child) => child.attrs?.id === selectedContent.attrs?.id) ?? -1;

        if (!hoverParent || !dragOverData) {
          // replace content back to where it was
          return prev;
        }

        if (originalParent.attrs?.id === hoverParent.attrs?.id) {
          const newContent = arrayMove(originalParent.content, originalIndex, dragOverData.index);

          const updatedContent = updateNodeContent(prev, originalParent, newContent);
          addChangesToStack(prev, updatedContent);

          return updatedContent;
        }

        const hoverParentContent = ((hoverParent as NavbarNodeWithContent)?.content as NavbarSerializableNode[]) || [];
        const newHoverParentContent = [
          ...hoverParentContent.slice(0, dragOverData.index),
          selectedContent,
          ...hoverParentContent.slice(dragOverData.index),
        ];

        const originalParentContent = (originalParent.content as NavbarSerializableNode[]).filter(
          (item) => item.attrs?.id !== selectedContent.attrs?.id
        );

        const updateContentWithHover = updateNodeContent(prev, hoverParent, newHoverParentContent);
        const updateContentWithOriginal = updateNodeContent(
          updateContentWithHover,
          originalParent,
          originalParentContent
        );

        addChangesToStack(prev, updateContentWithOriginal);

        return updateContentWithOriginal;
      });
    }
  }, [content, dragOverData, iframeRef, onReset, selectedContent, selectedNodeEl, addChangesToStack]);

  const restoreEmptyColumnState = useCallback(() => {
    const placeholderElements = iframeRef?.current?.contentDocument?.querySelectorAll('.navbar-column-empty-state');
    placeholderElements?.forEach((element) => {
      (element as HTMLElement).style.setProperty('display', 'flex');
    });
  }, [iframeRef]);

  const onDragMove = useCallback(
    (event: React.DragEvent) => {
      if (event.clientX < 0 || event.clientY < 0) return;
      if (!content) return;
      if (!selectedContent) return;
      if (!selectedNodeEl) return;

      const placeholderContainer = iframeRef?.current?.contentDocument?.getElementById('drag-placeholder');
      if (!placeholderContainer) return;

      let currentNode = placeholderContainer;
      let parentNode = placeholderContainer?.parentNode as HTMLElement;

      if (parentNode && !Array.from(parentNode.classList).some((className) => className.startsWith('dream-'))) {
        // Some components are wrapped with <div> from radix and so the real parent are the one above it.
        currentNode = parentNode;
        parentNode = parentNode.parentNode as HTMLElement;
      }

      if (!parentNode) return;

      const parentClass = Array.from(parentNode.classList).find((className) => className.startsWith('dream-'));
      const eligibleDropParents = iframeRef?.current?.contentDocument?.querySelectorAll(`[class*="${parentClass}"]`);

      if (!eligibleDropParents) return;
      // Get the current mouse position and drag preview container dimensions
      let mouseX = event.clientX;
      let mouseY = event.clientY;

      if (!isDraggingInsideIframe && iframeRef?.current) {
        // Get iframe scale transform if any
        const iframeTransform = window.getComputedStyle(iframeRef.current as Element).transform;
        const matrix = new DOMMatrix(iframeTransform);
        const scale = matrix.m11; // Get scale factor from transform matrix
        const iframeLeft = iframeRef?.current?.getBoundingClientRect()?.left ?? 0;
        const iframeTop = iframeRef?.current?.getBoundingClientRect()?.top ?? 0;

        // Adjust mouse position to be relative to the iframe
        mouseX -= iframeLeft;
        mouseY -= iframeTop;

        // Adjust coordinates by scale
        mouseX /= scale;
        mouseY /= scale;

        // Round to the nearest integer
        mouseX = Math.round(mouseX);
        mouseY = Math.round(mouseY);
      }

      // Calculate the effective area for hovering
      let hoverLeft = mouseX;
      let hoverRight = mouseX;
      let hoverTop = mouseY;
      let hoverBottom = mouseY;

      if (dragPreviewSize) {
        const { width, height, orientation } = dragPreviewSize;
        if (orientation === 'horizontal') {
          hoverLeft = mouseX - width / 2;
          hoverRight = mouseX + width / 2;
          hoverTop = mouseY - height - 10;
          hoverBottom = mouseY;
        } else {
          hoverLeft = mouseX - 10;
          hoverRight = mouseX + width;
          hoverTop = mouseY - height / 2;
          hoverBottom = mouseY + height / 2;
        }
      }

      // Find the eligible drop parent that the cursor or drag preview is over
      const hoveredParent = Array.from(eligibleDropParents).find((element) => {
        const rect = (element as HTMLElement).getBoundingClientRect();
        return hoverLeft < rect.right && hoverRight > rect.left && hoverTop < rect.bottom && hoverBottom > rect.top;
      });

      // Update hoveredParent style to light purple
      // Reset all parents' background color to default
      eligibleDropParents.forEach((parent) => {
        (parent as HTMLElement).removeAttribute('data-hover-parent');
        (parent as HTMLElement).classList.remove('outline-dashed', 'outline-1', 'outline-violet-400');
      });

      // Update the hovered parent's background color
      if (hoveredParent) {
        (hoveredParent as HTMLElement).setAttribute('data-hover-parent', 'true');
        (hoveredParent as HTMLElement).classList.add('outline-dashed', 'outline-1', 'outline-violet-400');
      }

      // If we found a new parent to drop into, move the placeholder
      if (hoveredParent && hoveredParent !== parentNode) {
        hoveredParent.appendChild(currentNode);
        parentNode = hoveredParent as HTMLElement;
      }

      let siblingBefore = currentNode.previousElementSibling;
      if (siblingBefore && siblingBefore.id === 'original-placement-container') {
        siblingBefore = siblingBefore.previousElementSibling;
      }
      let siblingAfter = currentNode.nextElementSibling;
      if (siblingAfter && siblingAfter.id === 'original-placement-container') {
        siblingAfter = siblingAfter.nextElementSibling;
      }
      const parentContent = getParent(content, selectedContent);
      const parentOrientation = parentContent ? getParentOrientation(parentContent) : 'vertical';

      if (siblingBefore && (siblingBefore as HTMLElement).classList.contains('navbar-column-empty-state')) {
        (siblingBefore as HTMLElement).style.display = 'none';
        siblingBefore = null;
      } else if (siblingAfter && (siblingAfter as HTMLElement).classList.contains('navbar-column-empty-state')) {
        (siblingAfter as HTMLElement).style.display = 'none';
        siblingAfter = null;
      } else {
        restoreEmptyColumnState();
      }

      if (!siblingAfter && !siblingBefore) {
        parentNode.appendChild(currentNode);
      } else if (parentOrientation === 'horizontal') {
        if (siblingBefore && mouseX < siblingBefore.getBoundingClientRect().right) {
          siblingBefore.insertAdjacentElement('beforebegin', currentNode);
        } else if (siblingAfter && mouseX > siblingAfter.getBoundingClientRect().left) {
          siblingAfter.insertAdjacentElement('afterend', currentNode);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (siblingBefore && mouseY < siblingBefore.getBoundingClientRect().bottom) {
          siblingBefore.insertAdjacentElement('beforebegin', placeholderContainer);
        } else if (siblingAfter && mouseY > siblingAfter.getBoundingClientRect().top) {
          siblingAfter.insertAdjacentElement('afterend', placeholderContainer);
        }
      }

      setDragOverData({
        parentID: parentNode.getAttribute('data-element-id') || '',
        index: Array.from(parentNode.children)
          .filter((child) => !child.classList.contains('navbar-add-item-button'))
          .indexOf(placeholderContainer),
      });
    },
    [
      content,
      selectedContent,
      selectedNodeEl,
      iframeRef,
      dragPreviewSize,
      restoreEmptyColumnState,
      isDraggingInsideIframe,
    ]
  );

  const onDrag = useCallback(
    (event: React.DragEvent) => {
      if (isDragging) {
        onDragMove(event);
      }
    },
    [onDragMove, isDragging]
  );

  useEffect(() => {
    onResetDragAndSelectionBox();
  }, [contentWidth, onResetDragAndSelectionBox]);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleDragOver = (e: DragEvent): void => {
      e.preventDefault();
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'move'; // or 'copy', 'link', 'none'
      }
      iframe?.contentDocument?.body?.classList?.add('cursor-grabbing');
    };

    const handleDrop = (e: DragEvent): void => {
      e.preventDefault();
      onDrop();
    };

    const handleClickOutside = (): void => {
      onReset(true);
    };

    const handleDragEnterIframe = (): void => {
      setIsDraggingInsideIframe(true);
    };

    const handleDragLeaveIframe = (): void => {
      setIsDraggingInsideIframe(false);
    };

    const addEventListeners = (): void => {
      if (iframe?.contentDocument) {
        iframe.contentDocument.addEventListener('dragover', handleDragOver);
        iframe.contentDocument.addEventListener('drop', handleDrop);
        iframe.contentDocument.addEventListener('click', handleClickOutside);
        iframe.contentDocument.addEventListener('dragover', handleDragEnterIframe);
        iframe.contentDocument.addEventListener('dragleave', handleDragLeaveIframe);
      }

      // Add drop handler to main document as well
      document.addEventListener('drop', handleDrop);
    };

    // Add listeners to the main document
    document.addEventListener('dragover', handleDragOver);

    // If the iframe is already loaded, add event listeners immediately
    if (iframe?.contentDocument?.readyState === 'complete') {
      addEventListeners();
    } else {
      // Otherwise, wait for the iframe to load
      iframe?.addEventListener('load', addEventListeners);
    }

    return () => {
      document.removeEventListener('dragover', handleDragOver);
      if (iframe?.contentDocument) {
        iframe.contentDocument.removeEventListener('dragover', handleDragOver);
        iframe.contentDocument.removeEventListener('drop', handleDrop);
        iframe.contentDocument.removeEventListener('click', handleClickOutside);
        iframe.contentDocument.removeEventListener('dragenter', handleDragEnterIframe);
        iframe.contentDocument.removeEventListener('dragleave', handleDragLeaveIframe);
      }
      document.removeEventListener('drop', handleDrop);
      iframe?.removeEventListener('load', addEventListeners);
    };
  }, [iframeRef, onDrop, onReset]);

  const onUpdateNodeAttributes = useCallback(
    (nodeID: string, attrs: Record<string, any>) => {
      if (!content) return;

      flushSync(() => {
        setContent((prev) => {
          if (!prev) return prev;
          const newContent = updateNodeAttributes(prev, nodeID, attrs);
          addChangesToStack(prev, newContent);
          return newContent;
        });
      });
    },
    [content, addChangesToStack]
  );

  const onDeleteNode = useCallback(
    (nodeID: string) => {
      if (!content) return;

      flushSync(() => {
        setContent((prev) => {
          if (!prev) return prev;
          const newContent = deleteNode(prev, nodeID);

          if (!newContent) return newContent;
          addChangesToStack(prev, newContent);

          return newContent;
        });
      });
    },
    [content, addChangesToStack]
  );

  const onUpdateNodeContent = useCallback(
    (nodeID: string, nodeContent: NavbarSerializableNode[]) => {
      if (!content) return;

      flushSync(() => {
        setContent((prev) => {
          if (!prev) return prev;
          const node = getNodeByID(prev, nodeID);
          if (!node) return prev;
          const newContent = updateNodeContent(prev, node, nodeContent);
          addChangesToStack(prev, newContent);
          return newContent;
        });
      });
    },
    [content, addChangesToStack]
  );

  const withMerging = useCallback((fn: () => any | void, merge = true) => {
    const prev = isMerging.current;
    isMerging.current = merge;

    // We need the undefined here to prevent it from merging to previous deltas
    // that is not part of this sequence of changes.
    setUndoStack((prevUndoStack) => {
      return [...prevUndoStack, undefined];
    });

    fn();

    isMerging.current = prev;
  }, []);

  const contextValue = useMemo(
    () => ({
      content,
      setContent,
      selectedContent,
      selectedNodeEl,
      onSelectNode,
      hoverNodeEl,
      hoveredContent,
      onHoverNode,
      iframeRef,
      editorContainerRef,
      onDragStart,
      onDrag,
      isDragging,
      dragPreviewSize,
      setDragPreviewSize,
      onUpdateNodeAttributes,
      onUpdateNodeContent,
      onDeleteNode,
      onCreateNavbar,
      isSelectedNodeDraggable,
      hoveredContentID,
      contentWidth,
      isMobile: contentWidth < 500,
      withMerging,
      undo,
      redo,
    }),
    [
      content,
      selectedContent,
      selectedNodeEl,
      onSelectNode,
      hoverNodeEl,
      hoveredContent,
      onHoverNode,
      iframeRef,
      editorContainerRef,
      onDragStart,
      onDrag,
      isDragging,
      dragPreviewSize,
      onUpdateNodeAttributes,
      onUpdateNodeContent,
      onDeleteNode,
      onCreateNavbar,
      isSelectedNodeDraggable,
      hoveredContentID,
      contentWidth,
      withMerging,
      undo,
      redo,
    ]
  );

  return <NavbarContext.Provider value={contextValue}>{children}</NavbarContext.Provider>;
};

export const useNavbarContext = () => {
  const context = React.useContext(NavbarContext);
  if (context === undefined) {
    throw new Error('useNavbarContext must be used within a NavbarProvider');
  }
  return context;
};
