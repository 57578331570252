import { useSettings } from '@/context/settings-context';
import { useOnboarding } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import Onboarding from '@/pages/Dashboard/Onboarding';
import Dashboardv2 from '@/pages/Dashboard/v2';

const Dashboard = () => {
  const requiredSteps = [
    'customize_newsletter_completed',
    'discover_editor_completed',
    'explore_website_builder_completed',
    'finish_publication_settings_completed',
    'import_prior_publication_completed',
    'start_publication_completed',
  ];

  const settingsData = useSettings();
  const publicationId = useCurrentPublicationId();
  const { data: currentPublication } = usePublication(publicationId);
  const { data: onboarding } = useOnboarding(publicationId, currentPublication?.onboarding_id);
  const onboardingComplete = () => {
    return requiredSteps.every((step) => !!onboarding?.[step as keyof typeof onboarding]);
  };
  const showOnboarding = settingsData?.settings?.react_app_onboarding_v4_enabled === true && !onboardingComplete();

  if (showOnboarding) {
    return <Onboarding />;
  }

  return <Dashboardv2 />;
};

export default Dashboard;
