import { useCallback } from 'react';

import { WebTheme } from '@/interfaces/web_theme';

import TemplatePreviewer from '../../_components/Templates/TemplatePreviewer';
import { ColorSelection } from '../../settings/themes/_components/ColorSelection';
// import { FontSelection } from '../../settings/themes/_components/FontSelection';
import { RadiusSelection } from '../../settings/themes/_components/RadiusSelection';

import Heading from './Heading';

interface Props {
  previewNavbar: any;
  previewContent: any;
  previewFooter: any;
  onNext: () => void;
  onPrevious?: () => void;
  style: WebTheme;
  setStyle: (style: WebTheme) => void;
  title?: string;
  description?: string;
}

const Step2 = ({
  previewNavbar,
  previewContent,
  previewFooter,
  onNext,
  onPrevious,
  style,
  setStyle,
  title,
  description,
}: Props) => {
  const handleSetColor = useCallback(
    (key: string, value: string) => {
      setStyle({ ...style, [key]: value });
    },
    [style, setStyle]
  );

  const handleSetRadius = useCallback(
    (value: number) => {
      setStyle({ ...style, border_radius: `${value}px` });
    },
    [style, setStyle]
  );

  return (
    <div className="w-full flex flex-col gap-8">
      <Heading
        title={title || 'Design the basics'}
        description={description || 'You can always change these any time in the editor'}
        onNext={onNext}
        onPrevious={onPrevious}
      />

      <div className="grid grid-cols-12 gap-16">
        <div className="col-span-8">
          <TemplatePreviewer
            navbarContent={previewNavbar as any}
            content={previewContent as any}
            footerContent={previewFooter as any}
            containerHeightClass="h-[500px]"
            enableScroll
            disableMemo
            hasBrowserBar
            allowClicking={false}
          />
        </div>

        <div className="col-span-4">
          <div className="flex flex-col gap-4">
            <ColorSelection
              title="Primary Color"
              initialColor={style?.primary_color || ''}
              onSetColor={(value) => handleSetColor('primary_color', value || '')}
              side="bottom"
              offset={10}
            />
            <ColorSelection
              title="Text on Primary Color"
              initialColor={style?.text_on_primary_color || ''}
              onSetColor={(value) => handleSetColor('text_on_primary_color', value || '')}
              side="bottom"
              offset={10}
            />
            <ColorSelection
              title="Background Color"
              initialColor={style?.background_color || ''}
              onSetColor={(value) => handleSetColor('background_color', value || '')}
              side="bottom"
              offset={10}
            />
            <ColorSelection
              title="Text on Background Color"
              initialColor={style?.text_on_background_color || ''}
              onSetColor={(value) => handleSetColor('text_on_background_color', value || '')}
              side="bottom"
              offset={10}
            />
            <ColorSelection
              title="Border Color"
              initialColor={style?.border_color || ''}
              onSetColor={(value) => handleSetColor('border_color', value || '')}
              side="bottom"
              offset={10}
            />
            <RadiusSelection title="Border Radius" min={0} max={16} unit="px" step={1} onSetRadius={handleSetRadius} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
