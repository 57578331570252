import { useNavigate } from 'react-router-dom';

import { ActionProps } from '@/components/PricingPanel';
import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { useCurrentPublication } from '@/hooks';
import { PlanPrice } from '@/interfaces/plan_price';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';
import { PLAN } from '@/utils/plans';

const LaunchButton: React.FC<ActionProps & { planPrice: PlanPrice }> = ({
  disabled,
  newPlanPriceOption,
  planPrice,
}) => {
  const { currentUser } = useCurrentUser();
  const { data: currentPublication, isLoading: isLoadingPublication } = useCurrentPublication();
  const navigate = useNavigate();

  const hasPublicationSettingsV2 = currentUser?.has_settings_v2;
  const isCurrentPlanPrice = planPrice?.plan_name === PLAN.LAUNCH;
  const downgradRedirectPath =
    hasPublicationSettingsV2 && currentPublication
      ? '/settings/workspace/billing_and_plan/downgrade'
      : '/settings/billing/downgrade';

  const onClick = () => {
    if (newPlanPriceOption && currentUser) {
      analytics.identify(currentUser.id);
      analytics.track('Selected Plan', {
        subscriber_limit: 2500,
        frequency: 'monthly',
        stripe_price_id: newPlanPriceOption.stripe_price_id,
        price: 0,
      });
    }

    navigate(downgradRedirectPath);
  };

  return (
    <div className="relative h-full flex flex-col justify-end py-6">
      <Button
        type="button"
        disabled={disabled || isCurrentPlanPrice || isLoadingPublication}
        variant="primary-inverse"
        onClick={onClick}
      >
        {isCurrentPlanPrice ? 'Your Current Plan' : 'Downgrade to Launch'}
      </Button>
      {!isCurrentPlanPrice && (
        <Typography as="div" token="font-light/text/xs" colorWeight="500" className="absolute bottom-0">
          * Sending limited to 2,500 subscribers
        </Typography>
      )}
    </div>
  );
};

export default LaunchButton;
