import React from 'react';

import LoadingBox from '@/components/LoadingBox';
import { useCurrentPublication, useOrganization } from '@/hooks';
import { Card } from '@/ui/Card';

import PageContainer from '../../_components/PageContainer';

import List from './List';

const WorkspaceTeam: React.FC = () => {
  const { data: currentPublication } = useCurrentPublication();
  const {
    data: organizationData,
    isLoading: isLoadingOrgnanization,
    isError: isErrorOrgnanization,
  } = useOrganization(currentPublication?.organization_id, currentPublication?.id || '');
  const { organization } = organizationData || {};

  if (!currentPublication) {
    return null;
  }

  return (
    <LoadingBox isLoading={isLoadingOrgnanization} isError={isErrorOrgnanization}>
      {organization ? (
        <PageContainer key={`${organization.id}-${currentPublication.id}`} isWide>
          <Card>
            <List organization={organization} publicationId={currentPublication.id} />
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default WorkspaceTeam;
