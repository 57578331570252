import { useCallback, useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { JSONContent } from '@tiptap/core';

import { Button } from '../../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../UI/Dialog';
import { Text } from '../../UI/Text';

type Props = {
  content: JSONContent;
  onUpdate?: (content?: JSONContent) => void;
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
};

export const AdminJsonEditor = ({ content, onUpdate, isOpen, onClose, isLoading }: Props) => {
  const [structure, setStructure] = useState(content);
  const [editorInstance, setEditorInstance] = useState<any>();

  // We only want this to ensure the data is fresh when the dialog state is changed
  useEffect(() => {
    if (!structure) {
      setStructure(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleFormat = () => {
    editorInstance?.getAction('editor.action.formatDocument').run();
  };

  const handleReset = () => {
    editorInstance?.getModel()?.setValue(JSON.stringify(content));
    setStructure(content);
    handleFormat();
  };

  const handleDownload = () => {
    const blob = new Blob([JSON.stringify(structure)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'structure.json';
    a.click();
  };

  const handleUpdate = useCallback(() => {
    if (!content) return;

    // get the editor instance value
    const value = editorInstance?.getModel()?.getValue();

    onUpdate?.(value);
  }, [editorInstance, onUpdate, content]);

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="w-[100vw] max-w-none h-[100vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-end justify-between ">
              <div className="flex flex-col gap-2">
                <Text size="xl" weight="semibold" variant="primary" as="h4">
                  Structure
                </Text>
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="flex gap-8">
          <div className="flex flex-col gap-2 w-full">
            <div className="rounded-lg overflow-hidden w-full">
              <Editor
                height="90vh"
                width="100%"
                defaultLanguage="json"
                value={JSON.stringify(structure)}
                theme="vs-dark"
                options={{
                  formatOnType: true,
                  formatOnPaste: true,
                  tabSize: 2,
                }}
                onMount={(e: any) => {
                  setEditorInstance(e);
                  e.getAction('editor.action.formatDocument').run();
                }}
              />
            </div>
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
          <div className="flex gap-2 justify-between w-full">
            <Button variant="outlined" onClick={handleReset}>
              Reset
            </Button>
            <div className="flex gap-2">
              <Button variant="outlined" onClick={handleDownload}>
                Download Copy Before Editing
              </Button>
              <Button variant="outlined" onClick={handleFormat}>
                Format JSON
              </Button>
              <Button variant="primary" onClick={handleUpdate} isDisabled={isLoading}>
                {isLoading ? 'Applying...' : 'Apply'}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
