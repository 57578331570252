import { memo, useCallback, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { sticky } from 'tippy.js';
import { v4 as uuid } from 'uuid';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';

import { ColumnsOptionsPanel } from './ColumnsOptionsPanel';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const ColumnsMenu = memo(({ editor, appendTo }: MenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOptionsPanelOpen, setIsOptionsPanelOpen] = useState<boolean>(false);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'columns');

    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    return editor.isActive('columns');
  }, [editor]);

  const removeNode = useCallback(() => {
    editor.chain().focus().deleteNode('columns').run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`columnsMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => appendTo?.current,
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar ref={menuRef}>
        <Tippy
          render={(attrs) => isOptionsPanelOpen && <ColumnsOptionsPanel editor={editor} {...attrs} />}
          popperOptions={{
            modifiers: [{ name: 'flip', enabled: false }],
          }}
          placement="bottom"
          reference={menuRef?.current}
          offset={[0, 4]}
          trigger="click"
          interactive
          onShow={() => {
            setIsOptionsPanelOpen(true);
          }}
          onHidden={() => {
            setIsOptionsPanelOpen(false);
          }}
          onClickOutside={() => {
            setIsOptionsPanelOpen(false);
          }}
        >
          <div>
            <Tooltip enabled={!isOptionsPanelOpen} title="Options">
              <Button
                $leftSlot={<Icon name="Settings" />}
                $rightSlot={<Icon name="ChevronDown" $size="0.66rem" />}
                $active={isOptionsPanelOpen}
                $muted={isOptionsPanelOpen}
                onClick={() => {
                  setIsOptionsPanelOpen((prev) => !prev);
                }}
                {...buttonProps}
              />
            </Tooltip>
          </div>
        </Tippy>
        <Divider />
        <Tooltip title="Remove columns">
          <Button $leftSlot={<Icon name="Trash" />} onClick={removeNode} {...buttonProps} />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
});

export default ColumnsMenu;
