import { useQuery } from 'react-query';

import api from '../../../services/swarm';

const useAdvertisers = ({ enabled }: { enabled?: boolean } = { enabled: true }) =>
  useQuery(['advertisers'], () => api.get(`/ad_portal/advertisers`).then((res) => res.data), {
    enabled,
  });

export default useAdvertisers;
