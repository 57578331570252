import { useCallback, useMemo, useState } from 'react';
import { ArrowLeft, MagnifyingGlass, X } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { useDebounce } from 'use-debounce';

import useInfinitePosts from '@/hooks/useDreamBuilder/useInfinitePosts';
import { Post } from '@/interfaces/dream_builder/post';
import Callout from '@/routes/website/_components/UI/Callout';
import useIsPageCheck from '@/routes/website/_hooks/useIsPageCheck';

import { cn } from '../../../../../_utils/cn';
import { Button } from '../../../../UI/Button';
import { Checkbox } from '../../../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../../../UI/Dialog';
import { Input, NumberInput } from '../../../../UI/Input';
import { Label } from '../../../../UI/Label';
import { Switch } from '../../../../UI/Switch';
import { Text } from '../../../../UI/Text';
import { Tooltip } from '../../../../UI/Tooltip';
import { dummyPosts } from '../../../dummyData/posts';
import useSetPostGroupType from '../hooks/useSetPostGroupType';
import useShouldNotScrollHorizontally from '../hooks/useShouldNotScrollHorizontally';
import { PostSelectionType } from '../types';

import PostTypeSelect from './PostTypeSelect';

const title = 'Select posts to display';

const callout: Record<string, { title: string; description: string }> = {
  latest: {
    title: 'Previewing Latest Posts',
    description: `We'll display the latest posts that you have published.`,
  },
  archive: {
    title: 'Previewing Archive Posts',
    description: `We'll display all of your posts that you have published.`,
  },
  categories: {
    title: 'Previewing Posts by Category',
    description: `We'll display all of your posts that match the selected category.`,
  },
  tag_page: {
    title: 'Previewing Posts by Tag',
    description: `We'll display all of your posts that match the selected tag.`,
  },
  author_page: {
    title: 'Previewing Posts by Author',
    description: `We'll display all of your posts that match the selected author.`,
  },
  recommendations: {
    title: 'Previewing Recommended Posts',
    description: `We'll display all of your recommended posts.`,
  },
};

const PostsSelectionModal = ({
  editor,
  node,
  isOpen,
  onClose,
  pos,
}: {
  editor: Editor;
  node: ProseMirrorNode;
  isOpen: boolean;
  onClose: () => void;
  pos: number;
}) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [selectedPosts, setSelectedPosts] = useState<Post[]>(node.attrs.data.posts || []);
  const [isLayoutOpen, setIsLayoutOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(node.attrs.postsCategory || null);
  const [postType, setPostType] = useState<PostSelectionType>(node.attrs.postGroupType || 'free_selection');

  const [count, setCount] = useState(node.attrs.postsCount || 5);
  const [showFakeData, setShowFakeData] = useState(node.attrs.hasFakeData || false);

  // We have special cases for tag, author, and archive pages. We don't want users to select different post types on these pages.
  const { isTagPageOrTemplate, isAuthorPageOrTemplate, isArchivePageOrTemplate } = useIsPageCheck();
  const isSelectDropdownDisabled = isTagPageOrTemplate || isAuthorPageOrTemplate || isArchivePageOrTemplate;

  useSetPostGroupType<PostSelectionType>({
    isPageOrTemplate: isTagPageOrTemplate,
    typeCheck: postType !== 'tag_page',
    typeToSet: 'tag_page',
    pos,
    editor,
    setPostType,
  });

  useSetPostGroupType({
    isPageOrTemplate: isAuthorPageOrTemplate,
    typeCheck: postType !== 'author_page',
    typeToSet: 'author_page',
    pos,
    editor,
    setPostType,
  });

  useSetPostGroupType({
    isPageOrTemplate: isArchivePageOrTemplate,
    typeCheck: postType !== 'archive',
    typeToSet: 'archive',
    pos,
    editor,
    setPostType,
  });

  const reset = () => {
    setSelectedPosts([]);
    setCount(5);
  };

  const { data } = useInfinitePosts({
    enabled: isOpen,
    tags: selectedCategory ? [selectedCategory] : undefined,
    q: debouncedSearch,
  });
  const posts = useMemo(() => {
    return showFakeData ? dummyPosts : data?.pages.flatMap((page) => page.posts) || [];
  }, [showFakeData, data]);

  const selectedPostsIds = useMemo(() => {
    return selectedPosts.map((post) => post.id);
  }, [selectedPosts]);

  const isArchive = postType === 'archive';
  const isLatest = postType === 'latest';
  const isAuthorPage = postType === 'author_page';
  const isTagPage = postType === 'tag_page';
  const isFreeSelection = postType === 'free_selection';
  const isCategorySelected = selectedCategory !== null;

  const getSelectedPosts = useCallback(() => {
    if (isLatest || isArchive) {
      return posts.slice(0, count);
    }

    if (isCategorySelected) {
      return selectedPosts.map((post, index) => ({
        ...post,
        content_tags: [{ id: `${postType}-${index}`, display: postType }],
      }));
    }

    if (isAuthorPage || isTagPage) {
      return posts;
    }

    return selectedPosts;
  }, [count, isArchive, isCategorySelected, isLatest, postType, posts, selectedPosts, isAuthorPage, isTagPage]);

  const shouldNotScrollHorizontally = useShouldNotScrollHorizontally({ postGroupType: postType });

  const handleSubmit = () => {
    editor?.commands.command(({ tr }) => {
      tr.setNodeAttribute(pos, 'insertedFromSidebar', false);
      tr.setNodeAttribute(pos, 'hasFakeData', showFakeData);
      tr.setNodeAttribute(pos, 'data', {
        posts: getSelectedPosts(),
      });
      tr.setNodeAttribute(pos, 'postsCount', count);

      if (shouldNotScrollHorizontally) {
        tr.setNodeAttribute(pos, 'shouldScrollHorizontally', false);
      }

      if (isCategorySelected) {
        tr.setNodeAttribute(pos, 'postGroupType', 'category');
        tr.setNodeAttribute(pos, 'postsCategory', selectedCategory);
      } else {
        tr.setNodeAttribute(pos, 'postGroupType', postType);
        tr.setNodeAttribute(pos, 'postsCategory', null);
      }
      return true;
    });

    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(value) => {
        if (!value) {
          onClose();
        }
      }}
    >
      <DialogContent className="w-[60vw] max-w-none h-[90vh] flex flex-col overflow-hidden p-0 gap-0">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2 p-4">
              {isLayoutOpen && <Button variant="ghost" onClick={() => setIsLayoutOpen(false)} LeftIcon={ArrowLeft} />}
              <Text size="xl" weight="semibold" variant="primary" as="h4">
                {title}
              </Text>
            </div>
          </DialogTitle>
        </DialogHeader>

        {/** PREFERENCES SECTION */}

        <div className="flex flex-col gap-4 px-4 py-2">
          {/** avoids fetching options when modal is closed */}
          {isOpen && (
            <div className="flex items-end gap-2">
              <Tooltip center="You can't select different categories for tag pages or templates">
                <div className={isSelectDropdownDisabled ? 'opacity-50 pointer-events-none' : ''}>
                  <PostTypeSelect
                    setSelectedCategory={setSelectedCategory}
                    selectedCategory={selectedCategory}
                    setPostType={(value) => {
                      setPostType(value);
                      reset();
                    }}
                    node={node}
                    editor={editor}
                    pos={pos}
                    postType={postType}
                    showFakeData={showFakeData}
                    isTagPageOrTemplate={isTagPageOrTemplate}
                    isAuthorPageOrTemplate={isAuthorPageOrTemplate}
                  />
                </div>
              </Tooltip>
              {(isLatest || isArchive) && (
                <NumberInput
                  name="post_count"
                  labelText="Post Count"
                  type="number"
                  value={count}
                  onChange={(e) => setCount(parseInt(e.target.value, 10))}
                  className="max-w-[120px]"
                  onClickMinus={() => setCount((newCount: number) => newCount - 1)}
                  onClickPlus={() => setCount((newCount: number) => newCount + 1)}
                />
              )}
            </div>
          )}
        </div>

        {isFreeSelection || isCategorySelected ? (
          <div className="flex flex-col gap-2 w-full px-4 py-2">
            <div className="flex gap-2 flex-wrap">
              {selectedPosts.map((post) => (
                <button
                  type="button"
                  key={post.id}
                  className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1"
                  onClick={() => setSelectedPosts(selectedPosts.filter((p) => p.id !== post.id))}
                >
                  <Text
                    weight="regular"
                    variant="accent"
                    size="3xs"
                    as="span"
                    className="line-clamp-1 max-w-[150px] truncate"
                  >
                    {post.web_title}
                  </Text>
                  <X className="w-3 h-3" />
                </button>
              ))}
            </div>
          </div>
        ) : null}

        {!isFreeSelection && (
          <div className="flex px-4 py-2 w-full">
            <Callout
              title={isCategorySelected ? 'Category preview' : callout[postType]?.title}
              className="w-full p-4 max-w-full"
            >
              {isCategorySelected
                ? 'We will display all posts that match the selected category.'
                : callout[postType]?.description}
            </Callout>
          </div>
        )}

        <div className="flex flex-1 flex-col gap-4 w-full min-h-0 px-4 py-2">
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold" variant="secondary" as="p">
              {isFreeSelection ? 'Posts to show' : 'Select posts to show'}
            </Text>

            <Input
              name="name"
              className="max-w-[250px]"
              LeftIcon={MagnifyingGlass}
              type="text"
              placeholder="Search posts"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="flex-1 overflow-y-auto no-scrollbar">
            {posts.length === 0 ? (
              <div className="flex items-center justify-center h-full py-20">
                <Text size="xl" weight="semibold" variant="secondary" as="h4">
                  No posts found
                </Text>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 pb-32">
                {posts.map((post: Post, index: number) => {
                  const canShowSelectedState = isFreeSelection || isCategorySelected;
                  const isSelected = selectedPostsIds.includes(post.id);

                  return (
                    <Label
                      key={post.id}
                      htmlFor={post.id}
                      className={cn(
                        'flex flex-col cursor-pointer border shadow p-0 rounded-md focus:outline-none relative overflow-hidden',
                        canShowSelectedState && isSelected ? 'border-wb-accent border-2' : 'border-wb-primary',
                        isLatest && index >= count ? 'opacity-50' : '',
                        isFreeSelection && !isCategorySelected ? 'cursor-auto' : 'cursor-pointer hover:shadow-md'
                      )}
                    >
                      <div className="w-full h-36 overflow-hidden">
                        <img
                          src={post.image_url}
                          alt={post.web_title}
                          className="w-full h-full object-cover aspect-video"
                        />
                      </div>
                      <div className="flex justify-between items-center gap-2 p-3">
                        <div className="flex flex-col">
                          <Text weight="semibold" variant="primary" size="xs" as="span" className="line-clamp-1">
                            {post.web_title}
                          </Text>
                          <Text weight="regular" variant="secondary" size="2xs" as="span" className="line-clamp-1">
                            {post.web_subtitle}
                          </Text>
                        </div>
                        {canShowSelectedState && (
                          <Checkbox
                            id={post.id}
                            checked={Boolean(isSelected)}
                            onCheckedChange={() => {
                              if (isSelected) {
                                setSelectedPosts(selectedPosts.filter((p) => p.id !== post.id));
                              } else {
                                setSelectedPosts([...selectedPosts, post]);
                              }
                            }}
                          />
                        )}
                      </div>
                    </Label>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center p-4 bg-white border-t border-wb-primary">
          <div className="flex items-center gap-2 justify-between w-full">
            <Tooltip center="Toggle off to switch between template placeholder data and your actual content. ">
              <Switch
                id="show-fake-data"
                checked={showFakeData}
                onCheckedChange={() => {
                  if (showFakeData) {
                    if (isArchive || isLatest) {
                      setCount(selectedPosts.length);
                    }
                    setSelectedPosts([]);
                  }
                  setShowFakeData(!showFakeData);
                }}
                labelText="Placeholder data"
                labelClassName="whitespace-nowrap"
              />
            </Tooltip>
            <div className="flex gap-2 w-full justify-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                {postType === 'free_selection' ? 'Select posts' : 'Save'}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PostsSelectionModal;
