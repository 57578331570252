import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { Option } from '@/interfaces/general';
import { Dropdown } from '@/ui/Dropdown';

export interface Props {
  savedFilterOptions: Option[];
  isOpen: boolean;
  isWorking: boolean;
  onClose: () => void;
  onProceed: (name: string, replacingFilterId?: string) => void;
}

const SaveBoostAgreementFilterModal = ({ savedFilterOptions, isOpen, isWorking, onClose, onProceed }: Props) => {
  const [name, setName] = useState('');
  const [selectedFilterId, setSelectedFilterId] = useState('');
  const actionText = selectedFilterId ? 'Replace filter' : 'Save filter';

  const handleSelectFilter = (_name: string, value: string) => {
    setName('');
    setSelectedFilterId(value);
  };

  const handleClose = () => {
    setName('');
    setSelectedFilterId('');
    onClose();
  }

  const handleProceed = () => {
    onProceed(name, selectedFilterId);
    setName('');
    setSelectedFilterId('');
  };

  return (
    <ActionModal
      resourceId="save-boost-agreemeent-filter"
      isOpen={isOpen}
      isWorking={isWorking}
      onClose={handleClose}
      onProceed={handleProceed}
      modalMessageType="info"
      headerText="Save active filters"
      actionText={actionText}
    >
      <div className="my-6 space-y-6">
        <Input
          name="filter-name"
          labelText="Filter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholderText="Enter filter name"
          helperText="Specify a name to easily identify this filter"
        />

        {savedFilterOptions.length > 0 && (
          <>
            <div>
              <Typography token="font-medium/text/sm" colorWeight='500'>or</Typography>
            </div>

            <Dropdown
              name="filters-popover-select"
              labelText="Select saved filter to replace"
              options={savedFilterOptions}
              value={selectedFilterId}
              placeholderText="Select saved filter"
              onSelect={handleSelectFilter}
              className="w-full"
            />
          </>
        )}
      </div>
    </ActionModal>
  );
};

export default SaveBoostAgreementFilterModal;
