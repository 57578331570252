import { useMemo } from 'react';
import { JSONContent } from '@tiptap/core';

import { Page } from '@/interfaces/dream_builder/page';

interface Props {
  page: Page;
}

function traverseContentAndFindPlaceholderData(content: JSONContent): boolean {
  if (!content || typeof content !== 'object') return false;

  if ((content.attrs && content.attrs.hasFakeData === true) || content.attrs?.hasFakeData === 'true') {
    return true;
  }

  if (Array.isArray(content.content)) {
    return content.content.some((child: JSONContent) => traverseContentAndFindPlaceholderData(child));
  }

  return false;
}
const useIdentifyPlaceholderData = ({ page }: Props) => {
  const hasPlaceholderData = useMemo(() => {
    return traverseContentAndFindPlaceholderData(page?.draft_page_version?.content);
  }, [page]);

  return hasPlaceholderData;
};

export default useIdentifyPlaceholderData;
