import { Link } from 'react-router-dom';

import { PublicationLogo } from '@/components/PublicationLogo';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import useOfferStatus from '@/hooks/boosts/monetize/useOfferStatus';
import useAffiliateLink from '@/hooks/boosts/useAffiliateLink';
import { BoostPublicationWithBoostAgreement } from '@/interfaces/boosts/monetize/boost_publication_with_boost_agreement';

import AgreementAndSendActions from './AgreementAndSendActions';
import BoostAgreementStatusBadge from './BoostAgreementStatusBadge';

interface Props {
  publication: BoostPublicationWithBoostAgreement;
  onActionSuccess: () => void;
}

const BoostedPublicationListItem = ({ publication, onActionSuccess }: Props) => {
  const { settings } = useSettings();

  const { handleCopyAffiliateLink } = useAffiliateLink(publication.url);
  const boostAgreement = publication?.actionable_boost_agreement || publication?.latest_boost_agreement;
  const boostOffer = boostAgreement?.boost_offer;
  const { payout } = useOfferStatus(boostOffer);
  const hasBoostsPlus = settings?.boosts_plus === true;

  return (
    <ItemRow className="bg-white">
      <ItemColumn align={AlignType.TOP}>
        <Link to={`/monetize/boosts/boosted_publication/${publication.id}`} className="flex items-center gap-x-2">
          <PublicationLogo publication={publication} size="sm" />
          <Typography weight="medium" colorWeight="900">
            {publication.name}
          </Typography>
        </Link>
      </ItemColumn>
      <ItemColumn>
        <BoostAgreementStatusBadge status={boostAgreement.status} />
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {payout}
        </Typography>
      </ItemColumn>
      {hasBoostsPlus && (
        <ItemColumn>
          <Typography weight="medium" size="sm" colorWeight="700">
            {boostAgreement.calc_max_payout}
          </Typography>
        </ItemColumn>
      )}
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_pending_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_accepted_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_subscribers}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_pending_payments}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {publication.top_line_metrics?.total_payments}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <AgreementAndSendActions
          boostAgreement={boostAgreement}
          onAgreementUpdateSuccess={onActionSuccess}
          onSendUpdateSuccess={onActionSuccess}
          onCopyAffiliateLink={handleCopyAffiliateLink}
        />
      </ItemColumn>
    </ItemRow>
  );
};

export default BoostedPublicationListItem;
