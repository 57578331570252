import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Switch from '@/ui/Switch';

import ActionModal from '../../../../../../components/ActionModal';
import { useSettings } from '../../../../../../context/settings-context';
import { useUpdateWebTemplate } from '../../../../../../hooks';
import { useCurrentPublication } from '../../../../../../hooks/usePublications';
import { SignupFlowItem, WebTemplate } from '../../../../../../interfaces/web_template';

import FlowStep from './FlowStep';
import { UpdateMessage, useUpdateMessage } from './UpdateMessage';

interface Props {
  step: SignupFlowItem;
  webTemplate: WebTemplate;
}

const UpgradeStep = ({ step, webTemplate }: Props) => {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
  const hasPremiumPlan = settings?.premium_subscriptions;
  const isPremiumEnabled = currentPublication?.is_premium_enabled;

  const webTemplateMutation = useUpdateWebTemplate();
  const signupMessaging = webTemplate?.signup_flow_messaging;

  const {
    isOpen: isMessageModalOpen,
    message,
    handleClose: handleCloseMessageModal,
    handleOpen: handleOpenMessageModal,
    onChange,
  } = useUpdateMessage({ message: signupMessaging?.upgrade_message || '' });

  const addUpgradeRedirect = async () => {
    await webTemplateMutation.mutateAsync({ enable_upgrade_page_post_signup: true });
    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
    setIsModalOpen(false);
  };

  const removeUpgradePage = async () => {
    await webTemplateMutation.mutateAsync({ enable_upgrade_page_post_signup: false });
    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
    setIsDisableModalOpen(false);
  };

  const handleUpdateMessage = async () => {
    await webTemplateMutation.mutateAsync({
      signup_flow_messaging: JSON.stringify({
        ...signupMessaging,
        upgrade_message: message,
      }),
    });

    queryClient.invalidateQueries(['web_template', currentPublication?.id]);
    toast.success('Saved!');
    handleCloseMessageModal();
  };

  if (!isPremiumEnabled) {
    return (
      <>
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          resourceId=""
          headerText={
            hasPremiumPlan ? 'You need to enable paid subscriptions first!' : 'You need to upgrade your plan first!'
          }
          onProceed={
            hasPremiumPlan
              ? () => navigate('/monetize/subscriptions/paid_tiers')
              : () => navigate('/settings/workspace/billing_and_plan')
          }
          isWorking={webTemplateMutation.isLoading}
          actionText={hasPremiumPlan ? "Let's do it!" : 'Upgrade Current Plan'}
        >
          <div className="text-sm space-y-4">
            {hasPremiumPlan ? (
              <p>
                Paid subscriptions need to be turned on for this publication before adding this redirect to the
                subscribe flow.
              </p>
            ) : (
              <p>
                You need to upgrade your plan to add this redirect to the subscribe flow. You can upgrade your plan by
                clicking the button below.
              </p>
            )}
          </div>
        </ActionModal>
        <FlowStep title={step.title} url={step.full_url} enabled={step.enabled}>
          <Switch name="upgrade" checked={step.enabled} onChange={() => setIsModalOpen(true)} variant="primary" />
        </FlowStep>
      </>
    );
  }

  return (
    <>
      {/* Disable Action Modal */}
      <ActionModal
        isOpen={isDisableModalOpen}
        onClose={() => setIsDisableModalOpen(false)}
        resourceId=""
        headerText={step.modal_header}
        onProceed={removeUpgradePage}
        isWorking={false}
        actionText={step.action_text}
      >
        <div className="text-sm space-y-4">{step.modal_body}</div>
      </ActionModal>

      {/* Enable Action Modal */}
      <ActionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        resourceId=""
        headerText="Add Upgrade Redirect"
        onProceed={addUpgradeRedirect}
        isWorking={webTemplateMutation.isLoading}
        actionText="Confirm"
      >
        <div className="text-sm space-y-4">
          <p>This will add back the upgrade page to the signup flow. Would you like to proceed?</p>
        </div>
      </ActionModal>

      {/** Custom Page Message Modal */}
      <UpdateMessage
        isOpen={isMessageModalOpen}
        onClose={handleCloseMessageModal}
        isLoading={false}
        message={message}
        onProceed={handleUpdateMessage}
        name={step?.title?.split(' ')?.join('_')?.toLowerCase()}
        onChange={onChange}
      />

      <FlowStep title={step.title} url={step.full_url} enabled={step.enabled} onUpdateMessage={handleOpenMessageModal}>
        <Switch
          name="upgrade"
          checked={step.enabled}
          onChange={() => {
            if (step.enabled) return setIsDisableModalOpen(true);

            return setIsModalOpen(true);
          }}
          variant="primary"
        />
      </FlowStep>
    </>
  );
};

export default UpgradeStep;
