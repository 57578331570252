import { useMemo } from 'react';
import { PlusCircle } from '@phosphor-icons/react';
import {
  NAVBAR_MENU_ITEM_LINK_DEFAULTS,
  NavbarSerializableNode,
  TNavbarMenuListElement,
} from '@shared/dream-components';
import { v4 as uuidv4 } from 'uuid';

import { useSite } from '@/hooks/useSite';
import { useSiteThemes } from '@/hooks/useSiteThemes';
import { cn } from '@/utils/cn';

import { useNavbarContext } from '../NavbarContext';
import { applyThemeToNode, getParent } from '../utils';

// Simple function to check if color is light or dark
const useIsLight = (hexColor: string): boolean => {
  return useMemo(() => {
    // Remove # if present
    const hex = hexColor.replace('#', '');

    // Convert to RGB
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // HSP equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using 127.5 as medium point
    return hsp > 127.5;
  }, [hexColor]);
};

export const AddNavbarItemButton = ({
  node,
  position,
}: {
  node: NavbarSerializableNode;
  position: 'start' | 'end';
}) => {
  const { content, onUpdateNodeContent, onSelectNode, iframeRef } = useNavbarContext();
  const { data: siteThemesData } = useSiteThemes();
  const { data: site } = useSite();

  const siteThemes = siteThemesData?.pages.flatMap((page) => page.site_themes) || [];
  const defaultTheme = siteThemes.find((theme) => theme.is_primary);

  const parentNode = (content ? getParent(content, node) : {}) as TNavbarMenuListElement;
  const parentNodeBackground = parentNode?.attrs?.background || '#FFFFFF';
  const isParentLight = useIsLight(parentNodeBackground);

  if (!content) return null;
  if (!node || node.type !== 'navbar_menu_list_group') return null;

  const onAddNavbarItem = () => {
    if (!node.attrs) return;
    const nodeContent = node.content || [];
    let newItem = {
      type: 'navbar_item',
      attrs: {
        id: uuidv4(),
        type: 'link',
        label: 'Link',
        color: NAVBAR_MENU_ITEM_LINK_DEFAULTS.color,
        background: undefined,
      },
    } as NavbarSerializableNode;

    if (defaultTheme && site?.theme_rules) {
      newItem = applyThemeToNode(newItem, defaultTheme.data, site?.theme_rules);
    }

    if (position === 'end') {
      onUpdateNodeContent(node.attrs.id, [...nodeContent, newItem] as NavbarSerializableNode[]);
    } else {
      onUpdateNodeContent(node.attrs.id, [newItem, ...nodeContent] as NavbarSerializableNode[]);
    }

    // Scroll to the new item if it's not visible and then select it
    const newItemElement = iframeRef.current?.contentDocument?.querySelector(
      `[data-element-id="${newItem.attrs?.id}"]`
    );
    const scrollableContainer = newItemElement?.parentElement?.parentElement;
    if (newItemElement && scrollableContainer) {
      // Check if element is fully within the visible area of container
      const itemRect = newItemElement.getBoundingClientRect();
      const scrollableContainerRect = scrollableContainer.getBoundingClientRect();
      const isVisible =
        itemRect.left >= scrollableContainerRect.left && itemRect.right <= scrollableContainerRect.right;

      // If not visible, scroll AND THEN select
      if (!isVisible) {
        newItemElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        scrollableContainer.addEventListener('scrollend', () => {
          // Select the new item after scrolling
          onSelectNode(newItemElement as HTMLElement, newItem.attrs?.id || 'empty', true);

          // Remove the event listener
          scrollableContainer.removeEventListener('scrollend', () => {});
        });
      } else {
        // Otherwise, select immediately
        onSelectNode(newItemElement as HTMLElement, newItem.attrs?.id || 'empty', true);
      }
    }
  };

  return (
    <button
      data-is-editor
      className={cn(
        'navbar-add-item-button bg-none outline-none border-none p-0 cursor-pointer sticky opacity-0 hover:opacity-100 group-hover/navbar-menu-list-group:opacity-100  transition-opacity duration-150',
        position === 'start' ? 'left-0' : 'right-0'
      )}
      style={{
        marginRight: position === 'start' ? 'calc(-1 * var(--gap, 16px))' : '0',
        marginLeft: position === 'end' ? 'calc(-1 * var(--gap, 16px))' : '0',
      }}
      onClick={onAddNavbarItem}
      type="button"
    >
      <PlusCircle
        size={24}
        weight="fill"
        className={cn('transition-colors duration-200', isParentLight ? 'text-neutral-950/20' : 'text-neutral-50/50')}
      />
    </button>
  );
};
