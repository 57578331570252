import { CaretDown, CaretRight, Image, MagnifyingGlass, User } from '@phosphor-icons/react';
import {
  NAVBAR_MENU_ITEM_BUTTON_DEFAULTS,
  NAVBAR_MENU_ITEM_LINK_DEFAULTS,
  NAVBAR_MENU_ITEM_SEARCH_DEFAULTS,
  NavbarItemType,
  NavbarSerializableNode,
  TNavbarDropdownElement,
} from '@shared/dream-components';

import { useCurrentPublication } from '@/hooks/usePublications';
import { useSite } from '@/hooks/useSite';
import { useSiteThemes } from '@/hooks/useSiteThemes';
import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Text } from '@/routes/website/_components/UI/Text';

import { navbarDropdownContent } from '../../../defaultContent';
import { useNavbarContext } from '../../../NavbarContext';
import { applyThemeToNode, formatCamelCase } from '../../../utils';
import { OptionsWithPreview } from '../../ui/OptionsWithPreview';

export const TypeSettings = () => {
  const { selectedContent, onUpdateNodeAttributes, onUpdateNodeContent } = useNavbarContext();

  const { data: currentPublication } = useCurrentPublication();

  const { data: siteThemesData } = useSiteThemes();
  const { data: site } = useSite();

  const siteThemes = siteThemesData?.pages.flatMap((page) => page.site_themes) || [];
  const defaultTheme = siteThemes.find((theme) => theme.is_primary);

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  const contentValue = selectedContent.attrs?.type || 'link';

  const handleTypeChange = (type: NavbarItemType) => {
    if (!selectedContent.attrs?.id) return;

    // If the type is not dropdown, we need to remove the dropdown content BEFORE changing the item's type
    if (type !== 'dropdown') {
      onUpdateNodeContent(selectedContent.attrs?.id, []);
    }

    let additionalAttr = {};
    let updateAttributes = false;
    if (type === 'logo') {
      additionalAttr = {
        width: 'auto',
        height: '36px',
        showOnMobile: true,
        href: currentPublication?.url,
        mobileJustify: 'start',
        src: currentPublication?.logo?.url || undefined,
      };
      updateAttributes = true;
    }
    if (type === 'search') {
      additionalAttr = {
        action: 'search',
        background: NAVBAR_MENU_ITEM_SEARCH_DEFAULTS.background,
        color: NAVBAR_MENU_ITEM_SEARCH_DEFAULTS.color,
      };
      updateAttributes = true;
    }
    if (type === 'link' || type === 'dropdown') {
      if (contentValue !== 'link' && contentValue !== 'dropdown') {
        // Only update the attributes if the content value is not of similar type
        additionalAttr = {
          color: NAVBAR_MENU_ITEM_LINK_DEFAULTS.color,
          background: undefined,
        };
        updateAttributes = true;
      }


      if (type === 'dropdown') {
        additionalAttr = {
          ...additionalAttr,
          href: undefined,
        };
      }
    }
    if (type === 'button' || type === 'user_button') {
      if (contentValue !== 'button' && contentValue !== 'user_button') {
        // Only update the attributes if the content value is not of similar type
        additionalAttr = {
          color: NAVBAR_MENU_ITEM_BUTTON_DEFAULTS.color,
          background: NAVBAR_MENU_ITEM_BUTTON_DEFAULTS.background,
        };
        updateAttributes = true;
      }
    }

    const additionalAttrWithTheme =
      defaultTheme && site?.theme_rules && updateAttributes
        ? applyThemeToNode(
          {
            type: 'navbar_item',
            attrs: {
              type,
              ...additionalAttr,
            },
          } as Partial<NavbarSerializableNode>,
          defaultTheme.data,
          site?.theme_rules
        )?.attrs
        : additionalAttr;

    onUpdateNodeAttributes(selectedContent.attrs?.id, { type, ...additionalAttrWithTheme });

    // If the type is dropdown, we need to add a dropdown content AFTER changing the item's type
    if (type === 'dropdown') {
      let newDropdownContent = navbarDropdownContent();

      if (defaultTheme && site?.theme_rules) {
        newDropdownContent = applyThemeToNode(
          newDropdownContent,
          defaultTheme.data,
          site?.theme_rules
        ) as TNavbarDropdownElement;
      }

      onUpdateNodeContent(selectedContent.attrs?.id, [newDropdownContent]);
    }
  };

  const options: NavbarItemType[] = selectedContent.attrs?.autoGenerated
    ? ['link', 'button']
    : ['link', 'button', 'dropdown', 'search', 'logo'];

  if (selectedContent.attrs?.action === 'profile') {
    options.push('user_button');
  }

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2">
        <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
          <Text variant="secondary" size="2xs" weight="medium">
            Type
          </Text>
        </div>

        <PopoverTrigger asChild>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer w-[120px]">
            <div className="w-full justify-between flex items-center gap-2 p-2 ">
              <div className="flex items-center gap-1">
                <Text size="2xs" weight="medium">
                  {formatCamelCase(contentValue)}
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-6">
          <Text size="sm" weight="semibold">
            Type
          </Text>
          <div className="flex flex-col gap-2">
            {options.map((type) => (
              <OptionsWithPreview
                key={type}
                label={formatCamelCase(type)}
                selected={type === contentValue}
                onClick={() => handleTypeChange(type)}
              >
                {/* preview */}
                <div className="flex items-center justify-center bg-wb-primary rounded-md p-2 w-full gap-1 shadow-wb-md">
                  {type === 'link' && (
                    <Text size="sm" weight="medium" className="text-[8px]">
                      Products
                    </Text>
                  )}
                  {type === 'button' && (
                    <div className="flex items-center px-2 py-1 bg-wb-accent rounded-full">
                      <Text size="sm" weight="medium" variant="on-accent" className="text-[8px]">
                        Products
                      </Text>
                    </div>
                  )}
                  {type === 'user_button' && (
                    <div className="flex items-center px-2 py-1 bg-wb-accent rounded-full">
                      <User size={12} weight="bold" className="text-wb-on-accent" />
                    </div>
                  )}
                  {type === 'search' && <MagnifyingGlass size={16} weight="bold" className="text-wb-secondary" />}
                  {type === 'logo' && <Image size={16} weight="bold" className="text-wb-secondary" />}
                  {type === 'dropdown' && (
                    <div className="flex items-center gap-1">
                      <Text size="sm" weight="medium" className="text-[8px]">
                        Products
                      </Text>
                      <CaretDown size={8} weight="bold" className="text-wb-primary" />
                    </div>
                  )}
                </div>
              </OptionsWithPreview>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
