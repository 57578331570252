import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { ButtonVariants } from '@/interfaces/ui';

import Tooltip from '../../ui/Tooltip';

export interface DropdownOption {
  label: string;
  disabled?: boolean;
  disabledTooltip?: string;
  hidden?: boolean;
  onClick: () => void;
  color?: string;
}

export interface OptionsDropdownProps {
  options: DropdownOption[][];
  rounded?: 'full' | 'none' | true | false;
  variant?: ButtonVariants;
  className?: string;
}

const OptionsDropdown = ({ options, rounded = 'full', variant, className }: OptionsDropdownProps) => {
  const classNames = cx(
    variant === 'primary' && 'text-gray-600',
    variant === 'secondary' && 'text-gray-400',
    rounded === 'full' && 'rounded-full',
    (rounded === 'none' || rounded === false) && 'rounded-none',
    rounded === true && 'rounded',
    className,
    'bg-white border h-8 w-8 flex items-center justify-center text-gray-400 hover:text-gray-900 focus:outline-none'
  );
  const tooltipKey = Math.floor(Math.random() * 9999).toString();

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className={classNames}>
              <EllipsisVerticalIcon
                className={cx('w-4 h-4', open ? 'text-primary-600' : 'text-grey-600')}
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="z-20 absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {options.map((group, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                  {group.length >= 1 && (
                    <div className="px-1 py-1">
                      {group
                        .filter((option) => !option.hidden)
                        .map((option) => {
                          const color = option.color || 'gray';

                          return (
                            <Menu.Item key={option.label}>
                              {({ active }) => (
                                <Tooltip
                                  tooltipClass="text-center"
                                  id={`${tooltipKey}-${option.label}-tooltip`}
                                  text={option.disabled && option.disabledTooltip ? option.disabledTooltip : ''}
                                  showIcon={false}
                                  autoWidth
                                  isEnabled={option.disabled}
                                >
                                  <button
                                    type="button"
                                    className={`${
                                      active ? `bg-${color}-50 text-${color}-900` : `text-${color}-700`
                                    } group flex rounded-md items-center w-full px-2 py-2 text-sm disabled:opacity-50 disabled:cursor-not-allowed`}
                                    onClick={option.onClick}
                                    disabled={option.disabled}
                                  >
                                    {option.label}
                                  </button>
                                </Tooltip>
                              )}
                            </Menu.Item>
                          );
                        })}
                    </div>
                  )}
                </Fragment>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default OptionsDropdown;
