import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { useCurrentAdvertiserState } from '@/ad_portal/context/current-advertiser-context';
import useAdvertisers from '@/ad_portal/hooks/useAdvertisers/useAdvertisers';
import useCurrentAdvertiser from '@/ad_portal/hooks/useAdvertisers/useCurrentAdvertiser';
import { AdPortalAdvertiser } from '@/ad_portal/interfaces/advertiser';
import { PUBLICATION_DROPDOWN_HEIGHT_CLASS_NAME } from '@/components/Layout/AppLayout/constants';
import { SkeletonLoader } from '@/components/SkeletonLoader';
import { useClickOutside } from '@/hooks/useUsers/useClickOutside';

import AdvertiserOptions from './AdvertiserOptions';

interface Props {
  onSwitch?: (newAdvertiserId: string) => void;
}

const AdvertiserDropdown = ({ onSwitch }: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useClickOutside(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, menuRef);

  const { data: advertisers, isLoading: isLoadingAdvertisers } = useAdvertisers();
  const { data: currentAdvertiser } = useCurrentAdvertiser();
  const [currentAdvertiserId, setCurrentAdvertiserId] = useCurrentAdvertiserState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const hasMultipleAdvertisers = advertisers?.length > 1;

  const onSelectOption = (advertiser: AdPortalAdvertiser) => {
    if (currentAdvertiserId !== advertiser.id) {
      setCurrentAdvertiserId(advertiser.id);
      queryClient.invalidateQueries();
      if (onSwitch) {
        console.log('onSwitch', advertiser.id);
        onSwitch(advertiser.id);
      } else {
        console.log('navigate', '/');
        navigate('/');
      }
    }

    setIsOpen(false);
  };

  const isLoading = isLoadingAdvertisers || !currentAdvertiser;

  const onToggleDropdown = () => {
    if (isLoading || !hasMultipleAdvertisers) {
      return;
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx('flex flex-col mb-0')}>
      <SkeletonLoader
        isLoading={isLoading}
        skeletons={
          <div className={cx('flex items-center justify-end p-2', PUBLICATION_DROPDOWN_HEIGHT_CLASS_NAME)}>
            <div className="bg-gray-200 h-8 w-full rounded" />
          </div>
        }
        useFragment
      >
        {currentAdvertiser && (
          <div className={cx('relative flex flex-col space-y-4 p-2')}>
            <div className="relative">
              <button
                aria-expanded={isOpen}
                aria-haspopup="true"
                className={cx(
                  'flex-grow-0 relative bg-surface-50 border border-surface-100 rounded-md text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm pl-3 pr-4 py-2 w-full',
                  hasMultipleAdvertisers ? 'cursor-pointer' : 'cursor-default'
                )}
                type="button"
                onClick={onToggleDropdown}
              >
                <div className="flex items-center">
                  <div className="w-5 h-5 min-w-5 min-h-5 rounded overflow-hidden">
                    {currentAdvertiser.logo?.url ? (
                      <img className="h-5 w-5" src={currentAdvertiser.logo.url} alt="" />
                    ) : (
                      <div className="bg-gray-200 h-4 w-4 rounded" />
                    )}
                  </div>
                  <div className="truncate mr-2 flex-1">
                    <span className="ml-2 block truncate">{currentAdvertiser.name}</span>
                  </div>
                  {hasMultipleAdvertisers && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
                    </span>
                  )}
                </div>
              </button>
              {advertisers && (
                <div ref={menuRef}>
                  <AdvertiserOptions
                    currentAdvertiserId={currentAdvertiser.id}
                    advertisers={advertisers}
                    isOpen={isOpen}
                    onSelectOption={onSelectOption}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </SkeletonLoader>
    </div>
  );
};

export default AdvertiserDropdown;
