import { useState } from 'react';
import { useMutation } from 'react-query';
import ReactTooltip from 'react-tooltip';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { cx } from 'class-variance-authority';

import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Post } from '@/interfaces/post';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { parameterize } from '@/utils/parameterize';

interface Props {
  postId: Post['id'];
  slug: string;
  hostname: string;
  postTitle: Post['web_title'];
}

interface ApiSuccessResponse {
  slug: string;
}

const SlugInput = ({ postId, slug: givenSlug, hostname, postTitle }: Props) => {
  const [slug, setSlug] = useState(givenSlug);
  const [cancelOnBlur, setCancelOnBlur] = useState(false);
  const [publicationId] = useCurrentPublicationState();
  const [error, setError] = useState('');

  const updateSlugMutation = useMutation<ApiSuccessResponse>(
    () => {
      return api
        .patch(`/posts/${postId}/slug`, {
          post: { slug },
          publication_id: publicationId,
        })
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        setSlug(data.slug);
      },
      onError: (err: any) => {
        setError(err?.response.data.error || "Couldn't update slug");
      },
    }
  );

  const handleFormat = () =>
    updateSlugMutation.mutateAsync().then((res) => {
      if (slug !== res.slug) {
        setSlug(res.slug);
      }
    });

  const onBlur = async () => {
    if (!cancelOnBlur) {
      if (slug === '') {
        await setSlug(parameterize(postTitle));
      }
      handleFormat();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setSlug(e.target.value);
  };

  const onSyncMouseDown = () => {
    setCancelOnBlur(true);
  };

  const syncSlug = async () => {
    const newSlug = parameterize(postTitle);

    await setSlug(newSlug);

    setError('');
    handleFormat();
    setCancelOnBlur(false);
  };

  const slugSynced = slug === parameterize(postTitle);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="flex">
        <Typography token="font-medium/text/sm" className="py-2">
          Post URL*
        </Typography>
        <Button
          disabled={slugSynced}
          onMouseDown={onSyncMouseDown}
          onClick={syncSlug}
          type="button"
          variant="flush"
          className={cx('ml-2 mt-[0.6rem] w-4 h-4 !p-0 relative', {
            'text-surface-300': slugSynced,
            'text-surface-500': !slugSynced,
          })}
          data-tip
          data-for="resync-slug-tooltip"
        >
          <ArrowPathIcon
            className={cx('w-4 h-4 absolute', {
              'text-surface-300': slugSynced,
              'text-surface-500': !slugSynced,
            })}
          />
        </Button>
        <ReactTooltip id="resync-slug-tooltip" effect="solid" place="top">
          {slugSynced ? 'Synced' : 'Resync'} with post title
        </ReactTooltip>
      </div>
      <Input
        onChange={handleChange}
        name="slug"
        required
        leadingText={`https://${hostname}/p/`}
        placeholder="new-post"
        value={slug}
        errorText={error}
        onBlur={onBlur}
      />
    </form>
  );
};

export default SlugInput;
