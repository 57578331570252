import { useCallback, useEffect, useState } from 'react';

import { BottomLeftIcon, BottomRightIcon, TopLeftIcon, TopRightIcon } from '../../../../Icons/BorderIcons';
import BoxModelToggle from '../../helpers/BoxModelToggle';
import { AttributeSettingProps } from '../../types';
import { parseCssValue } from '../../utils/parseCssValue';

type BorderRadiusValue = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export const getDefaultBorderRadius = () => {
  return '0px';
};

export const SWButtonRadiusSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const [borderRadius, setBorderRadius] = useState<BorderRadiusValue | undefined>(undefined);

  useEffect(() => {
    return () => {
      setBorderRadius(undefined);
    };
  }, []);

  const initializeUnitAndValue = useCallback((attrs: Record<string, any>) => {
    if (!attrs) return;

    let nodeBorderRadius = attrs.borderRadius;

    if (!nodeBorderRadius) {
      nodeBorderRadius = getDefaultBorderRadius();
    }

    // padding is always 4 numbers top, right, bottom, left
    let br = nodeBorderRadius.split(' ');
    if (br.length < 4) {
      if (br.length === 2) {
        nodeBorderRadius = `${br[0]} ${br[1]} ${br[0]} ${br[1]}`;
      } else if (br.length === 1) {
        nodeBorderRadius = `${br[0]} ${br[0]} ${br[0]} ${br[0]}`;
      } else {
        nodeBorderRadius = getDefaultBorderRadius();
      }
      br = nodeBorderRadius.split(' ');
    }

    // get the unit from the first value
    const top = br[0];
    const right = br[1];
    const bottom = br[2];
    const left = br[3];

    setBorderRadius({
      top: parseCssValue(top).value,
      right: parseCssValue(right).value,
      bottom: parseCssValue(bottom).value,
      left: parseCssValue(left).value,
    });
  }, []);

  useEffect(() => {
    if (!activeNodeAttributes || !activeNodeAttributes.buttonProps) return;
    initializeUnitAndValue(activeNodeAttributes.buttonProps);
  }, [initializeUnitAndValue, activeNodeAttributes]);

  const updateBorderRadius = useCallback(
    (position: 'top' | 'right' | 'bottom' | 'left' | 'all', value: number) => {
      if (activeNodePos === undefined || !activeNodeType) return;
      if (position === 'all') {
        setBorderRadius({
          top: value,
          right: value,
          bottom: value,
          left: value,
        });

        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'buttonProps', {
            ...activeNodeAttributes.buttonProps,
            borderRadius: `${value}px ${value}px ${value}px ${value}px`,
          });
          return true;
        });
      } else {
        const p = { ...borderRadius, [position]: value } as BorderRadiusValue;
        setBorderRadius(p);
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'buttonProps', {
            ...activeNodeAttributes.buttonProps,
            borderRadius: `${p.top}px ${p.right}px ${p.bottom}px ${p.left}px`,
          });
          return true;
        });
      }
    },
    [activeNodePos, activeNodeType, editor, borderRadius, activeNodeAttributes]
  );

  return (
    <BoxModelToggle
      title="Radius"
      defaultValues={{
        top: borderRadius?.top || 0,
        right: borderRadius?.right || 0,
        bottom: borderRadius?.bottom || 0,
        left: borderRadius?.left || 0,
      }}
      suffixes={{
        top: <TopLeftIcon />,
        right: <TopRightIcon />,
        bottom: <BottomRightIcon />,
        left: <BottomLeftIcon />,
      }}
      showCombinedValues={false}
      onUpdate={updateBorderRadius}
    />
  );
};
