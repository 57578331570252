type GradientType = 'linear' | 'radial';

interface ColorStop {
  color: string;
  position: number | null;
}

interface BaseGradient {
  type: GradientType;
  colorStops: ColorStop[];
}

interface LinearGradient extends BaseGradient {
  type: 'linear';
  direction?: string;
}

interface RadialGradient extends BaseGradient {
  type: 'radial';
  shape?: string;
}

type Gradient = LinearGradient | RadialGradient;

export function parseGradient(gradientStr: string): Gradient {
  // Determine the gradient type
  const type: GradientType = gradientStr.startsWith('radial-gradient') ? 'radial' : 'linear';

  // Remove gradient function and clean whitespace
  const cleaned = gradientStr.replace(/(linear|radial)-gradient\(|\)/g, '').trim();

  // Split by commas and clean each part
  const parts = cleaned.split(',').map((part) => part.trim());

  // Check for configuration in first part
  let startIndex = 0;
  let config: Partial<LinearGradient | RadialGradient> = { type };

  // Handle linear gradient angle/direction
  if (
    type === 'linear' &&
    (parts[0].includes('deg') ||
      parts[0].includes('turn') ||
      parts[0].includes('rad') ||
      parts[0].includes('grad') ||
      parts[0].startsWith('to '))
  ) {
    config = { ...config, direction: parts[0] } as Partial<LinearGradient>;
    startIndex = 1;
  }

  // Handle radial gradient shape/size/position
  if (
    type === 'radial' &&
    (parts[0].includes('circle') ||
      parts[0].includes('ellipse') ||
      parts[0].includes('at') ||
      parts[0].includes('closest-side') ||
      parts[0].includes('closest-corner') ||
      parts[0].includes('farthest-side') ||
      parts[0].includes('farthest-corner'))
  ) {
    config = { ...config, shape: parts[0] } as Partial<RadialGradient>;
    startIndex = 1;
  }

  // Parse color stops
  const colorStops: ColorStop[] = parts
    .slice(startIndex)
    .map((stop) => {
      const matches = stop.match(/(#[0-9a-f]{3,8}|rgba?\([^)]+\)|hsla?\([^)]+\)|[a-z]+)\s*([0-9.]+%)?/i);

      if (!matches) return null;

      return {
        color: matches[1],
        position: matches[2] ? parseFloat(matches[2]) : null,
      };
    })
    .filter((stop): stop is ColorStop => stop !== null);

  return {
    ...config,
    colorStops,
  } as Gradient;
}
