import { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
  NAVBAR_MENU_ITEM_BUTTON_DEFAULTS,
  NAVBAR_MENU_ITEM_LINK_DEFAULTS,
  NAVBAR_MENU_ITEM_LOGO_DEFAULTS,
  NAVBAR_MENU_ITEM_SEARCH_DEFAULTS,
} from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import FontWeightSelection from '@/routes/website/_components/UI/FontWeightSelection';
import { Length, parseLength, ScalarUnitInput } from '@/routes/website/_components/UI/ScalarUnitInput';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';
import { BorderRadiusSettings } from '../general/BorderRadiusSettings';
import BorderSettings from '../general/BorderSettings';
import { ColorSettings } from '../general/ColorSettings';
import { PaddingSettings } from '../general/PaddingSettings';

import { FontSettings } from './FontSettings';
import { LogoSrcSettings } from './LogoSrcSettings';

export function NavItemStylingSettings() {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();
  const [defaults, setDefaults] = useState<Record<string, string>>(NAVBAR_MENU_ITEM_LINK_DEFAULTS);

  const getDefaults = useCallback((): Record<string, any> => {
    if (!selectedContent || !selectedContent.attrs?.id) return NAVBAR_MENU_ITEM_LINK_DEFAULTS;
    if (selectedContent.type !== 'navbar_item') return NAVBAR_MENU_ITEM_LINK_DEFAULTS;
    if (selectedContent.attrs?.type === 'button') return NAVBAR_MENU_ITEM_BUTTON_DEFAULTS;
    if (selectedContent.attrs?.type === 'link') return NAVBAR_MENU_ITEM_LINK_DEFAULTS;
    if (selectedContent.attrs?.type === 'search') return NAVBAR_MENU_ITEM_SEARCH_DEFAULTS;
    if (selectedContent.attrs?.type === 'logo') return NAVBAR_MENU_ITEM_LOGO_DEFAULTS;
    if (selectedContent.attrs?.type === 'user_button') return NAVBAR_MENU_ITEM_BUTTON_DEFAULTS;
    return NAVBAR_MENU_ITEM_LINK_DEFAULTS;
  }, [selectedContent]);

  useEffect(() => {
    setDefaults(getDefaults());
  }, [selectedContent, getDefaults]);

  const handleUpdateFontSize = useCallback(
    (fontSize: Length) => {
      const id = selectedContent?.attrs?.id;
      if (!id) return;

      onUpdateNodeAttributes(id, {
        fontSize: `${fontSize.value}${fontSize.unit}`,
      });
    },
    [selectedContent, onUpdateNodeAttributes]
  );

  const handleUpdateFontWeight = useCallback(
    (fontWeight: CSSProperties['fontWeight']) => {
      const id = selectedContent?.attrs?.id;
      if (!id) return;

      onUpdateNodeAttributes(id, {
        fontWeight,
      });
    },
    [selectedContent, onUpdateNodeAttributes]
  );

  const fontSize = (selectedContent?.attrs as any)?.fontSize ?? '16px';
  const fontWeight = (selectedContent?.attrs as any)?.fontWeight ?? '500';

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  return (
    <SectionRenderer title="Styling">
      {selectedContent.attrs?.type !== 'logo' && <FontSettings />}
      {selectedContent.attrs?.type !== 'logo' && (
        <ColorSettings title="Text" property="color" selectedContent={selectedContent} defaultValue={defaults.color} />
      )}
      {selectedContent.attrs?.type !== 'logo' &&
        selectedContent.attrs?.type !== 'search' &&
        selectedContent.attrs?.action !== 'profile' && (
          <>
            <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
              <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
                Font Size
              </Text>
              <ScalarUnitInput value={parseLength(fontSize as string)} onChange={handleUpdateFontSize} />
            </div>
            <FontWeightSelection value={fontWeight} onChange={handleUpdateFontWeight} />
          </>
        )}
      {(selectedContent.attrs?.type === 'button' || selectedContent.attrs?.type === 'user_button') && (
        <ColorSettings
          title="Fill"
          property="background"
          selectedContent={selectedContent}
          defaultValue={defaults.background}
        />
      )}
      {selectedContent.attrs?.type === 'logo' && <LogoSrcSettings />}
      {selectedContent.attrs?.type !== 'logo' && (
        <PaddingSettings selectedContent={selectedContent} attribute="padding" defaultValue={defaults?.padding} />
      )}
      {(selectedContent.attrs?.type === 'button' || selectedContent.attrs?.type === 'user_button') && (
        <BorderSettings
          selectedContent={selectedContent}
          defaults={{
            borderWidth: NAVBAR_MENU_ITEM_BUTTON_DEFAULTS.borderWidth,
            borderStyle: NAVBAR_MENU_ITEM_BUTTON_DEFAULTS.borderStyle,
            borderColor: NAVBAR_MENU_ITEM_BUTTON_DEFAULTS.borderColor,
          }}
        />
      )}
      <BorderRadiusSettings
        selectedContent={selectedContent}
        defaults={{
          borderRadius: defaults.borderRadius,
        }}
      />
    </SectionRenderer>
  );
}
