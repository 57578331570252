/* eslint-disable react/no-danger */
import { useState } from 'react';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import { v4 as generateUuid } from 'uuid';

import { Color } from '@/components/DesignTokens';
import { Typography } from '@/components/Typography';
import { Card } from '@/ui/Card';
import Tooltip from '@/ui/Tooltip';

import Complement from './Complement';
import { MetricCardProps } from './MetricCard.types';

const BACKGROUND_COLORS = {
  primary: 'bg-white',
  secondary: 'bg-surface-100',
  success: 'bg-feedback-success-100',
};

const ICON_COLORS = {
  primary: '',
  secondary: '',
  success: 'feedback-success-700',
};

const TEXT_COLORS: Record<MetricCardProps['variant'], Color> = {
  primary: Color.SURFACE,
  secondary: Color.SURFACE,
  success: Color.SUCCESS,
};

const MetricCard = ({
  variant,
  label,
  LabelIcon,
  value,
  complements,
  tooltip,
  cta,
  initialVisibleComplements = 2,
  slot,
  className,
  isLoading,
  additionalComponent,
  bottomComponent,
  expandComplementsLabel,
  expandComplementsTooltip,
}: MetricCardProps) => {
  const [expanded, setExpanded] = useState(false);

  const visibleComplements = complements?.slice(0, initialVisibleComplements) || [];
  const expandedComplements = (expanded ? complements?.slice(initialVisibleComplements) : []) || [];

  return (
    <Card
      variant={variant}
      density="none"
      backgroundColor={BACKGROUND_COLORS[variant]}
      className={cx('flex flex-col', className)}
    >
      <div className={cx('h-full flex flex-col justify-between p-4', cta ? 'space-y-1' : 'space-y-4')}>
        {cta && <div className="flex justify-end mb-6">{cta}</div>}
        <div className="space-y-2 py-2">
          <div className="flex items-center space-x-2">
            <Typography
              token="font-medium/text/base"
              className="flex items-center gap-x-1 break-word"
              colorWeight="700"
              color={TEXT_COLORS[variant]}
            >
              {LabelIcon ? <LabelIcon className={cx('w-5 h-5', ICON_COLORS[variant])} /> : null}
              {label}
            </Typography>
            {tooltip && <Tooltip text={tooltip} id={generateUuid()} />}
          </div>
          {isLoading ? (
            <div className="bg-gray-200 animate-pulse rounded w-fit">
              <p
                className="font-semibold text-gray-900 invisible text-3xl truncate "
                dangerouslySetInnerHTML={{ __html: '-----' }}
              />
            </div>
          ) : (
            <div>
              <Typography token="font-semibold/text/3xl" color={TEXT_COLORS[variant]}>
                <p className="truncate" dangerouslySetInnerHTML={{ __html: String(value) }} />
              </Typography>
            </div>
          )}

          {slot}
        </div>
        {additionalComponent}
        {visibleComplements.length > 0 && (
          <div className="flex flex-col gap-y-1">
            {visibleComplements.map((complement) => (
              <Complement complement={complement} />
            ))}
          </div>
        )}
        {bottomComponent}
      </div>
      {complements?.length! > initialVisibleComplements && (
        <div className="flex flex-col gap-y-2 px-4 py-2 border-t border-surface-200">
          <button
            className="flex flex-row justify-between items-center hover:underline"
            onClick={() => setExpanded(!expanded)}
            type="button"
          >
            <span className="inline-flex flex-row gap-x-1">
              <Typography token="font-medium/text/xs">
                {expandComplementsLabel || (expanded ? 'View less' : 'View more')}
              </Typography>
            </span>
            <Tooltip
              id={generateUuid()}
              isEnabled={!!expandComplementsTooltip}
              text={expandComplementsTooltip || ''}
              containerClass="flex flex-row gap-x-1 items-center ml-2 mr-auto"
              iconClass="text-surface-900"
              childrenContainerClass="flex"
              Icon={CheckBadgeIcon}
            />
            {expanded ? (
              <ChevronUpIcon className="w-4 h-4 text-surface-500" />
            ) : (
              <ChevronDownIcon className="w-4 h-4 text-surface-500" />
            )}
          </button>
          {expandedComplements.map((complement) => (
            <Complement complement={complement} />
          ))}
        </div>
      )}
    </Card>
  );
};

export default MetricCard;
