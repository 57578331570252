import { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClockCounterClockwise, Eye, EyeSlash, GearSix, Link as LinkIcon, Pencil, Trash } from '@phosphor-icons/react';
import { DropdownMenuContentProps, DropdownMenuProps } from '@radix-ui/react-dropdown-menu';

import { useWebsitePageRouteGetter } from '@/context/website-context';
import { useDeletePage } from '@/hooks/usePages';
import { useUpdatePageVersion } from '@/hooks/usePageVersion';
import { Page } from '@/interfaces/dream_builder/page';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../UI/DropdownMenu';
import Modal from '../UI/Modal';
import { Text } from '../UI/Text';

type Props = {
  pageId: string;
  isDefaultPage: boolean;
  isHome: boolean;
  page?: Page;
  onPageVersionHistory?: () => void;
};

export const PageOption = ({
  children,
  align,
  pageId,
  isDefaultPage,
  isHome,
  page,
  onPageVersionHistory,
  ...props
}: PropsWithChildren<{ align: DropdownMenuContentProps['align'] } & DropdownMenuProps & Props>) => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const pageRouteGetter = useWebsitePageRouteGetter();
  const homePageID = pageRouteGetter?.getHomePageID();
  const isPageEnabled = page?.draft_page_version?.page_enabled;

  const handleDeleteSuccess = () => {
    setIsDeleteModalOpen(false);
    navigate(`/website_builder_v2/page/${homePageID}`);
  };

  const deletePage = useDeletePage({ onSuccess: handleDeleteSuccess });

  const handleDeletePage = () => {
    deletePage.mutate({ pageId });
  };

  const { mutate: updatePageVersion } = useUpdatePageVersion({
    pageId,
    id: page?.draft_page_version?.id || '',
  });

  const handleUpdatePageVersion = (value: boolean) => {
    updatePageVersion({
      page_enabled: value,
    });
  };

  return (
    <>
      <DropdownMenu {...props}>
        <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={2} align={align} className="w-[165px]">
          <DropdownMenuItem LeftIcon={GearSix} asLink linkProps={{ to: `/website_builder_v2/settings/page/${pageId}` }}>
            Settings
          </DropdownMenuItem>
          <DropdownMenuItem
            LeftIcon={isPageEnabled ? EyeSlash : Eye}
            onClick={() => {
              handleUpdatePageVersion(!isPageEnabled);
            }}
          >
            {isPageEnabled ? 'Disable' : 'Enable'}
          </DropdownMenuItem>
          {onPageVersionHistory && (
            <DropdownMenuItem LeftIcon={ClockCounterClockwise} onClick={onPageVersionHistory}>
              Page Version History
            </DropdownMenuItem>
          )}
          <DropdownMenuItem LeftIcon={Pencil} disabled>
            Rename
          </DropdownMenuItem>
          <DropdownMenuItem LeftIcon={LinkIcon} disabled>
            Copy Link
          </DropdownMenuItem>
          <DropdownMenuItem
            LeftIcon={Trash}
            onSelect={() => setIsDeleteModalOpen(true)}
            disabled={isDefaultPage || isHome}
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Page"
        onConfirm={handleDeletePage}
        ctaText={deletePage.isLoading ? 'Deleting...' : 'Delete Page'}
        ctaVariant="danger"
        isLoading={deletePage.isLoading}
      >
        <Text size="sm" weight="medium" variant="secondary" as="p" className="mb-4">
          Are you sure you want to delete this page? This will delete the current page and all of its subpages.
        </Text>
      </Modal>
    </>
  );
};
