import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  onSuccess?: () => void;
}

const useDeleteBoostAgreementFilter = ({ onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const deleteBoostAgreementFilter = async (id: string): Promise<void> =>
    api.delete(`/boosts/grow/boost_agreement_filters/${id}`).then(() => undefined);

  return useMutation(deleteBoostAgreementFilter, {
    onSuccess: () => {
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_agreement_filters']);

      onSuccess?.();
    },
    onError: () => {
      toast.error('Something went wrong deleting your filter');
    },
  });
};

export default useDeleteBoostAgreementFilter;
