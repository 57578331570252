import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import ReactTooltip from 'react-tooltip';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, SpeakerWaveIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { AccordionCard } from '@/components/Accordion/variants/AccordionCard';
import Banner from '@/components/Banner';
import { ImageSelect, Input } from '@/components/Form';
import Modal from '@/components/Modal';
import { SEOPreview } from '@/components/SEOPreview';
import SortFeaturedPosts from '@/components/SortableList/SortableLists/SortFeaturedPosts';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Typography, TypographyRow, TypographyStack } from '@/components/Typography';
import UpgradeIntent from '@/components/UpgradeIntent/TieredUpgradeIntent';
import { useSettings } from '@/context/settings-context';
import {
  useCurrentPublication,
  useElevenlabsVoices,
  usePostTextToSpeechConfig,
  useUpdatePostTextToSpeechConfig,
  useUpdateWebTemplate,
  useWebTemplate,
} from '@/hooks';
import { ElevenlabsVoice, PartialPost, Post, TextToSpeechConfig } from '@/interfaces/post';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import { Dropdown } from '@/ui/Dropdown';
import Switch from '@/ui/Switch';
import { Textarea } from '@/ui/Textarea';
import Tooltip from '@/ui/Tooltip';

import { usePostContext } from '../PostContext';

import SlugInput from './SlugInput';

type FeaturedPosts = Array<Post | PartialPost>;

interface FeaturedPostModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  featuredPosts: FeaturedPosts[] | any;
  handleItemRemoved: any;
  handleItemsOrdered: any;
}

const DEFAULTS = {
  THUMBNAIL_URL: 'https://media.beehiiv.com/static_assets/publish_on_beehiiv.png',
};

const FeaturedPostModal = ({
  isOpen,
  onClose,
  onSave,
  featuredPosts,
  handleItemRemoved,
  handleItemsOrdered,
}: FeaturedPostModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} includeCloseButton={false} className="max-w-xl w-full">
      <div className="max-w-2xl w-full rounded">
        <div className="px-6 pt-6 pb-4 flex flex-col gap-6">
          <TypographyStack>
            <Typography token="font-medium/text/lg">Featured Posts</Typography>
            <Typography token="font-light/text/xs">
              You may feature up to 6 posts on your website&apos;s homepage. You have currently featured{' '}
              {featuredPosts.length || 0} post{featuredPosts.length === 1 ? '' : 's'}.
            </Typography>
          </TypographyStack>
          <div className="w-full">
            <SortFeaturedPosts
              featuredPosts={[...featuredPosts]}
              onItemRemoved={(newList) => handleItemRemoved(newList)}
              onItemsOrdered={(newList) => handleItemsOrdered(newList)}
            />
          </div>
        </div>
        <div className="px-6 py-3 flex flex-row justify-end gap-3">
          <Button onClick={onClose} variant="primary-inverse" size="sm">
            Cancel
          </Button>
          <Button onClick={onSave} variant="primary" size="sm">
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const Web = () => {
  const { formData, onChange } = usePostContext();

  const [seoMetaDescriptionEdited, setSeoMetaDescriptionEdited] = useState(false);
  const [seoMetaOpenGraphDescriptionEdited, setSeoMetaOpenGraphDescriptionEdited] = useState(false);
  const [seoMetaOpenGraphTitleEdited, setSeoMetaOpenGraphTitleEdited] = useState(false);
  const [seoMetaTitleEdited, setSeoMetaTitleEdited] = useState(false);
  const [seoMetaTwitterDescriptionEdited, setSeoMetaTwitterDescriptionEdited] = useState(false);
  const [seoMetaTwitterTitleEdited, setSeoMetaTwitterTitleEdited] = useState(false);

  const postId = formData?.id || '';
  const { data: textToSpeechConfigData, isLoading, refetch } = usePostTextToSpeechConfig(formData?.id || '');
  const { data: voicesData = [] } = useElevenlabsVoices();
  const voices = Array.isArray(voicesData) ? voicesData : [];
  const [newList, setNewList] = useState<any>([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isFeaturedPostModalOpen, setIsFeaturedPostModalOpen] = useState(false);
  const { data: webTemplate } = useWebTemplate();
  const { data: publication } = useCurrentPublication();
  const webTemplateMutation = useUpdateWebTemplate();
  const { settings } = useSettings();
  const [textToSpeechConfig, setTextToSpeechConfig] = useState<TextToSpeechConfig | undefined>(undefined);

  const featuredPosts = useMemo(() => webTemplate?.feature_post_ids || [], [webTemplate]);
  const featuredPostsCount = featuredPosts.length;
  const isPostFeatured = Boolean(featuredPosts.find((featuredPost: Post) => featuredPost?.id === formData?.id));

  const [isOgAccordionOpen, setIsOgAccordionOpen] = useState(false);
  const [isTwitterAccordionOpen, setIsTwitterAccordionOpen] = useState(false);
  const [isAdvancedSettingsAccordionOpen, setIsAdvancedSettingsAccordionOpen] = useState(false);
  const [isUpgradeIntentOpen, setIsUpgradeIntentOpen] = useState(false);

  useEffect(() => {
    setNewList(featuredPosts);
  }, [featuredPosts]);

  useEffect(() => {
    if (!isLoading) {
      setTextToSpeechConfig(textToSpeechConfigData);
    }
  }, [textToSpeechConfigData, isLoading]);

  const postTextToSpeechConfigMutation = useUpdatePostTextToSpeechConfig({ postId, onError: refetch });

  const onChangeTextToSpeechConfig = useCallback(
    async (data: any) => {
      setTextToSpeechConfig({ ...textToSpeechConfig, ...data });

      await postTextToSpeechConfigMutation.mutateAsync(data);

      refetch();
    },
    [textToSpeechConfig, postTextToSpeechConfigMutation, refetch]
  );

  function truncate(str: string, maxLength: number) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength);
    }
    return str;
  }

  function lengthColor(length: number, targetLength: number, delta: number) {
    const successLowerBound = targetLength - delta;
    const successUpperBound = targetLength + delta;

    if (length >= successLowerBound && length <= successUpperBound) {
      return 'success';
    }

    return 'warning';
  }

  useEffect(() => {
    setIsFeatured(isPostFeatured);
  }, [isPostFeatured]);

  useEffect(() => {
    if (!seoMetaTitleEdited && (!formData?.meta_default_title || formData?.meta_default_title?.length === 0)) {
      onChange({ meta_default_title: truncate(formData?.web_title || '', 200) });
    }

    if (
      !seoMetaDescriptionEdited &&
      (!formData?.meta_default_description || formData?.meta_default_description?.length === 0) &&
      formData?.web_subtitle !== ''
    ) {
      onChange({ meta_default_description: truncate(formData?.web_subtitle || '', 500) });
    }

    if (!seoMetaOpenGraphTitleEdited && (!formData?.meta_og_title || formData?.meta_og_title?.length === 0)) {
      onChange({ meta_og_title: truncate(formData?.web_title || '', 200) });
    }

    if (
      !seoMetaOpenGraphDescriptionEdited &&
      (!formData?.meta_og_description || formData?.meta_og_description?.length === 0) &&
      formData?.web_subtitle !== ''
    ) {
      onChange({ meta_og_description: truncate(formData?.web_subtitle || '', 500) });
    }

    if (!seoMetaTwitterTitleEdited && (!formData?.meta_twitter_title || formData?.meta_twitter_title?.length === 0)) {
      onChange({ meta_twitter_title: truncate(formData?.web_title || '', 200) });
    }

    if (
      !seoMetaTwitterDescriptionEdited &&
      (!formData?.meta_twitter_description || formData?.meta_twitter_description?.length === 0) &&
      formData?.web_subtitle !== ''
    ) {
      onChange({ meta_twitter_description: truncate(formData?.web_subtitle || '', 500) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const voiceSamplePlayer = useRef<Howl>();
  const playSample = useCallback((voice: ElevenlabsVoice) => {
    voiceSamplePlayer.current?.unload();

    const howl = new Howl({
      src: [voice.preview_url],
      loop: false,
      volume: 0.5,
    });

    howl.play();

    voiceSamplePlayer.current = howl;
  }, []);

  if (!formData) {
    return null;
  }

  const searchEngineUrl = formData.custom_live_url || formData.url;

  let hostname = '';

  try {
    const url = new URL(searchEngineUrl);
    hostname = url.hostname;
  } catch {
    hostname = '';
  }

  const buildPostsPayload = (value: boolean) => {
    let newFeaturedPosts = [...featuredPosts];

    if (value) {
      newFeaturedPosts.push(formData);
    } else {
      newFeaturedPosts = newFeaturedPosts.filter((featuredPost) => featuredPost.id !== formData.id);
    }

    return {
      feature_post_ids: newFeaturedPosts.map((featuredPost) => featuredPost.id),
    };
  };

  const featuredPostMessage = (
    <Typography token="font-normal/text/sm">
      You may feature up to 6 posts on your website&apos;s homepage. You have currently featured {featuredPosts.length}{' '}
      {featuredPosts.length === 1 ? 'post' : 'posts'}.{' '}
      <span
        onClick={() => setIsFeaturedPostModalOpen(true)}
        onKeyDown={() => setIsFeaturedPostModalOpen(true)}
        role="button"
        tabIndex={0}
      >
        <Typography token="font-medium/text/sm" color="secondary" colorWeight="600">
          Manage featured posts
        </Typography>
      </span>
    </Typography>
  );

  const onFeaturedSelected = async (value: boolean) => {
    setIsFeatured(value);
    if (value) {
      onChange({ hide_from_feed: false });
    }
    if (featuredPostsCount >= 6 && value) {
      setIsFeaturedPostModalOpen(true);
    } else {
      await webTemplateMutation.mutateAsync(buildPostsPayload(value));
    }
  };

  const handleItemRemoved = (updatedList: string[]) => {
    const newFeaturedPosts = featuredPosts.filter((featuredPost: Post) => updatedList.includes(featuredPost.id));
    setNewList(newFeaturedPosts);
  };

  const handleSave = async () => {
    await webTemplateMutation
      .mutateAsync({
        feature_post_ids: newList.map((featuredPost: Post) => featuredPost.id),
      })
      .then(() => {
        setIsFeaturedPostModalOpen(false);
        setNewList(featuredPosts);
      });
  };

  const handleItemsOrdered = async (updatedList: string[]) => {
    const orderedFeaturedPosts = updatedList.map((id: string) =>
      featuredPosts.find((featuredPost: Post) => featuredPost.id === id)
    );
    setNewList(orderedFeaturedPosts);
  };

  const handleHideFromFeedChange = async (name: string, value: boolean) => {
    onChange({ hide_from_feed: value });
    if (value) {
      setIsFeatured(false);
    }
  };

  const updateDisplayDate = (date: Date | null) => {
    const updateVal = date ? date.toISOString() : '';
    onChange({ override_scheduled_at: updateVal });
  };

  const syncSeoMetaTitle = () => {
    onChange({ meta_default_title: formData.web_title });
    setSeoMetaTitleEdited(false);
  };

  const syncSeoMetaDescription = () => {
    onChange({ meta_default_description: formData.web_subtitle });
    setSeoMetaDescriptionEdited(false);
  };

  const syncSeoOpenGraphTitle = () => {
    onChange({ meta_og_title: formData.web_title });
    setSeoMetaOpenGraphTitleEdited(false);
  };

  const syncSeoOpenGraphDescription = () => {
    onChange({ meta_og_description: formData.web_subtitle });
    setSeoMetaOpenGraphDescriptionEdited(false);
  };

  const syncMetaTwitterTitle = () => {
    onChange({ meta_twitter_title: formData.web_title });
    setSeoMetaTwitterTitleEdited(false);
  };

  const syncMetaTwitterDescription = () => {
    onChange({ meta_twitter_description: formData.web_subtitle });
    setSeoMetaTwitterDescriptionEdited(false);
  };

  let publicationThumbnail = DEFAULTS.THUMBNAIL_URL;
  if (
    typeof publication?.thumbnail.url === 'string' &&
    !publication?.thumbnail.url.endsWith('defaults/thumbnail.png')
  ) {
    publicationThumbnail = publication?.thumbnail?.url;
  }
  const seoPreviewImage = formData.thumbnail?.url || publicationThumbnail;

  const localeString = new Date()
    .toLocaleDateString('en-US', {
      day: '2-digit',
      timeZoneName: 'long',
    })
    .slice(4);

  const seoMetaTitleSynced = formData?.meta_default_title === formData?.web_title;
  const seoMetaDescriptionSynced = formData?.meta_default_description === formData?.web_subtitle;
  const seoMetaOpenGraphTitleSynced = formData?.meta_og_title === formData?.web_title;
  const seoMetaOpenGraphDescriptionSynced = formData?.meta_og_description === formData?.web_subtitle;
  const seoMetaTwitterTitleSynced = formData?.meta_twitter_title === formData?.web_title;
  const seoMetaTwitterDescriptionSynced = formData?.meta_twitter_description === formData?.web_subtitle;

  const truncatedMetaDefaultTitle = truncate(formData.meta_default_title || formData.web_title || '', 200);
  const truncatedMetaDefaultDescription = truncate(
    formData.meta_default_description || formData.web_subtitle || publication?.description || '',
    500
  );
  const truncatedMetaOgTitle = truncate(formData.meta_og_title || '', 200);
  const truncatedMetaOgDescription = truncate(formData.meta_og_description || '', 500);
  const truncatedMetaTwitterTitle = truncate(formData.meta_twitter_title || '', 200);
  const truncatedMetaTwitterDescription = truncate(formData.meta_twitter_description || '', 500);

  return (
    <div className="px-2 md:px-20 py-10 flex flex-col gap-6 overflow-auto">
      <UpgradeIntent isOpen={isUpgradeIntentOpen} onClose={() => setIsUpgradeIntentOpen(false)} />
      <FeaturedPostModal
        isOpen={isFeaturedPostModalOpen}
        onClose={() => {
          setIsFeaturedPostModalOpen(false);
          setNewList(featuredPosts);
        }}
        onSave={handleSave}
        featuredPosts={newList}
        handleItemRemoved={handleItemRemoved}
        handleItemsOrdered={handleItemsOrdered}
      />
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6 max-w-[40rem]">
        <Typography token="font-medium/text/base">Web post settings</Typography>
        {formData.status === 'published' && (
          <Banner
            variant="warning"
            isScreenWide={false}
            title="Changing the URL after a post is published will invalidate the previous URL."
            bodyText=""
          />
        )}
        <SlugInput hostname={hostname} postId={formData.id} slug={formData.slug} postTitle={formData.web_title} />
        <ImageSelect
          name="thumbnail"
          labelText="Post thumbnail"
          dimensionSuggestion="This image will show up as the thumbnail in your feed and SEO. 1200px x 630px recommended"
          onMediaSelect={(payload) => onChange({ thumbnail_id: payload.media.id, thumbnail: payload.media })}
          onFileClear={() => onChange({ thumbnail_id: null, thumbnail: null })}
          file={formData.thumbnail?.url}
          type="landscape"
          fullWidth
          placeholderText="browse to upload"
          publicationId={publication?.id}
        />
        {formData?.thumbnail?.url && (
          <Switch
            name="hide-thumbnail"
            checked={formData.display_thumbnail_on_web}
            onChange={(name, e) => onChange({ display_thumbnail_on_web: e })}
            prefixLabelText={<Typography token="font-medium/text/sm">Show thumbnail on top in web</Typography>}
            variant="primary"
            showIcons={false}
            fullWidth
          />
        )}
        <Dropdown
          name="email_capture_type"
          value={formData.email_capture_type}
          labelText="Advanced email capture"
          suffixElement={<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          options={[
            { label: 'None', value: 'none' },
            { label: 'Gated', value: 'gated' },
            { label: 'Popup', value: 'popup' },
          ]}
          onSelect={(name, value) => onChange({ email_capture_type_override: value, email_capture_type: value })}
        />
        <Dropdown
          labelText="Comments"
          name="comments_state"
          value={formData.comments_state}
          suffixElement={<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
          options={[
            { label: 'All subscribers', value: 'default' },
            { label: 'Paid subscribers only', value: 'premium' },
            { label: 'Disabled: Hide comments section', value: 'disabled' },
            { label: 'Locked: Show comments section but disable new comments', value: 'locked' },
          ]}
          onSelect={(name, value) => onChange({ comments_state: value })}
        />
      </Card>
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6 max-w-[40rem]">
        <div className="flex flex-col gap-2 items-start w-full">
          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-row gap-3 items-center">
              <Typography token="font-medium/text/base">Audio newsletter</Typography>
              <Tooltip
                id={String(Math.random())}
                text="Audio will capture the entire post content, regardless of post visibility settings. It might take 5–10 minutes to generate the audio transcript."
              />
            </div>
            {settings?.audio_newsletters || textToSpeechConfig?.enabled ? (
              <Switch
                name="audio_newsletter"
                checked={textToSpeechConfig?.enabled || false}
                onChange={(name, e) => onChangeTextToSpeechConfig({ enabled: e })}
                variant="primary"
                showIcons={false}
              />
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  setIsUpgradeIntentOpen(true);
                }}
              >
                <span>Upgrade to </span>&nbsp;<span className="font-bold">MAX</span>
              </Button>
            )}
          </div>
          <Typography token="font-normal/text/sm" className="max-w-md">
            Readers will see a &apos;listen online&apos; option in email header to access the audio transcript at the
            top of the published web post.
          </Typography>
        </div>
        {textToSpeechConfig?.enabled && (
          <Dropdown
            name="elevenlabs-voice-id"
            labelText="Choose Voice"
            required
            value={textToSpeechConfig?.voice_id || ''}
            onSelect={(_name: string, value: string) => onChangeTextToSpeechConfig({ voice_id: value })}
            options={voices?.map((v) => {
              return {
                value: v.voice_id,
                label: v.name,
                optionAction: (
                  <SpeakerWaveIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      playSample(v);
                    }}
                    className="h-4 w-4 cursor-pointer text-surface-500 hover:text-surface-700"
                  />
                ),
              };
            })}
            emptyLabel="No voices available"
            buttonClassNames={{ focus: 'focus:ring-primary-500 focus:border-primary-500' }}
            placeholderText="Select a Voice"
          />
        )}
      </Card>
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6 max-w-[40rem]">
        <Typography token="font-medium/text/base">SEO settings</Typography>
        <div className="space-y-2">
          <div className="flex flex-row justify-between items-center">
            <div className="flex">
              <Typography token="font-medium/text/sm" className="py-2">
                Meta title
              </Typography>
              <Button
                disabled={seoMetaTitleSynced}
                onClick={syncSeoMetaTitle}
                type="button"
                variant="flush"
                className={cx('ml-2 mt-[0.6rem] w-4 h-4 !p-0 relative', {
                  'text-surface-300': seoMetaTitleSynced,
                  'text-surface-500': !seoMetaTitleSynced,
                })}
                data-tip
                data-for="resync-seo-meta-title-tooltip"
              >
                <ArrowPathIcon
                  className={cx('w-4 h-4 mt-1 absolute', {
                    'text-surface-300': seoMetaTitleSynced,
                    'text-surface-500': !seoMetaTitleSynced,
                  })}
                />
              </Button>
              <ReactTooltip id="resync-seo-meta-title-tooltip" effect="solid" place="top">
                {seoMetaTitleSynced ? 'Synced' : 'Resync'} with post title
              </ReactTooltip>
            </div>
            <Typography token="font-normal/text/xs">
              You&apos;ve used{' '}
              <Typography
                token="font-medium/text/xs"
                color={lengthColor(truncatedMetaDefaultTitle.length || 0, 60, 10)}
                colorWeight="600"
              >
                {truncatedMetaDefaultTitle.length || 0}
              </Typography>
              /200 characters
            </Typography>
          </div>
          <Input
            onKeyUp={() => setSeoMetaTitleEdited(true)}
            name="meta-title"
            value={truncatedMetaDefaultTitle}
            onChange={(e) => onChange({ meta_default_title: e.target.value })}
            maxLength={200}
            className="py-2"
          />
          <Typography token="font-light/text/xs">
            Recommended: <Typography token="font-medium/text/xs">60</Typography> characters per SEO best practices
          </Typography>
        </div>
        <div className="space-y-2">
          <div className="flex flex-row justify-between items-center">
            <div className="flex">
              <Typography token="font-medium/text/sm" className="py-2">
                Meta description
              </Typography>
              <Button
                disabled={seoMetaDescriptionSynced}
                onClick={syncSeoMetaDescription}
                type="button"
                variant="flush"
                className={cx('ml-2 mt-[0.6rem] w-4 h-4 !p-0 relative', {
                  'text-surface-300': seoMetaDescriptionSynced,
                  'text-surface-500': !seoMetaDescriptionSynced,
                })}
                data-tip
                data-for="resync-seo-meta-description-tooltip"
              >
                <ArrowPathIcon
                  className={cx('w-4 h-4 absolute', {
                    'text-surface-300': seoMetaDescriptionSynced,
                    'text-surface-500': !seoMetaDescriptionSynced,
                  })}
                />
              </Button>
              <ReactTooltip id="resync-seo-meta-description-tooltip" effect="solid" place="top">
                {seoMetaDescriptionSynced ? 'Synced' : 'Resync'} with post subtitle
              </ReactTooltip>
            </div>
            <Typography token="font-normal/text/xs">
              You&apos;ve used{' '}
              <Typography
                token="font-medium/text/xs"
                color={lengthColor(truncatedMetaDefaultDescription.length || 0, 145, 15)}
                colorWeight="600"
              >
                {truncatedMetaDefaultDescription.length || 0}
              </Typography>
              /500 characters
            </Typography>
          </div>
          <Textarea
            name="meta-description"
            value={truncatedMetaDefaultDescription}
            onChange={(e) => onChange({ meta_default_description: e.target.value })}
            className="py-2"
            maxLength={500}
          />
          <Typography token="font-light/text/xs">
            Recommended: <Typography token="font-medium/text/xs">145</Typography> characters per SEO best practices
          </Typography>
        </div>
        <div className="flex flex-col gap-2">
          <Typography token="font-medium/text/sm">Search engine result preview</Typography>
          <div className="p-4 flex flex-col gap-2 border rounded-md border-surface-200 break-words">
            <Typography token="font-medium/text/xs">
              {hostname} › {formData.slug}
            </Typography>
            <div className="flex flex-col gap-1">
              <Typography color="tertiary" size="xl" className="leading-6">
                {formData.meta_default_title || formData.web_title}
              </Typography>
              <Typography token="font-normal/text/sm">
                {formData.meta_default_description || formData.web_subtitle || publication?.description || ''}
              </Typography>
            </div>
          </div>
        </div>
        <AccordionCard
          title="Open Graph (Facebook and LinkedIn)"
          titleSize="base"
          titleWeight="medium"
          padding="px-4 pb-4 pt-0"
          marginTop="mt-0"
          subText=""
          topBorder={false}
          isControlledState
          isControlledStateOpen={isOgAccordionOpen}
          onClick={() => {
            if (!isOgAccordionOpen) {
              setIsOgAccordionOpen(true);
            } else {
              setIsOgAccordionOpen(false);
            }
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
              <div className="flex">
                <Typography token="font-medium/text/sm" className="py-2">
                  Meta title
                </Typography>
                <Button
                  disabled={seoMetaOpenGraphTitleSynced}
                  onClick={syncSeoOpenGraphTitle}
                  type="button"
                  variant="flush"
                  className={cx('ml-2 mt-[0.6rem] w-4 h-4 !p-0 relative', {
                    'text-surface-300': seoMetaOpenGraphTitleSynced,
                    'text-surface-500': !seoMetaOpenGraphTitleSynced,
                  })}
                  data-tip
                  data-for="resync-seo-open-graph-title-tooltip"
                >
                  <ArrowPathIcon
                    className={cx('w-4 h-4 mt-1 absolute', {
                      'text-surface-300': seoMetaOpenGraphTitleSynced,
                      'text-surface-500': !seoMetaOpenGraphTitleSynced,
                    })}
                  />
                </Button>
                <ReactTooltip id="resync-seo-open-graph-title-tooltip" effect="solid" place="top">
                  {seoMetaOpenGraphTitleSynced ? 'Synced' : 'Resync'} with post title
                </ReactTooltip>
              </div>
              <Typography token="font-normal/text/xs">
                You&apos;ve used{' '}
                <Typography
                  token="font-medium/text/xs"
                  color={lengthColor(truncatedMetaOgTitle.length || 0, 60, 10)}
                  colorWeight="600"
                >
                  {truncatedMetaOgTitle.length || 0}
                </Typography>
                /200 characters
              </Typography>
            </div>
            <Input
              name="meta-og-title"
              value={truncatedMetaOgTitle}
              onChange={(e) => onChange({ meta_og_title: e.target.value })}
              className="py-2"
              maxLength={200}
            />
            <Typography token="font-light/text/xs">
              Recommended: <Typography token="font-medium/text/xs">60</Typography> characters per SEO best practices
            </Typography>
            <div className="flex flex-row justify-between items-center">
              <div className="flex">
                <Typography token="font-medium/text/sm" className="py-2">
                  Meta description
                </Typography>
                <Button
                  disabled={seoMetaOpenGraphDescriptionSynced}
                  onClick={syncSeoOpenGraphDescription}
                  type="button"
                  variant="flush"
                  className={cx('ml-2 mt-[0.6rem] w-4 h-4 !p-0 relative', {
                    'text-surface-300': seoMetaOpenGraphDescriptionSynced,
                    'text-surface-500': !seoMetaOpenGraphDescriptionSynced,
                  })}
                  data-tip
                  data-for="resync-seo-open-graph-description-tooltip"
                >
                  <ArrowPathIcon
                    className={cx('w-4 h-4 mt-1 absolute', {
                      'text-surface-300': seoMetaOpenGraphDescriptionSynced,
                      'text-surface-500': !seoMetaOpenGraphDescriptionSynced,
                    })}
                  />
                </Button>
                <ReactTooltip id="resync-seo-open-graph-description-tooltip" effect="solid" place="top">
                  {seoMetaOpenGraphDescriptionSynced ? 'Synced' : 'Resync'} with post subtitle
                </ReactTooltip>
              </div>
              <Typography token="font-normal/text/xs">
                You&apos;ve used{' '}
                <Typography
                  token="font-medium/text/xs"
                  color={lengthColor(truncatedMetaOgDescription.length || 0, 145, 15)}
                  colorWeight="600"
                >
                  {truncatedMetaOgDescription.length || 0}
                </Typography>
                /500 characters
              </Typography>
            </div>
            <Textarea
              name="meta-description"
              value={truncatedMetaOgDescription}
              onChange={(e) => onChange({ meta_og_description: e.target.value })}
              className="py-2"
              maxLength={500}
            />
            <Typography token="font-light/text/xs">
              Recommended: <Typography token="font-medium/text/xs">145</Typography> characters per SEO best practices
            </Typography>
            <SEOPreview
              title={formData.meta_og_title || formData.meta_default_title || formData.web_title}
              description={
                formData.meta_og_description ||
                formData.meta_default_description ||
                formData.web_subtitle ||
                publication?.description ||
                ''
              }
              url={formData.url}
              imageUrl={seoPreviewImage}
              variant="facebook"
            />
          </div>
        </AccordionCard>
        <AccordionCard
          title="X (previously Twitter)"
          titleSize="base"
          titleWeight="medium"
          padding="px-4 pb-4 pt-0"
          marginTop="mt-0"
          subText=""
          topBorder={false}
          isControlledState
          isControlledStateOpen={isTwitterAccordionOpen}
          onClick={() => {
            if (!isTwitterAccordionOpen) {
              setIsTwitterAccordionOpen(true);
            } else {
              setIsTwitterAccordionOpen(false);
            }
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between items-center">
              <div className="flex">
                <Typography token="font-medium/text/sm" className="py-2">
                  Meta title
                </Typography>
                <Button
                  disabled={seoMetaTwitterTitleSynced}
                  onClick={syncMetaTwitterTitle}
                  type="button"
                  variant="flush"
                  className={cx('ml-2 mt-[0.6rem] w-4 h-4 !p-0 relative', {
                    'text-surface-300': seoMetaTwitterTitleSynced,
                    'text-surface-500': !seoMetaTwitterTitleSynced,
                  })}
                  data-tip
                  data-for="resync-seo-twitter-title-tooltip"
                >
                  <ArrowPathIcon
                    className={cx('w-4 h-4 mt-1 absolute', {
                      'text-surface-300': seoMetaTwitterTitleSynced,
                      'text-surface-500': !seoMetaTwitterTitleSynced,
                    })}
                  />
                </Button>
                <ReactTooltip id="resync-seo-twitter-title-tooltip" effect="solid" place="top">
                  {seoMetaTwitterTitleSynced ? 'Synced' : 'Resync'} with post title
                </ReactTooltip>
              </div>
              <Typography token="font-normal/text/xs">
                You&apos;ve used{' '}
                <Typography
                  token="font-medium/text/xs"
                  color={lengthColor(truncatedMetaTwitterTitle.length || 0, 60, 10)}
                  colorWeight="600"
                >
                  {truncatedMetaTwitterTitle.length || 0}
                </Typography>
                /200 characters
              </Typography>
            </div>
            <Input
              name="meta-og-title"
              value={truncatedMetaTwitterTitle}
              onChange={(e) => onChange({ meta_twitter_title: e.target.value })}
              className="py-2"
              maxLength={200}
            />
            <Typography token="font-light/text/xs">
              Recommended: <Typography token="font-medium/text/xs">60</Typography> characters per SEO best practices
            </Typography>
            <div className="flex flex-row justify-between items-center">
              <div className="flex">
                <Typography token="font-medium/text/sm" className="py-2">
                  Meta description
                </Typography>
                <Button
                  disabled={seoMetaTwitterDescriptionSynced}
                  onClick={syncMetaTwitterDescription}
                  type="button"
                  variant="flush"
                  className={cx('ml-2 mt-[0.6rem] w-4 h-4 !p-0 relative', {
                    'text-surface-300': seoMetaTwitterDescriptionSynced,
                    'text-surface-500': !seoMetaTwitterDescriptionSynced,
                  })}
                  data-tip
                  data-for="resync-seo-twitter-description-tooltip"
                >
                  <ArrowPathIcon
                    className={cx('w-4 h-4 mt-1 absolute', {
                      'text-surface-300': seoMetaTwitterDescriptionSynced,
                      'text-surface-500': !seoMetaTwitterDescriptionSynced,
                    })}
                  />
                </Button>
                <ReactTooltip id="resync-seo-twitter-description-tooltip" effect="solid" place="top">
                  {seoMetaTwitterDescriptionSynced ? 'Synced' : 'Resync'} with post subtitle
                </ReactTooltip>
              </div>
              <Typography token="font-normal/text/xs">
                You&apos;ve used{' '}
                <Typography
                  token="font-medium/text/xs"
                  color={lengthColor(truncatedMetaTwitterDescription.length || 0, 145, 15)}
                  colorWeight="600"
                >
                  {truncatedMetaTwitterDescription.length || 0}
                </Typography>
                /500 characters
              </Typography>
            </div>
            <Textarea
              name="meta-description"
              value={truncatedMetaTwitterDescription}
              onChange={(e) => onChange({ meta_twitter_description: e.target.value })}
              className="py-2"
              maxLength={500}
            />
            <Typography token="font-light/text/xs">
              Recommended: <Typography token="font-medium/text/xs">145</Typography> characters per SEO best practices
            </Typography>
            <SEOPreview
              title={formData.meta_twitter_title || formData.meta_default_title || formData.web_title}
              description={
                formData.meta_twitter_description ||
                formData.meta_default_description ||
                formData.web_subtitle ||
                publication?.description ||
                ''
              }
              url={formData.url}
              imageUrl={seoPreviewImage}
              variant="twitter"
            />
          </div>
        </AccordionCard>
      </Card>
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6 max-w-[40rem]">
        <Typography token="font-medium/text/base">Post Visibility</Typography>
        <div>
          <Switch
            name="hide-post-from-feed"
            fullWidth
            checked={formData.hide_from_feed}
            onChange={handleHideFromFeedChange}
            prefixLabelText={<Typography token="font-medium/text/sm">Hide the post from feed</Typography>}
            variant="primary"
            showIcons={false}
          />
        </div>
        <div>
          <Switch
            name="feature-the-post"
            checked={isFeatured}
            fullWidth
            onChange={(name, e) => onFeaturedSelected(e)}
            prefixLabelText={<Typography token="font-medium/text/sm">Feature the post</Typography>}
            variant="primary"
            showIcons={false}
            disabled={featuredPosts.length >= 6 && !isFeatured}
            helperText={<TypographyRow className="max-w-md">{featuredPostMessage}</TypographyRow>}
          />
        </div>
      </Card>
      <div className="w-full md:w-2/3 mx-auto flex flex-col gap-6 max-w-[40rem]">
        <AccordionCard
          title="Advanced settings"
          titleSize="base"
          titleWeight="medium"
          padding="px-4 pb-4 pt-0"
          marginTop="mt-0"
          subText=""
          topBorder={false}
          isControlledState
          isControlledStateOpen={isAdvancedSettingsAccordionOpen}
          onClick={() => {
            if (!isAdvancedSettingsAccordionOpen) {
              setIsAdvancedSettingsAccordionOpen(true);
            } else {
              setIsAdvancedSettingsAccordionOpen(false);
            }
          }}
        >
          <Typography token="font-medium/text/sm">Custom display date ({localeString})</Typography>
          <div className="flex flex-row gap-2 items-center">
            <Flatpickr
              className="mt-2 appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed border-gray-300 opacity-100"
              options={{
                enableTime: false,
                altInput: true,
              }}
              defaultValue={formData.override_scheduled_at}
              placeholder="Click to select a date"
              onChange={([date]) => {
                updateDisplayDate(date);
              }}
            />
            <Icon name="Calendar" className="mt-2 ml-[-2.5rem] pointer-events-none text-surface-400" />
          </div>
          <div className="mt-4 text-xs text-surface-900 font-light ">
            By default, the publish date is displayed in the post. You can override this without affecting the actual
            publish time.
          </div>
        </AccordionCard>
      </div>
    </div>
  );
};

export default Web;
