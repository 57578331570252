import { useInfiniteQuery, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import {
  BoostAgreement,
  BoostAgreementCreateWithReferralCount,
  BoostAgreementStatus,
} from '@/interfaces/boosts/grow/boost_agreement';
import { BoostOfferStatus } from '@/interfaces/boosts/grow/boost_offer';
import { OrderBy } from '@/interfaces/boosts/order';
import { Order, Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  boost_agreements: BoostAgreement[] | BoostAgreementCreateWithReferralCount[];
  pagination: Pagination;
}

interface Props {
  boostOfferId?: string;
  recommendingPublicationId?: string;
  search?: string;
  perPage?: number;
  status?: BoostAgreementStatus | BoostAgreementStatus[] | null;
  excludeStatus?: BoostAgreementStatus | BoostAgreementStatus[] | null;
  offerStatus?: BoostOfferStatus | 'all';
  tagsOperator?: string;
  tags?: string[];
  languagesOperator?: string;
  languages?: string[];
  countries?: string[];
  postCountOperator?: string;
  postCount?: number;
  sizeScores?: number[];
  growthScores?: number[];
  engagementScores?: number[];
  boostQualityScores?: number[];
  orderBy?: OrderBy;
  direction?: Order;
  returnAllIds?: boolean;
  isEnabled?: boolean;
}

const useBoostAgreements = ({
  boostOfferId,
  recommendingPublicationId,
  excludeStatus,
  perPage = 10,
  search,
  status,
  offerStatus = 'all',
  tagsOperator,
  tags,
  languagesOperator,
  languages,
  countries,
  postCountOperator,
  postCount,
  sizeScores,
  growthScores,
  engagementScores,
  boostQualityScores,
  orderBy = OrderBy.TOTAL_SUBSCRIBER_COUNT,
  direction = Order.DESC,
  returnAllIds,
  isEnabled = true,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchBoostAgreements = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/boosts/grow/boost_agreements`, {
        params: {
          boost_offer_id: boostOfferId,
          exclude_status: excludeStatus,
          page: pageParam,
          per_page: perPage,
          publication_id: currentPublicationId,
          recommending_publication_id: recommendingPublicationId,
          q: search,
          status,
          offer_status: offerStatus,
          tags_operator: tagsOperator,
          tags,
          languages_operator: languagesOperator,
          languages,
          countries,
          post_count_operator: postCountOperator,
          post_count: postCount,
          size_scores: sizeScores,
          growth_scores: growthScores,
          engagement_scores: engagementScores,
          boost_quality_scores: boostQualityScores,
          order_by: orderBy,
          return_all_ids: returnAllIds,
          direction,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(
    [
      currentPublicationId,
      'boosts',
      'grow',
      'boosts_agreements',
      boostOfferId,
      recommendingPublicationId,
      status,
      excludeStatus,
      search,
      offerStatus,
      tagsOperator,
      tags,
      languagesOperator,
      languages,
      countries,
      postCountOperator,
      postCount,
      sizeScores,
      growthScores,
      engagementScores,
      boostQualityScores,
      orderBy,
      direction,
    ],
    fetchBoostAgreements,
    {
      enabled: isEnabled,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      onSuccess: (data) => {
        const boostAgreements = data.pages.flatMap((page) => page.boost_agreements);

        boostAgreements.forEach((boostAgreement) => {
          queryClient.setQueryData(['boost_agreements', boostAgreement.id], boostAgreement);
        });
      },
      keepPreviousData: true,
    }
  );
};

export default useBoostAgreements;
