import { AttributeSettingProps } from '../../../types';
import { BooleanSettings } from '../../BooleanSettings';
import ActionRenderer from '../ActionRenderer';

const SectionActions = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeType } = activeNodeResult;

  return (
    <ActionRenderer nodeType="section" activeNodeType={activeNodeType}>
      <BooleanSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        property="isHiddenInProduction"
        title="Hide"
      />
    </ActionRenderer>
  );
};

export default SectionActions;
