import ActionModal from '@/components/ActionModal';

import { AUTO_PAUSE_MODAL_TEXTS, AutoPauseModalType } from '../constants';

interface Props {
  isOpen: boolean;
  isWorking: boolean;
  type: AutoPauseModalType;
  onClose: () => void;
  onProceed: () => void;
}

const BoostAgreementAutoPauseConfirmationModal = ({ isOpen, isWorking, type, onClose, onProceed }: Props) => {
  const texts = AUTO_PAUSE_MODAL_TEXTS[type];

  return (
    <ActionModal
      resourceId="boost-agreement-auto-accept-confirmation-modal"
      isOpen={isOpen}
      isWorking={isWorking}
      onClose={onClose}
      onProceed={onProceed}
      modalMessageType="info"
      headerText={texts.header}
      descriptionText={texts.description}
      actionText={texts.action}
    />
  );
};

export default BoostAgreementAutoPauseConfirmationModal;
