import { useCallback } from 'react';
import { TextAa } from '@phosphor-icons/react';

import { PopoverHelper, PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';

const FONT_SIZE_OPTIONS = [
  {
    label: 'xs',
    value: 'text-xs',
  },
  {
    label: 'sm',
    value: 'text-sm',
  },
  {
    label: 'md',
    value: 'text-base',
  },
  {
    label: 'lg',
    value: 'text-lg',
  },
];

type Props = AttributeSettingProps & {
  property: string;
  title: string;
};

export const SmallFontSettings = ({ editor, activeNodeResult, property, title = 'Font Size' }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const textValue =
    FONT_SIZE_OPTIONS.find((option) => option.value === activeNodeAttributes?.[property])?.label || 'None';

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos, property]
  );

  return (
    <PopoverHelper title={title} text={textValue} popoverTitle={title} Icon={TextAa}>
      <div className="flex flex-col gap-2">
        {FONT_SIZE_OPTIONS.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={textValue === option.label}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};
