import { useCallback, useMemo, useState } from 'react';
import { Empty, X } from '@phosphor-icons/react';

import { AttributeDropdown } from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/AttributeDropdown';
import { parseCssValue } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/parseCssValue';
import { Button } from '@/routes/website/_components/UI/Button';
import { SimpleInput, SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';
import { ColorSettings } from '../general/ColorSettings';

type LineHeightSettingsProps = {
  properties?: {
    color: string;
    style: string;
    width: string;
  };
  defaults?: {
    borderWidth?: string;
    borderStyle?: string;
    borderColor?: string;
  };
};

const DEFAULT_MAPPING = {
  color: 'lineDividerColorX',
  style: 'lineDividerStyleX',
  width: 'lineDividerWidthX',
};

export const DEFAULT_LINE_DIVIDER_HEIGHT = '18px';

const HorizontalDividerSettings = ({ properties = DEFAULT_MAPPING, defaults }: LineHeightSettingsProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  const DEFAULT_COLOR = defaults?.borderColor ?? '#000000FF';
  const DEFAULT_BORDER_WIDTH = defaults?.borderWidth ?? '1px';
  const DEFAULT_BORDER_STYLE = defaults?.borderStyle ?? 'none';

  const contentBorderWidthValue =
    selectedContent?.attrs && properties.width in selectedContent.attrs
      ? (selectedContent.attrs[properties.width as keyof typeof selectedContent.attrs] as string)
      : DEFAULT_BORDER_WIDTH;

  const contentBorderStyleValue =
    selectedContent?.attrs && properties.style in selectedContent.attrs
      ? (selectedContent.attrs[properties.style as keyof typeof selectedContent.attrs] as string)
      : DEFAULT_BORDER_STYLE;

  const contentBorderColorValue =
    selectedContent?.attrs && properties.color in selectedContent.attrs
      ? (selectedContent.attrs[properties.color as keyof typeof selectedContent.attrs] as string)
      : DEFAULT_COLOR;

  const width = parseCssValue(contentBorderWidthValue || DEFAULT_BORDER_WIDTH);

  const isNone = contentBorderStyleValue === 'none';

  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!selectedContent || !selectedContent.attrs?.id) return null;

      onUpdateNodeAttributes(selectedContent.attrs?.id, {
        [properties.style]: 'none',
        [properties.color]: DEFAULT_COLOR,
        [properties.width]: DEFAULT_BORDER_WIDTH,
      });
      setIsPopoverOpen(false);
      return true;
    },
    [selectedContent, onUpdateNodeAttributes, properties, setIsPopoverOpen, DEFAULT_COLOR, DEFAULT_BORDER_WIDTH]
  );

  const handleUpdateBorderStyle = useCallback(
    (style: string) => {
      if (!selectedContent || !selectedContent.attrs?.id) return;
      onUpdateNodeAttributes(selectedContent.attrs?.id, {
        [properties.style]: style,
      });
    },
    [selectedContent, onUpdateNodeAttributes, properties.style]
  );

  const handleWidthChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!selectedContent || !selectedContent.attrs?.id) return;
      const parsedValue = parseInt(e.target.value, 10);
      onUpdateNodeAttributes(selectedContent.attrs?.id, {
        [properties.width]: `${parsedValue}${width.unit}`,
      });
    },
    [selectedContent, onUpdateNodeAttributes, width.unit, properties.width]
  );

  const dropdownOptions = useMemo(
    () => [
      {
        label: 'None',
        onSelect: () => handleUpdateBorderStyle('none'),
      },
      {
        label: 'Solid',
        onSelect: () => handleUpdateBorderStyle('solid'),
      },
      {
        label: 'Dashed',
        onSelect: () => handleUpdateBorderStyle('dashed'),
      },
      {
        label: 'Dotted',
        onSelect: () => handleUpdateBorderStyle('dotted'),
      },
    ],
    [handleUpdateBorderStyle]
  );

  if (!selectedContent || !selectedContent.attrs?.id) return null;

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(open) => {
        setIsPopoverOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2 select-none">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Divider X-Axis
          </Text>

          <div className="grow bg-wb-secondary rounded-lg shadow-sm">
            <div className="w-full justify-between flex items-center gap-2 p-2 cursor-pointer">
              <div className="flex items-center gap-1">
                {isNone ? (
                  <Empty className="text-wb-secondary" weight="bold" />
                ) : (
                  <div className="w-4 h-4 rounded-md" style={{ backgroundColor: contentBorderColorValue }} />
                )}

                <Text size="2xs" weight="medium" className="capitalize">
                  {contentBorderStyleValue}
                </Text>
              </div>
              {!isNone && (
                <Button
                  variant="ghost"
                  Icon={X}
                  iconClassName="text-wb-secondary w-3 h-3"
                  onClick={handleReset}
                  className="p-0"
                />
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[264px]" align="start" side="left" sideOffset={20}>
        <div className="max-h-[500px] overflow-y-auto flex flex-col gap-2">
          <Text size="sm" weight="semibold">
            Line Divider
          </Text>

          <ColorSettings
            title="Color"
            property={properties?.color}
            defaultValue={DEFAULT_COLOR || '#000000FF'}
            selectedContent={selectedContent}
            hasTokenizedColors={false}
          />

          <AttributeDropdown title="Style" defaultValue={contentBorderStyleValue} options={dropdownOptions} />

          <div className="flex items-center justify-stretch gap-2 select-none">
            <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
              Width
            </Text>
            <SimpleInputWrapper>
              <SimpleInput
                type="number"
                placeholder="0"
                defaultValue={width.value}
                min={0}
                step={0.5}
                onChange={handleWidthChange}
              />
              <Text size="2xs" variant="secondary" weight="medium">
                px
              </Text>
            </SimpleInputWrapper>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default HorizontalDividerSettings;
