import React, { Fragment, ReactNode, useMemo, useRef } from 'react';
import { Modifier, usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import { v4 as generateUuid } from 'uuid';

import { SelectSubElementClassNames } from '@/components/Form/types';
import { DROP_DOWN_Z_INDEX, SIMPLE_SELECT_OPTIONS_Z_INDEX } from '@/components/zIndexes';
import { Option } from '@/interfaces/general';
import Tooltip from '@/ui/Tooltip';

export interface Props {
  button?: ReactNode;
  className?: string;
  name: string;
  labelText?: string;
  required?: boolean;
  value?: string | string[];
  onClose?: () => void;
  onSelect: (name: string, value: string | any) => void;
  options: Option[];
  disabled?: boolean;
  helperText?: string;
  placeholderText?: string | ReactNode;
  errorText?: string;
  dropdownDirection?: 'up' | 'down';
  buttonClassNames?: SelectSubElementClassNames;
  optionsContainerClassNames?: SelectSubElementClassNames;
  portalMountedId?: string;
  prefixText?: string;
  suffixElement?: ReactNode;
  emptyLabel?: string;
  labelTextClassName?: string;
  shouldBindOptionAsValue?: boolean;
  multiple?: boolean;
  topRightLinkText?: string;
  onTopRightLinkClick?: (name: string) => void;
  variant?: 'default' | 'ellipsis' | 'tabMenu';
  ellipsisClassNames?: string;
  ellipsisBordered?: boolean;
}

const Dropdown = ({
  button,
  className,
  name,
  labelText,
  onClose,
  onSelect,
  value,
  options,
  helperText,
  placeholderText,
  errorText,
  emptyLabel,
  required = false,
  disabled = false,
  labelTextClassName,
  multiple = false,
  shouldBindOptionAsValue = false,
  dropdownDirection = 'down',
  optionsContainerClassNames = {},
  buttonClassNames = {},
  prefixText,
  suffixElement = <ChevronDownIcon className="h-5 w-5 text-surface-500" aria-hidden="true" />,
  onTopRightLinkClick,
  portalMountedId,
  topRightLinkText,
  variant,
  ellipsisClassNames,
  ellipsisBordered,
}: Props) => {
  const popperElRef = useRef(null);
  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);

  const modifiers = useMemo(
    (): Modifier<string, Record<string, unknown>>[] => [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'matchReferenceSize',
        enabled: true,
        fn: ({ state, instance }) => {
          const widthOrHeight =
            state.placement.startsWith('left') || state.placement.startsWith('right') ? 'height' : 'width';

          if (!popperElement) return;

          const popperSize =
            popperElement[`offset${widthOrHeight[0].toUpperCase() + widthOrHeight.slice(1)}` as 'offsetWidth'];
          const referenceSize = state.rects.reference[widthOrHeight];

          if (Math.ceil(popperSize) >= Math.floor(referenceSize)) return;

          // @ts-ignore: Style is accessible
          popperElement.style[widthOrHeight] = `${referenceSize}px`;
          instance.update();
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
    [popperElement]
  );

  const calculatePlacement = () => {
    if (!referenceElement) return 'bottom';
    const rect = referenceElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;

    return spaceBelow < 200 && spaceAbove > spaceBelow ? 'top' : 'bottom'; // Use 'top' if not enough space below
  };

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers,
  });

  const values = [value].flat();
  const selectedOptions = useMemo(
    () => options.filter((option) => !option.isOptGroup && values.includes(option.value)),
    [options, values]
  );
  const selectedOptionsClassNames = useMemo(() => {
    const firstOption = selectedOptions[0];

    if (selectedOptions.length !== 1 || !firstOption.colorClassName) {
      return 'w-full inline-flex truncate';
    }

    return `bg-${firstOption.colorClassName}-100 text-${firstOption.colorClassName}-800 border-${firstOption.colorClassName}-200 px-2.5 py-0.5 text-xs rounded-md border shadow-xs inline-flex gap-x-1`;
  }, [selectedOptions]);

  const handleSelect = (selectedValue: string | string[]) => {
    onSelect(name, selectedValue);
  };

  return (
    <div className={className}>
      <Listbox
        value={value}
        onChange={handleSelect}
        disabled={disabled}
        multiple={multiple}
        by={shouldBindOptionAsValue ? 'value' : undefined}
      >
        {({ open }) => (
          <>
            {labelText && (
              <div className="flex justify-between">
                <Listbox.Label className={labelTextClassName || 'block text-sm font-medium text-gray-900'}>
                  {labelText}
                  {required ? ' *' : ''}
                </Listbox.Label>
              </div>
            )}
            {topRightLinkText && onTopRightLinkClick && (
              <button
                type="button"
                className="text-primary-600 hover:text-primary-900 text-sm ml-2"
                onClick={() => onTopRightLinkClick(name)}
              >
                {topRightLinkText}
              </button>
            )}

            <div className={cx('relative', labelText ? 'mt-1' : undefined)}>
              <div ref={(element) => setReferenceElement(element)}>
                {button && <Listbox.Button className="appearance-none relative w-full">{button}</Listbox.Button>}
                {!button && variant === 'ellipsis' && (
                  <div
                    className={cx(
                      'relative w-full text-right',
                      ellipsisBordered &&
                      'border rounded-md border-gray-200 p-2 flex items-center justify-end hover:bg-surface-100',
                      ellipsisBordered && open && 'bg-surface-100 border-gray-400'
                    )}
                    onClick={(e) => {
                      const target = e.target as HTMLElement;

                      if (referenceElement) {
                        const buttonRef = referenceElement.querySelector('button');
                        if (buttonRef && !target.closest('button')) {
                          buttonRef.click(); // Simulates button click if click is outside the button
                        }
                      }
                    }}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        if (referenceElement) {
                          const buttonRef = referenceElement.querySelector('button');
                          if (buttonRef) {
                            buttonRef.click();
                          }
                        }
                      }
                    }}
                  >
                    <Listbox.Button className={cx('w-[14px] h-[13px] right-0', ellipsisClassNames)}>
                      <EllipsisVerticalIcon />
                    </Listbox.Button>
                  </div>
                )}
                {!button && variant !== 'ellipsis' && (
                  <Listbox.Button
                    className={cx(
                      'relative rounded-md focus:outline-none text-900',
                      buttonClassNames.focus || '',
                      buttonClassNames.text || 'text-left text-sm',
                      buttonClassNames.cursor || 'cursor-default',
                      buttonClassNames.shadow || 'shadow-sm',
                      buttonClassNames.padding || 'py-2 pl-3 pr-10',
                      buttonClassNames.border || 'border border-surface-200',
                      buttonClassNames.hover,
                      buttonClassNames.height,
                      buttonClassNames.width || 'w-full',
                      !disabled && (buttonClassNames.background || 'bg-white'),
                      disabled ? 'bg-gray-50 cursor-not-allowed text-gray-400' : 'cursor-pointer',
                      open && 'border-surface-300 !shadow-[0px_0px_0px_4px_#E5E7EB,0px_1px_2px_0px_rgba(0,0,0,0.05)]',
                      variant === 'tabMenu' && '!py-1 !pl-3 !pr-10 text-sm font-medium leading-5 text-gray-900'
                    )}
                  >
                    {selectedOptions.length > 0 && (
                      <span className={selectedOptionsClassNames}>
                        <span className="truncate">{prefixText}{selectedOptions.map((option) => option.label).join(', ')}</span>
                      </span>
                    )}
                    {selectedOptions.length === 0 && (
                      <span className="w-full inline-flex truncate">
                        <span className="truncate">{prefixText}{placeholderText || 'Select an Option'}</span>
                      </span>
                    )}
                    {suffixElement && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        {suffixElement}
                      </span>
                    )}
                  </Listbox.Button>
                )}
                <Portal node={portalMountedId && document ? document.getElementById(portalMountedId) : null}>
                  <div ref={popperElRef} className={DROP_DOWN_Z_INDEX} style={styles.popper} {...attributes.popper}>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      beforeEnter={() => setPopperElement(popperElRef.current)}
                      afterLeave={() => {
                        setPopperElement(null);
                        onClose?.();
                      }}
                    >
                      <Listbox.Options
                        className={cx(
                          'absolute bg-white shadow-md max-h-60 rounded-md py-2 overflow-auto focus:outline-none text-sm',
                          dropdownDirection === 'up' ? 'bottom-10' : 'shadow-lg',
                          optionsContainerClassNames.height || 'max-h-60', // Max height has some weird behavior with modals so allowing for an escape hatch here. It couldn't be overridden otherwise
                          optionsContainerClassNames.position || 'origin-top-right right-0',
                          optionsContainerClassNames.width || 'w-full',
                          optionsContainerClassNames.margin || 'mt-1',
                          calculatePlacement() === 'top' ? 'bottom-full mb-2' : 'top-full mt-2',
                          SIMPLE_SELECT_OPTIONS_Z_INDEX
                        )}
                      >
                        {options.length === 0 && emptyLabel ? (
                          <div className="py-2 px-3 pr-9 font-bold text-gray-400 text-xs">{emptyLabel}</div>
                        ) : null}
                        {options.map((option, index) => {
                          if (option.isOptGroup) {
                            return (
                              <div
                                key={`select-group-label-${option.label}-${generateUuid()}`}
                                className={cx(
                                  'py-2 px-3 pr-9 font-bold text-gray-400 text-xs',
                                  index > 0 && 'mt-1 border-t'
                                )}
                              >
                                {option.label}
                              </div>
                            );
                          }

                          return (
                            <Tooltip
                              tooltipClass="text-center"
                              id={`${option.value}-${option.label}-tooltip`}
                              key={`${option.value}-${option.label}-tooltip`}
                              text={option.disabled && option.disabledTooltip ? option.disabledTooltip : ''}
                              showIcon={false}
                              autoWidth
                              isEnabled={option.disabled}
                            >
                              <Listbox.Option
                                disabled={option.disabled}
                                key={option.value}
                                className={({ active, selected }) =>
                                  cx(
                                    active || selected ? 'text-gray-900 bg-surface-100' : 'text-gray-900',
                                    !option.disabled && 'cursor-pointer',
                                    'select-none relative py-3 px-4',
                                    option.optionAction ? 'group' : '',
                                    (option.topBorder || option.subtitle) && 'border-t border-surface-100',
                                    option.danger && '!text-feedback-danger-600',
                                    option.disabled && '!text-gray-400 !bg-neutrals-200 cursor-not-allowed'
                                  )
                                }
                                value={shouldBindOptionAsValue ? option : option.value}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div
                                      className={
                                        option.colorClassName
                                          ? `bg-${option.colorClassName}-100 text-${option.colorClassName}-800 border-${option.colorClassName}-200 px-2.5 py-0.5 text-xs rounded-md border shadow-xs inline-flex gap-x-1 pr-6`
                                          : 'flex gap-2 items-center'
                                      }
                                    >
                                      <span
                                        className={cx(
                                          'font-normal truncate',
                                          option.subtitle ? 'font-semibold' : 'font-normal'
                                        )}
                                      >
                                        {option.label}
                                      </span>

                                      {option.optionAction && (
                                        <div className="md:opacity-0 md:group-hover:opacity-100">
                                          {option.optionAction}
                                        </div>
                                      )}
                                    </div>

                                    {option.description ? (
                                      <span
                                        className={cx('font-normal truncate text-xs text-gray-400 pr-6', 'truncate')}
                                      >
                                        {option.description}
                                      </span>
                                    ) : null}

                                    {!selected && option?.optionSuffix ? (
                                      <span
                                        className={cx(
                                          active ? 'text-gray-900' : 'text-gray-800',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        {option.optionSuffix}
                                      </span>
                                    ) : null}

                                    {option.subtitle && (
                                      <div
                                        className={cx(
                                          'font-normal text-xs truncate mt-2 w-full pr-6',
                                          option.danger ? 'text-feedback-danger-600' : 'text-gray-700'
                                        )}
                                      >
                                        {option.subtitle}
                                      </div>
                                    )}

                                    {selected ? (
                                      <span
                                        className={cx(
                                          active ? 'text-gray-900' : 'text-gray-800',
                                          'absolute inset-y-0 right-0 flex items-center pr-3'
                                        )}
                                      >
                                        <CheckIcon className="h-4 w-4" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            </Tooltip>
                          );
                        })}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Portal>
              </div>

              {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
              {errorText && <p className="mt-2 text-xs text-red-500">{errorText}</p>}
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Dropdown;
