/* eslint-disable no-param-reassign */
import { JSONContent } from '@tiptap/core';

import { Site } from '@/interfaces/dream_builder/site';
import { WebTheme } from '@/interfaces/web_theme';

import transformTokenOverrides from './transformThemeOverrides';

/**
 * Applies the given theme to the given node, in place.
 *
 * @param node - The node to apply the theme to.
 * @param theme - The theme to apply. Usually a `WebTheme`
 * @param themeRules - The rules of which attribute of which node gets which theme property. Usually a `Site['theme_rules']`
 */
export const applyThemeToNode = (
  node: JSONContent,
  theme: WebTheme,
  themeRules: Site['theme_rules'],
  parentNode?: JSONContent
) => {
  if (!node?.type) return;

  if (node.content?.length) {
    node.content.forEach((child) => {
      applyThemeToNode(child, theme, themeRules, node);
    });
  }

  // We want to skip applying styles to the buttons text child element when traversing down the tree.
  if (node.type === 'text' && parentNode?.type === 'button') {
    return;
  }

  if (!(node.type in themeRules)) {
    return;
  }

  if (node.type === 'text' && node.type) {
    if (!node.marks) {
      node.marks = [];
    }

    const hasTextStyleMark = node.marks.some((mark) => mark.type === 'textStyle');

    if (!hasTextStyleMark) {
      node.marks.push({
        type: 'textStyle',
        attrs: {},
      });
    }

    // We want to skip applying styles to the buttons text child element when traversing down the tree.
    const textStyleMark =
      node.type === 'text' && parentNode?.type === 'button'
        ? {
            type: 'textStyle',
            attrs: {},
          }
        : node.marks.find((mark) => mark.type === 'textStyle')!;

    const nodeThemeMapping = transformTokenOverrides(textStyleMark.attrs?.tokens || {}, themeRules[node.type]);

    Object.entries(nodeThemeMapping).forEach(([themeProperty, textStyleMarkAttrsToSet]) => {
      if (!(themeProperty in theme) || !textStyleMarkAttrsToSet?.length) return;

      const themePropertyValue = theme[themeProperty as keyof WebTheme];

      textStyleMarkAttrsToSet.forEach((textStyleAttr) => {
        textStyleMark.attrs![textStyleAttr] = themePropertyValue;
      });
    });
  } else if (node.type in themeRules) {
    if (!node.attrs) {
      node.attrs = {};
    }

    const nodeThemeMapping = transformTokenOverrides(node.attrs?.tokens || {}, themeRules[node.type]);

    Object.entries(nodeThemeMapping).forEach(([themeProperty, nodeAttrsToSet]) => {
      if (!(themeProperty in theme) || !nodeAttrsToSet?.length) return;

      const themePropertyValue = theme[themeProperty as keyof WebTheme];

      nodeAttrsToSet.forEach((nodeAttr) => {
        node.attrs![nodeAttr] = themePropertyValue;
      });
    });
  }
};
