/**
 * Darkens a hex color by a given percentage
 * @param hexInput - The hex color to darken (e.g., "#FF0000" or "#F00")
 * @param percentage - Amount to darken by (0-100)
 * @returns Darkened hex color
 */
export const darkenHexColor = (hexInput: string, percentage: number = 20): string => {
  // Remove # if present
  const hex = hexInput.replace('#', '');

  // Convert to RGB
  let r = parseInt(hex.length === 3 ? hex[0] + hex[0] : hex.substring(0, 2), 16);
  let g = parseInt(hex.length === 3 ? hex[1] + hex[1] : hex.substring(2, 4), 16);
  let b = parseInt(hex.length === 3 ? hex[2] + hex[2] : hex.substring(4, 6), 16);

  // Darken
  r = Math.max(0, Math.floor(r * (1 - percentage / 100)));
  g = Math.max(0, Math.floor(g * (1 - percentage / 100)));
  b = Math.max(0, Math.floor(b * (1 - percentage / 100)));

  // Convert back to hex
  return `#${[r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')}`;
};
