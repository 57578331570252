import { Extension } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';

import { getStringAttribute } from '../../../utils/attributesUtils';

interface LayoutAttributeOptions {
  nodesToAddLayoutAttributeTo: PMNode['type']['name'][];
}

interface LayoutAttributeStorage {}

export const LayoutAttribute = Extension.create<LayoutAttributeOptions, LayoutAttributeStorage>({
  name: 'layoutAttribute',

  addOptions() {
    return {
      nodesToAddLayoutAttributeTo: ['columns', 'section'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.nodesToAddLayoutAttributeTo,
        attributes: {
          layout: getStringAttribute('layout', 'data-layout', 'custom', 'custom'),
        },
      },
    ];
  },
});
