import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Editor, JSONContent } from '@tiptap/react';

import { DreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext } from '@/context/website-context';
import { useBeforeNavigate } from '@/hooks/useBeforeNavigate';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';
import useCreateSiteVersion from '@/hooks/useSiteVersion/useCreateSiteVersion';
import { SiteVersion } from '@/interfaces/dream_builder/site_version';
import { contentByType, EditorDocumentType } from '@/routes/website/_components/DreamEditor/extensions/Document';
import { getPlaceholderContentWithDefaultNode } from '@/routes/website/_components/DreamEditor/utils';

function getPreviewContent(siteVersion: SiteVersion | undefined, elementType: EditorDocumentType | undefined) {
  switch (elementType) {
    case 'recommendations_modal':
    case 'signup_modal':
    case 'login_modal':
      if (elementType && siteVersion && siteVersion[elementType]) {
        return siteVersion[elementType] as JSONContent;
      }
      break;
    default:
  }

  return undefined;
}

export const EditorDataProvider = ({ children }: { children: React.ReactNode }) => {
  const { elementType } = useParams<{ elementType: EditorDocumentType }>();
  const { site, previewSiteVersion } = useWebsiteContext();
  const [initialContent, setInitialContent] = useState<JSONContent>();
  const [changesMade, setChangesMade] = useState(false);
  const [editor, setEditor] = useState<Editor | null>(null);

  useEffect(() => {
    // page id changed
    if (!initialContent && site?.draft_site_version && elementType) {
      switch (elementType) {
        case 'recommendations_modal':
        case 'signup_modal':
        case 'login_modal':
          if (site.draft_site_version[elementType] && !initialContent) {
            const content =
              (site.draft_site_version[elementType] as JSONContent) ||
              getPlaceholderContentWithDefaultNode(contentByType[elementType]);

            setInitialContent(content);
          }
          break;
        default:
      }
    }
  }, [site?.draft_site_version, editor, elementType, initialContent]);

  const { mutateAsync: updateSiteVersionAsync, isLoading: isSaveLoading } = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
  });

  const { isLoading: isSaveVersionLoading, mutateAsync: createSiteVersionAsync } = useCreateSiteVersion({});

  const save = useCallback(async () => {
    if (previewSiteVersion) return; // don't save if previewing other versions
    if (!elementType) return;
    await updateSiteVersionAsync({ [elementType]: editor?.getJSON() });
    setChangesMade(false);
  }, [updateSiteVersionAsync, previewSiteVersion, editor, elementType]);

  const onSaveToVersionHistory = useCallback(
    async (autoSave: boolean = false, versionName?: string): Promise<void> => {
      if (previewSiteVersion) return; // don't save if previewing other versions
      if (!elementType) return;

      await updateSiteVersionAsync({ [elementType]: editor?.getJSON() });
      setChangesMade(false);
      await createSiteVersionAsync({
        version_name: versionName,
        version_type: autoSave ? 'autosave' : 'usersave',
      });
    },
    [updateSiteVersionAsync, createSiteVersionAsync, editor, previewSiteVersion, elementType]
  );

  // Auto save every 10 seconds
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        save();
      }
    }, 10000); // 10 seconds

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, save]);

  // Auto save Version History every 10 minutes
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      onSaveToVersionHistory(true);
    }, 10 * 60000); // 10 minutes

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, onSaveToVersionHistory]);

  // Save on navigate away & prompt on reload or leaving page
  useBeforeNavigate(save, !!changesMade);

  const value = useMemo(
    () => ({
      initialContent,
      setEditor,
      changesMade,
      setChangesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory,
      previewContent: getPreviewContent(previewSiteVersion, elementType),
      isSaveVersionLoading,
      editor,
    }),
    [
      initialContent,
      changesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory,
      previewSiteVersion,
      elementType,
      isSaveVersionLoading,
      editor,
    ]
  );

  return <DreamEditorContext.Provider value={value}>{children}</DreamEditorContext.Provider>;
};

export const useEditorDataContext = () => {
  const context = React.useContext(DreamEditorContext);
  if (context === undefined) {
    throw new Error('useEditorDataContext must be used within a EditorDataProvider');
  }
  return context;
};
