import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../context/current-publication-context';
import api from '../services/swarm';

interface ApiResponse {
  count: number;
}

export default function useNotificationsUnreadCount() {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchUnreadCount = (): Promise<ApiResponse> =>
    api
      .get(`/notifications/unread_count`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'notifications', 'unread_count'], fetchUnreadCount, {
    keepPreviousData: true,
  });
}
