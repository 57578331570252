import LoadingBox from '@/components/LoadingBox';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication, useOrganization, useUser } from '@/hooks';
import { Card } from '@/ui/Card';

import PageContainer from '../../_components/PageContainer';

import AddressForm from './AddressForm';
import DeleteWorkspaceForm from './DeleteWorkspaceForm';
import OverviewForm from './OverviewForm';
import PublicationsList from './PublicationsList';

const WorkspaceOverview: React.FC = () => {
  const { data: currentPublication } = useCurrentPublication();
  const {
    data: organizationData,
    isLoading: isLoadingOrgnanization,
    isError: isErrorOrgnanization,
  } = useOrganization(currentPublication?.organization_id, currentPublication?.id || '');
  const { organization } = organizationData || {};
  const { currentUser } = useCurrentUser();
  const { data: user } = useUser(currentPublication?.id || '', currentUser?.id);
  const { settings } = useSettings();

  if (!currentPublication) {
    return null;
  }

  return (
    <LoadingBox isLoading={isLoadingOrgnanization} isError={isErrorOrgnanization}>
      {organization ? (
        <PageContainer key={`${organization.id}-${currentPublication?.id}`}>
          <Card id="overview" showCopyIdButton>
            <OverviewForm organization={organization} publicationId={currentPublication?.id} />
          </Card>
          <Card id="address" showCopyIdButton>
            <AddressForm organization={organization} publicationId={currentPublication?.id} />
          </Card>
          <Card id="publications" showCopyIdButton>
            <PublicationsList organization={organization} publicationId={currentPublication?.id} />
          </Card>
          {(settings?.system_admin || user?.owns_organization) && (
            <Card id="delete" showCopyIdButton>
              <DeleteWorkspaceForm organization={organization} publicationId={currentPublication?.id} />
            </Card>
          )}
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default WorkspaceOverview;
