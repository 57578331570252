import { useMemo } from 'react';

import useShouldNotScrollHorizontally from '../../../extensions/Post/hooks/useShouldNotScrollHorizontally';
import { AttributeDropdown } from '../../helpers/AttributeDropdown';
import DisabledSetting from '../../helpers/DisabledSetting';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import { ColorPopoverGroupSettings } from '../ColorPopoverGroupSettings';
import { ColorSettings } from '../ColorSettings';
import DividerSettings from '../DividerSettings';
import { EmptyStateSettings } from '../EmptyStateSettings';
import { GapSettings } from '../GapSettings';
import { GridColumnSetting } from '../GridColumnSetting';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

import { AuthorsPostSettings } from './AuthorsPostSettings';
import { ImagePostSetting } from './ImagePostSettings';
import { PaginationPostSetting } from './PaginationPostSettings';
import { PremiumPostSettings } from './PremiumPostSettings';
import { SearchPostSetting } from './SearchPostSetting';
import { SeperatorPostSetting } from './SeperatorPostSettings';
import { SubtitlePostsSettings } from './SubtitlePostsSettings';
import { TagsPostSetting } from './TagsPostSettings';
import { TimestampPostSettings } from './TimestampPostSettings';
import { TitlesPostSetting } from './TitlesPostSetting';

const getTooltipName = (isArchivePost: boolean, isTagPost: boolean, isAuthorPost: boolean) => {
  if (isArchivePost) return 'Archive';
  if (isTagPost) return 'Tag Page';
  if (isAuthorPost) return 'Author Page';
  return '';
};

export const PostSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeAttributes } = activeNodeResult;
  const isArchivePost = activeNodeAttributes?.postGroupType === 'archive';
  const isTagPost = activeNodeAttributes?.postGroupType === 'tag_page';
  const isAuthorPost = activeNodeAttributes?.postGroupType === 'author_page';

  const hasSeparator = useMemo(() => {
    return JSON.stringify(activeNodeAttributes?.cardStructure || {})?.includes('separator');
  }, [activeNodeAttributes?.cardStructure]);

  const shouldScrollHorizontally = activeNodeAttributes?.shouldScrollHorizontally === true;
  const disableShouldScrollHorizontally = useShouldNotScrollHorizontally({
    postGroupType: activeNodeAttributes?.postGroupType,
  });

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['post']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Layout">
        <DisabledSetting
          disabled={disableShouldScrollHorizontally}
          tooltip={`Not supported for ${getTooltipName(isArchivePost, isTagPost, isAuthorPost)} posts`}
        >
          <BooleanSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="shouldScrollHorizontally"
            title="Horizontal Scroll"
          />
        </DisabledSetting>
        {shouldScrollHorizontally && (
          <>
            <AttributeDropdown
              editor={editor}
              activeNodeResult={activeNodeResult}
              title="Scroll Position"
              property="sliderControlsDisplayType"
              options={[
                {
                  label: 'Inline',
                  value: 'inline',
                },
                {
                  label: 'Overlay',
                  value: 'overlay',
                },
              ]}
            />
            <ColorPopoverGroupSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              title="Scroll Color"
              properties={[
                { title: 'Background', property: 'sliderControlsBackgroundColor' },
                { title: 'Border', property: 'sliderControlsBorderColor' },
                { title: 'Icon', property: 'sliderControlsTextColor' },
              ]}
            />
          </>
        )}
        <GridColumnSetting editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <DividerSettings editor={editor} activeNodeResult={activeNodeResult} />
        {isArchivePost && (
          <>
            <SearchPostSetting editor={editor} activeNodeResult={activeNodeResult} />
            <PaginationPostSetting editor={editor} activeNodeResult={activeNodeResult} />
            <EmptyStateSettings editor={editor} activeNodeResult={activeNodeResult} />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Cards">
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <ShadowSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="hoverShadow"
          title="Hover Shadow"
        />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings title="Background" property="background" editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="cardPadding" />
        <ImagePostSetting editor={editor} activeNodeResult={activeNodeResult} />
        <TitlesPostSetting editor={editor} activeNodeResult={activeNodeResult} />
        <SubtitlePostsSettings editor={editor} activeNodeResult={activeNodeResult} />
        <TagsPostSetting editor={editor} activeNodeResult={activeNodeResult} />
        <AuthorsPostSettings editor={editor} activeNodeResult={activeNodeResult} />
        <TimestampPostSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PremiumPostSettings editor={editor} activeNodeResult={activeNodeResult} />
        {hasSeparator && <SeperatorPostSetting editor={editor} activeNodeResult={activeNodeResult} />}
      </SectionRenderer>
    </RenderCondition>
  );
};
