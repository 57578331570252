import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { IconSettings } from '../IconSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SliderSettings } from '../SliderSettings';

export const IconBlockSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['icon']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Style">
        <IconSettings editor={editor} activeNodeResult={activeNodeResult} property="icon" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ColorSettings editor={editor} activeNodeResult={activeNodeResult} title="Fill" property="fill" />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Background"
          property="backgroundColor"
        />
        <SliderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Size"
          property="size"
          unit="px"
          min={0}
          max={150}
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
