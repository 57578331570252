import { ReactNodeViewRenderer } from '@tiptap/react';

import { Heading as TiptapHeading } from '@/components/TiptapEditor/extensions/Heading';

import { getStringAttribute } from '../../utils/attributesUtils';

import { HeadingView } from './views/HeadingView';

export const Heading = TiptapHeading.extend({
  addAttributes() {
    return {
      level: {
        default: 1,
        renderHTML: (attributes) => ({ 'data-level': attributes.level }),
        parseHTML: (element) => element.getAttribute('data-level') || null,
      },
      lineHeight: getStringAttribute('lineHeight', 'data-line-height', '120%', '120%'),
      mobileLineHeight: getStringAttribute('mobileLineHeight', 'data-mobile-line-height', '120%', '120%'),
      padding: getStringAttribute('padding', 'data-padding', '8px 8px 8px 8px', '8px 8px 8px 8px'),
    };
  },

  renderHTML({ HTMLAttributes }) {
    // only take the data- attributes prefix
    const dataAttributes = Object.fromEntries(
      Object.entries(HTMLAttributes).filter(([key]) => key.startsWith('data-'))
    );

    return ['h1', dataAttributes, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(HeadingView, {
      attrs: (props) => {
        return {
          ...props.HTMLAttributes,
          style: `width: ${props.node.attrs.width};max-width: ${props.node.attrs.maxWidth};`,
        };
      },
      stopEvent: () => false,
    });
  },
});
