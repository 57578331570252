import { useNavigate, useParams } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { AdNetworkIndustryGroup } from '@/interfaces/ad_network/internal/industry_group';
import { AdNetworkRoleName } from '@/interfaces/ad_network/internal/role';
import { Button } from '@/ui/Button';

import {
  useAdvertiser,
  useAdvertiserDestroy,
  useAdvertiserIndustries,
  useAdvertiserSimilarBrands,
  useIndustryGroups,
} from '../../../hooks/useAdNetwork/internal';
import { AdNetworkAdvertiser } from '../../../interfaces/ad_network/internal/advertiser';
import { AdNetworkIndustry } from '../../../interfaces/ad_network/internal/industry';
import { AdNetworkSimilarBrand } from '../../../interfaces/ad_network/internal/similar_brand';
import { Badge } from '../../../ui/Badge';
import { DescriptionList } from '../_components/DescriptionList';
import { MultiActionCard } from '../_components/MultiActionCard';
import { PageHeading } from '../_components/PageHeading';
import useConfirmDelete from '../_hooks/useConfirmDelete';

interface Props {
  advertiser: AdNetworkAdvertiser;
  industries: AdNetworkIndustry[];
  similarBrands: AdNetworkSimilarBrand[];
  industryGroups: AdNetworkIndustryGroup[];
}

const Advertiser = ({ advertiser, industries, similarBrands, industryGroups }: Props) => {
  const deleteMutation = useAdvertiserDestroy({ id: advertiser.id });
  const warning = 'Once you delete an advertiser, there is no going back. Please be certain.';
  const primaryContact = advertiser.contacts.find((contact) =>
    contact.roles.some((role) => role && role.name === AdNetworkRoleName.PRIMARY)
  );
  const primaryContactDetails = primaryContact
    ? `${primaryContact.first_name} ${primaryContact.last_name} <${primaryContact.email}>`
    : '-';
  const { ConfirmDeleteModal, modalProps, handleDelete, isDeleting } = useConfirmDelete({
    deleteMutation,
    warning,
    confirmWith: advertiser.name,
  });
  const navigate = useNavigate();

  return (
    <>
      <PageHeading>
        <PageHeading.Side>
          <PageHeading.Breadcrumbs>
            <PageHeading.Breadcrumb to="..">Advertisers</PageHeading.Breadcrumb>
            <PageHeading.Breadcrumb to="">{advertiser.name}</PageHeading.Breadcrumb>
          </PageHeading.Breadcrumbs>
        </PageHeading.Side>
        <PageHeading.Side>
          <Button to="campaigns" size="xs" variant="primary-inverse">
            Campaigns
          </Button>
          <Button to="edit" size="xs" variant="primary-inverse" Icon={PencilSquareIcon}>
            Edit
          </Button>
        </PageHeading.Side>
      </PageHeading>

      <div className="divide-y divide-gray-100">
        <DescriptionList>
          <DescriptionList.Item term="ID" copyValue={advertiser.id}>
            {advertiser.id}
          </DescriptionList.Item>
          <DescriptionList.Item term="Name">{advertiser.name}</DescriptionList.Item>
          <DescriptionList.Item term="Description">{advertiser.description}</DescriptionList.Item>
          <DescriptionList.Item term="Logo">
            <img src={advertiser.logo.url} alt={advertiser.name} className="border border-gray-100 rounded p-4" />
          </DescriptionList.Item>
          <DescriptionList.Item term="Industries">
            <div className="flow-root">
              <div className="-m-1 flex flex-wrap max-w-md">
                {industries.map((industry) => (
                  <div className="p-1" key={industry.id}>
                    <Badge>
                      {industry.industry_group_id && (
                        <>{industryGroups.find((group) => group.id === industry.industry_group_id)?.name} &rarr; </>
                      )}
                      {industry.name}
                    </Badge>
                  </div>
                ))}
              </div>
            </div>
          </DescriptionList.Item>
          <DescriptionList.Item term="Similar Brands">
            <div className="flow-root">
              <div className="-m-1 flex flex-wrap max-w-md">
                {similarBrands.map((brand) => (
                  <div className="p-1" key={brand.id}>
                    <Badge>{brand.name}</Badge>
                  </div>
                ))}
              </div>
            </div>
          </DescriptionList.Item>
          <DescriptionList.Item term="Created">
            <time>{moment(advertiser.created_at).fromNow()}</time>
          </DescriptionList.Item>
          <DescriptionList.Item term="Updated">
            <time>{moment(advertiser.updated_at).fromNow()}</time>
          </DescriptionList.Item>
          <DescriptionList.Item term="Primary Contact">{primaryContactDetails}</DescriptionList.Item>
        </DescriptionList>
        <MultiActionCard>
          <MultiActionCard.Item
            title="Configure advertiser"
            description="Edit advertiser details, assign industries, and more."
            onClick={() => navigate('edit')}
          />
          <MultiActionCard.Item
            title="View campaigns"
            description="View and manage campaigns for this advertiser."
            onClick={() => navigate('campaigns')}
          />
          <MultiActionCard.Item
            title="View preferred/excluded list"
            description="View and manage preferred and excluded publishers for this advertiser."
            onClick={() => navigate('publisher_list')}
          />
          <MultiActionCard.Item
            title="Delete this advertiser"
            description={warning}
            loading={isDeleting}
            onClick={() => handleDelete()}
            danger
          />
        </MultiActionCard>
      </div>
      <ConfirmDeleteModal {...modalProps} />
    </>
  );
};

export default function Loader() {
  const { advertiser_id: id } = useParams<'advertiser_id'>() as { advertiser_id: string };
  const advertiserQuery = useAdvertiser({ id });
  const advertiserIndustriesQuery = useAdvertiserIndustries({ id });
  const advertiserSimilarBrandsQuery = useAdvertiserSimilarBrands({ id });
  const industryGroupsQuery = useIndustryGroups();

  const isSuccess =
    advertiserQuery.isSuccess &&
    advertiserIndustriesQuery.isSuccess &&
    advertiserSimilarBrandsQuery.isSuccess &&
    industryGroupsQuery.isSuccess;
  const isError =
    advertiserQuery.isError ||
    advertiserIndustriesQuery.isError ||
    advertiserSimilarBrandsQuery.isError ||
    industryGroupsQuery.isError;
  const isLoading =
    !isError &&
    (advertiserQuery.isLoading ||
      advertiserIndustriesQuery.isLoading ||
      advertiserSimilarBrandsQuery.isLoading ||
      industryGroupsQuery.isLoading);

  if (!isSuccess || isLoading || isError) return null;

  return (
    <Advertiser
      advertiser={advertiserQuery.data}
      industries={advertiserIndustriesQuery.data}
      similarBrands={advertiserSimilarBrandsQuery.data}
      industryGroups={industryGroupsQuery.data.industry_groups}
    />
  );
}
