import { Link } from 'react-router-dom';
import { LightBulbIcon } from '@heroicons/react/24/outline';

import FaqModal from '../../../../../components/FaqModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SmartWarmingFaqModal = ({ isOpen, onClose }: Props) => (
  <FaqModal
    title="Smart Warming Frequently Asked Questions"
    faqs={[
      {
        question: 'How does my Custom Domain affect deliverability?',
        answer: (
          <div className="text-sm">
            The reputation of domain names and IP addresses play a crucial role in email deliverability. When you
            introduce a new IP + domain combination, in this case your custom domain with one of beehiiv&apos;s IPs,
            it&apos;s important to introduce it gradually to the email ecosystem to build a good reputation amongst
            ESPs.{' '}
            <a
              href="https://support.beehiiv.com/hc/en-us/sections/12313963232279-Deliverability"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-2 text-primary-700 font-medium hover:text-primary-800 inline-flex items-baseline"
            >
              <LightBulbIcon className="w-4 h-4 self-center mr-1" />
              Learn more about deliverability
            </a>
          </div>
        ),
        defaultOpen: true,
      },
      {
        question: 'What is Smart Warming?',
        answer: (
          <div className="text-sm">
            Smart Warming is the process of gradually increasing sending volume on your custom domain to optimize
            deliverability. We&apos;ve designed this process to analyse many different signals to optimize
            deliverability for you and ensure your emails land in your subscribers inboxes, not spam. This happens
            automatically in the background, so nothing will be impacted for you.
            <a
              href="https://support.beehiiv.com/hc/en-us/articles/14976194322327"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-2 text-primary-700 font-medium hover:text-primary-800 flex items-baseline"
            >
              <LightBulbIcon className="w-4 h-4 self-center mr-1" />
              Learn more about Smart Warming in detail
            </a>
          </div>
        ),
      },
      {
        question: 'Do I need Smart Warming?',
        answer: (
          <div className="text-sm">
            The Smart Warming process is crucial for all setups, whether you are:
            <ul className="mt-2 list-disc pl-6 mb-2">
              <li>Starting a new newsletter</li>
              <li>Migrating an existing newsletter to a new domain</li>
              <li>Migrating an existing newsletter that used this domain to beehiiv</li>
              <li>Currently sending using our shared beehiiv.com domain</li>
            </ul>
            <a
              href="https://support.beehiiv.com/hc/en-us/articles/14976017431319"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-2 text-primary-700 font-medium hover:text-primary-800 flex items-baseline"
            >
              <LightBulbIcon className="w-4 h-4 self-center mr-1" />
              Learn more about how Smart Warming applies to you
            </a>
          </div>
        ),
      },
      {
        question: 'What functionality is impacted during Smart Warming?',
        answer: (
          <div className="text-sm">
            All of beehiiv&apos;s functionality is available during Smart Warming, except A/B testing of email subject
            lines.
          </div>
        ),
      },
      {
        question: 'How long does Smart Warming take to complete?',
        answer: (
          <div className="text-sm">
            Smart Warming is a dynamic process that analyzes many different signals while increasing the sending volume
            on your custom domain. Depending on your publication&apos;s list size and sending cadence the length of the
            process may vary, but typically takes 4-8 weeks to complete. You can track progress under Settings &rarr;
            Publication &rarr; Domains &rarr; Email Domain section.
          </div>
        ),
      },
      {
        question: 'Can I opt-out of Smart Warming?',
        answer: (
          <div className="text-sm">
            We strongly recommend anyone who is setting up a new Custom Domain for Email Sending to go through the Smart
            Warming process. We&apos;ve designed this process to analyse many different signals to optimize
            deliverability for you and ensure your emails land in your subscribers inboxes, not spam. If you want to
            opt-out of Smart Warming,{' '}
            <Link reloadDocument to="/?new_support_ticket" className="text-red-900 hover:text-red-800 underline">
              contact our support
            </Link>{' '}
            acknowledging that you understand the risks involved and want to proceed without Smart Warming.
          </div>
        ),
      },
    ]}
    isOpen={isOpen}
    onClose={onClose}
  />
);

export default SmartWarmingFaqModal;
