import { useCallback } from 'react';

import { Button } from '@/routes/website/_components/UI/Button';

import { Image } from '../../../extensions';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import { LinkToSettingsBlock } from '../LinkToSettingsBlock';
import { NewTabSettingsBlock } from '../NewTabSettingsBlock';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

import { ImageFitSettings } from './ImageFitSettings';
import { ImageSrcSettings } from './ImageSrcSettings';
import { ImageWidthSettings } from './ImageWidthSettings';

export const ImageSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const hasLink = activeNodeAttributes?.href !== undefined && activeNodeAttributes?.href !== null;

  const onAddLink = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (activeNodePos === undefined || !activeNodeType) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'href', '');
        return true;
      });
    },
    [activeNodePos, activeNodeType, editor]
  );

  const onRemoveLink = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (activeNodePos === undefined || !activeNodeType) return;
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'href', undefined);
        return true;
      });
    },
    [activeNodePos, activeNodeType, editor]
  );

  const onFullWidth = useCallback(
    (properties: { isFullWidth: string; width: string; height: string }) => (isFullWidth: boolean) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, properties.isFullWidth, isFullWidth);
        return true;
      });
      try {
        const node = editor.view.nodeDOM(activeNodePos) as HTMLElement;
        // get the bounding box to recalibrate height
        const boundingBox = node.getBoundingClientRect();
        // recalibrate width to be full width of the bounding box
        const { width } = boundingBox;
        const height = activeNodeAttributes.aspectRatio ? width / activeNodeAttributes.aspectRatio : undefined;
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, properties.width, width);
          tr.setNodeAttribute(activeNodePos, properties.height, height);
          return true;
        });
      } catch (e) {
        console.error('unable to recalibrate height', e);
      }
    },
    [activeNodePos, activeNodeAttributes, editor]
  );

  return (
    <RenderCondition editor={editor} allowedNodeTypes={[Image.name]}>
      <SectionRenderer title="Mobile" isMobile>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          onChange={onFullWidth({ isFullWidth: 'mobileIsFullWidth', width: 'mobileWidth', height: 'mobileHeight' })}
          property="mobileIsFullWidth"
          title="Full Width"
        />
        {activeNodeResult.activeNodeAttributes.mobileIsFullWidth !== true && (
          <ImageWidthSettings editor={editor} activeNodeResult={activeNodeResult} property="mobileWidth" />
        )}
      </SectionRenderer>

      <SectionRenderer
        title="Link"
        actions={
          <Button variant="ghost" size="sm" className="text-wb-accent" onClick={hasLink ? onRemoveLink : onAddLink}>
            {hasLink ? 'Remove' : 'Add'}
          </Button>
        }
        collapsible={hasLink}
        isOpen={!!hasLink}
      >
        {hasLink && (
          <>
            <LinkToSettingsBlock editor={editor} activeNodeResult={activeNodeResult} property="href" />
            <NewTabSettingsBlock editor={editor} activeNodeResult={activeNodeResult} />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Media">
        <ImageSrcSettings editor={editor} activeNodeResult={activeNodeResult} />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Alt Text"
          property="alt"
          placeholder="Image description"
        />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} title="Shadow" property="boxShadow" />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          onChange={onFullWidth({ isFullWidth: 'isFullWidth', width: 'width', height: 'height' })}
          property="isFullWidth"
          title="Full Width"
        />
        {activeNodeResult.activeNodeAttributes.isFullWidth !== true && (
          <ImageWidthSettings editor={editor} activeNodeResult={activeNodeResult} />
        )}
        <ImageFitSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
