import { useAsyncExport, useCurrentPublication } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';
import { ResourceListFilters } from '@/ui/ResourceListFilters';

interface Props {
  searchQuery: string;
  onSetSearchQuery: (q: string) => void;
  filter: string;
  onSetFilter: (filter: string) => void;
  orderBy: string;
  setOrderBy: (order: string) => void;
}

const FulfillmentTableHeader = ({ searchQuery, onSetSearchQuery, filter, onSetFilter, orderBy, setOrderBy }: Props) => {
  const { data: currentPublication } = useCurrentPublication();
  const currentPublicationId = useCurrentPublicationId();
  const { isExporting, startExport } = useAsyncExport(currentPublicationId, {
    exportType: 'referral_program_subscriber',
    resourceType: 'referral_program',
    resourceId: currentPublication?.referral_program_id || '',
  });

  return (
    <div className="flex flex-col lg:flex-row gap-y-2 sm:justify-end gap-x-2">
      <ResourceListFilters
        search={searchQuery}
        setSearch={onSetSearchQuery}
        searchPlaceholder="Search subscriber emails"
        filter={filter}
        setFilter={onSetFilter}
        filterOptions={[
          { label: 'All', value: 'all' },
          { label: 'Pending', value: 'pending' },
          { label: 'Awaiting Promo Code', value: 'awaiting_promo_code' },
          { label: 'Fulfilled', value: 'fulfilled' },
          { label: 'Ignored', value: 'ignored' },
        ]}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        sortOrderOptions={[
          { label: 'Pending First', value: 'pending' },
          { label: 'Fulfilled First', value: 'fulfilled' },
          { label: 'Created At', value: 'created_at' },
        ]}
      />
      <Button onClick={startExport} loading={isExporting} size="xs">
        Download Referrals
      </Button>
    </div>
  );
};

export default FulfillmentTableHeader;
