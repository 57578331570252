import { Link } from 'react-router-dom';

import { Typography } from '@/components/Typography';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';

const DeleteAccountForm = () => {
  return (
    <BodyContainer>
      <CardHeader
        title="Account Profile Deletion"
        description="Deleting your workspace automatically removes your account profile. These actions occur together to ensure all your data is properly managed."
      />
      <Link to="/settings/workspace/overview">
        <Typography token="font-medium/text/sm" color="secondary" colorWeight="600" className="underline">
          Take me to my workspace settings
        </Typography>
      </Link>
    </BodyContainer>
  );
};

export default DeleteAccountForm;
