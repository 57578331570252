/* eslint-disable consistent-return */

import { Extension } from '@tiptap/core';
import { Node } from '@tiptap/pm/model';
import { EditorState, Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    paragraphLists: {
      /**
       * Splits a paragraph list item into two.
       */
      splitParagraphListItem: () => ReturnType;
    };
  }
}

interface ParagraphSplit {
  textNodes: { child: Node; offset: number; index: number }[];
  from: number;
  to: number;
  text: string;
}

interface ParagraphData {
  node: Node;
  pos: number;
  from: number;
  to: number;
  splits: ParagraphSplit[];
}

const bulletListIdentifierRegex = /^\s*([-+—*•])\s/;
const newBulletListIdentifierRegex = /^\s*([-+—*])\s/;
const bulletListMarkerRegex = /[-+—*•]/;

const orderedListIdentifierRegex = /^\s*(\d+)\.\s/;
const orderedListMarkerRegex = /\d+/;

export const ParagraphListsPluginKey = new PluginKey('paragraphLists');

export const getParagraphsData = (doc: Node): ParagraphData[] => {
  const paragraphs: ParagraphData[] = [];

  doc.descendants((node, pos) => {
    if (node.type.name !== 'paragraph') {
      return;
    }

    const splits: any[] = [];

    let split: ParagraphSplit = {
      textNodes: [],
      text: '',
      from: -1,
      to: -1,
    };

    node.content.forEach((child, offset, index) => {
      if (child.type.name !== 'hardBreak') {
        split.textNodes.push({
          child,
          offset,
          index,
        });
      }

      if (child.type.name === 'hardBreak' || index === node.children.length - 1) {
        const text = split.textNodes.reduce((str, item) => {
          return str + item.child.text;
        }, '');

        split.text = text;

        split.from = pos + 1 + (split.textNodes[0]?.offset ?? 0);
        split.to = split.from + split.text.length;

        splits.push(split);

        split = { textNodes: [], text: '', from: -1, to: -1 };
      }
    });

    paragraphs.push({
      node,
      pos,
      from: pos,
      to: pos + node.nodeSize,
      splits,
    });
  });

  return paragraphs;
};

export const getParagraphListDecorations = (doc: Node) => {
  const paragraphs = getParagraphsData(doc);

  const decorations: Decoration[] = [];

  paragraphs.forEach((paragraph) => {
    paragraph.splits.forEach((split) => {
      const isBulletList = bulletListIdentifierRegex.test(split.text);

      if (isBulletList) {
        const match = split.text.match(bulletListMarkerRegex);

        if (match?.index !== undefined) {
          const offset = match.index + split.textNodes[0].offset;
          const pos = paragraph.pos + 1 + offset;

          decorations.push(
            Decoration.inline(
              pos,
              pos + 2,
              {
                class: 'bullet-list-marker',
              },
              {
                contenteditable: 'false',
              }
            )
          );
        }

        return;
      }

      const isOrderedList = orderedListIdentifierRegex.test(split.text);

      if (isOrderedList) {
        const match = split.text.match(orderedListMarkerRegex);

        if (match?.index !== undefined) {
          const offset = match.index + split.textNodes[0].offset;
          const pos = paragraph.pos + 1 + offset;

          decorations.push(
            Decoration.inline(
              pos,
              pos + match[0].length + 2,
              {
                class: 'ordered-list-marker',
              },
              {
                contenteditable: 'false',
              }
            )
          );
        }
      }
    });
  });

  return decorations.length ? DecorationSet.create(doc, decorations) : DecorationSet.empty;
};

export const getParagraphOrderedListGroups = (paragraph: ParagraphData) => {
  const orderedListSplitGroups: ParagraphSplit[][] = [];

  let splitGroup: ParagraphSplit[] = [];

  for (let i = 0; i < paragraph.splits.length; i += 1) {
    const split = paragraph.splits[i];

    const isOrderedList = orderedListIdentifierRegex.test(split.text);

    if (isOrderedList) {
      splitGroup.push(split);
    } else {
      orderedListSplitGroups.push(splitGroup);
      splitGroup = [];
    }
  }

  orderedListSplitGroups.push(splitGroup);

  return orderedListSplitGroups;
};

export const getParagraphBulletListGroups = (paragraph: ParagraphData) => {
  const bulletListSplitGroups: ParagraphSplit[][] = [];

  let splitGroup: ParagraphSplit[] = [];

  for (let i = 0; i < paragraph.splits.length; i += 1) {
    const split = paragraph.splits[i];

    const isBulletList = bulletListIdentifierRegex.test(split.text);

    if (isBulletList) {
      splitGroup.push(split);
    } else {
      bulletListSplitGroups.push(splitGroup);
      splitGroup = [];
    }
  }

  bulletListSplitGroups.push(splitGroup);

  return bulletListSplitGroups;
};

export const getFixListMarkersTr = (state: EditorState) => {
  const { tr } = state;
  const { doc } = tr;

  const paragraphs = getParagraphsData(doc);

  paragraphs.forEach((paragraph) => {
    paragraph.splits.forEach((split) => {
      const isNewBulletList = newBulletListIdentifierRegex.test(split.text);

      if (isNewBulletList) {
        const match = split.text.match(bulletListMarkerRegex);

        if (match?.index !== undefined) {
          const offset = match.index + split.textNodes[0].offset;
          const pos = paragraph.pos + 1 + offset;

          const $pos = tr.doc.resolve(pos);

          tr.replaceRangeWith(pos, pos + 1, state.schema.text('•', $pos.marks()));
        }
      }
    });
  });

  // fix ordered list item indices
  paragraphs.forEach((paragraph) => {
    const orderedListSplitGroups = getParagraphOrderedListGroups(paragraph);

    for (let i = 0; i < orderedListSplitGroups.length; i += 1) {
      const listItemsGroup = orderedListSplitGroups[i];

      for (let j = 0; j < listItemsGroup.length; j += 1) {
        const split = listItemsGroup[j];
        const match = split.text.match(/(\d+)\./);

        if (match?.index !== undefined) {
          const shouldBeIndex = j + 1;
          const existingIndex = parseInt(match?.[1], 10);

          if (existingIndex !== shouldBeIndex) {
            const from = split.from + match.index;
            const to = from + `${existingIndex}`.length;
            tr.insertText(`${shouldBeIndex}`, tr.mapping.map(from), tr.mapping.map(to));
          }
        }
      }
    }
  });

  return tr;
};

export const ParagraphLists = Extension.create({
  name: 'paragraphLists',

  addCommands() {
    return {
      splitParagraphListItem:
        () =>
        ({
          chain,
          state: {
            doc,
            selection: { from },
          },
        }) => {
          const paragraphs = getParagraphsData(doc);

          const paragraph = paragraphs.find((p) => p.from <= from && p.to >= from);

          if (!paragraph) {
            return false;
          }

          const { splits } = paragraph;

          const split = splits.find((s) => s.from <= from && s.to >= from);

          if (!split) {
            return false;
          }

          const isSplitBulletList = bulletListIdentifierRegex.test(split.text);
          const isSplitOrderedList = orderedListIdentifierRegex.test(split.text);

          if (!isSplitBulletList && !isSplitOrderedList) {
            return false;
          }

          if (isSplitBulletList) {
            const bulletListSplitGroups = getParagraphBulletListGroups(paragraph);

            const group = bulletListSplitGroups.find((g) => g.some((s) => s.from <= from && s.to >= from));

            if (group) {
              const splitIndex = group.findIndex((s) => s.from <= from && s.to >= from);

              if (
                split &&
                splitIndex !== undefined &&
                splitIndex === group.length - 1 &&
                split.textNodes.length === 1 &&
                split.textNodes[0].child.text?.length === 2
              ) {
                return chain()
                  .deleteRange({ from: from - 2, to: from })
                  .run();
              }
            }

            return chain()
              .insertContentAt(from, [
                {
                  type: 'hardBreak',
                },
                {
                  type: 'text',
                  marks: doc
                    .resolve(from)
                    .marks()
                    .map((m) => m.toJSON()),
                  text: '• ',
                },
              ])
              .run();
          }

          const orderedListSplitGroups = getParagraphOrderedListGroups(paragraph);

          const group = orderedListSplitGroups.find((g) => g.some((s) => s.from <= from && s.to >= from));

          if (group) {
            const splitIndex = group.findIndex((s) => s.from <= from && s.to >= from);

            if (
              split &&
              splitIndex !== undefined &&
              splitIndex === group.length - 1 &&
              split.textNodes.length === 1 &&
              split.textNodes[0].child.text?.length === 3
            ) {
              return chain()
                .deleteRange({ from: from - 3, to: from })
                .run();
            }
          }

          const match = split.text.match(/(\d+)\./);

          if (!match) {
            return false;
          }

          const index = parseInt(match?.[1], 10);

          return chain()
            .insertContentAt(from, [
              {
                type: 'hardBreak',
              },
              {
                type: 'text',
                marks: doc
                  .resolve(from)
                  .marks()
                  .map((m) => m.toJSON()),
                text: `${index + 1}. `,
              },
            ])
            .run();
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitParagraphListItem(),
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: ParagraphListsPluginKey,
        appendTransaction: (transactions, _oldState, newState) => {
          if (!transactions.some((tr) => tr.docChanged)) {
            return;
          }

          const fixBulletListMarkerTr = getFixListMarkersTr(newState);

          if (fixBulletListMarkerTr.docChanged) {
            return fixBulletListMarkerTr;
          }

          return null;
        },
        state: {
          init(_config, { doc }) {
            return {
              decorations: getParagraphListDecorations(doc),
            };
          },
          apply(tr, pluginState) {
            if (!tr.docChanged) {
              return pluginState;
            }

            return {
              decorations: getParagraphListDecorations(tr.doc),
            };
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)?.decorations ?? DecorationSet.empty;
          },
        },
      }),
    ];
  },
});
