import { XLogo } from '@phosphor-icons/react';

import SocialsSelectionModal from '../../../../extensions/Socials/modals/SocialsSelectionModal';
import { AttributeSettingProps } from '../../../types';
import { DataModalSettings } from '../../DataModalSettings';
import ActionRenderer from '../ActionRenderer';

const SocialsActions = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNode, activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const { insertedFromSidebar } = activeNodeAttributes;

  const handleCloseModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', false);
      return true;
    });
  };

  const handleOpenModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', true);
      return true;
    });
  };

  return (
    <ActionRenderer nodeType="socials" activeNodeType={activeNodeType}>
      <DataModalSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        handleOpenModal={handleOpenModal}
        text="Socials"
        Icon={XLogo}
      />
      {insertedFromSidebar && activeNode && (
        <SocialsSelectionModal
          pos={activeNodePos}
          editor={editor}
          node={activeNode}
          isOpen={insertedFromSidebar}
          onClose={handleCloseModal}
        />
      )}
    </ActionRenderer>
  );
};

export default SocialsActions;
