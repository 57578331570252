import {
  AdNetworkAcceptedEvent,
  AdNetworkPendingEvent,
  AdNetworkPublishedEvent,
  CalendarType,
  Event,
} from '../../utils/useCalendar/types';

import AdNetworkAcceptedEventDetails from './AdNetworkAcceptedEventDetails';
import AdNetworkPendingEventDetails from './AdNetworkPendingEventDetails';
import AdNetworkPublishedEventDetails from './AdNetworkPublishedEventDetails';
import { EventDetailsProps } from './types';

const isPublished = (event: Event): event is AdNetworkPublishedEvent =>
  event.calendar === CalendarType.AD_NETWORK && event.category === 'published';

const isAccepted = (event: Event): event is AdNetworkAcceptedEvent =>
  event.calendar === CalendarType.AD_NETWORK && event.category === 'accepted';

const isPending = (event: Event): event is AdNetworkPendingEvent =>
  event.calendar === CalendarType.AD_NETWORK && event.category === 'pending';

const AdNetworkEventDetails = ({ event }: EventDetailsProps) => {
  if (isPublished(event)) {
    return <AdNetworkPublishedEventDetails event={event} />;
  }

  if (isAccepted(event)) {
    return <AdNetworkAcceptedEventDetails event={event} />;
  }

  if (isPending(event)) {
    return <AdNetworkPendingEventDetails event={event} />;
  }

  return null;
};

export default AdNetworkEventDetails;
