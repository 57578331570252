import { Link } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import Card from '@/components/Card';
import { getColor } from '@/components/DesignTokens';
import { Typography } from '@/components/Typography';
import useStripeConnectAccount from '@/hooks/boosts/monetize/useStripeConnectAccount';
import { useOpportunities } from '@/hooks/useAdNetwork/publisher';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { AdNetworkCampaignPayoutMethod } from '@/interfaces/ad_network/internal/campaign';

interface Props {
  classes?: string;
}

const StripeConnectMessage = ({ classes }: Props) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: stripeConnectAccount, isLoading: isStripeConnectAccountLoading } =
    useStripeConnectAccount(currentPublicationId);
  const { boosts_account_status: boostsAccountStatus } = stripeConnectAccount || {};
  const { data: opportunitiesData, isLoading: isOpportunitiesLoading } = useOpportunities({
    payoutMethod: AdNetworkCampaignPayoutMethod.AUTO,
  });
  const requiresStripeConnect = (opportunitiesData?.pages.flatMap((page) => page.opportunities)?.length || 0) > 0;

  if (isStripeConnectAccountLoading || isOpportunitiesLoading) return null;
  if (!isStripeConnectAccountLoading && boostsAccountStatus === 'active') return null;
  if (!isOpportunitiesLoading && !requiresStripeConnect) return null;

  return (
    <Card className="relative" background={cx(getColor('info/50', 'background'), classes)}>
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-2 items-center">
          <InformationCircleIcon className={cx('w-5 h-5', getColor('info/500', 'text'))} />
          <Typography weight="semibold" size="sm" className="!text-feedback-info-600">
            Stripe Required
          </Typography>
        </div>
        <div className="ml-7">
          <Typography size="xs" className="block !text-feedback-info-600">
            All Ad Network payments are made through Stripe, directly into your beehiiv wallet. Please ensure your
            Stripe account is set up in order to receive earnings.
          </Typography>
          <Link to="/settings/publication/payment_accounts">
            <Typography weight="semibold" size="sm" className="!text-feedback-info-600 hover:!text-feedback-info-400">
              Connect Stripe
            </Typography>
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default StripeConnectMessage;
