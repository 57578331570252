import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Bug, ClockCounterClockwise, Eye, Plus } from '@phosphor-icons/react';

import { DreamEditorProvider, useDreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext, WebsiteProvider } from '@/context/website-context';

import { AttributesPanelNavbarFooterContextProvider } from '../_components/DreamEditor/AttributesPanel/navbarFooterContextProvider';
import Editor from '../_components/Editor';
import EditorPageLoader from '../_components/Editor/EditorPageLoader';
import { Layout } from '../_components/Layout';
import { ActionMenu } from '../_components/Layout/ActionMenu';
import AttributesPanelPortal from '../_components/Layout/AttributesPanelPortal';
import AutoSwapComingSoon from '../_components/Layout/AutoSwapComingSoon';
import InsertPanelPortal from '../_components/Layout/InsertPanelPortal';
import KnowledgePanelPortal from '../_components/Layout/KnowledgePanelPortal';
import SidePanelTabs from '../_components/Layout/SidePanelTabs';
import VersionHistoryPortal from '../_components/Layout/VersionHistoryPortal';
import PageVersionHistory from '../_components/PageVersionHistory';
import { NavSection } from '../_components/SideNav/NavSection';
import { SavePageIndicator } from '../_components/SideNav/SaveIndicator';
import { Button } from '../_components/UI/Button';
import { Tooltip } from '../_components/UI/Tooltip';
import VersionHistoryNotice from '../_components/VersionHistoryNotice';

import PreviewModal from './_components/Preview';

const SideNavChildren = memo(
  ({
    isInsertPanelOpen,
    insertPanelRef,
    tab,
  }: {
    isInsertPanelOpen: boolean;
    insertPanelRef: React.RefObject<HTMLDivElement>;
    tab: string | null;
  }) => {
    const { pagesRoutes, defaultRoutes, previewSiteVersion } = useWebsiteContext();
    return (
      <>
        <SidePanelTabs
          pagesRoutes={pagesRoutes}
          defaultRoutes={defaultRoutes}
          previewSiteVersion={previewSiteVersion}
          defaultTab={tab || undefined}
        />
        <InsertPanelPortal isInsertPanelOpen={isInsertPanelOpen} ref={insertPanelRef} />
        {!previewSiteVersion && (
          <NavSection>
            <SavePageIndicator />
          </NavSection>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.isInsertPanelOpen === nextProps.isInsertPanelOpen;
  }
);

const PageEditorPage = () => {
  const { previewSiteVersion, setPreviewSiteVersion, isLoading, pageVersionHistoryId, setPageVersionHistoryId } =
    useWebsiteContext();
  const [searchParams] = useSearchParams();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);

  const { editor } = useDreamEditorContext();
  const tab = searchParams.get('tab');

  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion]);

  const insertPanelRef = useRef<HTMLDivElement>(null);
  const insertPanelTriggerRef = useRef<HTMLButtonElement>(null);

  const handlePreviewModalOpen = useCallback(() => {
    setIsVersionHistoryPanelOpen(false);
    setIsPreviewModalOpen((prev) => !prev);
  }, []);

  const handleVersionHistoryPanelOpen = useCallback(() => {
    setIsVersionHistoryPanelOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Layout
        logoDropdownProps={{
          actionText: 'Go To Dashboard',
          backPath: '/',
        }}
        mainClassName="bg-wb-secondary"
        isSidebarResizable
        leftChildren={
          <>
            <ActionMenu
              Icon={Plus}
              text="Insert"
              isActive={isInsertPanelOpen}
              onClick={() => {
                setIsInsertPanelOpen((prev) => !prev);
              }}
              ref={insertPanelTriggerRef}
            />
            <AutoSwapComingSoon />
          </>
        }
        rightChildren={
          <>
            <Tooltip center="Report a Bug" delay={300}>
              <Button
                variant="secondary"
                Icon={Bug}
                iconWeight="bold"
                onClick={() => {
                  window.open('https://app.formcrafts.com/b3bed052', '_blank');
                }}
                className="h-full"
              />
            </Tooltip>
            <Tooltip center="Version History" delay={300}>
              <Button
                variant="secondary"
                Icon={ClockCounterClockwise}
                iconWeight="bold"
                onClick={handleVersionHistoryPanelOpen}
                className="h-full"
              />
            </Tooltip>
            <Tooltip center="Preview" delay={300}>
              <Button
                variant="secondary"
                Icon={Eye}
                iconWeight="fill"
                className="h-full"
                onClick={handlePreviewModalOpen}
              />
            </Tooltip>
          </>
        }
        sidenavChildren={
          <SideNavChildren isInsertPanelOpen={isInsertPanelOpen} insertPanelRef={insertPanelRef} tab={tab} />
        }
        rightSideChildren={
          <>
            <AttributesPanelPortal />
            <KnowledgePanelPortal />
            <VersionHistoryPortal
              isVersionHistoryPanelOpen={isVersionHistoryPanelOpen}
              setIsVersionHistoryPanelOpen={setIsVersionHistoryPanelOpen}
            />
          </>
        }
        titleType="page_name"
        onContainerClick={() => {
          editor?.commands.focusDoc();
        }}
      >
        <PreviewModal isOpen={isPreviewModalOpen} setIsOpen={setIsPreviewModalOpen} />
        {isLoading ? (
          <EditorPageLoader />
        ) : (
          <Editor className="typedream content" setIsInsertPanelOpen={setIsInsertPanelOpen} />
        )}
        {previewSiteVersion?.id && <VersionHistoryNotice />}
      </Layout>
      <PageVersionHistory isOpen={Boolean(pageVersionHistoryId)} onClose={() => setPageVersionHistoryId(undefined)} />
    </>
  );
};

export default () => (
  <WebsiteProvider>
    <DreamEditorProvider>
      <AttributesPanelNavbarFooterContextProvider>
        <PageEditorPage />
      </AttributesPanelNavbarFooterContextProvider>
    </DreamEditorProvider>
  </WebsiteProvider>
);
