const DEFAULT_BACKGROUND_SECONDARY_COLOR = '#F9FAFB';
const WHITE_COLOR = '#FFFFFF';
const BLACK_COLOR = '#000000';

const DEFAULT_PRIMARY_COLOR = '#030712';
const DEFAULT_PRIMARY_COLOR_HOVER = '#03071280';
const DEFAULT_TEXT_ON_PRIMARY_COLOR = '#F9FAFB';
const DEFAULT_BORDER_COLOR = '#E5E7EB';
const DEFAULT_BORDER_SECONDARY_COLOR = '#f1f1f1';
const DEFAULT_MODAL_OVERLAY_BACKGROUND_COLOR = '#00000050';

const DEFAULT_BORDER_RADIUS = '8px';

const DEFAULT_HEADING_FONT_SIZE = 'text-3xl';
const DEFAULT_DESCRIPTION_FONT_SIZE = 'text-lg';

const INSERT_PANEL_DROP_EVENT_IDENTIFIER = 'hello-from-the-panel-side-(in-adeles-voice)';

const INSERTED_FROM_INSERT_PANEL_IDENTIFIER = 'insertedFromInsertPanelIdentifier';

const OPEN_INSERT_PANEL_IDENTIFIER = 'openInsertPanelIdentifier';

const CONTAINER_NODES = ['container'];

export {
  BLACK_COLOR,
  CONTAINER_NODES,
  DEFAULT_BACKGROUND_SECONDARY_COLOR,
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BORDER_SECONDARY_COLOR,
  DEFAULT_DESCRIPTION_FONT_SIZE,
  DEFAULT_HEADING_FONT_SIZE,
  DEFAULT_MODAL_OVERLAY_BACKGROUND_COLOR,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_PRIMARY_COLOR_HOVER,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
  INSERT_PANEL_DROP_EVENT_IDENTIFIER,
  INSERTED_FROM_INSERT_PANEL_IDENTIFIER,
  OPEN_INSERT_PANEL_IDENTIFIER,
  WHITE_COLOR,
};
