import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useRequireSetting } from '@/hooks';
import { useSite } from '@/hooks/useSite';
import { Setting } from '@/interfaces/setting';

const ONBOARDING_PATH = '/website_builder_v2/onboarding';

const OnboardingRedirector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isNotOnboardingPath = !location.pathname.startsWith(ONBOARDING_PATH);

  useRequireSetting({
    setting: Setting.HIIV_DREAM,
    message: 'Upgrade to get beta access to the new website builder.',
    redirectTo: '/website_builder_v2_overview',
  });

  const { isLoading } = useSite({
    refetchOnWindowFocus: false,
    onError: () => {
      if (isNotOnboardingPath) {
        navigate(ONBOARDING_PATH);
      }
    },
  });

  if (isLoading && isNotOnboardingPath) {
    return (
      <div className="h-screen w-screen flex items-center justify-center p-8">
        <div className="animate-pulse h-full w-full bg-gray-100" />
      </div>
    );
  }

  return <Outlet />;
};

export default OnboardingRedirector;
