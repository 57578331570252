import { Route, Routes } from 'react-router-dom';

import Layout from './ad_portal/_layout';
import { CurrentAdvertiserProvider } from './ad_portal/context/current-advertiser-context';
import Dashboard from './ad_portal/routes/Dashboard';
import { CurrentUserContext, CurrentUserProvider } from './context/current-user-context';
import ErrorPage from './pages/Error';
import Loading from './pages/Loading';

const AuthenticatedAdPortalApp = () => {
  return (
    <CurrentUserProvider>
      <CurrentUserContext.Consumer>
        {(value) =>
          value?.isLoading ? (
            <Loading />
          ) : (
            value?.currentUser && (
              <CurrentAdvertiserProvider fallbackAdvertiserId={value?.currentUser?.default_advertiser_id || ''}>
                <Routes>
                  <Route element={<Layout />}>
                    <Route path="/" element={<Dashboard />} />
                    {/* Catch-all route */}
                    <Route path="*" element={<ErrorPage statusCode={404} />} />
                  </Route>
                </Routes>
              </CurrentAdvertiserProvider>
            )
          )
        }
      </CurrentUserContext.Consumer>
    </CurrentUserProvider>
  );
};

export default AuthenticatedAdPortalApp;
