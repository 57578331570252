import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';

import api from '../../services/swarm';

interface ApiResponse {
  forms: { id: string; title: string }[];
  pagination: Pagination;
}

interface Props {
  enabled?: boolean;
}

export default function useForms({ enabled = true }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'dream_editor', 'forms'],
    ({ pageParam = 1 }) =>
      api
        .get(`/dream_editor/forms`, {
          params: {
            publication_id: currentPublicationId,
            page: pageParam,
          },
        })
        .then((res) => res.data),
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      enabled: !!currentPublicationId && enabled,
    }
  );
}
