import { Outlet, useLocation } from 'react-router-dom';

import { WebsiteProvider } from '@/context/website-context';

import { ContentTree } from '../../_components/ContentTree';
import { ContentTreeProvider } from '../../_components/ContentTree/context';
import { Layout } from '../../_components/Layout';
import { Main } from '../../_components/Main';
import { TopBar } from '../../_components/Main/TopBar';
import { NavMenuItem } from '../../_components/SideNav/NavMenuItem';
import { NavSection } from '../../_components/SideNav/NavSection';
import { NavSectionTitle } from '../../_components/SideNav/NavSectionTitle';
import useSetPages from '../../_hooks/useSetPages';

const SETTINGS_PAGE_DETAILS: any = Object.freeze({
  settings: {
    title: 'General',
    description: 'Configure general details about your website',
    callout: {
      title: 'Some settings on this page are read-only',
      description: 'They are global settings pulled in from your publication.',
    },
  },
  seo: {
    title: 'Search Engine Optimization (SEO)',
    description: 'Set how your site looks like on search engines and when shared',
  },
  comments: {
    title: 'Comments',
    description:
      'Allow users to engage in a comments section for a post. You can disable this feature on a post-by-post basis',
  },
  testimonials: {
    title: 'Testimonials',
    description: 'Add testimonials to your website',
  },
  signup_flows: {
    title: 'Signup Flows',
    description: 'Customize the signup flow for your website',
  },
  themes: {
    title: 'Themes',
    description: 'Manage your website themes',
  },
  pixels: {
    title: 'Pixels',
    description: 'Manage your tracking pixels used for ads and analytics',
  },
  socials: {
    title: 'Socials',
    description: 'Manage your social media links',
  },
  gdpr: {
    title: 'GDPR, CCPA, & Compliance',
    description: 'Manage your website to meet the requirements for GDPR & CCPA compliance',
  },
  page: {
    title: 'Page Settings',
    description: 'Configure your page settings and metadata',
  },
  redirects: {
    title: 'Redirects',
    description: 'Manage your redirects for your website',
  },
});

const SettingsLayout = () => {
  const { pathname } = useLocation();
  const { pageRoutes, defaultRoutes } = useSetPages();
  const paths = pathname.split('/');
  const pathCheck = paths?.splice(3)?.shift() || 'settings';

  const pageDetails = SETTINGS_PAGE_DETAILS[pathCheck];

  return (
    <Layout
      showBackButton
      showProjects={false}
      sidenavChildren={
        <>
          <NavSection>
            <NavSectionTitle title="Site Settings" />
            <NavMenuItem title="General" to="/website_builder_v2/settings" />
            <NavMenuItem title="Search Engine Optimization" to="/website_builder_v2/settings/seo" />
            <NavMenuItem title="Pixels" to="/website_builder_v2/settings/pixels" />
            <NavMenuItem title="GDPR, CCPA, & Compliance" to="/website_builder_v2/settings/gdpr" />
            <NavMenuItem title="Redirects" to="/website_builder_v2/settings/redirects" />
          </NavSection>

          <NavSection>
            <NavSectionTitle title="Features" />
            <NavMenuItem title="Socials" to="/website_builder_v2/settings/socials" />
            <NavMenuItem title="Themes" to="/website_builder_v2/settings/themes" />
            <NavMenuItem title="Comments" to="/website_builder_v2/settings/comments" />
            <NavMenuItem title="Signup Flows" to="/website_builder_v2/settings/signup_flows" />
            <NavMenuItem title="Testimonials" to="/website_builder_v2/settings/testimonials" />
          </NavSection>

          <NavSection>
            <NavSectionTitle title="Default Pages" />
            {defaultRoutes && (
              <ContentTreeProvider
                isAllowDnD={false}
                isShowOptions={false}
                isShowAddPage={false}
                pageLinkPrefix="/website_builder_v2/settings/page"
              >
                <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
          </NavSection>

          <NavSection>
            <NavSectionTitle title="Page Settings" />
            {pageRoutes && (
              <ContentTreeProvider
                isAllowDnD={false}
                isShowOptions={false}
                isShowAddPage={false}
                pageLinkPrefix="/website_builder_v2/settings/page"
              >
                <ContentTree route={pageRoutes} parentPath={[]} slug="" showHome={false} />
              </ContentTreeProvider>
            )}
          </NavSection>
        </>
      }
    >
      <Main className="w-full max-w-[1200px] mx-auto !gap-4 pb-8">
        <TopBar
          title={pageDetails.title}
          description={pageDetails.description}
          callout={pageDetails?.callout}
          hasDivider
        />
        <Outlet />
      </Main>
    </Layout>
  );
};

const WebsiteSettingsLayout = () => {
  return (
    <WebsiteProvider>
      <SettingsLayout />
    </WebsiteProvider>
  );
};

export default WebsiteSettingsLayout;
