import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { CheckIcon, DocumentPlusIcon, WrenchScrewdriverIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { EmptyBlock } from '@/components/EmptyBlock';
import { Input } from '@/components/Form';
import UnstyledSortableListItem from '@/components/SortableList/UnstyledSortableListItem';
import Text from '@/components/Text';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { useUpdateWebTemplate } from '@/hooks';
import { useAuthors, useRemoveAuthorFromPosts } from '@/hooks/useAuthors';
import { usePublications } from '@/hooks/usePublications';
import { PublicationSubscriberPreference } from '@/interfaces/subscribers_preferences';
import MultiPubSection from '@/pages/Settings/Pages/Website/Advanced/MultiPubSection';
import SettingsSection from '@/ui/Settings/SettingsSection';
import Tooltip from '@/ui/Tooltip';

import { SettingsContainer, SettingsGroup } from '../../../../ui/Settings/SettingsContainer';
import SwitchDescriptionInput from '../../../../ui/Settings/SwitchDescriptionInput';
import ConfigureSubscriberPreferences from '../../_components/ConfigureSubscriberPreferences';
import { usePageContext } from '../../_components/Context/PageContext';

import {
  MULTI_PUBLICATIONS_SECTION_DESCRIPTION,
  MULTI_PUBLICATIONS_SECTION_TITLE,
  ORGANIZATION_PUBLICATIONS_SECTION_DESCRIPTION,
  ORGANIZATION_PUBLICATIONS_SECTION_TITLE,
} from './constants';

const DELETE_CONFIRMATION = 'DELETE';

const WebBuilderStaticPages = () => {
  const authorsQuery = useAuthors();
  const { data: authors } = authorsQuery;

  const { webTemplate } = usePageContext();

  const webTemplateMutation = useUpdateWebTemplate(() => {});

  const authorIds = webTemplate?.author_ids || [];
  const authorsEnabled = authorIds?.length > 0;
  const [authorToRemove, setAuthorToRemove] = useState<string>('');
  const [confirmDelete, setConfirmDelete] = useState<string>('');

  const { data: publications, isLoading: isLoadingPublications } = usePublications();
  const hasMultiplePublications = publications?.length > 1;

  const { settings } = useSettings();
  const hasSubscriberPreferences = settings?.subscribers_preferences === true;

  const removeAuthoredPosts = useRemoveAuthorFromPosts({
    authorId: authorToRemove,
    onSuccess: () => {
      setAuthorToRemove('');
      setConfirmDelete('');
      webTemplateMutation.mutate({ author_ids: authorIds }); // This will update the list of author ids for the site.
    },
  });

  const handleToggleAuthorsPage = async () => {
    if (authorsEnabled) {
      await webTemplateMutation.mutateAsync({ author_ids: [] });
    } else {
      await webTemplateMutation.mutateAsync({ author_ids: authors?.map((author: any) => author.id) });
    }
  };

  return (
    <SettingsContainer>
      <ActionModal
        isOpen={Boolean(authorToRemove)}
        onClose={() => {
          setAuthorToRemove('');
          setConfirmDelete('');
        }}
        resourceId={authorToRemove}
        headerText="Remove author from posts"
        onProceed={() => {
          if (confirmDelete !== DELETE_CONFIRMATION) {
            toast.error('Please type DELETE to confirm');
          } else {
            removeAuthoredPosts.mutate();
          }
        }}
        actionText="Delete Author"
        isWorking={removeAuthoredPosts.isLoading}
        buttonType="danger"
      >
        <div className="text-sm space-y-2">
          <p>Taking this action will remove this user as an author from all posts they are associated with.</p>
          <p>This action CANNOT be undone.</p>
          <Input
            name="confirm-delete"
            placeholderText="Type DELETE"
            onChange={(e) => setConfirmDelete(e.target.value)}
            value={confirmDelete}
            helperText="Type DELETE and click 'Delete Author' to confirm."
          />
        </div>
      </ActionModal>
      <SettingsGroup
        title="Website Static Pages"
        description="Manage additional pages available to you to show on your publication site."
      >
        <>
          <hr className="my-6 w-full" />
          {authors && authors?.length > 0 ? (
            <SwitchDescriptionInput
              name="enable_disable_authors_page"
              title="Authors Page"
              description="Customize the authors that appear on your publications Author Page."
              Icon={DocumentPlusIcon}
              value={authorsEnabled}
              onSave={handleToggleAuthorsPage}
            />
          ) : (
            <Tooltip
              tooltipClass="text-center w-60"
              id="authors-disabled"
              text="Make sure at least one of your posts has authors to add an authors page."
              showIcon={false}
            >
              <div className="opacity-50 pointer-events-none">
                <SwitchDescriptionInput
                  name="enable_disable_authors_page"
                  title="Authors Page"
                  description="Customize the authors that appear on your publications Author Page."
                  Icon={DocumentPlusIcon}
                  value={authorsEnabled}
                  onSave={handleToggleAuthorsPage}
                />
              </div>
            </Tooltip>
          )}
          {authorsEnabled && (
            <div className="p-6 flex flex-col border rounded-lg border-surface-200">
              {authors?.map((author: any, index: number) => {
                const isLast = index === authors.length - 1;
                const isLive = authorIds?.includes(author.id);
                const options = [];

                if (!author.is_team_member) {
                  options.push({
                    label: 'Delete author',
                    onClick: () => setAuthorToRemove(author.id),
                  });
                }

                if (isLive) {
                  options.push({
                    label: 'Remove from authors page',
                    onClick: () => {
                      const newAuthorIds = authorIds.filter((id: string) => id !== author.id);
                      webTemplateMutation.mutate({ author_ids: newAuthorIds });
                    },
                  });
                } else {
                  options.push({
                    label: 'Add to authors page',
                    onClick: () => {
                      const newAuthorIds = [...authorIds, author.id];
                      webTemplateMutation.mutate({ author_ids: newAuthorIds });
                    },
                  });
                }

                return (
                  <div
                    key={author.id}
                    className={cx('flex justify-between items-center', !isLast && 'border-b border-gray-100 pb-2')}
                  >
                    <div className="space-y-1 py-2">
                      <div className="flex space-x-1 items-center ">
                        <Typography token="font-normal/text/sm" className="text-gray-700">
                          {author.name}
                        </Typography>
                        {isLive && <CheckIcon className="w-4 h-4 text-green-500" />}
                      </div>
                      {!author.is_team_member && (
                        <div className="w-full max-w-sm">
                          <div className="bg-red-100  px-2 py-1 rounded">
                            <Text as="p" size="xs" className="text-red-500">
                              It looks like this user is no longer on your team but is still tagged as an author for
                              posts on your site. Select &lsquo;Delete author&rsquo; in the dropdown to remove them from
                              all posts on your site.
                            </Text>
                          </div>
                        </div>
                      )}
                    </div>
                    {options.length > 0 ? <EllipsisDropdown options={options} /> : null}
                  </div>
                );
              })}
            </div>
          )}
          {hasMultiplePublications && (
            <div className="pt-6 flex w-full space-y-4">
              <SettingsSection
                title={MULTI_PUBLICATIONS_SECTION_TITLE}
                description={MULTI_PUBLICATIONS_SECTION_DESCRIPTION}
                Icon={DocumentPlusIcon}
              >
                <MultiPubSection
                  sectionTitle={MULTI_PUBLICATIONS_SECTION_TITLE}
                  sectionDescription={MULTI_PUBLICATIONS_SECTION_DESCRIPTION}
                  webTemplate={webTemplate}
                  publications={publications}
                  isLoading={isLoadingPublications}
                  isV2
                  attributeName="multi_pub_ids"
                />
              </SettingsSection>
            </div>
          )}
          {hasSubscriberPreferences && (
            <div className="pt-6 flex space-y-4">
              <SettingsSection
                title="Subscriber Preferences"
                description={
                  hasMultiplePublications
                    ? 'Configure the preferences and publications you want to show on this page'
                    : 'Configure the order in which preferences are shown to your subscribers'
                }
                Icon={WrenchScrewdriverIcon}
                className="w-full"
              >
                <ConfigureSubscriberPreferences
                  emptyBlock={
                    <EmptyBlock className="gap-x-1">
                      No Preferences added yet, click{' '}
                      <Link className="text-primary-700" to="/subscribers/preferences/new">
                        here
                      </Link>{' '}
                      to add one
                    </EmptyBlock>
                  }
                >
                  {(list) => {
                    return list.map((item: PublicationSubscriberPreference) => {
                      return (
                        <UnstyledSortableListItem
                          className="bg-white rounded hover:bg-gray-100 px-4 py-4 border rounded-md"
                          key={item.id}
                          listItem={item}
                          text={item.name}
                        />
                      );
                    });
                  }}
                </ConfigureSubscriberPreferences>
                {hasMultiplePublications && (
                  <div className="flex flex-col gap-y-2">
                    <Typography token="font-semibold/text/sm" className="text-gray-700">
                      Publications
                    </Typography>
                    <MultiPubSection
                      sectionTitle={ORGANIZATION_PUBLICATIONS_SECTION_TITLE}
                      sectionDescription={ORGANIZATION_PUBLICATIONS_SECTION_DESCRIPTION}
                      webTemplate={webTemplate}
                      publications={publications}
                      isLoading={isLoadingPublications}
                      isV2
                      attributeName="preferences_pub_ids"
                      showLabelText={false}
                    />
                  </div>
                )}
              </SettingsSection>
            </div>
          )}
        </>
      </SettingsGroup>
    </SettingsContainer>
  );
};

export default WebBuilderStaticPages;
