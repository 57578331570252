import { useCallback, useMemo, useState } from 'react';
import { Empty, X } from '@phosphor-icons/react';
import { NavbarSerializableNode } from '@shared/dream-components';
import classNames from 'classnames';

import { AttributeDropdown } from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/AttributeDropdown';
import BoxModelToggle from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/BoxModelToggle';
import { getTRBLValue } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/getTRBLValue';
import { parseCssValue } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/parseCssValue';
import {
  BottomIcon,
  LeftIcon,
  LeftRightIcon,
  RightIcon,
  TopBottomIcon,
  TopIcon,
} from '@/routes/website/_components/Icons/BorderIcons';
import { Button } from '@/routes/website/_components/UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';

import { ColorSettings } from './ColorSettings';

type TRBL = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type BorderSettingsProps = {
  selectedContent: NavbarSerializableNode;
  properties?: {
    color: string;
    style: string;
    width: string;
  };
  defaults?: {
    borderWidth?: string;
    borderStyle?: string;
    borderColor?: string;
  };
};

const DEFAULT_MAPPING = {
  color: 'borderColor',
  style: 'borderStyle',
  width: 'borderWidth',
};

const getStringValue = (value: TRBL, unit: string) => {
  return `${value.top}${unit} ${value.right}${unit} ${value.bottom}${unit} ${value.left}${unit}`;
};

const BorderSettings = ({ selectedContent, properties = DEFAULT_MAPPING, defaults }: BorderSettingsProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { onUpdateNodeAttributes } = useNavbarContext();

  const DEFAULT_COLOR = defaults?.borderColor ?? '#000000FF';
  const DEFAULT_BORDER_WIDTH = defaults?.borderWidth ?? '1px';
  const DEFAULT_BORDER_STYLE = defaults?.borderStyle ?? 'solid';
  const contentBorderWidthValue =
    selectedContent?.attrs && properties.width in selectedContent.attrs
      ? (selectedContent.attrs[properties.width as keyof typeof selectedContent.attrs] as string)
      : DEFAULT_BORDER_WIDTH;

  const contentBorderStyleValue =
    selectedContent?.attrs && properties.style in selectedContent.attrs
      ? (selectedContent.attrs[properties.style as keyof typeof selectedContent.attrs] as string)
      : DEFAULT_BORDER_STYLE;

  const contentBorderColorValue =
    selectedContent?.attrs && properties.color in selectedContent.attrs
      ? (selectedContent.attrs[properties.color as keyof typeof selectedContent.attrs] as string)
      : DEFAULT_COLOR;

  const { width } = useMemo(() => {
    const borderWidth = getTRBLValue(contentBorderWidthValue || DEFAULT_BORDER_WIDTH);

    return {
      width: {
        top: parseCssValue(borderWidth.top).value,
        right: parseCssValue(borderWidth.right).value,
        bottom: parseCssValue(borderWidth.bottom).value,
        left: parseCssValue(borderWidth.left).value,
      },
    };
  }, [contentBorderWidthValue]);

  const isNone = contentBorderStyleValue === 'none';

  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!selectedContent || !selectedContent.attrs?.id) return null;

      onUpdateNodeAttributes(selectedContent.attrs?.id, {
        [properties.style]: 'none',
        [properties.color]: DEFAULT_COLOR,
        [properties.width]: DEFAULT_BORDER_WIDTH,
      });
      setIsPopoverOpen(false);
      return true;
    },
    [selectedContent, onUpdateNodeAttributes, properties, setIsPopoverOpen]
  );

  const handleTRBLChange = useCallback(
    (propertyName: string, defaultValues: string) =>
      (position: 'top' | 'right' | 'bottom' | 'left' | 'top-bottom' | 'left-right' | 'all', value: number) => {
        if (!selectedContent || !selectedContent.attrs?.id) return;
        const rawValue =
          selectedContent.attrs &&
          propertyName in selectedContent.attrs &&
          (selectedContent.attrs[propertyName as keyof typeof selectedContent.attrs] as string);
        const currentTRBL = getTRBLValue(rawValue || defaultValues);
        let updatedTRBL = {
          top: parseCssValue(currentTRBL.top).value,
          right: parseCssValue(currentTRBL.right).value,
          bottom: parseCssValue(currentTRBL.bottom).value,
          left: parseCssValue(currentTRBL.left).value,
        };

        if (position === 'all') {
          updatedTRBL = { top: value, right: value, bottom: value, left: value };
        } else if (position === 'top-bottom') {
          updatedTRBL = { top: value, bottom: value, left: updatedTRBL.left, right: updatedTRBL.right };
        } else if (position === 'left-right') {
          updatedTRBL = { left: value, right: value, top: updatedTRBL.top, bottom: updatedTRBL.bottom };
        } else {
          updatedTRBL[position] = value;
        }
        onUpdateNodeAttributes(selectedContent.attrs?.id, {
          [propertyName]: getStringValue(updatedTRBL, 'px'),
        });
      },
    [selectedContent, onUpdateNodeAttributes]
  );

  const handleUpdateBorderStyle = useCallback(
    (style: string) => {
      if (!selectedContent || !selectedContent.attrs?.id) return;
      onUpdateNodeAttributes(selectedContent.attrs?.id, {
        [properties.style]: style,
      });
    },
    [selectedContent, onUpdateNodeAttributes, properties.style]
  );

  const dropdownOptions = useMemo(
    () => [
      {
        label: 'None',
        onSelect: () => handleUpdateBorderStyle('none'),
      },
      {
        label: 'Solid',
        onSelect: () => handleUpdateBorderStyle('solid'),
      },
      {
        label: 'Dashed',
        onSelect: () => handleUpdateBorderStyle('dashed'),
      },
      {
        label: 'Dotted',
        onSelect: () => handleUpdateBorderStyle('dotted'),
      },
    ],
    [handleUpdateBorderStyle]
  );
  if (!selectedContent || !selectedContent.attrs?.id) return null;

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(open) => {
        setIsPopoverOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2 select-none">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Border
          </Text>

          <div className="grow bg-wb-secondary rounded-lg shadow-sm">
            <div className="w-full justify-between flex items-center gap-2 p-2 cursor-pointer">
              <div className="flex items-center gap-1">
                {isNone ? (
                  <Empty className="text-wb-secondary" weight="bold" />
                ) : (
                  <div className="w-4 h-4 rounded-md" style={{ backgroundColor: contentBorderColorValue }} />
                )}

                <Text size="2xs" weight="medium" className="capitalize">
                  {contentBorderStyleValue}
                </Text>
              </div>
              {!isNone && (
                <Button
                  variant="ghost"
                  Icon={X}
                  iconClassName="text-wb-secondary w-3 h-3"
                  onClick={handleReset}
                  className="p-0"
                />
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[264px]" align="start" side="left" sideOffset={20}>
        <div className="max-h-[500px] overflow-y-auto flex flex-col gap-2">
          <Text size="sm" weight="semibold">
            Border
          </Text>

          <AttributeDropdown title="Style" defaultValue={contentBorderStyleValue} options={dropdownOptions} />

          <div className={classNames(isNone && 'opacity-50 pointer-events-none', 'flex flex-col gap-2')}>
            <ColorSettings
              title="Color"
              property={properties.color}
              defaultValue={DEFAULT_COLOR}
              selectedContent={selectedContent}
            />

            <BoxModelToggle
              title="Width"
              defaultValues={width}
              onUpdate={handleTRBLChange(properties.width, DEFAULT_BORDER_WIDTH)}
              suffixes={{
                top: <TopIcon />,
                right: <RightIcon />,
                bottom: <BottomIcon />,
                left: <LeftIcon />,
                topBottom: <TopBottomIcon />,
                leftRight: <LeftRightIcon />,
              }}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default BorderSettings;
