import { useCallback } from 'react';

import { AddableSettings } from '../../helpers/AddableSettings';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { darkenHexColor } from '../../utils/darkenHexColor';
import BorderRadiusSettings from '../BorderRadiusSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';

const ButtonHoverEffectSettings = ({
  children,
  editor,
  activeNodeResult,
  title = 'Hover Effect',
  propertyPrefix = 'hover',
  backgroundColorProperty = 'customBackgroundColor',
}: AttributeSettingProps & {
  backgroundColorProperty: string;
}) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;
  const hasHoverEffectBorder = activeNodeAttributes?.[`${propertyPrefix}BorderColor`];
  const hasHoverEffectRadius = activeNodeAttributes?.[`${propertyPrefix}BorderRadius`];
  const hasHoverEffectText = activeNodeAttributes?.[`${propertyPrefix}CustomTextColor`];
  const hasHoverEffectBackground = activeNodeAttributes?.[`${propertyPrefix}CustomBackgroundColor`];
  const hasHoverEffectIcon = activeNodeAttributes?.[`${propertyPrefix}IconColor`];

  const currentBackgroundColor = activeNodeAttributes?.[backgroundColorProperty];
  const darkenedBackgroundColor = darkenHexColor(currentBackgroundColor, 20);

  const onAddHoverEffect = useCallback(
    (property: string, value: string) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [activeNodePos, editor.commands]
  );

  const onRemoveHoverEffect = useCallback(
    (property: string) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, undefined);
        return true;
      });
    },
    [activeNodePos, editor.commands]
  );

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['button']}>
      <SectionRenderer title={title} collapsible>
        <AddableSettings
          title="Border"
          isEmpty={!hasHoverEffectBorder}
          onAdd={() => {
            onAddHoverEffect(`${propertyPrefix}BorderColor`, darkenedBackgroundColor);
            onAddHoverEffect(`${propertyPrefix}BorderStyle`, 'solid');
            onAddHoverEffect(`${propertyPrefix}BorderWidth`, '1px');
          }}
          onRemove={() => {
            onRemoveHoverEffect(`${propertyPrefix}BorderColor`);
            onRemoveHoverEffect(`${propertyPrefix}BorderStyle`);
            onRemoveHoverEffect(`${propertyPrefix}BorderWidth`);
          }}
        >
          <BorderSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            properties={
              propertyPrefix
                ? {
                    color: `${propertyPrefix}BorderColor`,
                    style: `${propertyPrefix}BorderStyle`,
                    width: `${propertyPrefix}BorderWidth`,
                    radius: `${propertyPrefix}BorderRadius`,
                  }
                : undefined
            }
            showRadiusSettings={false}
          />
        </AddableSettings>

        <AddableSettings
          title="Radius"
          isEmpty={!hasHoverEffectRadius}
          onAdd={() => onAddHoverEffect(`${propertyPrefix}BorderRadius`, '8px')}
          onRemove={() => onRemoveHoverEffect(`${propertyPrefix}BorderRadius`)}
        >
          <BorderRadiusSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Radius"
            property={propertyPrefix ? `${propertyPrefix}BorderRadius` : 'borderRadius'}
          />
        </AddableSettings>

        <AddableSettings
          title="Text"
          isEmpty={!hasHoverEffectText}
          onAdd={() => onAddHoverEffect(`${propertyPrefix}CustomTextColor`, '#000000')}
          onRemove={() => onRemoveHoverEffect(`${propertyPrefix}CustomTextColor`)}
        >
          <ColorSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Text"
            property={propertyPrefix ? `${propertyPrefix}CustomTextColor` : 'customTextColor'}
          />
        </AddableSettings>

        <AddableSettings
          title="Background"
          isEmpty={!hasHoverEffectBackground}
          onAdd={() => onAddHoverEffect(`${propertyPrefix}CustomBackgroundColor`, darkenedBackgroundColor)}
          onRemove={() => onRemoveHoverEffect(`${propertyPrefix}CustomBackgroundColor`)}
        >
          <ColorSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Background"
            property={propertyPrefix ? `${propertyPrefix}CustomBackgroundColor` : 'customBackgroundColor'}
          />
        </AddableSettings>

        <AddableSettings
          title="Icon Fill"
          isEmpty={!hasHoverEffectIcon}
          onAdd={() => onAddHoverEffect(`${propertyPrefix}IconColor`, '#000000')}
          onRemove={() => onRemoveHoverEffect(`${propertyPrefix}IconColor`)}
          disabled={!activeNodeResult?.activeNodeAttributes?.icon}
        >
          <ColorSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Icon Fill"
            disabled={!activeNodeResult?.activeNodeAttributes?.icon}
            property={propertyPrefix ? `${propertyPrefix}IconColor` : 'iconColor'}
          />
        </AddableSettings>

        {children}
      </SectionRenderer>
    </RenderCondition>
  );
};

export { ButtonHoverEffectSettings };
