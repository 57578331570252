import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import Modal from '../Modal';
import { Typography } from '../Typography';

interface Faq {
  question: string;
  answer: string | JSX.Element;
  defaultOpen?: boolean;
}

interface Props {
  title?: string;
  faqs: Faq[];
  isOpen: boolean;
  onClose: () => void;
}

const FaqModal = ({ title = 'Frequently Asked Questions', faqs, isOpen, onClose }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="inline-block align-bottom bg-white rounded text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full md:my-6 md:max-w-xl lg:my-4 lg:max-w-2xl"
    >
      <div className="pt-6 px-4 space-y-3 sm:p-6 sm:pb-0">
        <Typography token="font-medium/text/lg" colorWeight="900" className="w-fit">
          {title}
        </Typography>

        <dl className="divide-y divide-gray-100 overflow-scroll my-12" style={{ maxHeight: 600 }}>
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="py-6" defaultOpen={faq.defaultOpen}>
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                      <Typography token="font-medium/text/sm" colorWeight="900">
                        {faq.question}
                      </Typography>
                      <span className="ml-6 -mt-1 flex h-7 items-center">
                        {open ? (
                          <ChevronUpIcon className="h-6 w-6 text-surface-500" aria-hidden="true" />
                        ) : (
                          <ChevronDownIcon className="h-6 w-6 text-surface-500" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <Typography token="font-normal/text/sm" colorWeight="500">
                      {faq.answer}
                    </Typography>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </Modal>
  );
};

export default FaqModal;
