import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { SubscriptionMilestone } from '@/interfaces/milestone';
import useFulfillment from '@/pages/ReferralProgram/Fulfillment/hooks/useFulfillment';
import StatusBadge from '@/pages/ReferralProgram/Fulfillment/StatusBadge';

import AssignPromoCode from './AssignPromoCode';
import ManageReferrals from './ManageReferrals';

interface Props {
  subscriptionMilestone: SubscriptionMilestone;
  onActionTaken: () => void;
}

const FulfillmentRow = ({ subscriptionMilestone, onActionTaken }: Props) => {
  const {
    canMarkAsFulfilled,
    canMarkAsIgnored,
    canMarkAsPending,
    onMarkAsFulfilled,
    onMarkAsIgnored,
    onMarkAsPending,
    canPerformActions,
    shouldAssignPromoCode,
  } = useFulfillment(subscriptionMilestone, onActionTaken);
  const [showAssignCodeModal, setShowAssignCodeModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const subscriptionMilestoneId = searchParams.get('subscription_milestone_id') || undefined;

  const handleCloseManageReferrals = () => {
    searchParams.delete('subscription_milestone_id');
    setSearchParams(searchParams);
  };

  const promoCode = subscriptionMilestone.reward_use_static_promo_code
    ? subscriptionMilestone.static_promo_code
    : subscriptionMilestone.promo_code;

  return (
    <>
      <ActionModal
        headerText="Manage Referrals"
        resourceId={subscriptionMilestone.id}
        isOpen={subscriptionMilestoneId === subscriptionMilestone.id}
        onClose={handleCloseManageReferrals}
        isWorking={false}
      >
        <div className="mt-4">
          <ManageReferrals subscriptionMilestone={subscriptionMilestone} />
        </div>
      </ActionModal>
      <AssignPromoCode
        isOpen={showAssignCodeModal}
        onClose={() => setShowAssignCodeModal(false)}
        subscriptionMilestoneId={subscriptionMilestone.id}
        rewardId={subscriptionMilestone.reward_id}
      />
      <ItemRow className="bg-white">
        <ItemColumn align={AlignType.MIDDLE}>
          <Link type="button" to={`/subscribers/${subscriptionMilestone.subscriber_id}`}>
            <Typography token="font-normal/text/sm" color="secondary" colorWeight="600">
              {subscriptionMilestone.subscriber_email}
            </Typography>
          </Link>
        </ItemColumn>
        <ItemColumn align={AlignType.MIDDLE}>
          <StatusBadge status={subscriptionMilestone.status} />
        </ItemColumn>
        <ItemColumn align={AlignType.MIDDLE}>
          <Typography token="font-normal/text/sm">{subscriptionMilestone.reward_name}</Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.MIDDLE}>
          <Typography token="font-normal/text/sm">{subscriptionMilestone.num_referrals}</Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.MIDDLE}>
          <Typography token="font-normal/text/sm">{promoCode || '-'}</Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.MIDDLE}>
          <Typography token="font-normal/text/sm">
            {subscriptionMilestone.fulfilled_at ? moment(subscriptionMilestone.fulfilled_at).format('lll') : '-'}
          </Typography>
        </ItemColumn>
        <ItemColumn>
          <EllipsisDropdown
            options={[
              {
                label: 'Manage Referrals',
                onClick: () => {
                  searchParams.set('subscription_milestone_id', subscriptionMilestone.id);
                  setSearchParams(searchParams);
                },
              },
              {
                label: 'Mark as Fulfilled',
                onClick: onMarkAsFulfilled,
                isVisible: canPerformActions && canMarkAsFulfilled,
              },
              {
                label: 'Mark as Ignored',
                onClick: onMarkAsIgnored,
                isVisible: canPerformActions && canMarkAsIgnored,
              },
              {
                label: 'Mark as Pending',
                onClick: onMarkAsPending,
                isVisible: canPerformActions && canMarkAsPending,
              },
              {
                label: 'Assign Promo Code',
                onClick: () => setShowAssignCodeModal(true),
                isVisible: shouldAssignPromoCode,
              },
            ]}
          />
        </ItemColumn>
      </ItemRow>
    </>
  );
};

export default FulfillmentRow;
