import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';

const PastDueNotice = () => {
  return (
    <div className="p-4 rounded bg-red-50 border border-red-200">
      <div className="flex space-x-3">
        <div>
          <InformationCircleIcon className="w-4 h-4 text-red-500" />
        </div>
        <div className="space-y-2">
          <Typography as="div" token="font-medium/text/sm" color="danger">
            Your subscription payment has failed
          </Typography>
          <Typography as="div" token="font-normal/text/sm" colorWeight="700" color="danger">
            Please check your billing info below to ensure there is a valid card on file. Payment will retry
            automatically soon or when a new payment method is added to your account.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PastDueNotice;
