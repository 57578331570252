import { useCallback } from 'react';
import { StackSimple } from '@phosphor-icons/react';

import { PopoverHelper, PopoverItem } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';

export type ImageFitSettingsProps = AttributeSettingProps;

const objectFitOptions = [
  { label: 'Cover', value: 'cover' },
  { label: 'Contain', value: 'contain' },
];

export const ImageFitSettings = ({ editor, activeNodeResult }: ImageFitSettingsProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;

  const textValue =
    objectFitOptions.find((option) => option.value === activeNodeAttributes?.objectFit)?.label || 'Cover';

  const isSelected = objectFitOptions.find((option) => option.label === textValue)?.value;

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'objectFit', value);
        return true;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos]
  );

  return (
    <PopoverHelper title="Fit" text={textValue} popoverTitle="Object Fit" Icon={StackSimple}>
      <div className="flex flex-col gap-2">
        {objectFitOptions.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={isSelected === option.value}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};
