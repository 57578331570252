import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import {
  getBooleanAttribute,
  getIntegerAttribute,
  getJSONAttribute,
  getStringAttribute,
} from '../../utils/attributesUtils';
import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_PRIMARY_COLOR_HOVER,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
  WHITE_COLOR,
} from '../constants';

import { PostView } from './views/PostView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    post: {
      setPost: () => ReturnType;
      setFontFamily: (fontFamily: string, property: string, pos?: number) => ReturnType;
    };
  }
}

type CardSchema = {
  node: string;
  className: string;
  children: CardSchema[];
};

const cardStructure: CardSchema = {
  node: 'div',
  className: 'flex flex-col gap-3 w-full w-full',
  children: [
    {
      node: 'image',
      className: '',
      children: [],
    },
    {
      node: 'div',
      className: 'flex flex-col gap-2',
      children: [
        {
          node: 'tags',
          className: '',
          children: [],
        },
        {
          node: 'div',
          className: 'flex flex-col gap-1',
          children: [
            {
              node: 'title',
              className: '',
              children: [],
            },
            {
              node: 'subtitle',
              className: '',
              children: [],
            },
            {
              node: 'premium',
              className: '',
              children: [],
            },
            {
              node: 'authors',
              className: 'text-xs',
              children: [],
            },
            {
              node: 'timestamp',
              className: '',
              children: [],
            },
          ],
        },
      ],
    },
  ],
};

export const Post = Node.create<{}, {}>({
  name: 'post',

  group: 'block',

  draggable: true,

  atom: true,

  defining: true,

  selectable: true,

  addAttributes() {
    return {
      insertedFromSidebar: getStringAttribute('insertedFromSidebar', 'data-inserted-from-sidebar', 'true'),

      hasFakeData: getStringAttribute('hasFakeData', 'data-has-fake-data', 'true'),

      postGroupType: getStringAttribute('postGroupType', 'data-post-group-type', 'free_selection'),

      postsCount: getIntegerAttribute('postsCount', 'data-posts-count', 5),

      postsCategory: getStringAttribute('postsCategory', 'data-posts-category', ''),

      gap: getStringAttribute('gap', 'data-gap', '16px'),

      columns: getStringAttribute('columns', 'data-columns', '3'),

      shouldScrollHorizontally: getBooleanAttribute('shouldScrollHorizontally', 'data-scroll-on-mobile', false),

      sliderControlsDisplayType: getStringAttribute(
        'sliderControlsDisplayType',
        'data-slider-controls-display-type',
        'overlay'
      ),

      sliderControlsTextColor: getStringAttribute(
        'sliderControlsTextColor',
        'data-slider-controls-text-color',
        DEFAULT_BORDER_COLOR
      ),

      sliderControlsBorderColor: getStringAttribute(
        'sliderControlsBorderColor',
        'data-slider-controls-border-color',
        DEFAULT_BORDER_COLOR
      ),

      sliderControlsBackgroundColor: getStringAttribute(
        'sliderControlsBackgroundColor',
        'data-slider-controls-background-color',
        WHITE_COLOR
      ),

      background: getStringAttribute('background', 'data-background', '#FFFFFF'),

      color: getStringAttribute('color', 'data-color', '#000000'),

      dividerColor: getStringAttribute('dividerColor', 'data-divider-color', '#000000FF'),

      dividerStyle: getStringAttribute('dividerStyle', 'data-divider-style', 'none'),

      dividerThickness: getStringAttribute('dividerThickness', 'data-divider-thickness', '1px', '1px'),

      shadow: getStringAttribute('shadow', 'data-shadow', '0px 0px 0px 0px rgba(0, 0, 0, 0)'),

      borderRadius: getStringAttribute(
        'borderRadius',
        'data-border-radius',
        DEFAULT_BORDER_RADIUS,
        DEFAULT_BORDER_RADIUS
      ),

      cardPadding: getStringAttribute('cardPadding', 'data-card-padding', '0px', '0px'),

      borderWidth: getStringAttribute('borderWidth', 'data-border-width', '1px', '1px'),

      borderColor: getStringAttribute('borderColor', 'data-border-color', DEFAULT_BORDER_COLOR, DEFAULT_BORDER_COLOR),

      borderStyle: getStringAttribute('borderStyle', 'data-border-style', 'none', 'none'),

      // Image
      imageEnabled: getBooleanAttribute('imageEnabled', 'data-image-enabled', true),

      imageSizeFixed: getBooleanAttribute('imageSizeFixed', 'data-image-size-fixed', false),

      imageWidth: getStringAttribute('imageWidth', 'data-image-width', '100%'),

      imageHeight: getStringAttribute('imageHeight', 'data-image-height', 'auto'),

      imageBorderRadius: getStringAttribute('imageBorderRadius', 'data-image-border-radius', '0px'),

      // Tags
      tagsEnabled: getBooleanAttribute('tagsEnabled', 'data-tags-enabled', true),

      tagTextColor: getStringAttribute('tagTextColor', 'data-tag-text-color', '#111827'),

      tagTransform: getStringAttribute('tagTransform', 'data-tag-transform', 'capitalize'),

      tagBadgeEnabled: getBooleanAttribute('tagBadgeEnabled', 'data-tag-badge-enabled', false),

      tagBackgroundColor: getStringAttribute('tagBackgroundColor', 'data-tag-background-color', '#f3f4f6'),

      tagBorderRadius: getStringAttribute('tagBorderRadius', 'data-tag-border-radius', '9999px'),

      tagFontFamily: getStringAttribute('tagFontFamily', 'data-tag-font-family', 'inherit'),

      tagFontWeight: getStringAttribute('tagFontWeight', 'data-tag-font-weight', '400'),

      // Titles

      titleTextColor: getStringAttribute('titleTextColor', 'data-title-text-color', '#000000'),

      titleFontSize: getStringAttribute('titleFontSize', 'data-title-font-size', 'text-lg'),

      titleFontWeight: getStringAttribute('titleFontWeight', 'data-title-font-weight', '700'),

      titleLineClamp: getStringAttribute('titleLineClamp', 'data-title-line-clamp', 'line-clamp-none'),

      titleFontFamily: getStringAttribute('titleFontFamily', 'data-title-font-family', 'inherit'),

      titleTransform: getStringAttribute('titleTransform', 'data-title-transform', 'normal-case'),

      subtitleTextColor: getStringAttribute('subtitleTextColor', 'data-subtitle-text-color', '#000000'),

      subtitleFontSize: getStringAttribute('subtitleFontSize', 'data-subtitle-font-size', 'text-sm'),

      subtitleFontWeight: getStringAttribute('subtitleFontWeight', 'data-subtitle-font-weight', '400'),

      subtitleLineClamp: getStringAttribute('subtitleLineClamp', 'data-subtitle-line-clamp', 'none'),

      subtitleShowing: getBooleanAttribute('subtitleShowing', 'data-subtitle-showing', true),

      subtitleFontFamily: getStringAttribute('subtitleFontFamily', 'data-subtitle-font-family', 'inherit'),

      subtitleTransform: getStringAttribute('subtitleTransform', 'data-subtitle-transform', 'none'),

      // Authors
      authorsEnabled: getBooleanAttribute('authorsEnabled', 'data-author-enabled', true),

      authorsImagesEnabled: getBooleanAttribute('authorsImagesEnabled', 'data-author-images-enabled', false),

      authorsTextEnabled: getBooleanAttribute('authorsTextEnabled', 'data-author-text-enabled', true),

      authorsTruncateListEnabled: getBooleanAttribute(
        'authorsTruncateListEnabled',
        'data-author-truncate-list-enabled',
        false
      ),

      authorsTextColor: getStringAttribute('authorsTextColor', 'data-author-text-color', '#000000'),

      authorsTextTransform: getStringAttribute('authorsTextTransform', 'data-author-text-transform', 'capitalize'),

      authorsFontSize: getStringAttribute('authorsFontSize', 'data-author-font-size', 'text-xs'),

      authorsFontWeight: getStringAttribute('authorsFontWeight', 'data-author-font-weight', '400'),

      authorsFontFamily: getStringAttribute('authorsFontFamily', 'data-author-font-family', 'inherit'),

      // Timestamps
      timestampsEnabled: getBooleanAttribute('timestampsEnabled', 'data-timestamp-enabled', true),

      timestampsTextColor: getStringAttribute('timestampsTextColor', 'data-timestamp-text-color', '#000000'),

      timestampsDisplay: getStringAttribute('timestampsDisplay', 'data-timestamp-line-clamp', 'short_date'),

      timestampsFontWeight: getStringAttribute('timestampsFontWeight', 'data-timestamp-font-weight', '400'),

      // Premium
      premiumIconEnabled: getBooleanAttribute('premiumIconEnabled', 'data-premium-icon-enabled', true),

      premiumTextEnabled: getBooleanAttribute('premiumTextEnabled', 'data-premium-text-enabled', true),

      premiumBadgeEnabled: getBooleanAttribute('premiumBadgeEnabled', 'data-premium-badge-enabled', true),

      premiumTextColor: getStringAttribute('premiumTextColor', 'data-premium-text-color', '#111827'),

      premiumBackgroundColor: getStringAttribute('premiumBackgroundColor', 'data-premium-background-color', '#f3f4f6'),

      premiumTextTransform: getStringAttribute('premiumTextTransform', 'data-premium-text-transform', 'capitalize'),

      premiumFontWeight: getStringAttribute('premiumFontWeight', 'data-premium-font-weight', '400'),

      cardStructurePredefinedTemplate: getStringAttribute(
        'cardStructurePredefinedTemplate',
        'data-card-structure-predefined-template',
        'Image Top'
      ),
      cardStructure: getJSONAttribute('cardStructure', 'data-card-structure', cardStructure),

      separatorColor: getStringAttribute('separatorColor', 'data-separator-color', '#000000'),

      byLineText: getStringAttribute('byLineText', 'data-by-line-text', ''),

      // Pagination
      paginateButtonBorderRadius: getStringAttribute(
        'paginateButtonBorderRadius',
        'data-paginate-button-border-radius',
        '8px'
      ),

      paginateButtonBorderWidth: getStringAttribute(
        'paginateButtonBorderWidth',
        'data-paginate-button-border-width',
        '1px'
      ),

      paginateButtonBorderStyle: getStringAttribute(
        'paginateButtonBorderStyle',
        'data-paginate-button-border-style',
        'solid'
      ),

      paginateButtonBorderColor: getStringAttribute(
        'paginateButtonBorderColor',
        'data-paginate-button-border-color',
        DEFAULT_PRIMARY_COLOR
      ),

      paginateButtonPadding: getStringAttribute(
        'paginateButtonPadding',
        'data-paginate-button-padding',
        '12px 16px 12px 16px'
      ),

      paginateButtonBackgroundColor: getStringAttribute(
        'paginateButtonBackgroundColor',
        'data-paginate-button-background-color',
        DEFAULT_PRIMARY_COLOR
      ),

      paginateButtonHoverBackgroundColor: getStringAttribute(
        'paginateButtonHoverBackgroundColor',
        'data-paginate-button-hover-background-color',
        DEFAULT_PRIMARY_COLOR_HOVER
      ),

      paginateButtonTextColor: getStringAttribute(
        'paginateButtonTextColor',
        'data-paginate-button-text-color',
        DEFAULT_TEXT_ON_PRIMARY_COLOR
      ),

      paginateButtonHoverTextColor: getStringAttribute(
        'paginateButtonHoverTextColor',
        'data-paginate-button-hover-text-color',
        DEFAULT_TEXT_ON_PRIMARY_COLOR
      ),

      paginateButtonShadow: getStringAttribute(
        'paginateButtonShadow',
        'data-paginate-button-shadow',
        '0px 0px 0px 0px rgba(0, 0, 0, 0)'
      ),

      paginateButtonIconColor: getStringAttribute(
        'paginateButtonIconColor',
        'data-paginate-button-icon-color',
        DEFAULT_TEXT_ON_PRIMARY_COLOR
      ),

      paginateButtonIconPlacement: getStringAttribute(
        'paginateButtonIconPlacement',
        'data-paginate-button-icon-placement',
        'left'
      ),

      paginateButtonIcon: getJSONAttribute('paginateButtonIcon', 'data-paginate-button-icon', undefined),

      paginateButtonText: getStringAttribute('paginateButtonText', 'data-paginate-button-text', 'Load more'),

      paginateButtonBusyText: getStringAttribute(
        'paginateButtonBusyText',
        'data-paginate-button-busy-text',
        'Loading...'
      ),

      // Search Input
      inputAlignment: getStringAttribute('inputAlignment', 'data-input-alignment', 'center'),

      inputMaxWidth: getStringAttribute('inputMaxWidth', 'data-input-max-width', '600px'),

      inputMaxHeight: getStringAttribute('inputMaxHeight', 'data-input-max-height', '60px'),

      inputEnabled: getBooleanAttribute('inputEnabled', 'data-search-input-enabled', true),

      inputIconFill: getStringAttribute('inputIconFill', 'data-input-icon-fill', DEFAULT_PRIMARY_COLOR),

      inputBorderWidth: getStringAttribute('inputBorderWidth', 'data-input-border-width', '1px'),

      inputBorderColor: getStringAttribute('inputBorderColor', 'data-input-border-color', DEFAULT_BORDER_COLOR),

      inputBorderStyle: getStringAttribute('inputBorderStyle', 'data-input-border-style', 'solid'),

      inputBorderRadius: getStringAttribute('inputBorderRadius', 'data-input-border-radius', '8px'),

      inputBackgroundColor: getStringAttribute('inputBackgroundColor', 'data-input-background-color', '#ffffff'),

      inputTextColor: getStringAttribute('inputTextColor', 'data-input-text-color', DEFAULT_PRIMARY_COLOR),

      inputPlaceholderColor: getStringAttribute('inputPlaceholderColor', 'data-input-placeholder-color', '#6b7280'),

      data: getJSONAttribute('data', 'data-data', {
        posts: [],
      }),

      // Empty state
      emptyStateTextColor: getStringAttribute('emptyStateColor', 'data-empty-state-text-color', DEFAULT_PRIMARY_COLOR),

      emptyStateBackgroundColor: getStringAttribute(
        'emptyStateBackgroundColor',
        'data-empty-state-background-color',
        DEFAULT_TEXT_ON_PRIMARY_COLOR
      ),

      emptyStateText: getStringAttribute('emptyStateText', 'data-empty-state-text', 'No posts found'),

      emptyStatePadding: getStringAttribute('emptyStatePadding', 'data-empty-state-padding', '24px'),
    };
  },

  addOptions() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setPost:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(PostView, {
      stopEvent: () => false,
      className: 'relative',
      attrs: (props) => {
        return {
          ...props.HTMLAttributes,
          style: `--react-renderer-width: 100%;`,
        };
      },
    });
  },
});

export default Post;

// const cardStructure2 = {
//   node: 'div',
//   className: 'flex gap-2',
//   children: [
//     {
//       node: 'image',
//       className: '',
//       children: [],
//     },
//     {
//       node: 'div',
//       className: 'flex flex-col',
//       children: [
//         {
//           node: 'tags',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'titles',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'authors',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'timestamp',
//           className: '',
//           children: [],
//         },
//         {
//           node: 'premium',
//           className: '',
//           children: [],
//         },
//       ],
//     },
//   ],
// };
