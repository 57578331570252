import { NavbarSerializableNode } from '@shared/dream-components/dist/navbarElement/Serializer.types';

import { Slider } from '@/routes/website/_components/UI/Slider';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';

type SliderSettingsProps = {
  selectedContent: NavbarSerializableNode;
  attribute: string;
  defaultValue?: number;
  title: string;
  min?: number;
  max?: number;
  unit?: string;
  tooltip?: React.ReactNode;
};

const parseCssValue = (value: string | undefined) => {
  if (!value) return 0;
  if (value === 'auto') {
    return 0;
  }
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }
  const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
  return Number.isNaN(numericValue) ? 0 : numericValue;
};

export const SliderSettings = ({
  selectedContent,
  attribute,
  title,
  min,
  max,
  unit,
  defaultValue,
  tooltip,
}: SliderSettingsProps) => {
  const { onUpdateNodeAttributes } = useNavbarContext();
  const rawContentValue =
    selectedContent?.attrs && attribute in selectedContent.attrs
      ? (selectedContent.attrs[attribute as keyof typeof selectedContent.attrs] as string | number)
      : defaultValue;

  const contentValue = typeof rawContentValue === 'number' ? rawContentValue : parseCssValue(rawContentValue);

  const handleSliderChange = (value: number) => {
    if (!selectedContent.attrs?.id) return;

    const newValue = unit ? `${value}${unit}` : value;
    onUpdateNodeAttributes(selectedContent.attrs.id, { [attribute]: newValue });
  };

  return (
    <div className="flex items-center justify-stretch gap-2">
      <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
        <Text variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        {tooltip}
      </div>
      <div className="grow flex items-center h-[32px] gap-2">
        <div className="flex flex-row gap-1 h-full">
          <input
            className="bg-wb-secondary rounded-lg border-none p-2 text-xs w-full"
            type="number"
            min={0}
            value={contentValue}
            onChange={(e) => handleSliderChange(Number(e.target.value))}
          />
          {unit && (
            <Text size="2xs" weight="medium" className="my-auto" variant="secondary">
              {unit}
            </Text>
          )}
        </div>
        <Slider
          defaultValue={[contentValue]}
          onValueChange={(value) => {
            handleSliderChange(value[0]);
          }}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};
