import { useEffect, useState } from 'react';
import { NavbarSerializableNode } from '@shared/dream-components';

import { Site } from '@/interfaces/dream_builder/site';
import { WebTheme } from '@/interfaces/web_theme';
import transformTokenOverrides from '@/routes/website/_components/DreamEditor/utils/transformThemeOverrides';

export const getParentOrientation = (parentNode: NavbarSerializableNode): 'vertical' | 'horizontal' => {
  switch (parentNode.type) {
    case 'navbar_menu':
    case 'navbar_dropdown_column':
      return 'vertical';
    case 'navbar_menu_list_group':
    case 'navbar_item':
    case 'navbar_menu_list':
    case 'navbar_dropdown':
    default:
      return 'horizontal';
  }
};

export const getParent = (
  content: NavbarSerializableNode,
  node: NavbarSerializableNode
): NavbarSerializableNode | null => {
  if (content === node) {
    return null; // Node is the root, it's its own parent
  }

  const findParent = (currentNode: NavbarSerializableNode): NavbarSerializableNode => {
    if (!('content' in currentNode)) {
      return content; // If no content, return the root
    }

    if (Array.isArray(currentNode.content)) {
      for (let i = 0; i < currentNode.content.length; i += 1) {
        const child = currentNode.content[i];
        if (child === node) {
          return currentNode;
        }

        const result = findParent(child);
        if (result !== content) {
          return result;
        }
      }
    }

    return content; // If not found, return the root
  };

  return findParent(content);
};

export const getNodeByID = (content: NavbarSerializableNode, id: string): NavbarSerializableNode | null => {
  if (content.attrs?.id === id) {
    return content;
  }
  if (!('content' in content)) {
    return null; // If no content, return the null
  }

  if (Array.isArray(content.content)) {
    for (let i = 0; i < content.content.length; i += 1) {
      const child = content.content[i];
      const result = getNodeByID(child, id);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

export const formatCamelCase = (value: string): string => {
  if (!value) return 'unknown';

  // split camel case and capitalize each word
  const formattedType = value
    .split(/(?=[A-Z])|_/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  return formattedType;
};

export const applyThemeToNode = (
  node: Partial<NavbarSerializableNode>,
  theme: WebTheme,
  mapping: Site['theme_rules'],
  applyToOldNode?: boolean
): NavbarSerializableNode => {
  const newNode = { ...node } as NavbarSerializableNode;

  let nodeTypeKey = node.type as keyof typeof mapping;
  if (node.attrs && 'type' in node.attrs && node.attrs?.type) {
    nodeTypeKey = `${node.type}.${node.attrs.type}`;
  }

  if (nodeTypeKey in mapping) {
    const nodeThemeMapping = transformTokenOverrides(node.attrs?.tokens || {}, mapping[nodeTypeKey]);
    if (!nodeThemeMapping) {
      return newNode;
    }
    const themeAttrKeys = Object.keys(nodeThemeMapping);
    const updatedNodeAttrs: Record<string, string> = {};

    themeAttrKeys.forEach((attrKey) => {
      if (!Object.hasOwn(theme, attrKey)) {
        return;
      }
      const themeValue = theme[attrKey as keyof WebTheme];
      const attrsToUpdate = nodeThemeMapping[attrKey as keyof WebTheme];

      attrsToUpdate?.forEach((attr) => {
        updatedNodeAttrs[attr] = themeValue;
      });
    });

    if (Object.keys(updatedNodeAttrs).length > 0) {
      newNode.attrs = { ...(newNode.attrs || {}), ...updatedNodeAttrs } as NavbarSerializableNode['attrs'];
      if (applyToOldNode) {
        /* eslint-disable no-param-reassign */
        node.attrs = { ...(node.attrs || {}), ...updatedNodeAttrs } as NavbarSerializableNode['attrs'];
      }
    }
  }

  if ('content' in newNode && Array.isArray(newNode.content)) {
    const newContent = newNode.content.map((child) =>
      applyThemeToNode(child, theme, mapping, applyToOldNode)
    ) as typeof newNode.content;
    newNode.content = newContent;
  }

  return newNode as NavbarSerializableNode;
};

// Custom hook for detecting mobile viewport
export const useIsMobile = (iframeRef: React.RefObject<HTMLIFrameElement>) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const currentIframe = iframeRef.current;

    const checkMobile = () => {
      setIsMobile((currentIframe?.contentWindow?.innerWidth || 1024) < 768); // Adjust breakpoint as needed
    };

    checkMobile();
    currentIframe?.contentWindow?.addEventListener('resize', checkMobile);
    return () => currentIframe?.contentWindow?.removeEventListener('resize', checkMobile);
  }, [iframeRef]);

  return isMobile;
};
