import toast from 'react-hot-toast';
import { Node as PMNode } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';

const camelCaseToWords = (s: string) => {
  if (!s || typeof s !== 'string') {
    return '';
  }

  const result = s.replace(/([A-Z])/g, ' $1');

  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const NonDeletableNodesPluginKey = new PluginKey('nonDeletableNodes');

const extractNonDeletableNodesDataFromDoc = (doc: PMNode) => {
  const nodeTypeNameNodesMap: Record<PMNode['type']['name'], PMNode[]> = {};
  const nodeTypeNameNodesCountMap: Record<PMNode['type']['name'], number> = {};

  doc.descendants((node) => {
    nodeTypeNameNodesCountMap[node.type.name] = (nodeTypeNameNodesCountMap[node.type.name] || 0) + 1;

    if (typeof node.attrs.deletable !== 'boolean' || node.attrs.deletable) {
      return;
    }

    if (!nodeTypeNameNodesMap[node.type.name]) {
      nodeTypeNameNodesMap[node.type.name] = [];
    }

    if (node.attrs.id) {
      nodeTypeNameNodesMap[node.type.name].push(node);
    }
  });

  return [nodeTypeNameNodesMap, nodeTypeNameNodesCountMap] as const;
};

// nonDeletableNodes refers to an entire category of nodes, not a single node that are not deletable
// nonDeletableSpecificNodes refers to a single node that are not deletable, ie All posts are deletable, but a specific post is not deletable
export const getNonDeletableNodes = (nonDeletableNodesTypeNames: PMNode['type']['name'][], notify?: Function) => {
  return new Plugin({
    key: NonDeletableNodesPluginKey,
    filterTransaction(tr, state) {
      if (
        !tr.docChanged ||
        (typeof tr.getMeta('shouldReplaceNode') === 'boolean' && tr.getMeta('shouldReplaceNode')) ||
        (typeof tr.getMeta('addToHistory') === 'boolean' && !tr.getMeta('addToHistory')) ||
        tr.getMeta('overrideNonDeletableNodesFilterTransaction')
      ) {
        return true;
      }

      const [nodeTypeNameNodesMapBefore, nodeTypeNameNodesCountMapBefore] = extractNonDeletableNodesDataFromDoc(
        state.doc
      );

      const [nodeTypeNameNodesMapAfter, nodeTypeNameNodesCountMapAfter] = extractNonDeletableNodesDataFromDoc(tr.doc);

      let deletedNonDeletableNodeTypeName = nonDeletableNodesTypeNames.find((nodeName) => {
        return (nodeTypeNameNodesCountMapBefore[nodeName] || 0) > (nodeTypeNameNodesCountMapAfter[nodeName] || 0);
      });

      if (deletedNonDeletableNodeTypeName) {
        toast.error(`${camelCaseToWords(deletedNonDeletableNodeTypeName) || 'This node'} cannot be deleted`);
        notify?.();
        return false;
      }

      let nonDeletableNodeDeleted = false;

      Object.entries(nodeTypeNameNodesMapBefore).forEach(([nodeTypeName, nodesBefore]) => {
        if (nonDeletableNodeDeleted || deletedNonDeletableNodeTypeName) {
          return;
        }

        const nodesAfter = nodeTypeNameNodesMapAfter[nodeTypeName] || [];

        if (
          !nodesBefore.every((node) =>
            nodesAfter.some(
              (nodeAfter) => node.type.name === nodeAfter.type.name && node.attrs.id === nodeAfter.attrs.id
            )
          )
        ) {
          nonDeletableNodeDeleted = true;
          deletedNonDeletableNodeTypeName = nodeTypeName;
        }
      });

      if (nonDeletableNodeDeleted) {
        toast.error(`${camelCaseToWords(deletedNonDeletableNodeTypeName || '') || 'this node'} cannot be deleted`);
        notify?.();
        return false;
      }

      return true;
    },
  });
};
