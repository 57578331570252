import { BellSnoozeIcon } from "@heroicons/react/20/solid";

import IconButton from "@/components/IconHelpers/IconButton";
import Tooltip from "@/ui/Tooltip";

interface Props {
  isSnoozing: boolean;
  onSnooze: () => void;
  tooltipId: string;
}

const SnoozeButton = ({ isSnoozing, onSnooze, tooltipId }: Props) => {
  return (
    <Tooltip
      tooltipClass="text-center"
      id={`snooze-boost-offer-${tooltipId}`}
      text="Snooze offer"
      showIcon={false}
      autoWidth
    >
      <IconButton onClick={() => onSnooze()} disabled={isSnoozing}>
        <BellSnoozeIcon />
      </IconButton>
    </Tooltip>
  )
}

export default SnoozeButton;
