import { useState } from 'react';

import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import useFormattedTopLineMetrics from '@/hooks/boosts/grow/useFormattedTopLineMetrics';
import useOfferStatus from '@/hooks/boosts/grow/useOfferStatus';
import { BoostOffer } from '@/interfaces/boosts/grow/boost_offer';
import { EllipsisDropdownOption } from '@/interfaces/general';

import BoostAgreementsList from '../../../Shared/BoostAgreementsList';
import BoostOfferActionsDropdown from '../../../Shared/BoostOfferActionsDropdown';
import BoostSendsList from '../../../Shared/BoostSendsList';
import BoostOfferStatusBadge from '../BoostOfferStatusBadge';

interface Props {
  boostOffer: BoostOffer;
}

const BoostOfferListItem = ({ boostOffer }: Props) => {
  const [showBoostAgreements, setShowBoostAgreements] = useState(false);
  const [showBoostSends, setShowBoostSends] = useState(false);
  const { cost } = useOfferStatus(boostOffer);

  const enabledChannels = ['Web'];
  const additionalOptions: EllipsisDropdownOption[] = [
    {
      label: `${showBoostAgreements ? 'Hide' : 'View'} Web Boosts`,
      onClick: () => {
        setShowBoostAgreements(!showBoostAgreements);
        setShowBoostSends(false);
      },
    },
  ];
  if (boostOffer.max_spend_cents) {
    enabledChannels.push('Email');
    additionalOptions.push({
      label: `${showBoostSends ? 'Hide' : 'View'} Email Boosts`,
      onClick: () => {
        setShowBoostSends(!showBoostSends);
        setShowBoostAgreements(false);
      },
    });
  }

  const { totalSpend, pendingSpend } = useFormattedTopLineMetrics(boostOffer.top_line_metrics);

  return (
    <>
      <ItemRow className="bg-white">
        <ItemColumn align={AlignType.TOP}>
          <div className="w-64">
            <TypographyStack>
              <Typography weight="medium" colorWeight="900">
                {cost}
              </Typography>
              <Typography size="xs" colorWeight="500">
                {enabledChannels.join(', ')}
              </Typography>
            </TypographyStack>
          </div>
        </ItemColumn>
        <ItemColumn>
          <BoostOfferStatusBadge status={boostOffer.status} />
        </ItemColumn>
        <ItemColumn>
          <Typography size="sm" colorWeight="700">
            {boostOffer.top_line_metrics.total_pending_subscribers.toLocaleString()}
          </Typography>
        </ItemColumn>
        <ItemColumn>
          <Typography size="sm" colorWeight="700">
            {boostOffer.top_line_metrics.total_accepted_subscribers.toLocaleString()}
          </Typography>
        </ItemColumn>
        <ItemColumn>
          <Typography size="sm" colorWeight="700">
            {boostOffer.top_line_metrics.total_subscribers.toLocaleString()}
          </Typography>
        </ItemColumn>
        <ItemColumn>
          <Typography size="sm" colorWeight="700">
            {pendingSpend}
          </Typography>
        </ItemColumn>
        <ItemColumn>
          <Typography size="sm" colorWeight="700">
            {totalSpend}
          </Typography>
        </ItemColumn>
        <ItemColumn isSticky className="w-full h-full flex items-center">
          <BoostOfferActionsDropdown boostOffer={boostOffer} additionalOptions={additionalOptions} />
        </ItemColumn>
      </ItemRow>
      {showBoostAgreements && (
        <ItemRow>
          <ItemColumn colSpan={13}>
            <BoostAgreementsList boostOfferId={boostOffer.id} isSecondary showSearch />
          </ItemColumn>
        </ItemRow>
      )}
      {showBoostSends && (
        <ItemRow>
          <ItemColumn colSpan={13}>
            <BoostSendsList boostOfferId={boostOffer.id} isSecondary />
          </ItemColumn>
        </ItemRow>
      )}
    </>
  );
};

export default BoostOfferListItem;
