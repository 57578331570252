import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { usePage } from '@/hooks/usePages';
import { useUpdatePageVersion } from '@/hooks/usePageVersion';

import { Text } from '../../UI/Text';

import NavigationItem from './NavigationItem';

const NavigationItems = () => {
  const { pageId } = useParams();
  const navigate = useNavigate();
  const { data: page } = usePage({ pageId: pageId as string });

  const { mutate: updatePageVersion } = useUpdatePageVersion({
    pageId: page?.id || '',
    id: page?.draft_page_version?.id || '',
  });

  const handleFooterUpdate = (value: boolean) => {
    updatePageVersion({
      customization: { show_footer: value },
    });
    toast.success(value ? 'Footer will be enabled for this page' : 'Footer will be disabled for this page');
  };

  const handleNavbarUpdate = (value: boolean) => {
    updatePageVersion({
      customization: { show_navbar: value },
    });
    toast.success(value ? 'Navbar will be enabled for this page' : 'Navbar will be disabled for this page');
  };

  const isFooterEnabled =
    'show_footer' in (page?.draft_page_version?.customization || {})
      ? page?.draft_page_version?.customization?.show_footer
      : true;

  const isNavbarEnabled =
    'show_navbar' in (page?.draft_page_version?.customization || {})
      ? page?.draft_page_version?.customization?.show_navbar
      : true; // default to true if show_navbar is not defined

  const navigateToNavbar = () => {
    navigate(`/website_builder_v2/navbar?previousPageID=${pageId}`);
  };

  const navigateToFooter = () => {
    navigate(`/website_builder_v2/footer?previousPageID=${pageId}`);
  };

  return (
    <div className="flex flex-col">
      <Text size="xs" variant="secondary" weight="semibold" className="p-2">
        Navigation
      </Text>
      <div className="w-full flex items-center justify-between px-2">
        <div className="flex flex-col w-full">
          <NavigationItem
            title="Navbar"
            onUpdate={handleNavbarUpdate}
            isEnabled={isNavbarEnabled}
            onClick={navigateToNavbar}
            editText="Edit global navbar"
          />
          <NavigationItem
            title="Footer"
            onUpdate={handleFooterUpdate}
            isEnabled={isFooterEnabled}
            onClick={navigateToFooter}
            editText="Edit global footer"
          />
        </div>
      </div>
    </div>
  );
};

export default NavigationItems;
