import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link as LinkIcon } from '@phosphor-icons/react';
import { useEditorState } from '@tiptap/react';

import { useCurrentPublication } from '@/hooks';
import useResolvedRoutes from '@/routes/website/_hooks/useResolvedRoutes';

import { Button } from '../../../UI/Button';
import { SimpleInput, SimpleInputWrapper } from '../../../UI/Input';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { PopoverHelper, PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';
import { isInternalLink } from '../utils/isInternalLink';

export interface ComboboxOption {
  id: number | string;
  name: string;
  LeftIcon?: React.ReactNode;
}

const validateUrl = (url: string) => {
  try {
    if (!url.length) return true;

    const parsedUrl = new URL(url);

    if (process.env.NODE_ENV !== 'development') {
      return parsedUrl.protocol === 'https:';
    }

    return true;
  } catch (error) {
    toast.error('Invalid URL');
    return false;
  }
};

export const LinkToSettings = ({ editor }: AttributeSettingProps) => {
  const routes = useResolvedRoutes();
  const { data: currentPublication } = useCurrentPublication();

  const [currentURL, setCurrentURL] = useState('');
  const [userFriendlyName, setUserFriendlyName] = useState('');
  const [selectedLinkType, setSelectedLinkType] = useState<'routes' | 'custom'>('routes');
  const [isEditing, setIsEditing] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const href = useEditorState<string>({
    editor,
    selector: ({ editor: e }) => e.getAttributes('link')?.href,
  });

  useEffect(() => {
    if (!isEditing && href && !isInitialized) {
      const isInternal = isInternalLink(currentPublication?.url || '', href);
      if (isInternal) {
        try {
          const url = new URL(href);
          const slug = url.pathname;
          const route = routes.find((r: any) => `/${r.fullSlug}` === slug);
          setUserFriendlyName(route?.name || '');
          setSelectedLinkType('routes');
        } catch (error) {
          setUserFriendlyName('');
        }
      } else if (href) {
        setUserFriendlyName('');
        setSelectedLinkType('custom');
      }
      setCurrentURL(href || '');
      setIsInitialized(true);
    }
    if (href !== currentURL) {
      setIsInitialized(false);
    }
  }, [currentPublication?.url, href, routes, isEditing, isInitialized]);

  const handleSetLink = useCallback(
    (url: string, isInternal?: boolean) => {
      if (!validateUrl(url)) return;

      if (!isInternal) {
        setUserFriendlyName('');
      }

      if (isInternal || isInternalLink(currentPublication?.url || '', url)) {
        editor.chain().extendMarkRange('link').setLink({ href: url, target: '_self' }).focus().run();
      } else {
        editor
          .chain()
          .extendMarkRange('link')
          .setLink({ href: url, target: '_blank', rel: 'noopener noreferrer' })
          .focus()
          .run();
      }
      setIsEditing(false);
      setIsInitialized(false);
    },
    [editor, currentPublication?.url]
  );

  return (
    <PopoverHelper title="Link" text={userFriendlyName || href} align="end" popoverTitle="Link" Icon={LinkIcon}>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="routes"
          value={selectedLinkType}
          onValueChange={(value: 'routes' | 'custom') => {
            setSelectedLinkType(value);
            if (value === 'routes') {
              setCurrentURL('');
            } else {
              setUserFriendlyName('');
            }
          }}
        >
          <ToggleGroupItem value="routes" className="grow">
            Pages
          </ToggleGroupItem>
          <ToggleGroupItem value="custom" className="grow">
            Custom
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
      <div className="flex flex-col gap-2 max-h-[200px] mt-2 pb-4">
        {selectedLinkType === 'custom' && (
          <div className="flex flex-col gap-2">
            <SimpleInputWrapper>
              <SimpleInput
                placeholder="https://www.example.com"
                type="text"
                value={currentURL}
                onChange={(e) => {
                  setIsEditing(true);
                  setCurrentURL(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSetLink(currentURL);
                  }
                }}
              />
            </SimpleInputWrapper>
            <Button variant="outlined" size="sm" onClick={() => handleSetLink(currentURL)}>
              Apply
            </Button>
          </div>
        )}
        {selectedLinkType === 'routes' && (
          <>
            {routes.map((route: any) => {
              return (
                <PopoverItem
                  key={route.id}
                  isSelected={route.name === userFriendlyName}
                  value={route.name}
                  onSelect={() => {
                    const settableUrl = `${currentPublication?.url}${route.fullSlug}`;
                    setUserFriendlyName(route.name);
                    handleSetLink(settableUrl, true);
                  }}
                  subtitle={`/${route.fullSlug}`}
                />
              );
            })}
          </>
        )}
      </div>
    </PopoverHelper>
  );
};
