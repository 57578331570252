import { Link } from 'react-router-dom';
import { CaretDown, Lightning, Question } from '@phosphor-icons/react';

import { Button } from '@/routes/website/_components/UI/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/routes/website/_components/UI/DropdownMenu';
import { Text } from '@/routes/website/_components/UI/Text';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';
import { cn } from '@/utils/cn';

import { useNavbarContext } from '../../../NavbarContext';

export const ACTION_TITLES: Record<
  string,
  {
    title: string;
    description: string;
    disabledTooltip?: string;
    edit?: string;
    link?: string;
  }
> = {
  none: {
    title: 'None',
    description: 'No action will be performed when the button is clicked.',
  },
  sign_up: {
    title: 'Sign Up Modal',
    description: 'Opens the sign up modal',
    disabledTooltip: 'The Sign Up button is essential for user access and cannot be changed',
    edit: 'Edit Sign Up Modal',
    link: '/website_builder_v2/globals/signup_modal',
  },
  login: {
    title: 'Login Modal',
    description: 'Opens the login modal',
    disabledTooltip: 'The Login button is essential for user access and cannot be changed',
    edit: 'Edit Login Modal',
    link: '/website_builder_v2/globals/login_modal',
  },
  profile: {
    title: 'Profile Page',
    description: "Opens the current user's profile page",
    disabledTooltip: 'The Profile button is essential for user access and cannot be changed',
  },
  search: {
    title: 'Search Modal',
    description: 'Opens the search modal',
  },
};

export const ActionSettings = () => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;
  if (selectedContent.attrs?.type === 'search' || selectedContent.attrs?.type === 'dropdown') return null;

  const currentValue = selectedContent.attrs?.action || 'none';
  const handleActionChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;

    if (value === 'none') {
      onUpdateNodeAttributes(selectedContent.attrs?.id, { action: undefined });
    } else {
      onUpdateNodeAttributes(selectedContent.attrs?.id, { action: value });
    }
    onUpdateNodeAttributes(selectedContent.attrs?.id, { href: undefined });
  };

  const isDisabled = selectedContent.attrs?.autoGenerated;
  const triggerComponent = (
    <div
      className={cn(
        'grow w-full rounded-lg shadow-sm justify-between flex items-center gap-2 p-1',
        isDisabled && 'cursor-not-allowed',
        isDisabled ? 'bg-wb-primary border border-wb-primary' : 'bg-wb-secondary'
      )}
    >
      <div className="flex items-center gap-2">
        <div className="flex items-center justify-center w-6 h-6 bg-wb-accent rounded-md">
          <Lightning size={12} className="text-wb-on-accent" weight="fill" />
        </div>
        <Text size="2xs" weight="medium" variant={currentValue === 'none' ? 'secondary' : 'primary'}>
          {ACTION_TITLES[currentValue]?.title}
        </Text>
      </div>
      {!isDisabled && <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />}
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-stretch gap-2">
        <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
          <Text variant="secondary" size="2xs" weight="medium">
            Action
          </Text>
          <Tooltip placement="bottom-end" center={ACTION_TITLES[currentValue]?.description}>
            <Question size={16} weight="bold" className="text-wb-secondary" />
          </Tooltip>
        </div>
        <DropdownMenu open={isDisabled ? false : undefined}>
          <DropdownMenuTrigger asChild className="cursor-pointer">
            {isDisabled ? (
              <Tooltip center={ACTION_TITLES[currentValue]?.disabledTooltip} className="w-full">
                {triggerComponent}
              </Tooltip>
            ) : (
              triggerComponent
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer" align="end">
            {Object.keys(ACTION_TITLES).map((optionKey) => (
              <DropdownMenuItem
                key={optionKey}
                onSelect={() => {
                  handleActionChange(optionKey);
                }}
              >
                {ACTION_TITLES[optionKey]?.title}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {ACTION_TITLES[currentValue]?.edit && ACTION_TITLES[currentValue]?.link && (
        <Link to={ACTION_TITLES[currentValue].link || ''} className="w-fit self-end">
          <Button variant="ghost" size="sm" className="underline">
            {ACTION_TITLES[currentValue]?.edit} &rarr;
          </Button>
        </Link>
      )}
    </div>
  );
};
