import { Length, ScalarUnitInput } from '../../../UI/ScalarUnitInput';
import { Text } from '../../../UI/Text';

type SimpleLengthSettingsProps = {
  defaultValue?: Length;
  value?: Length;
  onChange?: (length: Length) => void;
  title: string;
  units?: Array<string>;
  allowDecimal?: boolean;
  bounds?: Record<
    string,
    {
      min: number;
      max: number;
    }
  >;
};

const DEFAULT_UNITS = ['em', 'px'];

export const SimpleLengthSettings = ({
  defaultValue,
  value,
  onChange,
  title,
  units = DEFAULT_UNITS,
  allowDecimal = false,
  bounds,
}: SimpleLengthSettingsProps) => {
  return (
    <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
      <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <ScalarUnitInput
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        units={units}
        allowDecimal={allowDecimal}
        bounds={bounds}
      />
    </div>
  );
};
