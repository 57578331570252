import { Dispatch, SetStateAction, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { cn } from '@/utils/cn';

import { FooterPreview } from '../../footer/_components/FooterEditor';
import { FooterTemplatePreview } from '../../footer/_components/FooterEditor/FooterTemplatePreview';
import { NavbarPreview } from '../../navbar/_components/NavbarEditor/NavbarPreview';
import { NavbarTemplatePreview } from '../../navbar/_components/NavbarEditor/NavbarTemplatePreview';
import { Editor } from '../../page/_components/Editor';
import IframeWrapper from '../../page/_components/Iframe';
import { useContainerRef } from '../../page/_hooks';
import { useEditorFontHrefs } from '../../page/_hooks/useEditorFontHrefs';

import useEditorHelpers from './_hooks/useEditorHelpers';
import { DesktopMobileTabs, useDesktopMobileTabs, ViewportProvider } from './DesktopMobileTabs';

interface Props {
  className?: string;
  showNavAndFooter?: boolean;
  type?: 'page' | 'template';
  isFullPageHeight?: boolean;
  isTemplateView?: boolean;
  setIsInsertPanelOpen: Dispatch<SetStateAction<boolean>>;
}

const EditorExperience = ({
  className,
  showNavAndFooter = true,
  type = 'page',
  isFullPageHeight = false,
  isTemplateView = false,
  setIsInsertPanelOpen,
}: Props) => {
  const { rect: editorRect, containerRef: editorContainerRef } = useContainerRef();
  const fontHrefs = useEditorFontHrefs();
  const { pageId } = useParams();
  const { selectedTab, setSelectedTab, viewport } = useDesktopMobileTabs();

  const NavPreview = isTemplateView ? NavbarTemplatePreview : NavbarPreview;
  const FootPreview = isTemplateView ? FooterTemplatePreview : FooterPreview;

  useEditorHelpers({ setIsInsertPanelOpen });

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);

  return (
    <ViewportProvider>
      <DesktopMobileTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div
        ref={editorContainerRef}
        className={cn('h-full shadow-md bg-wb-primary', {
          'w-[375px] m-auto': selectedTab === 'mobile',
          'w-full': selectedTab === 'desktop',
        })}
      >
        <IframeWrapper
          width={editorRect.width || 0}
          height={editorRect.height || 0}
          ref={iframeRef}
          contentWidth={viewport.width}
          fontHrefs={fontHrefs}
        >
          {showNavAndFooter && (
            <NavPreview
              portalRef={portalRef}
              iframeRef={iframeRef}
              onFocus={() => {
                setIsInsertPanelOpen(false);
              }}
            />
          )}
          <Editor
            key={pageId}
            setInsertPanelOpen={setIsInsertPanelOpen}
            className={className}
            isFullPageHeight={isFullPageHeight}
            type={type}
          />
          {showNavAndFooter && (
            <FootPreview
              iframeRef={iframeRef}
              onFocus={() => {
                setIsInsertPanelOpen(false);
              }}
            />
          )}
          <div ref={portalRef} id="portal-ref" className="z-50" />
        </IframeWrapper>
      </div>
    </ViewportProvider>
  );
};

export default EditorExperience;
