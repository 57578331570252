import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import api from '../services/swarm';

interface Props {
  exportType: string;
  resourceType: string;
  resourceId: string;
  fileName?: string;
  filters?: {
    [key: string]: string;
  };
}

const useAsyncExport = (publicationId: string, props: Props) => {
  const { exportType, resourceType, resourceId, filters } = props;

  const [isExporting, setIsExporting] = useState(false);

  const startExport = () => {
    setIsExporting(true);

    const payload = {
      publication_id: publicationId,
      export_type: exportType,
      file_type: 'csv',
      resource_type: resourceType,
      resource_id: resourceId,
      filters,
    };

    api
      .post(`/exports`, payload)
      .then(() => {
        toast.success(
          <span>
            You will get an email when your export is ready, or you can view the status and download the file{' '}
            <Link to="/settings/publication/export_data#history" className="text-primary-500 hover:underline">
              here
            </Link>
          </span>,
          {
            duration: 10000,
          }
        );
      })
      .catch((errPayload) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  return {
    startExport,
    isExporting,
  };
};

export default useAsyncExport;
