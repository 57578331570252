import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { DreamEditorProvider } from '@/context/dream-editor-context';
import { useWebsiteContext, WebsiteProvider } from '@/context/website-context';
import { useSwapPackages } from '@/hooks/useSite';

import { AttributesPanelNavbarFooterContextProvider } from '../_components/DreamEditor/AttributesPanel/navbarFooterContextProvider';
import { Button } from '../_components/UI/Button';
import Callout from '../_components/UI/Callout';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../_components/UI/Dialog';
import { Text } from '../_components/UI/Text';
import WebsiteOnboardingPage from '../onboarding';

const SwitchTemplatePage = () => {
  const navigation = useNavigate();
  const { site, defaultRoutes } = useWebsiteContext();
  const [isOpen, setIsOpen] = useState(false);
  const [newSitePackageId, setNewSitePackageId] = useState('');
  const [themeParams, setThemeParams] = useState<Record<string, any>>({});

  const { mutate, isLoading } = useSwapPackages({
    newSitePackageId,
    siteId: site?.id || '',
    onSuccess: () => {
      toast.success('Template switched successfully');
      navigation(`/website_builder_v2/page/${defaultRoutes?.page_id}`);

      // TODO: This is a hack to refresh the editor. We need to find a better solution.
      window.location.reload();
    },
  });

  const handleSwap = () => {
    mutate({ themeParams });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="w-full max-w-md flex flex-col overflow-hidden p-4 gap-2">
          <DialogHeader>
            <DialogTitle>
              <div className="flex items-center gap-2">
                <Text size="lg" weight="semibold" variant="primary" as="h4">
                  Switch Templates
                </Text>
              </div>
            </DialogTitle>
          </DialogHeader>

          <div className="flex flex-col gap-2 py-2">
            <Callout title="Before you switch templates">
              Please note that all of your site data will be reset to placeholder data values for this template.
            </Callout>
          </div>
          <DialogFooter className="pt-4">
            <Button variant="secondary" onClick={() => setIsOpen(false)}>
              <Text size="xs" weight="medium" as="span">
                Cancel
              </Text>
            </Button>
            <Button variant="primary" onClick={handleSwap}>
              {isLoading ? 'Switching...' : 'Switch Templates'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <WebsiteOnboardingPage
        stepConfigOverride={{
          1: {
            title: 'Switch templates',
          },
          2: {
            title: 'Select a template',
          },
          3: {
            title: 'Update your theme',
          },
          4: {
            title: 'Update your fonts',
          },
        }}
        stepOneOverrideProps={{
          title: 'Pick a new template',
          description: 'Choose a template to get started',
        }}
        stepTwoOverrideProps={{
          title: 'Update your theme',
          description: 'Select new colors and border styles',
        }}
        stepThreeOverrideProps={{
          title: 'Update your fonts',
          description: 'Select new fonts for your template',
          nextText: 'Switch Templates',
          isLoading,
          onNext: () => {
            setIsOpen(true);
          },
        }}
        passUpState={(state) => {
          setNewSitePackageId(state.newSitePackageId);
          setThemeParams(state.themeParams);
        }}
      />
    </>
  );
};

export default () => (
  <WebsiteProvider>
    <DreamEditorProvider>
      <AttributesPanelNavbarFooterContextProvider>
        <SwitchTemplatePage />
      </AttributesPanelNavbarFooterContextProvider>
    </DreamEditorProvider>
  </WebsiteProvider>
);
