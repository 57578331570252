import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment-mini';

import usePostNodeContent from '@/hooks/usePostNodeContent';
import Tooltip from '@/ui/Tooltip';
import analytics from '@/utils/analytics';

import { useCurrentUser } from '../../../../context/current-user-context';
import { Post, PostNodeContent, PostScheduleOptions } from '../../../../interfaces/post';
import api from '../../../../services/swarm';

import ConfirmModal from './ConfirmModal';

interface Props {
  postData: Post;
  onConfirm: () => void;
  disabled: boolean;
  disabledTooltip?: string;
}

const ScheduleModal: React.FunctionComponent<Props> = (props: Props) => {
  const { postData, onConfirm, disabled, disabledTooltip } = props;

  const [isScheduling, setIsScheduling] = useState(false);
  const [saving, setSaving] = useState(false);

  const [selectedScheduleOption, setSelectedScheduleOption] = useState(PostScheduleOptions.SCHEDULE);
  const [selectedTime, setSelectedTime] = useState(postData.scheduled_at);
  const [isSelectedTimeInvalid, setIsSelectedTimeInvalid] = useState(false);
  const { currentUser } = useCurrentUser();
  const { data: postNodeData, refetch } = usePostNodeContent({ id: postData.id });
  const postNodeContent = postNodeData || ({} as PostNodeContent);

  useEffect(() => {
    if (isScheduling) {
      refetch();
    }
  }, [isScheduling]);

  // Update the post with the selected publish times then confirm the post
  const confirm = () => {
    const publishNow = selectedTime === null || selectedTime === '' || moment(selectedTime).isBefore(moment());

    const formattedDate = publishNow ? moment().toISOString() : moment(selectedTime).toISOString();

    setSaving(true);

    return api
      .patch(`/posts/${postData.id}`, {
        post: {
          scheduled_at: formattedDate,
        },
      })
      .then(() => {
        api
          .patch(`/posts/${postData.id}/transition`, { status: 'confirmed' })
          .then(() => {
            analytics.track('Sent Post');
            toast.success(selectedTime ? 'Post successfully scheduled' : 'Post successfully published');
            setIsScheduling(false);
            onConfirm();
          })
          .catch((errPayload) =>
            toast.error(() => (
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: errPayload.response.data.message,
                }}
              />
            ))
          );
      })
      .catch(() => toast.error('There was a problem publishing your post'))
      .finally(() => setSaving(false));
  };

  const handleClose = () => {
    setIsScheduling(false);
    setIsSelectedTimeInvalid(false);
  };

  const handleTimeSelect = (value: string | null) => value && setSelectedTime(value);

  return (
    <>
      <ConfirmModal
        isOpen={isScheduling}
        isWorking={saving}
        onClose={handleClose}
        postData={postData}
        postNodeContent={postNodeContent}
        refetchPostNodeContent={refetch}
        isEmailConfirmed={!!currentUser?.is_confirmed}
        selectedScheduleOption={selectedScheduleOption}
        setSelectedScheduleOption={setSelectedScheduleOption}
        selectedTime={selectedTime}
        setSelectedTime={handleTimeSelect}
        isSelectedTimeInvalid={isSelectedTimeInvalid}
        setIsSelectedTimeInvalid={setIsSelectedTimeInvalid}
        onConfirm={confirm}
      />
      <Tooltip
        tooltipClass="text-center"
        id="disabled-schedule-btn-tooltip"
        text={disabled && disabledTooltip ? disabledTooltip : ''}
        showIcon={false}
        autoWidth
        isEnabled={disabled}
      >
        <button
          type="button"
          onClick={() => setIsScheduling(true)}
          disabled={disabled}
          className="justify-center duration-200 py-1 px-3 border font-medium focus:outline-none focus:ring-1 inline-flex items-center disabled:cursor-not-allowed whitespace-nowrap text-sm bg-gray-800 hover:bg-gray-900 text-white disabled:text-gray-200 disabled:bg-gray-700 rounded-md shadow-sm border-transparent"
        >
          Schedule
        </button>
      </Tooltip>
    </>
  );
};

export default ScheduleModal;
