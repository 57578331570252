import { useMemo } from 'react';
import { CaretRight, Layout, Question } from '@phosphor-icons/react';

import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

import { Text } from '../../../../UI/Text';
import InsertTemplateModal from '../../../Templates/InsertTemplateModal';
import { AttributeSettingProps } from '../../types';
import { Disabled } from '../ui/Disabled';

export const TemplateSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { templateType } = useMemo(() => {
    if (!activeNodeResult?.activeNodeType) return { node: null, templateType: 'block' };

    let type = 'block';
    if (activeNodeResult.activeNodeType === 'doc') {
      type = 'page';
    } else if (activeNodeResult.activeNodeType === 'section') {
      type = 'section';
    } else if (['container', 'column', 'columns'].includes(activeNodeResult.activeNodeType)) {
      type = 'invalid';
    } else {
      type = 'block';
    }

    return {
      templateType: type,
    };
  }, [activeNodeResult?.activeNodeType]);

  const isDisabled = templateType === 'invalid';

  const insertTemplateModal = (
    <InsertTemplateModal
      key={`template-settings-${templateType}`}
      shouldReplaceNode
      type={templateType as 'block' | 'section' | 'page'}
      activeNodeResult={activeNodeResult}
      onlyAllowNodeCategory
      actionButton={
        <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
          <div className="w-full justify-between flex items-center gap-2 p-2 ">
            <div className="flex items-center gap-1">
              <Layout className="text-wb-secondary" weight="bold" />
              <Text size="2xs" weight="medium">
                Template
              </Text>
            </div>
            <CaretRight className="text-wb-secondary" weight="bold" />
          </div>
        </div>
      }
      editor={editor}
    />
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
        <Text variant="secondary" size="2xs" weight="medium">
          Templates
        </Text>
        {isDisabled && (
          <Tooltip placement="bottom-end" center="Only Section & Block templates are supported.">
            <Question size={16} weight="bold" className="text-wb-secondary" />
          </Tooltip>
        )}
      </div>

      {isDisabled ? <Disabled>{insertTemplateModal}</Disabled> : insertTemplateModal}
    </div>
  );
};
