import { Dispatch, forwardRef, SetStateAction } from 'react';
import { CaretLeft, CaretRight, Cube, Icon as PhosphorIcon, Layout } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { Text } from '../../UI/Text';
import { Tooltip } from '../../UI/Tooltip';
import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';
import InsertTemplateModal from '../Templates/InsertTemplateModal';

import { Draggable } from './components/Draggable';
import { components, LABELS_BY_CATEGORY } from './constants';
import { ComponentKey } from './types';

const Item = forwardRef<HTMLDivElement, { id: ComponentKey }>(({ id, ...props }, ref) => {
  const IconComp = components[id].Icon;
  const component: any = components[id];

  return (
    <Tooltip center={component?.tooltip || component.label} placement="bottom">
      <div ref={ref} {...props}>
        <div className="w-12 h-12 rounded-lg bg-wb-background flex items-center justify-center border border-wb-primary shadow-sm">
          <IconComp size={18} weight="bold" />
        </div>

        <Text
          as="p"
          weight="medium"
          variant="secondary"
          className="text-xs text-center text-ellipsis overflow-hidden whitespace-nowrap w-12 py-1"
        >
          {components[id].label}
        </Text>
      </div>
    </Tooltip>
  );
});

const CategorySection = ({ title, category, editor }: { title: string; category: string; editor: Editor }) => (
  <div className="flex flex-col gap-2">
    <Text size="xs" variant="secondary" weight="semibold">
      {title}
    </Text>
    <div className="flex gap-2 flex-wrap">
      {Object.keys(components)
        .filter((key) => components[key as ComponentKey].category === category)
        .map((key) => {
          const component: any = components[key as ComponentKey];

          // For the HTML component that's coming soon
          if (component?.draggable === false) {
            return <Item id={key as ComponentKey} />;
          }

          return (
            <Draggable key={key} id={key as ComponentKey} editor={editor}>
              <Item id={key as ComponentKey} />
            </Draggable>
          );
        })}
    </div>
  </div>
);

const PublicationItem = ({
  icon: Icon,
  label,
  onClick,
}: {
  icon: PhosphorIcon;
  label: string;
  onClick: () => void;
}) => (
  <button
    type="button"
    className="flex items-center gap-2 w-full justify-between px-2 py-3 hover:bg-wb-secondary rounded-lg"
    onClick={onClick}
  >
    <div className="flex items-center gap-2">
      <div className="shadow w-6 h-6 rounded-md bg-wb-background border border-wb-primary border-solid flex items-center justify-center">
        <Icon size={16} weight="duotone" />
      </div>
      <Text size="xs" weight="medium">
        {label}
      </Text>
    </div>
    <CaretRight size={12} weight="bold" className="text-wb-secondary" />
  </button>
);

export const InsertPanel = ({
  editor,
  allowedCategories = ['templates', 'layout', 'widget', 'text', 'lists', 'media'],
  setIsInsertPanelOpen,
}: {
  editor: Editor;
  allowedCategories?: string[];
  setIsInsertPanelOpen?: Dispatch<SetStateAction<boolean>>;
}) => {
  const activeNodeResult = useActiveNode(editor);

  return (
    <div className="flex flex-col p-4 space-y-4">
      {setIsInsertPanelOpen && (
        <button
          type="button"
          onClick={() => {
            setIsInsertPanelOpen(false);
          }}
          className="flex items-center gap-1 text-wb-primary hover:text-wb-primary-dark hover:opacity-80"
        >
          <CaretLeft size={12} weight="bold" />
          <Text as="p" weight="medium" variant="primary" className="text-sm text-center text-ellipsis">
            Back
          </Text>
        </button>
      )}
      <div className="flex flex-col space-y-8">
        {allowedCategories.map((category) => {
          switch (category) {
            case 'templates':
              return (
                <>
                  <div>
                    <Text size="xs" variant="secondary" weight="semibold">
                      Templates
                    </Text>
                    <InsertTemplateModal
                      key="insert-panel-sections"
                      type="section"
                      activeNodeResult={activeNodeResult}
                      actionButton={<PublicationItem icon={Layout} label="Sections" onClick={() => {}} />}
                      editor={editor}
                    />
                    <InsertTemplateModal
                      key="insert-panel-blocks"
                      type="block"
                      activeNodeResult={activeNodeResult}
                      actionButton={<PublicationItem icon={Cube} label="Blocks" onClick={() => {}} />}
                      editor={editor}
                    />
                  </div>
                  <hr />
                </>
              );
            case 'layout':
            case 'widget':
            case 'text':
            case 'lists':
            case 'media':
              return <CategorySection title={LABELS_BY_CATEGORY[category]} category={category} editor={editor} />;
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};
