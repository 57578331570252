import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

type Props = PropsWithChildren<{
  rhsColumn: ReactNode;
  useAlternateWidth?: boolean;
}>;

const TwoColumnPageContainer = ({ children, rhsColumn, useAlternateWidth = false }: Props) => (
  <div className="w-full flex flex-col gap-y-6 lg:flex-row sm:gap-x-6 lg:justify-center mx-6 my-6 sm:mx-0 sm:px-6">
    <div
      className={classNames(
        'flex flex-col gap-y-6 w-full',
        useAlternateWidth ? 'lg:max-w-[546px]' : 'lg:max-w-[592px]'
      )}
    >
      {children}
    </div>
    <div className={classNames('w-full', useAlternateWidth ? 'lg:max-w-[432px]' : 'lg:max-w-[306px]')}>{rhsColumn}</div>
  </div>
);

export default TwoColumnPageContainer;
