import { Extension } from '@tiptap/core';

import '@tiptap/extension-text-style';

interface TokensOptions {
  nodesToAddTokensAttributeTo: string[];
  marksToAddTokensAttributeTo: string[];
}

interface TokensStorage {}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    tokens: {
      /**
       * Set the text color
       * @param tokens The tokens to set
       * @example editor.commands.setTokens({ backgroundColor: 'primary_color' })
       */
      setTokens: (tokens: Record<string, string>) => ReturnType;
    };
  }
}

export const Tokens = Extension.create<TokensOptions, TokensStorage>({
  name: 'tokens',

  addOptions() {
    return {
      nodesToAddTokensAttributeTo: [
        'heading',
        'paragraph',
        'section',
        'signup',
        'socials',
        'button',
        'testimonials',
        'image',
        'post',
        'login',
        'container',
        'columns',
        'divider',
        'survey',
        'recommendations',
        'pricing',
        'authors',
        'socials',
      ],
      marksToAddTokensAttributeTo: ['textStyle'],
    };
  },

  addCommands() {
    return {
      setTokens:
        (tokens: Record<string, string>) =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { ...tokens })
            .run();
        },
    };
  },

  addGlobalAttributes() {
    const allTypesToAddTokenAttributeTo = [
      ...this.options.nodesToAddTokensAttributeTo,
      ...this.options.marksToAddTokensAttributeTo,
    ];

    return [
      {
        types: allTypesToAddTokenAttributeTo,
        attributes: {
          tokens: {
            default: {},
            renderHTML: (attributes) => ({ 'data-tokens': JSON.stringify(attributes.tokens) }),
            parseHTML: (element) => JSON.parse(element.getAttribute('data-tokens') || '{}') || {},
          },
        },
      },
    ];
  },
});
