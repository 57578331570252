import { Editor, isNodeSelection } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';
import { AllSelection } from '@tiptap/pm/state';

import { HiddenAllSelection, MultiNodeSelection } from '../../CustomSelections';

export const getActiveNodeData = (editor: Editor) => {
  const { selection, doc } = editor.state;

  let activeNodeType = '';
  let activeNodePos = -1;
  let activeNodeAttributes: Record<string, any> = {};
  let activeNode: PMNode | null = null;
  let activeNodes: PMNode[] = [];

  if (isNodeSelection(selection)) {
    activeNode = selection.node;
    activeNodeType = activeNode.type.name;
    activeNodeAttributes = activeNode.attrs;
    activeNodePos = selection.$anchor.pos;
  } else if (selection instanceof MultiNodeSelection) {
    activeNodes = selection.nodes;

    [activeNode] = selection.nodes;
    [activeNodePos] = selection.posList;
    activeNodeType = activeNode.type.name;
    activeNodeAttributes = activeNode.attrs;
  } else if (selection instanceof AllSelection || selection instanceof HiddenAllSelection) {
    activeNode = doc;
    activeNodeType = doc.type.name;
    activeNodeAttributes = doc.attrs;
    activeNodePos = 0;
  } else {
    // leaf node, select the parent
    const parentNode = selection.$anchor.parent;
    activeNode = parentNode;
    activeNodeType = parentNode.type.name;
    activeNodeAttributes = parentNode.attrs;
    activeNodePos = selection.$head.start(selection.$head.depth) - 1;
  }

  return {
    activeNode,
    activeNodeType,
    activeNodePos,
    activeNodeEnd: activeNodePos + activeNode.nodeSize,
    activeNodeAttributes,
    selection,

    activeNodes,
  };
};
