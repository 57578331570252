import { useCallback, useMemo } from 'react';
import { CaretDoubleRight } from '@phosphor-icons/react';

import { useAutomations } from '@/hooks/useAutomations';

import { PopoverHelper, PopoverItem } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';

type Props = AttributeSettingProps & {};

export const AutomationSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const { data: automationsData } = useAutomations({
    shouldFetchAll: true,
    onlyActive: true,
  });

  const automationOptions = useMemo(() => {
    const automations = automationsData?.pages.flatMap((page) => page.automations) || [];
    return [
      { label: 'None', value: '' },
      ...automations.map((automation) => ({
        label: automation.name,
        value: automation.id,
      })),
    ];
  }, [automationsData?.pages]);

  const textValue = useMemo(() => {
    let label = 'None';

    if (!activeNodeAttributes.automationIds) {
      return label;
    }

    automationOptions.some((option) => {
      if (option.value === activeNodeAttributes.automationIds) {
        label = option.label;
        return true;
      }
      return false;
    });

    return label;
  }, [activeNodeAttributes.automationIds, automationOptions]);

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodeResult) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'automationIds', value);
        return true;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, activeNodePos]
  );

  return (
    <PopoverHelper title="Automation" text={textValue} popoverTitle="Automations" Icon={CaretDoubleRight}>
      <div className="flex flex-col gap-2">
        {automationOptions.map((option) => (
          <PopoverItem
            key={option.value}
            value={option.label}
            onSelect={() => handleUpdate(option.value)}
            isSelected={option.value === activeNodeAttributes.automationIds}
          />
        ))}
      </div>
    </PopoverHelper>
  );
};
