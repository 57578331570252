import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostAgreementAutoPauseConfig } from '@/interfaces/boosts/grow/boost_agreement_auto_pause_config';
import api from '@/services/swarm';

interface Props {
  isEnabled?: boolean;
}

const useBoostAgreemenAutoPauseConfig = ({ isEnabled = true }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostAgreementAutoPauseConfig = (): Promise<BoostAgreementAutoPauseConfig> =>
    api
      .get(`/boosts/grow/boost_agreement_auto_pause_config`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, 'boosts', 'grow', 'boost_agreement_auto_pause_config'],
    fetchBoostAgreementAutoPauseConfig,
    {
      enabled: isEnabled,
      retry: false,
    }
  );
};

export default useBoostAgreemenAutoPauseConfig;
