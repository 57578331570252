import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import shuffle from 'lodash.shuffle';

import { Checkbox, Input } from '@/components/Form';
import { useCurrentUser } from '@/context/current-user-context';
import { useOnboarding } from '@/hooks';
import useUpdateOnboarding from '@/hooks/useOnboarding/useUpdateOnboarding';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { OnboardingAttributionSource } from '@/interfaces/onboarding';
import analytics from '@/utils/analytics';

import { Layout, LeftPane, RightPane } from '../_components/Layout';
import OnboardingStepForm from '../_components/OnboardingStepForm';

const ATTRIBUTION_OPTIONS = [
  {
    name: 'Affiliate Partner',
    value: OnboardingAttributionSource.AFFILIATE_PARTNER,
  },
  {
    name: 'Agency Partner',
    value: OnboardingAttributionSource.AGENCY_PARTNER,
  },
  {
    name: 'Podcast',
    value: OnboardingAttributionSource.PODCAST,
  },
  {
    name: 'Banner Ad',
    value: OnboardingAttributionSource.BANNER_AD,
  },
  {
    name: 'Reddit',
    value: OnboardingAttributionSource.REDDIT,
  },
  {
    name: 'Billboard',
    value: OnboardingAttributionSource.BILLBOARD,
  },
  {
    name: 'Spotify/Audio Ad',
    value: OnboardingAttributionSource.AUDIO_AD,
  },
  {
    name: 'Conference',
    value: OnboardingAttributionSource.CONFERENCE,
  },
  {
    name: 'TikTok',
    value: OnboardingAttributionSource.TIKTOK,
  },
  {
    name: 'Search Engine',
    value: OnboardingAttributionSource.SEARCH_ENGINE,
  },
  {
    name: 'Twitter/X',
    value: OnboardingAttributionSource.TWITTER,
  },
  {
    name: 'Influencer',
    value: OnboardingAttributionSource.INFLUENCER,
  },
  {
    name: 'Word-of-Mouth',
    value: OnboardingAttributionSource.WORD_OF_MOUTH,
  },
  {
    name: 'LinkedIn',
    value: OnboardingAttributionSource.LINKEDIN,
  },
  {
    name: 'YouTube',
    value: OnboardingAttributionSource.YOUTUBE,
  },
  {
    name: 'News/Press',
    value: OnboardingAttributionSource.NEWS_PRESS,
  },
  {
    name: 'Webinar/Virtual Event',
    value: OnboardingAttributionSource.VIRTUAL_EVENT,
  },
];

const OnboardingHowDidYouHearAboutUs = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const publicationId = useCurrentPublicationId();
  const { data: currentPublication } = usePublication(publicationId);
  const { data: onboarding } = useOnboarding(publicationId, currentPublication?.onboarding_id);

  const [attributionSources, setAttributionSources] = useState<OnboardingAttributionSource[] | string[]>([]);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [shuffledOptions] = useState(() => shuffle(ATTRIBUTION_OPTIONS));
  const [attributionOther, setAttributionOther] = useState('');

  useEffect(() => {
    if (onboarding) {
      setAttributionSources(onboarding.attribution_sources);
      const otherSource = onboarding.attribution_sources.find(
        (source) => !Object.values(OnboardingAttributionSource).includes(source)
      );
      if (otherSource) {
        setAttributionOther(otherSource);
      }
    }
  }, [onboarding]);

  useEffect(() => {
    setIsSubmitButtonDisabled(attributionSources.length === 0);
  }, [attributionSources]);

  const onboardingMutation = useUpdateOnboarding({});

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payloadSources = attributionSources.filter((source) =>
      Object.values(OnboardingAttributionSource).includes(source as OnboardingAttributionSource)
    );
    const otherSelected = attributionSources.includes(OnboardingAttributionSource.OTHER);
    let mutationPayload;
    if (attributionOther !== '' && otherSelected) {
      mutationPayload = { attribution_sources: [...payloadSources, attributionOther] };
    } else {
      mutationPayload = { attribution_sources: payloadSources };
    }
    onboardingMutation.mutate(mutationPayload, {
      onSuccess: () => {
        if (currentUser?.id) {
          analytics.identify(currentUser?.id, {
            userSource: payloadSources,
            other: otherSelected ? attributionOther : '',
            email: currentUser?.email,
          });
        }

        analytics.track('Attribution Survey', {
          userSource: payloadSources,
          other: otherSelected ? attributionOther : '',
          email: currentUser?.email,
        });

        navigate('/onboarding/primary_goals');
      },
      onError: () => {
        toast.error('Something went wrong, please try again');
      },
    });
  };

  const handleAttributionSourceChange = (value: OnboardingAttributionSource) => {
    if (attributionSources.includes(value)) {
      setAttributionSources(attributionSources.filter((source) => source !== value));
    } else {
      setAttributionSources([...attributionSources, value]);
    }
  };

  return (
    <>
      <Helmet>
        <title>How did you hear about us - beehiiv</title>
      </Helmet>

      <Layout>
        <LeftPane>
          <OnboardingStepForm
            title="How did you hear about us?"
            subtitle="This info helps us figure out where to focus our outreach to creators."
            onBack={() => navigate('/onboarding/publication_details')}
            onSubmit={onSubmit}
            onSkip={() => navigate('/onboarding/primary_goals')}
            isSubmitDisabled={isSubmitButtonDisabled}
            isProcessing={onboardingMutation.isLoading}
            showBea
            currentProgress={50}
            formClassName="p-8 border rounded-lg border-gray-200 bg-surface-50"
          >
            <div className="grid grid-cols-2 gap-x-8 gap-y-2 space-x-0">
              {shuffledOptions.map((option) => (
                <Checkbox
                  key={option.value}
                  name={option.value}
                  labelText={option.name}
                  size="lg"
                  color="secondary"
                  checked={attributionSources.includes(option.value)}
                  onChange={() => handleAttributionSourceChange(option.value)}
                />
              ))}
              <Checkbox
                key="other"
                name="other"
                labelText="Other"
                size="lg"
                color="secondary"
                checked={attributionSources.includes(OnboardingAttributionSource.OTHER)}
                onChange={() => handleAttributionSourceChange(OnboardingAttributionSource.OTHER)}
              />
              {attributionSources.includes(OnboardingAttributionSource.OTHER) && <span />}
              {attributionSources.includes(OnboardingAttributionSource.OTHER) && (
                <Input
                  name="value"
                  className=""
                  value={attributionOther}
                  placeholder="Please specify how you hear about us"
                  onChange={(event) => {
                    setAttributionOther(event.target.value);
                  }}
                />
              )}
            </div>
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingHowDidYouHearAboutUs;
