import { useCurrentTimeZone } from '@/hooks';
import { Wallet } from '@/hooks/boosts/useWallet';
import useTotalBoostsExpenses from '@/hooks/wallet/useTotalBoostsExpenses';
import useTotalBoostsExpensesSnapshots from '@/hooks/wallet/useTotalBoostsExpensesSnapshots';
import useTotalEarnings from '@/hooks/wallet/useTotalEarnings';
import useTotalEarningsSnapshots from '@/hooks/wallet/useTotalEarningsSnapshots';
import { Dropdown } from '@/ui/Dropdown';
import Stat from '@/ui/Stat';
import { timePeriodsButtonOptions } from '@/utils/timePeriods';

interface Props {
  walletData?: Wallet;
  setChartTimePeriod: (timePeriod: string) => void;
  setChartFilterType: (filterType: string) => void;
  chartTimePeriod?: string;
  chartFilterType?: string;
}

const Stats = ({ walletData, chartTimePeriod, setChartTimePeriod, chartFilterType, setChartFilterType }: Props) => {
  const timePeriodOptions = timePeriodsButtonOptions.map((timePeriod) => {
    return {
      label: timePeriod.label,
      value: timePeriod.id,
    };
  });

  const timeZone = useCurrentTimeZone();

  const totalExpensesQuery = useTotalBoostsExpensesSnapshots({
    timePeriod: chartTimePeriod,
    timeZone,
  });

  const snapshotsQuery = useTotalEarningsSnapshots({
    type: chartFilterType,
    timePeriod: chartTimePeriod,
    timeZone,
  });

  const earningsQuery = useTotalEarnings({
    timePeriod: chartTimePeriod,
  });

  const boostsExpensesQuery = useTotalBoostsExpenses({
    timePeriod: chartTimePeriod,
  });

  const totalEarningsChartData = snapshotsQuery.data?.data.result.data;
  const totalExpensesChartData = totalExpensesQuery.data?.data.result.data;

  const onTotalEarningsPeriodChange = (name: string, value: string) => {
    setChartTimePeriod(value);
  };

  const onTotalEarningsFilterTypeChange = (name: string, value: string) => {
    setChartFilterType(value);
  };

  const onTotalExpensesPeriodChange = (name: string, value: string) => {
    setChartTimePeriod(value);
  };

  let boostsExpenses = boostsExpensesQuery.data?.total_boosts_expenses || 0;
  if (boostsExpenses < 0) {
    boostsExpenses *= -1;
  }

  let totalEarnings = earningsQuery.data?.total_earnings_cents || 0;

  if (chartFilterType === 'ads') {
    totalEarnings = earningsQuery.data?.ads_earnings_cents || 0;
  }

  if (chartFilterType === 'boosts') {
    totalEarnings = earningsQuery.data?.boosts_earnings_cents || 0;
  }

  totalEarnings /= 100;

  let earningsSnapshots = totalEarningsChartData?.map((snapshot: any) => {
    return {
      date: snapshot.name,
      earnings: snapshot.earnings,
    };
  });

  let formattedExpenseSnapshots = totalExpensesChartData?.map((snapshot: any) => {
    let formattedExpenses = snapshot.expenses;
    if (formattedExpenses < 0) {
      formattedExpenses *= -1;
    }

    return {
      date: snapshot.name,
      expenses: formattedExpenses,
      formattedValue: formattedExpenses.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    };
  });

  if (!totalEarningsChartData || !earningsSnapshots.length) {
    earningsSnapshots = [
      {
        date: '',
        earnings: 0,
      },
      {
        date: '',
        earnings: 0,
      },
    ];
  }

  if (!totalExpensesChartData || !formattedExpenseSnapshots.length) {
    formattedExpenseSnapshots = [
      {
        date: '',
        expenses: 0,
      },
      {
        date: '',
        expenses: 0,
      },
    ];
  }

  return (
    <div className="flex flex-col md:flex-row w-full gap-2 mt-6 justify-between items-stretch md:h-48 min-h-48">
      <div className="w-full md:w-1/2 h-full">
        <Stat
          subHeader="Total earnings"
          title={
            totalEarnings.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }) || '$0.00'
          }
          listItems={[
            ...(chartFilterType !== 'boosts'
              ? [
                  {
                    title: 'Ad Network',
                    value: ((earningsQuery.data?.ads_earnings_cents || 0) / 100).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                  },
                ]
              : []),
            ...(chartFilterType !== 'ads'
              ? [
                  {
                    title: 'Boosts',
                    value: ((earningsQuery.data?.boosts_earnings_cents || 0) / 100).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                  },
                ]
              : []),
          ]}
          chartData={earningsSnapshots}
          chartIndex="date"
          chartColors={['green']}
          chartCategories={['earnings']}
          chartTitle="Total earnings"
          chartValueFormatter={(value: number) => {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          }}
          filters={[
            <Dropdown
              className="min-w-36"
              key="transaction_sort_type_earnings"
              name="transaction_sort_type"
              options={[
                { label: 'All Transactions', value: 'all' },
                { label: 'Ad Network', value: 'ads' },
                { label: 'Boosts', value: 'boosts' },
              ]}
              onSelect={onTotalEarningsFilterTypeChange}
              value={chartFilterType}
            />,
            <Dropdown
              className="min-w-36"
              name="earnings_transaction_period"
              options={timePeriodOptions}
              onSelect={onTotalEarningsPeriodChange}
              value={chartTimePeriod}
            />,
          ]}
        />
      </div>
      <div className="w-full md:w-1/2 h-full">
        <Stat
          chartData={formattedExpenseSnapshots}
          chartColors={['pink']}
          subHeader="Total expenses"
          title={(typeof walletData?.total_boosts_expenses_cents === 'number'
            ? (!!formattedExpenseSnapshots?.length &&
                formattedExpenseSnapshots[formattedExpenseSnapshots.length - 1].expenses) ||
              0
            : 0
          ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          description="Spent on Boosts"
          chartIndex="date"
          chartTitle="Total expenses"
          chartCategories={['expenses']}
          chartValueFormatter={(value: number) => {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          }}
          filters={[
            <Dropdown
              className="min-w-36"
              name="expenses_transaction_period"
              options={timePeriodOptions}
              onSelect={onTotalExpensesPeriodChange}
              value={chartTimePeriod}
            />,
          ]}
        />
      </div>
    </div>
  );
};

export default Stats;
