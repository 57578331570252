import { useMemo } from 'react';

import { useSettings } from '@/context/settings-context';
import useSubscriberRevenueByPeriod from '@/routes/monetize/subscriptions/growth/_hooks/useSubscriberRevenueByPeriod';
import { currencyFormatter, TimePeriod } from '@/utils';

import { usePremiumSettings } from '../usePublications';

const useTotalSubscriptionRevenuePerPeriod = (period: TimePeriod) => {
  // Data returned has revenue broken out over the selected period so we must sum it
  const { data, isLoading, isError } = useSubscriberRevenueByPeriod(period);
  const { data: premiumSettings } = usePremiumSettings();
  const { stripe_account_currency: currency = 'USD' } = premiumSettings || {};
  const { settings } = useSettings();

  const totalRevenue = useMemo(
    () =>
      data?.chart_data ? data?.chart_data.reduce((curr, chartData) => curr + parseFloat(chartData.Revenue), 0) : 0,
    [data?.chart_data]
  );

  // Since subscription revenue data can only be broken down monthly, we cannot display meaningful data for the more granual periods
  const invalidPeriod = [TimePeriod.LAST_24_HOURS, TimePeriod.LAST_4_WEEKS, TimePeriod.LAST_7_DAYS].includes(period);

  const revenueString =
    invalidPeriod || !settings?.premium_subscriptions ? '-' : currencyFormatter(totalRevenue, currency);

  return {
    data: revenueString,
    isLoading,
    isError,
  };
};

export default useTotalSubscriptionRevenuePerPeriod;
