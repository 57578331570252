import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { PaddingSettings } from '../PaddingSettings';

const AccordionContentSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['accordionContent']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Layout">
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
      </SectionRenderer>
    </RenderCondition>
  );
};

export { AccordionContentSettings };
