import {
  ArrowDownOnSquareIcon,
  ListBulletIcon,
  NewspaperIcon,
  PencilSquareIcon,
  RocketLaunchIcon,
  WindowIcon,
} from '@heroicons/react/24/outline';

import { useOnboarding } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import ChecklistItem from './ChecklistItem';

const Checklist = () => {
  const publicationId = useCurrentPublicationId();
  const { data: publication } = usePublication(publicationId);
  const { data: onboarding } = useOnboarding(publicationId, publication?.onboarding_id);

  const ONBOARDING_STEPS = [
    {
      id: 'start_publication',
      title: 'Start a beehiiv publication',
      icon: <RocketLaunchIcon className="w-6 h-6 text-gray-500" />,
      completed: onboarding?.start_publication_completed,
      url: '/',
    },
    {
      id: 'finish_publication_settings',
      title: 'Finish your publication settings',
      icon: <ListBulletIcon className="w-6 h-6 text-gray-500" />,
      completed: onboarding?.finish_publication_settings_completed,
      url: '/',
    },
    {
      id: 'import_prior_publication',
      title: 'Import prior publication',
      icon: <ArrowDownOnSquareIcon className="w-6 h-6 text-gray-500" />,
      completed: onboarding?.import_prior_publication_completed,
      url: '/',
    },
    {
      id: 'customize_newsletter',
      title: 'Customize your newsletter',
      icon: <NewspaperIcon className="w-6 h-6 text-gray-500" />,
      completed: onboarding?.customize_newsletter_completed,
      url: '/',
    },
    {
      id: 'explore_website_builder',
      title: 'Explore the website builder',
      icon: <WindowIcon className="w-6 h-6 text-gray-500" />,
      completed: onboarding?.explore_website_builder_completed,
      url: '/',
    },
    {
      id: 'discover_editor',
      title: 'Discover the editor',
      icon: <PencilSquareIcon className="w-6 h-6 text-gray-500" />,
      completed: onboarding?.discover_editor_completed,
      url: '/',
    },
  ];

  return (
    <ul className="flex flex-col gap-2 mt-10">
      {ONBOARDING_STEPS.map((step) => {
        return <ChecklistItem step={step} />;
      })}
    </ul>
  );
};

export default Checklist;
