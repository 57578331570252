import { MagnifyingGlass } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { SliderSettings } from '../SliderSettings';

type Props = AttributeSettingProps & {};

export const SearchPostSetting = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <PopoverHelper title="Search" text="Search" popoverTitle="Search" Icon={MagnifyingGlass} align="end">
      <>
        <BooleanSettings editor={editor} activeNodeResult={activeNodeResult} property="inputEnabled" title="Enabled" />
        {activeNodeAttributes.inputEnabled && (
          <>
            <AlignmentSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="inputAlignment"
              title="Alignment"
            />
            <SliderSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="inputMaxWidth"
              title="Width"
              unit="px"
              max={1280}
            />
            <SliderSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="inputMaxHeight"
              title="Height"
              unit="px"
              max={600}
              min={14}
            />
            <BorderSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              properties={{
                color: 'inputBorderColor',
                style: 'inputBorderStyle',
                width: 'inputBorderWidth',
                radius: 'inputBorderRadius',
              }}
            />
            <ColorSettings
              title="Background"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="inputBackgroundColor"
            />
            <ColorSettings
              title="Placeholder"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="inputPlaceholderColor"
            />
            <ColorSettings
              title="Value"
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="inputTextColor"
            />
            <ColorSettings title="Icon" editor={editor} activeNodeResult={activeNodeResult} property="inputIconFill" />
          </>
        )}
      </>
    </PopoverHelper>
  );
};

export default SearchPostSetting;
