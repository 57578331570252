/* eslint-disable consistent-return */
import { Editor, isNodeSelection } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';
import { AllSelection, NodeSelection, Plugin, PluginKey, Selection } from '@tiptap/pm/state';

import { CONTAINER_NODES, OPEN_INSERT_PANEL_IDENTIFIER } from '../../../constants';
import { modKey } from '../../utils';

export const DrilldownSelectorPluginKey = new PluginKey('drilldownSelectorPlugin');

export const skipDrilldownSelector = (event: MouseEvent) => {
  let target = event.target as HTMLElement;

  while (target && !target.classList.contains('ProseMirror')) {
    if (target.hasAttribute('data-skip-drilldown-selector')) {
      return true;
    }

    target = target.parentElement!;
  }

  return false;
};

export const getDrilldownSelectorPlugin = (editor: Editor) => {
  const setSelection = (selection: Selection) => {
    editor
      .chain()
      .command(({ tr }) => {
        tr.setSelection(selection);
        tr.setMeta('drillDownSelectionSet', true);

        return true;
      })
      .command(({ tr }) => {
        if (
          isNodeSelection(selection) &&
          CONTAINER_NODES.includes(selection.node.type.name) &&
          selection.node.content.size === 0
        ) {
          tr.setMeta(OPEN_INSERT_PANEL_IDENTIFIER, true);
        }

        return true;
      })
      .focus()
      .run();
  };

  return new Plugin({
    key: DrilldownSelectorPluginKey,

    props: {
      handleDOMEvents: {
        mousedown(view, event) {
          if (event[modKey]) {
            return;
          }

          const posDetails = view.posAtCoords({ left: event.clientX, top: event.clientY });

          if (!posDetails) {
            return;
          }

          const { inside } = posDetails;

          if (inside < 0) {
            return;
          }

          const { selection, doc } = view.state;
          const $inside = doc.resolve(inside);

          if (selection instanceof NodeSelection) {
            if (selection.node.isTextblock) {
              return;
            }

            if (selection.$from.parent.eq($inside.parent)) {
              setSelection(NodeSelection.create(doc, inside));
              event.preventDefault();

              return;
            }

            if (!skipDrilldownSelector(event)) event.preventDefault();
          }
        },
        dblclick(view, event) {
          if (event[modKey]) {
            return;
          }

          const posDetails = view.posAtCoords({ left: event.clientX, top: event.clientY });

          if (!posDetails) {
            return;
          }

          const { inside } = posDetails;

          if (inside < 0) {
            return;
          }

          const { selection, doc } = view.state;

          const $inside = doc.resolve(inside);
          const $insidePath = ($inside as any).path as (PMNode | number)[];
          const clickedNode = doc.nodeAt(inside)!;

          if (selection instanceof AllSelection) {
            const firstBefore = $inside.before(1);

            if (firstBefore < 0) {
              return;
            }

            event.preventDefault();
            setSelection(NodeSelection.create(doc, firstBefore));

            return;
          }

          if (selection instanceof NodeSelection) {
            if (selection.node.eq(clickedNode)) {
              if (selection.node.isTextblock) {
                return;
              }

              event.preventDefault();
              return;
            }

            if (selection.$from.sameParent($inside)) {
              event.preventDefault();
              setSelection(NodeSelection.create(doc, inside));

              return;
            }

            let isDescendant = false;

            selection.node.descendants((desc) => {
              if (isDescendant) return false;

              if (desc.eq(clickedNode)) isDescendant = true;
            });

            if (!isDescendant) {
              let firstBefore;

              try {
                firstBefore = $inside.before(selection.$from.sharedDepth(inside));
              } catch {
                firstBefore = $inside.before(1);
              }

              if (firstBefore < 0) {
                return;
              }

              event.preventDefault();
              setSelection(NodeSelection.create(doc, firstBefore));

              return;
            }

            const selectedNodeDepth = selection.$from.depth;
            const clickedNodeDepth = $inside.depth;

            if (clickedNodeDepth <= selectedNodeDepth) {
              return;
            }

            const indexInResolvedPosPath = $insidePath.findIndex((pathNode) => {
              return typeof pathNode !== 'number' && pathNode.eq(selection.node);
            });

            const nextAncestorPos =
              indexInResolvedPosPath < $insidePath.length
                ? ($insidePath[indexInResolvedPosPath + 2] as number)
                : $inside.start(selectedNodeDepth + 1);

            if (nextAncestorPos < 0) {
              return;
            }

            event.preventDefault();
            setSelection(NodeSelection.create(doc, nextAncestorPos));
          }
        },
      },
    },
  });
};
