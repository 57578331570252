import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import shuffle from 'lodash.shuffle';

import { Checkbox } from '@/components/Form';
import { useCurrentUser } from '@/context/current-user-context';
import { useOnboarding } from '@/hooks';
import useUpdateOnboarding from '@/hooks/useOnboarding/useUpdateOnboarding';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { OnboardingGoals } from '@/interfaces/onboarding';
import analytics from '@/utils/analytics';

import { Layout, LeftPane, RightPane } from '../_components/Layout';
import OnboardingStepForm from '../_components/OnboardingStepForm';

const PRIMARY_GOAL_OPTIONS = [
  {
    name: 'Become a trusted source of news',
    value: OnboardingGoals.BECOME_TRUSTED_SOURCE_OF_NEWS,
  },
  {
    name: 'Generate sustainable income',
    value: OnboardingGoals.GENERATE_SUSTAINABLE_INCOME,
  },
  {
    name: 'Grow my personal or professional brand',
    value: OnboardingGoals.BUILD_PERSONAL_BRAND,
  },
  {
    name: 'Influence public opinion and drive change',
    value: OnboardingGoals.INFLUENCE_PUBLIC_OPINION,
  },
  {
    name: 'Builder deeper connections within my community',
    value: OnboardingGoals.BUILD_DEEPER_CONECTIONS_WITH_MY_COMMUNITY,
  },
  {
    name: 'Keep stakeholders informed',
    value: OnboardingGoals.KEEP_STAKEHOLDERS_INFORMED,
  },
  {
    name: 'Nurture prospective customers',
    value: OnboardingGoals.NURTURE_LEADS,
  },
  {
    name: 'Rely less on social media to reach my audience',
    value: OnboardingGoals.RELY_LESS_ON_SOCIAL_MEDIA,
  },
  {
    name: 'Explore a hobby or personal interest',
    value: OnboardingGoals.EXPLORE_HOBBY_OR_INTEREST,
  },
];

const OnboardingPrimaryGoals = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const publicationId = useCurrentPublicationId();
  const { data: currentPublication } = usePublication(publicationId);
  const { data: onboarding } = useOnboarding(publicationId, currentPublication?.onboarding_id);

  const [primaryGoals, setPrimaryGoals] = useState<OnboardingGoals[] | string[]>([]);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [shuffledOptions] = useState(() => shuffle(PRIMARY_GOAL_OPTIONS));

  useEffect(() => {
    if (onboarding) {
      setPrimaryGoals(onboarding.goals);
    }
  }, [onboarding]);

  useEffect(() => {
    setIsSubmitButtonDisabled(primaryGoals.length === 0);
  }, [primaryGoals]);

  const onboardingMutation = useUpdateOnboarding({});

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payloadGoals = primaryGoals.filter((goal) =>
      Object.values(OnboardingGoals).includes(goal as OnboardingGoals)
    );
    const mutationPayload = { goals: payloadGoals };

    onboardingMutation.mutate(mutationPayload, {
      onSuccess: () => {
        analytics.identify(currentUser?.id, {
          goals: primaryGoals,
        });
        analytics.track('Selected Primary Goal', {
          goals: primaryGoals,
        });

        navigate('/onboarding/plan_confirmation');
      },
      onError: () => {
        toast.error('Something went wrong, please try again');
      },
    });
  };

  const handlePrimaryGoalsChange = (value: OnboardingGoals) => {
    if (primaryGoals.includes(value)) {
      setPrimaryGoals(primaryGoals.filter((source) => source !== value));
    } else {
      setPrimaryGoals([...primaryGoals, value]);
    }
  };

  return (
    <>
      <Helmet>
        <title>How did you hear about us - beehiiv</title>
      </Helmet>

      <Layout>
        <LeftPane>
          <OnboardingStepForm
            title="What are the primary goals beehiiv can help you achieve?"
            subtitle="This will help me and the team share resources tailored to your specific goals!"
            onBack={() => navigate('/onboarding/publication_details')}
            onSubmit={onSubmit}
            onSkip={() => navigate('/onboarding/plan_confirmation')}
            isSubmitDisabled={isSubmitButtonDisabled}
            isProcessing={onboardingMutation.isLoading}
            showBea
            currentProgress={75}
            formClassName="p-8 border rounded-lg border-gray-200 bg-surface-50"
          >
            <div className="grid grid-cols-2 gap-x-8 gap-y-2 space-x-0">
              {shuffledOptions.map((option) => (
                <Checkbox
                  key={option.value}
                  name={option.value}
                  labelText={option.name}
                  size="lg"
                  color="secondary"
                  checked={primaryGoals.includes(option.value)}
                  onChange={() => handlePrimaryGoalsChange(option.value)}
                />
              ))}
            </div>
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingPrimaryGoals;
