import React from 'react';

interface IconProps {
  className?: string;
}

const TopLeftIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 2H7.33333C4.38781 2 2 4.38781 2 7.33333V10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const TopRightIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 10V7.33333C10 4.38781 7.61219 2 4.66667 2H2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const BottomLeftIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2V4.66667C2 7.61219 4.38781 10 7.33333 10H10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const BottomRightIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 10H4.66667C7.61219 10 10 7.61219 10 4.66667V2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const TopIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="12" height="2" rx="1" stroke="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2 4.5C1.72386 4.5 1.5 4.27614 1.5 4V3H2H2.5V4C2.5 4.27614 2.27614 4.5 2 4.5ZM2.5 6C2.5 5.72386 2.27614 5.5 2 5.5C1.72386 5.5 1.5 5.72386 1.5 6V8C1.5 8.27614 1.72386 8.5 2 8.5C2.27614 8.5 2.5 8.27614 2.5 8V6ZM2.5 10C2.5 9.72386 2.27614 9.5 2 9.5C1.72386 9.5 1.5 9.72386 1.5 10V11C1.5 11.7249 2.01417 12.3297 2.6977 12.4695C2.79534 12.4895 2.89645 12.5 3 12.5H4C4.27614 12.5 4.5 12.2761 4.5 12C4.5 11.7239 4.27614 11.5 4 11.5H3C2.72386 11.5 2.5 11.2761 2.5 11V10ZM6 11.5C5.72386 11.5 5.5 11.7239 5.5 12C5.5 12.2761 5.72386 12.5 6 12.5H8C8.27614 12.5 8.5 12.2761 8.5 12C8.5 11.7239 8.27614 11.5 8 11.5H6ZM10 11.5C9.72386 11.5 9.5 11.7239 9.5 12C9.5 12.2761 9.72386 12.5 10 12.5H11C11.8284 12.5 12.5 11.8284 12.5 11V10C12.5 9.72386 12.2761 9.5 12 9.5C11.7239 9.5 11.5 9.72386 11.5 10V11C11.5 11.2761 11.2761 11.5 11 11.5H10ZM11.5 8C11.5 8.27614 11.7239 8.5 12 8.5C12.2761 8.5 12.5 8.27614 12.5 8V6C12.5 5.72386 12.2761 5.5 12 5.5C11.7239 5.5 11.5 5.72386 11.5 6V8ZM11.5 4C11.5 4.27614 11.7239 4.5 12 4.5C12.2761 4.5 12.5 4.27614 12.5 4V3H12H11.5V4Z" fill="currentColor" />
  </svg>
);



const RightIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12" y="1" width="12" height="2" rx="1" transform="rotate(90 12 1)" stroke="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.5 2C8.5 1.72386 8.72386 1.5 9 1.5L10 1.5V2V2.5H9C8.72386 2.5 8.5 2.27614 8.5 2ZM7 2.5C7.27614 2.5 7.5 2.27614 7.5 2C7.5 1.72386 7.27614 1.5 7 1.5L5 1.5C4.72386 1.5 4.5 1.72386 4.5 2C4.5 2.27614 4.72386 2.5 5 2.5L7 2.5ZM3 2.5C3.27614 2.5 3.5 2.27614 3.5 2C3.5 1.72386 3.27614 1.5 3 1.5H2C1.27513 1.5 0.670344 2.01417 0.530475 2.6977C0.510493 2.79534 0.5 2.89645 0.5 3V4C0.5 4.27614 0.723858 4.5 1 4.5C1.27614 4.5 1.5 4.27614 1.5 4V3C1.5 2.72386 1.72386 2.5 2 2.5H3ZM1.5 6C1.5 5.72386 1.27614 5.5 1 5.5C0.723858 5.5 0.5 5.72386 0.5 6L0.5 8C0.5 8.27614 0.723858 8.5 1 8.5C1.27614 8.5 1.5 8.27614 1.5 8L1.5 6ZM1.5 10C1.5 9.72386 1.27614 9.5 1 9.5C0.723858 9.5 0.5 9.72386 0.5 10V11C0.5 11.8284 1.17157 12.5 2 12.5H3C3.27614 12.5 3.5 12.2761 3.5 12C3.5 11.7239 3.27614 11.5 3 11.5H2C1.72386 11.5 1.5 11.2761 1.5 11V10ZM5 11.5C4.72386 11.5 4.5 11.7239 4.5 12C4.5 12.2761 4.72386 12.5 5 12.5L7 12.5C7.27614 12.5 7.5 12.2761 7.5 12C7.5 11.7239 7.27614 11.5 7 11.5L5 11.5ZM9 11.5C8.72386 11.5 8.5 11.7239 8.5 12C8.5 12.2761 8.72386 12.5 9 12.5H10V12V11.5H9Z" fill="currentColor" />
  </svg>
);



const BottomIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="13" y="12" width="12" height="2" rx="1" transform="rotate(-180 13 12)" stroke="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 8.5C12.2761 8.5 12.5 8.72386 12.5 9V10H12H11.5V9C11.5 8.72386 11.7239 8.5 12 8.5ZM11.5 7C11.5 7.27614 11.7239 7.5 12 7.5C12.2761 7.5 12.5 7.27614 12.5 7V5C12.5 4.72386 12.2761 4.5 12 4.5C11.7239 4.5 11.5 4.72386 11.5 5V7ZM11.5 3C11.5 3.27614 11.7239 3.5 12 3.5C12.2761 3.5 12.5 3.27614 12.5 3L12.5 2C12.5 1.27513 11.9858 0.670344 11.3023 0.530475C11.2047 0.510493 11.1036 0.5 11 0.5H10C9.72386 0.5 9.5 0.723858 9.5 1C9.5 1.27614 9.72386 1.5 10 1.5H11C11.2761 1.5 11.5 1.72386 11.5 2L11.5 3ZM8 1.5C8.27614 1.5 8.5 1.27614 8.5 1C8.5 0.723858 8.27614 0.5 8 0.5H6C5.72386 0.5 5.5 0.723858 5.5 1C5.5 1.27614 5.72386 1.5 6 1.5H8ZM4 1.5C4.27614 1.5 4.5 1.27614 4.5 1C4.5 0.723858 4.27614 0.5 4 0.5H3C2.17157 0.5 1.5 1.17157 1.5 2L1.5 3C1.5 3.27614 1.72386 3.5 2 3.5C2.27614 3.5 2.5 3.27614 2.5 3L2.5 2C2.5 1.72386 2.72386 1.5 3 1.5H4ZM2.5 5C2.5 4.72386 2.27614 4.5 2 4.5C1.72386 4.5 1.5 4.72386 1.5 5V7C1.5 7.27614 1.72386 7.5 2 7.5C2.27614 7.5 2.5 7.27614 2.5 7V5ZM2.5 9C2.5 8.72386 2.27614 8.5 2 8.5C1.72386 8.5 1.5 8.72386 1.5 9V10H2H2.5V9Z" fill="currentColor" />
  </svg>
);


const LeftIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="13" width="12" height="2" rx="1" transform="rotate(-90 1 13)" stroke="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.5 12C4.5 12.2761 4.27614 12.5 4 12.5H3V12V11.5H4C4.27614 11.5 4.5 11.7239 4.5 12ZM6 11.5C5.72386 11.5 5.5 11.7239 5.5 12C5.5 12.2761 5.72386 12.5 6 12.5H8C8.27614 12.5 8.5 12.2761 8.5 12C8.5 11.7239 8.27614 11.5 8 11.5H6ZM10 11.5C9.72386 11.5 9.5 11.7239 9.5 12C9.5 12.2761 9.72386 12.5 10 12.5H11C11.7249 12.5 12.3297 11.9858 12.4695 11.3023C12.4895 11.2047 12.5 11.1036 12.5 11V10C12.5 9.72386 12.2761 9.5 12 9.5C11.7239 9.5 11.5 9.72386 11.5 10V11C11.5 11.2761 11.2761 11.5 11 11.5H10ZM11.5 8C11.5 8.27614 11.7239 8.5 12 8.5C12.2761 8.5 12.5 8.27614 12.5 8V6C12.5 5.72386 12.2761 5.5 12 5.5C11.7239 5.5 11.5 5.72386 11.5 6V8ZM11.5 4C11.5 4.27614 11.7239 4.5 12 4.5C12.2761 4.5 12.5 4.27614 12.5 4V3C12.5 2.17157 11.8284 1.5 11 1.5H10C9.72386 1.5 9.5 1.72386 9.5 2C9.5 2.27614 9.72386 2.5 10 2.5H11C11.2761 2.5 11.5 2.72386 11.5 3V4ZM8 2.5C8.27614 2.5 8.5 2.27614 8.5 2C8.5 1.72386 8.27614 1.5 8 1.5H6C5.72386 1.5 5.5 1.72386 5.5 2C5.5 2.27614 5.72386 2.5 6 2.5H8ZM4 2.5C4.27614 2.5 4.5 2.27614 4.5 2C4.5 1.72386 4.27614 1.5 4 1.5H3V2V2.5H4Z" fill="currentColor" />
  </svg>
);


const TopBottomIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="12" height="2" rx="1" stroke="currentColor" />
    <rect x="1" y="11" width="12" height="2" rx="1" stroke="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.30269 10.8284C2.39857 10.8788 2.50056 10.9192 2.60728 10.9481C2.73245 10.9819 2.86412 11 3 11C2.87877 11 2.76761 10.9137 2.68106 10.7702C2.63408 10.6923 2.59435 10.5975 2.56418 10.4905C2.5585 10.4704 2.55316 10.4498 2.54818 10.4288C2.53126 10.3577 2.51843 10.2819 2.51027 10.2027C2.50354 10.1372 2.5 10.0694 2.5 10V9.125C2.5 8.84886 2.27614 8.625 2 8.625C1.72386 8.625 1.5 8.84886 1.5 9.125V9.5C1.5 9.67532 1.53008 9.84361 1.58535 10C1.64714 10.1748 1.74041 10.3347 1.85835 10.473C1.94435 10.5738 2.04347 10.6631 2.15306 10.7382C2.20101 10.7711 2.25096 10.8012 2.30269 10.8284ZM1.5 7.375C1.5 7.65114 1.72386 7.875 2 7.875C2.27614 7.875 2.5 7.65114 2.5 7.375V5.625C2.5 5.34886 2.27614 5.125 2 5.125C1.72386 5.125 1.5 5.34886 1.5 5.625V7.375ZM1.5 3.875C1.5 4.15114 1.72386 4.375 2 4.375C2.27614 4.375 2.5 4.15114 2.5 3.875V3H2H1.5V3.875ZM11.4358 10.4905C11.4057 10.5975 11.3659 10.6923 11.3189 10.7702C11.2324 10.9137 11.1212 11 11 11C11.1359 11 11.2675 10.9819 11.3927 10.9481C11.4994 10.9192 11.6014 10.8788 11.6973 10.8284C11.749 10.8012 11.799 10.7711 11.8469 10.7382C11.9565 10.6631 12.0557 10.5738 12.1417 10.473C12.2596 10.3347 12.3529 10.1748 12.4146 10C12.4699 9.84361 12.5 9.67532 12.5 9.5V9.125C12.5 8.84886 12.2761 8.625 12 8.625C11.7239 8.625 11.5 8.84886 11.5 9.125V10C11.5 10.0694 11.4965 10.1372 11.4897 10.2027C11.4816 10.2819 11.4687 10.3577 11.4518 10.4288C11.4468 10.4498 11.4415 10.4704 11.4358 10.4905ZM11.5 7.375C11.5 7.65114 11.7239 7.875 12 7.875C12.2761 7.875 12.5 7.65114 12.5 7.375V5.625C12.5 5.34886 12.2761 5.125 12 5.125C11.7239 5.125 11.5 5.34886 11.5 5.625V7.375ZM11.5 3.875C11.5 4.15114 11.7239 4.375 12 4.375C12.2761 4.375 12.5 4.15114 12.5 3.875V3H12H11.5V3.875Z" fill="currentColor" />
  </svg>
);


const LeftRightIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="13" width="12" height="2" rx="1" transform="rotate(-90 1 13)" stroke="currentColor" />
    <rect x="11" y="13" width="12" height="2" rx="1" transform="rotate(-90 11 13)" stroke="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.8284 11.6973C10.8788 11.6014 10.9192 11.4994 10.9481 11.3927C10.9819 11.2675 11 11.1359 11 11C11 11.1212 10.9137 11.2324 10.7702 11.3189C10.6923 11.3659 10.5975 11.4057 10.4905 11.4358C10.4704 11.4415 10.4498 11.4468 10.4288 11.4518C10.3577 11.4687 10.2819 11.4816 10.2027 11.4897C10.1372 11.4965 10.0694 11.5 10 11.5H9.125C8.84886 11.5 8.625 11.7239 8.625 12C8.625 12.2761 8.84886 12.5 9.125 12.5H9.5C9.67532 12.5 9.84361 12.4699 10 12.4146C10.1748 12.3529 10.3347 12.2596 10.473 12.1417C10.5738 12.0557 10.6631 11.9565 10.7382 11.8469C10.7711 11.799 10.8012 11.749 10.8284 11.6973ZM7.375 12.5C7.65114 12.5 7.875 12.2761 7.875 12C7.875 11.7239 7.65114 11.5 7.375 11.5H5.625C5.34886 11.5 5.125 11.7239 5.125 12C5.125 12.2761 5.34886 12.5 5.625 12.5H7.375ZM3.875 12.5C4.15114 12.5 4.375 12.2761 4.375 12C4.375 11.7239 4.15114 11.5 3.875 11.5H3V12V12.5H3.875ZM10.4905 2.56418C10.5975 2.59435 10.6923 2.63408 10.7702 2.68106C10.9137 2.76761 11 2.87877 11 3C11 2.86412 10.9819 2.73245 10.9481 2.60728C10.9192 2.50056 10.8788 2.39857 10.8284 2.30269C10.8012 2.25096 10.7711 2.20101 10.7382 2.15307C10.6631 2.04347 10.5738 1.94435 10.473 1.85835C10.3347 1.74041 10.1748 1.64714 10 1.58535C9.84361 1.53008 9.67532 1.5 9.5 1.5H9.125C8.84886 1.5 8.625 1.72386 8.625 2C8.625 2.27614 8.84886 2.5 9.125 2.5H10C10.0694 2.5 10.1372 2.50354 10.2027 2.51027C10.2819 2.51842 10.3577 2.53126 10.4288 2.54818C10.4498 2.55316 10.4704 2.5585 10.4905 2.56418ZM7.375 2.5C7.65114 2.5 7.875 2.27614 7.875 2C7.875 1.72386 7.65114 1.5 7.375 1.5H5.625C5.34886 1.5 5.125 1.72386 5.125 2C5.125 2.27614 5.34886 2.5 5.625 2.5H7.375ZM3.875 2.5C4.15114 2.5 4.375 2.27614 4.375 2C4.375 1.72386 4.15114 1.5 3.875 1.5H3V2V2.5H3.875Z" fill="currentColor" />
  </svg>

);

const PaddingTopBottomIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.25 1.75C13.25 1.94891 13.171 2.13968 13.0303 2.28033C12.8897 2.42098 12.6989 2.5 12.5 2.5H3.5C3.30109 2.5 3.11032 2.42098 2.96967 2.28033C2.82902 2.13968 2.75 1.94891 2.75 1.75C2.75 1.55109 2.82902 1.36032 2.96967 1.21967C3.11032 1.07902 3.30109 1 3.5 1H12.5C12.6989 1 12.8897 1.07902 13.0303 1.21967C13.171 1.36032 13.25 1.55109 13.25 1.75ZM11.25 4.75V11C11.25 11.3315 11.1183 11.6495 10.8839 11.8839C10.6495 12.1183 10.3315 12.25 10 12.25H6C5.66848 12.25 5.35054 12.1183 5.11612 11.8839C4.8817 11.6495 4.75 11.3315 4.75 11V4.75C4.75 4.41848 4.8817 4.10054 5.11612 3.86612C5.35054 3.6317 5.66848 3.5 6 3.5H10C10.3315 3.5 10.6495 3.6317 10.8839 3.86612C11.1183 4.10054 11.25 4.41848 11.25 4.75ZM9.75 5H6.25V10.75H9.75V5Z" fill="currentColor" />
    <path d="M13.0303 14.5303C13.171 14.3897 13.25 14.1989 13.25 14C13.25 13.8011 13.171 13.6103 13.0303 13.4697C12.8897 13.329 12.6989 13.25 12.5 13.25H3.5C3.30109 13.25 3.11032 13.329 2.96967 13.4697C2.82902 13.6103 2.75 13.8011 2.75 14C2.75 14.1989 2.82902 14.3897 2.96967 14.5303C3.11032 14.671 3.30109 14.75 3.5 14.75H12.5C12.6989 14.75 12.8897 14.671 13.0303 14.5303Z" fill="currentColor" />
  </svg>

);

const PaddingLeftRightIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.75 2.75C1.94891 2.75 2.13968 2.82902 2.28033 2.96967C2.42098 3.11032 2.5 3.30109 2.5 3.5L2.5 12.5C2.5 12.6989 2.42098 12.8897 2.28033 13.0303C2.13968 13.171 1.94891 13.25 1.75 13.25C1.55109 13.25 1.36032 13.171 1.21967 13.0303C1.07902 12.8897 1 12.6989 1 12.5L1 3.5C1 3.30109 1.07902 3.11032 1.21967 2.96967C1.36032 2.82902 1.55109 2.75 1.75 2.75ZM4.75 4.75H11C11.3315 4.75 11.6495 4.8817 11.8839 5.11612C12.1183 5.35054 12.25 5.66848 12.25 6V10C12.25 10.3315 12.1183 10.6495 11.8839 10.8839C11.6495 11.1183 11.3315 11.25 11 11.25H4.75C4.41848 11.25 4.10054 11.1183 3.86612 10.8839C3.6317 10.6495 3.5 10.3315 3.5 10L3.5 6C3.5 5.66848 3.6317 5.35054 3.86612 5.11612C4.10054 4.8817 4.41848 4.75 4.75 4.75ZM5 6.25V9.75H10.75V6.25L5 6.25Z" fill="currentColor" />
    <path d="M14.5303 2.96967C14.3897 2.82902 14.1989 2.75 14 2.75C13.8011 2.75 13.6103 2.82902 13.4697 2.96967C13.329 3.11032 13.25 3.30109 13.25 3.5V12.5C13.25 12.6989 13.329 12.8897 13.4697 13.0303C13.6103 13.171 13.8011 13.25 14 13.25C14.1989 13.25 14.3897 13.171 14.5303 13.0303C14.671 12.8897 14.75 12.6989 14.75 12.5V3.5C14.75 3.30109 14.671 3.11032 14.5303 2.96967Z" fill="currentColor" />
  </svg>


);

const BorderIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.57217 1H3.37217C2.04668 1 0.972168 2.07452 0.972168 3.4V4.6M9.37217 1H10.5722C11.8977 1 12.9722 2.07452 12.9722 3.4V4.6M12.9722 9.4V10.6C12.9722 11.9255 11.8977 13 10.5722 13H9.37217M4.47217 13H3.37217C2.04668 13 0.972168 11.9255 0.972168 10.6V9.4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const AllBorderIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="12" height="12" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.944336 3.9C0.944336 2.57452 2.01885 1.5 3.34434 1.5H10.5443C11.8698 1.5 12.9443 2.57452 12.9443 3.9V11.1C12.9443 12.4255 11.8698 13.5 10.5443 13.5H3.34434C2.01885 13.5 0.944336 12.4255 0.944336 11.1V3.9Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

const PaddingIcon: React.FC<IconProps> = ({ className }) => (
  <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.11155 0.472656C3.69733 0.472656 3.36155 0.808443 3.36155 1.22266C3.36155 1.63687 3.69733 1.97266 4.11155 1.97266H9.88932C10.3035 1.97266 10.6393 1.63687 10.6393 1.22266C10.6393 0.808443 10.3035 0.472656 9.88932 0.472656H4.11155ZM4.11155 12.0282C3.69733 12.0282 3.36155 12.364 3.36155 12.7782C3.36155 13.1924 3.69733 13.5282 4.11155 13.5282H9.88932C10.3035 13.5282 10.6393 13.1924 10.6393 12.7782C10.6393 12.364 10.3035 12.0282 9.88932 12.0282H4.11155ZM1.22266 10.6393C0.808443 10.6393 0.472656 10.3035 0.472656 9.88932V4.11155C0.472656 3.69733 0.808444 3.36155 1.22266 3.36155C1.63687 3.36155 1.97266 3.69733 1.97266 4.11155L1.97266 9.88932C1.97266 10.3035 1.63687 10.6393 1.22266 10.6393ZM12.0282 9.88932C12.0282 10.3035 12.364 10.6393 12.7782 10.6393C13.1924 10.6393 13.5282 10.3035 13.5282 9.88932L13.5282 4.11155C13.5282 3.69733 13.1924 3.36155 12.7782 3.36155C12.364 3.36155 12.0282 3.69733 12.0282 4.11155L12.0282 9.88932Z" fill="currentColor" />
  </svg>
);

export { AllBorderIcon, BorderIcon, BottomIcon, BottomLeftIcon, BottomRightIcon, LeftIcon, LeftRightIcon, PaddingIcon, PaddingLeftRightIcon, PaddingTopBottomIcon, RightIcon, TopBottomIcon, TopIcon, TopLeftIcon, TopRightIcon };
