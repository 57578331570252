import { Text } from '../../../../UI/Text';

interface Props {
  message: string;
}

const EmptyState = ({ message }: Props) => (
  <div className="flex items-center justify-center h-full py-20">
    <Text size="xl" weight="semibold" variant="secondary" as="h4">
      {message}
    </Text>
  </div>
);

export default EmptyState;
