/* eslint-disable consistent-return */

import { Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

export const getCustomHardBreakPlugin = (editor: Editor) => {
  return new Plugin({
    key: new PluginKey('custom-hard-break-plugin'),

    appendTransaction: (transactions, _oldState, newState) => {
      if (!transactions.some((tr) => tr.getMeta('customHardBreakTr'))) {
        return;
      }

      const { selection, storedMarks } = _oldState;

      const { splittableMarks } = editor.extensionManager;

      const marks = storedMarks || (selection.$to.parentOffset && selection.$from.marks());

      if (!Array.isArray(marks)) {
        return;
      }

      const filteredMarks = marks.filter((mark) => splittableMarks.includes(mark.type.name));

      return newState.tr.ensureMarks(filteredMarks);
    },
  });
};
