import { useNavigate, useSearchParams } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import { BoostsFrozenBanner, RecommendationsDisabledBanner } from '@/components/Boosts';
import { BoostsTopLineMetrics } from '@/components/BoostsTopLineMetrics';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import { useRequireSetting } from '@/hooks';
import useStripeConnectAccount, { StripeAccountStatus } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { useTutorial } from '@/hooks/useTutorials';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';
import { Button } from '@/ui/Button';

import ExplainMetrics from './components/ExplainMetrics';
import Tabs from './components/Tabs';

const BoostsOverview = () => {
  useRequireSetting({
    setting: Setting.BOOSTS,
    message: 'Upgrade to use boosts.',
    redirectTo: '/monetize/boosts/overview',
  });

  const tutorial = useTutorial(TutorialType.BOOSTS_MONETIZE);
  const currentPublicationId = useCurrentPublicationId();
  const { data: stripeConnectAccount, isLoading: isStripeConnectAccountLoading } =
    useStripeConnectAccount(currentPublicationId);
  const navigate = useNavigate();
  const boostsAccountStatus = stripeConnectAccount?.boosts_account_status || StripeAccountStatus.INACTIVE;

  const [searchParams] = useSearchParams();

  if (!isStripeConnectAccountLoading && boostsAccountStatus !== StripeAccountStatus.ACTIVE) {
    navigate('marketplace', {
      state: {
        throughBoostInviteEmail: !!searchParams.get('boost_invite_id'),
      },
    });
  }

  return (
    <div className="flex flex-col gap-y-8">
      <BoostsFrozenBanner />
      <RecommendationsDisabledBanner />
      <PageHeading
        title="Monetize with Boosts"
        description="Get paid to recommend your favorite beehiiv newsletters."
        tutorial={tutorial}
        cta={<ExplainMetrics />}
      >
        <Button Icon={MagnifyingGlassIcon} variant="primary" to="marketplace">
          Explore offers
        </Button>
      </PageHeading>
      <BoostsTopLineMetrics />
      <Tabs />
    </div>
  );
};

export default BoostsOverview;
