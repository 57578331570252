import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getStringAttribute } from '../../utils/attributesUtils';
import {
  DEFAULT_BACKGROUND_SECONDARY_COLOR,
  DEFAULT_BORDER_COLOR,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
  WHITE_COLOR,
} from '../constants';

import { PricingView } from './views/PricingView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    pricing: {
      setPricing: () => ReturnType;
    };
  }
}

export const Pricing = Node.create<{}, {}>({
  name: 'pricing',

  group: 'block',

  draggable: true,

  atom: true,

  defining: true,

  selectable: true,

  addAttributes() {
    return {
      hasFakeData: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-has-fake-data') === 'true',
        renderHTML: (attributes) => ({ 'data-has-fake-data': attributes.hasFakeData }),
      },
      insertedFromSidebar: {
        default: true,
        parseHTML: (element) => element.getAttribute('data-inserted-from-sidebar') === 'true',
        renderHTML: (attributes) => ({ 'data-inserted-from-sidebar': attributes.insertedFromSidebar }),
      },
      gap: {
        default: '32px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      padding: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      borderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
      },
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
      },
      borderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
      },
      cardShadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-card-shadow'),
        renderHTML: (attributes) => ({ 'data-card-shadow': attributes.cardShadow }),
      },
      cardBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-card-border-width'),
        renderHTML: (attributes) => ({ 'data-card-border-width': attributes.cardBorderWidth }),
      },
      cardBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-border-color'),
        renderHTML: (attributes) => ({ 'data-card-border-color': attributes.cardBorderColor }),
      },
      cardBorderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-card-border-style'),
        renderHTML: (attributes) => ({ 'data-card-border-style': attributes.cardBorderStyle }),
      },
      cardBorderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-card-border-radius'),
        renderHTML: (attributes) => ({ 'data-card-border-radius': attributes.cardBorderRadius }),
      },
      cardPadding: {
        default: '32px',
        parseHTML: (element) => element.getAttribute('data-card-padding'),
        renderHTML: (attributes) => ({ 'data-card-padding': attributes.cardPadding }),
      },
      cardHeight: getStringAttribute('cardHeight', 'data-card-height', '100%'),
      cardSelectedBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-card-selected-border-color'),
        renderHTML: (attributes) => ({ 'data-card-selected-border-color': attributes.cardSelectedBorderColor }),
      },
      cardBackgroundColor: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-card-background'),
        renderHTML: (attributes) => ({ 'data-card-background': attributes.cardBackground }),
      },
      cardSelectedBackgroundColor: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-card-selected-background-color'),
        renderHTML: (attributes) => ({ 'data-card-selected-background-color': attributes.cardSelectedBackgroundColor }),
      },
      signupFlowId: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-signup-flow-id') || '',
        renderHTML: (attributes) => ({ 'data-signup-flow-id': attributes.signupFlowId }),
      },
      recommendedObjectType: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-recommended-object-type'),
        renderHTML: (attributes) => ({ 'data-recommended-object-type': attributes.recommendedObjectType }),
      },
      recommendedTierId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-recommended-tier-id'),
        renderHTML: (attributes) => ({ 'data-recommended-tier-id': attributes.recommendedTierId }),
      },
      recommendedPriceId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-recommended-price-id'),
        renderHTML: (attributes) => ({ 'data-recommended-price-id': attributes.recommendedPriceId }),
      },
      internalCheckout: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-internal-checkout'),
        renderHTML: (attributes) => ({ 'data-internal-checkout': attributes.internalCheckout }),
      },
      internalCheckoutCta: {
        default: 'Upgrade',
        parseHTML: (element) => element.getAttribute('data-internal-checkout-cta'),
        renderHTML: (attributes) => ({ 'data-internal-checkout-cta': attributes.internalCheckoutCta }),
      },
      nameColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-name-color'),
        renderHTML: (attributes) => ({ 'data-name-color': attributes.nameColor }),
      },
      descriptionColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-description-color'),
        renderHTML: (attributes) => ({ 'data-description-color': attributes.descriptionColor }),
      },
      priceColor: getStringAttribute('priceColor', 'data-price-color', DEFAULT_PRIMARY_COLOR),
      priceDenominatorColor: getStringAttribute(
        'priceDenominatorColor',
        'data-price-denominator-color',
        DEFAULT_PRIMARY_COLOR
      ),
      priceFontSize: getStringAttribute('priceFontSize', 'data-price-font-size', '30px'),
      priceDenominatorFontSize: getStringAttribute(
        'priceDenominatorFontSize',
        'data-price-denominator-font-size',
        '20px'
      ),
      offerPriceColor: getStringAttribute('offerPriceColor', 'data-offer-price-color', `${DEFAULT_PRIMARY_COLOR}50`),
      offerPriceFontSize: getStringAttribute('offerPriceFontSize', 'data-offer-price-font-size', '30px'),
      offerDetailsColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-offer-details-color'),
        renderHTML: (attributes) => ({ 'data-offer-details-color': attributes.offerDetailsColor }),
      },
      offerDetailsIconColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-offer-details-icon-color'),
        renderHTML: (attributes) => ({ 'data-offer-details-icon-color': attributes.offerDetailsIconColor }),
      },
      featureColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-feature-color'),
        renderHTML: (attributes) => ({ 'data-feature-color': attributes.featureColor }),
      },
      featureIconColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-feature-icon-color'),
        renderHTML: (attributes) => ({ 'data-feature-icon-color': attributes.featureIconColor }),
      },
      buttonBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-button-border-width'),
        renderHTML: (attributes) => ({ 'data-button-border-width': attributes.buttonBorderWidth }),
      },
      buttonBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-border-color'),
        renderHTML: (attributes) => ({ 'data-button-border-color': attributes.buttonBorderColor }),
      },
      buttonBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-button-border-style'),
        renderHTML: (attributes) => ({ 'data-button-border-style': attributes.buttonBorderStyle }),
      },
      buttonBorderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-button-border-radius'),
        renderHTML: (attributes) => ({ 'data-button-border-radius': attributes.buttonBorderRadius }),
      },
      buttonColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-color'),
        renderHTML: (attributes) => ({ 'data-button-color': attributes.buttonColor }),
      },
      buttonTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-text-color'),
        renderHTML: (attributes) => ({ 'data-button-text-color': attributes.buttonTextColor }),
      },
      tabBackgroundColor: {
        default: DEFAULT_BACKGROUND_SECONDARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-tab-background-color'),
        renderHTML: (attributes) => ({ 'data-tab-background-color': attributes.tabBackgroundColor }),
      },
      tabSelectedBackgroundColor: {
        default: WHITE_COLOR,
        parseHTML: (element) => element.getAttribute('data-tab-selected-background-color'),
        renderHTML: (attributes) => ({ 'data-tab-selected-background-color': attributes.tabSelectedBackgroundColor }),
      },
      tabTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-tab-text-color'),
        renderHTML: (attributes) => ({ 'data-tab-text-color': attributes.tabTextColor }),
      },
      tabSelectedTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-tab-selected-text-color'),
        renderHTML: (attributes) => ({ 'data-tab-selected-text-color': attributes.tabSelectedTextColor }),
      },
      tabBorderWidth: getStringAttribute('tabBorderWidth', 'data-tab-border-width', '1px'),
      tabBorderColor: getStringAttribute('tabBorderColor', 'data-tab-border-color', DEFAULT_BORDER_COLOR),
      tabBorderStyle: getStringAttribute('tabBorderStyle', 'data-tab-border-style', 'solid'),
      tabBorderRadius: getStringAttribute('tabBorderRadius', 'data-tab-border-radius', '8px'),
      tabsContainerBorderWidth: getStringAttribute(
        'tabsContainerBorderWidth',
        'data-tabs-container-border-width',
        '1px'
      ),
      tabsContainerBorderColor: getStringAttribute(
        'tabsContainerBorderColor',
        'data-tabs-container-border-color',
        DEFAULT_BORDER_COLOR
      ),
      tabsContainerBorderStyle: getStringAttribute(
        'tabsContainerBorderStyle',
        'data-tabs-container-border-style',
        'solid'
      ),
      tabsContainerBorderRadius: getStringAttribute(
        'tabsContainerBorderRadius',
        'data-tabs-container-border-radius',
        '8px'
      ),
      recommendedBackgroundColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-recommended-background-color'),
        renderHTML: (attributes) => ({ 'data-recommended-background-color': attributes.recommendedBackgroundColor }),
      },
      recommendedTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-recommended-text-color'),
        renderHTML: (attributes) => ({ 'data-recommended-text-color': attributes.recommendedTextColor }),
      },
      inputBackgroundColor: {
        default: DEFAULT_BACKGROUND_SECONDARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-background-color'),
        renderHTML: (attributes) => ({ 'data-input-background-color': attributes.inputBackgroundColor }),
      },
      inputPlaceholderColor: {
        default: '#6B7280',
        parseHTML: (element) => element.getAttribute('data-input-placeholder-color') || '#6B7280',
        renderHTML: (attributes) => ({ 'data-input-placeholder-color': attributes.inputPlaceholderColor }),
      },
      inputTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-text-color') || DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-input-text-color': attributes.inputTextColor }),
      },
      data: {
        default: [],
        parseHTML: (element) => element.getAttribute('data-data'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
      fontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-font-family') || 'inherit',
        renderHTML: (attributes) => ({ 'data-font-family': attributes.fontFamily }),
      },
    };
  },

  addOptions() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(PricingView, {
      stopEvent: () => false,
    });
  },
});

export default Pricing;
