/* eslint-disable consistent-return */
import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getBooleanAttribute, getStringAttribute } from '../../utils/attributesUtils';
import { EditorDocumentType } from '../Document';

import { SectionView } from './views/SectionView';

export enum SectionLayout {
  Custom = 'custom',
  SidebarLeft = 'sidebar-left',
  SidebarRight = 'sidebar-right',
  TwoSection = 'two-section',
}

export const SECTION_DEFAULTS = {
  padding: '20px 0px 20px 0px', // top right bottom left
  paddingRoot: '20px 30px 20px 30px',
  paddingMobile: '5px 0px 5px 0px',
  paddingRootMobile: '5px 30px 5px 30px',
  margin: '0px 0px 0px 0px',
  gap: '30px',
  gapMobile: '16px',
  gapMobileVertical: '10px',
  borderRadius: '0px 0px 0px 0px',
  boxShadow: 'none',
};

interface SectionOptions {
  type: EditorDocumentType;
}

interface SectionStorage {}

export const Section = Node.create<SectionOptions, SectionStorage>({
  name: 'section',

  group: 'section',

  content: 'block*',

  defining: true,

  selectable: true,

  draggable: true,

  addOptions() {
    return {
      type: 'page',
    };
  },

  addAttributes() {
    return {
      isHiddenInProduction: getBooleanAttribute('isHiddenInProduction', 'data-is-hidden-in-production', false),
      align: getStringAttribute('align', 'data-align', 'left'),
      backgroundColor: getStringAttribute('backgroundColor', 'data-background-color', '#FFFFFF'),
      color: getStringAttribute('color', 'data-color', '#000000FF'),
      backgroundImage: getStringAttribute('backgroundImage', 'data-background-image', undefined),
      backgroundDarken: getBooleanAttribute('backgroundDarken', 'data-background-darken', false),
      innerVerticalAlign: getStringAttribute('innerVerticalAlign', 'data-inner-vertical-align', 'align_center'),
      innerHorizontalAlign: getStringAttribute('innerHorizontalAlign', 'data-inner-horizontal-align', 'center'),
      width: getStringAttribute('width', 'data-width', '100dvh'),
      height: getStringAttribute('height', 'data-height', undefined),
      width_max: getStringAttribute('width_max', 'data-width-max', undefined),
      innerWidth: getStringAttribute('innerWidth', 'data-inner-width', undefined),
      innerWidthMax: getStringAttribute('innerWidthMax', 'data-inner-width-max', undefined),
      innerHeight: getStringAttribute('innerHeight', 'data-inner-height', undefined),
      innerHeightMax: getStringAttribute('innerHeightMax', 'data-inner-height-max', undefined),
      mobilePadding: getStringAttribute('mobilePadding', 'data-mobile-padding', '0px 16px 0px 16px'),
      padding: getStringAttribute('padding', 'data-padding', '40px 20px 40px 20px'),
      margin: getStringAttribute('margin', 'data-margin', '0px 0px 0px 0px'),
      borderRadius: getStringAttribute('borderRadius', 'data-border-radius', '0px 0px 0px 0px'),
      isViewportWidth: getBooleanAttribute('isViewportWidth', 'data-is-viewport-width', false),
      isViewportHeight: getBooleanAttribute('isViewportHeight', 'data-is-viewport-height', false),
      gap: getStringAttribute('gap', 'data-gap', '0px'),
      inAnimation: getStringAttribute('inAnimation', 'data-in-animation', undefined),
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': this.name }), 0];
  },

  addProseMirrorPlugins() {
    if (this.options.type !== 'page') {
      return [];
    }

    return [
      new Plugin({
        key: new PluginKey('section-viewport-height-plugin'),

        appendTransaction: (transactions, _oldState, newState) => {
          if (!transactions.some((tr) => tr.docChanged)) {
            return;
          }

          const isLastRemainingSection =
            newState.doc.childCount === 1 && newState.doc.firstChild?.type.name === this.name;

          if (!isLastRemainingSection || newState.doc.firstChild?.attrs.isViewportHeight) {
            return;
          }

          const { tr } = newState;

          tr.doc.forEach((_node, offset) => {
            tr.setNodeAttribute(offset, 'isViewportHeight', true);
          });

          return tr;
        },
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SectionView, {
      stopEvent: () => false,
    });
  },
});

export default Section;
