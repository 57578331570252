import BaseListItem, { ListItemOptions as BaseListItemOptions } from '@tiptap/extension-list-item';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ListItemView } from './views/ListItemView';

interface ListItemOptions extends BaseListItemOptions {
  publicationId: string;
  userId: string;
}

interface ListItemStorage {}

export const ListItem = BaseListItem.extend<ListItemOptions, ListItemStorage>({
  addOptions() {
    return {
      ...this.parent?.(),
      publicationId: '',
      userId: '',
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ListItemView, {
      stopEvent: () => false,
    });
  },
});

export default ListItem;
