import { useCallback, useEffect, useMemo, useState } from 'react';
import { CaretRight, Check, TextT } from '@phosphor-icons/react';

import { Popover, PopoverContent, PopoverTrigger } from '../../../_components/UI/Popover';
import { Search } from '../../../_components/UI/Search';
import { Text } from '../../../_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../_components/UI/ToggleGroup';
import { getFontFamilies, getFontPreviewUrl } from '../../../_utils/getFonts';

const DEFAULT_FONT_FAMILY = 'Inter';

interface Props {
  title: string;
  onSetFontFamily: (value: string) => void;
  value?: string;
  offsetValue?: number;
  placement?: 'top' | 'left' | 'right' | 'bottom';
  showToggleGroup?: boolean;
}

export const FontSelection = ({
  title,
  onSetFontFamily,
  value,
  offsetValue,
  placement,
  showToggleGroup = true,
}: Props) => {
  const [fonts, setFonts] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const [group, setGroup] = useState<'all' | 'in-page'>('all');

  useEffect(() => {
    getFontFamilies().then((w) => setFonts(w));
  }, []);

  const filteredFonts = useMemo(() => {
    if (!query) return fonts;
    return fonts.filter((font) => font.toLowerCase().includes(query.toLowerCase()));
  }, [fonts, query]);

  const currentFont = value || DEFAULT_FONT_FAMILY;

  const handleSetFontFamily = useCallback(
    (val: string) => {
      onSetFontFamily(val);
    },
    [onSetFontFamily]
  );

  return (
    <Popover>
      <div className="flex items-center justify-between gap-2">
        <Text className="w-full max-w-[150px]" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <PopoverTrigger asChild>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm max-w-[150px] cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 p-2">
              <TextT className="text-wb-secondary" weight="bold" />
              <Text size="2xs" weight="medium" as="p" className="whitespace-nowrap truncate flex-1">
                {currentFont || DEFAULT_FONT_FAMILY}
              </Text>
              <CaretRight className="text-wb-secondary" weight="bold" size={12} />
            </div>
          </div>
        </PopoverTrigger>
      </div>
      <PopoverContent
        className="w-[255px] max-h-[400px] min-h-0 p-3 rounded-lg shadow-xl flex flex-col gap-2"
        side={placement}
        sideOffset={offsetValue}
      >
        <div className="flex items-center justify-between gap-2">
          <Text size="sm" weight="semibold">
            Fonts
          </Text>
        </div>
        {showToggleGroup && (
          <div className="grow bg-wb-secondary rounded-lg shadow-sm mb-3">
            <ToggleGroup
              className="p-[2px] grid grid-cols-2"
              type="single"
              defaultValue="left"
              value={group}
              onValueChange={(s) => setGroup(s as 'all' | 'in-page')}
            >
              <ToggleGroupItem value="all">All</ToggleGroupItem>
            </ToggleGroup>
          </div>
        )}

        <Search placeholder="Search font..." value={query} onChange={(e) => setQuery(e.target.value)} />
        <div className="mt-2 grow min-h-0 overflow-y-scroll flex flex-col gap-2">
          {filteredFonts.map((font) => (
            <div
              key={font}
              className={`flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer border hover:bg-wb-secondary ${font === currentFont
                ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                : 'bg-wb-primary border-transparent'
                }`}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleSetFontFamily(font);
                }
              }}
              onClick={() => {
                handleSetFontFamily(font);
              }}
            >
              <img src={getFontPreviewUrl(font)} alt={font} height={12} className="h-[12px] w-auto" />
              {font === currentFont && <Check weight="bold" className="text-wb-accent" />}
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
