import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getStringAttribute } from '../../utils/attributesUtils';
import { DEFAULT_PRIMARY_COLOR } from '../constants';

import { RecommendedPostsView } from './views/RecommendedPostsView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    recommendedPosts: {
      setRecommendedPosts: () => ReturnType;
    };
  }
}

export const RecommendedPosts = Node.create<{}, {}>({
  name: 'recommendedPosts',

  group: 'block',

  content: 'block*',

  draggable: true,

  addAttributes() {
    return {
      gap: getStringAttribute('gap', 'data-gap', '8px'),
      height: getStringAttribute('height', 'data-height', '550px'),
      width: getStringAttribute('width', 'data-width', '640px'),
      padding: getStringAttribute('padding', 'data-padding', '32px'),
      borderWidth: getStringAttribute('borderWidth', 'data-border-width', '2px'),
      borderColor: getStringAttribute('borderColor', 'data-border-width', DEFAULT_PRIMARY_COLOR),
      borderStyle: getStringAttribute('borderStyle', 'data-border-width', 'solid'),
    };
  },

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(RecommendedPostsView, {
      stopEvent: () => false,
      className: 'w-full h-full',
    });
  },
});

export default RecommendedPosts;
