import { Editor } from '@tiptap/core';
import { AllSelection, NodeSelection } from '@tiptap/pm/state';
import { useEditorState } from '@tiptap/react';

export const RenderCondition = ({
  editor,
  allowedNodeTypes,
  children,
}: {
  editor: Editor;
  allowedNodeTypes: string[];
  children: React.ReactNode;
}) => {
  const { activeNodeType } = useEditorState({
    editor,
    selector: ({ editor: e }) => {
      const { selection } = e.state;
      let at = '';

      if (selection instanceof NodeSelection) {
        at = selection.node.type.name;
      } else if (selection instanceof AllSelection) {
        at = selection.$anchor.parent.type.name;
      } else {
        // leaf node, select the parent
        const parentNode = selection.$anchor.parent;
        at = parentNode.type.name;
      }

      return { activeNodeType: at };
    },
    equalityFn: (a, b) => {
      if (!a || !b) return false;

      return a.activeNodeType === b.activeNodeType;
    },
  });

  if (!activeNodeType || !allowedNodeTypes.includes(activeNodeType)) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
