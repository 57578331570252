import { useParams } from 'react-router-dom';
import { Card } from '@tremor/react';

import { Input } from '@/components/Form';
import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import useSvixEndpoint from '@/hooks/webhooks/useSvixEndpoint';

import BodyContainer from '../../../_components/BodyContainer';
import PageContainer from '../../../_components/PageContainer';
import { usePublicationSettings } from '../../context';
import Header from '../components/Header';
import Summary from '../components/Summary';

import Messages from './Messages';
import SendTestMessage from './SendTestMessage';

const ShowWebhookEndpoint = () => {
  const { endpointId } = useParams<{ endpointId: string }>();
  const { currentPublicationId } = usePublicationSettings();
  const {
    data: publication,
    isLoading: isLoadingPublication,
    isError: isErrorPublication,
  } = usePublication(currentPublicationId);
  const {
    data: endpoint,
    isLoading: isLoadingEndpoint,
    isError: isErrorEndpoint,
  } = useSvixEndpoint(currentPublicationId, endpointId || '');

  return (
    <LoadingBox isLoading={isLoadingPublication || isLoadingEndpoint} isError={isErrorPublication || isErrorEndpoint}>
      {publication && endpoint ? (
        <PageContainer key={currentPublicationId} isWide>
          <Header endpoint={endpoint} />
          <div className="flex flex-row w-full gap-x-6">
            <Summary endpoint={endpoint} showEditButton />
            <Card>
              <BodyContainer>
                <Input
                  type="text"
                  name="secret"
                  labelText="Endpoint Secret"
                  value={endpoint.secret}
                  helperText="This secret is passed as a header in each message this webhook sends. The secret is used to validate that incoming webhook requests are authentic and haven't been tampered with."
                />
                <SendTestMessage endpoint={endpoint} publicationId={publication.id} />
              </BodyContainer>
            </Card>
          </div>
          <Card>
            <Messages endpointId={endpoint.id} publicationId={publication.id} />
          </Card>
        </PageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default ShowWebhookEndpoint;
