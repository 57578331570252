import { FC, useCallback, useState } from 'react';
import { AccordionElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent, useReactNodeView } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

import { getAccordionElement } from './utils';

export const AccordionView: FC<NodeViewProps> = (props) => {
  const { node, HTMLAttributes } = props;

  const { onDragStart } = useReactNodeView();

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <AccordionElement
      element={getAccordionElement(node)}
      attributes={{
        ...getDataAttributes(HTMLAttributes),
        'data-node-view-wrapper': '',
      }}
      onDragStart={onDragStart}
      isOpen={isOpen}
      className="relative"
      onClick={(e) => {
        const target = e.target as HTMLDivElement;

        const nearestSvgWithClass = target.closest('.accordion-toggle-button');

        if (nearestSvgWithClass?.contains(target)) {
          toggleOpen();
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <NodeViewContent data-node-view-display-contents />
    </AccordionElement>
  );
};
