import { useEffect, useState } from 'react';
import { getUsedFonts, NavbarSerializableNode } from '@shared/dream-components';

import { useWebsiteContext } from '@/context/website-context';

import { useNavbarContext } from '../../navbar/_components/NavbarEditor/NavbarContext';

import { useFonts } from './useFontHrefs';

export const useNavbarFontHrefs = () => {
  const { previewSiteVersion } = useWebsiteContext();
  const { content: draftContent } = useNavbarContext();
  const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());

  const content = (previewSiteVersion ? previewSiteVersion?.navbar : draftContent) as NavbarSerializableNode;

  const { data: allFonts } = useFonts({ enabled: !!content });

  useEffect(() => {
    if (!content || !allFonts) return;
    const f = getUsedFonts(content, allFonts, ['Inter']);
    setFontHrefs(new Set(f));
  }, [content, allFonts]);

  return fontHrefs;
};
