import { Condition } from '@/interfaces/segment';

import allOptions from './nameOptions';
import { DataTypes, NameTypes } from './types';

export const equalOperator = {
  value: 'equal',
};

const stringOperatorOptions = [
  {
    label: 'is',
    ...equalOperator,
  },
  {
    label: 'is not',
    value: 'not_equal',
  },
  {
    label: 'contains',
    value: 'contain',
  },
  {
    label: 'does not contain',
    value: 'not_contain',
  },
  {
    label: 'begins with',
    value: 'start_with',
  },
  {
    label: 'ends with',
    value: 'end_with',
  },
  {
    label: 'exists',
    value: 'exists',
  },
  {
    label: 'does not exist',
    value: 'does_not_exist',
  },
];

const datetimeOperatorOptions = [
  {
    label: 'is after',
    value: 'greater_than',
  },
  {
    label: 'is on or after',
    value: 'greater_than_equal_to',
  },
  {
    label: 'is before',
    value: 'less_than',
  },
  {
    label: 'is on or before',
    value: 'less_than_equal_to',
  },
];

const integerOperatorOptions = [
  {
    label: 'is exactly',
    ...equalOperator,
  },
  {
    label: 'is not exactly',
    value: 'not_equal',
  },
  {
    label: 'is greater than',
    value: 'greater_than',
  },
  {
    label: 'is greater than or equal to',
    value: 'greater_than_equal_to',
  },
  {
    label: 'is less than',
    value: 'less_than',
  },
  {
    label: 'is less than or equal to',
    value: 'less_than_equal_to',
  },
];

const emailActionOperatorOptions = [
  {
    label: 'was',
    ...equalOperator,
  },
  {
    label: 'was not',
    value: 'not_equal',
  },
];

const externalEmbedOptions = [
  {
    label: 'was',
    ...equalOperator,
  },
  {
    label: 'was not',
    value: 'not_equal',
  },
];

const pollOperatorOptions = [
  {
    label: 'Was submitted',
    value: 'submitted',
  },
  {
    label: 'Was not submitted',
    value: 'not_submitted',
  },
];

const subscriberTagOperators = [
  {
    label: 'Includes',
    value: 'contain',
  },
  {
    label: 'Excludes',
    value: 'not_contain',
  },
];

const automationActionOptions = [
  {
    label: 'was',
    ...equalOperator,
  },
  {
    label: 'was not',
    value: 'not_equal',
  },
];

const tierOperators = [
  {
    label: 'is',
    ...equalOperator,
  },
  {
    label: 'is not',
    value: 'not_equal',
  },
];

interface IStringData {
  [key: string]: string;
}

const nameOptionToDataType: IStringData = allOptions.reduce((acc, nameOption) => {
  const { value, dataType } = nameOption;
  return { ...acc, [value]: dataType };
}, {});

const operatorOptionsByDataType = (dataType: string) => {
  if (dataType === DataTypes.DATETIME) {
    return datetimeOperatorOptions;
  }

  if (dataType === DataTypes.INTEGER || dataType === DataTypes.PERCENTAGE) {
    return integerOperatorOptions;
  }

  if (dataType === DataTypes.BEHAVIOR_ACTION) {
    return pollOperatorOptions;
  }

  if (dataType === DataTypes.EMAIL_ACTION) {
    return emailActionOperatorOptions;
  }

  if (dataType === DataTypes.EXTERNAL_EMBED) {
    return externalEmbedOptions;
  }

  if (dataType === DataTypes.SUBSCRIBER_TAG) {
    return subscriberTagOperators;
  }

  if (dataType === DataTypes.AUTOMATION_ACTION) {
    return automationActionOptions;
  }

  if (dataType === DataTypes.TIER) {
    return tierOperators;
  }

  return stringOperatorOptions;
};

const operatorOptionsByConditionName = (name?: string) => {
  if (!name) {
    return operatorOptionsByDataType('');
  }

  if (name === 'referral_count') {
    return operatorOptionsByDataType(DataTypes.INTEGER);
  }

  const dataType = nameOptionToDataType[name];
  return operatorOptionsByDataType(dataType);
};

const defaultOperatorByConditionName = (name?: string) => {
  if (!name) {
    return operatorOptionsByDataType('')[0].value;
  }

  const dataType = nameOptionToDataType[name];
  return operatorOptionsByDataType(dataType)[0].value;
};

const isEmailClick = (condition: Condition) =>
  condition.type === NameTypes.EMAIL_ACTION && (condition.filters?.value === 'clicked' || condition.filters?.value === 'verified_clicked');

export {
  datetimeOperatorOptions,
  defaultOperatorByConditionName,
  integerOperatorOptions,
  isEmailClick,
  nameOptionToDataType,
  operatorOptionsByConditionName,
  operatorOptionsByDataType,
  stringOperatorOptions,
};
