import { IUser } from '../interfaces/user';

/**
 * Wraps a user object coming from the server to extend it with
 * additional helper methods and accessors.
 *
 * @param {IUser} user - The user object coming from the server.
 */
export default class User implements IUser {
  ad_network_admin: IUser['ad_network_admin'];

  system_accountant: IUser['system_accountant'];

  bio: IUser['bio'];

  currently_in_trial: IUser['currently_in_trial'];

  email: IUser['email'];

  facebook_url: IUser['facebook_url'];

  first_name: IUser['first_name'];

  has_premium_newsletter: IUser['has_premium_newsletter'];

  is_confirmed: IUser['is_confirmed'];

  last_name: IUser['last_name'];

  linkedin_url: IUser['linkedin_url'];

  plan_name: IUser['plan_name'];

  primary_publication_id: IUser['primary_publication_id'];

  default_advertiser_id: IUser['default_advertiser_id'];

  profile_picture: IUser['profile_picture'];

  publications: IUser['publications'];

  id: IUser['id'];

  instagram_url: IUser['instagram_url'];

  security_admin: IUser['security_admin'];

  system_admin: IUser['system_admin'];

  tiktok_url: IUser['tiktok_url'];

  time_zone: IUser['time_zone'];

  twitter_handle: IUser['twitter_handle'];

  youtube_url: IUser['youtube_url'];

  threads_url: IUser['threads_url'];

  discord_url: IUser['discord_url'];

  partner_terms_accepted: IUser['partner_terms_accepted'];

  partner_program_disabled: IUser['partner_program_disabled'];

  has_settings_v2: IUser['has_settings_v2'];

  /**
   * @returns {string} The user's full name
   * @example
   * user.fullName(); // 'John Doe'
   */
  get fullName(): string {
    return `${this.first_name} ${this.last_name}`.trim();
  }

  /**
   * @returns {boolean} true if the user is a security admin, false otherwise
   * @example
   * user.isSecurityAdmin(); // true
   */
  isSecurityAdmin(): boolean {
    return !!this.security_admin;
  }

  /**
   * @returns {boolean} true if the user is a system admin, false otherwise
   * @example
   * user.isSystemAdmin(); // true
   */
  isSystemAdmin(): boolean {
    return !!this.system_admin;
  }

  isBeehiivAccountant(): boolean {
    return !!this.system_accountant;
  }

  isAdNetworkAdmin(): boolean {
    return !!this.ad_network_admin;
  }

  constructor(user: IUser) {
    this.ad_network_admin = user.ad_network_admin;
    this.system_accountant = user.system_accountant;
    this.bio = user.bio;
    this.currently_in_trial = user.currently_in_trial;
    this.email = user.email;
    this.facebook_url = user.facebook_url;
    this.first_name = user.first_name;
    this.has_premium_newsletter = user.has_premium_newsletter;
    this.id = user.id;
    this.instagram_url = user.instagram_url;
    this.is_confirmed = user.is_confirmed;
    this.last_name = user.last_name;
    this.linkedin_url = user.linkedin_url;
    this.plan_name = user.plan_name;
    this.partner_terms_accepted = user.partner_terms_accepted;
    this.partner_program_disabled = user.partner_program_disabled;
    this.primary_publication_id = user.primary_publication_id;
    this.default_advertiser_id = user.default_advertiser_id;
    this.profile_picture = user.profile_picture;
    this.publications = user.publications;
    this.security_admin = user.security_admin;
    this.system_admin = user.system_admin;
    this.tiktok_url = user.tiktok_url;
    this.time_zone = user.time_zone;
    this.twitter_handle = user.twitter_handle;
    this.youtube_url = user.youtube_url;
    this.threads_url = user.threads_url;
    this.discord_url = user.discord_url;
    this.has_settings_v2 = user.has_settings_v2;
  }
}
