import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Instance } from 'tippy.js';

import { BubbleMenu as BaseBubbleMenu } from '@/components/TiptapEditor/components/menus/BubbleMenu';
import { EditLink } from '@/components/TiptapEditor/components/menus/Link';
import { MenuProps } from '@/components/TiptapEditor/components/menus/types';
import { forceUpdateTippy } from '@/components/TiptapEditor/components/menus/utils/forceUpdateTippy';
import { getRenderContainer } from '@/components/TiptapEditor/components/menus/utils/getRenderContainer';
import CustomColorPanel from '@/components/TiptapEditor/components/panels/CustomColor';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelFooter, PanelHeadline, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { Divider, Toolbar } from '@/components/TiptapEditor/components/ui/Toolbar';
import { Tooltip } from '@/components/TiptapEditor/components/ui/Tooltip';
import { useEditorStateNonBlocking } from '@/components/TiptapEditor/lib/hooks/useEditorStateNonBlocking';
import { useThemeData } from '@/components/TiptapEditor/lib/hooks/useThemeData';
import { EditorColor } from '@/components/TiptapEditor/lib/types';
import styles from '@/components/TiptapEditor/styled';
import { getBackgroundColors, getTextDocumentColors } from '@/utils/documentColors';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

const requiredButtons = ['confirmation_button'];

export const ButtonMenu = memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const themeColors = useThemeData('colors');

  const {
    documentColors,
    buttonAttrs: {
      href: buttonHref = '',
      target: buttonTarget,
      id: buttonId = '',
      customBackgroundColor: buttonCustomBackgroundColor,
      customTextColor: buttonCustomTextColor,
      alignment: buttonAlignment,
      size: buttonSize,
      fullWidth: buttonFullWidth,
    },
    isButtonActive,
  } = useEditorStateNonBlocking({
    editor,
    selector: () => {
      return {
        documentColors: editor.storage.documentColors?.colors as EditorColor[] | undefined,
        buttonAttrs: editor.getAttributes('button') || {},
        isButtonActive: editor.isActive('button'),
      };
    },
  });

  const backgroundColors = useMemo(() => {
    return getBackgroundColors(
      documentColors,
      themeColors.map((c) => c.value)
    );
  }, [themeColors, documentColors]);

  const textColors = useMemo(() => {
    return getTextDocumentColors(
      documentColors,
      themeColors.map((c) => c.value)
    );
  }, [documentColors, themeColors]);

  const [isColorPanelOpen, setIsColorPanelOpen] = useState(false);

  const [isColorSelecorOpen, setIsColorSelecorOpen] = useState(false);

  const [colorSelectionActiveItem, setColorSelectionActiveItem] = useState<'text' | 'background' | null>(null);

  const tippyInstance = useRef<Instance | null>(null);

  const [showLinkEdit, setShowLinkEdit] = useState(false);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'button');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    return editor.isActive('button');
  }, [editor]);

  const isNotRequiredButton = useMemo(() => requiredButtons.indexOf(buttonId) === -1, [buttonId]);

  // If the button changes, reset the visibility
  useEffect(() => {
    setShowLinkEdit(false);
  }, [buttonHref]);

  const openSelectColorMenu = useCallback((item: 'text' | 'background') => {
    setIsColorSelecorOpen(true);
    setColorSelectionActiveItem(item);
  }, []);

  const closeSelectColorMenu = useCallback(() => {
    setIsColorSelecorOpen(false);
    setColorSelectionActiveItem(null);
  }, []);

  const setButtonCustomBackgroundColor = useCallback(
    (color: string | null) => {
      editor.commands.updateAttributes('button', { customBackgroundColor: color });
    },
    [editor]
  );

  const setButtonCustomTextColor = useCallback(
    (color: string | null) => {
      editor.commands.updateAttributes('button', { customTextColor: color });
    },
    [editor]
  );

  const selectCustomColor = useCallback(
    (color: string | null) => {
      if (colorSelectionActiveItem === 'text') {
        setButtonCustomTextColor(color);
      } else {
        setButtonCustomBackgroundColor(color);
      }
    },
    [colorSelectionActiveItem, setButtonCustomBackgroundColor, setButtonCustomTextColor]
  );

  const hasOverrides = useMemo(
    () => buttonCustomBackgroundColor || buttonCustomTextColor,
    [buttonCustomBackgroundColor, buttonCustomTextColor]
  );

  const toggleButtonFullWidth = useCallback(() => {
    editor.chain().focus().updateAttributes('button', { fullWidth: !buttonFullWidth }).run();
  }, [buttonFullWidth, editor]);

  const setButtonSize = useCallback(
    (size: 'small' | 'normal' | 'large') => {
      editor.chain().focus().updateAttributes('button', { size }).run();
    },
    [editor]
  );

  const setButtonAlignment = useCallback(
    (alignment: 'left' | 'center' | 'right') => {
      editor.chain().focus().updateAttributes('button', { alignment }).run();
    },
    [editor]
  );

  const renderColorSelectorPanel = useCallback(
    () => (
      <CustomColorPanel
        selectedColor={colorSelectionActiveItem === 'text' ? buttonCustomTextColor : buttonCustomBackgroundColor}
        onBack={closeSelectColorMenu}
        onSelect={selectCustomColor}
      />
    ),
    [
      colorSelectionActiveItem,
      buttonCustomBackgroundColor,
      buttonCustomTextColor,
      closeSelectColorMenu,
      selectCustomColor,
    ]
  );

  const renderDefaultColorPanel = useCallback(
    (attrs: any = {}) => {
      return (
        <Panel tabIndex={-1} {...attrs}>
          <PanelSection>
            <PanelHeadline>Text Color</PanelHeadline>
            <div className="flex items-center gap-[1px]">
              {themeColors.map((themeColor, index) => {
                return (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    $variant="quaternary"
                    $isColorTileButton
                    $active={buttonCustomTextColor === themeColor.value}
                    onClick={() => setButtonCustomTextColor(themeColor.value)}
                    style={{
                      '--background': themeColor.value,
                    }}
                  />
                );
              })}
            </div>
            <div className="flex items-center gap-[1px] pt-0.5 border-t border-white border-opacity-10 mt-0.5">
              <Button
                $variant="quaternary"
                $isColorTileButton
                $active={buttonCustomTextColor && !themeColors.some(({ value }) => value === buttonCustomTextColor)}
                onClick={() => openSelectColorMenu('text')}
                style={styles.customColorCSSVar}
              />
              <Button
                $variant="quaternary"
                $size="small"
                $isIconButton
                $leftSlot={<Icon name="Cancel" />}
                disabled={!buttonCustomTextColor}
                onClick={() => setButtonCustomTextColor(null)}
              />
              {textColors.map((color) => {
                return (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={color}
                    $variant="quaternary"
                    $isColorTileButton
                    $active={buttonCustomTextColor === color}
                    onClick={() => setButtonCustomTextColor(color)}
                    style={{
                      '--background': color,
                    }}
                  />
                );
              })}
            </div>
          </PanelSection>

          <PanelSection>
            <PanelHeadline>Background Color</PanelHeadline>
            <div className="flex items-center gap-[1px]">
              {themeColors.map((themeColor, index) => {
                return (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    $variant="quaternary"
                    $isColorTileButton
                    $active={buttonCustomBackgroundColor === themeColor.value}
                    onClick={() => setButtonCustomBackgroundColor(themeColor.value)}
                    style={{
                      '--background': themeColor.value,
                    }}
                  />
                );
              })}
            </div>
            <div className="flex items-center gap-[1px] pt-0.5 border-t border-white border-opacity-10 mt-0.5">
              <Button
                $variant="quaternary"
                $isColorTileButton
                $active={
                  buttonCustomBackgroundColor && !themeColors.some(({ value }) => value === buttonCustomBackgroundColor)
                }
                onClick={() => openSelectColorMenu('background')}
                style={styles.customColorCSSVar}
              />

              <Button
                $variant="quaternary"
                $size="small"
                $isIconButton
                $leftSlot={<Icon name="Cancel" />}
                disabled={!buttonCustomBackgroundColor}
                onClick={() => setButtonCustomBackgroundColor(null)}
              />
              {backgroundColors.map((color) => {
                return (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={color}
                    $variant="quaternary"
                    $isColorTileButton
                    $active={buttonCustomBackgroundColor === color}
                    onClick={() => setButtonCustomBackgroundColor(color)}
                    style={{
                      '--background': color,
                    }}
                  />
                );
              })}
            </div>
          </PanelSection>

          {hasOverrides && (
            <PanelFooter>
              <Button
                $variant="quaternary"
                $size="small"
                $rightSlot={<Icon name="Reset" />}
                onClick={() => {
                  setButtonCustomBackgroundColor(null);
                  setButtonCustomTextColor(null);
                }}
                $fullWidth
                $active
                $muted
              >
                Reset all
              </Button>
            </PanelFooter>
          )}
        </Panel>
      );
    },
    [
      backgroundColors,
      buttonCustomBackgroundColor,
      buttonCustomTextColor,
      hasOverrides,
      openSelectColorMenu,
      setButtonCustomBackgroundColor,
      setButtonCustomTextColor,
      textColors,
      themeColors,
    ]
  );

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="buttonMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        onHidden: () => {
          setShowLinkEdit(false);
        },
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        maxWidth: 'none',
      }}
    >
      <Toolbar>
        {(isButtonActive && !buttonHref) || showLinkEdit ? (
          <EditLink
            link={buttonHref}
            target={buttonTarget}
            onSetLink={(newUrl: string, newTarget: string) => {
              editor.chain().focus().updateAttributes('button', { href: newUrl, target: newTarget }).run();
              setShowLinkEdit(false);
            }}
            onSetTarget={(target: string) => {
              editor.chain().focus().updateAttributes('button', { href: buttonHref, target }).run();
            }}
            onBack={() => {
              setShowLinkEdit(false);
              if (tippyInstance.current) {
                forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
              }
            }}
            autoFocus={showLinkEdit || false}
            editor={editor}
          />
        ) : (
          <>
            {isNotRequiredButton && (
              <>
                <Tooltip title="Edit link">
                  <Button
                    $active={!!buttonHref}
                    $leftSlot={<Icon name="Link" />}
                    onClick={() => {
                      setShowLinkEdit(true);

                      if (tippyInstance.current) {
                        forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
                      }
                    }}
                    {...buttonProps}
                  />
                </Tooltip>
                <Tooltip title="Open link">
                  <Button
                    $leftSlot={<Icon name="External" />}
                    as="a"
                    href={buttonHref}
                    target="_blank"
                    {...buttonProps}
                  />
                </Tooltip>
                <Divider />
              </>
            )}
            <Tooltip title="Align left">
              <Button
                $active={buttonAlignment === 'left'}
                $leftSlot={<Icon name="TextAlignLeft" />}
                onClick={() => setButtonAlignment('left')}
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Align center">
              <Button
                $active={buttonAlignment === 'center'}
                $leftSlot={<Icon name="TextAlignCenter" />}
                onClick={() => setButtonAlignment('center')}
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Align right">
              <Button
                $active={buttonAlignment === 'right'}
                $leftSlot={<Icon name="TextAlignRight" />}
                onClick={() => setButtonAlignment('right')}
                {...buttonProps}
              />
            </Tooltip>
            <Divider />
            <Tooltip title="Small size">
              <Button
                $active={buttonSize === 'small'}
                $leftSlot={<Icon name="SizeSmall" />}
                onClick={() => setButtonSize('small')}
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Normal size">
              <Button
                $active={buttonSize === 'normal'}
                $leftSlot={<Icon name="SizeNormal" />}
                onClick={() => setButtonSize('normal')}
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Large size">
              <Button
                $active={buttonSize === 'large'}
                $leftSlot={<Icon name="SizeLarge" />}
                onClick={() => setButtonSize('large')}
                {...buttonProps}
              />
            </Tooltip>

            <Divider />

            <Tooltip title="Full width">
              <Button
                $active={buttonFullWidth}
                $leftSlot={<Icon name="FullWidth" />}
                onClick={toggleButtonFullWidth}
                {...buttonProps}
              />
            </Tooltip>

            <Divider />

            <Tippy
              content={isColorSelecorOpen ? renderColorSelectorPanel() : renderDefaultColorPanel()}
              offset={[0, 8]}
              placement="bottom-start"
              trigger="click"
              interactive
              onShow={() => setIsColorPanelOpen(true)}
              onHidden={() => setIsColorPanelOpen(false)}
              hideOnClick
            >
              <div>
                <Tooltip enabled={!isColorPanelOpen} title="Background color">
                  <Button
                    $active={isColorPanelOpen || hasOverrides}
                    $muted={isColorPanelOpen && !hasOverrides}
                    $leftSlot={<Icon name="Settings" />}
                    $rightSlot={<Icon name="ChevronDown" $size="0.66rem" />}
                    {...buttonProps}
                  />
                </Tooltip>
              </div>
            </Tippy>

            {isNotRequiredButton && (
              <>
                <Divider />
                <Tooltip title="Delete button">
                  <Button
                    $leftSlot={<Icon name="Trash" />}
                    onClick={() => {
                      editor.chain().focus().deleteNode('button').run();
                    }}
                    {...buttonProps}
                  />
                </Tooltip>
              </>
            )}
          </>
        )}
      </Toolbar>
    </BaseBubbleMenu>
  );
});

export default ButtonMenu;
