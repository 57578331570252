import { Clock } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import { ColorSettings } from '../ColorSettings';
import { FontWeightSettings } from '../FontWeightSettings';

import { TimestampSettings } from './TimestampSettings';

type Props = AttributeSettingProps & {};

export const TimestampPostSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <PopoverHelper title="Timestamp" text="Timestamp" popoverTitle="Timestamp" Icon={Clock}>
      <>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="timestampsEnabled"
          title="Enabled"
        />
        {activeNodeAttributes?.timestampsEnabled && (
          <>
            <TimestampSettings editor={editor} activeNodeResult={activeNodeResult} property="timestampsDisplay" />
            <ColorSettings
              title="Color"
              property="timestampsTextColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
              initialColor={activeNodeAttributes?.timestampsTextColor}
            />
            <FontWeightSettings
              editor={editor}
              title="Font Weight"
              activeNodeResult={activeNodeResult}
              property="timestampsFontWeight"
              isMarkStyle={false}
            />
          </>
        )}
      </>
    </PopoverHelper>
  );
};

export default TimestampPostSettings;
