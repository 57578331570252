import { memo, useEffect, useMemo, useState } from 'react';
import { Editor } from '@tiptap/core';

import { useSettings } from '@/context/settings-context';

import { useActiveNode } from '../extensions/ActiveNode/hooks/useActiveNode';
import { MultiNodeSelection } from '../extensions/CustomSelections/selections';

import { AccordionContentSettings, AccordionSettings, AccordionTitleSettings } from './components/AccordionSettings';
import { AuthorsWidgetSettings } from './components/AuthorsWidgetSettings';
import { BlockSettings } from './components/BlockSettings';
import { ButtonSettings } from './components/ButtonSettings';
import { ColumnsSettings } from './components/ColumnsSettings';
import { ContainerSettings } from './components/ContainerSettings';
import { DividerSettings } from './components/DividerBlockSettings';
import { FooterSettings } from './components/FooterSettings';
import { IconBlockSettings } from './components/IconBlockSettings';
import { ImageSettings } from './components/ImageSettings';
import { LoginModalSettings } from './components/LoginModalSettings';
import { LoginSettings } from './components/LoginSettings';
import { MultinodeSettings } from './components/MultinodeSettings';
import { NavbarSettings } from './components/NavbarSettings';
import { PostSettings } from './components/PostsSettings';
import { PricingSettings } from './components/PricingSettings';
import { RecommendationsModalSettings } from './components/RecommendationsModalSettings';
import { RecommendationsSettings } from './components/RecommendationsSettings';
import { ResetPasswordSettings } from './components/ResetPasswordSettings';
import { SectionSettings } from './components/SectionSettings';
import { SignupModalSettings } from './components/SignupModalSettings';
import { SignupWidgetSettings } from './components/SignupWidgetSettings';
import { SocialsSettings } from './components/SocialsSettings';
import { SurveyWidgetSettings } from './components/SurveyWidgetSettings';
import { TagsSettings } from './components/TagsSettings';
import { TestimonialsSettings } from './components/TestimonialsSettings';
import { TypographySettings } from './components/TypographySettings';
import { UpdatePasswordSettings } from './components/UpdatePasswordSettings';
import { useAttributesPanelNavbarFooterContext } from './navbarFooterContextProvider';

// import { Debug } from './components/Debug';

const preventPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
};

export const AttributesPanel = memo(({ editor, isFooter }: { editor: Editor; isFooter?: boolean }) => {
  const activeNodeResult = useActiveNode(editor);
  const { isNavbarSelected, isFooterSelected } = useAttributesPanelNavbarFooterContext() || {};
  const { settings } = useSettings();
  const isHiivDesigner = settings?.hiiv_dream_designer;

  const isNavbarOrFooterSelected = useMemo(
    () => isNavbarSelected || isFooterSelected,
    [isNavbarSelected, isFooterSelected]
  );

  const [isMultinodeSelection, setIsMultinodeSelection] = useState(false);

  useEffect(() => {
    const updateIsMultinodeSelection = () => {
      setIsMultinodeSelection(editor.state.selection instanceof MultiNodeSelection);
    };

    editor.on('selectionUpdate', updateIsMultinodeSelection);

    return () => {
      editor.off('selectionUpdate', updateIsMultinodeSelection);
    };
  }, [editor]);

  return (
    <div className="flex flex-col p-3" onClick={preventPropagation} role="none">
      {!isNavbarOrFooterSelected && isMultinodeSelection && (
        <MultinodeSettings editor={editor} activeNodeResult={activeNodeResult} />
      )}

      {!isNavbarOrFooterSelected && !isMultinodeSelection && (
        <>
          <BlockSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            isFooter={isFooter}
            isDesigner={isHiivDesigner}
          />
          <TypographySettings editor={editor} activeNodeResult={activeNodeResult} />
          <SocialsSettings editor={editor} activeNodeResult={activeNodeResult} />
          <TestimonialsSettings editor={editor} activeNodeResult={activeNodeResult} />
          <SurveyWidgetSettings editor={editor} activeNodeResult={activeNodeResult} />
          <PricingSettings editor={editor} activeNodeResult={activeNodeResult} />
          <AuthorsWidgetSettings editor={editor} activeNodeResult={activeNodeResult} />
          <PostSettings editor={editor} activeNodeResult={activeNodeResult} />
          <SignupWidgetSettings editor={editor} activeNodeResult={activeNodeResult} />
          <SignupModalSettings editor={editor} activeNodeResult={activeNodeResult} />
          <RecommendationsSettings editor={editor} activeNodeResult={activeNodeResult} />
          <RecommendationsModalSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ResetPasswordSettings editor={editor} activeNodeResult={activeNodeResult} />
          <UpdatePasswordSettings editor={editor} activeNodeResult={activeNodeResult} />
          <LoginSettings editor={editor} activeNodeResult={activeNodeResult} />
          <LoginModalSettings editor={editor} activeNodeResult={activeNodeResult} />
          <SectionSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ContainerSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ColumnsSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ImageSettings editor={editor} activeNodeResult={activeNodeResult} />
          <ButtonSettings editor={editor} activeNodeResult={activeNodeResult} />
          <AccordionSettings editor={editor} activeNodeResult={activeNodeResult} />
          <AccordionTitleSettings editor={editor} activeNodeResult={activeNodeResult} />
          <AccordionContentSettings editor={editor} activeNodeResult={activeNodeResult} />
          <TagsSettings editor={editor} activeNodeResult={activeNodeResult} />
          <IconBlockSettings editor={editor} activeNodeResult={activeNodeResult} />
          <DividerSettings editor={editor} activeNodeResult={activeNodeResult} />
        </>
      )}

      <NavbarSettings />
      <FooterSettings />
      {/* <Debug editor={editor} /> */}
    </div>
  );
});
