import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { LinkToSettings } from '../general/LinkToSettings';
import { NewTabSettings } from '../general/NewTabSettings';

import { ActionSettings } from './ActionSettings';
import { OnClickSettings } from './OnClickSettings';
import { VisibilitySettingsReadOnly } from './VisibilitySettingsReadOnly';

export function NavItemLinkOrActionSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;
  const itemType = (selectedContent?.attrs && 'type' in selectedContent.attrs && selectedContent.attrs.type) || '';
  if (itemType !== 'link' && itemType !== 'button' && itemType !== 'user_button' && itemType !== 'logo') return null;

  const allowLink = !(selectedContent.attrs?.autoGenerated && selectedContent.attrs?.action);

  const onClick = selectedContent?.attrs?.onClick || 'link';

  return (
    <SectionRenderer title="Click Action" collapsible defaultIsOpen>
      {allowLink && <OnClickSettings />}
      {allowLink && onClick === 'link' && <LinkToSettings />}
      {allowLink && onClick === 'link' && <NewTabSettings />}
      {(!allowLink || onClick === 'action') && <ActionSettings />}
      {(!allowLink || onClick === 'action') && <VisibilitySettingsReadOnly />}
    </SectionRenderer>
  );
}
