import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AdvancedWidthSettings } from '../AdvancedWidthSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';

export const LoginModalSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['loginModal']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Modal">
        <ColorSettings
          title="Overlay Background"
          property="modalOverlayBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
      </SectionRenderer>
      <SectionRenderer title="Content">
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />

        <ColorSettings
          title="Background"
          property="backgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <AdvancedWidthSettings editor={editor} activeNodeResult={activeNodeResult} property="width" title="Width" />
      </SectionRenderer>
    </RenderCondition>
  );
};
