import {
  BanknotesIcon,
  CursorArrowRaysIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

import { Typography } from '@/components/Typography';

export interface MetricsBadgeProps {
  totalSent?: number;
  openRate?: number;
  clickRate?: number;
  totalUpgrades?: number;
  totalWebViewed?: number;
  totalWebClicked?: number;
}

const MetricsBadge = ({
  totalSent,
  openRate,
  clickRate,
  totalUpgrades,
  totalWebViewed,
  totalWebClicked,
}: MetricsBadgeProps) => {
  const formatter = Intl.NumberFormat('en');

  return (
    <div className="flex gap-5">
      {typeof totalSent === 'number' && (
        <div>
          <span className="flex items-center gap-3">
            <EnvelopeIcon className="h-5 w-5 text-gray-400" />
            <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
              {formatter.format(totalSent)}
            </Typography>
          </span>
        </div>
      )}
      {typeof totalWebViewed === 'number' && (
        <div>
          <span className="flex items-center gap-3">
            <EyeIcon className="h-5 w-5 text-gray-400" />
            <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
              {formatter.format(totalWebViewed)}
            </Typography>
          </span>
        </div>
      )}
      {typeof openRate === 'number' && (
        <div>
          <span className="flex items-center gap-3">
            <EnvelopeOpenIcon className="h-5 w-5 text-gray-400" />
            <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
              {openRate}%
            </Typography>
          </span>
        </div>
      )}
      {typeof clickRate === 'number' && (
        <div>
          <span className="flex items-center gap-3">
            <CursorArrowRaysIcon className="h-5 w-5 text-gray-400" />
            <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
              {clickRate}%
            </Typography>
          </span>
        </div>
      )}
      {typeof totalWebClicked === 'number' && (
        <div className="flex-1 min-w-0">
          <span className="flex items-center gap-3">
            <CursorArrowRaysIcon className="h-5 w-5 text-gray-400" />
            <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
              {totalWebClicked}
            </Typography>
          </span>
        </div>
      )}
      {typeof totalUpgrades === 'number' && (
        <div className="flex-1 min-w-0">
          <span className="flex items-center gap-3">
            <BanknotesIcon className="h-5 w-5 text-gray-400" />
            <Typography token="font-normal/text/sm" color="gray" colorWeight="900">
              {formatter.format(totalUpgrades)}
            </Typography>
          </span>
        </div>
      )}
    </div>
  );
};

export default MetricsBadge;
