import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface Props {
  onSuccess?: () => void;
}

interface SignupFlowPayload {
  name: string;
  urls?: string[];
}

export default function useCreateSignupFlow({ onSuccess }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    ({ name, urls }: SignupFlowPayload) =>
      api.post(`/signup_flows`, {
        publication_id: currentPublicationId,
        signup_flow: {
          name,
          urls,
        },
      }),
    {
      onSuccess: () => {
        onSuccess?.();
        toast.success('Created!');
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
}
