import { Extension, isTextSelection, KeyboardShortcutCommand } from '@tiptap/core';

interface ShortcutsOptions {
  additionalShortcuts: Record<string, KeyboardShortcutCommand>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    shortcuts: {
      selectParentNodeMod: () => ReturnType;
    };
  }
}

const extensionName = 'shortcuts';

const defaultShortcuts: ShortcutsOptions['additionalShortcuts'] = {
  'Mod-a': ({ editor }) => editor.commands.selectParentNodeMod(),
};

export const Shortcuts = Extension.create<ShortcutsOptions>({
  name: extensionName,

  addOptions() {
    return {
      additionalShortcuts: {},
    };
  },

  addKeyboardShortcuts() {
    return {
      ...defaultShortcuts,
      ...(this.options.additionalShortcuts || {}),
    };
  },

  addCommands() {
    return {
      selectParentNodeMod:
        () =>
        ({ state: { selection }, chain }) => {
          const { $from, $to, from, to } = selection;

          const textStart = $from.start();
          const textEnd = $from.end();

          if (isTextSelection(selection) && $from.sameParent($to) && !(textStart === from && textEnd === to)) {
            return chain().setTextSelection({ from: textStart, to: textEnd }).run();
          }

          return chain().selectParentNode().run();
        },
    };
  },
});

export default Shortcuts;
