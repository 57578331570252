import { PropsWithChildren, Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import useIsSettingPage from '@/hooks/useIsSettingPage';
import Loading from '@/pages/Loading';

import { SWITCH_PUBLICATION_QUERY_PARAM } from '../SwitchPublicationViaQuery';

const SETTINGS_PUBLICATION_ID_PARAM_NAME = 's_publication_id';

type Props = PropsWithChildren<{
  hasSettingsV2: boolean;
}>;

/*

  This component makes sure that the query parameters `s_publication_id`
  and `s_organization_id` are removed from the URL

  Scenario: A user lands on `https://app.beehiiv.com/settings/publication/general?s_publicationid=foo`
            and the url has the query param `s_publication_id`
  
  Solution: This component will assure that the said parameter is removed from
            the url
*/

const REDIRECT_URLS: Record<string, string> = {
  // settings v0 routes
  '/admin': '/settings/workspace/overview',
  '/api_keys': '/settings/workspace/api',
  '/billing': '/settings/workspace/billing_and_plan',
  '/company/edit': '/settings/workspace/overview',
  '/content_imports/*': '/settings/publication/content_import',
  '/custom_domains': '/settings/publication/domain',
  '/domain_settings/edit': '/settings/publication/domain',
  '/export_data': '/settings/publication/export_data',
  '/publication/edit': '/settings/publication/general',
  '/publications/new': '/settings/workspace/overview#publications',
  '/settings/import_subscribers': '/settings/publication/subscribers_import',
  '/settings/rss_feeds': '/settings/publication/rss',
  '/users/:id': '/settings/workspace/team',

  // settings v1 routes
  '/settings': '/current_user/personal_info',
  '/settings/notifications': '/current_user/notification_preferences',
  '/settings/password': '/current_user/account_security',
  '/settings/company': '/settings/workspace/overview',
  '/settings/company/publications': '/settings/workspace/overview#publications',
  '/settings/company/team': '/settings/workspace/team',
  '/settings/billing': '/settings/workspace/billing_and_plan',
  '/settings/integrations/api': '/settings/workspace/api',
  '/settings/integrations/webhooks': '/settings/publication/webhooks',
  '/settings/integrations/imports': '/settings/publication/content_import',
  '/settings/publication': '/settings/publication/general',
  '/settings/publication#sender-name': '/settings/publication/emails',
  '/settings/publication#copyright': '/settings/publication/emails#footer-details',
  '/settings/publication#welcome-email': '/settings/publication/emails#preset',
  '/settings/publication#double-opt-in': '/settings/publication/emails#preset',
  '/settings/publication#analytics': '/settings/publication/general#analytics',
  '/settings/publication/domains': '/settings/publication/domain',
  '/settings/publication/premium': '/monetize/subscriptions/paid_tiers',
  '/settings/publication/rss_feed': '/settings/publication/rss',
  '/settings/publication/import_subscribers': '/settings/publication/subscribers_import',
  '/settings/publication/import_content': '/settings/publication/content_import',
  '/settings/publication/widgets': '/website_builder/settings/embed_recommendations_widget',
};

const findReplacedPath = (currentPath: string) => {
  const replacedPath = REDIRECT_URLS[currentPath];
  if (replacedPath) {
    return replacedPath;
  }

  if (currentPath.startsWith('/content_imports/')) {
    return REDIRECT_URLS['/content_imports/*'];
  }

  if (currentPath.startsWith('/users/')) {
    return REDIRECT_URLS['/user/:id'];
  }

  return null;
};

export default function EnsureSettingsQueryParams({ children, hasSettingsV2 }: Props) {
  const [isLoading, setIsLoading] = useState(hasSettingsV2);
  const { search } = window.location;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const publicationIdInUrl = searchParams.get(SETTINGS_PUBLICATION_ID_PARAM_NAME);

  const switchablePublicationId = searchParams.get(SWITCH_PUBLICATION_QUERY_PARAM);

  const isASettingsPage = useIsSettingPage();

  const currentPath = `${window.location.pathname}${window.location.hash}`;
  const replacedPath = findReplacedPath(currentPath);
  const isOldSettingsRoute = !!replacedPath;

  const redirectIfRequired = useCallback(() => {
    if (!hasSettingsV2 || !isASettingsPage) {
      setIsLoading(false);
      return;
    }

    if (isOldSettingsRoute) {
      const redirectUrl = new URL(
        `${replacedPath}${switchablePublicationId ? `?spid=${switchablePublicationId}` : ''}`,
        window.location.origin
      );
      window.location.href = redirectUrl.toString();
      return;
    }

    // No publication id in the url, show current publication's settings
    if (!publicationIdInUrl) {
      setIsLoading(false);
      return;
    }

    const redirectUrl = new URL(currentPath, window.location.origin);
    searchParams.delete(SETTINGS_PUBLICATION_ID_PARAM_NAME);
    redirectUrl.search = searchParams.toString();
    window.location.href = redirectUrl.toString();
  }, [
    currentPath,
    hasSettingsV2,
    isASettingsPage,
    isOldSettingsRoute,
    publicationIdInUrl,
    replacedPath,
    searchParams,
    switchablePublicationId,
  ]);

  useEffect(() => {
    redirectIfRequired();
  }, [redirectIfRequired, replacedPath]);

  return <Suspense fallback={<Loading />}>{!isLoading ? children : null}</Suspense>;
}
