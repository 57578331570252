import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import FontGroupSettings from '../FontGroupSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

import { ButtonSettings } from './ButtonSettings';
import { DescriptionSettings } from './DescriptionSettings';
import { HelperSettings } from './HelperSettings';
import { InputSettings } from './InputSettings';
import { LabelSettings } from './LabelSettings';
import { TitleSettings } from './TitleSettings';

export const SurveyWidgetSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['survey']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Container">
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <ColorSettings title="Background" editor={editor} activeNodeResult={activeNodeResult} property="background" />
      </SectionRenderer>

      <SectionRenderer title="Header">
        <TitleSettings editor={editor} activeNodeResult={activeNodeResult} />
        <DescriptionSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
      <SectionRenderer title="Form">
        <FontGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isGroupedInitially
          isUngroupable={false}
          properties={[
            {
              title: 'Label',
              property: 'labelFontFamily',
            },
            {
              title: 'Input',
              property: 'inputTextFontFamily',
            },
            {
              title: 'Helper',
              property: 'helperTextFontFamily',
            },
          ]}
        />
        <LabelSettings editor={editor} activeNodeResult={activeNodeResult} />
        <InputSettings editor={editor} activeNodeResult={activeNodeResult} />
        <HelperSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ButtonSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
