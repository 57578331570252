import moment from 'moment-mini';

import Modal from '../../Modal';
import Grid from '../components/Grid';
import SidePanel from '../components/SidePanel';
import TopBar from '../components/TopBar';
import { CalendarContext, useCalendar } from '../utils/useCalendar';

const CalendarModalContent = () => {
  const calendar = useCalendar({
    year: moment().year(),
    month: moment().month(),
  });

  return (
    <CalendarContext.Provider value={calendar}>
      <div className="grid grid-cols-8 lg:h-full sm:grid-rows-[auto,auto,1fr] max-h-screen" data-testid="calendar">
        <TopBar />
        <Grid />
        <SidePanel />
      </div>
    </CalendarContext.Provider>
  );
};

export default function CalendarModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} fullScreen>
      <CalendarModalContent />
    </Modal>
  );
}
