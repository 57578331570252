import { PropsWithChildren } from "react"

import { Text } from "@/routes/website/_components/UI/Text"
import { cn } from "@/utils/cn"

export const OptionsWithPreview = ({
  children,
  label,
  selected,
  onClick
}: PropsWithChildren<{ label: string, selected: boolean, onClick: () => void }>) => {
  return <div className={cn("flex items-center gap-4 p-2 rounded-lg cursor-pointer overflow-hidden", selected ? "bg-wb-button-accent-soft border border-solid border-wb-accent-soft" : "bg-wb-secondary border border-transparent")}
    role="button"
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
    onClick={onClick}
  >

    {/* preview */}
    <div className="flex items-center justify-center bg-wb-surface rounded-md px-[2px] w-[120px] h-[60px]">
      {children}
    </div>

    <Text size="xs" weight="medium">
      {label}
    </Text>
  </div>
}
