import { useCallback } from 'react';
import { Question } from '@phosphor-icons/react';

import { cn } from '@/utils/cn';

import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';

type OnClickSettingsProps = AttributeSettingProps & {
  property?: string;
  disabled?: boolean;
};

export const OnClickSettings = ({ editor, activeNodeResult, property = 'onClick', disabled }: OnClickSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const currentValue = activeNodeAttributes?.[property] || 'link';

  const onValueChange = useCallback(
    (value: string) => {
      if (!activeNodeType) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, editor]
  );

  return (
    <div className={cn('flex items-center justify-stretch gap-2', disabled ? 'opacity-50 pointer-events-none' : '')}>
      <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
        <Text variant="secondary" size="2xs" weight="medium">
          On Click
        </Text>
        <Tooltip
          placement="bottom-end"
          center={
            currentValue === 'action'
              ? 'Clicking the button will do the selected action'
              : 'Clicking the button will navigate to the selected link'
          }
        >
          <Question size={16} weight="bold" className="text-wb-secondary" />
        </Tooltip>
      </div>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="link"
          value={currentValue}
          onValueChange={onValueChange}
        >
          <ToggleGroupItem value="link" className="grow">
            <Text variant="inherit" size="2xs" weight="medium">
              Link
            </Text>
          </ToggleGroupItem>
          <ToggleGroupItem value="action" className="grow">
            <Text variant="inherit" size="2xs" weight="medium">
              Action
            </Text>
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
