import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getBooleanAttribute, getStringAttribute } from '../../utils/attributesUtils';
import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
} from '../constants';

import { ButtonView } from './views/ButtonView';

export const Button = Node.create({
  name: 'button',

  group: 'block',

  content: 'inline*',

  draggable: true,

  addKeyboardShortcuts() {
    return {
      // On Enter at the end of line, create new paragraph and focus
      Enter: ({ editor }) => {
        const {
          state: {
            selection: { $from, empty },
          },
        } = editor;

        if (!empty || $from.parent.type !== this.type) {
          return false;
        }

        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (!isAtEnd) {
          return false;
        }

        const pos = editor.state.selection.$from.end() + (empty ? 1 : 0);

        return editor.chain().focus(pos).insertContentAt(pos, { type: 'paragraph' }).run();
      },
    };
  },

  addAttributes() {
    return {
      margin: getStringAttribute('margin', 'data-margin', '0px'),
      padding: getStringAttribute('padding', 'data-padding', '12px 12px 12px 12px'),
      width: getStringAttribute('width', 'data-width', 'fit-content'),
      maxWidth: getStringAttribute('maxWidth', 'data-max-width', '1280px'),
      shadow: getStringAttribute('shadow', 'data-shadow'),
      borderRadius: getStringAttribute('borderRadius', 'data-border-radius', DEFAULT_BORDER_RADIUS),
      borderWidth: getStringAttribute('borderWidth', 'data-border-width', '1px'),
      borderColor: getStringAttribute('borderColor', 'data-border-color', DEFAULT_BORDER_COLOR),
      borderStyle: getStringAttribute('borderStyle', 'data-border-style', 'none'),
      size: getStringAttribute('size', 'data-size', 'normal'),
      alignment: getStringAttribute('alignment', 'data-alignment', 'left'),
      url: getStringAttribute('url', 'data-url'),
      open_in_new_tab: getBooleanAttribute('open_in_new_tab', 'data-open-in-new-tab', false),
      action: getStringAttribute('action', 'data-action'),
      isCustomLink: getBooleanAttribute('isCustomLink', 'data-is-custom', false),
      fontFamily: getStringAttribute('fontFamily', 'data-font-family'),
      customBackgroundColor: getStringAttribute(
        'customBackgroundColor',
        'data-custom-background-color',
        DEFAULT_PRIMARY_COLOR
      ),
      customTextColor: getStringAttribute('customTextColor', 'data-custom-text-color', DEFAULT_TEXT_ON_PRIMARY_COLOR),
      icon: getStringAttribute('icon', 'data-icon'),
      iconColor: getStringAttribute('iconColor', 'data-icon-color'),
      iconPlacement: getStringAttribute('iconPlacement', 'data-icon-placement', 'left'),
      hoverBorderColor: getStringAttribute('hoverBorderColor', 'data-hover-border-color'),
      hoverBorderStyle: getStringAttribute('hoverBorderStyle', 'data-hover-border-style'),
      hoverBorderWidth: getStringAttribute('hoverBorderWidth', 'data-hover-border-width'),
      hoverBorderRadius: getStringAttribute('hoverBorderRadius', 'data-hover-border-radius'),
      hoverCustomBackgroundColor: getStringAttribute(
        'hoverCustomBackgroundColor',
        'data-hover-custom-background-color'
      ),
      hoverCustomTextColor: getStringAttribute('hoverCustomTextColor', 'data-hover-custom-text-color'),
      hoverIconColor: getStringAttribute('hoverIconColor', 'data-hover-icon-color'),
      onClick: getStringAttribute('onClick', 'data-on-click', 'link'),
    };
  },

  parseHTML() {
    return [{ tag: `button[data-type="${this.name}"]` }];
  },

  addOptions() {
    return {
      HTMLAttributes: {
        'data-type': this.name,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['button', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ButtonView, {
      stopEvent: () => false,
      className: 'contents',
    });
  },
});

export default Button;
