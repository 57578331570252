import { LineVertical } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';

type Props = AttributeSettingProps & {};

export const SeperatorPostSetting = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Separator" text="Separator" popoverTitle="Separator" Icon={LineVertical} align="end">
      <ColorSettings title="Color" property="separatorColor" editor={editor} activeNodeResult={activeNodeResult} />
    </PopoverHelper>
  );
};

export default SeperatorPostSetting;
