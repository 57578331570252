import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Navigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { ScrollOnMount } from '@/ui/ScrollOnMount';

import AdvancedOptionsSection from './_components/AdvancedOptionsSection';
import CancellationSection from './_components/CancellationSection';
import EnsurePremiumAccess from './_components/EnsurePremiumAccess';
import EnsureStripeConnection from './_components/EnsureStripeConnection';
import PaywallSection from './_components/PaywallSection';
import PremiumGiftsSection from './_components/PremiumGiftsSection';
import PremiumOffersSection from './_components/PremiumOffersSection';
import TierConfigurationSection from './_components/TierConfigurationSection';
import UpsellEmailSection from './_components/UpsellEmailSection';

const UpgradeRedirect = () => {
  useEffect(() => {
    toast.error('You need to upgrade your account before you can access our paid subscription features!');
  }, []);

  return <Navigate to="/settings/billing" />;
};

interface Props {
  className?: string;
}

const PublicationPremiumSettings = ({ className }: Props) => {
  const currentPublicationId = useCurrentPublicationId();
  const { data: currentPublication, isLoading, isError } = usePublication(currentPublicationId);
  const { data: settings, isLoading: loadingSettings } = usePublicationSettings(currentPublicationId);

  if (settings && !settings.premium_subscriptions) {
    <UpgradeRedirect />;
  }

  const { hash: scrollToElementId } = useLocation();

  return (
    <LoadingBox isLoading={isLoading || loadingSettings} isError={isError} backgroundClassName="bg-transparent">
      {currentPublication && (
        <div className={classNames('w-full max-w-4xl mx-auto', className)}>
          <EnsurePremiumAccess publicationId={currentPublication?.id || ''}>
            <EnsureStripeConnection>
              <ScrollOnMount elementId={scrollToElementId?.replace('#', '')} scrollIntoView>
                <div className="flex flex-col gap-y-10 items-start px-4">
                  <TierConfigurationSection />
                  <PremiumOffersSection />
                  <PaywallSection />
                  <UpsellEmailSection />
                  {settings?.gift_premium_subscriptions && <PremiumGiftsSection />}
                  <AdvancedOptionsSection publication={currentPublication} />
                  <CancellationSection />
                </div>
              </ScrollOnMount>
            </EnsureStripeConnection>
          </EnsurePremiumAccess>
        </div>
      )}
    </LoadingBox>
  );
};

export default PublicationPremiumSettings;
