import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { HexColorInput, RgbaColorPicker } from 'react-colorful';
import { getUsedColors } from '@shared/dream-components';

import { DreamEditorContext } from '@/context/dream-editor-context';

import { NavbarDataContext } from '../../navbar/_components/NavbarEditor/NavbarDataContext';

import { convertHexToRgba, convertRgbaToHex } from './ColorPicker.utils';
import { SimpleInput, SimpleInputWrapper } from './Input';
import { Text } from './Text';

import './ColorPicker.css';

const localStorageKey = 'wb-colors';

export interface ColorPickerProps {
  selectedColor?: string;
  onSetColor: (color: string | null) => void;
  onReset?: () => void;
  showAlpha?: boolean;
}

export const ColorPicker = ({
  selectedColor = '#939393FF',
  onSetColor = () => null,
  onReset,
  showAlpha = true,
}: ColorPickerProps): JSX.Element => {
  const dreamEditorContext = useContext(DreamEditorContext);
  const navbarDataContext = useContext(NavbarDataContext);

  const rgbaSelectedColor = convertHexToRgba(selectedColor ?? '#939393FF');
  const [newColor, setNewColor] = useState(rgbaSelectedColor);
  const [colorBuffer, setColorBuffer] = useState(convertRgbaToHex(rgbaSelectedColor));
  const [alpha, setAlpha] = useState(
    Number.isNaN(Math.round(rgbaSelectedColor.a * 100)) ? 100 : Math.round(rgbaSelectedColor.a * 100)
  );
  const [inPageColors, setInPageColors] = useState<string[]>([]);

  const dreamEditorColors = dreamEditorContext?.editor?.storage.colorStorage.usedColors;
  const [localStorageColors, setLocalStorageColors] = useState<{ [hex: string]: number }>({});

  useEffect(() => {
    const colors = localStorage.getItem(localStorageKey);
    if (colors) {
      try {
        const parsedColors = JSON.parse(colors) as { [hex: string]: number };
        setLocalStorageColors(parsedColors);
      } catch (error) {
        // do nothing
      }
    }
  }, []);

  const lastSetColor = useRef(selectedColor);

  const handleReset = useCallback(() => {
    if (typeof onReset === 'function') {
      onReset();
    } else {
      onSetColor(null);
    }
  }, [onReset, onSetColor]);

  const handleSetColor = useCallback(
    (color: string, prevColor?: string) => {
      const rgbaColor = convertHexToRgba(color);

      setNewColor(rgbaColor);

      setAlpha(Math.round(rgbaColor.a * 100));

      if (dreamEditorContext) {
        const { editor } = dreamEditorContext;
        editor?.commands.setUsedColor(color, prevColor);
      }

      setLocalStorageColors((prev) => {
        const updatedColors = {
          ...prev,
          [color]: (prev[color] || 0) + 1,
        };
        if (prevColor) {
          updatedColors[prevColor] = (updatedColors[prevColor] || 0) - 1;
        }
        localStorage.setItem(localStorageKey, JSON.stringify(updatedColors));
        return updatedColors;
      });

      onSetColor(color);
    },
    [dreamEditorContext, onSetColor]
  );

  useEffect(() => {
    if (dreamEditorColors) {
      setInPageColors(
        Object.entries(dreamEditorColors || {})
          .filter(([, count]) => Number(count) > 0)
          .sort(([, a], [, b]) => Number(b) - Number(a))
          .map(([color]) => color)
      );
    }
  }, [dreamEditorColors]);

  useEffect(() => {
    if (navbarDataContext) {
      const { content } = navbarDataContext;
      const colors = content ? getUsedColors(content) : [];
      setInPageColors(colors);
    }
  }, [navbarDataContext]);

  useEffect(() => {
    if (Number.isNaN(newColor.r) || Number.isNaN(newColor.g) || Number.isNaN(newColor.b) || Number.isNaN(newColor.a))
      return;
    const hexNewColor = convertRgbaToHex(newColor, { alpha: true });
    if (lastSetColor.current !== hexNewColor) {
      const prevColor = lastSetColor.current;
      lastSetColor.current = hexNewColor;
      if (Math.round(newColor.a * 100) !== alpha) {
        setAlpha(Math.round(newColor.a * 100));
      }
      setColorBuffer(hexNewColor);
      handleSetColor(hexNewColor, prevColor);
    }
  }, [newColor, alpha, handleSetColor]);

  const handleAlphaChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      setAlpha(Number.isNaN(value) ? 0 : Math.max(0, Math.min(value, 100)));
      setNewColor((prev) => ({ ...prev, a: value / 100 }));
    },
    [setAlpha]
  );

  const setNewHexColor = useCallback((hex: string) => {
    setColorBuffer(hex);

    if (/^#([A-Fa-f0-9]{6})$/.test(hex)) {
      setNewColor((prev) => {
        return { ...convertHexToRgba(hex), a: prev.a };
      });
    }
  }, []);

  const handleClear = useCallback(() => {
    setLocalStorageColors({});
    localStorage.removeItem(localStorageKey);
  }, []);

  const localStorageColorsFiltered = Object.entries(localStorageColors)
    .filter(([, count]) => Number(count) > 0)
    .map(([color]) => color);

  return (
    <div className="wb-react-colorful">
      <RgbaColorPicker color={newColor} onChange={setNewColor} />
      <div className="flex items-center justify-between gap-2 max-w-full mt-4">
        <div className="flex items-center p-2 bg-wb-secondary rounded-md">
          <Text size="2xs" weight="medium" className="leading-[18px]">
            Hex
          </Text>
        </div>
        <SimpleInputWrapper className="h-[34px] rounded-md">
          <div
            className="h-4 w-4 rounded-md"
            style={{ backgroundColor: convertRgbaToHex(newColor, { alpha: true }) }}
          />
          <div className="wb-react-colorful-input-wrapper">
            <HexColorInput prefixed color={colorBuffer} onChange={setNewHexColor} />
          </div>
        </SimpleInputWrapper>
        {showAlpha && (
          <SimpleInputWrapper className="gap-0 h-[34px] rounded-md">
            <SimpleInput value={alpha} type="number" onChange={handleAlphaChange} />
            <Text size="2xs" weight="medium">
              %
            </Text>
          </SimpleInputWrapper>
        )}
        <Text size="2xs" weight="medium" className="hover:underline cursor-pointer" onClick={handleReset}>
          Reset
        </Text>
      </div>
      {localStorageColorsFiltered?.length > 0 && (
        <div className="flex flex-col gap-2 mt-4">
          <div className="flex items-center justify-between">
            <Text size="xs" weight="medium" variant="secondary">
              Recently Used
            </Text>
            <Text
              size="2xs"
              weight="medium"
              variant="secondary"
              className="underline cursor-pointer"
              onClick={handleClear}
            >
              Clear
            </Text>
          </div>
          <div className="grid grid-cols-[repeat(8,_1fr)] gap-2">
            {localStorageColorsFiltered?.map((color) => (
              <button
                type="button"
                key={`${color}`}
                className="w-full h-auto aspect-1 rounded-md cursor-pointer hover:opacity-80 border border-wb-primary"
                style={{ backgroundColor: color }}
                onClick={() => {
                  handleSetColor(color);
                  setColorBuffer(color);
                }}
              />
            ))}
          </div>
        </div>
      )}
      {inPageColors?.length > 0 && (
        <div className="flex flex-col gap-2 mt-4">
          <Text size="xs" weight="medium" variant="secondary">
            In This Page
          </Text>
          <div className="grid grid-cols-[repeat(8,_1fr)] gap-2">
            {inPageColors?.map((color) => (
              <button
                type="button"
                key={`${color}`}
                className="w-full h-auto aspect-1 rounded-md cursor-pointer hover:opacity-80 border border-wb-primary"
                style={{ backgroundColor: color }}
                onClick={() => {
                  handleSetColor(color);
                  setColorBuffer(color);
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
