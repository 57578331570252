import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { PlusCircle } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { useCreateSignupFlow, useSignupFlows, useUpdateSignupFlow } from '@/hooks/useSignupFlows';
import { SignupFlow } from '@/interfaces/signup_flow';

import { Button } from '../../_components/UI/Button';
import {
  Table,
  TableBody,
  TableCaption,
  TableCard,
  TableHead,
  TableHeader,
  TablePaginationWrapper,
  TableRow,
  TableSimplePagination,
} from '../../_components/UI/Table';
import { Text } from '../../_components/UI/Text';

import SignupFlowModal from './_components/SignupFlowModal';
import SignupFlowRow from './_components/SignupFlowRow';

const WebsiteSettingsSignupFlows = () => {
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching, isLoading } = useSignupFlows();

  const queryKey = ['signup_flows', currentPublication?.id];

  const signupFlows = data?.pages.flatMap((page) => page.signup_flows) || [];
  const totalCount = data?.pages[0]?.pagination?.total || 0;
  const showingCount = signupFlows?.length || 0;
  const noResults = !isRefetching && !isLoading && signupFlows.length === 0;

  const [signupFlowToEdit, setSignupFlowToEdit] = useState<SignupFlow | null>(null);
  const [isNewSignupFlowOpen, setIsNewSignupFlowOpen] = useState(false);

  const createSignupFlow = useCreateSignupFlow({
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      setIsNewSignupFlowOpen(false);
    },
  });

  const updateSignupFlow = useUpdateSignupFlow({
    signupFlowId: signupFlowToEdit?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      setSignupFlowToEdit(null);
    },
  });

  const handleValidationChecks = (payload: { name: string; urls: string[] }) => {
    if (!payload.name) {
      toast.error('Name is required');
      return false;
    }

    if (payload.urls.length === 0) {
      toast.error('URLs are required');
      return false;
    }

    return true;
  };

  const handleEditSubmit = (payload: { name: string; urls: string[] }) => {
    if (handleValidationChecks(payload)) {
      updateSignupFlow.mutate(payload);
    }
  };

  const handleNewSubmit = (payload: { name: string; urls: string[] }) => {
    if (handleValidationChecks(payload)) {
      createSignupFlow.mutate(payload);
    }
  };

  const handleClose = () => {
    setSignupFlowToEdit(null);
    setIsNewSignupFlowOpen(false);
  };

  const isSubmitting = createSignupFlow.isLoading || updateSignupFlow.isLoading;
  const isOpen = isNewSignupFlowOpen || Boolean(signupFlowToEdit);

  return (
    <>
      <SignupFlowModal
        key={signupFlowToEdit?.id}
        isSubmitting={isSubmitting}
        isOpen={isOpen}
        publicationUrl={currentPublication?.url || ''}
        signupFlow={signupFlowToEdit}
        onClose={handleClose}
        onSubmit={isNewSignupFlowOpen ? handleNewSubmit : handleEditSubmit}
      />

      <div className="flex flex-col space-y-2">
        <div className="flex justify-end">
          <div>
            <Button variant="primary" onClick={() => setIsNewSignupFlowOpen(true)} LeftIcon={PlusCircle}>
              Add signup flow
            </Button>
          </div>
        </div>

        <TablePaginationWrapper>
          <TableCard>
            <Table>
              {noResults && (
                <TableCaption>
                  <div className="pb-4">
                    <Text size="sm" weight="medium" variant="secondary" as="span">
                      No results found
                    </Text>
                  </div>
                </TableCaption>
              )}
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[200px] truncate whitespace-nowrap">Subscriber</TableHead>
                  <TableHead className="whitespace-nowrap">Steps</TableHead>
                  <TableHead className="text-right">Default</TableHead>
                  <TableHead className="text-right w-4" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {signupFlows.map((signupFlow) => {
                  return (
                    <SignupFlowRow
                      key={signupFlow.id}
                      signupFlow={signupFlow}
                      onEdit={() => setSignupFlowToEdit(signupFlow)}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableCard>
          <TableSimplePagination
            showingCount={showingCount}
            totalCount={totalCount}
            hasNextPage={Boolean(hasNextPage)}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
          />
        </TablePaginationWrapper>
      </div>
    </>
  );
};

export default WebsiteSettingsSignupFlows;
