import toast from 'react-hot-toast';
import { Trash } from '@phosphor-icons/react';
import { NAVBAR_MENU_LIST_DEFAULTS, TNavbarMenuElement } from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import { parseCssValue } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/parseCssValue';
import { Button } from '@/routes/website/_components/UI/Button';

import { useNavbarContext } from '../../../NavbarContext';
import { getParent } from '../../../utils';
import { ActionsSettings } from '../general/ActionsSettings';
import { Disabled } from '../general/Disabled';
import { SliderSettings } from '../general/SliderSettings';

import { AlignmentSettings } from './AlignmentSettings';
import HorizontalDividerSettings from './HorizontalDividerSettings';
import LineDividerSettings from './LineDividerSettings';
import { RowNumberSettings } from './RowNumberSettings';

export function NavMenuLayoutSettings() {
  const { content, selectedContent, onDeleteNode, onUpdateNodeAttributes, isMobile, withMerging } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const handleDelete = () => {
    if (!content) return;
    const parent = getParent(content, selectedContent) as TNavbarMenuElement;
    const originalRowLength = parent?.attrs?.rows || 0;

    if (selectedContent.type === 'navbar_menu_list' && originalRowLength > 1) {
      const hasUserButton = selectedContent.content?.some((item) => {
        if (item.type === 'navbar_menu_list_group') {
          return item.content?.some(
            (groupItem) => groupItem.type === 'navbar_item' && groupItem.attrs?.type === 'user_button'
          );
        }
        return false;
      });

      if (hasUserButton) {
        toast.error(
          'You have some user authentication buttons in this row. Please move them to another row before deleting this one.'
        );
        return;
      }
    }

    if (parent?.type === 'navbar_menu') {
      withMerging(() => {
        onDeleteNode(selectedContent.attrs?.id || '');
        onUpdateNodeAttributes(parent.attrs?.id || '', { rows: Math.max(originalRowLength - 1, 0) });
      });
    } else {
      onDeleteNode(selectedContent.attrs?.id || '');
    }
  };

  return (
    <SectionRenderer
      title="Navigation Bar"
      actions={
        selectedContent.type === 'navbar_menu_list' ? (
          <Button size="sm" variant="secondary" iconClassName="text-wb-secondary" Icon={Trash} onClick={handleDelete} />
        ) : undefined
      }
    >
      <ActionsSettings />
      <Disabled isDisabled={isMobile} tooltipText="Switch to Desktop View to edit">
        <AlignmentSettings />
      </Disabled>
      <Disabled isDisabled={isMobile} tooltipText="Switch to Desktop View to edit">
        <RowNumberSettings />
      </Disabled>
      <Disabled isDisabled={isMobile} tooltipText="Switch to Desktop View to edit">
        <HorizontalDividerSettings />
      </Disabled>
      <LineDividerSettings />
      <SliderSettings
        title="Gap"
        attribute="gap"
        selectedContent={selectedContent}
        unit="px"
        defaultValue={parseCssValue(NAVBAR_MENU_LIST_DEFAULTS.gap).value}
      />
    </SectionRenderer>
  );
}
