import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { UseQueryResult } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowDownLeftIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowUpRightIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import useLedgerEntries from '@/hooks/boosts/useLedgerEntries';
import { Wallet } from '@/hooks/boosts/useWallet';
import useGroupedLedgerEntries from '@/hooks/wallet/useGroupedLedgerEntries';
import useLedgerEntriesExport from '@/hooks/wallet/useLedgerEntriesExport';
import { Order } from '@/interfaces/general';
import { LedgerEntry } from '@/interfaces/ledger_entry';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import { Dropdown } from '@/ui/Dropdown';
import { timePeriodsButtonOptions } from '@/utils/timePeriods';

import Stats from './Stats';

interface Props {
  walletData?: Wallet;
  walletQuery: UseQueryResult<Wallet, unknown>;
}

const EarningsAndExpenses = ({ walletQuery, walletData }: Props) => {
  const bodyContainerRef = useRef<HTMLElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const timePeriodOptions = timePeriodsButtonOptions.map((timePeriod) => {
    return {
      label: timePeriod.label,
      value: timePeriod.id,
    };
  });
  const [orderBy, setOrderBy] = useState<string>('created_at');
  const [direction, setDirection] = useState<Order>(Order.DESC);
  const [chartTimePeriod, setChartTimePeriod] = useState(String(timePeriodOptions[0].value));
  const [chartFilterType, setChartFilterType] = useState('all');
  const [transactionGroup, setTransactionGroup] = useState('all');

  const {
    data: ledgerEntriesData,
    refetch: ledgerEntriesRefetch,
    isLoading: isLedgerEntriesLoading,
    isFetching: isLedgerEntriesFetching,
    isFetchingNextPage: isLedgerEntriesFetchingNextPage,
    fetchNextPage: fetchNextLedgerPage,
    hasNextPage: ledgerEntriesHasNextPage,
  } = useLedgerEntries({
    sort: orderBy,
    sortDirection: direction,
    period: chartTimePeriod,
    networkType: chartFilterType,
  });

  const {
    data: groupedLedgerEntriesData,
    refetch: groupedLedgerEntriesRefetch,
    isLoading: isGroupedLedgerEntriesLoading,
    isFetching: isGroupedLedgerEntriesFetching,
    isFetchingNextPage: isGroupedLedgerEntriesFetchingNextPage,
    fetchNextPage: fetchNextGroupedLedgerPage,
    hasNextPage: groupedLedgerEntriesHasNextPage,
  } = useGroupedLedgerEntries({
    sort: orderBy,
    sortDirection: direction,
    period: chartTimePeriod,
    networkType: chartFilterType,
  });

  const derivedIsLoading = transactionGroup === 'all' ? isLedgerEntriesLoading : isGroupedLedgerEntriesLoading;
  const derivedIsFetching = transactionGroup === 'all' ? isLedgerEntriesFetching : isGroupedLedgerEntriesFetching;
  const derivedRefetch = transactionGroup === 'all' ? ledgerEntriesRefetch : groupedLedgerEntriesRefetch;
  const derivedEntriesHasNextPage =
    transactionGroup === 'all' ? ledgerEntriesHasNextPage : groupedLedgerEntriesHasNextPage;
  const derivedFetchNextPage = transactionGroup === 'all' ? fetchNextLedgerPage : fetchNextGroupedLedgerPage;
  const derivedIsFetchingNextPage =
    transactionGroup === 'all' ? isLedgerEntriesFetchingNextPage : isGroupedLedgerEntriesFetchingNextPage;
  const ledgerEntries =
    transactionGroup === 'all'
      ? ledgerEntriesData?.pages.flatMap((page) => page.ledger_entries) || []
      : groupedLedgerEntriesData?.pages.flatMap((page) => page.grouped_ledger_entries) || [];
  const exportCSV = useLedgerEntriesExport();

  /**
   * Adds polling to the page when the `success` query param is present. This is
   * used to get the latest ledger entries after a payment is made. It only runs for
   * a few seconds and then stops, but that should be plenty of time for the payment
   * to be processed.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    if (success === 'true') {
      toast.success('Your payment was successful!');

      // refetch every second until we get a new entry
      const interval = setInterval(() => {
        walletQuery.refetch();
        derivedRefetch();
      }, 1000);

      // stop polling after 3 seconds
      const timeout = setTimeout(() => {
        navigate('/wallet', { replace: true });
        clearInterval(interval);
      }, 3000);

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate]);

  const handleClickOrderButton = async (newOrderBy: string, newDirection: Order) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const handleClickExportAsCSVButton = async () => {
    const exportDateTime = moment().format('YYYY-MM-DD-HH-mm-ss');
    const data = await exportCSV.mutateAsync();
    const url = window.URL.createObjectURL(new Blob([data?.csv || '']));
    const link = document.createElement('a');
    const filename = `beehiiv-wallet-${exportDateTime}.csv`;

    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

    toast.success('CSV successfully exported');
  };

  const onWindowScroll = useCallback(() => {
    const bodyElHeight = bodyContainerRef.current?.offsetHeight || 0;
    const bodyElScrolled = bodyContainerRef.current?.scrollTop || 0;

    if (bodyElHeight + bodyElScrolled >= (bodyContainerRef.current?.firstChild as HTMLElement)?.offsetHeight) {
      if (!derivedIsLoading && !derivedIsFetching && !derivedIsFetchingNextPage && derivedEntriesHasNextPage) {
        derivedFetchNextPage();
      }
    }
  }, [derivedIsLoading, derivedIsFetching, derivedEntriesHasNextPage, derivedFetchNextPage, derivedIsFetchingNextPage]);

  const handleChartFilterTypeChange = (name: string, value: string) => {
    setChartFilterType(value);
  };

  const handleChartTimePeriodChange = (name: string, value: string) => {
    setChartTimePeriod(value);
  };

  const resetFilters = () => {
    setChartFilterType('all');
    setChartTimePeriod('all_time');
  };

  const toggletransactionGroup = (name: string, value: string) => {
    setTransactionGroup(value);
  };

  const getTransactionType = (entry: LedgerEntry) => {
    let transactionType = '';

    if (entry.category === 'earnings_deposit') {
      transactionType = 'Earned';
    }

    if (entry.category === 'earnings_payout') {
      transactionType = 'Spent';
    }

    if (entry.category === 'stripe_deposit') {
      transactionType = 'Deposit';
    }

    if (entry.amount_cents < 0) {
      transactionType = 'Spent';

      if (entry.category === 'stripe_payout') {
        transactionType = 'Withdrawal';
      }
    }

    return transactionType;
  };

  useEffect(() => {
    bodyContainerRef.current = document.getElementById('app-layout-body-container');
    bodyContainerRef.current?.addEventListener('scroll', onWindowScroll);

    return () => {
      bodyContainerRef.current?.removeEventListener('scroll', onWindowScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onWindowScroll]);

  const showingEntriesAmount = (ledgerEntries.length || 0).toLocaleString();
  const totalEntriesAmount = (
    (ledgerEntriesData?.pages && ledgerEntriesData?.pages[ledgerEntriesData.pages.length - 1].pagination.total) ||
    0
  ).toLocaleString();

  return (
    <Card className="mt-6 rounded-lg">
      <div className="flex flex-col md:flex-row w-full gap-4">
        <div className="w-full">
          <div className="w-full">
            <Typography token="font-medium/text/base" className="w-full" colorWeight="900">
              Earnings and expenses
            </Typography>
          </div>
          <div className="w-full mt-2">
            <Typography token="font-normal/text/sm" className="w-full" colorWeight="500">
              Dates are displayed in UTC timezone
            </Typography>
          </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row gap-4 lg:justify-end">
          <div className="w-full flex gap-4 lg:justify-end">
            <div className="w-full lg:w-44 relative">
              <Dropdown
                className="w-full"
                name="transaction_sort_type"
                options={[
                  { label: 'All Transactions', value: 'all' },
                  { label: 'Ad Network', value: 'ads' },
                  { label: 'Boosts', value: 'boosts' },
                ]}
                onSelect={handleChartFilterTypeChange}
                value={chartFilterType}
              />
            </div>
            <div className="w-full lg:w-44 relative">
              <Dropdown
                className="min-w-36 w-full"
                name="transaction_period"
                options={timePeriodOptions}
                onSelect={handleChartTimePeriodChange}
                value={chartTimePeriod}
              />
            </div>
          </div>
          <div className="w-full lg:w-44 relative">
            <Button
              className="w-full"
              type="button"
              Icon={ArrowDownTrayIcon}
              variant="primary-inverse"
              onClick={handleClickExportAsCSVButton}
              loading={exportCSV.isLoading}
            >
              Export as CSV
            </Button>
          </div>
        </div>
      </div>
      <Stats
        walletData={walletData}
        chartTimePeriod={chartTimePeriod}
        setChartTimePeriod={setChartTimePeriod}
        chartFilterType={chartFilterType}
        setChartFilterType={setChartFilterType}
      />
      {!ledgerEntries.length && (
        <div className="mt-6 p-0">
          <Card className="flex rounded-lg flex-col justify-center items-center text-center min-h-44">
            <Typography token="font-medium/text/sm" className="w-full" colorWeight="500">
              No results found
            </Typography>
            {(chartFilterType !== 'all' || chartTimePeriod !== 'all_time') && (
              <Button
                Icon={ArrowPathIcon}
                type="button"
                variant="primary-inverse"
                onClick={resetFilters}
                className="mt-9"
              >
                Reset filter
              </Button>
            )}
          </Card>
        </div>
      )}
      {!!ledgerEntries.length && (
        <>
          <div className="flex w-full justify-between mt-6 items-end">
            <Typography token="font-normal/text/xs" colorWeight="500" className="block w-full">
              Showing {showingEntriesAmount} of {totalEntriesAmount} transactions
            </Typography>
            <div>
              <Dropdown
                className="w-full max-w-52 mb-1"
                name="transaction_group"
                options={[
                  { label: 'Individual transactions', value: 'all' },
                  { label: 'Grouped by day', value: 'by_day' },
                ]}
                onSelect={toggletransactionGroup}
                value={transactionGroup}
              />
            </div>
          </div>
          <Card className="mt-2 p-0 rounded-lg">
            <Items>
              <ItemHeaders isSecondary>
                <ItemHeader
                  sortDirectionIconDown={<BarsArrowDownIcon className="w-4 h-4 text-surface-500 mt-1" />}
                  sortDirectionIconUp={<BarsArrowUpIcon className="w-4 h-4 text-surface-500 mt-1" />}
                  onClick={handleClickOrderButton}
                  orderBy="amount_cents"
                  isSorting={orderBy === 'amount_cents'}
                  currentDirection={direction}
                  align={AlignType.LEFT}
                >
                  AMOUNT
                </ItemHeader>
                <ItemHeader align={AlignType.LEFT}>TYPE</ItemHeader>
                {transactionGroup === 'all' && <ItemHeader align={AlignType.LEFT}>Publication/Ad </ItemHeader>}
                <ItemHeader
                  sortDirectionIconDown={<BarsArrowDownIcon className="w-4 h-4 text-surface-500 mt-1" />}
                  sortDirectionIconUp={<BarsArrowUpIcon className="w-4 h-4 text-surface-500 mt-1" />}
                  onClick={handleClickOrderButton}
                  orderBy="created_at"
                  isSorting={orderBy === 'created_at'}
                  currentDirection={direction}
                  align={AlignType.LEFT}
                >
                  DATE
                </ItemHeader>
              </ItemHeaders>
              <ItemsBody>
                {ledgerEntries.map((entry) => {
                  const transactionType = getTransactionType(entry);

                  const formattedValue = (entry.amount_cents / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  });

                  const dateFormat = transactionGroup === 'all' ? 'MMM D, YYYY h:mm A' : 'MMM D, YYYY';
                  const transactionDate =
                    transactionGroup === 'all'
                      ? moment(entry.created_at.toString())
                      : moment(entry.created_at.toString()).add(1, 'd');

                  return (
                    <ItemRow key={entry.id}>
                      <ItemColumn align={AlignType.LEFT}>
                        <div className="flex gap-1">
                          {entry.amount_cents > 0 ? (
                            <ArrowUpRightIcon className="w-4 h-4 text-feedback-success-600 mt-0.5" />
                          ) : (
                            <ArrowDownLeftIcon className="w-4 h-4 text-feedback-danger-600 mt-0.5" />
                          )}
                          <Typography token="font-medium/text/sm" colorWeight="900">
                            {formattedValue}
                          </Typography>
                        </div>
                      </ItemColumn>
                      <ItemColumn align={AlignType.LEFT} className="ml-2">
                        <Typography token="font-medium/text/xs" colorWeight="900" className="capitalize block w-full">
                          {transactionType === 'Withdrawal' ? transactionType : entry.network_type}
                        </Typography>
                        {transactionType !== 'Withdrawal' && (
                          <Typography token="font-normal/text/xs" colorWeight="500" className="capitalize block w-full">
                            {transactionType}
                          </Typography>
                        )}
                      </ItemColumn>
                      {transactionGroup === 'all' && (
                        <ItemColumn align={AlignType.LEFT} className="ml-2">
                          <Typography token="font-normal/text/xs" colorWeight="500" className="capitalize block w-full">
                            {entry.referrer_name || '--'}
                          </Typography>
                        </ItemColumn>
                      )}
                      <ItemColumn align={AlignType.LEFT} className="ml-2">
                        <Typography token="font-normal/text/xs" colorWeight="500" className="capitalize block w-full">
                          {transactionDate.format(dateFormat)}
                        </Typography>
                      </ItemColumn>
                    </ItemRow>
                  );
                })}
              </ItemsBody>
            </Items>
          </Card>
        </>
      )}
      {isLedgerEntriesFetchingNextPage && (
        <div className="w-full h-full flex justify-center items-center mt-5">
          <LoadingSpinner />
        </div>
      )}
    </Card>
  );
};

export default EarningsAndExpenses;
