import { Users } from '@phosphor-icons/react';
import { AuthorsElement, TAuthorAttributes } from '@shared/dream-components';

import { useSettings } from '@/context/settings-context';
import useIsPageCheck from '@/routes/website/_hooks/useIsPageCheck';

import AuthorsSelectionModal from '../../../../extensions/Authors/modal/AuthorsSelectionModal';
import DisabledSetting from '../../../helpers/DisabledSetting';
import { AttributeSettingProps } from '../../../types';
import { ImageLeftFlex, ImageRightFlex, ImageTop } from '../../AuthorsWidgetSettings/authorStructurePatterns';
import { BooleanSettings } from '../../BooleanSettings';
import { DataModalSettings } from '../../DataModalSettings';
import { StructureSettings } from '../../StructureSettings';
import ActionRenderer from '../ActionRenderer';

const AuthorsActions = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNode, activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const { insertedFromSidebar } = activeNodeAttributes;
  const { isAuthorPageOrTemplate } = useIsPageCheck();
  const { settings } = useSettings();
  const isDreamDesignerEnabled = settings?.hiiv_dream_designer;

  const handleCloseModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', false);
      return true;
    });
  };

  const handleOpenModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', true);
      return true;
    });
  };

  return (
    <ActionRenderer nodeType="authors" activeNodeType={activeNodeType}>
      <StructureSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        allowedProperties={['div', 'image', 'name', 'bio', 'socials']}
        predefinedPatterns={[ImageTop, ImageLeftFlex, ImageRightFlex]}
      >
        {(structure: any) => (
          <AuthorsElement
            element={{
              type: 'authors',
              attrs: {
                ...(activeNode?.attrs as TAuthorAttributes),
                cardStructure: structure as any,
                columns: 1,
                // We only need one author to display the structure
                data: {
                  authors: activeNode?.attrs?.data?.authors?.slice(0, 1),
                },
              },
              content: [{ id: 'text', text: '' }],
            }}
          >
            {null}
          </AuthorsElement>
        )}
      </StructureSettings>
      <DataModalSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        handleOpenModal={handleOpenModal}
        text="Authors"
        Icon={Users}
      />
      {insertedFromSidebar && activeNode && (
        <AuthorsSelectionModal
          pos={activeNodePos}
          editor={editor}
          node={activeNode}
          isOpen={Boolean(insertedFromSidebar)}
          onClose={handleCloseModal}
        />
      )}
      {isAuthorPageOrTemplate && (
        <DisabledSetting disabled={isAuthorPageOrTemplate && !isDreamDesignerEnabled} tooltip="Read-only setting">
          <BooleanSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="isAuthorPageHeader"
            title="Header"
          />
        </DisabledSetting>
      )}
    </ActionRenderer>
  );
};

export default AuthorsActions;
