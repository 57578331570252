import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft, Stack } from '@phosphor-icons/react';

import { useDreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext } from '@/context/website-context';

import { ContentTree } from '../ContentTree';
import { ContentTreeProvider } from '../ContentTree/context';
import { LAYERS_PANEL_ID } from '../DreamEditor/constants';
import { INSERTED_FROM_INSERT_PANEL_IDENTIFIER } from '../DreamEditor/extensions/constants';
import { NavSection } from '../SideNav/NavSection';
import { NavSectionTitle } from '../SideNav/NavSectionTitle';
import { Button } from '../UI/Button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../UI/Tabs';
import { Text } from '../UI/Text';

import AddableDefaultPages from './AddableDefaultPages';
import AddablePage from './AddablePage';

interface Props {
  pagesRoutes: any;
  defaultRoutes: any;
  previewSiteVersion?: any;
  isTemplatePage?: boolean;
  sitePackageId?: string;
  defaultTab?: string;
}

const SidePanelTabs = ({
  pagesRoutes,
  defaultRoutes,
  previewSiteVersion,
  isTemplatePage,
  sitePackageId,
  defaultTab,
}: Props) => {
  const { pageId } = useParams();
  const hasPageRoutes = pagesRoutes && Object.keys(pagesRoutes).length > 0;

  const [value, setValue] = useState(defaultTab || 'pages');

  const { editor } = useDreamEditorContext();
  const { globalElements } = useWebsiteContext();

  useEffect(() => {
    if (!editor) return;

    editor.on('transaction', ({ transaction }) => {
      if (transaction.getMeta('drillDownSelectionSet') || transaction.getMeta(INSERTED_FROM_INSERT_PANEL_IDENTIFIER)) {
        setValue('layers');
      }
    });

    editor.on('selectionUpdate', () => {
      setValue('layers');
    });
  }, [editor]);

  const globalModalElements = useMemo(() => globalElements.filter((element) => !!element.isModal), [globalElements]);

  return (
    <Tabs value={value} onValueChange={setValue} className="flex flex-col flex-1">
      {isTemplatePage && (
        <Link to={`/website_builder_v2/projects/${sitePackageId}`}>
          <Button variant="secondary" LeftIcon={ArrowLeft} className="w-full">
            All Templates
          </Button>
        </Link>
      )}
      {!isTemplatePage && (
        <TabsList className="p-2">
          <TabsTrigger value="pages">
            <Text weight="medium" size="2xs" className="leading-0">
              Pages
            </Text>
          </TabsTrigger>
          <TabsTrigger value="layers">
            <Text weight="medium" size="2xs" className="leading-0">
              Layers
            </Text>
          </TabsTrigger>
          <TabsTrigger value="modals">
            <Text weight="medium" size="2xs" className="leading-0">
              Modals
            </Text>
          </TabsTrigger>
        </TabsList>
      )}
      <TabsContent value="layers" id={LAYERS_PANEL_ID} />
      <TabsContent value="pages" asChild>
        <div className="flex flex-col flex-1 gap-6">
          <NavSection>
            <NavSectionTitle title="Default Pages" />
            {defaultRoutes && (
              <ContentTreeProvider
                dndDisabledErrorMessage={
                  previewSiteVersion?.id
                    ? "You can't rearrange pages in preview mode."
                    : "You can't re-arrange default pages"
                }
                isAllowDnD={false}
                isShowOptions={!previewSiteVersion?.id}
                isShowAddPage={!previewSiteVersion?.id}
                pageLinkPrefix="/website_builder_v2/page"
              >
                <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
            <AddableDefaultPages />
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Pages" />
            {hasPageRoutes && (
              <ContentTreeProvider
                isAllowDnD={!previewSiteVersion?.id}
                isShowOptions={!previewSiteVersion?.id}
                isShowAddPage={!previewSiteVersion?.id}
                pageLinkPrefix="/website_builder_v2/page"
                dndDisabledErrorMessage={previewSiteVersion?.id ? "You can't rearrange pages in preview mode." : ''}
              >
                <ContentTree route={pagesRoutes} parentPath={[]} slug="" showHome={false} />
              </ContentTreeProvider>
            )}
            <AddablePage />
          </NavSection>
        </div>
      </TabsContent>
      <TabsContent value="modals" asChild>
        <div className="flex flex-col flex-1 gap-6">
          <NavSection>
            <NavSectionTitle title="Modals" />
            {globalModalElements.map(
              (element) =>
                element?.editorPath && (
                  <Link
                    to={`${element.editorPath}?previousPageID=${pageId}`}
                    className="flex items-center gap-1 cursor-pointer p-1.5 rounded-md hover:bg-wb-secondary"
                  >
                    <Stack className="text-wb-accent" weight="bold" />
                    <span className="text-[13px] leading-5 font-normal text-inherit">{element.name}</span>
                  </Link>
                )
            )}
          </NavSection>
        </div>
      </TabsContent>
    </Tabs>
  );
};

export default SidePanelTabs;
