import { useCallback, useEffect, useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/routes/website/_components/UI/DropdownMenu';
import { SimpleInput, SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Text } from '@/routes/website/_components/UI/Text';

import { AttributeSettingProps } from '../types';

type AdvancedWidthSettingsProps = AttributeSettingProps & {
  property: string;
};

const parseCssValue = (value: string) => {
  if (!value) return 0;
  if (value === 'auto') {
    return 0;
  }
  if (typeof value === 'number') {
    return value;
  }
  const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
  return Number.isNaN(numericValue) ? 0 : numericValue;
};

const OPTIONS: Record<string, string> = {
  fixed: 'Fixed',
  fill: 'Fill',
  fit: 'Fit',
};

export const AdvancedWidthSettings = ({ editor, property, activeNodeResult }: AdvancedWidthSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const [dimensionType, setdimensionType] = useState<'fixed' | 'fill' | 'fit'>('fill');

  const contentValue =
    activeNodeAttributes && property in activeNodeAttributes
      ? activeNodeAttributes[property as keyof typeof activeNodeAttributes]
      : '100%';

  useEffect(() => {
    setdimensionType(() => {
      if (activeNodeAttributes?.maxWidth?.includes('px')) {
        return 'fixed';
      }
      if (contentValue === '100%') {
        return 'fill';
      }
      if (contentValue === 'auto' || contentValue === 'fit-content') {
        return 'fit';
      }
      return 'fixed';
    });
  }, [contentValue, activeNodeAttributes]);

  const onUpdateInput = useCallback(
    (overrideType?: 'fit' | 'fill' | 'fixed', fixedValue?: string | number) => {
      if (!activeNodeType) return;
      const typeChecked = overrideType ?? dimensionType;

      if (typeChecked === 'fit') {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'width', 'fit-content');
          tr.setNodeAttribute(activeNodePos, 'maxWidth', '100%');
          return true;
        });
      } else if (typeChecked === 'fill') {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'width', '100%');
          tr.setNodeAttribute(activeNodePos, 'maxWidth', '100%');
          return true;
        });
      } else {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'width', `100%`);
          tr.setNodeAttribute(activeNodePos, 'maxWidth', `${fixedValue ?? activeNodeAttributes?.maxWidth}`);
          return true;
        });
      }
    },
    [activeNodePos, editor, activeNodeType, activeNodeAttributes, dimensionType]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Width
      </Text>

      <div className="grow flex gap-2 min-w-0 w-[120px]">
        <SimpleInputWrapper>
          {dimensionType === 'fill' && <SimpleInput type="text" className="text-xs" value="100%" disabled />}
          {dimensionType === 'fit' && <SimpleInput type="text" className="text-xs" value="fit-content" disabled />}
          {dimensionType === 'fixed' && (
            <div className="relative">
              <SimpleInput
                type="number"
                className="text-xs"
                value={
                  activeNodeAttributes?.maxWidth?.includes('px')
                    ? parseCssValue(activeNodeAttributes.maxWidth)
                    : parseCssValue(contentValue)
                }
                onChange={(e) => onUpdateInput('fixed', `${e.target.value}px`)}
                max={1280}
              />

              {dimensionType === 'fixed' && (
                <Text
                  size="2xs"
                  weight="medium"
                  className="absolute top-1/2 -right-0.5 -translate-y-1/2"
                  variant="secondary"
                >
                  px
                </Text>
              )}
            </div>
          )}
        </SimpleInputWrapper>
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="cursor-pointer">
            <div className="grow w-full bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-2">
              <div className="flex items-center gap-1">
                <Text size="2xs" weight="medium">
                  {OPTIONS[dimensionType]}
                </Text>
              </div>
              <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer" align="end">
            {Object.keys(OPTIONS).map((optionKey) => (
              <DropdownMenuItem
                key={optionKey}
                onSelect={() => {
                  setdimensionType(optionKey as 'fixed' | 'fill' | 'fit');
                  onUpdateInput(optionKey as 'fixed' | 'fill' | 'fit');
                }}
              >
                {OPTIONS[optionKey]}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
