import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Image as ImageIcon, Trash } from '@phosphor-icons/react';
import { StyleSheetManager } from 'styled-components';

import { MediaLibrary } from '@/components/MediaLibrary';
import { OnMediaPayload } from '@/components/MediaLibrary/MediaLibrary.types';
import { useFileUpload, useUploader } from '@/components/TiptapEditor/extensions/ImageUpload/view/hooks';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Asset } from '@/interfaces/asset';
import { Button } from '@/routes/website/_components/UI/Button';
import { cn } from '@/utils/cn';

import { Popover, PopoverContent, PopoverTrigger } from '../../../../UI/Popover';
import { Text } from '../../../../UI/Text';
import { AttributeSettingProps } from '../../types';

export const ImageSrcSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;
  const { src } = activeNodeAttributes;
  const [currentPublicationId] = useCurrentPublicationState();
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const [mediaUrl, setMediaUrl] = useState(src || '');

  useEffect(() => {
    setMediaUrl(src || '');
  }, [src]);

  const onUpload = useCallback(
    (asset: Asset) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'src', asset.url);
        return true;
      });
      setMediaUrl(asset.url);
    },
    [activeNodePos, editor]
  );

  const handleMediaURLChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newUrl = event.target.value;
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'src', newUrl);
        return true;
      });
      setMediaUrl(newUrl);
    },
    [activeNodePos, editor]
  );

  const handleUpload = useCallback(
    (payload: OnMediaPayload) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, 'src', payload.media.url);
        return true;
      });
      setMediaUrl(payload.media.url);
    },
    [activeNodePos, editor]
  );

  const { uploadFile } = useUploader({ publicationId: currentPublicationId, onUpload });
  const { handleUploadClick, ref: fileInputRef } = useFileUpload();

  const handleDelete = useCallback(() => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'src', null);
      return true;
    });
    setMediaUrl('');
  }, [activeNodePos, editor]);

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Image
        </Text>

        <div className="grow bg-wb-secondary rounded-lg shadow-sm">
          <div className="w-full justify-between flex items-center gap-2 p-2">
            <PopoverTrigger asChild>
              <div className="flex items-center gap-1 cursor-pointer select-none">
                <ImageIcon className="text-wb-secondary" weight="bold" />
                <Text size="2xs" weight="medium">
                  Image
                </Text>
              </div>
            </PopoverTrigger>
            {src && (
              <Trash
                className="text-wb-secondary hover:text-wb-danger cursor-pointer"
                weight="bold"
                onClick={handleDelete}
              />
            )}
          </div>
        </div>
      </div>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <Text size="sm" weight="semibold">
            Background
          </Text>
          <div className="w-full min-w-0 flex justify-center items-center relative">
            <StyleSheetManager target={document.head}>
              <MediaLibrary
                isOpen={showImageLibrary}
                onClose={() => setShowImageLibrary(false)}
                publicationId={currentPublicationId}
                onMediaSelect={handleUpload}
              />
            </StyleSheetManager>
            <input
              ref={fileInputRef}
              type="file"
              className="h-0 opacity-0 overflow-hidden w-0"
              accept=".jpg,.jpeg,.png,.webp,.gif"
              onChange={(e) => (e.target.files ? uploadFile(e.target.files[0]) : null)}
            />
            <div className="w-full h-[150px] bg-wb-secondary rounded-lg shadow-sm flex flex-col gap-2 items-center justify-center overflow-hidden relative">
              {src && <img src={src} alt="background" className="w-full h-full object-contain" />}

              <div
                className={cn(
                  'absolute top-0 left-0 w-full h-full flex flex-col gap-2 items-center justify-center bg-wb-overlay',
                  src ? 'opacity-0 hover:opacity-100' : 'opacity-100'
                )}
              >
                <Button size="sm" variant="secondary" onClick={handleUploadClick}>
                  Upload an Image
                </Button>

                <Text size="2xs" weight="medium" variant="on-accent">
                  OR
                </Text>

                <Button size="sm" variant="secondary" onClick={() => setShowImageLibrary(true)}>
                  Use from library
                </Button>
                <div className="absolute top-3 right-3 cursor-pointer hover:bg-wb-secondary/50 rounded-lg p-1">
                  <Trash className="text-wb-secondary hover:text-wb-danger" weight="bold" onClick={handleDelete} />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <Text size="xs" weight="medium" variant="secondary">
              OR
            </Text>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Text size="2xs" variant="secondary" weight="medium" className="w-[100px]">
                Media URL
              </Text>
              <input
                className="outline-none bg-wb-secondary rounded-lg shadow-sm p-2 border-transparent border focus:outline-none w-full text-xs"
                placeholder="https://google.com"
                value={mediaUrl}
                onChange={handleMediaURLChange}
              />
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
