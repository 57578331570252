import { CheckCircleIcon, UserGroupIcon } from '@heroicons/react/20/solid';

import Tag from '@/components/Tag';
import { usePermissions } from '@/context/permissions-context';
import { AutomationStep } from '@/interfaces/automations/automation_step';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import Tooltip from '@/ui/Tooltip';
import getLocaleString from '@/utils/getLocaleString';
import pluralize from '@/utils/pluralize';

import ConditionsLabel from '../components/ConditionsLabel';
import isDelayStep from '../utils/isDelayStep';

interface Props {
  automationStep: AutomationStep;
}

const StatisticTags = ({ automationStep }: Props) => {
  const { checkPermissions } = usePermissions();
  const canSeeStats = checkPermissions('views/automations/overview', 'read');
  const waitingCount = automationStep.journey_step_counts.fresh_in_progress;
  const formattedWaitingCount = canSeeStats ? getLocaleString(waitingCount) : '-';
  const freshCompletedCount = automationStep.journey_step_counts.fresh_completed;
  const formattedFreshCompletedCount = canSeeStats ? getLocaleString(freshCompletedCount) : '-';
  const staleCompletedCount = automationStep.journey_step_counts.stale_completed;
  const formattedStaleCompletedCount = canSeeStats ? getLocaleString(staleCompletedCount) : '-';
  const formattedCompletedCount = canSeeStats ? getLocaleString(freshCompletedCount + staleCompletedCount) : '-';
  const conditionsCount = canSeeStats ? automationStep.conditions.conditions?.length || 0 : '-';
  const supportsConditions = !isDelayStep(automationStep);

  const inProgressTooltipText = `${formattedWaitingCount} ${pluralize(
    'subscriber',
    waitingCount,
    undefined,
    true
  )} waiting on this step`;

  const hasPreviousVersions = staleCompletedCount > 0;

  let staleCompletedTooltipText;
  if (hasPreviousVersions) {
    staleCompletedTooltipText = `${formattedStaleCompletedCount} ${pluralize(
      'subscriber',
      staleCompletedCount,
      undefined,
      true
    )} completed previous versions`;
  }
  const completedTooltipText = [
    `${formattedFreshCompletedCount} ${pluralize(
      'subscriber',
      freshCompletedCount,
      undefined,
      true
    )} completed this step`,
    staleCompletedTooltipText,
  ]
    .filter(Boolean)
    .join(' and ');

  const conditionsTooltipText = canSeeStats ? pluralize('condition', conditionsCount) : 'condition';

  return (
    <div className="flex gap-x-1">
      <Tooltip
        tooltipClass="text-center"
        id={`stat-${automationStep.id}-waiting`}
        text={canSeeStats ? inProgressTooltipText : NO_PERMISSION_MESSAGE}
        showIcon={false}
        autoWidth
      >
        <Tag icon={<UserGroupIcon />} label={formattedWaitingCount} />
      </Tooltip>

      <Tooltip
        tooltipClass="text-center"
        id={`stat-${automationStep.id}-completed`}
        text={canSeeStats ? completedTooltipText : NO_PERMISSION_MESSAGE}
        showIcon={false}
        autoWidth
      >
        <Tag icon={<CheckCircleIcon />} label={formattedCompletedCount} />
      </Tooltip>

      {supportsConditions && (
        <Tooltip
          tooltipClass="text-center"
          id={`stat-${automationStep.id}-conditions`}
          text={canSeeStats ? conditionsTooltipText : NO_PERMISSION_MESSAGE}
          showIcon={false}
          autoWidth
        >
          <ConditionsLabel count={conditionsCount} showLabel={false} />
        </Tooltip>
      )}
    </div>
  );
};

export default StatisticTags;
