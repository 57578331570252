import { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ArrowLeft } from '@phosphor-icons/react';

import { useWebsitePageRouteGetter, WebsiteProvider } from '@/context/website-context';
import { cn } from '@/utils/cn';

import { ATTRIBUTES_PANEL_ID } from '../_components/DreamEditor/constants';
import { EditorHeader } from '../_components/EditorHeader/EditorHeader';
import { Layout } from '../_components/Layout';
import { NavSection } from '../_components/SideNav/NavSection';
import { SaveNavbarIndicator } from '../_components/SideNav/SaveIndicator';
import { Button } from '../_components/UI/Button';
import { Tabs, TabsList, TabsTrigger } from '../_components/UI/Tabs';
import { Text } from '../_components/UI/Text';

import { NavbarProvider } from './_components/NavbarEditor/NavbarContext';
import { NavbarDataProvider } from './_components/NavbarEditor/NavbarDataContext';
import { NavbarEditor } from './_components/NavbarEditor/NavbarEditor';

const NavbarEditorPage = () => {
  const [searchParams] = useSearchParams();
  const previousPageID = searchParams.get('previousPageID');
  const [selectedTab, setSelectedTab] = useState<'desktop' | 'mobile'>('desktop');
  const pageRouteGetter = useWebsitePageRouteGetter();

  const homePageId = pageRouteGetter?.getHomePageID();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [editorRect, setEditorRect] = useState<Partial<DOMRect>>({ height: 0, width: 0 });

  useEffect(() => {
    const editorContainer = editorContainerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of entries) {
        if (entry.contentRect) {
          setEditorRect(entry.contentRect);
        }
      }
    });

    if (editorContainer) {
      // setup listener for element size change
      resizeObserver.observe(editorContainer);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary pb-0 pt-0"
      isSidebarResizable
      sidenavChildren={
        <>
          <div className="flex-1">
            <NavSection>
              <Link to={`/website_builder_v2/page/${previousPageID || homePageId}`} className="w-full cursor-pointer">
                <Button variant="secondary" size="sm" LeftIcon={ArrowLeft} className="w-full py-3">
                  Back to page
                </Button>
              </Link>
            </NavSection>
          </div>
          <NavSection>
            <SaveNavbarIndicator />
          </NavSection>
        </>
      }
      rightSideChildren={
        <div
          className="w-[266px] min-w-[266px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-auto"
          style={{
            scrollbarGutter: 'stable',
          }}
          id={ATTRIBUTES_PANEL_ID}
        />
      }
      titleType="page_name"
    >
      <div className="w-full h-full flex flex-col items-center">
        <EditorHeader type="navbar" iconType="component" />
        <Tabs
          defaultValue="desktop"
          className="flex flex-col"
          value={selectedTab}
          onValueChange={(value) => setSelectedTab(value as 'desktop' | 'mobile')}
        >
          <TabsList className="p-2" defaultValue="desktop">
            <TabsTrigger value="desktop">
              <Text weight="medium" size="2xs" className="leading-0">
                Desktop
              </Text>
            </TabsTrigger>
            <TabsTrigger value="mobile">
              <Text weight="medium" size="2xs" className="leading-0">
                Mobile
              </Text>
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <div
          ref={editorContainerRef}
          className={cn('flex-1 min-h-0 bg-transparent relative', selectedTab === 'mobile' ? 'w-[300px]' : 'w-full')}
        >
          <NavbarProvider
            iframeRef={iframeRef}
            editorContainerRef={editorContainerRef}
            contentWidth={selectedTab === 'mobile' ? 400 : 1024}
          >
            <NavbarEditor
              iframeRef={iframeRef}
              editorRect={editorRect}
              contentWidth={selectedTab === 'mobile' ? 400 : 1024}
              isPreview={selectedTab === 'mobile'}
            />
          </NavbarProvider>
        </div>
      </div>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <NavbarDataProvider>
      <NavbarEditorPage />
    </NavbarDataProvider>
  </WebsiteProvider>
);
