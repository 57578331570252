import { Text } from '../../../UI/Text';

const DynamicContentTagPage = () => {
  return (
    <div className="flex flex-col gap-2">
      <Text weight="medium" variant="secondary" size="xs" as="p">
        This is a dynamic page that applies this styling to all of your individual tag pages. So any tag you add to the
        main “tags page” will have this styling applied to that specific tag page.
      </Text>

      <Text weight="medium" variant="secondary" size="xs" as="p">
        We provide this placeholder data to demonstrate how this styling will look on one of your actual tag pages.
      </Text>

      <Text weight="medium" variant="secondary" size="xs" as="p">
        You cannot have different styles across different tag pages at this time since it is a universally applied
        style. However, we are committed to maximizing flexibility in customization here over time.
      </Text>
    </div>
  );
};

export default DynamicContentTagPage;
