import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostAgreementAutoPauseConfig } from '@/interfaces/boosts/grow/boost_agreement_auto_pause_config';
import api from '@/services/swarm';

interface Variables {
  active?: boolean;
  openRate?: number;
  numSubscribers?: number;
}

interface Props {
  onSuccess?: (boostAgreementAutoPauseConfig: BoostAgreementAutoPauseConfig, variables: any) => void;
}

const useUpdateBoostAgreementAutoPauseConfig = ({ onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const updateBoostAgreementAutoPauseConfig = async (data: Variables): Promise<BoostAgreementAutoPauseConfig> =>
    api
      .patch('/boosts/grow/boost_agreement_auto_pause_config', {
        boost_agreement_auto_pause_config: {
          active: data.active,
          open_rate: data.openRate,
          num_subscribers: data.numSubscribers,
        },
      })
      .then((res) => res.data);

  return useMutation(updateBoostAgreementAutoPauseConfig, {
    onSuccess: (boostAgreementAutoPauseConfig, variables) => {
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_agreement_auto_pause_config']);

      onSuccess?.(boostAgreementAutoPauseConfig, variables);
    },
    onError: () => {
      toast.error('Something went wrong updating your filter');
    },
  });
};

export default useUpdateBoostAgreementAutoPauseConfig;
