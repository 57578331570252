import { useCallback } from 'react';
import { useEditorState } from '@tiptap/react';

import { AttributeSettingProps } from '../types';

import { BooleanSettings } from './BooleanSettings';

export const HasUnderlineSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { hasUnderline } = useEditorState<Record<string, any>>({
    editor,
    selector: ({ editor: e }) => e.getAttributes('link'),
  });

  const updateTarget = useCallback(
    (updatedHasUnderline: boolean) => {
      editor
        .chain()
        .extendMarkRange('link')
        .updateAttributes('link', { hasUnderline: updatedHasUnderline })
        .focus()
        .run();
    },
    [editor]
  );

  return (
    <BooleanSettings
      editor={editor}
      activeNodeResult={activeNodeResult}
      defaultValue={hasUnderline}
      title="Underline"
      onChange={updateTarget}
    />
  );
};
