import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Bug, ClockCounterClockwise, Plus } from '@phosphor-icons/react';

import { useDreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext, WebsiteProvider } from '@/context/website-context';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';
import useSiteTemplate from '@/hooks/useSiteTemplates/useSiteTemplate';

import { AttributesPanelNavbarFooterContextProvider } from '../../_components/DreamEditor/AttributesPanel/navbarFooterContextProvider';
import Editor from '../../_components/Editor';
import EditorPageLoader from '../../_components/Editor/EditorPageLoader';
import { EditorHeader, EditorHeaderBreadcrumb } from '../../_components/EditorHeader/EditorHeader';
import { Layout } from '../../_components/Layout';
import { ActionMenu } from '../../_components/Layout/ActionMenu';
import AttributesPanelPortal from '../../_components/Layout/AttributesPanelPortal';
import InsertPanelPortal from '../../_components/Layout/InsertPanelPortal';
import SidePanelTabs from '../../_components/Layout/SidePanelTabs';
import { NavSection } from '../../_components/SideNav/NavSection';
import { SavePageIndicator } from '../../_components/SideNav/SaveIndicator';
import { Button } from '../../_components/UI/Button';
import { Tooltip } from '../../_components/UI/Tooltip';
import VersionHistoryNotice from '../../_components/VersionHistoryNotice';

import { TemplatesEditorProvider } from './_components/TemplatesEditor';

const TemplatesEditorPage = () => {
  const { pagesRoutes, defaultRoutes } = useWebsiteContext();
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const { previewSiteVersion, setPreviewSiteVersion } = useWebsiteContext();
  const [isVersionHistoryPanelOpen, setIsVersionHistoryPanelOpen] = useState(false);
  const { templateId } = useParams();
  const { data: template, isLoading } = useSiteTemplate({ siteTemplateId: templateId as string });
  const { data: sitePackage } = useSitePackage({ sitePackageId: template?.site_package_id as string });
  const isPageLevel = template?.level === 'page';
  const isPartOfASitePackage = template?.site_package_id !== null;
  const showNavAndFooter = isPartOfASitePackage && isPageLevel;

  const { editor } = useDreamEditorContext();

  useEffect(() => {
    if (!isVersionHistoryPanelOpen) {
      setPreviewSiteVersion(undefined);
    }
  }, [isVersionHistoryPanelOpen, setPreviewSiteVersion]);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary pb-0 pt-0"
      isSidebarResizable
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
        />
      }
      rightChildren={
        <>
          <Tooltip center="Report a Bug" delay={300}>
            <Button
              variant="secondary"
              Icon={Bug}
              iconWeight="bold"
              onClick={() => {
                window.open('https://app.formcrafts.com/b3bed052', '_blank');
              }}
              className="h-full"
            />
          </Tooltip>
          <Tooltip center="Version History" delay={300}>
            <Button
              variant="secondary"
              Icon={ClockCounterClockwise}
              iconWeight="bold"
              onClick={() => setIsVersionHistoryPanelOpen((prev) => !prev)}
              className="h-full"
            />
          </Tooltip>
        </>
      }
      sidenavChildren={
        <>
          <SidePanelTabs
            pagesRoutes={pagesRoutes}
            defaultRoutes={defaultRoutes}
            previewSiteVersion={previewSiteVersion}
            sitePackageId={template?.site_package_id || undefined}
            isTemplatePage
          />
          <InsertPanelPortal isInsertPanelOpen={isInsertPanelOpen} />

          {!previewSiteVersion && (
            <NavSection>
              <SavePageIndicator />
            </NavSection>
          )}
        </>
      }
      rightSideChildren={<AttributesPanelPortal />}
      titleType="template_name"
      onContainerClick={() => {
        editor?.commands.focusDoc();
      }}
    >
      <EditorHeader type="template" name={template?.name} iconType="layout">
        {template?.site_package_id && (
          <EditorHeaderBreadcrumb text="Projects" linkTo="/website_builder_v2/projects" iconType="folder" />
        )}
        <EditorHeaderBreadcrumb
          text={template?.site_package_id ? `${sitePackage?.name}` : 'Templates'}
          iconType="folder"
          linkTo={
            template?.site_package_id
              ? `/website_builder_v2/projects/${template.site_package_id}`
              : '/website_builder_v2/templates'
          }
        />
      </EditorHeader>
      {isLoading ? (
        <EditorPageLoader />
      ) : (
        <Editor
          className="typedream content template"
          showNavAndFooter={showNavAndFooter}
          isFullPageHeight={false}
          type={isPageLevel ? 'page' : 'template'}
          isTemplateView
          setIsInsertPanelOpen={setIsInsertPanelOpen}
        />
      )}
      {previewSiteVersion?.id && <VersionHistoryNotice />}
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <TemplatesEditorProvider>
      <AttributesPanelNavbarFooterContextProvider>
        <TemplatesEditorPage />
      </AttributesPanelNavbarFooterContextProvider>
    </TemplatesEditorProvider>
  </WebsiteProvider>
);
