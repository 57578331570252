import React, { ElementType, FC } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import Tooltip from '../Tooltip';

// some of these are legacy that are not used anymore, but were
// kept for backwards compatibility. Check Storybook for the most
// up-to-date list of variants.
export const COLORS = {
  flush: 'border border-transparent text-gray-700', // no longer used
  warning: 'bg-amber-100 text-amber-900 border-amber-200 border',
  alert: 'bg-red-100 text-red-900 border-red-200 border', // no longer used (use danger instead)
  success: 'bg-emerald-100 text-emerald-900 border-emerald-200 border',
  information: 'bg-gray-100 text-gray-700 border-gray-200 border', // no longer used (use default_lined instead)
  info_blue: 'bg-indigo-100 text-indigo-900 border-indigo-200 border', // no longer used (use info instead)
  tertiary: 'bg-violet-100 text-violet-700 border-violet-200 border', // no longer used (use tertiary_lined instead)
  danger: 'bg-red-100 text-red-900 border-red-200 border',
  info: 'bg-indigo-100 text-indigo-900 border-indigo-200 border',
  default_lined: 'bg-gray-100 text-gray-700 border-gray-200 border',
  default_filled: 'bg-gray-100 text-gray-700',
  tertiary_lined: 'bg-violet-100 text-violet-700 border-violet-200 border',
};

// some of these are legacy that are not used anymore, but were
// kept for backwards compatibility.
export const ICON_COLORS = {
  flush: 'text-gray-700', // no longer used
  warning: 'text-amber-900',
  alert: 'text-red-800', // no longer used (use danger instead)
  success: 'text-emerald-900',
  information: 'text-gray-700', // no longer used (use default_lined instead)
  info_blue: 'text-indigo-800', // no longer used (use info instead)
  tertiary: 'text-violet-800', // no longer used (use tertiary_lined instead)
  danger: 'text-red-800',
  info: 'text-indigo-800',
  default_lined: 'text-gray-700',
  default_filled: 'text-gray-700',
  tertiary_lined: 'text-violet-800',
};

export interface BadgeProps {
  type?:
    | 'warning'
    | 'alert'
    | 'success'
    | 'information'
    | 'info_blue'
    | 'tertiary'
    | 'flush'
    | 'danger'
    | 'info'
    | 'default_lined'
    | 'default_filled'
    | 'tertiary_lined';
  size?: 'sm' | 'md';
  className?: string;
  children?: React.ReactNode;
  onDismiss?: () => void;
  alwaysShowDismiss?: boolean;
  dismissClassName?: string;
  tooltip?: string;
  text?: string;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  Icon?: ElementType;
  labelMaxWidth?: string;
}

const Badge: FC<BadgeProps> = ({
  dismissClassName,
  type = 'default_lined',
  size = 'md',
  children,
  className = '',
  onDismiss,
  alwaysShowDismiss = false,
  tooltip,
  Icon,
  leadingIcon,
  trailingIcon,
  text,
  labelMaxWidth = '',
}: BadgeProps) => {
  if (!children && !text) return null;

  const colorClasses = COLORS[type] ?? COLORS.default_lined;
  const iconColorClasses = ICON_COLORS[type] ?? ICON_COLORS.default_lined;

  return (
    <span
      className={cx(
        'shadow-xs inline-flex items-center text-xs font-medium relative group rounded',
        className,
        colorClasses,
        { 'px-1.5 py-0.5': size === 'sm' },
        { 'px-2 py-1': size === 'md' },
        Icon ? 'gap-x-1' : 'gap-x-1'
      )}
    >
      {tooltip ? (
        <Tooltip id={`badge-tooltip-${btoa(tooltip)}`} text={tooltip} showIcon={false}>
          <div className="flex flex-row gap-x-1 items-center">
            {leadingIcon && <span className={iconColorClasses}>{leadingIcon}</span>}
            <span className={`w-full block overflow-hidden whitespace-nowrap text-ellipsis ${labelMaxWidth}`}>
              {text || children}
            </span>
            <span className={iconColorClasses}>{trailingIcon}</span>
          </div>
        </Tooltip>
      ) : (
        <>
          {Icon && (
            <span className={iconColorClasses}>
              <Icon className="w-4 h-4" />
            </span>
          )}
          {leadingIcon && <span className={iconColorClasses}>{leadingIcon}</span>}
          <span className={`w-full block overflow-hidden whitespace-nowrap text-ellipsis ${labelMaxWidth}`}>
            {text || children}
          </span>
          {trailingIcon && <span className={iconColorClasses}>{trailingIcon}</span>}
        </>
      )}
      {onDismiss && (
        <XMarkIcon
          onClick={onDismiss}
          className={cx(iconColorClasses, alwaysShowDismiss ? 'w-4' : 'w-4', 'cursor-pointer', dismissClassName)}
        />
      )}
    </span>
  );
};

export default Badge;
