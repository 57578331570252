/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useRef } from 'react';
import { createPortal } from 'react-dom';

import Content from './components/Content';
import Navigation from './components/Navigation';
import { MediaLibraryProvider } from './context/MediaLibraryContext';
import { MediaLibraryComponentProps, MediaLibraryId, MediaLibraryTabState } from './MediaLibrary.types';

const MediaLibrary = ({ onMediaSelect, publicationId, isOpen, onClose }: MediaLibraryComponentProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div
      className="fixed left-0 top-0 w-screen h-screen z-[100000] bg-gray-300 bg-opacity-80 flex items-center justify-center"
      ref={containerRef}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose?.();
        }
      }}
    >
      <div className="w-full max-w-6xl bg-white rounded pointer-events-auto h-[80vh] mx-6 flex flex-col">
        <MediaLibraryProvider
          onClose={onClose}
          publicationId={publicationId}
          onMediaSelect={onMediaSelect}
          initialLibraryId={MediaLibraryId.CURRENTPOST}
          initialTab={MediaLibraryTabState.BEEHIIV}
        >
          <Navigation />

          <Content />
        </MediaLibraryProvider>
      </div>
    </div>,
    document.body
  );
};

export default MediaLibrary;
