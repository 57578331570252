import { useMutation } from 'react-query';

import api from '@/services/swarm';

const useLedgerEntriesExport = () => {
  const fetchCSV = useMutation(() => api.get(`/boosts/ledger_entries_export`).then((res) => res.data));

  return fetchCSV;
};

export default useLedgerEntriesExport;
