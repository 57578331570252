import { DragEventHandler, useEffect, useRef, useState } from 'react';
import { List } from '@phosphor-icons/react';
import { TNavbarDropdownElement, TNavbarMenuElement } from '@shared/dream-components';

import { cn } from '@/routes/website/_utils/cn';

import { useNavbarContext } from './NavbarContext';
import { getParent, getParentOrientation } from './utils';

export const DragHandle = () => {
  const {
    content,
    selectedContent,
    selectedNodeEl,
    isSelectedNodeDraggable,
    isDragging,
    iframeRef,
    editorContainerRef,
    onDragStart,
    onDrag,
    setDragPreviewSize,
    contentWidth,
  } = useNavbarContext();
  const [style, setStyle] = useState<React.CSSProperties>({
    display: 'none',
  });
  const isInitialized = useRef(false);
  const dragHandleRef = useRef<HTMLDivElement>(null);
  const [isAllowedDrag, setIsAllowedDrag] = useState(true);

  const selectedNodeParent = content && selectedContent ? getParent(content, selectedContent) : null;
  const parentOrientation = selectedNodeParent ? getParentOrientation(selectedNodeParent) : 'vertical';

  useEffect(() => {
    isInitialized.current = false;
  }, [selectedNodeEl]);

  useEffect(() => {
    if (isDragging) {
      setStyle((prev) => ({
        ...prev,
        display: 'none',
      }));
    } else if (selectedNodeEl) {
      setStyle((prev) => ({
        ...prev,
        display: 'flex',
      }));
    }
  }, [isDragging, selectedNodeEl]);

  useEffect(() => {
    if (
      selectedContent?.type === 'navbar_menu' ||
      (selectedContent?.type === 'navbar_menu_list' &&
        ((content as TNavbarMenuElement)?.content?.length <= 1 ||
          ((content as TNavbarMenuElement)?.attrs?.rows || 0) <= 1)) ||
      selectedContent?.type === 'navbar_dropdown' ||
      (selectedContent?.type === 'navbar_dropdown_column' &&
        selectedNodeParent?.type === 'navbar_dropdown' &&
        ((selectedNodeParent as TNavbarDropdownElement)?.content?.length || 0) <= 1)
    ) {
      setIsAllowedDrag(false);
    } else {
      setIsAllowedDrag(true);
    }
  }, [selectedContent, content, selectedNodeParent]);

  useEffect(() => {
    if (isInitialized.current || !selectedNodeEl) return () => {};
    isInitialized.current = true;

    const updateDragHandlePosition = () => {
      const iframeRect = iframeRef.current?.getBoundingClientRect();
      const containerRect = editorContainerRef.current?.getBoundingClientRect();
      const iframeWindow = iframeRef.current?.contentWindow;
      const iframeDocument = iframeRef.current?.contentDocument;

      if (iframeRect && containerRect && iframeWindow && iframeDocument && selectedNodeEl) {
        const nodeRect = selectedNodeEl.getBoundingClientRect();

        const scrollX = iframeWindow.scrollX || 0;
        const scrollY = iframeWindow.scrollY || 0;

        // Calculate zoom by comparing iframe's visual size to its content size
        const zoom = iframeRect.width / iframeDocument.documentElement.clientWidth;

        const handleSize = 20;
        const handlePosition =
          parentOrientation === 'vertical'
            ? {
                left: (nodeRect.left - scrollX) * zoom - handleSize - 2,
                top: (iframeRect.top - containerRect.top + (nodeRect.top - scrollY) + nodeRect.height / 2) * zoom,
              }
            : {
                left: (nodeRect.left - scrollX) * zoom + (nodeRect.width * zoom) / 2 - handleSize / 2,
                top:
                  (iframeRect.top - containerRect.top + (nodeRect.top - scrollY) + nodeRect.height) * zoom +
                  handleSize / 2 +
                  2,
              };

        setStyle({
          display: 'flex',
          position: 'absolute',
          ...handlePosition,
        });
      }
    };

    updateDragHandlePosition();

    // Store the current iframe element
    const currentIframe = iframeRef.current;
    const scrollableContainer = currentIframe?.contentDocument?.getElementById('scrollable-container');
    const delayedUpdateHandlePosition = () => setTimeout(() => updateDragHandlePosition(), 0);
    // Add event listeners for scroll and resize
    window.addEventListener('resize', updateDragHandlePosition);
    currentIframe?.contentWindow?.addEventListener('scroll', updateDragHandlePosition);
    scrollableContainer?.addEventListener('scroll', updateDragHandlePosition);
    currentIframe?.contentWindow?.addEventListener('drop', delayedUpdateHandlePosition);

    // Create a ResizeObserver to detect size changes
    let resizeObserver: ResizeObserver | null = null;
    if (selectedNodeEl && 'ResizeObserver' in window) {
      resizeObserver = new ResizeObserver((entries) => {
        // Wrap the callback in requestAnimationFrame
        window.requestAnimationFrame(() => {
          if (!entries.length) return;
          updateDragHandlePosition();
        });
      });
      resizeObserver.observe(selectedNodeEl);
    }

    return () => {
      window.removeEventListener('resize', updateDragHandlePosition);
      currentIframe?.contentWindow?.removeEventListener('scroll', updateDragHandlePosition);
      scrollableContainer?.removeEventListener('scroll', updateDragHandlePosition);
      currentIframe?.contentWindow?.removeEventListener('drop', delayedUpdateHandlePosition);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [selectedNodeEl, iframeRef, editorContainerRef, parentOrientation, contentWidth]);

  const handleDragStart: DragEventHandler<HTMLDivElement> = (e) => {
    const iframeRect = iframeRef.current?.getBoundingClientRect();
    const iframeDocument = iframeRef.current?.contentDocument;

    if (!iframeRect || !iframeDocument || !selectedContent || !content || !selectedNodeEl) return;

    // Calculate zoom by comparing iframe's visual size to its content size
    const zoom = iframeRect.width / iframeDocument.documentElement.clientWidth;

    const nodeRect = selectedNodeEl.getBoundingClientRect();

    // Create and style the drag preview container
    const dragPreviewContainer = document.createElement('div');
    dragPreviewContainer.id = 'drag-preview-container';
    dragPreviewContainer.style.position = 'absolute';
    dragPreviewContainer.style.left = '0';
    dragPreviewContainer.style.top = '0';
    dragPreviewContainer.style.padding = '4px';
    dragPreviewContainer.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
    dragPreviewContainer.style.maxHeight = '100px';
    dragPreviewContainer.style.maxWidth = '700';
    dragPreviewContainer.style.overflow = 'hidden';
    dragPreviewContainer.style.pointerEvents = 'none';
    dragPreviewContainer.style.zIndex = '9999';
    dragPreviewContainer.style.display = 'flex';
    dragPreviewContainer.style.alignItems = 'center';
    dragPreviewContainer.style.gap = '2px';
    dragPreviewContainer.style.width = `${nodeRect.width * zoom}px`;
    dragPreviewContainer.style.height = `${nodeRect.height * zoom}px`;

    // Clone the node and apply necessary styles

    const clonedNode = selectedNodeEl ? (selectedNodeEl.cloneNode(true) as HTMLElement) : document.createElement('div');
    clonedNode.style.pointerEvents = 'none';
    clonedNode.style.userSelect = 'none';

    const container = document.createElement('div');
    container.style.width = 'fit-content';
    container.style.height = 'fit-content';
    container.classList.add('border', 'border-solid', 'border-wb-accent');
    container.style.background = 'white';
    container.style.transform = `scale(${zoom})`;
    container.style.transformOrigin = 'center left';

    container.appendChild(clonedNode);
    const dragHandle = document.createElement('div');
    dragHandle.className = 'h-5 w-5 bg-wb-accent rounded-full flex items-center justify-center cursor-grabbing';
    const listIcon = dragHandleRef?.current?.querySelector('svg');
    const listIconClone = listIcon?.cloneNode(true) as SVGElement;
    dragHandle.appendChild(listIconClone);
    dragPreviewContainer.appendChild(dragHandle);
    dragPreviewContainer.appendChild(container);

    if (parentOrientation === 'vertical') {
      dragPreviewContainer.appendChild(dragHandle);
      dragPreviewContainer.appendChild(container);
    } else {
      dragPreviewContainer.style.flexDirection = 'column';
      dragPreviewContainer.style.alignItems = 'center';
      dragPreviewContainer.style.justifyContent = 'center';
      container.style.transformOrigin = 'bottom center';
      dragPreviewContainer.appendChild(container);
      dragPreviewContainer.appendChild(dragHandle);
    }

    // Append the preview to the document body
    document.body.appendChild(dragPreviewContainer);

    // Set the custom drag image with adjusted offset
    if (parentOrientation === 'vertical') {
      e.dataTransfer.setDragImage(
        dragPreviewContainer,
        dragHandle.offsetWidth / 2,
        dragPreviewContainer.offsetHeight / 2
      );
    } else {
      e.dataTransfer.setDragImage(
        dragPreviewContainer,
        dragPreviewContainer.offsetWidth / 2,
        dragPreviewContainer.offsetHeight - dragHandle.offsetHeight / 2
      );
    }
    const dragPreviewRect = dragPreviewContainer.getBoundingClientRect();
    setDragPreviewSize({
      width: dragPreviewRect.width,
      height: dragPreviewRect.height,
      orientation: parentOrientation,
    });

    // Remove the preview element after a short delay
    setTimeout(() => {
      document.body.removeChild(dragPreviewContainer);
    }, 0);

    onDragStart();
  };

  if (!selectedNodeEl || !isAllowedDrag || !isSelectedNodeDraggable) return null;
  return (
    <div
      style={style}
      className={cn(
        'h-5 w-5 bg-wb-accent rounded-full flex items-center justify-center absolute top-1/2 -translate-y-1/2 select-none',
        isDragging ? 'cursor-grabbing' : 'cursor-grab'
      )}
      ref={dragHandleRef}
      onDragStart={handleDragStart}
      onDrag={onDrag}
      draggable
    >
      <List
        size={12}
        className={cn('text-wb-on-accent', parentOrientation === 'horizontal' ? 'rotate-90' : '')}
        weight="bold"
      />
    </div>
  );
};
