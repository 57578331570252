/* eslint-disable */

import { FC, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { InformationCircleIcon as SolidInformationCircleIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon as OutlineInformationCircleIcon } from '@heroicons/react/24/outline';
import { Portal } from 'react-portal';
import { IconComponent } from '@heroicons/react';
import classNames from 'classnames';

interface TooltipProps {
  id: string;
  text: string;
  content?: React.ReactNode;
  onHover?: Function;
  iconClass?: string;
  Icon?: IconComponent;
  tooltipClass?: string;
  containerClass?: string;
  childrenContainerClass?: string;
  children?: React.ReactNode;
  portalMountedId?: string;
  infoIcon?: 'solid' | 'outline';
  showIcon?: boolean;
  iconRight?: boolean;
  autoWidth?: boolean;
  isEnabled?: boolean;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  padding?: string;
  supportingText?: string;
  preventMaxWidth?: boolean;
}

const Tooltip: FC<TooltipProps> = (props: TooltipProps) => {
  const {
    id,
    text,
    content,
    onHover,
    iconClass,
    tooltipClass,
    children,
    containerClass,
    childrenContainerClass,
    portalMountedId,
    Icon,
    infoIcon = 'outline',
    showIcon = true,
    iconRight = false,
    autoWidth = true,
    isEnabled = true,
    placement,
    padding,
    supportingText,
    preventMaxWidth = false,
  } = props;
  const [onHoverEnd, setOnHoverEnd] = useState(() => () => { });

  const handleMouseEnter = () => {
    if (onHover && isEnabled) {
      setOnHoverEnd(() => onHover());
    }
  };

  const tooltipContent = () => {
    return !!supportingText ? `<div><h2>${text}</h2><p class="font-normal mt-1">${supportingText}</p></div>` : text;
  };

  const icon = Icon ? (
    <Icon
      className={classNames('w-4 h-4 cursor-pointer text-surface-500', iconClass)}
      data-tip={tooltipContent()}
      data-for={`${id}-tip`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onHoverEnd}
    />
  ) : infoIcon === 'solid' ? (
    <SolidInformationCircleIcon
      className={classNames('w-4 h-4 cursor-pointer text-surface-500', iconClass)}
      data-tip={tooltipContent()}
      data-for={`${id}-tip`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onHoverEnd}
    />
  ) : (
    <OutlineInformationCircleIcon
      className={classNames('w-4 h-4 cursor-pointer text-surface-500', iconClass)}
      data-tip={tooltipContent()}
      data-for={`${id}-tip`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onHoverEnd}
    />
  );

  return (
    <div className={classNames(containerClass)}>
      {showIcon && isEnabled && !iconRight && icon}
      {children && (
        <div
          className={childrenContainerClass}
          data-tip={text}
          data-for={`${id}-tip`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={onHoverEnd}
        >
          {children}
        </div>
      )}
      {showIcon && isEnabled && iconRight && icon}
      {isEnabled && (
        <Portal node={portalMountedId && document ? document.getElementById(portalMountedId) : null}>
          <ReactTooltip
            id={`${id}-tip`}
            backgroundColor="#111827"
            place={placement || 'top'}
            type="dark"
            padding={padding}
            html={!!supportingText}
            effect="solid"
            delayShow={250}
            delayHide={250}
            className={classNames(
              '!p-2 !text-xs text-white leading-4 font-medium !rounded-[4px] !shadow-lg !opacity-100 break-words',
              autoWidth && !preventMaxWidth && 'max-w-52',
              !autoWidth && !preventMaxWidth && 'w-52',
              tooltipClass
            )}
          >
            {content}
          </ReactTooltip>
        </Portal>
      )}
    </div>
  );
};

export default Tooltip;
