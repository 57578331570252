import React, { createContext, useEffect, useState } from 'react';

import api from '../../services/swarm';

type ContextValue = [string, (advertiserId: string) => void];

const CurrentAdvertiserContext = createContext<ContextValue | undefined>(undefined);

CurrentAdvertiserContext.displayName = 'CurrentAdvertiserContext';

const CurrentAdvertiserProvider = ({
  fallbackAdvertiserId,
  children,
}: {
  fallbackAdvertiserId: string;
  children: React.ReactNode;
}) => {
  const defaultToken = localStorage.getItem('currentAdvertiserId');

  const [currentAdvertiserId, setCurrentAdvertiserId] = useState(defaultToken || fallbackAdvertiserId);

  const handleSetCurrentAdvertiserId = React.useCallback(
    (id: string) => {
      localStorage.setItem('currentAdvertiserId', id);

      // So that the Rails side is kept informed
      api
        .patch('/current_advertiser', { id })
        .then(() => {
          setCurrentAdvertiserId(id);
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);
        });
    },
    [setCurrentAdvertiserId]
  );

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'currentAdvertiserId' && e.newValue) {
        setCurrentAdvertiserId(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const contextValue: ContextValue = React.useMemo(() => {
    return [currentAdvertiserId, handleSetCurrentAdvertiserId];
  }, [currentAdvertiserId, handleSetCurrentAdvertiserId]);

  return <CurrentAdvertiserContext.Provider value={contextValue}>{children}</CurrentAdvertiserContext.Provider>;
};

function useCurrentAdvertiserState() {
  const context = React.useContext(CurrentAdvertiserContext);
  if (context === undefined) {
    throw new Error(`useCurrentAdvertiserState must be used within a CurrentAdvertiserProvider`);
  }

  return context;
}

export { CurrentAdvertiserContext, CurrentAdvertiserProvider, useCurrentAdvertiserState };
