import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { SiteTemplate } from '@/interfaces/site_template';

import api from '../../services/swarm';

const useUpdateSiteTemplate = ({ id, onSuccess }: { id: string; onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (siteTemplate: Partial<SiteTemplate>) =>
      api.patch(`/site_templates/${id}`, {
        publication_id: currentPublicationId,
        site_template: siteTemplate,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.message || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['publications', currentPublicationId, 'site_templates']);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'site_templates', id]);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'site_packages']);
      },
    }
  );
};

export default useUpdateSiteTemplate;
