import { ElementType } from 'react';
import cx from 'classnames';

import ProgressIndicator from '@/components/ProgressIndicator';
import { Typography } from '@/components/Typography';
import Tooltip from '@/ui/Tooltip';

import PlanPill from '../PlanPill';

interface Props {
  title: string;
  tagText: string;
  overlineText: string;
  underlineText: string;
  tooltipText?: string;
  percentCompleted?: number;
  variant: 'primary' | 'secondary' | 'tertiary';
  TagIcon: ElementType;
  className?: string;
}

const COLOR_MAP = Object.freeze({
  primary: {
    bgColor: 'bg-action-primary-50',
    gradient: 'bg-gradient-to-r from-action-primary-300 to-action-primary-700',
    barColor: 'bg-action-primary-700',
  },
  secondary: {
    bgColor: 'bg-action-secondary-50',
    gradient: 'bg-gradient-to-r from-action-secondary-300 to-action-secondary-700',
    barColor: 'bg-action-secondary-700',
  },
  tertiary: {
    bgColor: 'bg-action-tertiary-50',
    gradient: 'bg-gradient-to-r from-action-tertiary-300 to-action-tertiary-700',
    barColor: 'bg-action-tertiary-700',
  },
});

const PlanCardBase = ({
  title,
  variant,
  tagText,
  overlineText,
  underlineText,
  className,
  percentCompleted = 0,
  TagIcon,
  tooltipText,
}: Props) => {
  return (
    <div className={cx('rounded-md p-3 w-full', COLOR_MAP[variant].bgColor, className)}>
      <div className="flex justify-between items-center">
        <Typography size="sm" weight="bold">
          {title}
        </Typography>

        <PlanPill variant={variant} text={tagText} Icon={TagIcon} />
      </div>

      <div className="pt-2">
        <div className="space-y-1 text-left">
          <div className="flex items-center space-x-1">
            <Typography size="xs" weight="medium" color={variant}>
              {overlineText}
            </Typography>
            {tooltipText && (
              <Tooltip
                iconClass="text-action-tertiary-900"
                id={title}
                text={tooltipText}
                onHover={() => {}}
                portalMountedId={title?.split(' ').join('-').toLowerCase()}
              />
            )}
          </div>
          <ProgressIndicator value={percentCompleted} showIconTracker={false} color={COLOR_MAP[variant].barColor} />
          <Typography size="xs" weight="medium" color={variant}>
            {underlineText}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PlanCardBase;
