import { useState } from 'react';
import { Grid } from '@tremor/react';

import Card from '@/components/Card';
import { Typography } from '@/components/Typography';
import useBoostAgreements from '@/hooks/boosts/monetize/useBoostAgreements';
import BoostAgreementStatus from '@/interfaces/boosts/monetize/boost_agreement_status';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import AvailableEmailBoost from './AvailableEmailBoost';

const INITIAL_TILE_COUNT = 3;

const AvailableEmailBoosts = () => {
  const [showAll, setShowAll] = useState(false);

  const { data, refetch } = useBoostAgreements({
    status: BoostAgreementStatus.ACTIVE,
    isEnabled: true,
    allResults: true,
  });

  const boostAgreements = data?.pages.flatMap((page) => page.boost_agreements) || [];
  /*
    Filter and don't show agreements that
      * are already sent
      * not active
      * calc_max_payout_cents is zero, that is, the publication
        itself doesn't have any subscribers and won't yield
        any boosted subscribers
    Show max 3 at a time
  */
  const filteredBoostAgreements = boostAgreements.filter((boostAgreement) => {
    const { boost_send_is_not_expired: alreadySent } = boostAgreement;
    const boostAgreementActive = boostAgreement.status === BoostAgreementStatus.ACTIVE;

    if (boostAgreement.calc_max_payout_cents === 0 || alreadySent || !boostAgreementActive) {
      return false;
    }

    return true;
  });
  const slicedBoostAgreements = showAll
    ? filteredBoostAgreements
    : filteredBoostAgreements.slice(0, INITIAL_TILE_COUNT);

  if (filteredBoostAgreements.length === 0) {
    return null;
  }

  // Refetch agreements after a second so user
  // sees the "applied" button as a feedback
  const handleApply = () => {
    setTimeout(() => refetch(), 1000);
  };

  const handleClickLoadMore = () => setShowAll(true);

  return (
    <Card>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-2">
          <Typography token="font-bold/text/sm">Partners who have Email Boosts available</Typography>
          <Typography token="font-medium/text/sm">
            Apply to boost email as an additional channel offered by these publications.
          </Typography>
        </div>
        <Grid numCols={1} numColsMd={2} numColsLg={3} className="gap-5">
          {slicedBoostAgreements.map((boostAgreement) => (
            <AvailableEmailBoost
              key={`available-email-boost-${boostAgreement.id}`}
              onApply={handleApply}
              boostAgreement={boostAgreement}
            />
          ))}
        </Grid>
        {!showAll && filteredBoostAgreements.length > INITIAL_TILE_COUNT && (
          <div className="flex justify-start mt-8 w-fit">
            <LoadMoreButton fetchNextPage={handleClickLoadMore} isFetchingNextPage={false} />
          </div>
        )}
      </div>
    </Card>
  );
};

export default AvailableEmailBoosts;
