import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import ColorGroupSettings from '../ColorGroupSettings';
import { ColorSettings } from '../ColorSettings';
import FontGroupSettings from '../FontGroupSettings';
import { GapSettings } from '../GapSettings';
import { GridColumnSetting } from '../GridColumnSetting';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

import { BioSettings } from './BioSettings';
import { ImageSettings } from './ImageSettings';
import { NameSettings } from './NameSettings';
import { SocialsSettings } from './SocialsSettings';

export const AuthorsWidgetSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['authors']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Layout">
        <GridColumnSetting editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
        <GapSettings title="Gap" property="gap" editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>

      <SectionRenderer title="Click Action">
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Navigate to Author"
          property="shouldNavigateToAuthorPage"
        />
      </SectionRenderer>

      <SectionRenderer title="Card">
        <ColorSettings
          title="Background"
          property="cardBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'cardBorderColor',
            width: 'cardBorderWidth',
            style: 'cardBorderStyle',
            radius: 'cardBorderRadius',
          }}
        />
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} title="Align" property="alignment" />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="cardPadding" />
        <ColorGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Text"
          isUngroupable={false}
          properties={[
            { title: 'Name', property: 'cardTextColor' },
            { title: 'Bio', property: 'cardBioColor' },
            { title: 'Socials', property: 'cardSocialFill' },
          ]}
        />
        <FontGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isGroupedInitially
          isUngroupable={false}
          properties={[
            {
              title: 'Name',
              property: 'nameFontFamily',
            },
            {
              title: 'Bio',
              property: 'bioFontFamily',
            },
          ]}
        />
        <ImageSettings editor={editor} activeNodeResult={activeNodeResult} />
        <NameSettings editor={editor} activeNodeResult={activeNodeResult} />
        <BioSettings editor={editor} activeNodeResult={activeNodeResult} />
        <SocialsSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
