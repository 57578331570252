import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import Badge from '@/ui/Badge';

interface BulletPoint {
  text: string;
  enabled: boolean;
}

interface PlanCardProps {
  selected: boolean;
  onClick: () => void;
  title: string;
  bulletPointsHeader?: React.ReactNode;
  bulletPoints: BulletPoint[];
  showNoCardRequired?: boolean;
}

const PlanCard = ({
  selected,
  onClick,
  title,
  bulletPointsHeader,
  bulletPoints,
  showNoCardRequired,
}: PlanCardProps) => {
  return (
    <div className="w-full">
      <div
        className={cx(
          'cursor-pointer rounded border shadow-sm bg-white hover:border-gray-900',
          selected ? 'border-gray-900' : 'border-gray-200'
        )}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <div className="border-b border-b-gray-200 py-4 px-6 flex-row justify-between">
          <div className="flex flex-col gap-2">
            <Typography token="font-semibold/text/base">{title}</Typography>
            {showNoCardRequired && (
              <Badge type="info" className="w-fit" trailingIcon={<InformationCircleIcon className="w-3.5" />}>
                No credit card required
              </Badge>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4 py-4 px-6">
          {bulletPointsHeader}
          <div className="grid grid-cols-2 gap-4">
            {bulletPoints?.map((bulletPoint) => (
              <div key={bulletPoint.text} className="flex items-center gap-2">
                {bulletPoint.enabled ? (
                  <CheckIcon className="w-5 h-5 text-violet-600" />
                ) : (
                  <XMarkIcon className="text-surface-500 w-5 h-5" />
                )}
                <Typography token="font-normal/text/sm" colorWeight="700">
                  {bulletPoint.text}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
