import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { WebTheme } from '@/interfaces/web_theme';
import api from '@/services/swarm';

interface CreateSiteProps {
  sitePackageId: string;
  autoPopulate: boolean;
  theme: WebTheme;
}

export default function useCreateSite({ sitePackageId, autoPopulate, theme }: CreateSiteProps) {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<void, unknown>(
    () =>
      api.post(`/sites`, {
        publication_id: currentPublicationId,
        site_package_id: sitePackageId,
        auto_populate: autoPopulate,
        theme,
      }),
    {
      onSuccess: () => {
        toast.success('Website created successfully');
        queryClient.invalidateQueries(['site_themes', 'publications', currentPublicationId]);

        navigate(`/website_builder_v2`);
      },
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Something went wrong, please try again.');
      },
    }
  );
}
