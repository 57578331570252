import { TextAa } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { FontWeightSettings } from '../FontWeightSettings';
import { LineClampSettings } from '../LineClampSettings';
import { SimpleFontSizeSettings } from '../SimpleFontSizeSettings';
import { TransformSettings } from '../TransformSettings';

type Props = AttributeSettingProps & {};

export const TitlesPostSetting = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Title" text="Title" popoverTitle="Title" Icon={TextAa}>
      <ColorSettings title="Color" property="titleTextColor" editor={editor} activeNodeResult={activeNodeResult} />
      <LineClampSettings title="Lines" editor={editor} activeNodeResult={activeNodeResult} property="titleLineClamp" />
      <SimpleFontSizeSettings
        title="Size"
        editor={editor}
        activeNodeResult={activeNodeResult}
        property="titleFontSize"
      />
      <FontWeightSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        property="titleFontWeight"
        isMarkStyle={false}
      />
      <FontSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        isMarkStyle={false}
        title="Title Font"
        property="titleFontFamily"
      />
      <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="titleTransform" />
    </PopoverHelper>
  );
};

export default TitlesPostSetting;
