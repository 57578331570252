import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

import Banner from "@/components/Banner";
import { useWebTemplate } from "@/hooks";
import useBoostAgreements from "@/hooks/boosts/monetize/useBoostAgreements";
import BoostAgreementStatus from "@/interfaces/boosts/monetize/boost_agreement_status";

const RecommendationsDisabledBanner = () => {
  const navigate = useNavigate();
  const webTemplate = useWebTemplate();
  const { data } = useBoostAgreements({
    perPage: 1,
    status: [BoostAgreementStatus.PENDING, BoostAgreementStatus.ACTIVE],
    isEnabled: true,
  });

  const { enable_recommendations_page_post_signup: recommendationsEnabled } = webTemplate.data || {};
  const webAgreements = data?.pages.flatMap((page) => page.boost_agreements).filter(Boolean) || [];
  const shouldShowBanner = webAgreements.length > 0 && !recommendationsEnabled;

  if (!shouldShowBanner) return null;

  return (
    <Banner
      isScreenWide={false}
      variant="danger"
      title="Your Recommendation Page is currently disabled. Please enable Recommendations in your website's Signup Flow Settings to start earning with Boosts."
      ctaText="Go to Website Settings"
      ctaIcon={<ArrowRightIcon className="h-5 w-5" />}
      onClick={() => navigate('/website_builder/settings/signup_flow')}
    />
  );
};

export default RecommendationsDisabledBanner;
