import { useEffect, useMemo, useState } from 'react';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { SortableList } from '@/components/SortableList';
import { useSubscriberPreferences } from '@/hooks/useSubscriberPreferences';
import useUpdateSubscriberPreferences from '@/hooks/useSubscriberPreferences/useUpdateSubscriberPreferences';
import { Order } from '@/interfaces/general';
import {
  OrderBy,
  PublicationSubscriberPreference,
  SubscriberPreferenceVisibility,
} from '@/interfaces/subscribers_preferences';

interface Props {
  children: (list: PublicationSubscriberPreference[]) => JSX.Element[];
  emptyBlock: JSX.Element;
}

const ConfigureSubscriberPreferences = ({ children, emptyBlock }: Props) => {
  const [preferencesInState, setPreferencesInState] = useState<PublicationSubscriberPreference[]>([]);

  const { data, isLoading } = useSubscriberPreferences({
    all: true,
    visibility: SubscriberPreferenceVisibility.VISIBLE,
    orderBy: OrderBy.SORT_ORDER,
    direction: Order.ASC,
    keepPreviousData: false,
  });
  const preferences = useMemo(() => data?.pages.flatMap((page) => page.subscriber_preferences) || [], [data?.pages]);

  const { mutateAsync: update } = useUpdateSubscriberPreferences();

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const activePositionId = preferences.find((position: any) => position.id === active.id);
      const overPositionId = preferences.find((position: any) => position.id === over?.id);
      const oldIndex = preferences.indexOf(activePositionId as any);
      const newIndex = preferences.indexOf(overPositionId as any);
      const newList = arrayMove(preferences, oldIndex, newIndex);
      setPreferencesInState(newList);
      update(newList);
    }
  };

  useEffect(() => {
    if (!isLoading && preferences.length > 0) {
      setPreferencesInState(preferences);
    }
  }, [isLoading, preferences]);

  if (preferencesInState.length === 0) {
    return emptyBlock;
  }

  return (
    <SortableList listItems={preferencesInState} onDragEndEvent={onDragEnd}>
      {children}
    </SortableList>
  );
};

export default ConfigureSubscriberPreferences;
