import { Outlet } from 'react-router-dom';

import { UserDropdown } from './components/Layout/UserDropdown';
import AdvertiserDropdown from './Layout/AdvertiserDropdown';

export default function Layout() {
  return (
    <div className="flex min-h-screen flex-col">
      <header className="bg-white shadow">
        <div className="w-full h-full flex flex-row justify-between items-between px-4 py-2">
          <AdvertiserDropdown />
          <div className="pt-1">
            <UserDropdown />
          </div>
        </div>
      </header>
      <main className="bg-surface-50 flex-1">
        <Outlet />
      </main>
    </div>
  );
}
