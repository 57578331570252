import { PropsWithChildren } from 'react';

import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

interface DisabledProps {
  isDisabled?: boolean;
  tooltipText?: string;
}

export const Disabled = ({
  children,
  isDisabled,
  tooltipText = 'Switch to Desktop View to edit',
}: PropsWithChildren<DisabledProps>) => {
  if (!isDisabled) {
    return <div>{children}</div>;
  }

  return (
    <Tooltip center={tooltipText}>
      <div className="opacity-50 pointer-events-none">{children}</div>
    </Tooltip>
  );
};
