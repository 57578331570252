import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

import ActivityFeed from '../_components/ActivityFeed';
import { useSearchContext } from '../_context/searchContext';
import { useSubscriberContext } from '../_context/subscriberContext';
import { useSubscriberBillingHistoryActivityFeedEvents } from '../_hooks/useActivityFeedEvents';

const BillingHistory = () => {
  const { subscription } = useSubscriberContext();
  const { search, eventType } = useSearchContext();
  const query = useSubscriberBillingHistoryActivityFeedEvents({ search: search || undefined, subscription, eventType });

  return (
    <ActivityFeed
      title="Billing History Activity Feed"
      emptyText="No billing history events for this subscriber have been recorded yet."
      query={query}
      eventTypeOptions={[
        ActivityFeedEventType.PAYMENT_SUCCEEDED,
        ActivityFeedEventType.PAYMENT_FAILED,
        ActivityFeedEventType.GIFT_PURCHASED,
      ]}
    />
  );
};
export default BillingHistory;
