import { XMarkIcon } from '@heroicons/react/20/solid';

import IconButton from '@/components/IconHelpers/IconButton';

import { useMediaLibrary } from '../context/MediaLibraryContext';
import { MediaLibraryId, MediaLibraryTabState } from '../MediaLibrary.types';

import NavigationButton from './NavigationButton/NavigationButton';

const Navigation = () => {
  const { onClose } = useMediaLibrary();

  return (
    <div className="w-full grid grid-cols-[1fr_auto_1fr] py-3 px-6 border-b border-black/10 items-center">
      <div />

      <ul className="flex py-1.5 px-1 border rounded-md border-surface-200 bg-surface-100">
        <li>
          <NavigationButton
            label="Current publication"
            tabId={MediaLibraryTabState.BEEHIIV}
            libraryId={MediaLibraryId.CURRENTPOST}
          />
        </li>
        <li>
          <NavigationButton label="Giphy" icon="Giphy" tabId={MediaLibraryTabState.GIPHY} />
        </li>
        <li>
          <NavigationButton label="Unsplash" icon="Unsplash" tabId={MediaLibraryTabState.UNSPLASH} />
        </li>
      </ul>

      <IconButton onClick={onClose} className="ml-auto w-6 h-6 -mr-1">
        <XMarkIcon className="w-6 h-6" />
      </IconButton>
    </div>
  );
};

export default Navigation;
