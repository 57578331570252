import { useCallback, useEffect, useMemo, useState } from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { useEditorState } from '@tiptap/react';

import { cn } from '../../../../_utils/cn';
import { ColorPicker } from '../../../UI/ColorPicker';
import { SimpleInput, SimpleInputWrapper } from '../../../UI/Input';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { AttributeSettingProps } from '../types';
import { parseGradient } from '../utils/parseGradient';

type GradientSettingsProps = AttributeSettingProps & {
  property?: string;
  isFontGradient?: boolean;
  isMarkStyle?: boolean;
  onSetLastValue?: (value: string) => void;
};

const ThumbContent = ({
  color,
  isActive,
  setActive,
}: {
  color: string | undefined;
  isActive: boolean;
  setActive: () => void;
}) => {
  return (
    <button
      type="button"
      className={cn(
        'relative p-1.5 flex items-center justify-center rounded translate-x-[-50%] translate-y-[-28px]',
        isActive ? 'bg-blue-500' : 'bg-wb-tertiary'
      )}
      onClick={setActive}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setActive();
        }
      }}
    >
      <div className="w-4 h-4" style={{ background: color }} />
      <div
        className={cn(
          'absolute left-1/2 bottom-0 translate-x-[-50%] translate-y-[100%] w-0 h-0 border-x-[4px] border-x-transparent border-t-[8px] border-t-wb-darken',
          isActive ? 'border-t-blue-500' : 'border-t-wb-darken'
        )}
      />
    </button>
  );
};

const GradientSettings = ({
  editor,
  activeNodeResult,
  property = 'background',
  isFontGradient,
  isMarkStyle = false,
  onSetLastValue,
}: GradientSettingsProps) => {
  const [value, setValue] = useState<number[]>([0, 100]);
  const [colorOne, setColorOne] = useState<string | undefined>('#000000FF');
  const [colorTwo, setColorTwo] = useState<string | undefined>('#000000FF');
  const [gradientType, setGradientType] = useState<'linear' | 'radial'>('linear');
  const [degree, setDegree] = useState(90);
  const [isColorOnePickerOpen, setIsColorOnePickerOpen] = useState(true);
  const [isColorTwoPickerOpen, setIsColorTwoPickerOpen] = useState(false);

  const buildTailwindGradient = (
    c1Percentage: number,
    c2Percentage: number,
    c1: string,
    c2: string,
    type: 'linear' | 'radial'
  ) => {
    if (type === 'linear') {
      return `linear-gradient(${degree}deg, ${c1} ${c1Percentage}%, ${c2} ${c2Percentage}%)`;
    }

    return `radial-gradient(circle, ${c1} ${c1Percentage}%, ${c2} ${c2Percentage}%)`;
  };

  const attrGradient = useMemo(() => {
    return activeNodeResult.activeNodeAttributes[property];
  }, [activeNodeResult, property]);

  const fontGradient = useEditorState({
    editor,
    selector: ({ editor: e }) => {
      if (isMarkStyle) {
        return e?.getAttributes('textStyle').fontGradient;
      }

      return activeNodeResult.activeNodeAttributes.fontGradient;
    },
  });

  const onSetGradient = useCallback(
    (gradient: string) => {
      if (!editor || !activeNodeResult) return;
      if (onSetLastValue) {
        onSetLastValue(gradient);
      }
      if (isFontGradient) {
        editor.chain().selectTextBlock().setFontGradient(gradient).run();
      } else {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodeResult.activeNodePos, property, gradient);
          return true;
        });
      }
    },
    [editor, activeNodeResult, onSetLastValue, isFontGradient, property]
  );

  const currentGradientType = fontGradient?.startsWith('linear') ? 'linear' : fontGradient ?? 'linear';

  useEffect(() => {
    if (gradientType !== currentGradientType) {
      setGradientType(currentGradientType);
    }
  }, [currentGradientType]);

  useEffect(() => {
    if (!fontGradient && !attrGradient) return;

    const { colorStops } = parseGradient(fontGradient || attrGradient);
    if (colorStops?.length !== 2) return;

    const c1 = colorStops?.[0]?.color;
    const c2 = colorStops?.[1]?.color;
    const percent1 = colorStops?.[0]?.position || 0;
    const percent2 = colorStops?.[1]?.position || 100;

    if (!c1 || !c2) return;

    setColorOne(c1);
    setColorTwo(c2);
    setValue([percent1, percent2]);
  }, [fontGradient, attrGradient]);

  const handleColorChange = (c1: string | undefined, c2: string | undefined) => {
    if (!c1 || !c2) return;
    const computedGradient = buildTailwindGradient(value[0], value[1], c1, c2, gradientType);
    onSetGradient(computedGradient);
  };

  const handleValueChange = (newValue: number[]) => {
    if (!colorOne || !colorTwo) return;
    const computedGradient = buildTailwindGradient(newValue[0], newValue[1], colorOne, colorTwo, gradientType);
    onSetGradient(computedGradient);
    setValue(newValue);
  };

  const handleChangeGradientType = (type: 'linear' | 'radial') => {
    setGradientType(type);
    if (!colorOne || !colorTwo) return;
    if (value.length < 2) return;
    const computedGradient = buildTailwindGradient(value[0], value[1], colorOne, colorTwo, type);
    onSetGradient(computedGradient);
  };

  return (
    <div
      className="flex flex-col gap-2"
      style={
        {
          '--gradient-color-one': colorOne,
          '--gradient-color-two': colorTwo,
        } as React.CSSProperties
      }
    >
      <div className="w-full flex flex-col gap-2">
        <div className="flex items-center justify-stretch gap-2">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Gradient
          </Text>
          <div className="grow flex items-center">
            <SimpleInputWrapper className="h-[38px] px-1">
              <ToggleGroup
                className="py-[1px] w-full"
                type="single"
                value={gradientType}
                defaultValue={gradientType}
                onValueChange={(type: 'linear' | 'radial') => {
                  if (!type) return;
                  handleChangeGradientType(type);
                }}
              >
                <ToggleGroupItem asChild value="linear">
                  <Text variant="inherit" size="2xs" weight="medium" className="grow">
                    Linear
                  </Text>
                </ToggleGroupItem>
                <ToggleGroupItem asChild value="radial">
                  <Text variant="inherit" size="2xs" weight="medium" className="grow">
                    Radial
                  </Text>
                </ToggleGroupItem>
              </ToggleGroup>
            </SimpleInputWrapper>
          </div>
        </div>

        {gradientType === 'linear' && (
          <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
            <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
              Degree
            </Text>
            <SimpleInputWrapper className="h-[32px] relative">
              <SimpleInput
                type="number"
                min={0}
                max={90}
                className="text-xs"
                defaultValue={degree}
                onChange={(e) => setDegree(parseInt(e.target.value, 10))}
              />
              <Text
                size="2xs"
                weight="medium"
                variant="secondary"
                className="absolute top-1/2 right-3 -translate-y-1/2"
              >
                deg
              </Text>
            </SimpleInputWrapper>
          </div>
        )}

        <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
          <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
            Color One
          </Text>
          <button
            type="button"
            onClick={() => {
              setIsColorOnePickerOpen(true);
              setIsColorTwoPickerOpen(false);
            }}
            className="flex items-center justify-stretch gap-2 select-none min-w-0"
          >
            <SimpleInputWrapper className="h-[32px] relative">
              <div
                style={{ backgroundColor: colorOne }}
                className="absolute top-1/2 left-2 -translate-y-1/2 w-4 h-4 rounded-md"
              />
              <SimpleInput type="text" className="text-xs pl-6" value={colorOne} onChange={() => {}} />
            </SimpleInputWrapper>
          </button>
        </div>

        <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
          <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
            Color Two
          </Text>
          <button
            type="button"
            onClick={() => {
              setIsColorTwoPickerOpen(true);
              setIsColorOnePickerOpen(false);
            }}
            className="flex items-center justify-stretch gap-2 select-none min-w-0"
          >
            <SimpleInputWrapper className="h-[32px] relative">
              <div
                style={{ backgroundColor: colorTwo }}
                className="absolute top-1/2 left-2 -translate-y-1/2 w-4 h-4 rounded-md"
              />
              <SimpleInput type="text" className="text-xs pl-6" value={colorTwo} onChange={() => {}} />
            </SimpleInputWrapper>
          </button>
        </div>

        <SliderPrimitive.Root
          className={cn('relative flex w-[calc(100%-28px)] mx-auto touch-none select-none items-center pt-10 pb-4')}
          value={value}
          defaultValue={value} // Center the thumb initially
          max={100} // Assuming max range is 100
          step={5}
          onValueChange={handleValueChange} // Update state on slider change
        >
          <SliderPrimitive.Track className="relative h-2 w-[calc(100%-40px)] grow rounded-full bg-wb-secondary">
            <div
              className="absolute left-0 top-0 w-full h-full rounded-full"
              style={{
                background: `linear-gradient(90deg, ${colorOne} ${value[0]}%, ${colorTwo} ${value[1]}%)`,
              }}
            />

            <SliderPrimitive.Range className="absolute h-full bg-transparent" />
          </SliderPrimitive.Track>
          <SliderPrimitive.Thumb className="block h-fit w-[1px] bg-wb-secondary rounded-full">
            <ThumbContent
              color={colorOne}
              isActive={isColorOnePickerOpen}
              setActive={() => {
                setIsColorOnePickerOpen(true);
                setIsColorTwoPickerOpen(false);
              }}
            />
          </SliderPrimitive.Thumb>
          <SliderPrimitive.Thumb className="block h-fit w-[1px] bg-wb-secondary rounded-full">
            <ThumbContent
              color={colorTwo}
              isActive={isColorTwoPickerOpen}
              setActive={() => {
                setIsColorTwoPickerOpen(true);
                setIsColorOnePickerOpen(false);
              }}
            />
          </SliderPrimitive.Thumb>
        </SliderPrimitive.Root>
      </div>

      {isColorOnePickerOpen && (
        <ColorPicker
          selectedColor={colorOne}
          onSetColor={(color: string | null) => {
            setColorOne(color || '#000000');
            handleColorChange(color || '#000000', colorTwo);
          }}
          onReset={() => {
            setColorOne('#000000');
            handleColorChange('#000000', colorTwo);
          }}
        />
      )}

      {isColorTwoPickerOpen && (
        <ColorPicker
          selectedColor={colorTwo}
          onSetColor={(color: string | null) => {
            setColorTwo(color || '#E3E3E3');
            handleColorChange(colorOne, color || '#E3E3E3');
          }}
          onReset={() => {
            setColorTwo('#E3E3E3');
            handleColorChange(colorOne, '#E3E3E3');
          }}
        />
      )}
    </div>
  );
};

export default GradientSettings;
