import { useCallback } from 'react';

import { WebTheme } from '@/interfaces/web_theme';
import { cn } from '@/utils/cn';

import TemplatePreviewer from '../../_components/Templates/TemplatePreviewer';
import { Checkbox } from '../../_components/UI/Checkbox';
import { Label } from '../../_components/UI/Label';
import { Text } from '../../_components/UI/Text';
import { FontSelection } from '../../settings/themes/_components/FontSelection';

import Heading from './Heading';

type FontPairing = {
  title: string;
  header: string;
  body: string;
};

const DEFAULT_FONT_PAIRINGS: FontPairing[] = [
  {
    title: 'Classical Elegance',
    header: 'Cormorant',
    body: 'Raleway',
  },
  {
    title: 'Refined Contemporary',
    header: 'Libre Bodoni',
    body: 'Inter',
  },
  {
    title: 'Experimental',
    header: 'Aboreto',
    body: 'Work Sans',
  },
  {
    title: 'Friendly & Casual',
    header: 'Pacifico',
    body: 'Source Sans Pro',
  },
  {
    title: 'Industrial & Modern',
    header: 'Bebas Neue',
    body: 'Source Sans Pro',
  },
  {
    title: 'Corporate & Professional',
    header: 'IBM Plex',
    body: 'Nunito',
  },
  {
    title: 'Bold Contemporary ',
    header: 'Montserrat',
    body: 'Raleway',
  },
  {
    title: 'Modern Editorial',
    header: 'DM Serif',
    body: 'Roboto',
  },
];

interface Props {
  previewNavbar: any;
  previewContent: any;
  previewFooter: any;
  onNext: () => void;
  onPrevious?: () => void;
  style: WebTheme;
  setStyle: (style: WebTheme) => void;
  isLoading: boolean;
  title?: string;
  description?: string;
  nextText?: string;
}

const Step3 = ({
  previewNavbar,
  previewContent,
  previewFooter,
  onNext,
  onPrevious,
  style,
  setStyle,
  isLoading,
  title,
  description,
  nextText,
}: Props) => {
  const handleSetFontFamily = useCallback(
    (newStyle: any) => {
      setStyle({ ...style, ...newStyle });
    },
    [style, setStyle]
  );

  return (
    <div className="w-full flex flex-col gap-8">
      <Heading
        title={title || 'Pick your fonts'}
        description={description || 'You can always change these any time in the editor'}
        onNext={onNext}
        onPrevious={onPrevious}
        nextText={nextText || 'Start Building'}
        isLoading={isLoading}
      />

      <div className="grid grid-cols-12 gap-16">
        <div className="col-span-8 h-[500px] overflow-y-scroll no-scrollbar">
          <TemplatePreviewer
            navbarContent={previewNavbar as any}
            content={previewContent as any}
            footerContent={previewFooter as any}
            containerHeightClass="h-full"
            enableScroll
            disableMemo
            hasBrowserBar
            allowClicking={false}
          />
        </div>

        <div className="col-span-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 px-2">
              <FontSelection
                title="Heading Font"
                onSetFontFamily={(value) => handleSetFontFamily({ header_font: value })}
                value={style?.header_font}
                placement="bottom"
                offsetValue={10}
                showToggleGroup={false}
              />
              <FontSelection
                title="Body Font"
                onSetFontFamily={(value) => handleSetFontFamily({ body_font: value })}
                value={style?.body_font}
                placement="bottom"
                offsetValue={10}
                showToggleGroup={false}
              />
              <FontSelection
                title="Button Font"
                onSetFontFamily={(value) => handleSetFontFamily({ button_font: value })}
                value={style?.button_font}
                placement="bottom"
                offsetValue={10}
                showToggleGroup={false}
              />
            </div>

            <div className="flex flex-col gap-2 h-[350px] overflow-y-scroll no-scrollbar px-2">
              {DEFAULT_FONT_PAIRINGS?.map((fontPairing: FontPairing) => {
                const isSelected =
                  style?.header_font === fontPairing.header &&
                  style?.body_font === fontPairing.body &&
                  style?.button_font === fontPairing.body;

                return (
                  <div className="relative">
                    <Label
                      key={fontPairing.title}
                      htmlFor={fontPairing.title}
                      className={cn(
                        'flex flex-col cursor-pointer border rounded-xl bg-wb-secondary focus:outline-none relative justify-center items-center px-4 py-2',
                        isSelected ? 'border-wb-accent border-2 bg-wb-button-accent-soft' : 'border-wb-primary'
                      )}
                    >
                      <div className="flex flex-col w-full">
                        <Text weight="bold" variant="primary" size="xl" as="span" className="">
                          <span
                            className="text-left"
                            style={{
                              fontFamily: fontPairing.header,
                            }}
                          >
                            {fontPairing.header}
                          </span>
                        </Text>
                        <Text weight="medium" variant="primary" size="md" as="span" className="">
                          <span
                            className="text-left"
                            style={{
                              fontFamily: fontPairing.body,
                            }}
                          >
                            {fontPairing.body}
                          </span>
                        </Text>
                      </div>
                      <Checkbox
                        id={fontPairing.title}
                        className={cn('absolute -top-2 -right-2', isSelected ? 'opacity-0' : 'opacity-0')}
                        checked={Boolean(isSelected)}
                        onCheckedChange={() => {
                          handleSetFontFamily({
                            header_font: fontPairing.header,
                            body_font: fontPairing.body,
                            button_font: fontPairing.body,
                          });
                        }}
                      />
                    </Label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
