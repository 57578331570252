import { useInfiniteQuery } from 'react-query';

import { AdNetworkUser } from '@/interfaces/ad_network/internal/user';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  users: AdNetworkUser[];
  pagination: Pagination;
}

interface Props {
  query?: string;
  orderBy?: 'created_at' | 'email';
  dir?: 'asc' | 'desc';
}

export default function useUsers({ query = '', orderBy = 'created_at', dir = 'desc' }: Props) {
  const fetchUsers = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/ad_network/internal/users`, {
        params: {
          order_by: orderBy,
          page: pageParam,
          per_page: 25,
          dir,
          query,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(['ad_network', 'internal', 'users', orderBy, dir, query], fetchUsers, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
