import { FileArrowUp } from '@phosphor-icons/react';

import { useSettings } from '@/context/settings-context';
import { Button } from '@/routes/website/_components/UI/Button';
import { Text } from '@/routes/website/_components/UI/Text';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

import NavbarSelection from '../NavbarSelectionModal';
import NavbarTemplate from '../NavTemplate';

import ThemeSettings from './ThemeSettings';

export const ActionsSettings = () => {
  const { settings } = useSettings();
  const isHiivDesigner = settings?.hiiv_dream_designer;

  return (
    <div className="flex gap-2 justify-between">
      <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
        Actions
      </Text>

      <div className="flex justify-end gap-2">
        <ThemeSettings />
        {isHiivDesigner && (
          <>
            <NavbarTemplate />
            <Tooltip center="Load Template" delay={300}>
              <NavbarSelection
                actionButton={
                  <Button variant="secondary" size="sm" className="text-wb-secondary" LeftIcon={FileArrowUp} />
                }
              />
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};
