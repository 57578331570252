import { Image } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import { ColorSettings } from '../ColorSettings';
import { SliderSettings } from '../SliderSettings';

type Props = AttributeSettingProps & {};

export const ImagePostSetting = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;
  const imageSizeFixed = Boolean(activeNodeAttributes?.imageSizeFixed);
  const imageEnabled = Boolean(activeNodeAttributes?.imageEnabled);

  return (
    <PopoverHelper title="Image" text="Image" popoverTitle="Image" Icon={Image}>
      <>
        <BooleanSettings editor={editor} activeNodeResult={activeNodeResult} property="imageEnabled" title="Enabled" />
        {imageEnabled && (
          <>
            <BooleanSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="imageSizeFixed"
              title="Size Fixed"
            />
            <BooleanSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="hasBackgroundImage"
              title="As Background Image"
            />
            <ColorSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="backgroundImageFilterColor"
              title="Filter"
            />
            {imageSizeFixed && (
              <>
                <SliderSettings
                  editor={editor}
                  activeNodeResult={activeNodeResult}
                  property="imageWidth"
                  title="Width"
                  unit="px"
                  min={0}
                  max={600}
                />
                <SliderSettings
                  editor={editor}
                  activeNodeResult={activeNodeResult}
                  property="imageHeight"
                  title="Height"
                  unit="px"
                  min={0}
                  max={600}
                />
              </>
            )}
            <SliderSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="imageBorderRadius"
              title="Radius"
              unit="px"
              min={0}
              max={100}
            />
          </>
        )}
      </>
    </PopoverHelper>
  );
};

export default ImagePostSetting;
