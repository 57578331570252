import { useCallback, useEffect, useState } from 'react';
import { Info } from '@phosphor-icons/react';

import { SimpleInputWrapper } from '../../../UI/Input';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';

type BooleanSettingsProps = AttributeSettingProps<boolean> & {
  property?: string;
  title?: string;
  defaultValue?: boolean;
  tooltip?: string;
  isExternalLink?: boolean;
};

export const BooleanSettings = ({
  editor,
  activeNodeResult,
  property = 'enabled',
  title = 'Enabled',
  defaultValue = false,
  tooltip = '',
  onChange,
  isExternalLink,
}: BooleanSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const [val, setVal] = useState<string>(defaultValue || activeNodeAttributes?.[property] ? 'true' : 'false');

  useEffect(() => {
    if (isExternalLink) {
      setVal('true');
    }
  }, [isExternalLink]);

  useEffect(() => {
    if (activeNodeAttributes) {
      if (isExternalLink) {
        setVal('true');
      } else {
        setVal(`${!!activeNodeAttributes[property]}`);
      }
    }
  }, [activeNodeAttributes, property, isExternalLink]);

  const handleBooleanChange = useCallback(
    (value: boolean) => {
      if (activeNodePos === undefined || !activeNodeType) return;

      if (onChange) {
        onChange(value);
        return;
      }

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, property, editor, onChange]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <div className="flex items-center gap-1 w-[80px]">
        <Text className="" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        {tooltip && (
          <Tooltip center={tooltip}>
            <Info size={16} className="text-wb-secondary" />
          </Tooltip>
        )}
      </div>
      <div className="grow flex items-center">
        <SimpleInputWrapper className="h-[38px] px-1">
          <ToggleGroup
            className="py-[2px] w-full"
            type="single"
            value={val}
            onValueChange={(value) => {
              if (!value) return;
              setVal(value);
              handleBooleanChange(value === 'true');
            }}
          >
            <ToggleGroupItem asChild value="true">
              <div className="grow">
                <Text variant="inherit" size="2xs" weight="medium">
                  YES
                </Text>
              </div>
            </ToggleGroupItem>
            <ToggleGroupItem asChild value="false">
              <div className="grow">
                <Text variant="inherit" size="2xs" weight="medium">
                  NO
                </Text>
              </div>
            </ToggleGroupItem>
          </ToggleGroup>
        </SimpleInputWrapper>
      </div>
    </div>
  );
};
