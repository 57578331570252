import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface ApiResponse {
  data: any;
}

type Props = {
  type?: string;
  timePeriod?: string;
  timeZone?: string;
};

const useTotalBalanceSnapshots = ({ type, timePeriod, timeZone }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchSnapshots = (): Promise<ApiResponse> =>
    api
      .get(`/wallet/total_balance_snapshots`, {
        params: {
          publication_id: currentPublicationId,
          type,
          period: timePeriod,
          time_zone: timeZone,
        },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, 'wallet', 'total_balance_snapshots', type, timePeriod, timeZone],
    fetchSnapshots,
    {
      keepPreviousData: true,
    }
  );
};

export default useTotalBalanceSnapshots;
