import { useNavigate } from 'react-router-dom';
import { ServerStackIcon } from '@heroicons/react/24/solid';

import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import BodyContainer from '../../../_components/BodyContainer';
import CardHeader from '../../../_components/CardHeader';

import { CONNECT_DOMAIN_HEADER_TEXTS } from './constants';

interface Props {
  showOnboardingStep: boolean;
  canEntri: boolean;
}

const ConnectDomainCard = ({ showOnboardingStep, canEntri }: Props) => {
  const navigate = useNavigate();

  return (
    <Card>
      <BodyContainer>
        <CardHeader
          title="Connect to an Existing Website Domain"
          description={
            <div>
              <Typography token="font-normal/text/sm" colorWeight="500" as="span">
                {CONNECT_DOMAIN_HEADER_TEXTS[canEntri ? 'entri' : 'default']}
              </Typography>{' '}
              <Typography token="font-medium/text/sm" colorWeight="600" color="secondary" as="span">
                <a
                  href="https://www.beehiiv.com/support/article/12999491162391#h_01HBV5BWSH6V66BPB98KYPK7R2-how-to-articles-about-using-a-custom-domain-with-beehiiv"
                  rel="nofollow noreferrer noopener"
                  target="_blank"
                >
                  Learn more about popular DNS providers
                </a>
              </Typography>
            </div>
          }
        />

        {showOnboardingStep ? (
          <Button
            variant="primary"
            size="xs"
            Icon={ServerStackIcon}
            onClick={() => navigate('/settings/publication/domain/new')}
            className="w-fit"
          >
            Set Up Custom Domains
          </Button>
        ) : null}
      </BodyContainer>
    </Card>
  );
};

export default ConnectDomainCard;
