import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import useCheckPublicationPermissions from '@/hooks/useCheckPublicationPermissions/useCheckPublicationPermissions';
import { CurrentUserPublication } from '@/hooks/useCurrentUserOrganizations';

import NavLink from './NavLink';

interface Props {
  publication: CurrentUserPublication;
  onLinkClick: () => void;
}

const PublicationNav = ({ publication, onLinkClick }: Props) => {
  const location = useLocation();
  const { permissions } = publication;

  const navLinks = [
    {
      label: 'General Info',
      to: '/settings/publication/general',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/general', 'read'),
    },
    {
      label: 'Emails',
      to: '/settings/publication/emails',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/emails', 'read'),
    },
    {
      label: 'Content Import',
      to: '/settings/publication/content_import',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/content_import', 'read'),
    },
    {
      label: 'Subscribers Import',
      to: '/settings/publication/subscribers_import',
      isDisabled: !useCheckPublicationPermissions(
        permissions,
        'views/settings/v2/publication/subscribers_import',
        'read'
      ),
    },
    {
      label: 'Export Data',
      to: '/settings/publication/export_data',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/export_data', 'read'),
    },
    {
      label: 'Domains',
      to: '/settings/publication/domain',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/domain', 'read'),
    },
    {
      label: 'Webhooks',
      to: '/settings/publication/webhooks',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/webhooks', 'read'),
    },
    {
      label: 'Payment Accounts',
      to: '/settings/publication/payment_accounts',
      isDisabled: !useCheckPublicationPermissions(
        permissions,
        'views/settings/v2/publication/payment_accounts',
        'read'
      ),
    },
    {
      label: 'RSS',
      to: '/settings/publication/rss',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/v2/publication/rss', 'read'),
    },
  ];

  return (
    <ul>
      <li className="rounded-md bg-surface-50 border border-surface-100">
        <nav className={classNames('w-full flex flex-col gap-y-1 px-3 py-2')}>
          {navLinks.map((link) => (
            <NavLink
              to={link.to}
              label={link.label}
              isSelected={location.pathname.startsWith(link.to)}
              isDisabled={link.isDisabled}
              key={link.to}
              onClick={onLinkClick}
            />
          ))}
        </nav>
      </li>
    </ul>
  );
};

export default PublicationNav;
