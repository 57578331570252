import cx from 'classnames';

interface Props {
  children: React.ReactNode;
  largeNavOpen: boolean;
}

export const NavSection = ({ children, largeNavOpen }: Props) => {
  return <div className={cx('w-full flex flex-col', largeNavOpen ? 'p-4' : 'p-2 pt-4')}>{children}</div>;
};
