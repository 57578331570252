import { useEffect, useMemo, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import ReactTooltip from 'react-tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { Input } from '.';

interface Props {
  value?: number;
  name: string;
  id?: string;
  placeholder?: string;
  labelText?: string;
  helperText?: string;
  errorText?: string;
  onChange: (value: number) => void;
  minCents?: number;
  maxCents?: number;
  disabled?: boolean;
  infoText?: string;
  required?: boolean;
  portalMountedId?: string;
  currency?: string;
  className?: string;
  leadingText?: string;
}

function currencyToCents(input: string): number | null {
  const cleanedInput = input.replace(/[^0-9.]/g, '');
  const parts = cleanedInput.split('.');

  if (parts.length > 2) {
    return null;
  }

  const hasDecimal = parts.length === 2;
  const integerPart = parseInt(parts[0], 10) || 0;

  const decimalPart = hasDecimal ? parseInt(parts[1].slice(0, 2).padEnd(2, '0'), 10) : 0;

  if (!Number.isNaN(integerPart) && (!hasDecimal || !Number.isNaN(decimalPart))) {
    return integerPart * 100 + decimalPart;
  }

  return null;
}

const CurrencyInput = ({
  value,
  onChange,
  name,
  placeholder,
  id,
  labelText,
  helperText,
  errorText,
  minCents,
  maxCents,
  disabled = false,
  infoText,
  required = false,
  portalMountedId,
  className,
  currency = 'USD',
  leadingText,
}: Props) => {
  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 2,
      }),
    [currency]
  );

  const [friendlyAmount, setFriendlyAmount] = useState(value ? formatter.format(value / 100) : undefined);
  const [amountCents, setAmountCents] = useState<number | undefined>(value);
  const tooltipId = `currency-input-tooltip-${id}`;

  useEffect(() => {
    if (friendlyAmount) {
      setAmountCents(currencyToCents(friendlyAmount) || 0);
    } else {
      setAmountCents(undefined);
    }
  }, [friendlyAmount]);

  useEffect(() => {
    if (amountCents || amountCents === 0) {
      setFriendlyAmount(formatter.format(amountCents / 100));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatter]);

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    if (amountCents !== undefined) {
      onChange(amountCents);
    }
  }, [amountCents, onChange]);

  let amountError: string | undefined;
  if (Number.isNaN(amountCents)) {
    amountError = 'Amount must be a number';
  } else if ((minCents || minCents === 0) && (amountCents || amountCents === 0) && amountCents < minCents) {
    amountError = `Amount must be at least ${formatter.format(minCents / 100)}`;
  } else if ((maxCents || maxCents === 0) && (amountCents || amountCents === 0) && amountCents > maxCents) {
    amountError = `Amount must be at most ${formatter.format(maxCents / 100)}`;
  }

  return (
    <div className="flex flex-row space-x-2 items-center">
      <Input
        className={className}
        type="text"
        name={name}
        id={id}
        disabled={disabled}
        placeholder={placeholder || formatter.format(0)}
        value={friendlyAmount}
        onChange={(e) => setFriendlyAmount(e.target.value)}
        labelText={labelText}
        helperText={!amountError && helperText}
        errorText={amountError || errorText}
        onBlur={() => {
          if (friendlyAmount && (amountCents || amountCents === 0)) {
            setFriendlyAmount(formatter.format(amountCents / 100));
          }
        }}
        leadingText={leadingText}
        required={required}
        onKeyDown={(e: React.KeyboardEvent) => {
          // subtract 100 cents when the user presses the down arrow
          if (e.key === 'ArrowDown' && (amountCents || amountCents === 0)) {
            const decrease = e.shiftKey ? 1000 : 100;
            const val = formatter.format((amountCents - decrease) / 100);

            // if the amount is less than the min, set it to the min
            if (minCents) {
              setFriendlyAmount(amountCents - decrease < minCents ? formatter.format(minCents / 100) : val);
            } else {
              setFriendlyAmount(val);
            }
          }

          // add 100 cents when the user presses the up arrow
          if (e.key === 'ArrowUp' && (amountCents || amountCents === 0)) {
            const increase = e.shiftKey ? 1000 : 100;
            const val = formatter.format((amountCents + increase) / 100);

            // if the amount is greater than the max, set it to the max
            if (maxCents) {
              setFriendlyAmount(amountCents + increase > maxCents ? formatter.format(maxCents / 100) : val);
            } else {
              setFriendlyAmount(val);
            }
          }

          if (e.key === 'Enter' && (amountCents || amountCents === 0)) {
            setFriendlyAmount(formatter.format(amountCents / 100));
          }
        }}
      />
      {infoText && (
        <>
          <div data-tip data-for={tooltipId}>
            <InformationCircleIcon className="w-5 h-5 text-gray-400" />
          </div>
          <Portal node={portalMountedId && document ? document.getElementById(portalMountedId) : null}>
            <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-300">{infoText}</span>
                </div>
              </div>
            </ReactTooltip>
          </Portal>
        </>
      )}
    </div>
  );
};

export default CurrencyInput;
