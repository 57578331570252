import { ArrowLeftIcon } from '@heroicons/react/24/solid';

import { Checkbox } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { IUser } from '@/interfaces/user';
import CardHeader from '@/routes/settings/_components/CardHeader';
import { Button } from '@/ui/Button';

interface Props {
  user?: IUser;
  premiumSubscriptionCount: number;
  acknowledged: boolean;
  onAcknowledgedChanged: (val: boolean) => void;
  onPreviousStep: () => void;
  onNextStep: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

const CancelPaidSubscriptions = (props: Props) => {
  const {
    user,
    premiumSubscriptionCount,
    acknowledged,
    onAcknowledgedChanged,
    onPreviousStep,
    onNextStep,
    onCancel,
    isLoading,
  } = props;

  if (!user) return null;

  return (
    <div className="flex flex-col gap-y-6 justify-between min-h-[80vh]">
      <div className="flex flex-col gap-10 flex-grow">
        <CardHeader
          title={`It looks you have ${premiumSubscriptionCount} active paying subscribers.`}
          description={
            <Typography token="font-normal/text/sm" colorWeight="500">
              Downgrading to the launch plan will remove their paid access and stop charging these subscribers.{' '}
              <Typography token="font-bold/text/sm" colorWeight="900">
                This action is irreversible.
              </Typography>
            </Typography>
          }
        />

        <div className="lg:flex items-start justify-between space-y-8 lg:space-y-0">
          <div className="w-full">
            <form className="space-y-4">
              <Checkbox
                name="acknowledged"
                labelText="I understand that downgrading will cancel any recurring subscriptions in stripe."
                onChange={(val) => onAcknowledgedChanged(val)}
                checked={acknowledged}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 gap-x-2 justify-end sm:flex-row sm:justify-between">
        <Button variant="flush" Icon={ArrowLeftIcon} onClick={onPreviousStep}>
          Back
        </Button>

        <div className="flex flex-col gap-y-2 sm:flex-row sm:gap-x-2">
          <Button variant="primary-inverse" onClick={onCancel}>
            Nevermind, keep my plan
          </Button>

          <Button variant="primary" loading={isLoading} onClick={onNextStep} disabled={!acknowledged}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CancelPaidSubscriptions;
