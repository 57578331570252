import { useInfiniteQuery } from 'react-query';

import { AdNetworkAdvertiser } from '@/interfaces/ad_network/internal/advertiser';
import { Pagination } from '@/interfaces/general';
import api from '@/services/swarm';

interface ApiResponse {
  advertisers: AdNetworkAdvertiser[];
  pagination: Pagination;
}

interface Props {
  query?: string;
  order?: string;
}

export default function useAdvertisers({ query = '', order = 'updated_at' }: Props) {
  const fetchAdvertisers = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/ad_network/internal/advertisers`, {
        params: {
          page: pageParam,
          per_page: 25,
          query,
          order
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery(['ad_network', 'internal', 'advertisers', query, order], fetchAdvertisers, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    keepPreviousData: true,
  });
}
