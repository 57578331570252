import { Extension } from '@tiptap/core';
import { Node as PMNode } from '@tiptap/pm/model';

import { getStringAttribute } from '../../../utils/attributesUtils';

interface BoxShadowOptions {
  nodesToAddBoxShadowAttributeTo: PMNode['type']['name'][];
}

interface BoxShadowStorage {}

export const BoxShadowAttribute = Extension.create<BoxShadowOptions, BoxShadowStorage>({
  name: 'boxShadowAttribute',

  addOptions() {
    return {
      nodesToAddBoxShadowAttributeTo: ['accordion', 'columns', 'image', 'section'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.nodesToAddBoxShadowAttributeTo,
        attributes: {
          boxShadow: getStringAttribute('boxShadow', 'data-box-shadow', 'none'),
        },
      },
    ];
  },
});
