import { useCallback, useEffect, useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './DropdownMenu';
import { SimpleInput, SimpleInputWrapper } from './Input';
import { Text } from './Text';

type SimpleLengthSettingsProps = {
  defaultValue?: Length;
  value?: Length;
  onChange?: (length: Length) => void;
  units?: Array<string>;
  allowDecimal?: boolean;
  /**
   * mapping between unit and min/max value
   */
  bounds?: Record<
    string,
    {
      min: number;
      max: number;
    }
  >;
};

const DEFAULT_UNITS = ['px', 'em'];

// https://developer.mozilla.org/en-US/docs/Web/CSS/length
export type Length = {
  value: number;
  unit: string;
};

export const parseLength = (length: string, defaultUnit?: string): Length => {
  // grab the numbers from length string
  try {
    const value = length.match(/[\d.]+/)?.[0] || '0';
    const unit = length.match(/[a-z%]+$/i)?.[0] || defaultUnit || '';
    return {
      value: parseFloat(value),
      unit,
    };
  } catch (error) {
    return {
      value: 0,
      unit: 'px',
    };
  }
};

export const ScalarUnitInput = ({
  defaultValue,
  value,
  onChange,
  units = DEFAULT_UNITS,
  bounds,
  allowDecimal = false,
}: SimpleLengthSettingsProps) => {
  const [defaultLength, setDefaultLength] = useState(defaultValue?.value);
  const [defaultUnit, setDefaultUnit] = useState(defaultValue?.unit || value?.unit || units[0]);

  const handleValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return;
      const { value: inputValue } = e.target;
      let parsedInputValue = 0;

      if (allowDecimal) {
        parsedInputValue = inputValue !== '' ? parseFloat(inputValue) : 0;
      } else {
        parsedInputValue = inputValue !== '' ? parseInt(inputValue, 10) : 0;
      }

      if (allowDecimal) {
        parsedInputValue = parseFloat(parsedInputValue.toFixed(2));
      }

      if (bounds?.[defaultUnit]?.min && parsedInputValue < bounds?.[defaultUnit]?.min) {
        parsedInputValue = bounds?.[defaultUnit]?.min;
      }

      if (bounds?.[defaultUnit]?.max && parsedInputValue > bounds?.[defaultUnit]?.max) {
        parsedInputValue = bounds?.[defaultUnit]?.max;
      }

      setDefaultLength(parsedInputValue);
      onChange?.({ value: parsedInputValue, unit: defaultUnit });
    },
    [defaultUnit, onChange, allowDecimal, bounds]
  );

  useEffect(() => {
    if (value) {
      setDefaultUnit(value.unit);
    }
  }, [value]);

  const handleUnitChange = useCallback(
    (unit: string) => {
      setDefaultUnit(unit);
      onChange?.({ value: value?.value || defaultLength || 0, unit });
    },
    [defaultLength, onChange, value]
  );

  return (
    <>
      <SimpleInputWrapper noShadow className="h-[32px] relative">
        <SimpleInput
          type="number"
          className="text-xs"
          min={bounds?.[defaultUnit]?.min}
          max={bounds?.[defaultUnit]?.max}
          defaultValue={defaultLength}
          value={Number(value?.value || defaultLength || 0).toString()}
          onChange={handleValueChange}
        />
      </SimpleInputWrapper>
      <DropdownMenu>
        <DropdownMenuTrigger asChild className="cursor-pointer">
          <div className="h-[32px] bg-wb-secondary rounded-lg justify-between flex items-center gap-2 p-2">
            <div className="flex items-center gap-1">
              <Text size="2xs" weight="medium">
                {value?.unit || defaultUnit}
              </Text>
            </div>
            <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[4rem] cursor-pointer">
          {units.map((unit) => (
            <DropdownMenuItem
              key={unit}
              onSelect={() => {
                handleUnitChange(unit);
              }}
            >
              {unit}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
