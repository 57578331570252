import { Text } from '../../../UI/Text';

const DynamicAuthorPage = () => {
  return (
    <div className="flex flex-col gap-2">
      <Text weight="medium" variant="secondary" size="xs" as="p">
        This is a dynamic page that applies this styling to all of your individual author pages. So any author you add
        to the main “authors page” will have this styling applied to their individual author pages.
      </Text>

      <Text weight="medium" variant="secondary" size="xs" as="p">
        We provide this placeholder data to demonstrate how this styling will look on one of your author pages.
      </Text>

      <Text weight="medium" variant="secondary" size="xs" as="p">
        You cannot have different styles across different authors pages at this time since it is a universally applied
        style to all of your author pages. However, we are committed to maximizing flexibility in customization here
        over time.
      </Text>
    </div>
  );
};

export default DynamicAuthorPage;
