import SectionRenderer from '../../helpers/SectionRenderer';
import { AttributeSettingProps } from '../../types';

import { ActionsSettings } from './ActionsSettings';

export function MultinodeSettings({ editor, activeNodeResult }: AttributeSettingProps) {
  return (
    <SectionRenderer title="Block" collapsible={false}>
      <ActionsSettings editor={editor} activeNodeResult={activeNodeResult} />
    </SectionRenderer>
  );
}
