import Campaigns from './Campaigns/Campaigns';

const Dashboard = () => {
  return (
    <div className="container mx-auto overflow-auto py-6 h-[calc(100vh_-_54px)]">
      <div className="flex flex-col space-y-4">
        <Campaigns />
      </div>
    </div>
  );
};

export default Dashboard;
