import { useCallback, useEffect, useMemo, useState } from 'react';
import { Editor, findChildren, findParentNode } from '@tiptap/core';

import { useDebounce } from '@/components/TiptapEditor/lib/hooks/useDebounce';
import { useEditorStateNonBlocking } from '@/components/TiptapEditor/lib/hooks/useEditorStateNonBlocking';

const useColumnsOptionsPanel = (editor: Editor) => {
  const onToggleStackOnMobile = useCallback(() => {
    editor.chain().focus().toggleStackOnMobile().run();
  }, [editor]);

  const selection = useEditorStateNonBlocking({
    editor,
    selector: () => editor.state.selection,
    equalityFn: (a, b) => {
      if (!a || !b) return false;

      return a.eq(b);
    },
  });

  const stackOnMobile = useEditorStateNonBlocking({
    editor,
    selector: () => editor.getAttributes('columns')?.stackOnMobile,
  });

  const { columnsChildren, columnCount } = useEditorStateNonBlocking({
    editor,
    selector: () => {
      const columnsParent = findParentNode((node) => node.type.name === 'columns')(editor.state.selection);

      const columnsNode = columnsParent ? columnsParent.node : null;

      const children = columnsNode ? findChildren(columnsNode, (node) => node.type.name === 'column') : [];

      return {
        columnsChildren: children,
        columnCount: children.length,
      };
    },
    equalityFn: (a, b) => {
      if (!a || !b) return false;

      if (a.columnCount !== b.columnCount) return false;

      return a.columnsChildren.every(
        ({ node, pos }, i) => node.eq(b.columnsChildren[i].node) && pos === b.columnsChildren[i].pos
      );
    },
  });

  const isStackedOnMobile = useMemo(() => selection && stackOnMobile, [stackOnMobile, selection]);

  const [columnWidth, setColumnWidth] = useState<any>({
    0: columnsChildren?.[0]?.node.attrs.width || '',
    1: columnsChildren?.[1]?.node.attrs.width || '',
    2: columnsChildren?.[2]?.node.attrs.width || '',
  });

  const onSetColumnCount = useCallback(
    (count: number) => {
      if (count > 2) {
        setColumnWidth((oldState: any) => ({ ...oldState, 1: undefined, 2: undefined }));
      }

      editor.chain().focus().setColumnCount(count).run();
    },
    [editor]
  );

  const debouncedColumnWidth = useDebounce(columnWidth, 250);

  const onChangeColumnsWidth = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const minColumnWidth = 10;

      const newState = {
        ...columnWidth,
        [index]: e.target.value,
      };

      const totalWidth = Object.values(newState)
        .map((i) => parseInt(i as string, 10) || minColumnWidth)
        .filter((_, i) => i < columnCount)
        .reduce((sum, curr) => sum + curr, 0);

      // Ensure that the total entered widths don't surpass 100%
      if (totalWidth <= 100) {
        setColumnWidth(newState);
      }
    },
    [columnCount, columnWidth]
  );

  // On debounced input changes, save to node
  useEffect(() => {
    const chain = editor.chain();

    const firstColumnWidth = Number(debouncedColumnWidth[0]) || undefined;
    const secondColumnWidth = Number(debouncedColumnWidth[1]) || undefined;
    const thirdColumnWidth = Number(debouncedColumnWidth[2]) || undefined;

    if (firstColumnWidth !== columnsChildren?.[0]?.node.attrs.width) {
      chain.setColumnWidth(0, firstColumnWidth);
    }

    if (secondColumnWidth !== columnsChildren?.[1]?.node.attrs.width) {
      chain.setColumnWidth(1, secondColumnWidth);
    }

    if (columnsChildren.length > 2 && thirdColumnWidth !== columnsChildren?.[2]?.node.attrs.width) {
      chain.setColumnWidth(2, thirdColumnWidth);
    }

    chain.run();
  }, [editor, columnsChildren, debouncedColumnWidth]);

  return {
    columnCount,
    onSetColumnCount,
    isStackedOnMobile,
    onToggleStackOnMobile,
    columnWidth,
    onChangeColumnsWidth,
  };
};

export default useColumnsOptionsPanel;
