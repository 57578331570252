import { useCallback, useMemo } from 'react';
import { PostGroupType } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';

import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';

import InputWrapper from '../../../../UI/InputWrapper';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from '../../../../UI/Select';
import { DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG } from '../../../dummyData/tags';
import { PostSelectionType } from '../types';

type Props = Partial<NodeViewProps> & {
  pos: number;
  selectedCategory: string | null;
  setSelectedCategory: (value: any | null) => void;
  setPostType: (value: PostSelectionType) => void;
  postType: PostSelectionType;
  showFakeData: boolean;
  isTagPageOrTemplate: boolean;
  isAuthorPageOrTemplate: boolean;
};

const PostTypeSelect = ({
  selectedCategory,
  setSelectedCategory,
  setPostType,
  postType,
  node,
  editor,
  pos,
  showFakeData,
  isTagPageOrTemplate,
  isAuthorPageOrTemplate,
}: Props) => {
  const publicationId = useCurrentPublicationId();
  const tags = useOptions(publicationId, 'content_tags');
  const currentPostGroupType = node?.attrs.postGroupType;
  const tagOptions = useMemo(() => {
    if (showFakeData) {
      return [DESTINATION_TAG, FOOD_AND_DRINK_TAG, HIKING_TAG, HISTORY_AND_CULTURE_TAG].map((tag) => ({
        label: tag.display,
        value: tag.display,
      }));
    }

    return tags?.data?.options?.map((tag: any) => ({ label: tag?.display, value: tag?.display }));
  }, [tags, showFakeData]);

  const handleUpdatePostGroupType = useCallback(
    (value: PostGroupType) => {
      editor?.commands.command(({ tr }) => {
        tr.setNodeAttribute(pos ?? 0, 'postGroupType', value);
        return true;
      });
    },
    [editor, pos]
  );

  return (
    <InputWrapper name="post_type" labelText="Post Type" className="w-[250px]">
      <Select
        defaultValue={currentPostGroupType}
        value={selectedCategory || postType}
        onValueChange={(value) => {
          // Let the parent know if a catgery has been selected to determine if we should insert the appropriate tags
          const foundCategory = tagOptions?.find((option: any) => option.value === value);

          if (foundCategory) {
            setSelectedCategory(foundCategory.value);
          } else {
            setSelectedCategory(null);
          }

          setPostType(value as PostSelectionType);
          handleUpdatePostGroupType(value as PostGroupType);
        }}
      >
        <SelectTrigger className="w-full" id="by_status">
          <SelectValue placeholder="Select a category" />
        </SelectTrigger>
        <SelectContent className="max-h-[280px] overflow-y-auto no-scrollbar">
          <SelectGroup>
            <SelectLabel>Basic</SelectLabel>
            <SelectItem value="free_selection">Free Selection</SelectItem>
            <SelectItem value="latest">Latest</SelectItem>
            <SelectItem value="archive">Archive</SelectItem>
            {/* <SelectItem value="recommendations">Recommendations</SelectItem> */}
            {isTagPageOrTemplate && <SelectItem value="tag_page">Tag Page</SelectItem>}
            {isAuthorPageOrTemplate && <SelectItem value="author_page">Author Page</SelectItem>}
          </SelectGroup>
          <SelectSeparator />
          <SelectGroup>
            <SelectLabel>Categories</SelectLabel>
            {tagOptions?.map((option: any) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </InputWrapper>
  );
};

export default PostTypeSelect;
