import { useEffect, useRef, useState } from 'react';

import { EmptyBlock } from '@/components/EmptyBlock';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography } from '@/components/Typography';
import { useEmailMessageClicks } from '@/hooks';
import { EmailMessageClick } from '@/interfaces/email_message';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import Tooltip from '@/ui/Tooltip';

interface LinkPopoverProps {
  children: React.ReactNode;
}

const LinkPopover: React.FC<LinkPopoverProps> = ({ children }: LinkPopoverProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const [allowHover, setAllowHover] = useState(false);
  const childRef = useRef<any>(null);

  // Only let hover affect happen if the width of the url is greater than the truncated constraint
  useEffect(() => {
    if (childRef.current?.scrollWidth >= childRef?.current?.offsetWidth) {
      setAllowHover(true);
    }
  }, [childRef, setAllowHover]);

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className="col-span-6 py-4 whitespace-nowrap text-sm font-medium"
    >
      {allowHover && isHovering && (
        <div className="transition-all ease-in-out absolute w-full overflow-x-auto px-6 py-4 -mt-4 max-w-full flex items-center z-10 whitespace-nowrap bg-white shadow">
          {children}
        </div>
      )}
      <div className="px-6 max-w-lg truncate" ref={childRef}>
        {children}
      </div>
    </div>
  );
};

interface ClicksTableProps {
  publicationId: string;
  emailMessageId: string | undefined;
}

const ClicksTable = ({ publicationId, emailMessageId }: ClicksTableProps) => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isIdle } = useEmailMessageClicks(
    publicationId,
    {
      emailMessageId,
    }
  );
  const clicksCount = data?.pages[0]?.pagination?.total;
  const clicksShowing = data?.pages?.flatMap((page) => page.clicks) || [];

  if (isLoading || isIdle) {
    return (
      <div className="flex items-center">
        <LoadingSpinner className="mr-2" />
        <p className="text-gray-400 text-sm">Loading clicks...</p>
      </div>
    );
  }

  const formatter = new Intl.NumberFormat('en');
  const decimals = new Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const noClicks = clicksShowing.length === 0;

  return (
    <div>
      {noClicks ? (
        <EmptyBlock>
          <Typography token="font-normal/text/sm" colorWeight="600">
            There are currently no clicks recorded for this email. Check back later.
          </Typography>
        </EmptyBlock>
      ) : (
        <>
          <div className="flex items-center">
            <div className="flex items-center mr-2 space-x-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Clicks</h3>
              <Badge>
                Showing {clicksShowing.length} of {clicksCount} results
              </Badge>
            </div>
          </div>
          <div className="flex flex-col mt-5 overflow-x-scroll">
            <div className="divide-y divide-gray-200">
              <div className="bg-gray-50 grid grid-cols-12">
                <div className="col-span-6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  URL
                </div>
                <div className="col-span-2 px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                  Total
                </div>
                <div className="col-span-2 px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                  Unique
                </div>
                <div className="col-span-2 px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                  <div className="flex items-center space-x-1 justify-end">
                    <div>CTR</div>
                    <div>
                      <Tooltip id="ctr" text="Unique Email Clicks / Unique Opens" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white divide-y divide-gray-200 relative">
                {clicksShowing.map((click: EmailMessageClick) => (
                  <div key={click.id} className="grid grid-cols-12">
                    <LinkPopover>
                      <a
                        href={click.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary-600 hover:text-primary-500 hover:underline"
                      >
                        {click.url}
                      </a>
                    </LinkPopover>
                    <div className="col-span-2 px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                      {formatter.format(click.total_clicked)}
                    </div>
                    <div className="col-span-2 px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                      {formatter.format(click.total_unique_clicked)}
                    </div>
                    <div className="col-span-2 px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                      {decimals.format(click.click_through_rate)}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {hasNextPage && (
              <div className="flex justify-center align-middle p-6">
                <Button
                  variant="primary-inverse"
                  onClick={() => fetchNextPage()}
                  loading={isFetchingNextPage}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ClicksTable;
