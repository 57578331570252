import { useNavigate } from 'react-router-dom';
import { SparklesIcon } from '@heroicons/react/20/solid';
import { SparklesIcon as SparklesIconOutline, WalletIcon } from '@heroicons/react/24/outline';

import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

const UpgradeToScale = () => {
  const navigate = useNavigate();

  return (
    <Card className="flex flex-col w-full justify-center items-center h-[calc(100vh-54px-3rem)]">
      <div className="flex flex-col items-center gap-10 w-full max-w-96">
        <div className="flex items-center justify-center w-full">
          <div className="w-[calc(2.75rem + 1px)] w-[calc(2.75rem + 1px)] bg-action-tertiary-100 p-3 rounded-full border border-white">
            <SparklesIconOutline className="w-5 h-5 text-action-tertiary-600" />
          </div>
          <div className="w-[calc(2.75rem + 1px)] w-[calc(2.75rem + 1px)] bg-action-secondary-50 p-3 rounded-full border border-white -ml-2">
            <WalletIcon className="w-5 h-5 text-action-secondary-600" />
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 w-full">
          <Typography token="font-semibold/text/2xl">Wallet is available on the Scale Plan</Typography>
          <Typography token="font-normal/text/sm" colorWeight="500" className="text-center">
            Wallet allows you to manage your funds and earnings when using Boosts or Ad Network. Upgrade to access
            Boosts and Ad Network to grow and monetize your newsletter.
          </Typography>
        </div>
        <Button Icon={SparklesIcon} onClick={() => navigate('/settings/workspace/billing_and_plan')} type="button">
          Upgrade to Scale
        </Button>
      </div>
    </Card>
  );
};

export default UpgradeToScale;
