import { useNavigate } from 'react-router-dom';
import {
  ArrowTrendingUpIcon,
  DocumentArrowDownIcon,
  EnvelopeIcon,
  HandThumbUpIcon,
  LinkIcon,
  PaintBrushIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid';

import { useSettings } from '@/context/settings-context';

import {
  AccordionCard,
  AccordionIconWrapper,
  AccordionListItem,
} from '../../../components/Accordion/variants/AccordionCard';

const GrowListActions = () => {
  const navigate = useNavigate();
  const { settings } = useSettings();
  const isPagesEnabled = settings?.custom_pages;

  return (
    <AccordionCard
      title={
        <div className="flex items-center">
          <AccordionIconWrapper>
            <ArrowTrendingUpIcon className="w-4 h-4 text-feedback-info-500" />
          </AccordionIconWrapper>
          Grow your list
        </div>
      }
      subText="Supercharge your newsletter's growth with all of our powerful features."
    >
      <div className="space-y-1">
        <AccordionListItem
          title="Import subscribers"
          icon={<DocumentArrowDownIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/settings/publication/content_import')}
        />
        {isPagesEnabled && (
          <AccordionListItem
            title="Design landing page"
            icon={<PaintBrushIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
            onClick={() => navigate('/design_lab/landing_page')}
          />
        )}
        <AccordionListItem
          title="Build external signup forms"
          icon={<EnvelopeIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/subscribe_forms')}
        />
        <AccordionListItem
          title="Launch referral program"
          icon={<UserPlusIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/referral_program/overview')}
        />
        <AccordionListItem
          title="Create magic links"
          icon={<LinkIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/magic_links')}
        />
        <AccordionListItem
          title="Make recommendations"
          icon={<HandThumbUpIcon className="w-4 h-4 text-gray-600 group-hover:text-feedback-info-600" />}
          onClick={() => navigate('/recommendations')}
        />
      </div>
    </AccordionCard>
  );
};

export default GrowListActions;
