import { NAVBAR_DEFAULTS, NAVBAR_MENU_LIST_DEFAULTS } from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { getParent } from '../../../utils';
import BorderSettings from '../general/BorderSettings';
import { PaddingSettings } from '../general/PaddingSettings';

import { StickyOptions } from './StickyOptions';
import { StyleOptions } from './StyleOptions';

export function NavMenuStyleSettings() {
  const { content, selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const rootContent =
    selectedContent?.type === 'navbar_menu_list' && content ? getParent(content, selectedContent) : selectedContent;

  return (
    <SectionRenderer title="Styling">
      <StyleOptions />
      {rootContent && rootContent?.type === 'navbar_menu' && rootContent.attrs?.style === 'border' && (
        <BorderSettings
          selectedContent={rootContent}
          defaults={{
            borderWidth: NAVBAR_DEFAULTS.borderBorderWidth,
            borderStyle: NAVBAR_DEFAULTS.borderBorderStyle,
            borderColor: NAVBAR_DEFAULTS.borderBorderColor,
          }}
        />
      )}
      {/* Unable to add border radius to navbar menu because this requires overflow: hidden but this will hide the mobile burger dropdown */}
      {/* {rootContent && (
        <BorderRadiusSettings
          selectedContent={rootContent}
          defaults={{
            borderRadius: NAVBAR_DEFAULTS.borderRadius,
          }}
        />
      )} */}
      <StickyOptions />
      {selectedContent.type === 'navbar_menu_list' && (
        <PaddingSettings
          selectedContent={selectedContent}
          attribute="padding"
          defaultValue={NAVBAR_MENU_LIST_DEFAULTS.padding}
        />
      )}
    </SectionRenderer>
  );
}
