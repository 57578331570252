import { ReactNode, useState } from 'react';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { AreaChart } from '@/components/Chartsv2';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

interface Props {
  subHeader?: string;
  title: string;
  listItems?: { title: string; value: string }[];
  description?: string;
  chartTitle?: string;
  chartData?: any;
  chartCategories?: string[];
  chartIndex?: string;
  chartColors?:
    | (
        | 'gray'
        | 'slate'
        | 'zinc'
        | 'neutral'
        | 'stone'
        | 'red'
        | 'orange'
        | 'amber'
        | 'yellow'
        | 'lime'
        | 'green'
        | 'emerald'
        | 'teal'
        | 'cyan'
        | 'sky'
        | 'blue'
        | 'indigo'
        | 'violet'
        | 'purple'
        | 'fuchsia'
        | 'pink'
        | 'rose'
      )[]
    | undefined;
  filters?: ReactNode;
  chartValueFormatter?: (value: number) => string;
}

const Stat = ({
  subHeader,
  title,
  listItems,
  description,
  chartData,
  chartCategories,
  chartColors,
  chartIndex,
  chartTitle,
  chartValueFormatter,
  filters,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onModalOpen = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Card className="w-full h-full relative flex flex-col rounded-lg p-4">
        <div className={cx('w-full flex gap-6', (!!description || !!listItems) && 'mb-10')}>
          <div>
            {subHeader && (
              <h3>
                <Typography token="font-medium/text/sm" colorWeight="700" className="w-full mb-2">
                  {subHeader}
                </Typography>
              </h3>
            )}
            <h2 className="mt-2">
              <Typography token="font-semibold/text/3xl" colorWeight="900" className="w-full">
                {title}
              </Typography>
            </h2>
          </div>
          {chartData && (
            <div className="w-full h-16 relative max-w-28 ml-auto group hover:bg-surface-100 transition-all">
              <AreaChart
                index={chartIndex || ''}
                className="w-full h-full"
                data={chartData}
                categories={chartCategories || []}
                colors={chartColors || ['pink']}
                showLegend={false}
                showXAxis={false}
                showYAxis={false}
                showGridLines={false}
                showTooltip={false}
              />
              <Button
                size="xxs"
                type="button"
                Icon={ArrowsPointingOutIcon}
                onClick={onModalOpen}
                className="!px-1 absolute top-1/2 left-1/2 -ml-3 -mt-3 opacity-0 group-hover:opacity-100 transition-opacity"
              />
            </div>
          )}
        </div>
        <div className="w-full mt-auto">
          {description && (
            <Typography token="font-normal/text/xs" colorWeight="500" className="block w-full">
              {description}
            </Typography>
          )}
          {listItems && (
            <ul className="w-full flex flex-col gap-1 mt-auto">
              {listItems.map((item, index) => (
                <li
                  key={`list-item-${item.title}-${item.value}`}
                  className={cx('flex w-full pb-1', index < listItems.length - 1 && 'border-b border-b-surface-200')}
                >
                  <Typography token="font-medium/text/xs" colorWeight="500" className="block w-full">
                    {item.title}
                  </Typography>
                  <Typography token="font-medium/text/xs" colorWeight="900" className="block w-full text-right">
                    {item.value}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Card>
      <Modal isOpen={modalOpen} onClose={onModalClose} className="relative w-full max-w-2xl">
        <Card className="w-full h-full px-6 pt-6 max-h-[560px] pb-16">
          <Typography token="font-medium/text/lg" colorWeight="900" className="block w-full mb-4">
            {chartTitle}
          </Typography>
          {filters && <div className="w-full my-6 flex justify-end space-x-2">{filters}</div>}
          <div className="w-full">
            <AreaChart
              valueFormatter={chartValueFormatter}
              index={chartIndex || ''}
              data={chartData}
              categories={chartCategories || []}
              colors={chartColors || ['pink']}
              showLegend={false}
              showGridLines={false}
              yAxisWidth={80}
            />
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default Stat;
