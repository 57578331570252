import { createRef, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { ColorEditModal } from '@/components/AssetEditor/modals/ColorEditModal';
import { CropModal } from '@/components/AssetEditor/modals/CropModal';
import { MetaDataModal } from '@/components/AssetEditor/modals/MetadataModal';
import { Asset } from '@/interfaces/asset';

import { LoadingDots } from '../../TiptapEditor/components/ui/Loader/LoadingDots';
import { useUploader } from '../../TiptapEditor/extensions/ImageUpload/view/hooks';
import AssetGrid from '../components/AssetGrid/AssetGrid';
import SearchBar from '../components/SearchBar';
import UploadButton from '../components/UploadButton';
import { UploadDropArea } from '../components/UploadDropArea';
import { useMediaLibrary } from '../context/MediaLibraryContext';
import { useAssetEditor, useAssetEditorModal } from '../hooks/assetEditor';
import useAssets from '../hooks/assets/useAssets';

export type CurrentPostViewProps = {
  onMediaSelect: (image: Asset) => void;
};

export default function CurrentPostView({ onMediaSelect }: CurrentPostViewProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const searchBarRef = createRef<HTMLDivElement>();
  const uploadDropAreaRef = createRef<HTMLDivElement>();
  const { publicationId } = useMediaLibrary();

  const { assets, error, loading, prependAsset, fetchNextPage, hasNextPage, removeAsset, updateAsset } = useAssets({
    query: searchQuery,
    publicationId,
  });

  const metaDataModal = useAssetEditorModal();
  const cropModal = useAssetEditorModal();
  const colorModal = useAssetEditorModal();

  const assetEditor = useAssetEditor({
    onAssetUpdate: updateAsset,
  });

  const onMetaEditClick = useCallback(
    (asset: Asset) => {
      assetEditor.handleEdit(asset);
      metaDataModal.open();
    },
    [assetEditor, metaDataModal]
  );

  const onCropEditClick = useCallback(
    (asset: Asset) => {
      assetEditor.handleEdit(asset);
      cropModal.open();
    },
    [assetEditor, cropModal]
  );

  const onColorEditClick = useCallback(
    (asset: Asset) => {
      assetEditor.handleEdit(asset);
      colorModal.open();
    },
    [assetEditor, colorModal]
  );

  const handleDeleteClick = useCallback(
    async (asset: Asset) => {
      try {
        const deletedId = await assetEditor.handleDelete(asset, publicationId);
        removeAsset(deletedId);
      } catch (e: any) {
        throw new Error(e);
      }
    },
    [assetEditor, publicationId, removeAsset]
  );

  const fileUploader = useUploader({ publicationId, onUpload: prependAsset });

  const handleUpload = useCallback(
    async (files: FileList) => {
      const requests: Array<Promise<void>> = [];

      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        requests.push(fileUploader.uploadFile(file));
      }

      await Promise.all(requests);
    },
    [fileUploader]
  );

  useLayoutEffect(() => {
    if (!wrapperRef.current || loading) return undefined;

    const wrapper = wrapperRef.current;
    const searchBar = searchBarRef.current;
    const uploadDropArea = uploadDropAreaRef.current;

    const bodyHeight = wrapper.offsetHeight - (searchBar?.offsetHeight || 0);
    const uploadDropAreaHeight = uploadDropArea?.offsetHeight || 0;

    if (uploadDropAreaHeight <= bodyHeight) {
      fetchNextPage();
    }

    const handleScroll = () => {
      const scrollDistance = wrapper.scrollHeight - (wrapper.scrollTop + wrapper.clientHeight);

      if ((scrollDistance <= 0 || scrollDistance / wrapper.scrollHeight <= 0.02) && hasNextPage) {
        fetchNextPage();
      }
    };

    wrapper.addEventListener('scroll', handleScroll);

    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, [fetchNextPage, hasNextPage, loading, searchBarRef, uploadDropAreaRef]);

  return (
    <div ref={wrapperRef} className="h-full w-full overflow-x-hidden overflow-y-auto">
      <div className="sticky top-0 z-30">
        <SearchBar
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder="Search asset…"
          suffix={
            <div className="flex items-center gap-x-2">
              <UploadButton onUpload={handleUpload} />
            </div>
          }
        />
      </div>
      {assets && assets.length > 0 && (
        <UploadDropArea
          ref={uploadDropAreaRef}
          onUpload={handleUpload}
          accepted={['image/jpeg', 'image/jpg', 'image/png', 'image/gif']}
        >
          <div className="max-w-full p-6">
            {assetEditor.id && assetEditor.asset ? (
              <>
                <MetaDataModal
                  assetId={assetEditor.id}
                  publicationId={publicationId}
                  open={metaDataModal.isOpen}
                  onClose={metaDataModal.close}
                  onChange={updateAsset}
                />
                <CropModal
                  asset={assetEditor.asset}
                  assetId={assetEditor.id}
                  publicationId={publicationId}
                  open={cropModal.isOpen}
                  onClose={cropModal.close}
                  onChange={updateAsset}
                  onCreate={prependAsset}
                />
                <ColorEditModal
                  asset={assetEditor.asset}
                  assetId={assetEditor.id}
                  publicationId={publicationId}
                  open={colorModal.isOpen}
                  onClose={colorModal.close}
                  onChange={updateAsset}
                  onCreate={prependAsset}
                />
              </>
            ) : null}
            <AssetGrid
              publicationId={publicationId}
              images={assets}
              onSelect={onMediaSelect}
              onEditMeta={onMetaEditClick}
              onEditColor={onColorEditClick}
              onEditCrop={onCropEditClick}
              onDelete={handleDeleteClick}
            />
          </div>
        </UploadDropArea>
      )}
      {!loading && !error && (!assets || assets.length === 0) && (
        <div className="max-w-full px-6 text-center text-gray-500">No assets found.</div>
      )}
      {error && (
        <div className="max-w-full px-6">
          <div className="flex items-center justify-center py-8 text-gray-500">{error}</div>
        </div>
      )}
      {loading && (
        <div className="max-w-full p-6">
          <div className="flex items-center justify-center py-8">
            <LoadingDots />
          </div>
        </div>
      )}
    </div>
  );
}
