import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { JSONContent } from '@tiptap/core';

import { useCurrentPage, useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';
import { usePage } from '@/hooks/usePages';
import { useSiteWideThemeUpdate } from '@/hooks/useSite';
import { WebTheme } from '@/interfaces/web_theme';

import { ColorSelection } from '../../settings/themes/_components/ColorSelection';
import { FontSelection } from '../../settings/themes/_components/FontSelection';
import { RadiusSelection } from '../../settings/themes/_components/RadiusSelection';
import { previewThemeApplier } from '../DreamEditor/utils/previewThemeApplier';
import TemplatePreviewer from '../Templates/TemplatePreviewer';
import { Button } from '../UI/Button';
import { ComboboxOption } from '../UI/ComboboxInput';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../UI/Dialog';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../UI/Select';
import { Text } from '../UI/Text';

const useThemePreviewer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  return { isOpen, onClose, onOpen };
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  theme: any;
  themeId: string;
}

const ThemePreviewer = ({ isOpen, onClose, theme, themeId }: Props) => {
  const { site, pagesRoutes, defaultRoutes } = useWebsiteContext();
  const pageRouteGetter = useWebsitePageRouteGetter();

  const { page } = useCurrentPage();
  const [style, setStyle] = useState(theme);
  const [originalTheme] = useState(theme);
  const [fetchablePage, setFetchablePage] = useState<string | null>(null);
  const [hasApplied, setHasApplied] = useState(false);

  const { data: fetchedPage } = usePage({ pageId: fetchablePage as string });

  const pageToPreview = fetchedPage || page;

  useEffect(() => {
    if (!theme) return;
    setStyle(theme);
  }, [theme]);

  const { defaultOptions }: { defaultOptions: ComboboxOption[] } = useMemo(() => {
    const defaultPages: ComboboxOption[] =
      Object.values(defaultRoutes?.children || {}).map((route) => {
        const foundPage = pageRouteGetter?.getPageFromID(route.page_id);
        return {
          id: route.page_id,
          name: foundPage?.draft_page_version?.name || 'home',
        };
      }) || [];

    return {
      defaultOptions: defaultPages,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesRoutes, defaultRoutes, pageRouteGetter]);

  const { previewNavbar, previewContent, previewFooter } = useMemo(() => {
    if (!site || !pageToPreview || !page || !theme) return {};

    return {
      previewNavbar: previewThemeApplier({
        type: 'navbar',
        content: site?.draft_site_version?.navbar as JSONContent,
        themeRules: site?.theme_rules || {},
        theme: style as unknown as WebTheme,
      }),
      previewContent: previewThemeApplier({
        type: 'tiptap',
        content: pageToPreview?.draft_page_version?.content as JSONContent,
        themeRules: site?.theme_rules || {},
        theme: style as unknown as WebTheme,
      }),
      previewFooter: previewThemeApplier({
        type: 'tiptap',
        content: site?.draft_site_version?.footer as JSONContent,
        themeRules: site?.theme_rules || {},
        theme: style as unknown as WebTheme,
      }),
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, pageToPreview, page, style]);

  const handleClose = () => {
    onClose();
    setStyle(theme);
    if (hasApplied) {
      // TODO: Remove this full page reload once we figure out why the editor is not updating, could be a memo issue.
      window.location.reload();
    }
  };

  const { mutate, isLoading } = useSiteWideThemeUpdate({
    id: site?.id || '',
    onSuccess: () => {
      toast.success('Theme applied successfully');
      setHasApplied(true);
    },
  });

  const handleApply = () => {
    mutate({ theme_id: themeId, theme_params: style });
  };

  // to force re-render inputs
  const [resetCounter, setResetCounter] = useState(0);
  const handleReset = useCallback(() => {
    setResetCounter((prev) => prev + 1);
    setStyle(originalTheme);
  }, [originalTheme]);

  const handleSetFontFamily = useCallback(
    (key: string, value: string) => {
      setStyle({ ...style, [key]: value });
    },
    [style]
  );

  const handleSetColor = useCallback(
    (key: string, value: string) => {
      setStyle({ ...style, [key]: value });
    },
    [style]
  );

  const handleSetRadius = useCallback(
    (value: number) => {
      setStyle({ ...style, border_radius: `${value}px` });
    },
    [style]
  );

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="w-[100vw] max-w-none h-[100vh] flex flex-col overflow-hidden p-0 gap-0">
        <DialogHeader className="p-4">
          <DialogTitle>
            <div className="flex items-end justify-between">
              <div className="flex flex-col gap-2">
                <Text size="xl" weight="semibold" variant="primary" as="h4">
                  Edit Theme
                </Text>
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="flex gap-16 flex-1 min-h-0 p-4">
          <div className="w-full pb-12 overflow-y-auto h-full">
            <div className="flex flex-col gap-4" key={`settings-${resetCounter}`}>
              <FontSelection
                title="Heading Font"
                onSetFontFamily={(value) => handleSetFontFamily('header_font', value)}
                value={style?.header_font}
              />
              <FontSelection
                title="Body Font"
                onSetFontFamily={(value) => handleSetFontFamily('body_font', value)}
                value={style?.body_font}
              />
              <FontSelection
                title="Button Font"
                onSetFontFamily={(value) => handleSetFontFamily('button_font', value)}
                value={style?.button_font}
              />
              <ColorSelection
                title="Primary Color"
                initialColor={style?.primary_color || ''}
                onSetColor={(value) => handleSetColor('primary_color', value || '')}
                side="bottom"
                offset={10}
              />
              <ColorSelection
                title="Text on Primary Color"
                initialColor={style?.text_on_primary_color || ''}
                onSetColor={(value) => handleSetColor('text_on_primary_color', value || '')}
                side="bottom"
                offset={10}
              />
              <ColorSelection
                title="Background Color"
                initialColor={style?.background_color || ''}
                onSetColor={(value) => handleSetColor('background_color', value || '')}
                side="bottom"
                offset={10}
              />
              <ColorSelection
                title="Text on Background Color"
                initialColor={style?.text_on_background_color || ''}
                onSetColor={(value) => handleSetColor('text_on_background_color', value || '')}
                side="bottom"
                offset={10}
              />
              <ColorSelection
                title="Border Color"
                initialColor={style?.border_color || ''}
                onSetColor={(value) => handleSetColor('border_color', value || '')}
                side="bottom"
                offset={10}
              />
              <RadiusSelection
                title="Border Radius"
                min={0}
                max={100}
                unit="px"
                step={1}
                onSetRadius={handleSetRadius}
                defaultValue={style?.border_radius}
              />
            </div>
          </div>
          <div className="w-full max-w-[500px] max-h-[500px] flex flex-col gap-2">
            <div className="flex flex-col gap-2 justify-center items-center">
              {page?.id && (
                <Select
                  defaultValue={String(page?.id)}
                  onValueChange={(value: string) => {
                    setFetchablePage(value);
                  }}
                >
                  <SelectTrigger className="w-full" id="page_selection">
                    <SelectValue placeholder="Select a page" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {defaultOptions?.map((option) => {
                        const val = option.name || 'Home';

                        return (
                          <SelectItem key={option.id} value={String(option.id)}>
                            {val}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )}
            </div>
            {previewNavbar && previewContent && previewFooter && (
              <TemplatePreviewer
                navbarContent={previewNavbar as any}
                content={previewContent as any}
                footerContent={previewFooter as any}
                hasBrowserBar={false}
                containerHeightClass="h-full"
                enableScroll
                disableMemo
              />
            )}
          </div>
          <div className="w-full" />
        </div>

        <DialogFooter className="flex justify-between items-center p-4 bg-white border-t border-wb-primary">
          <div className="flex gap-2 justify-between w-full">
            <Button variant="outlined" onClick={handleReset} isDisabled={false}>
              Reset
            </Button>
            <div className="flex gap-2">
              <Button variant="primary" onClick={handleApply} isDisabled={isLoading}>
                {isLoading ? 'Applying...' : 'Apply'}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ThemePreviewer, useThemePreviewer };
