import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';

import { Site } from '../../interfaces/dream_builder/site';
import api from '../../services/swarm';

interface Props {
  onError?: () => void;
  refetchOnWindowFocus?: boolean;
}

export default function useSite({ onError, refetchOnWindowFocus = true }: Props = {}) {
  const [currentPublicationId] = useCurrentPublicationState();
  const id = 'null'; // there's only 1 site per publication, this parameter is unused

  const fetchSite = () =>
    api
      .get(`/sites/${id}`, {
        params: {
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useQuery<Site>({
    queryKey: ['publications', currentPublicationId, 'sites', id],
    queryFn: fetchSite,
    retry: false,
    refetchOnWindowFocus,
    onError: () => {
      onError?.();
    },
  });
}
