import { useWebsiteContext } from '@/context/website-context';
import { useCurrentPublication, useUpdatePublication } from '@/hooks/usePublications';
import BetaBadge from '@/ui/BetaBadge';

import { Button } from '../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../UI/Dialog';
import { Text } from '../UI/Text';

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onPublish: () => void;
}

const DialogPublishV2 = ({ isOpen, setIsOpen, onPublish }: Props) => {


  const { data: currentPublication } = useCurrentPublication();
  const { site } = useWebsiteContext();
  const publicationMutation = useUpdatePublication(currentPublication?.id || '');

  const handlePreviewOnStaging = () => {
    const url = `${currentPublication?.url}preview/${site?.draft_site_version.id}?page=/`;
    window.open(url, '_blank');
  };

  const handlePublishV2 = () => {
    publicationMutation.mutate({ uses_dream_builder_site: true });
    onPublish();
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="w-full max-w-md flex flex-col overflow-hidden p-4 gap-2">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              <Text size="lg" weight="semibold" variant="primary" as="h4">
                Website V2
              </Text>
              <BetaBadge />
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-2 py-2">
          <Text size="sm" weight="regular" as="p" variant="secondary">
            This software is still actively being developed. You may experience bugs or issues with your new site.
            Prior to publishing your website V2 to your site fully, we encourage you to test out your site with our staging
            experience.
          </Text>

          <Text size="sm" weight="regular" as="p" variant="secondary">
            If you toggle your new site on, you can always switch back to the old site (V1).
          </Text>
        </div>
        <DialogFooter className="pt-4">
          <Button variant="secondary" onClick={handlePreviewOnStaging}>
            <Text size="xs" weight="medium" as="span">
              Preview Draft Site
            </Text>
          </Button>
          <Button variant="primary" onClick={handlePublishV2}>
            Publish Website V2 to Live Site
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DialogPublishV2;
