import { useNavigate } from 'react-router-dom';
import { ArrowPathIcon, UsersIcon } from '@heroicons/react/20/solid';

import { useCurrentUser } from '@/context/current-user-context';
import useTierLabel from '@/hooks/useTierLabel';
import { SubscriptionStatuses } from '@/interfaces/subscription';
import { Button } from '@/ui/Button';

interface Props {
  search: string;
  status: string;
  tierIdValue: string;
  handleOnClick: () => void;
}

const NoResults = ({ search, status, tierIdValue, handleOnClick }: Props) => {
  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;

  const tier = useTierLabel(tierIdValue);
  const noSearchResults = search;
  const noStatusResults = status !== 'all';
  const noTiersResults = tierIdValue !== 'all';
  const noResults = noSearchResults || noStatusResults || noTiersResults;

  let text;

  if (noSearchResults) {
    text = `No results found for search '${search}'.`;
  } else if (noStatusResults || noTiersResults) {
    text = 'No results found with ';
    if (noStatusResults) {
      text += `status ${SubscriptionStatuses[status]}`;
    }
    if (noTiersResults) {
      if (noStatusResults) {
        text += ' and ';
      }
      text += `tier ${tier || ''}`;
    }
    text += '.';
  } else {
    text = `You currently have no subscribers, start importing some emails below.`;
  }

  return (
    <div className="w-full h-full p-8">
      <div className="h-full bg-gray-50 rounded flex items-center justify-center px-4 py-16">
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500">{text}</p>
          <div>
            {noResults ? (
              <Button variant="primary-inverse" onClick={handleOnClick}>
                <div className="flex">
                  <ArrowPathIcon className="h-5 w-5 mr-2" />
                  Reset Table
                </div>
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() =>
                  navigate(
                    hasSettingsV2
                      ? '/settings/publication/subscribers_import'
                      : '/settings/publication/import_subscribers'
                  )
                }
              >
                <div className="flex">
                  <UsersIcon className="h-5 w-5 mr-2" />
                  Import Subscribers
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoResults;
