import { Extension } from '@tiptap/core';

import { AppliedThemeId } from './AppliedThemeId';
import { BackgroundColorAttribute } from './BackgroundColorAttribute';
import { BorderRadiusAttribute } from './BorderRadiusAttribute';
import { BoxShadowAttribute } from './BoxShadowAttribute';
import { Card } from './Card';
import { LayoutAttribute } from './LayoutAttribute';
import { MaxWidthAttribute } from './MaxWidthAttribute';
import { MergeTag } from './MergeTag';
import { NodeTextAlign } from './NodeTextAlign';
import { StackOnMobileAttribute } from './StackOnMobileAttribute';
import { WidthAttribute } from './WidthAttribute';

export const GlobalAttributes = Extension.create({
  name: 'globalAttributes',

  addExtensions() {
    return [
      Card,
      MergeTag,
      NodeTextAlign,
      WidthAttribute,
      MaxWidthAttribute,
      LayoutAttribute,
      StackOnMobileAttribute,
      BorderRadiusAttribute,
      BoxShadowAttribute,
      BackgroundColorAttribute,
      AppliedThemeId,
    ];
  },
});
