import { memo, useCallback, useRef, useState } from 'react';
import { Editor } from '@tiptap/react';
import { Instance, sticky } from 'tippy.js';

import { useEditorStateNonBlocking } from '@/components/TiptapEditor/lib/hooks/useEditorStateNonBlocking';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { EditLink } from '../../../components/menus/Link';
import { MenuProps } from '../../../components/menus/types';
import { forceUpdateTippy } from '../../../components/menus/utils/forceUpdateTippy';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';
import { Variant } from '../GenericEmbed';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const GenericEmbedMenu = memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const tippyInstance = useRef<Instance | null>(null);

  const [showLinkEdit, setShowLinkEdit] = useState(false);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-genericEmbed');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    return editor.isActive('genericEmbed');
  }, [editor]);

  const { genericEmbedUrl, genericEmbedTarget, genericEmbedVariant, isButtonActive } = useEditorStateNonBlocking<{
    genericEmbedUrl: string;
    genericEmbedTarget: string;
    genericEmbedVariant: string;
    isButtonActive: boolean;
  }>({
    editor,
    selector: () => {
      const attrs = editor.getAttributes('genericEmbed') || {};

      return {
        genericEmbedUrl: attrs.url || '',
        genericEmbedTarget: attrs.target,
        genericEmbedVariant: attrs.variant,

        isButtonActive: editor.isActive('button'),
      };
    },
  });

  const setGenericEmbedVariant = useCallback(
    (variant: Variant) => {
      editor.chain().setGenericEmbedVariant(variant).focus().run();
    },
    [editor]
  );

  const removeGenericEmbed = useCallback(() => {
    editor.chain().deleteNode('genericEmbed').focus().run();
  }, [editor]);

  const handleEditLinkClick = useCallback(() => {
    setShowLinkEdit(true);

    if (tippyInstance.current) {
      forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
    }
  }, [getReferenceClientRect]);

  return (
    <BaseBubbleMenu
      editor={editor as Editor}
      pluginKey="genericEmbedMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        onHidden: () => {
          setShowLinkEdit(false);
        },
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()}>
        {(isButtonActive && !genericEmbedUrl) || showLinkEdit ? (
          <EditLink
            link={genericEmbedUrl}
            target={genericEmbedTarget}
            onSetLink={(newUrl: string) => {
              editor.chain().focus().updateAttributes('genericEmbed', { url: newUrl }).run();
              setShowLinkEdit(false);
            }}
            onUnsetLink={() => {
              editor.chain().focus().updateAttributes('genericEmbed', { url: undefined }).run();
              setShowLinkEdit(false);
              return null;
            }}
            onSetTarget={(target: string) => {
              editor.chain().focus().updateAttributes('genericEmbed', { target }).run();
            }}
            onBack={() => {
              setShowLinkEdit(false);
              if (tippyInstance.current) {
                forceUpdateTippy(tippyInstance.current, getReferenceClientRect);
              }
            }}
            autoFocus={showLinkEdit || false}
            editor={editor}
          />
        ) : (
          <>
            <Tooltip title="Text left / image right">
              <Button
                $active={genericEmbedVariant === 'text-left-image-right'}
                $leftSlot={<Icon name="TextLeftImageRight" />}
                onClick={() => {
                  setGenericEmbedVariant('text-left-image-right');
                }}
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Image left / text right">
              <Button
                $active={genericEmbedVariant === 'image-left-text-right'}
                $leftSlot={<Icon name="ImageLeftTextRight" />}
                onClick={() => {
                  setGenericEmbedVariant('image-left-text-right');
                }}
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Image top / text bottom">
              <Button
                $active={genericEmbedVariant === 'image-top-text-bottom'}
                $leftSlot={<Icon name="ImageTopTextBottom" />}
                onClick={() => {
                  setGenericEmbedVariant('image-top-text-bottom');
                }}
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Text only">
              <Button
                $active={genericEmbedVariant === 'text-only'}
                $leftSlot={<Icon name="TextOnly" />}
                onClick={() => {
                  setGenericEmbedVariant('text-only');
                }}
                {...buttonProps}
              />
            </Tooltip>
            <Divider />
            <Tooltip title="Edit link">
              <Button
                $active={!!genericEmbedUrl}
                $leftSlot={<Icon name="Link" />}
                onClick={handleEditLinkClick}
                {...buttonProps}
              />
            </Tooltip>
            <Tooltip title="Open link">
              <Button
                $leftSlot={<Icon name="External" />}
                as="a"
                href={genericEmbedUrl}
                target="_blank"
                {...buttonProps}
              />
            </Tooltip>
            <Divider />
            <Tooltip title="Delete embed">
              <Button $leftSlot={<Icon name="Trash" />} onClick={removeGenericEmbed} {...buttonProps} />
            </Tooltip>
          </>
        )}
      </Toolbar>
    </BaseBubbleMenu>
  );
});

export default GenericEmbedMenu;
