import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';

import { ColumnCountSettings } from './ColumnCountSettings';

export function NavDropdownLayoutSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown') return null;

  return (
    <SectionRenderer title="Layout">
      <ColumnCountSettings />
    </SectionRenderer>
  );
}
