import { useEffect } from 'react';
import { Editor } from '@tiptap/core';

interface Props<T> {
  isPageOrTemplate: boolean;
  typeCheck: boolean;
  typeToSet: T;
  pos: number;
  setPostType: (type: T) => void;
  editor: Editor;
}

const useSetPostGroupType = <T>({ isPageOrTemplate, typeCheck, typeToSet, pos, editor, setPostType }: Props<T>) => {
  useEffect(() => {
    if (isPageOrTemplate && typeCheck) {
      setPostType(typeToSet);
      editor?.commands.command(({ tr }) => {
        tr.setNodeAttribute(pos, 'postGroupType', typeToSet);
        return true;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageOrTemplate]);
};

export default useSetPostGroupType;
