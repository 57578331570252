import { Trash } from '@phosphor-icons/react';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import { Button } from '@/routes/website/_components/UI/Button';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';

import { useNavbarContext } from '../../../NavbarContext';
import { ACTION_TITLES } from '../NavItemLinkOrActionSettings/ActionSettings';

import { LabelSettings } from './LabelSettings';
import { TypeSettings } from './TypeSettings';

export function NavItemContentSettings() {
  const { selectedContent, onDeleteNode } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;

  const isDisabled = selectedContent.attrs?.autoGenerated;
  const deleteButton = (
    <Button
      size="sm"
      variant="secondary"
      iconClassName="text-wb-secondary"
      Icon={Trash}
      isDisabled={isDisabled}
      onClick={() => onDeleteNode(selectedContent.attrs?.id || '')}
    />
  );

  const name = selectedContent.attrs?.action && ACTION_TITLES[selectedContent.attrs?.action]?.title;

  return (
    <SectionRenderer
      title="Navigation Item"
      actions={
        isDisabled ? (
          <Tooltip center={`This ${name} button is essential for your website and cannot be removed`}>
            {deleteButton}
          </Tooltip>
        ) : (
          deleteButton
        )
      }
    >
      <LabelSettings />
      <TypeSettings />
    </SectionRenderer>
  );
}
