import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';

import Checklist from './Checklist';

const Onboarding = () => {
  const publicationId = useCurrentPublicationId();
  const { data: publication } = usePublication(publicationId);
  const { currentUser } = useCurrentUser();

  return (
    <div className="flex items-center justify-center w-[calc(100%+3rem)] h-[calc(100vh-54px)] bg-white -mt-6 -ml-6 -mb-12">
      <div className="w-full max-w-[624px] px-10 py-6 text-center">
        <h1 className="flex justify-center items-center w-full">
          <img
            src={publication?.logo?.thumb?.url || './images/publication-logo-placeholder.png'}
            alt="Publication Logo"
            className="w-7 h-7 block rounded-lg"
          />
          <Typography className="ml-2" token="font-medium/text/sm" colorWeight="900">
            {publication?.name}
          </Typography>
        </h1>
        <Typography className="w-full block mt-3" token="font-semibold/text/2xl" colorWeight="900">
          Welcome to beehiiv, {currentUser?.first_name}👋
        </Typography>
        <Checklist />
        <div className="mt-10">
          <Typography token="font-normal/text/sm" colorWeight="500" className="w-full block">
            Learn more about beehiiv through our{' '}
            <a className="font-medium text-sm text-pink-600 underline" href="https://www.beehiiv.com/support">
              Knowledge Base
            </a>
            .
          </Typography>
          <Button type="button" size="xs" variant="primary-inverse" className="mt-4">
            Skip Onboarding
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
