import { Editor, isTextSelection } from '@tiptap/core';
import { Slice } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';

export const CustomPastePluginKey = new PluginKey('customPastePluginKey');

function sliceSingleNode(slice: Slice) {
  return slice.openStart === 0 && slice.openEnd === 0 && slice.content.childCount === 1
    ? slice.content.firstChild
    : null;
}

export const getCustomPastePlugin = (editor: Editor): Plugin => {
  return new Plugin({
    key: CustomPastePluginKey,
    props: {
      handlePaste(view, _event, slice) {
        const {
          state: { selection },
        } = view;
        const { $from, $to, from, to } = selection;

        if (!isTextSelection(selection) || !$from.sameParent($to)) {
          return false;
        }

        const singleNode = sliceSingleNode(slice);

        const { input } = view as any;

        const preferPlain = input.shiftKey && input.lastKeyCode !== 45;

        return editor
          .chain()
          .command(({ tr }) => {
            if (singleNode) {
              tr.replaceSelectionWith(singleNode, preferPlain);
            } else {
              try {
                tr.replace(from, to, slice);
              } catch {
                tr.replaceSelection(slice);
              }
            }

            return true;
          })
          .setMeta('paste', true)
          .setMeta('uiEvent', 'paste')
          .scrollIntoView()
          .run();
      },
    },
  });
};
