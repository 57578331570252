import { CSSProperties, useCallback } from 'react';
import { TextAa } from '@phosphor-icons/react';
import { NAVBAR_MENU_DROPDOWN_ITEM_DEFAULTS, TNavbarDropdownElementItem } from '@shared/dream-components';

import { PopoverHelper } from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/PopoverHelper';
import FontWeightSelection from '@/routes/website/_components/UI/FontWeightSelection';
import { Length, parseLength, ScalarUnitInput } from '@/routes/website/_components/UI/ScalarUnitInput';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';
import { ColorSettings } from '../general/ColorSettings';
import { FontSettings } from '../NavItemStylingSettings/FontSettings';

export function ItemDescriptionTypographySettings() {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  const defaults = NAVBAR_MENU_DROPDOWN_ITEM_DEFAULTS;

  const handleUpdateDescriptionFontSize = useCallback(
    (l: Length) => {
      const id = selectedContent?.attrs?.id;
      if (!id) return;

      onUpdateNodeAttributes(id, {
        descriptionFontSize: `${l.value}${l.unit}`,
      });
    },
    [selectedContent, onUpdateNodeAttributes]
  );
  const defaultFontSize = (selectedContent as TNavbarDropdownElementItem)?.attrs?.descriptionFontSize ?? '14px';
  const handleUpdateTitleFontWeight = useCallback(
    (w: CSSProperties['fontWeight']) => {
      const id = selectedContent?.attrs?.id;
      if (!id) return;

      onUpdateNodeAttributes(id, {
        titleFontWeight: w,
      });
    },
    [selectedContent, onUpdateNodeAttributes]
  );
  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown_item') return null;

  const defaultFontWeight = (selectedContent as TNavbarDropdownElementItem)?.attrs?.titleFontWeight ?? '500';

  return (
    <PopoverHelper title="Description" text="Description" popoverTitle="Description" Icon={TextAa}>
      <ColorSettings
        title="Text"
        property="descriptionColor"
        selectedContent={selectedContent}
        defaultValue={defaults.descriptionTextColor}
      />
      <FontSettings property="descriptionFontFamily" />
      <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          Font Size
        </Text>
        <ScalarUnitInput
          defaultValue={parseLength(defaultFontSize as string)}
          onChange={handleUpdateDescriptionFontSize}
        />
      </div>
      <FontWeightSelection defaultValue={defaultFontWeight} onChange={handleUpdateTitleFontWeight} />
    </PopoverHelper>
  );
}
