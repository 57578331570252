import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { DEFAULT_BORDER_COLOR } from '../constants';
import { Section } from '../Section';

import { ContainerView } from './view/ContainerView';

export const Container = Node.create({
  name: 'container',

  group: 'block',

  content: 'block*',

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      flexDirection: {
        default: 'column',
        parseHTML: (element) => element.getAttribute('data-flex-direction'),
        renderHTML: (attributes) => ({
          'data-flex-direction': attributes.flexDirection,
        }),
      },
      backgroundColor: {
        default: '#FFFFFFFF',
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({
          'data-background-color': attributes.backgroundColor,
        }),
      },
      shadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      blur: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-blur'),
        renderHTML: (attributes) => ({
          'data-blur': attributes.blur,
        }),
      },
      backgroundImage: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-background-image'),
        renderHTML: (attributes) => ({
          'data-background-image': attributes.backgroundImage,
        }),
      },
      backgroundDarken: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-background-darken'),
        renderHTML: (attributes) => ({
          'data-background-darken': attributes.backgroundDarken,
        }),
      },
      horizontalAlign: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-horizontal-align'),
        renderHTML: (attributes) => ({
          'data-horizontal-align': attributes.horizontalAlign,
        }),
      },
      verticalAlign: {
        default: 'align_center',
        parseHTML: (element) => element.getAttribute('data-vertical-align'),
        renderHTML: (attributes) => ({
          'data-vertical-align': attributes.verticalAlign,
        }),
      },
      height: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({
          'data-height': attributes.height,
        }),
      },
      width_max: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
        }),
      },
      padding: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
        }),
      },
      margin: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => ({
          'data-margin': attributes.margin,
        }),
      },
      gap: {
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({
          'data-gap': attributes.gap,
        }),
      },
      borderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '1px',
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_BORDER_COLOR,
      },
      borderStyle: {
        default: 'none',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },
      flexBasis: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-flex-basis'),
        renderHTML: (attributes) => ({ 'data-flex-basis': attributes.flexBasis }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addOptions() {
    return {
      HTMLAttributes: {
        'data-type': this.name,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ContainerView, {
      attrs: (props) => {
        return {
          ...props.HTMLAttributes,
          style: `display: flex; min-width: 0; width:${props.node.attrs.width}; ${
            props.node.attrs.height === 'stretch' ? 'flex: 1' : `height:${props.node.attrs.height || 'auto'}`
          }`,
        };
      },
      stopEvent: () => false,
    });
  },
});

export default Section;
