import React, { ReactElement } from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

import { Typography } from '@/components/Typography';

interface Props {
  step: {
    id: string;
    title: string;
    icon: ReactElement;
    completed?: boolean;
    url: string;
  };
}

const ChecklistItem = ({ step }: Props) => {
  if (step.completed) {
    return (
      <li key={step.id} className="w-full opacity-50">
        <div className="text-left flex items-center justify-between w-full px-3 py-3 gap-3 border border-gray-200 rounded-md shadow-sm bg-gray-100">
          {step.icon}
          <Typography className="w-full" token="font-medium/text/sm" colorWeight="900">
            {step.title}
          </Typography>
          <div className="flex items-center justify-center min-w-11 w-11 h-11 bg-emerald-50 rounded-md">
            <CheckCircleIcon className="w-6 h-6 text-emerald-600" />
          </div>
        </div>
      </li>
    );
  }

  return (
    <li key={step.id} className="w-full group">
      <a
        className="text-left flex items-center justify-between w-full px-3 py-3 gap-3 border border-gray-200 group-hover:border-pink-600 group-hover:shadow-md rounded-md shadow-sm bg-white transition-all"
        href={step.url}
      >
        {step.icon}
        <Typography className="w-full" token="font-medium/text/sm" colorWeight="900">
          {step.title}
        </Typography>
        <div className="flex items-center justify-center min-w-11 w-11 h-11 bg-surface-50 group-hover:bg-pink-50 rounded-md">
          <ArrowRightIcon className="w-6 h-6 text-gray-500 group-hover:text-pink-600" />
        </div>
      </a>
    </li>
  );
};

export default ChecklistItem;
