import { useState } from 'react';

import { useCurrentUser } from '@/context/current-user-context';

const useRawEditorJson = () => {
  const { currentUser } = useCurrentUser();
  const [isMasquerading] = useState(!!localStorage.getItem('masqueradeToken'));
  const isSystemAdmin = Boolean(currentUser?.isSystemAdmin()) && !isMasquerading;

  const [isRawJsonModalOpen, setIsRawJsonModalOpen] = useState(false);

  return {
    hasAccess: isSystemAdmin,
    isRawJsonModalOpen,
    setIsRawJsonModalOpen,
  };
};

export default useRawEditorJson;
