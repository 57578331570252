export const SLUG_ORDER = [
  '', // home
  'subscribe',
  'upgrade',
  'archive',
  'forms/$id',
  'recommendations',
  'authors/$author', // author
  'authors', // authors
  't/$tag', // tag
  'tags', // tags
  'login',
  'reset_password',
  'update_password'
];
