import { useCallback } from 'react';
import { CaretRight } from '@phosphor-icons/react';

import { formatCamelCase } from '@/routes/website/navbar/_components/NavbarEditor/utils';
import { cn } from '@/utils/cn';

import { Popover, PopoverContent, PopoverTrigger } from '../../../../UI/Popover';
import { Text } from '../../../../UI/Text';
import { OptionsWithPreview } from '../../helpers/OptionsWithPreview';
import { AttributeSettingProps } from '../../types';

type EnterAnimationSettingsProps = AttributeSettingProps & {
  title?: string;
  property?: string;
};

export const EnterAnimationSettings = ({
  editor,
  activeNodeResult,
  title = 'Animate In',
  property = 'inAnimation',
}: EnterAnimationSettingsProps) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const currentValue = activeNodeAttributes?.[property] || 'none';

  const handleSetInAnimation = useCallback(
    (value: string) => {
      editor.commands.command(({ tr }) => {
        if (value === 'none') {
          tr.setNodeAttribute(activeNodePos, property, undefined);
        } else {
          tr.setNodeAttribute(activeNodePos, property, value);
        }
        return true;
      });
    },
    [editor, activeNodePos, property]
  );

  const options = ['fade', 'slide', 'grow', 'shrink', 'none'];

  const animations = {
    fade: 'animate-[fadeIn_2s_cubic-bezier(.14,_0.75,_0.2,_1.01)_infinite]',
    slide: 'animate-[slideIn_2s_cubic-bezier(.14,_0.75,_0.2,_1.01)_infinite]',
    grow: 'animate-[growIn_2s_cubic-bezier(.14,_0.75,_0.2,_1.01)_infinite]',
    shrink: 'animate-[shrinkIn_2s_cubic-bezier(.14,_0.75,_0.2,_1.01)_infinite]',
    none: '',
  };

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2">
        <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
          <Text variant="secondary" size="2xs" weight="medium">
            {title}
          </Text>
        </div>

        <PopoverTrigger asChild>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer w-[120px]">
            <div className="w-full justify-between flex items-center gap-2 p-2 ">
              <div className="flex items-center gap-1">
                <Text size="2xs" weight="medium">
                  {formatCamelCase(currentValue)}
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[255px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-6">
          <Text size="sm" weight="semibold">
            Enter Animations
          </Text>
          <div className="flex flex-col gap-2">
            {options.map((value) => (
              <OptionsWithPreview
                key={value}
                label={formatCamelCase(value)}
                selected={value === currentValue}
                onClick={() => handleSetInAnimation(value)}
              >
                {/* preview */}
                <div
                  className={cn(
                    `bg-wb-primary rounded-md w-9 h-9 shadow-wb-md`,
                    animations[value as keyof typeof animations]
                  )}
                />
              </OptionsWithPreview>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
