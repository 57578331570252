import { Minus, Plus, Question } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';
import { cn } from '@/utils/cn';

import { SimpleInputWrapper } from '../../../UI/Input';
import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';

import { Disabled } from './ui/Disabled';

type UnitlessNumberSettingProps = AttributeSettingProps & {
  title: string;
  disabledTooltip?: string;
  isDisabled?: boolean;
  onIncrese: () => void;
  onDecrease: () => void;
  value: number | string;
};

export function UnitlessNumberSetting({
  title,
  disabledTooltip,
  isDisabled,
  onIncrese,
  onDecrease,
  value,
}: UnitlessNumberSettingProps) {
  const input = (
    <SimpleInputWrapper className="h-[32px] cursor-default py-1">
      <div
        className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {})}
        onClick={onDecrease}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onDecrease();
          }
        }}
        role="none"
      >
        <Minus weight="bold" size={12} />
      </div>
      <Text size="2xs" weight="medium">
        {value}
      </Text>
      <div
        className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {})}
        onClick={onIncrese}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onIncrese();
          }
        }}
        role="none"
      >
        <Plus weight="bold" size={12} />
      </div>
    </SimpleInputWrapper>
  );

  return (
    <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
      <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
        <Text variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        {isDisabled && (
          <Tooltip placement="bottom-end" center={disabledTooltip}>
            <Question size={16} weight="bold" className="text-wb-secondary" />
          </Tooltip>
        )}
      </div>

      {isDisabled ? <Disabled>{input}</Disabled> : input}
    </div>
  );
}
