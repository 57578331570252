import { X } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';

import { ColorPicker } from '../../../UI/ColorPicker';
import { SimpleInputWrapper } from '../../../UI/Input';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { useHighlighting } from '../../hooks';

const parseContentValue = (
  contentValue?: string
): {
  color: string;
  alpha: number;
} => {
  if (!contentValue) return { color: 'Inherit', alpha: 100 };

  // if the total character is 9, then it has alpha value
  if (contentValue.length === 9) {
    return {
      color: contentValue.slice(0, -2),
      alpha: Math.round((parseInt(contentValue.slice(-2), 16) / 255.0) * 100),
    };
  }

  return {
    color: contentValue,
    alpha: 100,
  };
};

export const HighlightSettings = ({ editor }: { editor: Editor }) => {
  const { color } = editor.getAttributes('highlight');

  const { onHighlightSelect, onHighlightClear } = useHighlighting(editor);
  const { color: parsedColor, alpha: parsedAlpha } = parseContentValue(color);

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          Highlight
        </Text>
        <PopoverTrigger asChild>
          <SimpleInputWrapper className="justify-start relative">
            <div className="w-4 h-4 rounded-md" style={{ backgroundColor: color }} />
            <Text size="2xs" weight="medium" className="grow">
              {parsedColor?.toUpperCase()}
            </Text>
            <Text size="2xs" weight="medium" className="">
              {parsedAlpha}%
            </Text>
          </SimpleInputWrapper>
        </PopoverTrigger>
      </div>

      <PopoverContent
        className="select-none bg-wb-primary w-[275px] max-h-[500px] overflow-y-auto p-3 rounded-lg shadow-xl flex flex-col gap-4 "
        align="end"
        side="left"
        sideOffset={110}
      >
        <div className="flex items-center justify-between">
          <Text size="sm" weight="semibold" className="grow">
            Highlight Color
          </Text>
          <PopoverClose className="p-1 text-wb-secondary rounded-full bg-wb-secondary cursor-pointer">
            <X />
          </PopoverClose>
        </div>
        <ColorPicker selectedColor={color} onSetColor={(c) => (c ? onHighlightSelect(c) : onHighlightClear())} />
      </PopoverContent>
    </Popover>
  );
};
