import { Tag } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { FontWeightSettings } from '../FontWeightSettings';
import { SimpleBorderRadiusSettings } from '../SimpleBorderRadiusSettings';
import { TransformSettings } from '../TransformSettings';

type Props = AttributeSettingProps & {};

export const TagsPostSetting = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <PopoverHelper title="Tags" text="Tags" popoverTitle="Tags" Icon={Tag}>
      <>
        <BooleanSettings editor={editor} activeNodeResult={activeNodeResult} property="tagsEnabled" title="Enabled" />
        <ColorSettings title="Color" property="tagTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="tagTransform" />
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          property="tagFontFamily"
        />
        <FontWeightSettings
          editor={editor}
          title="Font Weight"
          activeNodeResult={activeNodeResult}
          property="tagFontWeight"
          isMarkStyle={false}
        />
        <BooleanSettings editor={editor} activeNodeResult={activeNodeResult} property="tagBadgeEnabled" title="Badge" />
        {activeNodeAttributes?.tagBadgeEnabled && (
          <>
            <ColorSettings
              title="Background"
              property="tagBackgroundColor"
              editor={editor}
              activeNodeResult={activeNodeResult}
            />
            <SimpleBorderRadiusSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="tagBorderRadius"
            />
          </>
        )}
      </>
    </PopoverHelper>
  );
};

export default TagsPostSetting;
