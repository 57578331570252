import AttributeInput from "@/routes/website/_components/DreamEditor/AttributesPanel/helpers/AttributeInput";

import { useNavbarContext } from "../../../NavbarContext";

export function LabelSettings() {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== "navbar_item") return null;
  if (selectedContent.attrs.type === 'logo'
    || selectedContent.attrs.type === 'search'
    || (selectedContent.attrs.type === 'user_button' && selectedContent.attrs.action === 'profile')) return null;


  const contentValue = selectedContent?.attrs && 'label' in selectedContent.attrs && selectedContent.attrs.label || '';


  const handleLabelChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { label: value });
  }

  return <AttributeInput
    title="Text"
    value={contentValue}
    onChange={e => handleLabelChange(e.target.value)}
  />
}
