import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { NavbarSerializableNode, SerializableNode } from '@shared/dream-components';

import { TabPills } from '@/components/TabPills';
import { useWebsiteContext } from '@/context/website-context';
import { usePage } from '@/hooks/usePages';
import { useUpdatePageVersion } from '@/hooks/usePageVersion';
import usePageVersions from '@/hooks/usePageVersion/usePageVersions';
import { PageVersion } from '@/interfaces/dream_builder/page_version';
import { capitalize } from '@/utils';
import { cn } from '@/utils/cn';

import { dateToAgo } from '../../_utils/dateToAgo';
import { dateToDateString } from '../../_utils/dateToDateString';
import { VIEWPORTS } from '../../page/constants';
import { Viewport } from '../../page/types';
import TemplatePreviewer from '../Templates/TemplatePreviewer';
import { Button } from '../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../UI/Dialog';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const PageVersionHistory = ({ isOpen, onClose }: Props) => {
  const { site, pageVersionHistoryId } = useWebsiteContext();
  const { data } = usePageVersions({ pageId: pageVersionHistoryId || '' });
  const { data: page } = usePage({ pageId: pageVersionHistoryId || '' });

  const pageVersions = data?.pages.flatMap((p) => p.page_versions);

  const [selectedPageVersion, setSelectedPageVersion] = useState<PageVersion | null>(null);
  const [viewport, setViewport] = useState<Viewport>(VIEWPORTS[0]);

  useEffect(() => {
    if (pageVersions && pageVersions?.length > 0 && !selectedPageVersion) {
      setSelectedPageVersion(pageVersions[0]);
    }
  }, [pageVersions, selectedPageVersion]);

  const { mutate: updatePageVersion, isLoading } = useUpdatePageVersion({
    pageId: pageVersionHistoryId || '',
    id: page?.draft_page_version?.id || '',
    onSuccess: () => {
      toast.success('Page version updated');
      onClose();
      // We do this because we need the content in the editor to refresh
      window.location.reload();
    },
  });

  const handleUpdatePageVersion = () => {
    if (!selectedPageVersion) return;
    updatePageVersion({
      content: selectedPageVersion?.content,
    });
  };

  const handleViewportChange = useCallback(
    (vp: Viewport) => () => {
      setViewport(vp);
    },
    []
  );

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[100vw] max-w-none h-[100vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-end justify-between ">
              <div className="flex flex-col gap-2">
                <Text size="xl" weight="semibold" variant="primary" as="h4">
                  Page Version History
                </Text>
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="flex gap-16 pb-80">
          <div className="w-full max-w-[600px] flex flex-col gap-2">
            <div className="flex flex-col gap-2 justify-center items-center">
              <div className="w-full flex flex-row gap-4">
                <TabPills.Wrapper>
                  {VIEWPORTS.map((tab) => (
                    <TabPills.Item
                      key={tab.type}
                      active={viewport.type === tab.type}
                      onClick={handleViewportChange(tab)}
                    >
                      {capitalize(tab.type)}
                    </TabPills.Item>
                  ))}
                </TabPills.Wrapper>
              </div>
            </div>
            {selectedPageVersion && site && (
              <TemplatePreviewer
                navbarContent={site.draft_site_version.navbar as NavbarSerializableNode}
                content={selectedPageVersion.content as SerializableNode}
                footerContent={site.draft_site_version.footer as SerializableNode}
                hasBrowserBar={false}
                containerHeightClass="h-[70vh]"
                viewPortWidth={viewport.width}
                enableScroll
                disableMemo
              />
            )}
          </div>
          <div className="w-full max-w-[266px] pt-20">
            {pageVersions && (
              <div className="flex flex-col">
                {pageVersions?.map((version) => {
                  const isCurrent = selectedPageVersion?.id === version.id;
                  return (
                    <div
                      className={cn(
                        'flex items-center px-3 gap-4 h-[54px] group hover:bg-wb-secondary rounded-lg cursor-pointer',
                        selectedPageVersion?.id === version.id ? 'bg-wb-secondary' : 'hover:bg-wb-secondary'
                      )}
                      onClick={() => {
                        if (isCurrent) {
                          setSelectedPageVersion(null);
                        } else {
                          setSelectedPageVersion(version);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          e.currentTarget.click();
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <div className="flex flex-col items-center h-full">
                        <div className="flex-1 w-[1px] bg-wb-surface group-first:bg-transparent" />
                        <div className="w-[7px] h-[7px] bg-wb-surface rounded-full" />
                        <div className="flex-1 w-[1px] bg-wb-surface group-last:bg-transparent" />
                      </div>
                      <div className="flex items-center justify-start gap-2 flex-1 min-w-0">
                        <Tooltip
                          center={isCurrent ? 'Current Draft Version' : dateToDateString(version.created_at)}
                          className="h-full flex items-center whitespace-nowrap"
                        >
                          <Text
                            size="xs"
                            weight="semibold"
                            variant={!isCurrent && version.version_type === 'autosave' ? 'secondary' : undefined}
                            className="leading-none"
                          >
                            {isCurrent ? 'Previewing Version' : dateToAgo(version.created_at, true)}
                          </Text>
                        </Tooltip>
                      </div>
                      {version.version}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
          <div className="flex gap-2 justify-between w-full">
            <div />
            <div className="flex gap-2">
              <Button variant="primary" onClick={handleUpdatePageVersion} isDisabled={isLoading}>
                {isLoading ? 'Saving...' : 'Revert to Version'}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PageVersionHistory;
