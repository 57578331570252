import { useQuery } from 'react-query';

import { AdNetworkRole } from '@/interfaces/ad_network/internal/role';
import api from '@/services/swarm';

interface ApiResponse {
  roles: AdNetworkRole[];
}

export default function useRoles() {
  return useQuery<ApiResponse>(
    ['ad_network', 'internal', 'roles'],
    () => api.get(`/ad_network/internal/roles`).then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );
}
