import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import { Button } from '@/ui/Button';
import Tooltip from '@/ui/Tooltip';

interface NavItemProps {
  onClick: () => any;
  title: string;
  disabled?: boolean;
}

const TopNavItem = ({ onClick, title, disabled = false }: NavItemProps) => {
  const tooltipId = `tooltip-${title}`;

  return (
    <Tooltip
      tooltipClass="text-center"
      id={tooltipId}
      text={NO_PERMISSION_MESSAGE}
      showIcon={false}
      autoWidth={false}
      isEnabled={disabled}
    >
      <Button onClick={onClick} size="sm" variant="flush" type="button" className="my-auto" disabled={disabled}>
        {title}
      </Button>
    </Tooltip>
  );
};

export default TopNavItem;
