import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { SideNavPortal } from '@/components/Layout/AppLayout/SideNav';
import { Skeleton, SkeletonLoader } from '@/components/SkeletonLoader';
import { useCurrentUser } from '@/context/current-user-context';
import useCurrentUserOrganizations from '@/hooks/useCurrentUserOrganizations';
import NewPublication from '@/pages/Settings/Pages/Company/Publications/NewPublication';

import MultiplePublicationSettings from './_components/MultiplePublicationSettings';
import PageContainer from './_components/PageContainer';
import { PublicationSettingsProvider } from './publication/context';

export const SHOW_CREATE_PUBLICATION_GET_PARAM = 'create_publication';

// Note: This should only be used for settings v2
// settings v2 => currentUser?.has_settings_v2 === true
const SettingsLayout: React.FC = () => {
  const queryClient = useQueryClient();
  const { currentUser } = useCurrentUser();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [disableButtons, setDisableButtons] = useState(false);

  const { data: organizationsData, isLoading: isLoadingWorkspaces } = useCurrentUserOrganizations(
    currentUser?.id || '',
    true
  );
  const organizations = organizationsData?.organizations || [];

  const handleCloseCreatePublicationForm = () => {
    searchParams.delete(SHOW_CREATE_PUBLICATION_GET_PARAM);
    setSearchParams(searchParams);
  };

  const handleCreatePublicationSuccess = async () => {
    try {
      setDisableButtons(true);
      await queryClient.invalidateQueries([currentUser?.id, 'organizations']);
      navigate('/settings/publication/general');
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }

    setDisableButtons(true);
  };

  const handleCreatePublicationError = (error: string) => {
    toast.error(error);
  };

  return (
    <PublicationSettingsProvider>
      <SideNavPortal publicationSwitchPath="/settings/publication/general" nested={false}>
        <MultiplePublicationSettings
          organizations={organizations}
          onLinkClick={() => {}}
          isLoading={isLoadingWorkspaces}
        />
      </SideNavPortal>
      <SkeletonLoader
        isLoading={isLoadingWorkspaces}
        skeletons={
          <div className="flex flex-row w-full justify-center">
            <PageContainer>
              <Skeleton className="h-44" />
              <Skeleton className="h-44" />
            </PageContainer>
          </div>
        }
      >
        <div className="flex flex-row w-full justify-center">
          <NewPublication
            isOpen={!!searchParams.get(SHOW_CREATE_PUBLICATION_GET_PARAM)}
            onClose={handleCloseCreatePublicationForm}
            onCreate={handleCreatePublicationSuccess}
            onError={handleCreatePublicationError}
            disableButtons={disableButtons}
          />
          <Outlet />
        </div>
      </SkeletonLoader>
    </PublicationSettingsProvider>
  );
};

export default SettingsLayout;
