import cx from 'classnames';

import { Typography } from '../Typography';

interface Props {
  value: number;
  total: number;
  showLabel?: boolean;
  helperText?: string;
  className?: string;
  bordered?: boolean;
  fullWidth?: boolean;
}

const ProgressBar = ({
  value,
  total,
  showLabel = true,
  helperText,
  className,
  bordered = true,
  fullWidth = false,
}: Props) => {
  const usableValue = Math.min(value, total);
  const percentageCompletion = (usableValue / total) * 100;

  return (
    <div className={cx('flex flex-col gap-y-1', fullWidth && 'w-full')}>
      <div
        className={cx(
          'flex bg-white rounded-lg h-6 justify-between items-center p-1 gap-x-2',
          bordered && 'border border-gray-100',
          fullWidth && 'w-full'
        )}
      >
        <div className={cx('bg-surface-50 rounded-lg h-2/3', fullWidth ? 'w-full' : 'w-3/4')}>
          <div
            className={cx('rounded-lg bg-surface-200 h-full', className)}
            style={{
              width: `${percentageCompletion}%`,
            }}
          >
            &nbsp;
          </div>
        </div>
        {showLabel && (
          <Typography size="xs" colorWeight="700" weight="semibold">
            {usableValue}/{total}
          </Typography>
        )}
      </div>
      {helperText && (
        <Typography size="xs" colorWeight="500" weight="semibold">
          {helperText}
        </Typography>
      )}
    </div>
  );
};

export default ProgressBar;
