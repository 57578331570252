export enum IntentAction {
  ADD_TEAM_MEMBER = 'addTeamMember',
  CREATE_SEGMENT = 'createSegment',
  USE_CUSTOM_HTML = 'useCustomHTML',
  USE_POLLS = 'usePolls',
  USE_AD_NETWORK = 'useAdNetwork',
  USE_AUDIO_NEWSLETTERS = 'useAudioNewsletters',
  USE_BOOSTS_GROW = 'useBoostsGrow',
  USE_BOOSTS_MONETIZE = 'useBoostsMonetize',
  USE_POSTS_REPORT = 'usePostsReport',
  USE_PREMIUM_SUBSCRIPTIONS = 'usePremiumSubscriptions',
  USE_REFERRAL_PROGRAM = 'useReferralProgram',
  EMAIL_A_SEGMENT = 'useSegmentEmailBlasts',
  USE_SPLIT_TEST = 'useSplitTest',
  USE_SPLIT_TEST_CUSTOMIZATION = 'useSplitTestCustomization',
  USE_SUBSCRIBERS_REPORT = 'useSubscribersReport',
  USE_AUTOMATIONS = 'useAutomations',
  USE_FORMS = 'useForms',
  USE_PAGES = 'usePages',
  USE_LANDING_PAGES = 'useLandingPages',
  USE_AI_WRITING_TOOLS = 'useAiWritingTools',
  USE_WEBHOOKS = 'useWebhooks',
  USE_RSS_FEEDS = 'useRssFeeds',
  USE_WEB_BUILDER_V2_BETA = 'useWebBuilderV2Beta',
}

export default { IntentAction };
