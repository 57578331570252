import React, { PropsWithChildren } from 'react';
import cx from 'classnames';

import * as CardUI from '@/components/Card/CardUI';
import { Skeleton } from '@/components/SkeletonLoader';
import { TitleSm, Typography, TypographyStack } from '@/components/Typography';
import Tooltip from '@/ui/Tooltip';

type Props = PropsWithChildren<{
  noResultsText: string;
  noResults: boolean;
  title?: string;
  tooltipText?: string;
  description?: string;
  isLoading?: boolean;
  actionChildren?: React.ReactNode;
  className?: string;
  hasPadding?: boolean;
}>;

const ChartCard = ({
  title,
  isLoading = false,
  children,
  tooltipText,
  noResultsText,
  noResults,
  actionChildren,
  className,
  description,
  hasPadding,
}: Props) => {
  return (
    <CardUI.Card hasPadding={hasPadding} className={cx('flex flex-col justify-between relative w-full', className)}>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex space-x-4 items-center">
            <TypographyStack>
              <div className="flex space-x-2 items-center">
                {title && <TitleSm className="text-sm">{title}</TitleSm>}
                {tooltipText && title && <Tooltip id={title} text={tooltipText} portalMountedId={title} />}
              </div>
              {description && (
                <Typography size="sm" weight="normal" colorWeight="400">
                  {description}
                </Typography>
              )}
            </TypographyStack>
          </div>
          {actionChildren && <div>{actionChildren}</div>}
        </div>
      </div>

      <div className="pt-2">{isLoading ? <Skeleton className="h-60 animate-pulse w-full rounded-md" /> : children}</div>

      {/** Card Overlay When No Results */}
      {noResults && (
        <>
          <div className="backdrop-blur bg-white/30 w-full h-[calc(100%-4rem)] rounded-lg absolute z-1 top-16 left-0" />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-2">
            <div className="px-4 text-center">
              <TitleSm className="text-sm">{noResultsText}</TitleSm>
            </div>
          </div>
        </>
      )}
    </CardUI.Card>
  );
};

export default ChartCard;
