import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { NavbarSerializableNode } from '@shared/dream-components';
import { Editor } from '@tiptap/core';
import { cx } from 'class-variance-authority';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useWebsitePageRouteGetter } from '@/context/website-context';
import { useSite } from '@/hooks/useSite';
import { useUpdateSitePackage } from '@/hooks/useSitePackages';
import useSitePackages from '@/hooks/useSitePackages/useSitePackages';
import { useCreateSiteTemplate } from '@/hooks/useSiteTemplates';
import { TemplateLevel } from '@/interfaces/site_template';
import api from '@/services/swarm';

import Callout from '../../UI/Callout';
import { Input } from '../../UI/Input';
import InputWrapper from '../../UI/InputWrapper';
import Modal from '../../UI/Modal';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../UI/Select';
import { Switch } from '../../UI/Switch';
import { Text } from '../../UI/Text';
import DisabledSetting from '../AttributesPanel/helpers/DisabledSetting';
import { ActiveNodeResult } from '../extensions/ActiveNode/types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  editor: Editor;
}

function getLevel(nodeJSON: any): TemplateLevel {
  if (nodeJSON.type === 'section') {
    return 'section';
  }

  if (nodeJSON.type === 'doc') {
    return 'page';
  }

  return 'block';
}

const capitalizeName = (name: string) => name.charAt(0).toUpperCase() + name.slice(1);

const FooterTemplateModal = ({ isOpen, onClose, editor }: Props) => {
  const queryClient = useQueryClient();

  const { pageId } = useParams() as unknown as { pageId: string };
  const pageRouteGetter = useWebsitePageRouteGetter();

  const pageName = useMemo(() => {
    const page = pageRouteGetter?.getPageFromID(pageId);
    return page?.draft_page_version?.name;
  }, [pageId, pageRouteGetter]);

  const { data: packages } = useSitePackages({ enabled: isOpen });
  const projects = packages?.pages.flatMap((pkg) => pkg.site_packages) || [];
  const hasProjects = projects.length > 0;

  const { currentUser } = useCurrentUser();
  const [isMasquerading] = useState(!!localStorage.getItem('masqueradeToken'));
  const isSystemAdmin = Boolean(currentUser?.isSystemAdmin()) && !isMasquerading;

  const [projectId, setProjectId] = useState<string | null>(null);
  const [availableToPublic, setAvailableToPublic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = useCallback(() => {
    setProjectId(null);
    setAvailableToPublic(false);
    onClose();
  }, [onClose]);

  const { mutate: updateSitePackage, isLoading: isUpdatingSitePackage } = useUpdateSitePackage({
    id: projectId as string,
  });

  const createSiteTemplate = useCreateSiteTemplate({
    onSuccess: () => {
      queryClient.invalidateQueries('site_templates');
      handleClose();
    },
  });

  const handleEditSiteTemplate = useCallback(() => {
    setIsLoading(true);
    const content = editor.getJSON();
    updateSitePackage(
      {
        footer: JSON.stringify(content),
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          toast.success('Saved');
          handleClose();
        },
        onError: (errPayload: any) => {
          setIsLoading(false);
          toast.error(errPayload?.response?.data?.error || 'Something went wrong');
        },
      }
    );
  }, [editor, updateSitePackage, handleClose]);

  const isSubmitting = createSiteTemplate.isLoading || isLoading;

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Edit Footer Template"
      onConfirm={handleEditSiteTemplate}
      ctaText="Save"
      ctaVariant="primary"
      isLoading={isSubmitting || isUpdatingSitePackage}
      isLoadingText="Saving..."
    >
      {hasProjects && (
        <InputWrapper name="category" labelText="Project" className="w-full">
          <Select
            defaultValue={pageName}
            onValueChange={(value: any) => {
              setProjectId(value);
            }}
          >
            <SelectTrigger className="w-full" id="by_status">
              <SelectValue placeholder="Select a project" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="none">None</SelectItem>
                {projects?.map((project) => (
                  <SelectItem key={project.id} value={project.id}>
                    {project.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </InputWrapper>
      )}
      {isSystemAdmin && (
        <DisabledSetting
          disabled={!!projectId && projectId !== 'none'}
          tooltip="This template will already be available to the public because it is part of a project."
        >
          <div className="flex flex-col gap-2">
            <Switch
              id="available_to_public"
              labelText="Available to public"
              checked={availableToPublic}
              onCheckedChange={(value: boolean) => {
                setAvailableToPublic(value);
              }}
            />
            <Text size="xs" variant="secondary" as="p">
              This setting is only avaliable for system admins, toggle this on to make your template publicly available
              in the template gallery view.
            </Text>
          </div>
        </DisabledSetting>
      )}
    </Modal>
  );
};

const ComponentTemplateModal = ({
  isOpen,
  onClose,
  isEditing,
  editor,
  activeNodeResult,
}: Props & {
  isEditing?: boolean;
  activeNodeResult: ActiveNodeResult;
}) => {
  const { activeNode, activeNodeType } = activeNodeResult;

  const { data: site } = useSite();
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  const navbarContent = site?.draft_site_version?.navbar as NavbarSerializableNode;

  const { pageId } = useParams() as unknown as { pageId: string };
  const pageRouteGetter = useWebsitePageRouteGetter();
  const isPageLevel = activeNodeType === 'doc';

  const pageName = useMemo(() => {
    const page = pageRouteGetter?.getPageFromID(pageId);
    return page?.draft_page_version?.name;
  }, [pageId, pageRouteGetter]);

  const { data: packages } = useSitePackages({ enabled: isOpen });
  const projects = packages?.pages.flatMap((pkg) => pkg.site_packages) || [];
  const hasProjects = projects.length > 0;

  const { currentUser } = useCurrentUser();
  const [isMasquerading] = useState(!!localStorage.getItem('masqueradeToken'));
  const isSystemAdmin = Boolean(currentUser?.isSystemAdmin()) && !isMasquerading;

  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [projectId, setProjectId] = useState<string | null>(null);
  const [availableToPublic, setAvailableToPublic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setProjectId(null);
    setCategory('');
    setName('');
    setAvailableToPublic(false);
    onClose();
  };

  const categories = useMemo(() => {
    if (!activeNode) {
      return [];
    }

    if (activeNode?.type?.name === 'section') {
      return site?.template_categories?.section;
    }

    if (activeNode?.type?.name === 'doc') {
      return site?.template_categories?.page;
    }

    return site?.template_categories?.block;
  }, [activeNode, site]);

  const { defaultCategory, defaultCategoryLabel } = useMemo(() => {
    if (activeNodeType === 'doc') {
      const cat = categories?.find((c) => c.label === pageName);
      return {
        defaultCategory: cat?.value,
        defaultCategoryLabel: cat?.label,
      };
    }

    if (activeNodeType !== 'section') {
      const cat = categories?.find((c) => c?.blocks?.includes(activeNodeType));
      return { defaultCategory: cat?.value, defaultCategoryLabel: cat?.label };
    }

    return { defaultCategory: 'none', defaultCategoryLabel: 'None' };
  }, [categories, pageName, activeNodeType]);

  const isEditingTemplates = useMemo(() => {
    const isDoc = activeNodeType === 'doc';
    const project = projects.find((p) => p.id === projectId);
    const projectHasCategory = project?.templates.some((t) => t.name === defaultCategoryLabel);

    return isEditing || (isDoc && projectHasCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNodeType, isEditing, projectId, category, defaultCategory]);

  const { mutate: updateSitePackage, isLoading: isUpdatingSitePackage } = useUpdateSitePackage({
    id: projectId as string,
  });

  const createSiteTemplate = useCreateSiteTemplate({
    onSuccess: () => {
      queryClient.invalidateQueries('site_templates');
      handleClose();
      setIsLoading(false);
    },
  });

  const handleEditSiteTemplate = () => {
    let domNode: HTMLElement;
    if (activeNodeResult.activeNodePos === -1) {
      domNode = editor.view.dom as HTMLElement;
    } else {
      // Specific node in the document
      domNode = editor.view.nodeDOM(activeNodeResult.activeNodePos) as HTMLElement;
    }

    if (!domNode) {
      return;
    }

    domNode.classList.remove('dream-hovered-widget');
    domNode.classList.remove('ProseMirror-selectednode');

    setIsLoading(true);

    const content = activeNodeResult.activeNode?.toJSON();
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('site_template[available_to_public]', availableToPublic.toString());
    formData.append('site_template[created_by_super_admin]', isSystemAdmin.toString());
    formData.append('site_template[content]', JSON.stringify(content));
    formData.append('site_template[level]', getLevel(content));

    if (isPageLevel) {
      updateSitePackage({
        navbar: JSON.stringify(navbarContent),
      });
      formData.append('site_template[name]', defaultCategoryLabel || '');
      formData.append('site_template[category]', defaultCategory || '');
    } else if (category) {
      formData.append('site_template[category]', category);
      formData.append('site_template[name]', name);
    }

    if (projectId) {
      // If there is a project, the project being public will give users access to the template, no need to set available_to_public to true
      formData.append('site_template[available_to_public]', 'false');
      formData.append('site_template[site_package_id]', projectId || '');
    }

    const project = projects.find((p) => p.id === projectId);
    const projectTemplateId =
      project?.templates.find((t) => t.category === category)?.id ||
      project?.templates.find((t) => t.name === defaultCategoryLabel)?.id;

    api
      .patch(`/site_templates/${projectTemplateId}`, formData)
      .then(() => {
        setIsLoading(false);
        toast.success('Saved');
        handleClose();
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreateSiteTemplate = async () => {
    const domNode = editor.view.nodeDOM(activeNodeResult.activeNodePos) as HTMLElement;
    if (!domNode) {
      return;
    }

    domNode.classList.remove('dream-hovered-widget');
    domNode.classList.remove('ProseMirror-selectednode');

    setIsLoading(true);

    const content = activeNodeResult.activeNode?.toJSON();
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('site_template[name]', capitalizeName(name));
    formData.append('site_template[available_to_public]', availableToPublic.toString());
    formData.append('site_template[created_by_super_admin]', isSystemAdmin.toString());
    formData.append('site_template[content]', JSON.stringify(content));
    formData.append('site_template[level]', getLevel(content));

    if (isPageLevel) {
      updateSitePackage({
        navbar: JSON.stringify(navbarContent),
      });
      formData.append('site_template[name]', defaultCategoryLabel || '');
      formData.append('site_template[category]', defaultCategory || '');
    } else if (category || defaultCategory) {
      formData.append('site_template[category]', category || defaultCategory || '');
      formData.append('site_template[name]', name);
    }

    if (projectId) {
      // If there is a project, the project being public will give users access to the template, no need to set available_to_public to true
      formData.append('site_template[available_to_public]', 'false');
      formData.append('site_template[site_package_id]', projectId || '');
    }

    createSiteTemplate.mutate(formData);
  };

  const onConfirm = () => (isEditingTemplates ? handleEditSiteTemplate() : handleCreateSiteTemplate());
  const isSubmitting = createSiteTemplate.isLoading || isLoading;
  const isEditingTemplate = isEditingTemplates || isEditing;
  const title = isEditingTemplate ? `Edit Template` : `Create Template`;
  const ctaText = isEditingTemplate ? 'Save' : 'Create';
  const isLoadingText = isEditingTemplate ? 'Saving...' : 'Creating...';

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      onConfirm={onConfirm}
      ctaText={ctaText}
      ctaVariant="primary"
      isLoading={isSubmitting || isUpdatingSitePackage}
      isLoadingText={isLoadingText}
    >
      <InputWrapper name="category" labelText="Category" className="w-full">
        <Select
          defaultValue={defaultCategory}
          onValueChange={(value: any) => {
            if (value === 'none') {
              setCategory('');
            } else {
              setCategory(value);
            }
          }}
        >
          <SelectTrigger
            className={cx('w-full', defaultCategory !== 'none' && 'opacity-50 pointer-events-none')}
            id="by_category"
          >
            <SelectValue placeholder="Select a category" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {categories?.map((cat) => (
                <SelectItem key={cat.value} value={cat.value}>
                  {cat.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </InputWrapper>
      {hasProjects && (
        <InputWrapper name="category" labelText="Project" className="w-full">
          <Select
            defaultValue={pageName}
            onValueChange={(value: any) => {
              setProjectId(value);
            }}
          >
            <SelectTrigger className="w-full" id="by_status">
              <SelectValue placeholder="Select a project" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="none">None</SelectItem>
                {projects?.map((project) => (
                  <SelectItem key={project.id} value={project.id}>
                    {project.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </InputWrapper>
      )}
      <Input
        name="name"
        labelText="Name"
        required
        placeholder="New Template"
        defaultValue={defaultCategory}
        className={cx(isPageLevel && 'opacity-50 pointer-events-none')}
        value={isPageLevel ? defaultCategoryLabel : name}
        onChange={(e) => setName(e.target.value)}
      />
      {isSystemAdmin && (
        <DisabledSetting
          disabled={!!projectId && projectId !== 'none'}
          tooltip="This template will already be available to the public because it is part of a project."
        >
          <div className="flex flex-col gap-2">
            <Switch
              id="available_to_public"
              labelText="Available to public"
              checked={availableToPublic}
              onCheckedChange={(value: boolean) => {
                setAvailableToPublic(value);
              }}
            />
            <Text size="xs" variant="secondary" as="p">
              This setting is only avaliable for system admins, toggle this on to make your template publicly available
              in the template gallery view.
            </Text>
          </div>
        </DisabledSetting>
      )}
      {isEditingTemplates && (
        <Callout title="Template Identified">
          A {`${defaultCategoryLabel || ''}`} template has already been created for this project. Saving will update the
          existing template.
        </Callout>
      )}
    </Modal>
  );
};

const TemplateModal = ({
  isEditing,
  activeNodeResult,
  isFooter,
  ...props
}: Props & {
  isEditing?: boolean;
  isFooter?: boolean;
  activeNodeResult?: ActiveNodeResult;
}) => {
  if (isFooter) {
    return <FooterTemplateModal {...props} />;
  }

  if (!activeNodeResult) {
    return null;
  }

  return <ComponentTemplateModal {...props} activeNodeResult={activeNodeResult} isEditing={isEditing} />;
};

export default TemplateModal;
