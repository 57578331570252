import React, { useCallback } from 'react';
import { TextAa } from '@phosphor-icons/react';

import { PopoverHelper, PopoverItem } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';

const TRANSFORM_OPTIONS = [
  { label: 'UPPERCASE', value: 'uppercase' },
  { label: 'lowercase', value: 'lowercase' },
  { label: 'Capitalize', value: 'capitalize' },
];

type Props = AttributeSettingProps & {
  property: string;
};

export const TransformSettings = ({ editor, activeNodeResult, property }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const textValue = activeNodeAttributes?.[property];

  const handleUpdate = useCallback(
    (value: string) => {
      if (!activeNodePos) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [editor, activeNodePos, property]
  );

  return (
    <PopoverHelper title="Transform" text={textValue} popoverTitle="Transform" Icon={TextAa}>
      <div className="flex flex-col gap-2">
        {TRANSFORM_OPTIONS.map(({ label, value }) => (
          <PopoverItem key={value} value={label} onSelect={() => handleUpdate(value)} isSelected={false} />
        ))}
      </div>
    </PopoverHelper>
  );
};
