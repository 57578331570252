import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Input, TypeaheadMultiSelect } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { useOnboarding } from '@/hooks';
import useOptions from '@/hooks/useOptions';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Option } from '@/interfaces/general';
import { OnboardingTypes } from '@/interfaces/onboarding';
import api from '@/services/swarm';
import { Dropdown } from '@/ui/Dropdown';
import analytics from '@/utils/analytics';

import { Layout, LeftPane, RightPane } from '../_components/Layout';
import OnboardingStepForm from '../_components/OnboardingStepForm';

const normalizedTags = (tags: string[]) => {
  const tagsIds: string[] = [];
  const tagsNames: string[] = [];

  tags.forEach((tag) => {
    const [id, name] = tag.split(':');
    tagsIds.push(id);
    tagsNames.push(name);
  });

  return {
    tagsIds,
    tagsNames,
  };
};

interface IData {
  [key: string]: any;
}

const OnboardingPublicationDetails = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const [image, setImage] = useState<IData>();
  const currentPublicationId = useCurrentPublicationId();
  const { data: currentPublication } = usePublication(currentPublicationId);
  const { data: onboarding } = useOnboarding(currentPublicationId, currentPublication?.onboarding_id);
  const isMigrating = onboarding?.onboarding_type === OnboardingTypes.MIGRATING;

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [subdomainError, setSubdomainError] = useState('');
  const [publicationRate, setPublicationRate] = useState<string | undefined>(onboarding?.publication_rate);
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [chosenTagsError, setChosenTagsError] = useState('');
  const [tagSearchQuery, setTagSearchQuery] = useState('');
  const [isRequesting, setIsRequesting] = useState(false);
  const isThreeSelected = chosenTags.length === 3;
  const isSubmitDisabled = nameError || subdomainError || chosenTagsError;

  useEffect(() => {
    if (currentPublication) {
      setName(currentPublication.name);
      setSubdomain(currentPublication.subdomain || '');
      const imageUrl =
        currentPublication?.logo?.url && currentPublication?.logo?.url.includes('defaults/logo.png')
          ? undefined
          : currentPublication?.logo?.url;
      setImage({ image: imageUrl });
      setChosenTags(
        currentPublication?.tags.map((tag: any) => {
          const capitalizedTag = tag.name
            .split(' ')
            .map((word: string) => {
              return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
          return `${tag.id}:${capitalizedTag}`;
        })
      );
    }
  }, [currentPublication]);

  useEffect(() => {
    if (onboarding) {
      setPublicationRate(onboarding.publication_rate);
    }
  }, [onboarding]);

  const { tagsIds, tagsNames } = normalizedTags(chosenTags);

  const tags = useOptions(currentPublicationId, 'tags');
  const { data } = tags;
  const loadedOptions = data?.options;

  const memoizedOptions = useMemo(() => {
    if (loadedOptions?.length > 0) {
      return loadedOptions.map((option: [number, string]): Option => {
        const [id, value] = option;
        return {
          label: value,
          value: `${id}:${value}`,
        };
      });
    }

    return null;
  }, [loadedOptions]);

  const filtererdOptions = useMemo(() => {
    if (memoizedOptions && tagSearchQuery) {
      return memoizedOptions.filter((option: any) =>
        option.label.toLowerCase().includes(tagSearchQuery.toLowerCase().trim())
      );
    }

    return memoizedOptions;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagSearchQuery, memoizedOptions]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Form Validations
    if (!name) {
      setNameError('This field is required.');
      return;
    }

    if (!subdomain) {
      setSubdomainError('This field is required.');
      return;
    }

    const formData = new FormData();

    if (typeof image?.image !== 'string') {
      formData.append('publication[logo]', image?.image);
    }

    formData.append('publication_id', currentPublication?.id || '');
    formData.append('publication[name]', name);

    tagsIds.forEach((tagId) => {
      formData.append('publication[tag_ids][]', tagId);
    });

    setIsRequesting(true);
    api
      .patch(`/publications/${currentPublication?.id}`, formData)
      .then(() => {
        analytics.identify(currentUser?.id);
        analytics.track('Set Publication Details', {
          skippedPubTags: false,
          tags: tagsIds,
          email: currentUser?.email,
        });
        analytics.group(currentPublication?.id, {
          name,
          avatar: `https://media.beehiiv.com/cdn-cgi/image/fit=scale-down,format=auto,onerror=redirect,quality=80${image?.image}`,
          object_type_id: 1,
        });
      })
      .then(() => {
        api
          .patch(`/onboarding/${currentPublication?.onboarding_id}`, {
            publication_rate: publicationRate,
          })
          .then(() => {
            analytics.identify(currentUser?.id, {
              publishingFrequency: publicationRate,
            });
            analytics.track('Selected Publishing Frequency', {
              publishingFrequency: publicationRate,
            });
            analytics.group(currentPublication?.id, {
              publishingFrequency: publicationRate,
              object_type_id: 1,
            });
          });
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        if (subdomain !== currentPublication?.subdomain) {
          api
            .patch(`/publications/${currentPublication?.id}/subdomain`, {
              resource_locator: {
                subdomain,
              },
            })
            .then((resp) => {
              analytics.track('Set Publication Domain', {
                skippedPubDomain: false,
                publicationUrl: resp.data.url,
                email: currentUser?.email,
              });
              navigate('/onboarding/how_did_you_hear_about_us');
            })
            .catch((errPayload) => {
              const error = errPayload?.response?.data?.error || 'Something went wrong';
              setSubdomainError(error);
            })
            .finally(() => {
              setIsRequesting(false);
            });
        } else {
          setIsRequesting(false);
          navigate('/onboarding/how_did_you_hear_about_us');
        }
      });
  };

  // eslint-disable-next-line
  const handleDeselect = (name: string, value: string) => {
    const newChosenTags = chosenTags.filter((item) => !item.includes(value));
    setChosenTags(newChosenTags);
  };

  return (
    <>
      <Helmet>
        <title>Publication Details - beehiiv</title>
      </Helmet>

      <Layout>
        <LeftPane>
          <OnboardingStepForm
            title="This is our favorite part! Let’s create your publication."
            subtitle="By the way, my name's Bea 👋 I'm part of the beehiiv team, here to help you get up and running on the platform. Think of me as your tour guide, user manual, and biggest fan—all in one!"
            onBack={() => {
              if (isMigrating) {
                return navigate('/onboarding/platform_migration');
              }

              return navigate('/onboarding/personas');
            }}
            showBea
            onSubmit={handleSubmit}
            onSkip={() => navigate('/onboarding/how_did_you_hear_about_us')}
            isProcessing={isRequesting}
            isSubmitDisabled={Boolean(isSubmitDisabled)}
            currentProgress={30}
            disableSkip
            formClassName="p-8 border rounded-lg border-gray-200 bg-surface-50"
          >
            <div className="flex space-y-4 lg:space-y-0 lg:space-x-6 space-x-0 lg:flex-row flex-col">
              <div className="flex flex-col space-y-4 w-full">
                <Input
                  className="w-full"
                  labelText="Newsletter Name"
                  variant="primary"
                  name="name"
                  placeholderText="John's Newsletter"
                  helperText="This name will be displayed on your landing page."
                  value={name}
                  onChange={(e) => {
                    setNameError('');
                    setName(e.target.value);
                  }}
                  autoFocus
                  errorText={nameError}
                />
                <div className="flex space-x-1 items-center">
                  <Input
                    className="w-full"
                    labelText="Subdomain Name"
                    variant="primary"
                    name="subdomain"
                    placeholderText="John's Newsletter"
                    helperText="This is your publication's live URL."
                    value={subdomain}
                    onChange={(e) => {
                      setSubdomainError('');
                      const normalizedSubdomain = e.target.value.split(' ').join('-');
                      setSubdomain(normalizedSubdomain);
                    }}
                    autoFocus
                    errorText={subdomainError}
                  />
                  <Typography size="sm" weight="semibold">
                    .beehiiv.com
                  </Typography>
                </div>
                {memoizedOptions && (
                  <TypeaheadMultiSelect
                    emptyLabel="No tag(s) found"
                    labelText="What's your newsletter about?"
                    name="publication_tags"
                    disabled={isThreeSelected}
                    onClear={() => setTagSearchQuery('')}
                    onDeselect={handleDeselect}
                    badgeColor="tertiary"
                    onSelect={(tagName, tagValue) => {
                      setChosenTagsError('');
                      setChosenTags([...chosenTags, tagValue]);
                    }}
                    onDeselectAll={() => {}}
                    showClearAll={false}
                    onSearchQueryChange={setTagSearchQuery}
                    onSearch={async () => {
                      return new Promise((resolve) => {
                        resolve(filtererdOptions);
                      });
                    }}
                    helperText="Add tags to personalize your publication in the beehiiv network!"
                    placeholderText="Add up to 3 content tags"
                    selectedPreview={tagsNames}
                    values={chosenTags}
                    errorText={chosenTagsError}
                  />
                )}
                <Dropdown
                  name="publication_frequency"
                  labelText="How often do you plan to publish?"
                  helperText="Select one option"
                  options={[
                    { label: 'Daily', value: 'daily' },
                    { label: 'Weekly', value: 'weekly' },
                    { label: 'Bi-weekly', value: 'biweekly' },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'More than once per day', value: 'more_than_once_per_day' },
                    { label: 'Less than once per month', value: 'less_than_once_per_month' },
                  ]}
                  onSelect={(tagName, value) => {
                    setPublicationRate(value);
                  }}
                  value={publicationRate}
                />
              </div>
            </div>
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingPublicationDetails;
