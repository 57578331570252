import { useAdvertiser } from '@/hooks/useAdNetwork/internal';

import useCurrentAdvertiserId from './useCurrentAdvertiserId';

const useCurrentAdvertiser = () => {
  const advertiserId = useCurrentAdvertiserId();

  return useAdvertiser({ id: advertiserId });
};

export default useCurrentAdvertiser;
