import { useCallback, useState } from 'react';

import { Slider } from '../../../_components/UI/Slider';
import { Text } from '../../../_components/UI/Text';

type SliderSettingsProps = {
  title: string;
  min?: number;
  max?: number;
  unit?: string;
  step?: number;
  onSetRadius: (value: number) => void;
  defaultValue?: number;
};

const parseWidth = (width?: string | number, unit?: string) => {
  if (!width) return 0;
  if (typeof width === 'string') {
    // remove % and parse to number
    return parseInt(width.replace(unit ?? '%', ''), 10);
  }
  return width;
};

export const RadiusSelection = ({
  title,
  min,
  max,
  unit,
  step = 1,
  onSetRadius,
  defaultValue,
}: SliderSettingsProps) => {
  const [value, setValue] = useState(defaultValue || 0);

  const handleSliderChange = useCallback(
    (val: number) => {
      setValue(val);
      onSetRadius(val);
    },
    [onSetRadius]
  );

  return (
    <div className="flex items-center justify-between gap-2">
      <Text className="w-full max-w-[150px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow flex items-center h-[32px] gap-2 max-w-[150px]">
        <div className="relative h-full">
          <input
            className="bg-wb-secondary rounded-lg border-none p-2 text-xs w-full"
            type="number"
            min={0}
            value={parseWidth(value, unit)}
            onChange={(e) => handleSliderChange(Number(e.target.value))}
            style={{ borderRadius: `${value}px` }}
          />
          {unit && (
            <Text size="2xs" weight="medium" className="absolute top-1/2 right-3 -translate-y-1/2" variant="secondary">
              {unit}
            </Text>
          )}
        </div>
        <Slider
          defaultValue={[parseWidth(value, unit)]}
          onValueChange={(val: number[]) => {
            handleSliderChange(val[0]);
          }}
          min={min}
          max={max}
          step={step}
        />
      </div>
    </div>
  );
};
