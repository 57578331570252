import { useEffect } from 'react';

export type FontLoaderProps = {
  fontHrefs: Set<string>;
};

export const useFontLoader = ({ fontHrefs }: FontLoaderProps) => {
  useEffect(() => {
    const fontLinks: HTMLLinkElement[] = [];

    // add the fontHrefs to the document header script
    if (!fontHrefs) return () => {};

    fontHrefs.forEach((href) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      fontLinks.push(link);
    });

    fontLinks.forEach((link) => {
      document.head.appendChild(link);
    });

    return () => {
      fontLinks.forEach((link) => {
        document.head.removeChild(link);
      });
    };
  }, [fontHrefs]);
};
