import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Order, Pagination } from '@/interfaces/general';
import {
  OrderBy,
  PublicationSubscriberPreference,
  SubscriberPreferenceVisibility,
} from '@/interfaces/subscribers_preferences';
import api from '@/services/swarm';

interface ApiResponse {
  subscriber_preferences: PublicationSubscriberPreference[];
  pagination: Pagination;
}

export default function useSubscriberPreferences({
  search,
  perPage = 10,
  all = false,
  orderBy,
  direction,
  visibility,
  keepPreviousData = true,
}: {
  search?: string;
  perPage?: number;
  all?: boolean;
  orderBy?: OrderBy;
  direction?: Order;
  visibility?: SubscriberPreferenceVisibility;
  keepPreviousData?: boolean;
}) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchSubscriberPreferences = ({ pageParam = 1 }) =>
    api
      .get(`/publication_subscriber_preferences`, {
        params: {
          page: pageParam,
          per_page: perPage,
          all_results: all,
          publication_id: currentPublicationId,
          q: search,
          order_by: orderBy,
          direction,
          visibility,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    [currentPublicationId, 'subscriber_preferences', search, orderBy, direction, all],
    fetchSubscriberPreferences,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      retry: false,
      keepPreviousData,
      onError: (errPayload: any) => errPayload?.response?.data?.error || 'Something went wrong',
    }
  );
}
