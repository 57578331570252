import { FC } from 'react';
import { RecommendedPostsElement, SerializableNode } from '@shared/dream-components';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

export const RecommendedPostsView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  return (
    <NodeViewWrapper data-drag-handle data-id={node.attrs.id}>
      <RecommendedPostsElement
        isPreviewing
        element={{
          type: 'recommendedPosts',
          attrs: {
            id: node.attrs.id,
            gap: node.attrs.gap,
            width: node.attrs.width,
            height: node.attrs.height,
            backgroundColor: node.attrs.backgroundColor,
            padding: node.attrs.padding,
            borderRadius: node.attrs.borderRadius,
            borderWidth: node.attrs.borderWidth,
            borderColor: node.attrs.borderColor,
            borderStyle: node.attrs.borderStyle,
          },
          content: node.content as unknown as SerializableNode,
        }}
        attributes={{
          'data-node-view-wrapper': '',
          ...getDataAttributes(HTMLAttributes),
        }}
        className="relative"
      >
        <NodeViewContent data-node-view-display-contents />
      </RecommendedPostsElement>
    </NodeViewWrapper>
  );
};
