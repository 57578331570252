import { useCallback } from 'react';

import { useActiveNode } from '../../../extensions/ActiveNode/hooks/useActiveNode';
import SectionRenderer from '../../helpers/SectionRenderer';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';

import { ActionsSettings } from './ActionsSettings';
import AuthorsActions from './AuthorsActions';
import { BlockTypeSettings, formatActiveNodeType } from './BlockTypeSettings';
import ImageActions from './ImageActions';
import PostActions from './PostActions';
import PricingActions from './PricingActions';
import SectionActions from './SectionActions';
import SignupActions from './SignupActions';
import SocialsActions from './SocialsActions';
// import SurveyActions from './SurveyActions';
import TagsActions from './TagsActions';
import { TemplateSettings } from './TemplateSettings';
import TestimonialsActions from './TestimonialsActions';
import TextActions from './TextActions';

export function BlockSettings({
  editor,
  activeNodeResult,
  isFooter,
  isDesigner,
}: AttributeSettingProps & {
  isFooter?: boolean;
  isDesigner?: boolean;
}) {
  const setDeletableAttribute = useCallback(
    (value: boolean) => {
      const { activeNodePos } = activeNodeResult;

      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'deletable', value);
          tr.setMeta('overrideNonDeletableNodesFilterTransaction', true);
          tr.setMeta('addToHistory', false);

          return true;
        })
        .focus()
        .run();
    },
    [activeNodeResult, editor]
  );

  const { activeNodeType } = useActiveNode(editor);
  const blockName = formatActiveNodeType(
    activeNodeType && activeNodeType.charAt(0).toUpperCase() + activeNodeType.slice(1)
  );

  return (
    <SectionRenderer title={blockName || 'Block'} collapsible={false} defaultIsOpen isMobileAndDesktop>
      <ActionsSettings editor={editor} activeNodeResult={activeNodeResult} isFooter={isFooter} />

      <BlockTypeSettings editor={editor} />
      {isDesigner && (
        <BooleanSettings
          title="Deletable"
          property="deletable"
          editor={editor}
          activeNodeResult={activeNodeResult}
          defaultValue
          tooltip="Action only available to designers"
          onChange={setDeletableAttribute}
        />
      )}
      <TemplateSettings editor={editor} activeNodeResult={activeNodeResult} />
      <PostActions editor={editor} activeNodeResult={activeNodeResult} />
      <PricingActions editor={editor} activeNodeResult={activeNodeResult} />
      <ImageActions editor={editor} activeNodeResult={activeNodeResult} />
      <TestimonialsActions editor={editor} activeNodeResult={activeNodeResult} />
      <TagsActions editor={editor} activeNodeResult={activeNodeResult} />
      <TextActions editor={editor} activeNodeResult={activeNodeResult} />
      <AuthorsActions editor={editor} activeNodeResult={activeNodeResult} />
      <SocialsActions editor={editor} activeNodeResult={activeNodeResult} />
      <SignupActions editor={editor} activeNodeResult={activeNodeResult} />
      <SectionActions editor={editor} activeNodeResult={activeNodeResult} />
      {/** TODO: We don't need the following actions just yet, but we will in the future so keeping here to comment in. */}
      {/* <SurveyActions editor={editor} activeNodeResult={activeNodeResult} /> */}
    </SectionRenderer>
  );
}
