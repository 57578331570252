import { useMemo } from 'react';
import { File, FileDashed } from '@phosphor-icons/react';

import { useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';
import { PageRoute } from '@/interfaces/dream_builder/page_route';

import { Text } from '../UI/Text';

import { SLUG_ORDER } from './constants';
import { ContentTreeItem } from './ContentTreeItem';
import { ContentTreeItemHome } from './ContentTreeItemHome';
import { useContentTreeContext } from './context';

type Props = {
  route: PageRoute;
  slug: string;
  parentPath: string[];
  showHome?: boolean;
};

export const ContentTree = ({ route, parentPath, slug, showHome = true }: Props) => {
  const { searchQuery, isSearchMode } = useContentTreeContext();
  const { pages } = useWebsiteContext();
  const pageRouteGetter = useWebsitePageRouteGetter();

  const searchResults = useMemo(() => {
    if (!searchQuery) return [];
    if (!pages) return [];

    return pages.filter((page) => {
      const nameMatch = page.draft_page_version?.name?.toLowerCase().includes(searchQuery?.toLowerCase() || '');
      const pageRoute = pageRouteGetter?.getPageRouteFromID(page.id);
      const path = pageRoute?.path?.join('/');
      const pathMatch = path?.toLowerCase().includes(searchQuery?.toLowerCase() || '');
      return nameMatch || pathMatch;
    });
  }, [pages, searchQuery, pageRouteGetter]);

  const content = (
    <div className="flex flex-col">
      {showHome && <ContentTreeItemHome route={route} parentPath={parentPath} />}

      {route?.children &&
        route?.children_keys &&
        route.children_keys
          ?.sort((a, b) => {
            const aIndex = SLUG_ORDER.indexOf(a);
            const bIndex = SLUG_ORDER.indexOf(b);
            if (aIndex === -1 && bIndex === -1) return 0;
            if (aIndex === -1) return 1;
            if (bIndex === -1) return -1;
            return aIndex - bIndex;
          })
          .map(
            (childSlug) =>
              route.children?.[childSlug] && (
                <ContentTreeItem
                  key={route.children[childSlug]?.page_id}
                  route={route.children[childSlug]}
                  parentPath={slug ? [...parentPath, slug] : [...parentPath]}
                  slug={childSlug}
                />
              )
          )}
    </div>
  );

  const searchContent = (
    <div className="flex flex-col">
      {searchResults.map((page) => {
        const pageRoute = pageRouteGetter?.getPageRouteFromID(page.id);
        const pathCopy = [...(pageRoute?.path || [])];
        const itemSlug = pathCopy.pop() || '';
        if (!pageRoute) return null;
        return (
          <ContentTreeItem
            key={page.id}
            route={pageRoute.route}
            parentPath={[...pathCopy]}
            slug={itemSlug}
            asListItem
          />
        );
      })}
      {isSearchMode && !searchResults.length && (
        <div className="flex flex-col items-center justify-center h-full min-h-[150px] gap-4">
          <div className="flex items-center justify-center h-9 w-9 bg-wb-primary rounded-lg border border-solid border-wb-primary shadow-wb-sm">
            {(searchQuery?.length || 0) > 0 ? (
              <FileDashed size={16} weight="bold" className="text-wb-secondary" />
            ) : (
              <File size={16} weight="bold" className="text-wb-secondary" />
            )}
          </div>

          <div className="flex flex-col items-center justify-center">
            <Text size="xs" weight="semibold" variant="secondary">
              {(searchQuery?.length || 0) > 0 ? 'No pages found' : 'Search Pages'}
            </Text>
            <Text size="2xs" weight="regular" variant="secondary">
              {(searchQuery?.length || 0) > 0
                ? 'Search for a different page name or url'
                : "Type in the page's name or url to start"}
            </Text>
          </div>
        </div>
      )}
    </div>
  );

  return isSearchMode ? searchContent : content;
};
