/* eslint-disable consistent-return */
import { createContext, memo, MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { attachInstruction, extractInstruction, Instruction } from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import {
  ArrowsOutLineVertical,
  CaretDown,
  CaretRight,
  ClipboardText,
  CurrencyCircleDollar,
  EyeSlash,
  File,
  HardDrive,
  HardDrives,
  Icon,
  Image,
  ListBullets,
  ListChecks,
  ListNumbers,
  Quotes,
  RectangleDashed,
  Slideshow,
  Square,
  SquareSplitHorizontal,
  Stack,
  Tag,
  Textbox,
  TextH,
  TextHFive,
  TextHFour,
  TextHOne,
  TextHSix,
  TextHThree,
  TextHTwo,
  TextT,
  Users,
  XLogo,
} from '@phosphor-icons/react';
import { Editor, JSONContent } from '@tiptap/core';
import { Fragment, Node, Slice } from '@tiptap/pm/model';
import deepEqual from 'fast-deep-equal/es6/react';

import { useEditorStateNonBlocking } from '@/components/TiptapEditor/lib/hooks/useEditorStateNonBlocking';
import { cn } from '@/utils/cn';

import { Text } from '../../UI/Text';
import { Tooltip } from '../../UI/Tooltip';
import { Section } from '../extensions';
import { getActiveNodeData } from '../extensions/ActiveNode/utils';
import { processLayersPanelContent } from '../utils/processLayersPanelContent';

import NavigationItems from './NavigationItems';

type TreeContextType = {
  editor: Editor;
  nodePosData: React.MutableRefObject<Record<string, { pos: number; end: number; node: Node }>>;
};

const TreeContext = createContext<TreeContextType>({
  editor: {} as unknown as TreeContextType['editor'],
  nodePosData: {} as unknown as TreeContextType['nodePosData'],
});

type TNodeItemProps = {
  node: JSONContent;
};

type DragData = {
  slice: Slice;
  data: {
    node: Node;
    nodeStart: number;
    nodeEnd: number;
  };
};

const Icons: Record<string, Icon> = {
  paragraph: TextT,
  heading: TextH,
  imageBlock: Image,
  doc: File,
  text: TextT,
  bulletList: ListBullets,
  listItem: ListBullets,
  accordion: HardDrives,
  orderedList: ListNumbers,
  image: Image,
  section: RectangleDashed,
  container: Square,
  columns: SquareSplitHorizontal,
  divider: ArrowsOutLineVertical,
  pricing: CurrencyCircleDollar,
  testimonials: Quotes,
  signup: Textbox,
  recommendations: ListChecks,
  post: Slideshow,
  survey: ClipboardText,
  tags: Tag,
  authors: Users,
  socials: XLogo,
  button: HardDrive,
};

const HEADING_OPTIONS = {
  1: TextHOne,
  2: TextHTwo,
  3: TextHThree,
  4: TextHFour,
  5: TextHFive,
  6: TextHSix,
};

const formatActiveNodeType = (type?: string): string => {
  if (!type) return 'unknown';

  // split camel case and capitalize each word
  const formattedType = type
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedType;
};

const TNodeItem = memo(({ node }: TNodeItemProps) => {
  const attrs = useMemo(() => node.attrs || {}, [node]);

  const isNodeActive = useMemo(() => attrs.isActive, [attrs]);

  const { editor, nodePosData } = useContext(TreeContext);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!editor) return;

      const nodePos = nodePosData.current[attrs.id].pos;

      if (e.metaKey || e.ctrlKey) {
        editor.chain().toggleNodeInMultinodeSelection(nodePos).focus().scrollIntoView().run();
        return;
      }

      editor.chain().setNodeSelection(nodePos).focus().scrollIntoView().run();
    },
    [editor, attrs.id, nodePosData]
  );

  const handleHover = useCallback(() => {
    if (!editor) return;

    const nodePos = nodePosData.current[attrs.id].pos;

    editor.chain().setHoveredNode(nodePos).focus().run();
  }, [editor, attrs.id, nodePosData]);

  const [isOpen, setIsOpen] = useState(attrs.isOpen);

  const [isDirtyOpen, setIsDirtyOpen] = useState<boolean>(false);

  useEffect(() => {
    // user already touched this, do not mess with it.
    if (isDirtyOpen) return;

    setIsOpen(attrs.isOpen);
  }, [attrs.isOpen, isDirtyOpen]);

  const dragItem = useRef<HTMLDivElement>(null);

  const [isOver, setIsOver] = useState(false);
  const [instruction, setInstruction] = useState<Instruction | null>(null);

  useEffect(() => {
    if (!dragItem.current) return () => {};

    const getDragData = () => {
      const nodeData = nodePosData.current[attrs.id];

      const { node: pmNode, pos: nodeStart, end: nodeEnd } = nodeData;

      const slice = new Slice(Fragment.from(pmNode), 0, 0);

      const data: DragData['data'] = {
        node: pmNode,
        nodeStart,
        nodeEnd,
      };

      return {
        slice,
        data,
      };
    };

    return combine(
      draggable({
        element: dragItem.current,
        getInitialData() {
          const { slice, data } = getDragData();

          editor.view.dragging = {
            slice,
            move: true,
          };

          return data;
        },
      }),
      dropTargetForElements({
        element: dragItem.current,
        getData: ({ input, element, source }) => {
          const { data } = getDragData();
          const { node: pmNode } = data;

          const block: ('reorder-above' | 'reorder-below' | 'make-child')[] = [];
          const sourceNode = (source.data as DragData['data']).node;

          if (pmNode.type.name === Section.name && sourceNode && sourceNode.type.name !== Section.name) {
            block.push('reorder-above');
            block.push('reorder-below');
          }

          const contentFragment = Fragment.from(sourceNode);
          if (!pmNode.type.validContent(contentFragment)) {
            block.push('make-child');
          }

          // this will 'attach' the instruction to your `data` object
          return attachInstruction(data, {
            input,
            element,
            currentLevel: 2,
            indentPerLevel: 20,
            mode: 'standard',
            block,
          });
        },
        onDragEnter() {
          setIsOver(true);
        },
        onDragLeave() {
          setIsOver(false);
        },
        onDrag({ self, source }) {
          const sourceNode = (source.data as DragData['data']).node;
          const selfNode = (self.data as DragData['data']).node;

          if (selfNode.attrs.id === sourceNode.attrs.id) return;

          setInstruction(extractInstruction(self.data));
        },
        onDrop({ self, source }) {
          const {
            data: { node: pmNode, nodeStart, nodeEnd },
          } = getDragData();

          // handle dropping
          const inst: Instruction | null = extractInstruction(self.data);

          const { node: sourceNode, nodeStart: sourceNodeStart } = source.data as DragData['data'];

          let isDescendant = false;

          // check if the source node is a descendant of the self node
          sourceNode.descendants((desc) => {
            if (isDescendant) return false;

            if (pmNode.eq(desc)) isDescendant = true;
          });

          setIsOver(false);

          if (isDescendant) return;

          // 'reorder-above' as default case;
          let insertPos: number = nodeStart;

          if (inst?.type === 'reorder-below') {
            insertPos = nodeEnd;
          } else if (inst?.type === 'make-child') {
            insertPos = nodeEnd - 1;
          }

          if (insertPos < 0) return;

          // the general idea is to copy dragged node to new position and delete from old position.
          editor
            .chain()
            .command(({ tr }) => {
              tr.insert(insertPos, sourceNode);

              const delPos = tr.mapping.map(sourceNodeStart);

              tr.deleteRange(delPos, delPos + sourceNode.nodeSize);

              return true;
            })
            .focus()
            .run();
        },
      })
    );
  }, [attrs.id, editor, nodePosData]);

  const IconComp = useMemo(() => {
    if (node.type === 'heading') {
      return HEADING_OPTIONS[attrs.level as keyof typeof HEADING_OPTIONS] || TextT;
    }

    return Icons[node.type!] || Stack;
  }, [node.type, attrs.level]);

  const isHiddenInProduction = node.type === 'section' && attrs.isHiddenInProduction;

  return (
    <div className="select-none">
      <div
        className={cn('w-full bg-wb-accent h-[1px]', { 'opacity-0': !isOver || instruction?.type !== 'reorder-above' })}
      />
      <div
        ref={dragItem}
        className={cn('flex items-center gap-1 cursor-pointer border rounded-md p-1.5 min-w-[200px] w-full', {
          'bg-wb-button-accent-soft border-wb-accent-soft': isNodeActive,
          'hover:bg-wb-secondary border-transparent': !isNodeActive,
          'border-wb-accent': isOver && instruction?.type === 'make-child',
          'opacity-50': isHiddenInProduction,
        })}
        onClick={handleClick}
        onMouseEnter={handleHover}
        role="none"
      >
        {!!node.content?.length && (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsOpen(!isOpen);
              setIsDirtyOpen(!isOpen);
            }}
            className="text-wb-primary-soft"
          >
            {isOpen ? <CaretDown /> : <CaretRight />}
          </button>
        )}
        <div className="w-full flex items-center gap-1 justify-between">
          <div className="flex items-center gap-1">
            <IconComp className="text-wb-accent" weight="bold" />
            <Text size="xs" className="text-inherit">
              {formatActiveNodeType(node.type)}
            </Text>
          </div>
          {isHiddenInProduction && (
            <Tooltip center="Hidden on live site">
              <EyeSlash className="text-wb-secondary" weight="bold" />
            </Tooltip>
          )}
        </div>
      </div>

      <div style={{ height: isOpen ? undefined : '0px', overflowY: 'hidden', minWidth: 'fit-content' }}>
        {node.content?.map((childNode) => (
          <div key={childNode.attrs?.id} style={{ marginLeft: '20px' }}>
            <TNodeItem node={childNode} />
          </div>
        ))}
      </div>

      <div
        className={cn('w-full bg-wb-accent h-[1px]', { 'opacity-0': !isOver || instruction?.type !== 'reorder-below' })}
      />
    </div>
  );
}, deepEqual);

export const LayersPanel = memo(({ editor }: { editor: Editor }) => {
  const nodePosData = useRef<TreeContextType['nodePosData']['current']>({});

  const contextValue = useMemo(() => ({ editor, nodePosData }), [editor]);

  const { pageId } = useParams();

  const showNavigationItems = Boolean(pageId);

  useEffect(() => {
    const processNodePosData = () => {
      editor.state.doc.descendants((node, pos) => {
        nodePosData.current[node.attrs.id] = { pos, end: pos + node.nodeSize, node };

        if (node.type.isTextblock) return false;
      });
    };

    processNodePosData();

    editor.on('update', processNodePosData);

    return () => {
      editor.off('update', processNodePosData);
    };
  }, [editor]);

  const directRootChildren = useEditorStateNonBlocking({
    editor,
    selector: ({ editor: e }) => {
      const contentJSON = e.getJSON();

      const { activeNode, activeNodes } = getActiveNodeData(e);

      const activeNodeIds = [...activeNodes, activeNode]
        .filter(Boolean)
        .map((node) => node.attrs?.id)
        .sort();

      processLayersPanelContent(contentJSON, activeNodeIds);

      return contentJSON.content;
    },
  });

  return (
    <TreeContext.Provider value={contextValue}>
      <div className="flex flex-col gap-2">
        {showNavigationItems && <NavigationItems />}
        <div className="flex flex-col">
          <Text size="xs" variant="secondary" weight="semibold" className="p-2">
            Layers
          </Text>
          <div className="w-full overflow-x-auto">
            {directRootChildren?.map((node) => (
              <TNodeItem key={`${node.attrs?.id}`} node={node} />
            ))}
          </div>
        </div>
      </div>
    </TreeContext.Provider>
  );
});

LayersPanel.displayName = 'LayersPanel';
TNodeItem.displayName = 'TNodeItem';
