import { mergeAttributes, Node } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { AccordionTitleView } from './view/AccordionTitleView';

export const AccordionTitle = Node.create({
  name: 'accordionTitle',

  group: 'block',

  content: 'paragraph',

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      height: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({
          'data-height': attributes.height,
        }),
      },
      width_max: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
        }),
      },
      padding: {
        default: '16px 0px 16px 0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const { selection } = editor.state;
        const { $from, from, to } = selection;

        if (!editor.isActive(this.name) || !(selection instanceof TextSelection)) {
          return false;
        }

        const [accordionTitleParagraphStart, accordionTitleParagraphEnd] = [$from.start(), $from.end()];

        if (!(accordionTitleParagraphStart <= from && to <= accordionTitleParagraphEnd)) {
          return false;
        }

        const accordionEnd = $from.end(-2);

        return editor
          .chain()
          .insertContentAt(accordionEnd, { type: 'paragraph' })
          .focus(accordionEnd + 1)
          .run();
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(AccordionTitleView, {
      stopEvent: () => false,
    });
  },
});

export default AccordionTitle;
