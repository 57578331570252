import { Minus, Plus } from '@phosphor-icons/react';
import { v4 as uuidv4 } from 'uuid';

import { SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Text } from '@/routes/website/_components/UI/Text';
import { cn } from '@/utils/cn';

import { useNavbarContext } from '../../../NavbarContext';

export const ColumnCountSettings = () => {
  const { selectedContent, onUpdateNodeContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown') return null;

  const contentValue = selectedContent.content?.length || 0;

  const onAddColumn = () => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeContent(selectedContent.attrs?.id, [
      ...(selectedContent.content || []),
      {
        type: 'navbar_dropdown_column',
        attrs: { id: uuidv4() },
      },
    ]);
  };

  const onRemoveColumn = () => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeContent(selectedContent.attrs?.id, (selectedContent.content || [])?.slice(0, -1));
  };

  const isRemoveable = contentValue > 1;
  const isAddable = contentValue < 3;

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Columns
      </Text>

      <SimpleInputWrapper className="h-[32px] w-[140px] cursor-default py-1">
        <div
          className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {
            'cursor-pointer': isRemoveable,
            'opacity-20': !isRemoveable,
          })}
          onClick={onRemoveColumn}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onRemoveColumn();
            }
          }}
        >
          <Minus weight="bold" size={12} />
        </div>
        <Text size="2xs" weight="medium">
          {contentValue}
        </Text>
        <div
          className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {
            'cursor-pointer': isAddable,
            'opacity-20 pointer-events-none': !isAddable,
          })}
          onClick={onAddColumn}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onAddColumn();
            }
          }}
        >
          <Plus weight="bold" size={12} />
        </div>
      </SimpleInputWrapper>
    </div>
  );
};
