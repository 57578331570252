import { Spinner } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorPopoverGroupSettings } from '../ColorPopoverGroupSettings';
import { ColorSettings } from '../ColorSettings';
import { IconPlacementSettings } from '../IconPlacementSettings';
import { IconSettings } from '../IconSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

type Props = AttributeSettingProps & {};

export const PaginationPostSetting = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Pagination" text="Pagination" popoverTitle="Pagination" Icon={Spinner} align="end">
      <>
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'paginateButtonBorderColor',
            style: 'paginateButtonBorderStyle',
            width: 'paginateButtonBorderWidth',
            radius: 'paginateButtonBorderRadius',
          }}
        />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="paginateButtonPadding" />
        <ShadowSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Shadow"
          property="paginateButtonShadow"
        />
        <ColorPopoverGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Text"
          properties={[
            { title: 'Color', property: 'paginateButtonTextColor' },
            { title: 'Hover', property: 'paginateButtonHoverTextColor' },
          ]}
        />
        <ColorPopoverGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Background"
          properties={[
            {
              title: 'Color',
              property: 'paginateButtonBackgroundColor',
              gradientProperty: 'paginateButtonBackgroundColor',
            },
            { title: 'Hover', property: 'paginateButtonHoverBackgroundColor' },
          ]}
        />
        <IconSettings editor={editor} activeNodeResult={activeNodeResult} property="paginateButtonIcon" />
        <IconPlacementSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Icon Placement"
          property="paginateButtonIconPlacement"
          disabled={!activeNodeResult?.activeNodeAttributes?.paginateButtonIcon}
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Icon Color"
          property="paginateButtonIconColor"
          disabled={!activeNodeResult?.activeNodeAttributes?.paginateButtonIcon}
        />
        <SimpleTextSettings
          title="Text"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="paginateButtonText"
        />
        <SimpleTextSettings
          title="Loading"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="paginateButtonBusyText"
        />
      </>
    </PopoverHelper>
  );
};

export default PaginationPostSetting;
