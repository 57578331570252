import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { ArrowSquareOut } from '@phosphor-icons/react';

import { useWebsiteContext } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';
import api from '@/services/swarm';

import { Switch } from '../../_components/UI/Switch';
import { Text } from '../../_components/UI/Text';
import { FBSettings } from '../_components/FBSettings';
import { MetadataSettings } from '../_components/MetadataSettings';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';
import { XSettings } from '../_components/XSettings';

const WebsiteSettingsSEO = () => {
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const { site } = useWebsiteContext();
  const updateSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  const handleImageSave = (key: string, file: string | File | any) => {
    const formData = new FormData();
    const currentPubId = currentPublication?.id || '';
    formData.append('publication_id', currentPubId);
    formData.append(`site_version[metadata][${key}]`, file);

    toast
      .promise(api.patch(`/site_versions/${site?.draft_site_version?.id}`, formData), {
        loading: 'Saving...',
        success: 'Saved!',
        error: 'Failed to upload image',
      })
      .finally(() => {
        queryClient.invalidateQueries(['publications', currentPubId, 'sites', 'null']);
      });
  };

  return (
    <div>
      <SettingsSection>
        <SettingsItem
          title="Search Engine Indexing"
          description="By default, websites are made discoverable on the web. Turning this off will add a noindex meta tag in the header of your publication to prevent your website from showing up in Google Search."
        >
          <Switch
            id="noindex_enabled"
            labelText="Discoverable on the web"
            checked={!site?.draft_site_version?.metadata?.noindex_enabled}
            onCheckedChange={(value: boolean) => {
              updateSiteVersion.mutate({
                metadata: {
                  noindex_enabled: !value,
                },
              });
            }}
          />
        </SettingsItem>
      </SettingsSection>
      {!site?.draft_site_version?.metadata?.noindex_enabled && (
        <SettingsSection>
          <MetadataSettings
            data={site?.draft_site_version}
            onUpdate={(key, value) => {
              updateSiteVersion.mutate({
                metadata: {
                  [key]: value,
                },
              });
            }}
          />
        </SettingsSection>
      )}

      <SettingsSection>
        <XSettings
          data={site?.draft_site_version}
          onUpdate={(key, value) => {
            updateSiteVersion.mutate({
              metadata: {
                [key]: value,
              },
            });
          }}
          onUpdateImage={(file) => handleImageSave('x_image_url', file)}
        />
      </SettingsSection>

      <SettingsSection>
        <FBSettings
          data={site?.draft_site_version}
          onUpdate={(key, value) => {
            updateSiteVersion.mutate({
              metadata: {
                [key]: value,
              },
            });
          }}
          onUpdateImage={(file) => handleImageSave('facebook_image_url', file)}
        />
      </SettingsSection>
      <SettingsSection>
        <SettingsItem
          title="Site Map"
          description="Provide information about the pages on your site to allow search engines to crawl your site more efficiently."
        >
          <div className="flex flex-col gap-1.5 pt-4">
            <Text size="sm" variant="secondary">
              Auto-generated sitemap:
            </Text>
            <a
              href={`${currentPublication?.url}sitemap.xml`}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 px-3 py-2 rounded-lg bg-wb-secondary w-fit borderr border-wb-primary"
            >
              <div className="w-2 h-2 rounded-full bg-wb-success" />
              <Text size="xs">{currentPublication?.hostname}/sitemap.xml</Text>
              <ArrowSquareOut size={16} className="text-wb-secondary" />
            </a>
          </div>
        </SettingsItem>
      </SettingsSection>
      <SettingsSection>
        <SettingsItem title="Robots.txt" description="Tell search engine crawlers what they can crawl your site.">
          <div className="flex flex-col gap-1.5 pt-4">
            <Text size="sm" variant="secondary">
              Auto-generated robots.txt:
            </Text>
            <a
              href={`${currentPublication?.url}robots.txt`}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 px-3 py-2 rounded-lg bg-wb-secondary w-fit borderr border-wb-primary"
            >
              <div className="w-2 h-2 rounded-full bg-wb-success" />
              <Text size="xs">{currentPublication?.hostname}/robots.txt</Text>
              <ArrowSquareOut size={16} className="text-wb-secondary" />
            </a>
          </div>
        </SettingsItem>
      </SettingsSection>
    </div>
  );
};

export default WebsiteSettingsSEO;
