import { useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

import useApplicationDemographics from '@/hooks/boosts/grow/useApplicationDemographics';
import useApplicationScores from '@/hooks/boosts/grow/useApplicationScores';
import useWebApplication from '@/hooks/boosts/grow/useWebApplication';
import { useCreateAbuseReport } from '@/hooks/useAbuseReports';
import { BoostAgreement } from '@/interfaces/boosts/grow/boost_agreement';

import ApplicationItem from './ApplicationItem';
import ConfirmBoostAgreementRejectModal from './ConfirmBoostAgreementRejectModal';
import { ReviewBoostModal } from './ReviewBoostModal';

interface Props {
  item: BoostAgreement;
  narrowLayout: boolean;
  isSelected: boolean;
  onSelect: () => void;
}

const WebApplicationItemV3 = ({ item, narrowLayout, isSelected, onSelect }: Props) => {
  const {
    created_at: createdAt,
    id: boostAgreementId,
    recommending_publication: publication,
    why_me: whyMe,
  } = item;

  const {
    data: applicationScoresData,
    isLoading: applicationScoresDataIsLoading
  } = useApplicationScores(publication.id);
  const {
    data: applicationDemographicsData,
    isLoading: applicationDemographicsDataIsLoading
  } = useApplicationDemographics(boostAgreementId);

  const [searchParams, setSearchParams] = useSearchParams();
  const isReviewModalOpen = searchParams.get('boost_agreement_id') === boostAgreementId;
  const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false);

  const [isAccepted, setIsAccepted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);

  const handleClickReview = () => {
    searchParams.set('boost_agreement_id', boostAgreementId);
    setSearchParams(searchParams);
  };

  const handleClickReject = () => setShowConfirmRejectModal(true);

  const { handleAccept, handleReject, isAccepting, isRejecting } = useWebApplication(item, {
    onAcceptSuccess: () => setIsAccepted(true),
    onRejectSuccess: () => setIsRejected(true),
  });

  const handleSuccessfulRejection = () => {
    searchParams.delete('boost_agreement_id');
    setSearchParams(searchParams);
    setIsRejected(true);
  };

  const onAbuseReportSuccess = () => {
    toast.success('Report submitted');
    handleReject('');
  };

  const { mutateAsync: createAbuseReport, isLoading: isReportLoading } = useCreateAbuseReport({
    onSuccess: onAbuseReportSuccess,
  });

  const handleReport = () => {
    createAbuseReport({
      publication_id: publication.id,
    });
  };

  return (
    <>
      <ApplicationItem
        createdAt={createdAt}
        publication={publication}
        applicationScores={applicationScoresData}
        applicationScoresIsLoading={applicationScoresDataIsLoading}
        applicationDemographics={applicationDemographicsData}
        applicationDemographicsIsLoading={applicationDemographicsDataIsLoading}
        onClickReview={handleClickReview}
        narrowLayout={narrowLayout}
        onAccept={handleAccept}
        onReject={handleClickReject}
        isAccepting={isAccepting}
        isRejecting={isRejecting}
        isAccepted={isAccepted}
        isRejected={isRejected}
        isSelected={isSelected}
        onSelect={onSelect}
      />
      <ConfirmBoostAgreementRejectModal
        isOpen={showConfirmRejectModal}
        boostAgreement={item}
        onClickCancel={() => setShowConfirmRejectModal(false)}
        onSuccess={handleSuccessfulRejection}
      />
      <ReviewBoostModal
        boostAgreementId={boostAgreementId}
        publication={publication}
        publicationMessage={whyMe || ""}
        applicationScores={applicationScoresData}
        applicationScoresIsLoading={applicationScoresDataIsLoading}
        applicationDemographics={applicationDemographicsData}
        applicationDemographicsIsLoading={applicationDemographicsDataIsLoading}
        isOpen={isReviewModalOpen}
        isAccepting={isAccepting}
        isRejecting={isRejecting}
        isReporting={isReportLoading}
        onClickReject={handleClickReject}
        onClickAccept={handleAccept}
        onClickReport={handleReport}
      />
    </>
  );
};

export default WebApplicationItemV3;
