import { useCallback, useMemo } from 'react';

import { BottomLeftIcon, BottomRightIcon, TopLeftIcon, TopRightIcon } from '../../../Icons/BorderIcons';
import BoxModelToggle from '../helpers/BoxModelToggle';
import { AttributeSettingProps } from '../types';
import { getTRBLValue } from '../utils/getTRBLValue';
import { parseCssValue } from '../utils/parseCssValue';

type TRBL = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type BorderRadiusSettingsProps = AttributeSettingProps & {
  property?: string;
};

const getStringValue = (value: TRBL, unit: string) => {
  return `${value.top}${unit} ${value.right}${unit} ${value.bottom}${unit} ${value.left}${unit}`;
};

const DEFAULT_BORDER_RADIUS = '0px';

const BorderRadiusSettings = ({
  editor,
  title = 'Radius',
  activeNodeResult,
  property = 'borderRadius',
}: BorderRadiusSettingsProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;

  const { radius } = useMemo(() => {
    const borderRadius = getTRBLValue(activeNodeAttributes[property] || DEFAULT_BORDER_RADIUS);

    return {
      radius: {
        top: parseCssValue(borderRadius.top).value,
        right: parseCssValue(borderRadius.right).value,
        bottom: parseCssValue(borderRadius.bottom).value,
        left: parseCssValue(borderRadius.left).value,
      },
    };
  }, [activeNodeAttributes, property]);

  const handleTRBLChange = useCallback(
    (propertyName: string, defaultValues: string) =>
      (position: 'top' | 'right' | 'bottom' | 'left' | 'all' | 'top-bottom' | 'left-right', value: number) => {
        const currentTRBL = getTRBLValue(activeNodeAttributes[propertyName] || defaultValues);
        let updatedTRBL = {
          top: parseCssValue(currentTRBL.top).value,
          right: parseCssValue(currentTRBL.right).value,
          bottom: parseCssValue(currentTRBL.bottom).value,
          left: parseCssValue(currentTRBL.left).value,
        };

        if (position === 'all') {
          updatedTRBL = { top: value, right: value, bottom: value, left: value };
        } else if (position === 'top-bottom') {
          updatedTRBL.top = value;
          updatedTRBL.bottom = value;
        } else if (position === 'left-right') {
          updatedTRBL.left = value;
          updatedTRBL.right = value;
        } else {
          updatedTRBL[position] = value;
        }

        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, propertyName, getStringValue(updatedTRBL, 'px'));
          return true;
        });
      },
    [editor, activeNodePos, activeNodeAttributes]
  );

  return (
    <BoxModelToggle
      title={title}
      defaultValues={radius}
      suffixes={{
        top: <TopLeftIcon />,
        right: <TopRightIcon />,
        bottom: <BottomRightIcon />,
        left: <BottomLeftIcon />,
      }}
      showCombinedValues={false}
      onUpdate={handleTRBLChange(property, DEFAULT_BORDER_RADIUS)}
    />
  );
};

export default BorderRadiusSettings;
