import { useCallback, useEffect, useState } from 'react';
import { CoinVertical, Link as LinkIcon, LinkBreak, X } from '@phosphor-icons/react';
import { useEditorState } from '@tiptap/react';
import classNames from 'classnames';

import { cn } from '../../../../_utils/cn';
import { ColorPicker } from '../../../UI/ColorPicker';
import { SimpleInputWrapper } from '../../../UI/Input';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';

import GradientSettings from './GradientSettings';
import { TokenizeColorSettings } from './TokenizeColorSettings';

type Props = AttributeSettingProps & {
  title: string;
  property: string;
  onOverrideSetColor?: (value: string | null) => void;
  properties?: string[];
  initialColor?: string | null;
  onUnGroup?: () => void;
  hasGradientPicker?: boolean;
  isFontGradient?: boolean;
  gradientProperty?: string;
  // if true, will set the color as a mark style instead of a node attribute
  isMarkStyle?: boolean;
  disabled?: boolean;
  isUngroupable?: boolean;
};

const rgbToHex = (rgb: string): string => {
  if (rgb.startsWith('#')) return rgb.toUpperCase();

  const rgbMatch = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!rgbMatch?.[1] || !rgbMatch?.[2] || !rgbMatch?.[3]) return rgb;

  const r = parseInt(rgbMatch[1], 10);
  const g = parseInt(rgbMatch[2], 10);
  const b = parseInt(rgbMatch[3], 10);

  const toHex = (n: number): string => {
    const hex = n.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
};

const parseContentValue = (
  contentValue?: string
): {
  color: string;
  alpha: number;
} => {
  if (!contentValue) return { color: 'Inherit', alpha: 100 };

  // if the total character is 9, then it has alpha value
  if (contentValue.length === 9) {
    return {
      color: contentValue.slice(0, -2),
      alpha: Math.round((parseInt(contentValue.slice(-2), 16) / 255.0) * 100),
    };
  }

  return {
    color: rgbToHex(contentValue),
    alpha: 100,
  };
};

export const ColorSettings = ({
  editor,
  title,
  property,
  properties,
  activeNodeResult,
  onOverrideSetColor,
  initialColor,
  isMarkStyle = false,
  onUnGroup,
  isUngroupable = true,
  hasGradientPicker = false,
  isFontGradient = false,
  gradientProperty = 'background',
  disabled,
}: Props) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const color = useEditorState({
    editor,
    selector: ({ editor: e }) => {
      if (isMarkStyle) {
        return e?.getAttributes('textStyle').color;
      }

      return activeNodeAttributes[property];
    },
  });

  const fontGradient = useEditorState({
    editor,
    selector: ({ editor: e }) => {
      if (isMarkStyle) {
        return e?.getAttributes('textStyle').fontGradient;
      }

      return activeNodeAttributes.fontGradient;
    },
  });

  const [pickerType, setPickerType] = useState<'color' | 'gradient'>('color');
  const isTokenApplied = activeNodeAttributes?.tokens?.[property] || editor.getAttributes('textStyle')?.tokens?.color;
  const hasTokenAttribute = activeNodeAttributes?.tokens || editor.getAttributes('textStyle')?.tokens?.color;

  const onSetColor = useCallback(
    (value: string | null) => {
      if (isMarkStyle) {
        if (value) {
          editor?.chain().selectTextBlock().resetFontGradient().setColor(value).run();
        } else {
          editor?.chain().selectTextBlock().unsetColor().run();
        }
        return;
      }
      if (activeNodePos === undefined || !activeNodeType) return;
      editor.commands.command(({ tr }) => {
        // This is in tandem with ColorGroupSettings, where we set multiple properties at once
        if (properties) {
          properties.forEach((propertyType: string) => {
            tr.setNodeAttribute(activeNodePos, propertyType, value);
          });
        } else {
          tr.setNodeAttribute(activeNodePos, property, value);
        }
        return true;
      });
    },
    [isMarkStyle, activeNodePos, activeNodeType, editor, properties, property]
  );

  const onReset = useCallback(() => {
    onSetColor(null);
  }, [onSetColor]);

  const { color: parsedColor, alpha: parsedAlpha } = parseContentValue(color || initialColor);

  useEffect(() => {
    if (fontGradient) {
      setPickerType('gradient');
    }
  }, [fontGradient]);

  return (
    <Popover>
      <div
        className={cn(
          'flex items-center justify-stretch gap-2 select-none',
          disabled ? 'opacity-50 pointer-events-none' : ''
        )}
      >
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <PopoverTrigger asChild>
          <SimpleInputWrapper className="justify-start relative">
            {isTokenApplied && (
              <div className="p-0.5 border rounded-full absolute -left-3 -top-2 bg-wb-accent-soft text-wb-accent border-wb-accent">
                <CoinVertical className="w-2 h-2" />
              </div>
            )}
            {onUnGroup && (
              <button
                type="button"
                className={classNames(
                  'absolute -left-5 top-2.5 hover:cursor-pointer text-wb-secondary transition-all',
                  isUngroupable && 'hover:text-wb-primary'
                )}
                onClick={onUnGroup}
              >
                {isUngroupable ? (
                  <LinkBreak className="w-4 h-4" />
                ) : (
                  <Tooltip center="Sync color changes">
                    <LinkIcon className="w-4 h-4" />
                  </Tooltip>
                )}
              </button>
            )}
            {color ? (
              <div className="w-4 h-4 rounded-md border border-border box-content" style={{ backgroundColor: color }} />
            ) : null}
            <Text size="2xs" weight="medium" className="grow">
              {parsedColor?.toUpperCase()}
            </Text>
            <Text size="2xs" weight="medium" className="" variant="secondary">
              {parsedAlpha}%
            </Text>
          </SimpleInputWrapper>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[280px] p-0" align="end" side="left" sideOffset={110}>
        <div className="flex items-center justify-between p-3">
          <Text size="sm" weight="semibold" className="grow">
            {title} {title === 'Color' ? '' : 'Color'}
          </Text>
          <PopoverClose className="p-1 text-wb-secondary rounded-full bg-wb-secondary cursor-pointer">
            <X />
          </PopoverClose>
        </div>
        <div className="max-h-[700px] overflow-y-auto p-3 flex flex-col gap-4">
          {hasGradientPicker && (
            <div className="w-full flex flex-col gap-2">
              <div className="grow flex items-center">
                <SimpleInputWrapper className="h-[38px] px-1">
                  <ToggleGroup
                    className="py-[1px] w-full"
                    type="single"
                    defaultValue={pickerType}
                    onValueChange={(type) => setPickerType(type as 'color' | 'gradient')}
                  >
                    <ToggleGroupItem asChild value="color">
                      <Text variant="inherit" size="2xs" weight="medium" className="grow">
                        Color
                      </Text>
                    </ToggleGroupItem>
                    <ToggleGroupItem asChild value="gradient">
                      <Text variant="inherit" size="2xs" weight="medium" className="grow">
                        Gradient
                      </Text>
                    </ToggleGroupItem>
                  </ToggleGroup>
                </SimpleInputWrapper>
              </div>
            </div>
          )}
          {hasGradientPicker && pickerType === 'gradient' && (
            <GradientSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property={gradientProperty}
              isFontGradient={isFontGradient}
              isMarkStyle={isMarkStyle}
            />
          )}
          {(pickerType === 'color' || !hasGradientPicker) && (
            <ColorPicker
              key={activeNodeAttributes.id}
              selectedColor={color}
              onSetColor={(value: string | null) => {
                // Used as an escape hatch to prevent this from submitting if it's consumed by a parent component like in BorderSettings
                if (onOverrideSetColor) {
                  onOverrideSetColor(value);
                } else {
                  onSetColor(value);
                }
              }}
              onReset={onReset}
            />
          )}

          {hasTokenAttribute && (
            <TokenizeColorSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property={property}
              title={title}
              isMarkStyle={isMarkStyle}
              color={color}
            />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
