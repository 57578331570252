import { BoostAgreementFilterData } from '@/interfaces/boosts/grow/boost_agreement_filter';
import { FiltersPopopoverOperator } from '@/ui/FiltersPopover/FiltersPopover.types';

export interface BoostAgreementsQueryParams {
  tagsOperator: FiltersPopopoverOperator;
  tags: string[];
  languagesOperator: FiltersPopopoverOperator;
  languages: string[];
  countries: string[];
  postCountOperator: FiltersPopopoverOperator;
  postCount?: number;
  sizeScores: number[];
  growthScores: number[];
  engagementScores: number[];
  boostQualityScores: number[];
}

export const toBoostAgreementFilterAttributes = (params: BoostAgreementsQueryParams): BoostAgreementFilterData => {
  return {
    tags: {
      operator: params.tagsOperator,
      value: params.tags,
    },
    languages: {
      operator: params.languagesOperator,
      value: params.languages,
    },
    countries: {
      value: params.countries,
    },
    post_count: {
      operator: params.postCountOperator,
      value: params.postCount,
    },
    size_scores: params.sizeScores,
    growth_scores: params.growthScores,
    engagement_scores: params.engagementScores,
    boost_quality_scores: params.boostQualityScores,
  };
};
