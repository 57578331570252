/* eslint-disable react/jsx-props-no-spreading */

import { ChangeEvent, FC, Fragment, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Modifier, usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import { v4 as generateUuid } from 'uuid';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { DROP_DOWN_Z_INDEX } from '@/components/zIndexes';
import Tooltip from '@/ui/Tooltip';

import { SelectSubElementClassNames } from '../../components/Form/types';
import { LineDivider } from '../../components/LineDivider';
import { Typography } from '../../components/Typography';
import { Option } from '../../interfaces/general';
import { Badge, BadgeProps } from '../Badge';

interface Props {
  name: string;
  onSelect: (name: string, value: string | any) => void;
  values: string[] | Option[];
  button?: ReactNode;
  buttonClassNames?: SelectSubElementClassNames;
  className?: string;
  disabled?: boolean;
  emptyLabel?: string;
  errorText?: string;
  helperText?: string;
  labelText?: string;
  labelTextClassName?: string;
  maxVisibleSelectedOptions?: number | 'none';
  onClose?: () => void;
  optionsContainerClassNames?: SelectSubElementClassNames;
  searchHandler?: (query: string) => Promise<Option[]> | Option[];
  onTopRightLinkClick?: (name: string) => void;
  placeholderText?: string | ReactNode;
  portalMountedId?: string;
  required?: boolean;
  search?: boolean;
  shouldBindOptionAsValue?: boolean;
  staticOptions?: Option[];
  suffixElement?: ReactNode;
  topRightLinkText?: string;
  selectedTagType?: BadgeProps['type'];
  labelMaxWidth?: string;
  tooltipClass?: string;
  tooltipContainerClass?: string;
  optionsPosition?: 'bottom-end' | 'bottom-start' | 'top-end' | 'top-start';
}

const MultiSelectDropdown: FC<Props> = ({
  name,
  onSelect,
  values,
  button,
  buttonClassNames = {},
  className,
  disabled,
  emptyLabel,
  errorText,
  helperText,
  labelText,
  labelTextClassName,
  maxVisibleSelectedOptions = 2,
  onClose,
  searchHandler,
  optionsContainerClassNames = {},
  onTopRightLinkClick,
  placeholderText,
  portalMountedId,
  required,
  search,
  shouldBindOptionAsValue = false,
  staticOptions,
  topRightLinkText,
  selectedTagType = 'default_filled',
  labelMaxWidth = '',
  tooltipClass = '',
  tooltipContainerClass = '',
  optionsPosition = 'bottom-end',
}: Props) => {
  const popperElRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const searchQueryTimeout = useRef<number>();
  const [popperElement, setPopperElement] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState<Option[]>(staticOptions || []);
  const [isLoading, setIsLoading] = useState(false);

  const SEARCH_DELAY = 300;

  const modifiers = useMemo(
    (): Modifier<string, Record<string, unknown>>[] => [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'matchReferenceSize',
        enabled: true,
        fn: ({ state, instance }) => {
          const widthOrHeight =
            state.placement.startsWith('left') || state.placement.startsWith('right') ? 'height' : 'width';

          if (!popperElement) return;

          const popperSize =
            popperElement[`offset${widthOrHeight[0].toUpperCase() + widthOrHeight.slice(1)}` as 'offsetWidth'];
          const referenceSize = state.rects.reference[widthOrHeight];

          if (Math.ceil(popperSize) >= Math.floor(referenceSize)) return;

          // @ts-ignore: Style is accessible
          popperElement.style[widthOrHeight] = `${referenceSize}px`;
          instance.update();
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [popperElement, values]
  );

  const calculatePlacement = () => {
    if (!referenceElement) return 'bottom';
    const rect = referenceElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;

    return spaceBelow < 200 && spaceAbove > spaceBelow ? 'top' : 'bottom'; // Use 'top' if not enough space below
  };

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: optionsPosition,
    modifiers,
  });

  const handleSelect = (selectedValues: string | any) => {
    onSelect(name, selectedValues);
  };

  const removeOption = (valueToRemove: string | Option) => {
    const newValues =
      Array.isArray(values) && typeof values[0] === 'string'
        ? (values as string[]).filter((v) => v !== valueToRemove)
        : (values as Option[]).filter((v) => v !== valueToRemove);
    onSelect(name, newValues);
  };

  const removeAll = () => {
    onSelect(name, []);
  };

  const performSearch = async () => {
    setIsLoading(true);

    if (!staticOptions && searchHandler) {
      const searchResult = await searchHandler(searchQuery);

      setOptions(searchResult);
    } else if (staticOptions && searchHandler) {
      const results = searchHandler(searchQuery) as Option[];
      setOptions(results);
    }

    setIsLoading(false);
  };

  const searchHandlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!searchHandler) return;

    if (searchQueryTimeout.current) {
      window.clearTimeout(searchQueryTimeout.current);
    }

    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    searchQueryTimeout.current = window.setTimeout(() => {
      performSearch();
    }, SEARCH_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const showAllOptions =
    maxVisibleSelectedOptions === 'none' || (values.length > 0 && values.length <= maxVisibleSelectedOptions);

  const showSelectedOptionsText = values
    .map((value) => {
      if (typeof value === 'string') {
        return value;
      }

      return value.label;
    })
    .join(', ');

  return (
    <div className={cx('relative text-nowrap', className)}>
      <Listbox
        value={values}
        onChange={handleSelect}
        disabled={disabled}
        by={shouldBindOptionAsValue ? 'value' : undefined}
        multiple
      >
        {({ open }) => (
          <>
            {(labelText || topRightLinkText) && (
              <div className="flex justify-between">
                {labelText && (
                  <Listbox.Label className={labelTextClassName || 'block text-sm font-normal text-gray-900'}>
                    {labelText}
                    {required ? ' *' : ''}
                  </Listbox.Label>
                )}
                {topRightLinkText && onTopRightLinkClick && (
                  <button
                    type="button"
                    className="text-primary-600 hover:text-primary-900 text-sm ml-2"
                    onClick={() => onTopRightLinkClick(name)}
                  >
                    {topRightLinkText}
                  </button>
                )}
              </div>
            )}
            <div className={cx('relative w-full', labelText && !labelTextClassName ? 'mt-1' : undefined)}>
              <div ref={(element) => setReferenceElement(element)}>
                {button && <Listbox.Button>{button}</Listbox.Button>}
                {!button && (
                  <div
                    className={cx(
                      'flex items-stretch relative rounded-md focus:outline-none focus:border-surface-200 w-auto max-w-full',
                      buttonClassNames.focus || 'focus:none focus:border-surface-200',
                      buttonClassNames.text || 'text-left text-sm',
                      buttonClassNames.cursor || 'cursor-default',
                      buttonClassNames.shadow || 'shadow-sm',
                      buttonClassNames.padding || 'py-2 px-3',
                      buttonClassNames.background || 'bg-white',
                      buttonClassNames.border || 'border border-surface-200',
                      buttonClassNames.hover,
                      disabled ? 'bg-gray-50 cursor-not-allowed' : '',
                      open && '!shadow-[0px_0px_0px_4px_#E5E7EB,0px_1px_2px_0px_rgba(0,0,0,0.05)]'
                    )}
                  >
                    <Listbox.Button className="p-0 m-0">
                      <div className="flex gap-x-2">
                        <span className="inline-flex items-center">
                          <Typography token="font-normal/text/sm">{placeholderText || 'Select an Option'}</Typography>
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-900 ml-2" aria-hidden="true" />
                        </span>
                      </div>
                    </Listbox.Button>
                    {values.length > 0 && <LineDivider direction="vertical" className="h-full mx-2" />}
                    {maxVisibleSelectedOptions !== 'none' && values.length > maxVisibleSelectedOptions && (
                      <Tooltip
                        id="show-selected-badge-multiselect"
                        text={showSelectedOptionsText}
                        showIcon={false}
                        autoWidth
                        tooltipClass={tooltipClass}
                        containerClass={tooltipContainerClass}
                      >
                        <Badge
                          key="selected-options-badge"
                          type={selectedTagType}
                          onDismiss={removeAll}
                          alwaysShowDismiss
                          className="w-full"
                          size="sm"
                        >
                          {values.length} selected
                        </Badge>
                      </Tooltip>
                    )}
                    {showAllOptions && (
                      <span className="inline-flex gap-x-1 overflow-hidden">
                        {values.map((value) => (
                          <Tooltip
                            id={`multiselect-dropdown-badge-${typeof value === 'string' ? value : value.label}`}
                            text={typeof value === 'string' ? value : value.label}
                            showIcon={false}
                            autoWidth
                            tooltipClass={tooltipClass}
                            containerClass={tooltipContainerClass}
                          >
                            <Badge
                              key={`selected-option-badge-${value}`}
                              type={selectedTagType}
                              labelMaxWidth={labelMaxWidth}
                              onDismiss={() => {
                                removeOption(value);
                              }}
                              alwaysShowDismiss
                              className="w-full"
                              size="sm"
                            >
                              {typeof value === 'string' ? value : value.label}
                            </Badge>
                          </Tooltip>
                        ))}
                      </span>
                    )}
                  </div>
                )}
              </div>

              <Portal node={portalMountedId && document ? document.getElementById(portalMountedId) : null}>
                <div ref={popperElRef} className={DROP_DOWN_Z_INDEX} style={styles.popper} {...attributes.popper}>
                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    beforeEnter={() => setPopperElement(popperElRef.current)}
                    afterLeave={() => {
                      setPopperElement(null);
                      onClose?.();
                    }}
                  >
                    <Listbox.Options
                      className={cx(
                        'absolute z-10 bg-white shadow-md max-h-60 py-1 rounded-md text-base overflow-auto focus:outline-none sm:text-sm',
                        optionsContainerClassNames.position || 'origin-top-right right-0',
                        optionsContainerClassNames.width || 'w-full',
                        calculatePlacement() === 'top' ? 'bottom-full mb-2' : 'top-full mt-2'
                      )}
                    >
                      {search && (
                        <div className="w-full px-3 py-2 relative">
                          <input
                            name={`${name}-search`}
                            className="w-full pl-10 pr-8 py-2 border-surface-200 focus:border-surface-200 focus:outline-none focus:!shadow-[0px_0px_0px_4px_#E5E7EB,0px_1px_2px_0px_rgba(0,0,0,0.05)] rounded-md focus:ring-0"
                            placeholder="Search"
                            onChange={searchHandlerChange}
                            value={searchQuery}
                          />
                          {isLoading && (
                            <LoadingSpinner className="w-5 h-5 text-surface-500 absolute left-0 top-0 mt-[19px] ml-[23px]" />
                          )}
                          {!isLoading && (
                            <MagnifyingGlassIcon className="w-5 h-5 text-surface-500 absolute left-0 top-0 mt-[19px] ml-[23px]" />
                          )}
                          {searchQuery?.trim().length > 0 && (
                            <button
                              type="button"
                              className="absolute right-0 top-0 w-4 h-4 mt-[14px] mr-[23px]"
                              onClick={() => {
                                setSearchQuery('');
                              }}
                            >
                              <XMarkIcon className="w-full h-full text-surface-500 absolute" />
                            </button>
                          )}
                        </div>
                      )}
                      {options.length === 0 && emptyLabel ? (
                        <div className="py-2 px-3 font-bold text-gray-400 text-xs">{emptyLabel}</div>
                      ) : null}
                      {options.map((option, index) => {
                        if (option.isOptGroup) {
                          return (
                            <div
                              key={`select-group-label-${option.label}-${generateUuid()}`}
                              className={cx('py-2 px-3 font-bold text-gray-400 text-xs', index > 0 && 'mt-1 border-t')}
                            >
                              {option.labelElement || option.label}
                            </div>
                          );
                        }

                        return (
                          <Listbox.Option
                            key={option.value}
                            className={({ selected, active }) =>
                              cx(
                                (selected || active) && ' bg-gray-100',
                                'cursor-pointer select-none relative py-2 px-3'
                              )
                            }
                            value={shouldBindOptionAsValue ? option : option.value}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                            style={{ pointerEvents: 'auto' }}
                          >
                            {({ selected }) => {
                              const Icon = option.icon;
                              return (
                                <>
                                  <div className="flex flex-col pr-6">
                                    <div className="flex items-center font-normal text-900 text-wrap">
                                      {Icon && <Icon className="w-4 h-4" />}
                                      {option.labelElement || option.label}
                                    </div>
                                    {option.description ? (
                                      <span className={cx('font-normal text-xs text-gray-400')}>
                                        {option.description}
                                      </span>
                                    ) : null}
                                  </div>

                                  {selected ? (
                                    <span className="text-gray-900 absolute inset-y-0 right-0 flex items-center pr-4">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              );
                            }}
                          </Listbox.Option>
                        );
                      })}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Portal>

              {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
              {errorText && <p className="mt-2 text-xs text-red-500 text-wrap">{errorText}</p>}
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default MultiSelectDropdown;
