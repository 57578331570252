import { AdNetworkPayoutModel } from '../shared/payout_model';

export enum AdNetworkCampaignOpportunityGroupStatus {
  DRAFT = 'draft',
  STAGING = 'staging',
  WORKING = 'working',
  PROVISIONED = 'provisioned',
}

export interface CampaignOpportunityGroupAdGroupOption {
  id: string;
  kind: string;
  name: string;
  description: string;
  units: {
    primary: number;
    secondary: number;
  };
  managed: boolean;
  selected: boolean;
}

export interface AdNetworkCampaignOpportunityGroup {
  id: string;
  campaign_id: string;
  deadline: number;
  message_to_publisher: string;
  name: string;
  payout_model: AdNetworkPayoutModel;
  status: AdNetworkCampaignOpportunityGroupStatus;
  opportunity_counts_by_status: {
    draft: number;
    pending: number;
    accepted: number;
    rejected: number;
    canceled: number;
    expired: number;
  };
  estimated_clicks_by_status: {
    draft: number;
    pending: number;
    accepted: number;
    rejected: number;
    canceled: number;
    expired: number;
  };
  estimated_opens_by_status: {
    draft: number;
    pending: number;
    accepted: number;
    rejected: number;
    canceled: number;
    expired: number;
  };
  estimated_payout_cents_by_status: {
    draft: number;
    pending: number;
    accepted: number;
    rejected: number;
    canceled: number;
    expired: number;
  };
  ad_group_name: string;
  ad_group_options: Array<CampaignOpportunityGroupAdGroupOption>;
  ad_group_units: { total: number; primary: number; secondary: number };
  tier_one_payout_per_click: string;
  tier_one_payout_per_click_cents: number;
  tier_two_payout_per_click: string;
  tier_two_payout_per_click_cents: number;
  tier_three_payout_per_click: string;
  tier_three_payout_per_click_cents: number;
  tier_four_payout_per_click: string;
  tier_four_payout_per_click_cents: number;
  tier_unknown_payout_per_click: string;
  tier_unknown_payout_per_click_cents: number;
  tier_one_payout_per_mille: string;
  tier_one_payout_per_mille_cents: number;
  tier_two_payout_per_mille: string;
  tier_two_payout_per_mille_cents: number;
  tier_three_payout_per_mille: string;
  tier_three_payout_per_mille_cents: number;
  tier_four_payout_per_mille: string;
  tier_four_payout_per_mille_cents: number;
}

export interface AdNetworkCampaignOpportunityGroupDraftOpportunity {
  id: string;
  publication_name: string;
  estimated_clicks: number;
  estimated_payout: string;
}
