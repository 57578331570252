import { CoinVertical } from '@phosphor-icons/react';
import { NavbarSerializableNode } from '@shared/dream-components/dist/navbarElement';

import { ColorPicker } from '@/routes/website/_components/UI/ColorPicker';
import { SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';

import { TokenizeColorSettings } from './TokenizeColorSettings';

type Props = {
  selectedContent: NavbarSerializableNode;
  title: string;
  property: 'color' | 'background' | string;
  defaultValue: string;
  hasTokenizedColors?: boolean;
};

const parseContentValue = (
  contentValue?: string
): {
  color: string;
  alpha: number;
} => {
  if (!contentValue) return { color: '', alpha: 100 };

  // if the total character is 9, then it has alpha value
  if (contentValue?.length === 9) {
    return {
      color: contentValue.slice(0, -2),
      alpha: Math.round((parseInt(contentValue.slice(-2), 16) / 255.0) * 100),
    };
  }

  return {
    color: contentValue,
    alpha: 100,
  };
};

export const ColorSettings = ({ selectedContent, title, property, defaultValue, hasTokenizedColors = true }: Props) => {
  const { onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;

  const isTokenApplied = selectedContent?.attrs?.tokens?.[property as keyof NavbarSerializableNode];

  const contentValue =
    selectedContent?.attrs && property in selectedContent.attrs
      ? (selectedContent.attrs as any)[property]
      : defaultValue;

  const onSetColor = (value: string | null) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { [property]: value });
  };

  const { color, alpha } = parseContentValue(contentValue);

  return (
    <Popover>
      <div className="flex items-center justify-stretch gap-2 select-none">
        <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
          {title}
        </Text>
        <PopoverTrigger asChild>
          <SimpleInputWrapper className="justify-start relative">
            {isTokenApplied && (
              <div className="p-0.5 border rounded-full absolute -left-3 -top-2 bg-wb-accent-soft text-wb-accent border-wb-accent">
                <CoinVertical className="w-2 h-2" />
              </div>
            )}
            <div className="w-4 h-4 rounded-md" style={{ backgroundColor: contentValue }} />
            <Text size="2xs" weight="medium" className="grow">
              {color.toUpperCase()}
            </Text>
            <Text size="2xs" weight="medium" className="">
              {alpha}%
            </Text>
          </SimpleInputWrapper>
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[280px] p-0" align="center" side="left" sideOffset={110}>
        <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
          <ColorPicker
            selectedColor={contentValue}
            onSetColor={(value: string | null) => {
              onSetColor(value);
            }}
          />
          {hasTokenizedColors && (
            <TokenizeColorSettings
              property={property}
              color={contentValue}
              selectedContent={selectedContent}
              onUpdateNodeAttributes={onUpdateNodeAttributes}
            />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
