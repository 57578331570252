import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Input } from '@/components/Form';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { useUser } from '@/hooks';
import { useDeletePublication, useOrganizationPublications } from '@/hooks/usePublications';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { Organization } from '@/interfaces/organization';
import { Publication } from '@/interfaces/publication';
// import TransferPublicationModal from '@/pages/Settings/Pages/Company/Publications/_components/TransferPublicationModal/TransferPublicationModal';
import { Button } from '@/ui/Button';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import { SHOW_CREATE_PUBLICATION_GET_PARAM } from '../../_layout';

interface Props {
  organization: Organization;
  publicationId: string;
}

const PublicationsList = ({ organization, publicationId }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const publicationsQuery = useOrganizationPublications({ organizationId: organization.id });
  const { data: publications } = publicationsQuery || {};
  const { currentUser } = useCurrentUser();
  const { data: settings } = usePublicationSettings(publicationId);
  const userQuery = useUser(publicationId, currentUser?.id);
  const { data: user } = userQuery;
  const [deletablePublication, setDeletablePublication] = useState<Publication | null>(null);
  const [confirmDeleteText, setConfirmDeleteText] = useState<string | null>(null);
  // const [isTransferringPublicationId, setIsTransferringPublicationId] = useState('');
  const cardId = 'publications-card';
  const isOrgOwner = user && organization && user?.id === organization?.owner_id;

  const isAdmin = () => {
    const isSysAdmin = settings?.system_admin;
    const isOrgAdmin = user?.roles.some(
      (role: any) => role.name === 'admin' && role.assignable_type === 'Organization'
    );

    return isSysAdmin || isOrgAdmin;
  };

  const isActionAvailable = isAdmin() && settings && settings?.max_publications > 1;

  const handleModalClose = () => {
    setDeletablePublication(null);
    setConfirmDeleteText(null);
  };

  const { mutate: deletePublicationMutation, isLoading: isDeleting } = useDeletePublication({
    onSuccess: handleModalClose,
  });

  const handleDeleteRequest = (publication: Publication) => {
    if (!deletablePublication) {
      setDeletablePublication(publication);
    }
  };

  const handleDeletePublication = () => {
    if (deletablePublication) {
      deletePublicationMutation(deletablePublication.id);
    }
  };

  const handleOpenCreatePublicationForm = () => {
    searchParams.set(SHOW_CREATE_PUBLICATION_GET_PARAM, 'true');
    setSearchParams(searchParams);
  };

  return (
    <BodyContainer>
      <CardHeader
        title="All Publications"
        cta={
          isActionAvailable ? (
            <Button variant="primary-inverse" size="xs" onClick={handleOpenCreatePublicationForm} Icon={PlusIcon}>
              New Publication
            </Button>
          ) : null
        }
      />
      <div className="flex flex-col gap-y-4">
        {publications?.map((publication: Publication) => (
          <div
            className="flex flex-row justify-between p-3 bg-surface-50 border border-surface-200 rounded-md"
            key={publication.id}
          >
            <div className="flex flex-row flex-grow gap-x-2">
              <div
                className={classNames(
                  'h-10 w-10 rounded overflow-hidden border bg-action-tertiary-50 rounded-sm grow-0 shrink-0'
                )}
              >
                <img className="h-full w-full object-cover" src={publication.logo.thumb.url} alt="" />
              </div>
              <TypographyStack>
                <Typography token="font-medium/text/sm">{publication.name}</Typography>
                <Typography
                  token="font-normal/text/sm"
                  colorWeight="600"
                  className={classNames(!!publication.description && 'italic')}
                >
                  {publication.description || 'No description'}
                </Typography>
              </TypographyStack>
            </div>
            <EllipsisDropdown
              portalMountedId={cardId}
              options={[
                {
                  label: 'View Site',
                  onClick: () => window.open(publication.url, '_blank'),
                },
                // TODO!: This feature is temporarily disabled
                // ...(isOrgOwner
                //   ? [
                //       {
                //         label: 'Transfer Ownership',
                //         onClick: () => setIsTransferringPublicationId(publication.id),
                //       },
                //     ]
                //   : []),
                ...(isOrgOwner && publications.length > 1
                  ? [
                      {
                        label: 'Delete',
                        onClick: () => handleDeleteRequest({ ...publication }),
                        isDestructive: true,
                      },
                    ]
                  : []),
              ]}
            />
          </div>
        ))}
      </div>
      <ActionModal
        isOpen={!!deletablePublication}
        isWorking={isDeleting}
        disabled={isDeleting || confirmDeleteText !== deletablePublication?.name}
        onClose={handleModalClose}
        onProceed={handleDeletePublication}
        resourceId={deletablePublication?.id || ''}
        modalMessageType="danger"
        headerText="Delete Publication"
        descriptionText="Deleting this publication will also delete any Subscribers, Segments, Metrics, and any other associated data."
        actionText="Delete"
        buttonType="danger"
        buttonShade="dark"
      >
        <div className="p-4">
          <Input
            name={`confirm-delete-text-input-${deletablePublication?.id}`}
            labelText="Type publication name to continue"
            placeholder="Publication name"
            onChange={(e) => setConfirmDeleteText(e.currentTarget.value)}
          />
        </div>
      </ActionModal>
      {/* <TransferPublicationModal
        isOpen={!!isTransferringPublicationId}
        publicationId={isTransferringPublicationId}
        onClose={() => setIsTransferringPublicationId('')}
      /> */}
    </BodyContainer>
  );
};

export default PublicationsList;
