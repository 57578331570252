import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { WidthHeightSettings } from '../general/WidthHeightSettings';

export function NavItemProfileSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (
    selectedContent.type !== 'navbar_item' ||
    selectedContent.attrs?.type !== 'user_button' ||
    selectedContent.attrs?.action !== 'profile'
  )
    return null;

  return (
    <SectionRenderer title="Profile">
      <WidthHeightSettings
        selectedContent={selectedContent}
        attribute="width"
        defaultFixedValue={32}
        options={{
          fixed: 'Fixed',
        }}
      />
      <WidthHeightSettings
        selectedContent={selectedContent}
        attribute="height"
        defaultFixedValue={32}
        options={{
          fixed: 'Fixed',
        }}
      />
    </SectionRenderer>
  );
}
