import { useState } from 'react';

import { Text } from '../../../../../UI/Text';
import TiersSelectionModal from '../../../../extensions/Pricing/modal/TiersSelectionModal';
import { AttributeSettingProps } from '../../../types';
import ActionRenderer from '../ActionRenderer';
import { ICON_OPTIONS } from '../BlockTypeSettings';
import Button from '../Button';

const PricingActions = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNode, activeNodeType, activeNodePos } = activeNodeResult;
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsSelectionModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsSelectionModalOpen(true);
  };

  const Icon = ICON_OPTIONS.pricing;

  return (
    <ActionRenderer nodeType="pricing" activeNodeType={activeNodeType}>
      <div className="flex items-center justify-stretch gap-2">
        <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
          Data
        </Text>

        <Button label="Tiers" icon={<Icon className="text-wb-secondary" weight="bold" />} onClick={handleOpenModal} />
      </div>
      {isSelectionModalOpen && activeNode && (
        <TiersSelectionModal
          key={activeNode.attrs.id}
          pos={activeNodePos}
          editor={editor}
          isOpen={isSelectionModalOpen}
          onClose={handleCloseModal}
          node={activeNode}
          setIsModalOpen={setIsSelectionModalOpen}
        />
      )}
    </ActionRenderer>
  );
};

export default PricingActions;
