import { useCallback, useMemo, useState } from 'react';
import { Empty, X } from '@phosphor-icons/react';
import debounce from 'lodash.debounce';

import { BottomIcon, BottomLeftIcon, BottomRightIcon, LeftIcon, LeftRightIcon, RightIcon, TopBottomIcon, TopIcon, TopLeftIcon, TopRightIcon } from '../../../Icons/BorderIcons';
import { Button } from '../../../UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { AttributeDropdown } from '../helpers/AttributeDropdown';
import BoxModelToggle from '../helpers/BoxModelToggle';
import { AttributeSettingProps } from '../types';
import { getTRBLValue } from '../utils/getTRBLValue';
import { parseCssValue } from '../utils/parseCssValue';

import { ColorSettings } from './ColorSettings';

type TRBL = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type BorderSettingsProps = AttributeSettingProps & {
  groupedProperties: {
    color: string;
    style: string;
    width: string;
    radius: string;
  }[];
};

const DEFAULT_COLOR = '#000000FF';

const getStringValue = (value: TRBL, unit: string) => {
  return `${value.top}${unit} ${value.right}${unit} ${value.bottom}${unit} ${value.left}${unit}`;
};

const DEFAULT_BORDER_WIDTH = '1px';

const DEFAULT_BORDER_RADIUS = '0px';

const BorderSettings = ({ editor, title = 'Border', activeNodeResult, groupedProperties }: BorderSettingsProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { radius, width } = useMemo(() => {
    const borderRadius = getTRBLValue(activeNodeAttributes[groupedProperties[0].radius] || DEFAULT_BORDER_RADIUS);
    const borderWidth = getTRBLValue(activeNodeAttributes[groupedProperties[0].width] || DEFAULT_BORDER_WIDTH);

    return {
      width: {
        top: parseCssValue(borderWidth.top).value,
        right: parseCssValue(borderWidth.right).value,
        bottom: parseCssValue(borderWidth.bottom).value,
        left: parseCssValue(borderWidth.left).value,
      },
      radius: {
        top: parseCssValue(borderRadius.top).value,
        right: parseCssValue(borderRadius.right).value,
        bottom: parseCssValue(borderRadius.bottom).value,
        left: parseCssValue(borderRadius.left).value,
      },
    };
  }, [activeNodeAttributes, groupedProperties]);

  const isNone = activeNodeAttributes[groupedProperties[0].style] === 'none';

  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, groupedProperties[0].style, 'none');
        tr.setNodeAttribute(activeNodePos, groupedProperties[0].color, DEFAULT_COLOR);
        tr.setNodeAttribute(activeNodePos, groupedProperties[0].width, DEFAULT_BORDER_WIDTH);
        tr.setNodeAttribute(activeNodePos, groupedProperties[0].radius, DEFAULT_BORDER_RADIUS);
        return true;
      });
      setIsPopoverOpen(false);
    },
    [editor, activeNodePos, groupedProperties]
  );

  const handleTRBLChange = useCallback(
    (propertyName: string, defaultValues: string) =>
      (position: 'top' | 'right' | 'bottom' | 'left' | 'all', value: number) => {
        const updateTRBL = () => {
          const currentTRBL = getTRBLValue(activeNodeAttributes[propertyName] || defaultValues);
          let updatedTRBL = {
            top: parseCssValue(currentTRBL.top).value,
            right: parseCssValue(currentTRBL.right).value,
            bottom: parseCssValue(currentTRBL.bottom).value,
            left: parseCssValue(currentTRBL.left).value,
          };

          if (position === 'all') {
            updatedTRBL = { top: value, right: value, bottom: value, left: value };
          } else {
            updatedTRBL[position] = value;
          }

          if (propertyName.includes('Width')) {
            groupedProperties.forEach((property) => {
              editor.commands.command(({ tr }) => {
                tr.setNodeAttribute(activeNodePos, property.width, getStringValue(updatedTRBL, 'px'));
                return true;
              });
            });
          } else if (propertyName.includes('Radius')) {
            groupedProperties.forEach((property) => {
              editor.commands.command(({ tr }) => {
                tr.setNodeAttribute(activeNodePos, property.radius, getStringValue(updatedTRBL, 'px'));
                return true;
              });
            });
          }
        };
        debounce(updateTRBL, 50)();
      },
    [editor, activeNodePos, activeNodeAttributes, groupedProperties]
  );

  const handleColorChange = useCallback(
    (color: string | null) => {
      const updateColor = () => {
        groupedProperties.forEach((property) => {
          editor.commands.command(({ tr }) => {
            tr.setNodeAttribute(activeNodePos, property.color, color || DEFAULT_COLOR);
            return true;
          });
        });
      };
      debounce(updateColor, 50)();
    },
    [editor, activeNodePos, groupedProperties]
  );

  const handleUpdateBorderStyle = useCallback(
    (style: string) => {
      const updateStyle = () => {
        groupedProperties.forEach((property) => {
          editor.commands.command(({ tr }) => {
            tr.setNodeAttribute(activeNodePos, property.style, style);
            return true;
          });
        });
      };
      debounce(updateStyle, 50)();
    },
    [editor, activeNodePos, groupedProperties]
  );

  const dropdownOptions = useMemo(
    () => [
      {
        label: 'None',
        onSelect: () => handleUpdateBorderStyle('none'),
      },
      {
        label: 'Solid',
        onSelect: () => handleUpdateBorderStyle('solid'),
      },
      {
        label: 'Dashed',
        onSelect: () => handleUpdateBorderStyle('dashed'),
      },
      {
        label: 'Dotted',
        onSelect: () => handleUpdateBorderStyle('dotted'),
      },
    ],
    [handleUpdateBorderStyle]
  );

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(open) => {
        setIsPopoverOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2 select-none">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            {title}
          </Text>

          <div className="grow bg-wb-secondary rounded-lg shadow-sm">
            <div className="w-full justify-between flex items-center gap-2 p-2 cursor-pointer">
              <div className="flex items-center gap-1">
                {isNone ? (
                  <Empty className="text-wb-secondary" weight="bold" />
                ) : (
                  <div
                    className="w-4 h-4 rounded-md"
                    style={{ backgroundColor: activeNodeAttributes[groupedProperties[0].color] }}
                  />
                )}

                <Text size="2xs" weight="medium" className="capitalize">
                  {activeNodeAttributes[groupedProperties[0].style]}
                </Text>
              </div>
              {!isNone && (
                <Button
                  variant="ghost"
                  Icon={X}
                  iconClassName="text-wb-secondary w-3 h-3"
                  onClick={handleReset}
                  className="p-0"
                />
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[275px]" align="start" side="left" sideOffset={20}>
        <div className="max-h-[500px] overflow-y-auto flex flex-col gap-2">
          <Text size="sm" weight="semibold">
            {title}
          </Text>

          <ColorSettings
            editor={editor}
            title="Color"
            property={groupedProperties[0].color}
            activeNodeResult={activeNodeResult}
            onOverrideSetColor={handleColorChange}
          />
          <AttributeDropdown
            title="Style"
            defaultValue={activeNodeAttributes[groupedProperties[0].style]}
            options={dropdownOptions}
          />
          <BoxModelToggle
            title="Width"
            defaultValues={width}
            onUpdate={handleTRBLChange(groupedProperties[0].width, DEFAULT_BORDER_WIDTH)}
            suffixes={{
              top: <TopIcon />,
              right: <RightIcon />,
              bottom: <BottomIcon />,
              left: <LeftIcon />,
              topBottom: <TopBottomIcon />,
              leftRight: <LeftRightIcon />,
            }}
          />

          <BoxModelToggle
            title="Radius"
            defaultValues={radius}
            suffixes={{
              top: <TopLeftIcon />,
              right: <TopRightIcon />,
              bottom: <BottomRightIcon />,
              left: <BottomLeftIcon />,
            }}
            showCombinedValues={false}
            onUpdate={handleTRBLChange(groupedProperties[0].radius, DEFAULT_BORDER_RADIUS)}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default BorderSettings;
