import { FC } from 'react';
import { PostElement, TPostAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import NotConnected from '../../components/NotConnected';

export const PostView: FC<NodeViewProps> = (nodeViewProps) => {
  const { HTMLAttributes, node, editor, getPos } = nodeViewProps;
  const { onDragStart } = useReactNodeView();
  const { hasFakeData, postGroupType } = node.attrs;
  const isAuthorPage = postGroupType === 'author_page';
  const isTagPage = postGroupType === 'tag_page';
  const hideNotConnected = isAuthorPage || isTagPage;

  const handleOpenModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
  };

  return (
    <PostElement
      className="relative"
      element={{
        type: 'post',
        attrs: {
          ...(node.attrs as TPostAttributes),
        },
      }}
      attributes={{
        'data-node-view-wrapper': '',
        ...HTMLAttributes,
      }}
      onDragStart={onDragStart}
    >
      {hasFakeData && !hideNotConnected && (
        <NotConnected text="Not connected to real posts" onClick={handleOpenModal} />
      )}
    </PostElement>
  );
};
