import { Attribute } from '@tiptap/core';

export const getBooleanAttribute = (
  attributeString: string,
  attributeDataString: string,
  defaultValue: boolean
): Attribute => {
  return {
    default: defaultValue,
    parseHTML: (element) => {
      if (typeof element.getAttribute(attributeDataString) === 'boolean') {
        return element.getAttribute(attributeDataString) === 'true';
      }

      return defaultValue;
    },
    renderHTML: (attributes) => ({ [attributeDataString]: attributes[attributeString] }),
  };
};

export const getStringAttribute = (
  attributeString: string,
  attributeDataString: string,
  defaultValue?: string | null,
  parsedValueFallback?: string | null
): Attribute => {
  return {
    default: defaultValue,
    ...(parsedValueFallback !== undefined
      ? {
          parseHTML: (element) => element.getAttribute(attributeDataString) || parsedValueFallback,
        }
      : {
          parseHTML: (element) => element.getAttribute(attributeDataString),
        }),
    renderHTML: (attributes) => ({ [attributeDataString]: attributes[attributeString] }),
  };
};

export const getIntegerAttribute = (
  attributeString: string,
  attributeDataString: string,
  defaultValue: number
): Attribute => {
  return {
    default: defaultValue,
    parseHTML: (element) => {
      const value = element.getAttribute(attributeDataString);
      return value ? parseInt(value, 10) : undefined;
    },
    renderHTML: (attributes) => ({ [attributeDataString]: attributes[attributeString].toString() }),
  };
};

export const getFloatAttribute = (
  attributeString: string,
  attributeDataString: string,
  defaultValue?: number
): Attribute => {
  return {
    default: defaultValue,
    parseHTML: (element) => {
      const value = element.getAttribute(attributeDataString);
      return value ? parseFloat(value) : undefined;
    },
    renderHTML: (attributes) => ({ [attributeDataString]: attributes[attributeString]?.toString() || '' }),
  };
};

export const getJSONAttribute = (
  attributeString: string,
  attributeDataString: string,
  defaultValue: any
): Attribute => {
  return {
    default: defaultValue,
    parseHTML: (element) => JSON.parse(element.getAttribute(attributeDataString) || '{}'),
    renderHTML: (attributes) => ({ [attributeDataString]: JSON.stringify(attributes[attributeString]) }),
  };
};
