import { useState } from 'react';
import toast from 'react-hot-toast';

import { useCreatePage } from '@/hooks/usePages';

import { Input } from '../UI/Input';
import Modal from '../UI/Modal';

import AddablePageHelper from './AddablePageHelper';

const AddablePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('Untitled');
  const [slug, setSlug] = useState('');

  const createPage = useCreatePage({
    routesType: 'custom',
  });

  const createPageMutation = (parentPath: string[]) => {
    createPage.mutate({ parentPath, slug, name });
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setName('Untitled');
    setSlug('');
  };

  const handleSubmit = () => {
    if (!name) {
      return toast.error('Name is required');
    }

    if (!slug) {
      return toast.error('Slug is required');
    }

    createPageMutation([]);
    handleClose();
    return true;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <AddablePageHelper text="New Page" tooltipText="Create New Page" onClick={() => setIsModalOpen(true)} />
      <Modal
        isOpen={isModalOpen}
        onClose={handleClose}
        title="Create New Page"
        onConfirm={handleSubmit}
        ctaText="Create"
        ctaVariant="primary"
        isLoadingText="Creating..."
        isLoading={createPage.isLoading}
      >
        <Input
          name="name"
          labelText="Name"
          placeholder="New Page"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          name="slug"
          labelText="Slug / URL"
          placeholder="/new-page"
          value={slug}
          required
          onChange={(e) => {
            setSlug(e.target.value.toLowerCase().replace(/[^a-z0-9-]/g, '-'));
          }}
          onKeyDown={handleKeyDown}
        />
      </Modal>
    </>
  );
};

export default AddablePage;
