import { useLocation, useNavigate } from 'react-router-dom';

import { useSettings } from '@/context/settings-context';
import TabNavigation from '@/ui/TabNavigation';

const AdNetworkTabNavigation = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { settings } = useSettings();

  const tabs = [
    {
      name: 'overview',
      label: 'Overview',
      selected: pathname === `/monetize/ads`,
      onSelect: () => navigate(`/monetize/ads`),
    },
    {
      name: 'reports',
      label: 'Reports',
      selected: pathname === `/monetize/ads/reports`,
      onSelect: () => navigate(`/monetize/ads/reports`),
    },
  ];

  if (!settings || settings?.sponsor_network) return null;

  return <TabNavigation tabs={tabs} className="mt-6" variant="tertiary" />;
};

export default AdNetworkTabNavigation;
