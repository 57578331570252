import { useMemo } from 'react';
import {
  AlignBottom,
  AlignCenterHorizontal,
  AlignCenterVertical,
  AlignLeft,
  AlignRight,
  AlignTop,
} from '@phosphor-icons/react';

import DisabledSetting from '../../helpers/DisabledSetting';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { BackgroundSettings } from '../BackgroundSettings';
import BorderSettings from '../BorderSettings';
import { DirectionSettings } from '../DirectionSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';
import { SizingSettings } from '../SizingSettings';
import { VerticalAlignmentSettings } from '../VerticalAlignmentSettings';

import { FlexBasisSettings } from './FlexBasisSettings';
import { HeightSettings } from './HeightSettings';
import { JustifyContentSettings } from './JustifyContentSettings';

export const ContainerSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;
  const isRow = activeNodeAttributes?.flexDirection === 'row';

  const isParentNodeColumns = useMemo(() => {
    if (!editor || !editor.isFocused) return false;

    try {
      const { parent } = editor.state.doc.resolve(activeNodePos);
      return parent?.type.name === 'columns';
    } catch (error) {
      return false;
    }
  }, [editor, activeNodePos]);

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['container']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Background">
        <BackgroundSettings editor={editor} activeNodeResult={activeNodeResult} />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
      </SectionRenderer>

      <SectionRenderer title="Layout">
        <DirectionSettings
          title="Direction"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="flexDirection"
          values={['row', 'column']}
        />
        {isRow && (
          <JustifyContentSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="X-Align"
            property="verticalAlign"
          />
        )}
        <AlignmentSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title={isRow ? 'Y-Align' : 'X-Align'}
          property="horizontalAlign"
          icons={{
            left: isRow ? AlignTop : AlignLeft,
            center: isRow ? AlignCenterVertical : AlignCenterHorizontal,
            right: isRow ? AlignBottom : AlignRight,
          }}
        />
        {!isRow && (
          <VerticalAlignmentSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Y-Align"
            icons={{
              left: isRow ? AlignLeft : AlignTop,
              center: isRow ? AlignCenterHorizontal : AlignCenterVertical,
              right: isRow ? AlignRight : AlignBottom,
            }}
          />
        )}
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} title="Text" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <SizingSettings editor={editor} />
        <DisabledSetting disabled={isParentNodeColumns} tooltip="Select columns block to update width">
          <FlexBasisSettings editor={editor} activeNodeResult={activeNodeResult} />
        </DisabledSetting>
        <HeightSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
