import { KeyboardEventHandler, useState } from 'react';
import { Plus } from '@phosphor-icons/react';
import { Price, PriceInterval, Tier } from '@shared/dream-components';

import SortableStringList from '@/components/SortableStringLIst';

import { Button } from '../../../../UI/Button';
import { Input } from '../../../../UI/Input';
import { Text } from '../../../../UI/Text';

interface Props {
  tier: Tier;
  price: Price;
  onChange: (changedTier: Tier) => void;
}

const labelByPriceInterval: Record<PriceInterval, string> = {
  [PriceInterval.MONTH]: 'Monthly Pricing Features',
  [PriceInterval.YEAR]: 'Yearly Pricing Features',
  [PriceInterval.ONE_TIME]: 'One Time Pricing Features',
  [PriceInterval.DONATION]: 'Features',
};

const FeaturesForm = ({ tier, price, onChange }: Props) => {
  const [feature, setFeature] = useState('');

  const handleAddNewFeature = () => {
    const trimmedFeature = feature.trim();
    if (!trimmedFeature) {
      return;
    }

    const changedTier = {
      ...tier,
      prices: tier.prices.map((_price) => ({
        ..._price,
        features:
          // eslint-disable-next-line no-nested-ternary
          _price.id === price.id
            ? Array.isArray(_price.features)
              ? [..._price.features, trimmedFeature]
              : [trimmedFeature]
            : _price.features,
      })),
    };

    onChange(changedTier);

    setFeature('');
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      handleAddNewFeature();
    }
  };

  const handleChangeInFeatures = (newFeatures: string[]) => {
    const changedTier = {
      ...tier,
      prices: tier.prices.map((_price) => ({
        ..._price,
        features: _price.id === price.id ? newFeatures : _price.features,
      })),
    };
    onChange(changedTier);
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-0.5">
        <Text size="md" variant="primary">
          {tier.prices.length > 0 && tier.id !== 'free' ? labelByPriceInterval[price.interval] : 'Features'}
        </Text>
        <Text size="sm" variant="secondary">
          Customize the benefits that will be displayed to the subscribers
        </Text>
      </div>
      <div className="flex flex-row gap-x-2 items-center w-full">
        <Input
          labelText=""
          name="benefit"
          placeholder="Benefit"
          className="w-9/12"
          value={feature}
          onChange={(e) => setFeature(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />

        <Button
          type="button"
          variant="outlined"
          LeftIcon={Plus}
          onClick={handleAddNewFeature}
          isDisabled={!setFeature}
          className="w-3/12"
        >
          Add feature
        </Button>
      </div>
      <SortableStringList
        id="monthly-benefits-list"
        stringList={price.features || []}
        onListChange={handleChangeInFeatures}
        variant="secondary"
      />
    </div>
  );
};

export default FeaturesForm;
