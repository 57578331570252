import { useQuery } from 'react-query';

import { ApplicationScores } from '@/interfaces/boosts/grow/application_scores';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import api from '../../../services/swarm';

const useApplicationScores = (publicationId?: string, isEnabled: boolean = true) => {
  const [currentApplicationId] = useCurrentPublicationState();
  const fetchApplicationScores = (): Promise<ApplicationScores> =>
    api
      .get(`/boosts/grow/application_scores`, {
        params: {
          boost_publication_id: publicationId,
          publication_id: currentApplicationId,
        },
      })
      .then((res) => res.data);

  return useQuery(
    [currentApplicationId, 'boosts', 'grow', publicationId, 'application_scores'],
    fetchApplicationScores,
    {
      enabled: isEnabled,
      staleTime: 600,
      keepPreviousData: true,
    }
  );
};

export default useApplicationScores;
