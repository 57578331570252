import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CaretRight, Check, Link as LinkIcon, LinkBreak, TextAa } from '@phosphor-icons/react';

import { useEditorStateNonBlocking } from '@/components/TiptapEditor/lib/hooks/useEditorStateNonBlocking';
import { cn } from '@/utils/cn';

import { getFontFamilies, getFontPreviewUrl } from '../../../../../_utils/getFonts';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../UI/Popover';
import { Search } from '../../../../UI/Search';
import { Text } from '../../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../../UI/ToggleGroup';
import { Tooltip } from '../../../../UI/Tooltip';
import { AttributeSettingProps } from '../../types';

const DEFAULT_FONT_FAMILY = 'Inter';

type FontSettingProps = AttributeSettingProps & {
  isMarkStyle?: boolean;
  title?: string;
  property?: string;
  properties?: {
    title: string;
    property: string;
  }[];
  onUnGroup?: () => void;
  isUngroupable?: boolean;
};

export const FontSettings = ({
  editor,
  activeNodeResult,
  isMarkStyle = true,
  title = 'Font',
  property = 'fontFamily',
  properties,
  onUnGroup,
  isUngroupable = true,
}: FontSettingProps) => {
  const { activeNodeType, activeNodeAttributes, activeNodePos } = activeNodeResult;
  const [fonts, setFonts] = useState<string[]>([]);
  const [query, setQuery] = useState('');
  const [group, setGroup] = useState<'all' | 'in-page'>('all');
  const [isOpen, setIsOpen] = useState(false);
  const inPageFonts = useEditorStateNonBlocking({
    editor,
    selector: ({ editor: e }) => e.storage.fontFamily.usedFonts,
  });
  const selectedFontRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const currentFont = isMarkStyle ? editor.getAttributes('textStyle').fontFamily : activeNodeAttributes?.[property];

  useEffect(() => {
    getFontFamilies().then((w) => setFonts(w));
  }, []);

  const filteredFonts = useMemo(() => {
    if (!query) return fonts;

    return fonts.filter((font) => font.toLowerCase().includes(query.toLowerCase()));
  }, [fonts, query]);

  const handleSetFontFamily = useCallback(
    (fontFamily: string) => {
      if (!isMarkStyle) {
        // used for FontGroupSettings
        if (properties) {
          properties.forEach((p) => {
            editor.commands.command(({ tr }) => {
              tr.setNodeAttribute(activeNodePos, p.property, fontFamily);
              return true;
            });
          });
        } else {
          editor.commands.command(({ tr }) => {
            tr.setNodeAttribute(activeNodePos, property, fontFamily);
            return true;
          });
        }
      }

      editor.chain().selectTextBlock().setFontFamily(fontFamily).focus().run();
    },
    [editor, activeNodePos, property, isMarkStyle, properties]
  );

  useEffect(() => {
    setTimeout(() => {
      if (selectedFontRef.current && scrollContainerRef.current) {
        selectedFontRef.current?.scrollIntoView({
          block: 'nearest',
        });
      }
    }, 0);
  }, [isOpen, currentFont, group]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <div className={cn('flex items-center justify-stretch gap-2')}>
        <div className="flex items-center gap-1">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            {title}
          </Text>
        </div>

        <div className="grow flex w-full gap-2 relative">
          {onUnGroup && (
            <button
              type="button"
              className={cn(
                'absolute -left-5 top-2.5 hover:cursor-pointer text-wb-secondary transition-all',
                isUngroupable && 'hover:text-wb-primary'
              )}
              onClick={onUnGroup}
            >
              {isUngroupable ? (
                <LinkBreak className="w-4 h-4" />
              ) : (
                <Tooltip center="Access granular font changes">
                  <LinkIcon className="w-4 h-4" />
                </Tooltip>
              )}
            </button>
          )}
          <PopoverTrigger asChild>
            <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer">
              <div className="w-full justify-between flex items-center gap-2 p-2 ">
                <div className="flex items-center gap-1">
                  <TextAa className="text-wb-secondary" weight="bold" />
                  <Text
                    size="2xs"
                    weight="medium"
                    className="whitespace-nowrap overflow-hidden overflow-ellipsis pr-2 max-w-[80px]"
                  >
                    {currentFont || DEFAULT_FONT_FAMILY}
                  </Text>
                </div>

                <CaretRight className="text-wb-secondary" weight="bold" />
              </div>
            </div>
          </PopoverTrigger>
        </div>
      </div>
      <PopoverContent className={cn('w-[255px] p-0')} side="left" sideOffset={110}>
        <div className={cn('max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4')}>
          <div className="flex items-center justify-between gap-2">
            <Text size="sm" weight="semibold">
              Font
            </Text>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className="flex flex-col gap-2">
              <div className="max-h-[400px] min-h-0 flex flex-col">
                <div className="grow bg-wb-secondary rounded-lg shadow-sm mb-3">
                  <ToggleGroup
                    className="p-[2px] grid grid-cols-2"
                    type="single"
                    defaultValue="left"
                    value={group}
                    onValueChange={(s) => setGroup(s as 'all' | 'in-page')}
                  >
                    <ToggleGroupItem value="all">All</ToggleGroupItem>
                    <ToggleGroupItem value="in-page">On this page</ToggleGroupItem>
                  </ToggleGroup>
                </div>

                {group === 'all' ? (
                  <>
                    <Search placeholder="Search font..." value={query} onChange={(e) => setQuery(e.target.value)} />

                    <div ref={scrollContainerRef} className="mt-2 grow min-h-0 overflow-y-scroll flex flex-col gap-2">
                      {filteredFonts.map((font) => (
                        <div
                          key={font}
                          ref={font === currentFont ? selectedFontRef : null}
                          className={`flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer border hover:bg-wb-secondary ${
                            font === currentFont
                              ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                              : 'bg-wb-primary border-transparent'
                          }`}
                          role="button"
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (!activeNodeType || !activeNodeAttributes) return;
                            if (e.key === 'Enter' || e.key === ' ') {
                              handleSetFontFamily(font);
                            }
                          }}
                          onClick={() => {
                            if (!activeNodeType || !activeNodeAttributes) return;
                            handleSetFontFamily(font);
                          }}
                        >
                          <img src={getFontPreviewUrl(font)} alt={font} height={12} className="h-[12px] w-auto" />
                          {font === currentFont && <Check weight="bold" className="text-wb-accent" />}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="grow min-h-0 overflow-y-scroll flex flex-col gap-2" ref={scrollContainerRef}>
                    {Object.entries(inPageFonts as Record<string, any>).map(([key]) => (
                      <div
                        key={key}
                        ref={key === currentFont ? selectedFontRef : null}
                        className={`flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer border hover:bg-wb-secondary ${
                          key === currentFont
                            ? 'bg-wb-button-accent-soft border-wb-accent-soft'
                            : 'bg-wb-primary border-transparent'
                        }`}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (!activeNodeType || !activeNodeAttributes) return;
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleSetFontFamily(key);
                          }
                        }}
                        onClick={() => {
                          if (!activeNodeType || !activeNodeAttributes) return;
                          handleSetFontFamily(key);
                        }}
                      >
                        <img src={getFontPreviewUrl(key)} alt={key} height={12} className="h-[12px] w-auto" />
                        {key === currentFont && <Check weight="bold" className="text-wb-accent" />}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
