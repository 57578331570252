import { useCallback } from 'react';
import cx from 'classnames';

import { TwIcon } from '../../../TiptapEditor/components/ui/Icon';
import { useMediaLibrary } from '../../context/MediaLibraryContext';

import { NavigationButtonProps } from './NavigationButton.types';

const NavigationButton = ({ tabId, libraryId, label, icon }: NavigationButtonProps) => {
  const { onTabChange, onLibraryChange, openTab, openLibraryId } = useMediaLibrary();

  const isActive = tabId === openTab && (!libraryId || libraryId === openLibraryId);

  const onClick = useCallback(() => {
    onTabChange(tabId);
    if (libraryId && onLibraryChange) {
      onLibraryChange(libraryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTabChange, onLibraryChange]);

  return (
    <button
      type="button"
      onClick={onClick}
      className={cx('flex px-2 py-1 text-xs border border-transparent rounded h-full items-center', {
        'text-surface-600': !isActive,
        'bg-white shadow text-surface-900': isActive,
      })}
    >
      {icon && (
        <div className="relative w-3 h-3 mr-1 mt-[.1rem]">
          <TwIcon name={icon} $display="block" className="!w-full !h-auto" />
        </div>
      )}
      <div>{label}</div>
    </button>
  );
};

export default NavigationButton;
