import { useEffect, useState } from 'react';

import { Typography } from '@/components/Typography';
import Switch from '@/ui/Switch';
import Tooltip from '@/ui/Tooltip';

import { TooltipProps } from '../../_types/misc';

interface Props {
  label: string;
  enabled: boolean;
  onSave: (value: any) => void;
  tooltip?: TooltipProps;
  name: string;
}

const SwitchInput = ({ label, enabled, onSave, tooltip, name }: Props) => {
  const [value, setValue] = useState(enabled);

  useEffect(() => {
    setValue(enabled);
  }, [enabled]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex space-x-1">
        <Typography token="font-normal/text/xs" colorWeight="500">
          {label}
        </Typography>
        {tooltip && <Tooltip id={tooltip.id} text={tooltip.text} iconClass="!text-surface-300" />}
      </div>
      <Switch
        variant="primary"
        size="small"
        name={name}
        checked={value}
        onChange={(_name: string, updatedValue: boolean) => {
          setValue(updatedValue);
          onSave(updatedValue);
        }}
      />
    </div>
  );
};

export default SwitchInput;
