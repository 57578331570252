import ActionModal from '@/components/ActionModal';
import { BoostAgreementFilter } from '@/interfaces/boosts/grow/boost_agreement_filter';

export interface Props {
  savedFilter?: BoostAgreementFilter;
  isWorking: boolean;
  onClose: () => void;
  onProceed: (filterId: string) => void;
}

const DeleteBoostAgreementFilterModal = ({ savedFilter, isWorking, onClose, onProceed }: Props) => {
  const isOpen = !!savedFilter;

  return (
    <ActionModal
      resourceId="delete-boost-agreemeent-filter"
      isOpen={isOpen}
      isWorking={isWorking}
      onClose={onClose}
      onProceed={() => onProceed(savedFilter?.id || '')}
      modalMessageType="danger"
      headerText="Delete saved filter"
      descriptionText="Are you sure you want to delete this filter?"
      actionText="Delete"
    />
  );
};

export default DeleteBoostAgreementFilterModal;
