import { Link, Outlet } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import { SideNavPortal } from '@/components/Layout/AppLayout/SideNav';
import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';

import AdBlockerWarning from './_components/AdBlockerWarning';
import StripeConnectMessage from './_components/StripeConnectMessage';

const MonetizeAdsLayout = () => {
  const { settings } = useSettings();

  return (
    <>
      {settings?.sponsor_network && (
        <SideNavPortal publicationSwitchPath="/monetize/ads">
          <div className="p-4 space-y-1">
            <Link to="/">
              <Button block type="button" align="left" variant="flush" Icon={ArrowLeftIcon}>
                Ads
              </Button>
            </Link>
            <Link to="/monetize/ads">
              <Button block type="button" align="left" variant="flush" className="mt-2 mb-1">
                Overview
              </Button>
            </Link>
            <Link to="/monetize/ads/reports">
              <Button block type="button" align="left" variant="flush">
                Reports
              </Button>
            </Link>
            <Link to="/monetize/ads/products">
              <Button block type="button" align="left" variant="flush">
                Products
              </Button>
            </Link>
          </div>
        </SideNavPortal>
      )}
      <StripeConnectMessage classes="mb-6" />
      <AdBlockerWarning>
        <Outlet />
      </AdBlockerWarning>
    </>
  );
};

export default MonetizeAdsLayout;
