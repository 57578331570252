interface Props {
  postGroupType: string;
}

const useShouldNotScrollHorizontally = ({ postGroupType }: Props) => {
  const isArchivePost = postGroupType === 'archive';
  const isTagPost = postGroupType === 'tag_page';
  const isAuthorPost = postGroupType === 'author_page';

  return isArchivePost || isTagPost || isAuthorPost;
};

export default useShouldNotScrollHorizontally;
