import { memo, PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import { autoUpdate, offset, Placement, shift, useFloating, useHover, useInteractions } from '@floating-ui/react';
import { Lightbulb } from '@phosphor-icons/react';
import cx from 'classnames';

export const Tooltip = memo(
  ({
    center,
    right,
    children,
    placement = 'bottom',
    className,
    delay = 0,
    tooltipClassName,
    onLearnMore,
  }: PropsWithChildren<{
    center: any;
    right?: any;
    placement?: Placement;
    className?: HTMLElement['className'];
    delay?: number;
    tooltipClassName?: HTMLElement['className'];
    onLearnMore?: () => void;
  }>) => {
    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
      placement,
      middleware: [shift(), offset(5)],
      open: isOpen,
      onOpenChange: setIsOpen,
      whileElementsMounted: autoUpdate,
    });

    const hover = useHover(context, {
      delay,
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    if (!center && !right) {
      return <>{children}</>;
    }

    return (
      <>
        <div ref={refs.setReference} className={className} {...getReferenceProps()}>
          {children}
        </div>
        {isOpen &&
          createPortal(
            <div
              className={cx(
                'bg-wb-inverse shadow-wb-md border border-wb-button flex text-[11px] items-center justify-between w-max h-fit text-wb-invert-primary font-medium tracking-tight px-2.5 py-0.5 rounded-[5px] border-solid z-50',
                tooltipClassName
              )}
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <div className="w-max text-center">{center}</div>
              {onLearnMore && (
                <button type="button" className="ml-2" onClick={onLearnMore}>
                  <Lightbulb weight="bold" className="my-auto text-wb-accent h-4 w-4" />
                </button>
              )}
              {right && (
                <>
                  <span className="text-wb-secondary ml-[5px]">•</span>
                  <div className="w-max ml-[5px]">{right}</div>
                </>
              )}
            </div>,
            document.body
          )}
      </>
    );
  }
);

interface LearnMoreTooltipProps extends PropsWithChildren {
  learnMoreType: 'placeholder_data' | 'data';
}

export const LearnMoreTooltip = ({ children, learnMoreType }: LearnMoreTooltipProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const hasTypeOpen = searchParams.get('learn_more') === learnMoreType;

  const handleOpenLearnMorePanel = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (hasTypeOpen) {
      searchParams.delete('learn_more');
      setSearchParams(searchParams);
    } else {
      searchParams.set('learn_more', learnMoreType);
      setSearchParams(searchParams);
    }
  };

  return (
    <button type="button" className="h-4 w-4" onClick={handleOpenLearnMorePanel}>
      <Tooltip center="Placeholder Data Present" className="cursor-pointer h-4" tooltipClassName="group">
        {children}
      </Tooltip>
    </button>
  );
};
