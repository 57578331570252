import { HardDrive } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { FontSizeSettings } from '../FontSizeSettings';
import { FontWeightSettings } from '../FontWeightSettings';
import { IconSettings } from '../IconSettings';
import { IconSideSettings } from '../IconSideSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

type Props = AttributeSettingProps & {};

export const ButtonSettings = ({ editor, activeNodeResult }: Props) => {
  return (
    <PopoverHelper title="Button" text="Button" popoverTitle="Button" Icon={HardDrive} align="end">
      <>
        <ColorSettings
          title="Background"
          property="buttonBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'buttonBorderColor',
            style: 'buttonBorderStyle',
            width: 'buttonBorderWidth',
            radius: 'buttonBorderRadius',
          }}
        />
        <ColorSettings editor={editor} activeNodeResult={activeNodeResult} property="buttonTextColor" title="Text" />
        <FontSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isMarkStyle={false}
          title="Font"
          property="buttonFontFamily"
        />
        <FontWeightSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="buttonFontWeight"
          title="Font Weight"
          isMarkStyle={false}
        />
        <FontSizeSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="buttonFontSize"
          title="Font Size"
          isMarkStyle={false}
        />
        <SimpleTextSettings editor={editor} activeNodeResult={activeNodeResult} property="buttonText" title="Text" />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="buttonSubmittingText"
          title="Submitting"
        />
        <IconSettings property="buttonIcon" editor={editor} activeNodeResult={activeNodeResult} />
        <IconSideSettings
          property="buttonIconSide"
          editor={editor}
          activeNodeResult={activeNodeResult}
          disabled={!activeNodeResult?.activeNodeAttributes?.buttonIcon}
        />
        <ColorSettings
          title="Icon Color"
          property="buttonIconColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
          disabled={!activeNodeResult?.activeNodeAttributes?.buttonIcon}
        />
      </>
    </PopoverHelper>
  );
};

export default ButtonSettings;
