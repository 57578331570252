import { useEffect, useMemo, useState } from 'react';

import { useCreateSite } from '@/hooks/useSite';
import useSitePackages from '@/hooks/useSitePackages/useSitePackages';
import { useSiteThemes } from '@/hooks/useSiteThemes';
import { SitePackage } from '@/interfaces/site_package';
import { SiteTemplate } from '@/interfaces/site_template';
import { WebTheme } from '@/interfaces/web_theme';

import { previewThemeApplier } from '../_components/DreamEditor/utils/previewThemeApplier';
import { getFontFamilies } from '../_utils/getFonts';

import Step1 from './_components/Step1';
import Step2 from './_components/Step2';
import Step3 from './_components/Step3';
// import Step4 from './_components/Step4';
import TopNav from './_components/TopNav';
import { useFontLoader } from './_components/useFontLoader';

type FontPairing = {
  title: string;
  header: string;
  body: string;
};

const DEFAULT_FONT_PAIRINGS: FontPairing[] = [
  {
    title: 'Classical elegance',
    header: 'Cormorant',
    body: 'Raleway',
  },
  {
    title: 'Refined Contemporary',
    header: 'Libre Bodoni',
    body: 'Inter',
  },
  {
    title: 'Experimental',
    header: 'Aboreto',
    body: 'Work Sans',
  },
  {
    title: 'Friendly & Casual',
    header: 'Pacifico',
    body: 'Source Sans Pro',
  },
  {
    title: 'Industrial & Modern',
    header: 'Bebas Neue',
    body: 'Source Sans Pro',
  },
  {
    title: 'Corporate & Professional',
    header: 'IBM Plex',
    body: 'Nunito',
  },
  {
    title: 'Bold contemporary ',
    header: 'Montserrat',
    body: 'Raleway',
  },
  {
    title: 'Modern editorial',
    header: 'DM Serif',
    body: 'Roboto',
  },
];

interface Props {
  stepConfigOverride?: {};
  stepOneOverrideProps?: {};
  stepTwoOverrideProps?: {};
  stepThreeOverrideProps?: {};
  passUpState?: ({
    newSitePackageId,
    themeParams,
  }: {
    newSitePackageId: string;
    themeParams: Record<string, any>;
  }) => void;
}

const WebsiteOnboardingPage = ({
  stepConfigOverride,
  stepOneOverrideProps,
  stepTwoOverrideProps,
  stepThreeOverrideProps,
  passUpState,
}: Props) => {
  const { data: packages } = useSitePackages({ enabled: true, allPublic: true });
  const projects = packages?.pages.flatMap((pkg) => pkg.site_packages) || [];
  const [selectedProject, setSelectedProject] = useState<SitePackage | null>(null);

  const [color, setColor] = useState<string | null>(null);
  const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());
  const [currentStep, setCurrentStep] = useState<number>(1);

  const [style, setStyle] = useState<WebTheme | null>(null);
  // const [autoPopulate, setAutoPopulate] = useState<boolean>(false);

  const isStepOne = currentStep === 1;
  const isStepTwo = currentStep === 2;
  const isStepThree = currentStep === 3;
  // const isStepFour = currentStep === 4; ## TODO: DATA SWAPPER WORK

  useEffect(() => {
    getFontFamilies().then(() => {
      const usableFonts = [...DEFAULT_FONT_PAIRINGS].reduce((acc: string[], font) => {
        acc.push(font.header);
        acc.push(font.body);
        return acc;
      }, []);

      setFontHrefs(
        new Set(usableFonts.map((font) => `https://fonts.googleapis.com/css2?family=${encodeURI(font)}&display=swap`))
      );
    });
  }, []);

  useEffect(() => {
    if (passUpState) {
      passUpState({ newSitePackageId: selectedProject?.id || '', themeParams: style as WebTheme });
    }
  }, [selectedProject, style, passUpState]);

  const { data, isRefetching } = useSiteThemes();

  const siteThemes = data?.pages.flatMap((page) => page.site_themes) || [];
  const siteThemesLength = siteThemes.length;
  const themeRules = data?.pages?.[0]?.theme_rules;

  const { primaryTheme } = useMemo(() => {
    const theme = siteThemes.find((thme) => thme.is_primary);
    if (!color && selectedProject) {
      setColor(theme?.data?.primary_color || '');
    }
    return { primaryTheme: theme };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteThemesLength, isRefetching, color, selectedProject]);

  useEffect(() => {
    if (!primaryTheme) return;
    setStyle(primaryTheme?.data);
  }, [primaryTheme]);

  const { previewNavbar, previewContent, previewFooter } = useMemo(() => {
    const project = projects.find((pkg) => pkg.id === selectedProject?.id);
    const homePage = project?.templates.find((template: SiteTemplate) => template.name === 'Home');
    const homPageCopy = { ...homePage };

    return {
      previewNavbar: previewThemeApplier({
        type: 'navbar',
        content: selectedProject?.navbar,
        themeRules: themeRules || {},
        theme: style as WebTheme,
      }),
      previewContent: previewThemeApplier({
        type: 'tiptap',
        content: homPageCopy?.content,
        themeRules: themeRules || {},
        theme: style as WebTheme,
      }),
      previewFooter: previewThemeApplier({
        type: 'tiptap',
        content: selectedProject?.footer,
        themeRules: themeRules || {},
        theme: style as WebTheme,
      }),
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, primaryTheme, themeRules, style]);

  const createSite = useCreateSite({
    sitePackageId: selectedProject?.id || '',
    autoPopulate: false,
    theme: style as WebTheme,
  });

  const createSiteMutation = () => {
    createSite.mutate();
  };

  useFontLoader({ fontHrefs });

  return (
    <div className="w-screen h-screen flex flex-col overflow-scroll no-scrollbar bg-white">
      <TopNav
        step={currentStep}
        onPrevious={() => setCurrentStep(currentStep - 1)}
        stepConfig={
          stepConfigOverride || {
            1: {
              title: 'Build your website',
            },
            2: {
              title: 'Select a template',
            },
            3: {
              title: 'Design the basics',
            },
            4: {
              title: 'Pick your fonts',
            },
          }
        }
      />

      <div className="flex flex-col items-center justify-center px-8 py-8">
        <div className="mx-auto w-full max-w-6xl">
          {projects && isStepOne && (
            <Step1
              projects={projects}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              onNext={() => setCurrentStep(2)}
              isDisabled={!selectedProject}
              {...stepOneOverrideProps}
            />
          )}
          {isStepTwo && style && (
            <Step2
              previewNavbar={previewNavbar}
              previewContent={previewContent}
              previewFooter={previewFooter}
              style={style}
              setStyle={setStyle}
              onNext={() => setCurrentStep(3)}
              {...stepTwoOverrideProps}
            />
          )}
          {isStepThree && style && (
            <Step3
              previewNavbar={previewNavbar}
              previewContent={previewContent}
              previewFooter={previewFooter}
              style={style}
              setStyle={setStyle}
              onNext={createSiteMutation}
              isLoading={createSite.isLoading}
              {...stepThreeOverrideProps}
            />
          )}
          {/* {isStepFour && style && (
            <Step4 onNext={createSiteMutation} autoPopulate={autoPopulate} setAutoPopulate={setAutoPopulate} />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default WebsiteOnboardingPage;
