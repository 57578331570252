/* eslint-disable consistent-return */
import { Extension, getMarkType } from '@tiptap/core';

import { SiteTheme } from '@/interfaces/web_theme';

import { THEME_ATTRS } from '../../AttributesPanel/components/BlockSettings/ActionsSettings/consts';
import transformTokenOverrides from '../../utils/transformThemeOverrides';
import { getActiveNodeData } from '../ActiveNode/utils';

const BOUNDARY_NODE_SIZE = 1;

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    theme: {
      applyTheme: ({
        siteTheme,
        mapping,
      }: {
        siteTheme: SiteTheme;
        mapping: Record<string, Partial<Record<(typeof THEME_ATTRS)[number], string[]>>>;
      }) => ReturnType;
    };
  }
}

export const ThemeCommands = Extension.create({
  name: 'themeCommands',

  addCommands() {
    const { editor } = this;

    return {
      applyTheme:
        ({ siteTheme: { data: theme, id: themeId }, mapping }) =>
        ({ state, dispatch, tr }) => {
          const { activeNodePos, activeNode } = getActiveNodeData(editor);

          // Update activeNode attrs
          const activeNodeTypeMapping = mapping[activeNode.type.name];

          if (activeNode.type.name !== 'doc' && activeNodeTypeMapping) {
            const activeNodeAttrs = Object.keys(activeNodeTypeMapping) as Array<keyof typeof activeNodeTypeMapping>;

            const updatedActiveNodeAttrs: Record<string, string> = {
              appliedThemeId: themeId,
            };

            activeNodeAttrs.forEach((attrKey) => {
              if (!Object.hasOwn(theme, attrKey)) {
                return;
              }

              const themeValue = theme[attrKey];

              const attrsToUpdate = activeNodeTypeMapping[attrKey] as string[];

              attrsToUpdate.forEach((attr) => {
                updatedActiveNodeAttrs[attr] = themeValue;
              });
            });

            tr.setNodeMarkup(activeNodePos, null, {
              ...activeNode.attrs,
              ...updatedActiveNodeAttrs,
            });
          }

          // Update activeNode's descendants' attrs based on scope
          activeNode.descendants((node, pos, parentNode) => {
            let adjustedPos = activeNodePos + pos;

            if (activeNode.type.name !== 'doc') {
              adjustedPos += BOUNDARY_NODE_SIZE;
            }

            let nodeThemeMetaData = mapping[node.type.name];

            if (!nodeThemeMetaData) {
              return;
            }

            if (node.attrs?.tokens) {
              nodeThemeMetaData = transformTokenOverrides(node.attrs?.tokens || {}, nodeThemeMetaData);
            }

            const themeAttrs = Object.keys(nodeThemeMetaData) as Array<keyof typeof nodeThemeMetaData>;

            const updatedAttrs: Record<string, string> = {};

            themeAttrs.forEach((nodeThemeMetaDataKey) => {
              if (!Object.hasOwn(theme, nodeThemeMetaDataKey)) {
                return;
              }
              const themeValue = theme[nodeThemeMetaDataKey];

              const attrsToUpdate = nodeThemeMetaData[nodeThemeMetaDataKey] as string[];

              attrsToUpdate.forEach((attr) => {
                updatedAttrs[attr] = themeValue;
              });
            });

            // For nodes that have text nodes as children we want to return early here so it does not appply the color as a mark
            // We dont offere the ability for the users to update the mark on the button, just the button color attribute
            if (node.type.name === 'button') {
              const newAttrs = {
                ...node.attrs,
                ...updatedAttrs,
                appliedThemeId: themeId,
              };

              tr.setNodeMarkup(adjustedPos, null, newAttrs);

              return;
            }

            if (node.type.name === 'text' && parentNode?.type.name === 'button') {
              const textStyleMark = node.marks.find((mark) => mark.type.name === 'textStyle');

              if (textStyleMark) {
                tr.removeMark(adjustedPos, adjustedPos + node.nodeSize, getMarkType('textStyle', editor.schema));
              }

              return;
            }

            if (node.type.name === 'text') {
              const textStyleMark = node.marks.find((mark) => mark.type.name === 'textStyle');
              const overrideColor = textStyleMark?.attrs?.tokens?.color;
              const overrides = overrideColor ? { color: theme[overrideColor as keyof SiteTheme['data']] } : {};

              const updatedTextStyleMark = state.schema.mark('textStyle', {
                ...(textStyleMark?.attrs || {}),
                ...updatedAttrs,
                ...overrides,
                appliedThemeId: themeId,
              });

              tr.addMark(adjustedPos, adjustedPos + node.nodeSize, updatedTextStyleMark);
            } else {
              const newAttrs = {
                ...node.attrs,
                ...updatedAttrs,
                appliedThemeId: themeId,
              };

              tr.setNodeMarkup(adjustedPos, null, newAttrs);
            }
          });

          if (tr.docChanged && dispatch) {
            dispatch(tr);
            return true;
          }

          return false;
        },
    };
  },
});
