import { useCallback } from 'react';

import { useEditorStateNonBlocking } from '@/components/TiptapEditor/lib/hooks/useEditorStateNonBlocking';

import { Accordion, AccordionContent, AccordionTitle, Paragraph } from '../../../extensions';
import { AttributeSettingProps } from '../../types';
import { UnitlessNumberSetting } from '../UnitlessNumberSetting';

const ACCORDION_JSON = {
  type: Accordion.name,
  attrs: {},
  content: [
    {
      type: AccordionTitle.name,
      attrs: {},
      content: [
        {
          type: Paragraph.name,
          content: [
            {
              type: 'text',
              text: 'Title',
            },
          ],
        },
      ],
    },
    {
      type: AccordionContent.name,
      attrs: {},
      content: [
        {
          type: Paragraph.name,
          content: [
            {
              type: 'text',
              text: 'Content',
            },
          ],
        },
      ],
    },
  ],
};

const StructureSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const accordionGroup = useEditorStateNonBlocking({
    editor,
    selector: () => {
      const $activeNodePos = editor.state.doc.resolve(activeNodeResult.activeNodePos);

      const accordionIndexInSection = $activeNodePos.index();

      const section = $activeNodePos.parent;

      const accordionGroupsInSection: { startIndex: number; endIndex: number }[] = [];

      let groupIndex = 0;

      section.forEach((node, _offset, index) => {
        if (node.type.name === 'accordion') {
          if (!accordionGroupsInSection[groupIndex]) {
            accordionGroupsInSection[groupIndex] = {
              startIndex: index,
              endIndex: index,
            };
          } else {
            accordionGroupsInSection[groupIndex].endIndex = index;
          }
        } else if (accordionGroupsInSection[groupIndex]) {
          groupIndex += 1;
        }
      });

      const groupOfActiveAccordionNode = accordionGroupsInSection.find(
        (group) => group.startIndex <= accordionIndexInSection && accordionIndexInSection <= group.endIndex
      );

      return groupOfActiveAccordionNode!;
    },
  });

  const addAccordionItem = useCallback(() => {
    const { endIndex } = accordionGroup;

    const $activeNodePos = editor.state.doc.resolve(activeNodeResult.activeNodePos);
    const endNodePos = $activeNodePos.posAtIndex(endIndex);
    const endNode = $activeNodePos.parent.child(endIndex);
    const to = endNodePos + endNode.nodeSize;

    editor.chain().insertContentAt(to, ACCORDION_JSON).run();

    editor.chain().setNodeSelection(to).focus().run();
  }, [accordionGroup, activeNodeResult.activeNodePos, editor]);

  const removeAccordionItem = useCallback(() => {
    const { endIndex } = accordionGroup;

    const $activeNodePos = editor.state.doc.resolve(activeNodeResult.activeNodePos);

    const endNodePos = $activeNodePos.posAtIndex(endIndex);
    const endNode = $activeNodePos.parent.child(endIndex);

    const to = endNodePos + endNode.nodeSize;

    const pasAtNewEndIndex = $activeNodePos.posAtIndex(endIndex + -1);

    editor.chain().deleteRange({ from: endNodePos, to }).setNodeSelection(pasAtNewEndIndex).focus().run();
  }, [activeNodeResult.activeNodePos, accordionGroup, editor]);

  return accordionGroup ? (
    <UnitlessNumberSetting
      editor={editor}
      activeNodeResult={activeNodeResult}
      title="Items"
      onIncrese={addAccordionItem}
      onDecrease={removeAccordionItem}
      value={accordionGroup.endIndex - accordionGroup.startIndex + 1}
    />
  ) : null;
};

export { StructureSettings };
