import { useCallback } from 'react';
import { useEditorState } from '@tiptap/react';

import { Length, parseLength } from '../../../UI/ScalarUnitInput';
import { ActiveNodeResult } from '../../extensions/ActiveNode/types';
import { AttributeSettingProps } from '../types';

import { SimpleLengthSettings } from './SimpleLengthSettings';

type FontSizeSettingsProps = AttributeSettingProps & {
  isMarkStyle?: boolean;
  title?: string;
  breakpoint?: 'mobile' | 'desktop';
};

const getFallbackValue = (activeNodeResult: ActiveNodeResult) => {
  if (!activeNodeResult) return '0px';

  const { activeNode } = activeNodeResult;
  if (!activeNode) return '0px';

  if (activeNode?.type?.name === 'heading') {
    if (!activeNode.attrs.level) return '16px';

    switch (activeNode.attrs.level) {
      case 1:
        return '32px';
      case 2:
        return '28px';
      case 3:
        return '24px';
      case 4:
        return '22px';
      case 5:
        return '20px';
      case 6:
        return '16px';
      default:
        return '16px';
    }
  }

  if (activeNode?.type?.name === 'paragraph') {
    return '16px';
  }

  return '16px';
};

export const FontSizeSettings = ({
  editor,
  activeNodeResult,
  property,
  isMarkStyle = true,
  breakpoint = 'desktop',
  title,
}: FontSizeSettingsProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;

  const { currentFontSize } = useEditorState({
    editor,
    selector: (state) => {
      if (isMarkStyle) {
        const { mobileFontSize, fontSize } = state.editor.getAttributes('textStyle');

        return {
          currentFontSize: breakpoint === 'mobile' ? mobileFontSize : fontSize || getFallbackValue(activeNodeResult),
        };
      }
      if (property) {
        const fontSize = activeNodeAttributes[property];
        return {
          currentFontSize: fontSize || getFallbackValue(activeNodeResult),
        };
      }
      return {
        currentFontSize: getFallbackValue(activeNodeResult),
      };
    },
  });

  const onChange = useCallback(
    (length: Length) => {
      if (isMarkStyle) {
        editor.chain().selectTextBlock().setFontSize(`${length.value}${length.unit}`, breakpoint).run();
      } else if (property) {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, property, `${length.value}${length.unit}`);
          return true;
        });
      }
    },
    [editor, activeNodePos, property, isMarkStyle, breakpoint]
  );

  return (
    <SimpleLengthSettings
      key={`${activeNodePos}-font-size`}
      title={title || 'Font Size'}
      value={parseLength(currentFontSize)}
      onChange={onChange}
      allowDecimal
    />
  );
};
