import { CheckIcon } from '@heroicons/react/24/outline';

import { RadioOption } from '@/components/Form/RadioSelect';
import { TypographyRow, TypographyStack } from '@/components/Typography';
import { Badge } from '@/ui/Badge';
import Tooltip from '@/ui/Tooltip';

import FormBlock from './FormBlock';

interface Props {
  checked: boolean;
  option: RadioOption;
}

const CustomOptionNode = ({ checked, option }: Props) => {
  return (
    <div className="cursor-pointer">
      <FormBlock isLight={!checked}>
        {option.customProps?.isRecommended ? (
          <Badge type="info_blue" className="w-fit">
            Recommended
          </Badge>
        ) : null}
        <div className="flex flex-row gap-x-4 items-center justify-between">
          <TypographyStack>
            <TypographyRow>
              {option.name}
              <Tooltip
                id={`custom-option-node-tooltip-${option.value}`}
                isEnabled={!!option.customProps?.tooltip}
                text={option.customProps?.tooltip}
              />
            </TypographyRow>
            {option.description}
          </TypographyStack>
          {checked ? (
            <div className="grow-0 shrink-0 h-5 w-5 flex items-center justify-center border border-action-secondary-700 rounded-md bg-action-secondary-700">
              <CheckIcon className="h-3 w-3 text-white" />
            </div>
          ) : (
            <div className="grow-0 shrink-0 h-5 w-5 border border-surface-200 rounded-md">&nbsp;</div>
          )}
        </div>
        {checked ? option.children : null}
      </FormBlock>
    </div>
  );
};

export default CustomOptionNode;
