import { useMemo } from 'react';

import { useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';
import { Page } from '@/interfaces/dream_builder/page';
import { PageRoute } from '@/interfaces/dream_builder/page_route';

interface Route {
  id: string;
  slug: string;
  fullSlug: string;
  name: string;
  page: Page | null;
}

const useResolvedRoutes = (): Route[] => {
  const { defaultRoutes, pagesRoutes } = useWebsiteContext();
  const pageRouteGetter = useWebsitePageRouteGetter();

  const routes = useMemo(() => {
    const parseRoutes = (routeData: PageRoute | undefined, parentSlugs: string[]): Route[] => {
      if (!routeData?.children_keys || !routeData?.children) {
        return [];
      }

      const directChildren = routeData.children_keys
        .map((key) => {
          const child = routeData.children?.[key];
          if (!child) {
            return null;
          }

          const page = pageRouteGetter?.getPageFromID(child.page_id);

          return {
            id: child.page_id,
            slug: key,
            fullSlug: [...parentSlugs, key].join('/'),
            name: page?.draft_page_version?.name || '',
            page: page || null, // Ensure null if page is undefined
          };
        })
        .filter((route): route is Route => route !== null); // Type guard for filtering

      const grandChildren = routeData.children_keys
        .map((key) => {
          const child = routeData.children?.[key];
          if (!child) {
            return null;
          }

          return parseRoutes(child, [...parentSlugs, key]);
        })
        .flat()
        .filter((route): route is Route => route !== null); // Type guard for filtering

      return [...directChildren, ...grandChildren];
    };

    const defaultParsedRoutes = parseRoutes(defaultRoutes, []);
    const pagesParsedRoutes = parseRoutes(pagesRoutes, []);

    return [...defaultParsedRoutes, ...pagesParsedRoutes];
  }, [defaultRoutes, pagesRoutes, pageRouteGetter]);

  return routes;
};

export default useResolvedRoutes;
