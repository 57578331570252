import { Node } from '@tiptap/pm/model';

type ImageDimensions = {
  width: number;
  height: number;
  aspectRatio: number;
};

// will load the image and grab the original aspect ratio
export const getOriginalAspectRatio = async (node: Node): Promise<ImageDimensions> => {
  const { height, width, aspect_ratio: aspectRatio } = node.attrs;

  if (aspectRatio && typeof aspectRatio === 'number' && height && width) return { width, height, aspectRatio };

  // load image and then get the original aspect ratio from loaded image
  const image = new Image();
  image.src = node.attrs.src;
  return new Promise<ImageDimensions>((resolve) => {
    image.onload = () => {
      const ap = image.width / image.height;
      resolve({ width: image.width, height: image.height, aspectRatio: ap });
    };
    image.onerror = () => {
      resolve({ width: 0, height: 0, aspectRatio: 1 });
    };
  });
};
