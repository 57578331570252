import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostAgreementFilter, BoostAgreementFilterData } from '@/interfaces/boosts/grow/boost_agreement_filter';
import api from '@/services/swarm';

interface Variables {
  autoAcceptBoostOfferId?: string;
  id: string;
  name?: string;
  autoAcceptActive?: boolean;
  filters?: BoostAgreementFilterData;
}

interface Props {
  onSuccess?: (boostAgreementFilter: BoostAgreementFilter, variables: any) => void;
}

const useUpdateBoostAgreementFilter = ({ onSuccess }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const updateBoostAgreementFilter = async ({ id, ...data }: Variables): Promise<BoostAgreementFilter> =>
    api
      .patch(`/boosts/grow/boost_agreement_filters/${id}`, {
        auto_accept_boost_offer_id: data.autoAcceptBoostOfferId,
        boost_agreement_filter: {
          name: data.name,
          auto_accept_active: data.autoAcceptActive || false,
          filters: data.filters,
        },
      })
      .then((res) => res.data);

  return useMutation(updateBoostAgreementFilter, {
    onSuccess: (boostAgreementFilter, variables) => {
      queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'boost_agreement_filters']);

      onSuccess?.(boostAgreementFilter, variables);
    },
    onError: () => {
      toast.error('Something went wrong updating your filter');
    },
  });
};

export default useUpdateBoostAgreementFilter;
