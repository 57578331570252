export enum OnboardingTypes {
  LOOKING = 'looking',
  STARTING = 'starting',
  MIGRATING = 'migrating',
}

export enum OnboardingStatus {
  WELCOME = 'welcome',
  PREVIOUS_PLATFORM = 'previous_platform',
  PAYING_SUBS = 'paying_subs',
  CHOOSE_TO_IMPORT = 'choose_to_import',
  IMPORT_SUBSCRIBERS = 'import_subscribers',
  IMPORT_CONTENT = 'import_content',
  CHOOSE_PUBLICATION_NAME = 'choose_publication_name',
  CHOOSE_SUBDOMAIN = 'choose_subdomain',
  CHOOSE_TAGS = 'choose_tags',
  UPLOAD_LOGO = 'upload_logo',
  WEB_THEME = 'web_theme',
  VERIFICATION = 'verification',
  COMPLETE = 'complete',
  START_TRIAL = 'start_trial',
}

export enum OnboardingAttributionSource {
  AFFILIATE_PARTNER = 'affiliate_partner',
  OTHER = 'other',
  AGENCY_PARTNER = 'agency_partner',
  PODCAST = 'podcast',
  BANNER_AD = 'banner_ad',
  REDDIT = 'reddit',
  BILLBOARD = 'billboard',
  AUDIO_AD = 'audio_ad',
  CONFERENCE = 'conference',
  TIKTOK = 'tiktok',
  SEARCH_ENGINE = 'search_engine',
  TWITTER = 'twitter',
  INFLUENCER = 'influencer',
  WORD_OF_MOUTH = 'word_of_mouth',
  LINKEDIN = 'linkedin',
  YOUTUBE = 'youtube',
  NEWS_PRESS = 'news_press',
  VIRTUAL_EVENT = 'virtual_event',
}

export enum OnboardingPreviousPlatforms {
  SUBSTACK = 'substack',
  GHOST = 'ghost',
  CONVERT_KIT = 'convert_kit',
  MAILER_LITE = 'mailer_lite',
  MAILCHIMP = 'mailchimp',
  OTHER = 'other',
  CAMPAIGN_MONITOR = 'campaign_monitor',
  WORDPRESS = 'wordpress',
  ACTIVE_CAMPAIGN = 'active_campaign',
  KLAVIYO = 'klaviyo',
  AWEBER = 'aweber',
  BUTTONDOWN = 'buttondown',
  BENCHMARK_EMAIL = 'benchmark_email',
  MAILMODO = 'mailmodo',
  MAILERCLOUD = 'mailercloud',
  OMNISEND = 'omnisend',
  SENDFOX = 'sendfox',
  ZOHO_CAMPAIGNS = 'zoho_campaigns',
}

interface SupportManager {
  first_name: string;
  last_name: string;
  email: string;
  profile_picture_url: string;
}

export interface IOnboarding {
  created_at: string;
  has_paid_subscribers: boolean;
  id: number;
  migrating_from: OnboardingPreviousPlatforms;
  onboarding_type: OnboardingTypes;
  organization_id: string;
  walkthrough_completed_at: string;
  walkthrough_delayed_until: string;
  status: OnboardingStatus;
  updated_at: string;
  chose_to_import: boolean;
  support_manager: SupportManager;
  attribution_sources: OnboardingAttributionSource[];
  business_type?: OnboardingBusinessType;
  organization_size?: OnboardingOrganizationSize;
  publication_rate?: OnboardingPublicationRate;
  primary_goal?: OnboardingPrimaryGoal;
  customize_newsletter_completed?: boolean;
  discover_editor_completed?: boolean;
  explore_website_builder_completed?: boolean;
  finish_publication_settings_completed?: boolean;
  import_prior_publication_completed?: boolean;
  start_publication_completed?: boolean;
  goals: OnboardingGoals[];
}

export interface ExtendedOnboardingStep {
  id: string;
  title: string;
  subtext: string;
  completed: boolean;
  redirect_to: string | null;
}

export enum OnboardingBusinessType {
  SOLO_CREATORS = 'independent',
  PART_OF_AN_ORGANIZATION = 'organization',
}

export enum OnboardingOrganizationSize {
  SMALL = 'one_to_ten',
  MEDIUM = 'eleven_to_fifty',
  LARGE = 'fifty_one_to_two_hundred',
  XLARGE = 'two_hundred_one_to_one_thousand',
  XXLARGE = 'one_thousand_one_and_above',
}

export enum OnboardingPublicationRate {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  MORE_THAN_ONCE_PER_DAY = 'more_than_once_per_day',
  LESS_THAN_ONCE_PER_MONTH = 'less_than_once_per_month',
}

export enum OnboardingPrimaryGoal {
  BECOME_FULL_TIME_CREATOR = 'become_full_time_creator',
  EXPLORE_HOBBY_OR_INTEREST = 'explore_hobby_or_personal_interest',
  SIDE_HUSTLE = 'side_hustle',
  BUILD_PERSONAL_BRAND = 'build_personal_brand',
  BECOME_TRUSTED_SOURCE_OF_NEWS = 'become_trusted_source_of_news',
  GENERATE_SUSTAINABLE_INCOME = 'generate_sustainable_income',
  INFLUENCE_PUBLIC_OPINION = 'influence_public_opinion',
  BUILD_DEEPER_CONECTIONS_WITH_MY_COMMUNITY = 'build_deeper_conections_with_my_community',
  KEEP_STAKEHOLDERS_INFORMED = 'keep_stakeholders_informed',
  NURTURE_LEADS = 'nurture_leads',
  RELY_LESS_ON_SOCIAL_MEDIA = 'rely_less_on_social_media',
}

export enum OnboardingGoals {
  BECOME_TRUSTED_SOURCE_OF_NEWS = 'become_trusted_source_of_news',
  GENERATE_SUSTAINABLE_INCOME = 'generate_sustainable_income',
  INFLUENCE_PUBLIC_OPINION = 'influence_public_opinion',
  BUILD_DEEPER_CONECTIONS_WITH_MY_COMMUNITY = 'build_deeper_conections_with_my_community',
  KEEP_STAKEHOLDERS_INFORMED = 'keep_stakeholders_informed',
  NURTURE_LEADS = 'nurture_leads',
  RELY_LESS_ON_SOCIAL_MEDIA = 'rely_less_on_social_media',
  BUILD_PERSONAL_BRAND = 'build_personal_brand',
  EXPLORE_HOBBY_OR_INTEREST = 'explore_hobby_or_personal_interest',
}

export interface MutationErrorResponse {
  response: {
    data: {
      error: string;
    };
    status: number;
  };
}
