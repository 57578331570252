import { CSSProperties, useEffect, useState } from 'react';
import cx from 'classnames';

import { Button } from '@/ui/Button';
import { cn } from '@/utils/cn';

import { LoadingSpinner } from '../LoadingSpinner';

type TailwindBackgroundColor = string;
interface LoadingBoxProps {
  isLoading: boolean;
  isError: boolean;
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
  backgroundClassName?: TailwindBackgroundColor;
  errorText?: string;
  errorButtonText?: string;
  className?: string;
  errorButtonOnClick?: () => void;
  children: React.ReactNode;
}

const LoadingBox = ({
  isLoading,
  isError,
  height = '500px',
  width = '100%',
  backgroundClassName = 'bg-gray-50',
  errorText = 'Something went wrong, try refreshing the page.',
  errorButtonText = 'Refresh',
  errorButtonOnClick = () => window.location.reload(),
  children,
  className,
}: LoadingBoxProps) => {
  const [displayLoading, setDisplayLoading] = useState(false);

  const loadingBoxStyle: CSSProperties = {
    height,
    width,
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      if (isLoading) {
        setDisplayLoading(true);
      }
    }, 500);

    return () => {
      clearTimeout(delay);
    };
  }, [isLoading]);

  if (!isLoading && !isError) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</> || null;
  }

  return (
    <div className={cn('rounded relative overflow-hidden', className)} style={loadingBoxStyle}>
      <div className={cx('absolute inset-0', backgroundClassName)} />

      {isLoading && (
        <div
          className={cx(
            'absolute top-1/2 right-1/2 transform translate-x-1/2 duration-500',
            displayLoading ? 'opacity-100' : 'opacity-0'
          )}
        >
          <div className="flex">
            <LoadingSpinner />
            <p className="ml-3 text-sm text-gray-400">Loading ...</p>
          </div>
        </div>
      )}

      {isError && (
        <div className="absolute top-1/2 right-1/2 transform translate-x-1/2 ">
          <div className="flex flex-col items-center">
            <p className="text-sm text-gray-600">{errorText}</p>
            <Button variant="primary-inverse" className="mt-2" onClick={errorButtonOnClick}>
              <span>{errorButtonText}</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingBox;
