import { useCallback } from 'react';

import { Length, parseLength } from '../../../UI/ScalarUnitInput';
import { AttributeSettingProps } from '../types';

import { SimpleLengthSettings } from './SimpleLengthSettings';

const LINE_HEIGHT_UNITS = ['%', 'em', 'rem', 'px'];

type LineHeightSettingsProps = AttributeSettingProps & { breakpoint?: 'mobile' | 'desktop'; title?: string };

export const LineHeightSettings = ({
  editor,
  activeNodeResult,
  breakpoint = 'desktop',
  title = 'Line Height',
}: LineHeightSettingsProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;

  const property = breakpoint === 'mobile' ? 'mobileLineHeight' : 'lineHeight';
  const value = activeNodeAttributes?.[property] || '120%';

  const onChange = useCallback(
    (length: Length) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, `${length.value}${length.unit}`);
        return true;
      });
    },
    [editor, activeNodePos, property]
  );

  return (
    <SimpleLengthSettings
      key={`${activeNodePos}-line-height`}
      title={title}
      value={parseLength(value, '')}
      onChange={onChange}
      allowDecimal
      units={LINE_HEIGHT_UNITS}
      bounds={{
        '%': {
          min: 0,
          max: 1000,
        },
        em: {
          min: 0,
          max: 5,
        },
        px: {
          min: 0,
          max: 200,
        },
        rem: {
          min: 0,
          max: 5,
        },
      }}
    />
  );
};
