import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SimpleNumberSettings } from '../SimpleNumberSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

export const UpdatePasswordSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['updatePassword']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Container">
        <FontSettings editor={editor} activeNodeResult={activeNodeResult} isMarkStyle={false} />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ColorSettings
          title="Background"
          property="backgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <SimpleNumberSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="width"
          title="Width"
          unit="px"
          max={1200}
        />
        <SimpleNumberSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="height"
          title="Height"
          unit="px"
          max={1200}
        />
      </SectionRenderer>
      <SectionRenderer title="Button">
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'buttonBorderColor',
            style: 'buttonBorderStyle',
            width: 'buttonBorderWidth',
            radius: 'buttonBorderRadius',
          }}
        />
        <ColorSettings editor={editor} activeNodeResult={activeNodeResult} property="buttonTextColor" title="Color" />
        <ColorSettings
          title="Color"
          property="buttonBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
      </SectionRenderer>
      <SectionRenderer title="Form">
        <ColorSettings title="Text Color" property="textColor" editor={editor} activeNodeResult={activeNodeResult} />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="buttonText"
          title="Submit Text"
        />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="buttonSubmittingText"
          title="Submitting Text"
        />
      </SectionRenderer>
      <SectionRenderer title="Input">
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'inputBorderColor',
            style: 'inputBorderStyle',
            width: 'inputBorderWidth',
            radius: 'inputBorderRadius',
          }}
        />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="passwordInputPlaceholder"
          title="Password Placeholder"
        />
        <ColorSettings title="Text" property="inputTextColor" editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings
          title="Placeholder"
          property="inputPlaceholderColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
        <ColorSettings
          title="Background"
          property="inputBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
