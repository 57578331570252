import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getStringAttribute } from '../../utils/attributesUtils';
import { DEFAULT_MODAL_OVERLAY_BACKGROUND_COLOR, DEFAULT_PRIMARY_COLOR } from '../constants';

import { LoginModalView } from './views/LoginModalView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    loginModal: {
      setLoginModal: () => ReturnType;
    };
  }
}

export const LoginModal = Node.create<{}, {}>({
  name: 'loginModal',

  group: 'block',

  content: 'block*',

  draggable: true,

  addAttributes() {
    return {
      orientation: {
        default: 'vertical',
        renderHTML: (attributes) => ({ 'data-orientation': attributes.orientation }),
        parseHTML: (element) => element.getAttribute('data-orientation') || 'vertical',
      },
      gap: {
        default: '8px',
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
        parseHTML: (element) => element.getAttribute('data-gap') || '8px',
      },
      modalOverlayBackgroundColor: {
        default: DEFAULT_MODAL_OVERLAY_BACKGROUND_COLOR,
        renderHTML: (attributes) => ({ 'data-modal-overlay-background-color': attributes.modalOverlayBackgroundColor }),
        parseHTML: (element) =>
          element.getAttribute('data-modal-overlay-background-color') || DEFAULT_MODAL_OVERLAY_BACKGROUND_COLOR,
      },
      height: {
        default: '250px',
        renderHTML: (attributes) => ({ 'data-height': attributes.height }),
        parseHTML: (element) => element.getAttribute('data-height'),
      },
      width: getStringAttribute('width', 'data-width', '100%'),
      maxWidth: getStringAttribute('maxWidth', 'data-max-width', '100%'),
      padding: {
        default: '32px',
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
        parseHTML: (element) => element.getAttribute('data-padding') || '32px',
      },
      borderWidth: {
        default: '2px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '2px',
      },
      borderColor: {
        default: DEFAULT_PRIMARY_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_PRIMARY_COLOR,
      },
      borderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'solid',
      },
    };
  },

  addOptions() {
    return {
      publicationId: '',
      userId: '',
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(LoginModalView, {
      stopEvent: () => false,
      className: 'w-full h-full',
    });
  },
});

export default LoginModal;
