import { Outlet } from 'react-router-dom';
import { ArrowLeftIcon, ArrowUpLeftIcon } from '@heroicons/react/20/solid';

import { SideNavPortal } from '@/components/Layout/AppLayout/SideNav';
import { TopBarPortal } from '@/components/Layout/AppLayout/TopBar';
import { useAppLayout } from '@/context/app-layout-context';
import { Button } from '@/ui/Button';
import useIsMobile from '@/ui/hooks/useIsMobile';
import Line from '@/ui/WizardHeader/Line';

const MonetizeAdsProductLayout = () => {
  const isMobile = useIsMobile();
  const { useCollapsedSideNav, useHidePublicationDropdown } = useAppLayout();

  useCollapsedSideNav();
  useHidePublicationDropdown();

  return (
    <>
      <TopBarPortal>
        <div className="relative w-full hidden sm:flex justify-center items-center">
          <Button
            size="xs"
            variant="primary-inverse"
            type="button"
            className="my-auto !px-2 ml-2 absolute left-0"
            Icon={ArrowUpLeftIcon}
          />
          <Button variant="flush" size="xs" type="button">
            Basic details
          </Button>
          <div className="flex flex-col justify-center px-2">
            <Line />
          </div>
          <Button variant="flush" size="xs" type="button">
            Frequency
          </Button>
          <div className="flex flex-col justify-center px-2">
            <Line />
          </div>
          <Button variant="flush" size="xs" type="button">
            Asset requirements
          </Button>
        </div>
      </TopBarPortal>

      {isMobile && (
        <SideNavPortal>
          <div className="space-y-2 p-4">
            <Button size="xs" variant="flush" type="button" Icon={ArrowLeftIcon}>
              Exit
            </Button>
            <Button block variant="flush" size="xs" type="button" align="left">
              Basic details
            </Button>
            <Button block variant="flush" size="xs" type="button" align="left">
              Frequency
            </Button>
            <Button block variant="flush" size="xs" type="button" align="left">
              Asset requirements
            </Button>
          </div>
        </SideNavPortal>
      )}

      <Outlet />
    </>
  );
};

export default MonetizeAdsProductLayout;
