import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { survey } from '../../dummyData/survey';
import {
  DEFAULT_BORDER_COLOR,
  DEFAULT_DESCRIPTION_FONT_SIZE,
  DEFAULT_HEADING_FONT_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_ON_PRIMARY_COLOR,
} from '../constants';

import { SurveyView } from './views/SurveyView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    survey: {
      setSurvey: () => ReturnType;
    };
  }
}

export const Survey = Node.create<{}, {}>({
  name: 'survey',

  group: 'block',

  draggable: true,

  selectable: true,

  addAttributes() {
    return {
      gap: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-gap') || '0px',
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      shadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      height: {
        default: 'auto',
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({ 'data-height': attributes.height }),
      },
      width: {
        default: '700px',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({ 'data-width': attributes.width }),
      },
      background: {
        default: '#ffffff',
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
      },
      borderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
      },
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
      },
      borderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({ 'data-border-radius': attributes.borderRadius }),
      },
      titleColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-title-color'),
        renderHTML: (attributes) => ({ 'data-title-color': attributes.titleColor }),
      },
      titleFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-title-font-family'),
        renderHTML: (attributes) => ({ 'data-title-font-family': attributes.titleFontFamily }),
      },
      titleFontSize: {
        default: DEFAULT_HEADING_FONT_SIZE,
        parseHTML: (element) => element.getAttribute('data-title-font-size'),
        renderHTML: (attributes) => ({ 'data-title-font-size': attributes.titleFontSize }),
      },
      isSurveyPageForm: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-is-survey-page-form'),
        renderHTML: (attributes) => ({ 'data-is-survey-page-form': attributes.isSurveyPageForm }),
      },
      descriptionColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-description-color'),
        renderHTML: (attributes) => ({ 'data-description-color': attributes.descriptionColor }),
      },
      descriptionFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-description-font-family'),
        renderHTML: (attributes) => ({ 'data-description-font-family': attributes.descriptionFontFamily }),
      },
      descriptionFontSize: {
        default: DEFAULT_DESCRIPTION_FONT_SIZE,
        parseHTML: (element) => element.getAttribute('data-description-font-size'),
        renderHTML: (attributes) => ({ 'data-description-font-size': attributes.descriptionFontSize }),
      },
      inputBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-input-border-width'),
        renderHTML: (attributes) => ({ 'data-input-border-width': attributes.inputBorderWidth }),
      },
      inputBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-border-color'),
        renderHTML: (attributes) => ({ 'data-input-border-color': attributes.inputBorderColor }),
      },
      inputBorderStyle: {
        default: 'solid',
        parseHTML: (element) => element.getAttribute('data-input-border-style'),
        renderHTML: (attributes) => ({ 'data-input-border-style': attributes.inputBorderStyle }),
      },
      inputBorderRadius: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-input-border-radius'),
        renderHTML: (attributes) => ({ 'data-input-border-radius': attributes.inputBorderRadius }),
      },
      inputBackgroundColor: {
        default: '#ffffff',
        parseHTML: (element) => element.getAttribute('data-input-background-color'),
        renderHTML: (attributes) => ({ 'data-input-background-color': attributes.inputBackgroundColor }),
      },
      inputTextColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-input-text-color'),
        renderHTML: (attributes) => ({ 'data-input-text-color': attributes.inputTextColor }),
      },
      inputTextFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-input-text-font-family'),
        renderHTML: (attributes) => ({ 'data-input-text-font-family': attributes.inputTextFontFamily }),
      },
      inputPlaceholderColor: {
        default: '#6b7280',
        parseHTML: (element) => element.getAttribute('data-input-placeholder-color'),
        renderHTML: (attributes) => ({ 'data-input-placeholder-color': attributes.inputPlaceholderColor }),
      },
      labelColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-label-color'),
        renderHTML: (attributes) => ({ 'data-label-color': attributes.labelColor }),
      },
      labelFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-label-font-family'),
        renderHTML: (attributes) => ({ 'data-label-font-family': attributes.labelFontFamily }),
      },
      helperTextColor: {
        default: '#6b7280',
        parseHTML: (element) => element.getAttribute('data-helper-text-color'),
        renderHTML: (attributes) => ({ 'data-helper-text-color': attributes.helperTextColor }),
      },
      helperTextFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-helper-text-font-family'),
        renderHTML: (attributes) => ({ 'data-helper-text-font-family': attributes.helperTextFontFamily }),
      },
      buttonColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-color'),
        renderHTML: (attributes) => ({ 'data-button-color': attributes.buttonColor }),
      },
      requiredColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-required-color'),
        renderHTML: (attributes) => ({ 'data-required-color': attributes.requiredColor }),
      },
      buttonTextColor: {
        default: DEFAULT_TEXT_ON_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-button-text-color'),
        renderHTML: (attributes) => ({ 'data-button-text-color': attributes.buttonTextColor }),
      },
      buttonTextFontFamily: {
        default: 'inherit',
        parseHTML: (element) => element.getAttribute('data-button-text-font-family'),
        renderHTML: (attributes) => ({ 'data-button-text-font-family': attributes.buttonTextFontFamily }),
      },
      padding: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      data: {
        default: survey,
        parseHTML: (element) => JSON.parse(element.getAttribute('data-data') || '{}'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
    };
  },

  addCommands() {
    return {
      setSurvey:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SurveyView, {
      stopEvent: () => false,
    });
  },
});

export default Survey;
