import { useCallback } from 'react';
import { NodeSelection } from '@tiptap/pm/state';

import { Button } from '../../../../../UI/Button';
import { Text } from '../../../../../UI/Text';
import { MultiNodeSelection } from '../../../../extensions/CustomSelections/selections';
import { AttributeSettingProps } from '../../../types';

export const ActionsSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const onRemove = useCallback(() => {
    const { selection } = editor.state;

    if (selection instanceof MultiNodeSelection || selection instanceof NodeSelection) {
      editor.chain().deleteSelection().focus().run();
      return;
    }

    const { activeNode, activeNodePos, activeNodeEnd } = activeNodeResult;

    if (
      !activeNode ||
      typeof activeNodePos !== 'number' ||
      typeof activeNodeEnd !== 'number' ||
      activeNodePos === -1 ||
      activeNodeEnd === -1
    ) {
      return;
    }

    editor.chain().deleteRange({ from: activeNodePos, to: activeNodeEnd }).focus().run();
  }, [editor, activeNodeResult]);

  const onWrapInContainer = useCallback(() => {
    editor.chain().multinodeSelectionToContainers().focus().run();
  }, [editor]);

  const onWrapInColumn = useCallback(() => {
    editor.chain().multinodeSelectionToColumns().focus().run();
  }, [editor]);

  return (
    <div className="flex flex-col gap-4">
      <Text variant="primary-soft" size="2xs" weight="medium">
        Turn into
      </Text>

      <div className="flex flex-col gap-2">
        <Button variant="secondary" size="sm" onClick={onWrapInContainer}>
          Container
        </Button>

        <Button variant="secondary" size="sm" onClick={onWrapInColumn}>
          Column
        </Button>

        <Button variant="danger-soft" size="sm" onClick={onRemove}>
          Delete
        </Button>
      </div>
    </div>
  );
};
