import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link as LinkIcon } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { isInternalLink } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/isInternalLink';
import useResolvedRoutes from '@/routes/website/_hooks/useResolvedRoutes';

import {
  PopoverHelper,
  PopoverItem,
} from '../../../../../../_components/DreamEditor/AttributesPanel/helpers/PopoverHelper';
import { Button } from '../../../../../../_components/UI/Button';
import { SimpleInput, SimpleInputWrapper } from '../../../../../../_components/UI/Input';
import { ToggleGroup, ToggleGroupItem } from '../../../../../../_components/UI/ToggleGroup';
import { useNavbarContext } from '../../../NavbarContext';

export interface ComboboxOption {
  id: number | string;
  name: string;
  LeftIcon?: React.ReactNode;
}

const validateUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);

    if (process.env.NODE_ENV !== 'development') {
      return parsedUrl.protocol === 'https:';
    }

    return true;
  } catch (error) {
    toast.error('Invalid URL');
    return false;
  }
};

const parseUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl;
  } catch (error) {
    return undefined;
  }
};

export const LinkToSettings = () => {
  const routes = useResolvedRoutes();
  const { data: currentPublication } = useCurrentPublication();

  const [currentURL, setCurrentURL] = useState('');
  const [userFriendlyName, setUserFriendlyName] = useState('');
  const [selectedLinkType, setSelectedLinkType] = useState<'routes' | 'custom'>('routes');

  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  const contentValue: string =
    selectedContent?.attrs && 'href' in selectedContent.attrs ? (selectedContent.attrs.href as string) : '';

  const parsedUrlPathname = parseUrl(contentValue)?.pathname;

  useEffect(() => {
    if (!routes) return;

    const foundRoute = routes?.find((route: any) => `/${route.fullSlug}` === parsedUrlPathname);
    if (foundRoute && isInternalLink(currentPublication?.url || '', contentValue)) {
      try {
        setUserFriendlyName(foundRoute?.name || '');
      } catch (error) {
        setUserFriendlyName('');
      }
    } else {
      setSelectedLinkType('custom');
      setUserFriendlyName('');
      setCurrentURL(contentValue);
    }
  }, [contentValue, routes, currentPublication?.url, parsedUrlPathname]);

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (!['navbar_item', 'navbar_dropdown_item'].includes(selectedContent.type)) return null;

  const handleSetLink = (url: string) => {
    if (!selectedContent.attrs?.id) return;
    if (!validateUrl(url)) return;

    onUpdateNodeAttributes(selectedContent.attrs?.id, { href: url });
    onUpdateNodeAttributes(selectedContent.attrs?.id, { action: undefined });
  };

  return (
    <PopoverHelper title="Link" text={userFriendlyName || contentValue} align="end" popoverTitle="Link" Icon={LinkIcon}>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="routes"
          value={selectedLinkType}
          onValueChange={(value: 'routes' | 'custom') => setSelectedLinkType(value)}
        >
          <ToggleGroupItem value="routes" className="grow">
            Pages
          </ToggleGroupItem>
          <ToggleGroupItem value="custom" className="grow">
            Custom
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
      <div className="flex flex-col gap-2 max-h-[200px] mt-2 pb-2">
        {selectedLinkType === 'custom' && (
          <div className="flex flex-col gap-2">
            <SimpleInputWrapper>
              <SimpleInput
                type="text"
                value={currentURL}
                onChange={(e) => setCurrentURL(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSetLink(currentURL);
                  }
                }}
              />
            </SimpleInputWrapper>
            <Button variant="outlined" size="sm" onClick={() => handleSetLink(currentURL)}>
              Apply
            </Button>
          </div>
        )}
        {selectedLinkType === 'routes' && (
          <div className="w-full flex flex-col gap-2 pb-4">
            {routes.map((route: any) => {
              return (
                <PopoverItem
                  key={route.id}
                  isSelected={route.name === userFriendlyName}
                  value={route.name}
                  onSelect={() => {
                    const settableUrl = `${currentPublication?.url}${route.fullSlug}`;
                    setUserFriendlyName(route.name);
                    handleSetLink(settableUrl);
                  }}
                  subtitle={`/${route.fullSlug}`}
                />
              );
            })}
          </div>
        )}
      </div>
    </PopoverHelper>
  );
};
