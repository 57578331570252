import { useMemo } from 'react';
import { UseQueryResult } from 'react-query';

import Card from '@/components/Card';
import { Typography, TypographyStack } from '@/components/Typography';
import TypographyRow from '@/components/Typography/TypographyRow';
import { currencyFormatter } from '@/utils';

import { usePremiumDataContext } from '../../_context/premiumDataContext';
import { ApiResponse } from '../../_hooks/useTopLevelMetrics';
import formatDuration from '../../_utils/formatDuration';

const LifetimeValueCard = ({ query }: { query: UseQueryResult<ApiResponse> }) => {
  const { lifetime_value_metrics: lifetimeValueMetrics } = query.data || {};

  const { stripe_account_currency: currency } = usePremiumDataContext();

  const ltvDisplay = lifetimeValueMetrics?.ltv ? currencyFormatter(lifetimeValueMetrics.ltv, currency) : '-';
  const upgradeTimeDisplay = useMemo(
    () => formatDuration(lifetimeValueMetrics?.avg_time_to_upgrade),
    [lifetimeValueMetrics]
  );
  const subscriptionDuration = useMemo(
    () => formatDuration(lifetimeValueMetrics?.avg_subscription_duration),
    [lifetimeValueMetrics]
  );

  return (
    <Card
      isLoading={query.isLoading}
      className="col-span-4 md:col-span-2 lg:col-span-1 flex flex-col justify-between space-y-4"
    >
      {lifetimeValueMetrics ? (
        <>
          <TypographyStack gap="2">
            <Typography token="font-medium/text/base" colorWeight="700">
              Lifetime Value
            </Typography>
            <Typography token="font-semibold/text/3xl">{ltvDisplay}</Typography>
          </TypographyStack>
          <TypographyStack gap="1">
            <TypographyRow className="justify-between">
              <Typography token="font-medium/text/xs" colorWeight="700">
                Avg. time to upgrade
              </Typography>
              <Typography token="font-semibold/text/xs">{upgradeTimeDisplay}</Typography>
            </TypographyRow>
            <TypographyRow className="justify-between">
              <Typography token="font-medium/text/xs" colorWeight="700">
                Avg. subscription duration
              </Typography>
              <Typography token="font-semibold/text/xs">{subscriptionDuration}</Typography>
            </TypographyRow>
          </TypographyStack>
        </>
      ) : (
        <TypographyRow className="h-full items-center justify-center text-center min-h-[8rem]">
          <Typography token="font-medium/text/xs" colorWeight="700">
            Error pulling subscription value metrics
          </Typography>
        </TypographyRow>
      )}
    </Card>
  );
};

export default LifetimeValueCard;
