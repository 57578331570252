import { memo, useCallback } from 'react';
import { sticky } from 'tippy.js';

import {
  Boost,
  EmbedInput,
  PaywallBreak,
  Recommendation,
  ReferralProgram,
  ServiceEmbed,
  SubscriberBreak,
} from '../../../extensions';
import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Toolbar } from '../../ui/Toolbar';
import { Tooltip } from '../../ui/Tooltip';
import { BubbleMenu as BaseBubbleMenu } from '../BubbleMenu';
import { MenuProps } from '../types';
import { getRenderContainer } from '../utils/getRenderContainer';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

const REMOVABLE_NODES = Object.freeze([
  PaywallBreak.name,
  ServiceEmbed.name,
  EmbedInput.name,
  Recommendation.name,
  ReferralProgram.name,
  SubscriberBreak.name,
  Boost.name,
]);

const RemoveBlockMenuBase = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const getReferenceClientRect = useCallback(() => {
    const nodeName = REMOVABLE_NODES.find((type) => editor.isActive(type));

    const container = getRenderContainer(editor, `node-${nodeName}`);

    return container?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);
  }, [editor]);

  const shouldShow = useCallback(() => {
    return !!REMOVABLE_NODES.find((type) => editor.isActive(type));
  }, [editor]);

  const handleRemove = useCallback(() => {
    const activeNodeName = REMOVABLE_NODES.find((type) => editor.isActive(type));

    if (activeNodeName) {
      editor.chain().focus().deleteNode(activeNodeName).deleteSelection().run();
    }
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="removeBlockMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar>
        <Tooltip title="Remove node">
          <Button $leftSlot={<Icon name="Trash" />} onClick={handleRemove} {...buttonProps} />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
};

export const RemoveBlockMenu = memo(RemoveBlockMenuBase);

export default RemoveBlockMenu;
