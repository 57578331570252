import { AlignCenterHorizontal, AlignLeft, AlignRight } from '@phosphor-icons/react';
import { useEditorState } from '@tiptap/react';

import { Section } from '../../../extensions';
import DisabledSetting from '../../helpers/DisabledSetting';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import { EnterAnimationSettings } from '../AnimationSettings';
import { BackgroundSettings } from '../BackgroundSettings';
import { BooleanSettings } from '../BooleanSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SliderSettings } from '../SliderSettings';
import { VerticalAlignmentSettings } from '../VerticalAlignmentSettings';

export const SectionSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  // check if this section is the only section in the page
  const isTheOnlySection = useEditorState({
    editor,
    selector: ({ editor: e }) => {
      let count = 0;
      e.view.state.doc.descendants((node) => {
        if (node.type.name === Section.name) {
          count += 1;
        }
      });

      return count === 1;
    },
  });

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['section']}>
      <SectionRenderer title="Mobile" isMobile>
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="mobilePadding" title="Padding" />
      </SectionRenderer>

      <SectionRenderer title="Layout">
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <BackgroundSettings editor={editor} activeNodeResult={activeNodeResult} />
        <AlignmentSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Horizontal"
          property="innerHorizontalAlign"
          icons={{
            left: AlignLeft,
            center: AlignCenterHorizontal,
            right: AlignRight,
          }}
        />
        <VerticalAlignmentSettings editor={editor} activeNodeResult={activeNodeResult} property="innerVerticalAlign" />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="isViewportWidth"
          title="Full Width"
        />
        {activeNodeResult.activeNodeAttributes.isViewportWidth !== true && (
          <SliderSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="innerWidthMax"
            title="Content Width"
            min={0}
            max={1280}
            unit="px"
          />
        )}
        <DisabledSetting
          tooltip="You can't set height when it's the only section in the page"
          disabled={isTheOnlySection}
        >
          <BooleanSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="isViewportHeight"
            title="Full Height"
          />
        </DisabledSetting>
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>

      <SectionRenderer title="Effects">
        <EnterAnimationSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
