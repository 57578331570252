import { createPortal } from 'react-dom';

import { useAppLayout } from '@/context/app-layout-context';

const TopBarPortal: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { useTopBarPortal, topBarRef } = useAppLayout();
  useTopBarPortal();

  if (!topBarRef?.current) return null;

  return createPortal(children, topBarRef.current);
};

export default TopBarPortal;
