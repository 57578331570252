import { Trash } from '@phosphor-icons/react';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import { Button } from '@/routes/website/_components/UI/Button';

import { useNavbarContext } from '../../../NavbarContext';
import { Disabled } from '../general/Disabled';

import { StyleSettings } from './StyleSettings';
import { SubtitleSettings } from './SubtitleSettings';
import { TitleSettings } from './TitleSettings';

export function NavDropdownItemMainSettings() {
  const { selectedContent, onDeleteNode } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown_item') return null;

  return (
    <SectionRenderer
      title="Dropdown Item"
      actions={
        <Button
          size="sm"
          variant="secondary"
          iconClassName="text-wb-secondary"
          Icon={Trash}
          onClick={() => onDeleteNode(selectedContent.attrs?.id || '')}
        />
      }
      contentClassName="mt-0.5 mr-0.5"
    >
      <StyleSettings />
      <TitleSettings />

      <Disabled
        isDisabled={selectedContent.attrs.style === 'link'}
        tooltipText='Description is not supported for "Link" style'
      >
        <SubtitleSettings />
      </Disabled>
    </SectionRenderer>
  );
}
