import { useCampaigns } from '@/ad_portal/hooks/useCampaigns';
import { AdPortalCampaignScopes } from '@/ad_portal/interfaces/campaign';
import LoadingBox from '@/components/LoadingBox';
import { EmptyCard, ItemHeader, ItemHeaders, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { LoadMoreButton } from '@/ui/LoadMoreButton';

import CampaignListItem from './CampaignListItem';

interface CampaignsListProps {
  status: AdPortalCampaignScopes;
}

const CampaignsList = ({ status }: CampaignsListProps) => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading, isError } = useCampaigns({ status });
  const campaigns = data?.pages.flatMap((page) => page.campaigns);
  const hasNoResults = campaigns?.length === 0;

  return (
    <div className="flex flex-col gap-y-4">
      {hasNoResults && !isLoading && <EmptyCard title="No Results" description="No campaigns found" />}
      {!hasNoResults && (
        <LoadingBox isLoading={isLoading} isError={isError}>
          <Items>
            {(isInViewport) => (
              <>
                <ItemHeaders isSticky={isInViewport}>
                  <ItemHeader align={AlignType.LEFT}>Name</ItemHeader>
                  <ItemHeader>Status</ItemHeader>
                  <ItemHeader>Start</ItemHeader>
                  <ItemHeader>End</ItemHeader>
                  <ItemHeader>Budget</ItemHeader>
                  <ItemHeader>Click Target</ItemHeader>
                  <ItemHeader>CPC</ItemHeader>
                </ItemHeaders>
                <ItemsBody>
                  {campaigns?.map((campaign) => (
                    <CampaignListItem key={campaign.id} campaign={campaign} />
                  ))}
                </ItemsBody>
              </>
            )}
          </Items>
        </LoadingBox>
      )}
      {hasNextPage && (
        <div className="flex justify-start mt-8 w-fit">
          <LoadMoreButton fetchNextPage={fetchNextPage} isFetchingNextPage={isFetchingNextPage} />
        </div>
      )}
    </div>
  );
};

export default CampaignsList;
