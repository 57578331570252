import { useParams } from 'react-router-dom';

import { useWebsitePageRouteGetter } from '@/context/website-context';
import { useSiteTemplate } from '@/hooks/useSiteTemplates';

const TAG_PAGE_NAME = 'Tag';
const AUTHOR_PAGE_NAME = 'Author';
const ARCHIVE_PAGE_NAME = 'Archive';
const SURVEY_PAGE_NAME = 'Surveys';

interface ReturnProps {
  isTagPageOrTemplate: boolean;
  isAuthorPageOrTemplate: boolean;
  isArchivePageOrTemplate: boolean;
  isSurveyPageOrTemplate: boolean;
}

const useIsPageCheck = (): ReturnProps => {
  const { pageId, templateId } = useParams() as unknown as { pageId: string; templateId: string };
  const pageRouteGetter = useWebsitePageRouteGetter();
  const { data: template } = useSiteTemplate({ siteTemplateId: templateId });

  let name = '';

  // Template check
  if (template) {
    const tempNameIsTag = template.name === TAG_PAGE_NAME;
    const tempNameIsAuthor = template.name === AUTHOR_PAGE_NAME;
    const tempNameIsArchive = template.name === ARCHIVE_PAGE_NAME;
    const tempNameIsSurvey = template.name === SURVEY_PAGE_NAME;
    const isPageLevel = template.level === 'page';

    if (isPageLevel && tempNameIsTag) {
      name = TAG_PAGE_NAME;
    }
    if (isPageLevel && tempNameIsAuthor) {
      name = AUTHOR_PAGE_NAME;
    }

    if (isPageLevel && tempNameIsArchive) {
      name = ARCHIVE_PAGE_NAME;
    }

    if (isPageLevel && tempNameIsSurvey) {
      name = SURVEY_PAGE_NAME;
    }
  }

  // Page check
  if (pageId) {
    const page = pageRouteGetter?.getPageFromID(pageId);
    name = page?.draft_page_version?.name || '';
  }

  return {
    isTagPageOrTemplate: name === TAG_PAGE_NAME,
    isAuthorPageOrTemplate: name === AUTHOR_PAGE_NAME,
    isArchivePageOrTemplate: name === ARCHIVE_PAGE_NAME,
    isSurveyPageOrTemplate: name === SURVEY_PAGE_NAME,
  };
};

export default useIsPageCheck;
