import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  id: string;
  onSuccess?: () => void;
}

const usePublishSite = ({ id, onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    ({
      publishSettings,
      publishPageIDs,
    }: {
      publishSettings: boolean;
      publishPageIDs: string[];
    }) =>
      api.post(`/sites/${id}/publish`, {
        publication_id: currentPublicationId,
        publish_settings: publishSettings,
        publish_page_ids: publishPageIDs,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.message || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['publications', currentPublicationId, 'sites', 'null']);
        queryClient.invalidateQueries(['publications', currentPublicationId, 'pages']);
      },
    }
  );
};

export default usePublishSite;
