/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable class-methods-use-this */

import { Node } from '@tiptap/pm/model';
import { AllSelection, Selection } from '@tiptap/pm/state';

export class HiddenAllSelection extends AllSelection {
  toJSON(): any {
    return { type: 'hidden-all' };
  }

  static fromJSON(doc: Node) {
    return new HiddenAllSelection(doc);
  }

  map(doc: Node) {
    return new HiddenAllSelection(doc);
  }

  eq(other: Selection) {
    return other instanceof HiddenAllSelection;
  }

  getBookmark() {
    return HiddenAllBookmark;
  }
}

HiddenAllSelection.prototype.visible = false;

Selection.jsonID('hidden-all', HiddenAllSelection);

const HiddenAllBookmark = {
  map() {
    return this;
  },
  resolve(doc: Node) {
    return new HiddenAllSelection(doc);
  },
};
