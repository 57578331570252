import { useCallback, useState } from 'react';
import { Empty, X } from '@phosphor-icons/react';
import classNames from 'classnames';

import { Button } from '../../../UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { AttributeDropdown } from '../helpers/AttributeDropdown';
import AttributeInput from '../helpers/AttributeInput';
import { AttributeSettingProps } from '../types';

import { ColorSettings } from './ColorSettings';

type Divider = {
  color: string;
  style: string;
  thickness: string;
};

const DEFAULT_COLOR = '#000000FF';

const DividerSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodePos } = activeNodeResult;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [divider, setDivider] = useState<Divider>({
    color: activeNodeResult?.activeNodeAttributes.dividerColor || DEFAULT_COLOR,
    style: activeNodeResult?.activeNodeAttributes.dividerStyle || 'none',
    thickness: activeNodeResult?.activeNodeAttributes.dividerThickness || '1px',
  });
  const isNone = divider.style === 'none';

  const handleUpdateBorder = useCallback(
    (incomingDivider: Divider) => {
      if (!activeNodePos) return;

      if (incomingDivider.style === 'none') {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'dividerStyle', 'none');
          return true;
        });
      } else {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'dividerColor', incomingDivider.color);
          tr.setNodeAttribute(activeNodePos, 'dividerStyle', incomingDivider.style);
          tr.setNodeAttribute(activeNodePos, 'dividerThickness', incomingDivider.thickness);
          return true;
        });
      }
    },
    [editor, activeNodePos]
  );

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={(open) => {
        setIsPopoverOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2 select-none">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Divider
          </Text>

          <div className="grow bg-wb-secondary rounded-lg shadow-sm">
            <div className="w-full justify-between flex items-center gap-2 p-2 cursor-pointer">
              <div className="flex items-center gap-1">
                {isNone ? (
                  <Empty className="text-wb-secondary" weight="bold" />
                ) : (
                  <div className="w-4 h-4 rounded-md" style={{ backgroundColor: divider.color }} />
                )}

                <Text size="2xs" weight="medium" className="capitalize">
                  {divider.style}
                </Text>
              </div>
              {!isNone && (
                <Button
                  variant="ghost"
                  Icon={X}
                  iconClassName="text-wb-secondary w-3 h-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    const updatedDivider = { ...divider, style: 'none' };
                    handleUpdateBorder(updatedDivider);
                    setDivider(updatedDivider);
                    setIsPopoverOpen(false);
                  }}
                  className="p-0"
                />
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[260px]" align="start" side="left" sideOffset={20}>
        <div className="max-h-[500px] pb-2 px-0.5 overflow-y-auto flex flex-col gap-2">
          <Text size="sm" weight="semibold">
            Divider
          </Text>

          <AttributeDropdown
            title="Style"
            defaultValue={divider.style}
            options={[
              {
                label: 'None',
                onSelect: () => {
                  const updatedDivider = { ...divider, style: 'none' };
                  handleUpdateBorder(updatedDivider);
                  setDivider(updatedDivider);
                },
              },
              {
                label: 'Solid',
                onSelect: () => {
                  const updatedDivider = { ...divider, style: 'solid' };
                  handleUpdateBorder(updatedDivider);
                  setDivider(updatedDivider);
                },
              },
            ]}
          />
          <div className={classNames(isNone ? 'opacity-50 pointer-events-none' : '', 'flex flex-col gap-2')}>
            <ColorSettings
              editor={editor}
              title="Color"
              property="dividerColor"
              activeNodeResult={activeNodeResult}
              onOverrideSetColor={(value: string | null) => {
                const updatedDivider = { ...divider, color: value || DEFAULT_COLOR };
                handleUpdateBorder(updatedDivider);
                setDivider(updatedDivider);
              }}
            />

            <AttributeInput
              title="Thickness"
              value={parseInt(divider.thickness, 10)}
              rightElement={
                <Text size="2xs" weight="medium">
                  px
                </Text>
              }
              rightElementClassName="absolute top-1/2 right-3 -translate-y-1/2"
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                if (Number.isNaN(value)) return;

                const updatedDivider = { ...divider, thickness: `${value}px` };
                handleUpdateBorder(updatedDivider);
                setDivider(updatedDivider);
              }}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default DividerSettings;
