import { createContext, useContext } from 'react';

import useCalendar from './useCalendar';

type CalendarContextType = ReturnType<typeof useCalendar>;
export const CalendarContext = createContext<CalendarContextType | undefined>(undefined);

const useCalendarContext = (): CalendarContextType => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error('useCalendarContext must be used within a CalendarProvider');
  }
  return context;
};

export default useCalendarContext;
