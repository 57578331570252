
import { Typography } from '@/components/Typography';
import { BoostPublication } from '@/interfaces/boosts/monetize/boost_publication';

export interface Props {
  publication?: BoostPublication;
}

const ModalPublicationHeader = ({ publication }: Props) => {
  if (!publication) return null;

  const { logo_url: logoUrl, name, description, url } = publication;

  return (
    <div className="flex items-start space-x-3 mb-8">
      {logoUrl && (
        <img
          src={logoUrl}
          alt={`${name} logo`}
          className="w-12 h-12 rounded-md border border-gray-200"
        />
      )}
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
          <Typography
            token="font-medium/text/sm"
            colorWeight="900"
            as="h3"
            className="pr-4"
          >
            <a href={url} target="_blank" rel="noreferrer" className="truncate hover:underline">
              {name}
            </a>
          </Typography>
        </div>
        <Typography
          token="font-normal/text/sm"
          colorWeight="700"
          as="p"
        >
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default ModalPublicationHeader;
