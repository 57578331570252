import { useEffect, useState } from 'react';
import { Info } from '@phosphor-icons/react';

import { useWebsiteContext } from '@/context/website-context';
import { useCurrentPublication, useUpdatePublication } from '@/hooks/usePublications';
import BetaBadge from '@/ui/BetaBadge';

import { Button } from '../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../UI/Dialog';
import { Switch } from '../UI/Switch';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

interface Props {
  title: string;
  usesDreamBuilderSite?: boolean;
}

const SiteToggle = ({ title, usesDreamBuilderSite = false }: Props) => {
  const [isActive, setIsActive] = useState(usesDreamBuilderSite);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsActive(usesDreamBuilderSite);
  }, [usesDreamBuilderSite]);

  const { data: currentPublication } = useCurrentPublication();
  const { site } = useWebsiteContext();
  const publicationMutation = useUpdatePublication(currentPublication?.id || '');

  const handlePreviewOnStaging = () => {
    const url = `${currentPublication?.url}preview/${site?.draft_site_version.id}?page=/`;
    window.open(url, '_blank');
  };

  const handleToggle = () => {
    publicationMutation.mutate({ uses_dream_builder_site: !isActive });
    setIsActive(!isActive);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="w-full max-w-md flex flex-col overflow-hidden p-4 gap-2">
          <DialogHeader>
            <DialogTitle>
              <div className="flex items-center gap-2">
                {!isActive ? (
                  <Text size="lg" weight="semibold" variant="primary" as="h4">
                    Website v2
                  </Text>
                ) : (
                  <Text size="lg" weight="semibold" variant="primary" as="h4">
                    Website v1
                  </Text>
                )}
                <BetaBadge />
              </div>
            </DialogTitle>
          </DialogHeader>

          {!isActive ? (
            <div className="flex flex-col gap-2 py-2">
              <Text size="sm" weight="regular" as="p" variant="secondary">
                This software is still actively being developed. You may experience bugs or issues with your new site.
                Prior to toggling on your site fully, we encourage you to test out your site with our staging
                experience.
              </Text>

              <Text size="sm" weight="regular" as="p" variant="secondary">
                If you toggle your new site on, you can always turn it back off.
              </Text>
            </div>
          ) : (
            <div className="flex flex-col gap-2 py-2">
              <Text size="sm" weight="regular" as="p" variant="secondary">
                This will switch your site back to the previously supported version, which is editable from the old site
                builder.
              </Text>
            </div>
          )}

          <DialogFooter className="pt-4">
            <Button variant="secondary" onClick={handlePreviewOnStaging}>
              <Text size="xs" weight="medium" as="span">
                Preview on staging
              </Text>
            </Button>
            {!isActive ? (
              <Button variant="primary" onClick={handleToggle}>
                Switch to Website v2
              </Button>
            ) : (
              <Button variant="primary" onClick={handleToggle}>
                Switch to Website v1
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <div className="flex gap-2 border-b border-wb-primary p-4 w-full">
        <div className="flex w-full justify-between gap-2">
          <div>
            <div className="flex items-center space-x-2">
              <div
                className={`h-1.5 w-1.5 text-xs font-medium rounded-full mt-0.5 ${
                  isActive ? 'bg-wb-success animate-pulse' : 'bg-gray-400'
                }`}
              />
              <div className="flex items-center gap-1">
                <Text size="xs" weight="medium" variant="secondary" className="max-w-[100px] truncate">
                  {title}
                </Text>
                <Tooltip center="Enable to use this site. Disbale to revert to the old site." delay={300}>
                  <Info className="text-wb-secondary w-4 h-4" />
                </Tooltip>
                <BetaBadge />
              </div>
            </div>
          </div>

          <Switch checked={isActive} onCheckedChange={() => setIsOpen(true)} />
        </div>
      </div>
    </>
  );
};

export default SiteToggle;
