import { Extension } from '@tiptap/core';

import '@tiptap/extension-text-style';

import { getBooleanAttribute, getStringAttribute } from '../../../utils/attributesUtils';

interface CardOptions {
  nodesToAddTokensAttributeTo: string[];
}

interface CardStorage {}

export const Card = Extension.create<CardOptions, CardStorage>({
  name: 'card',

  addOptions() {
    return {
      // This extension is really only intended for beehiiv-specific blocks at the moment.
      // Ex: post, testimonials, authors, tags
      nodesToAddTokensAttributeTo: ['post'],
    };
  },

  addGlobalAttributes() {
    const allTypesToAddTokenAttributeTo = [...this.options.nodesToAddTokensAttributeTo];

    return [
      {
        types: allTypesToAddTokenAttributeTo,
        attributes: {
          hasBackgroundImage: getBooleanAttribute('hasBackgroundImage', 'data-has-background-image', false),
          backgroundImageFilterColor: getStringAttribute(
            'backgroundImageFilterColor',
            'data-background-image-filter-color',
            ''
          ),
          hoverShadow: getStringAttribute('hoverShadow', 'data-hover-shadow', 'none'),
        },
      },
    ];
  },
});
