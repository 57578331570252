import { ArrowLeft } from '@phosphor-icons/react';

import { Button } from '../../_components/UI/Button';
import { Text } from '../../_components/UI/Text';

interface Props {
  step: number;
  onPrevious?: () => void;
  stepConfig: {
    [key: number]: {
      title: string;
    };
  };
}

const TopNav = ({ step, stepConfig, onPrevious }: Props) => {
  const showBackButton = step !== 1;

  return (
    <div className='w-full'>
      <div className="flex items-center gap-2 py-4 px-8 h-[68px]">
        {showBackButton && <Button variant="ghost" Icon={ArrowLeft} onClick={onPrevious} />}
        <Text weight="semibold" variant="primary" size="sm" as="h4">
          {stepConfig[step].title}
        </Text>
      </div>

      <div className='w-full h-[3px] bg-wb-secondary'>
        <div className='h-full bg-wb-accent rounded-full'
          style={{
            width: `${(step / 4) * 100}%`,
          }}
        />
      </div>
    </div>
  );
};

export default TopNav;
