import { useCallback, useState } from 'react';

import { BoostAgreementFilter } from '@/interfaces/boosts/grow/boost_agreement_filter';
import { Option } from '@/interfaces/general';
import {
  SCORE_ENGAGEMENT_LABELS,
  SCORE_GROWTH_LABELS,
  SCORE_QUALITY_LABELS,
  SCORE_SIZE_LABELS,
} from '@/pages/Grow/Boosts/Dashboard/constants';
import {
  FiltersPopopoverOperator,
  FiltersPopoverNumberInputSection,
  FiltersPopoverSection,
  FiltersPopoverSectionType,
} from '@/ui/FiltersPopover/FiltersPopover.types';

export const useFilterState = (
  tagOptions: Option[],
  languageOptions: Option[],
  countryOptions: Option[],
  size: 'md' | 'lg'
) => {
  const [tagsOperator, setTagsOperator] = useState<FiltersPopopoverOperator>(FiltersPopopoverOperator.EQUAL);
  const [tagsValue, setTagsValue] = useState<string[]>([]);
  const [languagesOperator, setLanguagesOperator] = useState<FiltersPopopoverOperator>(FiltersPopopoverOperator.EQUAL);
  const [languagesValue, setLanguagesValue] = useState<string[]>([]);
  const [countriesValue, setCountriesValue] = useState<string[]>([]);
  const [postCountOperator, setPostCountOperator] = useState<FiltersPopopoverOperator>(
    FiltersPopopoverOperator.GREATER_THAN
  );
  const [postCountValue, setPostCountValue] = useState<number>();
  const [sizeValue, setSizeValue] = useState<string[]>([]);
  const [growthValue, setGrowthValue] = useState<string[]>([]);
  const [engagementValue, setEngagementValue] = useState<string[]>([]);
  const [boostQualityValue, setBoostQualityValue] = useState<string[]>([]);

  const clearFilters = useCallback(() => {
    setTagsValue([]);
    setLanguagesValue([]);
    setCountriesValue([]);
    setPostCountValue(undefined);
    setSizeValue([]);
    setGrowthValue([]);
    setEngagementValue([]);
    setBoostQualityValue([]);
  }, []);

  const loadFilterValues = useCallback((filter: BoostAgreementFilter | undefined) => {
    if (!filter?.filters) return;

    const { filters } = filter;
    setTagsValue(filters.tags.value);
    setTagsOperator(filters.tags.operator);
    setLanguagesValue(filters.languages.value);
    setLanguagesOperator(filters.languages.operator);
    setCountriesValue(filters.countries.value);
    setPostCountValue(filters.post_count.value);
    setSizeValue(filters.size_scores.map(String));
    setGrowthValue(filters.growth_scores.map(String));
    setEngagementValue(filters.engagement_scores.map(String));
    setBoostQualityValue(filters.boost_quality_scores.map(String));
  }, []);

  const getFilterValues = useCallback(
    () => ({
      tagsOperator,
      tags: tagsValue,
      languagesOperator,
      languages: languagesValue,
      countries: countriesValue,
      postCountOperator,
      postCount: postCountValue,
      sizeScores: sizeValue.map(Number),
      growthScores: growthValue.map(Number),
      engagementScores: engagementValue.map(Number),
      boostQualityScores: boostQualityValue.map(Number),
    }),
    [
      tagsOperator,
      tagsValue,
      languagesOperator,
      languagesValue,
      countriesValue,
      postCountOperator,
      postCountValue,
      sizeValue,
      growthValue,
      engagementValue,
      boostQualityValue,
    ]
  );

  const sections: FiltersPopoverSection[] = [
    {
      type: FiltersPopoverSectionType.MULTI_SELECT,
      name: 'categories',
      title: 'Category',
      placeholderText: 'Categories',
      options: tagOptions,
      value: tagsValue,
      maxVisibleSelectedOptions: size === 'md' ? 1 : 2,
      onSelect: setTagsValue,
      operatorValue: tagsOperator,
      operators: [FiltersPopopoverOperator.EQUAL, FiltersPopopoverOperator.NOT_EQUAL],
      onOperatorChange: setTagsOperator,
      hasSearch: true,
    },
    {
      type: FiltersPopoverSectionType.MULTI_SELECT,
      name: 'language',
      title: 'Publication language',
      placeholderText: 'Languages',
      options: languageOptions,
      value: languagesValue,
      maxVisibleSelectedOptions: size === 'md' ? 1 : 3,
      onSelect: setLanguagesValue,
      operatorValue: languagesOperator,
      operators: [FiltersPopopoverOperator.EQUAL, FiltersPopopoverOperator.NOT_EQUAL],
      onOperatorChange: setLanguagesOperator,
      hasSearch: true,
    },
    {
      type: FiltersPopoverSectionType.MULTI_SELECT,
      name: 'demographic',
      title: 'Audience demographic',
      labelText: 'Majority from',
      placeholderText: 'Countries',
      options: countryOptions,
      value: countriesValue,
      maxVisibleSelectedOptions: size === 'md' ? 1 : 2,
      onSelect: setCountriesValue,
      hasSearch: true,
    },
    {
      type: FiltersPopoverSectionType.NUMBER,
      name: 'posts',
      title: 'Number of posts',
      value: postCountValue,
      min: 0,
      max: 1000,
      onChange: setPostCountValue,
      operatorValue: postCountOperator,
      operators: [
        FiltersPopopoverOperator.GREATER_THAN,
        FiltersPopopoverOperator.LESS_THAN,
        FiltersPopopoverOperator.EQUAL,
      ],
      onOperatorChange: setPostCountOperator,
    } as FiltersPopoverNumberInputSection,
    {
      type: FiltersPopoverSectionType.HEADER,
      name: 'metrics',
      title: 'Metrics',
    },
    {
      type: FiltersPopoverSectionType.MULTI_SELECT,
      name: 'size',
      placeholderText: 'Size',
      options: Object.entries(SCORE_SIZE_LABELS).map(([value, label]) => ({
        label,
        value,
      })),
      value: sizeValue,
      maxVisibleSelectedOptions: size === 'md' ? 1 : 3,
      onSelect: setSizeValue,
    },
    {
      type: FiltersPopoverSectionType.MULTI_SELECT,
      name: 'growth',
      placeholderText: 'Growth',
      options: Object.entries(SCORE_GROWTH_LABELS).map(([value, label]) => ({
        label,
        value,
      })),
      value: growthValue,
      maxVisibleSelectedOptions: size === 'md' ? 1 : 3,
      onSelect: setGrowthValue,
    },
    {
      type: FiltersPopoverSectionType.MULTI_SELECT,
      name: 'engagement',
      placeholderText: 'Engagement',
      options: Object.entries(SCORE_ENGAGEMENT_LABELS).map(([value, label]) => ({
        label,
        value,
      })),
      value: engagementValue,
      maxVisibleSelectedOptions: size === 'md' ? 1 : 3,
      onSelect: setEngagementValue,
    },
    {
      type: FiltersPopoverSectionType.MULTI_SELECT,
      name: 'boostQuality',
      placeholderText: 'Boost Quality',
      options: Object.entries(SCORE_QUALITY_LABELS).map(([value, label]) => ({
        label,
        value,
      })),
      value: boostQualityValue,
      maxVisibleSelectedOptions: size === 'md' ? 1 : 3,
      onSelect: setBoostQualityValue,
    },
  ];

  return {
    sections,
    clearFilters,
    loadFilterValues,
    getFilterValues,
    filterState: {
      tagsOperator,
      setTagsOperator,
      tagsValue,
      setTagsValue,
      languagesOperator,
      setLanguagesOperator,
      languagesValue,
      setLanguagesValue,
      countriesValue,
      setCountriesValue,
      postCountOperator,
      setPostCountOperator,
      postCountValue,
      setPostCountValue,
      sizeValue,
      setSizeValue,
      growthValue,
      setGrowthValue,
      engagementValue,
      setEngagementValue,
      boostQualityValue,
      setBoostQualityValue,
    },
  };
};
