import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CalendarIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { cx } from 'class-variance-authority';
import moment, { Moment } from 'moment-mini';

import { MODAL_Z_INDEX } from '@/components/zIndexes';
import { Button } from '@/ui/Button';
import { cn } from '@/utils/cn';

import Grid from '../components/Grid';
import SidePanel from '../components/SidePanel';
import TopBar from '../components/TopBar';
import { CalendarContext, useCalendar } from '../utils/useCalendar';

interface DateInputProps {
  placeholder?: string;
  disabled?: boolean;
  minDate?: Moment;
  maxDate?: Moment;
  isDateDisabled?: (date: Moment) => boolean;
  value: Moment | null;
  onChange: (date: Moment | null) => void;
  format?: string;
}

const DateInput = (props: DateInputProps) => {
  const {
    disabled = false,
    value,
    onChange,
    minDate: minSelectableDate,
    maxDate: maxSelectableDate,
    isDateDisabled,
    placeholder = 'Select date',
    format = 'LL',
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const calendar = useCalendar({
    year: moment().year(),
    month: moment().month(),
    maxSelectableDate,
    minSelectableDate,
    variant: 'compact',
    isDateDisabled,
  });

  const handleConfirm = () => {
    onChange?.(calendar.selectedDate);
    setIsOpen(false);
  };

  const tzAbbr = Intl.DateTimeFormat('default', { timeZoneName: 'long' })
    ?.formatToParts(new Date())
    .find((part) => part.type === 'timeZoneName')?.value;

  return (
    <>
      <div className="w-full">
        <button
          className={cn(
            'px-3 py-2 text-left block w-full rounded bg-white border shadow-none border-gray-200 text-sm transition-all ring-0 relative',
            !disabled && 'hover:border-gray-300',
            !disabled && 'focus:border-gray-300 focus:shadow-md focus:outline-none focus:ring-0',
            disabled && 'bg-gray-200 border-gray-300'
          )}
          type="button"
          onClick={() => setIsOpen(true)}
          disabled={disabled}
        >
          {value ? (
            <span className="text-gray-800">{value.format(format)}</span>
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}

          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
            <CalendarIcon className="h-5 w-5 text-gray-400" />
          </div>
        </button>
        <div className="flex justify-end items-center">
          <p className={cn('text-xs text-gray-400 mt-1')}>{tzAbbr}</p>
        </div>
      </div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className={cx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX)}
          onClose={() => setIsOpen(false)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="w-screen sm:my-8 relative inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:align-middle 2xl:max-w-3xl xl:max-w-2xl lg:max-w-xl max-w-lg sm:w-full sm:h-auto">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="absolute right-4 top-4 bg-transparent rounded-sm p-1 transition-all hover:bg-gray-200"
                >
                  <XMarkIcon className="w-5 h-5 bg-white bg-opacity-20 rounded-sm text-gray-900 text-opacity-60 transition duration-300 ease-in-out" />
                </button>

                <CalendarContext.Provider value={calendar}>
                  <TopBar />
                  <Grid />
                  <div className="block sm:hidden">
                    <SidePanel />
                  </div>
                  <div className="space-x-2 flex justify-end p-2">
                    <Button type="button" variant="primary-inverse" onClick={() => setIsOpen(false)}>
                      Cancel
                    </Button>
                    <Button type="button" variant="primary" onClick={handleConfirm}>
                      Confirm
                    </Button>
                  </div>
                </CalendarContext.Provider>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DateInput;
