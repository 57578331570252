import { useMemo, useState } from 'react';

import useAuthors from '@/hooks/useDreamBuilder/useAuthors';
import useForms from '@/hooks/useDreamBuilder/useForms';
import useTags from '@/hooks/useDreamBuilder/useTags';

import Modal from '../../../_components/UI/Modal';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../_components/UI/Select';

interface Props {
  url?: string;
  onClose: () => void;
  onSubmit: (newUrl?: string) => void;
}

const ConfigureRoute = ({ url, onClose, onSubmit }: Props) => {
  const [selectedId, setSelectedId] = useState<string | undefined>();

  const isAuthorRoute = !!url?.match(/authors\/\$author/gi);
  const isTagRoute = !!url?.match(/t\/\$tag/gi);
  const isFormRoute = !!url?.match(/forms\/\$id/gi);

  const { data: authorsData } = useAuthors({ enabled: isAuthorRoute });
  const { data: tagsData } = useTags({ enabled: isTagRoute });
  const { data: formsData } = useForms({ enabled: isFormRoute });

  const options = useMemo(() => {
    if (isAuthorRoute && authorsData) {
      return authorsData.authors.map((author) => ({
        value: author.id,
        label: author.name,
      }));
    }

    if (isTagRoute && tagsData) {
      return (tagsData?.pages.flatMap((page) => page.tags) || []).map((tag) => ({
        value: window.encodeURIComponent(tag.display),
        label: tag.display,
      }));
    }

    if (isFormRoute && formsData) {
      return (formsData?.pages.flatMap((page) => page.forms) || []).map((form) => ({
        value: form.id,
        label: form.title,
      }));
    }

    return [];
  }, [authorsData, formsData, isAuthorRoute, isFormRoute, isTagRoute, tagsData]);

  const handleConfirm = () => {
    if (!isFormRoute && !isAuthorRoute && !isTagRoute) {
      onSubmit();
      return;
    }

    if (!url || !selectedId || selectedId === 'none') {
      onSubmit();
      return;
    }

    if (isFormRoute) {
      onSubmit(url.replace(/\$id$/i, selectedId));
    } else if (isTagRoute) {
      onSubmit(url.replace(/\$tag$/i, selectedId));
    } else if (isAuthorRoute) {
      onSubmit(url.replace(/\$author$/i, selectedId));
    }
  };

  return (
    <Modal isOpen={!!url} onClose={onClose} title="Select an option" onConfirm={handleConfirm} ctaText="Save">
      <Select
        defaultValue={selectedId}
        onValueChange={(value: any) => {
          setSelectedId(value);
        }}
      >
        <SelectTrigger className="w-full" id="by_status">
          <SelectValue placeholder="None" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="none">None</SelectItem>
            {options?.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </Modal>
  );
};

export default ConfigureRoute;
