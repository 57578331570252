import { useQuery } from 'react-query';

import api from '@/services/swarm';

interface Props {
  organizationId: string;
}

interface ApiResponse {
  count: number;
}

const usePremiumSubscriptionsCount = ({ organizationId }: Props) => {
  return useQuery<ApiResponse>(
    ['downgrade', 'premium_subscriptions_count', organizationId],
    () => api.get(`/organizations/${organizationId}/premium_subscriptions_count`, {}).then((res) => res.data),
    {
      staleTime: 10000,
      enabled: !!organizationId,
    }
  );
};

export default usePremiumSubscriptionsCount;
