import { useState } from 'react';
import { BellSnoozeIcon } from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import useBoostOffer from '@/hooks/boosts/monetize/useBoostOffer';
import useBoostOfferCard from '@/hooks/boosts/monetize/useBoostOfferCard';
import useBoostOfferEligibleCountries from '@/hooks/boosts/useBoostOfferEligibleCountries';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';
import ModalPublicationHeader from '@/pages/Grow/Boosts/Dashboard/components/ModalPublicationHeader';
import Badge, { BadgeSize, BadgeType } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import Tooltip from '@/ui/Tooltip';

interface Props {
  boostOfferId?: string | null;
  onApply: ({ boostOffer }: { boostOffer: BoostOffer }) => void;
  onSnooze: (boostOfferId: string) => void;
  onClose: () => void;
}

const BoostOfferModal = ({ onClose, onApply, onSnooze, boostOfferId }: Props) => {
  const { settings } = useSettings();
  const { data: boostOffer, isLoading } = useBoostOffer(boostOfferId || '');
  const [isClosing, setIsClosing] = useState(false);

  const portalMountedId = `${boostOffer?.id}-modal`;
  const { description: boostOfferDescription, max_payout: maxPayout, payout_per_lead: payoutPerLead } = boostOffer || {};
  const { hasRegionalRestrictions, allMappedEligibleCountries } = useBoostOfferEligibleCountries(
    boostOffer?.eligible_countries || null,
    true
  );

  const { alreadyApplied, shouldDisableApply, boostsPlusEnabled, ctaText, boostedPublication } =
    useBoostOfferCard(boostOffer);
  const { hostname, tags, url } = boostedPublication || {};

  // To avoid a janky close animation, we call onClose after the closing animation is done
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 200);
  };

  const handleApply = () => {
    handleClose();

    if (boostOffer) {
      onApply({ boostOffer });
    }
  };

  const handleSnooze = () => {
    handleClose();

    if (boostOffer) {
      onSnooze(boostOffer.id);
    }
  };

  const modalFooterContent = (
    <div className="w-full flex gap-3 justify-end">
      <Button
        variant="primary-inverse"
        disabled={shouldDisableApply}
        Icon={BellSnoozeIcon}
        onClick={handleSnooze}
      />

      <Button disabled={shouldDisableApply} onClick={handleApply}>
        {alreadyApplied ? ctaText : `Earn ${payoutPerLead} per subscriber`}
      </Button>
    </div>
  );

  return (
    <ActionModal
      resourceId="review-boost-offer"
      modalSize="lg"
      bodyId={portalMountedId}
      isOpen={!!boostOfferId && !isClosing}
      isWorking={isLoading}
      onClose={handleClose}
      footer={modalFooterContent}
      hideCancelButton
      showXButton
    >
      <>
        <ModalPublicationHeader publication={boostedPublication} />
        <div className="space-y-6">
          <div className="space-y-2">
            <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
              Website URL
            </Typography>
            <Typography token="font-semibold/text/xs" colorWeight="900" as="div">
              <a href={url} target="_blank" rel="noreferrer" className="block truncate hover:underline">
                {hostname}
              </a>
            </Typography>
          </div>

          <div className="space-y-2">
            <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
              Categories
            </Typography>
            <div className="flex flex-wrap gap-2">
              {tags?.map((tag) => (
                <Badge
                  key={tag.id}
                  text={tag.name}
                  size={BadgeSize.SM}
                  type={BadgeType.DEFAULT_FILLED}
                />
              ))}
            </div>
          </div>

          <div className="space-y-2">
            <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
              Target audience
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="900" as="p">
              {boostOfferDescription}
            </Typography>
          </div>

          {hasRegionalRestrictions && allMappedEligibleCountries.length > 0 && (
            <div className="space-y-2">
              <Typography token="font-normal/text/xs" colorWeight="500" as="h4">
                <span className="flex gap-x-1 items-center">
                  Eligible subscriber countries
                  <Tooltip
                    id="boosts-modal-eligible-countries-tooltip"
                    text="This offer only pays for eligible subscribers from the specified countries"
                  />
                </span>
              </Typography>
              <div className="flex flex-wrap gap-2 items-center">
                {allMappedEligibleCountries.map((country) => (
                  <Badge
                    key={country}
                    text={country}
                    size={BadgeSize.SM}
                    type={BadgeType.DEFAULT_FILLED}
                  />
                ))}
              </div>
            </div>
          )}

          <dl className="flex flex-col justify-start p-4 gap-5 rounded-md border border-surface-200 md:flex-row lg:gap-10">
            <div className="flex flex-row items-center gap-2">
              <dt>
                <Badge
                  text="Web Boost"
                  size={BadgeSize.SM}
                  type={BadgeType.TERTIARY_LINED}
                />
              </dt>

              <Typography token="font-normal/text/sm" colorWeight="500" as="dd">
                <Typography token="font-semibold/text/sm" colorWeight="900">
                  {payoutPerLead}
                </Typography>{' '}
                per subscriber
              </Typography>
            </div>

            {settings?.boosts_plus && (
              <div className="flex flex-row items-center gap-2">
                <dt>
                  <Badge
                    text="Email Boost"
                    size={BadgeSize.SM}
                    type={BadgeType.TERTIARY_LINED}
                  />
                </dt>

                {boostsPlusEnabled ? (
                  <Typography token="font-normal/text/sm" colorWeight="500" as="dd">
                    up to{' '}
                    <Typography token="font-semibold/text/sm" colorWeight="900">
                      {maxPayout}
                    </Typography>{' '}
                    per send
                  </Typography>
                ) : (
                  <Typography token="font-normal/text/sm" colorWeight="500" as="dd">
                    Sending Not Enabled
                  </Typography>
                )}
              </div>
            )}
          </dl>
        </div>
      </>
    </ActionModal>
  );
};

export default BoostOfferModal;
