import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCurrentUser } from '@/context/current-user-context';
import Loading from '@/pages/Loading';
import api from '@/services/swarm';

interface ApiResponse {
  authorize_url: string;
}

const AuthMailchimp = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.replace(url);
  });

  return <Loading text="Redirecting..." />;
};

export default function Loader() {
  const navigate = useNavigate();
  const [currentPublicationId] = useCurrentPublicationState();
  const { currentUser } = useCurrentUser();
  const hasSettingsV2 = currentUser?.has_settings_v2;
  const redirectUrl = hasSettingsV2 ? '/settings/publication/content_import/new' : '/settings/integrations/imports';

  const { data, isSuccess } = useQuery({
    queryFn: (): Promise<ApiResponse> =>
      api
        .get('/oauth2/authorize_urls/mailchimp', {
          params: { publication_id: currentPublicationId },
        })
        .then((res) => res.data),
    onError: () => {
      navigate(redirectUrl);
      toast.error('Something went wrong. Please try again.');
    },
  });

  if (!isSuccess) return <Loading text="Talking to mailchimp..." />;

  return <AuthMailchimp url={data.authorize_url} />;
}
