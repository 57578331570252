import { useQuery, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { BoostAgreementFilter } from '@/interfaces/boosts/grow/boost_agreement_filter';
import api from '@/services/swarm';

interface ApiResponse {
  boost_agreement_filters: BoostAgreementFilter[];
}

interface Props {
  onlySaved: boolean;
  onlyAutoAcceptable: boolean;
  isEnabled?: boolean;
}

const useBoostAgreementFilters = ({ onlySaved, onlyAutoAcceptable, isEnabled = true }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();

  const fetchBoostAgreementFilters = (): Promise<ApiResponse> =>
    api
      .get(`/boosts/grow/boost_agreement_filters`, {
        params: {
          publication_id: currentPublicationId,
          only_saved: onlySaved,
          only_auto_acceptable: onlyAutoAcceptable,
        },
      })
      .then((res) => res.data);

  return useQuery(
    [currentPublicationId, 'boosts', 'grow', 'boost_agreement_filters', onlySaved, onlyAutoAcceptable],
    fetchBoostAgreementFilters,
    {
      onSuccess: (data) => {
        const filters = data.boost_agreement_filters;

        filters.forEach((filter) => {
          queryClient.setQueryData(['boost_agreement_filters', filter.id], filter);
        });
      },
      keepPreviousData: true,
      enabled: isEnabled,
    }
  );
};

export default useBoostAgreementFilters;
