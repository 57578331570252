import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/20/solid';

import { Breadcrumbs } from '@/components/Breadcrumbs';
import { useOpportunity } from '@/hooks/useAdNetwork/publisher';
import useOpportunityAdvertisements from '@/hooks/useAdNetwork/publisher/useOpportunityAdvertisements';
import {
  AdNetworkOpportunity,
  AdNetworkOpportunityAdvertisement,
  AdNetworkOpportunityStatus,
} from '@/interfaces/ad_network/publisher/opportunity';
import { OpportunityDetails } from '@/routes/monetize/ads/opportunities/$opportunity_id/_components/OpportunityDetails';

import { OpportunityForm } from './_components/OpportunityForm';
import { OpportunityFormCompleted } from './_components/OpportunityFormCompleted';
import { OpportunityReady } from './_components/OpportunityReady';

interface Props {
  opportunity: AdNetworkOpportunity;
  advertisements: AdNetworkOpportunityAdvertisement[];
}

const Page = ({ opportunity, advertisements }: Props) => {
  const navigate = useNavigate();
  const pageTitle = `${opportunity.advertiser.name} / ${opportunity.campaign.name}`;
  const [editMode, setEditMode] = useState(opportunity.status === AdNetworkOpportunityStatus.PENDING);
  const [readyModalOpen, setReadyModalOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="max-h-[calc(100vh-16rem)]">
        <Breadcrumbs>
          <Breadcrumbs.Item to="/">
            <HomeIcon className="w-4 h-4" />
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to="/monetize/ads">Advertisements</Breadcrumbs.Item>
          <Breadcrumbs.Item to="">{pageTitle}</Breadcrumbs.Item>
        </Breadcrumbs>
        <div className="grid lg:grid-cols-5 grid-cols-2 gap-6">
          <div className="col-span-2">
            <OpportunityDetails opportunity={opportunity} />
          </div>
          <div className="col-span-3 overflow-auto">
            {editMode ? (
              <OpportunityForm
                opportunity={opportunity}
                advertisements={advertisements}
                setEditMode={setEditMode}
                setReadyModalOpen={setReadyModalOpen}
              />
            ) : (
              <OpportunityFormCompleted
                opportunity={opportunity}
                advertisements={advertisements}
                setEditMode={setEditMode}
              />
            )}
          </div>
        </div>
        <OpportunityReady
          opportunity={opportunity}
          isOpen={readyModalOpen}
          onClose={() => {
            setReadyModalOpen(false);
            navigate('/monetize/ads');
          }}
        />
      </div>
    </>
  );
};

const Loader = () => {
  const { opportunity_id: id } = useParams<'opportunity_id'>() as { opportunity_id: string };

  const opportunityQuery = useOpportunity({ id });
  const advertisementsQuery = useOpportunityAdvertisements({ id });
  const isSuccess = opportunityQuery.isSuccess && advertisementsQuery.isSuccess;

  if (!isSuccess) return null;
  const { data: opportunity } = opportunityQuery;
  const { data: advertisements } = advertisementsQuery;

  return <Page opportunity={opportunity} advertisements={advertisements} />;
};

export default Loader;
