function isDynamicResourcePage(url: string) {
  try {
    const { pathname } = new URL(url);
    if (['/forms/$id', '/authors/$author', '/t/$tag'].includes(pathname)) {
      return true;
    }
  } catch (error) {
    return false;
  }
  return false;
}

export default isDynamicResourcePage;
