import { Author } from '@shared/dream-components';

import { imageUrl1, imageUrl2, imageUrl3, imageUrl4, imageUrl5, imageUrl6 } from './images';

const OLIVER_BUCHANNON: Author = {
  id: 'author123',
  name: 'Oliver Buchannon',
  profile_picture: {
    thumb: { url: imageUrl1 },
    url: imageUrl1,
    landscape: { url: imageUrl1 },
  },
  twitter_handle: 'oliver_buchannon',
  bio: 'Oliver Buchannon is a full-stack developer passionate about AI and machine learning. Previously worked at Google and Meta before joining Dream.',
  discord_url: 'https://discord.com/oliver_buchannon',
  facebook_url: 'https://facebook.com/oliver_buchannon',
  instagram_url: 'https://instagram.com/oliver_buchannon',
  linkedin_url: 'https://linkedin.com/oliver_buchannon',
  tiktok_url: 'https://tiktok.com/oliver_buchannon',
  youtube_url: 'https://youtube.com/oliver_buchannon',
};

const KYRA_SINGH = {
  name: 'Kyra Singh',
  profile_picture: {
    thumb: { url: imageUrl2 },
    url: imageUrl2,
    landscape: { url: imageUrl2 },
  },
  twitter_handle: 'kyra_singh',
  bio: "Kyra Singh is a UX specialist and frontend architect who loves creating intuitive user experiences. She leads Dream's design system implementation.",
  discord_url: 'https://discord.com/kyra_singh',
  facebook_url: 'https://facebook.com/kyra_singh',
  instagram_url: 'https://instagram.com/kyra_singh',
  linkedin_url: 'https://linkedin.com/kyra_singh',
  tiktok_url: 'https://tiktok.com/kyra_singh',
  youtube_url: 'https://youtube.com/kyra_singh',
};

const NATALIA_MIRESCU = {
  name: 'Natalia Mirescu',
  profile_picture: {
    thumb: { url: imageUrl3 },
    url: imageUrl3,
    landscape: { url: imageUrl3 },
  },
  twitter_handle: 'natalia_mirescu',
  id: 'author3',
  bio: "Security expert and blockchain enthusiast, Natalia brings her cryptography expertise to Dream's authentication systems.",
  discord_url: 'https://discord.com/natalia_mirescu',
  facebook_url: 'https://facebook.com/natalia_mirescu',
  instagram_url: 'https://instagram.com/natalia_mirescu',
  linkedin_url: 'https://linkedin.com/natalia_mirescu',
  tiktok_url: 'https://tiktok.com/natalia_mirescu',
  youtube_url: 'https://youtube.com/natalia_mirescu',
};

const LIAM_MCKENZIE = {
  name: 'Liam McKenzie',
  profile_picture: {
    thumb: { url: imageUrl4 },
    url: imageUrl4,
    landscape: { url: imageUrl4 },
  },
  twitter_handle: 'liam_mckenzie',
  id: 'author4',
  bio: "DevOps wizard and infrastructure specialist, Liam ensures Dream's systems run smoothly at scale. AWS certified and Kubernetes expert.",
  discord_url: 'https://discord.com/liam_mckenzie',
  facebook_url: 'https://facebook.com/liam_mckenzie',
  instagram_url: 'https://instagram.com/liam_mckenzie',
  linkedin_url: 'https://linkedin.com/liam_mckenzie',
  tiktok_url: 'https://tiktok.com/liam_mckenzie',
  youtube_url: 'https://youtube.com/liam_mckenzie',
};

const CATALINA_VIDAL = {
  name: 'Catlina Vidal',
  profile_picture: {
    thumb: { url: imageUrl5 },
    url: imageUrl5,
    landscape: { url: imageUrl5 },
  },
  twitter_handle: 'catalina_vidal',
  id: 'author5',
  bio: "Mobile development guru specializing in cross-platform solutions. Catalina leads Dream's mobile initiatives and mentors junior developers.",
  discord_url: 'https://discord.com/catalina_vidal',
  facebook_url: 'https://facebook.com/catalina_vidal',
  instagram_url: 'https://instagram.com/catalina_vidal',
  linkedin_url: 'https://linkedin.com/catalina_vidal',
  tiktok_url: 'https://tiktok.com/catalina_vidal',
  youtube_url: 'https://youtube.com/catalina_vidal',
};

const ALESSANDRO_ROSSI = {
  name: 'Alessandro Rossi',
  profile_picture: {
    thumb: { url: imageUrl6 },
    url: imageUrl6,
    landscape: { url: imageUrl6 },
  },
  twitter_handle: 'alessandro_rossi',
  id: 'author6',
  bio: 'Backend architect with a focus on distributed systems. Alessandro previously built high-frequency trading systems before joining Dream.',
  discord_url: 'https://discord.com/alessandro_rossi',
  facebook_url: 'https://facebook.com/alessandro_rossi',
  instagram_url: 'https://instagram.com/alessandro_rossi',
  linkedin_url: 'https://linkedin.com/alessandro_rossi',
  tiktok_url: 'https://tiktok.com/alessandro_rossi',
  youtube_url: 'https://youtube.com/alessandro_rossi',
};

const authors = [ALESSANDRO_ROSSI, CATALINA_VIDAL, KYRA_SINGH, LIAM_MCKENZIE, NATALIA_MIRESCU, OLIVER_BUCHANNON];

const selectThreeRandomAuthors = ({ count = 3 }: { count: number }) => {
  return authors.sort(() => 0.5 - Math.random()).slice(0, count);
};

export {
  ALESSANDRO_ROSSI,
  authors,
  CATALINA_VIDAL,
  KYRA_SINGH,
  LIAM_MCKENZIE,
  NATALIA_MIRESCU,
  OLIVER_BUCHANNON,
  selectThreeRandomAuthors,
};
