import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import moment from 'moment-mini';

import { Typography } from '@/components/Typography';

import { CALENDAR_POPOVER_Z_INDEX } from '../../zIndexes';
import { useCalendar, useCalendarContext } from '../utils/useCalendar';
import { Calendar } from '../utils/useCalendar/types';

import EventDetails from './EventDetails/EventDetails';

interface SidePanelEventProps {
  event: ReturnType<typeof useCalendar>['days'][0]['events'][0];
}

const SidePanelEvent = ({ event }: SidePanelEventProps) => {
  const { calendars } = useCalendarContext();

  const calendar = calendars.find((c) => c.id === event.calendar) as Calendar;

  const colorClass = {
    pink: 'bg-pink-500',
    violet: 'bg-violet-500',
  }[calendar.color];

  return (
    <Popover.Root>
      <Popover.Trigger className="border border-gray-200 rounded-lg p-3 space-y-1 w-full text-left data-[state=open]:shadow-md transition duration-100 outline-none focus:ring ring-gray-300">
        <Typography as="p" token="font-medium/text/sm" className="line-clamp-1">
          {event.title}
        </Typography>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className={cx('h-2 w-2 rounded-sm', colorClass)}>&nbsp;</div>
            <Typography as="p" token="font-normal/text/xs" colorWeight="500">
              {calendar?.name}
            </Typography>
          </div>
          <Typography token="font-normal/text/xs" colorWeight="500">
            {moment(event.time).format('MMM Do')}
          </Typography>
        </div>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content sideOffset={5} className={CALENDAR_POPOVER_Z_INDEX}>
          <EventDetails event={event} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default SidePanelEvent;
