import { Extension } from '@tiptap/core';

import { getStringAttribute } from '../../../utils/attributesUtils';

interface MergeTagOptions {
  nodesToAddMergeTagsAttributeTo: string[];
}

interface MergeTagStorage {}

export const MergeTag = Extension.create<MergeTagOptions, MergeTagStorage>({
  name: 'mergeTag',

  addOptions() {
    return {
      nodesToAddMergeTagsAttributeTo: ['heading', 'image', 'paragraph'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.nodesToAddMergeTagsAttributeTo,
        attributes: {
          mergeTag: getStringAttribute('mergeTag', 'data-merge-tag', null, null),
        },
      },
    ];
  },
});
