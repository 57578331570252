import { memo, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{}>;

export const TopNav = memo(({ children }: Props) => {
  return (
    <nav className="relative w-full bg-wb-primary px-4 py-2 flex justify-between border border-wb-primary">
      {children}
    </nav>
  );
});
