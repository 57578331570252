export enum LedgerKind {
  GROW = 'grow',
  MONETIZATION = 'monetization',
}

export enum LedgerEntryKind {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum LedgerEntryNetworkType {
  BOOSTS = 'boosts',
  ADS = 'ads',
}

export enum LedgerEntryCategory {
  STRIPE_DEPOSIT = 'stripe_deposit',
  STRIPE_PAYOUT = 'stripe_payout',
  EARNINGS_DEPOSIT = 'earnings_deposit',
  EARNINGS_PAYOUT = 'earnings_payout',
  MANUAL_ADJUSTMENT = 'manual_adjustment',
}

export interface LedgerEntry {
  id: string;
  description: string;
  kind: LedgerEntryKind;
  category: LedgerEntryCategory;
  source_gid: string;
  amount_cents: number;
  formatted_amount: string;
  created_at: Date;
  created_by_user_id: string;
  ledger_id: string;
  stripe_transfer_completed_at?: Date;
  network_type: LedgerEntryNetworkType;
  referrer_name?: string;
}
