import { ActivityFeedEventType } from '@/interfaces/activity_feed_event';

export const eventTypeDropdownOptions = [
  {
    label: 'Sends',
    value: ActivityFeedEventType.SENT,
  },
  {
    label: 'Deliveries',
    value: ActivityFeedEventType.DELIVERED,
  },
  {
    label: 'Opens',
    value: ActivityFeedEventType.OPEN,
  },
  {
    label: 'Clicks',
    value: ActivityFeedEventType.CLICK,
  },
  {
    label: 'Spam Reports',
    value: ActivityFeedEventType.SPAM_REPORT,
  },
  {
    label: 'Deferrals',
    value: ActivityFeedEventType.DEFERRAL,
  },
  {
    label: 'Delivery Errors',
    value: ActivityFeedEventType.DELIVERY_ERROR,
  },
  {
    label: 'Comments',
    value: ActivityFeedEventType.COMMENT,
  },
  {
    label: 'Automation Enrollment',
    value: ActivityFeedEventType.AUTOMATION_ENROLLED,
  },
  {
    label: 'Automation Completed',
    value: ActivityFeedEventType.AUTOMATION_COMPLETED,
  },
  {
    label: 'Poll Responses',
    value: ActivityFeedEventType.POLL_RESPONSE,
  },
  {
    label: 'Poll Comment',
    value: ActivityFeedEventType.POLL_EXTENDED_FEEDBACK,
  },
  {
    label: 'Milestone Achievements',
    value: ActivityFeedEventType.REFERRAL_MILESTONE_ACHIEVED,
  },
  {
    label: 'Milestone Email Sent',
    value: ActivityFeedEventType.REFERRAL_MILESTONE_EMAIL_SENT,
  },
  {
    label: 'Referrals',
    value: ActivityFeedEventType.REFERRAL,
  },
  {
    label: 'Subscribes',
    value: ActivityFeedEventType.SUBSCRIBE,
  },
  {
    label: 'Unsubscribes',
    value: ActivityFeedEventType.UNSUBSCRIBE,
  },
  {
    label: 'Upgrades',
    value: ActivityFeedEventType.UPGRADE,
  },
  {
    label: 'Downgrades',
    value: ActivityFeedEventType.DOWNGRADE,
  },
  {
    label: 'Payment Failed',
    value: ActivityFeedEventType.PAYMENT_FAILED,
  },
  {
    label: 'Payment Succeeded',
    value: ActivityFeedEventType.PAYMENT_SUCCEEDED,
  },
  {
    label: 'Gift Purchased',
    value: ActivityFeedEventType.GIFT_PURCHASED,
  },
];
