import { useCallback, useMemo, useState } from 'react';

import { BoostOfferSort } from '@/hooks/boosts/monetize/useBoostOffers';
import { Option } from '@/interfaces/general';
import { PUBLICATION_SIZE_OPTIONS } from '@/pages/Monetize/Boosts/Marketplace/constants';
import { BoostOffersFilterState } from '@/pages/Monetize/Boosts/Marketplace/types';
import convertToDays from '@/pages/Monetize/Boosts/utils/convertToDays';
import {
  FiltersPopopoverOperator,
  FiltersPopoverNumberInputSection,
  FiltersPopoverSection,
  FiltersPopoverSectionType,
  FiltersPopoverTimeframeUnits,
} from '@/ui/FiltersPopover/FiltersPopover.types';

const defaultSort: Option<BoostOfferSort> = {
  label: 'Featured',
  value: BoostOfferSort.FEATURED,
};

const useFilters = (
  tagOptions: Option[],
  languageOptions: Option[],
  countryOptions: Option[]
): BoostOffersFilterState => {
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<Option<BoostOfferSort>>(defaultSort);
  const [tagIds, setTagIds] = useState<string[]>([]);
  const [tagOperator, setTagOperator] = useState<FiltersPopopoverOperator>(FiltersPopopoverOperator.EQUAL);
  const [languageIds, setLanguageIds] = useState<string[]>([]);
  const [languageOperator, setLanguageOperator] = useState<FiltersPopopoverOperator>(FiltersPopopoverOperator.EQUAL);
  const [sizeScores, setSizeScores] = useState<string[]>([]);
  const [lastPostedWithinDaysAgo, setLastPostedWithinDaysAgo] = useState<number>();
  const [lastPostedWithinDaysAgoTimeFrame, setLastPostedWithinDaysAgoTimeFrame] =
    useState<FiltersPopoverTimeframeUnits>(FiltersPopoverTimeframeUnits.DAYS);
  const [eligibleCountries, setEligibleCountries] = useState<string[]>([]);
  const [minPerLeadCents, setMinPerLeadCents] = useState<number>();
  const [maxPerLeadCents, setMaxPerLeadCents] = useState<number>();
  const [minPayoutCents, setMinPayoutCents] = useState<number>();
  const [maxPayoutCents, setMaxPayoutCents] = useState<number>();

  const sections: FiltersPopoverSection[] = useMemo(
    () => [
      {
        type: FiltersPopoverSectionType.MULTI_SELECT,
        name: 'categories',
        title: 'Category',
        placeholderText: 'Categories',
        options: tagOptions,
        value: tagIds,
        maxVisibleSelectedOptions: 1,
        onSelect: setTagIds,
        operatorValue: tagOperator,
        operators: [FiltersPopopoverOperator.EQUAL, FiltersPopopoverOperator.NOT_EQUAL],
        onOperatorChange: setTagOperator,
        hasSearch: true,
      },
      {
        type: FiltersPopoverSectionType.MULTI_SELECT,
        name: 'languages',
        title: 'Publication language',
        placeholderText: 'Languages',
        options: languageOptions,
        value: languageIds,
        maxVisibleSelectedOptions: 1,
        onSelect: setLanguageIds,
        operatorValue: languageOperator,
        operators: [FiltersPopopoverOperator.EQUAL, FiltersPopopoverOperator.NOT_EQUAL],
        onOperatorChange: setLanguageOperator,
      },
      {
        type: FiltersPopoverSectionType.MULTI_SELECT,
        name: 'size_scores',
        title: 'Publication size',
        placeholderText: 'Size',
        options: PUBLICATION_SIZE_OPTIONS,
        value: sizeScores,
        maxVisibleSelectedOptions: 1,
        onSelect: setSizeScores,
      },
      {
        type: FiltersPopoverSectionType.NUMBER,
        name: 'last_posted_within_days',
        title: 'Last posted within',
        placeholderText: 'Enter number',
        value: lastPostedWithinDaysAgo,
        min: 0,
        max: 1000,
        onChange: setLastPostedWithinDaysAgo,
        onTimeframeChange: setLastPostedWithinDaysAgoTimeFrame,
        timeframeValue: lastPostedWithinDaysAgoTimeFrame,
        timeframeUnits: [
          FiltersPopoverTimeframeUnits.DAYS,
          FiltersPopoverTimeframeUnits.WEEKS,
          FiltersPopoverTimeframeUnits.MONTHS,
        ],
      } as FiltersPopoverNumberInputSection,
      {
        type: FiltersPopoverSectionType.DIVIDER,
        name: 'countries_divider',
      },
      {
        type: FiltersPopoverSectionType.MULTI_SELECT,
        name: 'countries',
        title: 'Eligible Countries',
        placeholderText: 'Countries',
        options: countryOptions,
        value: eligibleCountries,
        maxVisibleSelectedOptions: 1,
        onSelect: setEligibleCountries,
        hasSearch: true,
      },
      {
        type: FiltersPopoverSectionType.CURRENCY_RANGE,
        name: 'email_payout',
        title: 'Email payout per send',
        lowerBoundValue: minPerLeadCents,
        upperBoundValue: maxPerLeadCents,
        lowerBoundPlaceholder: 'No minimum',
        upperBoundPlaceholder: 'No maximum',
        onLowerBoundChange: setMinPerLeadCents,
        onUpperBoundChange: setMaxPerLeadCents,
      },
      {
        type: FiltersPopoverSectionType.CURRENCY_RANGE,
        name: 'per_send',
        title: 'Web payout per subscriber',
        lowerBoundValue: minPayoutCents,
        upperBoundValue: maxPayoutCents,
        lowerBoundPlaceholder: 'No minimum',
        upperBoundPlaceholder: 'No maximum',
        onLowerBoundChange: setMinPayoutCents,
        onUpperBoundChange: setMaxPayoutCents,
      },
    ],
    [
      tagOptions,
      tagIds,
      tagOperator,
      languageOptions,
      languageIds,
      languageOperator,
      sizeScores,
      lastPostedWithinDaysAgo,
      lastPostedWithinDaysAgoTimeFrame,
      countryOptions,
      eligibleCountries,
      minPerLeadCents,
      maxPerLeadCents,
      minPayoutCents,
      maxPayoutCents,
    ]
  );

  const clearFilters = useCallback(() => {
    setTagIds([]);
    setTagOperator(FiltersPopopoverOperator.EQUAL);
    setLanguageIds([]);
    setLanguageOperator(FiltersPopopoverOperator.EQUAL);
    setSizeScores([]);
    setLastPostedWithinDaysAgo(undefined);
    setLastPostedWithinDaysAgoTimeFrame(FiltersPopoverTimeframeUnits.DAYS);
    setEligibleCountries([]);
    setMinPerLeadCents(undefined);
    setMaxPerLeadCents(undefined);
    setMinPayoutCents(undefined);
    setMaxPayoutCents(undefined);
  }, []);

  const hasActiveFilters = useMemo(
    () =>
      search !== '' ||
      sort.value !== BoostOfferSort.FEATURED ||
      tagIds.length > 0 ||
      languageIds.length > 0 ||
      sizeScores.length > 0 ||
      !!lastPostedWithinDaysAgo ||
      eligibleCountries.length > 0 ||
      !!minPerLeadCents ||
      !!maxPerLeadCents ||
      !!minPayoutCents ||
      !!maxPayoutCents,
    [
      search,
      sort.value,
      tagIds.length,
      languageIds.length,
      sizeScores.length,
      lastPostedWithinDaysAgo,
      eligibleCountries.length,
      minPerLeadCents,
      maxPerLeadCents,
      minPayoutCents,
      maxPayoutCents,
    ]
  );

  const resetAllFilters = useCallback(() => {
    setSearch('');
    setSort(defaultSort);
    clearFilters();
  }, [clearFilters]);

  return {
    sections,
    clearFilters,
    filterValues: {
      search,
      sort,
      tagIds,
      tagOperator,
      languageIds,
      languageOperator,
      sizeScores,
      lastPostedWithinDaysAgo: convertToDays(lastPostedWithinDaysAgoTimeFrame, lastPostedWithinDaysAgo),
      eligibleCountries,
      minPerLeadCents,
      maxPerLeadCents,
      minPayoutCents,
      maxPayoutCents,
    },
    setSearch,
    setSort,
    hasActiveFilters,
    resetAllFilters,
  };
};

export default useFilters;
