import { useEffect } from 'react';
import { Question } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';
import { Tooltip } from '@/routes/website/_components/UI/Tooltip';
import { cn } from '@/utils/cn';

import { useNavbarContext } from '../../../NavbarContext';

type OnClickSettingsProps = {
  disabled?: boolean;
};

export const OnClickSettings = ({ disabled }: OnClickSettingsProps) => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  const hasLink =
    selectedContent?.type === 'navbar_item' &&
    selectedContent?.attrs?.href !== undefined &&
    selectedContent?.attrs?.href !== null;
  const hasAction =
    selectedContent?.type === 'navbar_item' &&
    selectedContent?.attrs?.action !== undefined &&
    selectedContent?.attrs?.action !== null;

  const hasOnClick =
    selectedContent?.type === 'navbar_item' &&
    selectedContent?.attrs?.onClick !== undefined &&
    selectedContent?.attrs?.onClick !== null;

  useEffect(() => {
    if (!selectedContent?.attrs?.id) return;
    if (selectedContent?.type !== 'navbar_item') return;
    if (selectedContent?.attrs?.type === 'search' || selectedContent?.attrs?.type === 'dropdown') return;

    if (!hasOnClick && hasAction) {
      onUpdateNodeAttributes(selectedContent?.attrs?.id, { onClick: 'action' });
      return;
    }

    if (!hasOnClick && hasLink) {
      onUpdateNodeAttributes(selectedContent.attrs?.id, { onClick: 'link' });
    }
  }, [hasLink, hasAction, hasOnClick, selectedContent?.attrs, onUpdateNodeAttributes, selectedContent?.type]);

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_item') return null;
  if (selectedContent.attrs?.type === 'search' || selectedContent.attrs?.type === 'dropdown') return null;

  const currentValue = selectedContent.attrs?.onClick || 'link';

  const handleOnClickChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;

    onUpdateNodeAttributes(selectedContent.attrs?.id, { onClick: value });
  };

  return (
    <div className={cn('flex items-center justify-stretch gap-2', disabled ? 'opacity-50 pointer-events-none' : '')}>
      <div className="flex items-center gap-1 w-[80px] min-w-[80px]">
        <Text variant="secondary" size="2xs" weight="medium">
          On Click
        </Text>
        <Tooltip
          placement="bottom-end"
          center={
            currentValue === 'action'
              ? 'Clicking the button will do the selected action'
              : 'Clicking the button will navigate to the selected link'
          }
        >
          <Question size={16} weight="bold" className="text-wb-secondary" />
        </Tooltip>
      </div>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="link"
          value={currentValue}
          onValueChange={handleOnClickChange}
        >
          <ToggleGroupItem value="link" className="grow">
            <Text variant="inherit" size="2xs" weight="medium">
              Link
            </Text>
          </ToggleGroupItem>
          <ToggleGroupItem value="action" className="grow">
            <Text variant="inherit" size="2xs" weight="medium">
              Action
            </Text>
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
