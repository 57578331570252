import { Dispatch, SetStateAction } from 'react';

import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import { DreamEditor } from '@/routes/website/_components/DreamEditor';

import '@shared/dream-components/style.css';

interface Props {
  setInsertPanelOpen?: Dispatch<SetStateAction<boolean>>;
  className?: string;
  type?: 'page' | 'template';
  isFullPageHeight?: boolean;
}

export const Editor = ({ setInsertPanelOpen, className, type = 'page', isFullPageHeight = true }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const { settings } = useSettings();

  return (
    <div className="grow overflow-y-scroll min-h-0 h-full">
      <PublicationProvider id={currentPublicationId}>
        {settings && (
          <DreamEditor
            publicationId={currentPublicationId}
            settings={settings}
            className={className}
            setInsertPanelOpen={setInsertPanelOpen}
            type={type}
            isFullPageHeight={isFullPageHeight}
          />
        )}
      </PublicationProvider>
    </div>
  );
};
