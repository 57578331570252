import { Document as TiptapDocument } from '@tiptap/extension-document';

export type EditorDocumentType = 'template' | 'page' | 'signup_modal' | 'recommendations_modal' | 'login_modal' | 'footer';

export const contentByType: Record<EditorDocumentType, string> = {
  template: '(block|columns|section|tableBlock|footnotesNode|paywallBreak|serviceEmbed|codeBlockGroup)+',
  page: 'section+',
  footer: 'section+',
  signup_modal: 'signupModal',
  recommendations_modal: 'recommendationsModal',
  login_modal: 'loginModal',
} as const;

export const getDocument = (type: EditorDocumentType = 'page') => {
  return TiptapDocument.extend({ content: contentByType[type] });
};
