import { useNavigate } from 'react-router-dom';
import { SparklesIcon } from '@heroicons/react/20/solid';

import { useSettings } from '@/context/settings-context';
import { Button } from '@/ui/Button';
import { SettingsContainer, SettingsGroup } from '@/ui/Settings/SettingsContainer';

interface Props {
  isPremiumActive?: boolean;
}

const UpgradePageDecisionTree = ({ isPremiumActive = false }: Props) => {
  const { settings } = useSettings();
  const navigate = useNavigate();

  if (settings?.premium_subscriptions || (settings?.landing_pages && !isPremiumActive)) {
    return (
      <SettingsContainer>
        <SettingsGroup
          title="Paid Subscriber Settings"
          description="Toggle on paid subscriber settings from the beehiiv settings page."
        >
          <Button Icon={SparklesIcon} onClick={() => navigate('/monetize/subscriptions/paid_tiers')} className="w-fit">
            Go to Paid Subscription Settings
          </Button>
        </SettingsGroup>
      </SettingsContainer>
    );
  }

  if (!settings?.premium_subscriptions && !isPremiumActive) {
    return (
      <SettingsContainer>
        <SettingsGroup
          title="Website Upgrade Page"
          description="Customize your site's upgrade page on a beehiiv paid plan."
        >
          <Button
            Icon={SparklesIcon}
            onClick={() => navigate('/settings/workspace/billing_and_plan')}
            className="w-fit"
          >
            Upgrade to Scale
          </Button>
        </SettingsGroup>
      </SettingsContainer>
    );
  }

  return null;
};

export default UpgradePageDecisionTree;
