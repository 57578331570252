import React, { createContext, useMemo } from 'react';

import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { Settings } from '@/interfaces/setting';

import { useCurrentPublicationState } from './current-publication-context';

interface ISettingsContext {
  settings?: Settings;
  isLoading: boolean;
  isError: boolean;
  reloadSettings: () => void;
}

const SettingsContext = createContext<ISettingsContext | undefined>(undefined);

SettingsContext.displayName = 'SettingsContext';

const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const [publicationId] = useCurrentPublicationState();

  const settingsQuery = usePublicationSettings(publicationId);

  const { data: settings, isLoading, isError } = settingsQuery;

  const contextProviderProps = useMemo(() => {
    const reloadSettings = () => settingsQuery.refetch();

    return { reloadSettings, settings, isLoading, isError };
  }, [isError, isLoading, settings, settingsQuery]);

  return <SettingsContext.Provider value={contextProviderProps}>{children}</SettingsContext.Provider>;
};

function useSettings() {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }

  return context;
}

export { SettingsContext, SettingsProvider, useSettings };
