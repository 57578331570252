/* eslint-disable no-param-reassign */

import { useEffect, useRef } from 'react';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Editor, JSONContent } from '@tiptap/core';
import { Fragment, Slice } from '@tiptap/pm/model';

import { INSERT_PANEL_DROP_EVENT_IDENTIFIER } from '../../extensions/constants';
import { components } from '../constants';
import { ComponentKey } from '../types';

export const Draggable = ({ id, children, editor }: React.PropsWithChildren<{ id: ComponentKey; editor: Editor }>) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return () => {};

    const blockJson = components[id].block() as JSONContent;

    return draggable({
      element: ref.current,
      onDragStart: () => {
        const content = Array.isArray(blockJson) ? blockJson : [blockJson];

        const frag = Fragment.from(content.map((c) => editor.schema.nodeFromJSON(c)));

        const slice = new Slice(frag, 0, 0);

        editor.storage.hover.insertNodeData = {
          slice,
          json: blockJson,
        };

        editor.view.dragging = {
          slice,
          move: false,
        };
      },
      getInitialDataForExternal: () => {
        return {
          'text/plain': INSERT_PANEL_DROP_EVENT_IDENTIFIER,
        };
      },
    });
  }, [ref, id, editor]);

  return (
    <div ref={ref} style={{ background: 'transparent', cursor: 'grab' }}>
      {children}
    </div>
  );
};
