import { useState } from 'react';
import { DotsThree, Eye, EyeSlash, Pencil, SquareHalfBottom } from '@phosphor-icons/react';

import { cn } from '@/utils/cn';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../UI/DropdownMenu';
import { Text } from '../../UI/Text';

interface Props {
  title: string;
  isEnabled: boolean;
  onUpdate: (value: boolean) => void;
  onClick: () => void;
  editText?: string;
}

const NavigationItem = ({ title, onUpdate, isEnabled, onClick, editText }: Props) => {
  const isNavbar = title === 'Navbar';
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div
      className={cn('flex items-center gap-1 cursor-pointer border rounded-md p-1.5 w-full justify-between group', {
        'border-transparent': true,
        'opacity-50 hover:opacity-100 transition-opacity duration-100': !isEnabled,
      })}
    >
      <div className="flex items-center gap-1">
        {isNavbar ? (
          <SquareHalfBottom className="text-wb-accent transform rotate-180" weight="bold" />
        ) : (
          <SquareHalfBottom className="text-wb-accent" weight="bold" />
        )}
        <Text size="xs" className="text-inherit">
          {title}
        </Text>
      </div>
      <DropdownMenu open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
        <div className="flex items-center gap-1">
          {!isEnabled ? (
            <button type="button" onClick={() => onUpdate(!isEnabled)} className="w-fit">
              <EyeSlash weight="bold" className={cn('my-auto text-wb-secondary h-4 w-4 group-hover:hidden')} />{' '}
              <Eye weight="bold" className={cn('my-auto text-wb-accent h-4 w-4 group-hover:block hidden')} />
            </button>
          ) : (
            <DropdownMenuTrigger asChild>
              <div className="hover:bg-wb-highlight rounded-full p-0.5 cursor-pointer">
                <DotsThree weight="bold" className="my-auto text-wb-secondary h-4 w-4" />
              </div>
            </DropdownMenuTrigger>
          )}
        </div>
        <DropdownMenuContent sideOffset={2} align="start" className="w-[170px]">
          <DropdownMenuItem
            LeftIcon={isEnabled ? EyeSlash : Eye}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onUpdate(!isEnabled);
              setIsDropdownOpen(false);
            }}
          >
            {isEnabled ? 'Disable for this page' : 'Enable for this page'}
          </DropdownMenuItem>
          <DropdownMenuItem
            LeftIcon={Pencil}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick();
              setIsDropdownOpen(false);
            }}
          >
            {editText || 'Edit'}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default NavigationItem;
