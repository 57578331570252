import { Fragment, useMemo } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { cx } from 'class-variance-authority';

import IconButton from '@/components/IconHelpers/IconButton';
import { Typography } from '@/components/Typography';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { ButtonGroup } from '@/ui/Button/ButtonGroup/ButtonGroup';
import { ScrollableContent } from '@/ui/ScrollableContent';

import { Dropdown } from '../Dropdown';
import useResourceListFilters from '../ResourceListFilters/useResourceListFilters';

import { FiltersPopover as FiltersPopoverProps } from './FiltersPopover.types';
import FiltersPopoverSection from './FiltersPopoverSection';

const FiltersPopover = ({
  sections,
  savedFilterOptions,
  savedFilterValue,
  onSelectSavedFilter,
  onRenameSavedFilter,
  onDeleteSavedFilter,
  onClearAll,
  onSaveFilters,
  popoverWidthClassName = "w-80"
}: FiltersPopoverProps): JSX.Element => {
  const { activeFilterSectionsCount } = useResourceListFilters({ popoverSections: sections });
  const hasActiveFilters = activeFilterSectionsCount > 0;

  const savedFilterOptionsWithActions = useMemo(() => {
    if (!savedFilterOptions) return [];

    return savedFilterOptions?.map((option) => ({
      ...option,
      optionAction: (
        <div className="flex items-center">
          <IconButton
            backgroundColor="text-surface-100"
            textColor="text-surface-500"
            className="hover:text-surface-900 active:text-surface-400 transition-colors"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRenameSavedFilter?.(option.value);
            }}
          >
            <PencilIcon />
          </IconButton>

          <IconButton
            backgroundColor="text-surface-100"
            textColor="text-surface-500"
            className="hover:text-surface-900 active:text-surface-400 transition-colors"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDeleteSavedFilter?.(option.value);
            }}
          >
            <TrashIcon />
          </IconButton>
        </div>
      ),
    }));
  }, [onDeleteSavedFilter, onRenameSavedFilter, savedFilterOptions]);

  return (
    <Popover className={cx('relative inline-block w-full sm:w-auto')}>
      {({ open }) => (
        <>
          <ButtonGroup variant="primary-inverse" className="w-full sm:w-auto">
            <Popover.Button
              as={Button}
              shade="dark"
              className={cx(
                open ? 'bg-neutrals-200 border-transparent outline-none ring-1 ring-action-primary-inverse-300 z-10' : '',
                'w-full sm:w-auto'
              )}
            >
              <div className="flex flex-row gap-2 items-center">
                <AdjustmentsHorizontalIcon className={cx('w-4 h-4 text-grey-600')} />
                <span>Filters</span>
                {hasActiveFilters && (
                  <>
                    <span className="border border-surface-20 border-l-0 h-5" />
                    <Badge type="tertiary" size="sm" className="h-5">
                      {activeFilterSectionsCount}
                    </Badge>
                  </>
                )}
              </div>
            </Popover.Button>
          </ButtonGroup>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className={cx(
              "z-50 absolute mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none left-0 sm:right-0 sm:left-auto",
              popoverWidthClassName
            )}>
              <ScrollableContent className="py-6 space-y-6">
                <div className="px-4 space-x-2 flex justify-between align-middle">
                  <Typography token="font-medium/text/base" className="leading-8">
                    Filters
                  </Typography>

                  {savedFilterOptions && savedFilterOptions.length > 0 && (
                    <Dropdown
                      name="filters-popover-select"
                      options={savedFilterOptionsWithActions}
                      value={savedFilterValue || ""}
                      placeholderText="Saved filters"
                      onSelect={(_name, value) => { onSelectSavedFilter?.(value); }}
                      className="w-full max-w-44"
                      optionsContainerClassNames={{ width: "w-72 right-0" }}
                    />
                  )}
                </div>

                <div className="px-4 space-y-6">
                  {sections.map((section) => (
                    <FiltersPopoverSection
                      key={section.name}
                      {...section}
                    />
                  ))}
                </div>
              </ScrollableContent>

              <div className="w-full px-4 py-3 flex flex-row justify-between items-center bg-surface-50 rounded-b-md">
                <div>
                  {onSaveFilters && hasActiveFilters && (
                    <Button variant="flush" onClick={onSaveFilters} className="text-gray-600">
                      Save active filters
                    </Button>
                  )}
                </div>

                <Button variant="primary-inverse" onClick={onClearAll} className="w-auto">
                  Clear all
                </Button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default FiltersPopover;
