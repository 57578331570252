import AttributeInput from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/AttributeInput';

import { useNavbarContext } from '../../../NavbarContext';

export function SubtitleSettings() {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown_item') return null;

  const contentValue =
    (selectedContent?.attrs && 'description' in selectedContent.attrs && selectedContent.attrs.description) || '';

  const handleSubtitleChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { description: value });
  };

  return (
    <AttributeInput title="Description" value={contentValue} onChange={(e) => handleSubtitleChange(e.target.value)} />
  );
}
