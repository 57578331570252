import { getColumnsElementRenderHTML } from '@shared/dream-components';
import { JSONContent, mergeAttributes, Node } from '@tiptap/core';
import { NodeSelection } from '@tiptap/pm/state';

import { getJSONAttribute, getStringAttribute } from '../../utils/attributesUtils';
import { Container } from '../Container';
import { getDataAttributes } from '../utils';

export enum ColumnLayout {
  Custom = 'custom',
  SidebarLeft = 'sidebar-left',
  SidebarRight = 'sidebar-right',
  TwoColumn = 'two-column',
}

export const COLUMN_DEFAULTS = {
  padding: '0px',
  paddingRoot: '20px 30px 20px 30px',
  paddingMobile: '5px 0px 5px 0px',
  paddingRootMobile: '5px 30px 5px 30px',
  margin: '0px 0px 0px 0px',
  gap: '30px',
  gapMobile: '16px',
  gapMobileVertical: '10px',
  borderRadius: '0px 0px 0px 0px',
  boxShadow: 'none',
};

export const Columns = Node.create({
  name: 'columns',

  group: 'block',

  content: 'container{2,}',

  defining: true,

  isolating: true,

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      gridTemplateColumns: {
        default: '1fr 1fr',
        parseHTML: (element) => element.getAttribute('data-grid-template-columns'),
        renderHTML: (attributes) => ({
          'data-grid-template-columns': attributes.gridTemplateColumns,
        }),
      },
      width: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
        }),
      },
      width_max: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
        }),
      },
      padding: {
        default: '20px 0px 20px 0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
        }),
      },
      margin: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => ({
          'data-margin': attributes.margin,
        }),
      },
      backgroundColor: {
        default: '#FFFFFFFF',
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({
          'data-background-color': attributes.backgroundColor,
        }),
      },
      backgroundImage: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-background-image'),
        renderHTML: (attributes) => ({
          'data-background-image': attributes.backgroundImage,
        }),
      },
      backgroundDarken: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-background-darken'),
        renderHTML: (attributes) => ({
          'data-background-darken': attributes.backgroundDarken,
        }),
      },
      gap: {
        default: '30px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({
          'data-gap': attributes.gap,
        }),
      },
      borderRadius: {
        default: '0px 0px 0px 0px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({
          'data-border-radius': attributes.borderRadius,
        }),
      },
      isFullHeight: {
        default: false,
        parseHTML: (element) => element.getAttribute('data-is-full-height'),
        renderHTML: (attributes) => ({
          'data-is-full-height': attributes.isFullHeight,
        }),
      },
      mobileLayout: getJSONAttribute('mobileLayout', 'data-mobile-layout', undefined),
      mobile_direction: getStringAttribute('mobile_direction', 'data-mobile-direction', 'direction_vertical'),
    };
  },

  addCommands() {
    const setColumnsContent: JSONContent = {
      type: this.name,
      content: new Array(2).fill({ type: Container.name, attrs: { flexBasis: '50%' } }),
    };
    return {
      setColumns:
        () =>
        ({ commands }) => {
          return commands.insertContent(setColumnsContent);
        },
      setColumnCount:
        (count) =>
        ({ dispatch, tr, editor }) => {
          const { selection } = editor.state;

          let activeNode;
          let activePos;
          if (selection instanceof NodeSelection) {
            activeNode = selection.node;
            activePos = selection.from;
          } else {
            // leaf node, select the parent
            const parentNode = selection.$anchor.parent;
            activeNode = parentNode;
            activePos = selection.$head.start(selection.$head.depth) - 1;
          }

          const isColumns = activeNode.type.name === 'columns';

          if (!activeNode || !dispatch || !isColumns || count === 1) {
            // we don't want to remove columns, should we unwrap??
            return true;
          }

          const columnsNode = activeNode;
          const columnsPos = activePos;
          const columnsCount = columnsNode.childCount;

          if (columnsCount < count) {
            const { gridTemplateColumns } = columnsNode.attrs;

            if (gridTemplateColumns) {
              const newGridTemplateColumns = gridTemplateColumns.split(' ');
              newGridTemplateColumns.push('1fr');

              tr.setNodeAttribute(columnsPos, 'gridTemplateColumns', newGridTemplateColumns.join(' '));
            } else {
              tr.setNodeAttribute(columnsPos, 'gridTemplateColumns', Array(count).fill('1fr').join(' '));
            }

            tr.insert(columnsPos + columnsNode.nodeSize - 1, editor.schema.node(Container.name));
          } else {
            // Remove column
            const lastColumnNode = columnsNode.child(columnsCount - 1);
            const { gridTemplateColumns } = columnsNode.attrs;

            if (gridTemplateColumns) {
              const newGridTemplateColumns = gridTemplateColumns.split(' ');
              newGridTemplateColumns.pop();
              tr.setNodeAttribute(columnsPos, 'gridTemplateColumns', newGridTemplateColumns.join(' '));
            } else {
              tr.setNodeAttribute(columnsPos, 'gridTemplateColumns', Array(count).fill('1fr').join(' '));
            }

            tr.delete(
              columnsPos + columnsNode.nodeSize - lastColumnNode.nodeSize - 1,
              columnsPos + columnsNode.nodeSize - 1
            );
          }
          return true;
        },
      addDreamColumnAfter:
        (pos) =>
        ({ dispatch, tr, editor }) => {
          const { selection } = editor.state;

          let activeNode;
          let activePos;
          if (selection instanceof NodeSelection) {
            activeNode = selection.node;
            activePos = selection.$anchor.pos;
          } else {
            // leaf node, select the parent
            const parentNode = selection.$anchor.parent;
            activeNode = parentNode;
            activePos = selection.$head.start(selection.$head.depth) - 1;
          }

          const isColumns = activeNode.type.name === 'columns';

          if (!activeNode || !dispatch || !isColumns) {
            // we don't want to remove columns, should we unwrap??
            return true;
          }

          const columnsNode = activeNode;
          const columnsPos = activePos;
          const columnsCount = columnsNode.childCount;

          const { gridTemplateColumns } = columnsNode.attrs;
          if (gridTemplateColumns) {
            const newGridTemplateColumns = gridTemplateColumns.split(' ');
            newGridTemplateColumns.push('1fr');
            tr.setNodeAttribute(columnsPos, 'gridTemplateColumns', newGridTemplateColumns.join(' '));
          } else {
            tr.setNodeAttribute(
              columnsPos,
              'gridTemplateColumns',
              Array(columnsCount + 1)
                .fill('1fr')
                .join(' ')
            );
          }
          tr.insert(pos, editor.schema.node(Container.name));
          return true;
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    // TODO: fix typing error
    return getColumnsElementRenderHTML({
      node: node as any,
      HTMLAttributes: mergeAttributes(getDataAttributes(HTMLAttributes), { 'data-type': this.name }),
    });
  },

  // Commenting this out first to look for other implementation
  // addProseMirrorPlugins() {
  //   const { editor } = this;

  //   const calculateDecorators = ({ doc, selection }: EditorState) => {
  //     const handleDecorations: Decoration[] = [];

  //     for (let i = selection.$head.depth; i > 0; i -= 1) {
  //       const node = selection.$head.node(i);
  //       if (node?.type.name === 'container') {
  //         const columnPos = selection.$head.before(i);
  //         handleDecorations.push(
  //           Decoration.widget(
  //             columnPos + node.nodeSize - 1,
  //             () => {
  //               const div = document.createElement('div');
  //               div.className =
  //                 'absolute top-1/2 -translate-y-1/2 right-0 translate-x-1/2 z-999 rounded-full bg-indigo-400 text-white cursor-pointer';
  //               div.setAttribute('data-position', columnPos.toString());
  //               div.contentEditable = 'false';
  //               div.innerHTML = '+';

  //               div.onclick = (e) => {
  //                 e.stopPropagation();
  //                 editor.commands.addDreamColumnAfter(columnPos + node.nodeSize);
  //               };
  //               return div;
  //             },
  //             {
  //               ignoreSelection: true,
  //               stopEvent: () => true,
  //             }
  //           )
  //         );
  //       }
  //     }

  //     return DecorationSet.create(doc, handleDecorations);
  //   };

  //   const addColumnHandlePlugin: Plugin<DecorationSet> = new Plugin<DecorationSet>({
  //     state: {
  //       init() {
  //         return DecorationSet.empty;
  //       },
  //       apply(tr, decorationSet, oldState, newState) {
  //         if (!oldState.selection.eq(newState.selection)) {
  //           return calculateDecorators(newState);
  //         }
  //         return decorationSet.map(tr.mapping, tr.doc);
  //       },
  //     },
  //     props: {
  //       decorations(state) {
  //         return this.getState(state);
  //       },
  //     },
  //   });

  //   return [addColumnHandlePlugin];
  // },
});

export default Columns;
