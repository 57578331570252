import { FC, useEffect, useState } from 'react';
import { PricingElement, Tier, TPricingAttributes } from '@shared/dream-components';
import { NodeViewProps, NodeViewWrapper, useReactNodeView } from '@tiptap/react';

import NotConnected from '../../components/NotConnected';
import TiersSelectionModal from '../modal/TiersSelectionModal';

export const PricingView: FC<NodeViewProps> = (nodeViewProps) => {
  const { onDragStart } = useReactNodeView();
  const { node, editor, getPos } = nodeViewProps;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { insertedFromSidebar, hasFakeData } = node.attrs;

  const tiers: Tier[] = node.attrs.data || [];
  const activeTiers =
    tiers
      .filter((tier) => !!tier.enabled)
      .map((tier) => {
        return {
          ...tier,
          prices: tier.prices.map((price) => {
            return {
              ...price,
              features: Array.isArray(price.features) ? [...price.features] : [],
            };
          }),
        };
      }) || [];
  const selectedTier = activeTiers[0];
  const selectedPrice = selectedTier ? selectedTier.prices[0] : undefined;

  useEffect(() => {
    if (insertedFromSidebar && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [insertedFromSidebar, isModalOpen]);

  const handleCloseModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', false);
      return true;
    });
    setIsModalOpen(false);
  };

  const handleOpenSelectionModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
    setIsModalOpen(true);
  };

  return (
    <NodeViewWrapper data-drag-handle data-id={node.attrs.id}>
      <PricingElement
        element={{
          type: 'pricing',
          attrs: {
            ...node.attrs,
            id: node.attrs.id,
            data: activeTiers,
          } as TPricingAttributes,
        }}
        attributes={{
          'data-node-view-wrapper': '',
        }}
        onDragStart={onDragStart}
        setSelectedTier={() => {}}
        setSelectedPrice={() => {}}
        isStripeLoading={false}
        email=""
        onEmailChange={() => {}}
        showTaxIdField={false}
        taxId=""
        isSubmitting={false}
        onTierClick={() => {}}
        onPriceClick={() => {}}
        stripeSessionIds={null}
        onTaxIdChange={() => {}}
        isInternalCheckout={node.attrs.internalCheckout}
        showContinueButton={false}
        donationAmountCents={0}
        onDonationAmountCentsChange={() => {}}
        selectedTier={selectedTier}
        selectedPrice={selectedPrice}
      >
        <NotConnected text="Not connected to real tiers" onClick={handleOpenSelectionModal} />
        {!hasFakeData && <NotConnected text="Change tiers" onClick={handleOpenSelectionModal} />}
        {insertedFromSidebar && (
          <TiersSelectionModal
            key={node.attrs.id}
            pos={getPos()}
            node={node}
            editor={editor}
            onClose={handleCloseModal}
            isOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </PricingElement>
    </NodeViewWrapper>
  );
};
