import { Tab } from '@headlessui/react';
import cx from 'classnames';

import { WalkthroughPortal } from '@/components/Walkthrough';
import { useWalkthroughContext } from '@/context/walkthrough-context';
import { TabDetails } from '@/interfaces/tab';
import Tooltip from '@/ui/Tooltip';

import useDashboardUrlState from '../../hooks/useDashboardUrlState';
import { TabTypes } from '../../types';

const SectionTab = ({
  text,
  onClick,
  isSingleTab,
  disabled,
  disabledText,
}: {
  text: string;
  onClick: () => void;
  isSingleTab: boolean;
  disabled?: boolean;
  disabledText?: string;
}) => {
  const tooltipId = `${text}-tooltip-${Math.random().toString(36).substring(2, 15)}`;
  if (disabled) {
    return (
      <div
        className={cx(
          'py-2 rounded-md text-sm font-medium leading-5 text-center',
          'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none',
          'text-gray-400 cursor-not-allowed select-none',
          isSingleTab ? 'bg-white shadow text-action-primary-900 px-6 cursor-default' : ''
        )}
      >
        <Tooltip
          tooltipClass="text-center"
          id={tooltipId}
          text={disabledText || ''}
          showIcon={false}
          isEnabled={disabledText !== undefined || disabledText !== ''}
          autoWidth={false}
        >
          {text}
        </Tooltip>
      </div>
    );
  }
  return (
    <Tab
      onClick={onClick}
      className={({ selected }) =>
        cx(
          isSingleTab ? 'px-6 cursor-default' : 'w-full',
          'py-2 px-2 md:px-3 w-full rounded-md text-xs font-medium ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none leading-4',
          'ring-white ring-opacity-60 ring-offset-2 ring-offset-white focus:outline-none',
          selected ? 'bg-white shadow text-gray-900' : 'hover:bg-surface-200 text-gray-700'
        )
      }
    >
      {text}
    </Tab>
  );
};

const SectionTabList = ({ tabList }: { tabList: TabDetails[] }) => {
  const { handleSetTab } = useDashboardUrlState();
  const isSingleTab = tabList.length === 1;

  return (
    <Tab.List className="p-1 md:p-2 flex space-x-0 md:space-x-1 max-w-md rounded-lg bg-surface-100">
      {tabList.map(({ name: category, disabled, disabledText }: TabDetails) => (
        <SectionTab
          key={category}
          text={category}
          onClick={() => handleSetTab(category as TabTypes)}
          isSingleTab={isSingleTab}
          disabled={disabled}
          disabledText={disabledText}
        />
      ))}
    </Tab.List>
  );
};

type Props = React.PropsWithChildren<{
  tabList: TabDetails[];
  actionChildren?: React.ReactNode;
  className?: string;
}>;
type NoListProps = Omit<Props, 'tabList'>;

const SectionTabPanel = ({ children }: NoListProps) => {
  return <Tab.Panel className="w-full">{children}</Tab.Panel>;
};

const SectionTabs = ({ children, tabList, actionChildren }: Props) => {
  const { currentTab } = useDashboardUrlState();
  const { currentStep, handleNextStep, handlePreviousStep, handleFinishWalkthrough } = useWalkthroughContext();
  const defaultIndex = currentTab ? tabList.findIndex((tab) => tab.name === currentTab) : 0;

  return (
    <Tab.Group defaultIndex={defaultIndex}>
      <div className="flex flex-col space-y-6">
        <WalkthroughPortal
          isActive={currentStep === 6}
          title="Analytics Navigation"
          description="Navigate between tabs to see targeted areas of data analytics to help you manage and grow your newsletter on the dashboard."
          arrowDirection="top"
          hasOverlay
          currentStep={6}
          totalSteps={7}
          continueButton={{
            text: 'Next',
            onClick: () => handleNextStep(),
          }}
          backButton={{
            text: 'Back',
            onClick: () => handlePreviousStep('/automations'),
          }}
          onClose={handleFinishWalkthrough}
        >
          <div className="space-x-2 justify-between items-center grid lg:grid-cols-3 grid-cols-1">
            <div className="flex justify-between col-span-2 items-center lg:pr-2.5 pr-0 w-full">
              <div className="flex items-end flex-col lg:items-center lg:flex-row rounded-lg bg-surface-100 border border-surface-grey-200 w-full">
                <div className="w-full md:w-auto">
                  <SectionTabList tabList={tabList} />
                </div>
                {actionChildren && <div className="flex lg:w-fit lg:p-0 p-2 ml-auto">{actionChildren}</div>}
              </div>
            </div>
          </div>
        </WalkthroughPortal>
        <Tab.Panels className="mt-12">{children}</Tab.Panels>
      </div>
    </Tab.Group>
  );
};

export { SectionTabPanel, SectionTabs };
