import { useCallback } from 'react';

import { SimpleInputWrapper } from '@/routes/website/_components/UI/Input';
import { Length, ScalarUnitInput } from '@/routes/website/_components/UI/ScalarUnitInput';
import { Text } from '@/routes/website/_components/UI/Text';

import { AttributeSettingProps } from '../../types';

type Props = AttributeSettingProps & {
  property?: string;
};

export const ImageWidthSettings = ({ editor, activeNodeResult, property = 'width' }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const handleChange = useCallback(
    (length: Length) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, Number(length.value));
        return true;
      });
    },
    [editor, activeNodePos, property]
  );

  return (
    <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
      <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
        Width
      </Text>
      <SimpleInputWrapper className="h-[32px] relative">
        <ScalarUnitInput
          key={`${activeNodePos}-button-text`}
          value={{ value: Number(activeNodeAttributes[property] || activeNodeAttributes.width), unit: 'px' }}
          units={['px']}
          onChange={handleChange}
        />
      </SimpleInputWrapper>
    </div>
  );
};
