import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

const RECALIBRATE_DELAY = 500;

const useContainerRect = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const [previewRect, setPreviewRect] = useState<Partial<DOMRect>>({
    width: 0,
    height: 0,
  });
  const previewContainerRef = useRef<HTMLDivElement>(null);
  const [isRendered, setIsRendered] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recalibratePreviewRect = useCallback(
    debounce(() => {
      if (!previewContainerRef.current) return;
      const rect = previewContainerRef.current.getBoundingClientRect();
      setPreviewRect(rect);
    }, 1),
    [previewContainerRef]
  );

  // Handle initial render detection
  useEffect(() => {
    if (!enabled || isRendered) return () => {};

    // Create mutation observer to watch for when our ref element gets added to DOM
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && previewContainerRef.current) {
          setIsRendered(true);
          observer.disconnect();
        }
      });
    });

    // Start observing the document body for our container
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      setIsRendered(false);
      observer.disconnect();
    };
  }, [enabled]);

  // Handle measurements once rendered
  useEffect(() => {
    if (!isRendered || !enabled || !previewContainerRef.current) return () => {};

    setTimeout(() => {
      requestAnimationFrame(recalibratePreviewRect);
    }, RECALIBRATE_DELAY);

    const resizeObserver = new ResizeObserver(recalibratePreviewRect);
    resizeObserver.observe(previewContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [enabled, recalibratePreviewRect, isRendered]);

  return {
    containerRef: previewContainerRef,
    rect: previewRect,
    recalibrate: recalibratePreviewRect,
  };
};

export default useContainerRect;
