import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';

import { useNavbarContext } from '../../../NavbarContext';

export const BooleanSettings = ({ title, property }: { title: string; property: string }) => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (!['navbar_item', 'navbar_dropdown_item'].includes(selectedContent.type)) return null;

  const contentValue =
    selectedContent?.attrs && property in selectedContent.attrs
      ? selectedContent.attrs[property as keyof typeof selectedContent.attrs]
      : false;

  const handleNewTabChange = (value: string) => {
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { [property]: value === 'true' });
  };

  const value = contentValue?.toString() || 'true';

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="true"
          value={value}
          onValueChange={handleNewTabChange}
        >
          <ToggleGroupItem value="true" className="grow">
            <Text size="2xs" weight="semibold" variant="inherit">
              YES
            </Text>
          </ToggleGroupItem>
          <ToggleGroupItem value="false" className="grow">
            <Text size="2xs" weight="semibold" variant="inherit">
              NO
            </Text>
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
