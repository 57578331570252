import { useMemo, useState } from 'react';

import InputWithSelect from '@/components/Form/InputWithSelect';
import Text from '@/components/Text';
import { Typography } from '@/components/Typography';
import { AutomationEnrolmentSettings, AutomationEnrolmentType } from '@/interfaces/automations/automation';
import { Duration, Option } from '@/interfaces/general';
import { NO_PERMISSION_MESSAGE } from '@/interfaces/permissions';
import { Dropdown } from '@/ui/Dropdown';
import Input from '@/ui/TextInput';
import Tooltip from '@/ui/Tooltip';
import { durationFromSeconds, secondsForDuration, secondsToDuration } from '@/utils/durationDisplay';

import { DURATION_OPTIONS, ENROLMENT_TYPE_OPTIONS } from '../../constants';

import { CooldownType, cooldownTypeOptions } from './constants';

interface Props {
  automationEnrolmentType: AutomationEnrolmentType;
  automationEnrolmentTypeOptions: Option[];
  onAutomationEnrolmentTypeChange: (value: AutomationEnrolmentType) => void;
  automationEnrolmentSettings: AutomationEnrolmentSettings;
  onAutomationEnrolmentSettingsChange: (value: AutomationEnrolmentSettings) => void;
  disabled?: boolean;
}

const ReenrolmentForm = ({
  automationEnrolmentType,
  automationEnrolmentTypeOptions,
  onAutomationEnrolmentTypeChange,
  automationEnrolmentSettings,
  onAutomationEnrolmentSettingsChange,
  disabled = false,
}: Props) => {
  const {
    max_enrolments: maxEnrolments = 1,
    seconds_since_completed: secondsSinceCompleted = 0,
    seconds_since_entered: secondsSinceEntered = 0,
  } = automationEnrolmentSettings;
  const initialCooldownType = useMemo(() => {
    if (secondsSinceEntered > 0) {
      return CooldownType.SECONDS_SINCE_ENTERED;
    }

    if (secondsSinceCompleted > 0) {
      return CooldownType.SECONDS_SINCE_COMPLETED;
    }

    return CooldownType.NONE;
  }, [secondsSinceCompleted, secondsSinceEntered]);
  const [cooldownType, setCooldownType] = useState<CooldownType>(initialCooldownType);
  const [secondsSinceEnteredDuration, setSecondsSinceEnteredDuration] = useState<Duration>(
    durationFromSeconds(secondsSinceEntered)
  );
  const [secondsSinceCompletedDuration, setSecondsSinceCompletedDuration] = useState<Duration>(
    durationFromSeconds(secondsSinceCompleted)
  );
  const secondsSinceEnteredDisplay = secondsToDuration(secondsSinceEntered, secondsSinceEnteredDuration);
  const secondsSinceCompletedDisplay = secondsToDuration(secondsSinceCompleted, secondsSinceCompletedDuration);

  const reenrolmentTypeHelperText = useMemo(() => {
    return ENROLMENT_TYPE_OPTIONS.find((option) => option.value === automationEnrolmentType)?.description;
  }, [automationEnrolmentType]);
  const cooldownHelperText = useMemo(() => {
    if (cooldownType === CooldownType.SECONDS_SINCE_COMPLETED) {
      return `When triggered to re-enter, require this amount of time since the subscriber last completed this automation.`;
    }
    if (cooldownType === CooldownType.SECONDS_SINCE_ENTERED) {
      return `When triggered to re-enter, require this amount of time since the subscriber last entered this automation.`;
    }

    return null;
  }, [cooldownType]);

  const onSelectEnrolment = (selectedName: string, selectedValue: AutomationEnrolmentType) => {
    onAutomationEnrolmentTypeChange(selectedValue);
  };

  const handleEnrolmentSettingsChange = (newSettings: object) => {
    onAutomationEnrolmentSettingsChange({
      ...automationEnrolmentSettings,
      ...newSettings,
    });
  };

  const handleSecondsSinceEnteredInputChange = (value: string) => {
    const newValue = secondsForDuration(parseInt(value, 10), secondsSinceEnteredDuration);

    handleEnrolmentSettingsChange({ seconds_since_entered: newValue.toString() });
    setSecondsSinceEnteredDuration(durationFromSeconds(newValue));
  };

  const handleSecondsSinceEnteredSelectChange = (duration: Duration) => {
    const newValue = secondsForDuration(secondsSinceEnteredDisplay, duration);

    handleEnrolmentSettingsChange({ seconds_since_entered: newValue.toString() });
    setSecondsSinceEnteredDuration(duration);
  };

  const handleSecondsSinceCompletedInputChange = (value: string) => {
    const newValue = secondsForDuration(parseInt(value, 10), secondsSinceCompletedDuration);

    handleEnrolmentSettingsChange({ seconds_since_completed: newValue.toString() });
    setSecondsSinceCompletedDuration(durationFromSeconds(newValue));
  };

  const handleSecondsSinceCompletedSelectChange = (duration: Duration) => {
    const newValue = secondsForDuration(secondsSinceCompletedDisplay, duration);

    handleEnrolmentSettingsChange({ seconds_since_completed: newValue.toString() });
    setSecondsSinceCompletedDuration(duration);
  };

  const handleCooldownTypeChange = (selectedName: string, selectedValue: CooldownType) => {
    setCooldownType(selectedValue);

    if (selectedValue === CooldownType.NONE) {
      handleEnrolmentSettingsChange({
        seconds_since_entered: 0,
        seconds_since_completed: 0,
      });
    } else if (selectedValue === CooldownType.SECONDS_SINCE_ENTERED) {
      handleEnrolmentSettingsChange({ seconds_since_completed: '0' });
    } else if (selectedValue === CooldownType.SECONDS_SINCE_COMPLETED) {
      handleEnrolmentSettingsChange({ seconds_since_entered: '0' });
    }
  };

  return (
    <>
      <Typography token="font-medium/text/lg" className="block leading-6 text-gray-900">
        Re-entry Settings
      </Typography>
      <p className="mt-1 text-sm text-gray-600">Control if subscribers can re-enter when meeting certain conditions</p>

      <div className="mt-6 space-y-6">
        <Tooltip
          tooltipClass="text-center"
          id="automation-enrollment-tooltip"
          text={disabled ? NO_PERMISSION_MESSAGE : ''}
          showIcon={false}
          autoWidth
          isEnabled={disabled}
        >
          <Dropdown
            name="enrolment"
            labelText="Allow re-entry?"
            value={automationEnrolmentType}
            onSelect={onSelectEnrolment}
            options={automationEnrolmentTypeOptions}
            helperText={reenrolmentTypeHelperText}
            required
            disabled={disabled}
          />
        </Tooltip>
        {automationEnrolmentType === AutomationEnrolmentType.CONDITIONAL && (
          <>
            <div className="flex">
              <Tooltip
                tooltipClass="text-center"
                id="automation-enrollment-tooltip"
                text={disabled ? NO_PERMISSION_MESSAGE : ''}
                showIcon={false}
                autoWidth
                isEnabled={disabled}
              >
                <Input
                  className="w-64"
                  name="max_enrolments"
                  labelText="Maximum re-entries per subscriber"
                  type="number"
                  value={maxEnrolments?.toString()}
                  onChange={(e) => {
                    handleEnrolmentSettingsChange({ max_enrolments: e.target.value });
                  }}
                  required
                  disabled={disabled}
                />
              </Tooltip>
            </div>
            <div>
              <div className="flex space-x-2 items-end">
                <Tooltip
                  tooltipClass="text-center"
                  id="automation-enrollment-tooltip"
                  text={disabled ? NO_PERMISSION_MESSAGE : ''}
                  showIcon={false}
                  autoWidth
                  isEnabled={disabled}
                >
                  <Dropdown
                    className="w-64"
                    name="cooldown_type"
                    labelText="Cooldown period"
                    value={cooldownType}
                    onSelect={handleCooldownTypeChange}
                    options={cooldownTypeOptions}
                    required
                    disabled={disabled}
                  />
                </Tooltip>

                {cooldownType === CooldownType.SECONDS_SINCE_ENTERED && (
                  <InputWithSelect
                    className="w-44"
                    name="seconds_since_entered"
                    inputType="number"
                    inputValue={secondsSinceEnteredDisplay.toString() || '0'}
                    selectedOption={secondsSinceEnteredDuration}
                    selectOptions={DURATION_OPTIONS}
                    onChangeInput={handleSecondsSinceEnteredInputChange}
                    onChangeSelect={(value) => handleSecondsSinceEnteredSelectChange(value as Duration)}
                    disabled={disabled}
                    disabledTooltip={NO_PERMISSION_MESSAGE}
                  />
                )}

                {cooldownType === CooldownType.SECONDS_SINCE_COMPLETED && (
                  <InputWithSelect
                    className="w-44"
                    name="seconds_since_completed"
                    inputType="number"
                    inputValue={secondsSinceCompletedDisplay.toString() || '0'}
                    selectedOption={secondsSinceCompletedDuration}
                    selectOptions={DURATION_OPTIONS}
                    onChangeInput={handleSecondsSinceCompletedInputChange}
                    onChangeSelect={(value) => handleSecondsSinceCompletedSelectChange(value as Duration)}
                    disabled={disabled}
                    disabledTooltip={NO_PERMISSION_MESSAGE}
                  />
                )}
              </div>

              <Text size="xs" className="mt-1 text-gray-500">
                {cooldownHelperText}
              </Text>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ReenrolmentForm;
