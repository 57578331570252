import { PopoverHelper } from '../../helpers/PopoverHelper';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import { ColorPopoverGroupSettings } from '../ColorPopoverGroupSettings';
import { ColorSettings } from '../ColorSettings';
import { FontSettings } from '../FontSettings';
import { GapSettings } from '../GapSettings';
import { GridColumnSetting } from '../GridColumnSetting';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';
import { SimpleNumberSettings } from '../SimpleNumberSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

export const RecommendationsSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['recommendations']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Layout">
        <GridColumnSetting editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>
      {activeNodeResult.activeNodeAttributes.isFullHeight !== true && (
        <SectionRenderer title="List">
          <SimpleNumberSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="listHeight"
            title="Height"
            unit="px"
            max={1200}
          />
        </SectionRenderer>
      )}
      {/** We should think through opening this up to users for now, no controls around people removing sponsored text or checkbox... */}
      {/* <SectionRenderer title="Structure">
        <StructureSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          allowedProperties={['div', 'image', 'name', 'description', 'checkbox', 'sponsored']}
          predefinedPatterns={[]}
        >
          {(structure: any) => (
            <RecommendationsElement
              element={{
                type: 'recommendations',
                attrs: {
                  ...(activeNode?.attrs as TRecommendationAttributes),
                  cardStructure: structure as any,
                  columns: '1',
                  // We only need one testimonial to display the structure
                  data: activeNode?.attrs?.data?.slice(0, 1),
                },
                content: [{ id: 'text', text: '' }],
              }}
            >
              {null}
            </RecommendationsElement>
          )}
        </StructureSettings>
      </SectionRenderer> */}
      <SectionRenderer title="Typography">
        <FontSettings editor={editor} activeNodeResult={activeNodeResult} isMarkStyle={false} />
      </SectionRenderer>
      <SectionRenderer title="Container">
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Border"
          properties={{
            color: 'containerBorderColor',
            style: 'containerBorderStyle',
            width: 'containerBorderWidth',
            radius: 'containerBorderRadius',
          }}
        />
      </SectionRenderer>
      <SectionRenderer title="Cards">
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'cardBorderColor',
            style: 'cardBorderStyle',
            width: 'cardBorderWidth',
            radius: 'cardBorderRadius',
          }}
        />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="cardPadding" />
        <ColorSettings
          title="Background"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="backgroundColor"
        />
        <ColorSettings
          title="Sponsored"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="sponsoredTextColor"
        />
        <ColorSettings title="Name" editor={editor} activeNodeResult={activeNodeResult} property="nameColor" />
        <ColorSettings
          title="Description"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="descriptionColor"
        />
      </SectionRenderer>
      <SectionRenderer title="Form">
        {activeNodeResult.activeNodeAttributes.disablePopupFormSetting !== true && (
          <>
            <BooleanSettings editor={editor} activeNodeResult={activeNodeResult} property="isPopupForm" title="Popup" />
            {activeNodeResult.activeNodeAttributes.isPopupForm && (
              <>
                <ColorSettings
                  title="Popup Background"
                  editor={editor}
                  activeNodeResult={activeNodeResult}
                  property="formBackgroundColor"
                />
                <ShadowSettings
                  editor={editor}
                  activeNodeResult={activeNodeResult}
                  property="formShadow"
                  title="Popup shadow"
                />
                <PaddingSettings
                  editor={editor}
                  activeNodeResult={activeNodeResult}
                  property="formPadding"
                  title="Popup padding"
                />
                <BorderSettings
                  editor={editor}
                  activeNodeResult={activeNodeResult}
                  title="Popup Border"
                  properties={{
                    color: 'formBorderColor',
                    style: 'formBorderStyle',
                    width: 'formBorderWidth',
                    radius: 'formBorderRadius',
                  }}
                />
              </>
            )}
          </>
        )}
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Input Border"
          properties={{
            color: 'inputBorderColor',
            style: 'inputBorderStyle',
            width: 'inputBorderWidth',
            radius: 'inputBorderRadius',
          }}
        />
        <SimpleTextSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="inputPlaceholderText"
          title="Input Placeholder Text"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Input Text"
          property="inputTextColor"
        />
        <ColorSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Input Background"
          property="inputBackgroundColor"
        />
        <PopoverHelper title="Submit Button" text="Configure" popoverTitle="Configure Submit Button">
          <SimpleTextSettings editor={editor} activeNodeResult={activeNodeResult} property="buttonText" title="Text" />
          <BorderSettings
            title="Border"
            editor={editor}
            activeNodeResult={activeNodeResult}
            properties={{
              color: 'buttonBorderColor',
              style: 'buttonBorderStyle',
              width: 'buttonBorderWidth',
              radius: 'buttonBorderRadius',
            }}
          />
          <ColorSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Text Color"
            property="buttonCustomTextColor"
          />
          <ColorSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Background Color"
            property="buttonCustomBackgroundColor"
          />
        </PopoverHelper>
        {/* <PopoverHelper title="Cancel Button" text="Configure" popoverTitle="Configure Cancel Button">
          <SimpleTextSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="cancelButtonRedirectUrl"
            title="Redirect URL"
          />
          <SimpleTextSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="cancelButtonText"
            title="Text"
          />
          <BorderSettings
            title="Border"
            editor={editor}
            activeNodeResult={activeNodeResult}
            properties={{
              color: 'cancelButtonBorderColor',
              style: 'cancelButtonBorderStyle',
              width: 'cancelButtonBorderWidth',
              radius: 'cancelButtonBorderRadius',
            }}
          />
          <ColorSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Text Color"
            property="cancelButtonCustomTextColor"
          />
          <ColorSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            title="Background Color"
            property="cancelButtonCustomBackgroundColor"
          />
        </PopoverHelper> */}
        <ColorPopoverGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Toggle Color"
          properties={[
            { title: 'Checked', property: 'toggleCheckedBackgroundColor' },
            { title: 'Default', property: 'toggleBackgroundColor' },
            { title: 'Text', property: 'selectAllTextColor' },
          ]}
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
