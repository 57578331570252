import countries from '@/utils/countries.json';

export interface MappedCountry {
  code: string;
  name: string;
  flag: string;
}

const mappedCountries: { [key: string]: MappedCountry } = countries.reduce((previousValue, currentvalue) => {
  return {
    ...previousValue,
    [currentvalue.code]: {
      code: currentvalue.code,
      name: currentvalue.name,
      flag: currentvalue.flag,
    },
  };
}, {});

export default mappedCountries;
