import { useState } from 'react';

import LoadingBox from '@/components/LoadingBox';
import { useCurrentUser } from '@/context/current-user-context';
import { usePausePlan } from '@/hooks/usePausePlan';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import Tooltip from '@/ui/Tooltip';
import analytics from '@/utils/analytics';
import { PLAN } from '@/utils/plans';

import { useBillingSummary, usePlanPrice } from '../../_hooks';
import PlanPriceChangeModal from '../PlanPriceChangeModal';

import AutoUpgradeToggle from './AutoUpgradeToggle';
import PastDueNotice from './PastDueNotice';
import PlanCard from './PlanCard';
import UpgradeCta from './UpgradeCta';

const CurrentPlan: React.FC<{ organizationId: string; publicationId: string }> = ({
  organizationId,
  publicationId,
}) => {
  const { data: pausePlanData } = usePausePlan(organizationId);
  const { currentUser } = useCurrentUser();
  const { data: billingSummaryData } = useBillingSummary({ organizationId, publicationId });

  const [modalOpen, setModalOpen] = useState(false);

  const {
    data: planPrice,
    isLoading: isLoadingCurrent,
    isError: isErrorCurrent,
  } = usePlanPrice({ organizationId, publicationId });

  const {
    data: upcomingPlanPrice,
    isLoading: isLoadingUpcoming,
    isError: isErrorUpcoming,
  } = usePlanPrice({ organizationId, publicationId, upcoming: true });

  const isPendingDowngrade = planPrice?.id !== upcomingPlanPrice?.id;
  const isCustomPlan = planPrice?.plan_name === PLAN.CUSTOM;
  const isPaused = !!pausePlanData?.resumes_at;
  const isPastDue = billingSummaryData?.past_due;

  let tooltipText = '';
  if (isPaused) tooltipText = 'You must unpause your plan in order to make modifications.';
  if (isPendingDowngrade) tooltipText = 'You must cancel the pending downgrade to modify your plan.';
  if (isCustomPlan) tooltipText = 'Please contact your CSM to modify your plan.';
  if (isPastDue) tooltipText = 'You must resolve the failed payment in order to make modifications.';

  const onClick = () => {
    if (currentUser) {
      analytics.identify(currentUser.id);
      analytics.track('Clicked Change Plan');
    }

    setModalOpen(true);
  };

  return (
    <>
      <PlanPriceChangeModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        organizationId={organizationId}
        publicationId={publicationId}
      />
      <Card>
        <BodyContainer>
          <CardHeader
            title="Your Current Plan"
            cta={
              <Tooltip
                id="organization-plan-change"
                showIcon={false}
                text={tooltipText}
                isEnabled={isPendingDowngrade || isPaused || isCustomPlan || isPastDue}
              >
                <Button
                  type="button"
                  size="xs"
                  variant="primary"
                  iconRight
                  onClick={onClick}
                  disabled={isLoadingCurrent || isPendingDowngrade || isPaused || isCustomPlan || isPastDue}
                >
                  Change
                </Button>
              </Tooltip>
            }
          />
          <LoadingBox
            isLoading={isLoadingCurrent || isLoadingUpcoming}
            isError={isErrorCurrent || isErrorUpcoming}
            backgroundClassName="bg-transparent"
            height={300}
          >
            <div className="space-y-6">
              {isPastDue && <PastDueNotice />}
              {planPrice && <UpgradeCta planPrice={planPrice} />}
              {planPrice && <PlanCard planPrice={planPrice} showUsage />}
              <AutoUpgradeToggle
                organizationId={organizationId}
                publicationId={publicationId}
                currentPlanPrice={planPrice}
                upcomingPlanPrice={upcomingPlanPrice}
              />
            </div>
          </LoadingBox>
        </BodyContainer>
      </Card>
    </>
  );
};

export default CurrentPlan;
