import { Extension } from '@tiptap/core';

import { getStringAttribute } from '../../../utils/attributesUtils';

interface NodeTextAlignOptions {
  nodesToAddLeftTextAlignAttributeTo: string[];
  nodesToAddCenterTextAlignAttributeTo: string[];
}

interface NodeTextAlignStorage {}

export const NodeTextAlign = Extension.create<NodeTextAlignOptions, NodeTextAlignStorage>({
  name: 'nodeTextAlign',

  addOptions() {
    return {
      nodesToAddLeftTextAlignAttributeTo: ['post', 'survey'],
      nodesToAddCenterTextAlignAttributeTo: ['container', 'section'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.nodesToAddLeftTextAlignAttributeTo,
        attributes: {
          textAlign: getStringAttribute('textAlign', 'data-text-align', 'left'),
        },
      },
      {
        types: this.options.nodesToAddCenterTextAlignAttributeTo,
        attributes: {
          textAlign: getStringAttribute('textAlign', 'data-text-align', 'center'),
        },
      },
    ];
  },
});
