import { useInfiniteQuery } from 'react-query';

import { Notifications } from '@/interfaces/notification';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Pagination } from '../interfaces/general';
import api from '../services/swarm';

interface ApiResponse {
  notifications: Notifications;
  pagination: Pagination;
}

export default function useNotifications({ onSuccess }: { onSuccess?: () => void } = {}) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchNotifications = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get(`/notifications`, {
        params: {
          page: pageParam,
          publication_id: currentPublicationId,
          per_page: 15,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery([currentPublicationId, 'notifications'], fetchNotifications, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
    },
    keepPreviousData: true,
  });
}
