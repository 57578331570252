import { NAVBAR_MENU_DROPDOWN_ITEM_DEFAULTS } from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { BorderRadiusSettings } from '../general/BorderRadiusSettings';
import { ColorSettings } from '../general/ColorSettings';
import { PaddingSettings } from '../general/PaddingSettings';

import { IconSettings } from './IconSettings';
import { ItemDescriptionTypographySettings } from './ItemDescriptionTypographySettings';
import { ItemTitleTypographySettings } from './ItemTitleTypographySettings';

export function NavDropdownItemStylingSettings() {
  const { selectedContent } = useNavbarContext();

  const defaults = NAVBAR_MENU_DROPDOWN_ITEM_DEFAULTS;

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown_item') return null;

  return (
    <SectionRenderer title="Styling">
      <ColorSettings
        title="Background"
        property="background"
        selectedContent={selectedContent}
        defaultValue={NAVBAR_MENU_DROPDOWN_ITEM_DEFAULTS.background}
      />
      <ItemTitleTypographySettings />
      <ItemDescriptionTypographySettings />

      <PaddingSettings selectedContent={selectedContent} attribute="padding" defaultValue={defaults?.padding} />

      <BorderRadiusSettings
        property="borderRadius"
        selectedContent={selectedContent}
        defaults={{
          borderRadius: defaults.borderRadius,
        }}
      />

      <IconSettings />

      {(selectedContent.attrs.style === 'icon' || selectedContent.attrs.style === 'featured') && (
        <>
          <ColorSettings
            title="Icon Color"
            property="iconColor"
            selectedContent={selectedContent}
            defaultValue={NAVBAR_MENU_DROPDOWN_ITEM_DEFAULTS.iconColor}
          />
          <ColorSettings
            title="Icon Background"
            property="iconBackgroundColor"
            selectedContent={selectedContent}
            defaultValue={NAVBAR_MENU_DROPDOWN_ITEM_DEFAULTS.iconBackgroundColor}
          />
        </>
      )}
    </SectionRenderer>
  );
}
