import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { ActionSettings } from '../ActionSettings';
import { LinkToSettingsBlock } from '../LinkToSettingsBlock';
import { NewTabSettingsBlock } from '../NewTabSettingsBlock';
import { OnClickSettings } from '../OnClickSettings';

const ButtonLinkSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeAttributes } = activeNodeResult;
  const onClick = activeNodeAttributes?.onClick;

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['button']}>
      <SectionRenderer title="Click Action" collapsible>
        <OnClickSettings editor={editor} activeNodeResult={activeNodeResult} />
        {onClick === 'link' && <LinkToSettingsBlock editor={editor} activeNodeResult={activeNodeResult} />}
        {onClick === 'link' && <NewTabSettingsBlock editor={editor} activeNodeResult={activeNodeResult} />}
        {onClick === 'action' && <ActionSettings editor={editor} activeNodeResult={activeNodeResult} />}
      </SectionRenderer>
    </RenderCondition>
  );
};

export { ButtonLinkSettings };
