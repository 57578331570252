import { Breadcrumbs } from '@/components/Breadcrumbs';

interface Props {
  showEdit: boolean;
}

const Header = ({ showEdit }: Props) => (
  <Breadcrumbs className="">
    <Breadcrumbs.Home />
    <Breadcrumbs.Item to="/settings/publication/general">Publication Settings</Breadcrumbs.Item>
    <Breadcrumbs.Item to="/settings/publication/emails">Emails</Breadcrumbs.Item>
    <Breadcrumbs.Item to="/settings/publication/emails/welcome_email">Welcome Email</Breadcrumbs.Item>
    {showEdit ? <Breadcrumbs.Item to="/settings/publication/emails/welcome_email/edit">Edit</Breadcrumbs.Item> : null}
  </Breadcrumbs>
);

export default Header;
