import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ColorPicker } from '../../../_components/UI/ColorPicker';
import { SimpleInputWrapper } from '../../../_components/UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../_components/UI/Popover';
import { Text } from '../../../_components/UI/Text';

type Props = {
  title?: string;
  initialColor?: string | null;
  onSetColor: (value: string | null) => void;
  children?: React.ReactNode;
  offset?: number;
  side?: 'bottom' | 'left' | 'right' | 'top';
};

const DEFAULT_COLOR = '#000000';

const removeOpacityFromColor = (color: string) => {
  if (color?.length === 9) {
    return color.slice(0, 7);
  }

  return color;
};

const RemoveOpacityColor = styled.div`
  .wb-react-colorful .react-colorful__alpha {
    display: none !important;
  }
`;

export const ColorSelection = ({ title, initialColor, onSetColor, children, offset = 110, side = 'left' }: Props) => {
  const [color, setColor] = useState<string | undefined>(initialColor || undefined);
  const reformattedColor = useMemo(() => removeOpacityFromColor(color || DEFAULT_COLOR), [color]);

  useEffect(() => {
    const alphaSlider = document.querySelector('.wb-react-colorful .react-colorful__alpha') as HTMLElement;
    if (alphaSlider) {
      alphaSlider.style.display = 'none';
    }
  }, []);

  const onChange = useCallback(
    (value: string | null) => {
      const formattedColor = removeOpacityFromColor(value || DEFAULT_COLOR) || null;
      setColor(formattedColor || DEFAULT_COLOR);
      onSetColor(formattedColor);
    },
    [onSetColor]
  );

  const onReset = useCallback(() => {
    setColor(DEFAULT_COLOR);
    onChange(null);
  }, [onChange]);

  return (
    <Popover>
      <div className="flex items-center justify-between gap-2 select-none">
        {title && (
          <Text className="w-full max-w-[150px]" variant="secondary" size="2xs" weight="medium">
            {title}
          </Text>
        )}
        <PopoverTrigger asChild>
          {children || (
            <SimpleInputWrapper className="justify-start relative max-w-[150px]">
              <div style={{ backgroundColor: reformattedColor }} className="h-4 w-4 rounded-md" />
              <Text size="2xs" weight="medium" className="">
                {reformattedColor?.toUpperCase()}
              </Text>
            </SimpleInputWrapper>
          )}
        </PopoverTrigger>
      </div>
      <PopoverContent className="w-[280px] p-0" align="center" side={side} sideOffset={offset}>
        <div className="max-h-[700px] overflow-y-auto p-3 flex flex-col gap-4">
          <RemoveOpacityColor>
            <ColorPicker
              selectedColor={color}
              showAlpha={false}
              onSetColor={(value: string | null) => {
                setColor(value || DEFAULT_COLOR);
                onChange(value);
              }}
              onReset={onReset}
            />
          </RemoveOpacityColor>
        </div>
      </PopoverContent>
    </Popover>
  );
};
