import { useState } from 'react';

import PremiumGiftModal from '@/components/PremiumGiftModal';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { usePremiumGifts } from '@/hooks';

const useGiftPremiumAction = ({ subscriberId, status }: { subscriberId: string; status: string | undefined }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();

  const premiumGiftsQuery = usePremiumGifts({ all: true });
  const premiumGifts = premiumGiftsQuery.data?.pages.flatMap((page) => page.premiumGifts) || [];
  const giftingEnabled = premiumGifts.length > 0;

  if (status === 'inactive' || !giftingEnabled) {
    return [];
  }

  return [
    {
      label: 'Complimentary Access',
      helperText: 'Grant complimentary access to a paid subscription',
      onClick: () => setModalOpen(true),
      children: (
        <PremiumGiftModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          premiumGifts={premiumGifts}
          publicationId={currentPublicationId}
          subscriberIds={[subscriberId]}
        />
      ),
    },
  ];
};

export default useGiftPremiumAction;
