import { PropsWithChildren, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cx from 'classnames';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../UI/Accordion';
import { Text } from '../../../UI/Text';

type Props = PropsWithChildren<{
  title: string;
  defaultIsOpen?: boolean;
  isOpen?: boolean;
  actions?: React.ReactNode;
  collapsible?: boolean;
  contentClassName?: string;
  isMobile?: boolean;
  isMobileAndDesktop?: boolean;
  noSettingsAvailable?: boolean;
}>;

const SectionRenderer = ({
  title,
  children,
  defaultIsOpen = true,
  isOpen,
  actions,
  collapsible = true,
  contentClassName,
  isMobile = false,
  isMobileAndDesktop = false,
  noSettingsAvailable = false,
}: Props) => {
  const [currentValue, setCurrentValue] = useState(defaultIsOpen ? 'item' : undefined);
  const [searchParams] = useSearchParams();
  const viewport = searchParams.get('viewport') as 'desktop' | 'mobile';

  useEffect(() => {
    if (isOpen !== undefined) {
      setCurrentValue(isOpen ? 'item' : undefined);
    }
  }, [isOpen]);

  if (isMobile && viewport !== 'mobile') return null;
  if (!isMobile && !isMobileAndDesktop && viewport === 'mobile') return null;

  return (
    <Accordion
      asChild
      type="single"
      value={currentValue}
      onValueChange={(value) => setCurrentValue(value)}
      collapsible={collapsible}
    >
      <AccordionItem value="item">
        <AccordionTrigger hideIcon={!collapsible}>
          <div className="grow flex justify-between items-center">
            <Text size="xs" weight="semibold">
              {title}
            </Text>
            {actions}
          </div>
        </AccordionTrigger>
        <AccordionContent className={cx('flex flex-col gap-4', contentClassName)}>
          {noSettingsAvailable ? (
            <div className="flex flex-col gap-4">
              <Text size="xs" weight="semibold" variant="secondary">
                This block currently has no mobile settings
              </Text>
            </div>
          ) : (
            children
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default SectionRenderer;
