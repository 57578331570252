import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Input } from '@/components/Form';
import { TopBar } from '@/components/Layout/AppLayout/TopBar';
import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCreateAccount } from '@/hooks/usePublications';
import { Button } from '@/ui/Button';

export default function NoPublication() {
  const navigate = useNavigate();
  const [, setCurrentPublicationId] = useCurrentPublicationState();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const handleCreateSuccess = () => {
    navigate('/');
  };

  const createPublication = useCreateAccount({ onSuccess: handleCreateSuccess });

  const handleCreatePublication = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (name && description) {
      const createdPublication = await createPublication.mutateAsync({
        name,
        description,
      });

      setCurrentPublicationId(createdPublication.data.publication.id);
    } else {
      toast.error('Please fill out all fields');
    }
  };

  return (
    <div className="h-screen w-full overflow-hidden bg-surface-50">
      <TopBar className="border-b border-surface-200 bg-white" />
      <div className="flex items-center justify-center w-full h-full">
        <div className="flex flex-col items-center justify-center space-y-6">
          <div className="flex flex-col space-y-1 items-center">
            <Typography token="font-normal/text/xl">There are no publications associated with this account</Typography>
            <Typography token="font-light/text/sm">Get started by creating one today!</Typography>
          </div>
          <form onSubmit={handleCreatePublication} className="w-full">
            <div className="w-full space-y-4">
              <Input
                className="w-full"
                name="name"
                labelText="Publication Name"
                placeholder="My New Publication"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                className="w-full"
                name="description"
                labelText="This publication is about..."
                placeholder="A newsletter about lifestyle"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </form>
          <Button variant="primary" onClick={handleCreatePublication}>
            Create Publication
          </Button>
        </div>
      </div>
    </div>
  );
}
