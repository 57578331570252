import { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowSquareOut, Palette } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { useSite } from '@/hooks/useSite';
import { useSiteThemes } from '@/hooks/useSiteThemes';
import { SiteTheme, WebTheme } from '@/interfaces/web_theme';
import { ThemePreviewer, useThemePreviewer } from '@/routes/website/_components/ThemePreviewer';

import { Button } from '../../../../../UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../UI/Popover';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../../../../UI/Select';
import { Text } from '../../../../../UI/Text';
import { Tooltip } from '../../../../../UI/Tooltip';
import { HoverStorage } from '../../../../extensions/Hover';
import { AttributeSettingProps } from '../../../types';

import { THEME_ATTRS } from './consts';

const ThemeSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { data: currentPublication } = useCurrentPublication();
  const { data: siteThemesData } = useSiteThemes('theme_settings');
  const { data: site } = useSite();
  const { activeNode } = activeNodeResult;
  const { isOpen, onClose: onPreviewerClose, onOpen: onPreviewerOpen } = useThemePreviewer();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<SiteTheme | null>(null);

  const siteThemes = useMemo(() => siteThemesData?.pages.flatMap((page) => page.site_themes) || [], [siteThemesData]);

  const hasSiteThemes = siteThemes.length > 0;

  const primaryTheme = useMemo(() => {
    return siteThemes.find((siteTheme) => siteTheme.is_primary);
  }, [siteThemes]);

  useEffect(() => {
    const hoverStorage = editor.storage.hover as HoverStorage;

    if (hoverStorage.primaryTheme && hoverStorage.themeRules) {
      return;
    }

    if (primaryTheme && editor && site?.theme_rules) {
      hoverStorage.primaryTheme = primaryTheme.data;
      hoverStorage.themeRules = site.theme_rules;
    }
  }, [primaryTheme, editor, site?.theme_rules, siteThemes]);

  useEffect(() => {
    if (!selectedTheme) {
      return;
    }

    const hoverStorage = editor.storage.hover as HoverStorage;

    hoverStorage.selectedTheme = selectedTheme.data;
  }, [editor, selectedTheme]);

  useEffect(() => {
    if (currentPublication?.web_theme && hasSiteThemes && !selectedTheme) {
      const theme = siteThemes.find((siteTheme) => siteTheme.is_primary);
      if (theme) {
        setSelectedTheme(theme);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedTheme, selectedTheme, hasSiteThemes]);

  const onClosePopover = useCallback(() => {
    setPopoverOpen(false);
  }, []);

  const onApplyTheme = useCallback(() => {
    if (!site?.theme_rules) return;
    if (!selectedTheme) return;
    if (!activeNode) return;

    editor
      .chain()
      .focus()
      .applyTheme({
        siteTheme: selectedTheme,
        mapping: site?.theme_rules,
      })
      .run();

    onClosePopover();
  }, [editor, site?.theme_rules, selectedTheme, activeNode, onClosePopover]);

  const onOpenPopover = () => {
    setPopoverOpen(true);
  };

  return (
    <>
      <ThemePreviewer
        isOpen={isOpen}
        onClose={onPreviewerClose}
        theme={selectedTheme?.data || {}}
        themeId={selectedTheme?.id || ''}
      />
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <Tooltip center="Apply Theme" delay={300}>
          <PopoverTrigger asChild>
            <Button
              variant="secondary"
              size="sm"
              LeftIcon={Palette}
              className="text-wb-secondary"
              onClick={onOpenPopover}
            />
          </PopoverTrigger>
        </Tooltip>

        <PopoverContent className="w-[325px] p-0" align="start" side="left" sideOffset={110}>
          <div className="max-h-[500px] overflow-y-auto p-3 flex flex-col gap-4">
            <div className="flex items-center justify-between gap-2">
              <Text size="sm" weight="semibold">
                Theme
              </Text>
            </div>

            <div className="flex flex-col gap-2">
              {selectedTheme && (
                <Select
                  defaultValue={selectedTheme?.name || 'Primary Theme'}
                  onValueChange={(value: string) => {
                    const theme = siteThemes.find((siteTheme) => siteTheme.name === value);
                    if (theme) {
                      setSelectedTheme(theme);
                    }
                  }}
                >
                  <SelectTrigger className="w-full" id="theme_selection">
                    <SelectValue placeholder="Select a theme" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {siteThemes.map((theme) => {
                        return (
                          <SelectItem key={theme.id} value={theme?.name || 'Primary Theme'}>
                            {theme?.name || 'Primary Theme'}
                          </SelectItem>
                        );
                      })}
                      <a
                        href="/website_builder_v2/settings/themes"
                        target="_blank"
                        className="relative flex w-full rounded-sm cursor-pointer select-none items-center py-1.5 px-2 text-sm outline-none hover:bg-wb-secondary hover:text-wb-hover-text z-10"
                        rel="noreferrer"
                      >
                        <div className="flex flex-row w-full justify-between items-between">
                          <span className="my-auto text-sm text-wb-primary">Edit Themes</span>
                          <ArrowSquareOut className="my-auto" size={16} weight="regular" />
                        </div>
                      </a>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )}
              {THEME_ATTRS.map((attr) => {
                const normalizedAttr = attr.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
                const value = selectedTheme?.data?.[attr as keyof WebTheme];

                return (
                  <div key={attr} className="flex items-center justify-between gap-2">
                    <Text size="xs" weight="medium" variant="secondary">
                      {normalizedAttr}
                    </Text>
                    <div className="flex items-center gap-2">
                      {value?.includes('#') && (
                        <div
                          className="w-4 h-4 rounded-md shadow-sm border border-wb-secondary"
                          style={{ backgroundColor: value }}
                        />
                      )}
                      <Text size="xs" weight="medium">
                        {value}
                      </Text>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col gap-2">
              <Button variant="secondary" onClick={onPreviewerOpen}>
                Edit Theme
              </Button>
              <Button variant="primary" onClick={onApplyTheme}>
                Apply Theme
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ThemeSettings;
