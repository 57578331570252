import { memo } from 'react';
import { Editor } from '@tiptap/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { InputField } from '@/components/TiptapEditor/components/ui/Input';
import { Panel, PanelDivider, PanelHeader, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ToggleSwitch } from '@/components/TiptapEditor/components/ui/ToggleSwitch';
import { ToggleWrapper } from '@/components/TiptapEditor/components/ui/ToggleWrapper';
import { useEditorStateNonBlocking } from '@/components/TiptapEditor/lib/hooks/useEditorStateNonBlocking';

import { ColumnLayout } from '../Columns';

import useColumnsOptionsPanel from './ColumnsOptionsPanel.hooks';
import Styled from './ColumnsOptionsPanel.styled';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $showTooltip: true,
  $isIconButton: false,
};

const labels: { [key: string]: string[] } = {
  2: ['Left', 'Right'],
  3: ['Left', 'Middle', 'Right'],
};

const getPlaceholder = (layout: ColumnLayout, columnCount: number, index: number): string => {
  if (layout === ColumnLayout.SidebarLeft && index === 0) {
    return '40';
  }

  if (
    layout === ColumnLayout.SidebarRight &&
    ((index === 1 && columnCount === 2) || (index === 2 && columnCount === 3))
  ) {
    return '40';
  }

  if (layout === ColumnLayout.TwoColumn && columnCount === 2) {
    return '50';
  }

  return 'auto';
};

export const ColumnsOptionsPanel = memo(({ editor, ...rest }: { editor: Editor; [key: string]: any }): JSX.Element => {
  const { columnCount, onSetColumnCount, isStackedOnMobile, onToggleStackOnMobile, columnWidth, onChangeColumnsWidth } =
    useColumnsOptionsPanel(editor);

  const { layout } = useEditorStateNonBlocking({ editor, selector: () => editor.getAttributes('columns') || {} });

  return (
    <Panel tabIndex={-1} {...rest}>
      <PanelHeader>
        <Styled.ButtonWrapper>
          <Button
            onClick={() => onSetColumnCount(2)}
            $isToggleButton
            $leftSlot={<Icon name="TwoCol" />}
            $active={columnCount === 2}
            $muted
            {...buttonProps}
          >
            2 Columns
          </Button>
          <Button
            onClick={() => onSetColumnCount(3)}
            $isToggleButton
            $leftSlot={<Icon name="ThreeCol" />}
            $active={columnCount === 3}
            $muted
            {...buttonProps}
          >
            3 Columns
          </Button>
        </Styled.ButtonWrapper>
      </PanelHeader>
      <PanelSection>
        <Styled.InputFieldsWrapper>
          {columnCount &&
            labels[columnCount].map((label: string, index: number) => (
              <Styled.InputFieldWrapper key={label}>
                <Styled.Label>{label}</Styled.Label>
                <InputField
                  placeholder={getPlaceholder(layout as ColumnLayout, columnCount, index)}
                  min={1}
                  max={80}
                  type="number"
                  value={columnWidth[index] || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeColumnsWidth(e, index)}
                  suffix="%"
                />
              </Styled.InputFieldWrapper>
            ))}
        </Styled.InputFieldsWrapper>
        <PanelDivider />
        <Styled.Label>Mobile behavior</Styled.Label>
        <ToggleWrapper switchEl={<ToggleSwitch isChecked={isStackedOnMobile} onChange={onToggleStackOnMobile} />}>
          Stack columns
        </ToggleWrapper>
      </PanelSection>
    </Panel>
  );
});

export default ColumnsOptionsPanel;
