import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TagsElement, TTagsAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import DynamicContent from '../../components/DynamicContent';
import NotConnected from '../../components/NotConnected';

export const TagsView: FC<NodeViewProps> = (nodeViewProps) => {
  const { node, editor, getPos } = nodeViewProps;
  const { onDragStart } = useReactNodeView();
  const [, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { insertedFromSidebar, hasFakeData, isTagPageHeader } = node.attrs;

  useEffect(() => {
    if (insertedFromSidebar && !isModalOpen) {
      setIsModalOpen(true);
    }
  }, [insertedFromSidebar, isModalOpen]);

  const handleOpenSelectionModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
    setIsModalOpen(true);
  };

  const handleClick = () => {
    setSearchParams({ learn_more: 'dynamic_content_tags' });
  };

  return (
    <TagsElement
      className="relative"
      element={{
        type: 'tags',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        } as TTagsAttributes,
        content: [{ id: 'text', text: '' }],
      }}
      onDragStart={onDragStart}
      attributes={{
        'data-node-view-wrapper': '',
      }}
      isLive={false}
    >
      <div className="absolute top-0 left-0 w-full h-full" />
      {isTagPageHeader && <DynamicContent title="Dynamic Tag Page" onClick={handleClick} />}
      {hasFakeData && !isTagPageHeader && (
        <NotConnected text="Not connected to real tags" onClick={handleOpenSelectionModal} />
      )}
    </TagsElement>
  );
};
