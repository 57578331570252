import { FC } from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography, TypographyStack } from '@/components/Typography';
import { useCopyToClipboard } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { Pagination } from '@/interfaces/general';
import { PremiumOffer } from '@/interfaces/premium_offer';

interface Props {
  offers: PremiumOffer[] | undefined;
  pagination: Pagination | undefined;
  onEditSelected: (offerId: string) => void;
  onDeleteSelected: (offerId: string) => void;
  onOfferSelected: (offerId: PremiumOffer) => void;
}

const Table: FC<Props> = (props: Props) => {
  const { offers, pagination, onEditSelected, onDeleteSelected, onOfferSelected } = props;
  const copy = useCopyToClipboard();
  const currentPublicationId = useCurrentPublicationId();
  const { data: settings } = usePublicationSettings(currentPublicationId);

  if (!offers || !pagination) {
    return null;
  }

  const getDefaultText = (offer: PremiumOffer) => {
    if (offer.default_annual && offer.default_monthly && settings?.one_time_payments && offer.default_one_time) {
      return 'All';
    }
    if (offer.default_annual) {
      return 'Annual';
    }
    if (offer.default_monthly) {
      return 'Monthly';
    }
    if (settings?.one_time_payments && offer.default_one_time) {
      return 'One Time';
    }

    return 'None';
  };

  return (
    <>
      <Typography token="font-normal/text/xs" colorWeight="500" className="mb-2">
        Showing page {pagination.page} of {pagination.total_pages}
      </Typography>
      <Items className="border rounded-lg !shadow-none">
        <ItemHeaders>
          <ItemHeader align={AlignType.LEFT}>Name</ItemHeader>
          <ItemHeader align={AlignType.LEFT}>Defaults</ItemHeader>
          <ItemHeader align={AlignType.LEFT}>Expires</ItemHeader>
          {settings?.cardless_trials && <ItemHeader align={AlignType.LEFT}>CC Required</ItemHeader>}
          <ItemHeader align={AlignType.LEFT}>Private Description</ItemHeader>
          <ItemHeader>
            <span className="sr-only">Actions</span>
          </ItemHeader>
        </ItemHeaders>
        <ItemsBody>
          {offers.map((offer: PremiumOffer) => (
            <ItemRow key={offer.id} className="text-sm last:border-b-0">
              <ItemColumn align={AlignType.LEFT}>
                <TypographyStack gap="2">
                  <Typography token="font-medium/text/sm">
                    {`${offer.name} - ${offer.tier_name || 'All Tiers'}`}
                  </Typography>
                  <Typography token="font-normal/text/xs" colorWeight="500">
                    {offer.terms}
                  </Typography>
                </TypographyStack>
              </ItemColumn>
              <ItemColumn align={AlignType.LEFT}>{getDefaultText(offer)}</ItemColumn>
              <ItemColumn align={AlignType.LEFT}>
                {offer.expires_at ? moment(offer.expires_at).format('lll') : '-'}
              </ItemColumn>
              {settings?.cardless_trials && (
                <ItemColumn align={AlignType.LEFT}>
                  {offer.card_required ? (
                    <CheckIcon className="h-4 w-4 text-green-500" aria-hidden="true" />
                  ) : (
                    <XMarkIcon className="h-4 w-4 text-red-500" aria-hidden="true" />
                  )}
                </ItemColumn>
              )}
              <ItemColumn align={AlignType.LEFT}>
                <span className="max-w-56 block truncate cursor-pointer" title={offer.private_description}>
                  {offer.private_description}
                </span>
              </ItemColumn>
              <ItemColumn>
                <EllipsisDropdown
                  options={[
                    {
                      label: 'Copy Link',
                      onClick: () => copy({ text: offer.offer_url, onSuccessText: 'Copied!' }),
                    },
                    { label: 'View Redemptions', onClick: () => onOfferSelected(offer) },
                    { label: 'Edit', onClick: () => onEditSelected(offer.id) },
                    { label: 'Delete', isDestructive: true, onClick: () => onDeleteSelected(offer.id) },
                  ]}
                />
              </ItemColumn>
            </ItemRow>
          ))}
        </ItemsBody>
      </Items>
    </>
  );
};

export default Table;
