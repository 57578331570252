import { LinkBreak } from '@phosphor-icons/react';
import classNames from 'classnames';

import { Tooltip } from '../../../UI/Tooltip';

type Props = {
  text?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

const NotConnected = ({ text, onClick, className }: Props) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        className || 'absolute -top-2 -right-2 w-fit bg-white shadow-md rounded-md p-1 group',
        'gap-2 flex items-center'
      )}
      data-skip-drilldown-selector
    >
      <div>
        <Tooltip center={text || 'Not connected to real data'} placement="bottom">
          <LinkBreak size={16} className="text-[#98A2B3] group-hover:text-[#6e46cb]" />
        </Tooltip>
      </div>
    </button>
  );
};

export default NotConnected;
