import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import LoadingBox from '@/components/LoadingBox';
import SlideOver from '@/components/SlideOver';
import {
  useCreateBoostAgreementFilter,
  useUpdateBoostAgreementFilter
} from '@/hooks/boosts/grow/useBoostAgreementFilters';
import useActiveFilterCount from '@/hooks/boosts/grow/useBoostAgreements/useActiveFilterCount';
import { useFilterState } from '@/hooks/boosts/grow/useBoostAgreements/useFilterState';
import { BoostAgreementFilter } from '@/interfaces/boosts/grow/boost_agreement_filter';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import FiltersPopoverSection from '@/ui/FiltersPopover/FiltersPopoverSection';

import { toBoostAgreementFilterAttributes } from '../../utils/boostAgreementFilterTransformers';
import { AutoAcceptModalType } from '../constants';

import BoostAgreementAutoAcceptConfirmationModal from './BoostAgreementAutoAcceptConfirmationModal';

interface Props {
  boostOfferId: string;
  savedFilter?: BoostAgreementFilter | undefined;
  languageOptions: Option[];
  countryOptions: Option[];
  isError: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  tagOptions: Option[];
}

const BUTTON_TEXTS = {
  primary: {
    CREATE: "Enable auto-accept",
    UPDATE: "Update criteria",
    ENABLE: "Enable auto-accept"
  },
  secondary: {
    CREATE: "Cancel",
    UPDATE: "Disable auto-accept",
    ENABLE: "Cancel"
  }
} as const;

const BoostAgreementAutoAcceptSlideOver = ({
  boostOfferId,
  savedFilter,
  tagOptions,
  languageOptions,
  countryOptions,
  isError,
  isLoading,
  isOpen,
  onClose,
}: Props) => {
  const { sections, loadFilterValues, getFilterValues, clearFilters } = useFilterState(
    tagOptions,
    languageOptions,
    countryOptions,
    'lg'
  );

  const { mutate: createBoostAgreementFilter, isLoading: isCreatingBoostAgreementFilter } =
    useCreateBoostAgreementFilter({
      onSuccess: () => {
        toast.success('Auto-accept enabled');
        onClose();
      }
    });

  const { mutate: updateBoostAgreementFilter, isLoading: isUpdatingBoostAgreementFilter } =
    useUpdateBoostAgreementFilter({
      onSuccess: (_filter, variables) => {
        const message = variables.autoAcceptActive
          ? 'Auto-accept criteria updated'
          : 'Auto-accept disabled';

        toast.success(message);
        onClose();
      }
    });

  const { hasActiveFilters } = useActiveFilterCount(sections);
  const [confirmationType, setConfirmationType] = useState<AutoAcceptModalType | null>(null);

  const isNewRecord = !savedFilter && !isLoading;
  const isAutoAcceptEnabled = savedFilter?.auto_accept_active;

  type ActionState = 'CREATE' | 'UPDATE' | 'ENABLE';
  const currentState = isNewRecord
    ? 'CREATE'
    : (isAutoAcceptEnabled ? 'UPDATE' : 'ENABLE') as ActionState;

  const primaryText = BUTTON_TEXTS.primary[currentState];
  const secondaryText = BUTTON_TEXTS.secondary[currentState];
  const canSave = isNewRecord ? hasActiveFilters : true;

  const handleCancel = useCallback(() => {
    clearFilters();
    onClose();
  }, [clearFilters, onClose]);

  const handlePrimaryAction = useCallback(() => {
    const filters = toBoostAgreementFilterAttributes(getFilterValues());

    if (isNewRecord) {
      const date = new Date();
      const epochSeconds = Math.round(date.getTime() / 1000);

      createBoostAgreementFilter({
        name: `Auto-accept filter ${epochSeconds}`,
        autoAcceptable: true,
        autoAcceptActive: true,
        autoAcceptBoostOfferId: boostOfferId,
        filters,
      });
      return;
    }

    if (!savedFilter) return;

    updateBoostAgreementFilter({
      id: savedFilter.id,
      filters,
      autoAcceptActive: true,
      autoAcceptBoostOfferId: boostOfferId,
    });
  }, [boostOfferId, createBoostAgreementFilter, getFilterValues, isNewRecord, savedFilter, updateBoostAgreementFilter]);

  const handleSecondaryAction = useCallback(() => {
    if (currentState === 'UPDATE' && savedFilter?.id) {
      updateBoostAgreementFilter({
        id: savedFilter.id,
        autoAcceptActive: false
      });

      return;
    }

    handleCancel();
  }, [currentState, savedFilter, handleCancel, updateBoostAgreementFilter]);

  const handleConfirm = () => {
    if (confirmationType === 'disable') {
      handleSecondaryAction();
    } else {
      handlePrimaryAction();
    }

    setConfirmationType(null);
  };

  const handlePrimaryClick = () => {
    setConfirmationType(currentState === 'UPDATE' ? 'update' : 'enable');
  };

  const handleSecondaryClick = () => {
    if (currentState === 'UPDATE') {
      setConfirmationType('disable');
      return;
    }

    onClose();
  };

  useEffect(() => {
    if (savedFilter) {
      loadFilterValues(savedFilter);
    }
  }, [loadFilterValues, savedFilter]);

  // Reset filters when closing the slide over
  useEffect(() => {
    if (!isOpen) {
      clearFilters();
    }
  }, [isOpen, clearFilters]);

  const isWorking = isCreatingBoostAgreementFilter || isUpdatingBoostAgreementFilter;

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={handleCancel}
      headerText="Auto-accept"
      headerDescription="Automatically accept applications based on your preferred criteria. You can disable the auto-accept feature at any time."
    >
      <BoostAgreementAutoAcceptConfirmationModal
        isOpen={!!confirmationType}
        isWorking={isWorking}
        type={confirmationType || 'enable'}
        onClose={() => setConfirmationType(null)}
        onProceed={handleConfirm}
      />
      <LoadingBox isLoading={isLoading} isError={isError}>
        <div className="h-full relative">
          <div className="space-y-6 pb-20">
            {sections.map((section) => (
              <FiltersPopoverSection
                key={section.name}
                {...section}
              />
            ))}
          </div>
          <div className="bg-white absolute -mb-6 bottom-0 -left-6 -right-6 p-6 border-t flex space-x-3 justify-end">
            <Button
              type="button"
              variant="primary-inverse"
              onClick={handleSecondaryClick}
              disabled={isWorking}
            >
              {secondaryText}
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handlePrimaryClick}
              disabled={!canSave || isWorking}
            >
              {primaryText}
            </Button>
          </div>
        </div>
      </LoadingBox>
    </SlideOver>
  );
};

export default BoostAgreementAutoAcceptSlideOver;
