import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getStringAttribute } from '../../utils/attributesUtils';
import { BLACK_COLOR, DEFAULT_BORDER_COLOR } from '../constants';

import { TestimonialsView } from './views/TestimonialsView';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    testimonials: {
      setTestimonials: () => ReturnType;
    };
  }
}

type CardSchema = {
  node: string;
  className: string;
  children: CardSchema[];
};

const cardStructure: CardSchema = {
  node: 'div',
  className: 'flex flex-col gap-3 w-full w-full',
  children: [
    {
      node: 'div',
      className: 'flex gap-2',
      children: [
        {
          node: 'div',
          className: 'flex flex-col gap-4 justify-between',
          children: [
            {
              node: 'message',
              className: '',
              children: [],
            },
            {
              node: 'div',
              className: 'flex gap-2',
              children: [
                {
                  node: 'div',
                  className: '',
                  children: [
                    {
                      node: 'image',
                      className: 'w-full h-full object-cover',
                      children: [],
                    },
                  ],
                },
                {
                  node: 'div',
                  className: 'flex flex-col items-start gap-1',
                  children: [
                    {
                      node: 'name',
                      className: '',
                      children: [],
                    },
                    {
                      node: 'description',
                      className: '',
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const Testimonials = Node.create<{}, {}>({
  name: 'testimonials',

  group: 'block',

  draggable: true,

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      insertedFromSidebar: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-inserted-from-sidebar'),
        renderHTML: (attributes) => ({ 'data-inserted-from-sidebar': attributes.insertedFromSidebar }),
      },
      hasFakeData: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-has-fake-data'),
        renderHTML: (attributes) => ({ 'data-has-fake-data': attributes.hasFakeData }),
      },
      gap: {
        default: '16px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      alignment: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({ 'data-alignment': attributes.alignment }),
      },
      shadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      columns: {
        default: '3',
        parseHTML: (element) => element.getAttribute('data-columns'),
        renderHTML: (attributes) => ({ 'data-columns': attributes.columns }),
      },
      borderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '1px',
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_BORDER_COLOR,
      },
      borderStyle: {
        default: 'solid',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },
      padding: {
        default: '16px',
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
        parseHTML: (element) => element.getAttribute('data-padding') || '4px',
      },
      messageColor: {
        default: BLACK_COLOR,
        renderHTML: (attributes) => ({ 'data-message-color': attributes.messageColor }),
        parseHTML: (element) => element.getAttribute('data-message-color') || BLACK_COLOR,
      },
      messageFontFamily: {
        default: 'Inter',
        renderHTML: (attributes) => ({ 'data-message-font-family': attributes.messageFontFamily }),
        parseHTML: (element) => element.getAttribute('data-message-font-family') || 'Inter',
      },
      messageFontSize: {
        default: 'text-sm',
        renderHTML: (attributes) => ({ 'data-message-font-size': attributes.messageFontSize }),
        parseHTML: (element) => element.getAttribute('data-message-font-size') || 'text-sm',
      },
      nameColor: {
        default: BLACK_COLOR,
        renderHTML: (attributes) => ({ 'data-name-color': attributes.nameColor }),
        parseHTML: (element) => element.getAttribute('data-name-color') || BLACK_COLOR,
      },
      nameFontFamily: {
        default: 'Inter',
        renderHTML: (attributes) => ({ 'data-name-font-family': attributes.nameFontFamily }),
        parseHTML: (element) => element.getAttribute('data-name-font-family') || 'Inter',
      },
      nameFontSize: {
        default: 'text-sm',
        renderHTML: (attributes) => ({ 'data-name-font-size': attributes.nameFontSize }),
        parseHTML: (element) => element.getAttribute('data-name-font-size') || 'text-sm',
      },
      descriptionColor: {
        default: BLACK_COLOR,
        renderHTML: (attributes) => ({ 'data-description-color': attributes.descriptionColor }),
        parseHTML: (element) => element.getAttribute('data-description-color') || BLACK_COLOR,
      },
      descriptionFontFamily: {
        default: 'Inter',
        renderHTML: (attributes) => ({ 'data-description-font-family': attributes.descriptionFontFamily }),
        parseHTML: (element) => element.getAttribute('data-description-font-family') || 'Inter',
      },
      descriptionFontSize: {
        default: 'text-xs',
        renderHTML: (attributes) => ({ 'data-description-font-size': attributes.descriptionFontSize }),
        parseHTML: (element) => element.getAttribute('data-description-font-size') || 'text-xs',
      },
      imageSize: {
        default: '40px',
        renderHTML: (attributes) => ({ 'data-image-size': attributes.imageSize }),
        parseHTML: (element) => element.getAttribute('data-image-size') || '40px',
      },
      imageRadius: {
        default: '100px',
        renderHTML: (attributes) => ({ 'data-image-radius': attributes.imageRadius }),
        parseHTML: (element) => element.getAttribute('data-image-radius') || '100px',
      },
      cardStructurePredefinedTemplate: getStringAttribute(
        'cardStructurePredefinedTemplate',
        'data-card-structure-predefined-template',
        'Image Top'
      ),
      cardStructure: {
        default: cardStructure,
        parseHTML: (element) =>
          JSON.parse(element.getAttribute('data-card-structure') || JSON.stringify(cardStructure)),
        renderHTML: (attributes) => ({ 'data-card-structure': JSON.stringify(attributes.cardStructure) }),
      },
      data: {
        default: [],
        parseHTML: (element) => JSON.parse(element.getAttribute('data-data') || '[]'),
        renderHTML: (attributes) => ({ 'data-data': JSON.stringify(attributes.data) }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  addCommands() {
    return {
      setTestimonials:
        () =>
        ({ chain }) =>
          chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                height: 100,
              },
            })
            .run(),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': this.name })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TestimonialsView, {
      stopEvent: () => false,
      attrs: (props) => {
        return {
          ...props.HTMLAttributes,
          style: `width: ${props.node.attrs.width};`,
        };
      },
    });
  },
});

export default Testimonials;
