import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { PendingOpportunitiesBanner } from '@/components/PendingOpportunitiesBanner';
import { WalkthroughPortal } from '@/components/Walkthrough';
import { useAppLayout } from '@/context/app-layout-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { useWalkthroughContext, WalkthroughProvider } from '@/context/walkthrough-context';
import { useOrganization, useUser } from '@/hooks';
import useFreeTrial from '@/hooks/useFreeTrial';
import { useCurrentPublication } from '@/hooks/usePublications';
import { Organization } from '@/interfaces/organization';
import { Publication } from '@/interfaces/publication';
import { IUser, UserWithRoles } from '@/interfaces/user';

import GlobalBanner from '../../GlobalBanner';
import MasqueradeBanner from '../../MasqueradeBanner';

import EmailConfirmationBanner from './Banners/EmailConfirmationBanner';
import SubscriberLimitBanner from './Banners/SubscriberLimitBanner';
import LockedAccountView from './Navbar/LockedAccountView';
import { APP_LAYOUT_BODY_CONTAINER_ID, BODY_HEIGHT_WITH_TOP_BAR } from './constants';
import { SideNav } from './SideNav';
import { TopBar } from './TopBar';

const getMetaFields = (
  user: IUser | UserWithRoles,
  isTrialActive: boolean,
  currentPublication: Publication,
  org: Organization
) => {
  return {
    user_id: user.id,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    channel: 'ada-in-app',
    hashed_email: user.ada_token,
    currently_in_trial: isTrialActive,
    has_premium_newsletter: currentPublication.is_premium_enabled,
    plan_name: currentPublication.plan_name,
    organization_id: currentPublication.organization_id,
    publication_id: currentPublication.id,
    publication_name: currentPublication.name,
    organization_name: org.name,
    sending_disabled: !org.sending_enabled,
    boosts_disabled: org.boosts_disabled,
    boosts_frozen: org.boosts_frozen,
    flagged_for_review: org.flagged_for_review,
  };
};

const toggleAdaChatButton = (adaWidgetEnabled: boolean | undefined) => {
  if (adaWidgetEnabled === true) {
    document.querySelector('#nav-ada-chat-button')?.classList.add('!block');
  } else {
    document.querySelector('#nav-ada-chat-button')?.classList.remove('!block');
  }
};

const resetMetaFields = async (
  user: IUser | UserWithRoles,
  isTrialActive: boolean,
  currentPublication: Publication | undefined,
  org: Organization,
  adaWidgetEnabled: boolean | undefined
) => {
  try {
    if (user && currentPublication && org && adaWidgetEnabled) {
      await window.adaEmbed.setMetaFields(getMetaFields(user, isTrialActive, currentPublication, org));
      toggleAdaChatButton(adaWidgetEnabled);
    }
  } catch {
    // console.error('Error resetting Ada Embed:', error);
  }
};

const NavWrapper = ({
  largeNavOpen,
  isMobileNavOpen,
  setIsMobileNavOpen,
}: {
  largeNavOpen: boolean;
  isMobileNavOpen: boolean;
  setIsMobileNavOpen: (val: boolean) => void;
}) => {
  const { currentStep, navigationStep, handleNextStep, handlePreviousStep, handleFinishWalkthrough } =
    useWalkthroughContext();
  const isLastStep = navigationStep?.currentStep === 7;

  return (
    <WalkthroughPortal
      isActive={currentStep && currentStep === navigationStep?.currentStep}
      title={navigationStep?.title || ''}
      description={navigationStep?.description || ''}
      arrowDirection="left"
      positionClassNameOverride={navigationStep?.positionClassNameOverride || ''}
      arrowPositionOverride={navigationStep?.arrowPositionOverride || ''}
      hasOverlay
      currentStep={navigationStep?.currentStep || 0}
      totalSteps={7}
      continueButton={{
        text: isLastStep ? 'Finish' : 'Next',
        onClick: () => handleNextStep(navigationStep?.nextPath),
      }}
      backButton={{
        text: 'Back',
        onClick: () => handlePreviousStep(navigationStep?.backPath),
      }}
      onClose={handleFinishWalkthrough}
    >
      <div className="z-20 print:hidden">
        <SideNav
          largeNavOpen={largeNavOpen}
          className="flex bg-white w-full"
          isMobileNavOpen={isMobileNavOpen}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
      </div>
    </WalkthroughPortal>
  );
};

const AppLayout = () => {
  const { disableView, largeNavOpen } = useAppLayout();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const { data: currentPublication } = useCurrentPublication();
  const { settings } = useSettings();
  const { currentUser } = useCurrentUser();
  const location = useLocation();
  const userQuery = useUser(currentPublication?.id || '', currentUser?.id);
  const user = userQuery?.data;
  const { data: org } = useOrganization(currentPublication?.organization_id, currentPublication?.id || '');
  const { isTrialActive } = useFreeTrial();

  const defaultTitle = currentPublication ? `${currentPublication.name} - beehiiv` : 'beehiiv';

  useEffect(() => {
    // This  useEffect gets call to often to be able to close the chat on pub switch so i think the best we can do is update the metaFields
    const startAdaEmbed = async () => {
      try {
        if (user && currentPublication && org && settings?.ada_widget) {
          await window.adaEmbed.start({
            handle: 'beehiiv',
            metaFields: getMetaFields(user, isTrialActive, currentPublication, org.organization),
          });
        }

        toggleAdaChatButton(settings?.ada_widget);
      } catch {
        resetMetaFields(
          user as UserWithRoles,
          isTrialActive,
          currentPublication,
          org.organization,
          settings?.ada_widget
        );
      }
    };

    startAdaEmbed();
  }, [user, isTrialActive, currentPublication, org, settings?.ada_widget]);

  useEffect(() => {
    setIsMobileNavOpen(false);
  }, [location]);

  return (
    <WalkthroughProvider>
      <Helmet defaultTitle={defaultTitle} titleTemplate={`%s - ${defaultTitle}`} />
      <header role="banner" id="cio-banner-app" />
      {currentPublication ? (
        <SubscriberLimitBanner
          key={`${currentPublication.organization_id}-${currentPublication.id}`}
          organization_id={currentPublication.organization_id}
          publicationId={currentPublication.id}
        />
      ) : null}
      <MasqueradeBanner org={org?.organization} />
      <EmailConfirmationBanner />
      <GlobalBanner />
      <PendingOpportunitiesBanner />

      {/* Main */}
      <div className="h-screen w-screen overflow-hidden sm:flex">
        <NavWrapper
          largeNavOpen={largeNavOpen}
          isMobileNavOpen={isMobileNavOpen}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <div className="h-screen w-full overflow-hidden bg-surface-50">
          <TopBar
            className="border-b border-surface-200 bg-white"
            onMobileToggle={() => setIsMobileNavOpen(!isMobileNavOpen)}
          />
          <div id={APP_LAYOUT_BODY_CONTAINER_ID} className={cx('overflow-auto py-6', BODY_HEIGHT_WITH_TOP_BAR)}>
            {disableView ? <LockedAccountView organization={org?.organization} /> : <Outlet />}
          </div>
        </div>
      </div>
    </WalkthroughProvider>
  );
};

export default AppLayout;
