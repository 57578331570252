import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Input } from '@/components/Form';
import SlideOver from '@/components/SlideOver';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCreatePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Publication } from '@/interfaces/publication';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreate?: (newPublication: Publication) => void;
  onError?: (error: any) => void;
  disableButtons?: boolean;
}

const NewPublication = ({ isOpen, onClose, onCreate, onError, disableButtons = false }: Props) => {
  const navigate = useNavigate();
  const [, setCurrentPublicationId] = useCurrentPublicationState();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [subdomain, setSubdomain] = useState<string>('');

  const currentPublicationId = useCurrentPublicationId();
  const createPublication = useCreatePublication(currentPublicationId);

  const handleUpdate = async (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (name && description && subdomain) {
      const createdPublication = await createPublication.mutateAsync({
        name,
        description,
      });

      setCurrentPublicationId(createdPublication.data.publication.id);

      // Update subdomain after publication is created
      api
        .patch(`/publications/${createdPublication.data.publication.id}/subdomain`, {
          resource_locator: {
            subdomain,
          },
        })
        .then(() => {
          if (onCreate) {
            onCreate(createdPublication.data.publication);
          } else {
            toast.success('Subdomain successfully updated!');
            navigate(`/settings/publication`);
          }
        })
        .catch((error: any) => {
          if (onError) {
            onError(error?.response?.data?.error || 'Something went wrong');
          } else {
            toast.error('Invalid subdomain');
            navigate('/settings/publication/domains');
          }
        });
    } else {
      toast.error('Please fill out all fields');
    }
  };

  return (
    <SlideOver
      headerText="New Publication"
      isOpen={isOpen}
      onClose={onClose}
      actionsChildren={
        <div className="flex gap-x-2 justify-end">
          <Button size="xs" type="button" variant="primary-inverse" onClick={onClose} disabled={disableButtons}>
            Cancel
          </Button>
          <Button
            size="xs"
            type="submit"
            variant="primary"
            loading={createPublication.isLoading}
            disabled={disableButtons}
            onClick={handleUpdate}
          >
            <div className="flex">{createPublication.isLoading ? 'Creating...' : 'Create Publication'}</div>
          </Button>
        </div>
      }
    >
      <form onSubmit={handleUpdate}>
        <div className="w-full space-y-4">
          <Input
            className="w-full"
            name="name"
            labelText="Publication Name"
            placeholder="My New Publication"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            className="w-full"
            name="description"
            labelText="This publication is about..."
            placeholder="A newsletter about lifestyle"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Input
            className="w-full"
            name="email"
            labelText="Subdomain"
            placeholder="my-new-publication"
            value={subdomain}
            onChange={(e) => setSubdomain(e.target.value)}
          />
        </div>
      </form>
    </SlideOver>
  );
};

export default NewPublication;
