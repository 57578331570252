import { memo, useCallback } from 'react';
import { sticky } from 'tippy.js';

import { useEditorStateNonBlocking } from '@/components/TiptapEditor/lib/hooks/useEditorStateNonBlocking';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const BlockquoteFigureMenu = memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'blockquoteFigure');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    return editor.isActive('blockquoteFigure');
  }, [editor]);

  const { isVariant1Active, isVariant2Active, isVariant3Active } = useEditorStateNonBlocking({
    editor,
    selector: () => {
      return {
        isVariant1Active: editor.isActive('blockquoteFigure', { variant: '1' }),
        isVariant2Active: editor.isActive('blockquoteFigure', { variant: '2' }),
        isVariant3Active: editor.isActive('blockquoteFigure', { variant: '3' }),
      };
    },
  });

  const handleSetBlockquoteVariant = useCallback(
    (variant: '1' | '2' | '3') => {
      editor.chain().focus().setBlockquoteVariant(variant).run();
    },
    [editor]
  );

  const handleDeleteBlockquote = useCallback(() => {
    editor.chain().focus().deleteNode('blockquoteFigure').run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="blockquoteMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar shouldShowContent={shouldShow()}>
        <Tooltip title="Variant 1">
          <Button
            $active={isVariant1Active}
            $leftSlot={<Icon name="BorderQuote" />}
            onClick={() => handleSetBlockquoteVariant('1')}
            {...buttonProps}
          />
        </Tooltip>
        <Tooltip title="Variant 2">
          <Button
            $active={isVariant2Active}
            $leftSlot={<Icon name="Blockquote" />}
            onClick={() => handleSetBlockquoteVariant('2')}
            {...buttonProps}
          />
        </Tooltip>
        <Tooltip title="Variant 3">
          <Button
            $active={isVariant3Active}
            $leftSlot={<Icon name="Dropquote" />}
            onClick={() => handleSetBlockquoteVariant('3')}
            {...buttonProps}
          />
        </Tooltip>
        <Divider />
        <Tooltip title="Delete quote">
          <Button $leftSlot={<Icon name="Trash" />} onClick={handleDeleteBlockquote} {...buttonProps} />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
});

export default BlockquoteFigureMenu;
