import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment-mini';

import { useSettings } from '@/context/settings-context';
import { EllipsisDropdownOption } from '@/interfaces/general';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import ActionModal from '../../../components/ActionModal';
import EllipsisDropdown from '../../../components/EllipsisDropdown';
import { useCopyToClipboard } from '../../../hooks';
import { useDeleteForm, useUpdateForm } from '../../../hooks/useForms';
import { useUpdatePublication } from '../../../hooks/usePublications';
import { Form, FormStatus } from '../../../interfaces/form';
import Tooltip from '../../../ui/Tooltip';

import StatusBadge from './StatusBadge';

interface Props {
  form: Form;
  isPostSubscribeForm: boolean;
  isPostUnsubscribeForm: boolean;
  publicationId: string;
  classNames: string;
  updateFormList: () => void;
}

const FormCard = ({
  form,
  isPostSubscribeForm,
  isPostUnsubscribeForm,
  publicationId,
  classNames,
  updateFormList,
}: Props) => {
  const queryClient = useQueryClient();
  const { settings } = useSettings();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const copy = useCopyToClipboard();
  const missingCustomFields = form.form_questions.some((question) => question.custom_field.id === null);
  const publicationMutation = useUpdatePublication(publicationId);

  const hasSurveysV2Enabled = settings?.surveys_v2 === true;

  const deleteForm = useDeleteForm({
    formId: form?.id || '',
  });
  const handleDelete = () => {
    deleteForm.mutate();
    analytics.track('Deleted a Survey Form');
    setIsDeleting(false);
  };

  const updateForm = useUpdateForm({
    formId: form.id || '',
    onSuccess: () => {
      toast.success('Survey saved!');
      queryClient.invalidateQueries([publicationId, 'forms', '']);
      navigate('/forms');
    },
  });

  const isFormLive = form.status === FormStatus.LIVE;
  const isFormDraft = form.status === FormStatus.DRAFT;

  const options: EllipsisDropdownOption[] = [
    isFormLive
      ? {
          label: 'View Settings',
          onClick: () =>
            hasSurveysV2Enabled ? navigate(`/forms/${form.id}/edit/settings`) : navigate(`/forms/${form.id}/edit`),
        }
      : {
          label: 'Edit',
          onClick: () =>
            hasSurveysV2Enabled ? navigate(`/forms/${form.id}/edit/form_builder`) : navigate(`/forms/${form.id}/edit`),
        },
    {
      label: 'Duplicate',
      onClick: async () => {
        try {
          await api.post(`/forms/${form.id}/duplicate`, { publication_id: publicationId });

          toast.success('Survey duplicated');
          updateFormList();
        } catch (err: any) {
          toast.error(err?.response?.data?.error || 'Something went wrong');
        }
      },
    },
  ];

  if (isFormDraft) {
    options.push({
      label: 'Delete',
      onClick: () => setIsDeleting(true),
    });
  } else {
    options.push({
      label: 'View results',
      onClick: () =>
        hasSurveysV2Enabled ? navigate(`/forms/${form.id}/edit/responses`) : navigate(`/forms/${form.id}`),
    });

    if (isFormLive) {
      options.push({
        label: 'Copy url',
        onClick: () => {
          copy({ text: form.url });
        },
      });

      if (!isPostUnsubscribeForm) {
        options.push({
          label: isPostSubscribeForm ? 'Disable as Subscribe Survey' : 'Enable as Subscribe Survey',
          onClick: () => {
            publicationMutation.mutate({
              post_subscribe_form_id: isPostSubscribeForm ? null : form.id,
            });
          },
        });
      }

      if (!isPostSubscribeForm) {
        options.push({
          label: isPostUnsubscribeForm ? 'Disable as Unsubscribe Survey' : 'Enable as Unsubscribe Survey',
          onClick: () => {
            publicationMutation.mutate({
              post_unsubscribe_form_id: isPostUnsubscribeForm ? null : form.id,
            });
          },
        });
      }
    }

    if (!isPostSubscribeForm || !isPostUnsubscribeForm) {
      options.push({
        label: isFormLive ? 'Archive' : 'Unarchive',
        onClick: () => {
          updateForm.mutate({
            ...form,
            status: isFormLive ? FormStatus.ARCHIVED : FormStatus.LIVE,
          });
        },
      });
    }
  }

  // eslint-disable-next-line no-nested-ternary
  const formTitleLinkHref = hasSurveysV2Enabled
    ? isFormLive
      ? `/forms/${form.id}/edit/responses`
      : `/forms/${form.id}/edit/form_builder`
    : `/forms/${form.id}`;

  return (
    <>
      <ActionModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onProceed={handleDelete}
        resourceId={form.id}
        isWorking={deleteForm.isLoading}
        headerText="Delete Survey"
        actionText="Delete"
        buttonType="danger"
      >
        Are you sure you want to delete this survey?
      </ActionModal>
      <tr key={form.id}>
        <td className={classNames}>
          <div className="flex flex-col w-full">
            <div className="font-medium">
              <Link className="text-primary-600 hover:text-primary-900" to={formTitleLinkHref}>
                {form.title}
              </Link>
              {form.description && (
                <p className="text-xs text-gray-500 py-1 truncate w-52" title={form.description}>
                  {form.description}
                </p>
              )}
            </div>
          </div>
        </td>
        <td className="text-sm text-gray-900">
          <div className="flex space-x-2 items-center">
            <StatusBadge status={form?.status} />
          </div>
        </td>
        <td className={cx(classNames, 'capitalize')}>
          {isPostSubscribeForm && <span>Subscribe</span>}
          {isPostUnsubscribeForm && <span>Unsubscribe</span>}
          {!isPostSubscribeForm && !isPostUnsubscribeForm && form?.status !== 'draft' && <span>Standalone</span>}
          {missingCustomFields && (
            <Tooltip
              iconClass="inline ml-1"
              id={`${form.id}-incomplete`}
              text="Some questions are missing custom fields"
            />
          )}
        </td>
        <td className={cx(classNames, 'pl-12')}>{form.form_response_count.toLocaleString()}</td>
        <td className={classNames}>
          {form.most_recent_response ? moment(form.most_recent_response).fromNow() : 'No responses'}
        </td>
        <td className={classNames}>{moment(form.created_at).fromNow()}</td>
        <td className={classNames}>
          <EllipsisDropdown options={options} />
        </td>
      </tr>
    </>
  );
};

export default FormCard;
