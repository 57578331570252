import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import isEqual from 'lodash.isequal';

import { BoostAgreementFilter } from '@/interfaces/boosts/grow/boost_agreement_filter';
import { Option } from '@/interfaces/general';
import { toBoostAgreementFilterAttributes } from '@/pages/Grow/Boosts/utils/boostAgreementFilterTransformers';

import {
  useBoostAgreementFilters,
  useCreateBoostAgreementFilter,
  useDeleteBoostAgreementFilter,
  useUpdateBoostAgreementFilter,
} from '../useBoostAgreementFilters';

import { useFilterState } from './useFilterState';

const useFilters = (tagOptions: Option[], languageOptions: Option[], countryOptions: Option[]) => {
  const [selectedSavedFilter, setSelectedSavedFilter] = useState<BoostAgreementFilter | undefined>();
  const [isConfirmingSaveFilter, setIsConfirmingSaveFilter] = useState(false);
  const [renamingSavedFilter, setRenamingSavedFilter] = useState<BoostAgreementFilter | undefined>();
  const [deletingSavedFilter, setDeletingSavedFilter] = useState<BoostAgreementFilter | undefined>();

  const {
    sections,
    clearFilters: clearFilterState,
    loadFilterValues,
    getFilterValues,
  } = useFilterState(tagOptions, languageOptions, countryOptions, 'md');

  const clearFilters = useCallback(
    (clearSavedFilter = true) => {
      if (clearSavedFilter) {
        setSelectedSavedFilter(undefined);
      }
      clearFilterState();
    },
    [clearFilterState]
  );

  const savedFilterAttributes = useMemo(() => toBoostAgreementFilterAttributes(getFilterValues()), [getFilterValues]);

  // Saved Filters
  const { data: boostAgreementFiltersData } = useBoostAgreementFilters({ onlySaved: true, onlyAutoAcceptable: false });
  const savedFilters = boostAgreementFiltersData?.boost_agreement_filters;
  const savedFilterOptions = useMemo(() => {
    if (!savedFilters) return [];

    return savedFilters.map((filter) => ({
      label: filter.name || 'Unnamed filter',
      value: filter.id,
    }));
  }, [savedFilters]);

  const { mutate: createBoostAgreementFilter, isLoading: isCreatingBoostAgreementFilter } =
    useCreateBoostAgreementFilter({
      onSuccess: (boostAgreementFilter) => {
        toast.success('Filter created successfully');

        setSelectedSavedFilter(boostAgreementFilter);
        setIsConfirmingSaveFilter(false);
      },
    });

  const { mutate: updateBoostAgreementFilter, isLoading: isUpdatingBoostAgreementFilter } =
    useUpdateBoostAgreementFilter({
      onSuccess: () => {
        toast.success('Filter updated successfully');

        setIsConfirmingSaveFilter(false);
        setRenamingSavedFilter(undefined);
      },
    });

  const { mutate: deleteBoostAgreementFilter, isLoading: isDeletingBoostAgreementFilter } =
    useDeleteBoostAgreementFilter({
      onSuccess: () => {
        toast.success('Filter deleted successfully');

        setDeletingSavedFilter(undefined);
      },
    });

  const handleSelectSavedFilter = (filterId: string) => {
    const filter = savedFilters?.find((f) => f.id === filterId);
    setSelectedSavedFilter(filter);
  };

  const handleSetIsRenamingSavedFilter = (filterId: string) => {
    const filter = savedFilters?.find((f) => f.id === filterId);
    setRenamingSavedFilter(filter);
  };

  const handleSetIsDeletingSavedFilter = (filterId: string) => {
    const filter = savedFilters?.find((f) => f.id === filterId);
    setDeletingSavedFilter(filter);
  };

  const handleSaveFilter = useCallback(
    (name: string, replacingFilterId?: string) => {
      if (replacingFilterId) {
        return updateBoostAgreementFilter({
          id: replacingFilterId,
          name,
          filters: savedFilterAttributes,
        });
      }
      return createBoostAgreementFilter({
        name,
        filters: savedFilterAttributes,
      });
    },
    [createBoostAgreementFilter, savedFilterAttributes, updateBoostAgreementFilter]
  );

  const handleRenameSavedFilter = useCallback(
    (name: string, filterId: string) => {
      return updateBoostAgreementFilter({
        id: filterId,
        name,
        filters: savedFilterAttributes,
      });
    },
    [updateBoostAgreementFilter, savedFilterAttributes]
  );

  const handleDeleteSavedFilter = useCallback(
    (filterId: string) => {
      return deleteBoostAgreementFilter(filterId);
    },
    [deleteBoostAgreementFilter]
  );

  // Load saved filter values when a saved filter is selected
  useEffect(() => {
    if (!selectedSavedFilter) return;

    clearFilters(false);
    loadFilterValues(selectedSavedFilter);
  }, [clearFilters, loadFilterValues, selectedSavedFilter]);

  // When a saved filter is selected, and the filter attributes change, deselect the saved filter
  useEffect(() => {
    if (!selectedSavedFilter) return;

    if (!isEqual(savedFilterAttributes, selectedSavedFilter.filters)) {
      setSelectedSavedFilter(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedFilterAttributes]);

  return {
    sections,
    clearFilters,
    boostAgreementsQueryParams: getFilterValues(),
    selectedSavedFilter,
    savedFilterAttributes,
    savedFilterOptions,
    handleSelectSavedFilter,
    isConfirmingSaveFilter,
    setIsConfirmingSaveFilter,
    setRenamingSavedFilter,
    renamingSavedFilter,
    handleSetIsRenamingSavedFilter,
    handleSetIsDeletingSavedFilter,
    setDeletingSavedFilter,
    deletingSavedFilter,
    handleSaveFilter,
    handleRenameSavedFilter,
    handleDeleteSavedFilter,
    isCreatingBoostAgreementFilter,
    isUpdatingBoostAgreementFilter,
    isDeletingBoostAgreementFilter,
  };
};

export default useFilters;
