import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';

export const CONNECT_DOMAIN_HEADER_TEXTS = {
  default: 'Add a custom domain for your beehiiv website and to send emails from.',
  entri: 'Add a custom website domain through Entri DNS to send emails from or set your domain yourself.',
};

export const DOMAINS_HEADER_TEXTS = {
  shared: {
    title: 'beehiiv Subdomains',
    description: 'Web subdomains and email usernames using our shared beehiiv infrastructure',
  },
  other: {
    title: 'Verified Domains',
    description: 'Custom domains and email address for your publication',
  },
};

export const SECONDARY_EMAIL_HELPER_TEXT =
  'During Smart Warming, additional rollover volume is sent from this address.';

export const DISCONNECT_EFFECTS: Record<CustomDomainTypes, string[]> = {
  [CustomDomainTypes.EMAIL]: [
    'Your emails will be sent from a shared mail.beehiiv.com domain',
    "You'll need to set up the domain again if you want to use it in the future",
  ],
  [CustomDomainTypes.WEB]: [
    'Your site will no longer be accessible via this domain',
    'All existing links using this domain will stop working',
    "You'll need to set up the domain again if you want to use it in the future",
  ],
  [CustomDomainTypes.REDIRECT]: [
    'This domain will no longer redirect to the specified URL',
    "You'll need to set up the domain again if you want to use it in the future",
  ],
  [CustomDomainTypes.BRANDED_LINK]: [
    'All links in your emails will use a beehiiv domain.',
    'Your existing custom domain branded links will stop working.',
  ],
};

export const BRANDED_LINK_STATUS_TEXT = {
  SETUP_AVAILABLE: 'Branded Link Setup Available',
  ENTRI_VERIFYING: 'Entri Branded Link Verification in Progress',
  VERIFYING: 'Branded Link Verification in Progress',
  ACTION_REQUIRED: 'Branded Link: Action Required',
} as const;

export interface DnsRecordsModalStep {
  type: CustomDomainTypes;
  label: string;
  customDomain: CustomDomain | undefined;
  verified: boolean;
  visible: boolean;
}
