import { useInfiniteQuery } from 'react-query';
import { Pagination } from '@shared/dream-components';

import { AdPortalCampaign, AdPortalCampaignStatus } from '@/ad_portal/interfaces/campaign';

import api from '../../../services/swarm';
import useCurrentAdvertiserId from '../useAdvertisers/useCurrentAdvertiserId';

interface ApiResponse {
  campaigns: AdPortalCampaign[];
  pagination: Pagination;
}

interface UseCampaignsProps {
  shouldFetchAll?: boolean;
  status: AdPortalCampaignStatus | 'all';
}

export default function useCampaigns({ shouldFetchAll = false, status = 'all' }: UseCampaignsProps) {
  const advertiserId = useCurrentAdvertiserId();
  const fetchCampaigns = ({ pageParam = 1 }) =>
    api
      .get('/ad_portal/campaigns', {
        params: {
          page: pageParam,
          all_results: shouldFetchAll,
          status,
          advertiser_id: advertiserId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['ad_portal', advertiserId, 'campaigns', shouldFetchAll, status],
    fetchCampaigns,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
}
