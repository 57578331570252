import { Setting, Settings } from '@/interfaces/setting';

const settings: Settings = {
  [Setting.AD_NETWORK]: true,
  [Setting.ADA_WIDGET]: false,
  [Setting.AI_EDITOR_REQUESTS]: 100,
  [Setting.APEX_DOMAINS]: true,
  [Setting.API_V1]: true,
  [Setting.API_V2]: true,
  [Setting.AUDIENCE_LEADERBOARD]: true,
  [Setting.AUDIO_NEWSLETTERS]: true,
  [Setting.AUTOMATIONS_USE_APIARY]: true,
  [Setting.AUTOMATIONS_WEBHOOK_STEP]: false,
  [Setting.AUTOMATIONS]: true,
  [Setting.BOOST_QUALITY_COMPARISON]: true,
  [Setting.BOOSTS_IDENTITY_VERIFICATION]: true,
  [Setting.BOOSTS_INVITES]: true,
  [Setting.BOOSTS_MONETIZE_V2]: true,
  [Setting.BOOSTS_PLUS]: true,
  [Setting.BOOSTS_STRICT_VERIFICATION]: false,
  [Setting.BOOSTS]: true,
  [Setting.BYPASS_DISABLE_RECAPTCHA_CHECKS]: false,
  [Setting.CALENDAR]: true,
  [Setting.CARDLESS_TRIALS]: true,
  [Setting.COLLABORATIVE_EDITING]: false,
  [Setting.COMMENTS]: true,
  [Setting.CONDITIONALLY_SHOWN_CPM_ADS]: true,
  [Setting.CONTENT_TAGS]: true,
  [Setting.CONTRIBUTOR_ROLE]: true,
  [Setting.CUSTOM_BRANDED_LINKS]: true,
  [Setting.CUSTOM_DOMAINS]: true,
  [Setting.CUSTOM_FIELDS]: true,
  [Setting.CUSTOM_HTML_BLOCKS]: true,
  [Setting.CUSTOM_PAGES]: true,
  [Setting.CUSTOM_SPONSOR_TEXT_ON_POST]: false,
  [Setting.DARK_MODE]: true,
  [Setting.DONATION_PAYMENTS]: false,
  [Setting.EDIT_AD_LOGO_ON_POST]: false,
  [Setting.EDITOR_TEXT_TO_SPEECH]: false,
  [Setting.EDITOR_THREADS]: false,
  [Setting.ENTRI]: false,
  [Setting.EXTERNAL_TEST_SENDS]: true,
  [Setting.FORMS]: true,
  [Setting.FREE_SUPPORT]: true,
  [Setting.GIFT_PREMIUM_SUBSCRIPTIONS]: true,
  [Setting.HIIV_DREAM]: true,
  [Setting.HIIV_DREAM_DESIGNER]: false,
  [Setting.HIIV_DREAM_VISIBLE]: false,
  [Setting.JWT_TOKEN_FETCH_ENDPOINT]: false,
  [Setting.LANDING_PAGES]: true,
  [Setting.LOCALIZATION]: true,
  [Setting.MAILCHIMP_INTEGRATION]: true,
  [Setting.MAX_AUTOMATION_DELAY_DAYS]: 9999,
  [Setting.MAX_AUTOMATION_STEPS]: 9999,
  [Setting.MAX_DAILY_PUBLICATION_TEST_SENDS]: 9999,
  [Setting.MAX_IMPORTED_SUBSCRIPTIONS]: 9999,
  [Setting.MAX_MONTHLY_BOOST_AGREEMENTS]: 25,
  [Setting.MAX_MONTHLY_BOOST_INVITATIONS]: 25,
  [Setting.MAX_PREMIUM_BENEFITS]: 9999,
  [Setting.MAX_PREMIUM_TIERS]: 9999,
  [Setting.MAX_PUBLICATIONS]: 9999,
  [Setting.MAX_SEGMENTS]: 9999,
  [Setting.MAX_SPLIT_TEST_OPTIONS]: 9999,
  [Setting.MAX_SUBSCRIPTIONS]: 9999,
  [Setting.MAX_TEAM_MEMBERS]: 9999,
  [Setting.MAX_TIP_TAP_CHARACTER_LIMIT]: 9999999,
  [Setting.MULTIPLE_TEAM_MEMBERS]: true,
  [Setting.NAVIGATION]: false,
  [Setting.ONE_CLICK_UNSUBSCRIBE]: true,
  [Setting.ONE_TIME_PAYMENTS]: false,
  [Setting.OPT_IN_EMAIL_EDITOR]: true,
  [Setting.ORGANIZATION_BOOSTS]: true,
  [Setting.PARTNER_PROGRAM_TIERS_TAB]: false,
  [Setting.PARTNER_PROGRAM]: false,
  [Setting.PAYWALLS]: false,
  [Setting.POLLS_V2]: true,
  [Setting.POLLS]: true,
  [Setting.POST_EDITOR_V2]: true,
  [Setting.POST_GIFT_LINKS]: true,
  [Setting.POST_TOP_SUBSCRIBERS_TABLE]: false,
  [Setting.POSTS_CLICK_MAP_LINK_GROUPING]: true,
  [Setting.POSTS_CLICK_TRACKING]: true,
  [Setting.POSTS_DASHBOARD_V2]: true,
  [Setting.POSTS_INDEX_V2]: false,
  [Setting.PREMIUM_SUBSCRIPTIONS]: true,
  [Setting.PREMIUM_TIERS_ROLLOUT]: false,
  [Setting.PRIVATE_PUBLICATIONS]: true,
  [Setting.PUBLICATION_SETTINGS_V2]: true,
  [Setting.PURCHASE_GIFT_SUBSCRIPTIONS]: true,
  [Setting.RAISE_SUBSCRIBER_IMPORT_LIMIT_FORM]: true,
  [Setting.REACT_APP_ONBOARDING_V4_ENABLED]: false,
  [Setting.RECOMMENDATIONS_WIDGET]: true,
  [Setting.RECOMMENDATIONS]: true,
  [Setting.RECOMMENDED_SENDING_TIME]: true,
  [Setting.REFERRAL_PROGRAM_PREMIUM_GIFT_REWARD]: true,
  [Setting.REFERRAL_PROGRAM_V2]: true,
  [Setting.REFERRAL_PROGRAM]: true,
  [Setting.REPORT_ABUSE]: true,
  [Setting.REPORTS]: true,
  [Setting.REWIND_2024]: false,
  [Setting.RSS_TO_SEND]: false,
  [Setting.SEND_API]: false,
  [Setting.SEND_TO_SEGMENTS]: true,
  [Setting.SMART_WARMING]: true,
  [Setting.SPLIT_TEST_CUSTOMIZATION]: true,
  [Setting.SPLIT_TEST_OVERRIDE_ENABLED]: true,
  [Setting.SPONSOR_NETWORK]: true,
  [Setting.STRIPE_IDENTITY_VERIFICATION]: true,
  [Setting.SUBSCRIBER_PROFILE_V2]: true,
  [Setting.SUBSCRIBER_TAGGING]: true,
  [Setting.SUBSCRIBERS_PREFERENCES]: true,
  [Setting.SUBSCRIBERS_PREMIUM_DASHBOARD_V2]: true,
  [Setting.SUBSCRIBERS_PREMIUM_DASHBOARD]: true,
  [Setting.SUBSCRIPTION_PASSWORD_RESET]: true,
  [Setting.SUBSCRIPTION_PASSWORD]: true,
  [Setting.SUPPORT_TICKETS]: true,
  [Setting.SURVEYS_V2]: true,
  [Setting.SYSTEM_ADMIN]: true,
  [Setting.TESTIMONIALS]: true,
  [Setting.THROTTLE_DAILY_SENDS]: true,
  [Setting.TIERED_PRICING_ROLLOUT]: false,
  [Setting.TIERED_PRICING]: true,
  [Setting.TWO_FACTOR_AUTH]: true,
  [Setting.UPGRADE_PAGE]: true,
  [Setting.USE_APIARY]: true,
  [Setting.USER_INVITES_V2]: true,
  [Setting.USER_ROLES_V2]: true,
  [Setting.UTM_PARAMS]: true,
  [Setting.VERIFIED_CLICKS_V2]: true,
  [Setting.VERIFICATION_REQUIRED]: true,
  [Setting.WALLET]: true,
  [Setting.WALLET_V2]: false,
  [Setting.WEB_BUILDER]: true,
  [Setting.WEBHOOKS]: true,
  [Setting.WHITE_LABELING]: true,
};

export default settings;
