/* eslint-disable react/no-unused-prop-types */
import React, { memo, ReactNode, useEffect, useState } from 'react';
import Frame, { useFrame } from 'react-frame-component';
import { SpinnerGap } from '@phosphor-icons/react';
import { StyleSheetManager } from 'styled-components';

interface IframeWrapperProps {
  children: ReactNode;
  width: number;
  height: number;
  contentWidth: number;
  fontHrefs: Set<string>;
  iframeProps?: React.IframeHTMLAttributes<HTMLIFrameElement>;
  allowClicking?: boolean;
}

const StyleManager = ({ children }: { children: React.ReactNode }) => {
  const { document } = useFrame();
  return (
    <StyleSheetManager target={document?.head}>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>{children}</>
    </StyleSheetManager>
  );
};

const IframeWrapper = memo(
  React.forwardRef<HTMLIFrameElement, IframeWrapperProps & { disableMemo?: boolean }>(
    ({ children, width, height, contentWidth, iframeProps, fontHrefs, allowClicking = true }, ref) => {
      const [initialContent, setInitialContent] = useState('');

      useEffect(() => {
        fetch('/canvas/index._html')
          .then((d) => d.text())
          .then(setInitialContent);
      }, []);

      const links = Array.from(new Set(fontHrefs)).map((href) => ({ rel: 'stylesheet', href }));

      useEffect(() => {
        if (!ref || !initialContent) return () => { };

        // Handle both RefObject and MutableRefObject cases
        const iframe = ('current' in ref) ? ref.current : null;
        if (!iframe) return () => { };

        // Wait for iframe to load
        const handleLoad = () => {
          if (!allowClicking) {
            iframe.contentWindow?.document.addEventListener('click', (e: MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
            });
          }
        };

        iframe.addEventListener('load', handleLoad);

        return () => {
          iframe.removeEventListener('load', handleLoad);
          // Clean up click listener
          if (!allowClicking) {
            iframe.contentWindow?.document.removeEventListener('click', (e: MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
            });
          }
        };
      }, [ref, allowClicking, initialContent]);

      if (!initialContent)
        return (
          <div className="w-full min-h-[400px] flex flex-col justify-center items-center p-16 bg-white">
            <SpinnerGap size={24} className="animate-spin text-wb-accent" />
          </div>
        );

      const scale = width / contentWidth;

      return (
        <Frame
          ref={ref}
          initialContent={initialContent}
          {...iframeProps}
          head={
            <>
              {links.map((link) => (
                <link key={link.href} rel={link.rel} href={link.href} />
              ))}
            </>
          }
          title="Page Editor Content"
          data-scale={scale}
          mountTarget="body"
          style={{
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            width: contentWidth,
            minHeight: height / scale,
            border: 'none',
            outline: 'none',
            ...iframeProps?.style,
          }}
        >
          <StyleManager>{children}</StyleManager>
        </Frame>
      );
    }
  ),
  (prevProps, nextProps) => {
    if (prevProps.disableMemo || nextProps.disableMemo) {
      return false; // Always re-render
    }

    return (
      prevProps.width === nextProps.width &&
      prevProps.height === nextProps.height &&
      prevProps.contentWidth === nextProps.contentWidth &&
      prevProps.fontHrefs.size === nextProps.fontHrefs.size &&
      Array.from(prevProps.fontHrefs).every((href) => nextProps.fontHrefs.has(href))
    );
  }
);

IframeWrapper.displayName = 'IframeWrapper';

export default IframeWrapper;
