import { useInfiniteQuery } from 'react-query';
import { Pagination } from '@shared/dream-components';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { Recommendation } from '../interfaces/recommendation';
import api from '../services/swarm';

interface ApiResponse {
  boosted_recommendations: Recommendation[];
  pagination: Pagination;
}

export default function useBoostedRecommendations({ enabled }: { enabled?: boolean } = { enabled: true }) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostedRecommendations = ({ pageParam = 1 }) =>
    api
      .get(`/boosted_recommendations`, {
        params: {
          page: pageParam,
          per_page: 10,
          publication_id: currentPublicationId,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(
    ['publications', currentPublicationId, 'boosted_recommendations'],
    fetchBoostedRecommendations,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
      enabled,
    }
  );
}
