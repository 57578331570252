import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getFonts, getUsedFonts } from '@shared/dream-components';
import type { JSONContent } from '@tiptap/core';

export const useFonts = ({ enabled = true }: { enabled?: boolean }) =>
  useQuery('hiiv-web-fonts', getFonts, {
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    enabled,
  });

export const useFontHrefsFromContent = (content?: JSONContent | null) => {
  const [fontHrefs, setFontHrefs] = useState<Set<string>>(new Set());

  const { data: fonts } = useFonts({ enabled: !!content });

  useEffect(() => {
    if (!content) return;

    const f = getUsedFonts(content, fonts);
    setFontHrefs(new Set(f));
  }, [content, fonts]);

  return fontHrefs;
};
