import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getJSONAttribute, getStringAttribute } from '../../utils/attributesUtils';

import { IconView } from './views/IconView';

export const Icon = Node.create<{}, {}>({
  name: 'icon',

  group: 'block',

  draggable: true,

  atom: true,

  selectable: true,

  addAttributes() {
    return {
      insertedFromSidebar: getStringAttribute('insertedFromSidebar', 'data-inserted-from-sidebar', 'true'),
      icon: getJSONAttribute('icon', 'data-icon', {
        iconName: 'Bell',
        iconType: 'phosphor',
      }),
      size: getStringAttribute('size', 'data-size', '24px'),
      fill: getStringAttribute('fill', 'data-fill', '#000000'),
      backgroundColor: getStringAttribute('backgroundColor', 'data-background-color', '#FFFFFF'),
      padding: getStringAttribute('padding', 'data-padding', '0px'),
      borderRadius: getStringAttribute('borderRadius', 'data-border-radius', '8px'),
      borderWidth: getStringAttribute('borderWidth', 'data-border-width', '1px'),
      borderColor: getStringAttribute('borderColor', 'data-border-color', '#000000'),
      borderStyle: getStringAttribute('borderStyle', 'data-border-style', 'none'),
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(HTMLAttributes, { 'data-type': this.name })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(IconView, { className: 'w-fit' });
  },
});

export default Icon;
