import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CaretDown } from '@phosphor-icons/react';

import { DreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';
import usePublishSite from '@/hooks/useSite/usePublishSite';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/routes/website/_components/UI/DropdownMenu';

import { NavbarDataContext } from '../../navbar/_components/NavbarEditor/NavbarDataContext';
import DialogPublishV2 from '../Main/DialogPublishV2';
import DialogRevertV1 from '../Main/DialogRevertV1';
import { Button } from '../UI/Button';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

import { PublishSettingsStep } from './PublishSettingsStep';
import { SearchPagesStep } from './SearchPagesStep';
import { Section } from './Section';
import { SelectPagesStep } from './SelectPagesStep';

export const PublishDropDown = () => {
  const [step, setStep] = useState<'publish_settings' | 'select_pages' | 'search_pages'>('publish_settings');
  const { site, pages } = useWebsiteContext();
  const { data: currentPublication } = useCurrentPublication();

  const dreamEditorContext = useContext(DreamEditorContext);
  const navbarDataContext = useContext(NavbarDataContext);

  const [isSettingsSelected, setIsSettingsSelected] = useState(false);

  const [selectedPages, setSelectedPages] = useState<Record<string, boolean>>({});
  const [isPublished, setIsPublished] = useState(false);

  const [isOpenPublishV2, setIsOpenPublishV2] = useState(false);
  const [isOpenRevertV1, setIsOpenRevertV1] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const isAllPagesSelected = pages && selectedPages && pages?.every((page) => selectedPages[page.id]);
  const pageIDs = pages?.map((page) => page.id);

  const [isPublishSelectedPages, setIsPublishSelectedPages] = useState(false);

  const isNothingToPublish = (!isPublishSelectedPages || Object.values(selectedPages).filter(Boolean).length === 0) && !isSettingsSelected;

  const { mutate: publishSiteMutate, isLoading } = usePublishSite({
    id: site?.id || '',
    onSuccess: () => {
      toast.success('Site published successfully');
      setIsPublished(true);
      setTimeout(() => {
        setIsPublished(false);
      }, 3000);
    },
  });

  const onPublish = async () => {
    const publishPageIDs = !isPublishSelectedPages ? [] : Object.keys(selectedPages).filter((pageId) => selectedPages[pageId]);

    // Save any editor or navbar editor changes before publishing
    if (dreamEditorContext?.changesMade && dreamEditorContext.save) {
      await dreamEditorContext.save();
    }
    if (navbarDataContext?.changesMade && navbarDataContext.onSave) {
      await navbarDataContext.onSave();
    }

    publishSiteMutate({
      publishSettings: isSettingsSelected,
      publishPageIDs,
    });
  };

  const onSelectAllPages = () => {
    if (!pages) return;
    if (isAllPagesSelected) {
      setSelectedPages({});
    } else {
      pages.forEach((page) => {
        setSelectedPages((prev) => ({ ...prev, [page.id]: true }));
      });
    }
  };

  useEffect(() => {
    // Select all pages by default when the component mounts or when pages change
    if (pageIDs && !initialized) {
      pageIDs.forEach((page) => {
        setSelectedPages((prev) => ({ ...prev, [page]: true }));
      });
      setInitialized(true);
    }
    if (pageIDs && initialized) {
      // Check if pageIDs have changed compared to currently selected pages
      const currentSelectedPageIds = Object.keys(selectedPages);
      const hasPageIdsChanged = pageIDs.length !== currentSelectedPageIds.length ||
        pageIDs.some(id => !currentSelectedPageIds.includes(id));

      if (hasPageIdsChanged) {
        setInitialized(false);
      }
    }
  }, [pageIDs, initialized, selectedPages]);

  const handlePreviewOnStaging = () => {
    const url = `${currentPublication?.url}preview/${site?.draft_site_version.id}?page=/`;
    window.open(url, '_blank');
  };

  const handlePublishClick = () => {
    if (!currentPublication?.uses_dream_builder_site) {
      setIsOpenPublishV2(true);
    } else {
      onPublish();
    }
  };

  const resetValues = () => {
    setIsPublished(false);
    setIsOpenPublishV2(false);
    setIsOpenRevertV1(false);
    setStep('publish_settings');
    setIsSettingsSelected(false);
    setIsPublishSelectedPages(false);
    setSelectedPages({});
  };

  return (
    <DropdownMenu onOpenChange={resetValues}>
      <DropdownMenuTrigger asChild>
        <Button className="p-2.5" RightIcon={CaretDown}>
          <Text size="xs" weight="medium" variant="on-accent">
            Publish
          </Text>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={2} align="end" className="flex flex-col gap-0 p-0 w-[312px]">
        {step === 'publish_settings' && (
          <>
            <PublishSettingsStep
              goToSelectPagesStep={() => setStep('select_pages')}
              isSettingsSelected={isSettingsSelected}
              setIsSettingsSelected={setIsSettingsSelected}
              selectedPages={selectedPages}
              isPublishSelectedPages={isPublishSelectedPages}
              setIsPublishSelectedPages={setIsPublishSelectedPages}
              onRevertToV1={() => setIsOpenRevertV1(true)}
            />
            <Section>
              <Button variant="secondary" onClick={handlePreviewOnStaging}>
                <Text size="xs" weight="medium" as="span">
                  Preview Draft Site
                </Text>
              </Button>
              <Tooltip
                center={isNothingToPublish ? 'Please select pages or site settings to publish' : ''}
                className="w-full"
              >
                <Button
                  isDisabled={isLoading || isNothingToPublish}
                  isLoading={isLoading}
                  onClick={isPublished ? () => { } : handlePublishClick}
                  className="w-full"
                >
                  <Text size="xs" weight="medium" variant="on-accent" as="span">
                    {isPublished ? 'Published' : 'Publish to Live Site'}
                  </Text>
                </Button>
              </Tooltip>
            </Section>
          </>
        )}
        {step === 'select_pages' && (
          <SelectPagesStep
            onBack={() => setStep('publish_settings')}
            selectedPages={selectedPages}
            onSelectPage={(id, checked) => setSelectedPages((prev) => ({ ...prev, [id]: checked }))}
            onSelectAllPages={onSelectAllPages}
            goToSearchPagesStep={() => setStep('search_pages')}
          />
        )}
        {step === 'search_pages' && (
          <SearchPagesStep
            onBack={() => setStep('select_pages')}
            selectedPages={selectedPages}
            onSelectPage={(id, checked) => {
              setSelectedPages((prev) => ({ ...prev, [id]: checked }));
            }}
            onSelectAllPages={onSelectAllPages}
          />
        )}
      </DropdownMenuContent>
      <DialogPublishV2 isOpen={isOpenPublishV2} setIsOpen={setIsOpenPublishV2} onPublish={onPublish} />
      <DialogRevertV1 isOpen={isOpenRevertV1} setIsOpen={setIsOpenRevertV1} />
    </DropdownMenu>
  );
};
