import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import moment, { Moment } from 'moment-mini';

import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import api from '@/services/swarm';

import useLocalStorage from '../../../../hooks/useLocalStorage';

import { ApiResponse } from './types';

const totalDays = 42; // 6 weeks

function generateDates({ year, month }: { year: number; month: number }) {
  let startDate = moment([year, month]).startOf('month');

  while (startDate.day() !== 0) {
    startDate = startDate.subtract(1, 'days');
  }

  return Array.from({ length: totalDays }, (_, i) => moment(startDate).add(i, 'days'));
}

interface UseCalendarProps {
  year: number;
  month: number;
  minSelectableDate?: Moment;
  maxSelectableDate?: Moment;
  selectedDate?: Moment;
  onSelectDate?: (date: Moment) => void;
  variant?: 'full' | 'compact';
  isDateDisabled?: (date: Moment) => boolean;
}

type Settings = {
  [key: string]: {
    [key: string]: boolean;
  };
};

/**
 * Custom hook to manage a calendar view and settings.
 *
 * @param {UseCalendarProps} - The initial year and month to display.
 * @returns {Object} - An object containing various properties and functions to manage the calendar:
 * - `days`: Array of days with events and current month status.
 * - `goToPrev`: Function to navigate to the previous month.
 * - `goToNext`: Function to navigate to the next month.
 * - `goToToday`: Function to navigate to the month of the current date.
 * - `currentMonth`: String representing the current month name.
 * - `currentYear`: String representing the current year.
 * - `selectedDay`: Object representing the currently selected day.
 * - `previousMonth`: String representing the previous month name.
 * - `previousYear`: String representing the previous year.
 * - `nextMonth`: String representing the next month name.
 * - `nextYear`: String representing the next year.
 * - `isLoading`: Boolean indicating if the calendar data is loading.
 * - `selectedDate`: The currently selected date.
 * - `setSelectedDate`: Function to set the selected date.
 * - `calendars`: Array of calendar objects.
 * - `settings`: Object representing the calendar settings.
 * - `toggleCategory`: Function to toggle the visibility of a category in a calendar.
 * - `toggleCalendar`: Function to toggle the visibility of all categories in a calendar.
 * - `upcomingEvents`: Array of upcoming events for the month based on the current view.
 */
function useCalendar({
  year: initialYear,
  month: initialMonth,
  minSelectableDate,
  maxSelectableDate,
  variant = 'full',
  isDateDisabled,
}: UseCalendarProps) {
  const [view, setView] = useState({ year: initialYear, month: initialMonth });
  const [settings, setSettings] = useLocalStorage<Settings>({}, 'calendar-settings');
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
  const { year, month } = view;

  const dates = useMemo(() => generateDates({ year, month }), [year, month]);

  const goToPrev = useCallback(() => {
    const prevMonth = month - 1;
    const prevYear = prevMonth < 0 ? year - 1 : year;

    setView({ year: prevYear, month: prevMonth < 0 ? 11 : prevMonth });
  }, [year, month]);

  const goToNext = useCallback(() => {
    const nextMonth = month + 1;
    const nextYear = nextMonth > 11 ? year + 1 : year;

    setView({ year: nextYear, month: nextMonth > 11 ? 0 : nextMonth });
  }, [year, month]);

  const goToToday = useCallback(() => {
    const today = moment();

    setView({ year: today.year(), month: today.month() });
  }, []);

  const publicationId = useCurrentPublicationId();

  const { data, isLoading } = useQuery<ApiResponse>(
    ['calendars', publicationId, dates[0].format(), dates[dates.length - 1].format()],
    () =>
      api
        .get(`/calendars`, {
          params: { start: dates[0].format() },
        })
        .then((res) => res.data),
    {
      keepPreviousData: true,
    }
  );

  const { calendars, events } = data || { calendars: [], events: [] };

  const isDateSelectable = useCallback(
    (date: Moment) =>
      (typeof isDateDisabled === 'undefined' || isDateDisabled(date) === false) &&
      (!minSelectableDate || date.isSameOrAfter(minSelectableDate, 'day')) &&
      (!maxSelectableDate || date.isSameOrBefore(maxSelectableDate, 'day')),
    [isDateDisabled, minSelectableDate, maxSelectableDate]
  );

  const currentMonth = moment([year, month]).format('MMMM');
  const currentYear = moment([year, month]).format('YYYY');
  const previousMonth = moment([year, month]).subtract(1, 'month').format('MMMM');
  const previousYear = moment([year, month]).subtract(1, 'month').format('YYYY');
  const nextMonth = moment([year, month]).add(1, 'month').format('MMMM');
  const nextYear = moment([year, month]).add(1, 'month').format('YYYY');
  const days = dates.map((date) => ({
    date,
    events: events
      ?.filter((event) => moment(event.time).isSame(date, 'day'))
      .filter((event) => settings?.[event?.calendar]?.[event?.category])
      .sort((a, b) => moment(a.time).diff(moment(b.time))),
    isCurrentMonth: date.month() === month,
    isSelectable: isDateSelectable(date),
  }));

  // clear selected date if the view changes
  useEffect(() => {
    setSelectedDate(null);
  }, [year, month]);

  // update settings when data first loads
  useEffect(() => {
    if (calendars.length) {
      setSettings((currentSettings) =>
        calendars.reduce((acc, calendar) => {
          const existingCategories = currentSettings?.[calendar.id] || {};

          const categories = calendar.categories.reduce((catAcc, category) => {
            return { ...catAcc, [category.id]: existingCategories[category.id] ?? true };
          }, {} as { [key: string]: boolean });

          acc[calendar.id] = categories;

          return acc;
        }, {} as Settings)
      );
    }
  }, [calendars, setSettings]);

  const toggleCategory = useCallback(
    (calendarId: string, categoryId: string) => {
      setSettings((prev) => {
        return {
          ...prev,
          [calendarId]: {
            ...prev[calendarId],
            [categoryId]: !prev[calendarId][categoryId],
          },
        };
      });
    },
    [setSettings]
  );

  const toggleCalendar = useCallback(
    (calendarId: string) => {
      setSettings((prev) => {
        const allCategoriesOn = Object.values(prev[calendarId]).every((value) => value);

        return {
          ...prev,
          [calendarId]: Object.keys(prev[calendarId]).reduce((acc, categoryId) => {
            acc[categoryId] = !allCategoriesOn;
            return acc;
          }, {} as { [key: string]: boolean }),
        };
      });
    },
    [setSettings]
  );

  const selectDate = useCallback(
    (date: Moment) => {
      setSelectedDate(isDateSelectable(date) ? date : null);
    },
    [isDateSelectable]
  );

  const selectedDay = selectedDate ? days.find((day) => day.date.isSame(selectedDate, 'day')) : null;
  const upcomingEvents = useMemo(() => {
    return events
      ?.filter((event) => moment(event.time).isAfter(moment()))
      .filter((event) => settings?.[event?.calendar]?.[event?.category])
      .sort((a, b) => moment(a.time).diff(moment(b.time)))
      .filter((event) => moment(event.time).isSame([year, month], 'month'));
  }, [events, settings, year, month]);

  return {
    days,
    goToPrev,
    goToNext,
    goToToday,
    currentMonth,
    currentYear,
    selectedDay,
    previousMonth,
    previousYear,
    nextMonth,
    nextYear,
    isLoading,
    selectDate,
    selectedDate,
    calendars,
    settings,
    toggleCategory,
    toggleCalendar,
    upcomingEvents,
    variant,
  };
}

export default useCalendar;
