import CurrencyInput from '@/components/Form/CurrencyInput';
import { Typography } from '@/components/Typography';
import { Option } from '@/interfaces/general';
import { Dropdown } from '@/ui/Dropdown';
import { MultiSelectDropdown } from '@/ui/MultiSelectDropdown';
import TextInput from '@/ui/TextInput';

import { FILTERS_POPOVER_OPERATOR_LABELS } from './FiltersPopover.constants';
import {
  FiltersPopopoverOperator,
  FiltersPopoverCurrencyRangeSection,
  FiltersPopoverMultiSelectSection,
  FiltersPopoverNumberInputSection,
  FiltersPopoverSection as FiltersPopoverSectionProps,
  FiltersPopoverSectionType,
  FiltersPopoverSelectSection,
  FiltersPopoverTimeframeUnits
} from './FiltersPopover.types';

const SectionTitle: React.FC<{ title: string }> = ({ title }) => (
  <Typography token="font-medium/text/sm">{title}</Typography>
);

const FiltersPopoverSection: React.FC<FiltersPopoverSectionProps> = (props) => {
  const { type } = props;

  if (type === FiltersPopoverSectionType.DIVIDER) {
    return <hr className="my-4 -mx-4 border-t border-surface-200" />;
  }

  const { title } = props;

  if (type === FiltersPopoverSectionType.HEADER) {
    return (
      <div className="mt-6">
        <hr className="mb-4 -mx-4 border-t border-surface-200" />
        <SectionTitle title={title || ''} />
      </div>
    );
  }

  const {
    name,
    labelText,
    placeholderText,
    badgeType,
    operatorValue,
    operators,
    onOperatorChange,
  } = props;

  const renderCurrencyRangeInput = ({ lowerBoundValue, upperBoundValue, lowerBoundPlaceholder, upperBoundPlaceholder, onLowerBoundChange, onUpperBoundChange }: FiltersPopoverCurrencyRangeSection) => (
    <div className="flex items-center space-x-2">
      <CurrencyInput
        name={`${name}-lower-bound`}
        value={lowerBoundValue}
        onChange={onLowerBoundChange}
        placeholder={lowerBoundPlaceholder || 'Enter number'}
      />
      <Typography token="font-normal/text/sm">-</Typography>
      <CurrencyInput
        name={`${name}-upper-bound`}
        value={upperBoundValue}
        onChange={onUpperBoundChange}
        placeholder={upperBoundPlaceholder || 'Enter number'}
      />
    </div>
  );

  const renderSelectInput = ({ options, value, onSelect }: FiltersPopoverSelectSection) => (
    <Dropdown
      name={name}
      placeholderText={placeholderText || 'Options'}
      options={options}
      value={value}
      shouldBindOptionAsValue
      onSelect={(_, newValue: Option) => {
        onSelect(newValue.value);
      }}
      className="min-w-0"
      optionsContainerClassNames={{ width: 'min-w-64 left-0' }}
    />
  );

  const renderNumberInput = ({ value, min, max, onChange, timeframeValue, timeframeUnits, onTimeframeChange }: FiltersPopoverNumberInputSection) => (
    <div className="flex items-center space-x-2">
      <div className="w-fit min-w-[116px]">
        <TextInput
          name={name}
          value={value?.toString() || ""}
          onChange={(e) => onChange(parseInt(e.target.value, 10))}
          placeholder={placeholderText || 'Enter number'}
          type="number"
          min={min}
          max={max}
        />
      </div>

      {timeframeUnits && (
        <Dropdown
          name={`${name}-timeframe`}
          value={timeframeValue}
          options={timeframeUnits.map((unit) => ({
            label: unit,
            value: unit,
          }))}
          onSelect={(_, newValue) => {
            onTimeframeChange?.(newValue as FiltersPopoverTimeframeUnits);
          }}
          buttonClassNames={{ background: 'bg-surface-50' }}
          optionsContainerClassNames={{ width: 'min-w-36 left-0' }}
        />
      )}
    </div>
  );

  const renderMultiSelectInput = ({ options, value, maxVisibleSelectedOptions = 1, onSelect, hasSearch }: FiltersPopoverMultiSelectSection) => (
    <MultiSelectDropdown
      name={name}
      placeholderText={placeholderText || 'Options'}
      staticOptions={options}
      selectedTagType={badgeType || 'info_blue'}
      values={options.filter((option) => value.includes(option.value))}
      shouldBindOptionAsValue
      onSelect={(_, newValue: Option[]) => {
        onSelect(newValue.map((option) => option.value));
      }}
      maxVisibleSelectedOptions={maxVisibleSelectedOptions}
      className="min-w-0"
      tooltipClass="max-w-[200px]"
      tooltipContainerClass="min-w-0"
      optionsContainerClassNames={{ width: 'min-w-64 left-0' }}
      optionsPosition="bottom-start"
      {...(hasSearch && {
        search: true,
        emptyLabel: "No options found",
        searchHandler: (query) => {
          return options.filter((option) =>
            option.label.toLowerCase().includes(query.toLowerCase())
          );
        }
      })}
    />
  );

  const renderInput = () => {
    switch (type) {
      case FiltersPopoverSectionType.CURRENCY_RANGE:
        return renderCurrencyRangeInput(props);
      case FiltersPopoverSectionType.MULTI_SELECT:
        return renderMultiSelectInput(props);
      case FiltersPopoverSectionType.NUMBER:
        return renderNumberInput(props);
      case FiltersPopoverSectionType.SELECT:
        return renderSelectInput(props);
      default:
        throw new Error(`Unsupported input type: ${type}`);
    }
  };

  return (
    <div className="space-y-2">
      {title && <SectionTitle title={title} />}
      <div className="flex justify-start items-center space-x-3">
        {operators && (
          <Dropdown
            name={`${name}-operator`}
            placeholderText="Options"
            value={operatorValue}
            options={operators.map((operator) => ({
              label: FILTERS_POPOVER_OPERATOR_LABELS[operator],
              value: operator,
            }))}
            onSelect={(_, newValue) =>
              onOperatorChange?.(newValue as FiltersPopopoverOperator)
            }
            buttonClassNames={{ background: 'bg-surface-50' }}
            optionsContainerClassNames={{ width: 'min-w-36 left-0' }}
          />
        )}

        {labelText && (
          <Typography token="font-normal/text/sm" className="flex-none">
            {labelText}
          </Typography>
        )}

        {renderInput()}
      </div>
    </div>
  );
};

export default FiltersPopoverSection;
