import cx from 'classnames';

import Text from '../../../components/Text';
import Tooltip from '../../../ui/Tooltip';

interface Props {
  className?: string;
  label: string;
  value: string | React.ReactNode;
  helperText?: string;
  tooltip?: string;
  id?: string;
}

const NotEditing = ({ id, label, value, helperText, tooltip, className }: Props) => {
  return (
    <div className={cx('space-y-0.5', className)}>
      <Text className="block text-sm font-semibold text-gray-700 flex items-center">
        {label}
        {tooltip && (
          <div className="ml-1">
            <Tooltip id={`${id}-switch-tooltip`} text={tooltip} />
          </div>
        )}
      </Text>
      {value ? (
        <p className="block text-sm font-medium text-gray-400 line-clamp-3">{value}</p>
      ) : (
        <p className="block text-sm font-regular italic text-gray-400">Empty</p>
      )}
      {helperText && <p className="pt-4 text-xs font-light text-gray-700 w-5/6 w-full">{helperText}</p>}
    </div>
  );
};

export default NotEditing;
