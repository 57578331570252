import { cx } from 'class-variance-authority';

import { Typography } from '@/components/Typography';

import { useCalendarContext } from '../utils/useCalendar';
import { Category } from '../utils/useCalendar/types';

interface CategoryLegendProps {
  category: Category;
  calendarId: string;
}

const CategoryLegend = ({ category, calendarId }: CategoryLegendProps) => {
  const { settings, toggleCategory } = useCalendarContext();

  const getVariantStyles = () => {
    switch (category.style) {
      case 'filled':
        return {
          container: 'p-1 pl-7 hover:bg-gray-100 rounded',
          box: 'relative h-4 w-4 rounded overflow-hidden bg-gray-200',
          indicator: 'absolute h-4 w-1 bg-gray-500',
        };
      case 'flush':
        return {
          container: 'p-1 pl-7 hover:bg-gray-100 rounded',
          box: 'relative h-4 w-4 rounded overflow-hidden',
          indicator: 'absolute h-4 w-1 rounded bg-gray-500',
        };
      case 'outline':
        return {
          container: 'p-1 pl-7 hover:bg-gray-100 rounded',
          box: 'relative h-4 w-4 rounded overflow-hidden border border-gray-300',
          indicator: '',
        };
      default:
        return {
          container: '',
          box: '',
          indicator: '',
        };
    }
  };

  const { container, box, indicator } = getVariantStyles();

  return (
    <label
      className={cx(
        'flex items-center justify-between cursor-pointer outline-none focus:ring ring-gray-300',
        container
      )}
      htmlFor={`category-${calendarId}-${category.id}`}
    >
      <div className="flex items-center space-x-2">
        <div className={cx(box)}>{indicator && <div className={cx(indicator)}>&nbsp;</div>}</div>
        <Typography as="h2" token="font-normal/text/sm" colorWeight="700">
          {category.name}
        </Typography>
      </div>
      <input
        type="checkbox"
        className="size-4 rounded border-gray-300 text-gray-600 outline-none focus:ring ring-gray-300 border-1"
        id={`category-${calendarId}-${category.id}`}
        onChange={() => toggleCategory(calendarId, category.id)}
        checked={settings?.[calendarId]?.[category.id]}
        aria-label={`Toggle ${category.name}`}
      />
    </label>
  );
};

export default CategoryLegend;
