import { NavbarSerializableNode } from '@shared/dream-components';
import { v4 } from 'uuid';

type Plugin = {
  apply(node: NavbarSerializableNode): NavbarSerializableNode;
};

const traverseNode = (
  node: NavbarSerializableNode,
  mutate: (node: NavbarSerializableNode) => NavbarSerializableNode
): NavbarSerializableNode => {
  if ('content' in node && Array.isArray(node.content)) {
    const updatedContent = node.content.map((child) => traverseNode(child, mutate));
    return { ...mutate(node), content: updatedContent as any };
  }

  return mutate(node);
};

const idPlugin: Plugin = {
  apply(node: NavbarSerializableNode): NavbarSerializableNode {
    const mutate = (n: NavbarSerializableNode) => {
      if (n.attrs?.id) {
        return n;
      }

      return {
        ...n,
        attrs: {
          ...(n.attrs || {}),
          id: n.attrs?.id || v4(),
        },
      } as NavbarSerializableNode;
    };

    const updatedNode = traverseNode(node, mutate);

    return updatedNode;
  },
};

export const plugins: Plugin[] = [idPlugin];
