import { memo } from 'react';

import { cn } from '@/utils/cn';

// TODO: Find a more generic way (https://create-react-app.dev/docs/adding-images-fonts-and-files#adding-svgs)
import { ReactComponent as AddColumnAfter } from '../../../assets/icons/AddColumnAfter.svg';
import { ReactComponent as AddColumnBefore } from '../../../assets/icons/AddColumnBefore.svg';
import { ReactComponent as AddRowAfter } from '../../../assets/icons/AddRowAfter.svg';
import { ReactComponent as AddRowBefore } from '../../../assets/icons/AddRowBefore.svg';
import { ReactComponent as AI } from '../../../assets/icons/AI.svg';
import { ReactComponent as AIAdjustTone } from '../../../assets/icons/AIAdjustTone.svg';
import { ReactComponent as AICompleteSentence } from '../../../assets/icons/AICompleteSentence.svg';
import { ReactComponent as AIExtend } from '../../../assets/icons/AIExtend.svg';
import { ReactComponent as AIImage } from '../../../assets/icons/AIImage.svg';
import { ReactComponent as AIImproveWriting } from '../../../assets/icons/AIImproveWriting.svg';
import { ReactComponent as AIRephrase } from '../../../assets/icons/AIRephrase.svg';
import { ReactComponent as AIShorten } from '../../../assets/icons/AIShorten.svg';
import { ReactComponent as AISimplify } from '../../../assets/icons/AISimplify.svg';
import { ReactComponent as AISpellingGrammar } from '../../../assets/icons/AISpellingGrammar.svg';
import { ReactComponent as AlignCaptionCenter } from '../../../assets/icons/AlignCaptionCenter.svg';
import { ReactComponent as AlignCaptionLeft } from '../../../assets/icons/AlignCaptionLeft.svg';
import { ReactComponent as AlignCaptionRight } from '../../../assets/icons/AlignCaptionRight.svg';
import { ReactComponent as AlignImageCenter } from '../../../assets/icons/AlignImageCenter.svg';
import { ReactComponent as AlignImageLeft } from '../../../assets/icons/AlignImageLeft.svg';
import { ReactComponent as AlignImageRight } from '../../../assets/icons/AlignImageRight.svg';
import { ReactComponent as Anchor } from '../../../assets/icons/Anchor.svg';
import { ReactComponent as ArrowSmallRight } from '../../../assets/icons/ArrowSmallRight.svg';
import { ReactComponent as AspectRatio } from '../../../assets/icons/AspectRatio.svg';
import { ReactComponent as Attachment } from '../../../assets/icons/Attachment.svg';
import { ReactComponent as Audio } from '../../../assets/icons/Audio.svg';
import { ReactComponent as Backlink } from '../../../assets/icons/Backlink.svg';
import { ReactComponent as Banknotes } from '../../../assets/icons/Banknotes.svg';
import { ReactComponent as Blockquote } from '../../../assets/icons/Blockquote.svg';
import { ReactComponent as Bluesky } from '../../../assets/icons/Bluesky.svg';
import { ReactComponent as Bold } from '../../../assets/icons/Bold.svg';
import { ReactComponent as Boost } from '../../../assets/icons/Boost.svg';
import { ReactComponent as Border } from '../../../assets/icons/Border.svg';
import { ReactComponent as BorderQuote } from '../../../assets/icons/BorderQuote.svg';
import { ReactComponent as BorderRadius } from '../../../assets/icons/BorderRadius.svg';
import { ReactComponent as BorderRadiusBottomLeft } from '../../../assets/icons/BorderRadiusBottomLeft.svg';
import { ReactComponent as BorderRadiusBottomRight } from '../../../assets/icons/BorderRadiusBottomRight.svg';
import { ReactComponent as BorderRadiusTopLeft } from '../../../assets/icons/BorderRadiusTopLeft.svg';
import { ReactComponent as BorderRadiusTopRight } from '../../../assets/icons/BorderRadiusTopRight.svg';
import { ReactComponent as BorderWidth } from '../../../assets/icons/BorderWidth.svg';
import { ReactComponent as BorderWidthBottom } from '../../../assets/icons/BorderWidthBottom.svg';
import { ReactComponent as BorderWidthLeft } from '../../../assets/icons/BorderWidthLeft.svg';
import { ReactComponent as BorderWidthRight } from '../../../assets/icons/BorderWidthRight.svg';
import { ReactComponent as BorderWidthTop } from '../../../assets/icons/BorderWidthTop.svg';
import { ReactComponent as Browse } from '../../../assets/icons/Browse.svg';
import { ReactComponent as BulletList } from '../../../assets/icons/BulletList.svg';
import { ReactComponent as Button } from '../../../assets/icons/Button.svg';
import { ReactComponent as Calendar } from '../../../assets/icons/Calendar.svg';
import { ReactComponent as Cancel } from '../../../assets/icons/Cancel.svg';
import { ReactComponent as Check } from '../../../assets/icons/Check.svg';
import { ReactComponent as ChevronDown } from '../../../assets/icons/ChevronDown.svg';
import { ReactComponent as ChevronLeft } from '../../../assets/icons/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from '../../../assets/icons/ChevronRight.svg';
import { ReactComponent as ClearFormat } from '../../../assets/icons/ClearFormat.svg';
import { ReactComponent as Close } from '../../../assets/icons/Close.svg';
import { ReactComponent as Code } from '../../../assets/icons/Code.svg';
import { ReactComponent as CodeBlock } from '../../../assets/icons/CodeBlock.svg';
import { ReactComponent as ColorBucket } from '../../../assets/icons/ColorBucket.svg';
import { ReactComponent as Comment } from '../../../assets/icons/Comment.svg';
import { ReactComponent as Copy } from '../../../assets/icons/Copy.svg';
import { ReactComponent as Crop } from '../../../assets/icons/Crop.svg';
import { ReactComponent as CTA } from '../../../assets/icons/CTA.svg';
import { ReactComponent as DotsVertical } from '../../../assets/icons/DotsVertical.svg';
import { ReactComponent as Download } from '../../../assets/icons/Download.svg';
import { ReactComponent as DragMenu } from '../../../assets/icons/DragMenu.svg';
import { ReactComponent as Dropquote } from '../../../assets/icons/Dropquote.svg';
import { ReactComponent as Duplicate } from '../../../assets/icons/Duplicate.svg';
import { ReactComponent as Edit } from '../../../assets/icons/Edit.svg';
import { ReactComponent as Emoji } from '../../../assets/icons/Emoji.svg';
import { ReactComponent as External } from '../../../assets/icons/External.svg';
import { ReactComponent as FileAttachment } from '../../../assets/icons/FileAttachment.svg';
import { ReactComponent as FileUpload } from '../../../assets/icons/FileUpload.svg';
import { ReactComponent as Fill } from '../../../assets/icons/Fill.svg';
import { ReactComponent as FlipHorizontal } from '../../../assets/icons/FlipHorizontal.svg';
import { ReactComponent as FlipVertical } from '../../../assets/icons/FlipVertical.svg';
import { ReactComponent as FloppyDisk } from '../../../assets/icons/FloppyDisk.svg';
import { ReactComponent as Folder } from '../../../assets/icons/Folder.svg';
import { ReactComponent as FourCol } from '../../../assets/icons/FourCol.svg';
import { ReactComponent as FullWidth } from '../../../assets/icons/FullWidth.svg';
import { ReactComponent as Getty } from '../../../assets/icons/Getty.svg';
import { ReactComponent as Giphy } from '../../../assets/icons/Giphy.svg';
import { ReactComponent as Globe } from '../../../assets/icons/Globe.svg';
import { ReactComponent as Group } from '../../../assets/icons/Group.svg';
import { ReactComponent as GroupSection } from '../../../assets/icons/GroupSection.svg';
import { ReactComponent as HardBreak } from '../../../assets/icons/HardBreak.svg';
import { ReactComponent as Heading } from '../../../assets/icons/Heading.svg';
import { ReactComponent as Heading1 } from '../../../assets/icons/Heading1.svg';
import { ReactComponent as Heading2 } from '../../../assets/icons/Heading2.svg';
import { ReactComponent as Heading3 } from '../../../assets/icons/Heading3.svg';
import { ReactComponent as Heading4 } from '../../../assets/icons/Heading4.svg';
import { ReactComponent as Heading5 } from '../../../assets/icons/Heading5.svg';
import { ReactComponent as Heading6 } from '../../../assets/icons/Heading6.svg';
import { ReactComponent as Help } from '../../../assets/icons/Help.svg';
import { ReactComponent as Hide } from '../../../assets/icons/Hide.svg';
import { ReactComponent as Highlight } from '../../../assets/icons/Highlight.svg';
import { ReactComponent as History } from '../../../assets/icons/History.svg';
import { ReactComponent as HorizontalRule } from '../../../assets/icons/HorizontalRule.svg';
import { ReactComponent as HtmlSnippet } from '../../../assets/icons/HtmlSnippet.svg';
import { ReactComponent as Image } from '../../../assets/icons/Image.svg';
import { ReactComponent as ImageLeftTextRight } from '../../../assets/icons/ImageLeftTextRight.svg';
import { ReactComponent as ImageTopTextBottom } from '../../../assets/icons/ImageTopTextBottom.svg';
import { ReactComponent as Info } from '../../../assets/icons/Info.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/Instagram.svg';
import { ReactComponent as EqualTo } from '../../../assets/icons/IsEqual.svg';
import { ReactComponent as GreaterThan } from '../../../assets/icons/IsLarger.svg';
import { ReactComponent as LowerThan } from '../../../assets/icons/IsSmaller.svg';
import { ReactComponent as Italic } from '../../../assets/icons/Italic.svg';
import { ReactComponent as Library } from '../../../assets/icons/Library.svg';
import { ReactComponent as Link } from '../../../assets/icons/Link.svg';
import { ReactComponent as Lock } from '../../../assets/icons/Lock.svg';
import { ReactComponent as Mail } from '../../../assets/icons/Mail.svg';
import { ReactComponent as Margin } from '../../../assets/icons/Margin.svg';
import { ReactComponent as MarginBottom } from '../../../assets/icons/MarginBottom.svg';
import { ReactComponent as MarginLeft } from '../../../assets/icons/MarginLeft.svg';
import { ReactComponent as MarginRight } from '../../../assets/icons/MarginRight.svg';
import { ReactComponent as MarginTop } from '../../../assets/icons/MarginTop.svg';
import { ReactComponent as Mention } from '../../../assets/icons/Mention.svg';
import { ReactComponent as NonSubscriber } from '../../../assets/icons/NonSubscriber.svg';
import { ReactComponent as OrderedList } from '../../../assets/icons/OrderedList.svg';
import { ReactComponent as Padding } from '../../../assets/icons/Padding.svg';
import { ReactComponent as PaddingBottom } from '../../../assets/icons/PaddingBottom.svg';
import { ReactComponent as PaddingLeft } from '../../../assets/icons/PaddingLeft.svg';
import { ReactComponent as PaddingRight } from '../../../assets/icons/PaddingRight.svg';
import { ReactComponent as PaddingTop } from '../../../assets/icons/PaddingTop.svg';
import { ReactComponent as PaidSubscriber } from '../../../assets/icons/PaidUser.svg';
import { ReactComponent as Paragraph } from '../../../assets/icons/Paragraph.svg';
import { ReactComponent as PaywallBreak } from '../../../assets/icons/PaywallBreak.svg';
import { ReactComponent as Person } from '../../../assets/icons/Person.svg';
import { ReactComponent as Plus } from '../../../assets/icons/Plus.svg';
import { ReactComponent as Poll } from '../../../assets/icons/Poll.svg';
import { ReactComponent as Recommendation } from '../../../assets/icons/Recommendation.svg';
import { ReactComponent as Redo } from '../../../assets/icons/Redo.svg';
import { ReactComponent as ReferralCount } from '../../../assets/icons/ReferralCount.svg';
import { ReactComponent as ReferralProgram } from '../../../assets/icons/ReferralProgram.svg';
import { ReactComponent as Refresh } from '../../../assets/icons/Refresh.svg';
import { ReactComponent as Reset } from '../../../assets/icons/Reset.svg';
import { ReactComponent as Resize } from '../../../assets/icons/Resize.svg';
import { ReactComponent as Return } from '../../../assets/icons/Return.svg';
import { ReactComponent as RSS } from '../../../assets/icons/RSS.svg';
import { ReactComponent as Search } from '../../../assets/icons/Search.svg';
import { ReactComponent as Settings } from '../../../assets/icons/Settings.svg';
import { ReactComponent as SettingsHorizontal } from '../../../assets/icons/SettingsHorizontal.svg';
import { ReactComponent as Show } from '../../../assets/icons/Show.svg';
import { ReactComponent as SizeLarge } from '../../../assets/icons/SizeLarge.svg';
import { ReactComponent as SizeNormal } from '../../../assets/icons/SizeNormal.svg';
import { ReactComponent as SizeSmall } from '../../../assets/icons/SizeSmall.svg';
import { ReactComponent as Sort } from '../../../assets/icons/Sort.svg';
import { ReactComponent as Spacing } from '../../../assets/icons/Spacing.svg';
import { ReactComponent as Strike } from '../../../assets/icons/Strike.svg';
import { ReactComponent as FreeSubscriber } from '../../../assets/icons/SubscribedUser.svg';
import { ReactComponent as SubscriberBreak } from '../../../assets/icons/SubscriberBreak.svg';
import { ReactComponent as Subscript } from '../../../assets/icons/Subscript.svg';
import { ReactComponent as Subtitle } from '../../../assets/icons/Subtitle.svg';
import { ReactComponent as Superscript } from '../../../assets/icons/Superscript.svg';
import { ReactComponent as Table } from '../../../assets/icons/Table.svg';
import { ReactComponent as TableColumnHeader } from '../../../assets/icons/TableColumnHeader.svg';
import { ReactComponent as TableOfContents } from '../../../assets/icons/TableOfContents.svg';
import { ReactComponent as TableRowHeader } from '../../../assets/icons/TableRowHeader.svg';
import { ReactComponent as Tag } from '../../../assets/icons/Tag.svg';
import { ReactComponent as Template } from '../../../assets/icons/Template.svg';
import { ReactComponent as TextAlignCenter } from '../../../assets/icons/TextAlignCenter.svg';
import { ReactComponent as TextAlignJustify } from '../../../assets/icons/TextAlignJustify.svg';
import { ReactComponent as TextAlignLeft } from '../../../assets/icons/TextAlignLeft.svg';
import { ReactComponent as TextAlignRight } from '../../../assets/icons/TextAlignRight.svg';
import { ReactComponent as TextLeftImageRight } from '../../../assets/icons/TextLeftImageRight.svg';
import { ReactComponent as TextOnly } from '../../../assets/icons/TextOnly.svg';
import { ReactComponent as ThreeCol } from '../../../assets/icons/ThreeCol.svg';
import { ReactComponent as Tiktok } from '../../../assets/icons/Tiktok.svg';
import { ReactComponent as TitleAboveImage } from '../../../assets/icons/TitleAboveImage.svg';
import { ReactComponent as Translation } from '../../../assets/icons/Translation.svg';
import { ReactComponent as Trash } from '../../../assets/icons/Trash.svg';
import { ReactComponent as Twitter } from '../../../assets/icons/Twitter.svg';
import { ReactComponent as TwoCol } from '../../../assets/icons/TwoCol.svg';
import { ReactComponent as TwoColOneThirdTwoThird } from '../../../assets/icons/TwoColOneThirdTwoThird.svg';
import { ReactComponent as TwoColTwoThirdOneThird } from '../../../assets/icons/TwoColTwoThirdOneThird.svg';
import { ReactComponent as Underline } from '../../../assets/icons/Underline.svg';
import { ReactComponent as Undo } from '../../../assets/icons/Undo.svg';
import { ReactComponent as Ungroup } from '../../../assets/icons/Ungroup.svg';
import { ReactComponent as Union } from '../../../assets/icons/Union.svg';
import { ReactComponent as UnorderedDot } from '../../../assets/icons/UnorderedDot.svg';
import { ReactComponent as Unsplash } from '../../../assets/icons/Unsplash.svg';
import { ReactComponent as Upgrade } from '../../../assets/icons/Upgrade.svg';
import { ReactComponent as Upload } from '../../../assets/icons/Upload.svg';
import { ReactComponent as Volume } from '../../../assets/icons/Volume.svg';
import { ReactComponent as Website } from '../../../assets/icons/Website.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/Youtube.svg';

import { Styled } from './styled';
import { IconProps } from './types';

const Icons = {
  AI,
  AIAdjustTone,
  AICompleteSentence,
  AIExtend,
  AIImage,
  AIImproveWriting,
  AIRephrase,
  AIShorten,
  AISimplify,
  AISpellingGrammar,
  AddColumnAfter,
  AddColumnBefore,
  AddRowAfter,
  AddRowBefore,
  AlignCaptionCenter,
  AlignCaptionLeft,
  AlignCaptionRight,
  AlignImageCenter,
  AlignImageLeft,
  AlignImageRight,
  Anchor,
  ArrowSmallRight,
  AspectRatio,
  Attachment,
  Audio,
  Backlink,
  Banknotes,
  Blockquote,
  Bluesky,
  Bold,
  Boost,
  Border,
  BorderQuote,
  BorderRadius,
  BorderRadiusBottomLeft,
  BorderRadiusBottomRight,
  BorderRadiusTopLeft,
  BorderRadiusTopRight,
  BorderWidth,
  BorderWidthBottom,
  BorderWidthLeft,
  BorderWidthRight,
  BorderWidthTop,
  Browse,
  BulletList,
  Button,
  CTA,
  Calendar,
  Cancel,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ClearFormat,
  Close,
  Code,
  CodeBlock,
  ColorBucket,
  Comment,
  Copy,
  Crop,
  DotsVertical,
  Download,
  DragMenu,
  Dropquote,
  Duplicate,
  Edit,
  Emoji,
  EqualTo,
  External,
  FileAttachment,
  FileUpload,
  Fill,
  FlipHorizontal,
  FlipVertical,
  FloppyDisk,
  Folder,
  FourCol,
  FreeSubscriber,
  FullWidth,
  Getty,
  Giphy,
  Globe,
  GreaterThan,
  Group,
  GroupSection,
  HardBreak,
  Heading,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Help,
  Hide,
  Highlight,
  History,
  HorizontalRule,
  HtmlSnippet,
  Image,
  ImageLeftTextRight,
  ImageTopTextBottom,
  Info,
  Instagram,
  Italic,
  Library,
  Link,
  Lock,
  LowerThan,
  Mail,
  Margin,
  MarginBottom,
  MarginLeft,
  MarginRight,
  MarginTop,
  Mention,
  NonSubscriber,
  OrderedList,
  Padding,
  PaddingBottom,
  PaddingLeft,
  PaddingRight,
  PaddingTop,
  PaidSubscriber,
  Paragraph,
  PaywallBreak,
  Person,
  Plus,
  Poll,
  RSS,
  Recommendation,
  Redo,
  ReferralCount,
  ReferralProgram,
  Refresh,
  Reset,
  Resize,
  Return,
  Search,
  Settings,
  SettingsHorizontal,
  Show,
  SizeLarge,
  SizeNormal,
  SizeSmall,
  Sort,
  Spacing,
  Strike,
  SubscriberBreak,
  Subscript,
  Subtitle,
  Superscript,
  Table,
  TableColumnHeader,
  TableOfContents,
  TableRowHeader,
  Tag,
  Template,
  TextAlignCenter,
  TextAlignJustify,
  TextAlignLeft,
  TextAlignRight,
  TextLeftImageRight,
  TextOnly,
  ThreeCol,
  Tiktok,
  TitleAboveImage,
  Translation,
  Trash,
  Twitter,
  TwoCol,
  TwoColOneThirdTwoThird,
  TwoColTwoThirdOneThird,
  Underline,
  Undo,
  Ungroup,
  Union,
  UnorderedDot,
  Unsplash,
  Upgrade,
  Upload,
  Volume,
  Website,
  Youtube,
};

export const Icon = memo(({ name, $size = undefined, $display = undefined, className, ...rest }: IconProps) => {
  const IconComponent = Icons[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <Styled.Wrapper className={className} $size={$size} $display={$display}>
      <IconComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </Styled.Wrapper>
  );
});

Icon.displayName = 'Icon';

export const TwIcon = memo(({ name, $size = undefined, $display = undefined, className, ...rest }: IconProps) => {
  const IconComponent = Icons[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <span
      className={cn(className, 'inline-block w-[1rem] h-[1rem]')}
      style={{
        ...($display ? { display: $display } : {}),
        ...($size ? { height: $size, width: $size } : {}),
      }}
    >
      <IconComponent className={cn('block w-full h-full')} {...rest} />
    </span>
  );
});

export default Icon;
