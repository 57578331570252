import { useCallback } from 'react';
import { ArrowsHorizontal, ArrowsVertical, Icon } from '@phosphor-icons/react';

import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { AttributeSettingProps } from '../types';

export const getDefaultAlignment = () => {
  return 'left';
};

type DirectionSettingsProps = AttributeSettingProps & {
  property?: string;
  title?: string;
  icons?: {
    direction_horizontal: Icon;
    direction_vertical: Icon;
  };
  values?: string[];
};

export const DirectionSettings = ({
  editor,
  activeNodeResult,
  property = 'mobile_direction',
  title = 'Direction',
  icons = {
    direction_horizontal: ArrowsHorizontal,
    direction_vertical: ArrowsVertical,
  },
  values = ['direction_horizontal', 'direction_vertical'],
}: DirectionSettingsProps) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;

  const updateAlignment = useCallback(
    (value: string) => {
      if (activeNodePos === undefined || !activeNodeType || !value) return;

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, value);
        return true;
      });
    },
    [activeNodePos, activeNodeType, property, editor]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue={values[0]}
          value={activeNodeAttributes?.[property]}
          onValueChange={updateAlignment}
        >
          <ToggleGroupItem value={values[0]} className="grow">
            <icons.direction_horizontal weight="bold" format="outline" className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem value={values[1]} className="grow">
            <icons.direction_vertical weight="bold" format="outline" className="h-4 w-4" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
