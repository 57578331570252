import { useEffect, useMemo, useState } from 'react';
import { CheckCircleIcon, LinkIcon } from '@heroicons/react/24/solid';
import Entri from 'entrijs';

import ActionModal from '@/components/ActionModal';
import { Typography } from '@/components/Typography';
import { useCopyToClipboard, useCustomDomains } from '@/hooks';
import { useCreateEntriModalRecords, useCustomDomainConfigurationVerification } from '@/hooks/useCustomDomains';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';
import TabNavigation from '@/ui/TabNavigation';

import { usePublicationSettings } from '../../../context';
import getRootDomain from '../../getRootDomain';
import { customDomainRecords, getDomainForType, isDomainVerified, isEntriPending } from '../../utils';
import { DnsRecordsModalStep } from '../constants';

import BrandedLink from './BrandedLink';
import Email from './Email';
import Redirect from './Redirect';
import Web from './Web';

interface Props {
  stepType: CustomDomainTypes;
  isOpen: boolean;
  onClose: () => void;
}

const DnsRecordsModal = ({ stepType, isOpen, onClose }: Props) => {
  const { currentPublicationId, settings } = usePublicationSettings();
  const customDomains = useCustomDomains(currentPublicationId);
  const { data: domains } = customDomains;

  const copy = useCopyToClipboard();
  const { mutate: verifyDomain, isLoading: isLoadingDomain } =
    useCustomDomainConfigurationVerification({ publicationId: currentPublicationId });
  const { mutate: createEntriModalRecords, isLoading: createEntriModalRecordsIsLoading } =
    useCreateEntriModalRecords({ publicationId: currentPublicationId, includeBrandedLink: true });

  const showEntriModal = (sendgridComplete?: boolean) => {
    try {
      createEntriModalRecords({ brandedLinkForCloudflare: sendgridComplete }, {
        onSuccess: (data) => {
          onClose();
          Entri.showEntri(data);
        },
      });
      // eslint-disable-next-line no-empty
    } catch (error) { }
  };

  const steps: DnsRecordsModalStep[] = useMemo(() => {
    const emailDomain = domains?.find((domain) => domain.email_enabled || domain.email_verification_pending);
    const brandedLinkDomain = domains?.find((domain) => domain.email_enabled && domain.branded_link_added);
    const webDomain = domains?.find((domain) => domain.web_enabled || domain.web_verification_pending);
    const redirectDomain = domains?.find(
      (domain) => domain.web_redirect_enabled || domain.web_redirect_verification_pending
    );

    return [
      {
        type: CustomDomainTypes.WEB,
        label: 'Web',
        customDomain: webDomain,
        verified: isDomainVerified(webDomain, CustomDomainTypes.WEB),
        visible: Boolean(webDomain),
      },
      {
        type: CustomDomainTypes.REDIRECT,
        label: 'Redirect',
        customDomain: redirectDomain,
        verified: isDomainVerified(redirectDomain, CustomDomainTypes.REDIRECT),
        visible: Boolean(redirectDomain),
      },
      {
        type: CustomDomainTypes.EMAIL,
        label: 'Email',
        customDomain: emailDomain,
        verified: isDomainVerified(emailDomain, CustomDomainTypes.EMAIL),
        visible: Boolean(emailDomain),
      },
      {
        type: CustomDomainTypes.BRANDED_LINK,
        label: 'Branded Link',
        customDomain: brandedLinkDomain,
        verified: isDomainVerified(brandedLinkDomain, CustomDomainTypes.BRANDED_LINK),
        visible: Boolean(brandedLinkDomain),
      },
    ].filter((s) => !(s.type === CustomDomainTypes.BRANDED_LINK && !settings?.custom_branded_links));
  }, [domains, settings?.custom_branded_links]);

  const [currentStep, setCurrentStep] = useState(
    steps.find((s) => s.type === stepType) || steps.find((s) => s.visible) || steps[0]
  );
  const [currentDomain, setCurrentDomain] = useState<CustomDomain | null>(getDomainForType(currentStep.type, steps));

  const {
    branded_link_pending: brandedLinkPending,
    branded_link_verified: brandedLinkVerified,
    branded_link_cloudflare_enabled: brandedLinkCloudflareEnabled,
    entri_configuration: entriConfiguration
  } = currentDomain || {};
  const {
    email_enabled: setupWithEntri,
  } = entriConfiguration || {};
  const currentDomainVerified = isDomainVerified(currentDomain, currentStep.type);
  const entriPending = isEntriPending(currentDomain, currentStep.type);
  const requiresSetup = currentStep.type === CustomDomainTypes.BRANDED_LINK && !brandedLinkVerified;
  const requiresCompletion = currentStep.type === CustomDomainTypes.BRANDED_LINK && (brandedLinkPending || !brandedLinkCloudflareEnabled);

  let verifyActionText = 'Verify Setup';
  if (setupWithEntri) {
    if (entriPending) {
      verifyActionText = 'Entri Verification in Progress…';
    } else if (requiresSetup) {
      verifyActionText = 'Start Setup with Entri';
    } else if (requiresCompletion) {
      verifyActionText = 'Complete Setup with Entri';
    }
  }

  const handleProceed = () => {
    if (!currentDomain) {
      return;
    }

    if (currentDomainVerified) {
      const records = customDomainRecords(currentDomain, currentStep.type);

      copy({ text: records.map((r) => `${r.type} ${r.name} ${r.value}`).join('\n') });
      onClose();
    } else if (setupWithEntri && requiresCompletion) {
      showEntriModal(brandedLinkVerified);
    } else {
      verifyDomain({ customDomainId: currentDomain.id, type: currentStep.type });
    }
  };

  useEffect(() => {
    setCurrentStep(steps.find((s) => s.type === stepType) || steps[0]);
    setCurrentDomain(getDomainForType(stepType, steps));
  }, [stepType, steps, domains]);

  if (!currentDomain) {
    return null;
  }

  return (
    <ActionModal
      isOpen={isOpen}
      isWorking={isLoadingDomain || createEntriModalRecordsIsLoading}
      disabled={!currentDomainVerified && entriPending}
      onClose={onClose}
      onProceed={handleProceed}
      resourceId=""
      actionText={currentDomainVerified ? 'Copy to Clipboard & Close' : verifyActionText}
      actionIcon={currentDomainVerified ? LinkIcon : undefined}
      modalSize="md"
    >
      <div className="mb-4">
        <TabNavigation
          variant="tertiary"
          tabs={steps.map((step) => {
            return {
              name: step.type,
              label: step.label,
              selected: step.type === currentStep.type,
              Icon: step.verified ? CheckCircleIcon : undefined,
              visible: step.visible,
              onSelect: () => {
                setCurrentStep(step);
                setCurrentDomain(getDomainForType(step.type, steps));
              },
            };
          })}
        />
      </div>

      <Typography token="font-semibold/text/lg" colorWeight="900" as="h4" className="mb-4">
        {getRootDomain(currentDomain.domain)}
      </Typography>

      {currentStep.type === CustomDomainTypes.WEB && <Web customDomain={currentDomain} />}
      {currentStep.type === CustomDomainTypes.REDIRECT && <Redirect customDomain={currentDomain} />}
      {currentStep.type === CustomDomainTypes.EMAIL && <Email customDomain={currentDomain} />}
      {currentStep.type === CustomDomainTypes.BRANDED_LINK && <BrandedLink customDomain={currentDomain} />}
    </ActionModal>
  );
};

export default DnsRecordsModal;
