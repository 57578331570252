import { BoostOfferSort } from '@/hooks/boosts/monetize/useBoostOffers';
import { Option } from '@/interfaces/general';

export const BOOST_OFFER_SORT_OPTIONS: Option<BoostOfferSort>[] = [
  { label: 'Featured', value: BoostOfferSort.FEATURED },
  { label: 'Newest first', value: BoostOfferSort.NEWEST },
  { label: 'Oldest first', value: BoostOfferSort.OLDEST },
  { label: 'Highest Web Payout first', value: BoostOfferSort.HIGHEST_PAYOUT },
  { label: 'Highest Email Payout first', value: BoostOfferSort.HIGHEST_SEND_PAYOUT },
];

export const PUBLICATION_SIZE_OPTIONS: Option[] = [
  { label: 'New <5K', value: '1' },
  { label: 'Small 5-25K', value: '2' },
  { label: 'Medium 25-100K', value: '3' },
  { label: 'Large 100-250K', value: '4' },
  { label: 'Very Large >250K', value: '5' },
];
