import { Sparkle } from '@phosphor-icons/react';

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '../UI/DropdownMenu';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

const AutoSwapComingSoon = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          type="button"
          className="group flex items-center gap-1.5 cursor-pointer hover:text-wb-primary text-wb-secondary"
        >
          <Tooltip center="Auto Swap Placeholder Data -- Coming Soon" delay={300}>
            <div className="flex items-center gap-1.5">
              <div className="flex items-center justify-center w-5 h-5 bg-wb-highlight rounded-md">
                <Sparkle size={16} weight="bold" />
              </div>

              <Text variant="secondary" size="2xs" weight="semibold" className="text-inherit">
                Auto Swap
              </Text>
            </div>
          </Tooltip>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={10} align="start" className="flex flex-col gap-2 p-4 w-[500px]">
        <Text size="sm" weight="semibold">
          Swapping Placeholder Data
        </Text>

        <Text size="sm" weight="regular" variant="secondary">
          We are actively working on a feature to help auto swap all placeholder data across your website for your
          publication&#39;s real data. Until then, we have made it simple to swap data across each page.
        </Text>

        <div className="relative w-full pt-[56.25%]">
          <iframe
            className="absolute top-0 left-0 w-full h-full rounded-lg"
            src="https://www.youtube.com/embed/QHacudiLoUY"
            title="Auto Swap Tutorial"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AutoSwapComingSoon;
