import { FiltersPopoverTimeframeUnits } from '@/ui/FiltersPopover/FiltersPopover.types';

function convertToDays(timeframe: FiltersPopoverTimeframeUnits, value?: number): number | undefined {
  if (!value) return undefined;

  switch (timeframe) {
    case FiltersPopoverTimeframeUnits.DAYS:
      return value;
    case FiltersPopoverTimeframeUnits.WEEKS:
      return value * 7;
    case FiltersPopoverTimeframeUnits.MONTHS:
      return value * 30;
    default:
      return value;
  }
}

export default convertToDays;
