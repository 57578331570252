import { useCallback, useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Card } from '@tremor/react';
import { cx } from 'class-variance-authority';
import { useDebounce } from 'use-debounce';

import { Input, TypeaheadMultiSelect } from '@/components/Form';
import { useRoles, useUsers } from '@/hooks/useAdNetwork/internal';
import { AdNetworkAdvertiser } from '@/interfaces/ad_network/internal/advertiser';
import { AdNetworkRole, AdNetworkRoleLabels } from '@/interfaces/ad_network/internal/role';
import { AdNetworkUser } from '@/interfaces/ad_network/internal/user';
import { Option } from '@/interfaces/general';
import Multiselect from '@/ui/Multiselect';
import Switch from '@/ui/Switch';

interface ContactFieldsProps {
  title: string;
  advertiser?: AdNetworkAdvertiser;
  contact?: AdNetworkUser;
  onChange: (data: AdNetworkUser) => void;
  onDelete: (id: string) => void;
  canDelete?: boolean;
}

const ContactFields = ({ title, advertiser, contact, onChange, onDelete, canDelete }: ContactFieldsProps) => {
  const { id } = contact || {};
  const [firstName, setFirstName] = useState<string>(contact?.first_name || '');
  const [lastName, setLastName] = useState<string>(contact?.last_name || '');
  const [email, setEmail] = useState<string>(contact?.email || '');
  const [isNewUser, setIsNewUser] = useState<boolean>(false);

  const [debouncedFirstName] = useDebounce(firstName, 800);
  const [debouncedLastName] = useDebounce(lastName, 800);
  const [debouncedEmail] = useDebounce(email, 800);

  const [selectedRoles, setSelectedRoles] = useState<AdNetworkRole[]>(contact?.roles || []);
  const { data: rolesData } = useRoles();
  const roles = rolesData?.roles ?? [];

  const rolesMultiSelectOptions = roles.map((role) => ({
    value: role.id,
    label: AdNetworkRoleLabels[role.name] || role.name,
  }));
  const [usersSearchQuery, setUsersSearchQuery] = useState(contact?.email || '');
  const { refetch: refetchUsers, data: usersData } = useUsers({
    query: usersSearchQuery,
    orderBy: 'email',
    dir: 'asc',
  });
  const users = usersData?.pages.flatMap((page) => page.users) || [];
  const [selectedUser, setSelectedUser] = useState<AdNetworkUser | undefined>(contact);
  const [storedUser, setStoredUser] = useState<AdNetworkUser | undefined>();

  const handleUsersSearchQueryChange = (query: string) => {
    setUsersSearchQuery(query);
  };

  const handleUsersSearch = useCallback(
    async (): Promise<Option[]> => {
      try {
        const response = await refetchUsers();
        return (
          response?.data?.pages
            .flatMap((page) => page.users)
            .map((user: AdNetworkUser) => ({ label: user.email, value: user.id })) || []
        );
      } catch (error) {
        // do something
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUsersSearchQuery]
  );

  useEffect(() => {
    if (contact?.email) {
      handleUsersSearchQueryChange(contact?.email);
    }
  }, [contact]);

  const handleSelectUser = (name: string, value: string) => {
    const user = users.find((u) => u.id === value);
    const { first_name: userFirstName, last_name: userLastName, email: userEmail } = user || {};
    setSelectedUser(user);
    setFirstName(userFirstName || '');
    setLastName(userLastName || '');
    setEmail(userEmail || '');
  };

  const handleDeselectUser = () => {
    setSelectedUser(undefined);
    setFirstName('');
    setLastName('');
    setEmail('');
    setUsersSearchQuery('');
  };

  const handleNewUserToggle = (name: string, checked: boolean) => {
    setIsNewUser(checked);
    if (checked) {
      const selectedUserCopy = { ...selectedUser } as AdNetworkUser;
      setStoredUser(selectedUserCopy);
      handleDeselectUser();
    } else {
      setSelectedUser(storedUser);
      setFirstName(storedUser?.first_name || '');
      setLastName(storedUser?.last_name || '');
      setEmail(storedUser?.email || '');
    }
  };

  const handleSelectRoles = (name: string, value: string) => {
    const newSelectedRole = roles.find((role) => role.id === value);
    if (!newSelectedRole) return;

    const newSelectedRoles = [...selectedRoles, newSelectedRole];
    setSelectedRoles(newSelectedRoles);
  };

  const handleDeselectRoles = (name: string, value: string) => {
    const newSelectedRoles = selectedRoles.filter((role) => role.id !== value);

    setSelectedRoles(newSelectedRoles);
  };

  useEffect(() => {
    onChange({
      id,
      first_name: debouncedFirstName,
      last_name: debouncedLastName,
      email: debouncedEmail,
      roles: selectedRoles,
    } as AdNetworkUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFirstName, debouncedLastName, debouncedEmail, selectedRoles]);

  if (!advertiser || !contact?.id) return null;

  return (
    <Card key={contact.id} className="flex flex-col">
      {canDelete && (
        <button
          type="button"
          onClick={() => onDelete(contact.id)}
          className="absolute right-4 top-4 bg-transparent rounded-sm p-1 transition-all hover:bg-gray-200"
        >
          <XMarkIcon className="w-5 h-5 bg-white bg-opacity-20 rounded-sm text-gray-900 text-opacity-60 transition duration-300 ease-in-out" />
        </button>
      )}
      <div className={cx('flex flex-row items-center justify-between', canDelete ? 'pt-8' : 'pt-0')}>
        <div className="text-xl">{title}</div>
        <Switch name="new-contact-switch" labelText="New Contact" checked={isNewUser} onChange={handleNewUserToggle} />
      </div>
      <div className="flex flex-row items-center">
        <div className="space-y-6 py-6 w-full">
          <Multiselect
            name={`contact-${contact?.id || 'new'}-roles-multi-select`}
            onDeselect={handleDeselectRoles}
            onDeselectAll={() => setSelectedRoles([])}
            onSelect={handleSelectRoles}
            labelText="Contact Tags"
            placeholderText="Select contact tags"
            staticOptions={rolesMultiSelectOptions}
            values={selectedRoles.map((role) => ({ value: role.id, label: AdNetworkRoleLabels[role.name] } as Option))}
            showClearAll={false}
            shouldCloseOnSelection={false}
            showSelectedOption={false}
            showValuesWhenMenuOpen
            required
          />
          {!isNewUser ? (
            <TypeaheadMultiSelect
              name="contact-user-typeahead-multi-select"
              placeholderText={selectedUser?.email || 'Type email'}
              labelText="Contact Email"
              values={selectedUser?.email ? [selectedUser.email] : []}
              onSelect={handleSelectUser}
              onDeselect={handleDeselectUser}
              onSearchQueryChange={handleUsersSearchQueryChange}
              onDeselectAll={handleDeselectUser}
              onSearch={handleUsersSearch}
              emptyLabel="No contact found"
              showSelectedOption
              showClearAll={false}
              suffixPreview
              showSuffixPreviewWhenMenuOpen
              required
            />
          ) : (
            <Input
              name="contact-email"
              labelText="Contact Email"
              value={selectedUser ? selectedUser?.email : email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={!!selectedUser?.email}
            />
          )}
          {(selectedUser || isNewUser) && (
            <div className="flex flex-col space-y-6 sm:flex-row sm:space-x-2 sm:space-y-0">
              <Input
                name="contact-first-name"
                labelText="First Name"
                value={selectedUser ? selectedUser?.first_name : firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                disabled={!!selectedUser?.first_name}
              />
              <Input
                name="contact-last-name"
                labelText="Last Name"
                value={selectedUser ? selectedUser?.last_name : lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                disabled={!!selectedUser?.email}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ContactFields;
