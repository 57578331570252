import { useCallback, useEffect, useMemo, useState } from 'react';
import { JSONContent } from '@tiptap/core';

import { TabPills } from '@/components/TabPills';
import { useWebsiteContext } from '@/context/website-context';
import { SitePackage } from '@/interfaces/site_package';
import { WebTheme } from '@/interfaces/web_theme';
import { capitalize } from '@/utils';

import { VIEWPORTS } from '../../page/constants';
import { Viewport } from '../../page/types';
import { ColorSelection } from '../../settings/themes/_components/ColorSelection';
import { FontSelection } from '../../settings/themes/_components/FontSelection';
import { RadiusSelection } from '../../settings/themes/_components/RadiusSelection';
import { previewThemeApplier } from '../DreamEditor/utils/previewThemeApplier';
import TemplatePreviewer from '../Templates/TemplatePreviewer';
import { Button } from '../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../UI/Dialog';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../UI/Select';
import { Text } from '../UI/Text';

const useProjectPreviewer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  return { isOpen, onClose, onOpen };
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  theme: any;
  project?: SitePackage;
}

const ProjectPreviewer = ({ isOpen, onClose, theme, project }: Props) => {
  const { site } = useWebsiteContext();
  const [viewport, setViewport] = useState<Viewport>(VIEWPORTS[0]);

  const handleViewportChange = useCallback(
    (vp: Viewport) => () => {
      setViewport(vp);
    },
    []
  );

  const [style, setStyle] = useState(theme);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(project?.templates[0]?.id || null);

  useEffect(() => {
    if (!theme) return;
    setStyle(theme);
  }, [theme]);

  const { previewNavbar, previewContent, previewFooter } = useMemo(() => {
    if (!site || !project || !theme || !selectedTemplateId) return {};

    const selectedTemplate = project?.templates?.find((template) => template.id === selectedTemplateId);

    return {
      previewNavbar: previewThemeApplier({
        type: 'navbar',
        content: project?.navbar as JSONContent,
        themeRules: site?.theme_rules || {},
        theme: style as unknown as WebTheme,
      }),
      previewContent: previewThemeApplier({
        type: 'tiptap',
        content: selectedTemplate?.content as JSONContent,
        themeRules: site?.theme_rules || {},
        theme: style as unknown as WebTheme,
      }),
      previewFooter: previewThemeApplier({
        type: 'tiptap',
        content: project?.footer as JSONContent,
        themeRules: site?.theme_rules || {},
        theme: style as unknown as WebTheme,
      }),
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, selectedTemplateId, style]);

  const handleReset = () => {
    setStyle(theme);
  };

  const handleSetFontFamily = useCallback(
    (key: string, value: string) => {
      setStyle({ ...style, [key]: value });
    },
    [style]
  );

  const handleSetColor = useCallback(
    (key: string, value: string) => {
      setStyle({ ...style, [key]: value });
    },
    [style]
  );

  const handleSetRadius = useCallback(
    (value: number) => {
      setStyle({ ...style, border_radius: `${value}px` });
    },
    [style]
  );

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[100vw] max-w-none h-[100vh] flex flex-col overflow-hidden">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-end justify-between ">
              <div className="flex flex-col gap-2">
                <Text size="xl" weight="semibold" variant="primary" as="h4">
                  Testing Project
                </Text>
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <div className="flex gap-16 pb-80">
          <div className="w-full">
            <div className="flex flex-col gap-4">
              <FontSelection
                title="Heading Font"
                onSetFontFamily={(value) => handleSetFontFamily('header_font', value)}
                value={style?.header_font}
                placement="bottom"
              />
              <FontSelection
                title="Body Font"
                onSetFontFamily={(value) => handleSetFontFamily('body_font', value)}
                value={style?.body_font}
                placement="bottom"
              />
              <FontSelection
                title="Button Font"
                onSetFontFamily={(value) => handleSetFontFamily('button_font', value)}
                value={style?.button_font}
                placement="bottom"
              />
              <ColorSelection
                title="Primary Color"
                initialColor={style?.primary_color || ''}
                onSetColor={(value) => handleSetColor('primary_color', value || '')}
                side="bottom"
                offset={0}
              />
              <ColorSelection
                title="Text on Primary Color"
                initialColor={style?.text_on_primary_color || ''}
                onSetColor={(value) => handleSetColor('text_on_primary_color', value || '')}
                side="bottom"
                offset={0}
              />
              <ColorSelection
                title="Background Color"
                initialColor={style?.background_color || ''}
                onSetColor={(value) => handleSetColor('background_color', value || '')}
                side="bottom"
                offset={0}
              />
              <ColorSelection
                title="Text on Background Color"
                initialColor={style?.text_on_background_color || ''}
                onSetColor={(value) => handleSetColor('text_on_background_color', value || '')}
                side="bottom"
                offset={0}
              />
              <ColorSelection
                title="Border Color"
                initialColor={style?.border_color || ''}
                onSetColor={(value) => handleSetColor('border_color', value || '')}
                side="bottom"
                offset={0}
              />
              <RadiusSelection
                title="Border Radius"
                min={0}
                max={100}
                unit="px"
                step={1}
                onSetRadius={handleSetRadius}
              />
            </div>
          </div>
          <div className="w-full max-w-[600px] flex flex-col gap-2">
            <div className="flex flex-col gap-2 justify-center items-center">
              <div className="w-full flex flex-row gap-4">
                <TabPills.Wrapper>
                  {VIEWPORTS.map((tab) => (
                    <TabPills.Item
                      key={tab.type}
                      active={viewport.type === tab.type}
                      onClick={handleViewportChange(tab)}
                    >
                      {capitalize(tab.type)}
                    </TabPills.Item>
                  ))}
                </TabPills.Wrapper>
                {project && (
                  <Select
                    defaultValue={String(project?.templates[0]?.id)}
                    onValueChange={(value: string) => {
                      setSelectedTemplateId(value);
                    }}
                  >
                    <SelectTrigger className="w-full" id="page_selection">
                      <SelectValue placeholder="Select a page" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {project?.templates?.map((option) => {
                          const val = option.name || 'Home';

                          return (
                            <SelectItem key={option.id} value={String(option.id)}>
                              {val}
                            </SelectItem>
                          );
                        })}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                )}
              </div>
            </div>
            {previewNavbar && previewContent && previewFooter && (
              <TemplatePreviewer
                navbarContent={previewNavbar as any}
                content={previewContent as any}
                footerContent={previewFooter as any}
                hasBrowserBar={false}
                containerHeightClass="h-[500px]"
                viewPortWidth={viewport.width}
                enableScroll
                disableMemo
              />
            )}
          </div>
          <div className="w-full" />
        </div>

        <DialogFooter className="flex justify-between items-center absolute bottom-0 left-0 right-0 p-4 bg-white border-t border-wb-primary">
          <div className="flex gap-2 justify-between w-full">
            <Button variant="outlined" onClick={handleReset} isDisabled={false}>
              Reset
            </Button>
            <div className="flex gap-2">
              <Button variant="primary" onClick={onClose} isDisabled={false}>
                Finished
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ProjectPreviewer, useProjectPreviewer };
