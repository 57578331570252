import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Content, JSONContent } from '@tiptap/core';
import { Editor } from '@tiptap/react';

import { DreamEditorContext } from '@/context/dream-editor-context';
import { useWebsiteContext } from '@/context/website-context';
import { useBeforeNavigate } from '@/hooks/useBeforeNavigate';
import useSiteTemplate from '@/hooks/useSiteTemplates/useSiteTemplate';
import useUpdateSiteTemplate from '@/hooks/useSiteTemplates/useUpdateSiteTemplate';
import { getPlaceholderContentWithDefaultNode } from '@/routes/website/_components/DreamEditor/utils';

const wrapContentInDocument = (content: JSONContent) =>
  ({
    type: 'doc',
    content: [content as Content],
  } as JSONContent);

const extractContentFromDocument = (content: JSONContent) => {
  if (content && 'type' in content && content.type === 'doc') {
    return content.content?.[0];
  }
  return content;
};

export const TemplatesEditorProvider = ({ children }: { children: React.ReactNode }) => {
  const { previewSiteVersion } = useWebsiteContext();
  const [initialContent, setInitialContent] = useState<JSONContent>();
  const [changesMade, setChangesMade] = useState(false);
  const [editor, setEditor] = useState<Editor | null>(null);

  const { templateId } = useParams();
  const { data: template, isLoading: isTemplateLoading } = useSiteTemplate({ siteTemplateId: templateId as string });
  const isPageLevel = template?.level === 'page';

  useEffect(() => {
    // page id changed
    if (templateId && template && !isTemplateLoading && !initialContent) {
      if (template?.content) {
        if (isPageLevel) {
          setInitialContent(template?.content as JSONContent);
        } else {
          setInitialContent(wrapContentInDocument(template?.content as JSONContent));
        }
      } else if (isPageLevel) {
        setInitialContent(getPlaceholderContentWithDefaultNode('section'));
      } else {
        setInitialContent(getPlaceholderContentWithDefaultNode('paragraph'));
      }
    }
    // return () => {
    //   editor?.commands.blur();
    //   setInitialContent(null);
    // };
  }, [templateId, template, isTemplateLoading, initialContent, editor, isPageLevel]);

  const { mutateAsync: updateSiteTemplateAsync, isLoading: isSaveLoading } = useUpdateSiteTemplate({
    id: template?.id || '',
  });

  const save = useCallback(async () => {
    if (previewSiteVersion) return; // don't save if previewing other versions
    const content = isPageLevel ? editor?.getJSON() : extractContentFromDocument(editor?.getJSON() as JSONContent);
    await updateSiteTemplateAsync({ content: JSON.stringify(content) });
    setChangesMade(false);
  }, [updateSiteTemplateAsync, previewSiteVersion, editor, isPageLevel]);

  // Auto save every 10 seconds
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        save();
      }
    }, 10000); // 10 seconds

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, save]);

  // Save on navigate away & prompt on reload or leaving page
  useBeforeNavigate(save, !!changesMade);

  const value = useMemo(
    () => ({
      initialContent,
      setEditor,
      changesMade,
      setChangesMade,
      save,
      isSaveLoading,
      onSaveToVersionHistory: () => Promise.resolve(),
      previewContent: undefined,
      isSaveVersionLoading: false,
      editor,
    }),
    [initialContent, setEditor, changesMade, save, isSaveLoading, editor]
  );

  return <DreamEditorContext.Provider value={value}>{children}</DreamEditorContext.Provider>;
};

export const useFooterDataContext = () => {
  const context = React.useContext(DreamEditorContext);
  if (context === undefined) {
    throw new Error('useFooterDataContext must be used within a FooterDataProvider');
  }
  return context;
};
