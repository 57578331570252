import { useLocation, useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/20/solid';

import { Typography } from '@/components/Typography';
import useCheckPublicationPermissions from '@/hooks/useCheckPublicationPermissions/useCheckPublicationPermissions';
import { CurrentUserOrganization, CurrentUserPublication } from '@/hooks/useCurrentUserOrganizations';
import { SHOW_CREATE_PUBLICATION_GET_PARAM } from '@/routes/settings/_layout';
import { Button } from '@/ui/Button';

import NavLink from './NavLink';
import PublicationNav from './PublicationNav';

interface Props {
  organization: CurrentUserOrganization;
  currentPublicationId: string | null;
  onLinkClick: (options: { shouldScrollToTop: boolean }) => void;
}

const WorkspaceNav = ({ organization, currentPublicationId, onLinkClick }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { publications } = organization;
  const currentPublication = publications.find((pub) => pub.id === currentPublicationId);
  const permissions = currentPublication?.permissions || {};

  const navLinks = [
    {
      label: 'Overview',
      to: '/settings/workspace/overview',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/company', 'read'),
    },
    {
      label: 'Team',
      to: '/settings/workspace/team',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/company', 'read'),
    },
    {
      label: 'Billing & Plan',
      to: '/settings/workspace/billing_and_plan',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/billing', 'read'),
    },
    {
      label: 'API',
      to: '/settings/workspace/api',
      isDisabled: !useCheckPublicationPermissions(permissions, 'views/settings/integrations', 'read'),
    },
  ];

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col gap-y-2">
          {publications.map((publication: CurrentUserPublication) => {
            if (publication.id !== currentPublicationId) {
              return null;
            }

            return (
              <PublicationNav
                key={publication.id}
                publication={publication}
                onLinkClick={() => onLinkClick({ shouldScrollToTop: false })}
              />
            );
          })}
        </div>
        <div className="flex flex-col gap-y-2">
          <Typography token="font-normal/text/xs" colorWeight="400">
            Workspace Settings
          </Typography>
          <nav className="w-full flex flex-col gap-y-1">
            {navLinks.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                label={link.label}
                isSelected={location.pathname === link.to}
                isDisabled={link.isDisabled}
                onClick={() => onLinkClick({ shouldScrollToTop: false })}
              />
            ))}
          </nav>
        </div>
        <Button
          type="button"
          size="xs"
          Icon={PlusIcon}
          className="w-full"
          onClick={() => navigate(`${location.pathname}?${SHOW_CREATE_PUBLICATION_GET_PARAM}=true`)}
        >
          New Publication
        </Button>
      </div>
    </div>
  );
};

export default WorkspaceNav;
