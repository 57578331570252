import { NavbarSerializableNode } from '@shared/dream-components';
import { JSONContent } from '@tiptap/core';

import { NodeAttrsToThemePropertiesMap } from '@/interfaces/dream_builder/site';
import { WebTheme } from '@/interfaces/web_theme';

import { applyThemeToNode as applyThemeToNavbarNode } from '../../../navbar/_components/NavbarEditor/utils';

import { applyThemeToNode } from './applyThemeToNode';

export const previewThemeApplier = ({
  type,
  content = {
    type: 'doc',
    content: [],
  },
  themeRules,
  theme,
}: {
  type: 'tiptap' | 'navbar';
  content: JSONContent;
  themeRules: NodeAttrsToThemePropertiesMap;
  theme: WebTheme;
}) => {
  const contentCopy = structuredClone(content);

  if (type === 'navbar') {
    applyThemeToNavbarNode(contentCopy as NavbarSerializableNode, theme, themeRules, true);
  } else {
    applyThemeToNode(contentCopy, theme, themeRules);
  }

  return contentCopy;
};
