import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Tippy from '@tippyjs/react';
import { Editor } from '@tiptap/react';

import SpamLinks from '../../../../../utils/spam-links.json';
import { LinkOptions } from '../../toolbars/LinkOptions';
import { Button } from '../../ui/Button';
import { Icon } from '../../ui/Icon';
import { Panel, PanelSection } from '../../ui/Panel';
import { Divider } from '../../ui/Toolbar';

import { Styled } from './EditLink.styled';

interface Props {
  onSetLink: (url: string, target: string) => void;
  onSetTarget: (target: string) => void;
  onUnsetLink?: () => void;
  onBack: () => void;
  editor: Editor;
  link?: string;
  target?: string;
  autoFocus?: boolean;
}

export const EditLink = ({
  onSetLink,
  onSetTarget,
  onUnsetLink = () => null,
  onBack,
  editor,
  link = '',
  target = '_blank',
  autoFocus = true,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showAnchors, setShowAnchors] = useState(false);
  const [newLink, setNewLink] = useState(link);
  const [newTarget, setNewTarget] = useState(target);
  const hasChanges = link !== newLink;
  const [selectedItem, setSelectedItem] = useState(0);
  const includesSpamLinks = useMemo(() => SpamLinks.url_includes, []);
  const exactSpamLinks = useMemo(() => SpamLinks.blocked_urls, []);

  // Don't support linking to anchors with the generic embed since it's purpose
  // is for linking to external sites
  const hideAnchorOption = useMemo(() => editor.isActive('genericEmbed'), [editor]);

  const anchors = editor?.storage.anchor.anchors;

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const selectedElement = scrollContainerRef.current?.children?.[selectedItem];

    if (selectedElement instanceof HTMLElement && scrollContainer) {
      const containerTop = scrollContainer?.scrollTop;
      const containerBottom = containerTop + scrollContainer?.offsetHeight;
      const elementTop = selectedElement.offsetTop;
      const elementBottom = elementTop + selectedElement.offsetHeight;

      if (
        elementBottom < containerTop ||
        elementBottom > containerBottom ||
        elementTop < containerTop ||
        elementTop > containerBottom
      ) {
        scrollContainer.scrollTo({
          top: selectedElement.offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedItem]);

  const filteredAnchors = useMemo(() => {
    return anchors.filter((anchor: { title: string; id: string }) => {
      return (
        anchor.title.toLowerCase().trim().includes(newLink.toLowerCase().trim()) ||
        `#${anchor.id.toLowerCase().trim()}`.includes(newLink.toLowerCase().trim())
      );
    });
  }, [anchors, newLink]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'Enter': {
          const includesSpam = includesSpamLinks.find((url) => newLink.toLowerCase().includes((url as string).toLowerCase()));
          const exactSpam = exactSpamLinks.find((url) => (url as string).toLowerCase() === newLink.toLowerCase());

          event.preventDefault();

          if (!hasChanges) {
            onBack();
          } else if (newLink?.length && includesSpam) {
            onUnsetLink();
            toast.error(`Cannot add ${includesSpam} links to beehiiv`);
          } else if (newLink?.length && exactSpam) {
            onUnsetLink();
            toast.error(`Cannot add ${exactSpam} to beehiiv`);
          } else if (filteredAnchors.length) {
            onSetLink(`#${filteredAnchors[selectedItem].id}`, '_self');
          } else if (newLink?.length) {
            onSetLink(newLink.trim(), newTarget);
          } else {
            onUnsetLink();
          }

          return true;
        }
        case 'Escape': {
          onBack();

          return true;
        }
        case 'ArrowUp': {
          const selectIndex = selectedItem === 0 ? filteredAnchors.length - 1 : selectedItem - 1;

          setSelectedItem(selectIndex);

          return true;
        }
        case 'ArrowDown': {
          const selectIndex = selectedItem === filteredAnchors.length - 1 ? 0 : selectedItem + 1;

          setSelectedItem(selectIndex);

          return true;
        }
        default:
          return false;
      }
    },
    [newLink, filteredAnchors, onBack, onSetLink, onUnsetLink, newTarget, hasChanges, selectedItem, setSelectedItem]
  );

  return (
    <Tippy
      render={() => {
        const anchorCount = anchors.length;
        const filteredAnchorCount = filteredAnchors.length;

        if (!showAnchors) {
          return null;
        }

        return (
          <Panel style={{ marginLeft: '-0.25rem' }}>
            <PanelSection>
              <Styled.ScrollContainer ref={scrollContainerRef}>
                {anchorCount === 0 && <Styled.EmptyState>No anchors set in your document.</Styled.EmptyState>}
                {filteredAnchorCount === 0 && anchorCount > 0 && (
                  <Styled.EmptyState>There are no anchors found matching your input.</Styled.EmptyState>
                )}

                {filteredAnchors.map((anchor: any, index: number) => {
                  return (
                    <Button
                      key={anchor.id}
                      $variant="quaternary"
                      $size="small"
                      onClick={() => {
                        onSetLink(`#${anchor.id}`, '_self');
                        setShowAnchors(!showAnchors);
                      }}
                      $active={selectedItem === index}
                      $fullWidth
                      $muted
                    >
                      {anchor.title}
                    </Button>
                  );
                })}
              </Styled.ScrollContainer>
            </PanelSection>
          </Panel>
        );
      }}
      offset={[0, 8]}
      placement="bottom-start"
      popperOptions={{
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
        ],
      }}
      interactive
      visible
    >
      <Styled.Container>
        <Styled.Input
          type="text"
          value={newLink}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewLink(event.target.value);
          }}
          onKeyDown={handleKeyDown}
          placeholder="Type or paste a link"
          autoFocus={autoFocus}
          ref={inputRef}
        />
        <Button
          $variant="quaternary"
          $size="small"
          $isIconButton
          $leftSlot={<Icon name={hasChanges ? 'Check' : 'Return'} />}
          $active={hasChanges}
          $muted={hasChanges}
          onClick={() => {
            const includesSpam = includesSpamLinks.find((url) => newLink.toLowerCase().includes((url as string).toLowerCase()));
            const exactSpam = exactSpamLinks.find((url) => (url as string).toLowerCase() === newLink.toLowerCase());

            if (!hasChanges) {
              onBack();
            } else if (newLink?.length && includesSpam) {
              onUnsetLink();
              toast.error(`Cannot add ${includesSpam} links to beehiiv`);
            } else if (newLink?.length && exactSpam) {
              onUnsetLink();
              toast.error(`Cannot add ${exactSpam} to beehiiv`);
            } else {
              onSetLink(newLink.trim(), newTarget);
            }
          }}
        />
        <Divider />
        {!hideAnchorOption && (
          <Button
            $variant="quaternary"
            $size="small"
            $isIconButton
            $leftSlot={<Icon name="Anchor" />}
            $active={showAnchors}
            $muted={showAnchors}
            onClick={() => {
              setShowAnchors(!showAnchors);

              if (inputRef) {
                inputRef.current?.focus();
              }
            }}
          />
        )}
        <LinkOptions
          target={newTarget}
          onUpdateTarget={(uptatedTarget: string) => {
            setNewTarget(uptatedTarget);
            onSetTarget(uptatedTarget);
          }}
          isDisabled={!newLink?.length}
        />
      </Styled.Container>
    </Tippy>
  );
};

export default EditLink;
