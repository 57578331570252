import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import api from '@/services/swarm';

interface ApiResponse {
  total_boosts_expenses: number;
}

type Props = {
  timePeriod?: string;
};

const useTotalBoostsExpenses = ({ timePeriod }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostsExpenses = (): Promise<ApiResponse> =>
    api
      .get(`/wallet/total_boosts_expenses`, {
        params: {
          publication_id: currentPublicationId,
          period: timePeriod,
        },
      })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'wallet', 'total_boosts_expenses', timePeriod], fetchBoostsExpenses, {
    keepPreviousData: true,
  });
};

export default useTotalBoostsExpenses;
