import { Empty, X } from '@phosphor-icons/react';
import * as Icon from '@phosphor-icons/react';
import type { IconTypes, TIcon } from '@shared/dream-components';

import { IconSelector } from '@/routes/website/_components/DreamEditor/AttributesPanel/components/ui/IconSelector';
import { Button } from '@/routes/website/_components/UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '@/routes/website/_components/UI/Popover';
import { Text } from '@/routes/website/_components/UI/Text';

import { useNavbarContext } from '../../../NavbarContext';

export const IconSettings = () => {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown_item') return null;
  if (selectedContent.attrs.style !== 'icon' && selectedContent.attrs.style !== 'featured') return null;

  const icon: TIcon | undefined =
    selectedContent?.attrs && 'icon' in selectedContent.attrs ? selectedContent.attrs.icon : undefined;
  const isNone = !icon;

  const IconComponent = !isNone && 'iconName' in icon ? Icon[icon.iconName as IconTypes] : null;

  const handleReset = () => {
    if (!selectedContent || !selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs.id, { icon: null });
  };

  const handleIconChange = (value: { type: string; iconType: string; iconName: string }) => {
    if (!selectedContent || !selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs.id, { icon: { ...icon, ...(value || {}) } });
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2 select-none">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Icon
          </Text>

          <div className="grow bg-wb-secondary rounded-lg shadow-sm">
            <div className="w-full justify-between flex items-center gap-2 p-2 cursor-pointer max-w-[138px]">
              {isNone ? (
                <Empty className="text-wb-secondary" weight="bold" />
              ) : (
                IconComponent && <IconComponent className="w-4 h-4 rounded-md" />
              )}

              <Text size="2xs" weight="medium" className="capitalize text-ellipsis overflow-hidden grow text-left">
                {icon && 'iconName' in icon ? icon.iconName || 'None' : 'None'}
              </Text>
              {!isNone && (
                <Button
                  variant="ghost"
                  Icon={X}
                  iconClassName="text-wb-secondary w-3 h-3"
                  onClick={handleReset}
                  className="p-0"
                />
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[255px] p-0" align="end" side="left" sideOffset={20}>
        <div className="h-[500px] p-3 flex flex-col gap-2 min-h-0">
          <Text size="sm" weight="semibold">
            Icon
          </Text>
          <IconSelector onChange={handleIconChange} />
        </div>
      </PopoverContent>
    </Popover>
  );
};
