import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { AcademicCapIcon, LinkIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import VideoModal from '@/components/Modal/VideoModal';
import { Tutorial } from '@/interfaces/tutorial';
import Tooltip from '@/ui/Tooltip';

import { Skeleton, SkeletonLoader } from '../../../components/SkeletonLoader';
import { useCopyToClipboard } from '../../../hooks';

interface Props {
  scrollToId: string;
  title: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode | React.ReactNode[] | any;
  hasDivider?: boolean;
  isLoading?: boolean;
  tooltipText?: string;
  size?: 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
  tutorial?: Tutorial;
  customCopyLink?: string;
}

const ItemsSection = ({
  scrollToId,
  title,
  description,
  children,
  hasDivider = true,
  isLoading = false,
  tooltipText,
  size = 'xl',
  tutorial,
  customCopyLink,
}: Props) => {
  const copy = useCopyToClipboard();
  const [isLinkShowing, setIsLinkShowing] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const sectionRef = useRef<any>(null);
  const location = useLocation();
  const isSelectedSection = location.hash === `#${scrollToId}`;
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const sizes = {
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
    '4xl': 'max-w-4xl',
    '5xl': 'max-w-5xl',
    '6xl': 'max-w-6xl',
    full: 'max-w-full',
  };

  useEffect(() => {
    if (isSelectedSection && sectionRef.current) {
      setTimeout(() => {
        sectionRef.current.scrollIntoView({
          alignToTop: true,
          behavior: 'smooth',
        });
      }, 100);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopyLink = () => {
    if (isLinkShowing) {
      setIsLinkShowing(false);
      setIsCopied(true);

      copy({
        text: customCopyLink || `${window.location.origin}${location.pathname}#${scrollToId}`,
        onSuccessText: '',
      });

      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    }
  };

  const renderChildren = () => {
    if (isLoading) {
      return (
        <SkeletonLoader
          isLoading={Boolean(true)}
          skeletons={
            <div className="space-y-6 w-full">
              <div>
                <Skeleton className="h-8 rounded" />
              </div>
              <div>
                <Skeleton className="h-8 rounded" />
              </div>
              <div>
                <Skeleton className="h-8 rounded" />
              </div>
            </div>
          }
        >
          <div />
        </SkeletonLoader>
      );
    }

    return children;
  };

  return (
    <>
      {tutorial && (
        <VideoModal
          title={title}
          src={tutorial.url}
          isOpen={isVideoModalOpen}
          onClose={() => setIsVideoModalOpen(false)}
        />
      )}
      <div
        id={scrollToId}
        className={cx('relative w-full pb-4 pt-2 text-left px-8', hasDivider ? ' border-b border-gray-200' : '')}
      >
        <div ref={sectionRef} className="absolute -top-24 left-0" />
        <div className={cx('w-full space-y-4 py-8 mx-auto', sizes[size])}>
          <button
            type="button"
            className="pb-4 flex justify-between items-center w-full transition-all"
            onFocus={() => setIsLinkShowing(true)}
            onMouseOver={() => {
              if (!isCopied) {
                setIsLinkShowing(true);
              }
            }}
            onMouseLeave={() => setIsLinkShowing(false)}
            onClick={handleCopyLink}
          >
            <div>
              <div className="w-full flex items-center space-x-1">
                <h2 className="text-2xl leading-6 font-regular text-gray-900 ml-0 flex whitespace-nowrap">{title}</h2>
                {tooltipText && <Tooltip text={tooltipText} id={scrollToId || ''} />}
              </div>

              {description && <p className="text-md text-left max-w-lg font-light text-gray-400 pt-2">{description}</p>}
              {tutorial && (
                <button
                  type="button"
                  onClick={() => setIsVideoModalOpen(true)}
                  className="flex items-center space-x-1 text-sm mt-1 text-blue-400 hover:text-blue-500"
                >
                  <AcademicCapIcon className="h-4 w-4" />
                  <span>{tutorial.cta}</span>
                </button>
              )}
            </div>
            {isLinkShowing && !isCopied && (
              <div>
                <LinkIcon className="w-6 h-6 text-gray-300" />
              </div>
            )}
            <Transition
              show={isCopied}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="text-primary-500 flex items-center space-x-1 py-1 px-2 rounded bg-gray-100">
                <span className="font-semibold text-xs">Copied!</span>
              </div>
            </Transition>
          </button>
          {renderChildren()}
        </div>
      </div>
    </>
  );
};

export default ItemsSection;
