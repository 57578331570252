import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import Card from '@/components/Card';
import LoadingBox from '@/components/LoadingBox';
import { Typography, TypographyStack } from '@/components/Typography';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useRetrySvixMessage from '@/hooks/webhooks/useRetrySvixMessage';
import useSvixEndpoint from '@/hooks/webhooks/useSvixEndpoint';
import useSvixMessageAttempt from '@/hooks/webhooks/useSvixMessageAttempt';
import StatusBadge from '@/pages/Settings/Pages/Integrations/Webhooks/Endpoints/Show/Messages/Index/StatusBadge';
import BodyContainer from '@/routes/settings/_components/BodyContainer';
import CardHeader from '@/routes/settings/_components/CardHeader';
import PageContainer from '@/routes/settings/_components/PageContainer';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';

import Header from '../../../../components/Header';

import MessagePayload from './MessagePayload';

const ShowMessageAttempt = () => {
  const { endpointId, messageId, messageAttemptId } = useParams<{
    endpointId: string;
    messageId: string;
    messageAttemptId: string;
  }>();

  const currentPublicationId = useCurrentPublicationId();
  const {
    data: endpoint,
    isLoading: isLoadingEndpoint,
    isError: isErrorEndpoint,
  } = useSvixEndpoint(currentPublicationId, endpointId || '');
  const {
    data: messageAttempt,
    isLoading: isLoadingMessageAttempt,
    isError: isErrorMessageAttempt,
  } = useSvixMessageAttempt(currentPublicationId, endpointId || '', messageAttemptId || '', messageId || '');

  const queryClient = useQueryClient();
  const [isRetrying, setIsRetrying] = useState(false);

  const retrySvixMessage = useRetrySvixMessage({
    publicationId: currentPublicationId,
    onSuccess: () => {
      toast.success('Retry sent successfully');
      setIsRetrying(false);
      queryClient.invalidateQueries(
        ['publications', currentPublicationId, 'webhooks', 'endpoints', endpointId, 'message_attempts'],
        { exact: true }
      );
    },
    onError: (errPayload) => {
      setIsRetrying(false);
      const errors = errPayload?.response?.data?.message || 'Something went wrong';
      toast.error(errors);
    },
  });

  const handleRetrySvixMessage = () => {
    if (!endpointId || !messageId) {
      return;
    }

    setIsRetrying(true);
    retrySvixMessage.mutate({ endpointId, messageId });
  };

  const columnClassName = 'md:w-[300px]';

  return (
    <LoadingBox
      isLoading={isLoadingEndpoint || isLoadingMessageAttempt}
      isError={isErrorEndpoint || isErrorMessageAttempt}
    >
      <PageContainer isWide>
        <Header endpoint={endpoint} />
        <Card>
          <BodyContainer>
            <CardHeader
              title="Message Details"
              cta={
                <Button
                  variant="primary"
                  size="xs"
                  disabled={isRetrying}
                  onClick={() => handleRetrySvixMessage()}
                  Icon={ArrowPathIcon}
                  iconRight
                >
                  Resend Message
                </Button>
              }
            />
            <div className="flex flex-row gap-x-2">
              <TypographyStack className={columnClassName}>
                <Typography token="font-normal/text/sm" colorWeight="600">
                  Timestamp
                </Typography>
                <Typography token="font-medium/text/sm" as="time">
                  {moment(messageAttempt?.timestamp).format('LLL')}
                </Typography>
              </TypographyStack>
              <TypographyStack className={columnClassName}>
                <Typography token="font-normal/text/sm" colorWeight="600">
                  Endpoint URL
                </Typography>
                <Typography token="font-medium/text/sm" as="time">
                  {moment(messageAttempt?.timestamp).format('LLL')}
                </Typography>
              </TypographyStack>
            </div>
            <div className="flex flex-row gap-x-2">
              <TypographyStack className={columnClassName}>
                <Typography token="font-normal/text/sm" colorWeight="600">
                  Event Status
                </Typography>
                {messageAttempt ? <StatusBadge messageAttempt={messageAttempt} /> : null}
              </TypographyStack>
              <TypographyStack className={columnClassName}>
                <Typography token="font-normal/text/sm" colorWeight="600">
                  Response Code
                </Typography>
                <Typography token="font-medium/text/sm">{messageAttempt?.response_status_code}</Typography>
              </TypographyStack>
            </div>
            <div className="flex flex-row gap-x-2">
              <TypographyStack>
                <Typography token="font-normal/text/sm" colorWeight="600">
                  Event Types
                </Typography>
                <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
                  {endpoint?.event_types.map((eventType) => (
                    <Badge>{eventType}</Badge>
                  ))}
                </div>
              </TypographyStack>
            </div>
          </BodyContainer>
        </Card>
        <MessagePayload payload={messageAttempt?.message.payload} />
      </PageContainer>
    </LoadingBox>
  );
};

export default ShowMessageAttempt;
