import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowRight, Palette, User, Users } from '@phosphor-icons/react';
import { Editor, isNodeSelection, isTextSelection, Range } from '@tiptap/core';
import { AllSelection } from '@tiptap/pm/state';

import { useSite } from '@/hooks/useSite';
import { useInfiniteScrollSiteTemplates } from '@/hooks/useSiteTemplates';
import { SiteTemplate } from '@/interfaces/site_template';

import { cn } from '../../../_utils/cn';
import TemplatePreviewer from '../../Templates/TemplatePreviewer';
import { Button } from '../../UI/Button';
import { Checkbox } from '../../UI/Checkbox';
import { Dialog, DialogContent, DialogFooter, DialogTrigger } from '../../UI/Dialog';
import { Label } from '../../UI/Label';
import { Text } from '../../UI/Text';
import { ActiveNodeResult } from '../extensions/ActiveNode/types';
import { HiddenAllSelection } from '../extensions/CustomSelections/selections';

interface Props {
  type: 'page' | 'section' | 'block';
  editor: Editor;
  actionButton: React.ReactNode;
  insertPos?: number | Range | null;
  multipleSelectionsAllowed?: boolean;
  activeNodeResult: ActiveNodeResult;
  shouldReplaceNode?: boolean;
  onlyAllowNodeCategory?: boolean;
}

const INSERT_TEMPLATE_TITLE_TEXT = {
  page: 'Select a Page',
  section: 'Select a Section',
  block: 'Select a Block',
};

const SUBMIT_BUTTON_TEXT = {
  page: 'page',
  section: 'section',
  block: 'block',
};

const LEFT_PANEL_TITLE_TEXT = {
  page: 'Pages',
  section: 'Sections',
  block: 'Blocks',
};

const getDefaultCategory = (
  type: 'page' | 'section' | 'block',
  options: { value: string; label: string }[],
  nodeType: string
): string | undefined => {
  if (!options?.length) {
    return undefined;
  }

  if (type !== 'block') {
    return options[0].value;
  }

  return options.find((option) => option.value === nodeType)?.value || options[0].value;
};

const iconMap = {
  theme: Palette,
  mine: User,
  public: Users,
};

const InsertTemplateModal = ({
  actionButton,
  editor,
  insertPos,
  multipleSelectionsAllowed = true,
  type,
  activeNodeResult,
  shouldReplaceNode = false,
  onlyAllowNodeCategory = false,
}: Props) => {
  const { data: site } = useSite();
  const { pageId } = useParams();
  const titleText = INSERT_TEMPLATE_TITLE_TEXT[type];
  const submitButtonText = SUBMIT_BUTTON_TEXT[type];
  let categoryOptions = site?.template_categories?.[type] || [];
  const leftPanelTitleText = LEFT_PANEL_TITLE_TEXT[type];
  const appliedSitePackageId = site?.draft_site_version?.applied_site_package_id;

  const defaultCategory = getDefaultCategory(type, categoryOptions, activeNodeResult.activeNodeType);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(defaultCategory || 'signup');
  const [selectedTemplates, setSelectedTemplates] = useState<SiteTemplate[]>([]);
  const [canFetchTemplates, setCanFetchTemplates] = useState(false);
  const [templateType, setTemplateType] = useState<'theme' | 'mine' | 'public'>('public');
  const isTheme = templateType === 'theme';

  if (type === 'block') {
    categoryOptions = categoryOptions.filter((category) => category?.show_in_template_gallery);
  }

  const selectedSectionsCount = selectedTemplates.length;
  const isDisabled = selectedSectionsCount === 0;
  const selectedSectionsCountText = selectedSectionsCount <= 1 ? '' : `(${selectedSectionsCount}) `;
  const selectedSectionsCountCTAText = selectedSectionsCount <= 1 ? submitButtonText : `${submitButtonText}s`;

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteScrollSiteTemplates({
    enabled: canFetchTemplates,
    category: selectedCategory,
    sitePackageId: isTheme ? appliedSitePackageId : undefined,
    allPublicTemplates: templateType === 'public',
    myTemplates: templateType === 'mine',
    level: type as 'block' | 'section' | 'page',
  });
  const siteTemplates = data?.pages.flatMap((page) => page.site_templates) || [];
  const hasNoResults = siteTemplates.length === 0;
  const totalTemplates = data?.pages[0].pagination.total || 0;
  const showingCount = siteTemplates.length;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const selectedCategoryRef = useRef<HTMLLIElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setCanFetchTemplates(true);
    setSelectedCategory(getDefaultCategory(type, categoryOptions, activeNodeResult.activeNodeType));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCanFetchTemplates(false);
    setSelectedCategory(undefined);
    setSelectedTemplates([]);
  };

  const handleInsertSection = useCallback(() => {
    const combinedContent = selectedTemplates?.map((template: SiteTemplate) => template.content) || [];

    const { activeNodePos: from, activeNodeEnd: to, activeNode, selection } = activeNodeResult;

    if (shouldReplaceNode) {
      if (activeNode?.type.name === 'doc') {
        editor.chain().setMeta('shouldReplaceNode', shouldReplaceNode).setContent(combinedContent).focus().run();
      } else {
        editor
          .chain()
          .setMeta('shouldReplaceNode', shouldReplaceNode)
          .insertThemedContentAt({ from, to }, combinedContent)
          .focus()
          .run();
      }

      return;
    }

    if (typeof insertPos === 'number') {
      editor.chain().insertThemedContentAt(insertPos, combinedContent).focus().run();
    } else if (type === 'section') {
      const { doc } = editor.state;

      if (isNodeSelection(selection)) {
        if (selection.node.type.name === 'section') {
          editor.chain().insertThemedContentAt(selection.to, combinedContent).focus().run();
        } else {
          const ancestorSectionEnd = selection.$from.after(1);
          editor.chain().insertThemedContentAt(ancestorSectionEnd, combinedContent).focus().run();
        }
      } else if (isTextSelection(selection)) {
        const ancestorSectionEnd = selection.$from.after(1);
        editor.chain().insertThemedContentAt(ancestorSectionEnd, combinedContent).focus().run();
      } else if (selection instanceof AllSelection || selection instanceof HiddenAllSelection) {
        editor
          .chain()
          .insertThemedContentAt(doc.nodeSize - 1, combinedContent)
          .focus()
          .run();
      } else {
        editor.chain().insertThemedContentAt(selection.from, combinedContent).focus().run();
      }
    } else {
      editor
        .chain()
        .insertThemedContentAt(Math.min(selection.$from.end(0), to), combinedContent)
        .focus()
        .run();
    }

    handleCloseModal();
  }, [activeNodeResult, editor, insertPos, selectedTemplates, shouldReplaceNode, type]);

  const handleSelection = (template: SiteTemplate, isSelected: boolean) => {
    if (isSelected) {
      if (multipleSelectionsAllowed) {
        setSelectedTemplates((prev) => [...prev, template]);
      } else {
        setSelectedTemplates([template]);
      }
    } else if (multipleSelectionsAllowed) {
      setSelectedTemplates((prev) => prev.filter((t) => t.id !== template.id));
    } else {
      setSelectedTemplates([]);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (selectedCategoryRef.current && scrollContainerRef.current) {
        selectedCategoryRef.current?.scrollIntoView({
          block: 'nearest',
        });
      }
    }, 0);
  }, [isModalOpen, selectedCategory]);

  return (
    <Dialog
      open={isModalOpen}
      onOpenChange={(open) => {
        if (open) {
          handleOpenModal();
        } else {
          handleCloseModal();
        }
      }}
    >
      <DialogTrigger asChild>{actionButton}</DialogTrigger>
      <DialogContent className="w-[800px] max-w-[95vw] p-0">
        <div className="flex flex-col p-4 h-[60vh]">
          <Text size="md" weight="semibold" className="mb-4">
            {titleText}
          </Text>

          <div className="flex h-full">
            <div
              className="w-[200px] border-wb-hr pr-4 gap-2 flex flex-col pb-10 overflow-y-auto h-full"
              ref={scrollContainerRef}
            >
              <Text weight="medium" variant="secondary" size="2xs">
                {leftPanelTitleText}
              </Text>
              <ul>
                {categoryOptions.map((category) => {
                  const isSelected = category.value === selectedCategory;

                  return (
                    <li key={category.value} ref={category.value === selectedCategory ? selectedCategoryRef : null}>
                      <Button
                        variant="ghost"
                        className={cn('w-full justify-start', isSelected ? 'bg-wb-secondary' : '')}
                        onClick={() => setSelectedCategory(category.value)}
                        isDisabled={onlyAllowNodeCategory && category.value !== defaultCategory}
                      >
                        <Text weight="medium" variant="primary" size="2xs" as="span">
                          {category.label}
                        </Text>
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="flex flex-col w-full overflow-y-auto no-scrollbar">
              <div className="flex items-center gap-4">
                {['public', 'mine', 'theme'].map((t) => (
                  <Button
                    variant={templateType === t ? 'secondary' : 'ghost'}
                    LeftIcon={iconMap[t as 'theme' | 'mine' | 'public']}
                    onClick={() => setTemplateType(t as 'theme' | 'mine' | 'public')}
                  >
                    <Text size="sm" weight="medium" variant={templateType === t ? 'primary' : 'secondary'}>
                      {t.charAt(0).toUpperCase() + t.slice(1)}
                    </Text>
                  </Button>
                ))}
              </div>
              {hasNoResults ? (
                <div className="flex justify-center items-center h-full">
                  <Text size="md" weight="medium" variant="secondary">
                    No templates found
                  </Text>
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-x-4 gap-y-8 pb-32 pr-4 pt-4">
                  {siteTemplates?.map((template) => {
                    const isSelected = selectedTemplates?.find((t: SiteTemplate) => t.id === template.id);
                    const isSmallViewport = ['signup', 'button', 'socials'].includes(template.category);
                    const isSection = type === 'section';

                    return (
                      <Label
                        htmlFor={template.id}
                        className={cn(
                          'flex flex-col justify-center items-center cursor-pointer p-4 rounded-md h-[300px] focus:outline-none relative',
                          isSelected ? 'border-wb-accent border-2' : 'border-wb-primary',
                          isSection ? 'h-[400px]' : 'h-[300px]'
                        )}
                      >
                        <TemplatePreviewer
                          content={template.content}
                          hasBrowserBar={false}
                          viewPortWidth={isSmallViewport ? 650 : 1280}
                          containerHeightClass="h-full"
                          centerAndZoom={isSmallViewport}
                        />
                        <Checkbox
                          id={template.id}
                          className={cn('absolute -top-2 -right-2', isSelected ? 'opacity-100' : 'opacity-0')}
                          checked={Boolean(isSelected)}
                          onCheckedChange={() => handleSelection(template, !isSelected)}
                        />

                        <Text weight="semibold" variant="primary" size="sm" as="span" className="mt-2">
                          {template.name}
                        </Text>
                      </Label>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="flex justify-between items-center border-t w-full p-4 z-20 bg-white rounded-b-md">
            <Link to={`/website_builder_v2/templates?type=${type}&previousPageId=${pageId}`}>
              <Button variant="outlined">Edit My Templates</Button>
            </Link>
            <div className="flex gap-2 items-center">
              <Text weight="regular" variant="secondary" size="xs" as="span">
                {showingCount} of {totalTemplates} templates
              </Text>
              <Button variant="outlined" onClick={() => fetchNextPage()} isDisabled={!hasNextPage}>
                {isFetchingNextPage ? 'Loading...' : 'Load More'}
              </Button>
              <Button variant="primary" onClick={handleInsertSection} isDisabled={isDisabled} RightIcon={ArrowRight}>
                Add {selectedSectionsCountCTAText} {selectedSectionsCountText}
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InsertTemplateModal;
