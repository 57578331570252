import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AlignmentSettings } from '../AlignmentSettings';
import BorderSettings from '../BorderSettings';
import ColorGroupSettings from '../ColorGroupSettings';
import { ColorSettings } from '../ColorSettings';
import FontGroupSettings from '../FontGroupSettings';
import { GapSettings } from '../GapSettings';
import { GridColumnSetting } from '../GridColumnSetting';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';

import { DescriptionSettings } from './DescriptionSettings';
import { ImageSettings } from './ImageSettings';
import { MessageSettings } from './MessageSettings';
import { NameSettings } from './NameSettings';

export const TestimonialsSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['testimonials']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Layout">
        <GridColumnSetting editor={editor} activeNodeResult={activeNodeResult} title="Columns" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
      </SectionRenderer>

      <SectionRenderer title="Cards">
        <ColorSettings
          title="Background"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="backgroundColor"
        />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
        <AlignmentSettings editor={editor} activeNodeResult={activeNodeResult} property="alignment" title="Alignment" />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="shadow" title="Shadow" />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ColorGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Text"
          isUngroupable={false}
          properties={[
            { title: 'Name', property: 'nameColor' },
            { title: 'Description', property: 'descriptionColor' },
            { title: 'Message', property: 'messageColor' },
          ]}
        />
        <FontGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          isGroupedInitially
          isUngroupable={false}
          properties={[
            {
              title: 'Name',
              property: 'nameFontFamily',
            },
            {
              title: 'Description',
              property: 'descriptionFontFamily',
            },
            {
              title: 'Message',
              property: 'messageFontFamily',
            },
          ]}
        />
        <ImageSettings editor={editor} activeNodeResult={activeNodeResult} />
        <NameSettings editor={editor} activeNodeResult={activeNodeResult} />
        <DescriptionSettings editor={editor} activeNodeResult={activeNodeResult} />
        <MessageSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>
    </RenderCondition>
  );
};
