import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@/components/Typography';
import { useCurrentUser } from '@/context/current-user-context';
import { useStripeBillingSession } from '@/hooks/useBilling';
import { usePublication } from '@/hooks/usePublications';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { BillingActions } from '@/interfaces/billing';
import useUpdateOnboarding from '@/routes/onboarding/_hooks/useUpdateOnboarding';
import usePlanPriceOptions from '@/routes/settings/billing/_hooks/usePlanPriceOptions';
import { capitalize } from '@/utils';
import analytics from '@/utils/analytics';
import { PLAN } from '@/utils/plans';

import useCreateTrial from '../../_hooks/useCreateTrial';
import { Layout, LeftPane, RightPane } from '../_components/Layout';
import OnboardingStepForm from '../_components/OnboardingStepForm';

import PlanCard from './_components/PlanCard';

const OnboardingPlanConfirmation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const publicationId = useCurrentPublicationId();
  const { data: currentPublication } = usePublication(publicationId);
  const organizationId = currentPublication?.organization_id;

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const [showConfetti, setShowConfetti] = useState(true);
  const [selectedOption, setSelectedOption] = useState<0 | 1>(0);
  const selectedPlan: 'scale' | 'max' | 'launch' =
    (localStorage.getItem('selectedPlan') as 'scale' | 'max' | 'launch') || 'scale';
  const updateOnboarding = useUpdateOnboarding();
  const createTrial = useCreateTrial();
  const stripeCheckoutSession = useStripeBillingSession({
    publicationId,
    organizationId,
    action: BillingActions.UPGRADE_CONFIRM,
    trackingEvent: 'Started Checkout',
  });
  const [newPlanPriceId, setNewPlanPriceId] = useState<string | undefined>();

  const onboardingMutation = useUpdateOnboarding();
  const { data: planPriceOptions } = usePlanPriceOptions({ planName: selectedPlan as PLAN, enabled: true });

  useEffect(() => {
    if (planPriceOptions) {
      const lowestTierPlanPrice = planPriceOptions
        .filter((priceOption) => priceOption.interval === 'month')
        .reduce((prev, current) => (prev.max_subscriptions < current.max_subscriptions ? prev : current));
      setNewPlanPriceId(lowestTierPlanPrice.id);
    }
  }, [planPriceOptions]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitButtonDisabled(true);

    updateOnboarding
      .mutateAsync({ status: 'complete' })
      .then(() => {
        if (currentUser?.id) {
          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
            state: 'trialing',
            currentPlan: 'launch',
          });
        }
        analytics.track('Completed Onboarding', { email: currentUser?.email });
        analytics.track('Trial Started', {
          state: 'trialing',
          currentPlan: 'launch',
          trialPlan: selectedPlan,
        });
      })
      .then(() => {
        if (selectedOption === 0) {
          stripeCheckoutSession.mutate({
            plan: selectedPlan,
            plan_price_id: newPlanPriceId,
            promotion_code: undefined,
          });
        } else {
          createTrial.mutateAsync({ selectedPlan });
        }
      })
      .finally(() => queryClient.invalidateQueries(['publications', currentPublication?.id]));
  };

  setTimeout(() => {
    setShowConfetti(false);
  }, 5000);

  const title = selectedPlan === 'launch' ? 'Launch Plan + 30-Day Free Max Trial!' : 'Start your 30-day free trial';

  const subtitle =
    'I know you were eyeing the Launch plan, but I got you a free upgrade: Launch, plus 30 days of access to the most powerful features of our Max plan.';

  const options = {
    launch: {
      includes: [
        'Audio Newsletters',
        'beehiiv AI',
        'Referral Program',
        'Email Automations',
        'Surveys & Polls',
        'Advanced Analytics',
      ],
      excludes: [],
    },
    scale: {
      includes: ['Ad Network', 'Paid Subscriptions', 'Boost Network', 'All other Scale features'],
      excludes: ['Ad Network', 'Boost Network', 'Paid Subscriptions'],
    },
    max: {
      includes: [
        'Ad Network',
        'Paid Subscriptions',
        'Boost Network',
        'Up to 10 publications',
        'NewsletterXP Course',
        'All other Max features',
      ],
      excludes: [
        'Ad Network',
        'Paid Subscriptions',
        'Boost Network',
        'limited to 3 publications',
        'Private Branding',
        'RSS to Send',
      ],
    },
  };

  return (
    <>
      <Helmet>
        <title>You’re almost in, just one more step to go! - beehiiv</title>
      </Helmet>

      <Layout>
        <Confetti
          recycle={showConfetti}
          numberOfPieces={100}
          gravity={0.08}
          drawShape={(ctx) => {
            const shapeType = Math.floor(Math.random() * 4); // 0 = rectangle, 1 = hexagon, 2 = triangle, 3 = star
            const size = 1 + Math.random() * 15; // Random size between 10 and 25

            ctx.beginPath();
            switch (shapeType) {
              case 0: // Rectangle
                ctx.rect(-size / 2, -size / 2, size, size);
                break;

              case 1: // Hexagon
                for (let i = 0; i < 6; i += 1) {
                  const angle = (Math.PI / 3) * i;
                  const x = size * Math.cos(angle);
                  const y = size * Math.sin(angle);
                  ctx.lineTo(x, y);
                }
                break;

              case 2: // Triangle
                for (let i = 0; i < 3; i += 1) {
                  const angle = (Math.PI * 2 * i) / 3;
                  const x = size * Math.cos(angle);
                  const y = size * Math.sin(angle);
                  ctx.lineTo(x, y);
                }
                break;

              case 3: // Five-pointed star
                for (let i = 0; i < 10; i += 1) {
                  const angle = (Math.PI / 5) * i;
                  const radius = i % 2 === 0 ? size : size / 2;
                  const x = radius * Math.cos(angle);
                  const y = radius * Math.sin(angle);
                  ctx.lineTo(x, y);
                }
                break;

              default: // Fallback shape (circle)
                ctx.arc(0, 0, size / 2, 0, Math.PI * 2);
                break;
            }
            ctx.closePath();
            ctx.fill();
          }}
          colors={['#FF5EC4A6', '#F092DD99', '#3843D0E5', '#888EE373']}
        />
        <LeftPane>
          <OnboardingStepForm
            title="You’re almost in, just one more step to go!"
            subtitle={subtitle}
            onBack={() => navigate('/onboarding/primary_goals')}
            onSubmit={onSubmit}
            onSkip={() => navigate('/onboarding/primary_goals')}
            isSubmitDisabled={isSubmitButtonDisabled}
            isProcessing={onboardingMutation.isLoading}
            showBea
            currentProgress={95}
            formClassName="p-8 border rounded-lg border-gray-200 bg-surface-50"
          >
            <div className="flex flex-col gap-6">
              {selectedPlan !== 'launch' && (
                <PlanCard
                  title={`Upgrade and unlock all ${capitalize(selectedPlan)}`}
                  onClick={() => {
                    setSelectedOption(0);
                  }}
                  selected={selectedOption === 0}
                  bulletPoints={options[selectedPlan].includes.map((text) => ({ text, enabled: true }))}
                />
              )}
              <PlanCard
                title={title}
                showNoCardRequired
                onClick={() => {
                  setSelectedOption(1);
                }}
                selected={selectedOption === 1}
                bulletPointsHeader={
                  <Typography token="font-normal/text/sm">
                    All {capitalize(selectedPlan)} features <Typography token="font-bold/text/sm">EXCEPT</Typography>:
                  </Typography>
                }
                bulletPoints={options[selectedPlan].excludes.map((text) => ({ text, enabled: false }))}
              />
            </div>
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingPlanConfirmation;
