import React, { Fragment, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Modifier, usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { Typography } from '@/components/Typography';
import { DROP_DOWN_Z_INDEX } from '@/components/zIndexes';
import { useClickOutside } from '@/hooks/useUsers/useClickOutside';
import { Option } from '@/interfaces/general';
import { Badge, BadgeProps, ICON_COLORS } from '@/ui/Badge';

interface Props {
  badgeClassName?: string;
  badgeTextClassName?: string;
  badgeLabelClassName?: string;
  badgeSubtitleClassName?: string;
  disabled?: boolean;
  dropdownClassnames?: string;
  emptyLabel?: string;
  helperText?: string | React.ReactNode;
  labelClassName?: string;
  labelText?: string;
  name: string;
  onClear?: (name: string) => void;
  onDeselect: (name: string, value: string) => void;
  onDeselectAll: (name: string) => void;
  onSearch?: () => Promise<Option[]>;
  onSearchQueryChange?: (query: string) => void;
  onSelect: (name: string, value: string) => void;
  badgeColor?: BadgeProps['type'];
  placeholderText?: string;
  required?: boolean;
  showClearAll?: boolean;
  showSelectedOption?: boolean;
  shouldCloseOnSelection?: boolean;
  showValuesWhenMenuOpen?: boolean;
  selectedPreview?: string[];
  values: string[] | Option[];
  errorText?: string;
  staticOptions?: Option[];
  autoWidth?: boolean;
}

const PreviewSelected = ({
  children,
  badgeColor = 'success',
  onRemoveItem,
}: {
  children: string;
  badgeColor: BadgeProps['type'];
  onRemoveItem?: () => void;
}) => (
  <li>
    <Badge type={badgeColor} className="truncate rounded">
      {children}{' '}
      <button type="button" onClick={onRemoveItem} className="opacity-60 hover:opacity-100">
        <XMarkIcon className="ml-1 h-3.5 w-3.5 text-inherit" />
      </button>
    </Badge>
  </li>
);

const Multiselect = ({
  disabled = false,
  dropdownClassnames,
  emptyLabel,
  helperText,
  labelClassName,
  badgeColor = 'success',
  labelText,
  name,
  onClear,
  onDeselect,
  onDeselectAll,
  onSearch,
  onSearchQueryChange,
  onSelect,
  placeholderText,
  required = false,
  showClearAll = true,
  showSelectedOption = true,
  shouldCloseOnSelection = true,
  showValuesWhenMenuOpen = false,
  selectedPreview = [],
  values,
  errorText,
  staticOptions,
  badgeClassName,
  badgeTextClassName,
  badgeLabelClassName,
  badgeSubtitleClassName,
  autoWidth,
}: Props) => {
  const popperElRef = useRef(null);
  const targetElement = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const [options, setOptions] = useState<Option[]>();
  const optionValues = values.map((value) => (typeof value === 'string' ? value : value.value));
  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownEl = useRef<any>();
  const inputEl = useRef<any>();

  // Load options when search query changes
  useEffect(() => {
    setIsLoading(true);
    if (!staticOptions && onSearch) {
      onSearch()
        .then((loadedOptions: Option[]) => {
          if (loadedOptions) {
            setOptions(loadedOptions);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setOptions(staticOptions);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, staticOptions]);

  useClickOutside(() => setMenuOpen(false), dropdownEl);

  const modifiers = useMemo(
    (): Modifier<string, Record<string, unknown>>[] => [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'matchReferenceSize',
        enabled: true,
        fn: ({ state, instance }) => {
          const widthOrHeight =
            state.placement.startsWith('left') || state.placement.startsWith('right') ? 'height' : 'width';

          if (!popperElement) return;

          const popperSize =
            popperElement[`offset${widthOrHeight[0].toUpperCase() + widthOrHeight.slice(1)}` as 'offsetWidth'];
          const referenceSize = state.rects.reference[widthOrHeight];

          if (Math.ceil(popperSize) >= Math.floor(referenceSize)) return;

          // @ts-ignore: Style is accessible
          popperElement.style[widthOrHeight] = `${referenceSize}px`;
          instance.update();
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [popperElement, values]
  );

  const { styles, attributes } = usePopper(targetElement?.current, popperElement, {
    placement: 'bottom-end',
    modifiers,
  });

  const updateSearchQuery = (query: string) => {
    onSearchQueryChange?.(query);
    setSearchQuery(query);
  };

  const onSelectOption = (newValue: any) => {
    if (!name || !newValue) {
      return;
    }

    onSelect(name, newValue);
  };

  const onReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (onClear) {
      onClear(name);
    }

    updateSearchQuery('');
    onDeselectAll(name);
    inputEl.current.value = '';
  };

  const onMenuClick = (e: any) => {
    // This is so we don't falsely unfocus
    if (e?.type === 'keydown' && e?.key !== 'return') {
      return;
    }

    if (menuOpen) {
      setMenuOpen(false);
    } else {
      if (inputEl) {
        inputEl?.current?.focus();
      }
      setIsLoading(true);

      if (!staticOptions && onSearch) {
        onSearch()
          .then((loadedOptions: Option[]) => {
            if (loadedOptions) {
              setOptions(loadedOptions);
            }
          })
          .finally(() => {
            setIsLoading(false);
            setMenuOpen(true);
          });
      } else {
        setIsLoading(false);
        setMenuOpen(true);
      }
    }
  };

  const onKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    if (key === 'Tab' && options) {
      onSelectOption(options[0].value);
    }
  };

  const onKeyDownControl = (e: KeyboardEvent<HTMLDivElement> | KeyboardEvent<HTMLButtonElement>) => {
    const { key } = e;

    if (key === 'Backspace' && name) {
      if (onClear) {
        onClear(name);
      }

      updateSearchQuery('');
    }
  };

  const onOptionPress = (option: Option) => {
    if (shouldCloseOnSelection) {
      setMenuOpen(false);
    }

    if (!optionValues.includes(option.value)) {
      onSelectOption(option.value);
    } else {
      onDeselect(name, option.value);
    }
  };

  const handleSearchQueryChange = debounce((e) => updateSearchQuery(e.target.value), 500);

  const renderOption = (option: Option, tabIndex: number) => {
    const selected = optionValues.includes(option.value);

    if (selected && !showSelectedOption) {
      return null;
    }

    return (
      <>
        <li
          role="option"
          tabIndex={tabIndex}
          aria-selected={selected}
          key={option.value}
          className={cx(
            'text-gray-900 hover:bg-surface-100 cursor-pointer select-none relative py-2 px-3',
            selected && 'bg-surface-100'
          )}
          onClick={() => onOptionPress(option)}
          onKeyDown={() => onOptionPress(option)}
        >
          <div className="flex">
            <span className="font-normal truncate">{option.label}</span>
            {selected && (
              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                <CheckIcon className="h-4 w-4 text-surface-900" aria-hidden="true" />
              </span>
            )}
          </div>
        </li>
        {option.subItems?.map((subItem) => {
          const selectedSubItem = optionValues.includes(subItem.value);
          const selectedParentItem = optionValues.includes(option.value);
          const selectedItem = selectedSubItem || selectedParentItem;

          return (
            <li
              role="option"
              tabIndex={tabIndex}
              aria-selected={selectedItem}
              key={subItem.value}
              className={cx(
                'text-gray-80 select-none relative py-2 pl-6 pr-6 hover:bg-surface-100',
                selectedParentItem && 'bg-surface-100 cursor-not-allowed',
                !selectedParentItem && 'cursor-pointer',
                selectedItem && 'bg-surface-100'
              )}
              onClick={() => !selectedParentItem && onOptionPress(subItem)}
              onKeyDown={() => !selectedParentItem && onOptionPress(subItem)}
              aria-disabled={selectedParentItem}
            >
              <div className="flex">
                <span className="font-normal truncate">{subItem.label}</span>
                {selectedItem && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-800 hover:text-gray-900">
                    <CheckIcon className="h-4 w-4 text-surface-900" aria-hidden="true" />
                  </span>
                )}
              </div>
            </li>
          );
        })}
      </>
    );
  };

  const renderOptions = () => {
    if (!options || options.length === 0) {
      return emptyLabel ? <div className="py-2 pl-3 pr-9 font-bold text-gray-400 text-xs">{emptyLabel}</div> : null;
    }

    return <ul role="listbox">{options.map((option, index) => renderOption(option, index))}</ul>;
  };

  return (
    <div className="h-fit flex flex-col gap-2 ">
      {labelText && (
        <div>
          <label htmlFor={name} className={cx('block text-sm font-medium', labelClassName || 'text-gray-700')}>
            {labelText}
            {required ? ' *' : ''}
          </label>
        </div>
      )}
      <div className={cx('relative w-full h-auto')} ref={dropdownEl}>
        {selectedPreview.length > 0 ? (
          <ul className="flex flex-row gap-1 pb-2 flex-wrap">
            {selectedPreview.map((value) => (
              <PreviewSelected
                key={value}
                badgeColor={badgeColor}
                onRemoveItem={() => {
                  if (!disabled) {
                    onDeselect(name, value);
                  }
                }}
              >
                {value}
              </PreviewSelected>
            ))}
          </ul>
        ) : null}

        <button
          type="button"
          ref={targetElement}
          tabIndex={0}
          onKeyDown={onKeyDownControl}
          className={cx(
            'flex items-stretch bg-white h-fit border border-surface-200 rounded-md shadow-sm text-left cursor-default relative text-base min-h-[35px]',
            disabled && 'bg-surface-100 cursor-not-allowed',
            !autoWidth && 'w-full',
            menuOpen && '!shadow-[0px_0px_0px_4px_#E5E7EB,0px_1px_2px_0px_rgba(0,0,0,0.05)]'
          )}
        >
          <div className={cx('w-full h-full', disabled && 'pointer-events-none opacity-60')}>
            <div role="button" className="h-full" tabIndex={0} onKeyDown={onKeyDownControl} onClick={onMenuClick}>
              <input
                type="text"
                ref={inputEl}
                name={name}
                className={cx(
                  'mt-0.5 bg-transparent w-full py-2 px-3 focus:outline-none focus:ring-0 appearance-none border-0 block placeholder-gray-400 h-full text-base',
                  disabled ? 'cursor-not-allowed' : 'cursor-pointer bg-none'
                )}
                placeholder={placeholderText || 'Search and select'}
                onChange={handleSearchQueryChange}
                onKeyDown={onKeyDownInput}
                onClick={onMenuClick}
              />

              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                {isLoading && (
                  <LoadingSpinner className={cx(values.length === 0 && 'mr-1', values.length > 0 && 'mr-2')} />
                )}
                {showClearAll && values.length > 0 && (
                  <button name="reset" type="button" onClick={onReset}>
                    <XMarkIcon className="h-4 w-4 text-surface-500 mr-1" aria-hidden="true" />
                  </button>
                )}
                <ChevronDownIcon className="h-5 w-5 text-surface-500" aria-hidden="true" />
              </span>
            </div>
          </div>
        </button>
        <Portal>
          <div ref={popperElRef} className={DROP_DOWN_Z_INDEX} style={styles.popper} {...attributes.popper}>
            <Transition
              show={menuOpen}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => {
                setPopperElement(null);
              }}
            >
              <div
                data-type="options"
                className={cx(
                  'absolute z-10 mt-1 py-2 w-full bg-white shadow-lg max-h-60 overflow-auto focus:outline-none text-base rounded-md',
                  dropdownClassnames
                )}
              >
                {isLoading && <div className="text-center py-4">Loading...</div>}
                {!isLoading && renderOptions()}
              </div>
            </Transition>
          </div>
        </Portal>
      </div>
      {(!menuOpen || (menuOpen && showValuesWhenMenuOpen)) && values.length > 0 && (
        <div className="flex flex-row flex-wrap gap-2 relative">
          {values.map((value) => {
            const currentOption = options?.find((option) => {
              const { value: optionValue } = option;
              if (typeof value === 'string') {
                return optionValue === value || option.subItems?.find((subItem) => subItem.value === value);
              }

              return option.value === value.value || option.subItems?.find((subItem) => subItem.value === value.value);
            });
            const currentSubItem = currentOption?.subItems?.find((subItem) =>
              typeof value === 'string' ? subItem.value === value : subItem.value === value.value
            );
            const foobar = typeof value === 'string' ? { label: value, subtitle: '' } : value;
            const { label, subtitle } = currentSubItem || currentOption || foobar;
            const optionValue = typeof value === 'string' ? value : value.value;

            return (
              (!currentSubItem || !optionValues.find((checkValue) => currentOption?.value === checkValue)) && (
                <span className="flex">
                  {values.length === 1 && (
                    <Typography token="font-normal/text/xs" colorWeight="500">
                      {currentOption?.onlySelectionHelpText}
                    </Typography>
                  )}
                  <Badge
                    key={optionValue}
                    size="md"
                    className={badgeClassName}
                    onDismiss={disabled ? undefined : () => onDeselect(name, optionValue)}
                    alwaysShowDismiss
                    dismissClassName={badgeLabelClassName}
                    type={badgeColor}
                  >
                    <div className={cx('flex-row items-center', badgeTextClassName)}>
                      <span className={cx('text-xs font-medium', ICON_COLORS[badgeColor], badgeLabelClassName)}>
                        {label}
                      </span>
                      {subtitle && (
                        <Typography
                          token="font-normal/text/xs"
                          colorWeight="500"
                          className={cx('ml-2', badgeSubtitleClassName)}
                        >
                          {subtitle}
                        </Typography>
                      )}
                    </div>
                  </Badge>
                </span>
              )
            );
          })}
        </div>
      )}

      {helperText && <p className="mt-2 text-xs text-gray-500">{helperText}</p>}
      {errorText && <p className="mt-2 text-xs text-feedback-danger-500">{errorText}</p>}
    </div>
  );
};

export default Multiselect;
