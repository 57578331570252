import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { ClockIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import moment from 'moment-mini';

import { Accordion } from '@/components/Accordion';
import ActionModal from '@/components/ActionModal';
import * as CardUI from '@/components/Card/CardUI';
import { OpensByHourChart } from '@/components/charts';
import LargeItemRadioGroup from '@/components/Form/LargeItemRadioGroup';
import { TitleSm, Typography, TypographyStack } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import usePostNodeContent from '@/hooks/usePostNodeContent';
import { Post, PostNodeContent, PostStatus } from '@/interfaces/post';
import Tooltip from '@/ui/Tooltip';
import getCurrentTimezone from '@/utils/getCurrentTimezone';

import { usePostContext } from '../PostContext';

interface Props {
  post: Post;
  selectedTime: string | null;
  setSelectedTime: (time: string | null) => void;
  onChange: (data: any) => void;
}

interface PostPublishedProps {
  post: Post;
  iconColor: string;
}

interface SchedulePostProps {
  post: Post;
  selectedTime: string | null;
  setSelectedTime: (data: any) => void;
  showScheduleModal: boolean;
  setShowScheduleModal: (open: boolean) => void;
  onChange: (data: any) => void;
}

const PostPublished = ({ post, iconColor }: PostPublishedProps) => {
  return (
    <CardUI.Card hasPadding={false}>
      <Accordion
        defaultOpen={false}
        className="p-6 cursor-default"
        onClick={() => {}}
        icon={<span />}
        titleChildren={
          <TypographyStack>
            <TitleSm>
              <span className="flex flex-row gap-3">
                <ClockIcon className={cx('h-6 w-6 flex-grow', iconColor)} />
                <div className="flex flex-row gap-1">
                  <Typography token="font-normal/text/base" colorWeight="500">
                    Published on
                  </Typography>
                  <Typography token="font-medium/text/base" colorWeight="900">
                    {moment(post.scheduled_at).format('MMMM Do YYYY, h:mma')}
                  </Typography>
                </div>
              </span>
            </TitleSm>
            <Typography className="w-full" colorWeight="500" weight="normal" size="sm" />
          </TypographyStack>
        }
      />
    </CardUI.Card>
  );
};

const PostScheduled = ({ post, iconColor }: PostPublishedProps) => {
  const { scheduled_at: scheduledAt } = post;
  const now = moment();

  const formatTimeUntil = useCallback(
    (isoDateString: string) => {
      const targetDate = moment(isoDateString);

      // Calculate the difference in milliseconds
      const diff = targetDate.diff(now);
      const duration = moment.duration(diff);

      // Calculate days, hours, and minutes
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();

      // Construct the result string
      let result = '';
      if (days > 0) result += `${days} days, `;
      if (hours > 0 || days > 0) result += `${hours} hours, `;
      result += `${minutes} minutes from now`;

      return result.startsWith('-') || result.startsWith('0 minutes') ? 'Now' : result;
    },
    [now]
  );

  const [countdown, setCountdown] = useState<string | null>(
    scheduledAt?.length > 0 ? formatTimeUntil(scheduledAt) : null
  );

  useEffect(() => {
    if (scheduledAt?.length > 0) {
      setCountdown(formatTimeUntil(scheduledAt));
    }
  }, [scheduledAt, now, formatTimeUntil]);

  return (
    <CardUI.Card hasPadding={false}>
      <Accordion
        defaultOpen={false}
        className="p-6 cursor-default"
        onClick={() => {}}
        icon={<span />}
        titleChildren={
          <TypographyStack>
            <TitleSm>
              <span className="flex flex-row gap-3">
                <ClockIcon className={cx('h-6 w-6 flex-grow', iconColor)} />
                <div className="flex flex-col gap-2">
                  <Typography token="font-normal/text/base" colorWeight="500">
                    Scheduled to{' '}
                    <Typography token="font-medium/text/base" colorWeight="900">
                      Publish
                    </Typography>{' '}
                    on{' '}
                    <Typography token="font-medium/text/base" colorWeight="900">
                      {moment(post.scheduled_at).format('MMMM Do YYYY, h:mma')} {getCurrentTimezone(post.scheduled_at)}
                    </Typography>
                  </Typography>
                  {countdown && countdown.length > 0 && (
                    <Typography token="font-normal/text/sm" colorWeight="500">
                      {countdown}
                    </Typography>
                  )}
                </div>
              </span>
            </TitleSm>
          </TypographyStack>
        }
      />
    </CardUI.Card>
  );
};

const SchedulePost = ({
  post,
  setSelectedTime,
  selectedTime,
  showScheduleModal,
  setShowScheduleModal,
  onChange,
}: SchedulePostProps) => {
  const [actionText, setActionText] = useState('Publish');
  const [dateInputType, setDateInputType] = useState('datetime-local');
  const { settings } = useSettings();
  const { publishPost } = usePostContext();
  const { scheduled_at: scheduledAt } = post;
  const now = moment();

  const formatTimeUntil = useCallback(
    (isoDateString: string) => {
      const targetDate = moment(isoDateString);

      // Calculate the difference in milliseconds
      const diff = targetDate.diff(now);
      const duration = moment.duration(diff);

      // Calculate days, hours, and minutes
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();

      // Construct the result string
      let result = '';
      if (days > 0) result += `${days} days, `;
      if (hours > 0 || days > 0) result += `${hours} hours, `;
      result += `${minutes} minutes from now`;

      return result.startsWith('-') || result.startsWith('0 minutes') ? 'Now' : result;
    },
    [now]
  );

  const [countdown, setCountdown] = useState<string | null>(
    scheduledAt?.length > 0 ? formatTimeUntil(scheduledAt) : null
  );

  const { data: postNodeData } = usePostNodeContent({ id: post.id });
  const postNodeContent = postNodeData || ({} as PostNodeContent);
  const minBoostSendBy = postNodeContent?.boost?.map((boost) => boost?.send_by).sort()[0];

  const schedulePostOption = useMemo(
    () => ({
      name: 'Schedule for later',
      value: 'later',
    }),
    []
  );

  const publishNowOption = useMemo(
    () => ({
      name: 'Publish now',
      value: 'now',
    }),
    []
  );

  const scheduledInThePast = moment(scheduledAt).isBefore(moment());
  const [selectedOption, setSelectedOption] = useState(scheduledInThePast ? publishNowOption : schedulePostOption);

  const onClickRecommended = (time: string) => {
    toast.success(`Date set to ${moment(time).calendar()}`);

    onChange({ scheduled_at: moment(time).toISOString() });
    setSelectedOption(schedulePostOption);
    setSelectedTime(time);
  };

  useEffect(() => {
    if (scheduledAt?.length > 0) {
      setCountdown(formatTimeUntil(scheduledAt));
    }
  }, [scheduledAt, now, formatTimeUntil]);

  useEffect(() => {
    if (selectedTime && moment(selectedTime).isBefore(moment())) {
      setActionText('Publish Now');
    }

    if (selectedTime && moment(selectedTime).isAfter(moment())) {
      setActionText(
        `Publish on ${moment(selectedTime).format('MMMM Do YYYY, h:mma')} ${getCurrentTimezone(selectedTime)}`
      );
    }
  }, [selectedTime]);

  const options = [schedulePostOption, publishNowOption];

  const handleOptionSelected = (option: any) => {
    if (option.value === 'now') {
      onChange({ scheduled_at: moment().toISOString() });
    }
    setSelectedOption(option);
  };

  return (
    <ActionModal
      buttonType="primary"
      buttonShade="dark"
      headerText="Schedule"
      headerTextClassName="font-medium text-base"
      isOpen={showScheduleModal}
      onClose={() => setShowScheduleModal(false)}
      isWorking={false}
      onProceed={() => publishPost?.mutate()}
      disabled={!selectedTime}
      actionText={actionText}
      resourceId={post.id}
      modalSize="lg"
    >
      <div className="flex flex-col gap-6 mt-6">
        {settings?.recommended_sending_time && <OpensByHourChart onClickRecommended={onClickRecommended} />}
        <LargeItemRadioGroup
          justifyItems="start"
          options={options}
          selected={selectedOption}
          onSelected={handleOptionSelected}
          variant="secondary"
        />
        {selectedOption.value === 'later' && (
          <>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                <input
                  id="scheduled_at"
                  type={dateInputType}
                  className={cx('rounded border-gray-200 text-gray-600 w-full mt-1')}
                  min={moment().format('YYYY-MM-DDThh:mm')}
                  max={minBoostSendBy ? moment(minBoostSendBy).format('YYYY-MM-DDThh:mm') : ''}
                  value={selectedTime ? moment(selectedTime).format('YYYY-MM-DDTHH:mm') : undefined}
                  onFocus={() => setDateInputType('datetime-local')}
                  placeholder="mm/dd/yyyy 00:00 am"
                  onChange={(e) => {
                    if (!e.target.value) return;
                    let formattedTime = moment(e.target.value).toISOString();
                    const newDateBeforeNow = moment(formattedTime).isBefore(moment());

                    if (newDateBeforeNow) {
                      formattedTime = moment().toISOString();
                    }

                    onChange({ scheduled_at: formattedTime });
                    setSelectedTime(formattedTime);
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setDateInputType('text');
                      return;
                    }
                    let formattedTime = moment(e.target.value).toISOString();
                    const newDateBeforeNow = moment(formattedTime).isBefore(moment());

                    if (newDateBeforeNow) {
                      formattedTime = moment().toISOString();
                    }

                    onChange({ scheduled_at: formattedTime });
                    setSelectedTime(formattedTime);
                  }}
                  required
                />
                <Tooltip
                  id="scheduled_at"
                  text="Audience may change if new subscribers join or segments are recalculated before this time"
                />
              </div>
              <Typography token="font-light/text/sm">
                {
                  new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
                    .formatToParts(new Date())
                    .find((part) => part.type === 'timeZoneName')?.value
                }{' '}
                ({getCurrentTimezone(new Date().toISOString())})
              </Typography>
            </div>
            {countdown && countdown.length > 0 && (
              <div className="relative z-0 border rounded-lg border-surface-100 bg-surface-50 w-full">
                <div className="mx-auto my-6 w-full text-center font-medium text-sm">{countdown}</div>
              </div>
            )}
          </>
        )}
      </div>
    </ActionModal>
  );
};

const ScheduleSettings = ({ post, setSelectedTime, selectedTime, onChange }: Props) => {
  const { showScheduleModal, setShowScheduleModal } = usePostContext();
  const [postSent, setPostSent] = useState(false);
  const [postScheduled, setPostScheduled] = useState(false);
  const [iconColor, setIconColor] = useState('text-action-secondary-600');

  useEffect(() => {
    if (post.status === PostStatus.PUBLISHED || post.status === PostStatus.ARCHIVED) {
      setIconColor('text-feedback-success-500');
      setPostSent(true);
    } else if (post.status === PostStatus.DRAFT) {
      setIconColor('text-action-secondary-600');
    } else {
      setPostScheduled(true);
      setIconColor('text-feedback-info-500');
    }
  }, [post.status]);

  return !postSent && !postScheduled ? (
    <SchedulePost
      post={post}
      selectedTime={selectedTime}
      setSelectedTime={setSelectedTime}
      showScheduleModal={showScheduleModal}
      setShowScheduleModal={setShowScheduleModal}
      onChange={onChange}
    />
  ) : (
    <div className="w-2/3 mx-auto max-w-[40rem]">
      {postScheduled && <PostScheduled post={post} iconColor={iconColor} />}
      {postSent && <PostPublished post={post} iconColor={iconColor} />}
    </div>
  );
};

export default ScheduleSettings;
