import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Link as LinkIcon } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import useResolvedRoutes from '@/routes/website/_hooks/useResolvedRoutes';

import { Button } from '../../../UI/Button';
import { SimpleInput, SimpleInputWrapper } from '../../../UI/Input';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { PopoverHelper, PopoverItem, usePopoverContext } from '../helpers/PopoverHelper';
import { AttributeSettingProps } from '../types';
import { isInternalLink } from '../utils/isInternalLink';

export interface ComboboxOption {
  id: number | string;
  name: string;
  LeftIcon?: React.ReactNode;
}

const validateUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);

    if (process.env.NODE_ENV !== 'development') {
      return parsedUrl.protocol === 'https:';
    }

    return true;
  } catch (error) {
    toast.error('Invalid URL');
    return false;
  }
};

const ButtonHelper = ({ onApply }: { onApply: () => void }) => {
  const { setOpen } = usePopoverContext();

  return (
    <Button
      variant="outlined"
      size="sm"
      onClick={() => {
        onApply();
        setOpen(false);
      }}
    >
      Apply
    </Button>
  );
};

export const LinkToSettingsBlock = ({
  editor,
  activeNodeResult,
  property = 'url',
}: AttributeSettingProps & { property?: string }) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;

  const routes = useResolvedRoutes();
  const { data: currentPublication } = useCurrentPublication();

  const [currentURL, setCurrentURL] = useState('');
  const [userFriendlyName, setUserFriendlyName] = useState('');
  const [tabsToggle, setTabsToggle] = useState<'routes' | 'custom'>('routes');

  const currentValue = activeNodeAttributes?.[property];

  useEffect(() => {
    if (isInternalLink(currentPublication?.url || '', currentValue)) {

      try {
        const url = new URL(currentValue);
        const slug = url.pathname;
        const route = routes.find((r: any) => `/${r.slug}` === slug);
        setUserFriendlyName(route?.name || '');
        setTabsToggle('routes');
      } catch (error) {
        setUserFriendlyName('');
        setCurrentURL(currentValue);
        setTabsToggle('custom');
      }

    } else {
      setTabsToggle('custom');
      setUserFriendlyName('');
      setCurrentURL(currentValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPublication?.url, routes, currentValue]);

  const handleSetLink = useCallback(
    (url: string, isInternal: boolean) => {
      if (!validateUrl(url)) return;

      if (!isInternal) {
        setUserFriendlyName('');

        // This lets us know in the client if we want to handle a client side navigation
        // Sometimes custom links can also be internal links so we need to do an extra check here.
        if (!isInternalLink(currentPublication?.url || '', url)) {
          editor.commands.command(({ tr }) => {
            tr.setNodeAttribute(activeNodePos, 'isCustomLink', true);
            return true;
          });
        }
      }

      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, url);
        tr.setNodeAttribute(activeNodePos, 'action', undefined);
        return true;
      });
    },
    [editor, activeNodePos, property]
  );

  const filteredRoutes = useMemo(() => {
    return routes.filter((r: any) => !r.slug.includes('$'));
  }, [routes]);

  return (
    <PopoverHelper title="Link" text={userFriendlyName || currentValue} align="end" popoverTitle="Link" Icon={LinkIcon}>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="routes"
          value={tabsToggle}
          onValueChange={(value: 'routes' | 'custom') => setTabsToggle(value)}
        >
          <ToggleGroupItem value="routes" className="grow">
            Pages
          </ToggleGroupItem>
          <ToggleGroupItem value="custom" className="grow">
            Custom
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
      <div className="flex flex-col gap-2 max-h-[200px] mt-2 pb-4">
        {tabsToggle === 'custom' && (
          <div className="flex flex-col gap-2">
            <SimpleInputWrapper>
              <SimpleInput
                type="text"
                value={currentURL}
                onChange={(e) => setCurrentURL(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSetLink(currentURL, false);
                  }
                }}
              />
            </SimpleInputWrapper>
            <ButtonHelper onApply={() => handleSetLink(currentURL, false)} />
          </div>
        )}
        {tabsToggle === 'routes' && (
          <>
            {filteredRoutes.map((route: any) => {
              return (
                <PopoverItem
                  key={route.id}
                  isSelected={route.name === userFriendlyName}
                  value={route.name}
                  onSelect={() => {
                    const settableUrl = `${currentPublication?.url}${route.fullSlug}`;
                    setUserFriendlyName(route.name);
                    handleSetLink(settableUrl, true);
                  }}
                  subtitle={`/${route.fullSlug}`}
                />
              );
            })}
          </>
        )}
      </div>
    </PopoverHelper>
  );
};
