import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  isSecondary?: boolean;
  isSticky?: boolean;
}>;

export const ItemHeaders = ({ children, isSecondary = false, isSticky = false }: Props) => {
  return (
    <thead
      className={cx(
        isSecondary && 'bg-surface-50',
        isSecondary && 'border-b',
        isSecondary && 'border-surface-200',
        !isSecondary && 'bg-surface-100',
        isSticky && 'sticky z-10',
        isSticky && '-top-6'
      )}
    >
      <tr>{children}</tr>
    </thead>
  );
};
