import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import { WebhookEndpoint } from '@/interfaces/webhooks/endpoint';
import { StepErrors } from '@/pages/Settings/Pages/Integrations/Webhooks/Endpoints/Create/Steps/types';
import { Button } from '@/ui/Button';
import { FormStep } from '@/ui/FormStep';
import validateUrl from '@/utils/validateUrl';

import TwoColumnPageContainer from '../../../_components/TwoColumnPageContainer';
import { usePublicationSettings } from '../../context';

import DetailsForm from './DetailsForm';
import EventTypesForm from './EventTypesForm';
import Summary from './Summary';

enum FormSteps {
  DETAILS = 1,
  EVENT_TYPES = 2,
}

interface Props {
  endpoint?: WebhookEndpoint;
  onSave: (endpoint: Partial<WebhookEndpoint>) => Promise<any>;
  isSaving: boolean;
}

const EndpointForm = ({ endpoint, onSave, isSaving }: Props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [stepErrors, setStepErrors] = useState<StepErrors>({});
  const [endpointInState, setEndpointInState] = useState<Partial<WebhookEndpoint>>({
    id: endpoint?.id || '',
    description: endpoint?.description || '',
    url: endpoint?.url || '',
    event_types: endpoint?.event_types || [],
    disabled: endpoint?.disabled || false,
  });
  const navigate = useNavigate();
  const { currentPublicationId } = usePublicationSettings();

  const getStepErrors = (step: number): StepErrors | null => {
    switch (step) {
      case 1:
        if (!validateUrl(endpointInState?.url || '')) {
          return { url: 'Please enter a valid URL' };
        }
        break;
      case 2:
        if (!endpointInState.event_types?.length) {
          return { event_types: 'Please select at least one event type' };
        }
        break;
      default:
        return null;
    }

    return null;
  };

  const handleSave = () => {
    const errors = getStepErrors(currentStep);
    if (errors) {
      setStepErrors(errors);
    } else {
      onSave(endpointInState);
    }
  };

  const handleCancel = () => navigate('/settings/publication/webhooks');

  const handleClickPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleClickNextStep = () => {
    const errors = getStepErrors(currentStep);
    if (errors) {
      setStepErrors(errors);
    } else {
      setStepErrors({});
      setCurrentStep(currentStep + 1);
    }
  };

  const { data: publication, isSuccess, isError } = usePublication(currentPublicationId);

  return (
    <LoadingBox isLoading={!isSuccess} isError={isError}>
      {publication ? (
        <TwoColumnPageContainer key={currentPublicationId} rhsColumn={<Summary endpoint={endpointInState} />}>
          <FormStep
            isOpen={currentStep === FormSteps.DETAILS}
            stepTitle="Webhook Endpoint Details"
            stepDescription="The webhook endpoint URL is the specific address where your application receives data sent by the webhook when triggered by selected events"
            footer={
              <>
                <Button type="button" variant="primary-inverse" onClick={handleCancel} size="sm">
                  Cancel
                </Button>
                <Button type="button" variant="primary" onClick={handleClickNextStep} size="sm">
                  Continue
                </Button>
              </>
            }
          >
            <DetailsForm
              endpoint={endpointInState}
              errors={stepErrors}
              onChange={(payload) => setEndpointInState(payload)}
            />
          </FormStep>
          <FormStep
            isOpen={currentStep === FormSteps.EVENT_TYPES}
            stepTitle="Event Types"
            stepDescription="Webhook event types refer to the specific actions that trigger the webhook to send date to your endpoint url"
            footer={
              <>
                <Button
                  type="button"
                  variant="primary-inverse"
                  onClick={handleClickPreviousStep}
                  size="sm"
                  disabled={isSaving}
                >
                  Back
                </Button>
                <Button type="button" variant="primary" onClick={handleSave} disabled={isSaving} size="sm">
                  {endpointInState.id ? 'Update' : 'Create'} Webhook
                </Button>
              </>
            }
          >
            <EventTypesForm
              endpoint={endpointInState}
              errors={stepErrors}
              onChange={(payload) => setEndpointInState(payload)}
            />
          </FormStep>
        </TwoColumnPageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default EndpointForm;
