import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useExternalRssFeedCreate } from '@/hooks/useExternalRssFeeds';
import { ExternalRssFeed } from '@/interfaces/external_rss_feed';

import { usePublicationSettings } from '../../context';
import ExternalRssFeedForm from '../components/ExternalRssFeedForm';

const NewExternalRssFeed = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentPublicationId } = usePublicationSettings();

  const { mutateAsync: createExternalRssFeed, isLoading } = useExternalRssFeedCreate({
    publicationId: currentPublicationId,
    onSuccess: () => {
      toast.success('RSS feed created successfully');
      queryClient.invalidateQueries([currentPublicationId, 'external_rss_feeds']);
      navigate('/settings/publication/rss');
    },
  });

  const handleSave = (payload: ExternalRssFeed) => createExternalRssFeed(payload);

  return <ExternalRssFeedForm onSave={handleSave} isSaving={isLoading} />;
};

export default NewExternalRssFeed;
