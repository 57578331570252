import { PostElement, TPostAttributes } from '@shared/dream-components';

import PostsSelectionModal from '../../../../extensions/Post/modal/PostsSelectionModal';
import { AttributeSettingProps } from '../../../types';
import { PostsSelectionSettings } from '../../PostsSettings/PostsSelectionSettings';
import {
  ImageLeft,
  ImageLeftCenter,
  ImageLeftFlex,
  ImageLeftFlexBetween,
  ImageRight,
  ImageRightCenter,
  ImageRightFlex,
  ImageRightFlexBetween,
  ImageTop,
} from '../../PostsSettings/postStructurePatterns';
import { StructureSettings } from '../../StructureSettings';
import ActionRenderer from '../ActionRenderer';

const PostActions = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNode, activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult;
  const { insertedFromSidebar } = activeNodeAttributes;

  const handleCloseModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', false);
      return true;
    });
  };

  const handleOpenModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', true);
      return true;
    });
  };

  return (
    <ActionRenderer nodeType="post" activeNodeType={activeNodeType}>
      <StructureSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        allowedProperties={[
          'div',
          'image',
          'tags',
          'title',
          'subtitle',
          'authors',
          'timestamp',
          'premium',
          'line-separator',
          'dot-separator',
          'dash-separator',
          'slash-separator',
        ]}
        predefinedPatterns={[
          ImageTop,
          ImageLeft,
          ImageLeftCenter,
          ImageLeftFlex,
          ImageLeftFlexBetween,
          ImageRight,
          ImageRightCenter,
          ImageRightFlex,
          ImageRightFlexBetween,
        ]}
        onUpdate={(pattern) => {
          editor.commands.command(({ tr }) => {
            if (pattern?.includes('Flex')) {
              // Grids will conform the image size to the width of the grid
              // For flex we want to help the user and set reasonable values for the image so it doesn't take up too much space
              // The user can then update these values to be more appropriate for their design
              tr.setNodeAttribute(activeNodePos, 'imageSizeFixed', true);
              tr.setNodeAttribute(activeNodePos, 'imageHeight', '100px');
              tr.setNodeAttribute(activeNodePos, 'imageWidth', '100px');
            } else {
              tr.setNodeAttribute(activeNodePos, 'imageSizeFixed', false);
              tr.setNodeAttribute(activeNodePos, 'imageHeight', 'auto');
              tr.setNodeAttribute(activeNodePos, 'imageWidth', '100%');
            }
            return true;
          });
        }}
      >
        {(structure: any) => (
          <PostElement
            element={{
              type: 'post',
              attrs: {
                ...(activeNode?.attrs as TPostAttributes),
                cardStructure: structure as any,
                columns: '1',
                data: {
                  // We only need one post to display the structure
                  posts: activeNode?.attrs?.data?.posts?.slice(0, 1) || [],
                },
              },
            }}
          >
            {null}
          </PostElement>
        )}
      </StructureSettings>
      <PostsSelectionSettings editor={editor} activeNodeResult={activeNodeResult} handleOpenModal={handleOpenModal} />
      {insertedFromSidebar && activeNode && (
        <PostsSelectionModal
          pos={activeNodePos}
          editor={editor}
          isOpen={Boolean(insertedFromSidebar)}
          onClose={handleCloseModal}
          node={activeNode}
        />
      )}
    </ActionRenderer>
  );
};

export default PostActions;
