import { useMemo } from 'react';

import { useWebsiteContext } from '@/context/website-context';
import { useCreatePage } from '@/hooks/usePages';
import { TemplateCategory } from '@/interfaces/dream_builder/site';

import { SLUG_ORDER } from '../ContentTree/constants';

import AddablePageHelper from './AddablePageHelper';

const AddableDefaultPages = () => {
  const { site, defaultRoutes } = useWebsiteContext();

  const createPage = useCreatePage({
    routesType: 'default',
  });

  const createPageMutation = (parentPath: string[], slug: string, name: string) => {
    createPage.mutate({ parentPath, slug, name });
  };

  const pageCategories = site?.template_categories?.page;

  const renderablePageCategories = useMemo(() => {
    const filtered = pageCategories?.filter(
      (category) => !defaultRoutes?.children_keys?.includes(category?.slug || '')
    );
    return filtered?.sort((a, b) => {
      const aIndex = SLUG_ORDER.indexOf(a.slug || '');
      const bIndex = SLUG_ORDER.indexOf(b.slug || '');
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  }, [pageCategories, defaultRoutes]);

  return (
    <div className="flex flex-col">
      {renderablePageCategories?.map((category: TemplateCategory) => {
        return (
          <AddablePageHelper
            key={category.value}
            text={category.label}
            tooltipText="Create Default Page"
            onClick={() => createPageMutation([], category?.slug || '', category?.label || 'Untitled')}
          />
        );
      })}
      <AddablePageHelper
        isUnderDevelopment
        text="Post Page"
        tooltipText="In Development - Coming Soon"
        onClick={() => {}}
      />
    </div>
  );
};

export default AddableDefaultPages;
