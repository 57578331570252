import { PropsWithChildren } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import ProgressBar from '@/components/ProgressBar';
import { Typography, TypographyStack } from '@/components/Typography';
import { Button } from '@/ui/Button';

import BlackAndWhiteLogo from './BlackAndWhiteLogo';

const BEA_LOGO = 'https://beehiiv-images-production.s3.us-east-1.amazonaws.com/bea.png';

type Props = PropsWithChildren<{
  title?: string | JSX.Element;
  superTitle?: string;
  subtitle?: string;
  onBack?: () => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  onSkip?: () => void;
  isSubmitDisabled?: boolean;
  isProcessing?: boolean;
  buttonsClassName?: string;
  className?: string;
  centered?: boolean;
  ctaVariant?:
    | 'primary'
    | 'primary-inverse'
    | 'secondary'
    | 'tertiary'
    | 'flush'
    | 'danger'
    | 'success'
    | 'info'
    | 'warning';
  ctaText?: string;
  ctaClassName?: string;
  currentProgress?: number;
  showBea?: boolean;
  formClassName?: string;
  disableSkip?: boolean;
}>;

const OnboardingStepForm = ({
  title,
  subtitle,
  superTitle,
  onBack,
  onSubmit,
  onSkip,
  children,
  isSubmitDisabled,
  isProcessing,
  buttonsClassName,
  className = 'max-w-2xl',
  centered = false,
  ctaVariant = 'primary',
  ctaText = 'Continue',
  ctaClassName,
  currentProgress,
  showBea = false,
  formClassName,
  disableSkip = false,
}: Props) => {
  return (
    <div className={cx('flex flex-col w-full gap-4', className)}>
      <div className="flex flex-col items-center">
        <BlackAndWhiteLogo size="md" />
      </div>
      <div className={cx('flex flex-col', centered ? 'items-center' : 'items-start')}>
        {currentProgress && (
          <ProgressBar
            value={currentProgress}
            total={100}
            className="w-full pr-2 !bg-pink-600"
            showLabel={false}
            bordered={false}
            fullWidth
          />
        )}
      </div>

      <form className={cx('space-y-8 flex flex-col w-full h-fit', formClassName)} onSubmit={onSubmit}>
        <div className="text-center flex flex-row gap-6">
          {showBea && (
            <div className="flex justify-center">
              <img src={BEA_LOGO} alt="BEA" className="w-24 h-24 min-w-24 rounded-md" />
            </div>
          )}
          <TypographyStack gap="1">
            {superTitle && (
              <Typography
                weight="normal"
                size="base"
                as="p"
                colorWeight="700"
                className={cx(centered ? 'text-center' : 'text-left')}
              >
                {superTitle}
              </Typography>
            )}
            {title && (
              <Typography
                colorWeight="700"
                weight="bold"
                size="lg"
                as="h4"
                className={cx(centered ? 'text-center' : 'text-left')}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography
                weight="normal"
                size="sm"
                as="p"
                colorWeight="700"
                className={cx(centered ? 'text-center' : 'text-left')}
              >
                {subtitle}
              </Typography>
            )}
          </TypographyStack>
        </div>
        {children}

        <div className={cx('flex w-full justify-between items-center', buttonsClassName)}>
          {onBack ? (
            <Button type="button" onClick={onBack} variant="flush" size="sm" Icon={ArrowLeftIcon}>
              Back
            </Button>
          ) : (
            <div />
          )}

          <div className="flex flex-row gap-4">
            {onSkip && (
              <Button
                className="rounded"
                type="button"
                variant="primary-inverse"
                onClick={onSkip}
                disabled={disableSkip}
                size="sm"
              >
                Skip
              </Button>
            )}
            {onSubmit && (
              <Button
                type="submit"
                className={cx(ctaClassName)}
                variant={ctaVariant}
                disabled={isSubmitDisabled}
                loading={isProcessing}
                size="sm"
                Icon={ArrowRightIcon}
                iconRight
              >
                {ctaText}
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default OnboardingStepForm;
