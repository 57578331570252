import { BURGER_DEFAULTS } from '@shared/dream-components';

import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';
import { getParent } from '../../../utils';
import { ColorSettings } from '../general/ColorSettings';

import { BurgerAlignmentSettings } from './BurgerAlignmentSettings';

export function NavMenuMobileSettings() {
  const { content, selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const rootMenuContent =
    selectedContent?.type === 'navbar_menu_list' && content ? getParent(content, selectedContent) : selectedContent;

  if (!rootMenuContent) return null;

  return (
    <SectionRenderer title="Mobile">
      <BurgerAlignmentSettings selectedContent={rootMenuContent} />
      <ColorSettings
        title="Menu Color"
        property="burgerColor"
        selectedContent={rootMenuContent}
        defaultValue={BURGER_DEFAULTS.color}
      />
      <ColorSettings
        title="Dropdown Background"
        property="burgerDropdownBackground"
        selectedContent={rootMenuContent}
        defaultValue="#FFFFFF"
      />
    </SectionRenderer>
  );
}
