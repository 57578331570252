import { memo, useCallback } from 'react';
import { sticky } from 'tippy.js';

import { useEditorStateNonBlocking } from '@/components/TiptapEditor/lib/hooks/useEditorStateNonBlocking';

import { BubbleMenu as BaseBubbleMenu } from '../../../components/menus/BubbleMenu';
import { MenuProps } from '../../../components/menus/types';
import { getRenderContainer } from '../../../components/menus/utils/getRenderContainer';
import { Button } from '../../../components/ui/Button';
import { Icon } from '../../../components/ui/Icon';
import { Divider, Toolbar } from '../../../components/ui/Toolbar';
import { Tooltip } from '../../../components/ui/Tooltip';
import { ListType } from '../TableOfContents';

// TODO: Keeping this here for future options to be added
// import { TableOfContentsOptions } from './TableOfContentsOptions';

const buttonProps = {
  $variant: 'quaternary',
  $size: 'small',
  $isIconButton: true,
};

export const TableOfContentsMenu = memo(({ editor, appendTo }: MenuProps) => {
  const { isTocUnordered, isTocOrdered } = useEditorStateNonBlocking({
    editor,
    selector: () => {
      const { listType } = editor.getAttributes('tableOfContents') || {};

      return {
        isTocUnordered: listType === ListType.Unordered,
        isTocOrdered: listType === ListType.Ordered,
      };
    },
  });

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-tableOfContents');

    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    return editor.isActive('tableOfContents');
  }, [editor]);

  const removeNode = useCallback(() => {
    editor.chain().focus().deleteNode('tableOfContents').deleteSelection().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableOfContentsMenu"
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => appendTo?.current,
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Toolbar>
        <Tooltip title="Bulleted list">
          <Button
            $leftSlot={<Icon name="BulletList" />}
            onClick={() => {
              editor.chain().focus().setListType(ListType.Unordered).run();
            }}
            $active={isTocUnordered}
            {...buttonProps}
          />
        </Tooltip>
        <Tooltip title="Numbered list">
          <Button
            $leftSlot={<Icon name="OrderedList" />}
            onClick={() => {
              editor.chain().focus().setListType(ListType.Ordered).run();
            }}
            $active={isTocOrdered}
            {...buttonProps}
          />
        </Tooltip>
        <Divider />
        {/* <TableOfContentsOptions editor={editor} isDisabled={false} /> */}
        <Tooltip title="Remove Table of Contents">
          <Button $leftSlot={<Icon name="Trash" />} onClick={removeNode} {...buttonProps} />
        </Tooltip>
      </Toolbar>
    </BaseBubbleMenu>
  );
});

export default TableOfContentsMenu;
