import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { AdNetworkCampaign } from '../../../interfaces/ad_network/internal/campaign';
import api from '../../../services/swarm';

interface SuccessResponse extends AdNetworkCampaign {}
interface ErrorResponse {
  response: {
    data: {
      errors: {
        [key: string]: string[];
      };
    };
  };
}

export default function useAdvertiserCampaignDuplicate({ advertiser_id, campaign_id }: { advertiser_id: string, campaign_id: string }) {
  const navigate = useNavigate();

  return useMutation<SuccessResponse, ErrorResponse>(
    () => api.post(`/ad_network/internal/advertisers/${advertiser_id}/campaigns/${campaign_id}/duplicate`).then((res) => res.data),
    {
      onSuccess: ({ id: campaignId }) => {
        toast.success('Campaign duplicated successfully');

        navigate(`/ad_network/campaigns/${campaignId}`);
      },
      onError: (error) => {
        const { errors } = error.response.data;

        if (errors) {
          Object.keys(errors).forEach((key) => {
            toast.error(`${key} ${errors[key][0]}`);
          });
        } else {
          toast.error('Something went wrong');
        }
      },
    }
  );
}
