import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Plus } from '@phosphor-icons/react';
import { NavbarSerializableNode, NavbarSerializer } from '@shared/dream-components';

import useSitePackage from '@/hooks/useSitePackages/useSitePackage';
import { useSiteTemplate } from '@/hooks/useSiteTemplates';
import { useAttributesPanelNavbarFooterContext } from '@/routes/website/_components/DreamEditor/AttributesPanel/navbarFooterContextProvider';
import { Text } from '@/routes/website/_components/UI/Text';
import { useClickOutside } from '@/routes/website/_utils/useClickOutside';
import { cn } from '@/utils/cn';

import { useIsMobile } from './utils';

export const NavbarTemplatePreview = ({
  portalRef,
  iframeRef,
}: {
  portalRef: React.RefObject<HTMLDivElement>;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}) => {
  const [selected, setSelected] = useState(false);
  const { templateId } = useParams();
  const { data: template } = useSiteTemplate({ siteTemplateId: templateId as string });
  const sitePackageId = template?.site_package_id;
  const { data: sitePackage } = useSitePackage({ sitePackageId: sitePackageId as string });
  const content = sitePackage?.navbar as NavbarSerializableNode;
  const isNavbarVisible = !!content;
  const isMobile = useIsMobile(iframeRef);

  const navigate = useNavigate();

  const { setIsNavbarSelected } = useAttributesPanelNavbarFooterContext() || {};

  useEffect(() => {
    setIsNavbarSelected?.(selected);
  }, [selected, setIsNavbarSelected]);

  const onDoubleClick = () => {
    navigate(`/website_builder_v2/navbar_template/${sitePackageId}`);
  };

  const onClickOutside = (): void => {
    setSelected(false);
  };

  const ref = useClickOutside(iframeRef, onClickOutside, ['navbar-settings']);

  if (!content || !('content' in content) || content.content?.length === 0)
    return (
      <div
        className={cn(
          'group w-full h-[60px] flex items-center justify-center gap-2 border border-solid border-inset-1',
          selected ? 'border-[#7C3AED]' : 'hover:border-neutral-200 border-transparent',
          !isNavbarVisible ? 'opacity-50' : 'opacity-100'
        )}
        onClick={() => setSelected(true)}
        role="button"
        tabIndex={0}
        onDoubleClick={onDoubleClick}
        ref={ref}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setSelected(true);
          }
        }}
      >
        <Plus size={16} className="text-neutral-400 group-hover:text-[#7C3AED]" />
        <Text size="sm" variant="secondary" className="text-neutral-400 group-hover:text-[#7C3AED]">
          Edit Navbar
        </Text>
      </div>
    );

  return (
    <div className="h-fit w-full flex flex-col justify-start items-center" ref={ref}>
      <div
        className={cn(
          'w-full flex justify-center items-center border border-solid border-inset-1',
          selected ? 'border-[#7C3AED]' : 'border-transparent hover:border-neutral-200',
          !isNavbarVisible ? 'opacity-50' : 'opacity-100'
        )}
        onClick={() => {
          setSelected(true);
        }}
        role="button"
        tabIndex={0}
        onDoubleClick={onDoubleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setSelected(true);
          }
        }}
      >
        <NavbarSerializer node={content} portalRef={portalRef} screen={isMobile ? 'mobile' : 'desktop'} />
      </div>
    </div>
  );
};
