import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Badge } from '@tremor/react';
import cx from 'classnames';

import { SortableList, SortableListItem, useSortableList } from '@/components/SortableList';
import { PageRouteGetter, useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';
import { GlobalElement } from '@/interfaces/dream_builder/global_element';
import { SignupFlow } from '@/interfaces/signup_flow';

import { Button } from '../../../_components/UI/Button';
import { Input } from '../../../_components/UI/Input';
import InputWrapper from '../../../_components/UI/InputWrapper';
import Modal from '../../../_components/UI/Modal';
import { Text } from '../../../_components/UI/Text';
import isDynamicResourcePage from '../utils/isDynamicResourcePage';

import ConfigureRoute from './ConfigureRoute';
import RoutesComboBox from './RoutesComboBox';

interface Props {
  signupFlow?: SignupFlow | null;
  isSubmitting: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (payload: { name: string; urls: string[] }) => void;
  publicationUrl: string;
}

type UrlItem = {
  id: string;
  value: string;
};

const SignupFlowModal = ({ signupFlow, onClose, onSubmit, isSubmitting, isOpen, publicationUrl }: Props) => {
  const pageRouteGetter = useWebsitePageRouteGetter();
  const [name, setName] = useState(signupFlow?.name || '');
  const [newUrl, setNewUrl] = useState('');
  const [urls, setUrls] = useState(signupFlow?.steps.map((step) => step.url) || []);
  const [mounted, setMounted] = useState(false);
  const [configurableUrl, setConfigurableUrl] = useState<string | undefined>();

  const { globalElements } = useWebsiteContext();
  const globalElementsBySearchParam: Record<string, GlobalElement> = useMemo(
    () =>
      globalElements.reduce((pv, cv) => {
        if (!cv.orchidSearchParam) {
          return pv;
        }

        return {
          ...pv,
          [cv.orchidSearchParam]: cv,
        };
      }, {}),
    [globalElements]
  );

  const { sortableList, setSortableList, reStringifyList } = useSortableList<UrlItem>({
    list: urls,
    addIdToListOfStrings: true,
    zeroBasedIndex: false,
  });

  useEffect(() => {
    if (signupFlow && !mounted) {
      setName(signupFlow.name);
      setUrls(signupFlow.steps.map((step) => step.url));
      setMounted(true);
    }
  }, [signupFlow, mounted]);

  const handleSubmit = () => {
    const stringifiedList = reStringifyList(sortableList);
    onSubmit({ name, urls: stringifiedList });
  };

  const onAddUrl = () => {
    try {
      const instantiatedUrl = new URL(newUrl);
      if (instantiatedUrl) {
        setUrls([...urls, newUrl]);
        setNewUrl('');
      }
    } catch (error) {
      toast.error('Invalid URL');
    }
  };

  const onSelectUrl = (selectedUrl: string) => {
    if (isDynamicResourcePage(selectedUrl)) {
      setConfigurableUrl(selectedUrl);
      return;
    }

    const instantiatedUrl = new URL(selectedUrl);
    const globalElement = globalElementsBySearchParam[instantiatedUrl.search.replace('?', '')];
    if (urls.length === 0 || !globalElement?.isModal) {
      setUrls([...urls, selectedUrl]);
      return;
    }

    // If selected option is a global element (like Recommendations, Login or Signup Modal)
    // Append search param to open the modal based on the previous url in the signup flow
    // Example signup flow;
    // Step 1: Go to upgrade
    // Step 2: Open recommendations modal
    // Now in step 2, the implied URL will be `https:://publication.com/upgrade?modal=recommendations`
    try {
      const urlWithPreviousUrlPath = new URL(urls[0]);
      if (globalElement.orchidSearchParam) {
        const [searchParamName, searchParamValue] = globalElement.orchidSearchParam.split('=');
        urlWithPreviousUrlPath.searchParams.set(searchParamName, searchParamValue);
        setUrls([...urls, urlWithPreviousUrlPath.href]);
      }
    } catch (error) {
      toast.error('Something went wrong, please try again!');
    }
  };

  const title = signupFlow ? 'Edit Signup Flow' : 'Create Signup Flow';
  const ctaText = signupFlow ? 'Save' : 'Create';
  const isLoadingText = signupFlow ? 'Saving...' : 'Creating...';

  const getPageNameFromUrl = (url: string) => {
    try {
      const { pathname, search } = new URL(url);
      const pathWithoutId = PageRouteGetter.removeResourceId(pathname);
      console.log({ pathWithoutId });
      return (
        globalElementsBySearchParam[search.replace('?', '')]?.name ||
        pageRouteGetter?.getPageFromPath(pathWithoutId.replace('/', ''))?.published_page_version?.name ||
        url
      );
    } catch (error: any) {
      return url;
    }
  };

  const handleClose = () => {
    setNewUrl('');
    setName('');
    setUrls([]);
    setMounted(false);
    onClose();
  };

  return (
    <>
      <ConfigureRoute
        key={configurableUrl}
        url={configurableUrl}
        onClose={() => setConfigurableUrl(undefined)}
        onSubmit={(url?: string) => {
          if (url) {
            setUrls([...urls, url]);
          }
          setConfigurableUrl(undefined);
        }}
      />
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        title={title}
        onConfirm={handleSubmit}
        isLoading={isSubmitting}
        ctaText={ctaText}
        isLoadingText={isLoadingText}
      >
        <div className="flex flex-col space-y-4">
          <Input
            name="signup_flow_name"
            labelText="Name"
            placeholder="New subscriber flow"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <div className="flex flex-col space-y-1">
            <InputWrapper
              labelText="Steps"
              helperText="Add urls and rearange them for your desired signup flow. External urls can only be places as the last destination in a sequence."
            >
              <SortableList
                listItems={sortableList}
                onItemsSorted={(sortedList: any[]) => {
                  setSortableList(sortedList);
                }}
              >
                {(list) => {
                  return list.map((item: UrlItem) => {
                    const isExternal = !item.value.includes(publicationUrl);

                    return (
                      <SortableListItem
                        key={item.id}
                        listItem={item}
                        className={cx(
                          '!bg-wb-primary !font-medium !text-sm !rounded-lg !h-10 !shadow-sm !border-wb-primary'
                        )}
                        onRemoveItem={(urlItem: UrlItem) => {
                          const filteredUrls = list.filter((listItem: UrlItem) => listItem.id !== urlItem.id);
                          setUrls(filteredUrls.map((lItem: any) => lItem.value));
                        }}
                        text={
                          <div className="flex items-center space-x-2">
                            <Text
                              size="xs"
                              variant="primary-soft"
                              className="tracking-tight !font-medium truncate max-w-[300px]"
                              weight="medium"
                              as="span"
                            >
                              {isExternal ? item.value : getPageNameFromUrl(item.value)}
                            </Text>
                            {isExternal && (
                              <Badge size="sm">
                                <span className="text-xs">External</span>
                              </Badge>
                            )}
                          </div>
                        }
                      />
                    );
                  });
                }}
              </SortableList>
              <div className={cx('flex flex-col space-y-2')}>
                <div className="flex items-center space-x-2">
                  <RoutesComboBox
                    key={`${signupFlow?.id}-${mounted}`}
                    publicationUrl={publicationUrl}
                    selectedOption={newUrl}
                    onInputChange={setNewUrl}
                    setSelectedOption={onSelectUrl}
                    onSubmit={onAddUrl}
                  />
                  <Button
                    variant="outlined"
                    className="whitespace-nowrap"
                    onClick={() => {
                      onAddUrl();
                    }}
                  >
                    Add url
                  </Button>
                </div>
              </div>
            </InputWrapper>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SignupFlowModal;
