import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Pagination } from '@/interfaces/general';
import { LedgerEntry } from '@/interfaces/ledger_entry';
import api from '@/services/swarm';

interface ApiResponse {
  grouped_ledger_entries: LedgerEntry[];
  pagination: Pagination;
}

interface Props {
  sort?: string;
  sortDirection?: string;
  period?: string;
  networkType?: string;
  ledgerKind?: string;
}

const useGroupedLedgerEntries = ({ sort, sortDirection, period, networkType, ledgerKind }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchLedgerEntries = ({ pageParam = 1 }): Promise<ApiResponse> =>
    api
      .get('/wallet/grouped_ledger_entries', {
        params: {
          page: pageParam,
          publication_id: currentPublicationId,
          sort,
          sort_direction: sortDirection,
          period,
          network_type: networkType,
          ledger_kind: ledgerKind,
        },
      })
      .then((res: any) => res.data);

  return useInfiniteQuery(
    [currentPublicationId, 'wallet', 'grouped_ledger_entries', sort, sortDirection, period, networkType, ledgerKind],
    fetchLedgerEntries,
    {
      cacheTime: 0,
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
    }
  );
};

export default useGroupedLedgerEntries;
