import { memo } from 'react';

import { useDreamEditorContext } from '@/context/dream-editor-context';

import { SaveIndicator } from './SaveIndicator';

export const SavePageIndicator = memo(() => {
  const { changesMade, save, isSaveLoading } = useDreamEditorContext();
  return <SaveIndicator changesMade={changesMade} onSave={save} isSaveLoading={isSaveLoading} type="page" />;
});
