import { useMemo, useState } from 'react';

import { TypeaheadMultiSelect } from '@/components/Form';
import SelectedOptionBadges from '@/components/Form/SelectedOptionBadges';
import { Option } from '@/interfaces/general';
import { normalizeString } from '@/utils';
import countries from '@/utils/countries.json';
import mappedCountries from '@/utils/mappedCountries';

type Props = {
  defaultSelection: string[];
  onChange: (countryCodes: string[]) => void;
  labelText?: string;
  helperText?: string;
  showSelection?: boolean;
};

const CountryMultiSelect = ({
  defaultSelection,
  onChange,
  labelText = 'Countries',
  helperText = '',
  showSelection = true,
}: Props) => {
  const [query, setQuery] = useState('');
  const [currentSelection, setCurrentSelection] = useState<string[]>(defaultSelection);

  const handleSearch = (): Promise<Option[]> => {
    const options =
      countries
        ?.filter(
          (country) => normalizeString(country.name).includes(query) || normalizeString(country.code).includes(query)
        )
        .map((country) => ({ label: country.name, value: country.code })) || [];

    return new Promise((resolve) => {
      resolve(options);
    });
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setQuery(normalizeString(newQuery));
  };

  const handleOnClear = () => setQuery('');

  const handleSelect = (name: string, value: string) => {
    const newCurrentSelection = [...currentSelection, value];

    setCurrentSelection(newCurrentSelection);
    onChange(newCurrentSelection);
  };

  const handleDeselect = (name: string, value: string) => {
    const newCurrentSelection = currentSelection.filter((item) => item !== value);

    setCurrentSelection(newCurrentSelection);
    onChange(newCurrentSelection);
  };

  const handleDeselectViaBadge = (value: string) => {
    handleDeselect('', value);
  };

  const handleDeselectAll = () => {
    setCurrentSelection([]);
  };

  const selectedOptions: Option[] = useMemo(
    () =>
      currentSelection.map((countryCode: string) => ({
        label: mappedCountries[countryCode].name,
        value: countryCode,
      })),
    [currentSelection]
  );

  return (
    <div className="flex flex-col gap-y-2">
      <TypeaheadMultiSelect
        name="country-multi-select"
        onClear={handleOnClear}
        onDeselect={handleDeselect}
        onDeselectAll={handleDeselectAll}
        onSearch={handleSearch}
        onSearchQueryChange={handleSearchQueryChange}
        onSelect={handleSelect}
        labelText={labelText}
        helperText={helperText}
        placeholderText="Select Countries"
        emptyLabel="No countries found"
        values={currentSelection}
        showClearAll={false}
        shouldCloseOnSelection={false}
      />
      {showSelection && <SelectedOptionBadges options={selectedOptions} onDeselect={handleDeselectViaBadge} />}
    </div>
  );
};

export default CountryMultiSelect;
