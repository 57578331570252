import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import useWallet from '@/hooks/boosts/useWallet';
import { PLAN } from '@/utils/plans';

import UpgradeToScale from './v2/UpgradeToScale';
import WalletV1 from './v1';
import WalletV2 from './v2';

const Wallet = () => {
  const currentPublication = useCurrentPublication();
  const isLaunchPlan = currentPublication?.data?.plan_name === PLAN.LAUNCH;
  const walletQuery = useWallet();
  const { data: walletData } = walletQuery;
  const hasWalletData = walletData && (walletData.total_balance_cents > 0 || walletData.all_time_earnings_cents > 0);
  const { settings } = useSettings();

  if (isLaunchPlan && !hasWalletData) {
    return <UpgradeToScale />;
  }

  if (settings?.wallet_v2 === true) {
    return <WalletV2 />;
  }

  return <WalletV1 />;
};

export default Wallet;
