import { Text } from '../../UI/Text';

interface Props {
  title: string;
}

const KnowledgeTag = ({ title }: Props) => {
  return (
    <div className="flex items-center justify-center px-2 py-1 bg-wb-accent-soft text-wb-accent rounded-md text-[10px] gap-1 w-fit">
      <Text weight="regular" variant="accent" size="3xs" as="span" className="line-clamp-1 max-w-[150px] truncate">
        {title}
      </Text>
    </div>
  );
};

export default KnowledgeTag;
