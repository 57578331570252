export enum ActivityFeedEventType {
  SENT = 'sent',
  DELIVERED = 'delivered',
  OPEN = 'open',
  CLICK = 'click',
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
  SPAM_REPORT = 'spam_report',
  DEFERRAL = 'deferral',
  DELIVERY_ERROR = 'delivery_error',
  COMMENT = 'comment',
  AUTOMATION_ENROLLED = 'automation_enrolled',
  AUTOMATION_COMPLETED = 'automation_completed',
  AUTOMATION_EXITED_EARLY = 'automation_exited_early',
  AUTOMATION_UNSUBSCRIBE = 'automation_unsubscribe',
  POLL_RESPONSE = 'poll_response',
  POLL_EXTENDED_FEEDBACK = 'poll_extended_feedback',
  REFERRAL_MILESTONE_ACHIEVED = 'referral_milestone_achieved',
  REFERRAL_MILESTONE_EMAIL_SENT = 'referral_milestone_email_sent',
  REFERRAL = 'referral',
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
  PAYMENT_FAILED = 'payment_failed',
  PAYMENT_SUCCEEDED = 'payment_succeeded',
  GIFT_RECEIVED = 'gift_received',
  GIFT_CLAIMED = 'gift_claimed',
  GIFT_ENDED = 'gift_ended',
  GIFT_SENT = 'gift_sent',
  GIFT_PURCHASED = 'gift_purchased',
}

export const ActivityFeedGiftEventIntervals: Record<string, string> = {
  month: 'one month',
  year: 'one year',
  one_time: 'lifetime',
  donation: 'lifetime',
};

export interface ActivityFeedEvent {
  type: ActivityFeedEventType;
  timestamp: string; // Timestamp of the event
  resource_title: string; // Display for the link
  resource_id: string; // ID of the resource to be linked to
  resource_type: string; // Class name of the resource to be linked to
  metadata?: any;
}
