import { TiptapCollabProvider } from '@hocuspocus/provider';
import { KeyboardShortcutCommand } from '@tiptap/core';
import { DocumentColors } from '@tiptap-pro/extension-document-colors';

import { Settings } from '@/interfaces/setting';
import SpamLinks from '@/utils/spam-links.json';

import { EditorDocumentType } from './Document';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  ActiveNode,
  AdvertisementOpportunity,
  AdvertisementOpportunityLogo,
  Authors,
  Boost,
  BulletList,
  Button,
  CharacterCount,
  CodeBlockLowlight,
  Color,
  ColorStorage,
  Columns,
  Container,
  CustomBehaviors,
  CustomSelections,
  Deletable,
  Divider,
  DreamSection,
  Dropcursor,
  EmbedInput,
  Figcaption,
  Focus,
  FontFamily,
  FontGradient,
  FontSize,
  FontWeight,
  GenericEmbed,
  GenericEmbedDescription,
  GenericEmbedHost,
  GenericEmbedTitle,
  getDocument,
  GlobalAttributes,
  Heading,
  Highlight,
  HorizontalRule,
  Hover,
  HtmlSnippet,
  Icon,
  Image,
  Link,
  ListItem,
  Login,
  LoginModal,
  MergeTags,
  OrderedList,
  Paragraph,
  ParagraphLists,
  PasteFromGoogleDoc,
  PaywallBreak,
  Placeholder,
  Poll,
  Post,
  Pricing,
  Recommendations,
  RecommendationsModal,
  RecommendedPosts,
  ReferralProgram,
  ResetPassword,
  SearchAndReplace,
  Section,
  ServiceEmbed,
  Shortcuts,
  Signup,
  SignupModal,
  Socials,
  Spacer,
  StarterKit,
  SubscriberBreak,
  Subscript,
  Superscript,
  Survey,
  Table,
  TableCell,
  TableHeader,
  TableOfContents,
  TableRow,
  Tags,
  Testimonials,
  TextAlign,
  TextStyle,
  ThemeCommands,
  Tokens,
  Typography,
  Underline,
  UniqueID,
  UpdatePassword,
} from '.';

interface ExtensionKitProps {
  usesCollaboration?: boolean;
  provider?: TiptapCollabProvider | null;
  userId?: string;
  publicationId?: string;
  settings?: Settings;
  onToggleUpgradeIntentModal?: Function;
  allowPolls?: boolean;
  allowAds?: boolean;
  additionalShortcuts?: Record<string, KeyboardShortcutCommand>;
  type?: EditorDocumentType;
  nonDeletableNodes?: string[];
}

// 50,000 = ~6250 words (avg length of 8 chars/word)
export const FALL_BACK_CHARACTER_LIMIT = 50_000;

export const ExtensionKit = ({
  additionalShortcuts = {},
  allowAds = false,
  allowPolls = false,
  provider,
  publicationId,
  settings,
  type,
  nonDeletableNodes = [],
}: ExtensionKitProps) => {
  return [
    getDocument(type),
    Columns,
    ColorStorage,
    Container,
    Accordion,
    AccordionContent,
    AccordionTitle,
    ActiveNode,
    TableOfContents,
    Pricing,
    UniqueID,
    Survey,
    Deletable,
    DocumentColors.configure({
      colorTypes: [
        {
          name: 'textStyle',
          schemaType: 'mark',
          colorAttribute: 'color',
        },
        {
          name: 'highlight',
          schemaType: 'mark',
          colorAttribute: 'color',
        },
        {
          name: 'link',
          schemaType: 'mark',
          colorAttribute: 'color',
        },
        {
          name: 'section',
          schemaType: 'node',
          colorAttribute: 'backgroundColor',
        },
        {
          name: 'section',
          schemaType: 'node',
          colorAttribute: 'color',
        },
        {
          name: 'section',
          schemaType: 'node',
          colorAttribute: 'borderColor',
        },
        {
          name: 'button',
          schemaType: 'node',
          colorAttribute: 'customTextColor',
        },
        {
          name: 'button',
          schemaType: 'node',
          colorAttribute: 'customBackgroundColor',
        },
      ],
    }),
    Heading.configure({
      levels: [1, 2, 3, 4, 5, 6],
    }),
    HtmlSnippet.configure({
      enabled: !!settings?.custom_html_blocks,
    }),
    GenericEmbedTitle, // Keep this before StarterKit
    GenericEmbedDescription,
    GenericEmbedHost,
    GenericEmbed.configure({
      clientId: provider?.document?.clientID,
    }),
    ServiceEmbed,
    HorizontalRule,
    EmbedInput,
    Paragraph,
    BulletList,
    OrderedList,
    Spacer,
    Signup,
    RecommendationsModal,
    RecommendedPosts,
    ResetPassword,
    UpdatePassword,
    SignupModal,
    Socials,
    ListItem,
    Login,
    LoginModal,
    Hover,
    Recommendations,
    Tags,
    StarterKit.configure({
      document: false,
      dropcursor: false,
      heading: false,
      horizontalRule: false,
      blockquote: false,
      codeBlock: false,
      paragraph: false,
      listItem: false,
      orderedList: false,
      bulletList: false,
    }),
    CodeBlockLowlight,
    Button,
    PaywallBreak.configure({
      enabled: !!settings?.premium_subscriptions,
    }),
    ...(allowPolls
      ? [
          Poll.configure({
            publicationId,
            enabled: !!settings?.polls,
          }),
        ]
      : []),
    ...(allowAds ? [AdvertisementOpportunity.configure({ publicationId })] : []),
    ...(allowAds ? [AdvertisementOpportunityLogo.configure({ publicationId })] : []),
    Boost.configure({ publicationId }),
    ReferralProgram.configure({
      publicationId,
      enabled: !!settings?.referral_program,
    }),
    TextStyle,
    Testimonials,
    FontSize,
    FontWeight,
    FontGradient,
    FontFamily,
    MergeTags,
    Color,
    Link.configure({
      openOnClick: false,
      validate: (link) => {
        const includesSpam = SpamLinks.url_includes.find((url) => link.includes(url as string));
        const exactSpam = SpamLinks.blocked_urls.find((url) => url === link);

        return !(includesSpam || exactSpam);
      },
    }),
    Highlight.configure({ multicolor: true }),
    Underline,
    CharacterCount.configure({
      limit: settings?.max_tip_tap_character_limit || FALL_BACK_CHARACTER_LIMIT,
    }),
    Image,
    Icon,
    TextAlign.extend({
      addKeyboardShortcuts() {
        return {};
      },
    }).configure({
      types: ['heading', 'paragraph'],
    }),
    SubscriberBreak,
    Subscript,
    Superscript,
    Section.configure({ type }),
    DreamSection,
    Table.configure({
      resizable: false,
      lastColumnResizable: false,
      HTMLAttributes: {
        class: 'table-fixed',
      },
    }),
    TableCell,
    TableHeader,
    TableRow,
    Typography,
    Placeholder.configure({
      includeChildren: true,
      showOnlyCurrent: true,
      placeholder: () => '',
    }),
    Authors,
    Post,
    Focus,
    PasteFromGoogleDoc,
    Figcaption,
    Dropcursor.configure({
      color: '#7C3AED',
      width: 2,
      class: 'ProseMirror-dropcursor',
    }),
    Shortcuts.configure({ additionalShortcuts }),
    SearchAndReplace,
    Divider,
    CustomSelections,
    CustomBehaviors.configure({
      nonDeletableNodes,
    }),
    ThemeCommands,
    Tokens,
    GlobalAttributes,
    ParagraphLists,
  ];
};

export default ExtensionKit;
