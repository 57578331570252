import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-mini';
import styled from 'styled-components';
import { BooleanParam, createEnumParam, DateParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import LoadingBox from '@/components/LoadingBox';
import SubscriberTagItem from '@/components/SubscriberTags/SubscriberTagItem';
import TableHeaders from '@/components/TableHeaders';
import { useSettings } from '@/context/settings-context';
import { useSubscribers } from '@/hooks';
import { DateFields, DateFilterTypes, SubscriberDateField } from '@/interfaces/dates';
import { Order, Sort } from '@/interfaces/general';
import { SubscribersFormQueryParams } from '@/interfaces/subscriber';
import { SubscriptionSubscriberTag } from '@/interfaces/subscriber_tag';
import { Subscription, SubscriptionStatuses } from '@/interfaces/subscription';
import AdvancedSearchOptions, { AdvancedSearchOptionsProps } from '@/models/advancedSearchOptions';
import SubscriberSearch from '@/models/subscriberSearch';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import pluralize from '@/utils/pluralize';

import NoResults from './_components/NoResults';
import Search from './_components/Search';

const TABLE_HEADERS: Map<string, string> = new Map([
  ['email_addresses.email', 'Email'],
  ['status', 'Status'],
  ['tiers', 'Tiers'],
  ['created_at', 'Subscribed On'],
  ['acquisition_sources.display', 'Acquisition Source'],
]);

const TABLE_HEADERS_WITH_TAGS: Map<string, string> = new Map([
  ['email_addresses.email', 'Email'],
  ['status', 'Status'],
  ['subscriber_tags', 'Tags'],
  ['tiers', 'Tiers'],
  ['created_at', 'Subscribed On'],
  ['acquisition_sources.display', 'Acquisition Source'],
]);

const SORTABLE_HEADERS = ['email_addresses.email', 'created_at', 'status'];

const BADGE_COLORS: any = { active: 'success', inactive: 'alert', pending: undefined, needs_attention: 'warning' };

const MaxHeightWrapper = styled.div.attrs({
  className: 'overflow-x-hidden border rounded-md border-gray-200 relative',
})``;

const Subscribers: React.FunctionComponent = () => {
  const { settings } = useSettings();
  const defaultDate = (change = -1, date = new Date()) => {
    date.setMonth(date.getMonth() + change);

    return date;
  };

  const [searchParams, setSearchParams] = useQueryParams<SubscribersFormQueryParams>({
    search: withDefault(StringParam, ''),
    order: withDefault(StringParam, 'created_at'),
    dir: withDefault(StringParam, Order.DESC),
    status: withDefault(StringParam, 'all'),
    tierId: withDefault(StringParam, 'all'),
    advanced: withDefault(BooleanParam, false),
    datesEnabled: withDefault(BooleanParam, false),
    startDate: withDefault(DateParam, defaultDate()),
    endDate: withDefault(DateParam, defaultDate(0)),
    beforeDate: withDefault(DateParam, defaultDate(0)),
    afterDate: withDefault(DateParam, defaultDate()),
    dateFilterType: withDefault(createEnumParam(Object.values(DateFilterTypes)), DateFilterTypes.BETWEEN),
    dateField: withDefault(
      createEnumParam<DateFields>(Object.values(SubscriberDateField)),
      SubscriberDateField.CREATED_AT
    ),
  });

  const advancedSearchOptions = new AdvancedSearchOptions({
    advanced: searchParams.advanced,
    afterDate: searchParams.afterDate,
    beforeDate: searchParams.beforeDate,
    dateField: searchParams.dateField,
    dateFilterType: searchParams.dateFilterType,
    datesEnabled: searchParams.datesEnabled,
    endDate: searchParams.endDate,
    startDate: searchParams.startDate,
  });

  const [shouldResetSearch, setShouldResetSearch] = useState(false);

  const query = new SubscriberSearch(searchParams);

  const subscriberQuery = useSubscribers(query);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, isLoading, isError } = subscriberQuery;
  const totalSubscriberCount = data?.pages[0]?.pagination?.total;
  const subscribersShowing = data?.pages?.flatMap((page) => page.subscriptions) || [];

  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  const handleSearch = (search: string, advancedSearch: AdvancedSearchOptionsProps) => {
    setSearchParams({ ...searchParams, search, ...advancedSearch });
  };

  // Reset search when no results
  const handleResetSearch = () => {
    setSearchParams({ ...searchParams, search: undefined, status: 'all', tierId: 'all' });
    setShouldResetSearch(true);
  };

  const handleSortChange = (newSort: Sort) => {
    setSearchParams({ ...searchParams, ...newSort });
  };

  // Handle filtering
  const handleStatusChange = (val: string) => {
    const status = val.toLowerCase();

    setSearchParams({ ...searchParams, status });
  };

  const handleTierIdChange = (value: string | string[]) => {
    const tierId = String(value).toLowerCase();

    setSearchParams({ ...searchParams, tierId });
  };

  const { search, order, dir, status, tierId } = searchParams;

  return (
    <>
      <Search
        search={search}
        handleStatusChange={handleStatusChange}
        handleTierIdChange={handleTierIdChange}
        status={status}
        tierId={tierId}
        isFetching={isFetching}
        shouldResetSearch={shouldResetSearch}
        handleResetSearch={handleResetSearch}
        handleSearch={handleSearch}
        advancedSearchOptions={advancedSearchOptions}
      />

      <div className="mb-1">
        {!isLoading && totalSubscriberCount !== undefined && totalSubscriberCount > 0 && (
          <span className="text-xs font-semibold text-gray-600">{`Showing ${subscribersShowing.length} of ${pluralize(
            'result',
            totalSubscriberCount
          )}`}</span>
        )}
      </div>
      <div>
        <div className="overflow-x-auto">
          <div className="align-middle inline-block min-w-full">
            <MaxHeightWrapper>
              <LoadingBox isLoading={isLoading} isError={isError}>
                {subscribersShowing.length === 0 ? (
                  <NoResults search={search} status={status} tierIdValue={tierId} handleOnClick={handleResetSearch} />
                ) : (
                  <>
                    <table className="min-w-full divide-y divide-gray-200 rounded-md">
                      <TableHeaders
                        headers={settings?.subscriber_tagging ? TABLE_HEADERS_WITH_TAGS : TABLE_HEADERS}
                        sort={{ order, dir: dir as Order }}
                        sortableHeaders={SORTABLE_HEADERS}
                        handleSortChange={handleSortChange}
                      />

                      <tbody className="bg-white divide-y divide-gray-200">
                        {subscribersShowing?.map((subscriber: Subscription) => (
                          <tr key={subscriber.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="text-sm font-medium text-gray-900">
                                  <Link
                                    className="text-primary-600 hover:text-primary-900"
                                    to={`/subscribers/${subscriber.uuid}`}
                                  >
                                    {subscriber.email}
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                <Badge type={BADGE_COLORS[subscriber.status]} className="capitalize">
                                  {SubscriptionStatuses[subscriber.status]}
                                </Badge>
                              </div>
                            </td>
                            {settings?.subscriber_tagging && (
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm text-gray-900">
                                  {subscriber.subscription_subscriber_tags?.map(
                                    (tag: SubscriptionSubscriberTag, index: number) => (
                                      <>
                                        {index < 3 && (
                                          <span key={tag.id}>
                                            <SubscriberTagItem name={tag.name} color={tag.color} />
                                          </span>
                                        )}
                                        {index === 2 && (
                                          <span className="bg-white-100 text-gray-600 text-xs rounded-md border-0 shadow-xs inline-flex gap-x-1 mr-2 px-2 py-1">
                                            + {subscriber.subscription_subscriber_tags.length - 3} More
                                          </span>
                                        )}
                                      </>
                                    )
                                  )}
                                </div>
                              </td>
                            )}
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{subscriber.tiers.join(', ')}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{moment(subscriber.created_at).format('LLL')}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{subscriber.acquisition_source_display}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {hasNextPage && (
                      <div className="flex justify-center align-middle p-6">
                        <Button
                          variant="primary-inverse"
                          onClick={() => fetchNextPage()}
                          loading={isFetchingNextPage}
                          disabled={!hasNextPage || isFetchingNextPage}
                        >
                          {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </LoadingBox>
            </MaxHeightWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribers;
