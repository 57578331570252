import { useNavigate } from 'react-router-dom';

import { Typography } from '@/components/Typography';
import useDisconnectIntegration from '@/hooks/useIntegrations/useDisconnectIntegration';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { IntegrationProvider } from '@/interfaces/integration';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import BodyContainer from '../../../_components/BodyContainer';
import useMailchimpConnection from '../hooks/useMailchimpConnection';
import { StepProps } from '../new/types';

import MailchimpAccount from './MailchimpAccount';

const MailchimpConnect: React.FunctionComponent<StepProps> = ({ contentImport, updateContentImport }: StepProps) => {
  const publicationId = useCurrentPublicationId();
  const navigate = useNavigate();
  const disconnect = useDisconnectIntegration(publicationId, IntegrationProvider.MAILCHIMP);
  const { isConnected, integrations, refetchIntegrations } = useMailchimpConnection({
    contentImport,
    updateContentImport,
  });

  const handleMailchimpConnect = () => {
    analytics.track('Mailchimp Integration');
    navigate('/auth/mailchimp');
  };

  const handleDisconnect = (integrationId: string) => {
    disconnect.mutateAsync({ integrationId }).then(() => {
      refetchIntegrations();
    });
  };

  return (
    <BodyContainer>
      {isConnected ? (
        <>
          <ul className="divide-y-4">
            {integrations?.map((integration) => (
              <MailchimpAccount key={integration.id} integration={integration} onDisconnect={handleDisconnect} />
            ))}
          </ul>

          <div>
            <Button variant="tertiary" onClick={handleMailchimpConnect}>
              Connect another account?
            </Button>
          </div>
        </>
      ) : (
        <>
          <Typography token="font-normal/text/sm" colorWeight="500">
            To import content from Mailchimp, you&apos;ll need to connect your Mailchimp account to beehiiv. This will
            allow us to pull your content and information about your publication during the import process. Once
            connected, you can come back to this page to import your content.
          </Typography>

          <div>
            <Button variant="primary-inverse" type="button" onClick={handleMailchimpConnect}>
              Connect to Mailchimp
            </Button>
          </div>
        </>
      )}
    </BodyContainer>
  );
};

export default MailchimpConnect;
