import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bars3Icon, CalendarIcon } from '@heroicons/react/20/solid';
import { GlobeAltIcon } from '@heroicons/react/24/solid';
import cx from 'classnames';

import { CalendarModal } from '@/components/Calendar/Modal';
import { NotificationsDropdown } from '@/components/Notifications/NotificationsDropdown';
import { useAppLayout } from '@/context/app-layout-context';
import { usePermissions } from '@/context/permissions-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication } from '@/hooks';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { Button } from '@/ui/Button';

import { TOP_BAR_HEIGHT_CLASS_NAME } from '../constants';

import TopNavItem from './_components/TopNavItem';
import { UserDropdown } from './UserDropdown';

interface Props {
  className?: string;
  onMobileToggle?: () => void;
}

const TopBar = ({ className, onMobileToggle }: Props) => {
  const navigate = useNavigate();
  const { checkPermissions } = usePermissions();
  const { settings } = useSettings();
  const { topBarRef, topBarPortalUsed } = useAppLayout();

  const currentPublicationId = useCurrentPublicationId();
  const { data: currentPublication } = useCurrentPublication();

  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div
      className={cx(
        'screen:flex flex-col justify-between items-center print:hidden border-b border-surface-200 bg-white',
        TOP_BAR_HEIGHT_CLASS_NAME,
        className
      )}
    >
      <div className="w-full h-full flex flex-row justify-center items-between">
        {onMobileToggle && (
          <div className="sm:hidden flex flex-col justify-center px-2">
            <Button variant="flush" size="xs" Icon={Bars3Icon} onClick={onMobileToggle} />
          </div>
        )}
        <div ref={topBarRef} className="w-full flex flex-row">
          {!topBarPortalUsed && (
            <div className="w-full py-2 pr-6 flex flex-row space-x-2 justify-end items-end">
              {currentPublicationId && (
                <>
                  <div className="hidden sm:flex space-x-2">
                    <TopNavItem
                      onClick={() => navigate('/partner_program')}
                      title="Share & Earn"
                      disabled={!checkPermissions('views/nav/share_and_earn', 'read')}
                    />
                    <TopNavItem
                      onClick={() => navigate('/wallet')}
                      title="Wallet"
                      disabled={!checkPermissions('views/nav/wallet', 'read')}
                    />
                    <TopNavItem onClick={() => navigate('/help')} title="Help" />
                  </div>
                  <a href={currentPublication?.url} target="_blank" rel="noreferrer">
                    <Button
                      href={currentPublication?.url}
                      size="sm"
                      variant="flush"
                      type="button"
                      paddingStyle="icon"
                      Icon={GlobeAltIcon}
                      tooltip="View site"
                    />
                  </a>
                  {settings?.calendar && (
                    <>
                      <Button
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        size="sm"
                        variant="flush"
                        type="button"
                        paddingStyle="icon"
                        Icon={CalendarIcon}
                        tooltip="View calendar"
                      />
                      <CalendarModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
                    </>
                  )}
                </>
              )}

              {currentPublicationId && <NotificationsDropdown />}
              <UserDropdown />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
