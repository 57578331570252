import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NavbarSerializableNode } from '@shared/dream-components';

import { useBeforeNavigate } from '@/hooks/useBeforeNavigate';
import { useUpdateSitePackage } from '@/hooks/useSitePackages';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';

import { NavbarDataContext } from '../../navbar/_components/NavbarEditor/NavbarDataContext';

export const TemplateNavbarDataProvider = ({ children }: PropsWithChildren) => {
  const { sitePackageId } = useParams();
  const { data: sitePackage } = useSitePackage({ sitePackageId: sitePackageId as string });
  const [content, setContent] = useState<NavbarSerializableNode | undefined>(undefined);
  const [changesMade, setChangesMade] = useState(false);

  const { mutateAsync: updateSitePackageAsync, isLoading: isSaveLoading } = useUpdateSitePackage({
    id: sitePackageId as string,
  });

  useEffect(() => {
    if (sitePackage?.navbar) {
      setContent(sitePackage.navbar as NavbarSerializableNode);
    }
  }, [sitePackage]);

  const isSaveVersionLoading = false;

  const onUpdateContent = useCallback((c: NavbarSerializableNode) => {
    setContent(c);
    setChangesMade(true);
  }, []);

  const onSave = useCallback(async () => {
    if (!changesMade) return;
    await updateSitePackageAsync({ navbar: JSON.stringify(content) });
    setChangesMade(false);
  }, [changesMade, updateSitePackageAsync, content]);

  const onSaveToVersionHistory = useCallback(async (): Promise<void> => {
    console.error('onSaveToVersionHistory not implemented');
  }, []);

  // Auto save every 10 seconds
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (changesMade) {
        onSave();
      }
    }, 10000); // 10 seconds

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [changesMade, onSave]);

  // Save on CMD + S
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault();
        onSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSave]);

  // Save on navigate away & prompt on reload or leaving page
  useBeforeNavigate(onSave, !!changesMade);

  const contextValue = useMemo(
    () => ({
      content,
      onUpdateContent,
      changesMade,
      onSave,
      isSaveLoading,
      onSaveToVersionHistory,
      isSaveVersionLoading,
    }),
    [content, onUpdateContent, changesMade, onSave, isSaveLoading, onSaveToVersionHistory, isSaveVersionLoading]
  );

  return <NavbarDataContext.Provider value={contextValue}>{children}</NavbarDataContext.Provider>;
};
