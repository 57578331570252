import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { AdvancedWidthSettings } from '../AdvancedWidthSettings';
import { ColorSettings } from '../ColorSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SliderSettings } from '../SliderSettings';

export const DividerSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <RenderCondition editor={editor} allowedNodeTypes={['divider']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Style">
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <ColorSettings editor={editor} activeNodeResult={activeNodeResult} title="Color" property="backgroundColor" />
        <AdvancedWidthSettings editor={editor} activeNodeResult={activeNodeResult} property="width" />
        <SliderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Height"
          property="height"
          min={0.1}
          max={100}
          step={0.1}
          unit="px"
        />
        <SliderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Radius"
          property="borderRadius"
          min={0}
          max={100}
          step={1}
          unit="px"
        />
      </SectionRenderer>
    </RenderCondition>
  );
};
