import DnsRecordDisplay from '@/components/_domain/CustomDomain/DnsRecordDisplay';
import Banner from '@/components/Banner';
import { Typography, TypographyStack } from '@/components/Typography';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';
import Badge from '@/ui/Badge';

import { customDomainRecords, isCloudflareListItemCreating, isDomainVerified, isEntriPending, isSetupUsingEntri } from '../../utils';

interface Props {
  customDomain: CustomDomain;
}

const DOCS_URL = 'https://www.beehiiv.com/support/article/14492990172823-How-to-add-and-configure-custom-domains';

const BrandedLink = ({ customDomain }: Props) => {
  const {
    domain,
    branded_link_verified: sendgridVerified,
    branded_link_cloudflare_verified: cloudflareVerified,
  } = customDomain;

  const setupUsingEntri = isSetupUsingEntri(customDomain, CustomDomainTypes.EMAIL);
  const records = customDomainRecords(customDomain, CustomDomainTypes.BRANDED_LINK);
  const verified = isDomainVerified(customDomain, CustomDomainTypes.BRANDED_LINK);
  const entriPending = isEntriPending(customDomain, CustomDomainTypes.BRANDED_LINK);

  const elinkRecord = records.find((record) => record.name.includes('elink'));
  const newOriginValue = window.env.CLOUDFLARE_CUSTOM_ORIGIN || 'branded-link.beehiiv.com';

  const needsSendgridVerification = !sendgridVerified;
  const creatingCloudflareListItem = isCloudflareListItemCreating(customDomain);
  const needsCloudflareVerification = sendgridVerified && !cloudflareVerified && elinkRecord;

  const canStartEntriAction = setupUsingEntri && !entriPending;
  const step2ActionRequired = needsCloudflareVerification && !entriPending;

  return (
    <div className="space-y-4">
      {verified && (
        <>
          {creatingCloudflareListItem && (
            <Banner
              variant="info"
              title="Branded Link Configuration in Progress"
              bodyText="We have verified your Branded Link DNS records and are finishing the setup process. No further action is required from you."
              isScreenWide={false}
            />
          )}
          <Typography token="font-normal/text/sm" colorWeight="500" as="p">
            The DNS records have been verified, and your branded link for {domain} is live. Please make sure to keep these
            records in place to ensure your branded links continue to work. For more information, visit the{' '}
            <Typography token="font-medium/text/sm" colorWeight="600" color="secondary" className="cursor-pointer">
              <a
                href={DOCS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                beehiiv knowledge base
              </a>
            </Typography>{' '}
            or check your DNS provider&apos;s documentation.
          </Typography>

          <div className="space-y-4">
            {records.map((record) => (
              <DnsRecordDisplay key={record.name} record={record} />
            ))}
          </div>
        </>
      )}

      {needsSendgridVerification && (
        <TypographyStack gap="4">
          <Typography token="font-normal/text/sm" colorWeight="500" as="p">
            Branded Links are custom short links unique to your custom domain.
            To learn more about Branded Links and how they are important to you, visit the{' '}
            <Typography token="font-medium/text/sm" colorWeight="600" color="secondary" className="cursor-pointer">
              <a
                href={DOCS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                beehiiv knowledge base
              </a>
            </Typography>
            .
          </Typography>

          <TypographyStack gap="4">
            {canStartEntriAction ? (
              <Typography token="font-normal/text/sm" colorWeight="500" as="p">
                Click &quot;Start Setup with Entri&quot; to start the setup process.
              </Typography>
            ) : (
              <>
                <Typography token="font-normal/text/sm" colorWeight="500" as="p">
                  Go to your DNS provider and create the following DNS records. Once done, click &quot;Verify Setup&quot; below to
                  verify your configuration. We additionally will check on your behalf over the next 24 hours.
                </Typography>

                <div className="space-y-4">
                  {records.map((record) => (
                    <DnsRecordDisplay key={record.name} record={record} />
                  ))}
                </div>

                <Typography token="font-normal/text/sm" colorWeight="500" as="p">
                  These records will ensure that your email links are branded with your custom domain.
                </Typography>
              </>
            )}
          </TypographyStack>
        </TypographyStack>
      )}

      {needsCloudflareVerification && (
        <div className="space-y-8">
          <TypographyStack className="opacity-60">
            <Typography token="font-medium/text/sm" colorWeight="500" as="p">
              1. Create DNS records (Completed)
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="500" as="p">
              You have successfully created and verified this DNS record.
            </Typography>

            <div>
              <DnsRecordDisplay
                key={elinkRecord.name}
                record={elinkRecord}
              />
            </div>
          </TypographyStack>

          <TypographyStack>
            <Typography token="font-semibold/text/sm" colorWeight="700" as="p" className="space-x-1">
              <span>2. Update DNS record</span>
              {step2ActionRequired && (
                <Badge size="sm" type="warning">
                  Action Required
                </Badge>
              )}
            </Typography>
            <Typography token="font-normal/text/sm" colorWeight="500" as="p">
              {canStartEntriAction ? (
                <>
                  To complete the setup, we need to replace the current &quot;{elinkRecord.value}&quot;
                  value with &quot;{newOriginValue}&quot;. Click &quot;Complete Setup with Entri&quot;
                  to have Entri complete the process by updating the DNS record for you.
                </>
              ) : (
                <>
                  Go to your DNS provider and update the following record, replacing the current
                  &quot;{elinkRecord.value}&quot; value with &quot;{newOriginValue}&quot;. Once done,
                  click &quot;Verify Setup&quot; to complete the process.
                </>
              )}
            </Typography>

            <div>
              <DnsRecordDisplay
                key={elinkRecord.name}
                record={elinkRecord}
                replaceWithValue={newOriginValue}
              />
            </div>
          </TypographyStack>
        </div>
      )}
    </div>
  );
};

export default BrandedLink;
