/* eslint-disable no-param-reassign */
import { JSONContent } from '@tiptap/core';

const PRESERVED_ATTRS = ['id', 'isHiddenInProduction', 'level'];

const containsActiveNode = (node: JSONContent, activeNodeIds: string[]): boolean => {
  if (node.attrs?.id && activeNodeIds.includes(node.attrs.id)) return true;

  if (node.content?.length) {
    return node.content.some((child) => !!containsActiveNode(child, activeNodeIds));
  }

  return false;
};

export const processLayersPanelContent = (contentJSON: JSONContent, activeNodeIds: string[] = []) => {
  if (contentJSON.attrs) {
    contentJSON.attrs = Object.keys(contentJSON.attrs)
      .filter((key) => PRESERVED_ATTRS.includes(key))
      .reduce((acc, key) => {
        acc[key] = contentJSON.attrs![key];
        return acc;
      }, {} as Record<string, any>);

    contentJSON.attrs.isActive = activeNodeIds.includes(contentJSON.attrs?.id);
    contentJSON.attrs.isOpen = !!contentJSON.content?.some((child) => containsActiveNode(child, activeNodeIds));
  }

  if (!contentJSON.content?.length) return;

  if (contentJSON.content?.some((node) => node.type === 'text')) {
    delete contentJSON.content;
  } else {
    contentJSON.content.forEach((child) => processLayersPanelContent(child, activeNodeIds));
  }
};
