import ParagraphExtension from '@tiptap/extension-paragraph';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { getStringAttribute } from '../../utils/attributesUtils';

import { ParagraphView } from './views/ParagraphView';

export const PARAGRAPH_DEFAULTS = {
  padding: '4px 4px 4px 4px', // top right bottom left
  paddingRoot: '4px 30px 4px 30px', // top right bottom left
  margin: '0px 0px 0px 0px', // top right bottom left
};

export const Paragraph = ParagraphExtension.extend({
  addAttributes() {
    return {
      textAlign: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-text-align'),
        renderHTML: (attributes) => ({
          'data-text-align': attributes.textAlign,
        }),
      },
      padding: getStringAttribute('padding', 'data-padding', '4px 4px 4px 4px', '4px 4px 4px 4px'),
      lineHeight: getStringAttribute('lineHeight', 'data-line-height', '120%', '120%'),
      mobileLineHeight: getStringAttribute('mobileLineHeight', 'data-mobile-line-height', '120%', '120%'),
      margin: {
        parseHTML: (element) => element.getAttribute('data-margin'),
        renderHTML: (attributes) => {
          return {
            'data-margin': attributes.margin,
          };
        },
      },
    };
  },

  addOptions() {
    return {
      HTMLAttributes: {
        'data-type': this.name,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ParagraphView, {
      stopEvent: () => false,
      attrs: (props) => {
        return {
          ...props.HTMLAttributes,
          style: `width: ${props.node.attrs.width};max-width: ${props.node.attrs.maxWidth};`,
        };
      },
    });
  },
});

export default Paragraph;
