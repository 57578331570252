import { useLayoutEffect, useRef, useState } from 'react';

import { UnsplashImage } from '../../../utils/unsplash';
import { LoadingDots } from '../../TiptapEditor/components/ui/Loader/LoadingDots';
import SearchBar from '../components/SearchBar';
import Tags from '../components/Tags';
import UnsplashImageGrid from '../components/UnsplashImageGrid/UnsplashImageGrid';
import useUnsplashSearch from '../hooks/unsplash/useUnsplashSearch';

const TAGS = [
  'editorial',
  'abstract',
  'animal',
  'architecture',
  'art',
  'business',
  'colorful',
  'food',
  'interior',
  'minimal',
  'nature',
  'plant',
  'portrait',
  'space',
  'technology',
  'texture',
  'wallpaper',
];

export type UnsplashViewProps = {
  onMediaSelect: (image: UnsplashImage) => void;
};

const UnsplashView = ({ onMediaSelect }: UnsplashViewProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { images, loading, fetchNextPage, hasMore } = useUnsplashSearch({ query: searchQuery });

  useLayoutEffect(() => {
    if (!wrapperRef.current || loading) return undefined;

    const wrapper = wrapperRef.current;

    const handleScroll = () => {
      const scrollDistance = wrapper.scrollHeight - (wrapper.scrollTop + wrapper.clientHeight);

      if ((scrollDistance <= 0 || scrollDistance / wrapper.scrollHeight <= 0.02) && hasMore) {
        fetchNextPage();
      }
    };

    wrapper.addEventListener('scroll', handleScroll);

    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, [fetchNextPage, hasMore, loading]);

  return (
    <div ref={wrapperRef} className="h-full w-full overflow-x-hidden overflow-y-auto">
      <div className="sticky top-0 z-10">
        <SearchBar value={searchQuery} onChange={setSearchQuery} placeholder="Search Unsplash..." />
      </div>
      <div className="max-w-full px-6">
        {!images ||
          (images.length === 0 && searchQuery.length === 0 && (
            <Tags tags={TAGS} onTagSelect={(tag) => setSearchQuery(tag)} />
          ))}
        {images && images.length > 0 && <UnsplashImageGrid images={images} onSelect={onMediaSelect} />}
        {loading && (
          <div className="flex items-center justify-center py-8">
            <LoadingDots />
          </div>
        )}
      </div>
    </div>
  );
};

export default UnsplashView;
