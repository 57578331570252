import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ArrowsClockwise, PlusCircle } from '@phosphor-icons/react';

import { useCurrentPublication } from '@/hooks';
import { useCreateSiteTheme, useSiteThemes, useUpdateSiteTheme } from '@/hooks/useSiteThemes';
import { SiteTheme } from '@/interfaces/web_theme';

import { Button } from '../../_components/UI/Button';
import {
  Table,
  TableBody,
  TableCaption,
  TableCard,
  TableHead,
  TableHeader,
  TablePaginationWrapper,
  TableRow,
  TableSimplePagination,
} from '../../_components/UI/Table';
import { Text } from '../../_components/UI/Text';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';

import SiteThemeModal from './_components/SiteThemeModal';
import SiteThemeRow from './_components/SiteThemeRow';

const WebsiteSettingsThemes = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: currentPublication } = useCurrentPublication();
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching, isLoading } = useSiteThemes();

  const queryKey = ['publications', currentPublication?.id, 'site_themes'];

  const siteThemes = data?.pages.flatMap((page) => page.site_themes) || [];
  const siteThemesLength = siteThemes.length;
  const hasSiteThemes = siteThemesLength > 0;

  const { primaryTheme, secondaryThemes } = useMemo(() => {
    let primary: SiteTheme | null = null;
    const secondaries: SiteTheme[] = [];

    siteThemes.forEach((theme) => {
      if (theme.is_primary) {
        primary = theme;
      } else {
        secondaries.push(theme);
      }
    });

    return { primaryTheme: primary, secondaryThemes: secondaries };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteThemesLength, isRefetching]);

  const secondaryThemesLength = secondaryThemes.length;
  const totalCount = data?.pages[0]?.pagination?.total || 0;
  const showingCount = secondaryThemesLength || 0;
  const noResults = !isRefetching && !isLoading && secondaryThemesLength === 0;

  const [siteThemeToEdit, setSiteThemeToEdit] = useState<SiteTheme | null>(null);
  const [isNewSiteThemeOpen, setIsNewSiteThemeOpen] = useState(false);

  const createSiteTheme = useCreateSiteTheme({
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      setIsNewSiteThemeOpen(false);
    },
  });

  const updateSiteTheme = useUpdateSiteTheme({
    siteThemeId: siteThemeToEdit?.id || '',
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      setSiteThemeToEdit(null);
    },
  });

  const handleValidationChecks = (payload: { name: string; data: any }) => {
    if (!payload.name) {
      toast.error('Name is required');
      return false;
    }

    return true;
  };

  const handleEditSubmit = (payload: { name: string; data: any }) => {
    if (handleValidationChecks(payload)) {
      updateSiteTheme.mutate(payload);
    }
  };

  const handleNewSubmit = (payload: { name: string; data: any }) => {
    if (handleValidationChecks(payload)) {
      createSiteTheme.mutate(payload);
    }
  };

  const handleClose = () => {
    setSiteThemeToEdit(null);
    setIsNewSiteThemeOpen(false);
  };

  const isSubmitting = createSiteTheme.isLoading || updateSiteTheme.isLoading;
  const isOpen = isNewSiteThemeOpen || Boolean(siteThemeToEdit);

  return (
    <>
      <SiteThemeModal
        isSubmitting={isSubmitting}
        isOpen={isOpen}
        siteTheme={siteThemeToEdit as SiteTheme | null}
        onClose={handleClose}
        onSubmit={isNewSiteThemeOpen ? handleNewSubmit : handleEditSubmit}
      />

      <SettingsSection hasDivider={false}>
        <SettingsItem
          title="Switch Templates"
          description="Go back through our onboarding process to switch your website to a different template"
        >
          <div className="flex">
            <Button
              Icon={ArrowsClockwise}
              variant="outlined"
              onClick={() => navigate('/website_builder_v2/switch_template')}
            >
              Switch Templates
            </Button>
          </div>
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem title="Primary Theme" description="The default theme for your publication.">
          <TableCard>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-full truncate whitespace-nowrap">Name</TableHead>
                  <TableHead className="text-right">Theme</TableHead>
                  <TableHead className="text-right w-4" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {hasSiteThemes && primaryTheme && (
                  <SiteThemeRow
                    siteTheme={primaryTheme as any}
                    queryKey={queryKey as string[]}
                    onEdit={() => setSiteThemeToEdit(primaryTheme as SiteTheme)}
                    hideDelete
                  />
                )}
              </TableBody>
            </Table>
          </TableCard>
        </SettingsItem>
      </SettingsSection>
      <SettingsSection>
        <SettingsItem title="Secondary Themes" description="Add additional themes to help you customize your site.">
          <div className="flex flex-col space-y-2">
            <div className="flex justify-end">
              <div>
                <Button variant="primary" onClick={() => setIsNewSiteThemeOpen(true)} LeftIcon={PlusCircle}>
                  Add theme
                </Button>
              </div>
            </div>

            <TablePaginationWrapper>
              <TableCard>
                <Table>
                  {noResults && (
                    <TableCaption>
                      <div className="pb-4">
                        <Text size="sm" weight="medium" variant="secondary" as="span">
                          No results found
                        </Text>
                      </div>
                    </TableCaption>
                  )}
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-full truncate whitespace-nowrap">Name</TableHead>
                      <TableHead className="text-right">Theme</TableHead>
                      <TableHead className="text-right w-4" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {secondaryThemes?.map((siteTheme) => {
                      return (
                        <SiteThemeRow
                          key={siteTheme.id}
                          siteTheme={siteTheme}
                          queryKey={queryKey as string[]}
                          onEdit={() => setSiteThemeToEdit(siteTheme as SiteTheme)}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableCard>
              <TableSimplePagination
                showingCount={showingCount}
                totalCount={totalCount}
                hasNextPage={Boolean(hasNextPage)}
                isFetchingNextPage={isFetchingNextPage}
                fetchNextPage={fetchNextPage}
              />
            </TablePaginationWrapper>
          </div>
        </SettingsItem>
      </SettingsSection>
    </>
  );
};

export default WebsiteSettingsThemes;
