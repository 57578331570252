import { PropsWithChildren } from 'react';
import { TrashSimple } from '@phosphor-icons/react';

import { cn } from '@/utils/cn';

import { Button } from '../../../UI/Button';
import { Text } from '../../../UI/Text';

export const AddableSettings = ({
  title,
  isEmpty,
  onAdd,
  onRemove,
  disabled,
  children,
}: PropsWithChildren<{
  title: string;
  isEmpty: boolean;
  onAdd: () => void;
  onRemove: () => void;
  disabled?: boolean;
}>) => {
  if (!isEmpty) {
    return (
      <div className="flex items-center gap-2 select-none">
        <div className="flex-1 min-w-0 max-w-[217px]">{children}</div>
        <Button variant="ghost" onClick={onRemove} className="p-0 w-4 h-4" Icon={TrashSimple} />
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex items-center justify-stretch gap-2 select-none',
        disabled ? 'opacity-50 pointer-events-none' : ''
      )}
    >
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>

      <Button variant="secondary" size="sm" onClick={onAdd} className="grow bg-wb-secondary rounded-lg shadow-sm p-2">
        Add
      </Button>
    </div>
  );
};
