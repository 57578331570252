import { FC } from 'react';
import { IconElement, TIconAttributes } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import { getDataAttributes } from '../../utils';

export const IconView: FC<NodeViewProps> = (nodeViewProps) => {
  const { HTMLAttributes, node } = nodeViewProps;
  const { onDragStart } = useReactNodeView();

  return (
    <IconElement
      element={{
        type: 'icon',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        } as TIconAttributes,
        content: [{ id: 'text', text: '' }],
      }}
      onDragStart={onDragStart}
      attributes={{
        ...getDataAttributes(HTMLAttributes),
        'data-node-view-wrapper': '',
      }}
    >
      {null}
    </IconElement>
  );
};
