import { useCallback } from 'react';
import { Cards } from '@phosphor-icons/react';

import { Text } from '../../../../UI/Text';
import { AttributeSettingProps } from '../../types';
import Button from '../BlockSettings/Button';

type Props = AttributeSettingProps & {
  handleOpenModal: () => void;
};

const callout: Record<string, string> = {
  latest: 'Latest',
  archive: 'Archive',
  category: 'Category',
  tag_page: 'Tag Page',
  author_page: 'Author Page',
  free_selection: 'Free Selection',
  recommendations: 'Recommendations',
};

export const PostsSelectionSettings = ({ editor, activeNodeResult, handleOpenModal }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const isCategory = activeNodeAttributes?.postGroupType === 'category';
  const categoryTitle = activeNodeAttributes?.postsCategory;

  const handleUpdate = useCallback(() => {
    if (!activeNodeAttributes) return;

    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'insertedFromSidebar', 'true');
      return true;
    });
    handleOpenModal();
  }, [editor, activeNodeAttributes, activeNodePos, handleOpenModal]);

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Posts
      </Text>

      <Button
        label={isCategory ? categoryTitle : callout[activeNodeAttributes.postGroupType]}
        icon={<Cards className="text-wb-secondary" weight="bold" />}
        onClick={handleUpdate}
      />
    </div>
  );
};
