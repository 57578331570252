import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { DEFAULT_BORDER_COLOR, DEFAULT_BORDER_RADIUS, DEFAULT_PRIMARY_COLOR } from '../constants';

import { SocialsView } from './views/SocialsView';

export const Socials = Node.create<{}, {}>({
  name: 'socials',

  group: 'block',

  addAttributes() {
    return {
      insertedFromSidebar: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-inserted-from-sidebar'),
        renderHTML: (attributes) => ({ 'data-inserted-from-sidebar': attributes.insertedFromSidebar }),
      },
      hasFakeData: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-has-fake-data'),
        renderHTML: (attributes) => ({ 'data-has-fake-data': attributes.hasFakeData }),
      },
      isGrouped: {
        default: 'true',
        parseHTML: (element) => element.getAttribute('data-is-grouped'),
        renderHTML: (attributes) => ({ 'data-is-grouped': attributes.isGrouped }),
      },
      iconSize: {
        default: '14px',
        parseHTML: (element) => element.getAttribute('data-icon-size'),
        renderHTML: (attributes) => ({ 'data-icon-size': attributes.iconSize }),
      },
      iconPadding: {
        default: '4px',
        parseHTML: (element) => element.getAttribute('data-icon-padding'),
        renderHTML: (attributes) => ({ 'data-icon-padding': attributes.iconPadding }),
      },
      alignment: {
        default: 'left',
        parseHTML: (element) => element.getAttribute('data-alignment'),
        renderHTML: (attributes) => ({ 'data-alignment': attributes.alignment }),
      },

      // This is the padding of the surrounding container
      padding: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({ 'data-padding': attributes.padding }),
      },
      gap: {
        default: '8px',
        parseHTML: (element) => element.getAttribute('data-gap'),
        renderHTML: (attributes) => ({ 'data-gap': attributes.gap }),
      },
      shadow: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-shadow'),
        renderHTML: (attributes) => ({ 'data-shadow': attributes.shadow }),
      },
      backgroundColor: {
        default: '#FFFFFFFF',
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: (attributes) => ({ 'data-background-color': attributes.backgroundColor }),
      },
      borderWidth: {
        default: '1px',
        renderHTML: (attributes) => ({ 'data-border-width': attributes.borderWidth }),
        parseHTML: (element) => element.getAttribute('data-border-width') || '1px',
      },
      borderColor: {
        default: DEFAULT_BORDER_COLOR,
        renderHTML: (attributes) => ({ 'data-border-color': attributes.borderColor }),
        parseHTML: (element) => element.getAttribute('data-border-color') || DEFAULT_BORDER_COLOR,
      },
      borderStyle: {
        default: 'none',
        renderHTML: (attributes) => ({ 'data-border-style': attributes.borderStyle }),
        parseHTML: (element) => element.getAttribute('data-border-style') || 'none',
      },

      linkedinFill: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-linkedin-fill'),
        renderHTML: (attributes) => ({ 'data-linkedin-fill': attributes.linkedinFill }),
      },
      linkedinBackground: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-linkedin-background'),
        renderHTML: (attributes) => ({ 'data-linkedin-background': attributes.linkedinBackground }),
      },
      linkedinBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-linkedin-border-width'),
        renderHTML: (attributes) => ({ 'data-linkedin-border-width': attributes.linkedinBorderWidth }),
      },
      linkedinBorderColor: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-linkedin-border-color'),
        renderHTML: (attributes) => ({ 'data-linkedin-border-color': attributes.linkedinBorderColor }),
      },
      linkedinBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-linkedin-border-radius'),
        renderHTML: (attributes) => ({ 'data-linkedin-border-radius': attributes.linkedinBorderRadius }),
      },
      linkedinBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-linkedin-border-style'),
        renderHTML: (attributes) => ({ 'data-linkedin-border-style': attributes.linkedinBorderStyle }),
      },

      // Twitter attributes
      twitterFill: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-twitter-fill'),
        renderHTML: (attributes) => ({ 'data-twitter-fill': attributes.twitterFill }),
      },
      twitterBackground: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-twitter-background'),
        renderHTML: (attributes) => ({ 'data-twitter-background': attributes.twitterBackground }),
      },
      twitterBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-twitter-border-width'),
        renderHTML: (attributes) => ({ 'data-twitter-border-width': attributes.twitterBorderWidth }),
      },
      twitterBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-twitter-border-color'),
        renderHTML: (attributes) => ({ 'data-twitter-border-color': attributes.twitterBorderColor }),
      },
      twitterBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-twitter-border-radius'),
        renderHTML: (attributes) => ({ 'data-twitter-border-radius': attributes.twitterBorderRadius }),
      },
      twitterBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-twitter-border-style'),
        renderHTML: (attributes) => ({ 'data-twitter-border-style': attributes.twitterBorderStyle }),
      },

      // Facebook attributes
      facebookFill: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-facebook-fill'),
        renderHTML: (attributes) => ({ 'data-facebook-fill': attributes.facebookFill }),
      },
      facebookBackground: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-facebook-background'),
        renderHTML: (attributes) => ({ 'data-facebook-background': attributes.facebookBackground }),
      },
      facebookBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-facebook-border-width'),
        renderHTML: (attributes) => ({ 'data-facebook-border-width': attributes.facebookBorderWidth }),
      },
      facebookBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-facebook-border-color'),
        renderHTML: (attributes) => ({ 'data-facebook-border-color': attributes.facebookBorderColor }),
      },
      facebookBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-facebook-border-radius'),
        renderHTML: (attributes) => ({ 'data-facebook-border-radius': attributes.facebookBorderRadius }),
      },
      facebookBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-facebook-border-style'),
        renderHTML: (attributes) => ({ 'data-facebook-border-style': attributes.facebookBorderStyle }),
      },

      // Instagram attributes
      instagramFill: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-instagram-fill'),
        renderHTML: (attributes) => ({ 'data-instagram-fill': attributes.instagramFill }),
      },
      instagramBackground: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-instagram-background'),
        renderHTML: (attributes) => ({ 'data-instagram-background': attributes.instagramBackground }),
      },
      instagramBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-instagram-border-width'),
        renderHTML: (attributes) => ({ 'data-instagram-border-width': attributes.instagramBorderWidth }),
      },
      instagramBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-instagram-border-color'),
        renderHTML: (attributes) => ({ 'data-instagram-border-color': attributes.instagramBorderColor }),
      },
      instagramBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-instagram-border-radius'),
        renderHTML: (attributes) => ({ 'data-instagram-border-radius': attributes.instagramBorderRadius }),
      },
      instagramBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-instagram-border-style'),
        renderHTML: (attributes) => ({ 'data-instagram-border-style': attributes.instagramBorderStyle }),
      },

      // TikTok attributes
      tiktokFill: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-tiktok-fill'),
        renderHTML: (attributes) => ({ 'data-tiktok-fill': attributes.tiktokFill }),
      },
      tiktokBackground: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-tiktok-background'),
        renderHTML: (attributes) => ({ 'data-tiktok-background': attributes.tiktokBackground }),
      },
      tiktokBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-tiktok-border-width'),
        renderHTML: (attributes) => ({ 'data-tiktok-border-width': attributes.tiktokBorderWidth }),
      },
      tiktokBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-tiktok-border-color'),
        renderHTML: (attributes) => ({ 'data-tiktok-border-color': attributes.tiktokBorderColor }),
      },
      tiktokBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-tiktok-border-radius'),
        renderHTML: (attributes) => ({ 'data-tiktok-border-radius': attributes.tiktokBorderRadius }),
      },
      tiktokBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-tiktok-border-style'),
        renderHTML: (attributes) => ({ 'data-tiktok-border-style': attributes.tiktokBorderStyle }),
      },

      // YouTube attributes
      youtubeFill: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-youtube-fill'),
        renderHTML: (attributes) => ({ 'data-youtube-fill': attributes.youtubeFill }),
      },
      youtubeBackground: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-youtube-background'),
        renderHTML: (attributes) => ({ 'data-youtube-background': attributes.youtubeBackground }),
      },
      youtubeBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-youtube-border-width'),
        renderHTML: (attributes) => ({ 'data-youtube-border-width': attributes.youtubeBorderWidth }),
      },
      youtubeBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-youtube-border-color'),
        renderHTML: (attributes) => ({ 'data-youtube-border-color': attributes.youtubeBorderColor }),
      },
      youtubeBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-youtube-border-radius'),
        renderHTML: (attributes) => ({ 'data-youtube-border-radius': attributes.youtubeBorderRadius }),
      },
      youtubeBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-youtube-border-style'),
        renderHTML: (attributes) => ({ 'data-youtube-border-style': attributes.youtubeBorderStyle }),
      },

      // Discord attributes
      discordFill: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-discord-fill'),
        renderHTML: (attributes) => ({ 'data-discord-fill': attributes.discordFill }),
      },
      discordBackground: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-discord-background'),
        renderHTML: (attributes) => ({ 'data-discord-background': attributes.discordBackground }),
      },
      discordBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-discord-border-width'),
        renderHTML: (attributes) => ({ 'data-discord-border-width': attributes.discordBorderWidth }),
      },
      discordBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-discord-border-color'),
        renderHTML: (attributes) => ({ 'data-discord-border-color': attributes.discordBorderColor }),
      },
      discordBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-discord-border-radius'),
        renderHTML: (attributes) => ({ 'data-discord-border-radius': attributes.discordBorderRadius }),
      },
      discordBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-discord-border-style'),
        renderHTML: (attributes) => ({ 'data-discord-border-style': attributes.discordBorderStyle }),
      },

      // Threads attributes
      threadsFill: {
        default: '#FFFFFF',
        parseHTML: (element) => element.getAttribute('data-threads-fill'),
        renderHTML: (attributes) => ({ 'data-threads-fill': attributes.threadsFill }),
      },
      threadsBackground: {
        default: DEFAULT_PRIMARY_COLOR,
        parseHTML: (element) => element.getAttribute('data-threads-background'),
        renderHTML: (attributes) => ({ 'data-threads-background': attributes.threadsBackground }),
      },
      threadsBorderWidth: {
        default: '1px',
        parseHTML: (element) => element.getAttribute('data-threads-border-width'),
        renderHTML: (attributes) => ({ 'data-threads-border-width': attributes.threadsBorderWidth }),
      },
      threadsBorderColor: {
        default: DEFAULT_BORDER_COLOR,
        parseHTML: (element) => element.getAttribute('data-threads-border-color'),
        renderHTML: (attributes) => ({ 'data-threads-border-color': attributes.threadsBorderColor }),
      },
      threadsBorderRadius: {
        default: DEFAULT_BORDER_RADIUS,
        parseHTML: (element) => element.getAttribute('data-threads-border-radius'),
        renderHTML: (attributes) => ({ 'data-threads-border-radius': attributes.threadsBorderRadius }),
      },
      threadsBorderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-threads-border-style'),
        renderHTML: (attributes) => ({ 'data-threads-border-style': attributes.threadsBorderStyle }),
      },

      data: {
        default: {},
        parseHTML: (element) => element.getAttribute('data-data'),
        renderHTML: (attributes) => ({ 'data-data': attributes.data }),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, 'data-type': this.name }];
  },

  parseHTML() {
    return [{ tag: `div[data-type=${this.name}]` }];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SocialsView, {
      stopEvent: () => false,
      attrs: (props) => {
        return {
          ...props.HTMLAttributes,
          style: `width: ${props.node.attrs.width};max-width: ${props.node.attrs.maxWidth};`,
        };
      },
    });
  },
});
