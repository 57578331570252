import { useCallback, useMemo, useState } from 'react';
import { Empty, X } from '@phosphor-icons/react';
import classNames from 'classnames';

import {
  BottomIcon,
  BottomLeftIcon,
  BottomRightIcon,
  LeftIcon,
  LeftRightIcon,
  RightIcon,
  TopBottomIcon,
  TopIcon,
  TopLeftIcon,
  TopRightIcon,
} from '../../../Icons/BorderIcons';
import { Button } from '../../../UI/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { AttributeDropdown } from '../helpers/AttributeDropdown';
import BoxModelToggle from '../helpers/BoxModelToggle';
import { AttributeSettingProps } from '../types';
import { getTRBLValue } from '../utils/getTRBLValue';
import { parseCssValue } from '../utils/parseCssValue';

import { ColorSettings } from './ColorSettings';

type TRBL = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type BorderSettingsProps = AttributeSettingProps & {
  properties?: {
    color: string;
    selectedColor?: string;
    style: string;
    width: string;
    radius: string;
  };
  showRadiusSettings?: boolean;
};

const DEFAULT_MAPPING = {
  color: 'borderColor',
  style: 'borderStyle',
  width: 'borderWidth',
  radius: 'borderRadius',
};

const DEFAULT_COLOR = '#000000FF';

const getStringValue = (value: TRBL, unit: string) => {
  return `${value.top}${unit} ${value.right}${unit} ${value.bottom}${unit} ${value.left}${unit}`;
};

const DEFAULT_BORDER_WIDTH = '1px';

const DEFAULT_BORDER_RADIUS = '0px';

const BorderSettings = ({
  editor,
  title = 'Border',
  activeNodeResult,
  properties = DEFAULT_MAPPING,
  showRadiusSettings = true,
}: BorderSettingsProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { radius, width } = useMemo(() => {
    const borderRadius = getTRBLValue(activeNodeAttributes[properties.radius] || DEFAULT_BORDER_RADIUS);
    const borderWidth = getTRBLValue(activeNodeAttributes[properties.width] || DEFAULT_BORDER_WIDTH);

    return {
      width: {
        top: parseCssValue(borderWidth.top).value,
        right: parseCssValue(borderWidth.right).value,
        bottom: parseCssValue(borderWidth.bottom).value,
        left: parseCssValue(borderWidth.left).value,
      },
      radius: {
        top: parseCssValue(borderRadius.top).value,
        right: parseCssValue(borderRadius.right).value,
        bottom: parseCssValue(borderRadius.bottom).value,
        left: parseCssValue(borderRadius.left).value,
      },
    };
  }, [activeNodeAttributes, properties]);

  const isNone = activeNodeAttributes[properties.style] === 'none';
  const colors = properties.selectedColor
    ? [activeNodeAttributes[properties.color], activeNodeAttributes[properties.selectedColor]]
    : [activeNodeAttributes[properties.color]];

  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, properties.style, 'none');
        tr.setNodeAttribute(activeNodePos, properties.color, DEFAULT_COLOR);
        tr.setNodeAttribute(activeNodePos, properties.width, DEFAULT_BORDER_WIDTH);
        tr.setNodeAttribute(activeNodePos, properties.radius, DEFAULT_BORDER_RADIUS);
        if (properties.selectedColor) {
          tr.setNodeAttribute(activeNodePos, properties.selectedColor, DEFAULT_COLOR);
        }
        return true;
      });
      setIsPopoverOpen(false);
    },
    [editor, activeNodePos, properties]
  );

  const handleTRBLChange = useCallback(
    (propertyName: string, defaultValues: string) =>
      (position: 'top' | 'right' | 'bottom' | 'left' | 'all' | 'top-bottom' | 'left-right', value: number) => {
        const currentTRBL = getTRBLValue(activeNodeAttributes[propertyName] || defaultValues);
        let updatedTRBL = {
          top: parseCssValue(currentTRBL.top).value,
          right: parseCssValue(currentTRBL.right).value,
          bottom: parseCssValue(currentTRBL.bottom).value,
          left: parseCssValue(currentTRBL.left).value,
        };

        if (position === 'all') {
          updatedTRBL = { top: value, right: value, bottom: value, left: value };
        } else if (position === 'top-bottom') {
          updatedTRBL.top = value;
          updatedTRBL.bottom = value;
        } else if (position === 'left-right') {
          updatedTRBL.left = value;
          updatedTRBL.right = value;
        } else {
          updatedTRBL[position] = value;
        }

        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, propertyName, getStringValue(updatedTRBL, 'px'));
          return true;
        });
      },
    [editor, activeNodePos, activeNodeAttributes]
  );

  const handleColorChange = useCallback(
    (color: string | null) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, properties.color, color || DEFAULT_COLOR);
        return true;
      });
    },
    [editor, activeNodePos, properties]
  );

  const handleSelectedColorChange = useCallback(
    (color: string | null) => {
      editor.commands.command(({ tr }) => {
        if (!properties.selectedColor) {
          return false;
        }

        tr.setNodeAttribute(activeNodePos, properties.selectedColor, color || DEFAULT_COLOR);
        return true;
      });
    },
    [editor, activeNodePos, properties]
  );

  const handleUpdateBorderStyle = useCallback(
    (style: string) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, properties.style, style);
        return true;
      });
    },
    [editor, activeNodePos, properties]
  );

  const dropdownOptions = useMemo(
    () => [
      {
        label: 'None',
        onSelect: () => handleUpdateBorderStyle('none'),
      },
      {
        label: 'Solid',
        onSelect: () => handleUpdateBorderStyle('solid'),
      },
      {
        label: 'Dashed',
        onSelect: () => handleUpdateBorderStyle('dashed'),
      },
      {
        label: 'Dotted',
        onSelect: () => handleUpdateBorderStyle('dotted'),
      },
    ],
    [handleUpdateBorderStyle]
  );

  return (
    <>
      <Popover
        open={isPopoverOpen}
        onOpenChange={(open) => {
          setIsPopoverOpen(open);
        }}
      >
        <PopoverTrigger asChild>
          <div className="flex items-center justify-stretch gap-2 select-none">
            <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
              {title}
            </Text>

            <div className="grow bg-wb-secondary rounded-lg shadow-sm">
              <div className="w-full justify-between flex items-center gap-2 p-2 cursor-pointer">
                <div className={classNames('flex items-center', colors.length > 1 ? 'gap-2' : 'gap-1')}>
                  {isNone ? (
                    <Empty className="text-wb-secondary" weight="bold" />
                  ) : (
                    <div className="flex items-center gap-1">
                      {colors.map((color: string) => (
                        <div className="w-4 h-4 rounded-md" style={{ backgroundColor: color }} />
                      ))}
                    </div>
                  )}
                  <Text size="2xs" weight="medium" className="capitalize">
                    {activeNodeAttributes[properties.style]}
                  </Text>
                </div>
                {!isNone && (
                  <Button
                    variant="ghost"
                    Icon={X}
                    iconClassName="text-wb-secondary w-3 h-3"
                    onClick={handleReset}
                    className="p-0"
                  />
                )}
              </div>
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-[275px]" align="start" side="left" sideOffset={20}>
          <div className="max-h-[500px] overflow-y-auto flex flex-col gap-2 pb-0.5">
            <Text size="sm" weight="semibold">
              {title}
            </Text>

            <AttributeDropdown
              title="Style"
              defaultValue={activeNodeAttributes[properties.style]}
              options={dropdownOptions}
            />

            <div className={classNames(isNone ? 'opacity-50 pointer-events-none' : '', 'flex flex-col gap-2')}>
              <ColorSettings
                editor={editor}
                title="Color"
                property={properties.color}
                activeNodeResult={activeNodeResult}
                onOverrideSetColor={handleColorChange}
              />
              {properties.selectedColor && (
                <ColorSettings
                  editor={editor}
                  title="Selected"
                  property={properties.selectedColor}
                  activeNodeResult={activeNodeResult}
                  onOverrideSetColor={handleSelectedColorChange}
                />
              )}

              <BoxModelToggle
                title="Width"
                defaultValues={width}
                onUpdate={handleTRBLChange(properties.width, DEFAULT_BORDER_WIDTH)}
                suffixes={{
                  top: <TopIcon />,
                  right: <RightIcon />,
                  bottom: <BottomIcon />,
                  left: <LeftIcon />,
                  topBottom: <TopBottomIcon />,
                  leftRight: <LeftRightIcon />,
                }}
              />
            </div>
          </div>
        </PopoverContent>
      </Popover>

      {showRadiusSettings && (
        <BoxModelToggle
          title="Radius"
          defaultValues={radius}
          suffixes={{
            top: <TopLeftIcon />,
            right: <TopRightIcon />,
            bottom: <BottomRightIcon />,
            left: <BottomLeftIcon />,
          }}
          showCombinedValues={false}
          onUpdate={handleTRBLChange(properties.radius, DEFAULT_BORDER_RADIUS)}
        />
      )}
    </>
  );
};

export default BorderSettings;
