import { Extension, isNodeSelection, JSONContent, RawCommands } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';

import { applyThemeToNode } from '../../utils/applyThemeToNode';
import { HiddenAllSelection } from '../CustomSelections';
import { HoverStorage } from '../Hover';

import {
  getCustomHardBreakPlugin,
  getCustomPastePlugin,
  getFilterDuplicateSections,
  getIgnoreTextInputOnNodes,
  getNonDeletableNodes,
} from './plugins';

type InsertContentAtParams = Parameters<RawCommands['insertContentAt']>;

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customBehaviors: {
      /**
       * Select the parent text block when selection is a cursor
       */
      selectTextBlock: () => ReturnType;
      /**
       * Focus doc
       */
      focusDoc: () => ReturnType;
      /**
       * Reset color
       */
      resetColor: () => ReturnType;
      /**
       * Insert themed content
       */
      insertThemedContentAt: (
        pos: InsertContentAtParams[0],
        content: JSONContent | JSONContent[],
        options?: InsertContentAtParams[2]
      ) => ReturnType;
    };
  }
}

interface CustomBehaviorsOptions {
  nonDeletableNodes: string[];
}

export const CustomBehaviors = Extension.create<CustomBehaviorsOptions>({
  name: 'customBehaviors',

  addOptions() {
    return {
      nonDeletableNodes: [],
    };
  },

  addCommands() {
    return {
      selectTextBlock:
        () =>
        ({ state: { selection }, dispatch, commands }) => {
          if (!dispatch || !(selection instanceof TextSelection) || !selection.$cursor) {
            return false;
          }

          const { $cursor } = selection;

          if (!$cursor.parent.isTextblock) {
            return false;
          }

          return commands.setNodeSelection($cursor.before());
        },
      focusDoc:
        () =>
        ({ chain }) => {
          return chain()
            .command(({ tr, dispatch }) => {
              if (dispatch) {
                tr.setSelection(new HiddenAllSelection(tr.doc));
              }

              return true;
            })
            .focus(undefined, { scrollIntoView: false })
            .run();
        },

      resetColor:
        () =>
        ({ chain }) => {
          return chain().updateAttributes('textStyle', { color: null }).run();
        },

      insertThemedContentAt:
        (pos, content, options) =>
        ({ commands, editor }) => {
          const { selectedTheme, themeRules: themeMapping } = editor.storage.hover as HoverStorage;

          const contentCopy = structuredClone(content);

          if (selectedTheme && themeMapping) {
            if (Array.isArray(contentCopy)) {
              contentCopy.forEach((nodeJSON) => applyThemeToNode(nodeJSON, selectedTheme, themeMapping));
            } else {
              applyThemeToNode(contentCopy, selectedTheme, themeMapping);
            }
          }

          return commands.insertContentAt(pos, contentCopy, options);
        },
    };
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      getIgnoreTextInputOnNodes(),
      getNonDeletableNodes(this.options.nonDeletableNodes),
      getCustomPastePlugin(editor),
      getFilterDuplicateSections(),
      getCustomHardBreakPlugin(editor),
    ];
  },

  addKeyboardShortcuts() {
    return {
      // inserts a line-break instead of inserting a new paragraph on Enter
      // See https://linear.app/beehiiv/issue/WEB-1056/dont-create-new-text-element-on-enter
      Enter: ({ editor }) => {
        if (this.editor.isActive('listItem')) {
          return false;
        }

        return editor.chain().setHardBreak().setMeta('customHardBreakTr', true).run();
      },

      // focuses the doc when deleting selected node to avoid confusion
      // See https://linear.app/beehiiv/issue/WEB-1234/layer-behavior-issue-when-deleting-a-layer
      Backspace: ({ editor }) => {
        return isNodeSelection(editor.state.selection) ? editor.chain().deleteSelection().focusDoc().run() : false;
      },
    };
  },
});
