import { Link as PostEditorLink } from '@/components/TiptapEditor/extensions/Link';

import { getBooleanAttribute } from '../../utils/attributesUtils';

export const Link = PostEditorLink.extend({
  addAttributes() {
    return {
      ...this.parent?.(),

      hasUnderline: getBooleanAttribute('hasUnderline', 'data-has-underline', false),
    };
  },
});
