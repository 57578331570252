import { SitePackage } from '@/interfaces/site_package';
import { SiteTemplate } from '@/interfaces/site_template';
import { cn } from '@/utils/cn';

import TemplatePreviewer from '../../_components/Templates/TemplatePreviewer';
import { Label } from '../../_components/UI/Label';
import { Text } from '../../_components/UI/Text';

import Heading from './Heading';

interface Props {
  selectedProject?: SitePackage | null;
  setSelectedProject: (project: SitePackage) => void;
  projects: SitePackage[];
  onNext: () => void;
  isDisabled?: boolean;
  title?: string;
  description?: string;
}

const Step1 = ({ projects, selectedProject, setSelectedProject, onNext, isDisabled, title, description }: Props) => {
  return (
    <div className="w-full flex flex-col gap-8">
      <Heading
        title={title || 'Select a template'}
        description={description || 'Choose a template to get started'}
        onNext={onNext}
        isDisabled={isDisabled}
      />

      <div className="grid grid-cols-3 gap-4 mx-auto">
        {projects?.map((project) => {
          const isSelected = selectedProject?.id === project.id;
          const homePage = project.templates.find((template: SiteTemplate) => template.name === 'Home');

          return (
            <div className="relative ">
              <Label
                key={project.id}
                htmlFor={project.id}
                onClick={() => setSelectedProject(project)}
                className={cn(
                  'flex flex-col cursor-pointer shadow-lg transition-all duration-200 p-0 rounded-xl focus:outline-none relative justify-center items-center z-10',
                  'hover:shadow-2xl hover:scale-[1.03]',
                  isSelected ? 'border-wb-accent border-2' : 'border-white border-2'
                )}
              >
                {homePage ? (
                  <TemplatePreviewer
                    navbarContent={project.navbar}
                    content={homePage?.content}
                    hasBrowserBar={false}
                    containerHeightClass="h-[250px] border-transparent"
                    enableScroll
                    disableMemo
                    allowClicking={false}
                  />
                ) : null}
              </Label>

              <div className="flex justify-center items-center pt-2">
                <Text weight="bold" variant="primary" size="xl" as="span" className="">
                  {project.name}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Step1;
