import { useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ArrowPathIcon, BanknotesIcon, LinkIcon } from '@heroicons/react/24/outline';

import Banner from '@/components/Banner';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import LoadingBox from '@/components/LoadingBox';
import Modal from '@/components/Modal';
import { EmptyCard } from '@/components/ResourceList';
import { useSettings } from '@/context/settings-context';
import useBoostOffers from '@/hooks/boosts/monetize/useBoostOffers';
import useFilters from '@/hooks/boosts/monetize/useBoostSends/useFilters';
import useCreateSnoozedBoostOffer from '@/hooks/boosts/monetize/useCreateSnoozedBoostOffer';
import useStripeConnectAccount, { StripeAccountStatus } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import useOptions from '@/hooks/useOptions';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { BoostOffer } from '@/interfaces/boosts/monetize/boost_offer';
import { Option } from '@/interfaces/general';
import { Button } from '@/ui/Button';
import { ResourceListFilters } from '@/ui/ResourceListFilters';
import countryData from '@/utils/countries.json';

import ConnectToStripe from '../components/ConnectToStripe';
import { BoostOfferCard } from '../Shared/BoostOfferCard';
import Teaser from '../Teaser';

import ApplyModal from './components/ApplyModal';
import BoostOfferModal from './components/BoostOfferModal';
import { BOOST_OFFER_SORT_OPTIONS } from './constants';

const BoostsMarketplace = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const boostOfferIdParam = searchParams.get('boost_offer_id');

  const location = useLocation();
  const locationState = location.state as { throughBoostInviteEmail?: boolean };

  const { settings } = useSettings();
  const publicationId = useCurrentPublicationId();

  // Load options for filters
  const tags = useOptions(publicationId, 'tags');
  const { data: tagsData } = tags;
  const loadedTagOptions = tagsData?.options;
  const tagOptions = useMemo(() => {
    if (loadedTagOptions?.length > 0) {
      return loadedTagOptions.map((option: [number, string]): Option => {
        const [id, value] = option;
        return {
          label: value,
          value: id.toString(),
        };
      });
    }

    return [];
  }, [loadedTagOptions]);

  const languages = useOptions(publicationId, 'languages');
  const { data: languagesData } = languages;
  const loadedLanguageOptions = languagesData?.options;
  const languageOptions = useMemo(() => {
    if (loadedLanguageOptions?.length > 0) {
      return loadedLanguageOptions.map((option: [number, string]): Option => {
        const [id, value] = option;
        return {
          label: value,
          value: id.toString(),
        };
      });
    }

    return [];
  }, [loadedLanguageOptions]);

  const countryOptions = useMemo(() => {
    return countryData.map((country: { name: string, code: string, flag: string }) => {
      return {
        label: country.name,
        value: country.code,
      };
    });
  }, []);

  // Setup filters
  const {
    sections: filterSections,
    filterValues,
    setSearch,
    setSort,
    hasActiveFilters,
    resetAllFilters,
  } = useFilters(tagOptions, languageOptions, countryOptions);

  // Load boost offers
  const createSnoozedBoostOffer = useCreateSnoozedBoostOffer({});

  const boostOffersQuery = useBoostOffers({
    perPage: 12,
    search: filterValues.search,
    sort: filterValues.sort.value,
    tagIds: filterValues.tagIds,
    tagOperator: filterValues.tagOperator,
    languageIds: filterValues.languageIds,
    languageOperator: filterValues.languageOperator,
    eligibleCountries: filterValues.eligibleCountries,
    sizeScores: filterValues.sizeScores,
    lastPostedWithinDaysAgo: filterValues.lastPostedWithinDaysAgo,
    minPerLeadCents: filterValues.minPerLeadCents,
    maxPerLeadCents: filterValues.maxPerLeadCents,
    minPayoutCents: filterValues.minPayoutCents,
    maxPayoutCents: filterValues.maxPayoutCents,
  });

  // Handle Stripe connection
  const { data: stripeConnectAccount, isLoading: isLoadingStripeAccount } = useStripeConnectAccount(publicationId);
  const boostsAccountStatus = stripeConnectAccount?.boosts_account_status || StripeAccountStatus.MISSING;
  const shouldConnectToStripe = !isLoadingStripeAccount && boostsAccountStatus !== StripeAccountStatus.ACTIVE;

  // UI state
  const showPendingInvitesBanner = locationState?.throughBoostInviteEmail === true;
  const [isConnectingToStripe, setIsConnectingToStripe] = useState(false);
  const [selectedBoost, setSelectedBoost] = useState<BoostOffer | null>(null);
  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);

  // Handle boost offers data
  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isError } = boostOffersQuery;
  const boostOffers = data?.pages.flatMap((page) => page.boost_offers) || [];
  const isNoResults = !isLoading && boostOffers.length === 0;

  const emptyResult = boostOffers.length === 0;
  const emptyCardDescription = hasActiveFilters ? 'No results found for current filters' : 'There are no applicable offers yet';
  const emptyCardPrimaryIcon = hasActiveFilters ? undefined : BanknotesIcon;
  const emptyCardPrimaryActionLabel = hasActiveFilters ? 'Reset Filters' : undefined;
  const onPrimaryActionClick = hasActiveFilters ? resetAllFilters : undefined;
  const emptyCardPrimaryActionIcon = hasActiveFilters ? ArrowPathIcon : undefined;

  // Handlers
  const handleClickConnectToStripe = () => {
    if (!settings?.boosts) {
      setIsUpsellModalOpen(true);
      return;
    }

    setIsConnectingToStripe(true);
  };

  const handleModalOpen = (id: string) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('boost_offer_id', id);
    setSearchParams(newParams);
  };

  const handleModalClose = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('boost_offer_id');
    setSearchParams(newParams);
  };

  const handleApply = ({ boostOffer }: { boostOffer: BoostOffer }) => {
    if (!settings?.boosts) {
      setIsUpsellModalOpen(true);
      return;
    }

    if (boostsAccountStatus !== StripeAccountStatus.ACTIVE) {
      setIsConnectingToStripe(true);
    } else {
      setSelectedBoost(boostOffer);
    }
  };

  const handleSnooze = (boostOfferId: string) => {
    createSnoozedBoostOffer.mutate(boostOfferId);
    refetch();
  };

  return (
    <div className="flex flex-col gap-y-6">
      <BoostOfferModal
        onClose={handleModalClose}
        onApply={handleApply}
        onSnooze={handleSnooze}
        boostOfferId={boostOfferIdParam}
      />

      {showPendingInvitesBanner && shouldConnectToStripe ? (
        <Banner
          className="mb-8"
          title="Pending Invites"
          bodyText={
            <>
              You have pending invites to Boost other publications. To review them and earn additional revenue,{' '}
              <button type="button" onClick={handleClickConnectToStripe}>
                <strong>connect</strong>
              </button>{' '}
              a Stripe account using the button below.
            </>
          }
          isScreenWide={false}
        />
      ) : null}

      <PageHeading
        title="Boosts Marketplace"
        description="Monetize your newsletter by boosting other publications in the beehiiv network."
      >
        {shouldConnectToStripe && (
          <Button Icon={LinkIcon} type="button" onClick={handleClickConnectToStripe}>
            Connect to Stripe
          </Button>
        )}

        <ConnectToStripe
          boostsAccountStatus={boostsAccountStatus}
          isOpen={isConnectingToStripe}
          onClose={() => setIsConnectingToStripe(false)}
          publicationId={publicationId}
        />

        <ApplyModal
          isOpen={!!selectedBoost}
          onClose={() => setSelectedBoost(null)}
          boostOffer={selectedBoost}
          onProceed={() => setSelectedBoost(null)}
        />
      </PageHeading>

      <ResourceListFilters
        search={filterValues.search}
        setSearch={setSearch}
        searchPlaceholder="Search offers"
        filterSections={filterSections}
        onClearFilters={resetAllFilters}
        sortOrderOptions={BOOST_OFFER_SORT_OPTIONS}
        orderBy={filterValues.sort.value}
        setOrderBy={(value) => setSort(BOOST_OFFER_SORT_OPTIONS.find(opt => opt.value === value) || BOOST_OFFER_SORT_OPTIONS[0])}
        orderByPrefixText='Sort by: '
      />

      <LoadingBox isLoading={isLoading} isError={isError}>
        {isNoResults || emptyResult ? (
          <EmptyCard
            title="No offers"
            description={emptyCardDescription}
            primaryIcon={emptyCardPrimaryIcon}
            primaryActionIcon={emptyCardPrimaryActionIcon}
            primaryActionLabel={emptyCardPrimaryActionLabel}
            onPrimaryActionClick={onPrimaryActionClick}
            isFiltering={hasActiveFilters}
          />
        ) : (
          <div className="grid grid-flow-row gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {boostOffers.map((boostOffer) => (
              <BoostOfferCard
                key={boostOffer.id}
                boostOffer={boostOffer}
                onApply={() => handleApply({ boostOffer })}
                onExpand={() => handleModalOpen(boostOffer.id)}
                onSnooze={handleSnooze}
              />
            ))}
          </div>
        )}

        {hasNextPage && (
          <div className="my-6 text-center">
            <Button
              variant="primary-inverse"
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load more'}
            </Button>
          </div>
        )}
      </LoadingBox>

      <Modal isOpen={isUpsellModalOpen} onClose={() => setIsUpsellModalOpen(false)}>
        <div className="m-6">
          <Teaser />
        </div>
      </Modal>
    </div>
  );
};

export default BoostsMarketplace;
