import React from 'react';
import { useNavigate } from 'react-router-dom';

import Banner from '@/components/Banner';
import DmarcWarningBanner from '@/components/DmarcWarningBanner';
import LoadingBox from '@/components/LoadingBox';
import { useCustomDomains } from '@/hooks';
import { usePublication } from '@/hooks/usePublications';

import PageContainer from '../../_components/PageContainer';
import { usePublicationSettings } from '../context';

import ConnectDomainCard from './components/ConnectDomainCard';
import DnsRecordsModal from './components/DnsRecordsModal';
import DomainsCard from './components/DomainsCard';
import useVerificationModals from './hooks/useVerificationModals';

const PublicationDomainSettings: React.FC = () => {
  const navigate = useNavigate();
  const { currentPublicationId, settings } = usePublicationSettings();
  const {
    data: publication,
    isSuccess: isSuccessPublication,
    isError: isErrorPublication,
  } = usePublication(currentPublicationId);
  const { dnsRecordsModalOpen, handleCloseDnsRecordsModal, selectedDnsRecordType } = useVerificationModals();

  const customDomains = useCustomDomains(currentPublicationId);
  const { data: domains, isLoading, isError } = customDomains;
  const webDomain = domains?.find((domain) => domain.web_enabled || domain.web_verification_pending);
  const emailDomain = domains?.find((domain) => domain.email_enabled || domain.email_verification_pending);
  const redirectDomain = domains?.find(
    (domain) => domain.web_redirect_enabled || domain.web_redirect_verification_pending
  );
  const onSharedInfra = !webDomain && !emailDomain;
  const smartWarmingActive = emailDomain?.warming_up || false;

  const canCustomDomain = settings?.custom_domains === true;
  const canRedirectDomain = settings?.apex_domains === true;
  const showOnboardingStep =
    canCustomDomain && !emailDomain && !webDomain && (!canRedirectDomain || (canRedirectDomain && !redirectDomain));

  return (
    <LoadingBox isLoading={!isSuccessPublication || isLoading} isError={isError || isErrorPublication}>
      {publication ? (
        <>
          <DnsRecordsModal
            stepType={selectedDnsRecordType}
            isOpen={dnsRecordsModalOpen}
            onClose={handleCloseDnsRecordsModal}
          />

          <PageContainer key={currentPublicationId}>
            {!canCustomDomain && (
              <Banner
                variant="warning"
                isScreenWide={false}
                title="Custom domains are only supported on the Grow plan or higher."
                ctaText="Upgrade"
                onClick={() => navigate('/settings/workspace/billing_and_plan')}
              />
            )}
            {emailDomain && <DmarcWarningBanner publicationId={currentPublicationId} customDomain={emailDomain} variant="compact" />}
            <ConnectDomainCard showOnboardingStep={showOnboardingStep} canEntri={settings?.entri || false} />
            <DomainsCard
              publication={publication}
              emailDomain={emailDomain}
              webDomain={webDomain}
              redirectDomain={redirectDomain}
              onSharedInfra={onSharedInfra}
              smartWarmingActive={smartWarmingActive}
              disabled={!canCustomDomain}
            />
          </PageContainer>
        </>
      ) : null}
    </LoadingBox>
  );
};

export default PublicationDomainSettings;
