import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Typography } from '@/components/Typography';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import useUpdateBoostOffer from '@/hooks/boosts/grow/useUpdateBoostOffer';
import useWallet from '@/hooks/boosts/useWallet';
import {
  BoostOffer,
  BoostOfferStatus,
  BoostOfferStatusTransitions,
  BoostOfferTransitionLabels,
} from '@/interfaces/boosts/grow/boost_offer';
import { EllipsisDropdownOption } from '@/interfaces/general';
import analytics from '@/utils/analytics';

interface Props {
  boostOffer: BoostOffer;
  onSuccess?: () => {};
  additionalOptions?: EllipsisDropdownOption[];
}

const BoostOfferActionsDropdown = ({ boostOffer, onSuccess, additionalOptions }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();
  const [requestedTransition, setRequestedTransition] = useState<BoostOfferStatus | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSuccess = () => {
    onSuccess?.();
    setIsModalOpen(false);
    toast.success('Boost agreement updated.');
  };

  const { mutateAsync: updateBoostOffer, isLoading: isUpdating } = useUpdateBoostOffer({
    id: boostOffer.id,
    onSuccess: handleSuccess,
  });
  const { data: wallet } = useWallet();
  const available = wallet?.available_balance_cents || 0;
  const cost = boostOffer?.cost_per_lead_cents || 0;
  const minimumNeeded = cost * 10;

  const availableTransitions = BoostOfferStatusTransitions[boostOffer?.status || ''] || [];
  const transitionOptions: EllipsisDropdownOption[] = availableTransitions.map((transition: BoostOfferStatus) => {
    const optDisabled = transition === BoostOfferStatus.LIVE && available <= minimumNeeded;

    return {
      label: BoostOfferTransitionLabels[transition],
      isVisible: !optDisabled,
      onClick: () => {
        setRequestedTransition(transition);
        setIsModalOpen(true);
      },
    };
  });

  const pageOptions: EllipsisDropdownOption[] = [BoostOfferStatus.LIVE, BoostOfferStatus.DRAFT].includes(
    boostOffer.status
  )
    ? [
        {
          label: 'Edit',
          onClick: () => navigate(`/grow/boosts/offers/${boostOffer.id}/edit`),
        },
      ]
    : [];

  const options: EllipsisDropdownOption[] = [...(additionalOptions || []), ...transitionOptions, ...pageOptions].filter(
    Boolean
  );

  return (
    <>
      <EllipsisDropdown options={options} />
      <ActionModal
        isOpen={isModalOpen}
        isWorking={isUpdating}
        resourceId={boostOffer.id}
        disabled={!isModalOpen}
        onClose={() => handleCloseModal()}
        onProceed={() => {
          if (requestedTransition) {
            updateBoostOffer({ status: requestedTransition });
            if (requestedTransition === BoostOfferStatus.INACTIVE) {
              analytics.track('Boost Offer Disabled');
            }
            if (requestedTransition === BoostOfferStatus.LIVE) {
              analytics.track('Boost Offer Re-enabled');
            }
            if (requestedTransition === BoostOfferStatus.ARCHIVED) {
              analytics.track('Boost Offer Archived');
            }
            queryClient.invalidateQueries([currentPublicationId, 'boosts', 'grow', 'primary_offer']);
          }
        }}
        headerText="Confirm Selection"
      >
        <div className="flex flex-col space-y-4">
          <Typography token="font-normal/text/base">
            Are you sure you want to {BoostOfferTransitionLabels[requestedTransition || '']} this boost offer?
          </Typography>
          {requestedTransition === BoostOfferStatus.INACTIVE && (
            <Typography token="font-normal/text/sm" colorWeight="700">
              Disabling this Boost offer will pause anyone actively boosting this offer. You can always re-activate it
              to resume boosts for this offer.
            </Typography>
          )}
          {requestedTransition === BoostOfferStatus.LIVE && (
            <Typography token="font-normal/text/sm" colorWeight="700">
              Publishing will make this offer visible in the marketplace. If you already have a live offer in the
              marketplace, this will replace that offer.
            </Typography>
          )}
          {requestedTransition === BoostOfferStatus.ARCHIVED && (
            <Typography token="font-normal/text/sm" colorWeight="700">
              Archiving will end all active boosts for this offer and you will not be able to re-activate this offer at
              any point.
            </Typography>
          )}
        </div>
      </ActionModal>
    </>
  );
};

export default BoostOfferActionsDropdown;
