import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ArrowLeft, Plus } from '@phosphor-icons/react';

import { useWebsitePageRouteGetter, WebsiteProvider } from '@/context/website-context';
import { cn } from '@/utils/cn';

import { ATTRIBUTES_PANEL_ID, LAYERS_PANEL_ID, SIDE_INSERT_PANEL_ID } from '../_components/DreamEditor/constants';
import { DesktopMobileTabs, useDesktopMobileTabs } from '../_components/Editor/DesktopMobileTabs';
import { EditorHeader } from '../_components/EditorHeader/EditorHeader';
import { Layout } from '../_components/Layout';
import { ActionMenu } from '../_components/Layout/ActionMenu';
import { NavSection } from '../_components/SideNav/NavSection';
import { SaveFooterIndicator } from '../_components/SideNav/SaveIndicator';
import { Button } from '../_components/UI/Button';
import { useContainerRef } from '../page/_hooks';

import { FooterEditor, FooterEditorProvider } from './_components/FooterEditor';

const FooterEditorPage = () => {
  const [searchParams] = useSearchParams();
  const previousPageID = searchParams.get('previousPageID');
  const [isInsertPanelOpen, setIsInsertPanelOpen] = useState(false);
  const pageRouteGetter = useWebsitePageRouteGetter();
  const homePageId = pageRouteGetter?.getHomePageID();

  const { rect: editorRect, containerRef: editorContainerRef } = useContainerRef();
  const { selectedTab, setSelectedTab, viewport } = useDesktopMobileTabs();

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Go To Dashboard',
        backPath: '/',
      }}
      mainClassName="bg-wb-secondary pb-0 pt-0"
      isSidebarResizable
      leftChildren={
        <ActionMenu
          Icon={Plus}
          text="Insert"
          isActive={isInsertPanelOpen}
          onClick={() => {
            setIsInsertPanelOpen((prev) => !prev);
          }}
        />
      }
      sidenavChildren={
        <>
          <NavSection>
            <Link to={`/website_builder_v2/page/${previousPageID || homePageId}`} className="w-full cursor-pointer">
              <Button variant="secondary" size="sm" LeftIcon={ArrowLeft} className="w-full py-3">
                Back to page
              </Button>
            </Link>
          </NavSection>
          <div className="flex-1">
            <NavSection>
              <div id={LAYERS_PANEL_ID} />
            </NavSection>
          </div>
          <NavSection>
            <SaveFooterIndicator />
          </NavSection>
          <div
            id={SIDE_INSERT_PANEL_ID}
            className={cn(
              'absolute top-0 left-0 bottom-0 min-w-[200px] max-w-[250px] transition-transform duration-250 bg-wb-primary border-r border-solid overflow-y-auto',
              isInsertPanelOpen ? 'translate-x-0' : `-translate-x-[500px]`
            )}
          />
        </>
      }
      rightSideChildren={
        <div
          className="w-[266px] min-w-[266px] h-full bg-wb-primary border-l border-solid border-wb-primary overflow-y-auto"
          style={{
            scrollbarGutter: 'stable',
          }}
          id={ATTRIBUTES_PANEL_ID}
        />
      }
      titleType="page_name"
    >
      <EditorHeader type="footer" iconType="component" />
      <DesktopMobileTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div
        ref={editorContainerRef}
        className={cn('h-full bg-transparent overflow-hidden w-full relative', {
          'w-[375px] m-auto': selectedTab === 'mobile',
          'w-full': selectedTab === 'desktop',
        })}
      >
        <FooterEditor editorRect={editorRect} viewport={viewport}>
          <div />
        </FooterEditor>
      </div>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <FooterEditorProvider>
      <FooterEditorPage />
    </FooterEditorProvider>
  </WebsiteProvider>
);
