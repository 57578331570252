import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BanknotesIcon,
  CheckIcon,
  MinusSmallIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';
import cx from 'classnames';
import moment from 'moment-mini';

import Card from '@/components/Card';
import { DataChart } from '@/components/charts';
import FaqModal from '@/components/FaqModal';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import LoadingBox from '@/components/LoadingBox';
import { useCurrentTimeZone } from '@/hooks';
import useTotalBalanceSnapshots from '@/hooks/wallet/useTotalBalanceSnapshots';
import { LedgerEntry, LedgerEntryCategory } from '@/interfaces/ledger_entry';
import { Button } from '@/ui/Button';

import useLedgerEntries from '../../hooks/boosts/useLedgerEntries';
import useWallet from '../../hooks/boosts/useWallet';

import WalletActions from './WalletActions';

const FeedItem = ({ ledgerEntry }: { ledgerEntry: LedgerEntry }) => {
  const mappings: {
    [key in LedgerEntryCategory]: {
      Description: any;
      Icon: any;
      bg: string;
    };
  } = {
    [LedgerEntryCategory.EARNINGS_DEPOSIT]: {
      Description: () => (
        <>
          <b>{ledgerEntry.formatted_amount}</b>
          {` earned from ${ledgerEntry.network_type}.`}
        </>
      ),
      Icon: BanknotesIcon,
      bg: 'bg-green-500',
    },
    [LedgerEntryCategory.EARNINGS_PAYOUT]: {
      Description: () => (
        <>
          <b>{ledgerEntry.formatted_amount}</b>
          {` paid for ${ledgerEntry.network_type}.`}
        </>
      ),
      Icon: CheckIcon,
      bg: 'bg-green-500',
    },
    [LedgerEntryCategory.STRIPE_DEPOSIT]: {
      Description: () => (
        <>
          <b>{ledgerEntry.formatted_amount}</b> added to your wallet.
        </>
      ),
      Icon: BanknotesIcon,
      bg: 'bg-primary-500',
    },
    [LedgerEntryCategory.STRIPE_PAYOUT]: {
      Description: () => (
        <>
          <b>{ledgerEntry.formatted_amount}</b> withdrawn from your wallet.
        </>
      ),
      Icon: MinusSmallIcon,
      bg: 'bg-gray-500',
    },
    [LedgerEntryCategory.MANUAL_ADJUSTMENT]: {
      Description: () => (
        <>
          Manual adjustment of <b>{ledgerEntry.formatted_amount}</b> applied.
        </>
      ),
      Icon: PencilIcon,
      bg: 'bg-gray-400',
    },
  };

  const { Icon } = mappings[ledgerEntry.category];
  const { Description } = mappings[ledgerEntry.category];

  return (
    <div className="relative flex space-x-3">
      <div>
        <span
          className={cx(
            mappings[ledgerEntry.category].bg,
            'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
          )}
        >
          <Icon className="h-5 w-5 text-white" aria-hidden="true" />
        </span>
      </div>
      <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
        <div>
          <p className="text-sm text-gray-500">
            <Description />
          </p>
          {ledgerEntry.description && <p className="mt-2 text-sm text-gray-700">{ledgerEntry.description}</p>}
        </div>
        <div className="whitespace-nowrap text-right text-sm text-gray-500">
          <time dateTime={ledgerEntry.created_at.toString()}>{moment(ledgerEntry.created_at).fromNow()}</time>
        </div>
      </div>
    </div>
  );
};

// This is the Wallet page for boosted publications. It will show the user's
// current balance and allow them to add funds to their account.
const Wallet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTimeZone = useCurrentTimeZone();

  const [isFaqModalOpen, setIsFaqModalOpen] = useState(false);

  const ledgerEntriesQuery = useLedgerEntries({});
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } = ledgerEntriesQuery;
  const ledgerEntries = data?.pages.flatMap((page) => page.ledger_entries) || [];
  const walletQuery = useWallet();
  const { data: walletData } = walletQuery;
  const snapshotsQuery = useTotalBalanceSnapshots({ timeZone: currentTimeZone, timePeriod: 'last_4_weeks' });

  /**
   * Adds polling to the page when the `success` query param is present. This is
   * used to get the latest ledger entries after a payment is made. It only runs for
   * a few seconds and then stops, but that should be plenty of time for the payment
   * to be processed.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    if (success === 'true') {
      toast.success('Your payment was successful!');

      // refetch every second until we get a new entry
      const interval = setInterval(() => {
        walletQuery.refetch();
        ledgerEntriesQuery.refetch();
      }, 1000);

      // stop polling after 3 seconds
      const timeout = setTimeout(() => {
        navigate('/wallet', { replace: true });
        clearInterval(interval);
      }, 3000);

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [location, navigate]);

  const onLoadMoreClick = () => {
    fetchNextPage();
  };

  return (
    <>
      <PageHeading title="Wallet">
        <WalletActions />
      </PageHeading>
      <hr />
      <div className="mt-4 grid grid-cols-6 gap-4">
        <div className="col-span-6 lg:col-span-3 xl:col-span-4">
          <div className="grid grid-cols-1 gap-4">
            <div className="col-span-1 space-y-2">
              <div className="flex items-center space-x-2">
                <h2 className="block text-md font-semibold text-gray-700">Overview</h2>
                <button
                  onClick={() => setIsFaqModalOpen(true)}
                  className="text-sm text-gray-500 underline"
                  type="button"
                >
                  <QuestionMarkCircleIcon className="h-4 w-4 text-gray-400 inline-block mr-1" />
                </button>
              </div>
              <div>
                <dl className="grid grid-cols-2 gap-4">
                  <LoadingBox isLoading={walletQuery.isLoading} isError={walletQuery.isError} height={130}>
                    {walletData && (
                      <Card className="col-span-2 xl:col-span-1">
                        <FaqModal
                          faqs={[
                            {
                              question: `What's the difference between total and available funds?`,
                              answer: `In an effort to avoid negative balance, we temporarily withhold funds during the Boosts verification phase, much like a bank setting a temporary charge on your credit card. After the verification is done, we return any surplus funds to your available balance, which is the amount you can still use for spending. For instance, if your Cost Per Acquisition (CPA) is $2.00 and there are 100 signups yet to be verified, we'll place a hold of $200.00. In this example, your available balance - the amount you're able to spend - will be your total balance minus this held $200.00.`,
                              defaultOpen: true,
                            },
                            {
                              question: `How long will my funds be held?`,
                              answer: `We understand how important it is for you to have access to your funds promptly. Our team works diligently to expedite the verification process, and we aim to make the period of funds being held as brief as possible. However, to ensure Boosts is delivering high-quality signups, the process can take anywhere from 10 to 17 days, depending on various factors. We appreciate your patience and understanding during this period, and we assure you that we're doing our best to complete the verification as quickly as we can.`,
                            },
                          ]}
                          isOpen={isFaqModalOpen}
                          onClose={() => setIsFaqModalOpen(false)}
                        />
                        <dt className="text-sm font-semibold text-gray-500 tracking-normal">Funds</dt>
                        <dd className="mt-1 truncate text-gray-500">
                          <span className="text-3xl font-black tracking-tight text-gray-800">
                            {walletData.available_balance}
                          </span>
                          <span className="ml-1 text-sm font-normal text-gray-500 tracking-normal">available</span>
                        </dd>
                        <dd className="mt-1 text-sm font-normal text-gray-500 tracking-normal truncate">
                          {walletData.total_balance} total
                        </dd>
                      </Card>
                    )}
                  </LoadingBox>
                  <LoadingBox isLoading={walletQuery.isLoading} isError={walletQuery.isError} height={130}>
                    {walletData && (
                      <Card className="col-span-2 xl:col-span-1">
                        <dt className="text-sm font-semibold text-gray-500 tracking-normal">Earnings</dt>
                        <dd className="mt-1 truncate text-gray-500">
                          <span className="text-3xl font-black tracking-tight text-gray-800">
                            {walletData.withdrawable_balance}
                          </span>
                          <span className="ml-1 text-sm font-normal text-gray-500 tracking-normal">available</span>
                        </dd>
                        <dd className="mt-1 text-sm font-normal text-gray-500 tracking-normal truncate">
                          {walletData.all_time_earnings} all time
                        </dd>
                      </Card>
                    )}
                  </LoadingBox>
                </dl>
              </div>
            </div>
            <div className="col-span-1 space-y-2">
              <h2 className="block text-md font-semibold text-gray-700">Last 30 Days</h2>
              <LoadingBox isLoading={snapshotsQuery.isLoading} isError={snapshotsQuery.isError} height={400}>
                <Card>
                  <DataChart data={snapshotsQuery.data?.data?.result?.data} height={400} />
                </Card>
                <p className="text-sm text-gray-500">Note: The dates listed above are displayed in UTC time.</p>
              </LoadingBox>
            </div>
          </div>
        </div>
        <div className="col-span-6 lg:col-span-3 xl:col-span-2 space-y-2">
          <h2 className="block text-md font-semibold text-gray-700">Activity</h2>
          <Card>
            <LoadingBox isLoading={isLoading} isError={isError} height={350}>
              {!isLoading && ledgerEntries.length === 0 ? (
                <div className="text-center my-6">
                  <p className="text-gray-500">No Transactions.</p>
                </div>
              ) : (
                <>
                  <div className="flow-root">
                    <ul className="-mb-8">
                      {ledgerEntries.map((ledgerEntry, eventIdx) => (
                        <li key={ledgerEntry.id}>
                          <div className="relative pb-8">
                            {eventIdx !== ledgerEntries.length - 1 ? (
                              <span
                                className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                                aria-hidden="true"
                              />
                            ) : null}
                            <FeedItem ledgerEntry={ledgerEntry} />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {hasNextPage && (
                    <div className="pt-6">
                      <Button variant="primary-inverse" onClick={onLoadMoreClick} loading={isFetchingNextPage} block>
                        {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </LoadingBox>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Wallet;
