import { useCallback, useMemo } from 'react';
import { NavbarSerializableNode } from '@shared/dream-components';

import BoxModelToggle from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/BoxModelToggle';
import { getTRBLValue } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/getTRBLValue';
import { parseCssValue } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/parseCssValue';
import {
  BottomLeftIcon,
  BottomRightIcon,
  TopLeftIcon,
  TopRightIcon,
} from '@/routes/website/_components/Icons/BorderIcons';

import { useNavbarContext } from '../../../NavbarContext';

type TRBL = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

type BorderSettingsProps = {
  selectedContent: NavbarSerializableNode;
  property?: string;
  defaults?: {
    borderRadius?: string;
  };
};

const getStringValue = (value: TRBL, unit: string) => {
  return `${value.top}${unit} ${value.right}${unit} ${value.bottom}${unit} ${value.left}${unit}`;
};

export const BorderRadiusSettings = ({ property = 'borderRadius', selectedContent, defaults }: BorderSettingsProps) => {
  const { onUpdateNodeAttributes } = useNavbarContext();

  const DEFAULT_BORDER_RADIUS = defaults?.borderRadius ?? '0px';

  const contentBorderRadiusValue =
    selectedContent?.attrs && property in selectedContent.attrs
      ? (selectedContent.attrs[property as keyof typeof selectedContent.attrs] as string)
      : DEFAULT_BORDER_RADIUS;

  const { radius } = useMemo(() => {
    const borderRadius = getTRBLValue(contentBorderRadiusValue || DEFAULT_BORDER_RADIUS);

    return {
      radius: {
        top: parseCssValue(borderRadius.top).value,
        right: parseCssValue(borderRadius.right).value,
        bottom: parseCssValue(borderRadius.bottom).value,
        left: parseCssValue(borderRadius.left).value,
      },
    };
  }, [contentBorderRadiusValue, DEFAULT_BORDER_RADIUS]);

  const handleTRBLChange = useCallback(
    (propertyName: string, defaultValues: string) =>
      (position: 'top' | 'right' | 'bottom' | 'left' | 'top-bottom' | 'left-right' | 'all', value: number) => {
        if (!selectedContent || !selectedContent.attrs?.id) return;
        const rawValue =
          selectedContent.attrs &&
          propertyName in selectedContent.attrs &&
          (selectedContent.attrs[propertyName as keyof typeof selectedContent.attrs] as string);
        const currentTRBL = getTRBLValue(rawValue || defaultValues);
        let updatedTRBL = {
          top: parseCssValue(currentTRBL.top).value,
          right: parseCssValue(currentTRBL.right).value,
          bottom: parseCssValue(currentTRBL.bottom).value,
          left: parseCssValue(currentTRBL.left).value,
        };

        if (position === 'all') {
          updatedTRBL = { top: value, right: value, bottom: value, left: value };
        } else if (position === 'top-bottom') {
          updatedTRBL = { top: value, bottom: value, left: updatedTRBL.left, right: updatedTRBL.right };
        } else if (position === 'left-right') {
          updatedTRBL = { left: value, right: value, top: updatedTRBL.top, bottom: updatedTRBL.bottom };
        } else {
          updatedTRBL[position] = value;
        }
        onUpdateNodeAttributes(selectedContent.attrs?.id, {
          [propertyName]: getStringValue(updatedTRBL, 'px'),
        });
      },
    [selectedContent, onUpdateNodeAttributes]
  );
  if (!selectedContent || !selectedContent.attrs?.id) return null;

  return (
    <BoxModelToggle
      title="Radius"
      defaultValues={radius}
      suffixes={{
        top: <TopLeftIcon />,
        right: <TopRightIcon />,
        bottom: <BottomRightIcon />,
        left: <BottomLeftIcon />,
      }}
      showCombinedValues={false}
      onUpdate={handleTRBLChange(property, DEFAULT_BORDER_RADIUS)}
    />
  );
};
