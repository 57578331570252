import React, { createContext, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { IUser } from '@/interfaces/user';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';
import { getAttributionCookies } from '@/utils/marketingCookies';

interface PasswordRequestVariables {
  email: string;
}

interface PasswordResetVariables {
  password: string;
  confirmPassword: string;
  tokenId: string;
  passwordToken: string;
  userId: string;
  otp: string;
  deviceConfirmationCode: string;
}

interface SignupResult {
  token: string;
  user: IUser;
}

interface LoginVariables {
  email: string;
  password: string;
  otp: string;
  deviceConfirmationCode: string;
}

interface InviteAcceptNewUserVariables {
  inviteId: string;
  inviteToken: string;
  tokenId: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
}

interface InviteAcceptExistingUserVariables {
  email: string;
  password: string;
  inviteId: string;
  inviteToken: string;
  tokenId: string;
  otp: string;
  deviceConfirmationCode: string;
}

interface InviteAcceptAuthenticatedUserVariables {
  inviteId: string;
  inviteToken: string;
  tokenId: string;
}

interface SignupProps {
  code: string;
  signupToken: string | null;
  number: string | null;
}

interface IAuthContext {
  isLoggedIn: boolean;
  isMasquerading: boolean;
  signup: UseMutationResult<SignupResult, unknown, SignupProps, unknown>;
  logout: any;
  login: any;
  loginWithToken: any;
  requestPassword: any;
  resetPassword: any;
  masquerade: UseMutationResult<void, unknown, { user_id: string }, unknown>;
  endMasquerade: UseMutationResult<void, unknown, void, unknown>;
  inviteAcceptNewUser: any;
  inviteAcceptExistingUser: any;
  inviteAcceptAuthenticatedUser: any;
  needsOtp: boolean;
  needsDeviceConfirmationCode: boolean;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

AuthContext.displayName = 'AuthContext';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();
  const attribution = getAttributionCookies();

  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [isMasquerading, setIsMasquerading] = useState(!!localStorage.getItem('masqueradeToken'));
  const [needsOtp, setNeedsOtp] = useState(false);
  const [needsDeviceConfirmationCode, setNeedsDeviceConfirmationCode] = useState(false);

  const loginWithToken = ({ token, userId }: { token: string; userId: string; email: string }) => {
    setIsLoggedIn(true);
    localStorage.setItem('token', token);

    if (userId) {
      analytics.identify(userId);
    }
  };

  const login = useMutation(
    async ({ email, password, otp, deviceConfirmationCode }: LoginVariables) => {
      const { data } = await api.post('/tokens', {
        email,
        password,
        otp,
        device_confirmation_code: deviceConfirmationCode,
      });
      return data;
    },
    {
      onSuccess: (data) => {
        if (data.code === 'OTP_REQUIRED') {
          setNeedsOtp(true);
        } else if (data.code === 'DEVICE_CONFIRMATION_REQUIRED') {
          setNeedsDeviceConfirmationCode(true);
        } else {
          loginWithToken({ token: data.token, userId: data.user.id, email: data.user.email });
        }
      },
    }
  );

  const signup = useMutation(
    ({ code, signupToken, number }: SignupProps) =>
      api
        .post(`/signups/verify_phone_number`, {
          code,
          number,
          signup_token: signupToken,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        analytics.alias(data.user.id, data.user.email);

        if (data.user.id) {
          analytics.identify(data.user.id, {
            firstName: data.user.first_name,
            lastName: data.user.last_name,
            email: data.user.email,
            emailVerified: true,
            phoneVerified: true,
            phoneMessageConsent: localStorage.getItem('phoneMessageConsent') === 'true',
            phone: localStorage.getItem('signupPhone'),
            attribution: {
              cookies: {
                ...(attribution?.fbc && { fbc: attribution.fbc }),
                ...(attribution?.fbp && { fbp: attribution.fbp }),
                ...(attribution?.ga?.slice(6) && { ga: attribution.ga.slice(6) }),
                ...(attribution?.gclid?.split('.')[2] && { gclid: attribution?.gclid?.split('.')[2] }),
                ...(attribution?.msclkid && { msclkid: attribution.msclkid }),
                ...(attribution?.li_fat_id && { li_fat_id: attribution.li_fat_id }),
                ...(attribution?.ttclid && { ttclid: attribution.ttclid }),
              },
              ...(attribution?.first && { first_touch: attribution.first }),
              ...(attribution?.last && { last_touch: attribution.last }),
            },
          });
        }

        analytics.track('Phone Verification', {
          email: data.user.email,
          phoneVerified: true,
        });

        analytics.track('Account Created', {
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          user_id: data.user.id,
          email: data.user.email,
          phoneMessageConsent: localStorage.getItem('phoneMessageConsent') === 'true',
          phone: localStorage.getItem('signupPhone'),
          attribution: {
            cookies: {
              ...(attribution?.fbc && { fbc: attribution.fbc }),
              ...(attribution?.fbp && { fbp: attribution.fbp }),
              ...(attribution?.ga?.slice(6) && { ga: attribution.ga.slice(6) }),
              ...(attribution?.gclid?.split('.')[2] && { gclid: attribution?.gclid?.split('.')[2] }),
              ...(attribution?.msclkid && { msclkid: attribution.msclkid }),
              ...(attribution?.li_fat_id && { li_fat_id: attribution.li_fat_id }),
              ...(attribution?.ttclid && { ttclid: attribution.ttclid }),
            },
            ...(attribution?.first && { first_touch: attribution.first }),
            ...(attribution?.last && { last_touch: attribution.last }),
          },
        });

        loginWithToken({ token: data.token, userId: data.user.id, email: data.user.email });
      },
    }
  );

  const requestPassword = useMutation(
    ({ email }: PasswordRequestVariables) =>
      api
        .post(`/password_resets`, {
          email,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        analytics.track('Forgot Password');
        toast.success(data?.message);
      },
    }
  );

  const resetPassword = useMutation(
    ({
      password,
      confirmPassword,
      tokenId,
      passwordToken,
      userId,
      otp,
      deviceConfirmationCode,
    }: PasswordResetVariables) =>
      api
        .patch(`/password_resets/${tokenId}`, {
          password,
          password_confirmation: confirmPassword,
          password_token: passwordToken,
          user_id: userId,
          otp,
          device_confirmation_code: deviceConfirmationCode,
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        if (data.code === 'OTP_REQUIRED') {
          setNeedsOtp(true);
        } else if (data.code === 'DEVICE_CONFIRMATION_REQUIRED') {
          setNeedsDeviceConfirmationCode(true);
        } else {
          analytics.track('Recovered Password');
          loginWithToken({ token: data.token, userId: data.user.id, email: data.user.email });
        }
      },
    }
  );

  /**
   * Logs the user out on both the client and rails app.
   */
  const logout = useMutation(() => {
    setIsLoggedIn(false);
    setIsMasquerading(false);
    const newUser = localStorage.getItem('newUser');
    localStorage.clear();
    if (newUser) {
      localStorage.setItem('newUser', 'true');
    }
    queryClient.clear();
    setNeedsOtp(false);
    setNeedsDeviceConfirmationCode(false);

    return api.delete(`/tokens`);
  });

  const masquerade = useMutation(({ user_id }: { user_id: string }) =>
    api
      .post('/system_admin/masquerade', {
        user_id,
      })
      .then((res) => res.data)
      .then((data) => {
        setIsMasquerading(true);
        if (data.primary_publication_id) {
          localStorage.setItem('currentPublicationId', data.primary_publication_id);
        } else {
          localStorage.removeItem('currentPublicationId');
        }
        localStorage.setItem('masqueradeToken', data.token);
      })
  );

  const endMasquerade = useMutation(() =>
    api.delete('/system_admin/masquerade').then(() => {
      setIsMasquerading(false);
      localStorage.removeItem('masqueradeToken');
      localStorage.removeItem('currentPublicationId');
    })
  );

  const inviteAcceptAuthenticatedUser = (variables: InviteAcceptAuthenticatedUserVariables) =>
    api.post(`/invites/${variables.inviteId}/authenticated_user`, {
      invite_token: variables.inviteToken,
      token_id: variables.tokenId,
    });

  const contextProviderProps = useMemo(() => {
    const inviteAcceptNewUser = (variables: InviteAcceptNewUserVariables) =>
      api
        .post(`/invites/${variables.inviteId}/new_user`, {
          email: variables.email,
          invite_token: variables.inviteToken,
          token_id: variables.tokenId,
          first_name: variables.firstName,
          last_name: variables.lastName,
          password: variables.password,
          password_confirmation: variables.passwordConfirmation,
        })
        .then((res) => res.data)
        .then((data) => {
          loginWithToken({ token: data.token, userId: data.user.id, email: data.user.email });
        });

    const inviteAcceptExistingUser = (variables: InviteAcceptExistingUserVariables) =>
      api
        .post(`/invites/${variables.inviteId}/existing_user`, {
          email: variables.email,
          password: variables.password,
          invite_token: variables.inviteToken,
          token_id: variables.tokenId,
          otp: variables.otp,
          deviceConfirmationCode: variables.deviceConfirmationCode,
        })
        .then((res) => res.data)
        .then((data) => {
          if (data.code === 'OTP_REQUIRED') {
            setNeedsOtp(true);
            return data;
          }

          if (data.code === 'DEVICE_CONFIRMATION_REQUIRED') {
            setNeedsDeviceConfirmationCode(true);
            return data;
          }

          return loginWithToken({ token: data.token, userId: data.user.id, email: data.user.email });
        });

    return {
      isLoggedIn,
      isMasquerading,
      signup,
      logout,
      login,
      loginWithToken,
      requestPassword,
      resetPassword,
      masquerade,
      endMasquerade,
      inviteAcceptNewUser,
      inviteAcceptExistingUser,
      inviteAcceptAuthenticatedUser,
      needsOtp,
      needsDeviceConfirmationCode,
    };
  }, [
    endMasquerade,
    isLoggedIn,
    isMasquerading,
    login,
    logout,
    masquerade,
    needsDeviceConfirmationCode,
    needsOtp,
    requestPassword,
    resetPassword,
    signup,
  ]);

  return <AuthContext.Provider value={contextProviderProps}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthContext, AuthProvider, useAuth };
