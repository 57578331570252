import { useMemo } from 'react';

import { useWebsiteContext, useWebsitePageRouteGetter } from '@/context/website-context';

import ComboboxInput, { ComboboxOption } from '../../../_components/UI/ComboboxInput';

interface RoutesComboBoxProps {
  publicationUrl: string;
  selectedOption: any;
  setSelectedOption: (selected: string) => void;
  onInputChange: (query: string) => void;
  onSubmit?: () => void;
}

const RoutesComboBox = ({
  publicationUrl,
  selectedOption,
  setSelectedOption,
  onInputChange,
  onSubmit,
}: RoutesComboBoxProps) => {
  const { pagesRoutes, defaultRoutes, globalElements } = useWebsiteContext();
  const pageRouteGetter = useWebsitePageRouteGetter();

  const allOptions: ComboboxOption[] = useMemo(() => {
    const defaultOptions: ComboboxOption[] =
      defaultRoutes?.children_keys?.map((route) => ({
        id: `${publicationUrl}${route}`,
        name: pageRouteGetter?.getPageFromPath(route)?.published_page_version?.name || route,
      })) || [];

    const pageOptions: ComboboxOption[] =
      pagesRoutes?.children_keys?.map((route) => ({
        id: `${publicationUrl}${route}`,
        name: pageRouteGetter?.getPageFromPath(route)?.published_page_version?.name || route,
      })) || [];

    return [
      { id: '', name: 'Default Pages', isOptGroup: true },
      ...defaultOptions,
      ...(pageOptions.length > 0 ? [{ id: '', name: 'Pages', isOptGroup: true }, ...pageOptions] : []),
      { id: '', name: 'Globals', isOptGroup: true },
      ...globalElements.map((element) => ({
        id: `${publicationUrl}?${element.orchidSearchParam}`,
        name: element.name,
      })),
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesRoutes, defaultRoutes, pageRouteGetter]);

  return (
    <ComboboxInput
      options={allOptions}
      selectedOption={selectedOption}
      onInputChange={(query: string) => onInputChange(query)}
      onChange={(selected: any) => {
        setSelectedOption(selected.id);
      }}
      placeholder="https://new-url.com/"
      onKeyPress={(e) => {
        if (e.key === 'Enter' && onSubmit) {
          e.preventDefault();
          onSubmit();
        }
      }}
    />
  );
};

export default RoutesComboBox;
