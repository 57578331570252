import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import useCreateSvixEndpoint from '@/hooks/webhooks/useCreateSvixEndpoint';
import { WebhookEndpoint, WebhookEndpointCreatePayload } from '@/interfaces/webhooks/endpoint';

import { usePublicationSettings } from '../../context';
import EndpointForm from '../components/EndpointForm';

const NewWebhookEndpoint = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentPublicationId } = usePublicationSettings();

  const { mutateAsync: createEndpoint, isLoading } = useCreateSvixEndpoint(currentPublicationId, {
    onSuccess: () => {
      toast.success('Webhook endpoint created successfully');
      queryClient.invalidateQueries(['publications', currentPublicationId, 'webhooks', 'endpoints']);
      navigate('/settings/publication/webhooks');
    },
  });

  const handleSave = (payload: Partial<WebhookEndpoint>) => createEndpoint(payload as WebhookEndpointCreatePayload);

  return <EndpointForm onSave={handleSave} isSaving={isLoading} />;
};

export default NewWebhookEndpoint;
