import React, { CSSProperties, DragEventHandler, useCallback, useRef } from 'react';
import { DotsSix } from '@phosphor-icons/react';
import { Editor } from '@tiptap/core';
import { Fragment, Slice } from '@tiptap/pm/model';
import { NodeSelection } from '@tiptap/pm/state';
import { useEditorState } from '@tiptap/react';

import { useFrame } from '../../../hooks/useFrame';
import { CONTAINER_NODES, OPEN_INSERT_PANEL_IDENTIFIER } from '../../constants';

const defaultHiddenStyle = {
  position: 'absolute',
  left: -1000,
  top: -1000,
  width: 0,
  height: 0,
} as React.CSSProperties;

// DragHandle is not used yet in the new editor. This code is
// still under heavy development
export const DragHandle = ({ editor }: { editor: Editor }) => {
  const dragHandleRef = useRef<HTMLDivElement>(null);
  const { node, nodePos } = useEditorState<{ node: Element | null; nodePos: number | null }>({
    editor,
    selector: ({ editor: e }) => ({
      node: e.storage.hover.node as Element | null,
      nodePos: e.storage.hover.pos as number | null,
    }),
  });

  const handleDragStart: DragEventHandler<HTMLDivElement> = (e) => {
    const { view } = editor;

    if (nodePos === null) return;

    // Get the selected content
    const $pos = view.state.doc.resolve(nodePos);
    const frag = Fragment.from($pos.nodeAfter);
    const slice = new Slice(frag, 0, 0);

    // Inform ProseMirror of the drag operation
    view.dragging = { slice, move: true };

    // Prevent duplication of the node when dropped by dispatching the transaction
    const selection = NodeSelection.create(view.state.doc, nodePos);
    const tr = editor.state.tr.setSelection(selection);
    editor.view.dispatch(tr);
    // Clone the node and apply necessary styles
    if (node) {
      const rect = node.getBoundingClientRect();

      // Set the custom drag image with adjusted offset
      e.dataTransfer.setDragImage(node, rect.width / 2, 0);
    } else {
      // Fallback content if node is not available
      const dragPreviewContainer = document.createElement('div');
      dragPreviewContainer.textContent = 'Dragging...';
      e.dataTransfer.setDragImage(dragPreviewContainer, 0, 0);
    }
  };

  const handleClick = useCallback(() => {
    if (nodePos === null) return;

    editor.chain().setNodeSelection(nodePos).focus(undefined, { scrollIntoView: false }).run();

    const pmNode = editor.view.state.doc.nodeAt(nodePos);

    if (pmNode && CONTAINER_NODES.includes(pmNode.type.name) && pmNode.content.size === 0) {
      editor.commands.setMeta(OPEN_INSERT_PANEL_IDENTIFIER, true);
    }
  }, [editor, nodePos]);

  let style = {} as CSSProperties;

  const { window } = useFrame();

  if (node) {
    const rect = node?.getBoundingClientRect();
    const parentRect = editor.view.dom.getBoundingClientRect();
    const nodeWidth = rect ? rect.right - rect.left : 0;

    if (parentRect) {
      style = {
        position: 'absolute',
        top: rect.top + (window?.scrollY || 0),
        left: rect.left + nodeWidth / 2 - parentRect.left + (window?.scrollX || 0),
        transform: 'translateX(-50%)',
        cursor: 'grab',
        userSelect: 'none',
        pointerEvents: 'all',
        color: `#9393FF`,
      };
    }
  } else {
    style = defaultHiddenStyle;
  }

  return (
    <div
      ref={dragHandleRef}
      style={{ cursor: 'grab', ...style }}
      draggable
      onDragStart={handleDragStart}
      onClick={handleClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
    >
      <DotsSix size={20} weight="bold" />
    </div>
  );
};
