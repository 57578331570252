import { useCallback, useState } from 'react';
import { CaretDown } from '@phosphor-icons/react';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../UI/DropdownMenu';
import { Text } from '../../../UI/Text';
import { AttributeSettingProps } from '../types';

type Options = {
  label: string;
  value?: string;
  onSelect?: () => void;
};

type Props = {
  editor?: AttributeSettingProps['editor'];
  activeNodeResult?: AttributeSettingProps['activeNodeResult'];
  property?: AttributeSettingProps['property'];
  title: string;
  defaultValue?: string;
  options: Options[];
};

export const AttributeDropdown = ({ editor, activeNodeResult, title, defaultValue, options, property }: Props) => {
  const { activeNodeType, activeNodePos, activeNodeAttributes } = activeNodeResult || {};
  const valueFromAttrs = typeof property === 'string' ? activeNodeAttributes?.[property] : undefined;
  const [value, setValue] = useState(valueFromAttrs || defaultValue);

  const handleChange = useCallback(
    (newValue: string) => {
      if (activeNodePos === undefined || !activeNodeType) return;

      editor?.commands.command(({ tr }) => {
        if (property) {
          tr.setNodeAttribute(activeNodePos, property, newValue);
        }
        return true;
      });
    },
    [activeNodePos, activeNodeType, property, editor]
  );

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="cursor-pointer">
            <div className="w-full justify-between flex items-center gap-2 p-2">
              <div className="flex items-center gap-1">
                {value && (
                  <Text size="2xs" weight="medium">
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                  </Text>
                )}
              </div>
              <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer">
            {options.map((option) => (
              <DropdownMenuItem
                key={option.value || option.label}
                onSelect={() => {
                  setValue(option.value || option.label);
                  if (property) {
                    handleChange(option.value || option.label);
                  }
                  option.onSelect?.();
                }}
              >
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
