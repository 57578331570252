import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { UseMutationResult } from 'react-query';
import { Listbox } from '@headlessui/react';
import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';
import { useDebounce } from 'use-debounce';

import { FileSelect, Input, TypeaheadMultiSelect } from '@/components/Form';
import SelectedOptionBadges from '@/components/Form/SelectedOptionBadges';
import { Typography } from '@/components/Typography';
import { useAdvertisers, useIndustries, useIndustryGroups } from '@/hooks/useAdNetwork/internal';
import { AdNetworkAdvertiser } from '@/interfaces/ad_network/internal/advertiser';
import { AdNetworkRoleName } from '@/interfaces/ad_network/internal/role';
import { AdNetworkUser } from '@/interfaces/ad_network/internal/user';
import { Option } from '@/interfaces/general';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { Textarea } from '@/ui/Textarea';
import { normalizeString } from '@/utils';

import ContactFields from './ContactFields';

interface FormProps {
  onSubmitMutation: UseMutationResult<any, any, any, any>;
  advertiser?: AdNetworkAdvertiser;
}

const Form = ({ onSubmitMutation, advertiser }: FormProps) => {
  const { mutateAsync, isLoading } = onSubmitMutation;
  const [advertiserName, setAdvertiserName] = useState(advertiser?.name || '');
  const [description, setDescription] = useState(advertiser?.description || '');
  const [logo, setLogo] = useState<File | null>(null);
  const [query, setQuery] = useState('');
  const [url, setUrl] = useState(advertiser?.url || '');
  const [industryIds, setIndustryIds] = useState<string[]>(advertiser?.industry_ids || []);
  const [similarBrandIds, setSimilarBrandIds] = useState<string[]>(advertiser?.similar_brand_ids || []);
  const { data: industriesData } = useIndustries();

  const [debouncedSearchQuery] = useDebounce(query, 800);
  const allIndustries = (industriesData?.pages.flatMap((page) => page.industries) || []).sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  const { data: industryGroupsData } = useIndustryGroups();
  const allIndustryGroups = industryGroupsData?.industry_groups || [];

  const groupMapArray =
    allIndustryGroups?.map((group) => ({ type: 'group', object_id: group.id, name: group.name })) || [];
  const industryMapArray =
    allIndustries
      .filter((industry) => industry.industry_group_id === null)
      .map((industry) => ({ type: 'industry', object_id: industry.id, name: industry.name })) || [];

  const topLevelGroups = [...groupMapArray, ...industryMapArray].sort((a, b) => (a.name > b.name ? 1 : -1));

  const {
    data: brandsData,
    isLoading: brandsAreLoading,
    isSuccess: brandsAreLoaded,
  } = useAdvertisers({ query: debouncedSearchQuery });

  const allBrands: AdNetworkAdvertiser[] =
    (brandsAreLoaded &&
      brandsData.pages.flatMap((page) => page.advertisers).filter((brand) => brand.id !== advertiser?.id)) ||
    [];
  const selectedOptions: Option[] =
    (brandsAreLoaded &&
      similarBrandIds.map((brandId: string) => ({
        label: allBrands.find((brand) => brand.id === brandId)?.name || brandId,
        value: brandId,
      }))) ||
    [];
  const [userCounter, setUserCounter] = useState(advertiser?.contacts?.length || 0);
  const newUserIdPrefix = `new-user`;
  const newUser = (id: string) =>
    ({
      id,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      roles: [],
    } as unknown as AdNetworkUser);
  const [users, setUsers] = useState<AdNetworkUser[]>(
    advertiser?.contacts || [newUser(`${newUserIdPrefix}-${userCounter}`)]
  );

  // useEffect(() => {
  //   const advertiserUsers = advertiser?.contacts || [];
  //   if (advertiserUsers.length > 0) {
  //     const mergedUsers = [...users, ...advertiserUsers];

  //     const uniqueUsers = Array.from(new Map(mergedUsers.map((user) => [user.id, user])).values());

  //     setUsers(uniqueUsers);
  //   }
  // }, [advertiser]);

  const handleAddNewUser = () => {
    setUsers([...users, newUser(`${newUserIdPrefix}-${userCounter + 1}`)]);
    setUserCounter(userCounter + 1);
  };

  const handleDeleteUser = (id: string) => {
    const updatedContacts = users.filter((user) => user.id !== id);
    setUsers(updatedContacts);
  };

  const handleUpdateUser = (updatedUser: AdNetworkUser) => {
    const updatedUsers = users.map((user) => (user.id === updatedUser.id ? updatedUser : user));
    setUsers(updatedUsers);
  };

  const validateContacts = () => {
    const primaryUsers = users.filter((user) =>
      (user.roles || []).some((role) => role && role.name === AdNetworkRoleName.PRIMARY)
    );
    if (primaryUsers.length < 1) return 'You must have at least one primary contact';
    if (primaryUsers.length > 1) return 'You may only have one primary contact';

    const billingUsers = users.filter((user) =>
      (user.roles || []).some((role) => role && role.name === AdNetworkRoleName.BILLING)
    );
    if (billingUsers.length > 1) return 'You may only have one billing contact';

    const emails = users.map((contact) => contact.email);
    const uniqueEmails = new Set(emails);
    if (emails.length !== uniqueEmails.size) return 'The contact emails must be unique';

    const usersWithoutRoles = users.filter((contact) => contact.roles.length === 0);
    if (usersWithoutRoles.length > 0) return 'Every contact must have at least one tag';

    return null;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validateContacts();
    if (errors) {
      toast.error(errors);
      return;
    }

    /**
     * Due to needing to upload an image we have to use multipart form instead of JSON
     */
    const formData = new FormData();
    formData.append('advertiser[name]', advertiserName);
    formData.append('advertiser[description]', description);

    if (industryIds.length) {
      industryIds.forEach((id) => formData.append('advertiser[industry_ids][]', id));
    } else {
      formData.append('advertiser[industry_ids][]', '');
    }

    if (similarBrandIds.length) {
      similarBrandIds.forEach((id) => formData.append('advertiser[similar_brand_ids][]', id));
    } else {
      formData.append('advertiser[similar_brand_ids][]', '');
    }

    formData.append('advertiser[url]', url);
    if (logo) formData.append('advertiser[logo]', logo as Blob);

    users.forEach((user) => {
      formData.append('advertiser[contacts][][id]', user.id);
      formData.append('advertiser[contacts][][first_name]', user.first_name);
      formData.append('advertiser[contacts][][last_name]', user.last_name);
      formData.append('advertiser[contacts][][email]', user.email);
      user.roles.forEach((role) => {
        formData.append('advertiser[contacts][][roles][][id]', role?.id || '');
        formData.append('advertiser[contacts][][roles][][name]', role?.name || '');
      });
    });

    mutateAsync(formData);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSearch = (): Promise<Option[]> => {
    const options =
      allBrands
        ?.filter((brand) => normalizeString(brand.name).includes(query))
        .map((brand) => ({ label: brand.name, value: brand.id })) || [];

    return new Promise((resolve) => {
      resolve(options);
    });
  };

  const handleSelect = (name: string, value: string) => {
    const newSimilarBrandIds = [...similarBrandIds, value];
    setSimilarBrandIds(newSimilarBrandIds);
  };

  const handleDeselect = (name: string, value: string) => {
    const newSimilarBrandIds = similarBrandIds.filter((item) => item !== value);

    setSimilarBrandIds(newSimilarBrandIds);
  };

  const handleDeselectViaBadge = (value: string) => {
    handleDeselect('', value);
  };

  const handleDeselectAll = () => {
    setSimilarBrandIds([]);
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setQuery(normalizeString(newQuery));
  };

  const handleOnClear = () => setQuery('');

  return (
    <div className="p-4">
      <div className="max-w-lg mx-auto w-full space-y-4">
        <form className="space-y-6" onSubmit={onSubmit}>
          <Typography size="2xl">Advertiser Details</Typography>
          <Input
            ref={inputRef}
            value={advertiserName}
            onChange={(e) => setAdvertiserName(e.target.value)}
            name="advertiser[name]"
            labelText="Name"
            helperText="The name of the advertiser (public)."
            required
          />
          <FileSelect
            name="file"
            labelText="Logo"
            onFileSelect={(file) => setLogo(file)}
            file={logo}
            accept=".jpg,.jpeg,.png"
            helperText="A logo for the advertiser (public)."
            required
          />
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name="advertiser[description]"
            labelText="Description"
            helperText="A brief description of the advertiser (public)."
            required
          />
          <Input
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            name="advertiser[url]"
            helperText="A website or homepage for the advertiser (public, not for tracking)."
            labelText="URL"
            required
          />

          <Listbox value={industryIds} onChange={(ids: string[]) => setIndustryIds(ids)} multiple as="div">
            <Listbox.Label className="block text-sm font-medium text-gray-700">Industries</Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed">
                <div className="flow-root">
                  <div className="-m-1 flex flex-wrap max-w-md">
                    {allIndustries
                      .filter((industry) => industryIds.includes(industry.id))
                      .map((industry) => (
                        <Badge key={industry.id} className="m-1">
                          {industry.industry_group_id && (
                            <>
                              {allIndustryGroups.find((group) => group.id === industry.industry_group_id)?.name} &rarr;{' '}
                            </>
                          )}
                          {industry.name}
                        </Badge>
                      ))}
                  </div>
                </div>
                {industryIds.length === 0 && <span className="text-gray-500">Select industries</span>}
              </Listbox.Button>
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm space-y-2">
                {topLevelGroups.map((group) => (
                  <>
                    {group.type === 'industry' && (
                      <Listbox.Option
                        key={group.object_id}
                        value={group.object_id}
                        className={({ active, selected }) =>
                          cx(
                            (active || selected) && 'bg-gray-50',
                            'relative select-none p-2 mx-2 rounded',
                            'cursor-pointer'
                          )
                        }
                      >
                        {({ selected }) => (
                          <div className="relative">
                            {group.name}
                            {selected && (
                              <div className="absolute inset-y-0 right-0 flex items-center">
                                <CheckIcon className="w-5 h-5 text-primary-500" aria-hidden="true" />
                              </div>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    )}
                    {group.type === 'group' && (
                      <Listbox.Option
                        key={group.object_id}
                        value={group.object_id}
                        disabled
                        className={({ active, selected }) =>
                          cx(
                            (active || selected) && 'bg-gray-50',
                            'relative select-none p-2 mx-2 rounded',
                            'cursor-default'
                          )
                        }
                      >
                        {({ selected }) => (
                          <div className="relative">
                            {group.name}
                            {selected && (
                              <div className="absolute inset-y-0 right-0 flex items-center">
                                <CheckIcon className="w-5 h-5 text-primary-500" aria-hidden="true" />
                              </div>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    )}
                    {allIndustries
                      .filter((industry) => industry.industry_group_id === group.object_id)
                      .map((industry) => (
                        <Listbox.Option
                          key={industry.id}
                          value={industry.id}
                          className={({ active, selected }) =>
                            cx(
                              (active || selected) && 'bg-gray-50',
                              'relative select-none p-2 mx-2 rounded',
                              'cursor-pointer'
                            )
                          }
                        >
                          {({ selected }) => (
                            <div className="relative">
                              -- {industry.name}
                              {selected && (
                                <div className="absolute inset-y-0 right-0 flex items-center">
                                  <CheckIcon className="w-5 h-5 text-primary-500" aria-hidden="true" />
                                </div>
                              )}
                            </div>
                          )}
                        </Listbox.Option>
                      ))}
                  </>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>

          <TypeaheadMultiSelect
            name="similar-brand-multi-select"
            onClear={handleOnClear}
            onDeselect={handleDeselect}
            onDeselectAll={handleDeselectAll}
            onSearch={handleSearch}
            onSearchQueryChange={handleSearchQueryChange}
            onSelect={handleSelect}
            labelText="Similar Brands"
            placeholderText="Select similar brands"
            emptyLabel={
              brandsAreLoading ? 'Now loading similar brand list...' : 'Type a search string to load matching brands'
            }
            values={similarBrandIds}
            showClearAll={false}
            shouldCloseOnSelection={false}
          />
          {selectedOptions.length > 0 && (
            <SelectedOptionBadges options={selectedOptions} onDeselect={handleDeselectViaBadge} />
          )}

          {/* Contacts Section */}
          <div>
            <Typography size="xl">Contacts</Typography>
          </div>
          {users.map((user, index) => (
            <ContactFields
              title={`Contact ${index + 1}`}
              advertiser={advertiser}
              contact={user}
              onChange={handleUpdateUser}
              onDelete={handleDeleteUser}
              canDelete={users.length > 1}
            />
          ))}

          <div className="w-full flex flex-row justify-center">
            <Button onClick={handleAddNewUser} variant="primary-inverse" size="xs" Icon={PlusIcon}>
              Add New Contact
            </Button>
          </div>

          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Form;
