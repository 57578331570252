import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft } from '@phosphor-icons/react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { WebsiteProvider } from '@/context/website-context';
import useSitePackage from '@/hooks/useSitePackages/useSitePackage';
import useUpdateSiteTemplate from '@/hooks/useSiteTemplates/useUpdateSiteTemplate';
import { SiteTemplate } from '@/interfaces/site_template';

import { AdminJsonEditor } from '../../_components/DreamEditor/AdminJsonEditor';
import { Layout } from '../../_components/Layout';
import { Main } from '../../_components/Main';
import { Breadcrumbs } from '../../_components/Main/Breadcrumbs';
import { ProjectPreviewer, useProjectPreviewer } from '../../_components/ProjectPreviewer';
import FooterCard from '../../_components/Templates/FooterCard';
import NavbarCard from '../../_components/Templates/NavbarCard';
import TemplateCard from '../../_components/Templates/TemplateCard';
import { Button } from '../../_components/UI/Button';
import { Text } from '../../_components/UI/Text';
import useRawEditorJson from '../../_hooks/useRawEditorJson';

import { TemplatesTopBar } from './_components/TemplatesTopBar';

const DEFAULT_THEME = {
  header_font: 'Inter',
  body_font: 'Inter',
  button_font: 'Inter',
  primary_color: '#000000',
  text_on_primary_color: '#FFFFFF',
  background_color: '#FFFFFF',
  text_on_background_color: '#000000',
  border_color: '#E5E7EB',
  border_radius: '0px',
};

const WebsiteProjectPage = () => {
  const { projectId } = useParams();
  const [currentPublicationId] = useCurrentPublicationState();
  const queryClient = useQueryClient();
  const { data: project, isLoading } = useSitePackage({ sitePackageId: projectId || '' });
  const templates = project?.templates;
  const hasNoTemplates = templates?.length === 0 && !isLoading;
  const [selectedTemplate, setSelectedTemplate] = useState<SiteTemplate | null>(null);

  const { isOpen, onClose, onOpen } = useProjectPreviewer();
  const { hasAccess } = useRawEditorJson();

  const { mutate, isLoading: isUpdating } = useUpdateSiteTemplate({
    id: selectedTemplate?.id || '',
    onSuccess: () => {
      setSelectedTemplate(null);
      toast.success('Template Updated!');
    },
  });

  const onDeleteTemplateSuccess = () => {
    queryClient.invalidateQueries(['publications', currentPublicationId, 'site_packages', projectId]);
  };

  const { pages, sections, blocks } = useMemo(() => {
    const pageTemplates = templates?.filter((template) => template.level === 'page');
    const sectionTemplates = templates?.filter((template) => template.level === 'section');
    const blockTemplates = templates?.filter((template) => template.level === 'block');

    return {
      pages: pageTemplates,
      sections: sectionTemplates,
      blocks: blockTemplates,
    };
  }, [templates]);

  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Back to Main App',
        backPath: '/',
      }}
      sidenavChildren={
        <Link to="/website_builder_v2/projects">
          <Button variant="secondary" LeftIcon={ArrowLeft} className="w-full">
            All Projects
          </Button>
        </Link>
      }
    >
      <Main>
        {hasAccess && selectedTemplate && (
          <AdminJsonEditor
            isOpen={Boolean(selectedTemplate)}
            onClose={() => setSelectedTemplate(null)}
            content={selectedTemplate?.content}
            isLoading={isUpdating}
            onUpdate={(json: any) => {
              mutate({ content: json });
            }}
          />
        )}
        {isOpen && project && (
          <ProjectPreviewer isOpen={isOpen} onClose={onClose} theme={DEFAULT_THEME} project={project} />
        )}
        <Breadcrumbs />
        <TemplatesTopBar project={project} onOpen={onOpen} />
        <div className="flex flex-col gap-3">
          <Text weight="semibold" variant="secondary" size="sm">
            Globals
          </Text>
          <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
            {project && <NavbarCard sitePackage={project} />}
            {project && <FooterCard sitePackage={project} />}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Text weight="semibold" variant="secondary" size="sm">
            Pages
          </Text>
          {hasNoTemplates ? (
            <div className="flex items-center justify-center h-full py-20">
              <Text size="xl" weight="semibold" variant="secondary" as="h4">
                No templates found
              </Text>
            </div>
          ) : (
            <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
              {pages?.map((template) => {
                return (
                  <TemplateCard
                    hasOptions
                    showDelete={false}
                    showEdit={false}
                    key={template.id}
                    template={template}
                    onDeleteSuccess={() => {}}
                    allowRawJson={hasAccess}
                    onRawJsonClick={() => setSelectedTemplate(template)}
                  />
                );
              })}
            </div>
          )}
        </div>

        {hasNoTemplates ? null : (
          <div className="flex flex-col gap-3">
            {sections && sections?.length > 0 && (
              <Text weight="semibold" variant="secondary" size="sm">
                Sections
              </Text>
            )}

            <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
              {sections?.map((template) => {
                return (
                  <TemplateCard
                    hasOptions
                    key={template.id}
                    template={template}
                    onDeleteSuccess={onDeleteTemplateSuccess}
                    allowRename
                  />
                );
              })}
            </div>
          </div>
        )}

        {hasNoTemplates ? null : (
          <div className="flex flex-col gap-3">
            {blocks && blocks?.length > 0 && (
              <Text weight="semibold" variant="secondary" size="sm">
                Blocks
              </Text>
            )}

            <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
              {blocks?.map((template) => {
                return (
                  <TemplateCard
                    hasOptions
                    key={template.id}
                    template={template}
                    onDeleteSuccess={onDeleteTemplateSuccess}
                    allowRename
                  />
                );
              })}
            </div>
          </div>
        )}
      </Main>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <WebsiteProjectPage />
  </WebsiteProvider>
);
