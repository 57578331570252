import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';

import { useNavbarContext } from '../../../NavbarContext';

import { StyleSettings } from './StyleSettings';

export function NavDropdownMainSettings() {
  const { selectedContent } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown') return null;

  return (
    <SectionRenderer title="Navigation Dropdown">
      <StyleSettings />
    </SectionRenderer>
  );
}
