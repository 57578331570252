import { Extension } from '@tiptap/core';

import { getStringAttribute } from '../../../utils/attributesUtils';

interface MaxWidthOptions {
  nodesToAddMaxWidthAttribute: string[];
}

interface MaxWidthStorage {}

export const MaxWidthAttribute = Extension.create<MaxWidthOptions, MaxWidthStorage>({
  name: 'maxWidthAttribute',

  addOptions() {
    return {
      nodesToAddMaxWidthAttribute: [
        "divider",
        "heading",
        "paragraph",
        "socials"
      ],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.nodesToAddMaxWidthAttribute,
        attributes: {
          maxWidth: getStringAttribute('maxWidth', 'data-max-width', '100%'),
        },
      },
    ];
  },
});
