import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

export interface Wallet {
  all_time_earnings_cents: number;
  all_time_earnings: string;
  available_balance_cents: number;
  available_balance: string;
  total_balance_cents: number;
  total_balance: string;
  total_earnings_cents: string;
  total_ads_earnings_cents: string;
  total_boosts_earnings_cents: string;
  total_boosts_expenses_cents: number;
  withdrawable_balance_cents: number;
  withdrawable_balance: string;
  pending_boost_sends_spend_cents: number;
  pending_boost_sends_spend: string;
}

const useWallet = () => {
  const [currentPublicationId] = useCurrentPublicationState();
  const fetchWallet = (): Promise<Wallet> =>
    api
      .get(`/wallet`, {
        params: { publication_id: currentPublicationId },
      })
      .then((res) => res.data);

  return useQuery([currentPublicationId, 'wallet'], fetchWallet);
};

export default useWallet;
