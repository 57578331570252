import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SurveyElement } from '@shared/dream-components';
import { NodeViewProps, useReactNodeView } from '@tiptap/react';

import { useCurrentPage } from '@/context/website-context';

import DynamicContent from '../../components/DynamicContent';
import { getDataAttributes } from '../../utils';

export const SurveyView: FC<NodeViewProps> = ({ HTMLAttributes, node }) => {
  const { onDragStart } = useReactNodeView();
  const { page } = useCurrentPage();
  const [, setSearchParams] = useSearchParams();
  const isSurveyPage = page?.draft_page_version?.name === 'Surveys';

  const handleClick = () => {
    setSearchParams({ learn_more: 'dynamic_content_surveys' });
  };

  return (
    <SurveyElement
      element={{
        type: 'survey',
        content: [{ text: '', id: 'text' }],
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
          data: node.attrs.data,
        },
      }}
      onDragStart={onDragStart}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
    >
      {isSurveyPage ? <DynamicContent title="Dynamic Survey Page" onClick={handleClick} /> : null}
      {null}
    </SurveyElement>
  );
};
