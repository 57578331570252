import { NavbarSerializableNode } from '@shared/dream-components';

import { useSite } from '@/hooks/useSite';
import { useSiteThemes } from '@/hooks/useSiteThemes';
import { Text } from '@/routes/website/_components/UI/Text';
import { ToggleGroup, ToggleGroupItem } from '@/routes/website/_components/UI/ToggleGroup';

import { navbarList2ndRowContent } from '../../../defaultContent';
import { useNavbarContext } from '../../../NavbarContext';
import { applyThemeToNode, getParent } from '../../../utils';

export const RowNumberSettings = () => {
  const {
    content,
    selectedContent: rawSelectedContent,
    onUpdateNodeAttributes,
    onUpdateNodeContent,
    withMerging,
  } = useNavbarContext();

  const { data: siteThemesData } = useSiteThemes();
  const { data: site } = useSite();

  const siteThemes = siteThemesData?.pages.flatMap((page) => page.site_themes) || [];
  const defaultTheme = siteThemes.find((theme) => theme.is_primary);

  const selectedContent =
    rawSelectedContent?.type === 'navbar_menu_list' && content
      ? getParent(content, rawSelectedContent)
      : rawSelectedContent;

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_menu' && selectedContent.type !== 'navbar_menu_list') return null;

  const contentValue =
    selectedContent?.attrs && 'rows' in selectedContent.attrs
      ? selectedContent.attrs.rows
      : selectedContent?.content?.length || 1;

  const handleRowNumberChange = (value: string) => {
    const nodeId = selectedContent.attrs?.id;

    if (!nodeId) return;

    const hasLessContentThanSelectedRow =
      selectedContent.content?.length && selectedContent.content?.length < parseInt(value, 10);

    withMerging(() => {
      onUpdateNodeAttributes(nodeId, { rows: parseInt(value, 10) });

      const childContent = selectedContent.content as NavbarSerializableNode[];

      if (hasLessContentThanSelectedRow) {
        let new2ndRowContent = navbarList2ndRowContent();

        if (defaultTheme && site?.theme_rules) {
          new2ndRowContent = applyThemeToNode(new2ndRowContent, defaultTheme.data, site?.theme_rules);
        }

        const newContent = childContent.concat(
          Array.from({ length: parseInt(value, 10) - childContent.length }).map(() => new2ndRowContent)
        );
        onUpdateNodeContent(nodeId, newContent);
      }
    });
  };

  const value = contentValue?.toString() || '1';

  return (
    <div className="flex items-center justify-stretch gap-2">
      <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
        Rows
      </Text>
      <div className="grow bg-wb-secondary rounded-lg shadow-sm">
        <ToggleGroup
          className="p-[2px]"
          type="single"
          defaultValue="1"
          value={value}
          onValueChange={handleRowNumberChange}
        >
          <ToggleGroupItem value="1" className="grow">
            <Text size="2xs" weight="semibold" variant="inherit">
              1
            </Text>
          </ToggleGroupItem>
          <ToggleGroupItem value="2" className="grow">
            <Text size="2xs" weight="semibold" variant="inherit">
              2
            </Text>
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </div>
  );
};
