import { useCallback } from 'react';
import { Minus, Plus } from '@phosphor-icons/react';

import { Text } from '@/routes/website/_components/UI/Text';
import { cn } from '@/utils/cn';

import { SimpleInputWrapper } from '../../../../UI/Input';
import { AttributeSettingProps } from '../../types';

type SimpleColumnCountInputProps = AttributeSettingProps & {
  title: string;
};

export function SimpleColumnCountInput({ editor, activeNodeResult, title }: SimpleColumnCountInputProps) {
  const { activeNode } = activeNodeResult;
  const childCount = activeNode?.content.childCount || 2;

  const isRemoveable = childCount > 2;
  const isAddable = childCount < 6;

  const onAddColumn = useCallback(() => {
    if (!isAddable) return;
    editor.commands.setColumnCount(childCount + 1);
  }, [childCount, editor, isAddable]);
  const onRemoveColumn = useCallback(() => {
    if (!isRemoveable) return;
    editor.commands.setColumnCount(childCount - 1);
  }, [childCount, editor, isRemoveable]);

  return (
    <div className="flex items-center justify-stretch gap-2 select-none min-w-0">
      <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
        {title}
      </Text>
      <SimpleInputWrapper className="h-[32px] cursor-default py-1">
        <div
          className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {
            'cursor-pointer': isRemoveable,
            'opacity-20': !isRemoveable,
          })}
          onClick={onRemoveColumn}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onRemoveColumn();
            }
          }}
          role="none"
        >
          <Minus weight="bold" size={12} />
        </div>
        <Text size="2xs" weight="medium">
          {activeNode?.content.childCount}
        </Text>
        <div
          className={cn('hover:bg-wb-tertiary rounded-full p-1 text-wb-secondary', {
            'cursor-pointer': isAddable,
            'opacity-20': !isAddable,
          })}
          onClick={onAddColumn}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onAddColumn();
            }
          }}
          role="none"
        >
          <Plus weight="bold" size={12} />
        </div>
      </SimpleInputWrapper>
    </div>
  );
}
