import { useState } from 'react';

import useActiveFilterCount from '@/hooks/boosts/grow/useBoostAgreements/useActiveFilterCount';
import { Order } from '@/interfaces/general';
import { FiltersPopoverSection } from '@/ui/FiltersPopover/FiltersPopover.types';

interface ResourceListFiltersProps<FilterType, OrderByType> {
  defaultFilter?: FilterType;
  defaultSortOrder?: OrderByType | string;
  popoverSections?: FiltersPopoverSection[];
  onClearFilters?: () => void;
}

function useResourceListFilters<FilterType, OrderByType>({
  defaultFilter,
  defaultSortOrder = '',
  popoverSections = [],
  onClearFilters,
}: ResourceListFiltersProps<FilterType, OrderByType>) {
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<FilterType | string>(defaultFilter || '');
  const [orderBy, setOrderBy] = useState<OrderByType | string>(defaultSortOrder);
  const [direction, setDirection] = useState<Order>(Order.DESC);

  const { activeFilterSectionsCount } = useActiveFilterCount(popoverSections);

  const handleClickOrderButton = (newOrderBy: OrderByType, newDirection: Order) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  return {
    direction,
    setDirection,
    orderBy,
    setOrderBy,
    hasFilter: search !== '' || filter !== defaultFilter || activeFilterSectionsCount > 0,
    activeFilterSectionsCount,
    search,
    setSearch,
    resetSearch: () => setSearch(''),
    filter,
    setFilter,
    resetFilter: () => setFilter(defaultFilter || ''),
    resetAllFilters: () => {
      setSearch('');
      setFilter(defaultFilter || '');
      onClearFilters?.();
    },
    handleClickOrderButton,
  };
}

export default useResourceListFilters;
