import { useCallback, useEffect, useRef, useState } from 'react';

import { cn } from '@/utils/cn';

import { useNavbarContext } from './NavbarContext';

export const SelectionBox = () => {
  const { isDragging, selectedNodeEl, iframeRef, editorContainerRef, hoveredContentID, hoverNodeEl, contentWidth } =
    useNavbarContext();
  const [selectionBoxStyle, setSelectionBoxStyle] = useState<React.CSSProperties>({
    display: 'none',
  });
  const [hoverBoxStyle, setHoverBoxStyle] = useState<React.CSSProperties>({
    display: 'none',
  });
  const [parentStyle, setParentStyle] = useState<React.CSSProperties>({
    display: 'none',
  });

  const isInitializedHover = useRef(false);
  const isInitializedSelection = useRef(false);

  useEffect(() => {
    isInitializedSelection.current = false;
  }, [selectedNodeEl]);

  useEffect(() => {
    isInitializedHover.current = false;
  }, [hoveredContentID]);

  const updateBox = useCallback(
    (el: HTMLElement): React.CSSProperties | null => {
      const iframeRect = iframeRef.current?.getBoundingClientRect();
      const containerRect = editorContainerRef.current?.getBoundingClientRect();
      const iframeWindow = iframeRef.current?.contentWindow;
      const iframeDocument = iframeRef.current?.contentDocument;

      if (iframeRect && containerRect && iframeWindow && iframeDocument) {
        if (!el) return null;
        const nodeRect = el.getBoundingClientRect();
        const scrollX = iframeWindow.scrollX || 0;
        const scrollY = iframeWindow.scrollY || 0;

        // Calculate zoom by comparing iframe's visual size to its content size
        const zoom = iframeRect.width / iframeDocument.documentElement.clientWidth;
        return {
          display: 'block',
          position: 'absolute',
          left: (nodeRect.left - scrollX) * zoom,
          top: iframeRect.top - containerRect.top + (nodeRect.top - scrollY) * zoom,
          width: nodeRect.width * zoom,
          height: nodeRect.height * zoom,
          pointerEvents: 'none',
          boxSizing: 'border-box',
        };
      }
      return null;
    },
    [iframeRef, editorContainerRef]
  );

  useEffect(() => {
    if (isInitializedSelection.current || !selectedNodeEl) return () => {};
    isInitializedSelection.current = true;

    const updateSelectionBoxes = () => {
      const boxStyle = updateBox(selectedNodeEl);
      if (boxStyle) {
        setSelectionBoxStyle(boxStyle);
      }

      let parentNode = selectedNodeEl.parentElement;
      if (!parentNode) {
        setParentStyle({
          display: 'none',
        });
        return;
      }

      if (parentNode && !Array.from(parentNode.classList).some((className) => className.startsWith('dream-'))) {
        // Some components are wrapped with <div> from radix and so the real parent are the one above it.
        parentNode = parentNode.parentNode as HTMLElement;
      }

      const parentBoxStyle = updateBox(parentNode);
      if (parentBoxStyle) {
        setParentStyle(parentBoxStyle);
      }
    };

    updateSelectionBoxes();

    const delayedUpdateSelectionBoxes = () => setTimeout(updateSelectionBoxes, 0);
    const currentIframe = iframeRef.current;
    const scrollableContainer = currentIframe?.contentDocument?.getElementById('scrollable-container');

    // Add event listeners for scroll and resize
    window.addEventListener('resize', updateSelectionBoxes);
    currentIframe?.contentWindow?.addEventListener('scroll', updateSelectionBoxes);
    scrollableContainer?.addEventListener('scroll', updateSelectionBoxes);
    currentIframe?.contentWindow?.addEventListener('drop', delayedUpdateSelectionBoxes);

    // Create a ResizeObserver to detect size changes
    let resizeObserver: ResizeObserver | null = null;
    if (selectedNodeEl && 'ResizeObserver' in window) {
      resizeObserver = new ResizeObserver((entries) => {
        // Wrap the callback in requestAnimationFrame
        window.requestAnimationFrame(() => {
          if (!entries.length) return;
          updateSelectionBoxes();
        });
      });
      resizeObserver.observe(selectedNodeEl);
    }

    return () => {
      window.removeEventListener('resize', updateSelectionBoxes);
      currentIframe?.contentWindow?.removeEventListener('scroll', updateSelectionBoxes);
      scrollableContainer?.removeEventListener('scroll', updateSelectionBoxes);
      currentIframe?.contentWindow?.removeEventListener('drop', delayedUpdateSelectionBoxes);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [selectedNodeEl, iframeRef, editorContainerRef, updateBox, contentWidth]);

  useEffect(() => {
    if (isInitializedHover.current || !hoverNodeEl) return () => {};
    isInitializedHover.current = true;

    const updateSelectionBox = () => {
      const boxStyle = updateBox(hoverNodeEl);
      if (boxStyle) {
        setHoverBoxStyle(boxStyle);
      }
    };

    updateSelectionBox();

    const delayedUpdateSelectionBox = () => setTimeout(() => updateSelectionBox(), 0);
    const currentIframe = iframeRef.current;
    const scrollableContainer = currentIframe?.contentDocument?.getElementById('scrollable-container');

    // Add event listeners for scroll and resize
    window.addEventListener('resize', updateSelectionBox);
    currentIframe?.contentWindow?.addEventListener('scroll', updateSelectionBox);
    scrollableContainer?.addEventListener('scroll', updateSelectionBox);
    currentIframe?.contentWindow?.addEventListener('drop', delayedUpdateSelectionBox);

    // Create a ResizeObserver to detect size changes
    let resizeObserver: ResizeObserver | null = null;
    if (hoverNodeEl && 'ResizeObserver' in window) {
      resizeObserver = new ResizeObserver((entries) => {
        // Wrap the callback in requestAnimationFrame
        window.requestAnimationFrame(() => {
          if (!entries.length) return;
          updateSelectionBox();
        });
      });
      resizeObserver.observe(hoverNodeEl);
    }

    return () => {
      window.removeEventListener('resize', updateSelectionBox);
      currentIframe?.contentWindow?.removeEventListener('scroll', updateSelectionBox);
      scrollableContainer?.removeEventListener('scroll', updateSelectionBox);
      currentIframe?.contentWindow?.removeEventListener('drop', delayedUpdateSelectionBox);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [hoverNodeEl, iframeRef, editorContainerRef, updateBox, contentWidth]);

  return (
    <>
      <div
        style={{
          ...parentStyle,
          display: selectedNodeEl && !isDragging ? 'block' : 'none',
        }}
        className="outline-dashed outline-1 outline-wb-accent"
      />
      <div
        style={{
          ...selectionBoxStyle,
          display: selectedNodeEl && !isDragging ? 'block' : 'none',
        }}
        className="border border-solid border-wb-accent select-none"
      />
      <div
        style={{
          ...hoverBoxStyle,
          display: hoverNodeEl && !isDragging ? 'block' : 'none',
        }}
        className={cn('border border-solid border-wb-accent select-none')}
      />
    </>
  );
};
