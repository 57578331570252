import { MouseEventHandler, ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DotsSixVertical } from '@phosphor-icons/react';
import classNames from 'classnames';

import { Text } from './Text';

interface Props {
  id: string;
  label: string;
  className?: string;
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  actions?: ReactNode;
}

const SimpleSortableItem = ({ id, label, className, onClick, isSelected = false, actions }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      className={classNames(
        'w-full rounded-md hover:bg-surface-100 flex flex-row gap-x-2 items-center',
        isSelected && 'bg-surface-100',
        className
      )}
      ref={setNodeRef}
      style={style}
    >
      <div
        className={classNames('mt-0.5 py-2 pl-2', isDragging ? 'cursor-grabbing' : 'cursor-grab')}
        {...listeners}
        {...attributes}
      >
        <DotsSixVertical className="h-3.5 w-3.5" />
      </div>
      <button type="button" onClick={onClick} className="w-full text-left capitalize py-2 pr-2">
        <Text size="sm">{label}</Text>
      </button>
      {actions}
    </li>
  );
};

export default SimpleSortableItem;
