import { PropsWithChildren, useState } from 'react';
import toast from 'react-hot-toast';
import { CursorText, Pencil, Trash } from '@phosphor-icons/react';
import { DropdownMenuContentProps, DropdownMenuProps } from '@radix-ui/react-dropdown-menu';

import { useDeleteSiteTemplate } from '@/hooks/useSiteTemplates';
import useUpdateSiteTemplate from '@/hooks/useSiteTemplates/useUpdateSiteTemplate';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/routes/website/_components/UI/DropdownMenu';
import { Input } from '@/routes/website/_components/UI/Input';
import Modal from '@/routes/website/_components/UI/Modal';
import { Text } from '@/routes/website/_components/UI/Text';

type Props = {
  siteTemplateId: string;
  onDeleteSuccess?: () => void;
  allowRename?: boolean;
  allowRawJson?: boolean;
  onRawJsonClick?: () => void;
  showDelete?: boolean;
  showEdit?: boolean;
};

export const TemplateOptions = ({
  children,
  align,
  siteTemplateId,
  onDeleteSuccess,
  allowRename,
  allowRawJson,
  onRawJsonClick,
  showDelete,
  showEdit,
  ...props
}: PropsWithChildren<{ align: DropdownMenuContentProps['align'] } & DropdownMenuProps & Props>) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [renameModalOpen, setRenameModalOpen] = useState(false);

  const updateSiteTemplate = useUpdateSiteTemplate({
    id: siteTemplateId,
    onSuccess: () => {
      setRenameModalOpen(false);
      setNewName('');
      toast.success('Template Renamed!');
    },
  });

  const deleteSiteTemplate = useDeleteSiteTemplate({
    siteTemplateId,
    onSuccess: () => {
      onDeleteSuccess?.();
      setIsDeleteModalOpen(false);
    },
  });

  const handleDelete = () => {
    deleteSiteTemplate.mutate();
  };

  const handleRename = () => {
    updateSiteTemplate.mutate({ name: newName });
  };

  return (
    <>
      <DropdownMenu {...props}>
        <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={2} align={align} className="w-[150px]">
          {allowRawJson && (
            <DropdownMenuItem
              LeftIcon={CursorText}
              onSelect={(e) => {
                e.stopPropagation();
                onRawJsonClick?.();
              }}
            >
              Edit Raw JSON
            </DropdownMenuItem>
          )}
          {showEdit && (
            <DropdownMenuItem
              LeftIcon={Pencil}
              asLink
              linkProps={{ to: `/website_builder_v2/templates/${siteTemplateId}` }}
            >
              Edit
            </DropdownMenuItem>
          )}
          {allowRename && (
            <DropdownMenuItem
              LeftIcon={CursorText}
              onSelect={(e) => {
                e.stopPropagation();
                setRenameModalOpen(true);
              }}
            >
              Rename
            </DropdownMenuItem>
          )}
          {showDelete && (
            <DropdownMenuItem
              LeftIcon={Trash}
              onSelect={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(true);
              }}
              disabled={false}
            >
              Delete
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title="Delete Site Template"
        onConfirm={handleDelete}
        ctaText={deleteSiteTemplate.isLoading ? 'Deleting...' : 'Delete Site Template'}
        ctaVariant="danger"
        isLoading={deleteSiteTemplate.isLoading}
      >
        <Text size="sm" weight="medium" variant="secondary" as="p" className="mb-4">
          Are you sure you want to delete this site template? This will delete the current site template.
        </Text>
      </Modal>
      <Modal
        isOpen={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
        title="Rename Site Template"
        onConfirm={handleRename}
        ctaText={updateSiteTemplate.isLoading ? 'Renaming...' : 'Rename'}
        ctaVariant="primary"
        isLoading={updateSiteTemplate.isLoading}
      >
        <Text size="sm" weight="medium" variant="secondary" as="p" className="mb-3">
          Enter a new name for your site template:
        </Text>
        <Input placeholder="New template name" value={newName} onChange={(e) => setNewName(e.target.value)} />
      </Modal>
    </>
  );
};
