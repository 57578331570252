import { FC } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import { ItemColumn, ItemHeader, ItemHeaders, ItemRow, Items, ItemsBody } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import { useCreatePaywall, useUpdatePaywall } from '@/hooks/usePaywalls';
import { Pagination } from '@/interfaces/general';
import { Paywall } from '@/interfaces/paywall';
import { Button } from '@/ui/Button';

interface Props {
  paywalls: Paywall[] | undefined;
  pagination: Pagination | undefined;
  onEditSelected: (paywall: Paywall) => void;
  onDeleteSelected: (paywall: Paywall) => void;
  onLoadMore: () => void;
  isLoadingMore: boolean;
  hasNextPage: boolean;
  currentPublicationId: string;
  handleInvalidateQueries: () => void;
}

const TableRow = ({
  paywall,
  onEditSelected,
  onDeleteSelected,
  currentPublicationId,
  handleInvalidateQueries,
}: {
  paywall: Paywall;
  onEditSelected: (paywall: Paywall) => void;
  onDeleteSelected: (paywall: Paywall) => void;
  currentPublicationId: string;
  handleInvalidateQueries: () => void;
}) => {
  const updatePaywall = useUpdatePaywall({
    paywallId: paywall?.id || '',
    onSuccess: () => {
      handleInvalidateQueries();
    },
  });

  const handleSetDefault = () => {
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('paywall[website_default]', 'true');

    updatePaywall.mutate(formData);
  };

  const createPaywall = useCreatePaywall({
    onSuccess: () => {
      handleInvalidateQueries();
    },
  });

  const handleDuplicate = () => {
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);
    formData.append('paywall[name]', `${paywall.name} (Copy)`);
    formData.append('paywall[title]', paywall.title);
    formData.append('paywall[description]', paywall.description);
    formData.append('paywall[cta_text]', paywall.cta_text);
    formData.append('paywall[subheader]', paywall.subheader);
    formData.append('paywall[benefits]', JSON.stringify(paywall.benefits));
    formData.append('paywall[style]', JSON.stringify(paywall.style));

    if (paywall.offer_id) {
      formData.append('paywall[offer_id]', paywall.offer_id);
    }

    createPaywall.mutate(formData);
  };

  return (
    <ItemRow key={paywall.id} className="last:border-b-0">
      <ItemColumn align={AlignType.LEFT}>
        <Typography token="font-medium/text/sm">{`${paywall.name}`}</Typography>
      </ItemColumn>

      <ItemColumn align={AlignType.CENTER}>
        <Typography token="font-medium/text/sm" className="flex justify-center">
          {paywall.website_default ? <CheckIcon className="w-4 h-4 text-green-500" /> : null}
        </Typography>
      </ItemColumn>

      <ItemColumn align={AlignType.RIGHT} className="pr-8">
        <EllipsisDropdown
          options={[
            { label: 'Edit', onClick: () => onEditSelected(paywall) },
            { label: 'Duplicate', onClick: handleDuplicate },
            { label: 'Set as Default Paywall', onClick: handleSetDefault },
            { label: 'Delete', isDestructive: true, onClick: () => onDeleteSelected(paywall) },
          ]}
        />
      </ItemColumn>
    </ItemRow>
  );
};

const Table: FC<Props> = ({
  handleInvalidateQueries,
  currentPublicationId,
  paywalls,
  pagination,
  onEditSelected,
  onDeleteSelected,
  onLoadMore,
  isLoadingMore,
  hasNextPage,
}: Props) => {
  if (!paywalls || !pagination) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <Items className="border rounded-lg !shadow-none">
        <ItemHeaders>
          <ItemHeader align={AlignType.LEFT}>Name</ItemHeader>
          <ItemHeader align={AlignType.CENTER}>Default Paywall</ItemHeader>
          <ItemHeader>
            <span className="sr-only">Actions</span>
          </ItemHeader>
        </ItemHeaders>
        <ItemsBody>
          {paywalls.map((paywall: Paywall) => (
            <TableRow
              key={paywall.id}
              paywall={paywall}
              currentPublicationId={currentPublicationId}
              handleInvalidateQueries={handleInvalidateQueries}
              onEditSelected={onEditSelected}
              onDeleteSelected={onDeleteSelected}
            />
          ))}
        </ItemsBody>
      </Items>
      <div className="flex justify-between items-center">
        <Typography token="font-normal/text/xs" colorWeight="500" className="mb-2">
          Showing page {pagination.page} of {pagination.total_pages}
        </Typography>
        <div>
          {hasNextPage && (
            <Button
              type="button"
              variant="primary-inverse"
              size="xs"
              onClick={onLoadMore}
              disabled={isLoadingMore}
              loading={isLoadingMore}
            >
              {isLoadingMore ? 'Loading...' : 'Load more'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
