import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Resizable } from 'react-resizable';
import { ImageElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { NodeViewContent } from '@tiptap/react';

import { useViewport } from '@/routes/website/_components/Editor/DesktopMobileTabs';

import { getDataAttributes } from '../../utils';

import { ImageUpload } from './ImageUpload';
import { getOriginalAspectRatio } from './utils';

export const ImageBlockView: FC<NodeViewProps> = (nodeViewProps) => {
  const { HTMLAttributes, node, selected, editor, getPos } = nodeViewProps;
  const imageRef = useRef<HTMLDivElement | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const { id, src } = node.attrs;

  useEffect(() => {
    if (src) {
      // append the base URL to the src
      if (window?.env?.REACT_APP_HEROKU_ENV === 'development' && !src.includes('http')) {
        setImage(`${window?.env?.REACT_APP_API_BASE_URL}${src}`);
      } else {
        setImage(src);
      }
    } else {
      setImage(null);
    }
  }, [src]);

  const [height, setHeight] = useState(node.attrs.height);
  const [width, setWidth] = useState(node.attrs.width);
  const [initialAspectRatio, setInitialAspectRatio] = useState(1);
  const imgSrc = useRef<string>();
  const [isResizing, setIsResizing] = useState(false);
  const { viewport } = useViewport();

  const isMobile = useMemo(() => viewport.type === 'mobile', [viewport]);

  useEffect(() => {
    if (!imageRef.current) return;
    if (!isMobile) {
      imageRef.current!.style.setProperty('--max-height', `${node.attrs.width / initialAspectRatio}px`);
      imageRef.current!.style.maxWidth = `${node.attrs.width}px`;
      imageRef.current!.style.maxHeight = `${node.attrs.width / initialAspectRatio}px`;
      return;
    }

    // clear resize styling
    imageRef.current!.style.setProperty(
      '--mobile-max-height',
      `${(node.attrs.mobileWidth || node.attrs.width) / initialAspectRatio}px`
    );
    imageRef.current!.style.maxWidth = `${node.attrs.mobileWidth || node.attrs.width}px`;
    imageRef.current!.style.maxHeight = `${(node.attrs.mobileWidth || node.attrs.width) / initialAspectRatio}px`;
  }, [isMobile, node, initialAspectRatio]);

  useEffect(() => {
    if (isResizing) return;
    if (imgSrc.current === node.attrs.src) return;

    if (node.attrs?.src) {
      getOriginalAspectRatio(node).then(({ width: fetchedWidth, height: fetchedHeight, aspectRatio }) => {
        imgSrc.current = node.attrs.src;
        const rect = imageRef.current?.getBoundingClientRect();

        // currently image will always maintain aspect ratio, so we can make
        // sure that the height is set correctly according to the aspect ratio
        const currentHeight = rect?.height || node.attrs[isMobile ? 'mobileHeight' : 'height'] || fetchedHeight;
        const currentWidth = rect?.width || node.attrs[isMobile ? 'mobileWidth' : 'width'] || fetchedWidth;
        const currentAspectRatio = node.attrs.aspectRatio;

        setWidth(currentWidth);

        const computedHeightWithAspectRatio = currentWidth / aspectRatio;
        if (computedHeightWithAspectRatio !== currentHeight || fetchedHeight !== node.attrs.height) {
          setHeight(computedHeightWithAspectRatio);
        }

        // set the nodes
        if (computedHeightWithAspectRatio !== currentHeight || currentAspectRatio !== aspectRatio) {
          editor.commands.command(({ tr }) => {
            if (computedHeightWithAspectRatio !== currentHeight) {
              tr.setNodeAttribute(getPos(), isMobile ? 'mobileHeight' : 'height', computedHeightWithAspectRatio);
            }
            if (currentAspectRatio !== aspectRatio) {
              tr.setNodeAttribute(getPos(), 'aspectRatio', aspectRatio);
            }
            return true;
          });
        }

        setInitialAspectRatio(aspectRatio);
      });
    }
  }, [node, editor, getPos, isResizing, isMobile]);

  useEffect(() => {
    if (!height || !width || !initialAspectRatio) return;
    if (isResizing) return;
    if (!imageRef.current) return;

    if (node.attrs.width) {
      setWidth(node.attrs.width);
      setHeight(node.attrs.width / initialAspectRatio);
    }

    if (!node.attrs.isFullWidth) {
      imageRef.current!.style.maxWidth = `${node.attrs[isMobile ? 'mobileWidth' : 'width']}px`;
    }
    imageRef.current!.style.maxHeight = `${node.attrs[isMobile ? 'mobileWidth' : 'width'] / initialAspectRatio}px`;
  }, [isResizing, width, height, node.attrs, initialAspectRatio, isMobile]);

  const handleResizeStop = useCallback(
    (e: any, data: any) => {
      if (!selected) return;

      setHeight(data.size.height);
      setWidth(data.size.width);

      // set width & height attribute
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(getPos(), 'height', data.size.height);
        tr.setNodeAttribute(getPos(), 'width', data.size.width);
        return true;
      });

      setIsResizing(false);
    },
    [editor, getPos, selected]
  );

  const handleResizeStart = useCallback(() => {
    setIsResizing(true);
  }, []);

  const handleResize = useCallback(
    (e: any, data: any) => {
      if (!selected) return;

      setHeight(data.size.height);
      setWidth(data.size.width);

      imageRef.current!.style.maxWidth = `${data.size.width}px`;
      imageRef.current!.style.maxHeight = `${data.size.height}px`;
      imageRef.current!.style.setProperty('--max-height', `${data.size.height}px`);
    },
    [selected]
  );

  if (!image) {
    return <ImageUpload {...nodeViewProps} />;
  }

  const showResize = selected && !node.attrs.isFullWidth && !isMobile;

  return (
    <ImageElement
      ref={imageRef}
      element={{
        type: 'image',
        id,
        attrs: {
          id,
          ...node.attrs,
          src: image || undefined,
        },
        content: [{ id: 'text', text: '' }],
      }}
      attributes={{
        'data-node-view-wrapper': true,
        ...getDataAttributes(HTMLAttributes),
      }}
    >
      {showResize && (
        <>
          {/* pill shaped resize handle left and right */}
          {height && width && selected && editor.isActive('image') && (
            <Resizable
              draggableOpts={{ grid: [10] }}
              lockAspectRatio
              height={height}
              width={width}
              axis="x"
              onResizeStop={handleResizeStop}
              onResizeStart={handleResizeStart}
              onResize={handleResize}
              resizeHandles={['w', 'e']}
            >
              <div className="absolute top-0 left-0 h-full w-full" />
            </Resizable>
          )}
        </>
      )}
      <NodeViewContent />
    </ImageElement>
  );
};
