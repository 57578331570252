export const convertHexToRgba = (hex: string | 'transparent') => {
  let parsedHex = hex;

  if (hex === 'transparent') {
    return { r: 0, g: 0, b: 0, a: 0 };
  }

  if (hex.length === 4) {
    // if hexcode is 4 digits, add the alpha value
    parsedHex = hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
  }

  const r = parseInt(parsedHex.slice(1, 3), 16);
  const g = parseInt(parsedHex.slice(3, 5), 16);
  const b = parseInt(parsedHex.slice(5, 7), 16);

  const a = parsedHex.length === 9 ? parseInt(parsedHex.slice(7, 9), 16) / 255 : 1;
  return { r, g, b, a };
};

export const convertRgbaToHex = (
  rgba: { r: number; g: number; b: number; a?: number },
  options?: { alpha?: boolean }
) => {
  const { r, g, b, a = 1 } = rgba;
  const alpha = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0');

  if (options?.alpha) {
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
      .toString(16)
      .padStart(2, '0')}${alpha}`.toUpperCase();
  }

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`.toUpperCase();
};
