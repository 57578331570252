import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import { Post } from '@/interfaces/post';

import Tooltip from '../../ui/Tooltip';

import { ScreenSize } from './types';

const AttributeRow = ({ label, value, screenSize }: { label: string; value: JSX.Element; screenSize: ScreenSize }) => {
  return (
    <div className="w-full grid grid-cols-12">
      <div className={cx(screenSize === 'mobile' ? 'col-span-3' : 'col-span-2')}>
        <Typography
          token="font-normal/text/sm"
          colorWeight="500"
          className={cx(screenSize === 'mobile' ? 'col-span-3' : 'col-span-1')}
        >
          {label}
        </Typography>
      </div>
      <div className={cx(screenSize === 'mobile' ? 'col-span-9' : 'col-span-10')}>{value}</div>
    </div>
  );
};

const EmailInformation = ({ post, screenSize, to }: { post: Post; screenSize: ScreenSize; to?: string }) => {
  const { settings } = useSettings();
  const smartWarmingEnabled = settings?.smart_warming || false;

  return (
    <div className="flex flex-col gap-3 items-start self-stretch px-6 p-3 bg-surface-50 border-b border-b-surface-200">
      <AttributeRow
        label="From:"
        value={
          <span className="flex flex-row gap-2">
            <Typography token="font-medium/text/sm" colorWeight="700">
              {post.email_sender_display_address}
            </Typography>
            {smartWarmingEnabled && (
              <Tooltip
                id="smart-warming-warning"
                iconClass="my-auto"
                text="If your account is undergoing smart-warming a portion of subscribers will see this address while the remaining will see your beehiiv domain address"
              />
            )}
          </span>
        }
        screenSize={screenSize}
      />
      {to && (
        <AttributeRow
          label="To:"
          value={
            <Typography token="font-medium/text/sm" colorWeight="700">
              {`${to} <${to}>`}
            </Typography>
          }
          screenSize={screenSize}
        />
      )}
      <AttributeRow
        label="Subject:"
        value={
          <Typography token="font-medium/text/sm" colorWeight="700">
            {post.email_subject_line || 'Subject Line'}
          </Typography>
        }
        screenSize={screenSize}
      />
      <AttributeRow
        label="Preview Text:"
        value={
          <Typography token="font-medium/text/sm" colorWeight="700">
            {post.email_preview_text || 'Preview Text'}
          </Typography>
        }
        screenSize={screenSize}
      />
    </div>
  );
};

export default EmailInformation;
