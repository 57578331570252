import { useMemo } from 'react';

import { FiltersPopoverSection, FiltersPopoverSectionType } from '@/ui/FiltersPopover/FiltersPopover.types';

const useActiveFilterCount = (sections: FiltersPopoverSection[]) => {
  const activeFilterSectionsCount = useMemo(() => {
    return sections.filter((section) => {
      if (section.type === FiltersPopoverSectionType.CURRENCY_RANGE) {
        return Boolean(section.lowerBoundValue) || Boolean(section.upperBoundValue);
      }
      if (section.type === FiltersPopoverSectionType.HEADER) return false;
      if (section.type === FiltersPopoverSectionType.MULTI_SELECT) {
        return section.value.length > 0;
      }
      if (section.type === FiltersPopoverSectionType.NUMBER) {
        return Boolean(section.value);
      }
      if (section.type === FiltersPopoverSectionType.SELECT) {
        return Boolean(section.value);
      }
      return false;
    }).length;
  }, [sections]);

  const hasActiveFilters = activeFilterSectionsCount > 0;

  return {
    activeFilterSectionsCount,
    hasActiveFilters,
  };
};

export default useActiveFilterCount;
