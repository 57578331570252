import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Banner from '@/components/Banner';
import { FileInput } from '@/components/Form';
import SelectAutomation from '@/components/SelectAutomation/SelectAutomation';
import SubscriberTagSelectInput from '@/components/SubscriberTags/SubscriberTagSelectInput';
import { PublicationSubscriberTagReference } from '@/interfaces/subscriber_tag';
import { SubscriberImportType } from '@/pages/Segments/types';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';
import { FormStep } from '@/ui/FormStep';
import HelperText from '@/ui/HelperText';
import Switch from '@/ui/Switch';
import { Textarea } from '@/ui/Textarea';

import FormRow from '../../../_components/FormRow';

import { LABEL_BY_IMPORT_TYPE } from './constants';

interface Props {
  onFileChange: (file: File | null) => void;
  selectedFile: File | null;
  commaSeparatedEmails: string;
  onCommaSeparatedEmailsChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  shouldSendWelcomeEmail: boolean;
  onSendWelcomeEmailChange: (value: boolean) => void;
  shouldDisableWelcomeEmail: boolean;
  canSkipEmailValidation: boolean;
  shouldSkipEmailValidation: boolean;
  onSkipEmailValidationChange: (value: boolean) => void;
  csvUploadElementLabel?: string;
  csvTextInputElementLabel?: string;
  isAddingToAutomations?: boolean;
  onAddToAutomationsChange?: (value: boolean) => void;
  selectedAutomations?: string[];
  onSelectAutomation?: (automationId: string) => void;
  onDeselectAutomation?: (automationId: string) => void;
  onDeselectAllAutomations?: () => void;
  canEnrollToAutomations?: boolean;
  selectedImportType: SubscriberImportType;
  onImportTypeChange: (name: string, value: SubscriberImportType) => void;
  taggingEnabled?: boolean;
  handleSubscriberTagSelect?: (tag: any) => void;
  handleSubscriberTagDeselect?: (subscriberTagId: string) => void;
  onAddSubscriberTagsChange?: (value: boolean) => void;
  isAddingSubscriberTags?: boolean;
  selectedSubscriberTags?: PublicationSubscriberTagReference[];
  onImportConfirm: () => void;
}

enum FormSteps {
  METHOD = 1,
  CONFIGURATION = 2,
}

const ImportSubscriberListForm = ({
  onFileChange,
  selectedFile,
  commaSeparatedEmails,
  onCommaSeparatedEmailsChange,
  shouldSendWelcomeEmail,
  shouldDisableWelcomeEmail,
  onSendWelcomeEmailChange,
  canSkipEmailValidation,
  shouldSkipEmailValidation,
  onSkipEmailValidationChange,
  onSelectAutomation,
  onDeselectAutomation,
  onDeselectAllAutomations,
  isAddingToAutomations,
  onAddToAutomationsChange,
  selectedAutomations = [],
  canEnrollToAutomations = false,
  selectedImportType,
  onImportTypeChange,
  taggingEnabled,
  handleSubscriberTagSelect,
  handleSubscriberTagDeselect,
  onAddSubscriberTagsChange,
  isAddingSubscriberTags,
  selectedSubscriberTags,
  onImportConfirm,
  csvTextInputElementLabel = 'Input a list of emails, separated by commas',
  csvUploadElementLabel = 'Upload Email List CSV',
}: Props) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [stepErrors, setStepErrors] = useState<{ file?: string; commaSeparatedEmails?: string }>({});

  const getStepErrors = (step: number) => {
    switch (step) {
      case 1:
        if (selectedImportType === SubscriberImportType.CSV && !selectedFile) {
          return { file: 'Please select a file' };
        }
        if (selectedImportType === SubscriberImportType.Manual && !commaSeparatedEmails) {
          return { commaSeparatedEmails: 'Please enter emails' };
        }
        break;
      default:
        return null;
    }

    return null;
  };

  const handleCancel = () => navigate('/settings/publication/subscribers_import');

  const handleClickPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleClickNextStep = () => {
    const errors = getStepErrors(currentStep);
    if (errors) {
      setStepErrors(errors);
    } else {
      setStepErrors({});
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <>
      <FormStep
        isOpen={currentStep === FormSteps.METHOD}
        stepTitle="Import Method"
        footer={
          <>
            <Button type="button" variant="primary-inverse" onClick={handleCancel} size="sm">
              Back
            </Button>
            <Button type="button" variant="primary" onClick={handleClickNextStep} size="sm">
              Next
            </Button>
          </>
        }
      >
        <Banner
          title="By importing subscribers, you agree to the following:"
          isScreenWide={false}
          bodyText={
            <ul className="space-y-1 list-disc">
              <li>
                You verify that all subscribers being imported have knowingly consented and opted into your newsletter
              </li>
              <li>
                You acknowledge that by falsely claiming the above statements, you will be in direct violation of
                beehiiv&apos;s{' '}
                <a href="https://www.beehiiv.com/tou" target="_blank" rel="noreferrer" className="underline">
                  ToS
                </a>{' '}
                &{' '}
                <a href="https://www.beehiiv.com/privacy" target="_blank" rel="noreferrer" className="underline">
                  Privacy Policy
                </a>
                , which can lead to immediate termination of service.
              </li>
            </ul>
          }
        />
        <Dropdown
          className="w-full"
          name="measure"
          labelText="Import method"
          helperText="Choose the type of import method you want to use "
          value={selectedImportType}
          onSelect={onImportTypeChange}
          options={[
            {
              label: LABEL_BY_IMPORT_TYPE[SubscriberImportType.CSV],
              value: SubscriberImportType.CSV,
            },
            {
              label: LABEL_BY_IMPORT_TYPE[SubscriberImportType.Manual],
              value: SubscriberImportType.Manual,
            },
          ]}
        />

        {selectedImportType === SubscriberImportType.CSV && (
          <FileInput
            name="file"
            labelText={csvUploadElementLabel}
            helperText="The entire CSV will be scanned for emails. Please only include emails that should be subscribed to your newsletter."
            onFileSelect={onFileChange}
            onFileRemoved={onFileChange}
            file={selectedFile}
            accept=".csv"
            errorText={stepErrors.file}
          />
        )}

        {selectedImportType === SubscriberImportType.Manual && (
          <Textarea
            name="comma_separated_emails"
            helperText="Input your list of emails by separating them with commas"
            value={commaSeparatedEmails}
            onChange={onCommaSeparatedEmailsChange}
            labelText={csvTextInputElementLabel}
            placeholderText="email1@beehiiv.com, email2@beehiiv.com, email3@beehiiv.com, etc."
            errorText={stepErrors.commaSeparatedEmails}
          />
        )}
      </FormStep>
      <FormStep
        isOpen={currentStep === FormSteps.CONFIGURATION}
        stepTitle="Advanced Configuration (Optional)"
        footer={
          <>
            <Button type="button" variant="primary-inverse" onClick={handleClickPreviousStep} size="sm">
              Back
            </Button>
            <Button type="button" variant="primary" onClick={onImportConfirm} size="sm">
              Confirm Subscribers Import
            </Button>
          </>
        }
      >
        <div className="flex flex-col gap-y-2">
          <FormRow
            label="Send Welcome Email"
            helperText="Enable your custom welcome email for the newly imported subscribers"
          >
            <Switch
              variant="primary"
              checked={shouldSendWelcomeEmail}
              name="should_send_welcome_email"
              onChange={(_: string, value: boolean) => onSendWelcomeEmailChange(value)}
            />
          </FormRow>
          <HelperText>
            {shouldDisableWelcomeEmail ? (
              <>
                {'You need to first setup a '}
                <Link to="/settings/publication/emails/welcome_email/edit" className="underline hover:text-primary-600">
                  welcome email
                </Link>
                {' to enable this option'}
              </>
            ) : (
              'If enabled, each imported email will automatically receive your welcome email'
            )}
          </HelperText>
        </div>

        {canSkipEmailValidation && (
          <FormRow
            label="Skip Email Validation"
            helperText="Email verification will reject bad emails that don't exist or have typos. Skip it for faster uploads, but at risk of worse quality subscribers"
          >
            <Switch
              variant="primary"
              checked={shouldSkipEmailValidation}
              name="should_skip_email_validation"
              onChange={(_: string, value: boolean) => onSkipEmailValidationChange(value)}
            />
          </FormRow>
        )}

        <div className="flex flex-col gap-y-2">
          {canEnrollToAutomations &&
            onAddToAutomationsChange &&
            onSelectAutomation &&
            onDeselectAutomation &&
            onDeselectAllAutomations && (
              <>
                <FormRow label="Add to Automation(s)">
                  <Switch
                    variant="primary"
                    checked={isAddingToAutomations || false}
                    name="add_to_automations"
                    onChange={(_: string, value: boolean) => onAddToAutomationsChange(value)}
                  />
                </FormRow>
                {isAddingToAutomations && (
                  <SelectAutomation
                    onSelect={onSelectAutomation}
                    onDeselect={onDeselectAutomation}
                    onDeselectAll={onDeselectAllAutomations}
                    selectedAutomations={selectedAutomations}
                    isEnrollingMultipleSubscribers
                  />
                )}
              </>
            )}
        </div>

        <div className="flex flex-col gap-y-2">
          {taggingEnabled &&
            selectedSubscriberTags &&
            onAddSubscriberTagsChange &&
            handleSubscriberTagSelect &&
            handleSubscriberTagDeselect && (
              <>
                <FormRow label="Apply Subscriber Tags">
                  <Switch
                    variant="primary"
                    checked={isAddingSubscriberTags || false}
                    name="add_subscriber_tags"
                    onChange={(_: string, value: boolean) => onAddSubscriberTagsChange(value)}
                  />
                </FormRow>
                {isAddingSubscriberTags && (
                  <SubscriberTagSelectInput
                    selectValue="id"
                    selectedTags={selectedSubscriberTags}
                    handleDeselect={handleSubscriberTagDeselect}
                    handleSelect={handleSubscriberTagSelect}
                  />
                )}
              </>
            )}
        </div>
      </FormStep>
    </>
  );
};
export default ImportSubscriberListForm;
