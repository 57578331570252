import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { AccordionContentView } from './view/AccordionContentView';

export const AccordionContent = Node.create({
  name: 'accordionContent',

  group: 'block',

  content: 'block+',

  selectable: true,

  draggable: true,

  addAttributes() {
    return {
      height: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({
          'data-height': attributes.height,
          style: `height: ${attributes.height}`,
        }),
      },
      width_max: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-width-max'),
        renderHTML: (attributes) => ({
          'data-width-max': attributes.width_max,
        }),
      },
      padding: {
        default: '16px 0px 16px 0px',
        parseHTML: (element) => element.getAttribute('data-padding'),
        renderHTML: (attributes) => ({
          'data-padding': attributes.padding,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        if (!editor.isActive(this.name)) {
          return false;
        }

        const { selection } = editor.state;
        const { $from, empty } = selection;

        if (!empty || $from.parent.type.name !== 'paragraph' || $from.parent.textContent) {
          return false;
        }

        const accordionContentNode = $from.node(-1);

        if (accordionContentNode.type.name !== this.name) {
          return false;
        }

        const accordionContentNodeEnd = $from.end(-1);

        const parentIndexInAccordionContentNode = $from.index(-1);

        const isAtEnd = parentIndexInAccordionContentNode === accordionContentNode.childCount - 1;

        if (!isAtEnd) {
          return false;
        }

        const nodeBeforeParentNode = accordionContentNode.child(parentIndexInAccordionContentNode - 1);

        if (nodeBeforeParentNode.type.name !== 'paragraph' || nodeBeforeParentNode.textContent) {
          return false;
        }

        const accordionEnd = $from.end(-2);

        return editor
          .chain()
          .command(({ commands }) => {
            return commands.deleteRange({
              from: accordionContentNodeEnd - 4,
              to: accordionContentNodeEnd,
            });
          })
          .command(({ tr, commands }) => {
            return commands.insertContentAt(tr.mapping.map(accordionEnd), { type: 'paragraph' });
          })
          .focus()
          .run();
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(AccordionContentView, {
      stopEvent: () => false,
    });
  },
});

export default AccordionContent;
