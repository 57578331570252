import { useState } from 'react';

import { AdPortalCampaignScopes } from '@/ad_portal/interfaces/campaign';
import { Typography } from '@/components/Typography';
import { Dropdown } from '@/ui/Dropdown';

import CampaignsList from './CampaignsList';

const Campaigns = () => {
  const [status, setStatus] = useState<AdPortalCampaignScopes>('all');

  const statusOptions = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'past', label: 'Past' },
    { value: 'upcoming', label: 'Upcoming' },
  ];
  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:justify-between">
        <Typography token="font-medium/text/lg">Campaigns</Typography>
        <Dropdown
          name="campaign-status-dropdown"
          value={status}
          options={statusOptions}
          onSelect={(name, value) => setStatus(value as AdPortalCampaignScopes)}
          className="w-full md:w-48"
        />
      </div>
      <CampaignsList status={status} />
    </div>
  );
};

export default Campaigns;
