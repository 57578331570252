import { useMemo } from 'react';

import { useCurrentPublication } from '@/hooks';
import { cn } from '@/utils/cn';

import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';
import { isInternalLink } from '../utils/isInternalLink';

import { BooleanSettings } from './BooleanSettings';

export const NewTabSettingsBlock = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { data: currentPublication } = useCurrentPublication();
  const { activeNodeAttributes } = activeNodeResult;

  const currentValue = activeNodeAttributes?.open_in_new_tab;

  const href = activeNodeAttributes?.href;

  const isExternalLink = useMemo(() => {
    if (!href) return false;
    return !isInternalLink(currentPublication?.url || '', href);
  }, [currentPublication?.url, href]);

  return (
    <Tooltip center="Custom external links must open in a new tab" delay={300}>
      <div className={cn(isExternalLink ? 'opacity-50 pointer-events-none' : '')}>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          defaultValue={isExternalLink ? true : currentValue === '_blank'}
          title="New Tab"
          property="open_in_new_tab"
          isExternalLink={isExternalLink}
        />
      </div>
    </Tooltip>
  );
};
