import { useState } from 'react';
import { CheckBadgeIcon, EnvelopeIcon, GlobeAltIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { getColor } from '@/components/DesignTokens';
import { Typography, TypographyStack } from '@/components/Typography';
import UrlCopy from '@/components/UrlCopy';
import { useSettings } from '@/context/settings-context';
import useOfferStatus from '@/hooks/boosts/grow/useOfferStatus';
import useBoostOfferEligibleCountries from '@/hooks/boosts/useBoostOfferEligibleCountries';
import useBoostsFrozen from '@/hooks/boosts/useBoostsFrozen';
import { BoostOffer, BoostOfferStatus } from '@/interfaces/boosts/grow/boost_offer';
import { Button } from '@/ui/Button';
import Tooltip from '@/ui/Tooltip';

import AddBoostInvite from '../../Shared/AddBoostInvite';

import BoostOfferStatusBadge from './BoostOfferStatusBadge';

interface Props {
  offer: BoostOffer;
}

const PrimaryOfferCard = ({ offer }: Props) => {
  const { settings } = useSettings();
  const [isInviteWidgetOpen, setIsInviteWidgetOpen] = useState(false);
  const { maxSpend, maxSpendText, hasMaxSpend, cost, verificationDaysText } = useOfferStatus(offer);
  const { hasRegionalRestrictions, regionalRestrictionsTooltip, regionalRestrictionsCountriesLabel } =
    useBoostOfferEligibleCountries(offer.eligible_countries);
  const verificationMode = offer.verification_mode;

  const onSuccess = () => {};

  const onClickInvite = () => setIsInviteWidgetOpen(true);

  const hasBoostInvites = settings?.boosts_invites === true;
  const boostsFrozen = useBoostsFrozen()?.data?.boosts_frozen;

  return (
    <>
      {hasBoostInvites && (
        <AddBoostInvite
          boostOfferId={offer.id}
          isOpen={isInviteWidgetOpen}
          setIsOpen={setIsInviteWidgetOpen}
          onSuccess={onSuccess}
        />
      )}
      <div className="flex flex-col gap-y-2 lg:flex-row sm:gap-x-2">
        {hasBoostInvites && (
          <div className="flex flex-col gap-y-3 lg:justify-between w-full lg:w-1/3 p-5 bg-surface-100 border border-surface-200 rounded-lg">
            <Typography token="font-medium/text/xs" colorWeight="700">
              Connect with other publishers
            </Typography>
            <TypographyStack>
              <Typography token="font-bold/text/lg" colorWeight="700">
                Find Potential Partners
              </Typography>
              <Typography token="font-normal/text/sm" colorWeight="700">
                Instead of waiting for publications to apply to your offer, search and invite them to boost your
                publication through our Boost Invites.
              </Typography>
            </TypographyStack>
            <Button variant="primary" onClick={onClickInvite} disabled={Boolean(boostsFrozen)}>
              Invite Publications to Boost
            </Button>
          </div>
        )}
        <div
          className={cx(
            'flex flex-col rounded-lg border overflow-hidden border-surface-200',
            hasBoostInvites ? 'lg:w-2/3' : 'w-full'
          )}
        >
          <div className="flex flex-col gap-y-5 sm:flex-row sm:justify-between sm:gap-x-4 bg-white p-5">
            <div className="flex flex-col gap-y-4 sm:w-1/4">
              <Typography token="font-semibold/text/xl" className="flex gap-x-3">
                {cost}
                <BoostOfferStatusBadge status={offer.status} />
              </Typography>
              <TypographyStack>
                <Typography token="font-medium/text/sm">Target Audience</Typography>
                <Typography colorWeight="500" token="font-normal/text/sm">
                  {offer.description || '-'}
                </Typography>
              </TypographyStack>
            </div>
            <div
              className={cx(
                'flex flex-col gap-y-4 sm:w-1/2 sm:border-l sm:border-r sm:px-4',
                getColor('surface/200', 'border')
              )}
            >
              <div className="flex gap-x-2 items-center">
                <Typography token="font-medium/text/base" colorWeight="700">
                  Boost Channels Enabled
                </Typography>
                <Tooltip
                  id="enabled-boost-channels-tooltip"
                  text="Applies to your Live Offer only. To adjust the Boost Channels, edit your offer."
                />
              </div>
              <div className={cx('flex flex-col gap-y-2', !hasBoostInvites && 'sm:flex-row sm:gap-x-5')}>
                <div className={cx('flex gap-x-2', !hasBoostInvites && 'sm:w-1/2')}>
                  <GlobeAltIcon className={cx('w-5 h-5', getColor('surface/500', 'text'))} />
                  <TypographyStack>
                    <Typography colorWeight="700" token="font-medium/text/sm">
                      Cost per acquisition
                    </Typography>
                    <div className="flex gap-x-1">
                      <Typography token="font-bold/text/sm" color="success" colorWeight="600">
                        {cost}
                      </Typography>{' '}
                      <Typography token="font-normal/text/sm">per subscriber</Typography>
                    </div>
                  </TypographyStack>
                </div>
                <div className={cx('flex gap-x-2', !hasBoostInvites && 'sm:w-1/2')}>
                  <EnvelopeIcon className={cx('w-5 h-5', getColor('surface/500', 'text'))} />
                  <TypographyStack>
                    <Typography colorWeight="700" token="font-medium/text/sm">
                      Max spend limit for email boost
                    </Typography>
                    {!hasMaxSpend ? (
                      <Typography token="font-normal/text/sm">{maxSpendText}</Typography>
                    ) : (
                      <div className="flex gap-x-1">
                        <Typography token="font-bold/text/sm" color="success" colorWeight="600">
                          {maxSpend}
                        </Typography>{' '}
                        <Typography token="font-normal/text/sm">per send</Typography>
                      </div>
                    )}
                  </TypographyStack>
                </div>
              </div>
            </div>
            <div className={cx('flex flex-col gap-y-4', !hasBoostInvites && 'sm:w-1/4')}>
              <div className="flex gap-x-2 items-center">
                <Typography token="font-medium/text/base" colorWeight="700">
                  Quality Controls
                </Typography>
                <Tooltip
                  id="boost-verification-mode-tooltip"
                  text="You will only pay for subscribers who successfully complete verification. To adjust the verification mode, edit your offer."
                />
              </div>
              <div className={cx('flex flex-col gap-y-2', !hasBoostInvites && 'sm:flex-row sm:gap-x-2')}>
                <div className={cx('flex gap-x-2 w-full', !hasBoostInvites && 'sm:w-1/2')}>
                  <CheckBadgeIcon className={cx('w-5 h-5', getColor('surface/500', 'text'))} />
                  <TypographyStack>
                    <Typography colorWeight="700" token="font-medium/text/sm" className="capitalize">
                      {verificationMode}
                    </Typography>
                    <Typography token="font-normal/text/sm">{verificationDaysText}</Typography>
                  </TypographyStack>
                </div>
                <div className={cx('flex gap-x-2 w-full', !hasBoostInvites && 'sm:w-1/2')}>
                  <GlobeAmericasIcon className={cx('w-5 h-5', getColor('surface/500', 'text'))} />
                  <TypographyStack>
                    <Typography
                      colorWeight="700"
                      token="font-medium/text/sm"
                      className="capitalize flex flex-row items-center gap-x-1"
                    >
                      Location
                      {hasRegionalRestrictions && (
                        <Tooltip id="boost-offer-eligible-countries-tooltip" text={regionalRestrictionsTooltip} />
                      )}
                    </Typography>
                    <Typography token="font-normal/text/sm">{regionalRestrictionsCountriesLabel}</Typography>
                  </TypographyStack>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx(
              'flex flex-col w-full gap-y-2 items-center sm:flex-row sm:justify-between px-5 py-3.5',
              getColor('surface/100', 'background')
            )}
          >
            {offer?.application_url && offer.status === BoostOfferStatus.LIVE && (
              <UrlCopy
                url={offer.application_url}
                tooltip="This link goes directly to your offer where publications on beehiiv can apply."
              />
            )}
            <div className="flex gap-x-2">
              <Button type="button" size="xs" variant="secondary" to={`/grow/boosts/offers/${offer.id}/edit`}>
                Edit Offer
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrimaryOfferCard;
