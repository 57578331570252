import * as React from 'react';

import { cn } from '../../_utils/cn';

import { Text } from './Text';

export interface TagsProps extends React.TextareaHTMLAttributes<HTMLDivElement> {
  text?: string;
}

const Tag = React.forwardRef<HTMLDivElement, TagsProps>(
  ({ className, text, ...props }, ref) => {
    return (
      <div ref={ref} className={cn("rounded-md bg-wb-button-accent-soft px-2 pb-0.5", className)} {...props}>
        <Text size="xs" weight="medium" variant="accent">
          {text}
        </Text>
      </div>
    );
  }
);
Tag.displayName = 'Tag';

export { Tag };
