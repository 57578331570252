import { Plugin, PluginKey } from '@tiptap/pm/state';

export const FilterDuplicateSectionsPluginKey = new PluginKey('ignoreTextInputOnNodes');

export const getFilterDuplicateSections = (): Plugin => {
  return new Plugin({
    key: FilterDuplicateSectionsPluginKey,
    filterTransaction(tr, state) {
      if (
        tr.getMeta('uiEvent') !== 'drop' ||
        !tr.docChanged ||
        (typeof tr.getMeta('addToHistory') === 'boolean' && !tr.getMeta('addToHistory')) ||
        state.doc.content.childCount > 1
      ) {
        return true;
      }

      if (tr.doc.content.childCount === 2 && tr.before.content.childCount === 1) {
        let docAfterHasDocBeforesFirstChild = false;

        tr.doc.content.forEach((child) => {
          if (child.eq(tr.before.content.firstChild!)) {
            docAfterHasDocBeforesFirstChild = true;
          }
        });

        if (!docAfterHasDocBeforesFirstChild) {
          return true;
        }

        return false;
      }

      return true;
    },
  });
};
