import { Link } from 'react-router-dom';
import { DotsThree } from '@phosphor-icons/react';

import { SiteTemplate } from '@/interfaces/site_template';

import { dateToAgo } from '../../_utils/dateToAgo';
import { Button } from '../UI/Button';
import { Text } from '../UI/Text';

import { TemplateOptions } from './TemplateOptions';
import TemplatePreviewer from './TemplatePreviewer';

type TemplateCardProps = {
  template: SiteTemplate;
  onDeleteSuccess?: () => void;
  hasOptions?: boolean;
  allowRename?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
  allowRawJson?: boolean;
  onRawJsonClick?: () => void;
};

const TemplateCard = ({
  template,
  onDeleteSuccess,
  hasOptions = true,
  allowRename,
  showDelete = true,
  showEdit = true,
  allowRawJson,
  onRawJsonClick,
}: TemplateCardProps) => {
  return (
    <div key={template.id}>
      <Link to={`/website_builder_v2/templates/${template.id}`} className="cursor-pointer">
        <TemplatePreviewer content={template.content} hasBrowserBar={false} />
      </Link>
      <div className="flex items-center justify-between py-1">
        <div className="flex flex-col">
          <Text size="sm" weight="semibold">
            {template.name}
          </Text>
          <Text size="xs" weight="medium" variant="secondary">
            Edited {dateToAgo(template.updated_at, true)}
          </Text>
        </div>

        {hasOptions && (
          <div className="flex items-center justify-end">
            <TemplateOptions
              allowRename={allowRename}
              align="end"
              siteTemplateId={template.id}
              onDeleteSuccess={onDeleteSuccess}
              allowRawJson={allowRawJson}
              onRawJsonClick={onRawJsonClick}
              showDelete={showDelete}
              showEdit={showEdit}
            >
              <Button
                variant="secondary"
                size="sm"
                Icon={DotsThree}
                iconClassName="h-5 w-5 text-wb-secondary"
                className="p-1.5 hover:opacity-100 data-[state=open]:opacity-100 transition-all"
              />
            </TemplateOptions>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateCard;
