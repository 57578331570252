import { Spinner } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { ColorSettings } from '../ColorSettings';
import { PaddingSettings } from '../PaddingSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

const EmptyStateSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  return (
    <PopoverHelper title="Empty State" text="Empty State" popoverTitle="Empty State" Icon={Spinner} align="end">
      <SimpleTextSettings editor={editor} activeNodeResult={activeNodeResult} title="Text" property="emptyStateText" />
      <ColorSettings
        title="Background"
        property="emptyStateBackgroundColor"
        editor={editor}
        activeNodeResult={activeNodeResult}
      />
      <ColorSettings title="Text" property="emptyStateTextColor" editor={editor} activeNodeResult={activeNodeResult} />
      <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="emptyStatePadding" />
    </PopoverHelper>
  );
};

export { EmptyStateSettings };
