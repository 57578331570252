import { useQuery } from 'react-query';

import { Publication } from '@/interfaces/publication';

import api from '../../services/swarm';

interface Props {
  organizationId: string | undefined;
  enabled?: boolean;
}

const useOrganizationPublications = ({ organizationId, enabled = true }: Props) =>
  useQuery<Publication[]>(
    ['organization', organizationId, 'publications'],
    () =>
      api
        .get(`/publications`, {
          params: {
            organization_id: organizationId,
          },
        })
        .then((res) => res.data),
    {
      enabled,
    }
  );

export default useOrganizationPublications;
