import { Editor, useEditorState } from '@tiptap/react';

import { ActiveNodeResult } from '../types';
import { getActiveNodeData } from '../utils';

export const useActiveNode = (e: Editor): ActiveNodeResult => {
  return useEditorState({
    editor: e,
    selector: ({ editor }) => getActiveNodeData(editor),
    equalityFn: (a, b) => {
      if (!a || !b) return false;

      return a.selection.eq(b.selection) && a.activeNode.eq(b.activeNode);
    },
  });
};
