import { Typography } from '@/components/Typography';

import { useCalendarContext } from '../utils/useCalendar';

import CalendarLegend from './CalendarLegend';
import SidePanelEvent from './SidePanelEvent';

const SidePanel = () => {
  const { calendars, selectedDay, upcomingEvents, currentMonth, variant } = useCalendarContext();

  return (
    <div
      className="lg:col-span-2 col-span-8 bg-gray-50 sm:bg-white h-full overflow-y-auto"
      data-testid="calendar-side-panel"
    >
      {variant === 'full' && (
        <>
          <div className="hidden sm:block p-4 space-y-3">
            <div>
              <Typography as="h2" token="font-medium/text/base">
                Calendars
              </Typography>
            </div>
            <div className="space-y-2">
              {calendars.map((calendar) => (
                <CalendarLegend key={calendar.id} calendar={calendar} />
              ))}
            </div>
          </div>

          <div className="hidden sm:block p-4 space-y-4 border-t border-surface-200">
            <div>
              <Typography as="h2" token="font-medium/text/base">
                Upcoming in {currentMonth}
              </Typography>
            </div>
            {upcomingEvents.map((event) => {
              return <SidePanelEvent key={event.id} event={event} />;
            })}
            {upcomingEvents.length === 0 && (
              <Typography as="p" token="font-normal/text/sm" colorWeight="500">
                No upcoming events
              </Typography>
            )}
          </div>
        </>
      )}
      {selectedDay ? (
        <div className="block sm:hidden p-4 border-t border-surface-200">
          <div className="p-4 bg-white space-y-4 rounded-lg border border-gray-200">
            <div>
              <Typography as="h2" token="font-medium/text/base">
                {selectedDay.date.format('LL')}
              </Typography>
            </div>
            {selectedDay.events.map((event) => {
              return <SidePanelEvent key={event.id} event={event} />;
            })}
            {selectedDay.events.length === 0 && (
              <Typography as="p" token="font-normal/text/sm" colorWeight="500">
                No events on this day
              </Typography>
            )}
          </div>
        </div>
      ) : (
        <div className="block sm:hidden p-4 border-t border-surface-200">
          <Typography as="p" token="font-normal/text/sm" colorWeight="500">
            No date selected.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default SidePanel;
