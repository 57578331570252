import { Extension } from '@tiptap/core';

import { getStringAttribute } from '../../../utils/attributesUtils';

interface WidthOptions {
  nodesToAddFullWidthAttribute: string[];
}

interface WidthStorage {}

export const WidthAttribute = Extension.create<WidthOptions, WidthStorage>({
  name: 'widthAttribute',

  addOptions() {
    return {
      nodesToAddFullWidthAttribute: [
        'accordion',
        'accordionContent',
        'accordionTitle',
        'container',
        'divider',
        'heading',
        'image',
        'paragraph',
        'socials',
      ],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.nodesToAddFullWidthAttribute,
        attributes: {
          width: getStringAttribute('width', 'data-width', '100%'),
        },
      },
    ];
  },
});
