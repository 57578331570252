import { useState } from 'react';
import toast from 'react-hot-toast';

import EllipsisDropdown from '@/components/EllipsisDropdown';
import { EllipsisDropdownOption } from '@/interfaces/general';

import ActionModal from '../../../../../../components/ActionModal';
import { useBoostSend, useUpdateBoostSend } from '../../../../../../hooks/boosts/grow/useBoostSends';
import {
  BoostSendStatus,
  BoostSendStatusTransitions,
  BoostSendTransitionLabels,
} from '../../../../../../interfaces/boosts/grow/boost_send';

interface Props {
  id: string;
}
const BoostSendActions = ({ id }: Props) => {
  const boostSendQuery = useBoostSend(id);
  const { data: boostSend, isLoading, refetch } = boostSendQuery;
  const [requestedAction, setRequestedAction] = useState<BoostSendStatus | null>(null);
  const onSuccess = () => {
    refetch();
    toast.success('Send request updated.');
  };
  const { mutateAsync: updateBoostSend, isLoading: updateLoading } = useUpdateBoostSend({ id, onSuccess });
  const availableTransitions = boostSend?.status ? BoostSendStatusTransitions[boostSend.status] : [];
  const options: EllipsisDropdownOption[] = availableTransitions.map(
    (transition: BoostSendStatus) =>
      ({
        label: BoostSendTransitionLabels[transition],
        description:
          transition === BoostSendStatus.ACCEPTED ? 'Accepting will immediately reserve the max spend.' : undefined,
        onClick: () => {
          setRequestedAction(transition);
        },
      } as EllipsisDropdownOption)
  );

  const handleProceed = () => {
    updateBoostSend({ status: requestedAction as BoostSendStatus });
    setRequestedAction(null);
  };

  if (!boostSend || isLoading) return null;
  return (
    <>
      <div className="flex items-center justify-end">
        {options.length > 0 && <EllipsisDropdown options={options} />}
      </div>

      <ActionModal
        isOpen={!!requestedAction}
        onClose={() => setRequestedAction(null)}
        onProceed={() => handleProceed()}
        resourceId={id}
        headerText={BoostSendTransitionLabels[requestedAction || '']}
        isWorking={updateLoading}
        actionText="Confirm"
      >
        <p className="text-sm text-gray-500">
          Are you sure you would like to {BoostSendTransitionLabels[requestedAction || '']} this Email Boost?
        </p>
      </ActionModal>
    </>
  );
};

export default BoostSendActions;
