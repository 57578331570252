import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import { useCurrentPublication } from '@/hooks/usePublications';
import {
  OnboardingAttributionSource,
  OnboardingGoals,
  OnboardingPreviousPlatforms,
  OnboardingTypes,
} from '@/interfaces/onboarding';
import api from '@/services/swarm';

interface Props {
  onSuccess?: (response: any) => void;
}

interface Payload {
  status?: string;
  onboarding_type?: OnboardingTypes;
  has_paid_subscribers?: boolean;
  id?: number;
  migrating_from?: OnboardingPreviousPlatforms;
  chose_to_import?: boolean;
  walkthrough_delayed_until?: string;
  walkthrough_completed_at?: string;
  attribution_sources?: OnboardingAttributionSource[] | string[];
  customize_newsletter_completed?: boolean;
  discover_editor_completed?: boolean;
  explore_website_builder_completed?: boolean;
  finish_publication_settings_completed?: boolean;
  import_prior_publication_completed?: boolean;
  start_publication_completed?: boolean;
  goals?: OnboardingGoals[] | string[];
}

const useUpdateOnboarding = ({ onSuccess }: Props) => {
  const { data: currentPublication } = useCurrentPublication();

  return useMutation(
    (payload: Payload) =>
      api.patch(`/onboarding/${currentPublication?.onboarding_id}`, {
        publication_id: currentPublication?.id,
        ...payload,
      }),
    {
      onSuccess,
      onError: (err: any) => {
        toast.error(err?.response?.data?.message || 'Oops there was an issue.');
      },
    }
  );
};

export default useUpdateOnboarding;
