import { BracketsCurly } from '@phosphor-icons/react';
import classNames from 'classnames';

import { CloseButton } from '../../../UI/Button';
import { Tooltip } from '../../../UI/Tooltip';

type Props = {
  className?: string;
  onClose: () => void;
};

const MergeTag = ({ className, onClose }: Props) => {
  return (
    <div
      className={classNames(
        className || 'absolute -top-2 right-0',
        'bg-white border border-wb-hr rounded-xl shadow-md p-1 cursor-pointer'
      )}
    >
      <CloseButton
        onClose={onClose}
        className="absolute -top-0 -right-0 opacity-0 group-hover:opacity-100 transition-opacity duration-100"
        size={14}
      />
      <Tooltip center="Merge tag">
        <BracketsCurly size={14} className="text-[#8B5CF6]" />
      </Tooltip>
    </div>
  );
};

export default MergeTag;
