import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import useWebBuilderOverlay from '@/hooks/useWebBuilderOverlay';
import Tooltip from '@/ui/Tooltip';

import { TooltipProps } from '../../_types/misc';
import { Option } from '../../_types/options';

import { InputWrapperProps } from './InputWrapper';
import ResetMenu from './ResetMenu';

interface Props extends Omit<InputWrapperProps, 'children'> {
  className?: string;
  options: Option[];
  value: string;
  onSave: (value: string) => void;
  tooltip?: TooltipProps;
  disabled?: boolean;
  resettable?: {
    resetValue: any;
    check: boolean;
    label?: string;
  };
}

const SelectInput = ({
  label,
  options = [],
  className,
  onSave,
  value,
  resettable,
  tooltip,
  disabled = false,
}: Props) => {
  const { styles, attributes, setReferenceElement, setPopperElement } = useWebBuilderOverlay({
    align: 'end',
  });

  const isResettable = resettable?.check;
  const resettableValue = resettable?.resetValue;
  const resetValueLabel = resettable?.label;

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div className={cx(className, 'flex justify-between items-center')}>
      <div className="flex space-x-1">
        <Typography token="font-normal/text/xs" colorWeight="500">
          {label}
        </Typography>
        {tooltip && <Tooltip id={tooltip.id} text={tooltip.text} iconClass="!text-surface-300" />}
      </div>
      <div className="flex items-center space-x-1">
        {isResettable && (
          <ResetMenu resettableValue={resettableValue} resetValueLabel={resetValueLabel} onSave={onSave} />
        )}
        <div className={cx('rounded-md bg-surface-100 border border-gray-200 w-28', { 'opacity-50': disabled })}>
          <Listbox
            value={selectedOption}
            onChange={(selection) => {
              onSave(selection.value);
            }}
            disabled={disabled}
          >
            <div className="relative w-full">
              <Listbox.Button
                ref={setReferenceElement}
                className="w-full flex space-x-2 justify-between items-center px-2 py-1 relative overflow-hidden"
              >
                <Typography token="font-normal/text/xs" className="whitespace-nowrap w-full text-left truncate">
                  {selectedOption?.label}
                </Typography>
                <div>
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                </div>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="py-[6px] bg-white shadow-md rounded z-10 border border-surface-200 absolute left-0 mt-1 max-h-60 w-[133px] overflow-auto"
                >
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.label}
                      value={option}
                      className={cx(
                        'px-3 py-1.5 hover:bg-surface-100 transition-all cursor-pointer',
                        value === option.value && 'bg-surface-100'
                      )}
                    >
                      <div className="flex flex-row w-full justify-between items-between">
                        <Typography token="font-normal/text/xs" className="text-900" as="span">
                          {option.label}
                        </Typography>
                        {value === option.value && <CheckIcon height={12} width={12} className="my-auto" />}
                      </div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
    </div>
  );
};

export default SelectInput;
