/* eslint-disable no-param-reassign */

import { Editor, Extension, JSONContent } from '@tiptap/core';
import { Slice } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { dropPoint } from '@tiptap/pm/transform';
import { Decoration, DecorationSet, EditorView } from '@tiptap/pm/view';

import { Site } from '@/interfaces/dream_builder/site';
import { WebTheme } from '@/interfaces/web_theme';

import { applyThemeToNode } from '../../utils/applyThemeToNode';
import { INSERT_PANEL_DROP_EVENT_IDENTIFIER, INSERTED_FROM_INSERT_PANEL_IDENTIFIER } from '../constants';

export interface HoverOptions {
  /**
   * The class name that should be added to the focused node.
   * @default 'has-focus'
   * @example 'is-focused'
   */
  className: string;

  /**
   * The mode by which the focused node is determined.
   * - All: All nodes are marked as focused.
   * - Deepest: Only the deepest node is marked as focused.
   * - Shallowest: Only the shallowest node is marked as focused.
   *
   * @default 'all'
   * @example 'deepest'
   * @example 'shallowest'
   */
  mode: 'all' | 'deepest' | 'shallowest';
}

export type HoverStorage = {
  hoverTimeout: NodeJS.Timeout | null;
  rect: DOMRect | null;
  node: Element | null;
  pos: number | null;

  primaryTheme: WebTheme | null;
  selectedTheme: WebTheme | null;
  themeRules: Site['theme_rules'] | null;

  insertNodeData: {
    slice: Slice;
    json: JSONContent;
  } | null;
};

const handleDropFromInsertPanel = (editor: Editor, view: EditorView, event: DragEvent) => {
  const jsonString = event.dataTransfer?.getData('text/plain');

  if (typeof jsonString !== 'string' || jsonString !== INSERT_PANEL_DROP_EVENT_IDENTIFIER) return false;

  if (!editor.storage.hover.insertNodeData) return false;

  const contentJSON = structuredClone(editor.storage.hover.insertNodeData.json) as JSONContent | JSONContent[];
  const slice = editor.storage.hover.insertNodeData.slice as Slice;

  if (!contentJSON) return false;

  try {
    const pos = view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    })?.pos;

    if (pos === undefined) return false;

    const dropPos = dropPoint(view.state.doc, pos, slice);

    if (typeof dropPos === 'number' && pos >= 0) {
      const { primaryTheme, themeRules: themeMapping } = editor.storage.hover as HoverStorage;

      if (primaryTheme && themeMapping) {
        if (Array.isArray(contentJSON)) {
          contentJSON.forEach((nodeJSON) => applyThemeToNode(nodeJSON, primaryTheme, themeMapping));
        } else {
          applyThemeToNode(contentJSON, primaryTheme, themeMapping);
        }
      }

      return editor
        .chain()
        .setMeta(INSERTED_FROM_INSERT_PANEL_IDENTIFIER, true)
        .insertContentAt(dropPos, contentJSON)
        .command(() => {
          editor.once('selectionUpdate', () => {
            editor.chain().setNodeSelection(dropPos).focus().run();
          });

          return true;
        })
        .focus()
        .run();
    }
  } catch {
    // no op
  } finally {
    editor.storage.hover.insertNodeData = null;
    editor.view.dragging = null;
  }

  return true;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    hover: {
      setHoveredNode: (nodePos: number) => ReturnType;
    };
  }
}

export const HoverPluginKey = new PluginKey('hover');

/**
 * This extension allows you to add a class to the focused node.
 * @see https://www.tiptap.dev/api/extensions/focus
 */
export const Hover = Extension.create<HoverOptions, HoverStorage>({
  name: 'hover',

  addOptions() {
    return {
      className: 'dream-hovering',
      mode: 'all',
    };
  },

  addStorage() {
    return {
      hoverTimeout: null,
      rect: null,
      node: null,
      pos: null,
      primaryTheme: null,
      selectedTheme: null,
      themeRules: null,
      insertNodeData: null,
    };
  },

  addCommands() {
    return {
      setHoveredNode:
        (nodePos) =>
        ({ commands, view }) => {
          // handle mouse leaving
          if (nodePos === -1) {
            this.storage.node = null;
            this.storage.rect = null;
            this.storage.pos = null;
            return commands.setMeta(HoverPluginKey, nodePos);
          }

          const maybeNodeDom = view.nodeDOM(nodePos) as Element | null;

          if (!maybeNodeDom) return false;

          const nodeDom = maybeNodeDom.firstElementChild;

          if (!nodeDom) return false;

          this.storage.node = nodeDom;
          this.storage.rect = nodeDom.getBoundingClientRect();
          this.storage.pos = nodePos;

          return commands.setMeta(HoverPluginKey, nodePos);
        },
    };
  },

  addProseMirrorPlugins() {
    const { editor } = this;

    return [
      new Plugin({
        key: HoverPluginKey,
        state: {
          init() {
            return DecorationSet.empty;
          },
          apply(tr, decorationSet) {
            const pos = tr.getMeta(HoverPluginKey);

            // handle transactions other than hover (try editing text while hovering elements)
            // hover box should not be missing/removed
            if (pos === undefined) {
              return decorationSet.map(tr.mapping, tr.doc);
            }

            // handle mouse leaving
            if (pos === -1) {
              return DecorationSet.empty;
            }

            const node = tr.doc.nodeAt(pos)!;

            return DecorationSet.create(tr.doc, [
              Decoration.node(pos, pos + node.nodeSize, {
                class: 'dream-hovered-widget',
                'data-position': pos.toString(),
              }),
            ]);
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
          handleDOMEvents: {
            drop: (view, event) => {
              const handled = handleDropFromInsertPanel(editor, view, event);

              if (!handled) return;

              event.preventDefault();
              event.stopPropagation();
            },
            mouseover: (view, event) => {
              const posDetails = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              });

              if (!posDetails) return;

              editor.commands.setHoveredNode(posDetails.inside);
            },
            mouseleave: (view, event) => {
              const posDetails = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
              });

              if (!posDetails) {
                // we're actually leaving the editor
                editor.commands.setHoveredNode(-1);
              }

              // do nothing, we're still hovering over some node in the editor
            },
          },
        },
      }),
    ];
  },
});
