import { useCallback } from 'react';
import { Link as PhosphorLink, LinkBreak, StackSimple } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import BorderSettings from '../BorderSettings';
import { ColorSettings } from '../ColorSettings';
import GroupedBorderSettings from '../GroupedBorderSettings';

type Props = AttributeSettingProps & {};

const SOCIAL_OPTIONS = [
  { label: 'Discord', prefix: 'discord', properties: ['discordBackground', 'discordFill', 'discordBorderColor'] },
  {
    label: 'Facebook',
    prefix: 'facebook',
    properties: ['facebookBackground', 'facebookFill', 'facebookBorderColor'],
  },
  {
    label: 'Instagram',
    prefix: 'instagram',
    properties: ['instagramBackground', 'instagramFill', 'instagramBorderColor'],
  },
  {
    label: 'LinkedIn',
    prefix: 'linkedin',
    properties: ['linkedinBackground', 'linkedinFill', 'linkedinBorderColor'],
  },
  { label: 'Threads', prefix: 'threads', properties: ['threadsBackground', 'threadsFill', 'threadsBorderColor'] },
  { label: 'TikTok', prefix: 'tiktok', properties: ['tiktokBackground', 'tiktokFill', 'tiktokBorderColor'] },
  { label: 'X', prefix: 'twitter', properties: ['twitterBackground', 'twitterFill', 'twitterBorderColor'] },
  { label: 'YouTube', prefix: 'youtube', properties: ['youtubeBackground', 'youtubeFill', 'youtubeBorderColor'] },
];

const GroupedSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;
  const isGrouped = activeNodeAttributes?.isGrouped === 'true';

  const handleGroupChange = useCallback(() => {
    if (!activeNodeAttributes) return;

    const value = isGrouped ? 'false' : 'true';

    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(activeNodePos, 'isGrouped', value);
      return true;
    });
  }, [editor, activeNodePos, activeNodeAttributes, isGrouped]);

  if (!isGrouped) {
    return (
      <div className="flex flex-col gap-4">
        <button
          type="button"
          className="flex items-center gap-2 text-wb-secondary hover:text-wb-primary transition-all"
          onClick={handleGroupChange}
        >
          <LinkBreak className="w-4 h-4" />
        </button>
        {SOCIAL_OPTIONS.map((option) => (
          <PopoverHelper
            title={option.label}
            text=""
            textChild={
              <div className="flex items-center gap-1">
                {option.properties.map((property) => (
                  <div style={{ backgroundColor: activeNodeAttributes[property] }} className="w-4 h-4 rounded-md" />
                ))}
              </div>
            }
            popoverTitle={option.label}
            Icon={StackSimple}
          >
            <div className="flex flex-col gap-2">
              <ColorSettings
                title="Background"
                property={`${option.prefix}Background`}
                editor={editor}
                activeNodeResult={activeNodeResult}
                initialColor="#FFFFFF"
              />
              <ColorSettings
                title="Fill"
                property={`${option.prefix}Fill`}
                editor={editor}
                activeNodeResult={activeNodeResult}
                initialColor="#FFFFFF"
              />
              <BorderSettings
                editor={editor}
                activeNodeResult={activeNodeResult}
                properties={{
                  color: `${option.prefix}BorderColor`,
                  style: `${option.prefix}BorderStyle`,
                  width: `${option.prefix}BorderWidth`,
                  radius: `${option.prefix}BorderRadius`,
                }}
              />
            </div>
          </PopoverHelper>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <button
        type="button"
        className="flex items-center gap-2 text-wb-secondary hover:text-wb-primary transition-all"
        onClick={handleGroupChange}
      >
        <PhosphorLink className="w-4 h-4" />
      </button>
      <ColorSettings
        title="Background"
        property="facebookBackground"
        properties={SOCIAL_OPTIONS.map((option) => `${option.prefix}Background`)}
        editor={editor}
        activeNodeResult={activeNodeResult}
        initialColor="#FFFFFF"
      />
      <ColorSettings
        title="Fill"
        property="facebookFill"
        properties={SOCIAL_OPTIONS.map((option) => `${option.prefix}Fill`)}
        editor={editor}
        activeNodeResult={activeNodeResult}
        initialColor="#FFFFFF"
      />
      <GroupedBorderSettings
        editor={editor}
        activeNodeResult={activeNodeResult}
        groupedProperties={SOCIAL_OPTIONS.map((option) => ({
          color: `${option.prefix}BorderColor`,
          style: `${option.prefix}BorderStyle`,
          width: `${option.prefix}BorderWidth`,
          radius: `${option.prefix}BorderRadius`,
        }))}
      />
    </div>
  );
};

export default GroupedSettings;
