import { useMemo } from 'react';
import cx from 'classnames';

import { Skeleton } from '@/components/SkeletonLoader';
import { BodyXs, HelperXs, Title2xl, TitleSm, TypographyStack } from '@/components/Typography';
import Tooltip from '@/ui/Tooltip';

import DeltaBadge from './DeltaBadge';

type Metric = {
  label: string;
  value: string | number;
};

interface Props {
  title: string;
  tooltipText?: string;
  value: number | string;
  isLoading: boolean;
  previousValue?: number | string;
  pctDelta?: number;
  isAllTime?: boolean;
  additionalMetrics?: Metric[];
  cta?: JSX.Element;
  backgroundColor?: string;
}

const KPI = (props: Props) => {
  const {
    title,
    tooltipText,
    value,
    previousValue,
    pctDelta,
    isLoading,
    cta,
    isAllTime = false,
    additionalMetrics = [],
    backgroundColor = '',
  } = props;

  const memoizedAdditionalMetrics = useMemo(
    () =>
      additionalMetrics.map((metric) => (
        <div className="flex justify-between items-center">
          <BodyXs colorWeight="700">{metric.label}</BodyXs>
          <BodyXs colorWeight="700">{metric.value}</BodyXs>
        </div>
      )),
    [additionalMetrics]
  );

  return (
    <div className={cx(backgroundColor)}>
      <div className="px-4 py-5 sm:p-6">
        {cta ? <div className="flex justify-end mb-6">{cta}</div> : null}
        <div className="dd mt-1 flex justify-between items-baseline md:block lg:flex">
          <div className="dt flex">
            <TitleSm>{title}</TitleSm>
            {tooltipText && <Tooltip id="tooltip" text={tooltipText} iconClass="ml-2 mt-0.5" />}
          </div>
        </div>
        {!isLoading && (
          <>
            <div className="dd mt-1 flex justify-between items-center md:block lg:flex">
              <Title2xl>{value}</Title2xl>
              {pctDelta !== undefined && !isAllTime && <DeltaBadge pctDelta={pctDelta} />}
            </div>

            {previousValue !== undefined && !isAllTime && <HelperXs>from {previousValue}</HelperXs>}
            {isAllTime && <HelperXs>all time</HelperXs>}
            {memoizedAdditionalMetrics.length > 0 ? (
              <div className={cx('flex flex-col', cta ? 'mt-1' : 'mt-6')}>
                <TypographyStack>{memoizedAdditionalMetrics}</TypographyStack>
              </div>
            ) : null}
          </>
        )}
        {isLoading && <Skeleton className="h-5 animate-pulse ml-0 mt-4 rounded-md" />}
      </div>
    </div>
  );
};

export default KPI;
