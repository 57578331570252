import React, { useState } from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import { useSubscriptionLimitMeter } from '@/hooks/usePublicationDashboard';
import { AdNetworkOpportunity } from '@/interfaces/ad_network/publisher/opportunity';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';
import Tooltip from '@/ui/Tooltip';

interface Props {
  opportunity: AdNetworkOpportunity;
}

const Calculator: React.FC<Props> = ({ opportunity }) => {
  const {
    payout_model: payoutModel,
    estimated_opens: estimatedOpens,
    payout_per_mille_cents: payoutPerMilleCents,
    payout_per_click_cents: payoutPerClickCents,
  } = opportunity;

  const { data, isLoading, isError } = useSubscriptionLimitMeter(payoutModel === AdNetworkPayoutModel.CPM);
  const { active_subscribers_count: activeSubCount } = data || { active_subscribers_count: 0 };

  const [estimatedRate, setEstimatedRate] = useState(payoutModel === AdNetworkPayoutModel.CPC ? 0.01 : 0.4);
  const payoutPerEvent = payoutModel === AdNetworkPayoutModel.CPC ? payoutPerClickCents : payoutPerMilleCents / 1000;
  const eventsBaseline = payoutModel === AdNetworkPayoutModel.CPC ? estimatedOpens : activeSubCount;
  const estimatedEvents = Math.round(eventsBaseline * estimatedRate);

  const estimatedPayout = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format((estimatedEvents * payoutPerEvent) / 100);

  const rateLabel = payoutModel === AdNetworkPayoutModel.CPC ? 'CTR' : 'OR';
  const maxRate = payoutModel === AdNetworkPayoutModel.CPC ? '0.02' : '0.8';

  return (
    <LoadingBox isLoading={isLoading} isError={isError} height={100} backgroundClassName="bg-transparent">
      <div className="border-b border-surface-200 p-6 space-y-6">
        <div className="space-y-6">
          <div className="flex flex-col space-y-2 w-full">
            <div className="flex items-center space-x-3">
              <div className="p-1 bg-action-secondary-50 rounded">
                <CurrencyDollarIcon className="w-4 h-4 text-action-secondary-600" />
              </div>
              <Typography as="p" token="font-medium/text/base">
                {estimatedPayout} estimated payout
              </Typography>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <input
              type="range"
              min="0"
              max={maxRate}
              step="any"
              value={estimatedRate}
              onChange={(e) => setEstimatedRate(parseFloat(e.target.value))}
              className="appearance-none w-full h-1 bg-surface-100 rounded accent-black"
            />
            <div className="flex-shrink-0">
              <Typography token="font-medium/text/sm">
                {new Intl.NumberFormat('en-US', {
                  style: 'percent',
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }).format(estimatedRate)}{' '}
                {rateLabel}
              </Typography>
            </div>
            <Tooltip
              id="estimated-payout"
              text={
                payoutModel === AdNetworkPayoutModel.CPC
                  ? 'Estimated payout is calculated by multiplying the total number of subscribers by the average open rate and the CTR (click-through rate)'
                  : 'Estimated payout is calculated by multiplying the total number of subscribers by the average open rate'
              }
            />
          </div>
          <Typography token="font-light/text/xs">
            {payoutModel === AdNetworkPayoutModel.CPC
              ? 'Modify to see how different click-through rates affect your estimated payout.'
              : 'Modify to see how different open rates affect your estimated payout.'}
          </Typography>
        </div>
      </div>
    </LoadingBox>
  );
};

export default Calculator;
