import { Extension } from '@tiptap/core';
import { EditorState, Plugin, PluginKey } from '@tiptap/pm/state';
import { v4 as uuidV4 } from 'uuid';

import { NODES_NAMES } from '../allExtensions';

const typesObject = NODES_NAMES.reduce((acc, type) => {
  acc[type] = true;
  return acc;
}, {} as Record<string, boolean>);

const attributeName = 'id';

const getUniqueIDsTr = (state: EditorState) => {
  const { tr } = state;

  const idsSet = new Set<string>();

  tr.doc.descendants((node, pos) => {
    if (!typesObject[node.type.name]) {
      return;
    }

    if (!node.attrs[attributeName] || (node.attrs[attributeName] && idsSet.has(node.attrs[attributeName]))) {
      const newId = uuidV4();

      tr.setNodeMarkup(pos, undefined, {
        ...node.attrs,
        [attributeName]: newId,
      });

      idsSet.add(newId);
    }

    if (node.attrs[attributeName] && !idsSet.has(node.attrs[attributeName])) {
      idsSet.add(node.attrs[attributeName]);
    }
  });

  tr.setMeta('addToHistory', false);

  return tr;
};

export const UniqueID = Extension.create({
  name: 'uniqueID',

  priority: 1e4,

  addGlobalAttributes() {
    return [
      {
        types: NODES_NAMES,
        attributes: {
          [attributeName]: {
            default: null,
            parseHTML: (element) => element.getAttribute(`data-${attributeName}`),
            renderHTML: (attributes) => ({
              [`data-${attributeName}`]: attributes[attributeName],
            }),
          },
        },
      },
    ];
  },

  onCreate() {
    const { editor } = this;

    const uniqueIDTr = getUniqueIDsTr(editor.state);

    if (!uniqueIDTr.docChanged) return;

    uniqueIDTr.setMeta('skipUniqueID', true);

    editor.view.dispatch(uniqueIDTr);
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('unique-id-plugin-key'),
        appendTransaction: (transactions, _oldState, newState) => {
          if (!transactions.some((transaction) => transaction.docChanged && !transaction.getMeta('skipUniqueID'))) {
            return null;
          }

          const tr = getUniqueIDsTr(newState);

          if (!tr.docChanged) return null;

          return tr;
        },
      }),
    ];
  },
});
