import toast from 'react-hot-toast';

import { useWebsiteContext } from '@/context/website-context';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';

import SettingsInput from '../_components/SettingsInput';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';

const WebsiteSettingsRedirects = () => {
  const { site } = useWebsiteContext();
  const updateSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  return (
    <SettingsSection hasDivider={false}>
      <SettingsItem
        title="404 Redirect"
        description="Redirect users to a different page when they try to access a page that doesn't exist."
      >
        <SettingsInput
          className="max-w-sm"
          name="404_redirect"
          type="input"
          placeholder="https://example.com/"
          value=""
          helperText="For instance, you might send users back to your Homepage if they try to access a page that doesn't exist."
          onSave={(value: string) => {
            if (value === '') return;

            try {
              const url = new URL(value);
              if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                throw new Error('Invalid protocol');
              }

              updateSiteVersion.mutate({
                settings: {
                  not_found_redirect: value,
                },
              });
            } catch (e) {
              toast.error('Please enter a valid URL.');
            }
          }}
        />
      </SettingsItem>
    </SettingsSection>
  );
};

export default WebsiteSettingsRedirects;
