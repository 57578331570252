import { useInfiniteQuery } from 'react-query';

import { FiltersPopopoverOperator } from '@/ui/FiltersPopover/FiltersPopover.types';

import { useCurrentPublicationState } from '../../../context/current-publication-context';
import { BoostOffer } from '../../../interfaces/boosts/monetize/boost_offer';
import { Pagination } from '../../../interfaces/general';
import api from '../../../services/swarm';

interface ApiResponse {
  boost_offers: BoostOffer[];
  pagination: Pagination;
}

export enum BoostOfferSort {
  FEATURED = 'featured',
  NEWEST = 'newest',
  OLDEST = 'oldest',
  HIGHEST_PAYOUT = 'highest_payout',
  LARGEST_BUDGET = 'largest_budget',
  HIGHEST_SEND_PAYOUT = 'highest_send_payout',
}

interface Props {
  search?: string;
  perPage?: number;
  sort?: BoostOfferSort;
  tagIds?: string[];
  tagOperator?: FiltersPopopoverOperator;
  languageIds?: string[];
  languageOperator?: FiltersPopopoverOperator;
  eligibleCountries?: string[];
  lastPostedWithinDaysAgo?: number;
  sizeScores?: string[];
  minPerLeadCents?: number;
  maxPerLeadCents?: number;
  minPayoutCents?: number;
  maxPayoutCents?: number;
}

const useBoostOffers = ({
  search = '',
  perPage = 10,
  sort = BoostOfferSort.NEWEST,
  tagIds = [],
  tagOperator = FiltersPopopoverOperator.EQUAL,
  languageIds = [],
  languageOperator = FiltersPopopoverOperator.EQUAL,
  eligibleCountries = [],
  sizeScores = [],
  lastPostedWithinDaysAgo,
  minPerLeadCents,
  maxPerLeadCents,
  minPayoutCents,
  maxPayoutCents,
}: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchBoostOffers = ({ pageParam = 1 }): Promise<ApiResponse> => {
    const searchQuery = new URLSearchParams({
      page: pageParam.toString(),
      per_page: perPage.toString(),
      publication_id: currentPublicationId,
      tag_operator: tagOperator,
      language_operator: languageOperator,
      q: search,
      sort,
    });

    tagIds.forEach((id: string) => {
      searchQuery.append(`tag_ids[]`, id);
    });

    languageIds.forEach((id: string) => {
      searchQuery.append(`language_ids[]`, id);
    });

    eligibleCountries.forEach((id: string) => {
      searchQuery.append(`eligible_countries[]`, id);
    });

    sizeScores.forEach((id: string) => {
      searchQuery.append(`size_scores[]`, id);
    });

    if (lastPostedWithinDaysAgo) searchQuery.append('last_posted_within_days_ago', lastPostedWithinDaysAgo.toString());
    if (minPerLeadCents) searchQuery.append('min_per_lead_cents', minPerLeadCents.toString());
    if (maxPerLeadCents) searchQuery.append('max_per_lead_cents', maxPerLeadCents.toString());
    if (minPayoutCents) searchQuery.append('min_payout_cents', minPayoutCents.toString());
    if (maxPayoutCents) searchQuery.append('max_payout_cents', maxPayoutCents.toString());

    return api.get(`/boosts/monetize/boost_offers?${searchQuery.toString()}`).then((res: any) => res.data);
  };

  return useInfiniteQuery(
    [
      currentPublicationId,
      'boosts',
      'monetize',
      'boost_offers',
      search,
      sort,
      tagIds,
      tagOperator,
      languageIds,
      languageOperator,
      sizeScores,
      lastPostedWithinDaysAgo,
      eligibleCountries,
      minPerLeadCents,
      maxPerLeadCents,
      minPayoutCents,
      maxPayoutCents,
    ],
    fetchBoostOffers,
    {
      getNextPageParam: (lastPage) => {
        const { page, total_pages: totalPages } = lastPage?.pagination || {};

        if (page < totalPages) return page + 1;

        return false;
      },
      keepPreviousData: true,
    }
  );
};

export default useBoostOffers;
