import { PageRoute } from "@/interfaces/dream_builder/page_route"

export const getAllRoutes = (routes: PageRoute | undefined, defaultRoutes: PageRoute) => {
  // Home page is in the root `page_id`, do not include it in the children
  const defaultRoutesChildren = defaultRoutes?.children ? Object.fromEntries(
    Object.entries(defaultRoutes.children).filter(([key]) => key !== '')
  ) : {};

  return {
    page_id: defaultRoutes.page_id,
    page_version_id: defaultRoutes.page_version_id,
    children: {
      ...routes?.children,
      ...defaultRoutesChildren,
    },
    children_keys: [
      ...(routes?.children_keys || []),
      ...(defaultRoutes?.children_keys?.filter((key) => key !== '') || []),
    ],
  }
}

