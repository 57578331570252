import { useCallback, useMemo } from 'react';
import { CaretDown } from '@phosphor-icons/react';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../../UI/DropdownMenu';
import { SimpleInput, SimpleInputWrapper } from '../../../../UI/Input';
import { Text } from '../../../../UI/Text';
import { AttributeSettingProps } from '../../types';

const getDimensionType = (dimensionType: 'fixed' | 'relative' | 'fill' | 'fit') => {
  switch (dimensionType) {
    case 'fixed':
      return 'px';
    case 'relative':
      return '%';
    default:
      return '';
  }
};

const getDimensionTypeFromValue = (value: string | undefined): DimensionType => {
  if (value === 'stretch') return 'fill';
  if (value === 'auto' || value === undefined) return 'fit';
  if (typeof value === 'string' && value.endsWith('%')) return 'relative';
  return 'fixed';
};

type HeightSettingsProps = AttributeSettingProps & {
  property?: string
};

type DimensionType = 'fixed' | 'relative' | 'fill' | 'fit';

const parseCssValue = (value: string) => {
  if (!value) return 0;
  if (typeof value === 'number') return value;
  if (value === 'auto') {
    return 0;
  }
  if (value === 'stretch') return 100;
  const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
  return Number.isNaN(numericValue) ? 0 : numericValue;
};

export const HeightSettings = ({
  editor,
  activeNodeResult,
  property = 'height',
}: HeightSettingsProps) => {
  const { activeNodeAttributes, activeNodePos } = activeNodeResult;

  const heightDimensionType = useMemo(
    () => getDimensionTypeFromValue(activeNodeAttributes[property]),
    [activeNodeAttributes, property]
  );

  const options: Record<string, string> = {
    fixed: 'Fixed',
    relative: 'Rel',
    fill: 'Fill',
    fit: 'Fit',
  };

  const withDimensionType = useCallback((num: number, dimensionType: DimensionType) => {
    if (dimensionType === 'fill') {
      return 'stretch';
    }
    if (dimensionType === 'fit') {
      return 'auto';
    }
    return `${num}${getDimensionType(dimensionType)}`;
  }, []);

  const setHeightAttribute = useCallback(
    (value: number, dimensionType: DimensionType) => {
      editor.commands.command(({ tr }) => {
        tr.setNodeAttribute(activeNodePos, property, withDimensionType(value, dimensionType));
        return true;
      });
    },
    [activeNodePos, editor, property, withDimensionType]
  );


  const handleHeightDimensionTypeChange = useCallback(
    (dimensionType: DimensionType) => {
      const heightValue = parseCssValue(activeNodeAttributes[property]);
      setHeightAttribute(heightValue, dimensionType);
    },
    [activeNodeAttributes, property, setHeightAttribute]
  );

  const handleHeightChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setHeightAttribute(0, heightDimensionType);
        return;
      }

      const heightValue = parseInt(e.target.value, 10);

      setHeightAttribute(heightValue, heightDimensionType);
    },
    [setHeightAttribute, heightDimensionType]
  );
  return (
    <div className="flex items-center justify-stretch gap-2 select-none">
      <Text className="w-[80px] shrink-0" variant="secondary" size="2xs" weight="medium">
        Height
      </Text>

      <div className="grow flex gap-2 min-w-0">
        <SimpleInputWrapper>
          {heightDimensionType === 'fit' && (
            <Text size="2xs" weight="medium" className="w-full">
              Auto
            </Text>
          )}
          {heightDimensionType === 'fill' && <SimpleInput type="text" value="-" disabled />}
          {(heightDimensionType === 'fixed' || heightDimensionType === 'relative') && (
            <SimpleInput
              type="number"
              defaultValue={parseCssValue(activeNodeAttributes[property])}
              value={parseCssValue(activeNodeAttributes[property])}
              onChange={handleHeightChange}
            />
          )}
          <Text size="2xs" variant="secondary" weight="medium">
            {getDimensionType(heightDimensionType)}
          </Text>
        </SimpleInputWrapper>
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="cursor-pointer">
            <div className="grow w-full bg-wb-secondary rounded-lg shadow-sm justify-between flex items-center gap-2 p-2">
              <div className="flex items-center gap-1">
                <Text size="2xs" weight="medium">
                  {options[heightDimensionType]}
                </Text>
              </div>
              <CaretDown className="text-wb-secondary w-3 h-3" weight="bold" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-full min-w-[8rem] cursor-pointer">
            {Object.keys(options).map((optionKey) => (
              <DropdownMenuItem
                key={optionKey}
                onSelect={() => {
                  handleHeightDimensionTypeChange(optionKey as DimensionType);
                }}
              >
                {options[optionKey]}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};
