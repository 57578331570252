import { AdPortalCampaignStatus } from '@/ad_portal/interfaces/campaign';
import Badge from '@/ui/Badge';

export interface StatusBadgeProps {
  status: AdPortalCampaignStatus;
}

const STATUS_DICTIONARY: {
  [key in AdPortalCampaignStatus]: {
    badgeType: 'success' | 'info' | 'info_blue';
  };
} = {
  past: {
    badgeType: 'info',
  },
  upcoming: {
    badgeType: 'info_blue',
  },
  active: {
    badgeType: 'success',
  },
};

const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { badgeType } = STATUS_DICTIONARY[status];

  return (
    <Badge type={badgeType}>
      <span className="capitalize text-xs">{status}</span>
    </Badge>
  );
};

export default StatusBadge;
