import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import { Button } from '@/routes/website/_components/UI/Button';

import { useNavbarContext } from '../../../NavbarContext';
import { LinkToSettings } from '../general/LinkToSettings';
import { NewTabSettings } from '../general/NewTabSettings';

export function NavDropdownItemLinkSettings() {
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();

  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown_item') return null;

  const hasLink =
    selectedContent?.attrs && 'href' in selectedContent.attrs
      ? selectedContent.attrs.href !== null && selectedContent.attrs.href !== undefined
      : false;

  const onRemoveLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { href: undefined });
  };

  const onAddLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { href: '' });
  };

  return (
    <SectionRenderer
      title="Link"
      actions={
        <Button variant="ghost" size="sm" className="text-wb-accent" onClick={hasLink ? onRemoveLink : onAddLink}>
          {hasLink ? 'Remove' : 'Add'}
        </Button>
      }
      collapsible={hasLink}
      isOpen={!!hasLink}
    >
      {hasLink && (
        <>
          <LinkToSettings />
          <NewTabSettings />
        </>
      )}
    </SectionRenderer>
  );
}
