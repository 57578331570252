import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import {
  HandThumbDownIcon as SolidThumbsDown,
  HandThumbUpIcon as SolidThumbsUp,
  PencilSquareIcon,
} from '@heroicons/react/20/solid';
import {
  HandThumbDownIcon as OutlineThumbsDown,
  HandThumbUpIcon as OutlineThumbsUp,
} from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import IconButton from '@/components/IconHelpers/IconButton';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useInfiniteScroll } from '@/hooks';
import usePublisherListItemCreate from '@/hooks/useAdNetwork/internal/campaign_performance/usePublisherListItemCreate';
import usePublisherListItemDestroy from '@/hooks/useAdNetwork/internal/campaign_performance/usePublisherListItemDestroy';
import usePublisherListItemUpdate from '@/hooks/useAdNetwork/internal/campaign_performance/usePublisherListItemUpdate';
import useCampaignOpportunities from '@/hooks/useAdNetwork/internal/useCampaignOpportunities';
import { AdNetworkCampaignPayoutMethod } from '@/interfaces/ad_network/internal/campaign';
import {
  AdNetworkCampaignPerformanceFilter,
  AdNetworkCampaignPerformanceOpportunity,
} from '@/interfaces/ad_network/internal/campaign_performance_opportunity';
import {
  AdNetworkDisbursementClicksAdjustmentReason,
  AdNetworkDisbursementStatus,
} from '@/interfaces/ad_network/internal/disbursement/types';
import { AdNetworkPayoutModel } from '@/interfaces/ad_network/shared/payout_model';
import { Pagination } from '@/interfaces/general';
import { InfiniteScrollOptions } from '@/interfaces/infinite_scroll_options';
import { Badge } from '@/ui/Badge';
import Switch from '@/ui/Switch';

import { LoadingListPage } from '../../../_components/LoadingListPage';
import { Tabs } from '../../../_components/Tabs';
import EditClicksModal from '../_components/EditClicksModal';

import { Table } from './_components/Table';
import { useOutletCampaignPerformance } from './_layout';

interface CampaignPerformanceDeliveredProps {
  opportunities: AdNetworkCampaignPerformanceOpportunity[];
  infiniteScrollOptions: InfiniteScrollOptions;
  isFetchingNextPage: boolean;
  refetchData: any;
  pagination: Pagination;
}

const formatter = new Intl.NumberFormat('en-US');

const CampaignPerformanceDelivered = ({
  opportunities,
  infiniteScrollOptions,
  pagination,
  refetchData,
  isFetchingNextPage,
}: CampaignPerformanceDeliveredProps) => {
  const campaign = useOutletCampaignPerformance();
  const [currentOpportunityId, setCurrentOpportunityId] = useState<string | undefined>();
  const handleClose = () => setCurrentOpportunityId(undefined);
  const { ref: lastElementRef } = useInfiniteScroll(infiniteScrollOptions);

  const { mutateAsync: createAsync } = usePublisherListItemCreate();
  const { mutateAsync: updateAsync } = usePublisherListItemUpdate();
  const { mutateAsync: destroyAsync } = usePublisherListItemDestroy();
  const [viewPixelStats, setViewPixelStats] = useState(false);

  const generateToast = (
    type: 'create' | 'destroy' | 'update',
    opportunity: AdNetworkCampaignPerformanceOpportunity,
    listType: string
  ) => {
    let toastMessage =
      listType === 'preferred'
        ? 'This publisher will be included in more future opportunities'
        : 'This publisher will be excluded from future opportunities';
    if (type === 'destroy')
      toastMessage =
        listType === 'preferred'
          ? 'This publisher has been removed from the preferred list'
          : 'This publisher has been removed from the exclusion list';

    toast.success(toastMessage);
  };

  const createMutation = (opportunity: AdNetworkCampaignPerformanceOpportunity, listType: string) => {
    createAsync({ advertiserId: opportunity.advertiser_id, publisherId: opportunity.publication_id, listType })
      .then(() => {
        refetchData();
      })
      .then(() => {
        generateToast('create', opportunity, listType);
      });
  };

  const updateMutation = (opportunity: AdNetworkCampaignPerformanceOpportunity, listType: string) => {
    updateAsync({
      advertiserId: opportunity.advertiser_id,
      publisherId: opportunity.publication_id,
      publisherListItemId: opportunity.publisher_list_item.id,
      listType,
    })
      .then(() => {
        refetchData();
      })
      .then(() => {
        generateToast('update', opportunity, listType);
      });
  };

  const destroyMutation = (opportunity: AdNetworkCampaignPerformanceOpportunity, listType: string) => {
    destroyAsync({
      advertiserId: opportunity.advertiser_id,
      publisherListItemId: opportunity.publisher_list_item.id,
      listType,
    })
      .then(() => {
        refetchData();
      })
      .then(() => {
        generateToast('destroy', opportunity, listType);
      });
  };

  const handleVote = (opportunity: AdNetworkCampaignPerformanceOpportunity, voteType: string) => {
    if (opportunity.publisher_list_item.id) {
      if (opportunity.publisher_list_item.type === voteType) {
        destroyMutation(opportunity, voteType);
      } else {
        updateMutation(opportunity, voteType);
      }
    } else {
      createMutation(opportunity, voteType);
    }
  };

  return (
    <>
      <div className="border-b border-gray-100 p-4 flex space-x-2">
        <div>
          <Tabs>
            <Tabs.Tab to="">Delivered</Tabs.Tab>
            <Tabs.Tab to="../upcoming">Upcoming</Tabs.Tab>
            <Tabs.Tab to="../pending">Pending</Tabs.Tab>
            <Tabs.Tab to="../declined_or_expired">Declined or Expired</Tabs.Tab>
          </Tabs>
        </div>
        <div className="flex float-right">
          <span className="pt-2 pr-2 text-sm">View Events</span>
          <Switch
            variant="primary"
            checked={viewPixelStats}
            name="pixel_stats"
            onChange={async (_name: string, value: boolean) => {
              setViewPixelStats(value);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col overflow-x-auto">
        <div className="overflow-x-auto w-full">
          <Table>
            <Table.Head>
              {viewPixelStats ? (
                <Table.Row>
                  <Table.Header>Publication ID</Table.Header>
                  <Table.Header>Publication</Table.Header>
                  <Table.Header>Post</Table.Header>
                  <Table.Header>Version</Table.Header>
                  <Table.Header>Date</Table.Header>
                  <Table.Header align="right">Opens</Table.Header>
                  <Table.Header align="right">Clicks</Table.Header>
                  <Table.Header align="right">Payout Rate</Table.Header>
                  <Table.Header align="right">CTR</Table.Header>
                  <Table.Header align="right">Total Pageviews</Table.Header>
                  <Table.Header align="right">Total Conversions</Table.Header>
                  <Table.Header align="right">Pageview Rate</Table.Header>
                  <Table.Header align="right">Conversion Rate</Table.Header>
                  <Table.Header>Performance</Table.Header>
                </Table.Row>
              ) : (
                <Table.Row>
                  <Table.Header>Publication ID</Table.Header>
                  <Table.Header>Publication</Table.Header>
                  <Table.Header>Post</Table.Header>
                  <Table.Header>Version</Table.Header>
                  <Table.Header>Date</Table.Header>
                  <Table.Header align="right">Opens</Table.Header>
                  <Table.Header align="right">Clicks</Table.Header>
                  <Table.Header align="right">Payout Rate</Table.Header>
                  <Table.Header align="right">CTR</Table.Header>
                  <Table.Header>Performance</Table.Header>
                </Table.Row>
              )}
            </Table.Head>
            <Table.Body>
              {opportunities.map((opportunity) => {
                const isLastRow = opportunities.length - 1 === opportunities.indexOf(opportunity);
                const campaignHasAutoPayout = campaign.payout_method === AdNetworkCampaignPayoutMethod.AUTO;
                const canEdit =
                  campaignHasAutoPayout &&
                  opportunity.is_finalized &&
                  opportunity.disbursement.status !== AdNetworkDisbursementStatus.PAID;
                const isFinalized = opportunity.is_finalized;
                const isClicksEdited =
                  isFinalized && opportunity.disbursement.approved_clicks !== opportunity.disbursement.actual_clicks;
                const isOpensEdited =
                  isFinalized && opportunity.disbursement.approved_opens !== opportunity.disbursement.actual_opens;
                const isEditedClass =
                  !!opportunity.disbursement.clicks_adjustment_reason &&
                  opportunity.disbursement.clicks_adjustment_reason ===
                    AdNetworkDisbursementClicksAdjustmentReason.CAPPED_CLICKS
                    ? 'text-red-300'
                    : 'text-gray-300';
                const actualClicks = isFinalized
                  ? opportunity.disbursement.actual_clicks
                  : opportunity.total_unique_eligible_clicked;
                const approvedClicks = isFinalized
                  ? opportunity.disbursement.approved_clicks
                  : opportunity.total_unique_eligible_clicked;
                const actualOpens = isFinalized
                  ? opportunity.disbursement.actual_opens
                  : opportunity.total_unique_eligible_opened;
                const approvedOpens = isFinalized
                  ? opportunity.disbursement.approved_opens
                  : opportunity.total_unique_eligible_opened;

                return (
                  <Table.Row key={opportunity.ad_network_opportunity_id} ref={isLastRow ? lastElementRef : null}>
                    <EditClicksModal
                      opportunity={opportunity}
                      modalOpen={currentOpportunityId === opportunity.ad_network_opportunity_id}
                      onClose={handleClose}
                    />
                    { viewPixelStats ? (
                      <>
                        <Table.Cell>
                          <span className="font-mono">{opportunity.publication_alphanumeric_id}</span>
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            to={`/ad_network/publications/${opportunity.publication_id}`}
                            className="hover:underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {opportunity.publication_name}
                            {opportunity.banned && <span className="text-red-500"> (banned)</span>}
                            <Badge size="sm" className="ml-2">
                              {opportunity.publication_tier_display_name}
                            </Badge>
                          </Link>
                        </Table.Cell>
                        <Table.Cell muted>
                          <Link
                            to={`/posts/${opportunity.post_id}`}
                            className="hover:underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {opportunity.post_title}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{opportunity.advertisement_option_name}</Table.Cell>
                        <Table.Cell>{moment(opportunity.date).format('MMM D, YYYY')}</Table.Cell>
                        <Table.Cell align="right">
                          {opportunity.total_unique_opened}
                        </Table.Cell>
                        <Table.Cell align="right">
                          <div className="flex justify-end items-center space-x-2">
                            {isClicksEdited && (
                              <span className={`line-through ${isEditedClass} select-none`}>
                                {formatter.format(actualClicks)}
                              </span>
                            )}
                            <span>{formatter.format(approvedClicks)}</span>
                            {canEdit && (
                              <button
                                type="button"
                                className="text-gray-500 hover:text-primary-500"
                                onClick={() => setCurrentOpportunityId(opportunity.ad_network_opportunity_id)}
                              >
                                <PencilSquareIcon className="w-4 h-4" />
                              </button>
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell align="right">
                          <div className="min-w-fit">
                            {opportunity.payout_model === AdNetworkPayoutModel.CPC
                              ? `${opportunity.payout_per_click} CPC`
                              : `${opportunity.payout_per_mille} CPM`}
                          </div>
                        </Table.Cell>
                        <Table.Cell align="right">
                          {opportunity.total_unique_opened === 0
                            ? 0
                            : ((100 * approvedClicks) / opportunity.total_unique_opened).toFixed(1)
                          }
                          %
                        </Table.Cell>
                        <Table.Cell align="right">
                          <div className="flex justify-end items-center space-x-2">
                            {opportunity.pixel_events.first_visited.total_events}
                          </div>
                        </Table.Cell>
                        <Table.Cell align="right">
                          <div className="flex justify-end items-center space-x-2">
                            {opportunity.pixel_events.conversion.total_events}
                          </div>
                        </Table.Cell>
                        <Table.Cell align="right">
                          <div className="min-w-fit">
                            {opportunity.pixel_events.first_visited.opened_event_rate}
                          </div>
                        </Table.Cell>
                        <Table.Cell align="right">
                          <div>
                            {opportunity.pixel_events.conversion.opened_event_rate}
                          </div>
                        </Table.Cell>

                        <Table.Cell>
                          <div className="flex">
                            <IconButton onClick={() => handleVote(opportunity, 'preferred')}>
                              {opportunity.publisher_list_item.type === 'preferred' ? (
                                <SolidThumbsUp aria-hidden="true" className="text-green-500" />
                              ) : (
                                <OutlineThumbsUp aria-hidden="true" />
                              )}
                            </IconButton>
                            <IconButton onClick={() => handleVote(opportunity, 'excluded')}>
                              {opportunity.publisher_list_item.type === 'excluded' ? (
                                <SolidThumbsDown aria-hidden="true" className="text-red-500" />
                              ) : (
                                <OutlineThumbsDown aria-hidden="true" />
                              )}
                            </IconButton>
                          </div>
                        </Table.Cell>
                      </>
                    ) : (
                      <>
                        <Table.Cell>
                          <span className="font-mono">{opportunity.publication_alphanumeric_id}</span>
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            to={`/ad_network/publications/${opportunity.publication_id}`}
                            className="hover:underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {opportunity.publication_name}
                            {opportunity.banned && <span className="text-red-500"> (banned)</span>}
                            <Badge size="sm" className="ml-2">
                              {opportunity.publication_tier_display_name}
                            </Badge>
                          </Link>
                        </Table.Cell>
                        <Table.Cell muted>
                          <Link
                            to={`/posts/${opportunity.post_id}`}
                            className="hover:underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {opportunity.post_title}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{opportunity.advertisement_option_name}</Table.Cell>
                        <Table.Cell>{moment(opportunity.date).format('MMM D, YYYY')}</Table.Cell>
                        <Table.Cell align="right">
                          <div>
                            {isOpensEdited && (
                              <span className={`line-through ${isEditedClass} select-none`}>
                                {formatter.format(actualOpens)}
                              </span>
                            )}
                            <span>{formatter.format(approvedOpens)}</span>
                          </div>
                        </Table.Cell>
                        <Table.Cell align="right">
                          <div className="flex justify-end items-center space-x-2">
                            {isClicksEdited && (
                              <span className={`line-through ${isEditedClass} select-none`}>
                                {formatter.format(actualClicks)}
                              </span>
                            )}
                            <span>{formatter.format(approvedClicks)}</span>
                            {canEdit && (
                              <button
                                type="button"
                                className="text-gray-500 hover:text-primary-500"
                                onClick={() => setCurrentOpportunityId(opportunity.ad_network_opportunity_id)}
                              >
                                <PencilSquareIcon className="w-4 h-4" />
                              </button>
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell align="right">
                          <div className="min-w-fit">
                            {opportunity.payout_model === AdNetworkPayoutModel.CPC
                              ? `${opportunity.payout_per_click} CPC`
                              : `${opportunity.payout_per_mille} CPM`}
                          </div>
                        </Table.Cell>
                        <Table.Cell align="right">
                          {opportunity.total_unique_opened === 0
                            ? 0
                            : ((100 * approvedClicks) / opportunity.total_unique_opened).toFixed(1)}
                          %
                        </Table.Cell>
                        <Table.Cell>
                          <div className="flex">
                            <IconButton onClick={() => handleVote(opportunity, 'preferred')}>
                              {opportunity.publisher_list_item.type === 'preferred' ? (
                                <SolidThumbsUp aria-hidden="true" className="text-green-500" />
                              ) : (
                                <OutlineThumbsUp aria-hidden="true" />
                              )}
                            </IconButton>
                            <IconButton onClick={() => handleVote(opportunity, 'excluded')}>
                              {opportunity.publisher_list_item.type === 'excluded' ? (
                                <SolidThumbsDown aria-hidden="true" className="text-red-500" />
                              ) : (
                                <OutlineThumbsDown aria-hidden="true" />
                              )}
                            </IconButton>
                          </div>
                        </Table.Cell>
                      </>
                    ) }
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className="sticky bottom-0">
        <div className="w-full px-4 py-2 border-t border-gray-100 bg-white">
          <div className="text-sm text-gray-500 float-left">
            Showing {opportunities.length} of {pagination.total}
          </div>
          <div className="flex text-sm text-gray-400 float-right">
            {infiniteScrollOptions.isFetching || infiniteScrollOptions.isLoading || isFetchingNextPage ? (
              <>
                <LoadingSpinner className="mr-2" />
                <p>Loading reports...</p>
              </>
            ) : (
              infiniteScrollOptions.hasNextPage && <p>Scroll down to load more reports</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default function Loader() {
  const { campaign_id: id } = useParams<'campaign_id'>() as { campaign_id: string };
  const response = useCampaignOpportunities({
    campaignId: id,
    filter: AdNetworkCampaignPerformanceFilter.DELIVERED,
  });

  const { data, isSuccess, isError, isLoading, fetchNextPage, hasNextPage, isFetching, refetch, isFetchingNextPage } =
    response;

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;
  const opportunities = data.pages.flatMap((page) => page.opportunities);
  const { pagination } = data.pages[0];

  return (
    <CampaignPerformanceDelivered
      opportunities={opportunities}
      infiniteScrollOptions={{
        fetchNextPage,
        hasNextPage: !!hasNextPage,
        isFetching,
        isLoading,
      }}
      refetchData={refetch}
      pagination={pagination}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}
