import { useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import { Typography, TypographyStack } from '@/components/Typography';
import { ExternalRssFeed, RSSCTAStyle, RSSEntry } from '@/interfaces/external_rss_feed';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import { Heading } from '@/ui/Heading';
import { capitalize } from '@/utils';

import CardHeader from '../../../_components/CardHeader';

interface Props {
  externalRssFeed: Partial<ExternalRssFeed>;
  content: RSSEntry[];
  showEditButton?: boolean;
}

const extractFirstImage = (htmlString: string) => {
  const match = htmlString.match(/<img[^>]+src="([^">]+)"/);
  return match ? match[1] : null;
};

const Preview = ({ externalRssFeed, content, showEditButton = false }: Props) => {
  const navigate = useNavigate();
  const {
    displayTitle,
    displayCta,
    displayAuthor,
    displayContent,
    displayDescription,
    displayThumbnail,
    displayTitleAboveThumbnail,
    displayCategories,
    displayPubDate,
    ctaText,
    ctaStyle,
  } = externalRssFeed;

  const previewContent = content[0];
  const articleThumbnail =
    previewContent &&
    (previewContent.thumbnail || (previewContent.content && extractFirstImage(previewContent.content)));

  const renderCategories = (categories: string[] | string) => {
    if (categories && Array.isArray(categories)) {
      return categories.map((category) => (
        <Badge key={category} type="info_blue" size="md">
          {capitalize(category)}
        </Badge>
      ));
    }

    return (
      <Badge type="info_blue" size="md">
        {capitalize(categories)}
      </Badge>
    );
  };

  return (
    <Card className="flex flex-col gap-y-6">
      <CardHeader
        title="Summary"
        cta={
          externalRssFeed.id && showEditButton ? (
            <Button size="xs" onClick={() => navigate(`/settings/publication/rss/${externalRssFeed.id}/edit`)}>
              Edit
            </Button>
          ) : null
        }
      />
      <TypographyStack gap="2">
        <Typography token="font-normal/text/sm" colorWeight="600">
          Feed Name
        </Typography>
        <Typography token="font-medium/text/sm" as="time">
          {externalRssFeed.name || '-'}
        </Typography>
      </TypographyStack>
      <TypographyStack gap="2">
        <Typography token="font-normal/text/sm" colorWeight="600">
          Feed URL
        </Typography>
        <Typography token="font-medium/text/sm" as="time">
          {externalRssFeed.url || '-'}
        </Typography>
      </TypographyStack>
      <TypographyStack gap="2">
        <Typography token="font-normal/text/sm" colorWeight="600">
          Preview
        </Typography>
        {previewContent && (
          <div className="flex flex-col items-start gap-2">
            {displayTitle && displayTitleAboveThumbnail && (!displayCta || ctaStyle !== RSSCTAStyle.TITLE) && (
              <Heading as="h4">{previewContent.title}</Heading>
            )}
            {displayTitle && displayTitleAboveThumbnail && displayCta && ctaStyle === RSSCTAStyle.TITLE && (
              <a href={previewContent.link} target="_blank" rel="noreferrer">
                <Heading as="h4">{previewContent.title}</Heading>
              </a>
            )}
            {displayThumbnail && articleThumbnail && (
              <img className="w-full h-48 object-cover" src={articleThumbnail} alt={previewContent.title} />
            )}
            {displayCategories && previewContent.categories && (
              <div className="flex flex-row gap-3 flex-wrap">{renderCategories(previewContent.categories)}</div>
            )}
            {displayTitle && !displayTitleAboveThumbnail && (!displayCta || ctaStyle !== RSSCTAStyle.TITLE) && (
              <Heading as="h4">{previewContent.title}</Heading>
            )}
            {displayTitle && !displayTitleAboveThumbnail && displayCta && ctaStyle === RSSCTAStyle.TITLE && (
              <a href={previewContent.link} target="_blank" rel="noreferrer">
                <Heading as="h4">{previewContent.title}</Heading>
              </a>
            )}
            {displayAuthor && <p style={{ textAlign: 'left', width: '100%' }}>by {previewContent.author}</p>}
            {displayPubDate && previewContent.pub_date && (
              <p style={{ textAlign: 'left', width: '100%' }}>{moment(previewContent.pub_date).format('LL')}</p>
            )}
            {displayDescription && <blockquote dangerouslySetInnerHTML={{ __html: previewContent.description }} />}
            {displayContent && <div dangerouslySetInnerHTML={{ __html: previewContent.content }} />}
            {displayCta && (ctaStyle === RSSCTAStyle.BUTTON || ctaStyle === RSSCTAStyle.TITLE_THUMBNAIL_AND_BUTTON) && (
              <Button variant="primary" onClick={() => window.open(previewContent.link, '_blank')}>
                {ctaText || 'View Article'}
              </Button>
            )}
            {displayCta && (ctaStyle === RSSCTAStyle.LINK || ctaStyle === RSSCTAStyle.TITLE_THUMBNAIL_AND_LINK) && (
              <a href={previewContent.link} target="_blank" rel="noreferrer">
                {ctaText || 'View Article'}
              </a>
            )}
          </div>
        )}
      </TypographyStack>
    </Card>
  );
};

export default Preview;
