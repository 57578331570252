import { Image as BaseImage } from '@tiptap/extension-image';
import { mergeAttributes, ReactNodeViewRenderer } from '@tiptap/react';

import { getBooleanAttribute, getFloatAttribute, getStringAttribute } from '../../utils/attributesUtils';

import { ImageBlockView } from './views/ImageView';

export const Image = BaseImage.extend({
  group: 'block',

  addAttributes() {
    return {
      url: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-url'),
        renderHTML: (attributes) => ({
          'data-url': attributes.url,
        }),
      },
      href: getStringAttribute('href', 'data-href'),
      open_in_new_tab: getBooleanAttribute('open_in_new_tab', 'data-open-in-new-tab', false),
      internal_link: getBooleanAttribute('internal_link', 'data-is-internal-link', false),
      padding: getStringAttribute('padding', 'data-padding', '0px', '0px'),
      target: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-target'),
        renderHTML: (attributes) => ({
          'data-target': attributes.target,
        }),
      },
      captionUrl: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-caption-url'),
        renderHTML: (attributes) => ({
          'data-caption-url': attributes.captionUrl,
        }),
      },
      captionTarget: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-caption-target'),
        renderHTML: (attributes) => ({
          'data-caption-target': attributes.captionTarget,
        }),
      },
      src: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-src'),
        renderHTML: (attributes) => ({
          'data-src': attributes.src,
        }),
      },
      title: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-title'),
        renderHTML: (attributes) => ({
          'data-title': attributes.title,
        }),
      },
      alt: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-alt'),
        renderHTML: (attributes) => ({
          'data-alt': attributes.alt,
        }),
      },
      height: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-height'),
        renderHTML: (attributes) => ({
          'data-height': attributes.height,
        }),
      },
      align: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-align'),
        renderHTML: (attributes) => ({
          'data-align': attributes.align,
        }),
      },
      captionAlign: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-caption-align'),
        renderHTML: (attributes) => ({
          'data-caption-align': attributes.captionAlign,
        }),
      },
      clientId: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('data-client-id'),
        renderHTML: (attributes) => ({
          'data-client-id': attributes.clientId,
        }),
      },
      // Border width
      borderWidth: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-border-width'),
        renderHTML: (attributes) => ({
          'data-border-width': attributes.borderWidth,
        }),
      },
      objectFit: getStringAttribute('objectFit', 'data-object-fit', 'cover'),
      aspectRatio: getFloatAttribute('aspectRatio', 'data-aspect-ratio', undefined),
      // Border radius
      borderRadius: {
        default: '0px',
        parseHTML: (element) => element.getAttribute('data-border-radius'),
        renderHTML: (attributes) => ({
          'data-border-radius': attributes.borderRadius,
        }),
      },
      // Border color
      borderColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-border-color'),
        renderHTML: (attributes) => ({
          'data-border-color': attributes.borderColor,
        }),
      },
      // Border style
      borderStyle: {
        default: 'none',
        parseHTML: (element) => element.getAttribute('data-border-style'),
        renderHTML: (attributes) => ({
          'data-border-style': attributes.borderStyle,
        }),
      },
      width: getFloatAttribute('width', 'data-width'),
      mobileWidth: getFloatAttribute('mobileWidth', 'data-mobile-width'),
      isFullWidth: getBooleanAttribute('isFullWidth', 'data-is-full-width', false),
      mobileIsFullWidth: getBooleanAttribute('mobileIsFullWidth', 'data-mobile-is-full-width', false),
    };
  },

  parseHTML() {
    return [{ tag: `img[data-type="${this.name}"]` }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(HTMLAttributes, { 'data-type': this.name })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageBlockView, {
      attrs: (props) => ({
        className: 'max-w-full w-[--react-renderer-mobile-width] md:w-[--react-renderer-width]',
        style: `
          --react-renderer-mobile-width: ${
            props.node.attrs.src && !props.node.attrs.mobileIsFullWidth ? 'fit-content' : '100%'
          };
          --react-renderer-width: ${props.node.attrs.src && !props.node.attrs.isFullWidth ? 'fit-content' : '100%'}
        `,
      }),
      stopEvent: () => false,
    });
  },
});
