import { useState } from 'react';
import toast from 'react-hot-toast';
import { ClockClockwise, DotsThree } from '@phosphor-icons/react';

import { useWebsiteContext } from '@/context/website-context';
import useRestoreSiteVersion from '@/hooks/useSiteVersion/useRestoreSiteVersion';
import { SiteVersion } from '@/interfaces/dream_builder/site_version';
import { dateToAgo } from '@/routes/website/_utils/dateToAgo';
import { dateToDateString } from '@/routes/website/_utils/dateToDateString';

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../UI/DropdownMenu';
import Modal from '../../UI/Modal';
import { Text } from '../../UI/Text';
import { Tooltip } from '../../UI/Tooltip';

export function RestoreMenuOption({
  isOptionsOpen,
  setIsOptionsOpen,
  version,
}: {
  isOptionsOpen: boolean;
  setIsOptionsOpen: (isOptionsOpen: boolean) => void;
  version: SiteVersion;
}) {
  const { setPreviewSiteVersion } = useWebsiteContext();
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);

  const { mutate: restoreSiteVersion, isLoading } = useRestoreSiteVersion({
    onSuccess: () => {
      toast.success('Site is restored to selected version');
      setPreviewSiteVersion(undefined); // reset to current version
      setIsRestoreModalOpen(false);
      window.location.reload();
    },
  });

  const handleRestoreVersion = () => {
    restoreSiteVersion({
      siteVersionId: version.id,
    });
  };
  return (
    <>
      <DropdownMenu open={isOptionsOpen} onOpenChange={setIsOptionsOpen}>
        <DropdownMenuTrigger asChild>
          <div className="opacity-0 group-hover:opacity-100 group-hover:bg-gray-200 rounded-full p-0.5 cursor-pointer transition-opacity duration-150 data-[state=open]:opacity-100">
            <DotsThree weight="bold" className="my-auto text-wb-secondary text-gray-700 h-4 w-4" />
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={2} align="end" className="w-[150px]">
          <DropdownMenuItem LeftIcon={ClockClockwise} onSelect={() => setIsRestoreModalOpen(true)}>
            Restore Version
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Modal
        isOpen={isRestoreModalOpen}
        onClose={() => setIsRestoreModalOpen(false)}
        title="Restore This Version?"
        onConfirm={handleRestoreVersion}
        ctaText={isLoading ? 'Restoring...' : 'Restore Version'}
        ctaVariant="primary"
        isLoading={isLoading}
      >
        <Text size="sm" weight="medium" variant="secondary" as="p" className="mb-4">
          We&apos;ll back up the current draft and set the selected version as the new current one.
        </Text>
        <div className="flex items-center gap-2 p-4 rounded-lg border border-wb-primary border-solid shadow-wb-sm min-w-0 w-full">
          <div className="w-[7px] h-[7px] bg-wb-surface rounded-full" />
          <Tooltip
            center={dateToDateString(version.created_at)}
            className="h-full flex items-center whitespace-nowrap cursor-pointer"
          >
            <Text size="sm" weight="semibold">
              {dateToAgo(version.created_at, true)}
            </Text>
          </Tooltip>
          {version.version && (
            <Tooltip center={version.version} className="flex-1 min-w-0 cursor-pointer">
              <Text size="sm" variant="secondary" as="p" className="truncate">
                {version.version}
              </Text>
            </Tooltip>
          )}
        </div>
      </Modal>
    </>
  );
}
