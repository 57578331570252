import { useState } from 'react';
import { Link as PhosphorLink } from '@phosphor-icons/react';

import { Tooltip } from '../../../UI/Tooltip';
import { AttributeSettingProps } from '../types';

import { FontSettings } from './FontSettings';

type Property = {
  title: string;
  property: string;
};

type Props = AttributeSettingProps & {
  properties: Property[];
  isGroupedInitially?: boolean;
  isUngroupable?: boolean;
};

const FontGroupSettings = ({
  editor,
  properties,
  activeNodeResult,
  isGroupedInitially = false,
  isUngroupable = true,
}: Props) => {
  const [isGroupOpen, setIsGroupOpen] = useState(!isGroupedInitially);

  if (!isGroupOpen) {
    return (
      <FontSettings
        title="Font"
        editor={editor}
        activeNodeResult={activeNodeResult}
        property={properties[0].property}
        properties={properties}
        onUnGroup={() => setIsGroupOpen(true)}
        isMarkStyle={false}
        isUngroupable={isUngroupable}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <Tooltip center="Sync font changes">
        <button
          type="button"
          className="flex items-center gap-2 text-wb-secondary hover:text-wb-primary transition-all"
          onClick={() => setIsGroupOpen(false)}
        >
          <PhosphorLink className="w-4 h-4" />
        </button>
      </Tooltip>
      {properties.map((property) => (
        <FontSettings
          key={property.property}
          title={property.title}
          editor={editor}
          activeNodeResult={activeNodeResult}
          property={property.property}
          isMarkStyle={false}
        />
      ))}
    </div>
  );
};

export default FontGroupSettings;
