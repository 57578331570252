import { useNavigate } from 'react-router-dom';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';

import LoadingBox from '@/components/LoadingBox';
import { usePublication } from '@/hooks/usePublications';
import usePublicationSettings from '@/hooks/usePublications/usePublicationSettings';
import { Button } from '@/ui/Button';
import { FormStep } from '@/ui/FormStep';

import TwoColumnPageContainer from '../../../../_components/TwoColumnPageContainer';
import ReviewForm from '../../components/form/ReviewForm';
import Summary from '../../components/form/Summary';
import { StepType } from '../../components/form/types';
import WebForm from '../../components/form/WebForm';
import getRedirectDomainValue from '../../getRedirectDomainValue';
import useForm from '../../hooks/useForm';

interface Props {
  publicationId: string;
}

const NewWebDomainsForm = ({ publicationId }: Props) => {
  const navigate = useNavigate();
  const { data: publication, isSuccess, isError } = usePublication(publicationId);
  const { data: settings } = usePublicationSettings(publicationId);

  const {
    isLoadingCollection,
    currentStep,
    errors,
    handleClickNextStep,
    handleClickPreviousStep,
    hasExistingWebDomain,
    webDomain,
    setWebDomain,
    redirectToDomain,
    setShouldUseDefaultWebDomain,
    setRedirectToWWW,
    setRedirectToDomain,
    isCreating,
    shouldUseDefaultWebDomain,
    hasExistingWebRedirectDomain,
    redirectToWWW,
    shouldRedirectToDomain,
    setShouldRedirectToDomain,
    shouldUseCustomEmailDomain,
    emailDomain,
    shouldUseWebDomainAsEmailDomain,
    shouldUseDefaultEmailDomain,
    setupMethod,
    setSetupMethod,
    isEntriAvailable,
    isSetupMethodModalOpen,
    setIsSetupMethodModalOpen,
    customDomainIds,
    startManualVerification,
  } = useForm({ publicationId, isWebOnly: true, initialStep: StepType.WEB });

  const hideWebRedirect = hasExistingWebRedirectDomain || !settings?.apex_domains;

  return (
    <LoadingBox isLoading={!isSuccess || isLoadingCollection} isError={isError}>
      {publication ? (
        <TwoColumnPageContainer
          rhsColumn={
            <Summary
              publicationSubdomain={publication.subdomain}
              shouldUseDefaultWebDomain={shouldUseDefaultWebDomain}
              webDomain={webDomain || ''}
              redirectDomainValue={redirectToWWW ? getRedirectDomainValue(webDomain as string) : undefined}
              redirectToWWW={redirectToWWW}
              redirectToDomain={redirectToDomain}
              shouldRedirectToDomain={shouldRedirectToDomain}
              shouldUseCustomEmailDomain={shouldUseCustomEmailDomain}
              emailDomain={emailDomain}
              shouldUseWebDomainAsEmailDomain={shouldUseWebDomainAsEmailDomain}
              shouldUseDefaultEmailDomain={shouldUseDefaultEmailDomain}
              hideWeb={hasExistingWebDomain}
              hideWebRedirect={hideWebRedirect}
              hideEmailSection
            />
          }
        >
          <FormStep
            isOpen={currentStep === 'web'}
            stepTitle="Configure Web Domain"
            footer={
              <>
                <Button
                  type="button"
                  variant="primary-inverse"
                  onClick={() => navigate('/settings/publication/domain')}
                  size="sm"
                >
                  Back
                </Button>
                <Button type="button" variant="primary" onClick={handleClickNextStep} size="sm">
                  Next
                </Button>
              </>
            }
          >
            <WebForm
              publicationSubdomain={publication.subdomain}
              errors={errors}
              shouldUseDefaultWebDomain={shouldUseDefaultWebDomain}
              setShouldUseDefaultWebDomain={setShouldUseDefaultWebDomain}
              webDomain={webDomain}
              setWebDomain={setWebDomain}
              redirectToWWW={redirectToWWW}
              setRedirectToWWW={setRedirectToWWW}
              shouldRedirectToDomain={shouldRedirectToDomain}
              setShouldRedirectToDomain={setShouldRedirectToDomain}
              redirectToDomain={redirectToDomain}
              setRedirectToDomain={setRedirectToDomain}
              hasExistingWebDomain={hasExistingWebDomain}
              hideWebRedirect={hideWebRedirect}
            />
          </FormStep>

          <FormStep
            isOpen={currentStep === 'review'}
            stepTitle="Review and Set Up"
            footer={
              <>
                <Button type="button" variant="primary-inverse" onClick={handleClickPreviousStep} size="sm">
                  Back
                </Button>
                <Button
                  variant="primary"
                  size="xs"
                  onClick={handleClickNextStep}
                  Icon={ArrowUpRightIcon}
                  iconRight
                  disabled={isCreating}
                >
                  Finish Set Up
                </Button>
              </>
            }
          >
            <ReviewForm
              publicationSubdomain={publication.subdomain}
              shouldUseDefaultWebDomain={shouldUseDefaultWebDomain}
              webDomain={webDomain || ''}
              redirectDomainValue={redirectToWWW ? getRedirectDomainValue(webDomain as string) : undefined}
              redirectToWWW={redirectToWWW}
              redirectToDomain={redirectToDomain}
              shouldRedirectToDomain={shouldRedirectToDomain}
              shouldUseCustomEmailDomain={shouldUseCustomEmailDomain}
              emailDomain={emailDomain}
              shouldUseWebDomainAsEmailDomain={shouldUseWebDomainAsEmailDomain}
              shouldUseDefaultEmailDomain={shouldUseDefaultEmailDomain}
              setupMethod={setupMethod}
              setSetupMethod={setSetupMethod}
              isEntriAvailable={isEntriAvailable}
              isSetupMethodModalOpen={isSetupMethodModalOpen}
              setIsSetupMethodModalOpen={setIsSetupMethodModalOpen}
              customDomainIds={customDomainIds}
              isCreating={isCreating}
              startManualVerification={startManualVerification}
              hideWeb={hasExistingWebDomain}
              hideWebRedirect={hideWebRedirect}
              hideEmailSection
            />
          </FormStep>
        </TwoColumnPageContainer>
      ) : null}
    </LoadingBox>
  );
};

export default NewWebDomainsForm;
