import { Lock } from '@phosphor-icons/react';

import { PopoverHelper } from '../../helpers/PopoverHelper';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import { ColorSettings } from '../ColorSettings';
import { FontWeightSettings } from '../FontWeightSettings';
import { TransformSettings } from '../TransformSettings';

type Props = AttributeSettingProps & {};

export const PremiumPostSettings = ({ editor, activeNodeResult }: Props) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <PopoverHelper
      title="Premium"
      text="Premium"
      popoverTitle="Premium"
      Icon={Lock}
      tooltipText="Only visible on live, paid posts"
    >
      <>
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="premiumIconEnabled"
          title="Icon"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="premiumTextEnabled"
          title="Text"
        />
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="premiumBadgeEnabled"
          title="Badge"
        />
        <ColorSettings
          title="Color"
          property="premiumTextColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
          initialColor={activeNodeAttributes?.premiumTextColor}
        />
        <FontWeightSettings
          editor={editor}
          title="Font Weight"
          activeNodeResult={activeNodeResult}
          property="premiumFontWeight"
          isMarkStyle={false}
        />
        <ColorSettings
          title="Background"
          property="premiumBackgroundColor"
          editor={editor}
          activeNodeResult={activeNodeResult}
          initialColor={activeNodeAttributes?.premiumBackgroundColor}
        />
        <TransformSettings editor={editor} activeNodeResult={activeNodeResult} property="premiumTextTransform" />
      </>
    </PopoverHelper>
  );
};

export default PremiumPostSettings;
