import { useMutation } from 'react-query';

import api from '../../services/swarm';

const useDisconnectIntegration = (publicationId: string, provider: string) => {
  return useMutation<{ message: string }, { error: string }, { integrationId: string }>(({ integrationId }) =>
    api.delete(`/integrations/${integrationId}`, {
      params: {
        provider,
        assignable_id: publicationId,
        assignable_type: 'Publication',
      },
    })
  );
};

export default useDisconnectIntegration;
