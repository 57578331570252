import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import LoadingBox from '@/components/LoadingBox';
import SlideOver from '@/components/SlideOver';
import { useCreateBoostAgreementAutoPauseConfig, useUpdateBoostAgreementAutoPauseConfig } from '@/hooks/boosts/grow/useBoostAgreementAutoPauseConfigs';
import { BoostAgreementAutoPauseConfig } from '@/interfaces/boosts/grow/boost_agreement_auto_pause_config';
import { Button } from '@/ui/Button';
import TextInput from '@/ui/TextInput';

import { AutoPauseModalType } from '../constants';

import BoostAgreementAutoPauseConfirmationModal from './BoostAgreementAutoPauseConfirmationModal';

interface Props {
  autoPauseConfig?: BoostAgreementAutoPauseConfig | undefined;
  isError: boolean;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const BUTTON_TEXTS = {
  primary: {
    CREATE: "Enable auto-pause",
    UPDATE: "Update criteria",
    ENABLE: "Enable auto-pause"
  },
  secondary: {
    CREATE: "Cancel",
    UPDATE: "Disable auto-pause",
    ENABLE: "Cancel"
  }
} as const;

const BoostAgreementAutoPauseSlideOver = ({
  autoPauseConfig,
  isError,
  isLoading,
  isOpen,
  onClose,
}: Props) => {
  const { mutate: createBoostAgreementAutoPauseConfig, isLoading: isCreatingBoostAgreementAutoPauseConfig } =
    useCreateBoostAgreementAutoPauseConfig({
      onSuccess: () => {
        toast.success('Auto-pause enabled');
        onClose();
      }
    });

  const { mutate: updateBoostAgreementAutoPauseConfig, isLoading: isUpdatingBoostAgreementAutoPauseConfig } =
    useUpdateBoostAgreementAutoPauseConfig({
      onSuccess: (_filter, variables) => {
        const message = variables.active
          ? 'Auto-pause criteria updated'
          : 'Auto-pause disabled';

        toast.success(message);
        onClose();
      }
    });

  const [confirmationType, setConfirmationType] = useState<AutoPauseModalType | null>(null);

  const isNewRecord = !autoPauseConfig && !isLoading;
  const isAutoPauseEnabled = autoPauseConfig?.active;
  const [openRate, setOpenRate] = useState<number | undefined>(autoPauseConfig?.open_rate);
  const [numSubscribers, setNumSubscribers] = useState<number | undefined>(autoPauseConfig?.num_subscribers || 100);

  type ActionState = 'CREATE' | 'UPDATE' | 'ENABLE';
  const currentState = isNewRecord
    ? 'CREATE'
    : (isAutoPauseEnabled ? 'UPDATE' : 'ENABLE') as ActionState;

  const primaryText = BUTTON_TEXTS.primary[currentState];
  const secondaryText = BUTTON_TEXTS.secondary[currentState];
  const canSave = (numSubscribers && numSubscribers >= 100) && openRate && (openRate >= 0 && openRate <= 100);
  const isWorking = isCreatingBoostAgreementAutoPauseConfig || isUpdatingBoostAgreementAutoPauseConfig;

  const handlePrimaryAction = useCallback(() => {
    if (!canSave) return;

    if (isNewRecord) {
      createBoostAgreementAutoPauseConfig({
        active: true,
        openRate,
        numSubscribers
      });
      return;
    }

    if (!autoPauseConfig) return;

    updateBoostAgreementAutoPauseConfig({
      active: true,
      openRate,
      numSubscribers
    });
  }, [autoPauseConfig, canSave, createBoostAgreementAutoPauseConfig, isNewRecord, numSubscribers, openRate, updateBoostAgreementAutoPauseConfig]);

  const handleSecondaryAction = useCallback(() => {
    if (currentState === 'UPDATE' && autoPauseConfig?.id) {
      updateBoostAgreementAutoPauseConfig({ active: false });

      return;
    }

    onClose();
  }, [currentState, autoPauseConfig?.id, onClose, updateBoostAgreementAutoPauseConfig]);

  const handleConfirm = () => {
    if (confirmationType === 'disable') {
      handleSecondaryAction();
    } else {
      handlePrimaryAction();
    }

    setConfirmationType(null);
  };

  const handlePrimaryClick = () => {
    setConfirmationType(currentState === 'UPDATE' ? 'update' : 'enable');
  };

  const handleSecondaryClick = () => {
    if (currentState === 'UPDATE') {
      setConfirmationType('disable');
      return;
    }

    onClose();
  };

  const handleCancel = useCallback(() => {
    onClose();
    setOpenRate(autoPauseConfig?.open_rate);
    setNumSubscribers(autoPauseConfig?.num_subscribers || 100);
  }, [autoPauseConfig, onClose]);

  useEffect(() => {
    if (!autoPauseConfig) return;

    setOpenRate(autoPauseConfig.open_rate);
    setNumSubscribers(autoPauseConfig.num_subscribers);
  }, [autoPauseConfig]);

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={handleCancel}
      headerText="Auto-pause"
      headerDescription="Set the threshold for the open rate and the number of verified subscribers required to activate the auto-pause feature. If the open rate drops below the set threshold after achieving the specified number of subscribers, the agreement will be paused. You can disable the auto-pause feature at any time."
    >
      <BoostAgreementAutoPauseConfirmationModal
        isOpen={!!confirmationType}
        isWorking={isWorking}
        type={confirmationType || 'enable'}
        onClose={() => setConfirmationType(null)}
        onProceed={handleConfirm}
      />
      <LoadingBox isLoading={isLoading} isError={isError && !isNewRecord}>
        <div className="h-full relative">
          <div className="space-y-6 pb-20">
            <TextInput
              type="number"
              name="open_rate"
              labelText="Open rate threshold"
              value={String(openRate)}
              onChange={(e) => setOpenRate(e.target.value === "" ? undefined : Number(e.target.value))}
              min="0"
              max="100"
              trailingText="%"
            />

            <TextInput
              type="number"
              name="num_subscribers"
              labelText="How many verified subscribers do you want to have before this takes effect?"
              helperText="Minimum: 100 subscribers"
              value={String(numSubscribers)}
              min="100"
              onChange={(e) => setNumSubscribers(e.target.value === "" ? undefined : Number(e.target.value))}
            />
          </div>
          <div className="bg-white absolute -mb-6 bottom-0 -left-6 -right-6 p-6 border-t flex space-x-3 justify-end">
            <Button
              type="button"
              variant="primary-inverse"
              onClick={handleSecondaryClick}
              disabled={isWorking}
            >
              {secondaryText}
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handlePrimaryClick}
              disabled={!canSave || isWorking}
            >
              {primaryText}
            </Button>
          </div>
        </div>
      </LoadingBox>
    </SlideOver>
  );
};

export default BoostAgreementAutoPauseSlideOver;
