import { useInfiniteQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PageVersion } from '@/interfaces/dream_builder/page_version';
import { Pagination } from '@/interfaces/general';

import api from '../../services/swarm';

interface ApiResponse {
  page_versions: PageVersion[];
  pagination: Pagination;
}

interface Props {
  pageId: string;
}

export default function usePageVersions({ pageId }: Props) {
  const [currentPublicationId] = useCurrentPublicationState();

  const fetchPageVersions = ({ pageParam = 1 }) =>
    api
      .get(`/pages/${pageId}/page_versions`, {
        params: {
          publication_id: currentPublicationId,
          page: pageParam,
        },
      })
      .then((res) => res.data);

  return useInfiniteQuery<ApiResponse>(['publications', currentPublicationId, 'page_versions'], fetchPageVersions, {
    getNextPageParam: (lastPage) => {
      const { page, total_pages: totalPages } = lastPage?.pagination || {};

      if (page < totalPages) return page + 1;

      return false;
    },
    enabled: Boolean(currentPublicationId && pageId),
    staleTime: 30000,
    keepPreviousData: true,
  });
}
