import { WarningCircle } from '@phosphor-icons/react';

import { Text } from '../../../UI/Text';
import NotConnected from '../../extensions/components/NotConnected';

const PlaceholderData = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <Text weight="medium" variant="primary" size="xs">
          What is it?
        </Text>
        <Text weight="medium" variant="secondary" size="xs">
          We provide a wide range of placeholder data to help you get started, design your website and envision what
          your site can be. It can be challenging to design a website if you are just starting and do not have a robust
          set of data to work with.
        </Text>
      </div>

      <div className="flex flex-col gap-2">
        <Text weight="medium" variant="primary" size="xs">
          Anything else I need to know?
        </Text>
        <Text weight="medium" variant="secondary" size="xs">
          Placeholder data is not meant to be used in production. It is only meant to be used for design. In the pages
          tab of the left-hand side panel, you will see a
          <WarningCircle weight="bold" className="my-auto text-wb-secondary h-4 w-4" /> to indicate that you still have
          pages on your site that contain placeholder data.
        </Text>

        <Text weight="medium" variant="secondary" size="xs" as="span">
          Additionally, you will see <NotConnected onClick={() => {}} className="relative" /> in the UI of the editor to
          indicate that specific components are still wired up to placeholder data.
        </Text>
      </div>

      <div className="flex flex-col gap-2">
        <Text weight="medium" variant="primary" size="xs">
          How can I remove it?
        </Text>
        <Text weight="medium" variant="secondary" size="xs">
          You can update placeholder data by clicking the <NotConnected onClick={() => {}} className="relative" />
          icon in the UI of the editor. Or, by clicking on the Data tab in the Attributes panel on the right hand side
          of the page.
        </Text>
      </div>
    </div>
  );
};

export default PlaceholderData;
