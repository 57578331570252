import { useMutation } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Price, Tier } from '@/interfaces/tier';
import api from '@/services/swarm';

interface Props {
  onSuccess?: () => void;
  onError?: (err: any) => void;
}

type PriceUpdateProps = Pick<Price, 'id'> & {
  features: Price['features'] | null;
};
export type TiersUpdateProps = {
  id: Tier['id'];
  name: Tier['name'];
  description: Tier['description'];
  prices_attributes: PriceUpdateProps[];
}[];

const useUpdatePricingTiers = ({ onSuccess, onError }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const updateTier = (variables: TiersUpdateProps) =>
    api
      .patch(`/dream_editor/tiers/bulk_update`, {
        publication_id: currentPublicationId,
        tiers: variables,
      })
      .then((res) => res.data);

  return useMutation<Tier, unknown, TiersUpdateProps>(updateTier, {
    onSuccess,
    onError,
  });
};

export default useUpdatePricingTiers;
