import { PopoverHelper } from '../../helpers/PopoverHelper';
import SectionRenderer from '../../helpers/SectionRenderer';
import { RenderCondition } from '../../RenderCondition';
import { AttributeSettingProps } from '../../types';
import { BooleanSettings } from '../BooleanSettings';
import BorderSettings from '../BorderSettings';
import ColorGroupSettings from '../ColorGroupSettings';
import { ColorPopoverGroupSettings } from '../ColorPopoverGroupSettings';
import { ColorSettings } from '../ColorSettings';
import { HeightSettings } from '../ContainerSettings/HeightSettings';
import { FontSettings } from '../FontSettings';
import { FontSizeSettings } from '../FontSizeSettings';
import { GapSettings } from '../GapSettings';
import { PaddingSettings } from '../PaddingSettings';
import ShadowSettings from '../ShadowSettings';
import { SimpleTextSettings } from '../SimpleTextSettings';

import RecommendedSetting from './RecommendedSetting';

export const PricingSettings = ({ editor, activeNodeResult }: AttributeSettingProps) => {
  const { activeNodeAttributes } = activeNodeResult;

  return (
    <RenderCondition editor={editor} allowedNodeTypes={['pricing']}>
      <SectionRenderer title="Mobile" isMobile noSettingsAvailable />

      <SectionRenderer title="Checkout">
        <BooleanSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="internalCheckout"
          title="Internal"
        />
        <ColorPopoverGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Button Color"
          properties={[
            { title: 'Background', property: 'buttonColor' },
            { title: 'Text', property: 'buttonTextColor' },
          ]}
        />
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Button Border"
          properties={{
            color: 'buttonBorderColor',
            style: 'buttonBorderStyle',
            width: 'buttonBorderWidth',
            radius: 'buttonBorderRadius',
          }}
        />
        {activeNodeAttributes?.internalCheckout && (
          <>
            <SimpleTextSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              property="internalCheckoutCta"
              title="Text"
            />

            <ColorPopoverGroupSettings
              editor={editor}
              activeNodeResult={activeNodeResult}
              title="Input"
              properties={[
                { title: 'Background', property: 'inputBackgroundColor' },
                { title: 'Text', property: 'inputTextColor' },
                { title: 'Placeholder', property: 'inputPlaceholderColor' },
              ]}
            />
          </>
        )}
      </SectionRenderer>

      <SectionRenderer title="Recommended">
        <RecommendedSetting editor={editor} activeNodeResult={activeNodeResult} />
        <ColorSettings
          title="Background"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="recommendedBackgroundColor"
        />
        <ColorSettings
          title="Text"
          editor={editor}
          activeNodeResult={activeNodeResult}
          property="recommendedTextColor"
        />
      </SectionRenderer>

      <SectionRenderer title="Layout">
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="padding" />
        <GapSettings editor={editor} activeNodeResult={activeNodeResult} property="gap" title="Gap" />
        <BorderSettings editor={editor} activeNodeResult={activeNodeResult} />
      </SectionRenderer>

      <SectionRenderer title="Cards">
        <HeightSettings editor={editor} activeNodeResult={activeNodeResult} property="cardHeight" />
        <PaddingSettings editor={editor} activeNodeResult={activeNodeResult} property="cardPadding" />
        <BorderSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          properties={{
            color: 'cardBorderColor',
            selectedColor: 'cardSelectedBorderColor',
            style: 'cardBorderStyle',
            width: 'cardBorderWidth',
            radius: 'cardBorderRadius',
          }}
        />
        <ColorPopoverGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Background"
          properties={[
            { title: 'Color', property: 'cardBackgroundColor' },
            { title: 'Selected', property: 'cardSelectedBackgroundColor' },
          ]}
        />
        <ShadowSettings editor={editor} activeNodeResult={activeNodeResult} property="cardShadow" title="Shadow" />
        <ColorGroupSettings
          editor={editor}
          activeNodeResult={activeNodeResult}
          title="Text"
          properties={[
            { title: 'Name', property: 'nameColor' },
            { title: 'Description', property: 'descriptionColor' },
            { title: 'Feature', property: 'featureColor' },
            { title: 'Feature Icon', property: 'featureIconColor' },
          ]}
        />
        <PopoverHelper title="Price" text="Configure" popoverTitle="Price">
          <FontSizeSettings
            title="Font Size"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="priceFontSize"
            isMarkStyle={false}
          />
          <ColorSettings title="Color" editor={editor} activeNodeResult={activeNodeResult} property="priceColor" />
          <FontSizeSettings
            title="Denominator Font Size"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="priceDenominatorFontSize"
            isMarkStyle={false}
          />
          <ColorSettings
            title="Denominator Color"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="priceDenominatorColor"
          />
        </PopoverHelper>
        <PopoverHelper title="Offer" text="Configure" popoverTitle="Offer">
          <FontSizeSettings
            title="Price Font Size"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="offerPriceFontSize"
            isMarkStyle={false}
          />
          <ColorSettings
            title="Price Color"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="offerPriceColor"
          />
          <ColorSettings
            title="Details Color"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="offerDetailsColor"
          />
          <ColorSettings
            title="Icon Color"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="offerDetailsIconColor"
          />
        </PopoverHelper>
        <PopoverHelper title="Tabs" text="Configure" popoverTitle="Tabs">
          <BorderSettings
            title="Border"
            editor={editor}
            activeNodeResult={activeNodeResult}
            properties={{
              color: 'tabsContainerBorderColor',
              style: 'tabsContainerBorderStyle',
              width: 'tabsContainerBorderWidth',
              radius: 'tabsContainerBorderRadius',
            }}
            showRadiusSettings
          />
          <ColorSettings
            title="Background"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="tabBackgroundColor"
          />
        </PopoverHelper>
        <PopoverHelper title="Tab" text="Configure" popoverTitle="Tab">
          <ColorSettings title="Text" editor={editor} activeNodeResult={activeNodeResult} property="tabTextColor" />
          <ColorSettings
            title="Selected Text"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="tabSelectedTextColor"
          />
          <ColorSettings
            title="Selected Background"
            editor={editor}
            activeNodeResult={activeNodeResult}
            property="tabSelectedBackgroundColor"
          />
          <BorderSettings
            editor={editor}
            activeNodeResult={activeNodeResult}
            properties={{
              color: 'tabBorderColor',
              style: 'tabBorderStyle',
              width: 'tabBorderWidth',
              radius: 'tabBorderRadius',
            }}
          />
        </PopoverHelper>
        <FontSettings editor={editor} activeNodeResult={activeNodeResult} isMarkStyle={false} />
      </SectionRenderer>
    </RenderCondition>
  );
};
