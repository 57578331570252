import { ElementType } from 'react';
import cx from 'classnames';

import Tooltip from '@/ui/Tooltip';

interface Props {
  variant?: 'secondary' | 'tertiary';
  Icon: ElementType;
  text: string;
}

const COLOR_MAP = Object.freeze({
  secondary: 'bg-action-secondary-50 text-action-secondary-500',
  tertiary: 'bg-action-tertiary-50 text-action-tertiary-500',
});

const PlanTag = ({ variant = 'secondary', Icon, text }: Props) => {
  return (
    <div className={cx('rounded p-1 flex space-x-1 items-center', COLOR_MAP[variant])}>
      <Tooltip tooltipClass="text-center" id={text} text={text} showIcon={false} autoWidth>
        <Icon className="w-4 h-4" />
      </Tooltip>
    </div>
  );
};

export default PlanTag;
