import { AddableSettings } from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/AddableSettings';
import SectionRenderer from '@/routes/website/_components/DreamEditor/AttributesPanel/helpers/SectionRenderer';
import { darkenHexColor } from '@/routes/website/_components/DreamEditor/AttributesPanel/utils/darkenHexColor';

import { useNavbarContext } from '../../../NavbarContext';
import { BorderRadiusSettings } from '../general/BorderRadiusSettings';
import BorderSettings from '../general/BorderSettings';
import { ColorSettings } from '../general/ColorSettings';

export const NavDropdownItemHoverSettings = () => {
  const properties = {
    borderColor: 'hoverBorderColor',
    borderRadius: 'hoverBorderRadius',
    borderStyle: 'hoverBorderStyle',
    borderWidth: 'hoverBorderWidth',
    textColor: 'hoverTextColor',
    descriptionTextColor: 'hoverDescriptionTextColor',
    backgroundColor: 'hoverBackgroundColor',
    iconColor: 'hoverIconColor',
    iconBackgroundColor: 'hoverIconBackgroundColor',
  };

  const backgroundColorProperty = 'background';
  const defaultBackgroundColor = '#ffffff';
  const { selectedContent, onUpdateNodeAttributes } = useNavbarContext();
  if (!selectedContent || !selectedContent.attrs?.id) return null;
  if (selectedContent.type !== 'navbar_dropdown_item') return null;

  const hasHoverEffectBorderColor =
    selectedContent?.attrs?.[properties.borderColor as keyof typeof selectedContent.attrs];
  const hasHoverEffectBorderRadius =
    selectedContent?.attrs?.[properties.borderRadius as keyof typeof selectedContent.attrs];
  const hasHoverEffectTextColor = selectedContent?.attrs?.[properties.textColor as keyof typeof selectedContent.attrs];
  const hasHoverEffectBackgroundColor =
    selectedContent?.attrs?.[properties.backgroundColor as keyof typeof selectedContent.attrs];
  const hasHoverEffectDescriptionTextColor =
    selectedContent?.attrs?.[properties.descriptionTextColor as keyof typeof selectedContent.attrs];
  const hasHoverEffectIconBackgroundColor =
    selectedContent?.attrs?.[properties.iconBackgroundColor as keyof typeof selectedContent.attrs];
  const hasHoverEffectIconColor = selectedContent?.attrs?.[properties.iconColor as keyof typeof selectedContent.attrs];

  const currentBackgroundColor =
    (selectedContent?.attrs?.[backgroundColorProperty as keyof typeof selectedContent.attrs] as string) ||
    defaultBackgroundColor;
  const darkenedBackgroundColor = darkenHexColor(currentBackgroundColor, 20);

  const onAddHoverEffect = (property: string, value: string) => {
    if (!selectedContent || !selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { [property]: value });
  };

  const onRemoveHoverEffect = (property: string) => {
    if (!selectedContent || !selectedContent.attrs?.id) return;
    onUpdateNodeAttributes(selectedContent.attrs?.id, { [property]: undefined });
  };

  return (
    <SectionRenderer title="Hover Effect" collapsible>
      <AddableSettings
        title="Border"
        isEmpty={!hasHoverEffectBorderColor}
        onAdd={() => {
          onAddHoverEffect(properties.borderColor, darkenedBackgroundColor);
          onAddHoverEffect(properties.borderStyle, 'solid');
          onAddHoverEffect(properties.borderWidth, '1px');
        }}
        onRemove={() => {
          onRemoveHoverEffect(properties.borderColor);
          onRemoveHoverEffect(properties.borderStyle);
          onRemoveHoverEffect(properties.borderWidth);
        }}
      >
        <BorderSettings
          selectedContent={selectedContent}
          properties={{
            color: properties.borderColor,
            style: properties.borderStyle,
            width: properties.borderWidth,
          }}
        />
      </AddableSettings>

      <AddableSettings
        title="Radius"
        isEmpty={!hasHoverEffectBorderRadius}
        onAdd={() => onAddHoverEffect(properties.borderRadius, '8px')}
        onRemove={() => onRemoveHoverEffect(properties.borderRadius)}
      >
        <BorderRadiusSettings selectedContent={selectedContent} property={properties.borderRadius} />
      </AddableSettings>

      <AddableSettings
        title="Title"
        isEmpty={!hasHoverEffectTextColor}
        onAdd={() => onAddHoverEffect(properties.textColor, '#000000')}
        onRemove={() => onRemoveHoverEffect(properties.textColor)}
      >
        <ColorSettings
          selectedContent={selectedContent}
          title="Title"
          property={properties.textColor}
          defaultValue="#000000"
        />
      </AddableSettings>

      <AddableSettings
        title="Description"
        isEmpty={!hasHoverEffectDescriptionTextColor}
        onAdd={() => onAddHoverEffect(properties.descriptionTextColor, '#000000')}
        onRemove={() => onRemoveHoverEffect(properties.descriptionTextColor)}
      >
        <ColorSettings
          selectedContent={selectedContent}
          title="Description"
          property={properties.descriptionTextColor}
          defaultValue="#000000"
        />
      </AddableSettings>

      <AddableSettings
        title="Background"
        isEmpty={!hasHoverEffectBackgroundColor}
        onAdd={() => onAddHoverEffect(properties.backgroundColor, darkenedBackgroundColor)}
        onRemove={() => onRemoveHoverEffect(properties.backgroundColor)}
      >
        <ColorSettings
          selectedContent={selectedContent}
          title="Background"
          property={properties.backgroundColor}
          defaultValue={darkenedBackgroundColor}
        />
      </AddableSettings>

      {(selectedContent.attrs.style === 'icon' || selectedContent.attrs.style === 'featured') && (
        <>
          <AddableSettings
            title="Icon"
            isEmpty={!hasHoverEffectIconColor}
            onAdd={() => onAddHoverEffect(properties.iconColor, '#000000')}
            onRemove={() => onRemoveHoverEffect(properties.iconColor)}
          >
            <ColorSettings
              selectedContent={selectedContent}
              title="Icon"
              property={properties.iconColor}
              defaultValue="#000000"
            />
          </AddableSettings>
          <AddableSettings
            title="Icon Background"
            isEmpty={!hasHoverEffectIconBackgroundColor}
            onAdd={() => onAddHoverEffect(properties.iconBackgroundColor, darkenedBackgroundColor)}
            onRemove={() => onRemoveHoverEffect(properties.iconBackgroundColor)}
          >
            <ColorSettings
              selectedContent={selectedContent}
              title="Icon Background"
              property={properties.iconBackgroundColor}
              defaultValue={darkenedBackgroundColor}
            />
          </AddableSettings>
        </>
      )}
    </SectionRenderer>
  );
};
