import { useQuery } from 'react-query';

import { ApiKeyWithFolders } from '../../interfaces/api_key';
import api from '../../services/swarm';

const useMailchimpFolder = (publicationId: string, integrationId?: string) => {
  return useQuery<ApiKeyWithFolders>(
    ['mailchimp_folders', integrationId, publicationId],
    () =>
      api
        .get(`/integrations/${integrationId}/mailchimp_folders`, {
          params: {
            provider: 'mailchimp',
            assignable_id: publicationId,
            assignable_type: 'Publication',
          },
        })
        .then((res) => res.data),
    {
      enabled: !!publicationId && !!integrationId,
    }
  );
};

export default useMailchimpFolder;
