import { Extension } from '@tiptap/core';

import '@tiptap/extension-text-style';

import { getBooleanAttribute } from '../../utils/attributesUtils';

interface DeletableOptions {
  nodesToAddTokensAttributeTo: string[];
}

interface DeletableStorage {}

export const Deletable = Extension.create<DeletableOptions, DeletableStorage>({
  name: 'deletable',

  addOptions() {
    return {
      // Add to this list as needed.
      nodesToAddTokensAttributeTo: [
        'doc',
        'heading',
        'container',
        'columns',
        'paragraph',
        'section',
        'signup',
        'socials',
        'post',
        'survey',
        'authors',
        'recommendations',
        'tags',
        'pricing',
        'signupModal',
        'recommendationsModal',
        'loginModal',
        'login',
        'updatePassword',
        'resetPassword',
        'divider',
      ],
    };
  },

  addGlobalAttributes() {
    const allTypesToAddTokenAttributeTo = [...this.options.nodesToAddTokensAttributeTo];

    return [
      {
        types: allTypesToAddTokenAttributeTo,
        attributes: {
          deletable: getBooleanAttribute('deletable', 'data-deletable', true),
        },
      },
    ];
  },
});
