import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import analytics from '@/utils/analytics';

import { NewPublication } from '../../interfaces/publication';
import api from '../../services/swarm';

interface CreateAccountProps {
  onSuccess: () => void;
}
// When the user has no publications, this hook is used to create a new publication.
// The endpoint will also create an organization for the user if none exists.
const useCreateAccount = ({ onSuccess }: CreateAccountProps) => {
  return useMutation(
    (publication: NewPublication) =>
      api.post(`/publications/create_accounts`, {
        publication: { ...publication },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: (result: any) => {
        analytics.track('Publication Created', {
          id: result?.data?.publication?.id,
          organizationId: result?.data?.publication?.organization_id,
        });
        onSuccess?.();
        return result?.data?.publication;
      },
    }
  );
};

export default useCreateAccount;
