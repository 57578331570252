import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';

import { Button } from '../../_components/UI/Button';
import { Text } from '../../_components/UI/Text';
import { Tooltip } from '../../_components/UI/Tooltip';

interface Props {
  title: string;
  description: string;
  onNext: () => void;
  onPrevious?: () => void;
  isDisabled?: boolean;
  nextText?: string;
  isLoading?: boolean;
}

const Heading = ({ title, description, onNext, onPrevious, isDisabled, nextText = 'Next', isLoading }: Props) => {
  return (
    <div className="flex gap-2 justify-between items-center w-full">
      <div className="flex flex-col">
        <Text weight="semibold" variant="primary" size="2xl" as="h4" className="">
          {title}
        </Text>
        <Text weight="medium" variant="secondary" size="md" as="p" className="">
          {description}
        </Text>
      </div>

      <div className="flex gap-2">
        {onPrevious && <Button variant="ghost" Icon={ArrowLeft} onClick={onPrevious} />}

        {isDisabled ? (
          <Tooltip center="Select a template to continue">
            <Button
              variant="primary"
              RightIcon={ArrowRight}
              onClick={onNext}
              isDisabled={isDisabled}
              isLoading={isLoading}
            >
              {isLoading ? 'Building Site...' : nextText}
            </Button>
          </Tooltip>
        ) : (
          <Button
            variant="primary"
            RightIcon={ArrowRight}
            onClick={onNext}
            isDisabled={isDisabled}
            isLoading={isLoading}
          >
            {isLoading ? 'Building Site...' : nextText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Heading;
