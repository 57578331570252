import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { PencilSquareIcon } from '@heroicons/react/20/solid';

import { Accordion } from '@/components/Accordion';
import ActionModal from '@/components/ActionModal';
import { Color } from '@/components/DesignTokens';
import DmarcWarningBanner from '@/components/DmarcWarningBanner';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { Input } from '@/components/Form';
import { Typography } from '@/components/Typography';
import { useCustomDomainToggleUsage } from '@/hooks/useCustomDomains';
import useSmartWarmingProgress from '@/hooks/useSmartWarmingProgress';
import { useUpdateSubdomain } from '@/hooks/useSubdomain';
import { SubdomainPayload } from '@/hooks/useSubdomain/useUpdateSubdomain';
import { CustomDomain, CustomDomainTypes } from '@/interfaces/custom_domain';
import { EllipsisDropdownOption } from '@/interfaces/general';
import type { SmartWarmingProgressData } from '@/interfaces/smart_warming_progress';
import SmartWarmingFaqModal from '@/pages/Settings/Pages/Publication/Domains/SmartWarmingFaqModal';
import { Badge } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { usePublicationSettings } from '../../context';
import useVerificationModals from '../hooks/useVerificationModals';
import { isDomainVerified, isEntriPending, shouldShowBrandedLinkPing } from '../utils';

import { SECONDARY_EMAIL_HELPER_TEXT } from './constants';
import DisconnectDomainForm from './DisconnectDomainForm';
import SmartWarmingChart from './SmartWarmingChart';
import StatusBadge, { SmartWarmingBadge } from './StatusBadge';

interface Props {
  subdomain: string;
  customDomain: CustomDomain;
  onSharedWeb: boolean;
  disabled: boolean;
  publicationId: string;
}

const EmailCustom: React.FC<Props> = ({ subdomain, customDomain, onSharedWeb, publicationId, disabled }) => {
  const { settings } = usePublicationSettings();

  const {
    id,
    domain,
    branded_link_added: brandedLinkAdded,
    email_username: emailUsername,
    branded_link_cloudflare_verified: brandedLinkVerified,
    warming_up: warmingUp,
  } = customDomain;
  const entriVerificationPending = isEntriPending(customDomain, CustomDomainTypes.EMAIL);
  const isVerified = isDomainVerified(customDomain, CustomDomainTypes.EMAIL);
  const hasBrandedLinksFeature = settings?.custom_branded_links;

  const { handleOpenDnsRecordsModal } = useVerificationModals();
  const toggleUsageMutation = useCustomDomainToggleUsage(() => {
    handleOpenDnsRecordsModal(CustomDomainTypes.BRANDED_LINK);
  });

  const smartWarmingProgressQuery = useSmartWarmingProgress(publicationId, id, warmingUp);

  const currentEmailUsername = emailUsername || 'hi';
  const [newEmailUsername, setNewEmailUsername] = useState<string>(currentEmailUsername);
  const [newSubdomain, setNewSubdomain] = useState<string>(subdomain);
  const [editUsernameModalOpen, setEditUsernameModalOpen] = useState(false);
  const [editSubdomainModalOpen, setEditSubdomainModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [smartWarmingOpen, setSmartWarmingOpen] = useState<boolean>(false);
  const [smartWarmingFaqModalOpen, setSmartWarmingFaqModalOpen] = useState<boolean>(false);

  const { data: smartWarmingProgress, isLoading: smartWarmingProgressIsLoading } = smartWarmingProgressQuery;

  const { mutateAsync: updateSubdomain, isLoading: updateSubdomainIsLoading } = useUpdateSubdomain();

  const type = CustomDomainTypes.EMAIL;

  const handleConfigureBrandedLink = useCallback(() => {
    toggleUsageMutation.mutate({ id, type: CustomDomainTypes.BRANDED_LINK, enabled: true });
  }, [id, toggleUsageMutation]);

  const handleSaveUsername = async (data: SubdomainPayload) => {
    try {
      await updateSubdomain({
        ...data,
        custom_email_domain_id: id,
      });

      analytics.track('Configured email domain');

      toast.success('Saved!');
      setEditUsernameModalOpen(false);
      // eslint-disable-next-line no-empty
    } catch (error) { }
  };

  const dropdownOptions = useMemo(() => {
    const options: EllipsisDropdownOption[] = [];

    // TODO: [BEE-7083] Trigger Entri modal when verified
    if (!entriVerificationPending) {
      options.push({
        label: isVerified ? 'View DNS Records' : 'Verify',
        onClick: () => handleOpenDnsRecordsModal(type),
      });

      if (isVerified && hasBrandedLinksFeature && (!brandedLinkAdded || !brandedLinkVerified)) {
        options.push({
          label: brandedLinkAdded ? 'Verify Branded Link' : 'Configure Branded Link',
          onClick: handleConfigureBrandedLink,
          pinging: shouldShowBrandedLinkPing(customDomain)
        });
      }
    }

    return [
      ...options,
      {
        label: 'Disconnect Domain',
        onClick: () => setDeleteModalOpen(true),
        isDestructive: true,
      },
    ];
  }, [entriVerificationPending, isVerified, hasBrandedLinksFeature, brandedLinkAdded, brandedLinkVerified, handleOpenDnsRecordsModal, type, handleConfigureBrandedLink, customDomain]);

  useEffect(() => {
    setNewEmailUsername(emailUsername || 'hi');
    setNewSubdomain(subdomain);
  }, [currentEmailUsername, emailUsername, subdomain]);

  const formattedSmartWarmingProgress = useMemo(() => {
    if (!smartWarmingProgress?.data || !smartWarmingProgress?.labels) return undefined;

    return {
      labels: smartWarmingProgress.labels,
      data: smartWarmingProgress.data.map((item) => ({
        ...item,
        name: new Intl.DateTimeFormat(navigator.language || 'en-US', {
          month: 'short',
          day: 'numeric',
        }).format(new Date(item.name)),
      })),
    } satisfies SmartWarmingProgressData;
  }, [smartWarmingProgress]);

  return (
    <>
      <ActionModal
        resourceId={currentEmailUsername}
        isOpen={editUsernameModalOpen}
        onClose={() => setEditUsernameModalOpen(false)}
        isWorking={updateSubdomainIsLoading}
        onProceed={() => handleSaveUsername({ email_username: newEmailUsername })}
        headerText="Update Email Username"
      >
        <div className="my-4 space-y-4">
          <Input
            name="subdomain"
            labelText="New Email Username"
            trailingText={`@${customDomain.domain}`}
            value={newEmailUsername}
            className="flex-grow"
            onChange={(e) => setNewEmailUsername(e.target.value)}
          />
        </div>
      </ActionModal>

      <ActionModal
        resourceId={subdomain}
        isOpen={editSubdomainModalOpen}
        onClose={() => setEditSubdomainModalOpen(false)}
        isWorking={updateSubdomainIsLoading}
        onProceed={() => handleSaveUsername({ subdomain: newSubdomain })}
        headerText="Update Secondary Email Username"
      >
        <div className="my-4 space-y-4">
          <Input
            name="subdomain"
            labelText="New Secondary Email Username"
            trailingText="@mail.beehiiv.com"
            helperText={SECONDARY_EMAIL_HELPER_TEXT}
            value={newSubdomain}
            className="flex-grow"
            onChange={(e) => setNewSubdomain(e.target.value)}
          />

          {onSharedWeb && (
            <Typography token="font-normal/text/xs" color={Color.DANGER} as="div" className="mt-4">
              <b>NOTE:</b> Changing this will also update your web URL.
            </Typography>
          )}
        </div>
      </ActionModal>

      <DisconnectDomainForm
        customDomain={customDomain}
        type={type}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />

      <SmartWarmingFaqModal isOpen={smartWarmingFaqModalOpen} onClose={() => setSmartWarmingFaqModalOpen(false)} />

      <div className="p-4 bg-gray-50 border border-surface-200 rounded-lg space-y-4">
        <DmarcWarningBanner publicationId={publicationId} customDomain={customDomain} variant="compact" />
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-start space-x-2">
            <Badge size="sm">Email</Badge>
            <StatusBadge domain={customDomain} type={type} />
            {hasBrandedLinksFeature && <StatusBadge domain={customDomain} type={CustomDomainTypes.BRANDED_LINK} />}
          </div>
          {!disabled && <EllipsisDropdown options={dropdownOptions} />}
        </div>

        <div className="flex items-end space-x-4">
          <Input
            name="username"
            labelText="Email Username"
            trailingText={`@${domain}`}
            value={currentEmailUsername}
            className="flex-grow"
            readOnly
          />
          <Button
            variant="primary-inverse"
            onClick={() => setEditUsernameModalOpen(true)}
            Icon={PencilSquareIcon}
            className="h-[38] flex-shrink"
            disabled={disabled}
          >
            Edit
          </Button>
        </div>

        {warmingUp && (
          <div className="flex items-end space-x-4">
            <Input
              name="subdomain"
              labelText="Secondary Email Username"
              trailingText="@mail.beehiiv.com"
              value={subdomain}
              className="flex-grow"
              readOnly
            />
            <Button
              variant="primary-inverse"
              onClick={() => setEditSubdomainModalOpen(true)}
              Icon={PencilSquareIcon}
              className="h-[38] flex-shrink"
              disabled={disabled}
            >
              Edit
            </Button>
          </div>
        )}

        <div>
          <Input name="domain" labelText="Domain" value={domain} className="flex-grow" readOnly />
        </div>

        {warmingUp && (
          <div className="bg-white rounded-lg p-4 border border-surface-200">
            <Accordion
              defaultOpen={smartWarmingOpen}
              onClick={() => setSmartWarmingOpen(!smartWarmingOpen)}
              titleChildren={<SmartWarmingBadge />}
            >
              <div className="pt-4 space-y-4">
                <div>
                  <Typography token="font-medium/text/base" colorWeight="900" as="h3" className="mb-2">
                    Smart Warming
                  </Typography>

                  <Typography token="font-normal/text/sm" colorWeight="500" as="p">
                    We are gradually increasing the sending volume on <strong>{domain}</strong> to optimize
                    deliverability for you. This happens automatically in the background, so nothing will be impacted
                    for you. Depending on your publication&apos;s list size, this process typically takes 2-4 weeks to
                    complete.
                  </Typography>

                  <Typography
                    token="font-medium/text/sm"
                    colorWeight="600"
                    color="secondary"
                    className="underline cursor-pointer"
                  >
                    <button type="button" onClick={() => setSmartWarmingFaqModalOpen(true)}>
                      See FAQs about Smart Warming
                    </button>
                  </Typography>
                </div>

                <SmartWarmingChart data={formattedSmartWarmingProgress} isLoading={smartWarmingProgressIsLoading} />
              </div>
            </Accordion>
          </div>
        )}
      </div>
    </>
  );
};

export default EmailCustom;
