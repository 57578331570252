import { Navigate } from 'react-router-dom';
import { GearSix, House } from '@phosphor-icons/react';

import { WebsiteProvider } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';

import { ContentTree } from './_components/ContentTree';
import { ContentTreeProvider } from './_components/ContentTree/context';
import { Layout } from './_components/Layout';
import { Main } from './_components/Main';
import { Breadcrumbs } from './_components/Main/Breadcrumbs';
import { PageCard } from './_components/Main/PageCard';
import SiteToggle from './_components/Main/SiteToggle';
import { TopBar } from './_components/Main/TopBar';
import { NavMenuItem } from './_components/SideNav/NavMenuItem';
import { NavSection } from './_components/SideNav/NavSection';
import { NavSectionTitle } from './_components/SideNav/NavSectionTitle';
import useSetPages from './_hooks/useSetPages';

const WebsiteDashboardPage = () => {
  const { data: currentPublication } = useCurrentPublication();
  const { pages, pageRoutes, defaultRoutes } = useSetPages();
  const homePage = pages?.find((page) => page.draft_page_version?.name === 'Home');

  if (homePage) {
    return <Navigate to={`/website_builder_v2/page/${homePage.id}`} />;
  }

  if (defaultRoutes?.page_id) {
    return <Navigate to={`/website_builder_v2/page/${defaultRoutes?.page_id}`} />;
  }

  // Leaving this here for now, incase we want to come back to using a dashboard
  // After several conversations with the team, we've decided to move forward with directing the user
  // Straight into the editor.
  return (
    <Layout
      logoDropdownProps={{
        actionText: 'Back to Main App',
        backPath: '/',
      }}
      sidenavChildren={
        <>
          <NavSection>
            <NavSectionTitle title="Main" />
            <NavMenuItem Icon={House} title="Home" to="/website_builder_v2" />
            <NavMenuItem Icon={GearSix} title="Site Settings" to="/website_builder_v2/settings" />
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Default Pages" />
            {defaultRoutes && (
              <ContentTreeProvider
                dndDisabledErrorMessage="You can't re-arrange default pages"
                isAllowDnD={false}
                isShowOptions
                isShowAddPage
                pageLinkPrefix="/website_builder_v2/page"
              >
                <ContentTree showHome={false} route={defaultRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Custom Pages" />
            {pageRoutes && (
              <ContentTreeProvider isAllowDnD isShowOptions isShowAddPage pageLinkPrefix="/website_builder_v2/page">
                <ContentTree route={pageRoutes} parentPath={[]} slug="" />
              </ContentTreeProvider>
            )}
          </NavSection>
        </>
      }
    >
      <Main>
        <Breadcrumbs />
        <TopBar showDropdown />
        <SiteToggle
          title={currentPublication?.name || ''}
          usesDreamBuilderSite={currentPublication?.uses_dream_builder_site || false}
        />
        <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
          {pages?.map((page) => {
            return <PageCard key={page.id} page={page} />;
          })}
        </div>
      </Main>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <WebsiteDashboardPage />
  </WebsiteProvider>
);
