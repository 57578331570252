import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import VideoModal from '@/components/Modal/VideoModal';
import { Typography, TypographyStack } from '@/components/Typography';
import { useTutorial } from '@/hooks/useTutorials';
import useCreateSvixApplication from '@/hooks/webhooks/UseCreateSvixApplication';
import useSvixEndpoints from '@/hooks/webhooks/useSvixEndpoints';
import { Publication } from '@/interfaces/publication';
import { TutorialType } from '@/interfaces/tutorial';
import Teaser from '@/pages/Settings/Pages/Integrations/Webhooks/Teaser';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import BodyContainer from '../../_components/BodyContainer';
import CardHeader from '../../_components/CardHeader';
import { usePublicationSettings } from '../context';

import EndpointListItem from './EndpointListItem';

interface Props {
  publication: Publication;
}

const Endpoints = ({ publication }: Props) => {
  const svixConfigured = !!publication.svix_application_id;
  const navigate = useNavigate();
  const { settings } = usePublicationSettings();
  const tutorial = useTutorial(TutorialType.WEBHOOKS);
  const queryClient = useQueryClient();
  const {
    data: endpointsData,
    isLoading: endpointsIsLoading,
    isError: endpointsIsError,
  } = useSvixEndpoints(publication.id, svixConfigured);
  const endpoints = endpointsData?.pages.flatMap((page) => page.endpoints) || [];

  const [showVideoTutorialModal, setShowVideoTutorialModal] = useState(false);

  const { mutate: createSvixApplication, isLoading } = useCreateSvixApplication(publication.id, {
    onSuccess: () => {
      toast.success('Webhooks account created successfully');
      queryClient.invalidateQueries(['publications', publication.id], { exact: true });
      navigate('/settings/publication/webhooks/new');
    },
    onError: (errPayload) => {
      const errors = errPayload?.response?.data?.message || 'Something went wrong';
      toast.error(errors);
    },
  });

  const handleCreateSvixApplication = () => {
    analytics.track('Created a Webhook Event');
    createSvixApplication();
  };

  if (!settings?.webhooks) {
    return <Teaser />;
  }

  return (
    <LoadingBox isLoading={endpointsIsLoading && svixConfigured} isError={endpointsIsError}>
      {tutorial ? (
        <VideoModal
          title=""
          src={tutorial.url}
          isOpen={showVideoTutorialModal}
          onClose={() => setShowVideoTutorialModal(false)}
        />
      ) : null}
      <BodyContainer>
        {endpoints.length === 0 ? (
          <>
            <CardHeader
              title="Webhooks"
              description={
                <TypographyStack>
                  <Typography token="font-normal/text/sm" colorWeight="500">
                    Get events in realtime with webhooks. They are a way for us to send you messages when things happen
                    in your account. You can use them to trigger actions in other systems too.
                  </Typography>
                  <button type="button" onClick={() => setShowVideoTutorialModal(true)} className="w-fit">
                    <Typography token="font-medium/text/sm" colorWeight="500" color="secondary">
                      Learn more about webhooks
                    </Typography>
                  </button>
                </TypographyStack>
              }
            />
            <Button
              size="xs"
              className="w-fit"
              onClick={
                svixConfigured ? () => navigate('/settings/publication/webhooks/new') : handleCreateSvixApplication
              }
              disabled={isLoading}
              disableWith="Create Webhook Endpoint..."
            >
              {svixConfigured ? 'Create Webhook Endpoint' : 'Get Started'}
            </Button>
          </>
        ) : null}
        {endpoints.length > 0 ? (
          <div className="flex flex-col gap-y-5">
            <div className="flex flex-row justify-between items-center">
              <CardHeader title="Webhook Endpoints" />
              <Button
                size="xs"
                className="w-fit"
                onClick={() => navigate('/settings/publication/webhooks/new')}
                disabled={isLoading}
                disableWith="Create Webhook Endpoint..."
              >
                Create Webhook Endpoint
              </Button>
            </div>
            {endpoints.map((endpoint) => (
              <EndpointListItem publicationId={publication.id} endpoint={endpoint} />
            ))}
          </div>
        ) : null}
      </BodyContainer>
    </LoadingBox>
  );
};

export default Endpoints;
