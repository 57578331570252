import { useCurrentPublication, useUpdatePublication } from '@/hooks/usePublications';
import BetaBadge from '@/ui/BetaBadge';

import { Button } from '../UI/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../UI/Dialog';
import { Text } from '../UI/Text';

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const DialogRevertV1 = ({ isOpen, setIsOpen }: Props) => {
  const { data: currentPublication } = useCurrentPublication();
  const publicationMutation = useUpdatePublication(currentPublication?.id || '');


  const handleRevertV1 = () => {
    publicationMutation.mutate({ uses_dream_builder_site: false });
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="w-full max-w-md flex flex-col overflow-hidden p-4 gap-2">
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2">
              <Text size="lg" weight="semibold" variant="primary" as="h4">
                Revert to Website V1
              </Text>
              <BetaBadge />
            </div>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-2 py-2">
          <Text size="sm" weight="regular" as="p" variant="secondary">
            This will switch your site back to the previously supported version, which is editable from the old site
            builder.
          </Text>
        </div>
        <DialogFooter className="pt-4">
          <Button variant="primary" onClick={handleRevertV1}>
            Revert to V1
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DialogRevertV1;
