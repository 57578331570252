import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CaretRight, CoinVertical, Image, Trash } from '@phosphor-icons/react';
import { StyleSheetManager } from 'styled-components';

import { MediaLibrary } from '@/components/MediaLibrary';
import { OnMediaPayload } from '@/components/MediaLibrary/MediaLibrary.types';
import { useFileUpload } from '@/components/TiptapEditor/extensions/ImageUpload/view/hooks';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { Asset } from '@/interfaces/asset';
import { cn } from '@/utils/cn';

import { Button } from '../../../UI/Button';
import { ColorPicker } from '../../../UI/ColorPicker';
import { SimpleInputWrapper } from '../../../UI/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/Popover';
import { Text } from '../../../UI/Text';
import { ToggleGroup, ToggleGroupItem } from '../../../UI/ToggleGroup';
import { useUploader } from '../../hooks/useUploader';
import { AttributeSettingProps } from '../types';

import GradientSettings from './GradientSettings';
import { SliderSettings } from './SliderSettings';
import { TokenizeColorSettings } from './TokenizeColorSettings';

const DEFAULT_COLOR = '#000000FF';

const removeOpacityFromColor = (color: string) => {
  if (color?.length === 9) {
    return color.slice(0, 7);
  }

  return color;
};

export const BackgroundSettings = ({ editor, activeNodeResult, propertyPrefix }: AttributeSettingProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;
  const hasTokenAttribute = activeNodeAttributes?.tokens;
  const isTokenApplied = activeNodeAttributes?.tokens?.backgroundColor;

  const { backgroundColor, backgroundVideo, backgroundImage } = useMemo(
    () => activeNodeAttributes,
    [activeNodeAttributes]
  );

  const determineViewType = () => {
    if (backgroundImage) return 'media';
    if (backgroundColor?.includes('gradient')) return 'gradient';
    if (backgroundColor) return 'color';
    return 'color';
  };

  const [currentPublicationId] = useCurrentPublicationState();
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const [viewType, setViewType] = useState<'gradient' | 'color' | 'media'>(determineViewType());
  const [mediaUrl, setMediaUrl] = useState(backgroundImage || '');

  useEffect(() => {
    setViewType(determineViewType());
  }, [activeNodePos]);

  const [lastValues, setLastValues] = useState<{
    gradient?: string;
    color?: string;
    media?: string;
  }>({});

  useEffect(() => {
    setLastValues({});
  }, [activeNodePos]);

  const onSetBackgroundImage = useCallback(
    (url: string | null) => {
      setLastValues((prev) => ({ ...prev, media: url || undefined }));
      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'backgroundImage', url);
          return true;
        })
        .run();
      setMediaUrl(url || '');
    },
    [editor, activeNodePos]
  );

  const onUpload = useCallback(
    (asset: Asset) => {
      onSetBackgroundImage(asset.url);
    },
    [onSetBackgroundImage]
  );

  const { uploadFile, loading } = useUploader({ publicationId: currentPublicationId, onUpload });
  const { handleUploadClick, ref: fileInputRef } = useFileUpload();

  const handleUpload = useCallback(
    (payload: OnMediaPayload) => {
      onSetBackgroundImage(payload.media.url);
    },
    [onSetBackgroundImage]
  );

  const handleDelete = useCallback(() => {
    onSetBackgroundImage(null);
  }, [onSetBackgroundImage]);

  const handleMediaURLChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newUrl = event.target.value;
      onSetBackgroundImage(newUrl);
    },
    [onSetBackgroundImage]
  );

  const [color, setColor] = useState<string>(backgroundColor || DEFAULT_COLOR);

  const onSetColor = useCallback(
    (value: string | null) => {
      if (value) setLastValues((prev) => ({ ...prev, color: value }));
      editor
        .chain()
        .command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, 'backgroundColor', value || DEFAULT_COLOR);
          return true;
        })
        .run();
    },
    [activeNodePos, editor]
  );

  const handleChangeViewType = (type: 'gradient' | 'color' | 'media') => {
    const lastValue = lastValues[type];
    if (lastValue) {
      if (type === 'gradient' || type === 'color') {
        editor
          .chain()
          .command(({ tr }) => {
            tr.setNodeAttribute(activeNodePos, 'backgroundColor', lastValue || DEFAULT_COLOR);
            return true;
          })
          .run();
        editor
          .chain()
          .command(({ tr }) => {
            tr.setNodeAttribute(activeNodePos, 'backgroundImage', null);
            return true;
          })
          .run();
      } else {
        editor
          .chain()
          .command(({ tr }) => {
            tr.setNodeAttribute(activeNodePos, 'backgroundColor', null);
            return true;
          })
          .run();
        editor
          .chain()
          .command(({ tr }) => {
            tr.setNodeAttribute(activeNodePos, 'backgroundImage', lastValue);
            return true;
          })
          .run();
      }
    }
    setViewType(type);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex items-center justify-stretch gap-2">
          <Text className="w-[80px]" variant="secondary" size="2xs" weight="medium">
            Background
          </Text>
          <div className="grow bg-wb-secondary rounded-lg shadow-sm cursor-pointer relative">
            {isTokenApplied && (
              <div className="p-0.5 border rounded-full absolute -left-1 top-0 bg-wb-accent-soft text-wb-accent border-wb-accent">
                <CoinVertical className="w-2 h-2" />
              </div>
            )}
            <div className="w-full justify-between flex items-center gap-2 p-2">
              <div className="flex items-center gap-1">
                {backgroundImage ? (
                  <Image className="text-wb-secondary" weight="bold" />
                ) : (
                  <div style={{ background: removeOpacityFromColor(backgroundColor) }} className="w-4 h-4 rounded-md" />
                )}
                <Text size="2xs" weight="medium">
                  Background
                </Text>
              </div>
              <CaretRight className="text-wb-secondary" weight="bold" />
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[290px] p-4 overflow-y-auto no-scrollbar" align="center" side="left" sideOffset={20}>
        <div className="max-h-[700px]  flex flex-col gap-4">
          <div className="flex items-center">
            <Text size="sm" weight="semibold">
              Background
            </Text>
          </div>

          <div className="grow flex items-center">
            <SimpleInputWrapper className="h-[38px] px-1 relative">
              <ToggleGroup
                className="py-[1px] w-full"
                type="single"
                defaultValue={viewType}
                onValueChange={(type) => handleChangeViewType(type as 'gradient' | 'color' | 'media')}
              >
                <ToggleGroupItem asChild value="media">
                  <Text variant="secondary" size="2xs" weight="medium" className="grow">
                    Media
                  </Text>
                </ToggleGroupItem>
                <ToggleGroupItem asChild value="color">
                  <Text variant="secondary" size="2xs" weight="medium" className="grow">
                    Color
                  </Text>
                </ToggleGroupItem>
                <ToggleGroupItem asChild value="gradient">
                  <Text variant="secondary" size="2xs" weight="medium" className="grow">
                    Gradient
                  </Text>
                </ToggleGroupItem>
              </ToggleGroup>
            </SimpleInputWrapper>
          </div>

          {viewType === 'color' && (
            <div className={cn('max-h-full flex flex-col gap-4')}>
              <ColorPicker
                selectedColor={color}
                onSetColor={(value: string | null) => {
                  if (!value) {
                    setColor(DEFAULT_COLOR);
                    onSetColor(null);
                    return;
                  }

                  setColor(value);
                  onSetColor(value);
                }}
              />

              {hasTokenAttribute && (
                <TokenizeColorSettings
                  editor={editor}
                  activeNodeResult={activeNodeResult}
                  property={propertyPrefix || 'backgroundColor'}
                  title="Tokens"
                />
              )}
            </div>
          )}
          {viewType === 'gradient' && (
            <div className={cn('h-full flex flex-col gap-4')}>
              <GradientSettings
                editor={editor}
                activeNodeResult={activeNodeResult}
                property="backgroundColor"
                onSetLastValue={(value) => setLastValues((prev) => ({ ...prev, gradient: value }))}
              />
            </div>
          )}

          {viewType === 'media' && (
            <div className={cn('flex flex-col gap-4')}>
              <div className="w-full min-w-0 flex justify-center items-center relative ">
                <StyleSheetManager target={document.head}>
                  <MediaLibrary
                    isOpen={showImageLibrary}
                    onClose={() => setShowImageLibrary(false)}
                    publicationId={currentPublicationId}
                    onMediaSelect={handleUpload}
                  />
                </StyleSheetManager>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="h-0 opacity-0 overflow-hidden w-0"
                  accept=".jpg,.jpeg,.png,.webp,.gif"
                  onChange={(e) => (e.target.files ? uploadFile(e.target.files[0]) : null)}
                />
                <div className="w-full h-[150px] bg-wb-secondary rounded-lg shadow-sm flex flex-col gap-2 items-center justify-center overflow-hidden relative">
                  {backgroundImage && (
                    <img src={backgroundImage} alt="background" className="w-full h-full object-contain" />
                  )}

                  <div
                    className={cn(
                      'absolute top-0 left-0 w-full h-full flex flex-col gap-2 items-center justify-center bg-wb-overlay',
                      backgroundImage || backgroundVideo ? 'opacity-0 hover:opacity-100' : 'opacity-100'
                    )}
                  >
                    <Button size="sm" variant="secondary" onClick={handleUploadClick} isLoading={loading}>
                      Upload an Image
                    </Button>

                    <Text size="2xs" weight="medium" variant="on-accent">
                      OR
                    </Text>

                    <Button size="sm" variant="secondary" onClick={() => setShowImageLibrary(true)} isLoading={loading}>
                      Use from library
                    </Button>
                    <div className="absolute top-3 right-3 cursor-pointer hover:bg-wb-secondary/50 rounded-lg p-1">
                      <Trash className="text-wb-secondary hover:text-wb-danger" weight="bold" onClick={handleDelete} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center justify-center">
                <Text size="xs" weight="medium" variant="secondary">
                  OR
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <Text size="2xs" variant="secondary" weight="medium" className="w-[100px]">
                    Media URL
                  </Text>
                  <input
                    className="outline-none bg-wb-secondary rounded-lg shadow-sm p-2 border-transparent border focus:outline-none w-full text-xs"
                    placeholder="https://google.com"
                    value={mediaUrl}
                    onChange={handleMediaURLChange}
                  />
                </div>
              </div>
              <hr />
              <SliderSettings
                editor={editor}
                activeNodeResult={activeNodeResult}
                property="backgroundDarken"
                title="Darkened"
                unit=""
                min={0}
                max={1}
                step={0.1}
                type="number"
              />
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
