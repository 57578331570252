import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import cx from 'classnames';

import { Typography } from '@/components/Typography';
import { useAuth } from '@/context/auth-context';
import { useCurrentUser } from '@/context/current-user-context';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

export const UserDropdown = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const { logout } = useAuth();
  const handleLogout = () => {
    analytics.track('Signed Out');
    logout.mutateAsync().then(() => navigate('/'));
  };

  const hasPublicationSettingsV2 = Boolean(currentUser?.has_settings_v2);

  const userBlock = (
    <>
      <div className="flex text-start flex-row justify-between items-between w-full">
        <Typography as="span" token="font-medium/text/sm" className="w-full">
          <span className="text-gray-500 w-full truncate">{currentUser?.fullName}</span>
        </Typography>
      </div>
      <div className="flex w-full truncate text-gray-400">
        <Typography as="span" token="font-normal/text/sm">
          <span className="text-gray-400">{currentUser?.email}</span>
        </Typography>
      </div>
    </>
  );

  return (
    <Menu as="div" className="relative my-auto h-full justify-center items-center inline-block">
      <Menu.Button className="h-full my-auto">
        <div className="rounded-full ring-2 ring-offset-2 ring-gray-50 hover:shadow-sm my-auto bg-surface-100 w-6 h-6 overflow-hidden hover:bg-surface-200">
          <img src={currentUser?.profile_picture.url} alt="avatar" className="object-cover w-full" />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={cx(
            'z-50 absolute mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            'right-0',
            hasPublicationSettingsV2 ? 'w-64' : 'w-52'
          )}
        >
          <div className="p-2 flex flex-col space-y-1 truncate">
            <div className="w-full truncate text-gray-400">
              <div className="w-full group flex flex-col justify-start space-y-1 p-2">{userBlock}</div>
            </div>
          </div>

          <div className="p-2 w-full flex flex-col space-y-2">
            <Menu.Item>
              <Button
                onClick={handleLogout}
                type="button"
                variant="flush"
                size="sm"
                className="flex flex-col text-start justify-start items-start "
              >
                <Typography token="font-normal/text/sm" className="w-full">
                  <span className="text-gray-700 w-full">Logout</span>
                </Typography>
              </Button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
