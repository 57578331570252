import { useRef } from 'react';
import { NavbarSerializableNode, SerializableNode, Serializer } from '@shared/dream-components';

import BrowserBar from '@/components/PreviewModalV2/BrowserBar';
import { cn } from '@/utils/cn';

import { SimpleFooterPreviewer } from '../../footer/_components/FooterEditor/SimpleFooterPreviewer';
import { SimpleNavPreviewer } from '../../navbar/_components/NavbarEditor/SimpleNavPreviewer';
import IframeWrapper from '../../page/_components/Iframe';
import { useContainerRef } from '../../page/_hooks';
import { useFontHrefsFromContent } from '../../page/_hooks/useFontHrefs';
import { VIEWPORTS } from '../../page/constants';

interface Props {
  navbarContent?: NavbarSerializableNode;
  content?: SerializableNode;
  footerContent?: SerializableNode;
  hasBrowserBar?: boolean;
  containerHeightClass?: string;
  containerRoundedClass?: string;
  viewPortWidth?: number;
  additionalFontHrefs?: Set<string>;
  enableScroll?: boolean;
  centerAndZoom?: boolean;
  disableMemo?: boolean;
  allowClicking?: boolean;
}

const TemplatePreviewer = ({
  content,
  hasBrowserBar = true,
  containerHeightClass = 'h-[200px]',
  containerRoundedClass = 'rounded-xl',
  navbarContent,
  footerContent,
  viewPortWidth = VIEWPORTS[0].width,
  additionalFontHrefs = new Set(),
  enableScroll = false,
  centerAndZoom = false,
  disableMemo = false,
  allowClicking = true,
}: Props) => {
  const { rect: previewRect, containerRef: previewContainerRef } = useContainerRef({
    enabled: true,
  });
  const fontHrefs = useFontHrefsFromContent(content as any);
  const mergedFontHrefs = new Set([...Array.from(fontHrefs), ...Array.from(additionalFontHrefs)]);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const portalRef = useRef<HTMLDivElement>(null);

  const handleInteraction = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  if (enableScroll) {
    return (
      <div
        className={cn(
          VIEWPORTS[0].type === 'desktop' ? 'w-full' : 'max-w-md',
          'm-auto flex flex-col border-surface-200 shadow-xl border border-solid rounded-xl overflow-clip',
          containerHeightClass
        )}
        ref={previewContainerRef}
      >
        {hasBrowserBar && <BrowserBar screenSize="desktop" />}
        <IframeWrapper
          width={previewRect.width || 0}
          height={(previewRect.height || 0) - (hasBrowserBar ? 40 : 0)}
          contentWidth={viewPortWidth}
          fontHrefs={mergedFontHrefs}
          ref={iframeRef}
          disableMemo={disableMemo}
          allowClicking={allowClicking}
        >
          {navbarContent && <SimpleNavPreviewer portalRef={portalRef} iframeRef={iframeRef} content={navbarContent} />}
          {centerAndZoom && (
            <div className="flex flex-col justify-center items-center w-full scale-110">
              {content && <Serializer rootClassName="flex flex-col justify-center items-center h-fit" node={content} />}
            </div>
          )}
          {content && !centerAndZoom && (
            <Serializer rootClassName="flex flex-col justify-center items-center h-fit" node={content} />
          )}
          {footerContent && <SimpleFooterPreviewer content={footerContent} />}
          <div ref={portalRef} id="portal-ref" className="z-50" />
        </IframeWrapper>
      </div>
    );
  }

  return (
    <div
      className={cn(
        'p-4 shadow-xl overflow-clip border-surface-200 border-solid border-b max-w-full max-h-full',
        containerRoundedClass
      )}
    >
      <div
        className={cn(
          VIEWPORTS[0].type === 'desktop' ? 'w-full' : 'max-w-md',
          'h-full m-auto flex flex-col overflow-clip  pointer-events-none',
          containerHeightClass
        )}
        onClick={handleInteraction}
        onKeyDown={handleInteraction}
        role="none"
        ref={previewContainerRef}
      >
        {hasBrowserBar && <BrowserBar screenSize="desktop" />}
        <IframeWrapper
          width={previewRect.width || 0}
          height={previewRect.height || 0}
          contentWidth={viewPortWidth}
          fontHrefs={mergedFontHrefs}
          ref={iframeRef}
          disableMemo={disableMemo}
        >
          {navbarContent && <SimpleNavPreviewer portalRef={portalRef} iframeRef={iframeRef} content={navbarContent} />}
          {centerAndZoom && (
            <div className="flex flex-col justify-center items-center w-full scale-110">
              {content && <Serializer rootClassName="flex flex-col justify-center items-center h-fit" node={content} />}
            </div>
          )}
          {content && !centerAndZoom && (
            <Serializer rootClassName="flex flex-col justify-center items-center h-fit" node={content} />
          )}
          {footerContent && <SimpleFooterPreviewer content={footerContent} />}
          <div ref={portalRef} id="portal-ref" className="z-50" />
        </IframeWrapper>
      </div>
    </div>
  );
};

export default TemplatePreviewer;
