import { useSearchParams } from 'react-router-dom';
import { AuthorsElement } from '@shared/dream-components';
import { NodeViewProps } from '@tiptap/core';
import { useReactNodeView } from '@tiptap/react';

import DynamicContent from '../../components/DynamicContent';
import NotConnected from '../../components/NotConnected';
import { getDataAttributes } from '../../utils';

export const AuthorsView = ({ HTMLAttributes, node, editor, getPos }: NodeViewProps) => {
  const { onDragStart } = useReactNodeView();
  const [, setSearchParams] = useSearchParams();
  const { hasFakeData, isAuthorPageHeader } = node.attrs;

  const handleOpenSelectionModal = () => {
    editor.commands.command(({ tr }) => {
      tr.setNodeAttribute(getPos(), 'insertedFromSidebar', true);
      return true;
    });
  };

  const handleClick = () => {
    setSearchParams({ learn_more: 'dynamic_author_page' });
  };

  return (
    <AuthorsElement
      element={{
        type: 'authors',
        attrs: {
          id: node.attrs.id,
          ...node.attrs,
        },
        content: [{ id: 'text', text: '' }],
      }}
      attributes={{
        'data-node-view-wrapper': '',
        ...getDataAttributes(HTMLAttributes),
      }}
      onDragStart={onDragStart}
    >
      {isAuthorPageHeader && <DynamicContent title="Dynamic Author Page" onClick={handleClick} />}
      {hasFakeData && !isAuthorPageHeader && (
        <NotConnected text="Not connected to real authors" onClick={handleOpenSelectionModal} />
      )}
      {null}
    </AuthorsElement>
  );
};
