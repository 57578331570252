/* eslint-disable no-console */
import { NodeSelection } from '@tiptap/pm/state';
import { Editor, JSONContent } from '@tiptap/react';

// Helper to debug copy behavior
const debugProseMirrorCopy = (editor: Editor | null): (() => void) => {
  if (!editor) return () => {};

  const handleClipboardEvent = (e: ClipboardEvent) => {
    console.group('ProseMirror Copy Debug');

    // Log the current selection
    const { selection } = editor.state;
    console.log('Selection type:', selection.constructor.name);
    if (selection instanceof NodeSelection) {
      console.log('Selected node:', {
        type: selection.node.type.name,
        attrs: selection.node.attrs,
        content: selection.node.content.size ? 'Has content' : 'Empty',
        marks: selection.node.marks.map((m) => m.type.name),
      });
    }

    // Log clipboard data
    const { clipboardData } = e;
    console.log('Clipboard formats:', clipboardData?.types);

    // Log the slice that ProseMirror will copy
    const slice = editor.state.selection.content();
    console.log('Copied slice:', {
      size: slice.size,
      content: slice.content,
      openStart: slice.openStart,
      openEnd: slice.openEnd,
    });

    // Monitor DOM copy content
    // get the html content of the clipboard data
    const htmlContent = e.clipboardData?.getData('text/html');
    console.log('HTML Content:', htmlContent);

    console.groupEnd();
  };

  editor.view.dom.addEventListener('copy', handleClipboardEvent);

  return () => editor.view.dom.removeEventListener('copy', handleClipboardEvent);
};

const getPlaceholderContentWithDefaultNode = (defaultNode: string): JSONContent => {
  return {
    type: 'doc',
    content: [
      {
        type: defaultNode,
      },
    ],
  };
};

export { debugProseMirrorCopy, getPlaceholderContentWithDefaultNode };
