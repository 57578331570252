import { CSSProperties, useCallback, useMemo } from 'react';
import { Editor } from '@tiptap/core';

import type { WeightType } from '../../../UI/FontWeightSelection';
import FontWeightSelection from '../../../UI/FontWeightSelection';
import { AttributeSettingProps } from '../types';

const fontWeights = [
  {
    label: '900',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontWeight === '900';
    },
    style: {
      fontWeight: '900',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontWeight('900').focus().run();
    },
  },
  {
    label: '800',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontWeight === '800';
    },
    style: {
      fontWeight: '800',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontWeight('800').focus().run();
    },
  },
  {
    label: '700',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontWeight === '700';
    },
    style: {
      fontWeight: '700',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontWeight('700').focus().run();
    },
  },
  {
    label: '600',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontWeight === '600';
    },
    style: {
      fontWeight: '600',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontWeight('600').focus().run();
    },
  },
  {
    label: '500',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontWeight === '500';
    },
    style: {
      fontWeight: '500',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontWeight('500').focus().run();
    },
  },
  {
    label: '400',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontWeight === '400';
    },
    style: {
      fontWeight: '400',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontWeight('400').focus().run();
    },
  },
  {
    label: '300',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontWeight === '300';
    },
    style: {
      fontWeight: '300',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontWeight('300').focus().run();
    },
  },
  {
    label: '200',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontWeight === '200';
    },
    style: {},
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontWeight('200').focus().run();
    },
  },
  {
    label: '100',
    isSelected: (editor: Editor) => {
      const attributes = editor.getAttributes('textStyle');
      return attributes.fontWeight === '100';
    },
    style: {
      fontWeight: '200',
    },
    handleClick: (editor: Editor) => {
      editor.chain().selectTextBlock().setFontWeight('100').focus().run();
    },
  },
] as Array<{
  label: WeightType;
  isSelected: (editor: Editor) => boolean;
  style: Record<string, string>;
  handleClick: (editor: Editor) => void;
}>;

type FontWeightSettingsProps = AttributeSettingProps & {
  isMarkStyle?: boolean;
};

export const FontWeightSettings = ({
  editor,
  activeNodeResult,
  property,
  isMarkStyle = true,
}: FontWeightSettingsProps) => {
  const { activeNodePos, activeNodeAttributes } = activeNodeResult;

  const selectedFontWeight = useMemo(() => {
    if (isMarkStyle) {
      return fontWeights.find((fontWeight) => fontWeight.isSelected(editor));
    }
    if (property && activeNodeAttributes[property]) {
      const weight = activeNodeAttributes[property] as number;
      return fontWeights.find((fontWeight) => fontWeight.label === weight.toString());
    }
    return undefined;
  }, [activeNodeAttributes, isMarkStyle, property, editor]);

  const handleChange = useCallback(
    (w: CSSProperties['fontWeight']) => {
      if (isMarkStyle) {
        editor
          .chain()
          .selectTextBlock()
          .setFontWeight(w as string)
          .focus()
          .run();
      } else if (property) {
        editor.commands.command(({ tr }) => {
          tr.setNodeAttribute(activeNodePos, property, w as string);
          return true;
        });
      } else {
        console.error('property is required when isMarkStyle is false');
      }
    },
    [editor, activeNodePos, property, isMarkStyle]
  );

  return (
    <FontWeightSelection
      key={`${activeNodeResult.activeNodePos}-${selectedFontWeight?.label}`}
      defaultValue={selectedFontWeight?.label}
      onChange={handleChange}
    />
  );
};
