import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';

import api from '../../services/swarm';

interface UseSwapPackagesProps {
  newSitePackageId: string;
  siteId: string;
  onSuccess?: () => void;
}

const useSwapPackages = ({ newSitePackageId, siteId, onSuccess }: UseSwapPackagesProps) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    ({ themeParams }: { themeParams: Record<string, any> }) =>
      api.patch(`/sites/${siteId}/package_swap/${newSitePackageId}`, {
        publication_id: currentPublicationId,
        theme_params: themeParams,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.message || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['site_themes']);
      },
    }
  );
};

export default useSwapPackages;
