import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { UpsellPage } from '@/components/UpsellPage';
import { useSettings } from '@/context/settings-context';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import useUpgradeIntent from '@/hooks/useUpgradeIntent';
import { IntentAction } from '@/interfaces/upgrades';
import { PLAN } from '@/utils/plans';

interface Props {
  planToUpgradeTo?: PLAN;
  showEnabledToast?: boolean;
}

const Teaser = ({ planToUpgradeTo = PLAN.SCALE, showEnabledToast = true }: Props) => {
  const publicationId = useCurrentPublicationId();
  const { pushDataLayerEvent } = useUpgradeIntent({ publicationId, intentAction: IntentAction.USE_BOOSTS_GROW });
  const { settings } = useSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (settings?.boosts && showEnabledToast) {
      toast.success('Boosts are now enabled.');
      navigate('/grow/boosts');
    }
  }, [settings, navigate, showEnabledToast]);

  return (
    <UpsellPage
      plan={planToUpgradeTo}
      title="Introducing Boosts"
      description="The easiest and most cost-effective way to grow your email newsletter, fast. Guaranteed to be your #1 performing paid acquisition channel for new subscribers. No confusing setup, no APIs needed, and absolutely no coding required."
      cards={[
        {
          heading: 'You determine the CPA (cost per acquisition).',
          description: 'Set the price you are willing to pay to acquire a new subscriber.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto,width=500/static_assets/boosts/offer.png',
        },
        {
          heading: 'You determine the budget.',
          description: 'Deposit funds directly into your beehiiv wallet to carefully manage your spend.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto,width=500/static_assets/boosts/funds.png',
        },
        {
          heading: 'You determine the distribution.',
          description:
            'Receive applications from our network of thousands of trusted publications and approve those you want to collaborate with.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto,width=500/static_assets/boosts/network.png',
        },
        {
          heading: 'You only pay for active and engaged subscribers.',
          description: 'Built entirely on trusted first party data, you’ll only ever pay for quality readers.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto,width=500/static_assets/boosts/overview.png',
        },
        {
          heading: 'You skyrocket your readership.',
          description:
            'Built by a team who has scaled newsletters well into the millions, Boosts has a ton under the hood to ensure this delivers results.',
          imageUrl: 'https://media.beehiiv.com/cdn-cgi/image/format=auto,width=500/static_assets/boosts/grow.png',
        },
      ]}
      learnMoreUrl="https://www.beehiiv.com/newsletter-solutions/monetize/boosts"
      onUpsell={() => {
        pushDataLayerEvent();
      }}
    />
  );
};

export default Teaser;
