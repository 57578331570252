import { useState } from 'react';

import ActionModal from '@/components/ActionModal';
import { Input } from '@/components/Form';
import { BoostAgreementFilter } from '@/interfaces/boosts/grow/boost_agreement_filter';

export interface Props {
  savedFilter?: BoostAgreementFilter;
  isWorking: boolean;
  onClose: () => void;
  onProceed: (name: string, filterId: string) => void;
}

const RenameBoostAgreementFilterModal = ({ savedFilter, isWorking, onClose, onProceed }: Props) => {
  const [name, setName] = useState(savedFilter?.name || '');
  const isOpen = !!savedFilter;
  const canSave = !!name;

  const handleClose = () => {
    setName('');
    onClose();
  };

  const handleProceed = () => {
    onProceed(name, savedFilter?.id || '');
    setName('');
  };

  return (
    <ActionModal
      resourceId="rename-boost-agreemeent-filter"
      isOpen={isOpen}
      isWorking={isWorking}
      disabled={!canSave}
      onClose={handleClose}
      onProceed={handleProceed}
      modalMessageType="info"
      headerText="Rename saved filter"
      actionText="Update name"
    >
      <div className="my-6 space-y-6">
        <Input
          name="filter-name"
          labelText="Filter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholderText={savedFilter?.name}
          helperText="Specify a name to easily identify this filter"
        />
      </div>
    </ActionModal>
  );
};

export default RenameBoostAgreementFilterModal;
