import { useNavigate } from 'react-router-dom';
import moment from 'moment-mini';

import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { Typography } from '@/components/Typography';
import { WebhookMessageAttempt } from '@/interfaces/webhooks/message_attempt';
import StatusBadge from '@/pages/Settings/Pages/Integrations/Webhooks/Endpoints/Show/Messages/Index/StatusBadge';
import { Button } from '@/ui/Button';

type ListRowProps = {
  messageAttempt: WebhookMessageAttempt;
};

const MessageListItem = ({ messageAttempt }: ListRowProps) => {
  const navigate = useNavigate();
  return (
    <ItemRow>
      <ItemColumn>
        <StatusBadge messageAttempt={messageAttempt} />
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {messageAttempt.message.event_type}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700">
          {messageAttempt.message.id}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Typography size="sm" colorWeight="700" as="time">
          {moment(messageAttempt.timestamp).format('LLL')}
        </Typography>
      </ItemColumn>
      <ItemColumn>
        <Button
          variant="primary-inverse"
          size="xxs"
          onClick={() =>
            navigate(
              `/settings/publication/webhooks/${messageAttempt.endpoint_id}/messages/${messageAttempt.message_id}/message_attempts/${messageAttempt.id}`
            )
          }
        >
          View
        </Button>
      </ItemColumn>
    </ItemRow>
  );
};

export default MessageListItem;
