import { NavbarSerializableNode, TNavbarDropdownElement, TNavbarMenuListElement } from '@shared/dream-components';
import { v4 as uuidv4 } from 'uuid';

export const navbarListContent = (logoSrc?: string, homeUrl?: string): TNavbarMenuListElement => ({
  type: 'navbar_menu_list',
  attrs: {
    id: uuidv4(),
    justify: 'center',
    width: '100%',
  },
  content: [
    {
      type: 'navbar_menu_list_group',
      attrs: {
        id: uuidv4(),
        type: 'left',
      },
      content: [
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'logo',
            label: 'Link',
            width: 'auto',
            height: 36,
            href: homeUrl || '/',
            src: logoSrc || '',
            showOnMobile: true,
            mobileJustify: 'start',
          },
          content: [],
        },
      ],
    },
    {
      type: 'navbar_menu_list_group',
      attrs: {
        id: uuidv4(),
        type: 'middle',
      },
      content: [
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'link',
            label: 'Link',
          },
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'link',
            label: 'Link',
          },
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'link',
            label: 'Link',
          },
        },
      ],
    },
    {
      type: 'navbar_menu_list_group',
      attrs: {
        id: uuidv4(),
        type: 'right',
      },
      content: [
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'search',
            label: 'Link',
            showOnMobile: true,
            mobileJustify: 'end',
            action: 'search',
          },
          content: [],
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'user_button',
            color: '#8A24FFFF',
            label: 'Profile',
            action: 'profile',
            background: '#FFFFFF',
            showOnMobile: true,
            mobileJustify: 'end',
            autoGenerated: true,
          },
          content: [],
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'button',
            color: '#8A24FFFF',
            label: 'Log In',
            action: 'login',
            background: '#F4EBFFFF',
            autoGenerated: true,
          },
          content: [],
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'button',
            color: '#FFFFFFFF',
            label: 'Sign Up',
            action: 'sign_up',
            autoGenerated: true,
          },
          content: [],
        },
      ],
    },
  ],
});

export const navbarList2ndRowContent = (): NavbarSerializableNode => ({
  type: 'navbar_menu_list',
  attrs: {
    id: uuidv4(),
    justify: 'center',
  },
  content: [
    {
      type: 'navbar_menu_list_group',
      attrs: {
        id: uuidv4(),
        type: 'left',
      },
      content: [],
    },
    {
      type: 'navbar_menu_list_group',
      attrs: {
        id: uuidv4(),
        type: 'middle',
      },
      content: [
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'link',
            label: 'Link',
          },
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'link',
            label: 'Link',
          },
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'link',
            label: 'Link',
          },
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'link',
            label: 'Link',
          },
        },
        {
          type: 'navbar_item',
          attrs: {
            id: uuidv4(),
            type: 'link',
            label: 'Link',
          },
        },
      ],
    },
    {
      type: 'navbar_menu_list_group',
      attrs: {
        id: uuidv4(),
        type: 'right',
      },
      content: [],
    },
  ],
});

export const navbarContent = (logoSrc?: string, homeUrl?: string): NavbarSerializableNode => ({
  type: 'navbar_menu',
  attrs: {
    id: uuidv4(),
    rows: 1,
  },
  content: [navbarListContent(logoSrc, homeUrl)],
});

export const navbarDropdownContent = () =>
  ({
    type: 'navbar_dropdown',
    attrs: {
      id: uuidv4(),
      label: 'Dropdown 1',
      style: 'card',
    },
    content: [
      {
        type: 'navbar_dropdown_column',
        attrs: {
          id: uuidv4(),
        },
        content: [
          {
            type: 'navbar_dropdown_item',
            attrs: {
              id: uuidv4(),
              title: 'Dropdown Item 1.A',
              description: 'Dropdown Item 1.A Description',
              style: 'description',
              href: '/item-1',
            },
          },
          {
            type: 'navbar_dropdown_item',
            attrs: {
              id: uuidv4(),
              title: 'Dropdown Item 1.B',
              description: 'Dropdown Item 1.B Description',
              style: 'description',
              href: '/item-1-b',
            },
          },
        ],
      },
      {
        type: 'navbar_dropdown_column',
        attrs: {
          id: uuidv4(),
        },
        content: [
          {
            type: 'navbar_dropdown_item',
            attrs: {
              id: uuidv4(),
              title: 'Dropdown Item 2.A',
              description: 'Dropdown Item 2.A Description',
              style: 'description',
              href: '/item-1',
            },
          },
          {
            type: 'navbar_dropdown_item',
            attrs: {
              id: uuidv4(),
              title: 'Dropdown Item 2.B',
              description: 'Dropdown Item 2.B Description',
              style: 'description',
              href: '/item-1-b',
            },
          },
        ],
      },
    ],
  } as TNavbarDropdownElement);
