import { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient, UseQueryResult } from 'react-query';
import { ArrowDownIcon, PlusIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import FaqModal from '@/components/FaqModal';
import CurrencyInput from '@/components/Form/CurrencyInput';
import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';
import useStripeConnectAccount, { StripeAccountStatus } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import useCreateLedgerEntry from '@/hooks/boosts/useCreateLedgerEntry';
import { Wallet } from '@/hooks/boosts/useWallet';
import useCurrentPublicationId from '@/hooks/usePublications/useCurrentPublicationId';
import { LedgerEntryCategory, LedgerKind } from '@/interfaces/ledger_entry';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';
import { Textarea } from '@/ui/Textarea';
import Tooltip from '@/ui/Tooltip';
import analytics from '@/utils/analytics';

interface WalletData {
  available_balance: string;
  total_balance: string;
  withdrawable_balance: string;
  all_time_earnings: string;
}

interface Props {
  walletData?: WalletData;
  walletQuery: UseQueryResult<Wallet, unknown>;
}

const AvailableFundsAndEarnings = ({ walletData, walletQuery }: Props) => {
  const [transferProcessing, setTransferProcessing] = useState(false);
  const [transferAmount, setTransferAmount] = useState<number | undefined>();
  const [transferDescription, setTransferDescription] = useState('');
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [isFaqModalOpen, setIsFaqModalOpen] = useState(false);
  const currentPublicationId = useCurrentPublicationId();
  const [checkoutSessionProcessing, setCheckoutSessionProcessing] = useState(false);
  const { data: ledgerData } = walletQuery;
  const { data: stripeConnectAccount } = useStripeConnectAccount(currentPublicationId);
  const ledgerBalanceCents = ledgerData?.withdrawable_balance_cents || 0;
  const boostsAccountStatus = stripeConnectAccount?.boosts_account_status || StripeAccountStatus.MISSING;
  const { settings } = useSettings();
  const hideAddFundsButton = settings && (!settings.organization_boosts || !settings.boosts);

  const queryClient = useQueryClient();

  const clearTransferModal = () => {
    setTransferProcessing(false);
    setTransferModalOpen(false);
    setTransferAmount(undefined);
    setTransferDescription('');
  };

  const createLedgerEntry = useCreateLedgerEntry({
    onSuccess: () => {
      toast.success('Withdrawal initiated!');
      queryClient.invalidateQueries([currentPublicationId, 'ledger_entries', LedgerKind.MONETIZATION]);
      clearTransferModal();
    },
    onError: (errPayload) => {
      clearTransferModal();
      const errors = errPayload?.response?.data?.message || 'Something went wrong';
      toast.error(errors);
    },
  });

  const handleCreateWithdrawlSession = () => {
    setTransferProcessing(true);

    createLedgerEntry.mutate({
      amount_cents: (transferAmount || 0) * -1,
      description: transferDescription,
      category: LedgerEntryCategory.STRIPE_PAYOUT,
      ledger_kind: LedgerKind.MONETIZATION,
      prevent_negative_ledger_balance: true,
    });
  };

  const withdrawDisabled = boostsAccountStatus !== StripeAccountStatus.ACTIVE || ledgerBalanceCents <= 0;

  const onCurrencyChange = (value: number) => {
    setTransferAmount(value);
  };

  const handleCreateSessionRequest = () => {
    if (currentPublicationId) {
      const params = { publication_id: currentPublicationId };
      setCheckoutSessionProcessing(true);

      api
        .get('/boosts/grow/create_checkout_sessions', { params })
        .then((res) => {
          analytics.track('Clicked Add Funds');
          window.open(`${res.data.url}`, '_blank');
        })
        .catch((errPayload) => {
          const error = errPayload?.response?.data?.message || 'Something went wrong';
          toast.error(error);
        })
        .finally(() => {
          setCheckoutSessionProcessing(false);
        });
    }
  };

  return (
    <>
      <Card className="mt-6 rounded-lg">
        <Typography token="font-medium/text/base" colorWeight="900">
          Deposited funds and available earnings
        </Typography>
        <div className="flex flex-col md:flex-row gap-2 justify-between mt-6">
          <LoadingBox isLoading={walletQuery.isLoading} isError={walletQuery.isError} height={130}>
            {walletData && (
              <Card className="col-span-2 xl:col-span-1 w-full p-4 rounded-lg">
                {!hideAddFundsButton && (
                  <div className="flex w-full justify-end mb-5">
                    <Button
                      loading={checkoutSessionProcessing}
                      type="button"
                      Icon={PlusIcon}
                      onClick={handleCreateSessionRequest}
                    >
                      Add funds
                    </Button>
                  </div>
                )}
                <div className="flex w-full">
                  <Typography token="font-medium/text/sm" colorWeight="700">
                    Available funds
                  </Typography>
                  <button type="button" className="ml-1" onClick={() => setIsFaqModalOpen(true)}>
                    <Tooltip id="available-funds-tooltip" text="Learn about available and total funds" />
                  </button>
                </div>
                <div className="flex w-full mt-2">
                  <Typography token="font-semibold/text/3xl">{walletData.available_balance}</Typography>
                </div>
                <div className="w-full flex justify-between mt-4">
                  <Typography token="font-medium/text/xs" colorWeight="500">
                    Total
                  </Typography>
                  <Typography token="font-medium/text/xs" colorWeight="900">
                    {walletData.total_balance}
                  </Typography>
                </div>
              </Card>
            )}
          </LoadingBox>
          <LoadingBox isLoading={walletQuery.isLoading} isError={walletQuery.isError} height={130}>
            {walletData && (
              <Card className="col-span-2 xl:col-span-1 w-full p-4 rounded-lg">
                <div className="flex w-full justify-end">
                  <Button
                    disabled={withdrawDisabled}
                    loading={transferProcessing}
                    type="button"
                    onClick={() => setTransferModalOpen(true)}
                    Icon={ArrowDownIcon}
                  >
                    Withdraw earnings
                  </Button>
                </div>
                <div className="flex w-full mt-5">
                  <Typography token="font-medium/text/sm" colorWeight="700">
                    Available earnings
                  </Typography>
                </div>
                <div className="flex w-full mt-2">
                  <Typography token="font-semibold/text/3xl">{walletData.withdrawable_balance}</Typography>
                </div>
                <div className="w-full flex justify-between mt-4">
                  <Typography token="font-medium/text/xs" colorWeight="500">
                    All time earnings
                  </Typography>
                  <Typography token="font-medium/text/xs" colorWeight="900">
                    {walletData?.all_time_earnings}
                  </Typography>
                </div>
              </Card>
            )}
          </LoadingBox>
        </div>
      </Card>
      <FaqModal
        faqs={[
          {
            question: `What can these funds be used for?`,
            answer: `You can use these funds to spend on Boosts to grow your newsletter.`,
            defaultOpen: true,
          },
          {
            question: `What's the difference between total and available funds?`,
            answer: `In an effort to avoid negative balance, we temporarily withhold funds during the Boosts verification phase, much like a bank setting a temporary charge on your credit card. After the verification is done, we return any surplus funds to your available balance, which is the amount you can still use for spending. For instance, if your Cost Per Acquisition (CPA) is $2.00 and there are 100 signups yet to be verified, we'll place a hold of $200.00. In this example, your available balance - the amount you're able to spend - will be your total balance minus this held $200.00.`,
          },
          {
            question: `How long will my funds be held?`,
            answer: `We understand how important it is for you to have access to your funds promptly. Our team works diligently to expedite the verification process, and we aim to make the period of funds being held as brief as possible. However, to ensure Boosts is delivering high-quality signups, the process can take anywhere from 10 to 17 days, depending on various factors. We appreciate your patience and understanding during this period, and we assure you that we're doing our best to complete the verification as quickly as we can.`,
          },
        ]}
        isOpen={isFaqModalOpen}
        onClose={() => setIsFaqModalOpen(false)}
      />
      <ActionModal
        isOpen={transferModalOpen}
        isWorking={transferProcessing}
        onClose={() => clearTransferModal()}
        onProceed={handleCreateWithdrawlSession}
        resourceId={currentPublicationId}
        disabled={(transferAmount || 0) <= 0}
        actionText="Withdraw"
      >
        <div className="flex flex-col space-y-4 mt-2">
          <Typography token="font-medium/text/lg" colorWeight="900">
            Withdraw Earnings
          </Typography>
          {ledgerData?.withdrawable_balance && (
            <div className="flex flex-col py-6 space-y-2">
              <Typography token="font-normal/text/sm" colorWeight="500">
                Available funds
              </Typography>
              <Typography token="font-semibold/text/3xl" colorWeight="900">
                {ledgerData?.withdrawable_balance}
              </Typography>
            </div>
          )}
          <CurrencyInput
            id="transfer_amount"
            name="transfer_amount"
            value={transferAmount}
            onChange={onCurrencyChange}
            labelText="Withdraw Amount"
            minCents={0}
            maxCents={1000000}
            required
            leadingText="USD"
            placeholder="0.00"
          />
          <Textarea
            name="transfer_description"
            value={transferDescription}
            onChange={(e) => setTransferDescription(e.target.value)}
            labelText="Description"
            placeholderText="This withdrawal is for..."
          />
        </div>
      </ActionModal>
    </>
  );
};

export default AvailableFundsAndEarnings;
